import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { noop } from '@thalesrc/js-utils';
import React, { useRef } from 'react';

import SearchBarContainer from './SearchBarContainer';

interface Props {
  value: string;

  onChange(value: string): void;

  placeholder: string;
}

export default function BlockSearchBar({ value = '', onChange = noop, placeholder = '' }: Props) {
  const inputRef = useRef<HTMLInputElement>();

  function handleEscape() {
    inputRef.current.blur();
  }

  return (
    <>
      <SearchBarContainer
        value={value}
        onChange={onChange}
        onEscape={handleEscape}
        input={({ value: inputValue, onChange: inputOnChange, onKeyPress: inputOnKeyPress, onBlur, onFocus }) => (
          <TextField
            value={inputValue || ''}
            onChange={({ target: { value: _value } }) => inputOnChange(_value)}
            onKeyPress={({ key }) => inputOnKeyPress(key)}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            inputRef={inputRef}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => inputOnChange('')} edge="end" className="text-white">
                    <ClearIcon style={{ color: 'dodgerblue' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        )}
      />
    </>
  );
}
