import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { PhysicalPayload } from './Physical.payload';
import { PhysicalResult } from './Physical.result';

export async function getPhysical(): Promise<PhysicalResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PhysicalResult[]>(`/firms/physical-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function savePhysical(body: PhysicalPayload): Promise<PhysicalResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/physical-check-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updatePhysical({ id, ...body }: MakeRequired<Partial<PhysicalResult>, 'id'>): Promise<PhysicalResult> {
  const [error, res] = await tryCatch(HSApi.put<PhysicalResult>(`/firms/physical-check-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deletePhysical(id: number) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/physical-check-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminPhysical(): Promise<PhysicalResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PhysicalResult[]>(`/physical-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminPhysical(body: PhysicalPayload): Promise<PhysicalResult> {
  const [err, res] = await tryCatch(HSApi.post('/physical-check-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminPhysical({ id, ...body }: MakeRequired<Partial<PhysicalResult>, 'id'>): Promise<PhysicalResult> {
  const [error, res] = await tryCatch(HSApi.put<PhysicalResult>(`/physical-check-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminPhysical(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/physical-check-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
