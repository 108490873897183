import { WorkEquipmentLayoutFilter } from '../../../client';
import HSApi from '../../HSApi';

export async function getWorkEquipmentLayout(page = 0, size = 20, order: null | string, filters: any) {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof WorkEquipmentLayoutFilter)[] = ['models', 'brands', 'serialNumbers'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }
  const payload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/work-equipment-layouts/views`, payload);

  return res.data;
}

export async function exportWorkEquipmentLayout(filters: any) {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof WorkEquipmentLayoutFilter)[] = ['models', 'brands', 'serialNumbers'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }
  const payload = {
    filter: parsedFilters,
  };

  const res = await HSApi.post(`/work-equipment-layouts/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'iş-ekipman-listesi.xlsx';
  a.click();
}
