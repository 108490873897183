// tslint:disable:max-classes-per-file

import { modelDataMatcher } from 'utils';

import { SearchMedicineResultItem } from '../medicine/get-medicine.result';
import { PrescriptionMedicineModel } from './prescription-medicine.model';
import { PrescriptionResult } from './prescription.result';

export class PrescriptionModel {
  public id: string = null;
  public examinationId: string = null;
  public employeeId: string = null;
  public protocolNo: string = null;
  public description: string = null;
  public prescriptionProvisionTypeDefinitionId: number = null;
  public prescriptionTypeDefinitionId: number = null;
  public prescriptionSubtypeDefinitionId: number = null;
  public prescriptionDescriptionTypeDefinitionId: number = null;
  public prescriptionMedicines: PrescriptionMedicineModel[] = [];
  public createdBy: string = null;
  public createdDate: string = null;
  public lastModifiedBy: string = null;
  public lastModifiedDate: string = null;
  public deleted: boolean = null;
  public prescriptionState: string = null;
  public request: string = null;
  public response: string = null;
  public requestDate: string = null;
  public statusId: number = null;
  public medulaPlantCode: number = null;
  public eprescriptionNo: string = null;

  constructor(data: Partial<PrescriptionModel> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: PrescriptionResult, prescriptionMedicines: SearchMedicineResultItem[]) {
    return new PrescriptionModel({
      ...data,
      prescriptionMedicines: [
        ...data.prescriptionMedicines?.map(med => {
          const medicine = prescriptionMedicines?.find(prescriptionMedicine => prescriptionMedicine.id === med.medicineId);
          return {
            ...med,
            employeeId: data.employeeId,
            label: medicine?.label,
            barcode: medicine?.barcode,
            atcCode: medicine?.atcCode,
            atcName: medicine?.atcName,
          };
        }),
      ],
    });
  }
}
