import { SaveEPrescriptionPayload } from './eprescription.payload';
import { EPrescriptionSaveResult } from './eprescription.result';

export const E_SIGN_TOKEN_KEY = {
  checked: 1,
  unchecked: 0,
  other: 2,
};

export async function checkESignToken(): Promise<unknown> {
  return await fetch('http://localhost:12200/checktoken')
    .then(response => response.json())
    .then(data => {
      if (data?.sonucKodu === 'INFO_001') {
        return E_SIGN_TOKEN_KEY.checked;
      } else if (data?.sonucKodu === 'INFO_002') {
        return E_SIGN_TOKEN_KEY.unchecked;
      } else {
        return E_SIGN_TOKEN_KEY.other;
      }
    })
    .catch(err => {
      console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
    });
}
var rand = function() {
  return Math.random().toString(36).substr(2); // remove `0.`
};

var token = function() {
  return rand() + rand(); // to make it longer
};
export async function saveEPrescription(payload: SaveEPrescriptionPayload): Promise<EPrescriptionSaveResult> {
  var token_ = token();
  var data_new = null;
  const data = await fetch('https://safe.artimetrik.net/action.php?token='+token_, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      window.open('https://safe.artimetrik.net/imza.php?token='+token_, "_blank", "noreferrer");
      data_new = fetch('https://safe.artimetrik.net/action.php?token='+token_, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({action: 'erecete_detay'}),
      })
          .then(response => {
            return response.json();
          })
          .then(d => {
            return d;
          })
          .catch(err => {
            console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
          });
    })
    .then(d => {
      return d;
    })
    .catch(err => {
      console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
    });
  return data_new;
}

export async function saveEPrescriptionOld(payload: SaveEPrescriptionPayload): Promise<EPrescriptionSaveResult> {
  const data = await fetch('http://localhost:12200/signdata', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
      .then(response => {
        return response.json();
      })
      .then(d => {
        return d;
      })
      .catch(err => {
        console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
      });

  return data;
}

export async function deleteEPrescription(payload: SaveEPrescriptionPayload): Promise<EPrescriptionSaveResult> {
    var token_ = token();
    var data_new = null;
    const data = await fetch('https://safe.artimetrik.net/action.php?token='+token_, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
        .then(response => {
            window.open('https://safe.artimetrik.net/recete_sil.php?token='+token_, "_blank", "noreferrer");
            data_new = fetch('https://safe.artimetrik.net/action.php?token='+token_, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({action: 'erecete_detay'}),
            })
                .then(response => {
                    return response.json();
                })
                .then(d => {
                    return d;
                })
                .catch(err => {
                    console.log('Fetch Error in deleteEPrescription:', err); // tslint:disable-line:no-console
                });
        })
        .then(d => {
            return d;
        })
        .catch(err => {
            console.log('Fetch Error in deleteEPrescription:', err); // tslint:disable-line:no-console
        });

    return data_new;
}

export async function deleteEPrescriptionOld(payload: SaveEPrescriptionPayload): Promise<EPrescriptionSaveResult> {
  const data = await fetch('http://localhost:12200/signdata', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      return response.json();
    })
    .then(d => {
      return d;
    })
    .catch(err => {
      console.log('Fetch Error in deleteEPrescription:', err); // tslint:disable-line:no-console
    });

  return data;
}
