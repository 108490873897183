import {
  Avatar,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeWorkSharpIcon from '@material-ui/icons/HomeWorkSharp';
import { Pagination } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';

import IndicatorWrapper from '../../../../../Common/IndicatorWrapper';
import Search from '../../../../BaseDefinitionPage/BaseDefinition/List/Search';
import { CompanyUserContext } from '../../company-user.context';
import Action from './Action';
import EditDialog from './EditDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    flexBasis: '30%',
    flexShrink: 0,
    color: 'gray',
    fontWeight: 'bold',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  heading2: {
    flexBasis: '70%',
    flexWrap: 'wrap',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
}));
// @ts-ignore
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / Number(itemsPerPage));

  function currentData() {
    const begin = (currentPage - 1) * Number(itemsPerPage);
    const end = begin + Number(itemsPerPage);
    return data.slice(begin, end);
  }

  function next() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

type DefaultSizeType = 5 | 10 | 20 | 50;
const DEFAULT_SIZES: DefaultSizeType[] = [5, 10, 20, 50];

export default function Index() {
  const classes = useStyles();
  const { companyUser, loading } = useContext(CompanyUserContext);
  const [page, setPage] = useState(1);

  const [defaultValue] = useState('');
  const [label, setLabel] = useState(defaultValue);
  const [size, setSize] = useState('10');

  const updateSearchKeyword = useCallback((value: string) => setLabel(value), [setLabel]);
  const handleSizeChange = useCallback(({ target: { value } }) => setSize(value as T), [setSize]);

  const filteredData = companyUser?.filter(x => x?.company?.companyName?.toLowerCase().includes(label.toLowerCase()));

  const count = Math.ceil(filteredData?.length / size);
  const _DATA = usePagination(filteredData, size);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <IndicatorWrapper data={companyUser} errorMessage="Gösterilecek Kayıt Bulunamadı" loading={loading}>
        <Search value={label} onChange={updateSearchKeyword} />
        <List dense className={classes.root}>
          {filteredData?.map((item: any, index) => (
            <>
              <ListItem button key={item.id}>
                <ListItemIcon>
                  <HomeWorkSharpIcon />
                </ListItemIcon>
                <ListItemText className={classes.heading} id={item.company.id} primary={item.company?.companyName} />
                <div style={{ flexWrap: 'wrap', flexBasis: '70%' }}>
                  {item.users.map((user: any) => (
                    <Chip
                      style={{ marginRight: '5px', marginTop: '5px' }}
                      key={user.id}
                      avatar={<Avatar alt={user?.name} src="/saglik-gozetimi/public/images/Avatars/Orginal/0.jpg" />}
                      label={user?.name}
                    />
                  ))}
                </div>
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography color="inherit" variant="body2" className="mr-1">
              Gözüken kayıt sayısı:
            </Typography>
          </Grid>
          <Grid item>
            <Select value={size} onChange={handleSizeChange} classes={{ root: 'pl-1' }}>
              {DEFAULT_SIZES.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Pagination className="ml-2" showFirstButton showLastButton onChange={handleChange} count={count} page={page} color="primary" />
          </Grid>
        </Grid>
      </IndicatorWrapper>
      <EditDialog />
    </>
  );
}
