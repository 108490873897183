import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetExposuresResult } from './get-exposures.result';

export async function getExposures(): Promise<GetExposuresResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetExposuresResult[]>(`/firms/exposure-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
