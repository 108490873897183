import { Drawer as MUIDrawer, makeStyles } from '@material-ui/core';
import React, { CSSProperties, PropsWithChildren, useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    transition: theme.transitions.create(['padding-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&.opened': {
      transition: theme.transitions.create(['padding-left'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawer: {
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: -1,
  },
  paper: {
    position: 'absolute',
    width: '100%',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
}));

interface Props {
  opened: boolean;
  Drawer: JSX.Element;
  className?: string;
  style?: CSSProperties;
  width?: number;
}

export default function PersistentDrawer({
  Drawer,
  opened,
  className = '',
  style = null,
  children,
  width = 320,
}: PropsWithChildren<Props>) {
  const classes = useStyles();

  const containerStyles = useMemo<CSSProperties>(() => (opened ? { ...style, paddingLeft: width } : style), [opened, width, style]);
  const drawerStyles = useMemo<CSSProperties>(() => (opened ? { width, zIndex: 1 } : { width }), [opened, width]);

  return (
    <section className={`${classes.container} ${className} ${opened ? 'opened' : ''}`} style={containerStyles}>
      <MUIDrawer
        variant="persistent"
        anchor="left"
        open={opened}
        classes={{ root: classes.drawer, paper: classes.paper }}
        style={drawerStyles}>
        {Drawer}
      </MUIDrawer>
      <div className={classes.content}>{children}</div>
    </section>
  );
}
