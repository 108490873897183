import Review from 'Accident/Review';
import Definitions from 'Definitions';
import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {UserContext} from 'context/user';
import {LicenceContext} from "context/licence";
import Training from 'Training';
import TransactionsAndLists from 'TransactionsAndLists';
import Accident from './Accident';
import Actions from './Actions/ActionListAdd';
import ForgotPassword from './Authentication/ForgotPassword';
import Login from './Authentication/Login';
import LicenceError from './Authentication/LicenceError';
import Loading from './Authentication/Loading';
import NewPassword from './Authentication/NewPassword';
import ResetPassword from './Authentication/ResetPassword';
import Committee from './Committee';
import Dashboard from './Dashboard';
import EmergencyManagement from './EmergencyManagement ';
import GuestLayout from './GuestLayout';
import Layout from './Layout';
import NearMiss from './NearMiss';
import OccupationalSafetyRegistries from './OccupationalSafetyRegistries';
import Ppe from './PPE';
import Profil from './ProfilPage';
import RiskAssessments from './RiskAssessments';
import Statistics from './Statistics';
import WorkEquipment from './WorkEquipment';
import EmployeeList from "./TransactionsAndLists/EmployeeList";

const UNAUTHORIZED_PATHS = (
  <Switch>
    <Route path="/" exact>
      <Login />
    </Route>
    <Route path="/sifremi-unuttum">
      <ForgotPassword />
    </Route>
    <Route path="/reset-forgot-password">
      <ResetPassword />
    </Route>
    <Route path="/new-user-activation">
      <NewPassword />
    </Route>
    <Route path="/accident/review">
      <GuestLayout>
        <Review />
      </GuestLayout>
    </Route>
    <Redirect to="/" />
  </Switch>
);

const LOADING_PATHS = (
    <Loading />
);

const UNLICENCED_PATHS = (
    <Switch>
        <Route path="/" exact>
            <LicenceError />
        </Route>
        <Redirect to="/" />
    </Switch>
);

export default function Routes() {
    const { user } = useContext(UserContext);
    const { licence } = useContext(LicenceContext);
    if(licence != undefined) {
        if(!licence.status) {
            return <BrowserRouter>{UNLICENCED_PATHS}</BrowserRouter>;
        } else {
            if(typeof user == 'undefined') {
                return <BrowserRouter>{LOADING_PATHS}</BrowserRouter>;
            } else {
                if(user != null && user.roles) {
                    return <BrowserRouter>
                        <Layout>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={() => {
                                        return <Dashboard />;
                                    }}
                                />
                                <Route path="/profile">
                                    <Profil />
                                </Route>
                                <Route path="/transactions/employee">
                                    <EmployeeList />
                                </Route>
                                {licence.modules.indexOf('SAGLIK_GOZETIMI') == -1 ? null :SAGLIK_GOZETIMI_PATHS}
                                {licence.modules.indexOf('EGITIM') == -1 ? null :EGITIM_PATHS}
                                {licence.modules.indexOf('KKD') == -1 ? null :KKD_PATHS}
                                {licence.modules.indexOf('AKSIYON') == -1 ? null :AKSIYON_PATHS}
                                {licence.modules.indexOf('ISG') == -1 ? null :ISG_PATHS}
                                {licence.modules.indexOf('IS_EKIPMAN') == -1 ? null :IS_EKIPMAN_PATHS}
                                {licence.modules.indexOf('IS_KAZA') == -1 ? null :IS_KAZA_PATHS}
                                {licence.modules.indexOf('IS_KAZA') == -1 ? null :RAMAK_KALA_PATHS}
                                {licence.modules.indexOf('ONAYLI_DEFTER') == -1 ? null :ONAYLI_DEFTER_PATHS}
                                {licence.modules.indexOf('RISK_DEGERLENDIRME') == -1 ? null :RISK_DEGERLENDIRME_PATHS}
                                {licence.modules.indexOf('ACIL_DURUM') == -1 ? null :ACIL_DURUM_PATHS}
                                <Route path="/definitions">
                                    <Definitions />
                                </Route>
                                <Route path="/statistics">
                                    <Statistics />
                                </Route>
                            </Switch>
                        </Layout>
                    </BrowserRouter>;
                } else {
                    return <BrowserRouter>{UNAUTHORIZED_PATHS}</BrowserRouter>;
                }
            }
        }
    } else {
        return <BrowserRouter>{LOADING_PATHS}</BrowserRouter>;
    }
}

const SAGLIK_GOZETIMI_PATHS = (
    <Route path="/transactions">
        <TransactionsAndLists />
    </Route>
);
const EGITIM_PATHS = (
    <Route path="/training">
        <Training />
    </Route>
);
const KKD_PATHS = (
    <Route path="/ppe">
        <Ppe />
    </Route>
);
const AKSIYON_PATHS = (
    <Route path="/actions">
        <Actions />
    </Route>
);
const ISG_PATHS = (
    <Route path="/committee">
        <Committee />
    </Route>
);
const IS_EKIPMAN_PATHS = (
    <Route path="/work-equipment">
        <WorkEquipment />
    </Route>
);
const IS_KAZA_PATHS = (
    <Route path="/accident">
        <Accident />
    </Route>
);
const RAMAK_KALA_PATHS = (
    <Route path="/near-miss">
        <NearMiss />
    </Route>
);
const ONAYLI_DEFTER_PATHS = (
    <Route path="/occupational-safety-registries">
        <OccupationalSafetyRegistries />
    </Route>
);
const RISK_DEGERLENDIRME_PATHS = (
    <Route path="/risk">
        <RiskAssessments />
    </Route>
);
const ACIL_DURUM_PATHS = (
    <Route path="/emergency-management">
        <EmergencyManagement />
    </Route>
);
const getAuthorizedPaths = () => (
    <Layout>
        <Switch>
          <Route path="/definitions">
            <Definitions />
          </Route>
          <Route path="/statistics">
            <Statistics />
          </Route>
        </Switch>
    </Layout>
);
