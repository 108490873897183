import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useCallback, useContext, useMemo } from 'react';

import Button from 'Common/Button';

import { CheckListContext } from './checklist.context';

const useStyles = makeStyles(theme => ({
  aside: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridTemplateRows: '1fr auto',
    },
  },
}));

export default function Content() {
  const classes = useStyles();
  const { list, loading, description, setEditOpened, editButtonText } = useContext(CheckListContext);

  const largerThanMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const buttonContainerClassName = useMemo(() => (largerThanMd ? 'pl-4 text-right' : 'pl-4'), [largerThanMd]);
  const openEditDialog = useCallback(() => setEditOpened(true), [setEditOpened]);

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        {!!list && !loading && (
          <>
            <TableContainer className="ml-2">
              <Table size="small">
                <TableBody>
                  {list.map(item => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.label}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          className="text-white text-bold"
                          style={{ backgroundColor: item.color, width: '100px' }}
                          variant="contained"
                          disabled>
                          {item.valueText}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
      <Grid item sm={12} md={6} className={classes.aside}>
        <List component="nav" style={{ width: '100%', marginLeft: '15px' }} aria-label="contacts">
          <ListItem>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="h6">Açıklama</Typography>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={description} />
          </ListItem>
        </List>
        <div className={buttonContainerClassName}>
          <Button color="secondary" variant="contained" onClick={openEditDialog}>
            {editButtonText}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
