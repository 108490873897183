import React from 'react';

import TypeAdd from './Add';
import TypeList from './List';

export default function Index() {
  return (
    <>
      <TypeAdd />
      <TypeList />
    </>
  );
}
