import React, { useState } from 'react';
import { useParams } from 'react-router';

import { WorkEquipmentPeriodicControlDto } from '../../api/client';
import { WorkEquipmentPeriodicControllerService } from '../../api/client';
import { useFetch } from '../../utils';
import { WorkEquipmentPeriodicControlContext } from './work-equipment-periodic-control.context';
import WorkEquipmentPeriodic from './WorkEquipmentPeriodic';

export default function Index({ WorkEquipmentDefinitionData }) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<WorkEquipmentPeriodicControlDto>(null);
  const [loading, setLoading] = useState(true);
  const { workEquipmentDefinitionId } = useParams<{ workEquipmentDefinitionId: string }>();

  const { data, fetch } = useFetch(
    () => WorkEquipmentPeriodicControllerService.getAllWorkEquipmentPeriodicControls(workEquipmentDefinitionId),
    [],
    { setLoading }
  );

  return (
    <WorkEquipmentPeriodicControlContext.Provider
      value={{
        workEquipmentPeriodicControl: data,
        reload: fetch,
        WorkEquipmentDefinitionData,
        setLoading,
        loading,
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <WorkEquipmentPeriodic />
    </WorkEquipmentPeriodicControlContext.Provider>
  );
}
