import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { saveTrainer, TrainerModel, updateTrainer } from 'api/hs/definitions/trainings/trainers';
import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import AutoComplete from 'Common/Form/AutoComplete';
import Select, { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { NID_MASK } from 'model';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: TrainerModel;
}

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

const PROPS_TO_CONVERT_INTO_NULL = ['title', 'lastName', 'name', 'nid', 'isEmployee', 'employeeId', 'trainerTypeDefinition'];

export default function TrainerForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { yesNoBoolean, trainingDocumentType } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      // Convert `''` values into `null`
      for (const key of PROPS_TO_CONVERT_INTO_NULL) {
        if ((values as any)[key] !== '') {
          continue;
        }

        (values as any)[key] = null;
      }

      try {
        if (!data?.id) {
          await saveTrainer(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateTrainer({
            id: data.id,
            ...values,
          });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      trigger();
    } else {
      reset();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 500 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Eğitmen Güncelle' : 'Eğitmen Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger, getValues }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required mask={NID_MASK} name="nid" label="T.C. Kimlik No" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField required name="name" label="Adı" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField required name="lastName" label="Soyadı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Select name="trainerTypeDefinition" options={trainingDocumentType} label="Eğitmen Türü" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Select name="isInternal" options={yesNoBoolean} label="İşyeri içerisinde mi ? " />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField name="title" label="Ünvan" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <Select name="isEmployee" options={yesNoBoolean} label="Şirket içerisinden mi ?" />
              </Grid>
              {getValues('isEmployee') ? (
                <Grid item md={12} sm={12} lg={12}>
                  <AutoComplete
                    name="employeeId"
                    label="Personel Seç"
                    optionFetcher={employeeOptionFetcher}
                    labelFetcher={employeeLabelFetcher}
                    single
                  />
                </Grid>
              ) : null}

              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
