import React, { useCallback, useContext } from 'react';

import { FirmSubscriptionsContext } from '../../firm-subscription.context';
import FirmSubscriptionsForm from '../form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(FirmSubscriptionsContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <FirmSubscriptionsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
