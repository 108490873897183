import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { FavouriteMedicineResult } from './favourite-medicine.result';
import { SearchMedicineResult, SearchMedicineResultItem } from './get-medicine.result';

export async function getMedicines(keyword: string): Promise<SearchMedicineResult> {
  const [error, res] = await tryCatch(HSApi.get<SearchMedicineResult>(`/medicine-definitions/?nameOrProducer=${keyword}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getMedicinesById(...ids: string[]): Promise<SearchMedicineResultItem[]> {
  if (!ids.length) {
    return [];
  }

  const [error, res] = await tryCatch(
    HSApi.get<SearchMedicineResultItem[]>(`/medicine-definitions/list?${ids.map(id => `ids=${id}`).join('&')}`)
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getMedicineById(id: string): Promise<SearchMedicineResultItem[]> {
  const [error, res] = await tryCatch(HSApi.get<SearchMedicineResultItem[]>(`/medicine-definitions/list?${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
  console.log('res.data', res.data); // tslint:disable-line:no-console
  return res.data;
}

export async function getFavouriteMedicines(): Promise<FavouriteMedicineResult[]> {
  const [error, res] = await tryCatch(HSApi.get<FavouriteMedicineResult[]>(`/favorite-medicines`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function addToFavouriteMedicines(id: string): Promise<FavouriteMedicineResult> {
  const [error, res] = await tryCatch(
    HSApi.post<FavouriteMedicineResult>(`/favorite-medicines`, { medicineId: id })
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function removeFromFavouriteMedicines(id: string): Promise<void> {
  const [error] = await tryCatch(HSApi.delete<FavouriteMedicineResult>(`/favorite-medicines/${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
