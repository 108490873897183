import { createContext } from 'react';

import { AccidentReviewerDto } from '../../api/client';

export interface AccidentReviewContextType {
  token: string;
  reviewer: AccidentReviewerDto;
  insertable: boolean;
  updatable: boolean;
  accidentId?: string;
}

export const AccidentReviewContext = createContext<AccidentReviewContextType>(null);
