import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import PpeReturnForm from '../../../TransactionsAndLists/EmployeeList/Employee/Ppe/ppe-outputs-groups-employee/Form';
import { PpeOutputsContext } from '../ppe-outputs.context';

export default function EditDialog() {
  const { newReturnForm, setNewReturnForm, selectedData } = useContext(PpeOutputsContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setNewReturnForm(false);
    },
    [setNewReturnForm, refresh]
  );

  return <PpeReturnForm open={newReturnForm} onClose={closeDialog} data={selectedData} />;
}
