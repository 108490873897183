import { AccidentView } from '../../api/client';
import { SelectOption } from '../../Common/Form/Select';
import { convertByFieldIdSum, PieChartData } from '../utils/chart-data-converter-pie';
import { sumValueOfField } from '../utils/chart-data-utils';

export const convertAccidentReviewPropertyToChartData = (
  data: any[],
  fieldName: string,
  options: SelectOption<string>[]
): PieChartData[] => {
  const map = new Map(options.map(a => [a.value, a.text as string]));
  const pieChartData = convertByFieldIdSum(data, fieldName, map);
  pieChartData.forEach(d => {
    if (!d.label || d.label === '') {
      d.label = 'Diğer';
    }
  });
  return pieChartData;
};

export const monthLostWorkDaysMap = (data: AccidentView[]): Map<string, any> => {
  const resultMap = new Map<string, any>();

  data.forEach(d => {
    const key = d.date.slice(0, 7);
    const compayName = d.companyName;
    if (!resultMap.has(key)) {
      resultMap.set(key, { date: key });
    }
    if (!resultMap.get(key)[compayName]) {
      resultMap.get(key)[compayName] = 0;
    }
    resultMap.get(key)[compayName] = resultMap.get(key)[compayName] + sumValueOfField(d, 'accidentEmployeeViews[].lostWorkDays');
  });
  return resultMap;
};
