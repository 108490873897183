import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetPpeTypeDefinitionsResult } from './get-ppe-type-definitions.result';

export async function getPpeTypeDefinitions(): Promise<GetPpeTypeDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetPpeTypeDefinitionsResult[]>(`firms/ppe/type-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
