import { Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { TrainingPlanningListContext } from '../training-planning-list.context';
import AddTrainingPlanning from './AddTrainingPlanning';
import PlanningListTable from './PlanningListTable';

const useStyles = makeStyles(theme => ({
  listItemStyle: {
    backgroundColor: 'whitesmoke',
    color: 'black',
    marginBottom: theme.spacing(1),
  },
  collapseStyle: {
    border: '2px solid whitesmoke ',
    padding: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(1),
    height: '100%',
    maxHeight: 475,
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section4: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
}));

export default function PlaningTrainingList() {
  const classes = useStyles();
  const [openPlaningTraining, setOpenPlaningTraining] = React.useState(true);
  const { loading, employeeTrainingListPlanning } = useContext(TrainingPlanningListContext);

  // const handleDelete = useCallback(
  //   // tslint:disable-next-line:no-shadowed-variable
  //   async (trainingId: string, employeeId: string) => {
  //     const submitted = await openWarnDialog({
  //       text: `Bu Eğitiminden çıkarmak istediğinize emin misiniz ?`,
  //       Icon: <DeleteDialogIcon />,
  //     });
  //     try {
  //       if (submitted) {
  //         await deleteEmployeeTrainingListPlanning(trainingId, employeeId);
  //         showMessage('Silme işlemi başarılı.', 'success', 'info');
  //         reload();
  //       }
  //     } catch (e) {
  //       showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
  //     }
  //   },
  //   [reload, openWarnDialog, showMessage]
  // );

  return (
    <div className="mt-1 mr-1">
      <List>
        <ListItem className={classes.listItemStyle} onClick={() => setOpenPlaningTraining(!openPlaningTraining)}>
          <ListItemIcon>
            <AccessAlarmsIcon />
          </ListItemIcon>
          <ListItemText primary="Planlanmış Eğitimler" />
          {openPlaningTraining ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.collapseStyle} in={openPlaningTraining} timeout="auto" unmountOnExit>
          <Grid container spacing={1}>
            <Grid className="text-right mb-1" item sm={12} md={12} lg={12}>
              <AddTrainingPlanning />
            </Grid>
          </Grid>
          <IndicatorWrapper
            loading={loading}
            data={employeeTrainingListPlanning?.filter(trainers => trainers?.isCompleted === false)}
            errorMessage="Henüz Bir Eğitim Planlaması Yapılmamıştır.">
            <PlanningListTable />
          </IndicatorWrapper>
        </Collapse>
      </List>
    </div>
  );
}
