import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { Vaccine } from './vaccine.model';
import { SaveVaccinePayload, UpdateVaccinePayload } from './vaccine.payload';
import { GetVaccinesResult, VaccineResultItem, VaccineResultItemChild } from './vaccine.result';

export async function getVaccines(employeeId: string, examinationId?: string): Promise<Vaccine[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<GetVaccinesResult>(`/vaccines?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<GetVaccinesResult>(`/vaccines?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return Vaccine.fromServerListData(dateSort(res.data));
}

export async function saveVaccine(body: SaveVaccinePayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/vaccines', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateVaccine(groupId: VaccineResultItem['id'], body: UpdateVaccinePayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.put(`/vaccines/${groupId}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteVaccine(id: VaccineResultItemChild['id']): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.delete(`/vaccines/detail/${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
