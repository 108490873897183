import { tryCatch } from '@thalesrc/js-utils';

import { PhpEnvironment } from './php-environment.result';

export async function getPhpEnvironment(): Promise<PhpEnvironment> {
  const [err, res] = await tryCatch<Response, Response>(fetch('/saglik-gozetimi/config/jssetting.php'));

  if (err) {
    throw await err.json();
  }

  return await res.json();
}

/**
 *
 *
 * <?php
 *
 *
 * class EnvConf implements JsonSerializable
 * {
 *     public static $ENV;
 *     public static $IBYS_URL;
 *     public static $SITE_URL;
 *     public static $ERECETE_SERVICE_URL;
 *     public static $DIGITAL_SIGNER_HOST;
 *     public static $INTERNAL_API_HOST;
 *     public static $INTERNAL_API_PATH;
 *
 *
 *     public static function init()
 *     {
 *         EnvConf::$ENV = $_ENV["ENVIRONMENT"];
 *         EnvConf::$SITE_URL = $_ENV["SITE_URL"];
 *         EnvConf::$INTERNAL_API_PATH = "/saglik-gozetimi/api/internal";
 *
 *
 *         switch (EnvConf::$ENV) {
 *             case "dev": {
 *                 EnvConf::$IBYS_URL = "https://testibys.artimetrik.com/artimetrik-api";
 *                 EnvConf::$INTERNAL_API_HOST = "http://localhost:8080";
 *                 EnvConf::$DIGITAL_SIGNER_HOST = "http://192.168.1.13:12200";
 *                 EnvConf::$ERECETE_SERVICE_URL = "https://sgkt.sgk.gov.tr/medula/eczane/saglikTesisiReceteIslemleriWS";
 *                 break;
 *             }
 *             case "test": {
 *
 *                 EnvConf::$IBYS_URL = "https://testibys.artimetrik.com/artimetrik-api";
 *                 EnvConf::$INTERNAL_API_HOST = "https://testisg.artimetrik.com";
 *                 EnvConf::$DIGITAL_SIGNER_HOST = "http://localhost:12200";
 *                 EnvConf::$ERECETE_SERVICE_URL = "https://sgkt.sgk.gov.tr/medula/eczane/saglikTesisiReceteIslemleriWS";
 *                 break;
 *             }
 *             case "prod": {
 *                 EnvConf::$IBYS_URL = "https://ibys.artimetrik.com/artimetrik-api";
 *                 EnvConf::$INTERNAL_API_HOST = "https://isg.artimetrik.com";
 *                 EnvConf::$DIGITAL_SIGNER_HOST = "http://localhost:12200";
 *                 EnvConf::$ERECETE_SERVICE_URL = "https://medeczane.sgk.gov.tr/medula/eczane/saglikTesisiReceteIslemleriWS";
 *                 break;
 *             }
 *             default:
 *                 break;
 *         }
 *     }
 *
 *     public function jsonSerialize()
 *     {
 *         return get_class_vars(get_class($this));
 *     }
 *
 * }
 *
 * EnvConf::init();
 *
 *
 */
