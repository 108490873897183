import React from 'react';

import PPEStocksList from './List';
export default function Index() {
  return (
    <>
      <PPEStocksList />
    </>
  );
}
