/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentTypeDefinitionDto } from '../models/AccidentTypeDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentTypeDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmAccidentTypeDefinition(
        id: string,
        requestBody: AccidentTypeDefinitionDto,
    ): CancelablePromise<AccidentTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/accident-type-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmAccidentTypeDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/accident-type-definitions/${id}`,
        });
    }

    /**
     * @param definitionId
     * @param requestBody
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateAccidentTypeDefinition(
        definitionId: string,
        requestBody: AccidentTypeDefinitionDto,
    ): CancelablePromise<AccidentTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/accident-type-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAccidentTypeDefinition(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accident-type-definitions/${definitionId}`,
        });
    }

    /**
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getFirmAccidentTypeDefinitions(): CancelablePromise<Array<AccidentTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/accident-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmAccidentTypeDefinition(
        requestBody: AccidentTypeDefinitionDto,
    ): CancelablePromise<AccidentTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/accident-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getAllAccidentTypeDefinitions(): CancelablePromise<Array<AccidentTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accident-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AccidentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveAccidentTypeDefinition(
        requestBody: AccidentTypeDefinitionDto,
    ): CancelablePromise<AccidentTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/accident-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}