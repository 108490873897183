/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PftCategoryEmployeeDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    pftCategory?: PftCategoryEmployeeDto.pftCategory;
    analysisId?: string;
}

export namespace PftCategoryEmployeeDto {

    export enum pftCategory {
        CATEGORY1 = 'CATEGORY1',
        CATEGORY2A = 'CATEGORY2A',
        CATEGORY2B = 'CATEGORY2B',
        CATEGORY3 = 'CATEGORY3',
        CATEGORY4 = 'CATEGORY4',
        CATEGORY5 = 'CATEGORY5',
        CATEGORY6 = 'CATEGORY6',
        CATEGORY7 = 'CATEGORY7',
        CATEGORY8 = 'CATEGORY8',
        CATEGORY9 = 'CATEGORY9',
        CATEGORY10 = 'CATEGORY10',
        CATEGORY11 = 'CATEGORY11',
        CATEGORY12 = 'CATEGORY12',
    }


}
