import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Routes from 'Routes';

import EmployeeSelectionCacheContextProvider from 'Common/Form/EmployeeSelection/EmployeeSelectionCacheContextProvider';
import Icd10CacheContextProvider from 'Common/Form/Icd10Selection/Icd10CacheContext';
import MedicineCacheContextProvider from 'Common/Form/MedicineSelection/MedicineCacheContextProvider';
import { AlertContextProvider } from 'context/alert.context';
import { DialogContextProvider } from 'context/dialog.context';
import { EnumContextProvider } from 'context/enum.context';
import { EnvironmentContextProvider } from 'context/environment.context';
import { ProtocolNumberProvider } from 'context/protocol-number.context';
import { ResourceContextProvider } from 'context/resource.context';
import { SelectOptionsContextProvider } from 'context/select-options.context';
import UserContextProvider from 'context/user/UserContextProvider';
import LicenceContextProvider from "context/licence/LicenceContextProvider";
import CssBaseline from 'style/CssBaseline';
import theme from 'style/mui-theme';
import { useI18n } from 'utils';

export default function App() {
  const { locale } = useI18n();

  return (
    <EnvironmentContextProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <LicenceContextProvider>
            <UserContextProvider>
              <EnumContextProvider>
                <ResourceContextProvider>
                  <SelectOptionsContextProvider>
                    <AlertContextProvider>
                      <DialogContextProvider>
                        <EmployeeSelectionCacheContextProvider>
                          <ProtocolNumberProvider>
                            <Routes />
                          </ProtocolNumberProvider>
                        </EmployeeSelectionCacheContextProvider>
                      </DialogContextProvider>
                    </AlertContextProvider>
                  </SelectOptionsContextProvider>
                </ResourceContextProvider>
              </EnumContextProvider>
            </UserContextProvider>
          </LicenceContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </EnvironmentContextProvider>
  );
}
