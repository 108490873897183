import React, { useContext } from 'react';

import TableWithPagination from 'Common/Table/WithPagination/TableWithPagination';

import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { HSTableContext } from '../../Common/Table/HSTable/hs-table.context';

export default function ListTable() {
  const { loading, data } = useContext(HSTableContext);

  return (
    <div className="m-1">
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Aşı Kayıtı Bulunamadı">
        <TableWithPagination />
      </IndicatorWrapper>
    </div>
  );
}
