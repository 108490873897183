import React from 'react';

import ListPhysicalCheck from './List';
import DialogFormPhysicalCheck from './List/DialogFormPhysicalCheck';

export default function SystemCheck() {
  return (
    <>
      <ListPhysicalCheck />
      <DialogFormPhysicalCheck />
    </>
  );
}
