import {
  FormControl,
  FormHelperText,
  IconButton,
  Input as MUIInput,
  InputAdornment,
  InputLabel,
  makeStyles,
  Paper,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { RefObject, useCallback, useContext, useState } from 'react';
import { toState } from 'react-color/lib/helpers/color';
import { Controller, useFormContext } from 'react-hook-form';
import tinyColor from 'tinycolor2';

import { ColorPickerContext } from './color-picker.context';

interface Props {
  ref?: RefObject<any>;
  defaultValue?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  helper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  box: {
    width: 32,
    height: 24,
    marginRight: 4,
    backgroundSize: '100% 100%, 8px 8px',
    cursor: 'pointer',
  },
}));

const PATTERN = 'repeating-conic-gradient(#eee 0% 25%, #fff 0% 50%)';
const CROSS = 'linear-gradient(to bottom right, transparent 48%, red 49%, red 51%, transparent 52%)';
const COLOR = (color: string) => `linear-gradient(to right, ${color}, ${color})`;

export default function Input({ ref, defaultValue }: Props) {
  const classes = useStyles();
  const { control } = useFormContext();
  const {
    name,
    label,
    setColor,
    colorText,
    picker: { open },
    inputRef,
  } = useContext(ColorPickerContext);

  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState(defaultValue ?? '');

  const handleFocus = useCallback(() => {
    setIsEditing(true);
    setInputText(colorText ?? '');
  }, [colorText]);

  const handleBlur = useCallback(() => setIsEditing(false), []);
  const handleInputChange = useCallback(
    ({ target: { value: val } }) => {
      setInputText(val);

      const isValid = tinyColor(val).isValid();

      if (isValid) {
        setColor(toState(val).hsv);
      } else {
        setColor(null);
      }
    },
    [setColor]
  );

  const clearColor = useCallback(() => setColor(null), [setColor]);

  const render = useCallback(
    ({ onChange, value }) => (
      <>
        <InputLabel>{label}</InputLabel>
        <MUIInput
          fullWidth
          defaultValue={defaultValue}
          value={isEditing ? inputText : colorText ?? (defaultValue || '')}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          endAdornment={
            <InputAdornment position="end">
              {colorText ? (
                <IconButton className="mr-1" size="small" onClick={clearColor}>
                  <ClearIcon />
                </IconButton>
              ) : null}
              <Paper
                onClick={open}
                elevation={2}
                className={classes.box}
                style={{ backgroundImage: `${colorText ? COLOR(colorText) : CROSS}, ${PATTERN}` }}
              />
            </InputAdornment>
          }
        />
      </>
    ),
    [label, classes, open, colorText, inputText, isEditing, handleFocus, handleBlur, handleInputChange, clearColor, defaultValue]
  );

  return (
    <FormControl fullWidth className={classes.root} innerRef={inputRef}>
      <Controller name={name} control={control} render={render} />
      <FormHelperText className={classes.helper} />
    </FormControl>
  );
}
