import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort, MakeRequired } from '../../../../utils';
import { FamilyHistoryModel } from './family-history.model';
import { FamilyHistoryPayload } from './family-history.payload';
import { FamilyHistoryResult } from './family-history.result';

export async function getFamilyHistory(employeeId: string, examinationId?: string): Promise<FamilyHistoryModel[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<FamilyHistoryResult[]>(`/family-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<FamilyHistoryResult[]>(`/family-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data ? FamilyHistoryModel.fromServerListData(dateSort(res.data)) : [];
}

export async function saveFamilyHistory(payload: FamilyHistoryPayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.post(`/family-histories`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error;
  }

  return res.data;
}

export async function updateFamilyHistory({ id, ...body }: MakeRequired<Partial<FamilyHistoryResult>, 'id'>): Promise<FamilyHistoryResult> {
  const [error, res] = await tryCatch(HSApi.put<FamilyHistoryResult>(`/family-histories/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteFamilyHistory(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/family-histories/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
