import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { FineKinneyPossibilityDefinitionControllerService } from 'api/client';
import { FineKinneyPossibilityDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import FineKinneyPossibilityDefinitionForm from './Forms/FineKinneyPossibilityDefinitionForm';

export default function FineKinneyPossibilityDefinition() {
  return (
    <BaseDefinitionPage<FineKinneyPossibilityDefinitionDto>
      listLabelPropName="label"
      fetchData={FineKinneyPossibilityDefinitionControllerService.getAllFirmFineKinneyPossibilityDefinitions}
      FormDialog={FineKinneyPossibilityDefinitionForm}
      Icon={<FlashOnIcon />}
      searchName="label"
      noDelete={true}
      noSave={true}
    />
  );
}
