import { modelDataMatcher } from 'utils';

export class TrainingDefinitionsModel {
  public id: string = null;
  public firmId: string = null;
  public name: string = null;
  public code: string = null;
  public groupId: string = null;
  public description: string = null;
  public isLegalObligation: boolean = null;
  public durationInMinute: number = null;
  public isPeriodic: boolean = null;
  public periodDurationInMonth: number = null;
  public aim: string = null;
  public objective: string = null;
  public trainingReference: string = null;
  public assignableType: string = null;
  public isIbysTraining: boolean = null;

  constructor(data: Partial<TrainingDefinitionsModel> = {}) {
    modelDataMatcher(data, this);
  }
}
