/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PolyclinicExamOpinionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    examinationId: string;
    employeeId: string;
    polyclinicExamOpinionDefinition: PolyclinicExamOpinionDto.polyclinicExamOpinionDefinition;
}

export namespace PolyclinicExamOpinionDto {

    export enum polyclinicExamOpinionDefinition {
        REFERRED = 'REFERRED',
        WORKING = 'WORKING',
        REST_REPORTED = 'REST_REPORTED',
    }


}
