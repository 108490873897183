import { useCallback, useMemo, useState } from 'react';

import { minusDays, plusDays } from './date-to-input-format';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface UseDateRangeState {
  dateRange: DateRange;
  days: number;
  setRange(_start: Date, _end: Date): void;
  setRangeByDays(_days: number, before?: boolean): void;
}

export const dateRangeOptions = [7, 15, 30];
export const defaultDays = 7;

export function useDateRangeState(): UseDateRangeState {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [days, setDays] = useState<number>(defaultDays);

  const setRange = useCallback((start: Date, end: Date) => {
    setDateRange({ start, end });
    setDays(undefined);
  }, []);

  const setRangeByDays = useCallback((_days: number = defaultDays, before = true) => {
    const now = new Date();
    setDays(_days);

    if (before) {
      setDateRange({ start: minusDays(now, _days), end: now });
    } else {
      setDateRange({ start: now, end: plusDays(now, _days) });
    }
  }, []);

  return useMemo<UseDateRangeState>(
    () => ({
      dateRange,
      days,
      setRange,
      setRangeByDays,
    }),
    [dateRange, days, setRange, setRangeByDays]
  );
}
