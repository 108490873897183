import { Button, Divider, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState } from 'utils';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../../api/hs/employee';
import { SelectOption } from '../../../../Common/Form/Select';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function Filters() {
  const classes = useStyles();
  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const { companies, reasons, positions, ppeClassificationDefinitions, ppeDefinitions, ppeTypeDefinitions } = useContext(
    SelectOptionsContext
  );
  const formRef = useRef<FormRef<Record<string, any>>>();

  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);

  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  return (
    <>
      <Fab
        onClick={open}
        size="small"
        className={classes.fab}
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">KKD Çıktı Filtreleme</Typography>
        </Toolbar>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid item xs={12}>
              <AutoComplete name="companyIds" options={companies} label="Şirketler" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="positionDefinitionIds" options={positions} label="Görevler" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                name="employeeIds"
                label="Personel Seç"
                optionFetcher={employeeOptionFetcher}
                labelFetcher={employeeLabelFetcher}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="classificationDefinitionIds" options={ppeClassificationDefinitions} label="KKD Ana Grup" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="typeDefinitionIds" options={ppeTypeDefinitions} label="KKD Alt Grup" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="ppeDefinitionIds" options={ppeDefinitions} label="KKD Tipi" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="reasons" options={reasons} label="Durumu" />
            </Grid>
            <Grid spacing={1} item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Zimmet Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="debitDateMax" label="Başlangıç" />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="debitDateMin" label="Bitiş" />
              </Grid>
            </Grid>
            <Grid className="mt-1" spacing={1} item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">İade Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid className="mt-1" item xs={6}>
                <DateInput type="date" name="refundDateMin" label="Başlangıç" />
              </Grid>
              <Grid className="mt-1" item xs={6}>
                <DateInput type="date" name="refundDateMax" label="Bitiş" />
              </Grid>
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
