import React, { useCallback } from 'react';

import { getTrainingList } from 'api/hs/trainings/training-view';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { CELL } from './columns';
import ListTable from './ListTable';
import ShowDialog from './ShowDialog';
import Toolbar from './Toolbar';
import TrainerDialogForm from './TrainerDialogForm';

export default function List() {
  const fetchTrainingList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getTrainingList(
      page,
      size,
      sorting === false ? 'createdDate-DESC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider fetcher={fetchTrainingList} columns={CELL}>
      <Toolbar />
      <ListTable />
      <ShowDialog />
      <TrainerDialogForm />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
