/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExposureRequest } from '../models/ExposureRequest';
import type { PageExposureView } from '../models/PageExposureView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ExposureViewControllerService {

    /**
     * @param requestBody
     * @returns PageExposureView OK
     * @throws ApiError
     */
    public static getEmployeeExposuresFiltered(
        requestBody: ExposureRequest,
    ): CancelablePromise<PageExposureView> {
        return __request({
            method: 'POST',
            path: `/exposures/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}