import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useFetch } from 'utils';

import { getAnalysisParameters } from '../../../../api/hs/definitions/health-definitions/analysis';
import { AnalysisParametersResult } from '../../../../api/hs/definitions/health-definitions/analysis/AnalysisParametersResult';
import AnalysisParameterDetail from '../../../../Common/Forms/Definitions/HealthDefinition/Analysis/AnalysisParameterDetail';
import { AnalysisParameterContext } from './AnalysisParametersContext';
import Analysis from './Parameters';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<AnalysisParametersResult>(null);

  const { categoryDefinitionId } = useParams<{ categoryDefinitionId: string }>();

  const { data, fetch } = useFetch(() => getAnalysisParameters(categoryDefinitionId), [], { setLoading });

  return (
    <AnalysisParameterContext.Provider
      value={{
        analysisParameters: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <AnalysisParameterDetail />
      <Analysis />
    </AnalysisParameterContext.Provider>
  );
}
