import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { getAccidentIdActions } from '../../../../../api/hs/accident/accident';
import Button from '../../../../../Common/Button';
import Dialog from '../../../../../Common/Dialog';
import { HSTableProps } from '../../../../../Common/Table/HSTable/hs-table.props';
import { ResourceContext } from '../../../../../context/resource.context';
import { SelectOptionsContext } from '../../../../../context/select-options.context';
import CustomAction from '../../../../../CustomAction';
import BaseSection from '../../../../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import { useDateFormat } from '../../../../../utils';
import NearMissEditForm from '../../../NearMiss/ListAdd/Form';
import { NearMissDetailContext } from '../../near-miss-detail.context';
import NearMissFiles from '../NearMissFiles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export default function Index() {
  const { nearMissDetail, reload } = useContext(NearMissDetailContext);

  const { companies, workStations, departments } = useContext(ResourceContext);
  const { nearMissTypes, shiftDefinitions } = useContext(SelectOptionsContext);

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { nearMissId } = useParams<{ nearMissId: string }>();
  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      if (!nearMissId) return;
      return getAccidentIdActions(
        nearMissId,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [nearMissId]
  );
  const onCloseDialog = useCallback(
    async (success: boolean, updateSelectedData?: any) => {
      if (success) {
        await reload();
        setOpen(false);
      }
      setOpen(false);
    },
    [reload]
  );

  const showAccidentRecordForm = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Paper className="m-1" elevation={2}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell width="50%" className={classes.textColor} align="left">
                    Şirket
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {findByKey(companies, 'id', nearMissDetail?.companyId)?.companyName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    İstasyon
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {nearMissDetail?.nearMissStations
                      ?.map(station => findByKey(workStations, 'id', station?.stationId)?.stationName)
                      .join(' , ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Vardiya
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {findByKey(shiftDefinitions, 'value', nearMissDetail?.shiftId)?.text}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Açıklama
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {nearMissDetail?.description}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Departman{' '}
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {/*{nearMissDetail?.nearMissDepartments?.map(department => department.departmentId)}*/}
                    {nearMissDetail?.nearMissDepartments
                      ?.map(department => findByKey(departments, 'id', department?.departmentId)?.departmentName)
                      .join(' , ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Ramak Kala Türü
                  </TableCell>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor2}>
                    {nearMissDetail?.nearMissTypes
                      ?.map(type => findByKey(nearMissTypes, 'value', type?.nearMissTypeDefinitionId)?.text)
                      .join(' , ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" className={classes.textColor} component="th" scope="row">
                    Tarih
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} component="th" scope="row">
                    {nearMissDetail?.date ? <DateField date={new Date(nearMissDetail?.date)} /> : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <div className="text-right mt-1 mr-2 mb-1">
        <Button onClick={showAccidentRecordForm} variant="contained" startIcon={<EditIcon />} color="primary" className="ml-1">
          Ramak Kala Bilgilerini Düzenle
        </Button>
      </div>
      <Dialog width="md" color="secondary" title={'Ramak Kala Düzenle'} opened={open} onClose={onCloseDialog}>
        <NearMissEditForm data={nearMissDetail} onClose={onCloseDialog} />
      </Dialog>
      <BaseSection icon="icon-flash" title="Ramak Kala Aksiyonları">
        <CustomAction fetcher={fetchActionList} referenceId={nearMissId} actionReferenceValue={'NEAR_MISS'} />
      </BaseSection>
      <BaseSection icon="icon-attachment" title={`Ramak Kala Dosyaları`}>
        <NearMissFiles />
      </BaseSection>
    </Paper>
  );
}
