// prettier-ignore
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { logout } from 'api/hs/authentication/logout';
import { AlertContext } from 'context/alert.context';
import { UserContext } from 'context/user';

import SupportDrawer from './SupportDrawer';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    minWidth: 'max-content',
  },
}));

export default function Header() {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);

  const handleClose = () => {
    setMenuAnchor(null);
  };

  async function handleLogout() {
    try {
      await logout();
      setUser(null);
      history.push('/');
    } catch (error) {
      showMessage('Bir hata oluştu', 'error', 'info');
    }
  }

  async function handleProfilePage() {
    setMenuAnchor(null);
    history.push('/profile');
  }

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button className="text-white" onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
          <Avatar src="/images/profile.jpg" className="mr-sm-2" />
          <Hidden xsDown>
            <span className="mr-1">{user.name}</span>
            <ArrowDropDownIcon />
          </Hidden>
        </Button>
        <Divider orientation="vertical" flexItem />
        <IconButton color="inherit" className="px-2" onClick={() => setDrawerOpened(true)}>
          <HelpIcon />
        </IconButton>
      </Grid>
      <SupportDrawer opened={drawerOpened} onClose={() => setDrawerOpened(false)} />
      <Popover
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.menuPaper,
          style: { width: menuAnchor ? getComputedStyle(menuAnchor).width : 'auto' },
        }}
        onClose={handleClose}>
        <List>
          <Hidden smUp>
            <ListSubheader>
              <ListItemText className="text-center mb-1">{user.name}</ListItemText>
            </ListSubheader>
            <Divider />
          </Hidden>
          <ListItem button onClick={handleProfilePage}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profil Ayarları" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Çıkış Yap" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
