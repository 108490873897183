import React from 'react';

import { TableColumn } from 'Common/Table/Raw/table-column.model';
import { dateToInputFormat } from 'utils';

import { NurseProtocolRegistryView } from '../../api/client';
import Avatar from '../EmployeeList/Avatar';
export const CELL: TableColumn<NurseProtocolRegistryView>[] = [
  {
    id: 'avatarPath',
    render(row: NurseProtocolRegistryView): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: false,
    width: '5%',
  },
  {
    id: 'protocolNo',
    label: 'Protokol Num',
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Adı Soyadı',
    sortable: true,
    width: '25%',
  },
  {
    id: 'createdDate',
    render(row: NurseProtocolRegistryView): JSX.Element {
      return <span>{dateToInputFormat(new Date(row.createdDate)).split('-')?.reverse()?.join('-')}</span>;
    },
    label: 'Tarih',
    sortable: true,
    width: '10%',
  },
  {
    id: 'birthDate',
    defaultVisibility: true,
    label: 'Doğum Tarihi',
    sortable: true,
    width: '10%',
    render(row: NurseProtocolRegistryView): JSX.Element | string {
      return <span>{row?.birthDate?.split('-')?.reverse()?.join('-')}</span>;
    },
  },
  {
    id: 'genderName',
    defaultVisibility: true,
    label: 'Cinsiyet',
    sortable: true,
    width: '10%',
  },
  {
    id: 'nurseOperationDefinitionLabel',
    defaultVisibility: true,
    label: 'Yapılan İşlem',
    sortable: true,
    width: '10%',
  },
  {
    id: 'description',
    defaultVisibility: true,
    label: 'Açıklama',
    sortable: true,
    width: '25%',
  },
];
