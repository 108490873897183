import { asCleanDays } from '@fullcalendar/react';
import {
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupIcon from '@material-ui/icons/Group';
import React, { Fragment, useCallback, useContext, useState } from 'react';

import { EmergencyCaseDefinitionControllerService, EmergencyCaseDefinitionDto } from '../../api/client';
import DeleteDialogIcon from '../../Common/DeleteDialogIcon';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { DialogContext } from '../../context/dialog.context';
import { downlaodFile, openFile, useFetch } from '../../utils';
import EmergencyCaseDefinitionForm from './EmergencyCaseDefinitionForm';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: '40px',
    height: '40px',
    color: 'white',
    float: 'right',
    marginRight: '5px',
    marginTop: '-45px',
    backgroundColor: '#eb4355',
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));

export default function EmergencyCaseDefinitions() {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>();
  const [selectedData, setSelectedData] = useState<EmergencyCaseDefinitionDto>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { data, fetch } = useFetch(() => EmergencyCaseDefinitionControllerService.getAllFirmEmergencyCaseDefinitions(), [], { setLoading });
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const onShowDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        setOpen(false);
        await fetch();
      }
      setOpen(false);
      setSelectedData(null);
    },
    [fetch, setOpen, setSelectedData]
  );

  const onEdit = useCallback(
    (item: EmergencyCaseDefinitionDto) => {
      setSelectedData(item);
      setOpen(true);
    },
    [setSelectedData, setOpen]
  );

  const onDelete = useCallback(
    async (emergencyManagementCaseDefinition: EmergencyCaseDefinitionDto) => {
      const submitted = await openWarnDialog({
        text: `${emergencyManagementCaseDefinition.label} - isimli kayıtı silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await EmergencyCaseDefinitionControllerService.deleteFirmEmergencyCaseDefinitionsDefinition(emergencyManagementCaseDefinition.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [fetch, openWarnDialog, showMessage]
  );

  return (
    <>
      <Toolbar color="secondary">
        {' '}
        <Typography variant="h6">Acil Durum Ekipleri</Typography>
      </Toolbar>
      <Fab onClick={onShowDialog} className={classes.fabButton}>
        <AddIcon className={classes.addButton} />
      </Fab>

      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek  Dosya Bulunamadı">
        <List style={{ width: '100%' }} key="emergency-case-definitions-list">
          <Grid container spacing={1}>
            {data?.map((item, index) => (
              <Grid item sm={6} key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <GroupIcon style={{ color: 'gray' }} />
                  </ListItemAvatar>
                  <ListItemText primary={item.label} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onEdit(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </List>
      </IndicatorWrapper>
      <EmergencyCaseDefinitionForm open={open} data={selectedData} onClose={onCloseDialog} />
    </>
  );
}
