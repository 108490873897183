/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChronicDiseaseRequest } from '../models/ChronicDiseaseRequest';
import type { PageChronicDiseaseView } from '../models/PageChronicDiseaseView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ChronicDiseaseViewControllerService {

    /**
     * @param requestBody
     * @returns PageChronicDiseaseView OK
     * @throws ApiError
     */
    public static getChronicDiseaseViews(
        requestBody: ChronicDiseaseRequest,
    ): CancelablePromise<PageChronicDiseaseView> {
        return __request({
            method: 'POST',
            path: `/chronic-diseases/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}