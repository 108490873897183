import { Grid } from '@material-ui/core';
import React from 'react';

import { MatrixHazardScoreDefinitionControllerService } from 'api/client';
import { MatrixHazardScoreDefinitionDto } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateRiskAssessmentsMatrixScoreDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: MatrixHazardScoreDefinitionDto;

  onClose(success: boolean): void;
}

export default function MatrixHazardScoreDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<MatrixHazardScoreDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={MatrixHazardScoreDefinitionControllerService.saveMatrixScoreDefinitions}
      onUpdate={updateRiskAssessmentsMatrixScoreDefinitionUpdate}
      data={data}
      createTitle="Matris Skor Ekleme"
      updateTitle="Matris Skor Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="İsmi" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="score" label="Skor" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="description" label="Açıklama" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="deadline" label="Zaman Sınırı" />
      </Grid>
    </BaseDefinitionForm>
  );
}
