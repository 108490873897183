import {Grid, IconButton, Typography} from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';
import {pink} from '@material-ui/core/colors';
import {findByKey} from '@thalesrc/js-utils';

import React, {useContext, useState} from 'react';
import Dialog from "../../../Common/Dialog";
import {IbysTrainingDefinitionEmployeeDto, IbysTrainingDto} from "../../../api/client";
import {ResourceContext} from "../../../context/resource.context";
import {EmployeeSelectionCacheContext} from "../../../Common/Form/EmployeeSelection/employee-selection.context";
import {IbysTrainingDetailResult} from "../../../api/hs/trainings/ibys-trainings";

interface Props {
  unsuccessfulTrainingCount:number
  data: IbysTrainingDto | IbysTrainingDetailResult;
}

const UnsuccessfulTrainingInfo = ({data, unsuccessfulTrainingCount}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const {trainingDefinitions} = useContext(ResourceContext);
  const {employees} = useContext(EmployeeSelectionCacheContext);
  const handleClose = () => setShowModal(false);


  const handleOpen = () => setShowModal(true);
  function trainingErrorDetails(trainings: IbysTrainingDefinitionEmployeeDto []) {
    let result: JSX.Element[] = [];

    if(trainings) {
      for (let training of trainings) {
        if (training.resultCode == 0) {
          result.push(
            <>
              <Grid item xs={1}>
                <Typography variant="subtitle1">
                  {findByKey(trainingDefinitions, 'id', training.trainingDefinitionId)?.code}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {findByKey(employees, 'id', training.employeeId)?.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {training.resultCode}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  {training.resultMessage}
                </Typography>
              </Grid>
            </>
          );
        }
      }
    }
    return result;
  }

  return (
    <>
      {
        unsuccessfulTrainingCount != 0 && (
          <>
            <IconButton style={{color: pink[500], padding: '6px', marginTop: '-2px'}} onClick={handleOpen}>
              <ReportIcon/>
            </IconButton>

            <Dialog width="md" title="Bakanlık Servisinden Dönen Hatalar" color="secondary" opened={showModal}
                    onClose={handleClose}>

              <Grid container spacing={1}>
                {trainingErrorDetails(data?.ibysTrainingDefinitionEmployees)}
              </Grid>
            </Dialog>
          </>
        )
      }
    </>

  );
};
export default UnsuccessfulTrainingInfo;
