import { Box, makeStyles, Paper } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Alpha from 'react-color/lib/components/common/Alpha';
import Hue from 'react-color/lib/components/common/Hue';
import Saturation from 'react-color/lib/components/common/Saturation';
import { toState } from 'react-color/lib/helpers/color';

import { ColorPickerContext } from './color-picker.context';

const useStyles = makeStyles(theme => ({
  saturationBox: {
    position: 'relative',
    width: 200,
    height: 200,
  },
  hueBox: {
    position: 'relative',
    width: '100%',
    height: 12,
  },
  alphaBox: {
    position: 'relative',
    width: '100%',
    height: 12,
  },
}));

export default function Picker() {
  const classes = useStyles();
  const [alpha, setAlpha] = useState(1);
  const [hsv, setHsv] = useState(toState('').hsv);
  const {
    color,
    setColor,
    picker: { opened },
  } = useContext(ColorPickerContext);

  const hsl = useMemo(() => ({ h: hsv.h, s: 0, l: 0, a: 1, source: 'hsl' }), [hsv]);
  const rgb = useMemo(() => ({ ...toState(hsv).rgb, a: alpha }), [hsv, alpha]);

  useEffect(() => {
    if (!opened) {
      return;
    }

    const state = toState(color ?? '');

    setHsv(state.hsv);
    setAlpha(state.hsv.a);
  }, [opened]); // eslint-disable-line

  useEffect(() => {
    setColor({ ...hsv, a: alpha });
  }, [hsv, alpha, setColor]);

  return (
    <Paper className={`p-1`}>
      <Box className={classes.saturationBox}>
        <Saturation onChange={setHsv} hsl={hsl} hsv={hsv} />
      </Box>
      <Box className={`${classes.hueBox} mt-1`}>
        <Hue onChange={({ h }) => setHsv({ ...hsv, h })} hsl={hsl} hsv={hsv} />
      </Box>
      <Box className={`${classes.alphaBox} mt-1`}>
        <Alpha onChange={({ a }) => setAlpha(a)} hsl={hsl} hsv={hsv} rgb={rgb} />
      </Box>
    </Paper>
  );
}
