import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { useCallback, useContext, useState } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';

import { WorkEquipmentDefinitionContext } from '../../../work-equipment-definition.context';
import WorkEquipmentDefinitionForm from '../../Form';
import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarWorkEquipmentDefinition() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const { setNewDialogOpened, newDialogOpened, setSelectedData } = useContext(WorkEquipmentDefinitionContext);
  const { data } = useContext(HSTableContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setNewDialogOpened(false);
      setSelectedData(null);
    },
    [setNewDialogOpened, setSelectedData, refresh]
  );

  const newPpeDefinition = useCallback(() => {
    closePopover();
    setNewDialogOpened(true);
  }, [setNewDialogOpened, closePopover]);
  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        İş Ekipman Tanımları ({data?.length})
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={openPopover}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -175 }} keepMounted open={!!anchorEl} onClose={closePopover}>
          <List>
            <ListItem onClick={newPpeDefinition} className="cursor-pointer">
              <ListItemIcon>
                <PostAddIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Yeni İş Ekipman Tanımı</Typography>
            </ListItem>
          </List>
        </Popover>
        <VisibleColumnMenu />
        <Filters />
      </div>
      <WorkEquipmentDefinitionForm open={newDialogOpened} onClose={closeDialog} data={null} />
    </Toolbar>
  );
}
