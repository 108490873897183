import React from 'react';

import FirmUsersAdd from './add';
import FirmUsersList from './list';
export default function FirmUsers() {
  return (
    <>
      <FirmUsersAdd />
      <FirmUsersList />
    </>
  );
}
