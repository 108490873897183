import { modelDataMatcher } from 'utils';

export class IbysPlanningTrainingDefinitionModel {
  public id: string = null;
  public durationInMinute: number = null;
  public trainingDefinitionId: string = null;
  public ibysTrainingId: string = null;

  constructor(data: Partial<IbysPlanningTrainingDefinitionModel> = {}) {
    modelDataMatcher(data, this);
  }
}
