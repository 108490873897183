/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeDto } from './EmployeeDto';
import type { PositionDefinitionDto } from './PositionDefinitionDto';

export type PpeAssignmentDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    assignmentType?: PpeAssignmentDto.assignmentType;
    ppeDefinitionId?: string;
    employeeId?: string;
    positionId?: string;
    position?: PositionDefinitionDto;
    employee?: EmployeeDto;
}

export namespace PpeAssignmentDto {

    export enum assignmentType {
        POSITION = 'POSITION',
        EMPLOYEE = 'EMPLOYEE',
    }


}
