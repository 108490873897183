import AddIcon from '@material-ui/icons/Add';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import { EnumContext } from 'context/enum.context';

import { DefaultNurseOperationDetailContext } from '../../default-nurse-operation-detail.context';
import DefaultNurseOperationDetailForm from '../Form';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload, nurseOperation } = useContext(DefaultNurseOperationDetailContext);
  const { nurseOperationDefinitions } = useContext(EnumContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <header className="text-right mr-1">
        <Button startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          <b>{findByKey(nurseOperationDefinitions, 'id', nurseOperation?.nurseOperationDefinitionId)?.label + ' Ekle'}</b>
        </Button>
      </header>
      <DefaultNurseOperationDetailForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
