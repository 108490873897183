import { debounceWithKey, difference, uniquifyByKey } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { getEmployeesByIds, getSearchEmployeesByIds, searchEmployee } from 'api/hs/employee';
import { BaseEmployee } from 'model';
import { useDebouncedCallback } from 'utils';

import { EmployeeSelectionCacheContext, EmployeeSelectionCacheContextType } from './employee-selection.context';

const DEBOUNCE_KEY = Symbol();

export default function EmployeeSelectionCacheContextProvider({ children }: PropsWithChildren<{}>) {
  const [employees, setEmployees] = useState<BaseEmployee[]>([]);
  const [fetchedKeywords, setFetchedKeywords] = useState<string[]>([]);

  const options = useMemo(() => employees.map(({ id }) => id), [employees]);
  const names = useMemo(() => employees.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {} as Record<BaseEmployee['id'], string>), [
    employees,
  ]);
  const avatars = useMemo(
    () => employees.reduce((acc, { id, avatarPath }) => ({ ...acc, [id]: avatarPath }), {} as Record<BaseEmployee['id'], string>),
    [employees]
  );
  const contentStrings = useMemo(
    () => employees.reduce((acc, { id, name, nid }) => ({ ...acc, [id]: `${name} ${nid}` }), {} as Record<BaseEmployee['id'], string>),
    [employees]
  );

  const loadEmployeesReq = useDebouncedCallback(getSearchEmployeesByIds, 300);

  const getEmployeesReq = useCallback(
    async (keyword: string) => {
      setFetchedKeywords([...fetchedKeywords, keyword]);

      const res = await searchEmployee(keyword);

      setEmployees(uniquifyByKey([...employees, ...res], 'id'));
    },
    [employees, fetchedKeywords]
  );

  const getEmployees = useCallback(
    (keyword: string) => {
      if (fetchedKeywords.includes(keyword)) {
        return;
      }

      debounceWithKey(DEBOUNCE_KEY, getEmployeesReq, 300, null, keyword);
    },
    [fetchedKeywords, getEmployeesReq]
  );

  const loadEmployees = useCallback(
    async (...ids: BaseEmployee['id'][]) => {
      const emps = await loadEmployeesReq(
        difference(
          ids,
          employees.map(({ id }) => id)
        )
      );

      setEmployees(uniquifyByKey([...employees, ...emps], 'id'));
    },
    [loadEmployeesReq, employees]
  );

  const context = useMemo<EmployeeSelectionCacheContextType>(
    () => ({ employees, options, names, avatars, contentStrings, getEmployees, loadEmployees }),
    [employees, options, names, avatars, contentStrings, getEmployees, loadEmployees]
  );

  return <EmployeeSelectionCacheContext.Provider value={context}>{children}</EmployeeSelectionCacheContext.Provider>;
}
