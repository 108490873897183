import React from 'react';

import { UserProfile } from './user-profile.model';
import { User } from './user.model';

export interface UserContextType {
  user: User;
  setUser(v: User): void;
  refreshUser(): Promise<void>;
  profile: UserProfile;
  updateProfile(payload: Partial<Omit<UserProfile, 'id' | 'userId'>>): Promise<void>;
}

export const UserContext = React.createContext<UserContextType>(null);
