import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { getTrainers, TrainersResult } from 'api/hs/definitions/trainings/trainers';
import { getTrainingDefinitions, TrainingDefinitionsModel } from 'api/hs/definitions/trainings/training-definitions';
import {
  getAllergies,
  GetAllergiesResult,
  GetBloodRelationResult,
  getBranchCodes,
  GetBranchCodesResult,
  getBuildings,
  GetBuildingsResult,
  getCompanies,
  GetCompaniesResult,
  getDepartments,
  GetDepartmentsResult,
  getDisabilities,
  GetDisabilitiesResult,
  GetExaminationDefinitionResult,
  getExaminationDefinitions,
  getExposures,
  GetExposuresResult,
  getFirms,
  GetFirmsResult,
  getFirmUser,
  GetFirmUserResult,
  getGetBloodRelation,
  getGetHazardCategories,
  GetHazardCategoriesResult,
  getHsExamOpinionDefinitions,
  getLocations,
  GetLocationsResult,
  getPhysicalCheckDefinition,
  GetPhysicalCheckDefinitionsResult,
  getPositions,
  GetPositionsResult,
  getPpeClassificationDefinitions,
  GetPpeClassificationDefinitionsResult,
  GetPpeDefinition,
  GetPpeDefinitionResult,
  GetPpeStoreHouseResult,
  getPpeStoreHouseSection,
  getPpeTypeDefinitions,
  GetPpeTypeDefinitionsResult,
  getReferralSection,
  GetReferralSectionResult,
  getRestReportDefinitions,
  GetRestReportDefinitionsResult,
  getSystemCheck,
  GetSystemCheckResult,
  getTrainings,
  getTrainingsGroupDefinitions,
  GetTrainingsGroupDefinitionsResult,
  getTrainingsMainGroupDefinitions,
  GetTrainingsMainGroupDefinitionsResult,
  GetTrainingsResult,
  getVaccines,
  GetVaccinesResult,
  getWorkEquipmentDefinition,
  GetWorkEquipmentDefinitionResult,
  GetWorkEquipmentTypeDefinitionResult,
  getWorkEquipmentTypeDefinitions,
  getWorkingRestrictionDefinitions,
  GetWorkingRestrictionDefinitionsResult,
  getWorkStations,
  GetWorkStationsResult,
  HsExamOpinionResult,
} from 'api/hs/resource';

import {
  AccidentEmployeeInjuryTypeDto,
  AccidentReviewCsiFileTypeDefinitionDto,
  AccidentReviewDefinitionControllerService,
  AccidentReviewDocumentDocumentTypeDefinitionDto,
  AccidentSeverityDefinitionDto,
  AccidentSeverityTypeDefinitionControllerService,
  AccidentTypeDefinitionControllerService,
  AccidentTypeDefinitionDto,
  AnalysisControllerService,
  AnalysisParameterUnitDefinitionDto,
  BaseAccidentReviewAnalysisDefinitionDto,
  BloodRelationDefinitionControllerService,
  BloodRelationDefinitionDto,
  BodyRegionDefinitionControllerService,
  BodyRegionDefinitionDto,
  EmergencyCaseDefinitionControllerService,
  EmergencyCaseDefinitionDto,
  FineKinneyHazardDamageDefinitionControllerService,
  FineKinneyHazardDamageDefinitionDto,
  FineKinneyHazardFrequencyDefinitionControllerService,
  FineKinneyHazardFrequencyDefinitionDto,
  FineKinneyHazardScoreDefinitionControllerService,
  FineKinneyHazardScoreDefinitionDto,
  FineKinneyPossibilityDefinitionControllerService,
  FineKinneyPossibilityDefinitionDto,
  HazardCategoryDefinitionControllerService,
  HazardCategoryDefinitionDto,
  HazardCurrentPrecautionDefinitionControllerService,
  HazardCurrentPrecautionDefinitionDto,
  InjuryTypeDefinitionControllerService,
  InjuryTypeDefinitionDto,
  MatrixHazardDamageDefinitionControllerService,
  MatrixHazardDamageDefinitionDto,
  MatrixHazardScoreDefinitionControllerService,
  MatrixHazardScoreDefinitionDto,
  MatrixPossibilityDefinitionControllerService,
  MatrixPossibilityDefinitionDto,
  NearMissTypeDefinitionControllerService,
  NearMissTypeDefinitionDto,
  NurseOperationDefinitionDto,
  ShiftDefinitionControllerService,
  ShiftDefinitionDto,
} from '../api/client';
import { getBloodRelation } from '../api/hs/definitions/health-definitions/blood-relation';
import { getNurseOperationDefinitions } from '../api/hs/enum';
import { UserContext } from './user';

export interface ResourceContextType {
  companies: GetCompaniesResult[];
  departments: GetDepartmentsResult[];
  locations: GetLocationsResult[];
  workStations: GetWorkStationsResult[];
  positions: GetPositionsResult[];
  exposures: GetExposuresResult[];
  disabilities: GetDisabilitiesResult[];
  allergies: GetAllergiesResult[];
  hazardCategories: GetHazardCategoriesResult[];
  firmUser: GetFirmUserResult[];
  referralSection: GetReferralSectionResult[];
  bloodRelation: GetBloodRelationResult[];
  vaccines: GetVaccinesResult[];
  systemCheck: GetSystemCheckResult[];
  physicalCheck: GetPhysicalCheckDefinitionsResult[];
  workingRestrictionDefinitions: GetWorkingRestrictionDefinitionsResult[];
  restReportDefinitions: GetRestReportDefinitionsResult[];
  examinationDefinitions: GetExaminationDefinitionResult[];
  branchCodes: GetBranchCodesResult[];
  firms: GetFirmsResult[];
  trainingDefinitions: TrainingDefinitionsModel[];
  trainingGroupDefinitions: GetTrainingsGroupDefinitionsResult[];
  trainingMainGroupDefinitions: GetTrainingsMainGroupDefinitionsResult[];
  trainers: TrainersResult[];
  hsExamOpinionDefinitions: HsExamOpinionResult[];
  trainings: GetTrainingsResult[];
  ppeDefinitions: GetPpeDefinitionResult[];
  storeHouse: GetPpeStoreHouseResult[];
  ppeClassificationDefinitions: GetPpeClassificationDefinitionsResult[];
  ppeTypeDefinitions: GetPpeTypeDefinitionsResult[];
  accidentTypes: AccidentTypeDefinitionDto[];
  nearMissTypes: NearMissTypeDefinitionDto[];
  shiftDefinitions: ShiftDefinitionDto[];
  employeeBodyRegions: BodyRegionDefinitionDto[];
  accidentSeverityTypes: AccidentSeverityDefinitionDto[];
  analysisParametersUnits: AnalysisParameterUnitDefinitionDto[];
  employeeInjuryTypes: InjuryTypeDefinitionDto[];
  accidentReviewCsiFileTypes: AccidentReviewCsiFileTypeDefinitionDto[];
  accidentReviewDocumentFileTypes: AccidentReviewDocumentDocumentTypeDefinitionDto[];
  workEquipmentTypeDefinitions: GetWorkEquipmentTypeDefinitionResult[];
  buildings: GetBuildingsResult[];
  workEquipmentDefinition: GetWorkEquipmentDefinitionResult[];
  bloodRelations: BloodRelationDefinitionDto[];
  hazardCategoryDefinition: HazardCategoryDefinitionDto[];
  matrixPossibilityDefinition: MatrixPossibilityDefinitionDto[];
  matrixHazardDamageDefinition: MatrixHazardDamageDefinitionDto[];
  hazardCurrentPrecautionDefinition: HazardCurrentPrecautionDefinitionDto[];
  fineKinneyHazardDamageDefinition: FineKinneyHazardDamageDefinitionDto[];
  fineKinneyHazardFrequencyDefinition: FineKinneyHazardFrequencyDefinitionDto[];
  fineKinneyPossibilityDefinition: FineKinneyPossibilityDefinitionDto[];
  fineKinneyHazardScoreDefinition: FineKinneyHazardScoreDefinitionDto[];
  riskAssessmentMatrixScoreDefinition: MatrixHazardScoreDefinitionDto[];
  nurseOperationDefinition: NurseOperationDefinitionDto[];
  accidentReviewBehaviorDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewFactDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewPersonalDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewOrganizationDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewEquipmentDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewMaterialDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  accidentReviewImprovementDefinition: BaseAccidentReviewAnalysisDefinitionDto[];
  emergencyCaseDefinition: EmergencyCaseDefinitionDto[];
}

export const ResourceContext = createContext<ResourceContextType & { reload(resourceName: keyof ResourceContextType): void }>(null);

export function ResourceContextProvider({ children }: PropsWithChildren<unknown>) {
  const [LOADING] = useState(Symbol('loading'));
  const [fetchers] = useState<{ [P in keyof ResourceContextType]: () => Promise<ResourceContextType[P]> }>({
    companies: getCompanies,
    departments: getDepartments,
    locations: getLocations,
    workStations: getWorkStations,
    positions: getPositions,
    exposures: getExposures,
    disabilities: getDisabilities,
    allergies: getAllergies,
    hazardCategories: getGetHazardCategories,
    firmUser: getFirmUser,
    referralSection: getReferralSection,
    bloodRelation: getGetBloodRelation,
    vaccines: getVaccines,
    systemCheck: getSystemCheck,
    physicalCheck: getPhysicalCheckDefinition,
    workingRestrictionDefinitions: getWorkingRestrictionDefinitions,
    restReportDefinitions: getRestReportDefinitions,
    examinationDefinitions: getExaminationDefinitions,
    branchCodes: getBranchCodes,
    firms: getFirms,
    trainingDefinitions: getTrainingDefinitions,
    trainingGroupDefinitions: getTrainingsGroupDefinitions,
    trainingMainGroupDefinitions: getTrainingsMainGroupDefinitions,
    trainers: getTrainers,
    hsExamOpinionDefinitions: getHsExamOpinionDefinitions,
    trainings: getTrainings,
    ppeDefinitions: GetPpeDefinition,
    storeHouse: getPpeStoreHouseSection,
    ppeClassificationDefinitions: getPpeClassificationDefinitions,
    ppeTypeDefinitions: getPpeTypeDefinitions,
    workEquipmentTypeDefinitions: getWorkEquipmentTypeDefinitions,
    buildings: getBuildings,
    workEquipmentDefinition: getWorkEquipmentDefinition,
    accidentTypes: AccidentTypeDefinitionControllerService.getFirmAccidentTypeDefinitions,
    nearMissTypes: NearMissTypeDefinitionControllerService.getFirmNearMissTypeDefinitions,
    shiftDefinitions: ShiftDefinitionControllerService.getFirmShiftDefinitions,
    employeeBodyRegions: BodyRegionDefinitionControllerService.getBodyRegionDefinitions,
    employeeInjuryTypes: InjuryTypeDefinitionControllerService.getFirmInjuryTypeDefinitions,
    accidentReviewCsiFileTypes: AccidentReviewDefinitionControllerService.getFileTypes,
    accidentReviewDocumentFileTypes: AccidentReviewDefinitionControllerService.getDocumentTypes,
    accidentSeverityTypes: AccidentSeverityTypeDefinitionControllerService.getFirmAccidentSeverityDefinitions,
    analysisParametersUnits: AnalysisControllerService.getAllAnalysisParameterUnitDefinition,
    bloodRelations: BloodRelationDefinitionControllerService.getAllFirmBloodRelationDefinitions,
    hazardCategoryDefinition: HazardCategoryDefinitionControllerService.getAllFirmHazardCategoryDefinitions,
    hazardCurrentPrecautionDefinition: HazardCurrentPrecautionDefinitionControllerService.getAllFirmHazardCurrentPrecautionDefinitions,
    matrixHazardDamageDefinition: MatrixHazardDamageDefinitionControllerService.getAllFirmMatrixDamageCategoryDefinitions,
    matrixPossibilityDefinition: MatrixPossibilityDefinitionControllerService.getAllFirmMatrixPossibilityDefinitions,
    fineKinneyHazardDamageDefinition: FineKinneyHazardDamageDefinitionControllerService.getAllFirmFineKinneyDamageCategoryDefinitions,
    fineKinneyPossibilityDefinition: FineKinneyPossibilityDefinitionControllerService.getAllFirmFineKinneyPossibilityDefinitions,
    fineKinneyHazardFrequencyDefinition: FineKinneyHazardFrequencyDefinitionControllerService.getAllFirmHazardFrequencyDefinitions,
    fineKinneyHazardScoreDefinition: FineKinneyHazardScoreDefinitionControllerService.getAllFirmFineKinneyScoreDefinitions,
    riskAssessmentMatrixScoreDefinition: MatrixHazardScoreDefinitionControllerService.getAllFirmMatrixScoreDefinitions,
    nurseOperationDefinition: getNurseOperationDefinitions,
    accidentReviewBehaviorDefinition: AccidentReviewDefinitionControllerService.getBehaviourTypes,
    accidentReviewFactDefinition: AccidentReviewDefinitionControllerService.getFactTypes,
    accidentReviewPersonalDefinition: AccidentReviewDefinitionControllerService.getPersonalTypes,
    accidentReviewOrganizationDefinition: AccidentReviewDefinitionControllerService.getOrganizationTypes,
    accidentReviewEquipmentDefinition: AccidentReviewDefinitionControllerService.getEquipmentTypes,
    accidentReviewMaterialDefinition: AccidentReviewDefinitionControllerService.getMaterialTypes,
    accidentReviewImprovementDefinition: AccidentReviewDefinitionControllerService.getImprovementTypes,
    emergencyCaseDefinition: EmergencyCaseDefinitionControllerService.getAllFirmEmergencyCaseDefinitions,
  });
  const [defaults] = useState<ResourceContextType>({
    companies: [],
    departments: [],
    locations: [],
    workStations: [],
    positions: [],
    exposures: [],
    disabilities: [],
    allergies: [],
    hazardCategories: [],
    firmUser: [],
    referralSection: [],
    bloodRelation: [],
    vaccines: [],
    systemCheck: [],
    physicalCheck: [],
    workingRestrictionDefinitions: [],
    restReportDefinitions: [],
    examinationDefinitions: [],
    branchCodes: [],
    firms: [],
    trainingDefinitions: [],
    trainingGroupDefinitions: [],
    trainingMainGroupDefinitions: [],
    trainers: [],
    hsExamOpinionDefinitions: [],
    trainings: [],
    ppeDefinitions: [],
    storeHouse: [],
    ppeTypeDefinitions: [],
    ppeClassificationDefinitions: [],
    accidentTypes: [],
    nearMissTypes: [],
    shiftDefinitions: [],
    employeeBodyRegions: [],
    employeeInjuryTypes: [],
    accidentSeverityTypes: [],
    analysisParametersUnits: [],
    workEquipmentTypeDefinitions: [],
    buildings: [],
    workEquipmentDefinition: [],
    accidentReviewCsiFileTypes: [],
    accidentReviewDocumentFileTypes: [],
    bloodRelations: [],
    matrixPossibilityDefinition: [],
    matrixHazardDamageDefinition: [],
    hazardCurrentPrecautionDefinition: [],
    hazardCategoryDefinition: [],
    fineKinneyHazardFrequencyDefinition: [],
    fineKinneyPossibilityDefinition: [],
    fineKinneyHazardDamageDefinition: [],
    fineKinneyHazardScoreDefinition: [],
    riskAssessmentMatrixScoreDefinition: [],
    nurseOperationDefinition: [],
    accidentReviewBehaviorDefinition: [],
    accidentReviewFactDefinition: [],
    accidentReviewPersonalDefinition: [],
    accidentReviewOrganizationDefinition: [],
    accidentReviewEquipmentDefinition: [],
    accidentReviewMaterialDefinition: [],
    accidentReviewImprovementDefinition: [],
    emergencyCaseDefinition: [],
  });

  const [data] = useState<{ results: { [P in keyof ResourceContextType]?: ResourceContextType[P] | symbol } }>({ results: {} });
  const [, triggerRender] = useState({});
  const { user } = useContext(UserContext);

  const reload = useCallback(
    (resourceName: keyof ResourceContextType) => {
      delete data.results[resourceName];
      sessionStorage.removeItem(`hsf/resources/${resourceName}`);
      triggerRender({});
    },
    [data, triggerRender]
  );

  useEffect(() => {
    if (!user) {
      data.results = {};

      for (const key of Object.keys(sessionStorage).filter(_key => _key.startsWith('hsf/resources/'))) {
        sessionStorage.removeItem(key);
      }
    }
  }, [user]); // eslint-disable-line

  const proxy = new Proxy(
    {},
    {
      // eslint-disable-next-line
      get(_, prop: keyof ResourceContextType | 'reload') {
        if (prop === 'reload') {
          return reload;
        }

        if (prop in data.results) {
          if (data.results[prop] === LOADING) {
            return defaults[prop];
          } else {
            return data.results[prop];
          }
        }

        const storedValue = JSON.parse(sessionStorage.getItem(`hsf/resources/${prop}`));

        if (storedValue) {
          data.results = { ...data.results, [prop]: storedValue };

          return storedValue;
        }

        data.results = { ...data.results, [prop]: LOADING };

        (fetchers[prop]() as Promise<any>).then(res => {
          sessionStorage.setItem(`hsf/resources/${prop}`, JSON.stringify(res));

          data.results = { ...data.results, [prop]: res };

          triggerRender(data.results);
        });

        return defaults[prop];
      },
    }
  );

  return <ResourceContext.Provider value={proxy as any}>{children}</ResourceContext.Provider>;
}
