import { createContext } from 'react';

import { TrainerModel, TrainersResult } from 'api/hs/definitions/trainings/trainers';

export interface TrainersContextType {
  trainers: TrainersResult[];

  loading: boolean;

  reload(): void;

  setLoading(loading: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: TrainerModel;

  setSelectedData(data: TrainerModel): void;
}

export const TrainersContext = createContext<TrainersContextType>(null);
