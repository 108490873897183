/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyCaseTeamTrainingDefinitionDto } from '../models/EmergencyCaseTeamTrainingDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmergencyCaseTeamTrainingDefinitionControllerService {

    /**
     * @param id
     * @returns EmergencyCaseTeamTrainingDefinitionDto OK
     * @throws ApiError
     */
    public static getEmergencyCaseTeamTrainingById(
        id: string,
    ): CancelablePromise<EmergencyCaseTeamTrainingDefinitionDto> {
        return __request({
            method: 'GET',
            path: `/emergency-case-team-trainings/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseTeamTrainingDefinitionDto OK
     * @throws ApiError
     */
    public static updateEmergencyCaseTeamTraining(
        id: string,
        requestBody: EmergencyCaseTeamTrainingDefinitionDto,
    ): CancelablePromise<EmergencyCaseTeamTrainingDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/emergency-case-team-trainings/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteEmployeeEmergencyCaseTeamTraining(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-team-trainings/${id}`,
        });
    }

    /**
     * @param emergencyCaseTeamId
     * @param requestBody
     * @returns EmergencyCaseTeamTrainingDefinitionDto OK
     * @throws ApiError
     */
    public static saveEmergencyCaseTeamTraining(
        emergencyCaseTeamId: string,
        requestBody: Array<EmergencyCaseTeamTrainingDefinitionDto>,
    ): CancelablePromise<Array<EmergencyCaseTeamTrainingDefinitionDto>> {
        return __request({
            method: 'POST',
            path: `/emergency-case-team-trainings`,
            query: {
                'emergencyCaseTeamId': emergencyCaseTeamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param emergencyCaseTeamId
     * @returns EmergencyCaseTeamTrainingDefinitionDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCaseTeamTraining(
        emergencyCaseTeamId: string,
    ): CancelablePromise<Array<EmergencyCaseTeamTrainingDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-team-training-definitions`,
            query: {
                'emergencyCaseTeamId': emergencyCaseTeamId,
            },
        });
    }

}