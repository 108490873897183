import { Collapse, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { TrainingPlanningListContext } from '../training-planning-list.context';
import ReceivedListTable from './ReceivedListTable';

const useStyles = makeStyles(theme => ({
  listItemStyle: {
    backgroundColor: 'whitesmoke',
    color: 'black',
    marginBottom: theme.spacing(1),
  },
  collapseStyle: {
    border: '2px solid whitesmoke ',
    padding: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(1),
    height: '100%',
    maxHeight: 475,
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  section4: {
    margin: theme.spacing(3, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
}));

export default function PlaningTrainingList() {
  const classes = useStyles();

  const [openTrainingsReceived, setOpenTrainingsReceived] = React.useState(false);
  const { employeeTrainingListPlanning, loading } = useContext(TrainingPlanningListContext);

  return (
    <div className="mt-1 mr-1">
      <List>
        <ListItem className={classes.listItemStyle} onClick={() => setOpenTrainingsReceived(!openTrainingsReceived)}>
          <ListItemIcon>
            <Icon className="icon-school" />
          </ListItemIcon>
          <ListItemText primary="Aldığı Eğitimler" />
          {openTrainingsReceived ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.collapseStyle} in={openTrainingsReceived} timeout="auto" unmountOnExit>
          <IndicatorWrapper
            loading={loading}
            data={employeeTrainingListPlanning.filter(trainers => trainers?.isCompleted === true)}
            errorMessage="Henüz Bir Eğitim Almamıştır.">
            <ReceivedListTable />
          </IndicatorWrapper>
        </Collapse>
      </List>
    </div>
  );
}
