import { findByKey } from '@thalesrc/js-utils';

import { AnalysisAggregate } from 'api/hs/examination/analysis';

import { Category } from '../category.enum';
import { DatasetModel, GraphModel } from './graph.model';

interface DataSet {
  leftEarDataSet: DatasetModel[];
  rightEarDataSet: DatasetModel[];
}

export default function prepareAudiometryGraphData(
  data: AnalysisAggregate[],
  type: 'left' | 'right',
  analysisCategoryDefinitions: any
): GraphModel {
  if (!data || data.length === 0) return null;
  const colorSet = [
    'rgba(204, 50, 54, 1)',
    'rgba(89, 140, 115, 1)',
    'rgba(89, 43, 115, 1)',
    'rgba(184, 186, 115, 1)',
    'rgba(0, 91, 208, 1)',
    'rgba(238, 130, 238, 1)',
  ];

  let leftEarDataSet: DatasetModel = null;
  let rightEarDataSet: DatasetModel = null;
  let leftEarDataSetLabels: string[] = [];
  let rightEarDataSetLabels: string[] = [];
  const dataSet: DataSet = {
    leftEarDataSet: [],
    rightEarDataSet: [],
  };
  data?.forEach((item, index) => {
    if (findByKey(analysisCategoryDefinitions, 'id', item?.categoryDefinitionId)?.label === Category.Audiometry) {
      if (item && item.audiometry && item.audiometry.leftEar && item.audiometry.rightEar) {
        leftEarDataSetLabels = Object.keys(item.audiometry.leftEar);
        rightEarDataSetLabels = Object.keys(item.audiometry.rightEar);
        leftEarDataSet = {
          label: `${index}. ${item.date}`,
          data: Object.values(item.audiometry?.leftEar),
          fill: false,
          borderColor: colorSet[index],
        };
        rightEarDataSet = {
          label: `${index}. ${item.date}`,
          data: Object.values(item.audiometry?.rightEar),
          fill: false,
          borderColor: colorSet[index],
        };
        dataSet.leftEarDataSet.push(leftEarDataSet);
        dataSet.rightEarDataSet.push(rightEarDataSet);
      }
    }
  });

  if (type === 'left') {
    return {
      labels: leftEarDataSetLabels,
      datasets: dataSet ? dataSet.leftEarDataSet : [],
    };
  } else if (type === 'right') {
    return {
      labels: rightEarDataSetLabels,
      datasets: dataSet ? dataSet.rightEarDataSet : [],
    };
  }

  return null;
}
