import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { CreateGuestPayload, createTrainingPlanning, TrainingPlaningEmployeeGroup } from 'api/hs/trainings';
import Button from 'Common/Button';
import { ResourceContext } from 'context/resource.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useDateFormat, useTranslate } from 'utils';

import { updateTrainingPlanning } from '../../api/hs/trainings';
import { AlertContext } from '../../context/alert.context';
import { GuestParticipant, PlanningContext } from './planning.context';
import {TrainingDefinitionEmployeeControllerService, TrainingDto} from "../../api/client";
import HSApi from "../../api/hs/HSApi";

export default function Preview() {
  const { selectedTrainings, info, durations, participants, participantForm, quiz, preQuiz, otherFiles, remoteTrainingEmployeeFile } = useContext(PlanningContext);
  const { trainingDefinitions, trainers } = useContext(ResourceContext);
  const { trainingType, trainingLocation } = useContext(SelectOptionsContext);
  const translate = useTranslate('form', 'training');
  const format = useDateFormat('dd MMMM yyyy HH:mm');
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();
  const { trainingId } = useParams<{ trainingId: string }>();
  const durationInMinutes = useMemo(() => Object.values(durations).reduce((total, dur) => total + +dur ?? 0, 0), [durations]);

  const saveTraining = useCallback(async () => {
    const payload = {
      ...info,
      trainingDefinitions: selectedTrainings.map(id => ({ trainingDefinitionId: id, durationInMinutes: durations[id] })),
      isCompleted: info.completedDate ? true : false,
      completedDate: info.completedDate ? info.completedDate : null,
      participants: {
        employees: participants.filter(({ id }) => typeof id === 'string').map(({ id }) => id) as string[],
        guests: participants.filter(({ id }) => typeof id === 'symbol') as CreateGuestPayload[],
      },
      trainingTrainers: info.trainerIds.map(trainerId => ({ trainerId })),
      trainingPlaningEmployeeExcluding: participantForm?.blacklist?.map(employeeId => ({ employeeId })),
      trainingPlaningEmployeeIncluding: participantForm?.added?.map(employeeId => ({ employeeId })),

      quizFiles: quiz,
      preQuizFiles: preQuiz,
      otherFiles,
    };
    if(info.isFaceToFace || !info.importFromExcel) {
      payload.trainingPlaningEmployeeGroups = [
        ...participantForm?.departmentDefinitionIds?.map<TrainingPlaningEmployeeGroup>(id => ({
          referenceTableId: id,
          assignmentTypeDefinition: 'DEPARTMENT',
        })),
        ...participantForm?.locationDefinitionIds?.map<TrainingPlaningEmployeeGroup>(id => ({
          referenceTableId: id,
          assignmentTypeDefinition: 'LOCATION',
        })),
        ...participantForm?.positionDefinitionIds?.map<TrainingPlaningEmployeeGroup>(id => ({
          referenceTableId: id,
          assignmentTypeDefinition: 'POSITION',
        })),
        ...participantForm?.workstationDefinitionIds?.map<TrainingPlaningEmployeeGroup>(id => ({
          referenceTableId: id,
          assignmentTypeDefinition: 'WORKSTATION',
        })),
      ];
    }

    try {
      if (trainingId) {
        let trainingDto = await updateTrainingPlanning(trainingId, payload);
        showMessage('Eğitim planı güncellendi.', 'success');

        history.push('/training/detail-list');
      } else {
        let trainingDto = await createTrainingPlanning(payload);
        showMessage('Eğitim planı oluşturuldu.', 'success');
        if(!info.isFaceToFace && info.importFromExcel) {
          /*const data = new FormData();
          data.set('imported-file', file);

          const [error, res] = await tryCatch(HSApi.post(`near-misses/${id}/imported-files`, data));

          if (error) {
            console.error(error); // tslint:disable-line:no-console
            throw error.response;
          }
          debugger;*/

          TrainingDefinitionEmployeeControllerService.importTrainingEmployeesFromFile(trainingDto.id,'EXCEL', {'imported-file':remoteTrainingEmployeeFile});
        }
        setTimeout(() => history.push('/training/detail-list'), 500);
      }
    } catch (e) {
      showMessage('Eğitim planı oluşturulurken bir hata oluştu.', 'error');
    }
  }, [selectedTrainings, info, durations, participants, participantForm, quiz, preQuiz, otherFiles, history, showMessage, trainingId]);

  const trainerNames = useMemo(
    () =>
      info.trainerIds
        ?.map(trainer => findByKey(trainers, 'id', trainer))
        .map(({ id, ...data }) => `${data?.name ?? ''} ${data?.lastName ?? ''}`)
        .join(', '),
    [info, trainers]
  );

  const participantNames = useMemo(
    () =>
      participants
        .map(({ id, ...data }) => {
          if (typeof id === 'symbol') {
            return `${data.name} ${(data as GuestParticipant).surname}`;
          } else {
            return data.name;
          }
        })
        .join(', '),
    [participants]
  );

  return (
    <>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>Eğitim Adı</TableCell>
            <TableCell>{info.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Konular</TableCell>
            <TableCell>{selectedTrainings.map(training => findByKey(trainingDefinitions, 'id', training)?.name).join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Eğitim Süresi</TableCell>
            <TableCell>{durationInMinutes}dk</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Eğitmenler</TableCell>
            <TableCell>{trainerNames}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Training Place')}</TableCell>
            <TableCell>{findByKey(trainingLocation, 'value', info.atWorkplace)?.text}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Training Type')}</TableCell>
            <TableCell>{findByKey(trainingType, 'value', info.isFaceToFace)?.text}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Training Date and Time')}</TableCell>
            <TableCell>{format(new Date(`${info.plannedDate}`))}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Training Location')}</TableCell>
            <TableCell>{info.location}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Description')}</TableCell>
            <TableCell>{info.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{translate('Participants')}</TableCell>
            <TableCell>{participantNames}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ön Test Dosyaları</TableCell>
            <TableCell>{preQuiz.map(file => file.name).join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Son Test Dosyaları</TableCell>
            <TableCell>{quiz.map(file => file.name).join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Eğitim Materyalleri</TableCell>
            <TableCell>{otherFiles.map(file => file.name).join(', ')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <footer className="text-right mt-2">
        <Button variant="contained" color="primary" onClick={saveTraining}>
          {trainingId ? 'Eğitimi Güncelle' : 'Eğitimi Planla'}
        </Button>
      </footer>
    </>
  );
}
