/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageVaccineView } from '../models/PageVaccineView';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { VaccineViewRequest } from '../models/VaccineViewRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class VaccineViewControllerService {

    /**
     * @param requestBody
     * @returns PageVaccineView OK
     * @throws ApiError
     */
    public static getVaccineListFiltered(
        requestBody: VaccineViewRequest,
    ): CancelablePromise<PageVaccineView> {
        return __request({
            method: 'POST',
            path: `/vaccines/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param psRequestParam
     * @returns PageVaccineView OK
     * @throws ApiError
     */
    public static getVaccineList(
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageVaccineView> {
        return __request({
            method: 'GET',
            path: `/vaccines/view`,
            query: {
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static vaccineViewExport(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/vaccines/view/export`,
            query: {
                'type': type,
            },
        });
    }

}