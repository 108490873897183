import { createContext } from 'react';

import { EmployeeImportedFilesModel, EmployeeImportedFilesResult } from 'api/hs/file-uploads/employee-imported-files';

export interface ProfileUploadContextType {
  employeeImported: EmployeeImportedFilesResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  previewImageOpened: boolean;

  setPreviewImageOpened(opened: boolean): void;

  selectedData: EmployeeImportedFilesModel;

  setSelectedData(data: EmployeeImportedFilesModel): void;
}

export const ProfileUploadContext = createContext<ProfileUploadContextType>(null);
