import {
  Dialog as MUIDialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { savePhysicalCheck } from 'api/hs/examination/physical-checks';
import Button from 'Common/Button';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';
import { useAsyncEffect, useXhr } from 'utils';

import { PhysicalCheckContext } from '../../physical-check.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface OptionExtra {
  color: string;
}

const DEFAULT_COMPLAINT_ID = 2;

const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Var', value: 1, color: '#eb4355' },
  { text: 'Yok', value: 0, color: '#469789' },
  { text: 'Belirsiz', value: DEFAULT_COMPLAINT_ID, color: '#c0c0c0' },
];

export default function DialogFormPhysicalCheck() {
  const classes = useStyles();
  const [previousForm, setPreviousForm] = useState({});
  const { editDialogOpened, setEditDialogOpened, physicalChecks, reload } = useContext(PhysicalCheckContext);

  const formRef = useRef<FormRef<Record<string, any>>>();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const cancel = useCallback(() => {
    setEditDialogOpened(false);
  }, [setEditDialogOpened]);

  useAsyncEffect(async () => {
    if (!physicalChecks) {
      return;
    }
    await defer();
    for (const item of physicalChecks) {
      formRef.current.methods.setValue(
        item.physicalCheckDefinitionId + '',
        item.physicalCheckStatusDefinitionId === undefined ? DEFAULT_COMPLAINT_ID : item.physicalCheckStatusDefinitionId
      );
      formRef.current.methods.setValue('description' + item.physicalCheckDefinitionId, item.description);
    }
    formRef.current.methods.trigger();
  }, [physicalChecks]);

  const submitForm = useXhr(
    async data => {
      const items = Object.entries(data)
        .filter(([key]) => !key.includes('description'))
        .reduce((acc, [key, value]) => {
          return [
            ...acc,
            {
              employeeId,
              examinationId,
              physicalCheckDefinitionId: key,
              physicalCheckStatusDefinitionId: +value,
              description: data['description' + key],
            },
          ];
        }, []);

      await savePhysicalCheck(items);
      await reload();
      setEditDialogOpened(false);
    },
    'Fizik Muayene Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [employeeId, examinationId, reload]
  );

  const allIgnore = useCallback(() => {
    const data = formRef.current.methods.getValues();
    const dataItem = Object.entries(data).filter(([key]) => !key.includes('description'));
    dataItem.map((item, index) => formRef.current.methods.setValue(item[0], 0));
  }, []);

  const formChange = useCallback(
    values => {
      setPreviousForm(values);
      let changed = false;
      for (const { physicalCheckDefinitionId: id, physicalCheckDefinitionDefaultNegativeResponse: description } of physicalChecks) {
        if (previousForm[id] === 0 || values[id] !== 0 || values['description' + id] !== '') {
          continue;
        }
        changed = true;
        formRef.current.methods.setValue('description' + id, description);
      }
      if (changed) {
        formRef.current.methods.trigger();
      }
    },
    [physicalChecks, previousForm]
  );

  return (
    <MUIDialog open={editDialogOpened} fullWidth maxWidth="md" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Fizik Muayene Düzenle</DialogTitle>
      <DialogContent>
        <Form onChange={formChange} onSubmit={submitForm} ref={formRef}>
          <div className="text-right">
            <Button onClick={allIgnore} color="primary">
              Tümünü Yok Seç
            </Button>
          </div>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {physicalChecks.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '35%', color: 'gray', textAlign: 'justify' }} align="left" component="th" scope="row">
                      <b>{item?.physicalCheckDefinitionName}</b>
                      <br />
                      <p>{item?.physicalCheckDefinitionDescription}</p>
                    </TableCell>
                    <TableCell style={{ width: '30%' }} align="right">
                      <ToggleButtonGroup<number, OptionExtra>
                        name={item.physicalCheckDefinitionId + ''}
                        options={OPTIONS}
                        defaultValue={DEFAULT_COMPLAINT_ID}
                        renderOption={({ color, selected, text, value }) => (
                          <ToggleButton
                            value={value}
                            className={selected ? 'text-white' : ''}
                            style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                            {text}
                          </ToggleButton>
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ width: '30%' }}>
                      <TextField name={'description' + item?.physicalCheckDefinitionId} label="Açıklama" variant="outlined" rows={3} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <footer className="px-2 text-right mt-2">
            <Button style={{ marginRight: '15px' }} type="button" variant="contained" onClick={cancel}>
              Vazgeç
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Kaydet
            </Button>
          </footer>
        </Form>
      </DialogContent>
    </MUIDialog>
  );
}
