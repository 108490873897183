import React from 'react';

import EditDialog from './EditDialog';
import TableList from './List';
import ToolbarInfo from './Toolbar';
export default function PpeDetail() {
  return (
    <>
      <ToolbarInfo />
      <TableList />
      <EditDialog />
    </>
  );
}
