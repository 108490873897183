import React from 'react';

import AdminDepartmentDefinition from './AdminDepartmentDefinition';
import AdminExposureDefinition from './AdminExposureDefinition';
import AdminPositionDefinition from './AdminPositionDefinition';
import AdminShiftDefinition from './AdminShiftDefinition';
import AdminStationDefinition from './AdminStationDefinition';

interface AdminWorkPlaceListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'Departmanlar',
    Component: AdminDepartmentDefinition,
    path: '',
  },
  {
    label: 'İş İstasyonları',
    Component: AdminStationDefinition,
    path: 'is-istasyonlari',
  },
  {
    label: 'Görevler',
    Component: AdminPositionDefinition,
    path: 'gorevler',
  },
  {
    label: 'Vardiya',
    Component: AdminShiftDefinition,
    path: 'shift',
  },
  {
    label: 'Maruziyetler',
    Component: AdminExposureDefinition,
    path: 'maruziyetler',
  },
] as AdminWorkPlaceListItem[];
