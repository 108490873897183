import { createContext } from 'react';

import { PregnanciesModel, PregnanciesResult } from 'api/hs/examination/pregnancies';

export interface PregnanciesContextType {
  pregnancies: PregnanciesResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PregnanciesModel;

  setSelectedData(data: PregnanciesModel): void;
}

export const PregnanciesContextContext = createContext<PregnanciesContextType>(null);
