import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';

import Form, { FormRef } from 'Common/Form/Form';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../api/hs/employee';
import AutoComplete from '../../Common/Form/AutoComplete';
import { SelectOption } from '../../Common/Form/Select';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}
export default function EmployeeSearch() {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const history = useHistory();

  const onChangeForm = useCallback(() => {
    const employeeId = formRef?.current?.methods?.getValues('employeeId');
    if (employeeId) {
      history.push(`/transactions/employee/${employeeId}`);
    }
  }, [history]);

  return (
    <Form onChange={onChangeForm} ref={formRef}>
      <AutoComplete
        single
        name="employeeId"
        label="Personel Arama"
        optionFetcher={employeeOptionFetcher}
        labelFetcher={employeeLabelFetcher}
        variant="outlined"
        endAdornment={<SearchIcon color="primary" className="mr-1 mt-2 " fontSize="large" />}
      />
    </Form>
  );
}
