import React, { useCallback, useState } from 'react';

import NewRecordButton from '../Review/NewRecordButton';
import AccidentEmployeesForm from './AccidentEmployeesForm';

interface Props {
  fetch(): void;
  accidentId: string;
}

export default function AccidentEmployeesAddButton({ fetch, accidentId }: Props) {
  const [openNewResult, setOpenNewResult] = useState(false);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await fetch();
      }
      setOpenNewResult(false);
    },
    [setOpenNewResult, fetch]
  );
  const onClick = () => {
    setOpenNewResult(true);
  };
  return (
    <>
      <NewRecordButton type="button" onClick={onClick} label={'Kaza Geçiren Personel Ekle'} />
      <AccidentEmployeesForm onClose={closeDialog} data={null} open={openNewResult} accidentId={accidentId} />
    </>
  );
}
