/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentEmployeeDto } from '../models/AccidentEmployeeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentEmployeeControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns AccidentEmployeeDto OK
     * @throws ApiError
     */
    public static updateAccidentEmployee(
        id: string,
        requestBody: AccidentEmployeeDto,
    ): CancelablePromise<AccidentEmployeeDto> {
        return __request({
            method: 'PUT',
            path: `/accidents-employees/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAccidentEmployee(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents-employees/${id}`,
        });
    }

    /**
     * @param id
     * @returns AccidentEmployeeDto OK
     * @throws ApiError
     */
    public static getAllAccidentEmployees(
        id: string,
    ): CancelablePromise<Array<AccidentEmployeeDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${id}/accidents-employees`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns AccidentEmployeeDto OK
     * @throws ApiError
     */
    public static saveAccidentEmployee(
        id: string,
        requestBody: AccidentEmployeeDto,
    ): CancelablePromise<AccidentEmployeeDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${id}/accidents-employees`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentIds
     * @returns AccidentEmployeeDto OK
     * @throws ApiError
     */
    public static getAllAccidentEmployeeMap(
        accidentIds: Array<string>,
    ): CancelablePromise<Record<string, Array<AccidentEmployeeDto>>> {
        return __request({
            method: 'GET',
            path: `/accidents/accidents-employees/map`,
            query: {
                'accidentIds': accidentIds,
            },
        });
    }

}