import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { ExposurePayload } from './Exposure.payload';
import { ExposureResult } from './Exposure.result';

export async function getExposure(): Promise<ExposureResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ExposureResult[]>(`/firms/exposure-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveExposure(body: ExposurePayload): Promise<ExposureResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/exposure-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateExposure({ id, ...body }: MakeRequired<Partial<ExposureResult>, 'id'>): Promise<ExposureResult> {
  const [error, res] = await tryCatch(HSApi.put<ExposureResult>(`/firms/exposure-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteExposure(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/exposure-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminExposure(): Promise<ExposureResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ExposureResult[]>(`/exposure-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminExposure(body: ExposurePayload): Promise<ExposureResult> {
  const [err, res] = await tryCatch(HSApi.post('/exposure-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminExposure({ id, ...body }: MakeRequired<Partial<ExposureResult>, 'id'>): Promise<ExposureResult> {
  const [error, res] = await tryCatch(HSApi.put<ExposureResult>(`/exposure-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminExposure(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/exposure-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function geExposureList(page = 0, size = 20, order: null | string, filters: any) {
  const payload = {
    filter: filters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/exposures/pages`, payload);

  return res.data;
}
