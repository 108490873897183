import { Card, CardContent, CardHeader, Container, Grid, Paper, Typography } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { convertWithExistingMapByValueAndDateFieldName, setDateKey } from 'Statistics/utils/field-value-sum-with-date';

import {
  AccidentControllerService,
  AccidentViewRequest,
  EmployeeControllerService,
  ReferralViewControllerService,
  ReferralViewRequest,
  RestReportViewControllerService,
  RestReportViewRequest,
} from '../../api/client';
import { EmployeeSelectionCacheContext } from '../../Common/Form/EmployeeSelection/employee-selection.context';
import Form, { FormRef } from '../../Common/Form/Form';
import Switch from '../../Common/Form/Switch';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { useTriggerRender } from '../../utils';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import AccidentPieChartGridContainer from '../Accident/AccidentPieChartGridContainer';
import { convertByFieldNameSum, convertByFieldNameTotalOfValue } from '../utils/chart-data-converter-pie';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';
import VitalChartContainer from '../VitalMeasurements/VitalChartContainer';

export default function LostWorkingDays() {
  const [referralChartData, setReferralChartData] = useState([]);
  const [restReportChartData, setRestReportChartData] = useState([]);
  const [accidentChartData, setAccidentChartData] = useState([]);
  const [mergeAllData, setMergeAllData] = useState([]);
  const [stackedData, setStackedData] = useState([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();
  const { companies, departments, workStations } = useContext(ResourceContext);
  const reRender = useTriggerRender();
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [collapsed, setCollapsed] = useState(false);

  const onChangeForm = useCallback(
    async values => {
      reRender();
    },
    [reRender]
  );

  const submit = useCallback(
    async values => {
      const request: ReferralViewRequest = {
        filter: {
          dateMin: values?.minDate + 'T00:00:00',
          dateMax: values?.maxDate + 'T23:59:59',
        },
        pagingAndSortingRequestParam: {
          page: 0,
          size: 2147483647,
          order: 'referralDate',
        },
      };

      const restReportRequest: RestReportViewRequest = {
        filter: {
          dateMin: values?.minDate + 'T00:00:00',
          dateMax: values?.maxDate + 'T23:59:59',
        },
        pagingAndSortingRequestParam: {
          page: 0,
          size: 2147483647,
          order: 'date',
        },
      };

      const accidentRequest: AccidentViewRequest = {
        filter: {
          accidentDateMin: values?.minDate + 'T00:00:00',
          accidentDateMax: values?.maxDate + 'T23:59:59',
        },
        pagingAndSortingRequestParam: {
          page: 0,
          size: 2147483647,
          order: 'date',
        },
      };

      const referralRes = await ReferralViewControllerService.getReferralListFiltered(request);
      const restReportRes = await RestReportViewControllerService.getRestReportListFiltered(restReportRequest);
      const accidentRes = await AccidentControllerService.getAccidentViews1(accidentRequest);
      const employeeIds: string[] = [];

      accidentRes?.content?.forEach(data => {
        data?.accidentEmployeeViews?.forEach(filterData => employeeIds.push(filterData?.employeeId));
      });

      const employeeIdsRes = await EmployeeControllerService.getEmployeeList1(employeeIds);

      const allData: any[] = [
        ...employeeIds?.map(employee => {
          return {
            companyName: findByKey(companies, 'id', employeeIdsRes?.find(emp => emp.id === employee)?.companyId)?.companyName,
            departmentName: findByKey(departments, 'id', employeeIdsRes?.find(emp => emp.id === employee)?.departmentId)?.departmentName,
            type: 'İş Kazası',
          };
        }),
        ...referralRes.content?.map(item => {
          return {
            companyName: item?.companyName,
            departmentName: item?.departmentName,
            type: 'Sevk',
          };
        }),
        ...restReportRes.content?.map(item => {
          return {
            companyName: item?.companyName,
            departmentName: item?.departmentName,
            type: 'İstirahat Raporu',
          };
        }),
      ];
      const resultMap: any = {};
      convertWithExistingMapByValueAndDateFieldName(
        resultMap,
        accidentRes.content,
        'date',
        'accidentEmployeeViews[].lostWorkDays',
        'İş Kazası',
        values.dataDisplayOption
      );
      referralRes.content?.forEach(item => {
        const date = new Date(Reflect.get(item, 'referralDate'));
        const key = setDateKey(resultMap, date, values.dataDisplayOption);
        const referralObj = {
          Sevk: 1,
          date: key,
        };
        if (resultMap[key]) {
          resultMap[key] = { ...resultMap[key], ...referralObj };
        } else {
          resultMap[key] = referralObj;
        }
      });

      restReportRes.content?.forEach(item => {
        const date = new Date(Reflect.get(item, 'date'));
        const key = setDateKey(resultMap, date, values.dataDisplayOption);
        const restReportObj = {
          'İstirahat Raporu': Reflect.get(item, 'durationInDays'),
          date: key,
        };
        if (resultMap[key]) {
          resultMap[key] = { ...resultMap[key], ...restReportObj };
        } else {
          resultMap[key] = restReportObj;
        }
      });
      setStackedData(Object.values(resultMap));
      setMergeAllData(allData);
      setAccidentChartData(accidentRes.content);
      setRestReportChartData(restReportRes.content);
      setReferralChartData(referralRes.content);
      setDataDisplayOption(values?.dataDisplayOption);
      return referralRes.content;
    },
    [companies, departments]
  );

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          İş Günü Kayıpları
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      <Form onChange={onChangeForm} ref={formRef}>
        <Paper>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={4}>
                <Switch defaultValue={true} name="accident" label="İş Kazası" />
              </Grid>
              <Grid item xs={4}>
                <Switch defaultValue={true} name="restReport" label="İstirahat Raporu" />
              </Grid>
              <Grid item xs={4}>
                <Switch defaultValue={true} name="referral" label="Sevk" />
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Form>
      {mergeAllData.length! > 0 && (
        <Card>
          <CardHeader className="text-center" title="İş Günü Kayıpları" />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Card style={{ padding: '10px', margin: '10px' }}>
                  <CardHeader title="Zaman ve Türe Göre Dağılımı" />
                  <DateGroupXYChart
                    chartName="lost-workingdays-date-stacked"
                    finalData={stackedData}
                    dateFieldName="date"
                    xAxesTitle="Tarih"
                    xAxesText="Tarih"
                    yAxesText="İş Günü Kaybı"
                    dataDisplayOption={dataDisplayOption}
                    stacked={true}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card style={{ padding: '10px', margin: '10px' }}>
                  <CardHeader title="Şirket Bazlı Dağılımı" />{' '}
                  <VitalChartContainer
                    chartName="merge-all-company-report"
                    rawData={mergeAllData}
                    categoryFieldName="companyName"
                    selectorFieldByName="type"
                    xAxesTitle="Şirketler"
                    xAxesText="Şirket"
                    yAxesText=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card style={{ padding: '10px', margin: '10px' }}>
                  <CardHeader title="Şirket Bazlı Dağılımı" />{' '}
                  <VitalChartContainer
                    chartName="merge-all-company-report"
                    rawData={mergeAllData}
                    categoryFieldName="companyName"
                    selectorFieldByName="type"
                    xAxesTitle="Şirketler"
                    xAxesText="Şirket"
                    yAxesText=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card style={{ padding: '10px', margin: '10px' }}>
                  <CardHeader title="Departman Bazlı Dağılımı" />{' '}
                  <VitalChartContainer
                    chartName="merge-all-departmentName-report"
                    rawData={mergeAllData}
                    categoryFieldName="departmentName"
                    selectorFieldByName="type"
                    xAxesTitle="Departmanlar"
                    xAxesText="Departman"
                    yAxesText=""
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <PieChartGridContainer
                  title="İş Günü Kayıplarına Göre Dağılımı"
                  chartProps={{
                    chartName: 'lost-working-report',
                    preparedData: [
                      { label: 'Sevk', value: referralChartData.length },
                      { label: 'İstirahat Raporu', value: restReportChartData.length },
                      { label: 'İş Kazası', value: accidentChartData.length },
                    ],
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {referralChartData.length! > 0 && dataDisplayOption != null && (
        <>
          {formRef?.current?.value?.referral === true ? (
            <Card>
              <CardHeader className="text-center" title="   Sevk İş Gün Kayıpları" />
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Sevklere Göre Şirket Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="referral-company-report"
                        data={referralChartData}
                        dateFieldName="referralDate"
                        selectorFieldByName="companyName"
                        xAxesTitle="Şirketler"
                        xAxesText="Tarih"
                        yAxesText="Sevk Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Sevklere Göre Departman Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="rest-report-department-report"
                        data={referralChartData}
                        dateFieldName="referralDate"
                        selectorFieldByName="departmentName"
                        xAxesTitle="Departmanlar"
                        xAxesText="Tarih"
                        yAxesText="Sevk Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Sevklere Göre Hastane Tipi Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="rest-report-reason-name-report"
                        data={referralChartData}
                        dateFieldName="referralDate"
                        selectorFieldByName="hospitalTypeName"
                        xAxesTitle="Hastaneler"
                        xAxesText="Tarih"
                        yAxesText="Sevk Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Sevklere Göre Bölüm Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="examination-examinationType-report"
                        data={referralChartData}
                        dateFieldName="referralDate"
                        selectorFieldByName="referralSectionDefinitionName"
                        xAxesTitle="Bölümler"
                        xAxesText="Tarih"
                        yAxesText="Sevk Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}
          {formRef?.current?.value?.restReport === true ? (
            <Card>
              <CardHeader className="text-center" title="   İstirahat Raporu İş Gün Kayıpları" />
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="İstirahat Bazlı Şirket Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-res-report-company-report"
                        data={restReportChartData}
                        dateFieldName="date"
                        selectorFieldByName="companyName"
                        xAxesTitle="Şirketler"
                        xAxesText="Tarih"
                        yAxesText="Rapor Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="İstirahat Bazlı Departman Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-rest-report-department-report"
                        data={restReportChartData}
                        dateFieldName="date"
                        selectorFieldByName="departmentName"
                        xAxesTitle="Departmanlar"
                        xAxesText="Tarih"
                        yAxesText="Rapor Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>

                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="İstirahat Bazlı Sebep Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-rest-report-reason-name-report"
                        data={restReportChartData}
                        dateFieldName="date"
                        selectorFieldByName="reasonName"
                        xAxesTitle="Sebepler"
                        xAxesText="Tarih"
                        yAxesText="Rapor Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} sm={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="İstirahat Bazlı Muayene Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-examination-examinationType-report"
                        data={restReportChartData}
                        dateFieldName="date"
                        selectorFieldByName="examinationTypeName"
                        xAxesTitle="Muayene Türleri"
                        xAxesText="Tarih"
                        yAxesText="Rapor Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}
          {formRef?.current?.value?.accident === true ? (
            <Card>
              <CardHeader className="text-center" title="   İş Kazası İş Gün Kayıpları" />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Şirket Bazlı Kaza Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-accident-company-report"
                        data={accidentChartData}
                        dateFieldName="date"
                        selectorFieldByName="companyName"
                        xAxesTitle="Şirketler"
                        xAxesText="Tarih"
                        yAxesText="Kaza Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="Departman Bazlı Kaza Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-accident-department-report"
                        data={accidentChartData}
                        dateFieldName="date"
                        selectorFieldByName="accidentDepartmentViews[].departmentName"
                        xAxesTitle="Departmanlar"
                        xAxesText="Tarih"
                        yAxesText="Kaza Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={{ padding: '10px', margin: '10px' }}>
                      <CardHeader title="İstasyon Bazlı Kaza Dağılımı" />{' '}
                      <DateGroupXYChart
                        chartName="lost-working-accident-station-report"
                        data={accidentChartData}
                        dateFieldName="date"
                        selectorFieldByName="accidentStationViews[].stationName"
                        xAxesTitle="İstasyonlar"
                        xAxesText="Tarih"
                        yAxesText="Kaza Sayısı"
                        dataDisplayOption={dataDisplayOption}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}
        </>
      )}
    </div>
  );
}
