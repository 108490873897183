/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProtocolNumber } from './ProtocolNumber';

export type NurseOperationProtocolRegistryView = {
    referenceTableId?: string;
    id?: string;
    protocolOperationType?: NurseOperationProtocolRegistryView.protocolOperationType;
    protocolNumber?: ProtocolNumber;
    employeeId?: string;
    createdDate?: string;
    avatarPath?: string;
    name?: string;
    age?: string;
    gender?: string;
    operation?: string;
    description?: string;
}

export namespace NurseOperationProtocolRegistryView {

    export enum protocolOperationType {
        EXAMINATION = 'EXAMINATION',
        REFERRAL = 'REFERRAL',
        REPORT = 'REPORT',
        ANALYSIS = 'ANALYSIS',
        DEFAULT_NURSE_OPERATION = 'DEFAULT_NURSE_OPERATION',
        VITAL_SIGN = 'VITAL_SIGN',
        BODY_MEASUREMENT = 'BODY_MEASUREMENT',
    }


}
