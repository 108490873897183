import { createContext } from 'react';

import { RiskAssessmentHazardDto } from '../../api/client';

export interface RiskAssessmentHazardContextType {
  riskAssessmentHazard: RiskAssessmentHazardDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  riskAssessmentId: string;

  riskAssessmentType: string;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: RiskAssessmentHazardDto;

  setSelectedData(data: RiskAssessmentHazardDto): void;
}

export const RiskAssessmentHazardContext = createContext<RiskAssessmentHazardContextType>(null);
