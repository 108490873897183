import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteVacccine, getVacccine, VaccineResult } from 'api/hs/definitions/health-definitions/vaccine';
import VaccineEditDialog from 'Common/Forms/Definitions/HealthDefinition/Vaccine/VaccineEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Vaccine() {
  return (
    <BaseDefinitionPage<VaccineResult>
      listLabelPropName="name"
      fetchData={getVacccine}
      FormDialog={VaccineEditDialog}
      onDelete={deleteVacccine}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
