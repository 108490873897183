import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { InjectionPayload } from './Injection.payload';
import { InjectionResult } from './Injection.result';

export async function getInjection(nurseOperationId: string): Promise<InjectionResult[]> {
  let res;
  try {
    res = await HSApi.get<InjectionResult[]>(`/nurse-operations/${nurseOperationId}/injections`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveInjection(id: string, body: InjectionPayload): Promise<InjectionResult> {
  const [err, res] = await tryCatch(HSApi.post(`/nurse-operations/${id}/injections`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateInjection({ id, ...body }: MakeRequired<Partial<InjectionResult>, 'id'>): Promise<InjectionResult> {
  const [error, res] = await tryCatch(HSApi.put<InjectionResult>(`/nurse-operations/injections/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteInjection(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/nurse-operations/injections/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
