export enum UserRole {
  /**
   * Doktor
   */
  PHYSICIAN = 'PHYSICIAN',

  /**
   * Hemşire
   */
  NURSE = 'NURSE',
  IT = 'IT',
  OSS = 'OSS',
  HR = 'HR',
  FIRM_ADMIN = 'FIRM_ADMIN',
  SYS_ADMIN = 'SYS_ADMIN',
}

const roleNames = new Map<UserRole, string>();

roleNames.set(UserRole.PHYSICIAN, 'HEKİM');
roleNames.set(UserRole.NURSE, 'HEMŞİRE');
roleNames.set(UserRole.IT, 'TEKNİK PERSONEL');
roleNames.set(UserRole.HR, 'İNSAN KAYNAKLARI');
roleNames.set(UserRole.OSS, 'İŞ GÜVENLİĞİ UZMANI');
roleNames.set(UserRole.FIRM_ADMIN, 'FİRMA YÖNETİCİSİ');
roleNames.set(UserRole.SYS_ADMIN, 'SİSTEM YÖNETİCİSİ');

const FullRoles = [UserRole.NURSE, UserRole.OSS, UserRole.PHYSICIAN, UserRole.HR, UserRole.IT, UserRole.FIRM_ADMIN, UserRole.SYS_ADMIN];

export { roleNames as RoleNames, FullRoles };
