import React from 'react';

import CommitteeMeetingsList from './List';
export default function Index() {
  return (
    <>
      <CommitteeMeetingsList />
    </>
  );
}
