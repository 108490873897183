import { List } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import { UserContext } from '../../../../context/user';
import { UserRole } from '../../../../model/user-role.enum';
import CommentForm from '../../EmployeeInfoBox/CommentForm';
import PersonalInfo from '../../EmployeeInfoBox/PersonalInfo';
import ProfessionalInfo from '../../EmployeeInfoBox/ProfessionalInfo';
import { EmployeeContext } from '../employee.context';
import Flags from './Flags';

export default function InfoDrawer() {
  const { update, employee } = useContext(EmployeeContext);
  const saveComment = useCallback(value => update({ id: employee.id, generalDescription: value }), [update, employee]);
  const { user } = useContext(UserContext);

  const TABS = [
    {
      Component: user ? <Flags key={'flag'} /> : <></>,
      visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      Component: <ProfessionalInfo key={'ProfessionalInfo'} employee={employee} />,
      visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      Component: <PersonalInfo key={'PersonalInfo'} employee={employee} />,
      visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      Component: <CommentForm key={'CommentForm'} value={employee.generalDescription} onSubmit={saveComment} />,
      visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    },
  ];

  return (
    <List component="nav">
      {TABS.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r))).map(({ Component, visibleTo }) => Component)}
    </List>
  );
}
