import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { AccidentControllerService } from '../../../../../api/client';
import { AccidentContentResult } from '../../../../../api/hs/accident';
import { accidentFileUpload } from '../../../../../api/hs/accident/accident';
import { HSTableContext } from '../../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../../context/dialog.context';
import { useDateFormat, useXhr } from '../../../../../utils';
import { AccidentsContext } from '../../accidents.context';

function AccidentComponent({ row }: { row: AccidentContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(AccidentsContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);
  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await AccidentControllerService.deleteAccident(id);
      await refresh();
    },
    'Seçili Kaza Kaydı Silindi',
    'Seçili Kaza Kaydı silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu kaza kaydını silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item.accidentId);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => accidentFileUpload(row?.accidentId, file)));
      await refresh();
    },
    'Kaza Dosyası yüklendi',
    'Dosya yüklenirken bir hata oluştu',
    [row, refresh]
  );

  const openFileUploadDialog = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({
      maxCount: 1,
      title: 'Kaza Dosyası Yükle',
    });

    if (!files) {
      return;
    }

    await uploadFilesReq(files);
  }, [openFileDialog, uploadFilesReq, closeMenu]);

  const routeAccidentDetails = useCallback(() => {
    history.push(`/accident/list/${row?.accidentId}`);
  }, [row, history]);

  return (
    <div key={row?.id}>
      <IconButton onClick={routeAccidentDetails} id={row?.id}>
        <VisibilityIcon />
      </IconButton>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => openFileUploadDialog(row)}>
          <ListItemIcon>
            <AttachmentIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Kaza Dosyası</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export const COLUMNS: TableColumn<AccidentContentResult>[] = [
  {
    id: 'companyName',
    width: '10%',
    label: 'Şirket',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'accidentDepartmentViews',
    width: '10%',
    label: 'Departman',
    defaultVisibility: true,
    sortable: true,
    render(row: AccidentContentResult): JSX.Element {
      return <div>{row.accidentDepartmentViews.map(x => x.departmentName).join(', ')}</div>;
    },
  },
  {
    id: 'accidentStationViews',
    width: '10%',
    label: 'İstasyon',
    defaultVisibility: true,
    sortable: true,
    render(row: AccidentContentResult): JSX.Element {
      return <div>{row.accidentStationViews.map(x => x.stationName).join(', ')}</div>;
    },
  },
  {
    id: 'description',
    width: '10%',
    label: 'Kaza Tanımı',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'shiftDescription',
    width: '10%',
    label: 'Vardiya',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'accidentReviewStatus',
    width: '10%',
    label: 'Kaza İnceleme',
    defaultVisibility: true,
    render(row: AccidentContentResult): JSX.Element {
      switch (row.accidentReviewStatus) {
        case 'COMPLETED':
          return <span>Tamamlandı</span>;

        case 'IN_PROGRESS':
          return <span>Devam Ediyor</span>;

        default:
          return <span>Yok</span>;
      }
    },
  },
  {
    id: 'userName',
    width: '10%',
    label: 'Oluşturan',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'date',
    width: '10%',
    label: 'Kaza Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: AccidentContentResult): JSX.Element {
      return <DateField date={new Date(row.date)} />;
    },
  },
  {
    id: 'createdDate',
    width: '10%',
    label: 'Girdi Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: AccidentContentResult): JSX.Element {
      return <DateField date={new Date(row.createdDate)} />;
    },
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '10%',
    render(row: AccidentContentResult): JSX.Element {
      return <AccidentComponent key={row?.id} row={row} />;
    },
  },
];
