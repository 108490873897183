import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { GetPpeStocksListPayload, PpeStocksFilters } from './get-ppe-stocks.payload';
import { PpeStocksContentResult } from './ppe-stocks-content.result';
import { PpeStocksPayload } from './ppe-stocks.payload';
import { PpeStocksResult } from './ppe-stocks.result';

export async function getPpeStocksList(page = 0, size = 20, order: null | string, filters: PpeStocksFilters): Promise<PpeStocksResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof PpeStocksFilters)[] = [
    'stockNumber',
    'expireDateMax',
    'expireDateMin',
    'productionDateMax',
    'productionDateMin',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.productionDateMax) {
    parsedFilters.productionDateMax = new Date(parsedFilters.productionDateMax).toISOString();
  }

  if (parsedFilters.productionDateMin) {
    parsedFilters.productionDateMin = new Date(parsedFilters.productionDateMin).toISOString();
  }

  if (parsedFilters.expireDateMin) {
    parsedFilters.expireDateMin = new Date(parsedFilters.expireDateMin).toISOString();
  }

  if (parsedFilters.expireDateMax) {
    parsedFilters.expireDateMax = new Date(parsedFilters.expireDateMax).toISOString();
  }

  const payload: GetPpeStocksListPayload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<PpeStocksResult>(`/ppe-definition/ppe-stocks/views`, payload);

  return res.data;
}

export async function deletePpeStocks(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe-stocks/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function savePpeStocks(body: PpeStocksPayload): Promise<PpeStocksContentResult> {
  const [err, res] = await tryCatch(HSApi.post('/ppe-stocks', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updatePpeStocks({ id, ...body }): Promise<PpeDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.patch<PpeStocksContentResult>(`/ppe-stocks/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
