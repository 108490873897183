import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { getPeriodicExaminationCounts, hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { DateRange, PeriodicExaminationCounts, WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DateRangeDialog from './DateRangeDialog';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }) => {
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const {
    examination: { open },
    examinationDateRange: { dateRange, days, setRangeByDays, setRange },
  } = useContext(DialogContext);

  const [counts, setCounts] = useState<PeriodicExaminationCounts>();
  useEffect(() => {
    setRangeByDays(7);
  }, [setRangeByDays]);

  useEffect(() => {
    if (dateRange) {
      getPeriodicExaminationCounts(new DateRange(dateRange.start, dateRange.end)).then(setCounts);
    }
  }, [dateRange]);

  const hide = () => {
    hideWidget({ widgetType: WidgetType.PERIODIC_EXAMINATION }).then(reload);
  };

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Card key="examination" className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.examination }}>
      <CardContent>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sm={10} onClick={open}>
            <h3>Periyodik Muayene : {counts?.periodicExamNearlied || 0}</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide}>
              <>
                {dateRangeOptions.map(dateRangeOption => (
                  <MenuItem key={dateRangeOption} onClick={() => setRangeByDays(dateRangeOption)}>
                    <ListItemIcon>{dateRangeOption === days && <Done fontSize="small" />}</ListItemIcon>
                    <ListItemText>{dateRangeOption} Gün</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setDialogVisibility(true)}>
                  <ListItemIcon>{days === undefined && <Done fontSize="small" />}</ListItemIcon>
                  <ListItemText>Diğer...</ListItemText>
                </MenuItem>
              </>
            </DropdownMenuContainer>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', flexDirection: 'column' }} container onClick={open}>
          <Divider />
          <Grid item sm={12}>
            <h3>Muayenesi olmayan : {counts?.periodicExamNotExist || 0}</h3>
          </Grid>
          <Grid item sm={12}>
            <p>Muayenesi Geciken : {counts?.periodicExamMissed || 0}</p>
          </Grid>
        </Grid>
      </CardContent>
      <DateRangeDialog close={() => setDialogVisibility(false)} opened={dialogVisibility} setRange={setRange} />
    </Card>
  );
};
