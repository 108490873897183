import { Card, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import HomeWorkSharpIcon from '@material-ui/icons/HomeWorkSharp';
import React from 'react';

import { CompanyResult, deleteCompany, getCompany } from 'api/hs/definitions/workplace-definition/company';
import CompanyEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Company/CompanyEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    maxWidth: 200,
  },
  titleText: {
    fontSize: '13px',
  },
  cardMargin: {
    marginBottom: '5px',
  },
  headerColored: {
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  textBold: {
    color: 'lightslategray',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    fontWeight: 'bold',
  },
  text: {
    color: 'lightslategray',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },

  header: {
    fontWeight: 'bold',
    color: 'lightslategray',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },

  fabButton: {
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: '67px',
    right: '10px',
  },
}));
function ItemTemplate({ item, Action }: ListItemTemplateProps<CompanyResult>) {
  const classes = useStyles();

  return (
    <>
      <Grid key={item.id} item md={12}>
        <Card variant="outlined" className={classes.cardMargin}>
          <List key={item.id}>
            <ListItem>
              <ListItemAvatar className={classes.text}>
                <HomeWorkSharpIcon fontSize="medium" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <div>
                  <span className={classes.headerColored}>{item.companyName}</span>
                  {` `}
                  <span className={classes.titleText}>({item.companyTitle})</span>
                </div>
                <div>
                  <span className={classes.header}>SGK Sicil No : </span>
                  <span className={classes.text}>{item.ssiId}</span>
                  {` `}

                  <span className={classes.header}>Vergi Dairesi : </span>
                  <span className={classes.text}>{item.taxOffice}</span>
                  {` `}
                  <span className={classes.header}>Şirket Kodu : </span>
                  <span className={classes.text}>{item.companyCode}</span>
                </div>
                <div>
                  <span className={classes.header}>Telefon : </span>
                  <span className={classes.text}>{item.phone}</span>
                  {` `}

                  <span className={classes.header}>Fax : </span>
                  <span className={classes.text}>{item.fax}</span>
                  {` `}

                  <span className={classes.header}>E-posta : </span>
                  <span className={classes.text}>{item.email}</span>
                </div>
                <div>
                  <span className={classes.header}>Adres : </span>
                  <span className={classes.text}>{item.address}</span>
                  {` `}
                </div>
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Card>
      </Grid>
    </>
  );
}

export default function Company() {
  return (
    <BaseDefinitionPage<CompanyResult>
      listLabelPropName="companyName"
      fetchData={getCompany}
      FormDialog={CompanyEditDialog}
      onDelete={deleteCompany}
      Icon={<HomeWorkSharpIcon />}
      ListItemTemplate={ItemTemplate}
      searchName="companyName"
    />
  );
}
