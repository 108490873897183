import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { noop, uniquify } from '@thalesrc/js-utils';
import React, { forwardRef, PropsWithChildren, Ref, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { useDialogState, useTranslate, useTriggerRender } from 'utils';

import AutoComplete from './AutoComplete';
import Form, { FormRef } from './Form';
import { SelectOption } from './Select';

interface Props<T, U extends {}> {
  name: string;
  options: SelectOption<symbol | T>[];
  label?: string;
  dialogLabel?: string;
  required?: boolean;
  adornment(openDialog: () => void): JSX.Element;
  onGenerate(id: symbol, data: U): void;
  onFormChange?(): void;
}

export interface GenerativeAutoCompleteRef<T extends Record<string, any> = {}> {
  formValue: T;
}

function GenerativeAutoComplete<T = any, U extends {} = any>(
  {
    name,
    options,
    label = '',
    dialogLabel = '',
    adornment,
    required = false,
    onGenerate,
    onFormChange = noop,
    children,
  }: PropsWithChildren<Props<T, U>>,
  componentRef: Ref<GenerativeAutoCompleteRef<U>>
) {
  const { open, close, opened } = useDialogState();
  const formRef = useRef<FormRef<U>>();
  const { setValue, getValues } = useFormContext();
  const reRender = useTriggerRender();
  const translate = useTranslate();

  const adornmentEl = useMemo(() => adornment(open), [adornment, open]);

  const handleSubmit = useCallback(() => {
    const id = Symbol();

    onGenerate(id, formRef.current.value);
    setValue(name, uniquify([...getValues(name), id]));
    close();
  }, [setValue, getValues, close, name, onGenerate]);

  useImperativeHandle(
    componentRef,
    () => ({
      formValue: formRef.current?.value,
    }),
    [formRef.current?.value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFormChange = useCallback(
    (value: any) => {
      reRender();
      onFormChange();
    },
    [reRender, onFormChange]
  );

  return (
    <>
      <AutoComplete<T | symbol> name={name} options={options} label={label} required={required} endAdornment={adornmentEl} />
      <Dialog open={opened} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle style={{ backgroundColor: '#2196f3', color: 'white' }}>{dialogLabel}</DialogTitle>
        <DialogContent>
          <Form ref={formRef} onChange={handleFormChange}>
            {children}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={!formRef.current?.valid}
            className="mr-1"
            onClick={handleSubmit}>
            {translate('Add')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default forwardRef(GenerativeAutoComplete);
