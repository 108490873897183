import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminVacccine, getAdminVacccine, VaccineResult } from 'api/hs/definitions/health-definitions/vaccine';
import VaccineAdminEditDialog from 'Common/Forms/Definitions/HealthDefinition/Vaccine/AdminVaccineEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminVaccine() {
  return (
    <BaseDefinitionPage<VaccineResult>
      listLabelPropName="name"
      fetchData={getAdminVacccine}
      FormDialog={VaccineAdminEditDialog}
      onDelete={deleteAdminVacccine}
      Icon={<DomainIcon />}
    />
  );
}
