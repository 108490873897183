import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem } from '@material-ui/core';
import MUISelect from '@material-ui/core/Select';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext, useWatch } from 'react-hook-form';

import { useTranslate } from 'utils';

import { FormContext } from './Form/form.context';

export type SelectRules = ControllerProps<'select'>['rules'];

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  menuPaper: {
    maxHeight: 250,
  },
  error: {
    position: 'absolute',
    bottom: 0,
  },
}));

export interface SelectOption<T> {
  text: string | JSX.Element;
  value: T;
  id?: string;
}

interface Props<T> {
  name: string;
  options: SelectOption<T>[];
  label?: string;
  defaultValue?: T;
  rules?: SelectRules;
  required?: boolean;
  emptyOption?: boolean | string;
  disabled?: boolean;
  onChangeListener?:(value:T)=>void;
}

const EMPTY_DEFAULT = Symbol();

export default function Select<T>({
  name,
  label = '',
  defaultValue = EMPTY_DEFAULT as any,
  options,
  rules = {},
  required = false,
  emptyOption = false,
  disabled = false,
  onChangeListener
}: Props<T>) {
  const classes = useStyles();
  const { control, trigger, errors } = useFormContext();
  const { readonly } = useContext(FormContext);
  const translate = useTranslate('form');

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  rules = useMemo<SelectRules>(
    () => ({
      ...rules,
      ...(!required
        ? null
        : {
            validate: {
              ...rules.validate,
              required: (value: T) => value !== null && value !== undefined && value !== ('' as any),
            },
          }),
    }),
    [required, rules]
  );

  return (
    <FormControl fullWidth error={!!errors[name]} required={required} className={classes.root}>
      <Controller
        rules={rules}
        control={control}
        defaultValue={defaultValue === (EMPTY_DEFAULT as any) ? '' : defaultValue}
        name={name}
        render={({ onChange, value }) => (
          <>
            <InputLabel shrink={!(value === null || value === '')}>{translate(label)}</InputLabel>
            <MUISelect
              value={value === null ? '' : value}
              disabled={notEditable}
              onChange={e => {
                onChange(e.target.value);
                if(onChangeListener) {
                  onChangeListener(e.target.value as T);
                }
                control.updateFormState({ touched: { ...control.formStateRef.current.touched, [name]: true } });
                trigger();
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}>
              {emptyOption && (
                <MenuItem value="">
                  <em>{typeof emptyOption === 'string' ? emptyOption : 'Hiçbiri'}</em>
                </MenuItem>
              )}
              {options.map(({ text, value: v }, index) => (
                <MenuItem value={v as any} key={index}>
                  {text}
                </MenuItem>
              ))}
            </MUISelect>
          </>
        )}
      />
      <FormHelperText className={classes.error}>{errors[name] ? translate('Errors.' + errors[name].type) : null}</FormHelperText>
    </FormControl>
  );
}
