import { Button, Container, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { AccidentControllerService, AccidentDto } from '../../../../api/client';
import { AlertContext } from '../../../../context/alert.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';

interface Props {
  data: AccidentDto;

  onClose?(success: boolean): void;
}

const AccidentRecordsForm = ({ data, onClose }: Props) => {
  const { companies, accidentTypes, shiftDefinitions, departments, workStations } = useContext(SelectOptionsContext);
  const formRef = useRef();

  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();
  const submit = useCallback(
    async values => {
      const payload = {
        accidentTypes: values?.accidentTypes.map((accidentType: string) => {
          return {
            accidentTypeDefinitionId: accidentType,
          };
        }),
        companyId: values?.companyId,
        accidentDepartments: values?.accidentDepartments.map((department: string) => {
          return {
            departmentId: department,
          };
        }),
        accidentStations: values?.accidentStations.map((station: string) => {
          return {
            stationId: station,
          };
        }),
        date: values?.date,
        shiftId: values?.shiftId,
        description: values?.description,
      };
      try {
        // @ts-ignore
        if (!data?.accidentId) {
          await AccidentControllerService.saveAccident(payload);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          // @ts-ignore
          await AccidentControllerService.partialUpdateAccident(data?.accidentId, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [showMessage, data, onClose]
  );
  useAsyncEffect(async () => {
    if (data) {
      await defer();
      formRef?.current?.methods?.setValue('companyId', data?.companyId);
      formRef?.current?.methods?.setValue('shiftId', data?.shiftId);
      formRef?.current?.methods?.setValue('description', data?.description);
      formRef?.current?.methods?.setValue('date', data?.date);

      formRef?.current?.methods?.setValue(
        'accidentStations',
        data?.accidentStationViews?.map(d => d?.stationId)
      );
      formRef?.current?.methods?.setValue(
        'accidentDepartments',
        data?.accidentDepartmentViews?.map(d => d?.departmentId)
      );
      formRef?.current?.methods?.setValue(
        'accidentTypes',
        data?.accidentTypeViews?.map(d => d.accidentTypeDefinitionId)
      );

      formRef?.current?.methods?.trigger();
    }
  }, [data]);

  return (
    <Container maxWidth={'lg'}>
      <Form onSubmit={submit} onChange={reRender} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item md={6} sm={12}>
            <AutoComplete single name="companyId" options={companies} label="Şirket" />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete name="accidentDepartments" options={departments} label="Departman" />
          </Grid>

          <Grid item md={6} sm={12}>
            <AutoComplete name="accidentStations" options={workStations} label="İstasyon" />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete single name="shiftId" options={shiftDefinitions} label="Vardiya" />
          </Grid>

          <Grid item md={6} sm={12}>
            <DateInput required type="date-time" name="date" label="Kaza Tarihi" />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete name="accidentTypes" options={accidentTypes} label="Kaza Türü" />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField name="description" label="Kaza Tanımı" rows={3} />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            {data?.id && (
              <Button variant="contained" type="button" onClick={() => onClose(false)}>
                Vazgeç
              </Button>
            )}
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default AccidentRecordsForm;
