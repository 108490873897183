import { tryCatch } from '@thalesrc/js-utils';
import axios from 'axios';

import HSApi from 'api/hs/HSApi';

import { ExaminationsPostModel } from '.';
import {
  getAuthenticate,
  getIBYS_URL,
  IbysTrainingDetailResult,
  SignIbysData,
  TrainingStatusResult
} from '../trainings/ibys-trainings';
import { ExaminationsPutModel } from './examinations.put.model';
import {MuayeneBilgiDto, MuayeneBilgiResponseDto} from "./ibys-examination";

export interface FizikselCalismaOrtami {
  id: string;
  kod: number;
  calisanMuayeneId: string;
}

interface CalismaSekli {
  calismaPozisyonu: number;
  calismaZamani: number;
  aracKullanimi: number;
}

interface CalisanMuayeneVerisi {
  calisanTcKimlikNo: number;
  raporTuru: string;
  calisanMeslegi: string;
  calismaSekli: CalismaSekli;
  isEkipmanlari: number[];
  raporTarihi: string;
  rontgenYapildiMi: false;
  isitmeTestiYapildiMi: false;
  solunumFonkTestiYapildiMi: false;
  kanTetkikiYapildiMi: false;
  idrarTetkikiYapildiMi: false;
  raporSonGecerlilikTarihi: string;
  sonucVeKanaat: string;
  fizikiCalismaOrtami: FizikselCalismaOrtami[];
  biyolojikEtkenOrtami: FizikselCalismaOrtami[];
  tozYonetmeligiOrtami: FizikselCalismaOrtami[];
  gurultuCalismaOrtami: FizikselCalismaOrtami[];
  kimyasalMaddeOrtamiEntity: FizikselCalismaOrtami[];
  havaCalismaOrtami: FizikselCalismaOrtami[];
  elektrikCalismaOrtami: FizikselCalismaOrtami[];
}

interface MuayeneKaydet {
  sgkTescilNo: string;
  isyeriHekimiTcKimlikNo: number;
  imzaliDeger: string;
  calisanMuayeneVerisi: CalisanMuayeneVerisi[];
}

export interface SaveExaminationPayload {
  muayeneKaydet: MuayeneKaydet;
}

export async function getExaminationById(examinationId: string): Promise<ExaminationsPostModel> {
  const [err, res] = await tryCatch(HSApi.get(`/examinations/${examinationId}/ibys-examination`));
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function putExaminations(examinationId: string, body: ExaminationsPutModel): Promise<ExaminationsPutModel> {
  const [err, res] = await tryCatch(HSApi.put(`/examinations/${examinationId}/ibys-examinations`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getExaminations(examinationId: string): Promise<ExaminationsPostModel> {
  const [err, res] = await tryCatch(HSApi.get(`/examinations/${examinationId}/ibys-examinations`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function xmlContentIbysExaminations(examinationId: string) {
  const [err, res] = await tryCatch(HSApi.get(`/ibys-examinations/${examinationId}/xml-contents`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

var rand = function() {
  return Math.random().toString(36).substr(2); // remove `0.`
};
var token = function() {
  return rand() + rand(); // to make it longer
};
export async function signIbysExaminations(payload: SignIbysData) {
  var token_ = token();
  var data_new = null;
  const data = await fetch('https://safe.artimetrik.net/action.php?token='+token_, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
      .then(response => {
        window.open('https://safe.artimetrik.net/ibys.php?token='+token_, "_blank", "noreferrer");
        data_new = fetch('https://safe.artimetrik.net/action.php?token='+token_, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({action: 'ibys_detay'}),
        })
            .then(response => {
              return response.json();
            })
            .then(d => {
              return d;
            })
            .catch(err => {
              console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
            });
      })
      .then(d => {
        return d;
      })
      .catch(err => {
        console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
      });
  return data_new;
}

export async function signIbysExaminationsOld(payload: SignIbysData) {
  return await fetch('http://localhost:12200/sign-ibys-data', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      return response.json();
    })
    .then(d => {
      return d;
    })
    .catch(err => {
      console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
    });
}

export async function saveExaminations(payload: MuayeneBilgiDto[]): Promise<MuayeneBilgiResponseDto[]> {
  const [err, res] = await tryCatch(
      axios.post<MuayeneBilgiResponseDto>('https://safe.artimetrik.net/action.php', payload)
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveExaminationsOld(payload: MuayeneBilgiDto[]): Promise<MuayeneBilgiResponseDto[]> {
  const URL_DATA = await getIBYS_URL();
  const TOKEN_DATA = await getAuthenticate(URL_DATA);
  const [err, res] = await tryCatch(
    axios.post<MuayeneBilgiResponseDto[]>(`${URL_DATA}/ibys/send-examination`, payload, {
    //axios.post<MuayeneBilgiResponseDto[]>(`http://localhost:9100/artimetrik-api/ibys/send-examination`, payload, {
      headers: { Authorization: 'Bearer ' + TOKEN_DATA.data },
    })
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }
  return res.data;
}

export async function updateExaminations(id: string, body: any) {
  const [error, res] = await tryCatch(HSApi.patch<IbysTrainingDetailResult>(`/ibys-examinations/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response;
  }

  return res.data;
}

export async function examinationGetList(ids: string[]) {
  const URL_DATA = await getIBYS_URL();
  const TOKEN_DATA = await getAuthenticate(URL_DATA);
  const [err, res] = await tryCatch(
    axios.get(`${URL_DATA}/ibys/examinations/status?ids=${ids}`, {
      headers: { Authorization: 'Bearer ' + TOKEN_DATA.data },
    })
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data.data;
}
