import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminReferral, getAdminReferral, ReferralResult } from 'api/hs/definitions/health-definitions/referral';
import AdminReferralEditDialog from 'Common/Forms/Definitions/HealthDefinition/Referral/AdminReferralEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminReferral() {
  return (
    <BaseDefinitionPage<ReferralResult>
      listLabelPropName="name"
      fetchData={getAdminReferral}
      FormDialog={AdminReferralEditDialog}
      onDelete={deleteAdminReferral}
      Icon={<DomainIcon />}
    />
  );
}
