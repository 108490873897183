import React from 'react';
import { Route, Switch } from 'react-router';

import Profile from './profilePage';

export default function TransactionsAndLists() {
  return (
    <Switch>
      <Route path="/profile">
        <Profile />
      </Route>
    </Switch>
  );
}
