import { createContext } from 'react';

import { FirmModel, FirmResult } from 'api/hs/definitions/firm-definitions/firms';

export interface FirmsContextType {
  firms: FirmResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: FirmModel;

  setSelectedData(data: FirmModel): void;
}

export const FirmsContext = createContext<FirmsContextType>(null);
