import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { BodyMeasurementsModel, BodyMeasurementsResult, getBodyMeasurements } from 'api/hs/examination/body-measurements';
import { dateSort, useAsyncEffect } from 'utils';

import { BodyMeasurementsContext } from './body-measurements.context';
import BodyMeasurements from './BodyMeasurement';

export default function Index() {
  const [bodyMeasurements, setBodyMeasurements] = useState<BodyMeasurementsResult[]>([]);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<BodyMeasurementsModel>(null);
  const { employeeId } = useParams<{ employeeId: string }>();

  const fetch = useCallback(async () => {
    const result = await getBodyMeasurements(employeeId);
    setBodyMeasurements(dateSort(result));
    setLoading(false);
  }, [employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <BodyMeasurementsContext.Provider
      value={{
        bodyMeasurements,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <BodyMeasurements />
    </BodyMeasurementsContext.Provider>
  );
}
