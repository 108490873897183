import React from 'react';

import TrainingMainGroupList from './List';
import TrainingMainGroupAdd from './TrainingMainGroupAdd';

export default function Index() {
  return (
    <>
      <TrainingMainGroupAdd />
      <TrainingMainGroupList />
    </>
  );
}
