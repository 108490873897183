import React, { useCallback, useContext } from 'react';

import { getRiskAssessmentHazardsList } from 'api/hs/risk-assessment';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import ActionDialog from '../../ActionDialog';
import { RiskAssessmentsListContext } from '../../risk-assessments-list.context';
import Toolbar from '../Toolbar';
import { COLUMNSFINE } from './FineKinneyColumns';
import ListTable from './ListTableData';
import { COLUMNSMATRIX } from './MatrixColumns';

export default function RiskAssessmentList() {
  const { riskAssessmentType } = useContext(RiskAssessmentsListContext);

  const fetchRiskAssessmentList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      return getRiskAssessmentHazardsList(
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
        extra.filters ?? { riskAssessmentType }
      );
    },
    [riskAssessmentType]
  );

  return (
    <div key={riskAssessmentType}>
      <HSTableAndPaginationAndTableContextProvider
        fetcher={fetchRiskAssessmentList}
        columns={riskAssessmentType === 'MATRIX' ? COLUMNSMATRIX : COLUMNSFINE}>
        <Toolbar />
        <ListTable />
        <ActionDialog />
      </HSTableAndPaginationAndTableContextProvider>
    </div>
  );
}
