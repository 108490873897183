import { createContext } from 'react';

import { PhysicalChecksModel } from 'api/hs/examination/physical-checks';

export interface PhysicalCheckContextType {
  physicalChecks: PhysicalChecksModel[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PhysicalChecksModel;

  setSelectedData(data: PhysicalChecksModel): void;
}

export const PhysicalCheckContext = createContext<PhysicalCheckContextType>(null);
