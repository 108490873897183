import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetPpeClassificationDefinitionsResult } from './get-ppe-classification-definitions.result';

export async function getPpeClassificationDefinitions(): Promise<GetPpeClassificationDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetPpeClassificationDefinitionsResult[]>(`firms/ppe/classification-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
