import HSApi from '../../HSApi';
import { ReferralListResult } from './referral-list.result';

export async function getReferralList(page = 0, size = 20, order: null | string): Promise<ReferralListResult> {
  const res = await HSApi.get<ReferralListResult>(`/referrals/view?page=${page}&size=${size}&order=${order ?? ''}`);

  return res.data;
}
export async function exportReferralList() {

  const res = await HSApi.get(`/referrals/view/export?type=EXCEL`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sevk-listesi.xlsx';
  a.click();
}