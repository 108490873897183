import { Divider, Icon, IconButton, List, ListItemAvatar, ListItemSecondaryAction } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { Fragment, useCallback, useContext } from 'react';

import { downlaodFile } from 'utils';
import { useXhr } from 'utils';

import { deleteImportedFiles } from '../../../../api/hs/trainings/PlannedCompleted/planned-completed';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { DialogContext } from '../../../../context/dialog.context';
import { TrainingDetailContext } from '../completed.context';

function ImportedFileTypeLabel(importedFileSource: string): string {
  switch (importedFileSource) {
    case 'TRAINING_PRETEST':
      return 'Ön Test Dosyası';
    case 'TRAINING_EXAM':
      return 'Son Test Dosyası';
    case 'TRAINING':
      return 'Eğitim Materyalleri Dosyası';
    default:
      return importedFileSource;
  }
}

export default function GeneralInfo() {
  const { trainingDetail, loading, reload } = useContext(TrainingDetailContext);
  const [openFilesInfo, setOpenFilesInfo] = React.useState(false);
  const { openWarnDialog } = useContext(DialogContext);

  const handleClick = () => {
    setOpenFilesInfo(!openFilesInfo);
  };

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteImportedFiles(id);
      await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon-file-document" />
        </ListItemIcon>
        <ListItemText primary="Eğitim Dokümanları" />
        {openFilesInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openFilesInfo} timeout="auto" unmountOnExit>
        <IndicatorWrapper loading={loading} data={trainingDetail?.files} errorMessage="Gösterilecek  Eğitim Dosyası Bulunamadı">
          <List className="pl-2 mt-2 ml-1" key="hs-analysisFileUploadList">
            {trainingDetail?.files?.map((item, index) => (
              <Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-account" />
                  </ListItemAvatar>
                  <ListItemText primary={item?.originalFileName} secondary={ImportedFileTypeLabel(item?.importedFileSource)} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                      edge="end"
                      aria-label="download">
                      <GetAppIcon />
                    </IconButton>
                    <IconButton onClick={() => removeFile(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </IndicatorWrapper>
      </Collapse>
    </>
  );
}
