import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { SystemSettingPayload } from './SystemSetting.payload';
import { SystemSettingResult } from './SystemSetting.result';

export async function getSystemSetting(): Promise<SystemSettingResult[]> {
  const [error, res] = await tryCatch(HSApi.get<SystemSettingResult[]>(`/firm/system-settings`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function searchSystemSetting(search: string): Promise<SystemSettingResult[]> {
  const [err, res] = await tryCatch(HSApi.get<SystemSettingResult[]>(`/firm/system-settings?search=${search}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveSystemSetting(body: SystemSettingPayload): Promise<SystemSettingResult> {
  const [err, res] = await tryCatch(HSApi.post('/firm/system-settings', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateSystemSetting({ id, ...body }: MakeRequired<Partial<SystemSettingResult>, 'id'>): Promise<SystemSettingResult> {
  const [error, res] = await tryCatch(HSApi.put<SystemSettingResult>(`/firm/system-settings/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteSystemSetting(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firm/system-settings/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
