import React, { useCallback, useContext } from 'react';

import MedicalInterventionForm from 'Common/Forms/Examination/MedicalInterventionForm';

import { MedicalInterventionContext } from '../../medical-intervention.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(MedicalInterventionContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <MedicalInterventionForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
