import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import MedicalInterventionForm from 'Common/Forms/Examination/MedicalInterventionForm';

import { MedicalInterventionContext } from '../../medical-intervention.context';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(MedicalInterventionContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={onClick} className="mt-1">
        Tıbbi Müdahale Ekle
      </Button>

      <MedicalInterventionForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
