import React, { PropsWithChildren } from 'react';

import AlertInformation from './AlertInformation';
import LoadingWrapper from './LoadingWrapper';

export interface IndicationWrapperProps {
  loading: boolean;
  data: {} | any[];
  errorMessage: string;
  errorChildren?: JSX.Element;
}

export default function IndicatorWrapper({
  data,
  loading,
  children,
  errorMessage,
  errorChildren = null,
}: PropsWithChildren<IndicationWrapperProps>) {
  return (
    <LoadingWrapper loading={loading}>
      {(data instanceof Array ? data.length : !!data) ? (
        children
      ) : (
        <>
          <AlertInformation severity="error" message={errorMessage} />
          {errorChildren}
        </>
      )}
    </LoadingWrapper>
  );
}
