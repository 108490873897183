import { makeStyles, Tab, TabProps, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { IS_MACOS } from 'model';

import { UserRole } from '../model/user-role.enum';
import { LicenceModule } from "../model/licence-module.enum";

const useStyles = makeStyles(theme => ({
  tabs: {
    minHeight: 'initial',
    alignSelf: 'flex-end',
    maxWidth: '100%',
  },
  'tabs-default': {},
  'tabs-toolbar': {
    '& .MuiTabs-scroller': {
      paddingBottom: theme.spacing(1),
    },

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.white,
      height: 1,
      opacity: 0.75,
      bottom: theme.spacing(0.5),
    },
  },
  'tab-default': {
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  },
  'tab-toolbar': {
    padding: `0 ${theme.spacing(2)}px`,
    minHeight: 'initial',
    fontSize: 'medium',
    whiteSpace: 'nowrap',
    maxWidth: 'initial',

    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      alignItems: 'flex-end',

      '& > i': {
        marginRight: theme.spacing(1),
      },
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
  },
}));

export interface TabModel {
  path: string;
  label?: string;
  icon?: TabProps['icon'];
  visibleTo?: UserRole[];
  activeTo?: LicenceModule[];
}

export interface RouterTabsProps {
  rootPath: string;
  tabs: TabModel[];
  className?: string;
  variant?: 'default' | 'toolbar';
}

export default function RouterTabs({ rootPath, tabs = [], className = '', variant = 'default' }: RouterTabsProps) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('');
  const { push } = useHistory();
  const { pathname } = useLocation();
  const tabRef = useRef<Element>();
  const actions = useRef<{ updateIndicator(): void; updateScrollButtons(): void }>();

  const changeTab = useCallback((_, path) => push(`${rootPath}/${path}`), [rootPath, push]);
  const getScroller = useCallback<() => HTMLDivElement>(() => tabRef.current.querySelector('.MuiTabs-scroller'), []);

  const tabsVariantClassName = useMemo(() => (classes as any)['tabs-' + variant], [variant, classes]);
  const tabVariantClassName = useMemo(() => (classes as any)['tab-' + variant], [variant, classes]);
  const textColor = useMemo(() => (variant === 'default' ? 'primary' : 'inherit'), [variant]);

  useEffect(() => {
    const path = pathname.split(rootPath)[1].replace('/', '').split('/')[0];
    const scroller = getScroller();
    const button = scroller.querySelector<HTMLButtonElement>(`[data-value${path ? '="' + path + '"' : ''}]`);

    setActiveTab(path);

    scroller.scrollTo({ left: button?.offsetLeft, behavior: 'smooth' });

    actions.current.updateIndicator();
    actions.current.updateScrollButtons();
  }, [pathname, rootPath, getScroller]);

  useEffect(() => {
    if (IS_MACOS) {
      return;
    }

    const scroller = getScroller();

    function handler(event: WheelEvent) {
      scroller.scrollTo({ left: scroller.scrollLeft + event.deltaY });
    }

    scroller.addEventListener('wheel', handler);

    return () => scroller.removeEventListener('wheel', handler);
  }, [getScroller]);

  return (
    <Tabs
      value={activeTab}
      onChange={changeTab}
      variant="scrollable"
      className={`${classes.tabs} ${tabsVariantClassName} ${className}`}
      indicatorColor="primary"
      textColor={textColor}
      ref={tabRef as any}
      action={actions as any}>
      {tabs
        .filter(item => !!item.label) // label yoksa gostermez. path variable varsa bu kullanilabilir
        .map(({ path, icon = null, label = '' }) => (
          <Tab key={path} className={tabVariantClassName} value={path} label={label} icon={icon} data-value={path} />
        ))}
    </Tabs>
  );
}
