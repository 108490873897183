import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { TypeDefinitionsContext } from '../../type-definitions.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ReferralsList() {
  const { loading, typeDefinitions } = useContext(TypeDefinitionsContext);

  return (
    <IndicatorWrapper loading={loading} data={typeDefinitions} errorMessage="Kayıt Bulunamadı.">
      <List id="listReferrals">
        {typeDefinitions?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <Icon className="icon-dna" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText
                  primary={item?.label}
                  secondary={
                    <>
                      <Typography style={{ display: 'block' }} component="strong">
                        {item?.isSerialNumberMandatory ? 'Seri Numarası Zorunlu' : 'Seri Numarası Zorunlu Değil'} {` `}
                      </Typography>
                    </>
                  }
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
