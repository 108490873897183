import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useContext } from 'react';

import { deleteFirmUsers } from 'api/hs/definitions/firm-definitions/firm-users';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { userRoleArrToStr } from 'Common/RoleUtils';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { FirmUsersContext } from '../../firm-users.context';
import EditDialog from './EditDialog';
import SelectFirms from './SelectFirms';

export default function ListFirmSubscriptions() {
  const { firmUsers, loading, setEditDialogOpened, setSelectedData, reload } = useContext(FirmUsersContext);
  const { showMessage } = useContext(AlertContext);
  const { openWarnDialog } = useContext(DialogContext);

  const openDeleteDialog = useCallback(
    async data => {
      const submitted = await openWarnDialog({
        text: `${data?.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await deleteFirmUsers(data.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  return (
    <div className="m-2">
      <SelectFirms />
      <IndicatorWrapper loading={loading} data={firmUsers} errorMessage="Gösterilecek Kullanıcı Bilgisi Bulunamadı.">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="25%" align="left">
                  Adı
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  E-mail
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  Role
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="10%" align="left">
                  Durum
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="35%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firmUsers.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{item?.email}</TableCell>
                  <TableCell align="left">{userRoleArrToStr(item?.roles)}</TableCell>
                  <TableCell align="left">
                    {item?.status ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => openDeleteDialog(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
