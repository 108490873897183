import React, { useState } from 'react';

import { PpeDefinitionsContentResult } from 'api/hs/ppe/ppe-definitions';

import { PpeDefinitionsContext } from './ppe-definitions.context';
import PpeDefinition from './PpeDefinitions';

export default function Index() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeDefinitionsContentResult>(null);

  return (
    <PpeDefinitionsContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <PpeDefinition />
    </PpeDefinitionsContext.Provider>
  );
}
