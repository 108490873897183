import { Dialog, DialogActions, DialogTitle, IconButton, List, ListItem, ListItemIcon, Popover, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { uploadFromExcel, exportEmployee } from 'api/hs/employee';
import Button from 'Common/Button';
import EditEmployeeDialog from 'Common/EditEmployeeDialog';
import { DialogContext } from 'context/dialog.context';
import { useDialogState, useXhr } from 'utils';

import { EmployeeListContext } from '../employee-list.context';

const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;

export default function MoreMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [editEmployeeOpened, setEditEmployeeOpened] = useState(false);
  const { reload } = useContext(EmployeeListContext);
  const { open, close, opened } = useDialogState();
  const { openFileDialog } = useContext(DialogContext);

  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const openEmployeeDialog = useCallback(() => setEditEmployeeOpened(true), []);
  const closeEmployeeDialog = useCallback(
    success => {
      setEditEmployeeOpened(false);

      if (success) {
        reload();
      }
    },
    [reload]
  );

  const exportFile = useCallback(async () => {
    await exportEmployee();
    closePopover();
  }, [closePopover]);
  const downloadTemplate = useCallback(() => window.open(`${BASE_URL}/employees/view/import/template?type=EXCEL`), []);

  const uploadFromFileReq = useXhr(
    async (file: File) => {
      await uploadFromExcel(file);
      reload();
    },
    'Personeller dosyadan yüklendi',
    error => (
      <>
        <div>Personeller dosyadan yükenirken bir hata oluştu</div>
        <div className="mt-2">{error?.data?.message}</div>
      </>
    ),
    [reload]
  );

  const uploadFromFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Personel Dosyası Seç', accept: ['.xlsx'] });

    if (!files) {
      return;
    }

    await uploadFromFileReq(files[0]);
    close();
  }, [uploadFromFileReq, close, openFileDialog]);

  useEffect(() => {
    if (editEmployeeOpened || opened) {
      setAnchorEl(null);
    }
  }, [editEmployeeOpened, opened]);

  return (
    <>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle className="text-center pt-4">Dosyadan Personel Yükle</DialogTitle>
        <DialogActions>
          <div className="text-center py-4 width-100">
            <Button color="secondary" onClick={downloadTemplate} className="mr-4">
              Şablonu İndir
            </Button>
            <Button variant="contained" color="primary" onClick={uploadFromFile}>
              Dosya Seç
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <IconButton color="inherit" onClick={openPopover}>
        <MoreIcon />
      </IconButton>
      <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -175 }} keepMounted open={!!anchorEl} onClose={closePopover}>
        <List>
          <ListItem onClick={openEmployeeDialog} className="cursor-pointer">
            <ListItemIcon>
              <PersonAddIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Yeni Personel Ekle</Typography>
          </ListItem>
          <ListItem onClick={open} className="cursor-pointer">
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Dosyadan Personel Yükle</Typography>
          </ListItem>
          <ListItem onClick={exportFile} className="cursor-pointer">
            <ListItemIcon>
              <PublishIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Personelleri Dışarı Aktar</Typography>
          </ListItem>
        </List>
      </Popover>
      <EditEmployeeDialog open={editEmployeeOpened} onClose={closeEmployeeDialog} employee={null} />
    </>
  );
}
