import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { savePolyclinicExamOpinions } from 'api/hs/examination/polyclinic-exam-opinions';
import Button from 'Common/Button';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';
import { useAsyncEffect, useXhr } from 'utils';

import { PolyclinicExamOpinionContext } from '../../polyclinic-exam-opinions.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface OptionExtra {
  color: string;
}

const DEFAULT_COMPLAINT_ID = 2;

const OPTIONS: (OptionExtra & SelectOption<number | string>)[] = [
  { text: 'Sevk Edildi', value: 'REFERRED', color: '#469789' },
  { text: 'İş Başı Yaptı', value: 'WORKING', color: '#2196f3' },
  { text: 'Rapor Verildi', value: 'REST_REPORTED', color: '#eb4355' },
];

export default function FormDialogPolyclinicExamOpinions() {
  const classes = useStyles();
  const { editDialogOpened, setEditDialogOpened, polyclinicExamOpinions, reload } = useContext(PolyclinicExamOpinionContext);
  const { setValue, getValues, trigger, ...methods } = useForm();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const cancel = useCallback(() => {
    setEditDialogOpened(false);
  }, [setEditDialogOpened]);

  const submitForm = useXhr(
    async values => {
      await savePolyclinicExamOpinions({
        examinationId,
        employeeId,
        description: values.description,
        polyclinicExamOpinionDefinition: values.polyclinicExamOpinionDefinition,
      });
      await reload();
      setEditDialogOpened(false);
    },
    'Kanaat Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [examinationId, reload]
  );

  useAsyncEffect(async () => {
    if (polyclinicExamOpinions) {
      await defer();
      setValue('polyclinicExamOpinionDefinition', polyclinicExamOpinions?.polyclinicExamOpinionDefinition);
      setValue('description', polyclinicExamOpinions?.description);

      trigger();
    }
  }, [polyclinicExamOpinions, setValue, trigger]);

  return (
    <MUIDialog open={editDialogOpened} fullWidth maxWidth="sm" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Kanaat Ekle / Düzenle</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, getValues, setValue, trigger }}>
          <form id="edit-polyclinic-exam-opinions-form" onSubmit={methods.handleSubmit(submitForm)}>
            <Grid container>
              <Grid item md={12} sm={12} lg={12}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow key={polyclinicExamOpinions?.id}>
                        <TableCell style={{ width: '10%', color: 'gray' }} align="left">
                          Kanaat
                        </TableCell>
                        <TableCell style={{ width: '90%' }} align="right">
                          <ToggleButtonGroup<number, OptionExtra>
                            name="polyclinicExamOpinionDefinition"
                            options={OPTIONS}
                            defaultValue={DEFAULT_COMPLAINT_ID}
                            renderOption={({ color, selected, text, value }) => (
                              <ToggleButton
                                value={value}
                                className={selected ? 'text-white' : ''}
                                style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                                {text}
                              </ToggleButton>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Açıklama" />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right p-4">
        <Button variant="contained" onClick={cancel}>
          Vazgeç
        </Button>
        <Button variant="contained" form="edit-polyclinic-exam-opinions-form" type="submit" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
