import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { useCallback, useContext, useState } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import CommonToolbar from 'Common/Toolbar';

import { exportPpeOutputsList } from '../../../../api/hs/ppe/ppe-outputs';
import { PpeOutputsContext } from '../../ppe-outputs.context';
import NewOutputDialog from '../newOutputForm';
import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function Toolbar() {
  const classes = useStyles();
  const { rawData, extraArgs } = useContext(HSTableContext);
  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setNewDialogOpened } = useContext(PpeOutputsContext);

  const newDialogShow = useCallback(() => {
    closePopover();
    setNewDialogOpened(true);
  }, [setNewDialogOpened, closePopover]);

  const exportFile = useCallback(async () => {
    await exportPpeOutputsList(extraArgs.filters);
    closePopover();
  }, [closePopover, extraArgs]);

  return (
    <CommonToolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Zimmet Listesi ( {rawData?.totalElements} )
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={openPopover}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -175 }} keepMounted open={!!anchorEl} onClose={closePopover}>
          <List>
            <ListItem onClick={newDialogShow} className="cursor-pointer">
              <ListItemIcon>
                <PostAddIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Yeni KKD Zimmet</Typography>
            </ListItem>
            <ListItem onClick={exportFile} className="cursor-pointer">
              <ListItemIcon>
                <GetAppIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Excel'e Aktar</Typography>
            </ListItem>
          </List>
        </Popover>
        <VisibleColumnMenu />
        <Filters />
        <NewOutputDialog />
      </div>
    </CommonToolbar>
  );
}
