import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { AudiometryResult } from './audiometry.result';

export async function getAudiometry(id: string): Promise<AudiometryResult> {
  const [error, res] = await tryCatch(HSApi.get(`/analysis/${id}/audio-details`));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res?.data;
}

export async function saveAudiometry({
  analysisId,
  ...payload
}: MakeRequired<Partial<AudiometryResult>, 'analysisId'>): Promise<AudiometryResult> {
  const [error, res] = await tryCatch(HSApi.post(`/analysis/${analysisId}/audio-details`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res?.data;
}

export async function updateAudiometry({
  analysisId,
  ...payload
}: MakeRequired<Partial<AudiometryResult>, 'analysisId'>): Promise<AudiometryResult> {
  const [error, res] = await tryCatch(HSApi.put(`/analysis/${analysisId}/audio-details`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res?.data;
}
