/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PpeOutputDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    outputDate?: string;
    employeeId?: string;
    firmId?: string;
    ppeDefinitionId?: string;
    outputGroupId?: string;
    refundDate?: string;
    reason?: PpeOutputDto.reason;
    stockId?: string;
    count?: number;
    refund?: boolean;
}

export namespace PpeOutputDto {

    export enum reason {
        DEBIT = 'DEBIT',
        REFUND = 'REFUND',
        EXPIRED = 'EXPIRED',
        DEFECTIVE = 'DEFECTIVE',
        BROKEN = 'BROKEN',
    }


}
