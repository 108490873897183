import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';

import { DefaultNurseOperationContext } from '../../nurse.operation.context';

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
}));
export default function Add() {
  const classes = useStyles();
  const { setNewDialogOpened } = useContext(DefaultNurseOperationContext);

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Fab onClick={onClick} color="primary" className={classes.absolute}>
        <AddIcon />
      </Fab>
    </>
  );
}
