import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { EmployeeView, PageEmployeeView } from '../../../../api/client';
import Avatar from '../../../../TransactionsAndLists/EmployeeList/Avatar';

interface Props {
  trainings: EmployeeView[];
}

export default ({ trainings }: Props) => {
  if (!trainings || trainings.length === 0) return <b>Kayıt bulunamadı!</b>;
  const history = useHistory();

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}/education`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold" />
            <TableCell className="text-bold">Adı Soyadı</TableCell>
            <TableCell className="text-bold">Şirket</TableCell>
            <TableCell className="text-bold">Departman</TableCell>
            <TableCell className="text-bold">İş İstasyonu</TableCell>
            <TableCell className="text-bold">Görevi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trainings.map((training, index) => (
            <TableRow key={index} hover onClick={() => handleClick(training.id)}>
              <TableCell scope="row">
                <Avatar url={training?.avatarPath} />
              </TableCell>
              <TableCell>{training.name}</TableCell>
              <TableCell>{training.companyName}</TableCell>
              <TableCell>{training.departmentName}</TableCell>
              <TableCell>{training.stationName}</TableCell>
              <TableCell>{training.positionName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
