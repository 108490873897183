import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { EnumContext } from 'context/enum.context';
import { ResourceContext } from 'context/resource.context';

import { dateDisplayFormat } from '../../../../../../utils';
import { ReferralsContextContext } from '../../referrals.context';
import Action from './Action';
import EditDialog from './EditDialog';
import EpostaDialog from './EpostaDialog';
import PreviewDialog from './PreviewDialog';

export default function ReferralsList() {
  const { loading, referrals } = useContext(ReferralsContextContext);
  const { referralSection } = useContext(ResourceContext);
  const { hospitalTypes } = useContext(EnumContext);

  return (
    <IndicatorWrapper loading={loading} data={referrals} errorMessage="Henüz Bir Sevk Eklenmemiş.">
      <List id="listReferrals">
        {referrals?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <Icon className="icon-dna" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText
                  primary={
                    item.hospitalTypeDefinitionId
                      ? findByKey(hospitalTypes, 'id', item.hospitalTypeDefinitionId)?.label +
                        ' - ' +
                        findByKey(referralSection, 'id', item.referralSectionDefinitionId)?.name
                      : findByKey(referralSection, 'id', item.referralSectionDefinitionId)?.name
                  }
                  secondary={
                    <>
                      <Typography style={{ display: 'block' }} component="strong">
                        {item?.description} {` `}
                      </Typography>
                      <Typography component="span">{dateDisplayFormat(item?.referralDate)}</Typography>
                    </>
                  }
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
      <EpostaDialog />
      <PreviewDialog />
    </IndicatorWrapper>
  );
}
