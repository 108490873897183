import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import React from 'react';

import { EventPayload } from '../../../api/hs/dashboard/event/event.payload';
import ColorPicker from '../../../Common/Form/ColorPicker';
import DateInput from '../../../Common/Form/DateInput';
import Switch from '../../../Common/Form/Switch';
import TextField from '../../../Common/Form/TextField';

interface Props {
  event: EventPayload;
  wholeDay: boolean;
}

export default (props: Props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField name="title" label="Title" required defaultValue={props.event.title} />
      </Grid>
      <Grid item xs={12} sm={6} className="pr-sm-1">
        <DateInput type="date" name="startDate" label="Start Date" required defaultValue={props.event?.startDate} />
      </Grid>
      <Grid item xs={12} sm={6} className="pl-sm-1">
        <DateInput type="date" name="endDate" label="End Date" required defaultValue={props.event?.endDate} />
      </Grid>
      <Grid item xs={12} sm={3} className="pr-sm-1">
        <Switch name="wholeDay" label="Whole Day" className="mt-sm-2 mb-1 mb-sm-0" defaultValue={props.wholeDay} />
      </Grid>
      <Grid item xs={12} sm={3} className="pr-sm-1">
        <DateInput
          type="time"
          name="startTime"
          label="Start Time"
          disabled={props.wholeDay}
          required={!props.wholeDay}
          defaultValue={props.event?.startTime}
        />
      </Grid>
      <Grid item xs={12} sm={3} className="pl-sm-1">
        <DateInput
          type="time"
          name="endTime"
          label="End Time"
          disabled={props.wholeDay}
          required={!props.wholeDay}
          defaultValue={props.event?.endTime}
        />
      </Grid>
      <Grid item xs={12} sm={3} className="pl-sm-1">
        <ColorPicker name="color" label="Etkinlik Rengi" defaultValue={props.event.color} />
      </Grid>
      <Grid item xs={12} className="pr-sm-1">
        <Switch
          name="public"
          defaultValue={props.event?.isPublic}
          label={
            <>
              <Typography>
                Genel Görünürlük
                <Tooltip
                  title="Bu etkinliği herkese açık yaparsanız bu takvime erişimi olan herkes, bu etkinliğin tüm ayrıntılarını görebilir."
                  placement="top">
                  <IconButton size="small" className="ml-1">
                    <HelpOutline />
                  </IconButton>
                </Tooltip>
              </Typography>
            </>
          }
          className="mt-sm-2 mb-1 mb-sm-0"
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextField rows={3} name="description" label="Description" defaultValue={props.event.description} />
      </Grid>
    </Grid>
  );
};
