import { createContext } from 'react';

import { TrainingDetailResult } from 'api/hs/trainings';

export interface TrainingDetailContextType {
  trainingDetail: TrainingDetailResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;
}

export const TrainingDetailContext = createContext<TrainingDetailContextType>(null);
