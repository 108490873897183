import { Chip } from '@material-ui/core';
import { remove } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';

import { EmployeeSelectionCacheContext, EmployeeSelectionContext } from '../employee-selection.context';

export default function Box() {
  const { getValues, setValue, trigger } = useFormContext();
  const { name } = useContext(EmployeeSelectionContext);
  const { avatars, names } = useContext(EmployeeSelectionCacheContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const values: string[] = getValues(name) ?? [];

  const handleDelete = useCallback(
    (employee: string) => () => {
      setValue(name, remove(values, employee));
      trigger();
    },
    [setValue, name, values, trigger]
  );

  return (
    <div>
      {values.map(value => (
        <Chip
          key={value}
          avatar={<Avatar url={avatars[value]} />}
          label={names[value]}
          onDelete={handleDelete(value)}
          className={`mr-2 mb-1`}
        />
      ))}
    </div>
  );
}
