import React from 'react';
import { Route, Switch } from 'react-router';

import ExaminationList from './ExaminationList';
import NurseOperationList from './NurseOperationList';
import ProtocolsNurseOperationList from './ProtocolsNurseOperationList';
import ProtocolsPhysicianList from './ProtocolsPhysicianList';
import ReferralList from './ReferralList';
import ReportList from './ReportList';
import VaccineList from './VaccineList';

export default function TransactionsAndLists() {
  return (
    <Switch>
      <Route path="/transactions/examination">
        <ExaminationList />
      </Route>
      <Route path="/transactions/nurse-operation">
        <NurseOperationList />
      </Route>
      <Route path="/transactions/vaccine">
        <VaccineList />
      </Route>
      <Route path="/transactions/report">
        <ReportList />
      </Route>
      <Route path="/transactions/referral">
        <ReferralList />
      </Route>
      <Route path="/transactions/protocols/registries/physician">
        <ProtocolsPhysicianList />
      </Route>
      <Route path="/transactions/protocols/registries/nurse-operation">
        <ProtocolsNurseOperationList />
      </Route>
    </Switch>
  );
}
