import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { CommitteeMembersPayload } from './committee-members.payload';
import { CommitteeMembersResult } from './committee-members.result';

export async function getCommitteeMembers(): Promise<unknown[]> {
  const [err, res] = await tryCatch(HSApi.get(`committee-members`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return await asyncMap(res.data, async item => ({
    ...item,
    file: (await getFileImportedFile(item?.id)) ?? null,
  }));
}

export async function saveCommitteeMembers(body: CommitteeMembersPayload): Promise<CommitteeMembersResult> {
  const [err, res] = await tryCatch(HSApi.post(`committee-members`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function patchCommitteeMembers(id: string, body: CommitteeMembersPayload): Promise<CommitteeMembersResult> {
  const [error, res] = await tryCatch(HSApi.patch<CommitteeMembersResult>(`/committee-members/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteCommitteeMembers(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/committee-members/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getFileImportedFile(id: string) {
  const [err, res] = await tryCatch(HSApi.get(`/committee-members/${id}/imported-files`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveFileImportedFile(id: string, file: File) {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/committee-members/${id}/imported-files`, data));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
    throw err.response;
  }

  return res.data;
}

export async function deleteFileImportedFile(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/committee-meetings/imported-files/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
