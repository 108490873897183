import { Button, FormControl, FormHelperText, Input } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { resetPassword } from 'api/hs/authentication/reset-password';
import { AlertContext } from 'context/alert.context';

import InputLabel from './InputLabel';
import Layout from './Layout';

export default function NewPassword() {
  const validPassword = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])');
  const { register, errors, trigger, handleSubmit, getValues } = useForm();
  const location = useLocation();
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();

  async function handleResetPassword({ password }: { password: string }) {
    try {
      const resetPassId = new URLSearchParams(location.search).get('resetPasswordId');
      const payload = {
        resetPasswordType: 'NEW_USER',
        newPassword: password,
      };
      await resetPassword(resetPassId, payload);
      showMessage(
        `Şifreniz ${payload?.resetPasswordType === 'NEW_USER' ? 'oluşturuldu' : 'güncellendi '}, lütfen tekrar giriş yapın'`,
        'success'
      );
      history.push('/');
    } catch (error) {
      showMessage('Bir hata oluştu', 'error', 'info');
    }
  }

  function validate() {
    trigger();
  }

  return (
    <Layout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleResetPassword)}>
        <FormControl fullWidth className="mb-2" error={!!errors.password}>
          <InputLabel htmlFor="password">
            <VpnKeyIcon className="mr-2" />
            Şifre Giriniz
          </InputLabel>
          <Input
            id="password"
            name="password"
            type="password"
            className="mt-3"
            inputRef={register({
              required: 'Bu alan zorunlu',
              validate: value => (validPassword.test(value) ? (getValues('password') === value ? true : 'Şifreler eşleşmiyor') : 'Şifreniz en az 1 harf ve 1 rakam içermelidir'),
            })}
            onChange={validate}
          />
          <FormHelperText>{errors.password?.message}</FormHelperText>
        </FormControl>
        <FormControl fullWidth className="mb-2" error={!!errors.repeatPassword}>
          <InputLabel htmlFor="repeatPassword">
            <VpnKeyIcon className="mr-2" />
            Şifreyi Doğrula
          </InputLabel>
          <Input
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            className="mt-3"
            inputRef={register({
              required: 'Bu alan zorunlu',
              validate: value => (validPassword.test(value) ? (getValues('password') === value ? true : 'Şifreler eşleşmiyor') : 'Şifreniz en az 1 harf ve 1 rakam içermelidir'),
            })}
            onChange={validate}
          />
          <FormHelperText>{errors.repeatPassword?.message}</FormHelperText>
        </FormControl>
        <footer className="text-center">
          <div className="mb-2">
            <Button variant="contained" color="primary" className="px-4" disabled={!!Object.keys(errors).length} type="submit">
              ŞİFRE OLUŞTUR
            </Button>
          </div>
          <div>
            <Link to="/" className="ml-1">
              Giriş Sayfası »
            </Link>
          </div>
        </footer>
      </form>
    </Layout>
  );
}
