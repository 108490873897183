import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormContext } from './Form/form.context';

interface Props {
  name: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: any;
}

export default function Checkbox({ name, label, disabled = false, className = '', onClick, checked = true }: Props) {
  const { trigger } = useFormContext();
  const { readonly } = useContext(FormContext);
  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const handleChange = useCallback(
    (onChange: (value: boolean) => void) => (e: ChangeEvent, value: boolean) => {
      if (notEditable) {
        return;
      }

      onChange(value);
      trigger();
    },
    [trigger, notEditable]
  );

  const renderCheckbox = useCallback(
    ({ onChange, value }) => <MUICheckbox checked={value} value={value} onChange={handleChange(onChange)} disabled={notEditable} />,
    [handleChange, notEditable]
  );

  return (
    <FormControlLabel
      onClick={onClick}
      control={<Controller defaultValue={false} name={name} render={renderCheckbox} />}
      label={label}
      className={className}
    />
  );
}
