import React, { useCallback, useContext, useState } from 'react';

import BlockSearchBar from 'Common/SearchBar/BlockSearchBar';

import { SystemSettingListContext } from '../../system-setting.context';

export default function Index() {
  const [defaultValue] = useState('');
  const { search } = useContext(SystemSettingListContext);

  const updateSearchKeyword = useCallback((value: string) => search(value), [search]);

  return (
    <div style={{ padding: '5px' }}>
      <BlockSearchBar value={defaultValue} onChange={updateSearchKeyword} placeholder="Anahtar Giriniz..." />
    </div>
  );
}
