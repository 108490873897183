import { createContext } from 'react';

import { Employee } from 'model';

export interface EmployeeContextType {
  employee: Employee;
  infoDrawerOpened: boolean;
  setInfoDrawerOpened(opened: boolean): void;
  update(data: Partial<Employee> & { id: Employee['id'] }): Promise<void>;
  refresh(): Promise<void>;
}

export const EmployeeContext = createContext<EmployeeContextType>(null);
