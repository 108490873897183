import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext, useState } from 'react';

import { RiskAssessmentHazardControllerService } from 'api/client';
import Button from 'Common/Button';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';
import { downlaodFile, openFile, useFetch, useXhr } from 'utils';

import { riskAssessmentFileUpload } from '../../../api/hs/risk-assessment';

interface Props {
  riskAssessmentHazardId: string;
}
export default function RiskAssessmentHazardFiles({ riskAssessmentHazardId }: Props) {
  const [loading, setLoading] = useState(true);
  const { openFileDialog, openWarnDialog } = useContext(DialogContext);
  const { data, fetch } = useFetch(
    () => RiskAssessmentHazardControllerService.getAllRiskAssessmentHazardFiles(riskAssessmentHazardId),
    [],
    { setLoading }
  );

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await RiskAssessmentHazardControllerService.riskAssessmentHazardFileDelete(id);
      await fetch();
      //  await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => riskAssessmentFileUpload(riskAssessmentHazardId, file)));
      await fetch();
    },
    'Dosyaları yüklendi',
    err => err,
    [riskAssessmentHazardId]
  );

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!files) {
      return;
    }

    await uploadFiles(files);
    await fetch();
    //  await fetch();
  }, [openFileDialog, fetch, uploadFiles]);

  return (
    <>
      <header className="text-right mb-1 mr-1">
        <Button onClick={uploadFile} variant="contained" startIcon={<AddIcon />} color="primary" className="ml-1">
          Dosya Ekle
        </Button>
      </header>
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Dosya Bulunamadı.">
        <List key="risk-assessment-files">
          {data?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-document" />
                </ListItemAvatar>
                <ListItemText primary={item?.originalFileName} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => removeFileShow(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => openFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end"
                    aria-label="delete">
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </IndicatorWrapper>
    </>
  );
}
