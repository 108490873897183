import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { ResourceContext } from '../../../../context/resource.context';
import { datePrettier } from '../../../../utils';

interface Props {
  nearMissData: any[];
}

export default ({ nearMissData }: Props) => {
  const { companies, departments, nearMissTypes, workStations } = useContext(ResourceContext);
  const history = useHistory();

  if (!nearMissData || nearMissData.length === 0) return <b>Kayıt bulunamadı!</b>;

  const handleClick = (nearMissId: string) => {
    history.push(`/near-miss/list/${nearMissId}`);
  };
  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Tanım</TableCell>
            <TableCell className="text-bold">Tarih</TableCell>
            <TableCell className="text-bold">Şirket</TableCell>
            <TableCell className="text-bold">Departmanlar</TableCell>
            <TableCell className="text-bold">İş İstasyonları</TableCell>
            <TableCell className="text-bold">Ramak Kala Türleri</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nearMissData.map((nearMiss, index) => (
            <TableRow key={index} hover onClick={() => handleClick(nearMiss.id)}>
              <TableCell scope="row">{nearMiss.description}</TableCell>
              <TableCell>{datePrettier(new Date(nearMiss.date).toLocaleDateString('tr-TR'))}</TableCell>
              <TableCell scope="row">{findByKey(companies, 'id', nearMiss?.companyId)?.companyName}</TableCell>
              <TableCell scope="row">
                {nearMiss.nearMissDepartments.map(x => findByKey(departments, 'id', x.departmentId)?.departmentName).join(', ')}
              </TableCell>
              <TableCell scope="row">
                {nearMiss.nearMissStations.map(z => findByKey(workStations, 'id', z.stationId)?.stationName).join(', ')}
              </TableCell>
              <TableCell scope="row">
                {nearMiss.nearMissTypes.map(y => findByKey(nearMissTypes, 'id', y.nearMissTypeDefinitionId)?.label).join(', ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
