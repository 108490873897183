import React, { createContext } from 'react';

import { TableDataResult } from 'model';

export interface ListItemTemplateProps<T> {
  item: T;
}

export interface FilteringPayload {
  size?: number;
  page?: number;
}

export interface Icd10MedicineContextType<T> {
  data: TableDataResult<T>;

  search(search: string): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  fetch(filters?: FilteringPayload): void;

  ListTableBody: React.ComponentType<ListItemTemplateProps<T>>;

  ListTableHead: React.ComponentType;

  searchPlaceholder: string;
}

export const Icd10MedicineListContext = createContext<Icd10MedicineContextType<any>>(null);
