import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { CommitteeMembersResult, patchCommitteeMembers, saveCommitteeMembers } from 'api/hs/definitions/committee-members';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../../api/hs/employee';
import { SelectOption } from '../../../../Common/Form/Select';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: CommitteeMembersResult;
}

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function CommitteeMembersForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { committeeMembersPositionDefinitions } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data) {
          await saveCommitteeMembers(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await patchCommitteeMembers(data?.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    formRef?.current?.methods?.setValue('committeeMemberPositionDefinition', data?.committeeMemberPositionDefinition);
    formRef?.current?.methods?.setValue('employeeId', data?.employeeId);
    formRef?.current?.methods?.trigger();
  }, [data]);

  return (
    <Dialog
      width="sm"
      title={data ? 'Kurul Üye Düzenleme' : 'Kurul Üye Ekleme'}
      color="secondary"
      opened={open}
      onClose={onClose.bind(null, false)}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <AutoComplete
              required
              name="employeeId"
              label="Personel Seç"
              optionFetcher={employeeOptionFetcher}
              labelFetcher={employeeLabelFetcher}
              single
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <AutoComplete
              required
              name="committeeMemberPositionDefinition"
              options={committeeMembersPositionDefinitions}
              label="Kuruldaki Görev"
              single
            />
          </Grid>
          <Grid item xs={12} md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
