import React from 'react';

import { UserRole } from 'model/user-role.enum';

import AnalysisParameterDetail from '../../Common/Forms/Definitions/HealthDefinition/Analysis/AnalysisParameterDetail';
import Allergy from './AlergyDefinition';
import Analysis from './AnalysisDefinitions';
import BloodRelation from './BloodRelationDefinition';
import Disability from './DisabilityDefinition';
import HealthPersonel from './HealtPersonelOperationDefinition';
import Icd10 from './Icd10Definition';
import Medicine from './MedicineDefinitions';
import Opinion from './OpinionDefinition';
import PhysicalCheck from './PhysicalCheckDefinition';
import Referral from './ReferralDefinition';
import RestReport from './RestReportDefinition';
import SystemCheck from './SystemCheckDefinition';
import Vaccine from './VaccineDefinition';

interface HealtDefinitionListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'ICD10',
    Component: Icd10,
    path: '',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'İlaçlar',
    Component: Medicine,
    path: 'medicine',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'ALERJILER',
    Component: Allergy,
    path: 'allergy',
    visibleTo: [UserRole.PHYSICIAN, UserRole.OSS],
  },
  {
    label: 'AŞILAR',
    Component: Vaccine,
    path: 'asilar',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'FİZİK MUAYENE',
    Component: PhysicalCheck,
    path: 'fizik-muayene',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'SİSTEM SORGULAMA',
    Component: SystemCheck,
    path: 'sistem-sorgulama',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'TAHLİLLER',
    Component: Analysis,
    path: 'tatlil-tetkik',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: undefined, // undefined ya da null ise tab de gozukmez
    Component: AnalysisParameterDetail,
    path: 'tatlil-tetkik/:categoryDefinitionId',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'İSTİRAHAT RAPORU',
    Component: RestReport,
    path: 'istirahat-raporu',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'SAĞLIK PERSONEL İŞLEMLERİ',
    Component: HealthPersonel,
    path: 'saglik-personel-islemleri',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'KANAAT',
    Component: Opinion,
    path: 'kanaat',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'ENGELLİLİK',
    Component: Disability,
    path: 'engellilik',
    visibleTo: [UserRole.PHYSICIAN, UserRole.OSS],
  },
  {
    label: 'Yakınlıklar',
    Component: BloodRelation,
    path: 'yakinliklar',
    visibleTo: [UserRole.PHYSICIAN],
  },
  {
    label: 'SEVKLER',
    Component: Referral,
    path: 'sevkler',
    visibleTo: [UserRole.PHYSICIAN],
  },
] as HealtDefinitionListItem[];
