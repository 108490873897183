import React, { useState } from 'react';

import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';

import TrainingList from './List';
import { TrainingDetailContext } from './training-detail.context';

export default function Index() {
  const [showDialogTraining, setShowDialogTraining] = useState(false);
  const [selectedData, setSelectedData] = useState<TrainingsViewContentResult>(null);
  const [trainerDialogOpened, setTrainerDialogOpened] = useState(false);
  return (
    <TrainingDetailContext.Provider
      value={{
        trainerDialogOpened,
        setTrainerDialogOpened,
        showDialogTraining,
        setShowDialogTraining,
        selectedData,
        setSelectedData,
      }}>
      <TrainingList />
    </TrainingDetailContext.Provider>
  );
}
