import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetCompaniesResult } from './get-companies.result';

export async function getCompanies(): Promise<GetCompaniesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetCompaniesResult[]>(`/companies`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
