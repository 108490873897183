import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';

import { WorkEquipmentJobStatusView } from 'api/client';
import { WorkEquipmentJobStatusControllerService } from 'api/client';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import { TableColumn } from 'Common/Table/Raw/table-column.model';
import { DialogContext } from 'context/dialog.context';
import { stopPropagation, useDateFormat, useXhr } from 'utils';

import { WorkEquipmentJobStatusContext } from '../../work-equipment-job-status.context';

function FileComponent({ row }: { row: WorkEquipmentJobStatusView }) {
  const { setSelectedData, setFileDialogOpened } = useContext(WorkEquipmentJobStatusContext);

  const fileFormOpen = useCallback(
    item => {
      setFileDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setFileDialogOpened]
  );
  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={() => fileFormOpen(row)}>
          <FileCopyIcon />
        </IconButton>
      </span>
    </>
  );
}

function JobStatusDetailComponent({ row }: { row: WorkEquipmentJobStatusView }) {
  const { setSelectedData, setJobStatusDetailDialogOpened } = useContext(WorkEquipmentJobStatusContext);

  const jobStatusDialogOpen = useCallback(
    item => {
      setJobStatusDetailDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setJobStatusDetailDialogOpened]
  );
  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={() => jobStatusDialogOpen(row)}>
          <VisibilityIcon />
        </IconButton>
      </span>
    </>
  );
}

function ActionComponent({ row }: { row: WorkEquipmentJobStatusView }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(WorkEquipmentJobStatusContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog } = useContext(DialogContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await WorkEquipmentJobStatusControllerService.deleteWorkEquipmentStatus(id);
      await refresh();
    },
    'Seçili Kayıt Silindi',
    'Kayıt silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.description} açıklamalı kayıtı silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.jobStatusId);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  return (
    <div key={row?.jobStatusId}>
      <IconButton id={row?.jobStatusId} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.jobStatusId} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        {row?.completed ? null : (
          <MenuItem id={row?.jobStatusId} onClick={() => editFormOpen(row)}>
            <ListItemIcon>
              <EditIcon fontSize="default" />
            </ListItemIcon>
            <Typography variant="inherit">Kontrol Et</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

function StatusShowAlert({ row }: { row: WorkEquipmentJobStatusView }) {
  if (row?.completed) {
    return (
      <>
        <Alert variant="outlined" severity="success">
          Kontrol Edildi.
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <Alert variant="outlined" severity="error">
          Kontrol Edilmedi.
        </Alert>
      </>
    );
  }
}

function SuitabilityUseShowAlert({ row }: { row: WorkEquipmentJobStatusView }) {
  if (row?.suitabilityUse) {
    return (
      <>
        <Alert variant="outlined" severity="success">
          Kullanılabilir
        </Alert>
      </>
    );
  } else {
    return (
      <>
        <Alert variant="outlined" severity="error">
          Kullanılamaz
        </Alert>
      </>
    );
  }
}
function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:hh')}</>;
}

export const COLUMNS: TableColumn<WorkEquipmentJobStatusView>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    sortable: true,
    defaultVisibility: true,
    render(row): JSX.Element {
      return (
        <Avatar alt={row?.definitionName} src={row?.imagePath ? `/saglik-gozetimi/public/images/work_equipment/${row?.imagePath}` : null} />
      );
    },
  },
  {
    id: 'definitionName',
    width: '10%',
    label: 'Tanımı',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'buildingName',
    width: '10%',
    label: 'Bina',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'floorName',
    width: '10%',
    label: 'Kat',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'layoutDescription',
    defaultVisibility: true,
    label: 'Bulunduğu Yer',
    sortable: true,
    width: '10%',
    render(row: WorkEquipmentJobStatusView): string {
      return row?.partName ?? row?.layoutDescription;
    },
  },
  {
    id: 'companyName',
    width: '10%',
    sortable: true,
    label: 'Şirket',
    defaultVisibility: true,
  },
  {
    id: 'executionDate',
    width: '5%',
    sortable: true,
    label: 'Planlanan Kontrol Tarihi',
    defaultVisibility: true,
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <DateField date={new Date(row.executionDate)} />;
    },
  },
  {
    id: 'suitabilityUse',
    width: '10%',
    sortable: true,
    label: 'Kullanım Durumu',
    defaultVisibility: true,
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <SuitabilityUseShowAlert row={row} />;
    },
  },
  {
    id: 'completed',
    width: '20%',
    sortable: true,
    label: 'Kontrol Durumu',
    defaultVisibility: true,
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <StatusShowAlert row={row} />;
    },
  },
  {
    id: 'userName',
    width: '10%',
    sortable: true,
    label: 'Oluşturan',
    defaultVisibility: false,
  },
  {
    id: Symbol('detailJobStatus').toString(),
    width: '5%',
    label: 'Detay',
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <JobStatusDetailComponent key={row?.jobStatusId} row={row} />;
    },
  },
  {
    id: Symbol('file').toString(),
    width: '5%',
    label: 'Dosya',
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <FileComponent key={row?.jobStatusId} row={row} />;
    },
  },
  {
    id: 'jobStatusId',
    label: 'İşlemler',
    width: '5%',
    render(row: WorkEquipmentJobStatusView): JSX.Element {
      return <ActionComponent key={row?.jobStatusId} row={row} />;
    },
  },
];
