import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';

interface Props {
  labels: string[];
  render(labels: string): JSX.Element;
}

interface TabPanelProps {
  children: JSX.Element;
  value: number;
  index: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function TabWithPanel({ labels, render }: Props) {
  const [activeTab, setActiveTab] = useState(0);
  const actualLabels = labels.find(label => label === 'left') ? ['SOL KULAK', 'SAĞ KULAK'] : labels;

  const handleTabChange = (e: any, value: number): void => {
    setActiveTab(value);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {actualLabels.map((label, index) => (
          <Tab
            key={`graph-tab-label-${index}`}
            label={label}
            style={{ width: `calc(100% / ${actualLabels.length})`, maxWidth: `calc(100% / ${actualLabels.length})` }}
          />
        ))}
      </Tabs>
      <TabPanel value={activeTab} index={activeTab}>
        {labels && render(labels[activeTab])}
      </TabPanel>
    </>
  );
}
