import { makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';

import RouterTabs from 'Common/RouterTabs';
import CoreToolbar from 'Common/Toolbar';
import { UserContext } from 'context/user';

import WorkPlaceDefinition from './workplace-definition-list';

const useStyles = makeStyles(theme => ({
  tabContainer: {
    display: 'grid',
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  routerTabs: {
    maxWidth: '100%',
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Index() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <div className={classes.root}>
      <CoreToolbar>
        <Typography variant="h6" color="inherit" className="text-bold">
          İşyeri Tanımları
        </Typography>
      </CoreToolbar>
      <Paper className={`${classes.tabContainer}`} elevation={3}>
        <RouterTabs
          rootPath="/definitions/workplace"
          tabs={WorkPlaceDefinition.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r)))}
          className={classes.routerTabs}
        />
      </Paper>
      <Switch>
        {WorkPlaceDefinition.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r))).map(
          ({ path, Component, visibleTo }) => (
            <Route key={path} path={`/definitions/workplace/${path}`} exact>
              <Component />
            </Route>
          )
        )}
      </Switch>
    </div>
  );
}
