import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { FirmSubscriptionsPayload } from './firm-subscriptions.payload';
import { FirmSubscriptionsResult } from './firm-subscriptions.result';

export async function getFirmSubscription(): Promise<FirmSubscriptionsResult[]> {
  let res;
  try {
    res = await HSApi.get<FirmSubscriptionsResult[]>(`/firm-subscriptions`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveFirmSubscription(body: FirmSubscriptionsPayload): Promise<FirmSubscriptionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/firm-subscriptions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmSubscription({
  id,
  ...body
}: MakeRequired<Partial<FirmSubscriptionsResult>, 'id'>): Promise<FirmSubscriptionsResult> {
  const [error, res] = await tryCatch(HSApi.put<FirmSubscriptionsResult>(`/firm-subscriptions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
