import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { PhysicalChecksPayload } from './physical-checks.payload';
import { PhysicalChecksResult } from './physical-checks.result';

export async function getPhysicalCheck(employeeId: string, examinationId?: string): Promise<PhysicalChecksResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<PhysicalChecksResult[]>(`/physical-checks?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<PhysicalChecksResult[]>(`/physical-checks?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function savePhysicalCheck(body: PhysicalChecksPayload[]): Promise<PhysicalChecksResult> {
  const [err, res] = await tryCatch(HSApi.post('/physical-checks', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updatePhysicalCheck(id: string, body: PhysicalChecksPayload): Promise<PhysicalChecksResult> {
  const [err, res] = await tryCatch(HSApi.put(`/physical-checks/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deletePhysicalCheck(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/physical-checks/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
