import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getSystemCheck, SystemCheckModel } from 'api/hs/examination/system-checks';
import { useAsyncEffect, useResourcePromise } from 'utils';

import { SystemCheckContext } from './system-check.context';
import SystemCheck from './systemCheck';

export default function Index() {
  const systemCheck$ = useResourcePromise('systemCheck');
  const [systemCheck, setSystemCheck] = useState<SystemCheckModel[]>([]);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<SystemCheckModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const systemChecks = await systemCheck$;
    const result = await getSystemCheck(employeeId, examinationId);
    const test = SystemCheckModel.fromEnumAndServerData(systemChecks, result);
    setSystemCheck([]);
    await setSystemCheck(test);
  }, [employeeId, examinationId, systemCheck$]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <SystemCheckContext.Provider
      value={{
        systemCheck,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
      }}>
      <SystemCheck />
    </SystemCheckContext.Provider>
  );
}
