import { Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useCallback, useContext } from 'react';

import { deleteTrainer } from 'api/hs/definitions/trainings/trainers';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { TrainersContext } from '../../trainers.context';
import EditDialog from './EditDialog';

export default function ListTrainer() {
  const { loading, trainers, setEditDialogOpened, setSelectedData, reload } = useContext(TrainersContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const onDelete = useCallback(
    async (training: any) => {
      const submitted = await openWarnDialog({
        text: `${training.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await deleteTrainer(training.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={trainers} errorMessage="Gösterilecek Eğitmen Bilgisi Bulunamadı">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold text-gray" width="5%" align="left" />
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  T.C
                </TableCell>
                <TableCell className="text-bold text-gray" width="20%" align="left">
                  Adı
                </TableCell>
                <TableCell className="text-bold text-gray" width="20%" align="left">
                  Soyadı
                </TableCell>
                <TableCell className="text-bold text-gray" width="15%" align="left">
                  Ünvan
                </TableCell>
                <TableCell className="text-bold text-gray" width="20%" align="left">
                  Şirket içerisinden mi ?
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainers.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <Icon style={{ color: 'gray' }} className="icon-person-box" />
                  </TableCell>
                  <TableCell align="left">{item?.nid}</TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{item?.lastName}</TableCell>
                  <TableCell align="left">{item?.title}</TableCell>
                  <TableCell align="left">{item?.isEmployee ? 'Evet' : 'Hayır'}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
