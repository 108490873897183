import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import { isFalsy, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { stopPropagation, useUniqueId } from 'utils';

import { TreeBoxController } from './controller.model';
import { getNames } from './get-names';
import { GroupStatus } from './group-status.model';
import Item from './Item';

interface GroupProps {
  control: TreeBoxController;
}

export default function Group({ control: { label, children } }: GroupProps) {
  const id = useUniqueId('tree-box-group-id');
  const { getValues, setValue, trigger } = useFormContext();

  const childrenNames = useMemo(() => getNames(children), [children]);
  const values = Object.values(getValues(childrenNames));

  const status = useMemo(
    () =>
      values.every(isTruthy) ? GroupStatus.AllChecked : values.every(isFalsy) ? GroupStatus.NoneChecked : GroupStatus.PartiallyChecked,
    [values]
  );

  const handleGroupClick = useCallback(() => {
    for (const name of childrenNames) {
      setValue(name, status !== GroupStatus.AllChecked);
    }

    trigger();
  }, [status, setValue, childrenNames, trigger]);

  return (
    <TreeItem
      nodeId={id}
      onLabelClick={stopPropagation}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={status === GroupStatus.AllChecked}
              indeterminate={status === GroupStatus.PartiallyChecked}
              onChange={handleGroupClick}
            />
          }
          label={label}
        />
      }>
      {children.map((control, index) => (
        <Item key={index} control={control} />
      ))}
    </TreeItem>
  );
}
