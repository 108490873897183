import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { CompanyUserResult } from './CompanyUser.result';

export async function getCompanyUser(): Promise<CompanyUserResult[]> {
  const [error, res] = await tryCatch(HSApi.get<CompanyUserResult[]>(`/company-users/filled`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function updateCompanyUser(companyUser: any) {
  const [error, res] = await tryCatch(HSApi.put(`/company-users/company/${companyUser.companyId}/users`, companyUser.userIds));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
