import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { ReferraledEmployeeItem } from '../../../../api/hs/dashboard/widget/widget.payload';
import { datePrettier } from '../../../../utils';

interface Props {
  users: ReferraledEmployeeItem[];
}

export default ({ users }: Props) => {
  const history = useHistory();

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}`);
  };

  if (!users || users.length === 0) return <b>Kayıt bulunamadı!</b>;

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Ad Soyad</TableCell>
            <TableCell className="text-bold">Sevk Tarihi</TableCell>
            <TableCell className="text-bold">Sevk Edilen Yer</TableCell>
            <TableCell className="text-bold">Sevk Edilen Bölüm</TableCell>
            <TableCell className="text-bold">Hekime Not</TableCell>
            <TableCell className="text-bold">Açıklama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: ReferraledEmployeeItem) => (
            <TableRow key={user?.employeeId} hover onClick={() => handleClick(user.employeeId)}>
              <TableCell scope="row">{user.name}</TableCell>
              <TableCell>{datePrettier(user.referralDate)}</TableCell>
              <TableCell>{user.hospitalType}</TableCell>
              <TableCell>{user.referralSectionDefinitionName}</TableCell>
              <TableCell>{user.noteToPhysician}</TableCell>
              <TableCell>{user.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
