import React, { useState } from 'react';

import { AccidentContentResult } from '../../../api/hs/accident';
import { AccidentsContext } from './accidents.context';
import ListAdd from './ListAdd';

export default function AccidentsListIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<AccidentContentResult>(null);

  return (
    <AccidentsContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <ListAdd />
    </AccidentsContext.Provider>
  );
}
