/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BloodRelationDefinitionDto } from '../models/BloodRelationDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BloodRelationDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmBloodRelationDefinition(
        id: string,
        requestBody: BloodRelationDefinitionDto,
    ): CancelablePromise<BloodRelationDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/blood-relation-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmBloodRelationDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/blood-relation-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static updateBloodRelationDefinition(
        id: string,
        requestBody: BloodRelationDefinitionDto,
    ): CancelablePromise<BloodRelationDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/blood-relation-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllBloodRelationDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/blood-relation-definitions/${id}`,
        });
    }

    /**
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmBloodRelationDefinitions(): CancelablePromise<Array<BloodRelationDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/blood-relation-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmBloodRelationDefinition(
        requestBody: BloodRelationDefinitionDto,
    ): CancelablePromise<BloodRelationDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/blood-relation-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static getAllBloodRelationDefinitions(): CancelablePromise<Array<BloodRelationDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/blood-relation-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns BloodRelationDefinitionDto OK
     * @throws ApiError
     */
    public static saveBloodRelationDefinition(
        requestBody: BloodRelationDefinitionDto,
    ): CancelablePromise<BloodRelationDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/blood-relation-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}