import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { PrescriptionContext } from '../../prescription.context';
import PrescriptionForm from '../form/PrescriptionForm';

export default function Add() {
  const {
    setNewDialogOpened,
    newDialogOpened,
    setEditDialogOpened,
    editDialogOpened,
    setNewSecondaryDialogOpened,
    newSecondaryDialogOpened,
    reload,
    selectedPrescription,
    setSelectedPrescription,
  } = useContext(PrescriptionContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const closeEditDialog = useCallback(
    (success: boolean) => {
      setEditDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setEditDialogOpened]
  );

  const closeSecondaryDialog = useCallback(
    success => {
      setNewSecondaryDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewSecondaryDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
    setSelectedPrescription(null);
  };

  const secondaryOnClick = useCallback(
    success => {
      setNewSecondaryDialogOpened(true);

      if (success) {
        reload();
      }
    },
    [reload, setNewSecondaryDialogOpened]
  );

  return (
    <>
      <Button className="mt-1" color="primary" variant="contained" onClick={onClick}>
        e-Reçete Ekle
      </Button>
      <PrescriptionForm
        onClose={editDialogOpened ? closeEditDialog : closeDialog}
        data={selectedPrescription}
        open={editDialogOpened ? editDialogOpened : newDialogOpened}
        secondaryOnClick={secondaryOnClick}
        secondaryOnClose={closeSecondaryDialog}
        secondaryData={null}
        secondaryOpen={newSecondaryDialogOpened}
      />
    </>
  );
}
