/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TrainingDefinitionTrainingDto } from './TrainingDefinitionTrainingDto';
import type { TrainingEmployeeDto } from './TrainingEmployeeDto';
import type { TrainingPlaningEmployeeExcludingDto } from './TrainingPlaningEmployeeExcludingDto';
import type { TrainingPlaningEmployeeGroupDto } from './TrainingPlaningEmployeeGroupDto';
import type { TrainingPlaningEmployeeIncludingDto } from './TrainingPlaningEmployeeIncludingDto';
import type { TrainingTrainerDto } from './TrainingTrainerDto';

export type TrainingDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    isCompleted?: boolean;
    title?: string;
    description?: string;
    plannedDate?: string;
    completedDate?: string;
    isInAnnualPlan?: boolean;
    location?: string;
    firmId?: string;
    validityPeriodInMonth?: number;
    atWorkplace?: boolean;
    isFaceToFace?: boolean;
    certificateType?: TrainingDto.certificateType;
    trainingDefinitions?: Array<TrainingDefinitionTrainingDto>;
    trainingEmployees?: Array<TrainingEmployeeDto>;
    trainingTrainers?: Array<TrainingTrainerDto>;
    trainingPlaningEmployeeGroups?: Array<TrainingPlaningEmployeeGroupDto>;
    trainingPlaningEmployeeExcluding?: Array<TrainingPlaningEmployeeExcludingDto>;
    trainingPlaningEmployeeIncluding?: Array<TrainingPlaningEmployeeIncludingDto>;
}

export namespace TrainingDto {

    export enum certificateType {
        PREDEFINED = 'PREDEFINED',
        PREDEFINED_LEGAL = 'PREDEFINED_LEGAL',
        CUSTOM = 'CUSTOM',
    }


}
