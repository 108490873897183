import { CardHeader, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { updateUser } from 'api/hs/users';
import TextField from 'Common/Form/TextField';
import { userRoleNameArrToStr } from 'Common/RoleUtils';
import { AlertContext } from 'context/alert.context';
import { UserContext } from 'context/user';
import { useAsyncEffect } from 'utils';
import { email } from 'validators';

const useStyles = makeStyles({
  root: {
    maxWidth: 450,
  },
});

export default function GeneralInformation() {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { user, refreshUser } = useContext(UserContext);
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(async () => {
    const formData = formMethods.getValues();
    try {
      await updateUser({
        id: user.id,
        email: formData.email,
        name: formData.name,
      });
      showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      refreshUser();
    } catch (e) {
      showMessage('Güncelleme işlemi başarısız.', 'error', 'info');
    }
  }, [showMessage, formMethods, user, refreshUser]);

  useAsyncEffect(async () => {
    if (user) {
      await defer();
      for (const [key, value] of Object.entries(user)) {
        setValue(key, value === null ? '' : value);
      }

      const userRole = formMethods.getValues('roles');
      setValue('roles', userRoleNameArrToStr(userRole?.split(',')));
      trigger();
    }
  }, [user, setValue, trigger]);

  return (
    <div className="mt-1 mr-1">
      <Card className={classes.root}>
        <CardHeader style={{ backgroundColor: '#2196f3', color: 'white' }} title="Genel Bilgiler" />
        <CardContent>
          <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
            <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(() => handleSubmit())}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField name="name" label="Ad Soyad" />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField name="email" label="E-mail" rules={{ validate: { email } }} />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField disabled name="roles" label="Kullanıcı Yetkilisi" />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField type="password" name="esignPinCode" label="E-Imza PIN Kodu" />
                </Grid>
                <Grid item md={12} className="text-right">
                  <Button variant="contained" type="submit" color="primary" className="mr-2">
                    Kaydet
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </div>
  );
}
