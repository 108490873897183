import HSApi from '../../HSApi';
import { ReportListResult } from './report-list.result';

export async function getReportList(page = 0, size = 20, order: null | string): Promise<ReportListResult> {
  const res = await HSApi.get<ReportListResult>(`/rest-reports/view?page=${page}&size=${size}&order=${order ?? ''}`);

  return res.data;
}
export async function exportReportList() {

  const res = await HSApi.get(`/rest-reports/view/export`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'rapor-listesi.xlsx';
  a.click();
}
