import { Chip, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { Timer } from '@material-ui/icons';
import FileCopy from '@material-ui/icons/FileCopy';
import React from 'react';
import { useHistory } from 'react-router';

import { UserEventType } from '../../../api/hs/dashboard/event/event.payload';
import Button from '../../../Common/Button';
import { dateTimePrettier } from '../../../utils';
import Header from '../../Header';

export default ({ event, close }) => {
  const history = useHistory();

  const handleClickDetail = () => {
    if (event.userEventType === UserEventType.PERIODIC_EXAMINATION) {
      history.push(`/transactions/employee/${event.employeeId}/examination`);
      return;
    }
    if (event.userEventType === UserEventType.VACCINE) {
      history.push(`/transactions/employee/${event.employeeId}/vaccine`);
      return;
    }
    if (event.userEventType === UserEventType.TRAINING) {
      history.push(`/training/detail-list/training/planning/${event.referenceTableId}`);
      return;
    }
    if (event.userEventType === UserEventType.ACTION) {
      history.push(`/actions`);
      return;
    }
    if (event.userEventType === UserEventType.COMMITTEE_MEETING) {
      history.push(`/committee/committee-meetings/${event.referenceTableId}`);
      return;
    }
    if (event.userEventType === UserEventType.DRILL) {
      history.push(`/emergency-management/drill`);
      return;
    }
    if (event.userEventType === UserEventType.PREGNANCY) {
      history.push(`/transactions/employee/${event.employeeId}`);
      return;
    }
    if (event.userEventType === UserEventType.RISK_ASSESSMENT) {
      history.push(`/risk/risk-assessments-form/${event.referenceTableId}`);
      return;
    }
    if (event.userEventType === UserEventType.PPE) {
      history.push(`/ppe/ppe-outputs`);
      return;
    }
  };

  return (
    <>
      <Header title={'Etkinlik Bilgileri'} icon={<FileCopy />} />
      <DialogContent>
        <Grid container className={'mt-4'}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" color="secondary">
              {event.description}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Chip style={{ float: 'right' }} icon={<Timer />} label={new Date(event.date).toLocaleDateString('tr')} />
          </Grid>
          <Grid item xs={12}>
            <p>{event.employeeName}</p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="pb-2 pr-2">
        <Button color="secondary" onClick={handleClickDetail}>
          Görüntüle
        </Button>
        <Button color="default" onClick={close}>
          Kapat
        </Button>
      </DialogActions>
    </>
  );
};
