import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import { PpeDetailStocksResult } from 'api/hs/ppe/ppe-detail-stocks';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { deletePpeStocks } from '../../../../api/hs/ppe/ppe-stocks';
import { DialogContext } from '../../../../context/dialog.context';
import { useDateFormat, useXhr } from '../../../../utils';
import { PpeDetailStocksContext } from '../../ppe-detail-stocks.context';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

function ActionComponent({ row }: { row: PpeDetailStocksResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { openWarnDialog } = useContext(DialogContext);
  const { setSelectedData, setEditDialogOpened, reload } = useContext(PpeDetailStocksContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deletePpeStocks(id);
      await reload();
    },
    'Seçili Kayıt Silindi',
    'Kayıt silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: ` kayıtı silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export const CELL: TableColumn<PpeDetailStocksResult>[] = [
  {
    id: 'stockCount',
    defaultVisibility: true,
    label: 'Stok Sayısı',
    sortable: false,
    width: '10%',
  },
  {
    id: 'stockNumber',
    defaultVisibility: true,
    label: 'Stok Numarası',
    sortable: false,
    width: '10%',
  },
  {
    id: 'createdDate',
    width: '10%',
    label: 'Girdi Tarihi',
    render(row): JSX.Element {
      return <DateField date={new Date(row.expireDate)} />;
    },
    defaultVisibility: true,
  },
  {
    id: 'productionDate',
    width: '10%',
    label: 'Üretim Tarihi',
    render(row): JSX.Element {
      return <DateField date={new Date(row.productionDate)} />;
    },
    defaultVisibility: true,
  },
  {
    id: 'storeHouse',
    defaultVisibility: true,
    label: 'Depo Adı',
    sortable: false,
    width: '10%',
    render(row: PpeDetailStocksResult): JSX.Element {
      return <>{row?.storeHouse?.name}</>;
    },
  },
  {
    id: Symbol(),
    label: 'İşlemler',
    width: '5%',
    render(row: PpeDetailStocksResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
