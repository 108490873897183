import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import { getMedicalHistories, upsertMedicalHistories } from 'api/hs/examination';
import { MedicalHistoryDetail } from 'model';
import { useEnumPromise } from 'utils';

import CheckList, { FetchResult } from './CheckList';
import { UpsertData } from './CheckList/checklist.context';

export default function MedicalHistory() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const medicalHistoryDefinitions$ = useEnumPromise('medicalHistoryDefinitions');

  const fetch = useCallback<() => Promise<FetchResult>>(async () => {
    const medicalHistoryDefinitions = await medicalHistoryDefinitions$;
    const result = await getMedicalHistories(employeeId, examinationId);

    return {
      description: result.description,
      list: medicalHistoryDefinitions
        .map(def => MedicalHistoryDetail.fromEnumAndServerData(def, result.medicalHistories))
        .map(({ definitionId, definitionName, color, valueText, value }) => ({
          id: definitionId,
          label: definitionName,
          color,
          valueText,
          value,
        })),
    };
  }, [employeeId, examinationId, medicalHistoryDefinitions$]);

  const upsert = useCallback(
    async ({ description, ...rest }: UpsertData) => {
      await upsertMedicalHistories({
        employeeId,
        examinationId,
        description,
        medicalHistories: Object.entries(rest).map(([id, value]) => ({ definitionId: +id, value })),
      });
    },
    [employeeId, examinationId]
  );

  return <CheckList fetch={fetch} upsert={upsert} editButtonText="Hastalık Öyküsü Düzenle" editDialogTitle="Hastalık Öyküsü Düzenle" />;
}
