import { createContext } from 'react';

import { NearMissDto, NearMissView } from '../../../api/client';

export interface NearMissDetailContextType {
  nearMissDetail: NearMissView;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: NearMissDto;

  setSelectedData(data: NearMissDto): void;

  reload(): void;
}

export const NearMissDetailContext = createContext<NearMissDetailContextType>(null);
