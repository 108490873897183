import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
export async function getWorkEquipmentList(page = 0, size = 20, order: null | string, filters: any) {
  const payload = {
    filter: filters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/work-equipment-definitions/views`, payload);

  return res.data;
}

export async function uploadWorkEquipmentDefinition(id: string, file: File) {
  const data = new FormData();
  data.set('image', file);

  const [err, res] = await tryCatch(HSApi.post(`/work-equipment-definitions/${id}/image`, data));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
