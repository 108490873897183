import { createContext } from 'react';

import { FirmSubscriptionsModel, FirmSubscriptionsResult } from 'api/hs/definitions/firm-definitions/firm-subscriptions';

export interface FirmSubscriptionsContextType {
  firmSubscriptions: FirmSubscriptionsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: FirmSubscriptionsModel;

  setSelectedData(data: FirmSubscriptionsModel): void;
}

export const FirmSubscriptionsContext = createContext<FirmSubscriptionsContextType>(null);
