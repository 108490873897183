import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetPpeStoreHouseResult } from './get-ppe-store-house.result';

export async function getPpeStoreHouseSection(): Promise<GetPpeStoreHouseResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetPpeStoreHouseResult[]>(`/ppe/storehouse-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
