import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { Shop } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { ActionsContentResult } from '../../../api/hs/actions';
import { deleteTraining, TrainingDetailResult } from '../../../api/hs/trainings';
import { saveIbysTraining } from '../../../api/hs/trainings/ibys-trainings';
import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { AlertContext } from '../../../context/alert.context';
import { DialogContext } from '../../../context/dialog.context';
import { ResourceContext } from '../../../context/resource.context';
import { stopPropagation, useDateFormat, useXhr } from '../../../utils';
import DateDifferent from '../../../utils/DateDifferent';
import { TrainingDetailContext } from '../training-detail.context';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}

function NextDateField({ row }: { row: TrainingsViewContentResult }) {
  const format = useDateFormat();

  const newDate = new Date(row.completedDate);
  newDate.setMonth(newDate.getMonth() + row?.validityPeriodInMonth);
  return <>{format(newDate, 'dd MMMM yyyy')}</>;
}

function TrainerComponent({ row }: { row: TrainingsViewContentResult }) {
  const { trainers } = useContext(ResourceContext);

  return (
    <>
      {row?.trainers
        ?.map(trainer => findByKey(trainers, 'id', trainer?.id))
        ?.map(trainer => `${trainer?.name} ${trainer?.lastName}`)
        ?.join(', ')}
    </>
  );
}

function ActionComponent({ row }: { row: TrainingsViewContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { openWarnDialog } = useContext(DialogContext);
  const { refresh } = useContext(HSTableContext);
  const { showMessage } = useContext(AlertContext);
  const { setTrainerDialogOpened, setSelectedData } = useContext(TrainingDetailContext);

  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteTraining(id);
      await refresh();
    },
    'Seçili Eğitim Silindi',
    'Eğitim silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.title} isimli eğitimi silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const IbysDataConvert = useCallback(
    async (item: TrainingsViewContentResult) => {
      if (item?.trainers.length === 1) {
        try {
          await saveIbysTraining(item?.id, {});
          showMessage('Bakanlık verisi başarılı ile oluşturuldu.', 'success');
          history.push('/training/detail-list');
        } catch (error) {
          showMessage('Bakanlık verisine dönüştürürken hata oluştu.', 'error');
        }
      } else {
        setTrainerDialogOpened(true);
        setSelectedData(item);
      }
      closeMenu();
    },
    [closeMenu, setTrainerDialogOpened, showMessage, history, setSelectedData]
  );

  return (
    <div key={row?.id}>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem onClick={() => history.push(`/training/detail-list/${row?.id}`)}>
            <ListItemIcon>
              <AssignmentReturnIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Düzenle</Typography>
          </MenuItem>
          {!row?.completedDate && (
            <MenuItem onClick={() => history.push(`/training/detail-list/planning/${row.id}`)}>
              <ListItemIcon>
                <EditIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit">Yeniden Planla</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={() => removeFile(row)}>
            <ListItemIcon>
              <DeleteIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Sil</Typography>
          </MenuItem>
          <MenuItem onClick={() => IbysDataConvert(row)}>
            <ListItemIcon>
              <CachedIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Bakanlık Verisine Dönüştür</Typography>
          </MenuItem>
        </Menu>
      </span>
    </div>
  );
}

function PreviewComponent({ row }: { row: TrainingsViewContentResult }) {
  const { setShowDialogTraining, setSelectedData } = useContext(TrainingDetailContext);

  const showDialog = useCallback(
    item => {
      setShowDialogTraining(true);
      setSelectedData(item);
    },
    [setSelectedData, setShowDialogTraining]
  );

  return (
    <div key={row?.id}>
      <IconButton onClick={() => showDialog(row)}>
        <VisibilityIcon />
      </IconButton>
    </div>
  );
}

function StatusTrainingShowAlert({ row }: { row: TrainingsViewContentResult }) {
  return row?.completedDate ? (
    <Alert icon={false} variant="outlined" color="success" severity="success">
      Tamamlandı
    </Alert>
  ) : (
    <Alert
      icon={false}
      variant="outlined"
      color={DateDifferent(new Date().toISOString(), row?.plannedDate) < 0 ? 'error' : 'warning'}
      severity="warning">
      Planlandı
    </Alert>
  );
}

export const CELL: TableColumn<TrainingsViewContentResult>[] = [
  {
    id: 'title',
    defaultVisibility: true,
    label: 'Eğitim',
    sortable: true,
    width: '10%',
  },
  {
    id: 'completedDate',
    defaultVisibility: true,
    label: 'Eğitim Tarihi',
    sortable: false,
    width: '10%',
    render(row): JSX.Element {
      return <DateField date={row?.completedDate ? new Date(row.completedDate) : new Date(row.plannedDate)} />;
    },
  },
  {
    id: 'durationInMinutes',
    defaultVisibility: true,
    label: 'Eğitim Süresi',
    sortable: true,
    width: '5%',
    render(row): string {
      return row?.durationInMinutes + ' dk';
    },
  },
  {
    id: Symbol('egitimdurumu').toString(),
    defaultVisibility: true,
    label: 'Eğitim Durumu',
    width: '%5',
    render(row: TrainingsViewContentResult): JSX.Element | string {
      return <StatusTrainingShowAlert row={row} />;
    },
  },
  {
    id: 'validityPeriodInMonth',
    defaultVisibility: true,
    label: 'Geçerlilik Tarihi',
    sortable: false,
    width: '10%',
    render(row): JSX.Element {
      return <NextDateField row={row} />;
    },
  },
  {
    id: 'trainers',
    label: 'Eğitmen',
    defaultVisibility: true,
    sortable: false,
    width: '20%',
    render(row: TrainingsViewContentResult): JSX.Element {
      return <TrainerComponent row={row} />;
    },
  },
  {
    id: 'faceToFace',
    defaultVisibility: true,
    label: 'Eğitim Şekli',
    width: '10%',
    render(row: TrainingsViewContentResult): string {
      // return row.faceToFace === '1' ? 'Yüz Yüze' : 'Uzaktan';
      return row.faceToFace;
    },
  },
  {
    id: 'location',
    defaultVisibility: true,
    label: 'Eğitim Yeri',
    sortable: false,
    width: '10%',
  },
  {
    id: 'trainingEmployees',
    width: '5%',
    label: 'Katılımcı Sayısı',
    render(row: TrainingsViewContentResult): JSX.Element {
      return <div>{row.trainingEmployees.length}</div>;
    },
  },
  {
    id: Symbol('katilanoran').toString(),
    width: '5%',
    label: 'Katılan Oranı',
    render(row: TrainingsViewContentResult): JSX.Element {
      return (
        <div>{`%${Math.round(
          (row.trainingEmployees.filter(trainingEmployee => trainingEmployee.isParticipated === true).length * 100) /
            row.trainingEmployees.length
        )}`}</div>
      );
    },
  },
  {
    id: Symbol('onizleme').toString(),
    defaultVisibility: true,
    label: 'Önizleme',
    sortable: false,
    width: '5%',
    render(row: TrainingsViewContentResult): JSX.Element {
      return <PreviewComponent key={row?.id} row={row} />;
    },
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '5%',
    render(row: TrainingsViewContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
