/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InjuryTypeDefinitionDto } from '../models/InjuryTypeDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InjuryTypeDefinitionControllerService {

    /**
     * @param definitionId
     * @param requestBody
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateInjuryTypeDefinition(
        definitionId: string,
        requestBody: InjuryTypeDefinitionDto,
    ): CancelablePromise<InjuryTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/injury-type-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteInjuryTypeDefinition(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/injury-type-definitions/${definitionId}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmInjuryTypeDefinition(
        id: string,
        requestBody: InjuryTypeDefinitionDto,
    ): CancelablePromise<InjuryTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/injury-type-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmInjuryTypeDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/injury-type-definitions/${id}`,
        });
    }

    /**
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getAllInjuryTypeDefinitions(): CancelablePromise<Array<InjuryTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/injury-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveInjuryTypeDefinition(
        requestBody: InjuryTypeDefinitionDto,
    ): CancelablePromise<InjuryTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/injury-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getFirmInjuryTypeDefinitions(): CancelablePromise<Array<InjuryTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/injury-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns InjuryTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmInjuryTypeDefinition(
        requestBody: InjuryTypeDefinitionDto,
    ): CancelablePromise<InjuryTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/injury-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}