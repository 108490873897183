/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkEquipmentTypeDefinitionDto } from '../models/WorkEquipmentTypeDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkEquipmentTypeDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateWorkEquipmentTypeDefinition(
        id: string,
        requestBody: WorkEquipmentTypeDefinitionDto,
    ): CancelablePromise<WorkEquipmentTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/work-equipment-type-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllWorkEquipmentTypeDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-type-definitions/${id}`,
        });
    }

    /**
     * @returns WorkEquipmentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentTypes(): CancelablePromise<Array<WorkEquipmentTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns WorkEquipmentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveWorkEquipmentTypeDefinition(
        requestBody: WorkEquipmentTypeDefinitionDto,
    ): CancelablePromise<WorkEquipmentTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/work-equipment-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}