import React, { useContext } from 'react';

import { DefinitionListContext } from '../page.context';
import Add from './Add';
import List from './List';
export default function BaseDefinition() {
  const { noSave } = useContext(DefinitionListContext);
  return (
    <>
      {' '}
      {noSave ? null : <Add />}
      <List />
    </>
  );
}
