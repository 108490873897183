/*eslint-disable */
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';

import { ParameterModel } from 'api/hs/examination/analysis';
import TextField from 'Common/Form/TextField';
import TextFieldWithOnChange from 'Common/Form/TextFieldWithOnChange';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';

import DateInput from '../../../../../../Common/Form/DateInput';
import { PulmonaryQuestion } from '../pulmonary-question.enum';

interface Props {
  parameters: ParameterModel[];

  handleInput(data: string): void;
}

const useStyles = makeStyles(theme => ({
  cellFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 0,
  },
  cellRoot: {
    border: 'none !important',
  },
  cellInputRoot: {
    border: 'none !important',
    padding: '6px 10px',
  },
  bolder: {
    fontWeight: 'bold',
  },
  formLabelRoot: {
    lineHeight: '1.5',
  },
}));

export default function PulmonaryForm({ parameters, handleInput }: Props) {
  const classes = useStyles();
  const SELECTION_OPTIONS = [
    { value: 0, text: 'Yok', color: '#469789' },
    { value: 1, text: 'Var', color: '#eb4355' },
  ];
  return (
    <>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <DateInput required type="date" name="date" label="Reçete Tarihi" />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TextField rows={2} label="Kanaat" name="opinion" />
      </Grid>
      <Typography color="inherit" align="left">
        Sonuç
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.cellRoot }} />
            <TableCell classes={{ root: classes.cellRoot }}>PRED</TableCell>
            <TableCell classes={{ root: classes.cellRoot }}>ACT</TableCell>
            <TableCell classes={{ root: classes.cellRoot }}>Birim</TableCell>
            <TableCell classes={{ root: classes.cellRoot }}>%PRED</TableCell>
            <TableCell classes={{ root: classes.cellRoot }}>NAS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters?.map((item, index) => {
            if (item?.parameter !== 'Soru1' && item?.parameter !== 'Soru2') {
              return (
                <TableRow key={`pulmonary-form-row-${item?.parameter}${index}`}>
                  <TableCell classes={{ root: classes.cellRoot }} className={classes.bolder} align="left">
                    {item?.parameter}
                  </TableCell>
                  <TableCell classes={{ root: classes.cellInputRoot }} align="left">
                    <TextFieldWithOnChange type="number" name={`pred_${item?.parameter}`} onChange={handleInput} />
                  </TableCell>
                  <TableCell classes={{ root: classes.cellInputRoot }} align="left">
                    <TextFieldWithOnChange type="number" name={`act_${item?.parameter}`} onChange={handleInput} />
                  </TableCell>
                  <TableCell classes={{ root: classes.cellRoot }} align="left">
                    {item?.analysisParameterUnit}
                  </TableCell>
                  <TableCell classes={{ root: classes.cellInputRoot }} align="left">
                    <TextFieldWithOnChange type="number" name={`predPercent_${item?.parameter}`} disabled={true} onChange={handleInput} />
                  </TableCell>
                  <TableCell classes={{ root: classes.cellInputRoot }} align="left">
                    <TextFieldWithOnChange type="number" name={`nas_${item?.parameter}`} disabled={true} onChange={handleInput} />
                  </TableCell>
                </TableRow>
              );
            }
          })}
          <TableRow>
            <TableCell colSpan={6}>
              <Typography color="initial" className="mb-2">
                {PulmonaryQuestion.Question1}
              </Typography>
              <ToggleButtonGroup
                name="nas_Soru1"
                options={SELECTION_OPTIONS}
                defaultValue={1}
                renderOption={({ text, value, selected, color }) => (
                  <ToggleButton
                    className={selected ? 'text-white' : ''}
                    style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}
                    value={value}>
                    {text}
                  </ToggleButton>
                )}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6}>
              <Typography color="initial" className="mb-2">
                {PulmonaryQuestion.Question2}
              </Typography>
              <ToggleButtonGroup
                name="nas_Soru2"
                options={SELECTION_OPTIONS}
                defaultValue={1}
                renderOption={({ text, value, selected, color }) => (
                  <ToggleButton
                    className={selected ? 'text-white' : ''}
                    style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}
                    value={value}>
                    {text}
                  </ToggleButton>
                )}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
