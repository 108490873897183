/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyCasePlanDto } from '../models/EmergencyCasePlanDto';
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmergencyCasePlanControllerService {

    /**
     * @param id
     * @returns EmergencyCasePlanDto OK
     * @throws ApiError
     */
    public static getEmergencyCasePlanById(
        id: string,
    ): CancelablePromise<EmergencyCasePlanDto> {
        return __request({
            method: 'GET',
            path: `/emergency-case-plans/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCasePlanDto OK
     * @throws ApiError
     */
    public static updateEmployeeEmergencyCasePlan(
        id: string,
        requestBody: EmergencyCasePlanDto,
    ): CancelablePromise<EmergencyCasePlanDto> {
        return __request({
            method: 'PUT',
            path: `/emergency-case-plans/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteEmployeeEmergencyCasePlan(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-plans/${id}`,
        });
    }

    /**
     * @returns EmergencyCasePlanDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCasePlans(): CancelablePromise<Array<EmergencyCasePlanDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-plans`,
        });
    }

    /**
     * @param requestBody
     * @returns EmergencyCasePlanDto OK
     * @throws ApiError
     */
    public static saveEmployeeEmergencyCasePlan(
        requestBody: EmergencyCasePlanDto,
    ): CancelablePromise<EmergencyCasePlanDto> {
        return __request({
            method: 'POST',
            path: `/emergency-case-plans`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getEmergencyPlanFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-plans/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param formData
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static emergencyPlanFileImport(
        id: string,
        formData?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/emergency-case-plans/${id}/imported-files`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getEmergencyPlanFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/emergency-case-plans/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteEmergencyPlanFile(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-plans/imported-files/${id}`,
        });
    }

}