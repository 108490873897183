/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IbysTrainingDto } from '../models/IbysTrainingDto';
import type { PageIbysTrainingDto } from '../models/PageIbysTrainingDto';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IbysTrainingControllerService {

    /**
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static generateXmlContent1(
        id: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/trainings/ibys-trainings/${id}/xml-content`,
        });
    }

    /**
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static generateXmlContent(
        id: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'POST',
            path: `/trainings/ibys-trainings/${id}/xml-content`,
        });
    }

    /**
     * @param id
     * @returns IbysTrainingDto OK
     * @throws ApiError
     */
    public static getIbysTraining(
        id: string,
    ): CancelablePromise<IbysTrainingDto> {
        return __request({
            method: 'GET',
            path: `/trainings/ibys-trainings/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IbysTrainingDto OK
     * @throws ApiError
     */
    public static updateIbysTrainingStatus(
        id: string,
        requestBody: IbysTrainingDto,
    ): CancelablePromise<IbysTrainingDto> {
        return __request({
            method: 'PUT',
            path: `/trainings/ibys-trainings/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static delete(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/trainings/ibys-trainings/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IbysTrainingDto OK
     * @throws ApiError
     */
    public static partialUpdateIbysTrainingStatus(
        id: string,
        requestBody: IbysTrainingDto,
    ): CancelablePromise<IbysTrainingDto> {
        return __request({
            method: 'PATCH',
            path: `/trainings/ibys-trainings/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param trainerId
     * @returns PageIbysTrainingDto OK
     * @throws ApiError
     */
    public static saveIbysTrainings(
        id: string,
        trainerId?: string,
    ): CancelablePromise<PageIbysTrainingDto> {
        return __request({
            method: 'POST',
            path: `/trainings/${id}/ibys-trainings`,
            query: {
                'trainerId': trainerId,
            },
        });
    }

    /**
     * @param pagingAndSortingRequestParam
     * @returns PageIbysTrainingDto OK
     * @throws ApiError
     */
    public static getAllIbysTrainingStatus(
        pagingAndSortingRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageIbysTrainingDto> {
        return __request({
            method: 'GET',
            path: `/trainings/ibys-trainings`,
            query: {
                'pagingAndSortingRequestParam': pagingAndSortingRequestParam,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IbysTrainingDto OK
     * @throws ApiError
     */
    public static saveIbysTrainingStatus(
        requestBody: IbysTrainingDto,
    ): CancelablePromise<IbysTrainingDto> {
        return __request({
            method: 'POST',
            path: `/trainings/ibys-trainings`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns number OK
     * @throws ApiError
     */
    public static getCountOfIbysTrainings(
        id: string,
    ): CancelablePromise<number> {
        return __request({
            method: 'GET',
            path: `/trainings/${id}/ibys-trainings/count`,
        });
    }

}