import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { PregnanciesContextContext } from '../../pregnancies.context';
import DialogPregnanciesForm from '../Form/DialogPregnanciesForm';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened } = useContext(PregnanciesContextContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);
    },
    [setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <header className="text-right">
        <Button onClick={onClick} color="primary">
          Gebelik Ekle
        </Button>
      </header>

      <DialogPregnanciesForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
