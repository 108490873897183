import { createContext } from 'react';

import { WorkEquipmentLayoutView } from '../../api/client';

export interface WorkEquipmentLayoutContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: WorkEquipmentLayoutView;

  setSelectedData(data: WorkEquipmentLayoutView): void;
}

export const WorkEquipmentLayoutContext = createContext<WorkEquipmentLayoutContextType>(null);
