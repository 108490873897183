import { Grid } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import Select, { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { PHONE_MASK, REGISTRY_NUMBER_MASK, SSI_NUMBER_MASK, YES_NO_ENUM } from 'model';
import { mapEnumsToOptions } from 'utils';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../api/hs/employee';
import DateInput from '../Form/DateInput';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function ProfessionalInformationForm() {
  const { departments, locations, workStations, positions, exposures, workingTypes } = useContext(SelectOptionsContext);

  const [retirementStatusOptions] = useState<SelectOption<number>[]>(mapEnumsToOptions(YES_NO_ENUM));

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <DateInput name="hireDate" type="date" label="İşe Başlangıç Tarihi" />
      </Grid>
      <Grid item md={6}>
        <DateInput name="quitDate" type="date" label="İşten Çıkış Tarihi" />
      </Grid>
      <Grid item md={6}>
        <TextField name="registryNumber" label="Sicil No" mask={REGISTRY_NUMBER_MASK} />
      </Grid>
      <Grid item md={6}>
        <TextField name="ssiNumber" label="SGK Numarası" mask={SSI_NUMBER_MASK} />
      </Grid>
      <Grid item md={6}>
        <Select name="workingType" options={workingTypes} label="Çalışma Şekli" />
      </Grid>
      <Grid item md={6}>
        <TextField name="businessTelephone" label="İş Telefon" mask={PHONE_MASK} maskError="Hatalı telefon numarası" />
      </Grid>
      <Grid item md={6}>
        <Select name="retirementStatus" options={retirementStatusOptions} label="Emeklilik Durumu" />
      </Grid>
      <Grid item md={6}>
        <TextField name="numberOfChildren" label="Çocuk Sayısı" type="number" />
      </Grid>
      <Grid item md={6}>
        <AutoComplete name="departmentId" options={departments} label="Departman" single />
      </Grid>
      <Grid item md={6}>
        <AutoComplete name="locationDefinitionId" options={locations} label="Lokasyon" single />
      </Grid>
      <Grid item md={6}>
        <AutoComplete name="stationId" options={workStations} label="İş İstasyonu" single />
      </Grid>
      <Grid item md={6}>
        <AutoComplete name="positionId" options={positions} label="Görev/Pozisyon" single />
      </Grid>
      <Grid item md={12}>
        <AutoComplete name="managerId" label="Yönetici" optionFetcher={employeeOptionFetcher} labelFetcher={employeeLabelFetcher} single />
      </Grid>
      <Grid item md={12}>
        <AutoComplete name="exposureId" options={exposures} label="Maruziyet" renderOption="checkbox" />
      </Grid>
    </Grid>
  );
}
