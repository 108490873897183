import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { data } from 'tslint-plugin-prettier';

import { TrainingEmployeeViewControllerService } from 'api/client';

import { hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { getTrainingsCount } from '../../../api/hs/trainings/training-widget';
import { useAsyncEffect } from '../../../utils';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DateRangeDialog from './DateRangeDialog';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }: any) => {
  const [expiredTrainings, setExpiredTrainings] = useState<number>();
  const [uneducatedEmployee, setUneducatedEmployee] = useState<number>();
  const [trainingedEmployee, setTrainingedEmployee] = useState<number>();

  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const {
    trainingWidget: { open },
    trainingWidgetDateRange: { dateRange, days, setRangeByDays, setRange },
  } = useContext(DialogContext);

  useAsyncEffect(async () => {
    if (dateRange) {
      const expiredTrainingsEmployeeCount = await TrainingEmployeeViewControllerService.getEmployeeExpiredTrainingCount();
      setExpiredTrainings(expiredTrainingsEmployeeCount);
      const uneducatedTrainingsEmployee = await TrainingEmployeeViewControllerService.getEmployeeTrainingCount({
        trainingEmployeeFilter: { isPlanned: false },
      });
      setUneducatedEmployee(uneducatedTrainingsEmployee);
      const trainingsEmployees = await TrainingEmployeeViewControllerService.getEmployeeTrainingCount({
        trainingEmployeeFilter: { isPlanned: true, isParticipated: true, startDate: dateRange?.start, endDate: dateRange?.end },
      });
      setTrainingedEmployee(trainingsEmployees);
    }
  }, [dateRange]);

  useEffect(() => {
    setRangeByDays(7);
  }, [setRangeByDays]);

  const hide = () => {
    hideWidget({ widgetType: WidgetType.TRAINING }).then(reload);
  };

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Card key={'training'} className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.training }}>
      <CardContent>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sm={10} onClick={open}>
            <h3>Süresi Dolmuş : {expiredTrainings || 0}</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide}>
              <>
                {dateRangeOptions.map(dateRangeOption => (
                  <MenuItem key={dateRangeOption} onClick={() => setRangeByDays(dateRangeOption)}>
                    <ListItemIcon>{dateRangeOption === days && <Done fontSize="small" />}</ListItemIcon>
                    <ListItemText>{dateRangeOption} Gün</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setDialogVisibility(true)}>
                  <ListItemIcon>{days === undefined && <Done fontSize="small" />}</ListItemIcon>
                  <ListItemText>Diğer...</ListItemText>
                </MenuItem>
              </>
            </DropdownMenuContainer>
          </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }} onClick={open}>
          <Grid item sm={12}>
            <h3>Eğitim Hiç Almamış : {uneducatedEmployee || 0}</h3>
          </Grid>
          <Divider />
          <p>Eğitim Alanlar : {trainingedEmployee || 0}</p>
        </Grid>
      </CardContent>
      <DateRangeDialog close={() => setDialogVisibility(false)} opened={dialogVisibility} setRange={setRange} dateRange={dateRange} />
    </Card>
  );
};
