import { Chip, FormControl, FormHelperText, IconButton, InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LoadingIcon from '@material-ui/icons/Autorenew';
import PickIcon from '@material-ui/icons/PlaylistAdd';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { remove } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { stopPropagation } from 'utils';

import { Icd10CacheContext, Icd10Context, Icd10Item } from './icd10.context';

type ValueType = Icd10Item['id'];
type OnChangeFunc = (value: ValueType[]) => void;
type AutocompletePropsType = AutocompleteProps<ValueType, true, false, true>;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  autoRoot: {
    display: 'grid',
    maxWidth: '100%',
  },
  inputRoot: {
    paddingRight: '144px !important',
    position: 'relative',
    maxWidth: '100%',
  },
  inputAdornment: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 'auto',
    maxHeight: 'initial',
    paddingTop: 4,
    paddingRight: theme.spacing(1),
  },
  chipLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chipLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  yellowStar: {
    color: theme.palette.warning.light,
  },
}));

export default function Input() {
  const classes = useStyles();
  const { control, errors, trigger } = useFormContext();
  const { name, setSelectionOpened, setFavouritesOpened, disabled } = useContext(Icd10Context);
  const { labels, options, searchOptions, loading, favourites, toggleFavourite } = useContext(Icd10CacheContext);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const openSelection = useCallback(() => {
    if (disabled) {
      return;
    }

    inputRef.current.blur();
    setSelectionOpened(true);
  }, [setSelectionOpened, disabled]);

  const openFavourites = useCallback(() => {
    if (disabled) {
      return;
    }

    inputRef.current.blur();
    setFavouritesOpened(true);
  }, [setFavouritesOpened, disabled]);

  const renderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...params}
        variant="outlined"
        label="Hastalık (ICD-10)"
        inputRef={inputRef}
        InputProps={{
          ...params.InputProps,
          className: `${params.InputProps.className} ${classes.inputRoot}`,
          placeholder: 'Hastalık Ara',
          disabled,
          endAdornment: (
            <InputAdornment onClick={stopPropagation} position="end" className={classes.inputAdornment}>
              <IconButton onClick={() => !loading && inputRef.current.focus()}>
                {loading ? <LoadingIcon className="rotate" /> : <ArrowDropDownIcon />}
              </IconButton>
              <IconButton onClick={openFavourites} title="Favorilerden tanı ekle">
                <StarBorderIcon />
              </IconButton>
              <IconButton onClick={openSelection} title="Listeden tanı ekle">
                <PickIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ),
    [openSelection, loading, classes.inputRoot, classes.inputAdornment, disabled, openFavourites]
  );

  const handleInputChange = useCallback(
    async (_, value: string, reason) => {
      if (reason === 'reset' || disabled) {
        return;
      }

      setSearchText(value);
      searchOptions(value);
    },
    [searchOptions, disabled]
  );

  const handleChipDelete = useCallback(
    (onChange: OnChangeFunc, values: string[], value: string) => () => {
      if (disabled) {
        return;
      }

      onChange(remove(values, value));
      trigger(name);
    },
    [trigger, name, disabled]
  );

  const handleFavouriteToggle = useCallback(
    (id: string) => (e: React.MouseEvent) => {
      e.stopPropagation();
      return toggleFavourite(id);
    },
    [toggleFavourite]
  );

  const renderTags = useCallback<(onChange: OnChangeFunc) => AutocompletePropsType['renderTags']>(
    onChange => (values, getTagProps) =>
      values.map((value, index) => (
        <Chip
          key={value}
          {...getTagProps({ index })}
          variant="outlined"
          label={
            <div className={classes.chipLabelWrapper}>
              <IconButton
                className="p-0 mr-1 text-smaller"
                onClick={handleFavouriteToggle(value)}
                title={favourites.includes(value) ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}>
                {favourites.includes(value) ? <StarIcon className={classes.yellowStar} /> : <StarBorderIcon />}
              </IconButton>
              <span className={classes.chipLabel}>{labels[value]}</span>
            </div>
          }
          onDelete={handleChipDelete(onChange, values, value)}
        />
      )),
    [handleChipDelete, labels, favourites, classes, handleFavouriteToggle]
  );

  const renderOption = useCallback((option: ValueType) => <>{labels[option]}</>, [labels]);

  const getOptionLabel = useCallback(
    (option: ValueType) => {
      const { id, label, name: optionName } = options.find(item => item.id === option);

      return `${id} ${label} ${optionName}`;
    },
    [options]
  );

  const getAutocompleteOptions = useCallback(
    (values: ValueType[]) => options.filter(({ id }) => !(values || []).includes(id)).map(({ id }) => id),
    [options]
  );

  const autoCompleteOnChange = useCallback<(onChange: OnChangeFunc) => AutocompletePropsType['onChange']>(
    onChange => (_, newValues) => {
      if (disabled) {
        return;
      }

      onChange(newValues as ValueType[]);
      control.updateFormState({ touched: { ...control.formStateRef.current.touched, [name]: true } });
      setSearchText('');
      trigger(name);
    },
    [trigger, name, control, disabled]
  );

  const renderAutoComplete = useCallback<ControllerProps<any>['render']>(
    ({ onChange, value }) => (
      <Autocomplete<ValueType, true, true, true>
        className={classes.autoRoot}
        multiple
        openOnFocus={!disabled}
        freeSolo
        options={!disabled ? getAutocompleteOptions(value) : []}
        value={value}
        renderInput={renderInput}
        renderTags={renderTags(onChange)}
        inputValue={searchText}
        onInputChange={handleInputChange}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        onChange={autoCompleteOnChange(onChange)}
      />
    ),
    [
      renderInput,
      renderTags,
      handleInputChange,
      searchText,
      renderOption,
      getOptionLabel,
      getAutocompleteOptions,
      autoCompleteOnChange,
      classes.autoRoot,
      disabled,
    ]
  );

  return (
    <FormControl fullWidth className={classes.root}>
      <Controller control={control} defaultValue={[]} name={name} render={renderAutoComplete} />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
