/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CommitteeMemberDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    employeeId?: string;
    firmId?: string;
    committeeMemberPositionDefinition?: CommitteeMemberDto.committeeMemberPositionDefinition;
}

export namespace CommitteeMemberDto {

    export enum committeeMemberPositionDefinition {
        COMMITTEE_SECRETARY = 'COMMITTEE_SECRETARY',
        EMPLOYER_REPRESENTATIVE = 'EMPLOYER_REPRESENTATIVE',
        PHYSICIAN = 'PHYSICIAN',
        OCCUPATIONAL_SAFETY_SPECIALIST = 'OCCUPATIONAL_SAFETY_SPECIALIST',
    }


}
