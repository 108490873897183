/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisCategoryDefinitionDto } from '../models/AnalysisCategoryDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AnalysisCategoryDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmAnalysisDefinition(
        id: string,
        requestBody: AnalysisCategoryDefinitionDto,
    ): CancelablePromise<AnalysisCategoryDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/analysis-category-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmAnalysisDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/analysis-category-definitions/${id}`,
        });
    }

    /**
     * @param definitionId
     * @param requestBody
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static updateAnalysisDefinition(
        definitionId: string,
        requestBody: AnalysisCategoryDefinitionDto,
    ): CancelablePromise<AnalysisCategoryDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/analysis-category-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAnalysisDefinition(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/analysis-category-definitions/${definitionId}`,
        });
    }

    /**
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmAnalysisDefinitions(): CancelablePromise<Array<AnalysisCategoryDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/analysis-category-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmAnalysisDefinition(
        requestBody: AnalysisCategoryDefinitionDto,
    ): CancelablePromise<AnalysisCategoryDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/analysis-category-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static getAllAnalysisDefinitions(): CancelablePromise<Array<AnalysisCategoryDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/analysis-category-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AnalysisCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static saveAnalysisDefinition(
        requestBody: AnalysisCategoryDefinitionDto,
    ): CancelablePromise<AnalysisCategoryDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/analysis-category-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}