import { Button, FormControl, Grid } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../api/hs/employee';
import Dialog from '../../../Common/Dialog';
import AutoComplete from '../../../Common/Form/AutoComplete';
import Form, { FormRef } from '../../../Common/Form/Form';
import { RadioGroupInput } from '../../../Common/Form/RadioGroup';
import { SelectOption } from '../../../Common/Form/Select';
import TextField from '../../../Common/Form/TextField';
import LoadingWrapper from '../../../Common/LoadingWrapper';
import { useTriggerRender } from '../../../utils';

interface Props {
  accidentId: string;
  open: boolean;
  data: any;
  teamLeadDisabled: boolean;

  onFormData(data: any): void;

  onClose(): void;

  fetch(): void;
}
function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default ({ open, data, onClose, onFormData }: Props) => {
  const reRender = useTriggerRender();
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (values: any) => {
    setLoading(true);
    if (values.reviewerTypeOptions === 'external') {
      onFormData({
        id: guid(),
        reviewerTypeOptions: values?.reviewerTypeOptions,
        name: values?.name,
        isEditing: false,
        surname: values?.surname,
        email: values?.email,
      });
    } else {
      onFormData({ ...values, id: guid(), isEditing: false });
    }
    setLoading(false);
    onClose();
  };

  const reviewerTypeOptions = [
    {
      text: 'Dışarıdan',
      value: 'external',
    },
    {
      text: 'Firma Çalışanı',
      value: 'employee',
    },
  ];

  // const reviewerTypeResource = reviewerTypeMeanings?.filter(type =>
  //   teamLeadDisabled ? type.value !== AccidentReviewerDto.reviewerType.LEAD : type
  // );

  return (
    <Dialog width="md" color="secondary" opened={open} onClose={onClose} title={'Kullanıcı Ekle'}>
      <Form onChange={reRender} onSubmit={submit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} spacing={2}>
            <FormControl component="fieldset">
              <RadioGroupInput defaultValue={'external'} name="reviewerTypeOptions" options={reviewerTypeOptions} />
            </FormControl>
          </Grid>
          {formRef?.current?.value?.reviewerTypeOptions === 'external' ? (
            <>
              <Grid container spacing={2} alignItems={'baseline'} className={'m-2'}>
                <Grid item xs={12} sm={4}>
                  <TextField name="name" label="İsim" required defaultValue={data?.name} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField name="surname" label="Soyisim" required defaultValue={data?.surname} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField name="email" label="E-Mail" required defaultValue={data?.email} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <AutoComplete
                  single
                  name="employeeId"
                  label="Personel"
                  optionFetcher={employeeOptionFetcher}
                  labelFetcher={employeeLabelFetcher}
                />
              </Grid>
            </>
          )}
          <Grid item md={12} className="text-right">
            <LoadingWrapper loading={loading}>
              <Button className="mr-1" variant="contained" type="submit" color="secondary">
                {'Listeye Kaydet'}
              </Button>
            </LoadingWrapper>
            <Button variant="contained" type="button" onClick={onClose}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
};
