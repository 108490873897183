import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetHazardCategoriesResult } from './get-hazard-categories.result';

export async function getGetHazardCategories(): Promise<GetHazardCategoriesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetHazardCategoriesResult[]>(`/hazard-categories`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
