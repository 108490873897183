import { Breadcrumbs, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { deleteAnalysisParameters, getAnalysisParameters } from '../../../../../api/hs/definitions/health-definitions/analysis';
import { getAnalysisCategoryDefinitions } from '../../../../../api/hs/enum';
import { AlertContext } from '../../../../../context/alert.context';
import { DialogContext } from '../../../../../context/dialog.context';
import { useFetch } from '../../../../../utils';
import IndicatorWrapper from '../../../../IndicatorWrapper';
import Link from '../../../../Link';
import Toolbar from '../../../../Toolbar';
import AddButton from './AddButton';
import AnalysisParametersForm from './AnalysisParametersForm';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 2,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  toolbar: {
    display: 'grid',
    width: '100%',
    grid: '100% / max-content max-content 1fr minMax(340px, auto)',
  },
  customHoverFocus: {
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#F5F5F5',
      boxShadow: '0 7px 17px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12)',
    },
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '6px',
    left: '10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));
export const AnalysisParameterDetail = () => {
  const classes = useStyles();
  const { categoryDefinitionId } = useParams<{ categoryDefinitionId: string }>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { data, fetch } = useFetch(() => getAnalysisParameters(categoryDefinitionId), [], { setLoading });
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const { data: analysisCategoryDefinitions } = useFetch(() => getAnalysisCategoryDefinitions(), [], { setLoading });

  const title = findByKey(analysisCategoryDefinitions, 'id', categoryDefinitionId)?.label;

  const onEdit = useCallback(
    editedItem => {
      setOpen(true);
      setSelectedData(editedItem);
    },
    [setOpen, setSelectedData]
  );
  const onClose = useCallback(async () => {
    setOpen(false);
    setSelectedData(null);
    await fetch();
  }, [setOpen, setSelectedData, fetch]);
  const openDeleteDialog = useCallback(
    async deletedItem => {
      const submitted = await openWarnDialog({
        text: `${deletedItem?.label} - uyarısını silmek istediğinize emin misiniz ?`,
      });
      try {
        if (submitted) {
          await deleteAnalysisParameters(deletedItem?.id);
          showMessage('Silme işlemi başarılı.', 'success');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error');
      }
    },
    [fetch, openWarnDialog, showMessage]
  );
  return (
    <div className={classes.root}>
      <Toolbar className="mt-2" color="secondary" toolbarProps={{ className: classes.toolbar }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
          <Link style={{ color: 'inherit' }} to={'/definitions/health/tatlil-tetkik'}>
            Tahlil/Tetkik
          </Link>
          <Typography style={{ color: 'white' }}> {title}</Typography>
        </Breadcrumbs>
        <AddButton fetch={fetch} />
      </Toolbar>
      <br />
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Parametre Bulunamadı">
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={1}>
            {data?.map(d => (
              <Grid key={d.id} item lg={4} md={6} xs={12}>
                <Paper className={classes.customHoverFocus} elevation={2}>
                  <Grid item xs={12} container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom color="textPrimary" variant="subtitle1">
                          {d?.parameter}{' '}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Referans Aralığı:{' '}
                          <b>
                            {d?.min} - {d?.max}
                          </b>{' '}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Birim: <b>{d?.analysisParameterUnit}</b>{' '}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <IconButton onClick={() => onEdit(d)}>
                          <EditIcon fontSize={'default'} />
                        </IconButton>
                      </Typography>
                      <Typography variant="subtitle1">
                        <IconButton onClick={() => openDeleteDialog(d)}>
                          <Delete fontSize={'default'} />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </IndicatorWrapper>

      <AnalysisParametersForm onClose={onClose} open={open} data={selectedData} />
    </div>
  );
};
export default AnalysisParameterDetail;
