import React from 'react';

import BaseSection from '../BaseSection';
import PpeAssigmentEmployee from './ppe-assignments-employee';
import PpeOutputsEmployee from './ppe-outputs-employee';
import PpeOutputsGroupsEmployee from './ppe-outputs-groups-employee';

export default function PpePage() {
  return (
    <div className="p-2">
      <BaseSection icon="icon-file-multiple" title="Kullanılması Gereken KKD Listesi">
        <PpeAssigmentEmployee />
      </BaseSection>
      <BaseSection icon="icon-file-document" title="Zimmetli KKD Listesi">
        <PpeOutputsGroupsEmployee />
      </BaseSection>
      <BaseSection icon="icon-quick-reply" title="Personel KKD Hareketleri( İade Hareketleri )">
        <PpeOutputsEmployee />
      </BaseSection>
    </div>
  );
}
