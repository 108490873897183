import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { BloodRelationPayload } from './blood-relation.payload';
import { BloodRelationResult } from './blood-relation.result';

export async function getBloodRelation(): Promise<BloodRelationResult[]> {
  const [error, res] = await tryCatch(HSApi.get<BloodRelationResult[]>(`/firms/blood-relation-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveBloodRelation(body: BloodRelationPayload): Promise<BloodRelationResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/blood-relation-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateBloodRelation({ id, ...body }: MakeRequired<Partial<BloodRelationResult>, 'id'>): Promise<BloodRelationResult> {
  const [error, res] = await tryCatch(HSApi.put<BloodRelationResult>(`/firms/blood-relation-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteBloodRelation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/blood-relation-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminBloodRelation(): Promise<BloodRelationResult[]> {
  const [error, res] = await tryCatch(HSApi.get<BloodRelationResult[]>(`/blood-relation-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminBloodRelation(body: BloodRelationPayload): Promise<BloodRelationResult> {
  const [err, res] = await tryCatch(HSApi.post('/blood-relation-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminBloodRelation({
  id,
  ...body
}: MakeRequired<Partial<BloodRelationResult>, 'id'>): Promise<BloodRelationResult> {
  const [error, res] = await tryCatch(HSApi.put<BloodRelationResult>(`/blood-relation-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminBloodRelation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/blood-relation-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
