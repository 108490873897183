import { Dialog, DialogContent, DialogTitle, Grid, Icon, List, ListItem, ListSubheader, makeStyles } from '@material-ui/core';
import { defer, difference } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import TextField from 'Common/Form/TextField';
import { ResourceContext } from 'context/resource.context';
import { ExaminationType } from 'model';
import { dateToInputFormat, preventDefault, useAsyncEffect, useDialogState } from 'utils';

import DateInput from '../../../../../Common/Form/DateInput';
import { ProtocolNumberContext } from '../../../../../context/protocol-number.context';

const useStyles = makeStyles(() => ({
  newButton: {
    minWidth: 'max-content',
  },
  examButton: {
    textTransform: 'none',
    minWidth: 'initial',
  },
  protocolNoText: {
    fontSize: '12px',
    color: 'red',
  },
  spanPadding: {
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: '10px',
    color: 'red',
  },
}));

interface Props {
  onSubmit(values: { date: string; protocolNumber: { yearPrefix: number; number: number }; examinationDefinitionId: number }): void;
}

export default function NewExamination({ onSubmit }: Props) {
  const classes = useStyles();
  const { open, close, opened } = useDialogState();
  const { getValues, errors, trigger, reset, setValue, ...methods } = useForm();
  const { examinationDefinitions } = useContext(ResourceContext);
  const { ProtocolNextNumber, showProtocolNumber } = useContext(ProtocolNumberContext);

  const polyclinicExams = useMemo(() => examinationDefinitions.filter(item => item.type === ExaminationType.POLYCLINIC_EXAMINATION), [
    examinationDefinitions,
  ]);

  const otherExams = useMemo(() => difference(examinationDefinitions, polyclinicExams), [polyclinicExams, examinationDefinitions]);

  const createExamination = useCallback(
    (id: number) => () => {
      const formValues = getValues();

      close();
      onSubmit({
        protocolNumber: formValues.protocolNo ? { yearPrefix: new Date().getFullYear(), number: +formValues.protocolNo } : null,
        date: formValues.date,
        examinationDefinitionId: id,
      });
    },
    [getValues, onSubmit, close]
  );

  const hasError = !!Object.keys({ ...errors }).length;

  useAsyncEffect(async () => {
    if (!opened) {
      return;
    }

    await defer();
    // reset();
    setValue('date', dateToInputFormat(new Date()));
    trigger();
  }, [trigger, opened, reset]);

  useAsyncEffect(() => {
    ProtocolNextNumber('PHYSICIAN');
  }, []);
  return (
    <>
      <Dialog open={opened} fullWidth maxWidth="md" onClose={close}>
        <DialogTitle>Yeni Muayene Oluştur</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...methods, getValues, errors, trigger, reset, setValue }}>
            <form onSubmit={preventDefault}>
              <Grid container>
                <Grid item sm={6} className="px-1">
                  <DateInput name="date" type="date" label="Muayene Tarihi" required />
                </Grid>
                <Grid item sm={6} className="px-1">
                  <TextField name="protocolNo" label="Protokol Numarası" />
                  <span className={classes.protocolNoText}>
                    *Protokol Numarası boş bırakıldığında oluşacak numara:{' '}
                    {showProtocolNumber?.yearPrefix + '/' + String(showProtocolNumber?.number).padStart(4, '0')}
                  </span>
                </Grid>
                <Grid item sm={6} className="px-1">
                  <List>
                    <ListSubheader>Sağlık gözetimi yapmak için bir muayene seçiniz.</ListSubheader>
                    {otherExams.map(exam => (
                      <ListItem key={exam.id} className="py-0">
                        <Button type="submit" className={classes.examButton} onClick={createExamination(exam.id)} disabled={hasError}>
                          {exam.examinationName}
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item sm={6} className="px-1">
                  <List>
                    <ListSubheader>Poliklinik işlemler yapmak için bir muayene seçiniz.</ListSubheader>
                    <span className={classes.spanPadding}>(Bu muayeneler bakanlığa gönderilememektedir)</span>

                    {polyclinicExams.map(exam => (
                      <ListItem key={exam.id} className="py-0">
                        <Button type="submit" className={classes.examButton} onClick={createExamination(exam.id)} disabled={hasError}>
                          {exam.examinationName}
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <Button className={`${classes.newButton} text-white`} onClick={open}>
        Yeni Muayene
        <Icon className="icon-stethoscope ml-2" />
      </Button>
    </>
  );
}
