import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useContext, useState } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';

import VisibleColumnMenu from './VisibleColumnMenu';
import {exportVaccine} from "../../api/hs/TransactionAndLists/VaccineList/vaccine-list";

const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function ToolbarVaccine() {
  const { rawData } = useContext(HSTableContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const classes = useStyles();

  const exportFile = useCallback(async () => {
    await exportVaccine();
    closeMenu();
  }, [closeMenu]);

  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Aşı Kayıt Listesi ( {rawData?.totalElements} )
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <VisibleColumnMenu />
        <IconButton color="inherit" onClick={openMenu}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -125 }} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <List>
            <ListItem onClick={exportFile} className="cursor-pointer">
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Excel'e Aktar</Typography>
            </ListItem>
          </List>
        </Popover>
      </div>
    </Toolbar>
  );
}
