import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import {
  AudioDetailRequest,
  AudioDetailView,
  AudioDetailViewControllerService,
  PftDetailView,
  PftDetailViewControllerService,
} from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { convertClusteredDataByFieldName } from '../utils/clustered-chart-data-converter-xy';
import ClusteredXYColumnChart from '../utils/ClusteredXYColumnChart';
import ReportForm from '../utils/ReportForm';

export default function OdioSft() {
  const [chartData, setChartData] = useState<AudioDetailView[]>([]);
  const [pftChartData, setPftChartData] = useState<PftDetailView[]>([]);
  const [collapsed, setCollapsed] = useState(false);

  const submit = useCallback(async values => {
    const request: AudioDetailRequest = {
      filter: {
        dateMin: values?.minDate + 'T00:00:00',
        dateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
      },
    };
    const res = await AudioDetailViewControllerService.getEmployeeEAudioDetailsFiltered(request);
    setChartData(res.content);
    const pftRes = await PftDetailViewControllerService.getEmployeeEPftDetailsFiltered(request);
    setPftChartData(pftRes.content);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Odyo / SFT İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm onSubmit={submit} collapsed={collapsed} setCollapsed={setCollapsed} />

      <>
        <Grid container>
          {chartData.length! > 0 && (
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }} raised>
                <CardHeader title="Odyo" />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Şirketlere Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="companyAudioXY"
                          data={convertClusteredDataByFieldName(chartData, 'companyName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Departmanlara Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="departmentAudioXY"
                          data={convertClusteredDataByFieldName(chartData, 'departmentName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Görevlere Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="positionAudioXY"
                          data={convertClusteredDataByFieldName(chartData, 'positionName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="İş İstasyonlarına Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="stationAudioXY"
                          data={convertClusteredDataByFieldName(chartData, 'stationName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {pftChartData.length > 0 && (
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }} raised>
                <CardHeader title="SFT" />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Şirketlere Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="companyPftXY"
                          data={convertClusteredDataByFieldName(pftChartData, 'companyName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Departmentlara Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="departmentPftXY"
                          data={convertClusteredDataByFieldName(pftChartData, 'departmentName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="İş İstasyonlarına Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="stationPftXY"
                          data={convertClusteredDataByFieldName(pftChartData, 'stationName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Görevlere Göre Dağılımı" />{' '}
                        <ClusteredXYColumnChart
                          chartName="positionPftXY"
                          data={convertClusteredDataByFieldName(pftChartData, 'positionName', 'categoryLabel')}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </>
    </div>
  );
}
