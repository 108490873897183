import { debounceWithKey } from '@thalesrc/js-utils';
import { useState } from 'react';

type Parameters<T> = T extends (...args: infer U) => any ? U : never;
type ReturnType<T> = T extends (...args: any[]) => infer U ? (U extends Promise<infer V> ? V : U) : never;

export default function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  time = 300
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  const [keys] = useState(new Map<any[], symbol>());

  return async (...args: Parameters<T>) => {
    let keyEntry = [...keys.keys()].find(keyArr => keyArr.every((v, i) => args[i] === v));

    if (!keyEntry) {
      const newKey = Symbol('useDebouncedCallback key');
      keyEntry = args;

      keys.set(keyEntry, newKey);
    }

    const key = keys.get(keyEntry);

    return await debounceWithKey(key, callback, time, null, ...args);
  };
}
