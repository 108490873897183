import { tryCatch } from '@thalesrc/js-utils';

import { DateRange } from '../../dashboard/widget/widget.payload';
import HSApi from '../../HSApi';
import { excelExport, handleResult } from '../../utils';

export async function getTrainingCounts(dateRange?: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/trainings/widgets/count${dateRange?.toString()}`));

  return handleResult(res, error);
}

export async function exportTrainingsWidgetExport(dateRange: DateRange, fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/trainings/widgets/views/export${dateRange.toString()}`, {
      responseType: 'blob',
    })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function getTrainingsWidgetView(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/trainings/widgets/views${dateRange.toString()}`));

  return handleResult(res, error);
}

export async function getTrainingsCount(request: any) {
  const [error, res] = await tryCatch(HSApi.get(`/training/employee/views/count?request=${request}`));

  return handleResult(res, error);
}

export async function exportExpiredTrainingEmployeesExport(fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/training/employees/views/expired/export`, {
      responseType: 'blob',
    })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function exportTrainingWidgetsEmployeesExport(payload: any, fileName: string) {
  const [error, res] = await tryCatch(HSApi.post(`training/employees/view/export`, payload, { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}
