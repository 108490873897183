/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FineKinneyHazardScoreDefinitionDto } from '../models/FineKinneyHazardScoreDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FineKinneyHazardScoreDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardScoreDefinitionDto OK
     * @throws ApiError
     */
    public static updateFineKinneyScoreDefinitions(
        id: string,
        requestBody: FineKinneyHazardScoreDefinitionDto,
    ): CancelablePromise<FineKinneyHazardScoreDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/fine-kinney-score-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllFineKinneyScoreDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/fine-kinney-score-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardScoreDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmFineKinneyScoreDefinitions(
        id: string,
        requestBody: FineKinneyHazardScoreDefinitionDto,
    ): CancelablePromise<FineKinneyHazardScoreDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/fine-kinney-score-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FineKinneyHazardScoreDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFineKinneyScoreDefinitions(): CancelablePromise<Array<FineKinneyHazardScoreDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/fine-kinney-score-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyHazardScoreDefinitionDto OK
     * @throws ApiError
     */
    public static saveFineKinneyScoreDefinitions(
        requestBody: FineKinneyHazardScoreDefinitionDto,
    ): CancelablePromise<FineKinneyHazardScoreDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/fine-kinney-score-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FineKinneyHazardScoreDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmFineKinneyScoreDefinitions(): CancelablePromise<Array<FineKinneyHazardScoreDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/fine-kinney-score-definitions`,
        });
    }

}