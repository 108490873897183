import React, { useCallback, useContext } from 'react';

import { TypeDefinitionsContext } from '../../type-definitions.context';
import TypeDefinitionsForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(TypeDefinitionsContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <TypeDefinitionsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
