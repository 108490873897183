import React from 'react';

import EmployeeSelectionContextProvider, { EmployeeSelectionContextProviderProps } from '../EmployeeSelectionContextProvider';
import Box from './Box';
import Input from './Input';

export default function EmployeeSelectionBox(props: Exclude<EmployeeSelectionContextProviderProps, 'single'>) {
  return (
    <EmployeeSelectionContextProvider {...props} single={false}>
      <Input />
      <Box />
    </EmployeeSelectionContextProvider>
  );
}
