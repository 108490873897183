import React, { useState } from 'react';

import { FirmModel, getFirms } from 'api/hs/definitions/firm-definitions/firms';
import { useFetch } from 'utils';

import { FirmsContext } from './firm.context';
import Firms from './firms';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<FirmModel>(null);

  const { data, fetch } = useFetch(() => getFirms(), [], { setLoading });

  return (
    <FirmsContext.Provider
      value={{
        firms: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Firms />
    </FirmsContext.Provider>
  );
}
