import {
  Avatar,
  Divider,
  List as MUIList,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useContext } from 'react';

import { ResourceContext } from 'context/resource.context';

import { dateDisplayFormat } from '../../../../../utils';
import { ProfessionalBackgroundContext } from '../professional-background.context';
import Action from './Action';

const useStyles = makeStyles(theme => ({
  secondBlock: {
    display: 'block',
  },
  thirdBlock: {
    fontSize: 'smaller',
  },
}));

export default function List() {
  const classes = useStyles();
  const { backgrounds } = useContext(ProfessionalBackgroundContext);
  const { exposures } = useContext(ResourceContext);

  return (
    <MUIList style={{ width: '100%' }}>
      {backgrounds.map(background => (
        <Fragment key={background.id}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DescriptionOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${background.companyName} ${background.job ? ' - ' + background.job : ''}`}
              secondary={
                <>
                  <Typography component="strong" className={classes.secondBlock}>
                    {background.hireDate ? dateDisplayFormat(background.hireDate) : null}{' '}
                    {background.quitDate ? ' - ' + dateDisplayFormat(background.quitDate) : ''}
                  </Typography>
                  <Typography component="span" className={classes.thirdBlock}>
                    Maruziyetler: {background.exposures.map(id => findByKey(exposures, 'id', id)?.exposureName).join(' - ')}
                  </Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              <Action background={background} />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </MUIList>
  );
}
