/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageTrainingView } from '../models/PageTrainingView';
import type { TrainingViewRequest } from '../models/TrainingViewRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TrainingViewControllerService {

    /**
     * @param requestBody
     * @returns PageTrainingView OK
     * @throws ApiError
     */
    public static getAllTrainingViews(
        requestBody?: TrainingViewRequest,
    ): CancelablePromise<PageTrainingView> {
        return __request({
            method: 'POST',
            path: `/trainings/view`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static trainingsViewExport(
        requestBody?: TrainingViewRequest,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/trainings/view/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}