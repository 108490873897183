import { format } from 'date-fns';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import { SelectOption } from 'Common/Form/Select';
import { HAZAR_CLASS_ENUM, YES_NO_ENUM } from 'model';
import { RoleNames as roleNames } from 'model/user-role.enum';
import { mapEnumsToOptions, ValueOf } from 'utils';

import { EnumContext, EnumContextType } from './enum.context';
import { ResourceContext, ResourceContextType } from './resource.context';

interface SelectOptionsContextType {
  // Static
  yesNo: SelectOption<number>[];
  hazarClass: SelectOption<number>[];
  yesNoBoolean: SelectOption<boolean>[];
  periodOfValidity: SelectOption<boolean>[];
  internalStatus: SelectOption<boolean>[];
  availabilities: SelectOption<number>[];
  userRoles: SelectOption<string>[];
  periodUnit: SelectOption<string>[];
  availabilitiesBoolean: SelectOption<boolean>[];
  trainingDocumentType: SelectOption<string>[];
  trainingLocation: SelectOption<boolean>[];
  trainingType: SelectOption<boolean>[];
  polyclinicOpinions: SelectOption<number>[];
  trainingsCertificateType: SelectOption<string>[];
  trainerType: SelectOption<number>[];
  reportType: SelectOption<string>[];
  opinion: SelectOption<string>[];
  sendType: SelectOption<string>[];
  isPlannedType: SelectOption<boolean>[];
  icd10ViewType: SelectOption<string>[];
  weeks: SelectOption<string>[];
  // Enum
  bloodDonations: SelectOption<number>[];
  bloodTypes: SelectOption<number>[];
  educationStatuses: SelectOption<number>[];
  genders: SelectOption<number>[];
  maritialStatuses: SelectOption<number>[];
  workingTypes: SelectOption<number>[];
  pregnancyStates: SelectOption<number>[];
  addictions: SelectOption<number>[];
  addictionStates: SelectOption<number>[];
  complaintDefinitions: SelectOption<number>[];
  medicalHistoryDefinitions: SelectOption<number>[];
  hospitalTypes: SelectOption<number>[];
  prescriptionTypeDefinitions: SelectOption<number>[];
  assignableTypeDefinitions: SelectOption<string>[];
  prescriptionMedicineUsageDefinitions: SelectOption<number>[];
  prescriptionDescriptionDefinitions: SelectOption<number>[];
  prescriptionMedicinePeriodUnitDefinitions: SelectOption<number>[];
  prescriptionProvisionDefinitions: SelectOption<number>[];
  prescriptionSubtypeDefinitions: SelectOption<number>[];
  satietyInformationDefinitions: SelectOption<number>[];
  prescriptionUnitDoseDefinitions: SelectOption<number>[];
  immunityStatuses: SelectOption<number>[];
  certificateDefinitions: SelectOption<number>[];
  analysisCategoryDefinitions: SelectOption<number>[];
  physicalWorkConditionAirDefinitions: SelectOption<number>[];
  physicalWorkConditionChemicalMatterDefinitions: SelectOption<number>[];
  physicalWorkConditionBiologicalFactorsDefinition: SelectOption<number>[];
  physicalWorkConditionDustRegulationDefinitions: SelectOption<number>[];
  physicalWorkConditionElectricityDefinitions: SelectOption<number>[];
  physicalWorkConditionNoiseDefinitions: SelectOption<number>[];
  physicalWorkConditionPhysicalEnvironmentDefinitions: SelectOption<number>[];
  vehicleOperationDefinitions: SelectOption<number>[];
  workingPositionDefinitions: SelectOption<number>[];
  workingTimeDefinitions: SelectOption<number>[];
  reasons: SelectOption<string>[];
  actionsStatusDefinitions: SelectOption<string>[];
  actionsReferenceDefinitions: SelectOption<string>[];
  actionsPriorityDefinitions: SelectOption<string>[];
  committeeMembersPositionDefinitions: SelectOption<string>[];
  committeeMeetingStatusDefinitions: SelectOption<string>[];

  // Resource
  companies: SelectOption<string>[];
  departments: SelectOption<string>[];
  exposures: SelectOption<number>[];
  locations: SelectOption<string>[];
  positions: SelectOption<string>[];
  workStations: SelectOption<string>[];
  disabilities: SelectOption<string>[];
  allergies: SelectOption<string>[];
  hazardCategories: SelectOption<number>[];
  firmUser: SelectOption<string>[];
  referralSection: SelectOption<string>[];
  bloodRelation: SelectOption<string>[];
  vaccines: SelectOption<string>[];
  restReportDefinitions: SelectOption<string>[];
  systemCheck: SelectOption<number>[];
  physicalCheck: SelectOption<string>[];
  workingRestrictionDefinitions: SelectOption<number>[];
  branchCodes: SelectOption<number>[];
  examinationDefinitions: SelectOption<number>[];
  firms: SelectOption<string>[];
  trainingDefinitions: SelectOption<string>[];
  trainingGroupDefinitions: SelectOption<string>[];
  trainingMainGroupDefinitions: SelectOption<string>[];
  trainers: SelectOption<string>[];
  hsExamOpinionDefinitions: SelectOption<string>[];
  trainings: SelectOption<string>[];
  ppeDefinitions: SelectOption<string>[];
  storeHouse: SelectOption<string>[];
  ppeClassificationDefinitions: SelectOption<string>[];
  ppeTypeDefinitions: SelectOption<string>[];
  workEquipmentTypeDefinitions: SelectOption<string>[];
  buildings: SelectOption<string>[];
  workEquipmentDefinition: SelectOption<string>[];
  accidentTypes: SelectOption<string>[];
  nearMissTypes: SelectOption<string>[];
  shiftDefinitions: SelectOption<string>[];
  employeeBodyRegions: SelectOption<string>[];
  employeeInjuryTypes: SelectOption<string>[];
  accidentSeverityTypes: SelectOption<string>[];
  accidentReviewCsiFileTypes: SelectOption<string>[];
  accidentReviewDocumentFileTypes: SelectOption<string>[];
  accidentReviewStatus: SelectOption<string>[];
  bloodRelations: SelectOption<string>[];
  matrixPossibilityDefinition: SelectOption<string>[];
  matrixHazardDamageDefinition: SelectOption<string>[];
  hazardCurrentPrecautionDefinition: SelectOption<string>[];
  hazardCategoryDefinition: SelectOption<string>[];
  fineKinneyHazardFrequencyDefinition: SelectOption<string>[];
  fineKinneyPossibilityDefinition: SelectOption<string>[];
  fineKinneyHazardDamageDefinition: SelectOption<string>[];
  riskAssessmentMatrixScoreDefinition: SelectOption<string>[];
  fineKinneyHazardScoreDefinition: SelectOption<string>[];
  nurseOperationDefinition: SelectOption<string>[];
  accidentReviewBehaviorDefinition: SelectOption<string>[];
  accidentReviewFactDefinition: SelectOption<string>[];
  accidentReviewPersonalDefinition: SelectOption<string>[];
  accidentReviewOrganizationDefinition: SelectOption<string>[];
  accidentReviewEquipmentDefinition: SelectOption<string>[];
  accidentReviewMaterialDefinition: SelectOption<string>[];
  accidentReviewImprovementDefinition: SelectOption<string>[];
  emergencyCaseDefinition: SelectOption<string>[];
}

export const SelectOptionsContext = createContext<SelectOptionsContextType>(null);

const STATIC_OPTIONS: {
  [P in Exclude<keyof SelectOptionsContextType, keyof (EnumContextType & ResourceContextType)>]: SelectOptionsContextType[P];
} = {
  yesNo: YES_NO_ENUM.map<SelectOption<number>>(({ id, label }) => ({
    text: label,
    value: id,
  })),
  hazarClass: HAZAR_CLASS_ENUM.map<SelectOption<number>>(({ id, label }) => ({
    text: label,
    value: id,
  })),
  yesNoBoolean: [
    { text: 'Evet', value: true },
    { text: 'Hayır', value: false },
  ],
  internalStatus: [
    { text: 'Kurum içi', value: true },
    { text: 'Kurum Dışı', value: false },
  ],
  availabilities: [
    { text: 'Var', value: 1 },
    { text: 'Yok', value: 0 },
  ],
  userRoles: Array.from(roleNames.entries()).map(obj => ({ text: obj[1], value: obj[0] })),
  periodUnit: [
    { text: 'Günde', value: 'DAILY' },
    { text: 'Haftada', value: 'WEEKLY' },
    { text: 'Ayda', value: 'MONTHLY' },
    { text: 'Yılda', value: 'YEARLY' },
  ],
  availabilitiesBoolean: [
    { text: 'Var', value: true },
    { text: 'Yok', value: false },
  ],
  accidentReviewStatus: [
    { text: 'Yok', value: 'NOT_STARTED' },
    { text: 'Devam Ediyor', value: 'IN_PROGRESS' },
    { text: 'Tamamlandı', value: 'COMPLETED' },
  ],
  icd10ViewType: [
    { text: 'Tüm Muayeneler', value: 'ALL_EXAMINATION' },
    { text: 'Son Muayene', value: 'LAST_EXAMINATION' },
  ],
  trainingDocumentType: [
    { text: 'İş Yeri Hekimi', value: 'WORKPLACE_PHYSICIAN' },
    { text: 'İş Güvenliği Uzmanı', value: 'JOB_SECURITY_SPECIALIST' },
    { text: 'Diğer', value: 'OTHER' },
  ],
  assignableTypeDefinitions: [
    { text: 'Atanamaz', value: 'NOT_ASSIGNABLE' },
    { text: 'Tüm çalışanlar için zorunlu', value: 'MANDATORY_FOR_ALL_EMPLOYEE' },
    { text: 'Atanabilir', value: 'ASSIGNABLE' },
  ],
  weeks: [
    {
      value: 'MON',
      text: 'Pazartesi',
    },
    {
      value: 'TUE',
      text: 'Salı',
    },
    {
      value: 'WED',
      text: 'Çarşamba',
    },
    {
      value: 'THU',
      text: 'Perşembe',
    },
    {
      value: 'FRI',
      text: 'Cuma',
    },
    {
      value: 'SAT',
      text: 'Cumartesi',
    },
    {
      value: 'SUN',
      text: 'Pazar',
    },
  ],
  trainingLocation: [
    { text: 'İş Yeri Dışında', value: false },
    { text: 'İş Yerinde', value: true },
  ],
  trainingType: [
    { text: 'Yüzyüze', value: true },
    { text: 'Uzaktan', value: false },
  ],
  trainingsCertificateType: [
    { text: 'Ön Tanımlı', value: 'PREDEFINED' },
    { text: 'Bakanlık', value: 'PREDEFINED_LEGAL' },
  ],

  trainerType: [
    { text: 'İşyeri Hekimi', value: 2 },
    { text: 'İş Güvenliği Uzmanı', value: 1 },
  ],

  polyclinicOpinions: [
    { text: 'Sevk Edildi', value: 94 },
    { text: 'İş Başı Yaptı', value: 95 },
    { text: 'Rapor Verildi', value: 96 },
  ],

  reportType: [
    { text: 'İşe Giriş Muayene', value: 'RECRUITMENT_EXAMINATION' },
    { text: 'Periyodik Muayene', value: 'PERIODIC_EXAMINATION' },
    { text: 'İşe Dönüş Muayenesi', value: 'RETURN_TO_WORK_EXAMINATION' },
    { text: 'İş Değişikliği', value: 'JOB_CHANGE_EXAMINATION' },
  ],
  opinion: [
    { text: 'Çalışabilir', value: 'COULD_WORK' },
    { text: 'Şartlı Çalışabilir', value: 'CONDITIONAL_WORK' },
    { text: 'İş Değişikliği', value: 'CHANGE_WORK' },
    { text: 'Çalışamaz', value: 'COULD_NOT_WORK' },
  ],
  sendType: [
    { text: 'Gönderildi', value: 'SENT' },
    { text: 'Gönderilmedi', value: 'NOT_SENT' },
    { text: 'Beklemede', value: 'ON_STAND_BY' },
    { text: 'Çalışamaz', value: 'ERROR' },
  ],
  isPlannedType: [
    { text: 'Planlanmış Eğitim ', value: true },
    { text: 'Planlanmamış Eğitim', value: false },
  ],

  reasons: [
    { text: 'Zimmet', value: 'DEBIT' },
    { text: 'İade', value: 'REFUND' },
    { text: 'Kullanım Süresi Dolmuş', value: 'EXPIRED' },
    { text: 'Hatalı', value: 'DEFECTIVE' },
    { text: 'Bozuk', value: 'BROKEN' },
  ],

  periodOfValidity: [
    { text: 'Geçerlilik Süresi Dolmuş', value: true },
    { text: 'Geçerlilik Süresi Dolmamış', value: false },
  ],
};

const ENUM_PROPS: (keyof EnumContextType)[] = [
  'bloodDonations',
  'bloodTypes',
  'educationStatuses',
  'genders',
  'maritialStatuses',
  'workingTypes',
  'pregnancyStates',
  'addictions',
  'addictionStates',
  'complaintDefinitions',
  'medicalHistoryDefinitions',
  'hospitalTypes',
  'immunityStatuses',
  'analysisCategoryDefinitions',
  'prescriptionTypeDefinitions',
  'prescriptionMedicineUsageDefinitions',
  'prescriptionDescriptionDefinitions',
  'prescriptionMedicinePeriodUnitDefinitions',
  'prescriptionProvisionDefinitions',
  'prescriptionSubtypeDefinitions',
  'satietyInformationDefinitions',
  'prescriptionUnitDoseDefinitions',
  'certificateDefinitions',
  'physicalWorkConditionAirDefinitions',
  'physicalWorkConditionChemicalMatterDefinitions',
  'physicalWorkConditionBiologicalFactorsDefinition',
  'physicalWorkConditionDustRegulationDefinitions',
  'physicalWorkConditionElectricityDefinitions',
  'physicalWorkConditionNoiseDefinitions',
  'physicalWorkConditionPhysicalEnvironmentDefinitions',
  'vehicleOperationDefinitions',
  'workingPositionDefinitions',
  'workingTimeDefinitions',
  'actionsPriorityDefinitions',
  'actionsReferenceDefinitions',
  'actionsStatusDefinitions',
  'committeeMembersPositionDefinitions',
  'committeeMeetingStatusDefinitions',
];

const RESOURCE_MAPPERS: { [P in keyof ResourceContextType]: (value: ResourceContextType[P]) => SelectOption<number | string>[] } = {
  companies: value => value.map<SelectOption<string>>(({ id, companyName }) => ({ value: id, text: companyName })),
  departments: value => value.map<SelectOption<number>>(({ id, departmentName }) => ({ value: id, text: departmentName })),
  exposures: value => value.map<SelectOption<string>>(({ id, exposureName }) => ({ value: id, text: exposureName })),
  locations: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  positions: value => value.map<SelectOption<string>>(({ id, positionName }) => ({ value: id, text: positionName })),
  workStations: value => value.map<SelectOption<string>>(({ id, stationName }) => ({ value: id, text: stationName })),
  disabilities: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  allergies: value => value.map<SelectOption<string>>(({ id, allergyName }) => ({ value: id, text: allergyName })),
  hazardCategories: value => value.map<SelectOption<number>>(({ id, definition }) => ({ value: id, text: definition })),
  firmUser: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  referralSection: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  bloodRelation: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  vaccines: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  systemCheck: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  physicalCheck: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  restReportDefinitions: value =>
    value.map<SelectOption<string>>(({ id, reasonName }) => ({
      value: id,
      text: reasonName,
    })),
  workingRestrictionDefinitions: value =>
    value.map<SelectOption<number>>(({ id, opinion }) => ({
      value: id,
      text: opinion,
    })),
  examinationDefinitions: value =>
    value.map<SelectOption<number>>(({ id, examinationName }) => ({
      value: id,
      text: examinationName,
    })),
  emergencyCaseDefinition: value =>
    value.map<SelectOption<string>>(({ id, label }) => ({
      value: id,
      text: label,
    })),
  branchCodes: value => value.map<SelectOption<string>>(({ no, name }) => ({ value: no, text: name })),
  firms: value => value.map<SelectOption<string>>(({ id, firmName }) => ({ value: id, text: firmName })),
  hazardCategoryDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  hazardCurrentPrecautionDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  matrixHazardDamageDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, value: values }) => ({ value: id, text: `${values} - ${label}` })),
  matrixPossibilityDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, value: values }) => ({ value: id, text: `${values} - ${label}` })),
  fineKinneyHazardDamageDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, value: values }) => ({ value: id, text: `${values} - ${label}` })),
  fineKinneyHazardFrequencyDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, value: values }) => ({ value: id, text: `${values} - ${label}` })),
  fineKinneyPossibilityDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, value: values }) => ({ value: id, text: `${values} - ${label}` })),
  fineKinneyHazardScoreDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, score: values }) => ({ value: id, text: `${values} - ${label}` })),
  riskAssessmentMatrixScoreDefinition: value =>
    value.map<SelectOption<string>>(({ id, label, score: values }) => ({ value: id, text: `${values} - ${label}` })),
  trainingDefinitions: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  trainingGroupDefinitions: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  trainingMainGroupDefinitions: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  trainers: value =>
    value.map<SelectOption<string>>(({ id, name, lastName }) => ({
      value: id,
      text: `${name} ${lastName}`,
    })),
  hsExamOpinionDefinitions: value => value.map(({ opinion, id }) => ({ text: opinion, value: id })),
  ppeDefinitions: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  storeHouse: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  ppeClassificationDefinitions: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  ppeTypeDefinitions: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  workEquipmentTypeDefinitions: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  workEquipmentDefinition: value =>
    value.map<SelectOption<string>>(({ definitionId, definitionName }) => ({ value: definitionId, text: definitionName })),
  buildings: value => value.map<SelectOption<string>>(({ id, name }) => ({ value: id, text: name })),
  bloodRelations: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  nearMissTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  shiftDefinitions: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  employeeBodyRegions: value => value.map<SelectOption<string>>(({ id, code, label }) => ({ id, value: code, text: label })),
  employeeInjuryTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentSeverityTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewCsiFileTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewDocumentFileTypes: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  nurseOperationDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  trainings: value =>
    value.map<SelectOption<string>>(({ title, id, plannedDate }) => ({
      text: `${title} - ${format(new Date(plannedDate), 'dd-mm-yyyy HH:mm')}`,
      value: id,
    })),
  accidentReviewBehaviorDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewFactDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewPersonalDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewOrganizationDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewEquipmentDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewMaterialDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
  accidentReviewImprovementDefinition: value => value.map<SelectOption<string>>(({ id, label }) => ({ value: id, text: label })),
};

export function SelectOptionsContextProvider({ children }: PropsWithChildren<unknown>) {
  const enums = useContext(EnumContext);
  const resources = useContext(ResourceContext);
  const [map] = useState(new WeakMap<ValueOf<EnumContextType & ResourceContextType>, SelectOption<string | number>>());

  const proxy = new Proxy(
    {},
    {
      // eslint-disable-next-line
      get(_, prop: keyof SelectOptionsContextType) {
        if (prop in STATIC_OPTIONS) {
          return (STATIC_OPTIONS as any)[prop];
        }

        const domain = (ENUM_PROPS.includes(prop as any) ? enums : resources) as EnumContextType & ResourceContextType;
        const value = (domain as any)[prop];

        if (!map.has(value)) {
          map.set(value, domain === enums ? mapEnumsToOptions(value as any) : (RESOURCE_MAPPERS as any)[prop](value));
        }

        return map.get(value);
      },
    }
  );

  return <SelectOptionsContext.Provider value={proxy as any}>{children}</SelectOptionsContext.Provider>;
}
