import EditIcon from '@material-ui/icons/Edit';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import Button from '../../../../Common/Button';
import { ResourceContext } from '../../../../context/resource.context';
import { CaseTeamsDetailContext } from '../../case-teams-detail.context';

export default function AddButtonNewCaseTeamTrainings() {
  const { setNewCaseTrainingsNewDialog, emergencyCaseTeams } = useContext(CaseTeamsDetailContext);
  const { emergencyCaseDefinition } = useContext(ResourceContext);

  const onClick = useCallback(() => {
    setNewCaseTrainingsNewDialog(true);
  }, [setNewCaseTrainingsNewDialog]);

  return (
    <>
      <header className="text-right mt-1 mb-1">
        <Button variant="contained" startIcon={<EditIcon />} color="secondary" onClick={onClick} style={{ marginRight: '120px' }}>
          {`${findByKey(emergencyCaseDefinition, 'id', emergencyCaseTeams?.emergencyCaseDefinitionId)?.label.toUpperCase()} Eğitim Atama`}
        </Button>
      </header>
    </>
  );
}
