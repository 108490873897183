/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AudioCategoryDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: AudioCategoryDefinitionDto.id;
    label?: string;
    description?: string;
}

export namespace AudioCategoryDefinitionDto {

    export enum id {
        CATEGORY1 = 'CATEGORY1',
        CATEGORY2 = 'CATEGORY2',
        CATEGORY_SSI_2A = 'CATEGORY_SSI_2A',
        CATEGORY_SSI_2B = 'CATEGORY_SSI_2B',
        CATEGORY3 = 'CATEGORY3',
        CATEGORY4 = 'CATEGORY4',
        CATEGORY5 = 'CATEGORY5',
        CATEGORY_SSI_1 = 'CATEGORY_SSI_1',
    }


}
