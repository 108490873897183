import { Paper } from '@material-ui/core';
import React from 'react';

import Reports from './Reports';

export default function ReportsPage() {
  return (
    <div className="p-2">
      <Paper className="p-2" elevation={3}>
        <Reports diagnosis={[]} />
      </Paper>
    </div>
  );
}
