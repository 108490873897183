import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { ProfessionalBackground } from 'model';

import { dateSort } from '../../../utils';
import { ProfessionalBackgroundPayload } from './professional-background.payload';
import { ProfessionalBackgroundResult } from './professional-background.result';

export type ConvertedProfessionalBackgroundPayload = Omit<ProfessionalBackgroundPayload, 'professionalBackgroundExposures'> & {
  exposures: number[];
};

function mapPayload({ exposures, ...payload }: ConvertedProfessionalBackgroundPayload): ProfessionalBackgroundPayload {
  return {
    ...payload,
    professionalBackgroundExposures: exposures.map(e => ({ exposureDefinitionId: e })),
  };
}

export async function getProfessionalBackgrounds(employeeId: string): Promise<ProfessionalBackground[]> {
  const [err, res] = await tryCatch(HSApi.get<ProfessionalBackgroundResult>(`/professional-backgrounds?employeeId=${employeeId}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return dateSort(res.data)?.map(data => ({
    ...data,
    exposures: data.professionalBackgroundExposures.map(exps => exps.exposureDefinitionId),
  }));
}

export async function getProfessionalBackground(employeeId: string, examinationId: string): Promise<ProfessionalBackgroundResult[]> {
  const [err, res] = await tryCatch(
    HSApi.get<ProfessionalBackgroundResult[]>(`/professional-backgrounds?employeeId=${employeeId}&examinationId=${examinationId}`)
  );

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveProfessionalBackground(payload: ConvertedProfessionalBackgroundPayload): Promise<ProfessionalBackgroundResult> {
  const [err, res] = await tryCatch(HSApi.post('/professional-backgrounds', mapPayload(payload)));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateProfessionalBackground(
  id: string,
  body: ConvertedProfessionalBackgroundPayload
): Promise<ProfessionalBackgroundResult> {
  const [err, res] = await tryCatch(HSApi.put(`/professional-backgrounds/${id}`, mapPayload(body)));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteProfessionalBackground(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/professional-backgrounds/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
