import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getPpeOutputsGroupsEmployee, PpeOutputsGroupsEmployeeResult } from 'api/hs/TransactionAndLists/Employee/PpeList';
import { useFetch } from 'utils';

import PpeOutputsGroupEmployeeList from './List';
import { PpeOutputsGroupContext } from './ppe-outputs-group.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [newDebitDialogOpened, setNewDebitDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeOutputsGroupsEmployeeResult>(null);
  const { employeeId } = useParams<{ employeeId: string }>();

  const { data, fetch } = useFetch(async () => await getPpeOutputsGroupsEmployee(employeeId), [], { setLoading });

  return (
    <PpeOutputsGroupContext.Provider
      value={{
        ppeOutputsGroupsEmployee: data,
        reload: fetch,
        loading,
        setLoading,
        newDialogOpened,
        newDebitDialogOpened,
        setNewDebitDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <PpeOutputsGroupEmployeeList />
    </PpeOutputsGroupContext.Provider>
  );
}
