/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CommitteeMeetingFilter } from './CommitteeMeetingFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type CommitteeMeetingFilterDto = {
    exposeType?: CommitteeMeetingFilterDto.exposeType;
    committeeMeetingFilter?: CommitteeMeetingFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
}

export namespace CommitteeMeetingFilterDto {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
