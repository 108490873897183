import React from 'react';

import { UserRole } from 'model/user-role.enum';

import Firms from './Firms';
import FirmSubscriptions from './FirmSubscriple';
import FirmUsers from './FirmUser';
interface FirmDefinitionsListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'Firmalar',
    Component: Firms,
    path: '',
    visibleTo: [UserRole.ADMIN],
  },
  {
    label: 'Firma Abonelik',
    Component: FirmSubscriptions,
    path: 'firm-kayit',
    visibleTo: [UserRole.ADMIN],
  },
  {
    label: 'Firma Kullanıcılar',
    Component: FirmUsers,
    path: 'firm-kullanicilar',
    visibleTo: [UserRole.ADMIN],
  },
] as FirmDefinitionsListItem[];
