import { createContext } from 'react';

import { PolyclinicExamOpinionsModel, PolyclinicExamOpinionsResult } from 'api/hs/examination/polyclinic-exam-opinions';

export interface PolyclinicExamOpinionContextType {
  polyclinicExamOpinions: PolyclinicExamOpinionsResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PolyclinicExamOpinionsModel;

  setSelectedData(data: PolyclinicExamOpinionsModel): void;
}

export const PolyclinicExamOpinionContext = createContext<PolyclinicExamOpinionContextType>(null);
