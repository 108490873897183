import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { usePrevious } from 'react-use';

import { PrescriptionMedicineModel, PrescriptionModel, savePrescription, updatePrescription } from 'api/hs/examination';
import { saveDiagnosis, updateDiagnosis } from 'api/hs/examination/diagnosis';
import Icd10Selection from 'Common/Form/Icd10Selection';
import { Icd10CacheContext } from 'Common/Form/Icd10Selection/icd10.context';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { dateToInputFormat, useAsyncEffect } from 'utils';

import DateInput from '../../../../../../Common/Form/DateInput';
import { ExaminationDetailContext } from '../../../ExaminationPage/Detail/examination-detail.context';
import PrescriptionMedicineList from '../../prescriptionMedicine/list';
import PrescriptionMedicineForm from './PrescriptionMedicineForm';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
  medicineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  secondaryOnClick(success: boolean): void;

  secondaryOpen: boolean;

  secondaryOnClose(success: boolean): void;

  data: PrescriptionModel;

  secondaryData: PrescriptionMedicineModel;
}

export default function PrescriptionForm({ open, onClose, secondaryOnClick, secondaryData, secondaryOnClose, secondaryOpen, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const { prescriptionTypeDefinitions, prescriptionProvisionDefinitions, prescriptionSubtypeDefinitions } = useContext(
    SelectOptionsContext
  );
  const { showMessage } = useContext(AlertContext);
  const { diagnosis, reloadDiagnosis } = useContext(ExaminationDetailContext);
  const { getOptions } = useContext(Icd10CacheContext);
  const prevOpen = usePrevious(open);

  const [prescriptionMedicines, setPrescriptionMedicines] = useState<PrescriptionMedicineModel[]>([]);
  const { employeeId, examinationId } = useParams<{
    employeeId: string;
    examinationId: string;
  }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          if (!values?.icd10Id || values?.icd10Id.length === 0) {
            showMessage('Lütfen tanı ekleyiniz!', 'error', 'info');
          } else if (!prescriptionMedicines || prescriptionMedicines.length === 0) {
            showMessage('Lütfen ilaç ekleyiniz!', 'error', 'info');
          } else {
            await savePrescription({
              employeeId,
              examinationId,
              description: values.description,
              prescriptionProvisionTypeDefinitionId: values.prescriptionProvisionTypeDefinitionId,
              prescriptionSubtypeDefinitionId: values.prescriptionSubtypeDefinitionId,
              prescriptionTypeDefinitionId: values.prescriptionTypeDefinitionId,
              prescriptionDescriptionTypeDefinitionId: values.prescriptionDescriptionTypeDefinitionId,
              requestDate: values.requestDate,
              prescriptionMedicines: [
                ...prescriptionMedicines.map(med => {
                  return {
                    ...med,
                    employeeId,
                    examinationId,
                    medicineId: med.medicineId,
                  };
                }),
              ],
            });
            if (!diagnosis.find(diag => !diag.occupationalDiseasePreDiagnosis)) {
              await saveDiagnosis({
                employeeId,
                examinationId,
                description: '',
                occupationalDiseasePreDiagnosis: false,
                diagnosisIcd10s: values?.icd10Id.map((id: number) => ({ icd10Id: id })),
              });
            } else {
              await updateDiagnosis({
                id: diagnosis.find(diag => !diag.occupationalDiseasePreDiagnosis)?.id,
                employeeId,
                examinationId,
                diagnosisIcd10s: values?.icd10Id.map((id: number) => ({ icd10Id: id })),
              });
            }

            reloadDiagnosis();
            showMessage('Ekleme işlemi başarılı.', 'success', 'info');
            onClose(true);
          }
        } else {
          if (!values?.icd10Id || values?.icd10Id.length === 0) {
            showMessage('Lütfen tanı ekleyiniz!', 'error', 'info');
          } else if (!prescriptionMedicines || prescriptionMedicines.length === 0) {
            showMessage('Lütfen ilaç ekleyiniz!', 'error', 'info');
          } else {
            const updatedPayload = {
              ...values,
              id: data.id,
              icd10Id: values?.icd10Id[0],
              prescriptionMedicines: [
                ...prescriptionMedicines.map(med => {
                  return {
                    ...med,
                    employeeId,
                    examinationId,
                    medicineId: med.medicineId,
                  };
                }),
              ],
            };
            await updatePrescription(updatedPayload);
            await updateDiagnosis({
              id: diagnosis.find(diag => !diag.occupationalDiseasePreDiagnosis)?.id,
              employeeId,
              examinationId,
              diagnosisIcd10s: values?.icd10Id.map((id: number) => ({
                icd10Id: id,
              })),
            });
            reloadDiagnosis();
            showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
            onClose(true);
          }
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        console.log('ERROR in handleSubmit', err); // tslint:disable-line:no-console
      }
    },
    [data, prescriptionMedicines, showMessage, employeeId, examinationId, diagnosis, onClose, reloadDiagnosis]
  );

  const removeMedicineFromList = (id: string) => {
    const newMedicineList = prescriptionMedicines.filter(med => {
      return med.medicineId !== id;
    });
    setPrescriptionMedicines(newMedicineList);
  };

  useAsyncEffect(async () => {
    if (!open || prevOpen === open) {
      return;
    }

    await defer();

    const actualDiagnosis = diagnosis.find(diag => !diag.occupationalDiseasePreDiagnosis) || null;
    getOptions(...(actualDiagnosis?.icd10s ?? []));

    if (data) {
      setValue('prescriptionSubtypeDefinitionId', data.prescriptionSubtypeDefinitionId);
      setValue('prescriptionProvisionTypeDefinitionId', data.prescriptionProvisionTypeDefinitionId);
      setValue('prescriptionTypeDefinitionId', data.prescriptionTypeDefinitionId);
      setValue('icd10Id', actualDiagnosis?.icd10s ?? []);
      setValue('description', data.description);
      setValue('requestDate', data.requestDate);
      trigger();
    } else {
      reset({
        description: '' as any,
        prescriptionProvisionTypeDefinitionId: prescriptionProvisionDefinitions[0].value,
        prescriptionSubtypeDefinitionId: prescriptionSubtypeDefinitions[0].value,
        prescriptionTypeDefinitionId: prescriptionTypeDefinitions[0].value,
        requestDate: dateToInputFormat(new Date()),
        prescriptionMedicines: null,
        icd10Id: actualDiagnosis?.icd10s ?? [],
      });
    }
  }, [data, setValue, getValues, reset, trigger, prescriptionMedicines, open, getOptions, prevOpen]);

  const handleAddMedicineToPrescription = async (values: PrescriptionMedicineModel) => {
    setPrescriptionMedicines([...prescriptionMedicines, values]);
  };

  useEffect(() => {
    setPrescriptionMedicines([...(data?.prescriptionMedicines ?? [])]);
  }, [data]);

  return (
    <>
      <Dialog
        PaperProps={{ style: { maxWidth: 600 } }}
        open={open}
        keepMounted
        onClose={onClose.bind(null, false)}
        classes={{
          paper: classes.dialogPaper,
          scrollPaper: classes.dialogScroll,
        }}>
        <DialogTitle>{data?.id ? 'e-Reçete Düzenle' : 'e-Reçete Ekle'}</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Grid container spacing={3} className="mb-2">
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <Icd10Selection name="icd10Id" />
                  {/* {!data?.eprescriptionNo && diagnosis?.length === 0 && (
                    <Typography color="error" className="mb-2" display="block">
                      Tanı sekmesinden tanı giriniz.
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={12} className="mb-2">
                  <header className={`${classes.medicineHeader} mb-2`}>
                    <Typography variant="h6" color="inherit">
                      İlaçlar
                    </Typography>
                    <Button variant="contained" color="primary" className="ml-2" size="small" onClick={secondaryOnClick.bind(null, false)}>
                      İLAÇ EKLE
                    </Button>
                  </header>
                  <PrescriptionMedicineList medicines={prescriptionMedicines} removeMedicineFromList={removeMedicineFromList} />
                </Grid>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Ek Seçenekleri Göster</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} sm={12} lg={6}>
                        <DateInput required type="date" name="requestDate" label="Reçete Tarihi" />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={6}>
                        <Select
                          options={prescriptionTypeDefinitions}
                          defaultValue={prescriptionTypeDefinitions[0]?.value}
                          name="prescriptionTypeDefinitionId"
                          label="Reçete Türü"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={6}>
                        <Select
                          options={prescriptionSubtypeDefinitions}
                          defaultValue={prescriptionSubtypeDefinitions[0]?.value}
                          name="prescriptionSubtypeDefinitionId"
                          label="Reçete Alt Türü"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={6}>
                        <Select
                          options={prescriptionProvisionDefinitions}
                          defaultValue={prescriptionProvisionDefinitions[0]?.value}
                          name="prescriptionProvisionTypeDefinitionId"
                          label="Reçete Provizyon Türü"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField rows={2} label="Açıklama" name="description" />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid container className="text-right">
                <Grid item xs={6} md={6} sm={6} lg={6} />
                <Grid item xs={6} md={6} sm={6} lg={6}>
                  <Button variant="contained" type="submit" color="primary" className="mr-2">
                    {data?.id ? 'Güncelle' : 'Kaydet'}
                  </Button>
                  <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                    Vazgeç
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <PrescriptionMedicineForm
        onClose={secondaryOnClose.bind(null, false)}
        data={secondaryData}
        open={secondaryOpen}
        handleAddMedicineToPrescription={handleAddMedicineToPrescription}
      />
    </>
  );
}
