import React, { useCallback, useContext } from 'react';

import ReferralForm from 'Common/Forms/Examination/RefferralForm';

import { ReferralsContextContext } from '../../referrals.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(ReferralsContextContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <ReferralForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
