/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionEmployeeDto } from './ActionEmployeeDto';

export type ActionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    plannedEndDate?: string;
    completedDate?: string;
    actionReference?: ActionDto.actionReference;
    referenceId?: string;
    actionStatusDefinition?: ActionDto.actionStatusDefinition;
    actionPriorityDefinition?: ActionDto.actionPriorityDefinition;
    actionEmployees?: Array<ActionEmployeeDto>;
}

export namespace ActionDto {

    export enum actionReference {
        HEALTH_MEETING = 'HEALTH_MEETING',
        AUDIT = 'AUDIT',
        ACCIDENT = 'ACCIDENT',
        ACCIDENT_REVIEW = 'ACCIDENT_REVIEW',
        RISK_ASSESSMENT = 'RISK_ASSESSMENT',
        NEAR_MISS = 'NEAR_MISS',
        DRILL = 'DRILL',
    }

    export enum actionStatusDefinition {
        TO_DO = 'TO_DO',
        DOING = 'DOING',
        CAN_NOT_BE_DONE = 'CAN_NOT_BE_DONE',
        DONE = 'DONE',
    }

    export enum actionPriorityDefinition {
        LOW = 'LOW',
        MIDDLE = 'MIDDLE',
        HIGH = 'HIGH',
    }


}
