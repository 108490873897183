import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useTriggerRender } from 'utils';

import { AccidentReviewControllerService } from '../../../../api/client';
import { AccidentReviewContext } from '../../accident-review-context';
import { employeeLabelFetcher, employeeOptionFetcher } from '../../utils';

interface Props {
  open: boolean;
  isReferenceDefinitions?: string;

  fetch(): void;

  onClose(success: boolean): void;
}

export default function ({ open, onClose, fetch }: Props) {
  const { token, accidentId } = useContext(AccidentReviewContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { actionsPriorityDefinitions } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      const payload = {
        description: values?.description,
        actionPriorityDefinition: values?.actionPriorityDefinition,
        dueDate: values?.plannedEndDate,
        employeeIds: values?.employeeIds,
      };
      try {
        await AccidentReviewControllerService.saveAction1(accidentId, null, payload, token);
        showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        onClose(true);
        fetch();
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [token, showMessage, onClose, fetch, accidentId]
  );

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title={'Aksiyon Ekle'}>
        <Form onChange={reRender} onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete
                name="employeeIds"
                label="Aksiyon Atanacak Personel"
                optionFetcher={employeeOptionFetcher}
                labelFetcher={employeeLabelFetcher}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField rows={3} name="description" label="Aksiyonun Tanımı" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete single name="actionPriorityDefinition" options={actionsPriorityDefinitions} label="Öncelik Durumu " />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="Termin Tarih" name="plannedEndDate" type="date" />
            </Grid>
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                Kaydet
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
