import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext, useState } from 'react';

import { deleteOccupationalSafetyRegistriesDetections } from '../../api/hs/occupational-safety-registries /occupational-safety-registries';
import DetectionsForm from '../../Common/Forms/OccupationalSafetyRegistries/Occupation/RegistriesForm/DetectionsForm';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { AlertContext } from '../../context/alert.context';
import { DialogContext } from '../../context/dialog.context';
import Add from './Add';
import UploadFile from './UploadFile';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '300px',
    right: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
  table: {
    minWidth: 650,
  },
}));

export default function DetectionsTable({ data, pageId, fetch, loading }: any) {
  const classes = useStyles();
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [selectedData, setSelectedData] = useState(null);
  const [open, setOpen] = useState(false);
  const onEdit = useCallback(
    editedItem => {
      setOpen(true);
      setSelectedData(editedItem);
    },
    [setOpen, setSelectedData]
  );
  const openDeleteDialog = useCallback(
    async deletedItem => {
      const submitted = await openWarnDialog({
        text: `${deletedItem?.description} - uyarısını silmek istediğinize emin misiniz ?`,
      });
      try {
        if (submitted) {
          await deleteOccupationalSafetyRegistriesDetections(deletedItem?.id);
          showMessage('Silme işlemi başarılı.', 'success');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error');
      }
    },
    [openWarnDialog, showMessage, fetch]
  );
  return (
    <div>
      <TableContainer component={Paper}>
        <Add pageId={pageId} fetch={fetch} />
        <IndicatorWrapper loading={loading} data={data} errorMessage={'Listelenecek Kayıt Bulunamadı.'}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Tespit</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(row => (
                <TableRow hover key={row.id}>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="left" />

                  <TableCell align="right">
                    <div>
                      {' '}
                      <IconButton onClick={() => openDeleteDialog(row)} edge="end" aria-label="delete">
                        <DeleteIcon fontSize={'default'} />
                      </IconButton>
                      <IconButton onClick={() => onEdit(row)} edge="end" aria-label="edit">
                        <EditIcon fontSize={'default'} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </IndicatorWrapper>
        <DetectionsForm open={open} onClose={() => setOpen(false)} data={selectedData} pageId={pageId} fetch={fetch} />
      </TableContainer>
      <br />
      <UploadFile pageId={pageId} />
    </div>
  );
}
