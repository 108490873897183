import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { EnumIbysWorkTypes } from './enum-ibys-work-types';
import { EnumResult } from './enum.result';
const detailPath = '/ibys/work-types/working-position-definitions';

export async function getWorkingPositionDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumIbysWorkTypes[]>(`${detailPath}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
  const resultArray: EnumResult[] = [];
  res.data.forEach(value => {
    const resultVal: EnumResult = {
      createdBy: value.createdBy,
      createdDate: value.createdDate,
      lastModifiedBy: value.lastModifiedBy,
      lastModifiedDate: value.lastModifiedDate,
      deleted: value.deleted,
      id: value.code,
      label: value.name,
    };
    resultArray.push(resultVal);
  });

  return resultArray;
}
