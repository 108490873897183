import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { BloodRelationResult, deleteAdminBloodRelation, getAdminBloodRelation } from 'api/hs/definitions/health-definitions/blood-relation';
import AdminBloodRelationEditDialog from 'Common/Forms/Definitions/HealthDefinition/BloodRelation/AdminBloodRelationEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminBloodRelation() {
  return (
    <BaseDefinitionPage<BloodRelationResult>
      listLabelPropName="label"
      fetchData={getAdminBloodRelation}
      FormDialog={AdminBloodRelationEditDialog}
      onDelete={deleteAdminBloodRelation}
      Icon={<DomainIcon />}
    />
  );
}
