import { DisplayOption } from './display-option';

export const convertDateClusteredByMultipleFieldNames = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByNames: string[],
  displayOption: DisplayOption
  // tslint:disable-next-line:no-empty
): any[] => {};

export const convertDateClusteredByMultipleFieldIdsAndIdMaps = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByIds: string[],
  displayOption: DisplayOption,
  fieldNameIdNameMaps: Map<string, Map<string, string>>
): any[] => {
  return null;
};

export const convertDateClusteredByMultipleFieldIds = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByIds: string[],
  displayOption: DisplayOption
): any[] => {
  return null;
};

export const convertClusteredDataByFieldName = (data: any[], categoryFieldName: string, selectorFieldByName: string): any[] => {
  const resultMap: any = {};
  data.forEach(d => {
    const key = Reflect.get(d, categoryFieldName);
    if (!resultMap[key]) {
      resultMap[key] = {
        category: key,
      };
    }
    addFieldValueCount(resultMap[key], d, selectorFieldByName);
  });
  return Object.values(resultMap);
};

export const addFieldValueCountArr = (resultItem: any, data: any[], selectorFieldByName: string) => {
  data.forEach(d => {
    addFieldValueCount(resultItem, d, selectorFieldByName);
  });
};

export const addFieldValueCount = (item: any, data: any, selectorFieldByName: string) => {
  if (!data) {
    return;
  }
  const indexOfArr: number = selectorFieldByName.indexOf('[].');
  const indexOfDot: number = selectorFieldByName.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = selectorFieldByName.substr(0, indexOfArr);
    const newFieldName = selectorFieldByName.substr(indexOfArr + 3);
    addFieldValueCountArr(item, Reflect.get(data, fragment), newFieldName);
  } else if (indexOfDot > 0) {
    const fragment = selectorFieldByName.substr(0, indexOfDot);
    const newFieldName = selectorFieldByName.substr(indexOfDot + 1);
    addFieldValueCount(item, Reflect.get(data, fragment), newFieldName);
  } else {
    const field = Reflect.get(data, selectorFieldByName);
    if (!item[field]) {
      item[field] = 0;
    }
    item[field]++;
  }
};
