import { IconButton, makeStyles, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';

import RouterTabs, { TabModel } from 'Common/RouterTabs';
import CoreToolbar from 'Common/Toolbar';

import { UserContext } from '../../../context/user';
import { UserRole } from '../../../model/user-role.enum';
import { LicenceContext } from '../../../context/licence';
import { LicenceModule } from '../../../model/licence-module.enum';
import Avatar from '../Avatar';
import { EmployeeContext } from './employee.context';

const useStyles = makeStyles(theme => ({
  maxContent: {
    minWidth: 'max-content',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto max-content 1fr',
    gridTemplateRows: 'auto',
  },
}));

const TABS: TabModel[] = [
  {
    path: '',
    label: 'Profil',
    icon: <i className="icon-account" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    activeTo: [LicenceModule.PERSONEL, LicenceModule.SAGLIK_GOZETIMI, LicenceModule.RISK_DEGERLENDIRME, LicenceModule.AKSIYON, LicenceModule.IS_KAZA, LicenceModule.EGITIM, LicenceModule.ACIL_DURUM, LicenceModule.KKD, LicenceModule.ISG, LicenceModule.ONAYLI_DEFTER, LicenceModule.IS_EKIPMAN, LicenceModule.ISTATISTIK, LicenceModule.TANIM],
  },
  {
    path: 'examination',
    label: 'Muayeneler',
    icon: <i className="icon-stethoscope" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'nursing-actions',
    label: 'SP İşlemleri',
    icon: <i className="icon-clipboard-text" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'analysis',
    label: 'Tahlil / Tetkikler',
    icon: <i className="icon-heart-pulse" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.ISTATISTIK],
  },
  {
    path: 'vaccine',
    label: 'Aşılar',
    icon: <i className="icon-needle" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'referral',
    label: 'Sevkler',
    icon: <i className="icon-code-string" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'report',
    label: 'Raporlar',
    icon: <i className="icon-seat-individual-suite" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'ppe',
    label: 'KKD',
    icon: <i className="icon-shopping" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    activeTo: [LicenceModule.KKD],
  },
  {
    path: 'diagnosis',
    label: 'Tanılar',
    icon: <i className="icon-magnify" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.TANIM],
  },
  {
    path: 'prescription',
    label: 'Reçeteler',
    icon: <i className="icon-prescription" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
  },
  {
    path: 'education',
    label: 'Eğitim',
    icon: <i className="icon-school" />,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    activeTo: [LicenceModule.EGITIM],
  },
];

export default function Toolbar() {
  const classes = useStyles();
  const { employeeId } = useParams<{ employeeId: string }>();
  const { user } = useContext(UserContext);
  const { licence } = useContext(LicenceContext);
  const {
    employee: { avatarPath, name },
    infoDrawerOpened,
    setInfoDrawerOpened,
  } = useContext(EmployeeContext);

  const toggleInfoDrawer = useCallback(() => setInfoDrawerOpened(!infoDrawerOpened), [infoDrawerOpened, setInfoDrawerOpened]);

  return (
    <CoreToolbar className="py-1" toolbarProps={{ className: classes.toolbar }}>
      <IconButton className={`mr-1 ml-n2 text-white ${classes.maxContent}`} onClick={toggleInfoDrawer}>
        {infoDrawerOpened ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
      <Avatar url={avatarPath} className={`mr-1`} />
      <Typography variant="h6" className={`${classes.maxContent} mr-4`}>
        {name}
      </Typography>
      <RouterTabs
        variant="toolbar"
        rootPath={`/transactions/employee/${employeeId}`}
        tabs={TABS?.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r)))?.filter(({ activeTo }) => activeTo.some(r => licence?.modules.includes(r)))}
      />
    </CoreToolbar>
  );
}
