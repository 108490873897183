import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { DiagnosisModel, saveDiagnosis, updateDiagnosis } from 'api/hs/examination/diagnosis';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { EmployeeContext } from '../../../TransactionsAndLists/EmployeeList/Employee/employee.context';
import Checkbox from '../../Form/Checkbox';
import Icd10Selection from '../../Form/Icd10Selection';
import { Icd10CacheContext } from '../../Form/Icd10Selection/icd10.context';
import TextField from '../../Form/TextField';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: DiagnosisModel;
}

export default function DiagnosisForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { getOptions } = useContext(Icd10CacheContext);
  const { refresh } = useContext(EmployeeContext);

  const handleSubmit = useCallback(
    async values => {
      if (values.description || values.icd10) {
        try {
          if (!data?.id) {
            await saveDiagnosis({
              employeeId,
              examinationId,
              description: values.description,
              occupationalDiseasePreDiagnosis: values.occupationalDiseasePreDiagnosis,
              diagnosisIcd10s: values.icd10.map((id: number) => ({ icd10Id: id })),
            });
            showMessage('Ekleme işlemi başarılı.', 'success', 'info');
            await refresh();
          } else {
            await updateDiagnosis({
              id: data.id,
              employeeId,
              examinationId,
              description: values.description,
              occupationalDiseasePreDiagnosis: values.occupationalDiseasePreDiagnosis,
              diagnosisIcd10s: values.icd10.map((id: number) => ({ icd10Id: id })),
            });

            showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
            await refresh();
          }
          onClose(true);
        } catch (error) {
          showMessage(error.data.message, 'error');
          onClose(false);
        }
      } else {
        showMessage('ICD10 ya da Açıklama alanını veri giriniz.', 'warning', 'info');
      }
    },
    [data, onClose, showMessage, employeeId, examinationId, refresh]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    if (data) {
      getOptions(...data.icd10s);
      setValue('icd10', data.icd10s);
      setValue('description', data.description);
      setValue('occupationalDiseasePreDiagnosis', data.occupationalDiseasePreDiagnosis);
      trigger();
    } else {
      reset({
        icd10: [],
        description: '' as any,
        occupationalDiseasePreDiagnosis: null,
      });
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 600 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Tanı Düzenleme' : 'Tanı Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <Icd10Selection name="icd10" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Checkbox name="occupationalDiseasePreDiagnosis" label="MESLEK HASTALIĞI ÖN TANISI" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField variant="outlined" rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
