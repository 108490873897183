/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentDto } from '../models/AccidentDto';
import type { AccidentReviewerDto } from '../models/AccidentReviewerDto';
import type { AccidentView } from '../models/AccidentView';
import type { AccidentViewRequest } from '../models/AccidentViewRequest';
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { PageAccidentView } from '../models/PageAccidentView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentControllerService {

    /**
     * @param requestBody
     * @returns AccidentDto OK
     * @throws ApiError
     */
    public static saveAccident(
        requestBody: AccidentDto,
    ): CancelablePromise<AccidentDto> {
        return __request({
            method: 'POST',
            path: `/accidents`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getAccidentFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static accidentFileImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageAccidentView OK
     * @throws ApiError
     */
    public static getAccidentViews1(
        requestBody: AccidentViewRequest,
    ): CancelablePromise<PageAccidentView> {
        return __request({
            method: 'POST',
            path: `/accidents/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static accidentViewExport(
        requestBody: AccidentViewRequest,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/accidents/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns AccidentDto OK
     * @throws ApiError
     */
    public static getAccident(
        id: string,
    ): CancelablePromise<AccidentDto> {
        return __request({
            method: 'GET',
            path: `/accidents/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAccident(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns AccidentDto OK
     * @throws ApiError
     */
    public static partialUpdateAccident(
        id: string,
        requestBody: AccidentDto,
    ): CancelablePromise<AccidentDto> {
        return __request({
            method: 'PATCH',
            path: `/accidents/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param auth
     * @param authenticationInfo
     * @returns AccidentView OK
     * @throws ApiError
     */
    public static getAccidentViewById(
        id: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentView> {
        return __request({
            method: 'GET',
            path: `/accidents/${id}/views`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static getAccidentFormExport(
        id: string,
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/accidents/${id}/forms/raw`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getAccidentFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/accidents/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAccidentFile(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/imported-files/${id}`,
        });
    }

}