import React, { useState } from 'react';

import { CommitteeMembersResult, getCommitteeMembers } from 'api/hs/definitions/committee-members';
import { useFetch } from 'utils';

import { CommitteeMembersContext } from './committee-members.context';
import CommitteeMembersList from './CommitteeMembersListAdd';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<CommitteeMembersResult>(null);

  const { data, fetch } = useFetch(() => getCommitteeMembers(), [], { setLoading });

  return (
    <CommitteeMembersContext.Provider
      value={{
        committeeMembers: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <CommitteeMembersList />
    </CommitteeMembersContext.Provider>
  );
}
