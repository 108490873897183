import React from 'react';

import AdminAccidentTypeDefinition from './AdminAccidentTypeDefinition';

interface AdminAccidentDefinitionListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'KAZA TÜRLERİ',
    Component: AdminAccidentTypeDefinition,
    path: '',
  },
] as AdminAccidentDefinitionListItem[];
