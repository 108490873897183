import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import TablePagination from 'Common/TablePagination';

import { EmployeeListContext } from '../employee-list.context';

export default function Footer() {
  const {
    cells,
    filters: {
      pagingAndSortingRequestParam: { size, page },
    },
    updateFilters,
    totalPages,
  } = useContext(EmployeeListContext);

  const changeSize = useCallback((s: number) => updateFilters({ pagingAndSortingRequestParam: { size: s, page: 0 } }), [updateFilters]);
  const changePage = useCallback((p: number) => updateFilters({ pagingAndSortingRequestParam: { page: p - 1 } }), [updateFilters]);

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={cells.filter(({ visible }) => visible).length + 2}>
          <TablePagination size={size} onSizeChange={changeSize} onPageChange={changePage} page={page + 1} count={totalPages} />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}
