function regexTurkishToEnglish(text: string): string {
  return text
    .replace('Ğ', 'g')
    .replace('Ü', 'u')
    .replace('Ş', 's')
    .replace('I', 'i')
    .replace('İ', 'i')
    .replace('Ö', 'o')
    .replace('Ç', 'c')
    .replace('ğ', 'g')
    .replace('ü', 'u')
    .replace('ş', 's')
    .replace('ı', 'i')
    .replace('ö', 'o')
    .replace('ç', 'c');
}

function regexRemoveSpecialChars(text: string): string {
  return text.replace(/[^\w\s]/gi, '');
}

function regexRemoveSpaces(text: string): string {
  return text.trim().replace(/\s/g, '-');
}

export { regexTurkishToEnglish, regexRemoveSpecialChars, regexRemoveSpaces };
