import { CardHeader, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { UserContext } from 'context/user';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
});

export default function DiplomaInformation() {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { profile, updateProfile } = useContext(UserContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        await updateProfile({ ...values });
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      } catch (e) {
        showMessage('Güncelleme işlemi başarısız.', 'error', 'info');
      }
    },
    [showMessage, updateProfile]
  );

  useAsyncEffect(async () => {
    if (profile) {
      await defer();
      for (const [key, value] of Object.entries(profile)) {
        setValue(key, value === null ? '' : value);
      }
      trigger();
    }
  }, [profile, setValue, trigger]);

  return (
    <div className="mt-1 mr-1">
      <Card className={classes.root}>
        <CardHeader style={{ backgroundColor: '#2196f3', color: 'white' }} title="Diploma Bilgiler" />
        <CardContent>
          <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
            <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField name="graduationDate" label="Diploma Tarihi ve No" />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField name="diplomaRegistrationDate" label="Diploma Tescil Tarihi ve No" />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField name="doctorCertificate" label="İş Yeri Hekimliği Belgesi Tarihi ve No" />
                </Grid>

                <Grid item md={12} className="text-right">
                  <Button variant="contained" type="submit" color="primary" className="mr-2">
                    Kaydet
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </div>
  );
}
