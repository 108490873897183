import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext } from 'react';

import { deleteEmployeeImported } from 'api/hs/file-uploads/employee-imported-files';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';

import { downlaodFile, useXhr } from '../../../../../../../../utils';
import { ProfileUploadContext } from '../../profile-file-upload-context';
import PreviewImageDialog from './PreviewImage';

export default function ListEmployeeFileImported() {
  const { loading, employeeImported, reload, setSelectedData, setPreviewImageOpened } = useContext(ProfileUploadContext);
  const { openWarnDialog } = useContext(DialogContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteEmployeeImported(id);
      await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const onPreview = useCallback(
    async employeeFileData => {
      setSelectedData(employeeFileData);
      setPreviewImageOpened(true);
    },
    [setPreviewImageOpened, setSelectedData]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={employeeImported} errorMessage="Gösterilecek  Dosya Bulunamadı">
        <List key="hs-defaultNurseOperationDetail">
          {employeeImported?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-account" />
                </ListItemAvatar>
                <ListItemText primary={item?.originalFileName} />
                <ListItemSecondaryAction>
                  {item?.type === 'image/jpeg' || item?.type === 'image/png' ? (
                    <IconButton onClick={() => onPreview(item)} edge="end" aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  ) : null}

                  <IconButton
                    onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => removeFile(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <PreviewImageDialog />
      </IndicatorWrapper>
    </div>
  );
}
