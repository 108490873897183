import React, { useCallback, useContext } from 'react';

import { CommitteeMembersContext } from '../../committee-members.context';
import CommitteeMembersForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(CommitteeMembersContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <CommitteeMembersForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
