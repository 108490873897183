import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext } from 'react';

import { deleteTrainingMainGroup, TrainingMainGroupModel } from 'api/hs/definitions/trainings/training-main-group';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { TrainingMainGroupContext } from '../../training-main-group.context';

interface Props {
  data: TrainingMainGroupModel;
}

export default function Action({ data }: Props) {
  const { setSelectedData, setEditDialogOpened, reload } = useContext(TrainingMainGroupContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const edit = useCallback(
    item => () => {
      setSelectedData(item);
      setEditDialogOpened(true);
    },
    [setSelectedData, setEditDialogOpened]
  );

  const handleDelete = useCallback(async () => {
    const submitted = await openWarnDialog({
      text: `${data.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteTrainingMainGroup(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [data, reload, openWarnDialog, showMessage]);

  return (
    <>
      <IconButton onClick={edit(data)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </>
  );
}
