import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import Toolbar from 'Common/Toolbar';
import { EnumContext } from 'context/enum.context';

import { dateDisplayFormat } from '../../../../../../../utils';
import { DefaultNurseOperationDetailContext } from '../../default-nurse-operation-detail.context';

export default function Index() {
  const { nurseOperation } = useContext(DefaultNurseOperationDetailContext);
  const { nurseOperationDefinitions } = useContext(EnumContext);

  return (
    <>
      <Toolbar color="secondary">
        <List style={{ width: '100%' }} component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemText
              primary={
                nurseOperation?.userData?.name + ' tarafından ' + dateDisplayFormat(nurseOperation?.date) + ' tarihinde oluşturuldu.'
              }
              secondary={
                <Typography className="text-italic">
                  Protokol Numarası :{' '}
                  {nurseOperation?.protocolNumber?.yearPrefix + '/' + String(nurseOperation?.protocolNumber?.number).padStart(4, '0')}
                </Typography>
              }
            />
            <ListItemText className="text-right">
              {findByKey(nurseOperationDefinitions, 'id', nurseOperation?.nurseOperationDefinitionId)?.label}
            </ListItemText>
          </ListItem>
        </List>
      </Toolbar>
    </>
  );
}
