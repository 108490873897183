import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { FirmPayload } from './firm.payload';
import { FirmResult } from './firm.result';

export async function getFirms(): Promise<FirmResult[]> {
  let res;
  try {
    res = await HSApi.get<FirmResult[]>(`/firms`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveFirm(body: FirmPayload): Promise<FirmResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirm({ id, ...body }: MakeRequired<Partial<FirmResult>, 'id'>): Promise<FirmResult> {
  const [error, res] = await tryCatch(HSApi.put<FirmResult>(`/firms/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteFirm(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
