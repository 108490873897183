import React from 'react';

import { UserRole } from 'model/user-role.enum';

import AdminClassificationDefinitions from './AdminClassificationDefinitions';
import AdminTypeDefinitions from './AdminTypeDefinitions';

interface AdminPpeListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'KKD Ana Grup',
    Component: AdminClassificationDefinitions,
    path: '',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'KKD Alt Grup',
    Component: AdminTypeDefinitions,
    path: 'kkdtipi',
    visibleTo: [UserRole.SYS_ADMIN],
  },
] as AdminPpeListItem[];
