import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import { ParameterModel } from 'api/hs/examination/analysis';
import TextField from 'Common/Form/TextField';
import TextFieldWithOnChange from 'Common/Form/TextFieldWithOnChange';

import DateInput from '../../../../../../Common/Form/DateInput';
import { useAsyncEffect } from '../../../../../../utils';
import { PARAMETER_NAMES } from '../audiometry-parameters';
interface Props {
  parameters: ParameterModel[];

  handleInput(data: string): void;
}

const useStyles = makeStyles(theme => ({
  cellFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 0,
  },
  cellRoot: {
    border: 'none !important',
  },
  cellInputRoot: {
    border: 'none !important',
    padding: '6px 10px',
  },
}));

export default function AudiometryForm({ parameters, handleInput }: Props) {
  const classes = useStyles();
  const [inputData, setInputData] = useState(null);

  useAsyncEffect(async () => {
    if (parameters) {
      const khzData = parameters
        ?.filter(a => a.parameter.includes('khz'))
        ?.map(b => +b.parameter.substring(3, 8))
        .sort((a, b) => {
          return a - b;
        })
        ?.map(c => 'khz' + c);

      const ssoData = parameters?.filter(a => !a.parameter?.includes('khz'))?.map(c => c.parameter);
      setInputData([...khzData, ...ssoData]);
    }
  }, [parameters]);

  return (
    <>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <DateInput required type="date" name="date" label="Reçete Tarihi" />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TextField rows={2} label="Kanaat" name="opinion" />
      </Grid>
      <Typography color="inherit" align="left">
        Sonuç
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.cellRoot }} />
            {PARAMETER_NAMES?.map((item, index) => (
              <TableCell classes={{ root: classes.cellInputRoot }} key={`left${item}${index}`} align="left">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell classes={{ root: classes.cellRoot }} className={classes.cellFlex} align="left">
              <Typography color="inherit" align="left">
                Sol
              </Typography>
            </TableCell>
            {inputData?.map((item, index) => (
              <TableCell classes={{ root: classes.cellInputRoot }} key={`leftEar_${item}${index}`} align="left">
                <TextFieldWithOnChange
                  type="number"
                  name={`leftEar_${item}`}
                  disabled={`leftEar_${item}` === 'leftEar_sso'}
                  onChange={handleInput}
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell classes={{ root: classes.cellRoot }} className={classes.cellFlex} align="left">
              <Typography color="inherit" align="left">
                Sağ
              </Typography>
            </TableCell>
            {inputData?.map((item, index) => {
              return (
                <TableCell classes={{ root: classes.cellInputRoot }} key={`rightEar_${item}${index}`} align="left">
                  <TextFieldWithOnChange
                    type="number"
                    name={`rightEar_${item}`}
                    disabled={`rightEar_${item}` === 'rightEar_sso'}
                    onChange={handleInput}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
