import { uniquify } from '@thalesrc/js-utils';
import i18n from 'i18n';
import React, { useCallback } from 'react';

import { LanguageNamespaces } from 'model/language-namespaces.type';

import { useI18n } from './use-i18n';

export function useTranslate(...namespaces: LanguageNamespaces[]) {
  const { loadNamespaces, language } = useI18n();

  loadNamespaces(...namespaces);

  function translateFn(key: string, values?: Record<string, any>): string;
  function translateFn(key: string, values: Record<string, any>, components: Record<string, JSX.Element>): JSX.Element;
  function translateFn(key: string, values: Record<string, any> = {}, components?: Record<string, JSX.Element>): string | JSX.Element {
    return translate(namespaces, key, values, components);
  }

  return useCallback(translateFn, [namespaces, language, translateFn]);
}

function translate(namespaces: LanguageNamespaces[], key: string, values?: Record<string, any>): string;
function translate(
  namespaces: LanguageNamespaces[],
  key: string,
  values: Record<string, any>,
  components: Record<string, JSX.Element>
): JSX.Element;
function translate(
  namespaces: LanguageNamespaces[],
  key: string,
  values: Record<string, any> = {},
  components?: Record<string, JSX.Element>
): string | JSX.Element {
  const translation = i18n.t(key, { ns: uniquify(['common', ...namespaces]), ...values });

  if (!components) {
    return translation;
  }

  const div = document.createElement('div');
  div.innerHTML = translation;

  return convert([...div.childNodes], components);
}

function convert(nodes: ChildNode[], components: Record<string, JSX.Element>): string | JSX.Element {
  if (!nodes.filter(node => node instanceof Element).length) {
    return nodes.reduce((acc, next) => acc + next.textContent, '');
  }

  return (
    <>
      {nodes.map(node =>
        node instanceof Text
          ? node.textContent
          : React.cloneElement(components[(node as Element).localName], { children: convert([...node.childNodes], components) })
      )}
    </>
  );
}
