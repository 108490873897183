/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Icd10DefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    icd10Name?: string;
    code?: string;
    level?: string;
    status?: Icd10DefinitionDto.status;
    upperCode?: string;
}

export namespace Icd10DefinitionDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
