import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import {
  exportAccidentEmployeesExport,
  exportAccidentsWidgetExport,
  getAccidentEmployeeView,
  getAccidentWidgetView,
} from '../../../../api/hs/accident-widget';
import { DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import AccidentEmployeeTable from './AccidentEmployeeTable';
import AccidentTable from './AccidentTable';

const fileNames = {
  accidentCount: 'kaza.xlsx',
  eccidentEmployeeCount: 'kaza-etkilenen-personel.xlsx',
};

export default () => {
  const [tab, setTab] = useState<string>('accidentCount');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: accidentCount } = useFetch(() => getAccidentWidgetView(new DateRange(dateRange.start, dateRange.end)), [], { setLoading });
  const { data: eccidentEmployeeCount } = useFetch(() => getAccidentEmployeeView(new DateRange(dateRange.start, dateRange.end)), [], {
    setLoading,
  });

  const {
    accident: { close, opened },
    accidentDateRange: { dateRange },
  } = useContext(DialogContext);

  const tabs = { accidentCount, eccidentEmployeeCount };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'accidentCount') {
      exportAccidentsWidgetExport(new DateRange(dateRange.start, dateRange.end), fileNames[tab]).then(noop);
    }

    if (tab === 'eccidentEmployeeCount') {
      exportAccidentEmployeesExport(new DateRange(dateRange.start, dateRange.end), fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab.toString()}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="İş Kazası" color={colors.accident} onClick={tabs[tab]?.length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`İş Kazası (${accidentCount.length ?? 0})`} value={'accidentCount'} />
            <Tab wrapped label={`Kazada Etkilenen Personel (${eccidentEmployeeCount.length ?? 0})`} value={'eccidentEmployeeCount'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'accidentCount'}>
            <IndicatorWrapper data={accidentCount} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <AccidentTable accidents={accidentCount} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'eccidentEmployeeCount'}>
            <IndicatorWrapper data={eccidentEmployeeCount} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <AccidentEmployeeTable eccidentEmployeeCount={eccidentEmployeeCount} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
