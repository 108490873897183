import { createContext } from 'react';

import { PpeDetailStocksResult } from 'api/hs/ppe/ppe-detail-stocks';

export interface PpeDetailStocksContextType {
  PpeDetailStocks: PpeDetailStocksResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PpeDetailStocksResult;

  setSelectedData(data: PpeDetailStocksResult): void;
}

export const PpeDetailStocksContext = createContext<PpeDetailStocksContextType>(null);
