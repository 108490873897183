import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import ComplaintsForm from 'Common/Forms/Examination/ComplaintsForm';

import { ComplaintsContextContext } from '../../complaints.context';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload, complaints } = useContext(ComplaintsContextContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      {complaints?.id ? null : (
        <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={onClick} className="mt-1">
          Yakınma Şikayet Ekle
        </Button>
      )}

      <ComplaintsForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
