import { createContext } from 'react';

import { ComplaintsResult } from 'api/hs/examination/complaint';
import { ComplaintModel } from 'model/employee/examination/complaint.model';

export interface ComplaintsContextType {
  complaints: ComplaintsResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: ComplaintModel;

  setSelectedData(data: ComplaintModel): void;
}

export const ComplaintsContextContext = createContext<ComplaintsContextType>(null);
