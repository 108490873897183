import React, { forwardRef, PropsWithChildren, Ref, useCallback, useImperativeHandle, useRef } from 'react';

import { printIframe } from 'utils';

export interface PrintTableRef {
  print(): void;
}

interface Props {
  styleTable?: string;
}

function PrintTable({ children, styleTable = '' }: PropsWithChildren<Props>, ref: Ref<PrintTableRef>) {
  const elRef = useRef<HTMLDivElement>();

  const print = useCallback(() => {
    const text = elRef.current.innerHTML;
    const html = `<html><head><style> ${styleTable}
  </style></head><body>${text}</body></html>`;
    const file = new Blob([html], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.setProperty('display', 'none');
    iframe.src = url;
    printIframe(iframe);
  }, [styleTable]);

  useImperativeHandle(ref, () => {
    return {
      print,
    };
  });

  return (
    <div ref={elRef} style={{ display: 'none' }}>
      {children}
    </div>
  );
}

export default forwardRef(PrintTable);
