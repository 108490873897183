/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MaternityLeaveDto } from './MaternityLeaveDto';

export type PregnancyDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    lastMenstruationDate?: string;
    multipleBirth?: boolean;
    birthDate?: string;
    pregnancyStateDefinitionId?: number;
    husbandBloodTypeDefinitionId?: number;
    examinationId?: string;
    employeeId?: string;
    bloodTypeIncompatibility?: boolean;
    status?: PregnancyDto.status;
    maternityLeave?: MaternityLeaveDto;
}

export namespace PregnancyDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
