import React from 'react';

import Add from './Add';
import List from './List';

export default function IndexDetailNurseOperation() {
  return (
    <>
      <Add />
      <List />
    </>
  );
}
