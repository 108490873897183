import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { defer, findByKey, isTruthy, uniqueId } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { ChronicDisease as ChronicDiseaseModel, getChronicDisease, saveChronicDisease, updateChronicDisease } from 'api/hs/examination';
import Button from 'Common/Button';
import Icd10Selection from 'Common/Form/Icd10Selection';
import { Icd10CacheContext } from 'Common/Form/Icd10Selection/icd10.context';
import TextField from 'Common/Form/TextField';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { useAsyncEffect, useDialogState } from 'utils';

import { EmployeeContext } from '../employee.context';

const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

export default function ChronicDisease() {
  const classes = useStyles();
  const [formId] = useState(uniqueId('chronic-form') as string);
  const [loading, setLoading] = useState(true);
  const { opened, open, close } = useDialogState();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const [data, setData] = useState<ChronicDiseaseModel>(null);
  const { getOptions, options } = useContext(Icd10CacheContext);
  const { refresh } = useContext(EmployeeContext);
  const { setValue, reset, trigger, ...methods } = useForm();

  const fetch = useCallback(async () => {
    setLoading(true);
    const res = await getChronicDisease(employeeId, examinationId);

    setData(res);
    setLoading(false);
  }, [employeeId, examinationId]);

  const submitForm = useCallback(
    async (form: { icd10: string[]; description: string }) => {
      setLoading(true);
      if (!data.id) {
        await saveChronicDisease({
          employeeId,
          examinationId,
          description: form.description,
          chronicDiseaseIcd10s: form.icd10.map(id => ({ icd10: id })),
        });
      } else {
        await updateChronicDisease(data.id, { description: form.description, chronicDiseaseIcd10s: form.icd10.map(id => ({ icd10: id })) });
      }
      refresh();
      fetch();
      close();
    },
    [data, refresh, fetch, close, employeeId, examinationId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useAsyncEffect(async () => {
    if (!data) {
      reset();
      return;
    }

    await defer();

    getOptions(...data.icd10s);
    setValue('icd10', data.icd10s);
    setValue('description', data.description);
    trigger();
  }, [data, setValue, reset, trigger, opened]);

  return (
    <>
      <Dialog open={opened} onClose={close} classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
        <DialogTitle>{data ? 'Kronik/Konjenital Hastalıkları Düzenle' : 'Kronik/Konjenital Hastalık Ekle'}</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...methods, setValue, reset, trigger }}>
            <form id={formId} onSubmit={methods.handleSubmit(submitForm)}>
              <Icd10Selection name="icd10" />
              <TextField name="description" label="Açıklama" rows={3} variant="outlined" />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className="text-right pr-3">
          <Button onClick={close}>İptal</Button>
          <Button color="primary" variant="contained" form={formId} type="submit">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
      <IndicatorWrapper
        data={data}
        loading={loading}
        errorMessage="Konjenital/Kronik Hastalık Girilmedi."
        errorChildren={
          <div className="mt-2 text-right">
            <Button color="primary" variant="contained" onClick={open}>
              Kronik Hastalık Ekle
            </Button>
          </div>
        }>
        <Grid container>
          <Grid item sm={6}>
            <List>
              {data?.icd10s
                ?.map(id => findByKey(options, 'id', id))
                ?.filter(isTruthy)
                ?.map(({ id, label }) => (
                  <ListItem key={id}>
                    <ListItemIcon>
                      <Icon className="icon-heart-pulse" />
                    </ListItemIcon>
                    <ListItemText> {label} </ListItemText>
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item sm={6} className={classes.sideGrid}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" className="text-italic text-gray">
                      Açıklama
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary={data?.description} />
              </ListItem>
            </List>
            <div className="text-right">
              <Button color="secondary" variant="contained" onClick={open}>
                Kronik Hastalıkları Düzenle
              </Button>
            </div>
          </Grid>
        </Grid>
      </IndicatorWrapper>
    </>
  );
}
