import {Chip, Grid} from '@material-ui/core';
import {uniquify} from '@thalesrc/js-utils';
import React, {MutableRefObject, useCallback, useContext} from 'react';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';
import {EmployeeSelectionCacheContext} from "../../../Common/Form/EmployeeSelection/employee-selection.context";
import {FormRef} from 'Common/Form/Form';

interface Params {
  selection: (string | symbol) [];
  formRef: MutableRefObject<FormRef<Record<string, string[]> & { guests: symbol[] }>>;
  handleGuestRemove: (id: symbol) => void;
  getGuestLabel: (id: symbol) => string;
}

export default function F2fParticipantList({selection, formRef, handleGuestRemove, getGuestLabel}: Params) {
  const {avatars, names} = useContext(EmployeeSelectionCacheContext);

  const blacklistEmployee = useCallback(
    (id: string) => () => {
      formRef?.current?.methods?.setValue('blacklist', uniquify([...formRef?.current?.value?.blacklist, id]));
      formRef?.current?.methods?.trigger();
    },
    []
  );
  return (
    <Grid item xs={12} className="px-2">
      {selection.length
        ? selection.map((emp, index) =>
          typeof emp === 'string' ? (
            <Chip
              key={emp}
              avatar={<Avatar url={avatars[emp]}/>}
              label={names[emp] ? names[emp] : getGuestLabel(emp)}
              onDelete={blacklistEmployee(emp)}
              className={`mr-2 mb-2`}
            />
          ) : (
            <Chip key={index} label={getGuestLabel(emp)} onDelete={() => handleGuestRemove(emp)}
                  className={`mr-2 mb-2`}/>
          )
        )
        : 'Hiç Personel Seçilmemiş'}
    </Grid>
  )

}
