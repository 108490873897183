import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminPhysical, getAdminPhysical, PhysicalResult } from 'api/hs/definitions/health-definitions/physical-check';
import AdminPhysicalCheckEditDialog from 'Common/Forms/Definitions/HealthDefinition/Physical-Check/AdminPhysicalCheckEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function PhysicalCheck() {
  return (
    <BaseDefinitionPage<PhysicalResult>
      listLabelPropName="label"
      fetchData={getAdminPhysical}
      FormDialog={AdminPhysicalCheckEditDialog}
      onDelete={deleteAdminPhysical}
      Icon={<DomainIcon />}
    />
  );
}
