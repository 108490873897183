/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePftDetailView } from '../models/PagePftDetailView';
import type { PftDetailRequest } from '../models/PftDetailRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PftDetailViewControllerService {

    /**
     * @param requestBody
     * @returns PagePftDetailView OK
     * @throws ApiError
     */
    public static getEmployeeEPftDetailsFiltered(
        requestBody: PftDetailRequest,
    ): CancelablePromise<PagePftDetailView> {
        return __request({
            method: 'POST',
            path: `/pft-details/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}