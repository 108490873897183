import React from 'react';

import ActionIndex from './actions';

interface Props {
  fetcher: () => Promise<any>;
  referenceId?: string;
  nearMissId?: string;
  actionReferenceValue?: string;
  baseFetcher?: () => Promise<any>;
}
export default function CustomAction({ fetcher, referenceId, nearMissId, actionReferenceValue, baseFetcher, noPagination }: Props) {
  return (
    <ActionIndex
      baseFetcher={baseFetcher}
      fetcher={fetcher}
      referenceId={referenceId}
      nearMissId={nearMissId}
      actionReferenceValue={actionReferenceValue}
    />
  );
}
