import React from 'react';

import Menu from './Menu';

export default function Sidebar() {
  return (
    <>
      <Menu />
    </>
  );
}
