import React, { useState } from 'react';

import { FirmSubscriptionsModel, getFirmSubscription } from 'api/hs/definitions/firm-definitions/firm-subscriptions';
import { useFetch } from 'utils';

import { FirmSubscriptionsContext } from './firm-subscription.context';
import FirmSubscriptions from './firm-subscriptions';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<FirmSubscriptionsModel>(null);

  const { data, fetch } = useFetch(() => getFirmSubscription(), [], { setLoading });

  return (
    <FirmSubscriptionsContext.Provider
      value={{
        firmSubscriptions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <FirmSubscriptions />
    </FirmSubscriptionsContext.Provider>
  );
}
