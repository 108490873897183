import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';

import { AccidentControllerService, AccidentView, AccidentViewRequest } from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertByFieldNameSum } from '../utils/chart-data-converter-pie';
import { getCountOfFieldArr, sumValueOfFieldArr } from '../utils/chart-data-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';
import { convertAccidentReviewPropertyToChartData } from './accident-chart-utils';
import AccidentPieChartGridContainer from './AccidentPieChartGridContainer';
import AccidentReviewContainer from './AccidentReviewContainer';

export const partHeaderStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  title: {
    fontSize: 'xx-large',
  },
});

export default function Accident() {
  const partHeaderSt = partHeaderStyles();

  const [chartData, setChartData] = useState<AccidentView[]>([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalAccidentCount, setTotalAccidentCount] = useState(0);
  const [effectedEmployeeCount, setEffectedEmployeeCount] = useState(0);
  const [totalLostWorkDays, setTotalLostWorkDays] = useState(0);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const {
    accidentReviewBehaviorDefinition,
    accidentReviewFactDefinition,
    accidentReviewPersonalDefinition,
    accidentReviewOrganizationDefinition,
    accidentReviewEquipmentDefinition,
    accidentReviewMaterialDefinition,
    accidentReviewImprovementDefinition,
  } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    setStartDate(values?.minDate);
    setEndDate(values?.maxDate);

    const request: AccidentViewRequest = {
      filter: {
        accidentDateMin: values?.minDate + 'T00:00:00',
        accidentDateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'date',
      },
    };
    const res = await AccidentControllerService.getAccidentViews1(request);
    setChartData(res.content);

    setTotalAccidentCount(res.totalElements);
    setEffectedEmployeeCount(getCountOfFieldArr(res.content, 'accidentEmployeeViews[].id'));
    setTotalLostWorkDays(sumValueOfFieldArr(res.content, 'accidentEmployeeViews[].lostWorkDays'));
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Kaza İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      <Grid container>
        <Grid item xs={4}>
          <SimpleCard className={classes.rootBgRed} title={'Toplam Kaza sayısı'} amount={totalAccidentCount} />
        </Grid>
        <Grid item xs={4}>
          <SimpleCard className={classes.rootBgOrange} title={'Etkilenen kişi sayısı'} amount={effectedEmployeeCount} />
        </Grid>
        <Grid item xs={4}>
          <SimpleCard className={classes.rootBgCyan} title={'Toplam gün kaybı'} amount={totalLostWorkDays} />
        </Grid>
      </Grid>
      {chartData.length! > 0 && dataDisplayOption != null && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }} raised>
                <CardHeader title="Kurumsal Kaza Verileri" classes={partHeaderSt} />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Şirket Bazlı Kaza Dağılımı" />{' '}
                        <DateGroupXYChart
                          chartName="accident-company-report"
                          data={chartData}
                          dateFieldName="date"
                          selectorFieldByName="companyName"
                          xAxesTitle="Şirketler"
                          xAxesText="Tarih"
                          yAxesText="Kaza Sayısı"
                          dataDisplayOption={dataDisplayOption}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="Departman Bazlı Kaza Dağılımı" />{' '}
                        <DateGroupXYChart
                          chartName="accident-department-report"
                          data={chartData}
                          dateFieldName="date"
                          selectorFieldByName="accidentDepartmentViews[].departmentName"
                          xAxesTitle="Departmanlar"
                          xAxesText="Tarih"
                          yAxesText="Kaza Sayısı"
                          dataDisplayOption={dataDisplayOption}
                        />
                      </Card>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Card style={{ padding: '10px', margin: '10px' }}>
                        <CardHeader title="İstasyon Bazlı Kaza Dağılımı" />{' '}
                        <DateGroupXYChart
                          chartName="accident-station-report"
                          data={chartData}
                          dateFieldName="date"
                          selectorFieldByName="accidentStationViews[].stationName"
                          xAxesTitle="İstasyonlar"
                          xAxesText="Tarih"
                          yAxesText="Kaza Sayısı"
                          dataDisplayOption={dataDisplayOption}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <AccidentReviewContainer data={chartData} startDate={startDate} endDate={endDate} />
            <Grid container>
              <Grid item xs={12}>
                <Card style={{ padding: '10px', margin: '10px' }} raised>
                  <CardHeader title="Kök Neden Analizi Verileri" classes={partHeaderSt} />
                  <CardContent>
                    <Grid container>
                      <AccidentPieChartGridContainer
                        title="Vücut Bölgesi Dağılımı"
                        chartProps={{
                          chartName: 'accident-body-region-report',
                          preparedData: convertByFieldNameSum(
                            chartData,
                            'accidentEmployeeViews[].employeeBodyRegionViews[].bodyRegionLabel'
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Yaralanma Türü Dağılımı"
                        chartProps={{
                          chartName: 'accident-injury-type-report',
                          preparedData: convertByFieldNameSum(
                            chartData,
                            'accidentEmployeeViews[].employeeInjuryTypeViews[].injuryTypeLabel'
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Kaza Agırlık Derecesi Dağılımı"
                        chartProps={{
                          chartName: 'accident-severity-report',
                          preparedData: convertByFieldNameSum(chartData, 'accidentEmployeeViews[].severityDefinition'),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Davranış Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-behavior-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisBehaviourViews[].behaviourTypeId',
                            accidentReviewBehaviorDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Durum Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-fact-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisFactViews[].factTypeId',
                            accidentReviewFactDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Kişisel Neden Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-personal-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisPersonalViews[].personalTypeId',
                            accidentReviewPersonalDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Organizasyon Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-organization-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisOrganizationViews[].organizationTypeId',
                            accidentReviewOrganizationDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Tesis/Makine/Ekipman Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-equipment-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisEquipmentViews[].equipmentTypeId',
                            accidentReviewEquipmentDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Malzeme Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-material-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisMaterialViews[].materialTypeId',
                            accidentReviewMaterialDefinition
                          ),
                        }}
                      />

                      <AccidentPieChartGridContainer
                        title="Kök Neden Bazlı Kaza Dağılımı"
                        chartProps={{
                          chartName: 'accident-review-improvement-report',
                          preparedData: convertAccidentReviewPropertyToChartData(
                            chartData,
                            'accidentReviewView.accidentReviewAnalysisImprovementViews[].improvementTypeId',
                            accidentReviewImprovementDefinition
                          ),
                        }}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
