import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import {
  patchOccupationalSafetyRegistriesDetections,
  saveOccupationalSafetyRegistriesDetections,
} from '../../../../../api/hs/occupational-safety-registries /occupational-safety-registries';
import { OccupationalSafetyRegistriesDetectionsResult } from '../../../../../api/hs/occupational-safety-registries /occupational-safety-registries-detections.result';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: OccupationalSafetyRegistriesDetectionsResult;

  pageId: string;

  fetch(): void;
}

export default function DetectionsForm({ open, onClose, data, pageId, fetch }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);
  const submit = useCallback(
    async values => {
      const payload = {
        description: values?.description,
        specialistSigned: true,
        physicianSigned: true,
        employerSigned: true,
        pageId,
      };
      try {
        if (!data?.id) {
          await saveOccupationalSafetyRegistriesDetections(payload);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await patchOccupationalSafetyRegistriesDetections(data?.id, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          await fetch();
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data, pageId, fetch]
  );
  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    formRef?.current?.methods?.setValue('description', data?.description);

    formRef?.current?.methods?.trigger();
  }, [data]);

  return (
    <Dialog color="primary" opened={open} onClose={onClose} title={data?.id ? 'Güncelle' : 'Tespit Ekle'}>
      <Form onSubmit={submit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} lg={12}>
            <TextField type="text" name="description" label="Açıklama" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
