import { Grid } from '@material-ui/core';
import React from 'react';

import { saveDisability, updateDisability } from 'api/hs/definitions/health-definitions/disability';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { DisabilityModel } from 'model/definitions/health-definitions/disability.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the allergy is created/updated
   */
  onClose(success: boolean): void;

  data: DisabilityModel;
}

export default function Disability({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<DisabilityModel>
      open={open}
      onClose={onClose}
      onSave={saveDisability}
      onUpdate={updateDisability}
      data={data}
      resourceReloadName="disabilities"
      createTitle="Yeni Engel Durumu Ekleme"
      updateTitle="Engel Durumu Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="name" label="Engel Durumu Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
