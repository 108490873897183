import { makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import Toolbar from 'Common/Toolbar';

import { HSTableContext } from '../../Common/Table/HSTable/hs-table.context';
import Filters from './Filters';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarOccupationalSafetyRegistriesList() {
  const classes = useStyles();

  const { data } = useContext(HSTableContext);

  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Onaylı Defter Listesi ({data?.length})
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop} />
      <Filters />
    </Toolbar>
  );
}
