import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../../../utils';
import { EmployeeTrainingListPlanningResult } from './employee-traning-list-planning.result';

export async function getEmployeeTrainingListPlanning(employeeId: string): Promise<EmployeeTrainingListPlanningResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EmployeeTrainingListPlanningResult[]>(`/trainings?employeeId=${employeeId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return dateSort(res.data);
}

export async function deleteEmployeeTrainingListPlanning(id: string, employeeId: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/${id}/training-employees/employees/${employeeId}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
