import React, { useState } from 'react';

import { DiagnosisModel } from 'api/hs/examination/diagnosis';

import Diagnosis from './diagnosis';
import { DiagnosisContextContext } from './diagnosis.context';

export interface DiagnosisProps {
  diagnosis: DiagnosisModel[];
  reloadDiagnosis(): Promise<any>;
}

export default function DiagnosisRoot({ diagnosis, reloadDiagnosis }: DiagnosisProps) {
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<DiagnosisModel>(null);

  return (
    <DiagnosisContextContext.Provider
      value={{
        diagnosis,
        reload: reloadDiagnosis,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Diagnosis />
    </DiagnosisContextContext.Provider>
  );
}
