import { asyncMap } from '@thalesrc/js-utils';
import React, { useState } from 'react';

import { useAsyncEffect, useFetch } from 'utils';

import { EmergencyCaseTeamMemberControllerService, TrainingEmployeeView, TrainingEmployeeViewControllerService } from '../../api/client';
import { EmergencyCaseTeamTrainingDefinitionControllerService } from '../../api/client';
import { EmergencyCaseTeamDto, EmergencyCaseTeamMemberDto } from '../../api/client';
import { getUserById } from '../../api/hs/users';
import { CaseTeamsDetailContext } from './case-teams-detail.context';
import CaseTeamDetail from './ListAdd';

interface Props {
  caseTeamsRowData: EmergencyCaseTeamDto;
}
export default function CaseTeamsDetail({ caseTeamsRowData }: Props) {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [newCaseTrainingsNewDialog, setNewCaseTrainingsNewDialog] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [newCaseTeamMembersNewDialog, setNewCaseTeamMembersNewDialog] = useState(false);
  const [employeeIdTrainingMap, setEmployeeIdTrainingMap] = useState<Map<string, TrainingEmployeeView[]>>();

  const [selectedData, setSelectedData] = useState<EmergencyCaseTeamMemberDto>(null);

  const request = (employeeId: string) => {
    return {
      trainingEmployeeFilter: {
        employeeIds: [employeeId],
        hasTraining: true,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
      },
    };
  };

  const { data, fetch } = useFetch(
    async () => {
      const res = await EmergencyCaseTeamMemberControllerService.getAllEmergencyCaseTeam1(caseTeamsRowData?.id, true);
      return await asyncMap(res, async item => ({
        ...item,
        employeeData: item?.employeeId
          ? (await TrainingEmployeeViewControllerService.getTrainingEmployeeViews(request(item?.employeeId))).content
          : [],
      }));
    },
    [],
    { setLoading }
  );

  const { data: emergencyCaseTeamTraining, fetch: emergencyCaseTeamTrainingReload } = useFetch(
    async () => await EmergencyCaseTeamTrainingDefinitionControllerService.getAllEmergencyCaseTeamTraining(caseTeamsRowData?.id),
    [],
    { setLoading }
  );

  useAsyncEffect(async () => {
    if (emergencyCaseTeamTraining && data) {
      const trainingDefinitionIds = emergencyCaseTeamTraining?.reduce((acc, item) => [...acc, item?.trainingDefinitionId], []);
      const employeeIds = data?.reduce((acc, item) => [...acc, item?.employeeId], []);

      const payload = {
        trainingEmployeeFilter: {
          employeeIds,
          trainingDefinitionIds,
          hasTraining: true,
        },
        pagingAndSortingRequestParam: {
          page: 0,
          size: 2147483647,
        },
      };

      const res = await TrainingEmployeeViewControllerService.getTrainingEmployeeViews(payload);
      const empIdTrainingMap = new Map<string, TrainingEmployeeView[]>();

      res?.content.forEach(tr => {
        if (empIdTrainingMap.has(tr.employeeId)) {
          empIdTrainingMap.get(tr.employeeId).push(tr);
        } else {
          const arr = [tr];
          empIdTrainingMap.set(tr.employeeId, arr);
        }
      });
      setEmployeeIdTrainingMap(empIdTrainingMap);
    }
  }, [emergencyCaseTeamTraining, data]);

  return (
    <CaseTeamsDetailContext.Provider
      value={{
        employeeIdTrainingMap,
        setNewCaseTrainingsNewDialog,
        newCaseTrainingsNewDialog,
        emergencyCaseTeamTrainingReload,
        emergencyCaseTeamTraining,
        emergencyCaseTeams: caseTeamsRowData,
        emergencyCaseTeamMember: data,
        reload: fetch,
        newCaseTeamMembersNewDialog,
        setNewCaseTeamMembersNewDialog,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <CaseTeamDetail />
    </CaseTeamsDetailContext.Provider>
  );
}
