import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { LocationPayload } from './Location.payload';
import { LocationResult } from './Location.result';

export async function getLocation(): Promise<LocationResult[]> {
  const [error, res] = await tryCatch(HSApi.get<LocationResult[]>(`/location-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveLocation(body: LocationPayload): Promise<LocationResult> {
  const [err, res] = await tryCatch(HSApi.post('/location-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateLocation({ id, ...body }: MakeRequired<Partial<LocationResult>, 'id'>): Promise<LocationResult> {
  const [error, res] = await tryCatch(HSApi.put<LocationResult>(`/location-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteLocation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/location-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
