import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { PolyclinicExamOpinionsPayload } from './polyclinic-exam-opinions.payload';
import { PolyclinicExamOpinionsResult } from './polyclinic-exam-opinions.result';

export async function getPolyclinicExamOpinions(examinationId?: string): Promise<PolyclinicExamOpinionsResult> {
  let res;
  try {
    res = await HSApi.get<PolyclinicExamOpinionsResult>(`/polyclinic-exam-opinions?examinationId=${examinationId}`);
  } catch (e) {
    if (e.status === 404) {
      return null;
    }
    console.error(e); // tslint:disable-line:no-console
    throw e;
  }

  return res.data;
}

export async function savePolyclinicExamOpinions(body: PolyclinicExamOpinionsPayload): Promise<PolyclinicExamOpinionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/polyclinic-exam-opinions', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
