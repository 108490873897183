/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationRequest } from '../models/NotificationRequest';
import type { PageRestReportView } from '../models/PageRestReportView';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { RestReportViewRequest } from '../models/RestReportViewRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RestReportViewControllerService {

    /**
     * @param requestBody
     * @returns PageRestReportView OK
     * @throws ApiError
     */
    public static getRestReportListFiltered(
        requestBody: RestReportViewRequest,
    ): CancelablePromise<PageRestReportView> {
        return __request({
            method: 'POST',
            path: `/rest-reports/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static restReportSendMail(
        requestBody: NotificationRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/rest-reports/detail-view/notification`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static restReportViewExport(
        id: string,
        type?: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/rest-reports/${id}/detail-view/export`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param psRequestParam
     * @returns PageRestReportView OK
     * @throws ApiError
     */
    public static getRestReportList(
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageRestReportView> {
        return __request({
            method: 'GET',
            path: `/rest-reports/view`,
            query: {
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static restReportViewExport1(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/rest-reports/view/export`,
            query: {
                'type': type,
            },
        });
    }

}