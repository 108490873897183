import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { DisabilityPayload } from './Disability.payload';
import { DisabilityResult } from './Disability.result';

export async function getDisability(): Promise<DisabilityResult[]> {
  const [error, res] = await tryCatch(HSApi.get<DisabilityResult[]>(`/firms/disability-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveDisability(body: DisabilityPayload): Promise<DisabilityResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/disability-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateDisability({ id, ...body }: MakeRequired<Partial<DisabilityResult>, 'id'>): Promise<DisabilityResult> {
  const [error, res] = await tryCatch(HSApi.put<DisabilityResult>(`/firms/disability-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteDisability(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/disability-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminDisability(): Promise<DisabilityResult[]> {
  const [error, res] = await tryCatch(HSApi.get<DisabilityResult[]>(`/disability-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminDisability(body: DisabilityPayload): Promise<DisabilityResult> {
  const [err, res] = await tryCatch(HSApi.post('/disability-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminDisability({ id, ...body }: MakeRequired<Partial<DisabilityResult>, 'id'>): Promise<DisabilityResult> {
  const [error, res] = await tryCatch(HSApi.put<DisabilityResult>(`/disability-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminDisability(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/disability-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
