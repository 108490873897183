import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { SaveHospitalHistoryPayload, UpdateHospitalHistoryPayload } from './hospital-history.payload';
import { HospitalHistoryResult } from './hospital-history.result';

export async function getHospitalHistory(employeeId: string, examinationId: string): Promise<HospitalHistoryResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<HospitalHistoryResult>(`/hospital-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<HospitalHistoryResult>(`/hospital-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
    throw e;
  }

  return dateSort(res.data);
}

export async function saveHospitalHistory(body: SaveHospitalHistoryPayload): Promise<HospitalHistoryResult> {
  const [err, res] = await tryCatch(HSApi.post('/hospital-histories', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function updateHospitalHistory(historyId: string, body: UpdateHospitalHistoryPayload): Promise<HospitalHistoryResult> {
  const [err, res] = await tryCatch(HSApi.put(`/hospital-histories/${historyId}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function deleteHospitalHistory(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/hospital-histories/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
