/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MatrixPossibilityDefinitionDto } from '../models/MatrixPossibilityDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class MatrixPossibilityDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static updateMatrixPossibilityDefinitions(
        id: string,
        requestBody: MatrixPossibilityDefinitionDto,
    ): CancelablePromise<MatrixPossibilityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/matrix-possibility-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllMatrixPossibilityDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/matrix-possibility-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmMatrixPossibilityDefinitions(
        id: string,
        requestBody: MatrixPossibilityDefinitionDto,
    ): CancelablePromise<MatrixPossibilityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/matrix-possibility-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmMatrixPossibilityDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/matrix-possibility-definitions/${id}`,
        });
    }

    /**
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static getAllMatrixPossibilityDefinitions(): CancelablePromise<Array<MatrixPossibilityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/matrix-possibility-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static saveMatrixPossibilityDefinitions(
        requestBody: MatrixPossibilityDefinitionDto,
    ): CancelablePromise<MatrixPossibilityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/matrix-possibility-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmMatrixPossibilityDefinitions(): CancelablePromise<Array<MatrixPossibilityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/matrix-possibility-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns MatrixPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmMatrixPossibilityDefinitions(
        requestBody: MatrixPossibilityDefinitionDto,
    ): CancelablePromise<MatrixPossibilityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/matrix-possibility-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}