import { createContext } from 'react';

import { InjectionModel, InjectionResult } from 'api/hs/nurse-operations/Injection';

export interface InjectionTypeContext {
  injections: InjectionResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: InjectionModel;

  setSelectedData(data: InjectionModel): void;
}

export const InjectionContext = createContext<InjectionTypeContext>(null);
