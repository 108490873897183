import HSApi from '../../HSApi';
import { GetTrainingEmployeePayload, TrainingEmployeeFilters } from './get-traininig-employee.payload';
import { TrainingResult } from './training.result';
import { TrainingEmployeesListResult } from './trainining-employee-list.result';

export async function getTrainingEmployeeList(
  page = 0,
  size = 20,
  order: null | string,
  filters: TrainingEmployeeFilters
): Promise<TrainingEmployeesListResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof TrainingEmployeeFilters)[] = [
    'minDuration',
    'isParticipated',
    'isArchived',
    'maxDuration',
    'startDate',
    'endDate',
    'isPlanned',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.startDate) {
    parsedFilters.startDate = new Date(parsedFilters.startDate).toISOString();
  }

  if (parsedFilters.endDate) {
    parsedFilters.endDate = new Date(parsedFilters.endDate).toISOString();
  }

  const payload: GetTrainingEmployeePayload = {
    trainingEmployeeFilter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<TrainingEmployeesListResult>(`/training/employee/views`, payload);

  return res.data;
}

export async function ExportTrainingEmployeeList(filters: TrainingEmployeeFilters): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof TrainingEmployeeFilters)[] = [
    'minDuration',
    'isParticipated',
    'isArchived',
    'maxDuration',
    'startDate',
    'endDate',
    'isPlanned',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.startDate) {
    parsedFilters.startDate = new Date(parsedFilters.startDate).toISOString();
  }

  if (parsedFilters.endDate) {
    parsedFilters.endDate = new Date(parsedFilters.endDate).toISOString();
  }

  const payload = {
    trainingEmployeeFilter: parsedFilters,
  };

  const res = await HSApi.post<TrainingEmployeesListResult>(`/training/employees/view/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'egitim-listesi.xlsx';
  a.click();
}

export async function getTrainingById(trainingId: string): Promise<TrainingResult[]> {
  let res;
  try {
    res = await HSApi.get<TrainingResult[]>(`/training/employees/${trainingId}/view`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}
