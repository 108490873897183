import { ITimeInterval } from '@amcharts/amcharts4/core';

import { Options } from '../../Common/Form/RadioGroup';

export interface Option {
  text: string;
  amcTimeInterval: ITimeInterval;
}

export enum DisplayOption {
  TOTAL = 'TOTAL',
  ANNUALLY = 'ANNUALLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export const displayOptionMap = new Map<DisplayOption, Option>([
  [DisplayOption.TOTAL, { text: 'Toplam', amcTimeInterval: { timeUnit: 'year', count: 100 } }],
  [DisplayOption.ANNUALLY, { text: 'Yıllık', amcTimeInterval: { timeUnit: 'year', count: 1 } }],
  [DisplayOption.SEMIANNUALLY, { text: '6 Aylık', amcTimeInterval: { timeUnit: 'month', count: 6 } }],
  [DisplayOption.QUARTERLY, { text: '3 Aylık', amcTimeInterval: { timeUnit: 'month', count: 3 } }],
  [DisplayOption.MONTHLY, { text: 'Aylık', amcTimeInterval: { timeUnit: 'month', count: 1 } }],
  [DisplayOption.WEEKLY, { text: 'Aylık', amcTimeInterval: { timeUnit: 'day', count: 7 } }],
  [DisplayOption.DAILY, { text: 'Günlük', amcTimeInterval: { timeUnit: 'day', count: 1 } }],
]);

export const Test = new Map<DisplayOption, Option>([
  [DisplayOption.TOTAL, { text: 'Toplam', amcTimeInterval: { timeUnit: 'year', count: 100 } }],
  [DisplayOption.ANNUALLY, { text: 'Yıllık', amcTimeInterval: { timeUnit: 'year', count: 1 } }],
  [DisplayOption.SEMIANNUALLY, { text: '6 Aylık', amcTimeInterval: { timeUnit: 'month', count: 6 } }],
  [DisplayOption.QUARTERLY, { text: '3 Aylık', amcTimeInterval: { timeUnit: 'month', count: 3 } }],
  [DisplayOption.MONTHLY, { text: 'Aylık', amcTimeInterval: { timeUnit: 'month', count: 1 } }],
  [DisplayOption.WEEKLY, { text: 'Aylık', amcTimeInterval: { timeUnit: 'day', count: 7 } }],
  [DisplayOption.DAILY, { text: 'Günlük', amcTimeInterval: { timeUnit: 'day', count: 1 } }],
]);

export function convertToRadioOptions(dOptions: DisplayOption[]): Options[] {
  return dOptions.map(d => {
    return { value: d.toString(), text: displayOptionMap.get(d).text } as Options;
  });
}
