/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionDefinitionDto } from '../models/PositionDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PositionDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static updatePositionDefinition(
        id: string,
        requestBody: PositionDefinitionDto,
    ): CancelablePromise<PositionDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/position-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deletePositionDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/position-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmPositionDefinition(
        id: string,
        requestBody: PositionDefinitionDto,
    ): CancelablePromise<PositionDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/position-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmPositionDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/position-definitions/${id}`,
        });
    }

    /**
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static getAllPositionDefinitions(): CancelablePromise<Array<PositionDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/position-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static savePositionDefinition(
        requestBody: PositionDefinitionDto,
    ): CancelablePromise<PositionDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/position-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param authenticationInfo
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmPositionDefinitions(
        authenticationInfo?: string,
    ): CancelablePromise<Array<PositionDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/position-definitions`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
        });
    }

    /**
     * @param requestBody
     * @returns PositionDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmPositionDefinition(
        requestBody: PositionDefinitionDto,
    ): CancelablePromise<PositionDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/position-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}