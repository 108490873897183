/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PpeDefinitionDto } from './PpeDefinitionDto';

export type PpeOutputViewDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    imagePath?: string;
    employeeName?: string;
    employeeId?: string;
    companyName?: string;
    model?: string;
    brand?: string;
    type?: string;
    outputReason?: string;
    reason?: PpeOutputViewDto.reason;
    outputGroupId?: string;
    firmId?: string;
    exposureTime?: number;
    refundDate?: string;
    companyId?: string;
    positionId?: string;
    classId?: string;
    classLabel?: string;
    typeId?: string;
    positionName?: string;
    ppeDefinitionId?: string;
    serialNumber?: string;
    isRefund?: string;
    refund?: boolean;
    count?: number;
    userName?: string;
    lastModifiedByName?: string;
    stockNumber?: string;
    ppeDefinition?: PpeDefinitionDto;
}

export namespace PpeOutputViewDto {

    export enum reason {
        DEBIT = 'DEBIT',
        REFUND = 'REFUND',
        EXPIRED = 'EXPIRED',
        DEFECTIVE = 'DEFECTIVE',
        BROKEN = 'BROKEN',
    }


}
