import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MUIAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { defer, findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { Fragment, useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { DiagnosisModel } from 'api/hs/examination/diagnosis';
import {
  deleteRestReport,
  getRestReports,
  previewReport,
  RestReportResultItem,
  saveRestReport,
  SaveRestReportPayload,
  updateRestReport,
  UpdateRestReportPayload,
  uploadRestReportFile,
} from 'api/hs/examination/rest-reports';
import { deleteRestReportFileUpload } from 'api/hs/file-uploads/rest-report-file-upload';
import Button from 'Common/Button';
import Checkbox from 'Common/Form/Checkbox';
import Icd10Selection from 'Common/Form/Icd10Selection';
import { Icd10CacheContext } from 'Common/Form/Icd10Selection/icd10.context';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import EmailForm from 'Common/Forms/EmailForm';
import PreviewForm from 'Common/Forms/PreviewForm';
import PanelWithDialog, { PanelWithDialogProps, PanelWithDialogRef } from 'Common/PanelWithDialog';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { NotificationTypeEnum } from 'model/notification-type.enum';
import { dateSort, openFile, printFile } from 'utils';
import { downlaodFile, useFetch, useXhr } from 'utils';

import DateInput from '../../../Common/Form/DateInput';
import {exportEmployeeReport} from "../../../api/hs/employee";
const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    borderRadius: '30px 0px 30px 0',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MUIAccordion);

function reverseDateFormat(date: string): string {
  return date.split('-').reverse().join('-');
}

function calculateDuration(startDate: string, endDate: string) {
  const end = new Date(endDate);
  const start = new Date(startDate);

  return Math.max(Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1), 1);
}

enum DialogMode {
  New = 1,
  NewExternal = 1 << 1,
  Edit = 1 << 2,
  EditExternal = 1 << 3,
  IsInternal = New | Edit,
  IsExternal = NewExternal | EditExternal,
  IsEdit = Edit | EditExternal,
}

function getDialogTitle(mode: DialogMode): string {
  switch (mode) {
    case DialogMode.New:
      return 'İstirahat Raporu Ekle';
    case DialogMode.NewExternal:
      return 'Dışarıdan İstirahat Raporu Ekle';
    case DialogMode.Edit:
      return 'İstirahat Raporu Düzenle';
    case DialogMode.EditExternal:
      return 'Dışarıdan İstirahat Raporu Düzenle';
    default:
      return '';
  }
}

export interface Props {
  diagnosis: DiagnosisModel[];
}

export default function Reports({ diagnosis }: Props) {
  const [loading, setLoading] = useState(true);
  const [previewData, setPreviewData] = useState('');
  const [newPreviewDialog, setPreviewDialogOpened] = useState(false);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { labels, getOptions } = useContext(Icd10CacheContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [dialogMode, setDialogMode] = useState(DialogMode.New);
  const { restReportDefinitions } = useContext(SelectOptionsContext);
  const [selectedReport, setSelectedReport] = useState<RestReportResultItem>(null);
  const actions = useRef<PanelWithDialogRef<RestReportResultItem, RestReportResultItem & { restReportIcd10s: string[] }>>();
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const { data, fetch } = useFetch(
    async () => {
      const res = await getRestReports(employeeId, examinationId);
      getOptions(
        ...res
          .flatMap(report => report.restReportIcd10s)
          .map(icd10 => icd10.icd10Id)
          .filter(isTruthy)
      );

      return dateSort(res);
    },
    [],
    { setLoading }
  );

  const closeMoreMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openMoreMenu = useCallback(
    (report: RestReportResultItem) => (e: React.MouseEvent<HTMLElement>) => {
      setSelectedReport(report);
      setAnchorEl(e.target as HTMLElement);
    },
    []
  );

  const closeEmailDialog = useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const closePreviewDialog = useCallback(() => {
    setPreviewDialogOpened(false);
  }, []);

  const saveRestReportReq = useXhr(
    async (body: Exclude<SaveRestReportPayload, 'employeeId' | 'examinationId'>) => {
      await saveRestReport({ ...body, employeeId, examinationId });
      await fetch();
    },
    'Rapor Oluşturuldu',
    'Rapor kaydedilirken bir hata oluştu',
    [fetch, employeeId, examinationId]
  );

  const updateRestReportReq = useXhr(
    async (body: UpdateRestReportPayload) => {
      await updateRestReport(selectedReport.id, body);
      await fetch();
    },
    'Rapor Güncellendi',
    'Rapor güncellenirken bir hata oluştu',
    [fetch, selectedReport?.id]
  );

  const deleteRestReportReq = useXhr(
    async () => {
      await deleteRestReport(selectedReport.id);
      await fetch();
    },
    'Rapor Silindi',
    'Rapor silinirken bir hata oluştu',
    [fetch, selectedReport?.id]
  );

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => uploadRestReportFile(selectedReport.id, file)));
      await fetch();
    },
    'Rapor dosyaları yüklendi',
    'Rapor dosyaları yüklenirken bir hata oluştu',
    [selectedReport?.id]
  );

  const openNewReport = useCallback(async () => {
    if (examinationId && !diagnosis.length) {
      return showMessage('Lütfen önce tanı girin', 'warning', 'info');
    }

    setDialogMode(DialogMode.New);

    const { submitted, form } = await actions.current.openDialog(null);

    if (!submitted) {
      return;
    }

    await saveRestReportReq({
      ...form,
      durationInDays: calculateDuration(form.startDate, form.endDate),
      isInternal: true,
      restReportIcd10s: examinationId
        ? diagnosis.flatMap(diag => diag.icd10s).map(diag => ({ icd10Id: diag }))
        : form.restReportIcd10s.map((id: string) => ({ icd10Id: id })),
    });
  }, [saveRestReportReq, examinationId, diagnosis, showMessage]);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteRestReportFileUpload(id);
      await fetch();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const openNewExternalReport = useCallback(async () => {
    setDialogMode(DialogMode.NewExternal);

    const { submitted, form } = await actions.current.openDialog(null);

    if (!submitted) {
      return;
    }

    await saveRestReportReq({
      ...form,
      durationInDays: calculateDuration(form.startDate, form.endDate),
      isInternal: false,
      restReportIcd10s: form.restReportIcd10s.map((id: string) => ({ icd10Id: id })),
    });
  }, [saveRestReportReq]);

  const openEdit = useCallback(async () => {
    closeMoreMenu();
    setDialogMode(!selectedReport.isInternal ? DialogMode.EditExternal : DialogMode.Edit);

    const { submitted, form } = await actions.current.openDialog(selectedReport);

    if (!submitted) {
      return;
    }

    await updateRestReportReq({
      ...form,
      durationInDays: calculateDuration(form.startDate, form.endDate),
      restReportIcd10s: form.restReportIcd10s.map((id: string) => ({ icd10Id: id })),
    });
  }, [selectedReport, closeMoreMenu, updateRestReportReq]);

  const openMail = useCallback(async () => {
    closeMoreMenu();
    setEmailDialogOpen(true);
  }, [closeMoreMenu]);

  const onPreview = useCallback(async () => {
    const htmlData = await previewReport(selectedReport?.id);
    setPreviewData(htmlData);
    setPreviewDialogOpened(true);
    closeMoreMenu();
  }, [selectedReport, setPreviewDialogOpened, closeMoreMenu, setPreviewData]);

  const prepareForm = useCallback<PanelWithDialogProps<RestReportResultItem>['onDialogOpen']>(
    async ({ reset, setValue, trigger, data: dialogData, opened }) => {
      if (!opened) {
        return;
      }

      await defer();

      reset();

      if (DialogMode.IsInternal & dialogMode) {
        await defer();
        setValue(
          'restReportIcd10s',
          examinationId ? (diagnosis || []).flatMap(diag => diag.icd10s) : (dialogData?.restReportIcd10s || []).map(icd => icd.icd10Id)
        );
        trigger('restReportIcd10s');
      }

      if (DialogMode.EditExternal === dialogMode) {
        setValue(
          'restReportIcd10s',
          dialogData.restReportIcd10s.map(icd => icd.icd10Id)
        );

        trigger('restReportIcd10s');
      }

      if (DialogMode.IsEdit & dialogMode) {
        const { restReportIcd10s, restReportReasonDefinitionId, hasIncapacityReport, ...formData } = dialogData;

        for (const [key, value] of Object.entries(formData)) {
          setValue(key, value);
        }

        await defer();
        setValue(
          'restReportIcd10s',
          restReportIcd10s.map(icd => icd.icd10Id)
        );
        setValue('restReportReasonDefinitionId', restReportReasonDefinitionId);
        setValue('hasIncapacityReport', hasIncapacityReport);

        trigger();
      }
    },
    [diagnosis, dialogMode, examinationId]
  );

  const exportFile = useCallback(async () => {
    var name = reverseDateFormat(selectedReport?.startDate) +' / '+ reverseDateFormat(selectedReport?.endDate) + '('+selectedReport?.durationInDays+' Gün)';
    await exportEmployeeReport(selectedReport?.id, name);
    closeMoreMenu();
  }, [selectedReport, closeMoreMenu]);

  const removeRestReport = useCallback(async () => {
    closeMoreMenu();
    const submitted = await openWarnDialog({ text: 'İstirahat raporunu silmek istediğinize emin misiniz?' });

    if (!submitted) {
      return;
    }

    await deleteRestReportReq();
  }, [closeMoreMenu, deleteRestReportReq, openWarnDialog]);

  const uploadFile = useCallback(async () => {
    closeMoreMenu();
    const files = await openFileDialog({ title: 'Rapora Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!files) {
      return;
    }

    await uploadFilesReq(files);
  }, [openFileDialog, closeMoreMenu, uploadFilesReq]);

  return (
    <>
      <PanelWithDialog
        form={(values, setValue) => {
          if (values.startDate && values.endDate) {
            setValue('durationInDays', calculateDuration(values.startDate, values.endDate));
          }

          return (
            <Grid container>
              <Grid item sm={5} className="px-1">
                <DateInput name="startDate" type="date" label="Başlangıç Tarihi" required />
              </Grid>
              <Grid item sm={5} className="px-1">
                <DateInput name="endDate" type="date" label="Bitiş Tarihi" required />
              </Grid>
              <Grid item sm={2} className="px-1">
                <TextField name="durationInDays" type="number" label="Gün" disabled />
              </Grid>
              <Grid item sm={12} className="px-1">
                <Select name="restReportReasonDefinitionId" options={restReportDefinitions} label="Sebep" required />
              </Grid>
              <Grid item sm={12} className="px-1">
                <Icd10Selection name="restReportIcd10s" disabled={examinationId && !(dialogMode & DialogMode.IsExternal)} />
              </Grid>
              <Grid item sm={12} className="px-1">
                <TextField name="description" label="Açıklama" rows={3} variant="outlined" />
              </Grid>
              {dialogMode & DialogMode.IsExternal ? (
                <Grid item sm={12} className="px-1">
                  <Checkbox name="hasIncapacityReport" label="İş Göremezlik Raporu Var." />
                </Grid>
              ) : null}
            </Grid>
          );
        }}
        dialogTitle={getDialogTitle(dialogMode)}
        indicationWrapperProps={{ data, loading, errorMessage: 'Henüz hiç rapor girilmemiş' }}
        onDialogOpen={prepareForm}
        ref={actions}>
        <List key="hs-reportFileUploadList">
          {data.map(report => (
            <ListItem key={report.id}>
              <ListItemIcon>
                <Icon className="icon-dna" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {`${reverseDateFormat(report.startDate)} / ${reverseDateFormat(report.endDate)} (${report.durationInDays} Gün)`}
                    {!report.isInternal ? (
                      <Chip component="span" className="ml-2 text-x-small" label="Dışarıdan" color="secondary" />
                    ) : null}
                    {report.hasIncapacityReport ? (
                      <Chip component="span" className="ml-2 text-x-small" label="İş Göremez" color="secondary" />
                    ) : null}
                  </>
                }
                secondary={
                  <>
                    <span className="display-block">
                      {findByKey(restReportDefinitions, 'value', report.restReportReasonDefinitionId)?.text}
                    </span>
                    <span className="display-block">Tanı: {report.restReportIcd10s.map(icd10 => labels[icd10.icd10Id]).join(', ')}</span>
                    {report?.filesRestReport.length !== 0 ? (
                      <Accordion className="mt-1">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Icon style={{ color: '#2196f3' }} className="icon-attachment" />
                          <Typography style={{ color: 'gray' }}> Ek Dosyalar</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ width: '100%' }}>
                          <List style={{ paddingLeft: '15px', width: '100%' }} key={report.id}>
                            {report.filesRestReport.map((reportFiles, itemIndex) => (
                              <Fragment key={itemIndex}>
                                <ListItem>
                                  <ListItemAvatar>
                                    <FileCopyIcon style={{ color: 'gray' }} />
                                  </ListItemAvatar>
                                  <ListItemText primary={reportFiles?.originalFileName} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() =>
                                        downlaodFile(
                                          `/saglik-gozetimi/${reportFiles?.path}/${reportFiles?.name}`,
                                          reportFiles?.originalFileName
                                        )
                                      }
                                      edge="end"
                                      aria-label="download">
                                      <GetAppIcon />
                                    </IconButton>

                                    {reportFiles?.type === 'image/jpeg' || reportFiles?.type === 'image/png' ? (
                                      <IconButton
                                        onClick={() =>
                                          openFile(
                                            `/saglik-gozetimi/${reportFiles?.path}/${reportFiles?.name}`,
                                            reportFiles?.originalFileName
                                          )
                                        }
                                        edge="end"
                                        aria-label="delete">
                                        <VisibilityIcon />
                                      </IconButton>
                                    ) : null}
                                    <IconButton onClick={() => removeFile(reportFiles)} edge="end" aria-label="delete">
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </Fragment>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ) : null}

                    <Divider style={{ marginTop: '15px' }} />
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={openMoreMenu(report)}>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </PanelWithDialog>
      <footer className="text-right mt-2">
        <Button color="secondary" variant="contained" className="mr-2" onClick={openNewExternalReport}>
          Dışarıdan İstirahat Raporu Gir
        </Button>
        <Button color="primary" variant="contained" onClick={openNewReport}>
          İstirahat Raporu Gir
        </Button>
      </footer>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMoreMenu}>
        <MenuItem
          onClick={() => printFile(`/rest-reports/${selectedReport?.id}/detail-view/export?type=HTML`)}>
          <ListItemIcon>
            <PrintIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Yazdır</Typography>
        </MenuItem>
        <MenuItem onClick={uploadFile}>
          <ListItemIcon>
            <FileIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Dosya Yükle</Typography>
        </MenuItem>
        <MenuItem onClick={openEdit}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        <MenuItem onClick={openMail}>
          <ListItemIcon>
            <MailIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Mail Gönder</Typography>
        </MenuItem>
        <MenuItem onClick={removeRestReport}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem onClick={exportFile}>
          <ListItemIcon>
            <GetAppIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">İndir</Typography>
        </MenuItem>
        <MenuItem onClick={onPreview}>
          <ListItemIcon>
            <VisibilityIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Önizleme</Typography>
        </MenuItem>
      </Menu>
      <EmailForm
        notificationType={NotificationTypeEnum.REST_REPORT}
        notificationReferenceId={selectedReport?.id}
        open={emailDialogOpen}
        onClose={closeEmailDialog}
      />
      <PreviewForm open={newPreviewDialog} htmlData={previewData} onClose={closePreviewDialog} />
    </>
  );
}
