import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminExposure, ExposureResult, getAdminExposure } from 'api/hs/definitions/workplace-definition/exposure';
import AdminExposureEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Exposure/AdminExposureEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminExposure() {
  return (
    <BaseDefinitionPage<ExposureResult>
      listLabelPropName="exposureName"
      fetchData={getAdminExposure}
      FormDialog={AdminExposureEditDialog}
      onDelete={deleteAdminExposure}
      Icon={<DomainIcon />}
    />
  );
}
