export class MuayeneBilgiDto
{
  calisanTcKNo:number;
  ihKimlikNo:number;
  imzalayanKimlikNo:number;
  calisanMeslegi:string;
  calismaOrtamiDTO:CalismaOrtamiBilgiDto;
  calismaSekliDTO:CalismaSekliBilgiDto;
  kullanilanIsEkipmanlari:IsEkipmanlariBilgiDto[];
  isyeriSgkSicilNo:string;
  muayeneRaporTarihi:string;
  raporGecerlikTarihi:string;
  raporTuru:string;
  sonucVeKanaat:string;
  action:string;
  zamanDamgasi:string;
  rontgenYapildiMi:number;
  solunumFonkTestiYapildiMi:number;
  isitmeTestiYapildiMi:number;
  idrarTetkikiYapildiMi:number;
  kanTetkikiYapildiMi:number;
}
export class CalismaOrtamiBilgiDto
{
  fizikiOrtamSinifi:string[];
  biyolojikEtkenlerSinifi:string[];
  tozYonetmeligiSinifi:string[];
  gurultuSinifi:string[];
  kimyasalMaddeSinifi:string[];
  havaSinifi:string[];
  elektrikSinifi:string[];
}
export class CalismaSekliBilgiDto
{
  calismaPozisyonu:string;
  aracKullanimi:string;
  calismaZamani:string;
}
export class IsEkipmanlariBilgiDto
{
  bagliReferansKodu:string;
  ustReferansKodu:string;
}

export class MuayeneBilgiResponseDto {

  aciklama:string;
  bildirimNo:string;
  sonuc:string;
}
