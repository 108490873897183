/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PositionIbysWorkConditionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    positionId?: string;
    physicalWorkCondition?: PositionIbysWorkConditionDto.physicalWorkCondition;
    code?: number;
}

export namespace PositionIbysWorkConditionDto {

    export enum physicalWorkCondition {
        PHYSICAL = 'PHYSICAL',
        AIR = 'AIR',
        NOISE = 'NOISE',
        ELECTRICITY = 'ELECTRICITY',
        DUST_REGULATIONS = 'DUST_REGULATIONS',
        CHEMICAL = 'CHEMICAL',
        BIOLOGICAL_FACTORS = 'BIOLOGICAL_FACTORS',
    }


}
