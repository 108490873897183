import { FormControl, FormGroup } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { AnalysisModel, AnalysisPayloadModel, saveAnalysis } from 'api/hs/examination/analysis';
import Checkbox from 'Common/Form/Checkbox';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { dateToInputFormat, makeSlug, useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: AnalysisModel;
}

export default function AnalysisForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { analysisCategoryDefinitions } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          const selectedAnalyzes: AnalysisPayloadModel[] = [];
          for (const [key, value] of Object.entries(values)) {
            const selectedDefinitions = analysisCategoryDefinitions.filter(item => value && makeSlug(item.text as string) === key);
            selectedDefinitions.forEach(def => {
              selectedAnalyzes.push({
                examinationId,
                employeeId,
                categoryDefinitionId: def.value,
                date: dateToInputFormat(new Date()),
                opinion: '',
              });
            });
          }

          await saveAnalysis(selectedAnalyzes);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (error) {
        showMessage(error.data.message, 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, analysisCategoryDefinitions, employeeId, examinationId]
  );

  useAsyncEffect(async () => {
    if (!data) {
      await defer();

      analysisCategoryDefinitions.forEach(def => {
        setValue(makeSlug(def.text as string), null);
      });

      trigger();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 600 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Tahlil/Tetkik Düzenleme' : 'Tahlil/Tetkik Ekle'}</DialogTitle>
      <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
        <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <DialogContent>
            <Grid container spacing={1}>
              <FormControl required component="fieldset">
                <FormGroup>
                  {analysisCategoryDefinitions.map((item, index) => {
                    const slug = makeSlug(item.text as string);
                    return <Checkbox key={`${slug}-${index}`} name={slug} label={item.text as string} />;
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
