import React from 'react';

import Add from './Add';
import ListUsers from './List';

export default function Users() {
  return (
    <>
      <Add />
      <ListUsers />
    </>
  );
}
