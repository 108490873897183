import React, { useCallback, useContext } from 'react';

import PpeStocksFormDialog from '../../ppe-stocks/ppe-stock/Form';
import { PpeDetailStocksContext } from '../ppe-detail-stocks.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, setSelectedData, reload } = useContext(PpeDetailStocksContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setEditDialogOpened(false);
      setSelectedData(null);
    },
    [reload, setEditDialogOpened, setSelectedData]
  );

  return <PpeStocksFormDialog open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
