import {
  createStyles,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { defer, noop } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { AccidentEmployeeControllerService } from '../../api/client';
import { EmployeeSelectionCacheContext } from '../../Common/Form/EmployeeSelection/employee-selection.context';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { DialogContext } from '../../context/dialog.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { useAsyncEffect, useFetch, useXhr } from '../../utils';
import AccidentEmployeesAddButton from './AccidentEmployeesAddButton';
import AccidentEmployeesForm from './AccidentEmployeesForm';
const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '300px',
    right: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
  table: {
    minWidth: 650,
  },
}));
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  accidentId: string;
}

export default function AccidentEmployeesRecords({ accidentId }: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { openWarnDialog } = useContext(DialogContext);
  const [selectedMdData, setSelectedMData] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const { accidentSeverityTypes } = useContext(SelectOptionsContext);
  const { loadEmployees, names } = useContext(EmployeeSelectionCacheContext);

  const { data, fetch } = useFetch(() => AccidentEmployeeControllerService.getAllAccidentEmployees(accidentId), [], { setLoading });
  const accidentSeverityTypesObj = accidentSeverityTypes.reduce((obj, item) => Object.assign(obj, { [item.value]: item.text }), {});
  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    loadEmployees(...data.map(employeeData => employeeData?.employeeId));
  }, [data]).then(noop);

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await AccidentEmployeeControllerService.deleteAccidentEmployee(id);
      await fetch();
    },
    'Seçili Kayıt Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.workDescription} kayıtı silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await fetch();
      }
      setEditOpen(false);
    },
    [setEditOpen, fetch]
  );

  const onEdit = useCallback((item: any) => {
    setEditOpen(true);
    setSelectedMData(item);
    setEditOpen(true);
  }, []);
  return (
    <>
      <TableContainer component={Paper}>
        <AccidentEmployeesAddButton fetch={fetch} accidentId={accidentId} />
        <IndicatorWrapper loading={loading} data={data} errorMessage={'Listelenecek Kayıt Bulunamadı.'}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"> Personel</TableCell>
                <TableCell align="left">Kaza Sırasında Yaptığı İş</TableCell>
                <TableCell align="left">Ağırlık Derecesi</TableCell>
                <TableCell align="left">Kayıp İş Günü</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(row => (
                <StyledTableRow hover key={row?.id}>
                  <TableCell align="left">{names[row?.employeeId]}</TableCell>
                  <TableCell align="left">{row?.workDescription}</TableCell>
                  <TableCell align="left">{accidentSeverityTypesObj[row?.accidentSeverityDefinitionId]}</TableCell>
                  <TableCell align="left">{row?.lostWorkDays}</TableCell>
                  <TableCell align="right">
                    <div>
                      <IconButton onClick={() => onEdit(row)} edge="end" aria-label="edit">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => removeFileShow(row)} edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </IndicatorWrapper>
        <AccidentEmployeesForm open={editOpen} onClose={closeDialog} data={selectedMdData} fetch={fetch} accidentId={accidentId} />
      </TableContainer>
    </>
  );
}
