import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';

export default function ToolbarIBYS() {
  const { rawData } = useContext(HSTableContext);

  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        IBYS Bildirim Takibi ( {rawData?.totalElements} )
      </Typography>
    </Toolbar>
  );
}
