import { Grid, List, MenuItem, Select, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';

import AlertInformation from 'Common/AlertInformation';
import CircularProgress from 'Common/CircularProgress';

import { DefinitionContextType, DefinitionListContext } from '../../page.context';
import Action from './Action';
import EditDialog from './EditDialog';
import Search from './Search';

// @ts-ignore
function usePagination(data, itemsPerPage, searchKey) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / Number(itemsPerPage));

  function currentData() {
    const begin = (currentPage - 1) * Number(itemsPerPage);
    const end = begin + Number(itemsPerPage);

    // console.log(data.slice(begin, end));
    return searchKey ? data.slice(0, end) : data.slice(begin, end);
  }

  function next() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

type DefaultSizeType = 5 | 10 | 20 | 50;
const DEFAULT_SIZES: DefaultSizeType[] = [5, 10, 20, 50];

export default function Index<T extends { id: string | number }>() {
  const { data, loading, ListItemTemplate, searchName } = useContext<DefinitionContextType<T>>(DefinitionListContext);
  const [page, setPage] = useState(1);
  const [defaultValue] = useState('');
  const [label, setLabel] = useState(defaultValue);
  const [size, setSize] = useState('10');

  const updateSearchKeyword = useCallback(
    (value: string) => {
      setLabel(value);
    },
    [setLabel]
  );
  const handleSizeChange = useCallback(({ target: { value } }) => setSize(value as T), [setSize]);

  const filteredData = searchName ? data?.filter(x => x?.[searchName].toLowerCase().includes(label.toLowerCase())) : data;

  const count = Math.ceil(filteredData?.length / size);
  const _DATA = usePagination(filteredData, size, label);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div style={{ padding: '10px' }}>
      {searchName ? (
        <>
          <Search value={label} onChange={updateSearchKeyword} />
          <List>
            {data !== null && data.length === 0 && !loading ? (
              <AlertInformation severity="error" message="Gösterilecek Kayıt Bulunamadı !" />
            ) : data !== null && !loading ? (
              _DATA.currentData().map((item, index) => <ListItemTemplate key={index} item={item} Action={Action} />)
            ) : (
              <CircularProgress />
            )}
          </List>
        </>
      ) : (
        <List>
          {data !== null && data.length === 0 && !loading ? (
            <AlertInformation severity="error" message="Gösterilecek Kayıt Bulunamadı !" />
          ) : data !== null && !loading ? (
            data.map((item, index) => <ListItemTemplate key={index} item={item} Action={Action} />)
          ) : (
            <CircularProgress />
          )}
        </List>
      )}

      {searchName && (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography color="inherit" variant="body2" className="mr-1">
              Gözüken kayıt sayısı:
            </Typography>
          </Grid>
          <Grid item>
            <Select value={size} onChange={handleSizeChange} classes={{ root: 'pl-1' }}>
              {DEFAULT_SIZES.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Pagination className="ml-2" showFirstButton showLastButton onChange={handleChange} count={count} page={page} color="primary" />
          </Grid>
        </Grid>
      )}
      <EditDialog />
    </div>
  );
}
