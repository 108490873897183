/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HazardCategoryDefinitionDto } from '../models/HazardCategoryDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class HazardCategoryDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static updateHazardCategoryDefinitions(
        id: string,
        requestBody: HazardCategoryDefinitionDto,
    ): CancelablePromise<HazardCategoryDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/hazard-category-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllHazardCategoryDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/hazard-category-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmHazardCategoryDefinitions(
        id: string,
        requestBody: HazardCategoryDefinitionDto,
    ): CancelablePromise<HazardCategoryDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/hazard-category-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmHazardCategoryDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/hazard-category-definitions/${id}`,
        });
    }

    /**
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static getAllHazardCategoryDefinitions(): CancelablePromise<Array<HazardCategoryDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/hazard-category-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static saveHazardCategoryDefinitions1(
        requestBody: HazardCategoryDefinitionDto,
    ): CancelablePromise<HazardCategoryDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/hazard-category-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmHazardCategoryDefinitions(): CancelablePromise<Array<HazardCategoryDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/hazard-category-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns HazardCategoryDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmHazardCategoryDefinitions(
        requestBody: HazardCategoryDefinitionDto,
    ): CancelablePromise<HazardCategoryDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/hazard-category-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}