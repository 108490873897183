import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { RiskAssessmentHazardContext } from '../../risk-assessment-hazard.context';
import RiskAssessmentHazardForm from '../Form';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(RiskAssessmentHazardContext);
  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setNewDialogOpened(false);
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <header className="text-right mt-2 mb-2">
        <Button variant="outlined" startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          Yeni Tehlike Ekle
        </Button>
      </header>
      <RiskAssessmentHazardForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
