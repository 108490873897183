import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';

import Button from 'Common/Button';
import DiagnosisForm from 'Common/Forms/Examination/DiagnosisForm';

import { DiagnosisContextContext } from '../../diagnosis.context';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(DiagnosisContextContext);
  const { examinationId } = useParams<{ examinationId: string }>();
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      {examinationId ? (
        <>
          <Button className="mt-1" startIcon={<AddIcon />} color="primary" variant="contained" onClick={onClick}>
            Tanı Ekle
          </Button>
          <DiagnosisForm onClose={closeDialog} data={null} open={newDialogOpened} />
        </>
      ) : null}
    </>
  );
}
