import { EmployeeSearchGuestControllerService, SearchEmployeeResponse } from '../../api/client';
import { SelectOption } from '../../Common/Form/Select';

export async function employeeOptionFetcher(value: string) {
  const res = await EmployeeSearchGuestControllerService.searchEmployees(value, localStorage.getItem('accidentReviewerToken'));

  return mapEmployeeToOptions(res);
}

export async function employeeLabelFetcher(values: string[]) {
  const res = await EmployeeSearchGuestControllerService.getEmployeeList(values, localStorage.getItem('accidentReviewerToken'));

  return mapEmployeeToOptions(res);
}

export function mapEmployeeToOptions(types: SearchEmployeeResponse[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}
