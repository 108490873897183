import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useFetch } from 'utils';

import { NearMissControllerService, NearMissDto, NearMissView } from '../../../api/client';
import { NearMissDetailContext } from './near-miss-detail.context';
import CommitteeMeeting from './NearMissDetailContainer';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<NearMissDto>(null);

  const { nearMissId } = useParams<{ nearMissId: string }>();
  const { data, fetch } = useFetch(() => NearMissControllerService.getNearMiss(nearMissId), {}, { setLoading });

  return (
    <NearMissDetailContext.Provider
      value={{
        nearMissDetail: data,
        reload: fetch,
        loading,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <CommitteeMeeting />
    </NearMissDetailContext.Provider>
  );
}
