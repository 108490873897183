import { Typography } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

import Toolbar from 'Common/Toolbar';
import { ResourceContext } from 'context/resource.context';

export default function ToolbarInfo() {
  const { ppeDetailId } = useParams<{ ppeDetailId: string }>();
  const { ppeDefinitions } = useContext(ResourceContext);
  return (
    <Toolbar color="secondary">
      <Typography variant="h6">{findByKey(ppeDefinitions, 'id', ppeDetailId)?.label}</Typography>
    </Toolbar>
  );
}
