import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { dateDisplayFormat } from '../../../../../../utils';
import { InjectionContext } from '../../injection.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ListDefaultNurseOperationDetail() {
  const { loading, injections } = useContext(InjectionContext);

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={injections} errorMessage="Gösterilecek Enjeksiyon Kayıtı Bulunamadı">
        <List key="hs-defaultNurseOperationDetail">
          {injections?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-needle" />
                </ListItemAvatar>
                <ListItemText
                  primary={item?.medicineId + ' - ' + item?.doctorName}
                  secondary={dateDisplayFormat(item?.date) + ' - ' + item?.description}
                />
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
