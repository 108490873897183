import React, { useCallback, useMemo, useState } from 'react';

import { useAsyncEffect } from 'utils';

import { CheckListContext, CheckListContextType, CheckListItem, UpsertData } from './checklist.context';
import Content from './Content';
import Dialog from './Dialog';

export interface FetchResult {
  list: CheckListItem[];
  description: string;
}

interface Props {
  fetch(): Promise<FetchResult>;
  editButtonText: string;
  editDialogTitle: string;
  upsert(data: UpsertData): Promise<void>;
}

export default function CheckList({ editButtonText, editDialogTitle, fetch: fetchFunc, upsert: upsertFunc }: Props) {
  const [loading, setLoading] = useState(true);
  const [editOpened, setEditOpened] = useState(false);
  const [{ list, description }, setData] = useState<FetchResult>({ list: [], description: '' });

  const fetch = useCallback(async () => {
    setLoading(true);
    setData(await fetchFunc());
    setLoading(false);
  }, [fetchFunc]);

  const upsert = useCallback(
    async (data: UpsertData) => {
      setLoading(true);
      await upsertFunc(data);
      await fetch();
      setLoading(false);
    },
    [upsertFunc, fetch]
  );

  const context = useMemo<CheckListContextType>(
    () => ({ loading, editOpened, setEditOpened, editButtonText, editDialogTitle, description, list, upsert }),
    [loading, editOpened, editButtonText, editDialogTitle, list, description, upsert]
  );

  useAsyncEffect(async () => {
    fetch();
  }, [fetch]);

  return (
    <CheckListContext.Provider value={context}>
      <Dialog />
      <Content />
    </CheckListContext.Provider>
  );
}
