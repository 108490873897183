import CustomAction from 'CustomAction';
import React, { useCallback } from 'react';

import { getAccidentIdActions } from 'api/hs/accident/accident';
import Dialog from 'Common/Dialog';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';

import { DrillDto } from '../../api/client';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: DrillDto;
}
export default function DrillActionListAdd({ open, onClose, data }: Props) {
  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      return getAccidentIdActions(
        data?.id,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [data?.id]
  );

  return (
    <div key={data?.id}>
      <Dialog color="secondary" width="xl" opened={open} onClose={() => onClose(false)} title="Tatbikat Aksiyonları">
        <CustomAction actionReferenceValue={'DRILL'} referenceId={data?.id} fetcher={fetchActionList} />
      </Dialog>
    </div>
  );
}
