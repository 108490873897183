import { tryCatch, uniquifyByKey } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MedicalHistoryPayload } from './medical-history.payload';
import { MedicalHistoryResult } from './medical-history.result';

export async function getMedicalHistories(employeeId: string, examinationId?: string): Promise<MedicalHistoryResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<MedicalHistoryResult>(`/medical-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<MedicalHistoryResult>(`/medical-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

async function saveComplaintHistories(body: MedicalHistoryPayload): Promise<MedicalHistoryResult> {
  const [err, res] = await tryCatch(HSApi.post('/medical-histories', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

async function updateComplaintHistories(body: MedicalHistoryPayload): Promise<MedicalHistoryResult> {
  const [err, res] = await tryCatch(HSApi.put(`/medical-histories`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function upsertMedicalHistories({ medicalHistories, description, employeeId, examinationId }: MedicalHistoryPayload) {
  const current = await getMedicalHistories(employeeId, examinationId);

  current.medicalHistories = uniquifyByKey(current.medicalHistories.reverse(), 'definitionId');

  // If it is the first define
  if (current.description === null) {
    await saveComplaintHistories({ medicalHistories, description, employeeId, examinationId });
  } else {
    // Otherwise
    const definedComplaints = current.medicalHistories.map(comp => comp.definitionId);

    const saveBody: MedicalHistoryPayload = {
      description,
      employeeId,
      examinationId,
      medicalHistories: medicalHistories
        .filter(h => !definedComplaints.includes(h.definitionId))
        .map(({ definitionId, value }) => ({ definitionId, value })),
    };

    if (saveBody.medicalHistories.length) {
      await saveComplaintHistories(saveBody);
    }

    await updateComplaintHistories({
      description,
      employeeId,
      examinationId,
      medicalHistories: medicalHistories
        .filter(h => !saveBody.medicalHistories.some(hh => hh.definitionId === h.definitionId))
        .map(({ definitionId, value }) => ({
          definitionId,
          value,
        })),
    });
  }
}

export async function deleteMedicalHistories(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/medical-histories/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
