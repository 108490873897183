import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteSystemCheck, getSystemCheck, SystemCheckResult } from 'api/hs/definitions/health-definitions/system-check';
import SystemCheckEditDialog from 'Common/Forms/Definitions/HealthDefinition/SystemCheck/SystemCheckEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Analysis() {
  return (
    <BaseDefinitionPage<SystemCheckResult>
      listLabelPropName="label"
      fetchData={getSystemCheck}
      FormDialog={SystemCheckEditDialog}
      onDelete={deleteSystemCheck}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
