import HSApi from 'api/hs/HSApi';

import { GetProfessionDefinitionsResult } from './profession-definitions.result';

export async function getProfessionDefinitions(nameOrCode: string | string[]): Promise<GetProfessionDefinitionsResult> {
  let res;
  try {
    res = await HSApi.get<GetProfessionDefinitionsResult>(`/ibys/profession-definitions?nameOrCode=${nameOrCode}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}
