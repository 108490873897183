import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { SaveAddictionPayload } from './addictions.payload';
import { GetAddictionsResult } from './addictions.result';

export async function getAddictions(employeeId: string, examinationId?: string): Promise<GetAddictionsResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<GetAddictionsResult>(`/addictions?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<GetAddictionsResult>(`/addictions?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveAddictions(body: SaveAddictionPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/addictions', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
