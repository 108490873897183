import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { EmployeeTrainingPlanningPayload } from './employee-training-planning.payload';
import { EmployeeTrainingPlanningResult } from './employee-training-planning.result';

export async function saveTrainingPlanning(body: EmployeeTrainingPlanningPayload): Promise<EmployeeTrainingPlanningResult> {
  const [err, res] = await tryCatch(HSApi.post('/trainings', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveTrainingInclude(trainingId: string, body: any): Promise<EmployeeTrainingPlanningResult> {
  const [err, res] = await tryCatch(HSApi.post(`/trainings/${trainingId}/training-employees`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
