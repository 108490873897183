import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { AnalysisAggregate } from 'api/hs/examination/analysis';
import AlertInformation from 'Common/AlertInformation';
import TabWithPanel from 'Common/TabWithPanel';
import { EnumContext, EnumType } from 'context/enum.context';

import { AnalysisContext } from '../../analysis.context';
import { Category } from '../category.enum';
import LineChart from '../graphs';
import prepareAudiometryGraphData from './prepareAudiometryGraphData';
import preparePulmonaryGraphData from './preparePulmonaryGraphData';

type PULMONARY_TAB_ENUM_VALUE = 'FEF25-75' | 'FVC' | 'FEV1' | 'FEV1/FVC' | 'PEF';
type AUDIOMETRY_TAB_ENUM_VALUE = 'left' | 'right';

const AUDIOMETRY_TAB_ENUM: AUDIOMETRY_TAB_ENUM_VALUE[] = ['left', 'right'];
const PULMONARY_TAB_ENUM: PULMONARY_TAB_ENUM_VALUE[] = ['FVC', 'FEV1', 'FEV1/FVC', 'PEF', 'FEF25-75'];

interface Props {
  activeCategory: EnumType;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const renderDialogContentByCategory = (data: AnalysisAggregate[], activeCategory: EnumType, analysisCategoryDefinitions: any) => {
  if (data) {
    if (findByKey(analysisCategoryDefinitions, 'id', activeCategory?.id)?.label === Category.Audiometry) {
      return (
        <DialogContent>
          <TabWithPanel
            labels={AUDIOMETRY_TAB_ENUM}
            render={(type: AUDIOMETRY_TAB_ENUM) => (
              <LineChart
                data={prepareAudiometryGraphData(data, type, analysisCategoryDefinitions)}
                axisLabels={{ y: 'İşitme Düzeyi (dB)', x: 'Frekans (Hz)' }}
              />
            )}
          />
        </DialogContent>
      );
    } else if (findByKey(analysisCategoryDefinitions, 'id', activeCategory?.id)?.label === Category.Pulmonary) {
      return (
        <DialogContent>
          <TabWithPanel
            labels={PULMONARY_TAB_ENUM}
            render={(type: PULMONARY_TAB_ENUM) => (
              <LineChart data={preparePulmonaryGraphData(data, type, analysisCategoryDefinitions)} axisLabels={null} />
            )}
          />
        </DialogContent>
      );
    }
  }
};

export default function GraphDialog({ activeCategory }: Props) {
  const { graphDialogOpened, setGraphDialogOpened, selectedGraphData } = useContext(AnalysisContext);
  const classes = useStyles();
  const { analysisCategoryDefinitions } = useContext(EnumContext);

  const onCloseGraphDialog = () => {
    setGraphDialogOpened(false);
  };

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 900 } }}
      open={graphDialogOpened}
      keepMounted
      onClose={onCloseGraphDialog.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{selectedGraphData ? `${activeCategory?.label} Grafiği` : 'Grafik'}</DialogTitle>
      {!selectedGraphData ||
      selectedGraphData?.length === 0 ||
      !selectedGraphData.find(d => d.categoryDefinitionId === activeCategory?.id) ? (
        <AlertInformation severity="error" message="Lütfen tahlil/tetkik seçiniz." />
      ) : (
        renderDialogContentByCategory(selectedGraphData, activeCategory, analysisCategoryDefinitions)
      )}
      <DialogActions>
        <Button color="primary" variant="contained" type="button" onClick={onCloseGraphDialog.bind(null, false)}>
          Kapat
        </Button>
      </DialogActions>
    </Dialog>
  );
}
