import { Breadcrumbs, Icon, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import Toolbar from '../../../../../Common/Toolbar';
import { NearMissDetailContext } from '../../near-miss-detail.context';

export default function Index() {
  const history = useHistory();
  const { nearMissDetail } = useContext(NearMissDetailContext);
  const backRoute = useCallback(() => {
    history.push('/near-miss/list');
  }, [history]);

  return (
    <Toolbar color="secondary">
      <Breadcrumbs style={{ color: 'white' }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link style={{ cursor: 'pointer' }} onClick={backRoute} color="inherit">
          <Icon className="icon-people mr-1" />
          Ramak Kala Listesi
        </Link>
        <Typography style={{ color: 'white' }} color="textPrimary">
          <Icon className="  icon-file-document-box mr-1" />
          {nearMissDetail?.description}
        </Typography>
      </Breadcrumbs>
    </Toolbar>
  );
}
