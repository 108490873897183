import React, { useCallback, useContext } from 'react';

import { CaseTeamsDetailContext } from '../case-teams-detail.context';
import NewCaseTrainingsForm from './Form/NewCaseTrainingsForm';

export default function NewCaseTrainingDialog() {
  const {
    newCaseTrainingsNewDialog,
    setNewCaseTrainingsNewDialog,
    emergencyCaseTeamTrainingReload,
    emergencyCaseTeamTraining,
  } = useContext(CaseTeamsDetailContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await emergencyCaseTeamTrainingReload();
      }
      setNewCaseTrainingsNewDialog(false);
    },
    [setNewCaseTrainingsNewDialog, emergencyCaseTeamTrainingReload]
  );

  return <NewCaseTrainingsForm data={emergencyCaseTeamTraining} open={newCaseTrainingsNewDialog} onClose={closeDialog} />;
}
