import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { HsExamOpinionResult } from './get-hsexam-opinion-definitions.result';

export async function getHsExamOpinionDefinitions(): Promise<HsExamOpinionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<HsExamOpinionResult[]>(`/firms/hsexam-opinion-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
