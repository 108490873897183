import React from 'react';

import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { PhysicianProtocolRegistryViewDto } from '../../api/client';
import { dateToInputFormat } from '../../utils';
import Avatar from '../EmployeeList/Avatar';

export const CELL: TableColumn<PhysicianProtocolRegistryViewDto>[] = [
  {
    id: 'avatarPath',
    render(row: PhysicianProtocolRegistryViewDto): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: false,
    width: '5%',
  },
  {
    id: 'protocolNo',
    label: 'Protokol Num',
    defaultVisibility: true,
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Adı Soyadı',
    sortable: true,
    width: '10%',
  },
  {
    id: 'createdDate',
    render(row: PhysicianProtocolRegistryViewDto): JSX.Element {
      return <span>{dateToInputFormat(new Date(row.createdDate)).split('-')?.reverse()?.join('-')}</span>;
    },
    label: 'Tarih',
    sortable: true,
    width: '10%',
  },
  {
    id: 'birthDate',
    defaultVisibility: true,
    label: 'Doğum Tarihi',
    sortable: true,
    width: '5%',
    render(row: PhysicianProtocolRegistryViewDto): JSX.Element | string {
      return <span>{row?.birthDate?.split('-')?.reverse()?.join('-')}</span>;
    },
  },
  {
    id: 'genderName',
    defaultVisibility: true,
    label: 'Cinsiyet',
    sortable: true,
    width: '5%',
  },
  {
    id: 'complaintHistory',
    defaultVisibility: true,
    label: 'Yakınma',
    sortable: true,
    width: '5%',
  },
  {
    id: 'analyses',
    defaultVisibility: true,
    label: 'Tahlil Tetkik',
    sortable: true,
    width: '10%',
  },
  {
    id: 'diagnoses',
    defaultVisibility: true,
    label: 'Tanı',
    sortable: true,
    width: '10%',
  },
  {
    id: 'prescriptionMedicineLabel',
    defaultVisibility: true,
    label: 'Reçete',
    sortable: true,
    width: '10%',
  },
  {
    id: 'medicines',
    defaultVisibility: true,
    label: 'İlaçlar',
    sortable: true,
    width: '10%',
  },
  {
    id: 'opinion',
    defaultVisibility: true,
    label: 'Karar/Kanaat',
    sortable: true,
    width: '10%',
  },
  {
    id: 'description',
    defaultVisibility: true,
    label: 'Açıklama',
    sortable: true,
    width: '30%',
  },
];
