import { Chip, Icon } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { defer } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { TrainingEmployeeEmployee } from 'api/hs/trainings';
import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';
import { EmployeeSelectionCacheContext } from 'Common/Form/EmployeeSelection/employee-selection.context';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Props {
  trainingDetail: TrainingsViewContentResult;
}

export default function ParticipantInfo({ trainingDetail }: Props) {
  const { loadEmployees, avatars, names } = useContext(EmployeeSelectionCacheContext);
  const [openParticipantInfo, setOpenParticipantInfo] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpenParticipantInfo(!openParticipantInfo);
  };

  useAsyncEffect(async () => {
    if (!trainingDetail?.trainingEmployees) {
      return;
    }
    await defer();

    loadEmployees(
      ...trainingDetail.trainingEmployees
        .filter(employee => (employee as TrainingEmployeeEmployee).employeeId)
        .map(employee => (employee as TrainingEmployeeEmployee).employeeId)
    );
  }, [trainingDetail?.trainingEmployees, openParticipantInfo]);

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon-account-multiple" />
        </ListItemIcon>
        <ListItemText primary="Eğitimin Katılımcı Bilgileri" />
        {openParticipantInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse style={{ paddingLeft: '15px', paddingTop: '10px' }} in={openParticipantInfo} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold">Adı Soyadı</TableCell>
                <TableCell className="text-bold" align="center">
                  Katılım Durumu
                </TableCell>
                <TableCell className="text-bold" align="center">
                  Sınav Sonucu
                </TableCell>
                <TableCell className="text-bold" align="center">
                  Durumu
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingDetail?.trainingEmployees?.map(row => (
                <TableRow hover key={row.name}>
                  <TableCell component="th" scope="row">
                    <Chip avatar={<Avatar url={avatars[row?.employeeId]} />} label={names[row?.employeeId]} />
                  </TableCell>
                  <TableCell align="center">{row?.isParticipated ? 'Katıldı' : 'Katılmadı'}</TableCell>
                  <TableCell align="center">{row?.grade}</TableCell>
                  <TableCell align="center">{row?.guestId ? 'Misafir' : 'Çalışan'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}
