import { Icon } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { TrainingPlanningListContext } from '../training-planning-list.context';
import NewTrainingPlanningForm from './NewTrainingPlanningForm';
import TrainingIncludeForm from './TrainingIncludeForm';
export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload, newDialogOpenedTrainingInclude, setNewDialogOpenedTrainingInclude } = useContext(
    TrainingPlanningListContext
  );
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = useCallback(() => {
    setNewDialogOpened(true);
  }, [setNewDialogOpened]);

  const onClickTrainingInclude = useCallback(() => {
    setNewDialogOpenedTrainingInclude(true);
  }, [setNewDialogOpenedTrainingInclude]);

  const closeDialogTrainingInclude = useCallback(
    success => {
      setNewDialogOpenedTrainingInclude(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpenedTrainingInclude]
  );

  return (
    <>
      <Button
        onClick={onClick}
        startIcon={<Icon className="icon-plus" />}
        style={{ marginRight: '5px' }}
        color="primary"
        variant="outlined">
        Yeni Eğitim Planla
      </Button>
      <Button
        onClick={onClickTrainingInclude}
        startIcon={<Icon className="icon-backburger" />}
        style={{ marginRight: '5px' }}
        color="primary"
        variant="outlined">
        Eğitime Dahil Et
      </Button>
      <NewTrainingPlanningForm onClose={closeDialog} open={newDialogOpened} />
      <TrainingIncludeForm onClose={closeDialogTrainingInclude} open={newDialogOpenedTrainingInclude} />
    </>
  );
}
