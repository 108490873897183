import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FirmUsersModel, saveFirmUsers, updateFirmUsers } from 'api/hs/definitions/firm-definitions/firm-users';
import AutoComplete from 'Common/Form/AutoComplete';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: FirmUsersModel;
}

export default function FirmUsersForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, getValues, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { firms, userRoles } = useContext(SelectOptionsContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        const firmId = getValues('firmId');
        if (!data?.id) {
          await saveFirmUsers(firmId, values);
          showMessage('Ekleme işlemi başarılı.', 'success');
        } else {
          await updateFirmUsers(firmId, { id: data.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error');
        onClose(false);
      }
    },
    [data, onClose, showMessage, getValues]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      trigger();
    } else {
      reset();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 550 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Firma User Düzenleme' : 'Firma User Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <Grid item md={12} sm={12} lg={12}>
                  <Select name="firmId" options={firms} label="Firma" required />
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required type="text" name="name" label="Adı Soyadı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required type="email" name="email" label="Email" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <AutoComplete
                  name="roles"
                  renderOption="checkbox"
                  options={userRoles?.filter(role => role.value !== 'SYS_ADMIN')}
                  label="User Role"
                  required
                />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
