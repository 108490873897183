import React, { useCallback, useContext } from 'react';

import ComplaintsForm from 'Common/Forms/Examination/ComplaintsForm';

import { ComplaintsContextContext } from '../../complaints.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(ComplaintsContextContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <ComplaintsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
