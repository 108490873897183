// tslint:disable:max-classes-per-file
import { modelDataMatcher } from 'utils';

class MaternityLeave {
  public startDate: string = null;
  public endDate: string = null;
}

export class PregnanciesModel {
  public id: string = null;
  public description: string = null;
  public lastMenstruationDate: string = null;
  public multipleBirth: boolean = null;
  public birthDate: string = null;
  public pregnancyStateDefinitionId: number = null;
  public husbandBloodTypeDefinitionId: number = null;
  public examinationId: string = null;
  public employeeId: string = null;
  public bloodTypeIncompatibility: boolean = null;
  public status: string = null;
  public maternityLeave: MaternityLeave = null;

  constructor(data: Partial<PregnanciesModel> = {}) {
    modelDataMatcher(data, this);
  }
}
