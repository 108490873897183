import { Box, Checkbox, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import React from 'react';

import { TodoItemControllerService, TodoItemDto } from '../../api/client';

const stylesByCompletion = {
  true: {
    textDecorationLine: 'line-through',
    color: 'grey',
    flex: 1,
  },
  false: {
    flex: 1,
  },
};

interface Props {
  fetch(): void;
  items: TodoItemDto[];
}

export default ({ fetch, items }: Props) => {
  const handleUpdate = (item: TodoItemDto, checked: boolean) => {
    item.isCompleted = checked;
    TodoItemControllerService.updateTodo(item.id, item).then(fetch);
  };

  const handleDelete = (id: string) => {
    TodoItemControllerService.deleteAllTodo(id).then(fetch);
  };

  return (
    <Box sx={{ height: '270px' }} style={{ overflowY: 'auto' }}>
      <List style={{ width: '100%' }}>
        {items.map((item: TodoItemDto) => (
          <ListItem className={{ maxWidth: '100px' }} alignItems="flex-start" key={item.id}>
            <Checkbox checked={item.isCompleted} onChange={e => handleUpdate(item, e.target.checked)} />
            <ListItemText
              primary={
                <Tooltip id={item?.id} title={item.todoContent} aria-label={item?.id}>
                  <Typography className="mt-1" noWrap variant="body2" gutterBottom>
                    {item.todoContent}
                  </Typography>
                </Tooltip>
              }
              style={stylesByCompletion[item.isCompleted]}
            />
            <IconButton onClick={() => handleDelete(item.id)}>
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
