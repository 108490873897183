import React from 'react';

import NearMissRecordsList from './List';
export default function Index() {
  return (
    <>
      <NearMissRecordsList />
    </>
  );
}
