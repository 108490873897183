import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { PeriodicExaminationWidgetItem } from '../../../../api/hs/dashboard/widget/widget.payload';
import { datePrettier } from '../../../../utils';

interface Props {
  users: PeriodicExaminationWidgetItem[];
}

export default ({ users }: Props) => {
  const history = useHistory();

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}/examination`);
  };

  if (!users || users.length === 0) return <b>Kayıt bulunamadı!</b>;

  return (
    <TableContainer component={Paper} className={'mt-3'}>
      <Table stickyHeader size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Ad Soyad</TableCell>
            <TableCell className="text-bold">Tarih</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: PeriodicExaminationWidgetItem) => (
            <TableRow key={user?.employeeId} hover onClick={() => handleClick(user.employeeId)}>
              <TableCell scope="row">{user.name}</TableCell>
              <TableCell>{datePrettier(user.date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
