import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useContext, useRef } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { WorkingHoursControllerService } from '../../api/client';
import Dialog from '../../Common/Dialog';
import AutoComplete from '../../Common/Form/AutoComplete';
import DateInput from '../../Common/Form/DateInput';
import TextField from '../../Common/Form/TextField';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { useAsyncEffect } from '../../utils';

interface Props {
  open: boolean;
  onClose(success: boolean): void;
  data: any;
}
export default function WorkingHoursForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { companies } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);

  const onSubmit = async (values: any) => {
    try {
      if (!data) {
        await WorkingHoursControllerService.saveUpdateWorkingHours(values);
        showMessage('Ekleme işlemi Başarılı', 'success', 'info');
        onClose(true);
      } else {
        await WorkingHoursControllerService.partialUpdateWorkingHours(data?.id, values);
        showMessage('Güncelleme işlemi Başarılı', 'success', 'info');
        onClose(true);
      }
    } catch (e) {
      showMessage('İşlem sırasında hata oluştu.', 'success', 'info');
      onClose(false);
    }
  };

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      for (const [key, value] of Object.entries(data)) {
        formRef?.current?.methods?.setValue(key, value);
      }

      await formRef?.current?.methods?.trigger();
    }
  }, [data, open]);

  return (
    <Dialog
      color="secondary"
      width="sm"
      opened={open}
      onClose={() => onClose(false)}
      title={data ? 'Çalışma Planı Düzenleme' : 'Çalışma Planı Ekle'}>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} lg={12} xl={12}>
            <AutoComplete required single name="companyId" options={companies} label="Şirketler" />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xl={6}>
            <DateInput required name="date" type="date" label="Tarih" />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xl={6}>
            <TextField required type="number" name="workingHours" label="Çalışma Saati" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.label ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
