import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { PpeStocksContext } from '../ppe-stocks.context';
import PpeStocksFormDialog from './Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(PpeStocksContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialogForm = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [refresh, setEditDialogOpened]
  );

  return <PpeStocksFormDialog open={editDialogOpened} onClose={closeDialogForm} data={selectedData} />;
}
