import { Avatar } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { PpeOutputsContentResult } from 'api/hs/ppe/ppe-outputs';
import { TableColumn } from 'Common/Table/Raw/table-column.model';
import { useDateFormat } from 'utils';

import { SelectOptionsContext } from '../../../../../context/select-options.context';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

function PpeReasonName({ row }: { row: PpeOutputsContentResult }) {
  const { reasons } = useContext(SelectOptionsContext);
  return (
    <div key={row?.id} id={row?.id}>
      {findByKey(reasons, 'value', row?.reason)?.text}
    </div>
  );
}

export const CELL: TableColumn<PpeOutputsContentResult>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    defaultVisibility: true,
    render(row: PpeOutputsContentResult): JSX.Element {
      return <Avatar src={`/saglik-gozetimi/public/images/ppe/${row?.imagePath}`} />;
    },
  },
  {
    id: 'stockNumber',
    defaultVisibility: true,
    label: 'Stok Numarası',
    sortable: false,
    width: '10%',
  },
  {
    id: 'classLabel',
    defaultVisibility: true,
    label: 'KKD Ana Grup',
    sortable: false,
    width: '15%',
  },
  {
    id: 'type',
    defaultVisibility: true,
    label: 'KKD Alt Grup',
    sortable: false,
    width: '10%',
  },
  {
    id: 'brand',
    defaultVisibility: true,
    label: 'Markası',
    sortable: false,
    width: '10%',
  },
  {
    id: 'model',
    defaultVisibility: true,
    label: 'Modeli',
    sortable: false,
    width: '10%',
  },
  {
    id: 'count',
    defaultVisibility: true,
    label: 'Adet',
    sortable: false,
    width: '5%',
  },
  {
    id: 'refundDate',
    defaultVisibility: true,
    label: 'İade Tarihi',
    sortable: false,
    width: '10%',
    render(row: PpeOutputsContentResult): JSX.Element {
      return <DateField date={new Date(row.refundDate)} />;
    },
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Teslim Alan',
    sortable: false,
    width: '15%',
  },
  {
    id: 'reason',
    defaultVisibility: true,
    label: 'İade Sebeb',
    sortable: false,
    width: '10%',
    render(row: PpeOutputsContentResult): JSX.Element {
      return <PpeReasonName row={row} />;
    },
  },
];
