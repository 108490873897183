/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeListRequest } from '../models/EmployeeListRequest';
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { PageEmployeeDto } from '../models/PageEmployeeDto';
import type { PageEmployeeEntity } from '../models/PageEmployeeEntity';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { SpecificationEmployeeEntity } from '../models/SpecificationEmployeeEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmployeeControllerService {

    /**
     * @param id
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployee1(
        id: string,
    ): CancelablePromise<EmployeeDto> {
        return __request({
            method: 'GET',
            path: `/employees/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static updateEmployee(
        id: string,
        requestBody: EmployeeDto,
    ): CancelablePromise<EmployeeDto> {
        return __request({
            method: 'PUT',
            path: `/employees/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllEmployee(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/employees/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns PageEmployeeDto OK
     * @throws ApiError
     */
    public static searchEmployee(
        requestBody: EmployeeListRequest,
    ): CancelablePromise<PageEmployeeDto> {
        return __request({
            method: 'POST',
            path: `/search-employees`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param psRequestParam
     * @param managerId
     * @param nids
     * @returns PageEmployeeDto OK
     * @throws ApiError
     */
    public static searchEmployee1(
        psRequestParam: PagingAndSortingRequestParam,
        managerId?: string,
        nids?: Array<string>,
    ): CancelablePromise<PageEmployeeDto> {
        return __request({
            method: 'GET',
            path: `/employees`,
            query: {
                'managerId': managerId,
                'nids': nids,
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param requestBody
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static saveEmployee(
        requestBody: EmployeeDto,
    ): CancelablePromise<EmployeeDto> {
        return __request({
            method: 'POST',
            path: `/employees`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getAnalysisFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/employees/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static employeeProfileFileImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/employees/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static employeeAvatarImport(
        id: string,
        requestBody?: {
            avatar: Blob;
        },
    ): CancelablePromise<EmployeeDto> {
        return __request({
            method: 'POST',
            path: `/employees/${id}/avatar`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageEmployeeDto OK
     * @throws ApiError
     */
    public static employeeView(
        requestBody?: PagingAndSortingRequestParam,
    ): CancelablePromise<PageEmployeeDto> {
        return __request({
            method: 'POST',
            path: `/employees/view`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param exposeType
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static employeeFilterViewImport1(
        exposeType: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
        requestBody?: {
            file: Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/employees/view/import`,
            query: {
                'exposeType': exposeType,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployeeList1(
        ids?: Array<string>,
    ): CancelablePromise<Array<EmployeeDto>> {
        return __request({
            method: 'GET',
            path: `/employees/pages`,
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @param requestBody
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployeeListWithRequest(
        requestBody: EmployeeListRequest,
    ): CancelablePromise<Array<EmployeeDto>> {
        return __request({
            method: 'POST',
            path: `/employees/pages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeSpec
     * @param psRequestParam
     * @returns PageEmployeeEntity OK
     * @throws ApiError
     */
    public static searchRawEmployeesPaging(
        employeeSpec: SpecificationEmployeeEntity,
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageEmployeeEntity> {
        return __request({
            method: 'GET',
            path: `/search-employees/raw`,
            query: {
                'employeeSpec': employeeSpec,
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static employeeFilterViewImportTemplate(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/employees/view/import/template`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getRestReportFile1(
        id: string,
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'GET',
            path: `/employees/imported-files/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static employeeFileDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/employees/imported-files/${id}`,
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static uploadRestReportFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/employees/imported-files/${id}/raw`,
        });
    }

}