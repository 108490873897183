import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { InjectionContext } from '../../injection.context';
import InjectionForm from '../Form';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(InjectionContext);

  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <header className="text-right mr-1">
        <Button startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          Enjeksiyon Ekle
        </Button>
      </header>
      <InjectionForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
