import { debounceWithKey } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { useFetch } from 'utils';

import { TableContextType } from '../Raw/table.context';
import { HSTableContext, HSTableContextType } from './hs-table.context';
import { HSTableProps } from './hs-table.props';

export interface HSTableContextProviderProps<T> {
  fetcher: HSTableProps<T>['fetcher'];
  defaultExtraArgs?: HSTableProps<T>['defaultExtraArgs'];
  sorting: TableContextType<T>['sort'];
}

export default function HSTableContextProvider<T>({
  fetcher,
  sorting,
  defaultExtraArgs = {},
  children,
}: PropsWithChildren<HSTableContextProviderProps<T>>) {
  const [debounceKey] = useState(Symbol());
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [extraArgs, setExtraArgs] = useState(defaultExtraArgs);
  const { data, fetch } = useFetch(() => debounceWithKey(debounceKey, fetcher, 50, null, page, size, sorting, extraArgs), null, {
    deps: [fetcher, page, size, sorting, extraArgs],
    reloadDeps: [page, size, sorting, extraArgs],
    setLoading,
  });

  const onSizeChange = useCallback((_size: number) => {
    setPage(0);
    setSize(_size);
  }, []);

  const context = useMemo<HSTableContextType<T>>(
    () => ({
      refresh: fetch,
      loading,
      setPage,
      setSize: onSizeChange,
      page,
      size,
      data: data?.content || [],
      totalPage: data?.totalPages || 0,
      rawData: data,
      extraArgs,
      setExtraArgs,
    }),
    [fetch, page, size, data, onSizeChange, loading, extraArgs]
  );

  return <HSTableContext.Provider value={context}>{children}</HSTableContext.Provider>;
}
