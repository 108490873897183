import { makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import { TableColumn } from './table-column.model';
import { TableContext, TableContextType } from './table.context';

const useStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    maxWidth: 200,
  },
}));

/**
 * Default Table Head Component
 */
export default function DefaultHead<T>() {
  const classes = useStyles();
  const { sort, setSort, visibleColumns } = useContext<TableContextType<T>>(TableContext);

  const handleSort = useCallback(
    (col: TableColumn<T>) => () => {
      if (!sort) {
        setSort({ direction: 'asc', id: col.id });
      } else if (sort.id !== col.id) {
        setSort({ direction: 'asc', id: col.id });
      } else if (sort.direction === 'asc') {
        setSort({ direction: 'desc', id: col.id });
      } else {
        setSort(false);
      }
    },
    [sort, setSort]
  );

  return (
    <TableHead>
      <TableRow>
        {visibleColumns.map((col, index) => (
          <TableCell
            key={typeof col.id === 'symbol' ? index : (col.id as string)}
            className={`${classes.cell} p-1 text-bold text-ellipsis`}
            width={col.width}>
            {col.sortable ? (
              <TableSortLabel active={sort && col.id === sort.id} direction={sort ? sort.direction : 'asc'} onClick={handleSort(col)}>
                {col.label}
              </TableSortLabel>
            ) : (
              col.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
