import { createContext } from 'react';

import { BaseEmployee } from 'model';

import { PlanningTrainingDefinitionModel } from '../../api/hs/trainings';
import { PlanningParticipantForm } from './planning-employees.model';
import { PlanningInfo } from './planning-info.model';
import { PlanningStep } from './planning-steps.enum';

export interface GuestTrainer {
  id: symbol;
  name: string;
  lastName: string;
  nid: string;
  title: string;
  isEmployee: boolean;
  isInternal: boolean;
  trainerTypeDefinition: string;
  employeeId: string;
}

export interface GuestParticipant {
  id: symbol;
  name: string;
  surname: string;
}

export interface PlanningContextType {
  activeStep: PlanningStep;
  setActiveStep(step: PlanningStep): void;
  selectedTrainings: PlanningTrainingDefinitionModel['trainingDefinitionId'][];
  setSelectedTrainings(trainings: PlanningTrainingDefinitionModel['id'][]): void;
  info: PlanningInfo;
  setInfo(info: PlanningInfo): void;
  participantForm: PlanningParticipantForm;
  setParticipantForm(form: PlanningParticipantForm): void;
  durations: Record<string, number>;
  setDurations(durations: Record<string, number>): void;
  remoteDurations: Map<string, Map<string, number>>; //in order employee Id, trainingDefinitionId, duration
  setRemoteDurations(employeeDurationMap: Map<string, Map<string, number>>):void;
  guestParticipants: GuestParticipant[];
  addGuestParticipants(participant: GuestParticipant): void;
  participants: (BaseEmployee | GuestParticipant)[];
  setParticipants(participants: (BaseEmployee | GuestParticipant)[]): void;
  quiz: File[];
  setQuiz(quiz: File[]): void;
  preQuiz: File[];
  setPreQuiz(preQuiz: File[]): void;
  otherFiles: File[];
  setOtherFiles(otherFiles: File[]): void;
  importFromExcelVisible:boolean;
  setImportFromExcelVisible(value:boolean):void;
  remoteTrainingEmployeeFile: File;
  setRemoteTrainingEmployeeFile(file:File):void;
}

export const PlanningContext = createContext<PlanningContextType>(null);
