import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { HealthInsurancesPayload } from './health-insurances.payload';
import { HealthInsurancesResult } from './health-insurances.result';

export async function getHealthInsurances(employeeId: string): Promise<HealthInsurancesResult[]> {
  let res;
  try {
    res = await HSApi.get<HealthInsurancesResult[]>(`/health-insurances?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveHealthInsurances(body: HealthInsurancesPayload): Promise<HealthInsurancesResult> {
  const [err, res] = await tryCatch(HSApi.post(`/health-insurances`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateHealthInsurances({
  id,
  ...body
}: MakeRequired<Partial<HealthInsurancesResult>, 'id'>): Promise<HealthInsurancesResult> {
  const [error, res] = await tryCatch(HSApi.put<HealthInsurancesResult>(`/health-insurances/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteHealthInsurances(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`health-insurances/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
