import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { CommitteeMeetingsContentResult } from '../committee-meetings';
import { CommitteeMeetingsDetailResult } from './committee-meetings-detail.result';

export async function getCommitteeMeetingId(committeeMeetingId: string): Promise<CommitteeMeetingsDetailResult> {
  const [error, res] = await tryCatch(
    Promise.all([
      HSApi.get(`/committee-meetings/views/${committeeMeetingId}`),
      getCommitteeMeetingActions(committeeMeetingId),
      getCommitteeMeetingFiles(committeeMeetingId),
      getCommitteeMeetingResult(committeeMeetingId),
    ])
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    throw error;
  }

  return {
    ...res[0].data,
    committeeMeetingsActionLength: res[1]?.totalElements ?? [],
    committeeMeetingsFileLength: res[2]?.length ?? [],
    committeeMeetingsResultLength: res[3]?.length ?? [],
  };
}
export async function getCommitteeMeetingResult(committeeMeetingId: string): Promise<any> {
  const [error, res] = await tryCatch(HSApi.get<any>(`/committee-meetings/${committeeMeetingId}/results`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteCommitteeMeetingResult(id: string): Promise<any> {
  const [error, res] = await tryCatch(HSApi.delete<any>(`/committee-meetings/results/${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveCommitteeMeetingResult(id: string, body: any): Promise<any> {
  const [err, res] = await tryCatch(HSApi.post(`/committee-meetings/${id}/results`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateCommitteeMeetingResult(id: string, payload: any): Promise<any> {
  const [error, res] = await tryCatch(HSApi.put<CommitteeMeetingsContentResult>(`/committee-meetings/results/${id}`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getCommitteeMeetingFiles(committeeMeetingId: string): Promise<any> {
  const [error, res] = await tryCatch(HSApi.get<any>(`/committee-meetings/${committeeMeetingId}/imported-files`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteCommitteeMeetingFiles(fileId: string): Promise<any> {
  const [error, res] = await tryCatch(HSApi.delete<any>(`/committee-meetings/imported-files/${fileId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function fileSaveCommitteeMeeting(id: CommitteeMeetingsDetailResult['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`/committee-meetings/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function getCommitteeMeetingActions(definitionId: string, page = 0, size = 20, order: null | string): Promise<any> {
  const payload = {
    actionFilter: {
      referenceId: definitionId,
    },
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<any>(`/actions/views`, payload);

  return res.data;
}
