import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deletePhysical, getPhysical, PhysicalResult } from 'api/hs/definitions/health-definitions/physical-check';
import PhysicalCheckEditDialog from 'Common/Forms/Definitions/HealthDefinition/Physical-Check/PhysicalCheckEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function PhysicalCheck() {
  return (
    <BaseDefinitionPage<PhysicalResult>
      listLabelPropName="label"
      fetchData={getPhysical}
      FormDialog={PhysicalCheckEditDialog}
      onDelete={deletePhysical}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
