import { TextFieldProps } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import { defer } from '@thalesrc/js-utils';
import React, { FC, FocusEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { switchCase, useDateFormat, useTranslate } from 'utils';

import { FormContext } from './Form/form.context';

type Types = 'date' | 'time' | 'date-time';

type PickerProps = Pick<
  KeyboardDatePickerProps & KeyboardTimePickerProps & KeyboardDateTimePickerProps,
  | 'variant'
  | 'value'
  | 'onChange'
  | 'onAccept'
  | 'ampm'
  | 'format'
  | 'label'
  | 'disabled'
  | 'inputVariant'
  | 'autoOk'
  | 'mask'
  | 'onBlur'
  | 'inputValue'
  | 'invalidDateMessage'
  | 'InputProps'
  | 'onFocus'
  | 'className'
  | 'PopoverProps'
  | 'required'
  | 'error'
>;

type OnChangeFunc = (date: string) => void;

interface Props {
  name: string;
  type?: Types;
  label?: string;
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
  required?: boolean;
  defaultValue?: string;
}

const TYPES_ARRAY: Types[] = ['date', 'time', 'date-time'];
const POPOVER_PROPS: PickerProps['PopoverProps'] = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  transformOrigin: { horizontal: 'center', vertical: 'top' },
};

export default function DateInput({
  name,
  type = 'date',
  disabled = false,
  label = '',
  variant = 'standard',
  required = false,
  defaultValue = '',
}: Props) {
  const { trigger, control, getValues, errors } = useFormContext();
  const { readonly } = useContext(FormContext);
  const format = useDateFormat();
  const [valid, setValid] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const translate = useTranslate('form');
  const [blurred, setBlurred] = useState<boolean>(true);

  const currentValue = getValues(name);
  const hasError = !!errors[name];
  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);
  const translatedLabel = useMemo(() => translate(label), [translate, label]);

  const Component = useMemo(
    () => switchCase<Types, FC<PickerProps>>(TYPES_ARRAY, [KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker], type),
    [type]
  );

  const outerDateParser = useCallback((date: Date) => (date ? format(date, 'yyyy-MM-dd') : null), [format]);
  const outerTimeParser = useCallback((date: Date) => (date ? format(date, 'HH:mm') : null), [format]);
  const outerDateTimeParser = useCallback((date: Date) => (date ? date.toISOString() : null), []);

  const dateToOuterValueParser = useMemo(() => switchCase(TYPES_ARRAY, [outerDateParser, outerTimeParser, outerDateTimeParser], type), [
    type,
    outerDateParser,
    outerTimeParser,
    outerDateTimeParser,
  ]);

  const handleOnAccept = useCallback(
    (onChange: OnChangeFunc) => {
      return (date: Date) => {
        onChange(dateToOuterValueParser(date));
        trigger();
        setValid(true);
      };
    },
    [trigger, dateToOuterValueParser]
  );

  const handleOnChange = useCallback(
    (onChange: OnChangeFunc) => (date: Date, dateStr: string) => {
      setInputValue(dateStr ?? '');

      if (!date || isNaN(date.getTime())) {
        setValid(false);
        return;
      }

      onChange(dateToOuterValueParser(date));
      trigger();
      setValid(true);
    },
    [dateToOuterValueParser, trigger]
  );

  const handleBlur = useCallback(
    (onChange: OnChangeFunc) => async (e: FocusEvent<HTMLInputElement>) => {
      setBlurred(true);
      if (valid) return;

      onChange(null);
      trigger();

      await defer();
      setInputValue('');
    },
    [valid, trigger]
  );

  const handleFocus = useCallback(() => setBlurred(false), []);

  const stringToDateParser = useCallback(
    (value: string) =>
      value ? switchCase<Types, Date>(TYPES_ARRAY, [new Date(value), new Date('0000-01-01T' + value), new Date(value)], type) : null,
    [type]
  );

  const inputFormat = useMemo(() => switchCase<Types, string>(TYPES_ARRAY, ['dd/MM/yyyy', 'HH:mm', 'dd/MM/yyyy HH:mm'], type), [type]);
  const mask = useMemo(() => switchCase<Types, string>(TYPES_ARRAY, ['__/__/____', '__:__', '__/__/____ __:__'], type), [type]);

  const invalidDateMessage = useMemo(
    () => translate(`Errors.${switchCase(TYPES_ARRAY, ['invalidDate', 'invalidTime', 'invalidDateTime'], type)}`),
    [translate, type]
  );

  const inputProps = useMemo<PickerProps['InputProps']>(
    () => ({ className: 'width-100', ...(blurred && !valid && { value: inputValue }) }),
    [blurred, inputValue, valid]
  );

  useEffect(() => {
    if (!blurred || !currentValue) {
      return;
    }
    const date = stringToDateParser(currentValue);
    setInputValue(format(date, inputFormat));
    setValid(true);
  }, [blurred, currentValue, stringToDateParser, format, inputFormat, setValid]);

  const renderComponent = useCallback<ControllerProps<'input'>['render']>(
    ({ onChange, value }) => (
      <Component
        className="width-100"
        variant="inline"
        value={stringToDateParser(value)}
        onAccept={handleOnAccept(onChange)}
        ampm={false}
        format={inputFormat}
        label={translatedLabel}
        disabled={notEditable}
        inputVariant={variant}
        autoOk
        mask={mask}
        error={hasError}
        onChange={handleOnChange(onChange)}
        onBlur={handleBlur(onChange)}
        onFocus={handleFocus}
        inputValue={inputValue}
        invalidDateMessage={invalidDateMessage}
        InputProps={inputProps}
        PopoverProps={POPOVER_PROPS}
        required={required}
      />
    ),
    [
      handleOnAccept,
      stringToDateParser,
      inputFormat,
      translatedLabel,
      notEditable,
      variant,
      mask,
      handleOnChange,
      handleBlur,
      inputValue,
      invalidDateMessage,
      inputProps,
      handleFocus,
      required,
      hasError,
      Component,
    ]
  );

  return <Controller name={name} control={control} defaultValue={defaultValue} render={renderComponent} rules={{ required }} />;
}
