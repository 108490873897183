import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import { EmergencyCaseTeamMemberControllerService } from '../../client';
import HSApi from '../HSApi';

export async function getEmergencyManagement() {
  const [err, res] = await tryCatch(HSApi.get(`/emergency-case-teams`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return await asyncMap(res.data, async item => ({
    ...item,
    employees:
      (await EmergencyCaseTeamMemberControllerService.getAllEmergencyCaseTeam2(item?.id))[item?.id]?.reduce(
        (acc, employee) => [...acc, employee?.employeeId],
        []
      ) ?? [],
  }));
}

export async function saveDrillFileImported(id: string, file: File) {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/drills/${id}/imported-files`, data));
  if (err) {
    throw err.response.data.message;
  }

  return res.data;
}

export async function saveEmergencyCasePlansFileImported(id: string, file: File) {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/emergency-case-plans/${id}/imported-files`, data));
  if (err) {
    throw err.response.data.message;
  }

  return res.data;
}
