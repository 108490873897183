/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PpeStockViewDto } from './PpeStockViewDto';

export type PpeDefinitionViewDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    ppeDefinitionId?: string;
    description?: string;
    label?: string;
    ppeClassificationId?: string;
    classificationName?: string;
    ppeTypeId?: string;
    ppeTypeName?: string;
    firmId?: string;
    criticalInventoryCount?: number;
    remainStockCount?: number;
    model?: string;
    imagePath?: string;
    stockStatus?: PpeDefinitionViewDto.stockStatus;
    ppeStocks?: Array<PpeStockViewDto>;
}

export namespace PpeDefinitionViewDto {

    export enum stockStatus {
        NEAR_OUT_OF_STOCK = 'NEAR_OUT_OF_STOCK',
        OUT_OF_STOCK = 'OUT_OF_STOCK',
        IN_STOCK = 'IN_STOCK',
    }


}
