import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { deleteWorkTypes, getWorkTypes, IbysWorkTypesResult, updateWorkTypes } from 'api/hs/ibys-work-types';
import { IbysWorkTypesModel } from 'api/hs/ibys-work-types/ibys-work-types.model';
import WorkType from 'Common/Forms/Definitions/WorkType/WorkType';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

interface WorkTypesFromPayload {
  workingTimeDefinitions: number;
  workingPositionDefinitions: number;
  vehicleOperationDefinitions: number;
}

const workType = { WORKING_POSITION: 'WORKING_POSITION', WORKING_TIME: 'WORKING_TIME', VEHICLE_OPERATION: 'VEHICLE_OPERATION' };
export default function WorkingTimeDefinitions() {
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId } = useParams<{ employeeId: string }>();

  useAsyncEffect(async () => {
    if (employeeId) {
      await defer();
      const workTypeResult: IbysWorkTypesResult[] = await getWorkTypes(employeeId.toString());
      workTypeResult.forEach(val => {
        if (val.workType === workType.WORKING_POSITION) setValue('workingPositionDefinitions', val.code);
        if (val.workType === workType.WORKING_TIME) setValue('workingTimeDefinitions', val.code);
        if (val.workType === workType.VEHICLE_OPERATION) setValue('vehicleOperationDefinitions', val.code);
      });
      trigger();
    }
  }, [employeeId, setValue, trigger]);

  const handleSubmit = useCallback(
    async (values: WorkTypesFromPayload) => {
      try {
        const workEquipmentArray: IbysWorkTypesModel[] = [];

        workEquipmentArray.push({ workType: workType.WORKING_POSITION, code: values.workingPositionDefinitions });
        workEquipmentArray.push({ workType: workType.WORKING_TIME, code: values.workingTimeDefinitions });
        workEquipmentArray.push({ workType: workType.VEHICLE_OPERATION, code: values.vehicleOperationDefinitions });

        await deleteWorkTypes(employeeId.toString());
        await updateWorkTypes(employeeId.toString(), workEquipmentArray);
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [employeeId, showMessage]
  );
  return (
    <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
      <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <WorkType required={false} />
        <Grid item md={12} className="text-right">
          <Button variant="contained" type="submit" color="primary" className="mr-2">
            Güncelle
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
}
