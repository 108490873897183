import { TableFooter, TableRow } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

/**
 * Table Footer Slot
 */
export default function Foot({ children }: PropsWithChildren<{}>) {
  return (
    <TableFooter>
      <TableRow>{children}</TableRow>
    </TableFooter>
  );
}
