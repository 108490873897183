import { Grid, IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useRef, useState } from 'react';

import { WorkEquipmentJobStatusControllerService, WorkEquipmentJobStatusView } from 'api/client';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';

import Button from '../../../Common/Button';
import DateInput from '../../../Common/Form/DateInput';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { dateToInputFormat, useAsyncEffect, useDateFormat, useTriggerRender } from '../../../utils';
interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentJobStatusView;
}

function nextMonths(date: Date, months: number) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function DetailJobStatusDialog({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [tableData, setTableData] = useState([]);
  const reRender = useTriggerRender();
  const format = useDateFormat();

  const handleSubmit = useCallback(
    async values => {
      const res = await WorkEquipmentJobStatusControllerService.getWorkEquipmentStatusView(
        data?.layoutId,
        values?.minDate,
        values?.maxDate
      );
      setTableData(res);
    },
    [data]
  );

  const onChangeForm = useCallback(
    formValues => {
      reRender();
    },
    [reRender]
  );

  useAsyncEffect(async () => {
    if (!data) return;
    await defer();
    formRef?.current?.methods?.setValue('minDate', dateToInputFormat(new Date()));
    formRef?.current?.methods?.setValue('maxDate', nextMonths(new Date(), 1));
    await formRef?.current?.methods?.trigger();
  }, [data]);

  useAsyncEffect(async () => {
    if (!data) return;
    await defer();
    const maxDate = formRef?.current?.methods?.getValues('maxDate');
    const minDate = formRef?.current?.methods?.getValues('minDate');
    if (minDate && maxDate) {
      const res = await WorkEquipmentJobStatusControllerService.getWorkEquipmentStatusView(
        data?.layoutId,
        formRef?.current?.methods?.getValues('minDate'),
        formRef?.current?.methods?.getValues('maxDate')
      );
      setTableData(res);
    }
  }, [data]);

  return (
    <>
      <Dialog
        width="md"
        color="secondary"
        opened={open}
        onClose={() => {
          onClose(true);
        }}
        title="Periyodik Kontroller">
        <Form onSubmit={handleSubmit} onChange={onChangeForm} ref={formRef}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateInput name="minDate" label="Başlangıç" />
            </Grid>
            <Grid item xs={6}>
              <DateInput type="date" name="maxDate" label="Bitiş" />
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={4} className="text-right">
              <Button fullWidth variant="contained" type="submit" color="secondary" className="mr-2">
                Arama
              </Button>
            </Grid>
          </Grid>
        </Form>
        <div className="m-1">
          <IndicatorWrapper loading={false} data={tableData} errorMessage={'Gösterilecek Kayıt Bulunamadı.'}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Tarih
                    </TableCell>
                    <TableCell width="25%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Kullanım Durumu
                    </TableCell>
                    <TableCell width="25%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Açıklama
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((rowData, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{format(new Date(rowData?.executionDate), 'dd MMMM yyyy HH:mm')}</TableCell>
                      <TableCell align="left">{rowData?.suitabilityUse ? 'Kullanılabilir' : 'Kullanılamaz'}</TableCell>
                      <TableCell align="left">{rowData?.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </IndicatorWrapper>
        </div>
      </Dialog>
    </>
  );
}
