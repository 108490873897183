/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FineKinneyHazardDamageDefinitionDto } from '../models/FineKinneyHazardDamageDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FineKinneyHazardDamageDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static updateFineKinneyDamageDefinitions(
        id: string,
        requestBody: FineKinneyHazardDamageDefinitionDto,
    ): CancelablePromise<FineKinneyHazardDamageDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/fine-kinney-damage-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllFineKinneyDamageDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/fine-kinney-damage-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmFineKinneyDamageDefinitions(
        id: string,
        requestBody: FineKinneyHazardDamageDefinitionDto,
    ): CancelablePromise<FineKinneyHazardDamageDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/fine-kinney-damage-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmFineKinneyDamageDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/fine-kinney-damage-definitions/${id}`,
        });
    }

    /**
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFineKinneyDamageDefinitions(): CancelablePromise<Array<FineKinneyHazardDamageDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/fine-kinney-damage-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static saveFineKinneyDamageDefinitions(
        requestBody: FineKinneyHazardDamageDefinitionDto,
    ): CancelablePromise<FineKinneyHazardDamageDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/fine-kinney-damage-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmFineKinneyDamageCategoryDefinitions(): CancelablePromise<Array<FineKinneyHazardDamageDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/fine-kinney-damage-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmFineKinneyDamageDefinitions(
        requestBody: FineKinneyHazardDamageDefinitionDto,
    ): CancelablePromise<FineKinneyHazardDamageDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/fine-kinney-damage-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}