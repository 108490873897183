import { Icon } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

import Add from './Add';
import ListHealthInsurances from './List';

export default function IndexHealthInsurances() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <List>
        <ListItem
          button
          onClick={() => {
            setOpen(!open);
          }}>
          <ListItemIcon>
            <Icon className="icon-tag-multiple" />
          </ListItemIcon>
          <ListItemText primary="Özel Sağlık Sigortası" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Add />
          <ListHealthInsurances />
        </Collapse>
      </List>
    </>
  );
}
