import { Fade, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { forwardRef, PropsWithChildren, Ref, useImperativeHandle, useState } from 'react';

type AlertType = 'success' | 'info' | 'warning' | 'error';
type AlertUsage = 'token' | 'info';
type AutoHideDuration = number | null;
type ShowMessageFunc = (text: string | JSX.Element, type: AlertType, usage?: AlertUsage, autoHideDuration?: AutoHideDuration) => void;
export type AlertRef = { showMessage: ShowMessageFunc };

function Alert(_: unknown, ref: Ref<AlertRef>) {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | JSX.Element>('');
  const [severity, setSeverity] = useState<AlertType>('info');
  const [usageType, setUsageType] = useState<AlertUsage>('info');
  const [duration, setAutoHideDuration] = useState<AutoHideDuration>(6000);

  const showMessage: ShowMessageFunc = (text, type, usage, autoHideDuration = 6000) => {
    setOpen(true);
    setMessage(text);
    setSeverity(type);
    setUsageType(usage);
    setAutoHideDuration(duration);
  };

  function InnerAlert(props: PropsWithChildren<{ severity: AlertType }>) {
    return <MuiAlert onClose={() => setOpen(false)} elevation={6} variant="filled" {...props} />;
  }

  useImperativeHandle(ref, () => {
    return {
      showMessage,
    };
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: usageType === 'token' ? 'center' : 'right',
      }}
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Fade}
      autoHideDuration={duration}>
      <InnerAlert severity={severity}>{message}</InnerAlert>
    </Snackbar>
  );
}

export default forwardRef(Alert);
