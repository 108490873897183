import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { getPhpEnvironment } from 'api/hs/environment';
import { PhpEnvironment } from 'api/hs/environment/php-environment.result';
import { useFetch } from 'utils';

export interface EnvironmentContextType {
  name: 'dev' | 'prod';
}

export const EnvironmentContext = createContext<EnvironmentContextType>(null);

export function EnvironmentContextProvider({ children }: PropsWithChildren<{}>) {
  const { data } = useFetch(() => getPhpEnvironment(), {} as PhpEnvironment);
  const context = useMemo<EnvironmentContextType>(() => ({ name: data.ENV }), [data]);

  return <EnvironmentContext.Provider value={context}>{children}</EnvironmentContext.Provider>;
}
