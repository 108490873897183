import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { Icd10Favourite } from './icd10-favourite.model';
import { Icd10ListResult, Icd10TableResult } from './icd10-list.result';
import { Icd10 } from './icd10.model';

export async function getIcd10sByParent(parentName: string): Promise<Icd10[]> {
  const [error, res] = await tryCatch(HSApi.get<Icd10ListResult>(`/icd10-definitions/list?upperCode=${parentName}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return Icd10.fromServerListData(res.data);
}

export async function searchIcd10s(search: string): Promise<Icd10[]> {
  const [error, res] = await tryCatch(HSApi.get<Icd10TableResult>(`/icd10-definitions/?codeOrName=${search}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return Icd10.fromServerListData(res.data.content);
}

export async function getIdc10sByIds(...ids: Icd10['id'][]): Promise<Icd10[]> {
  if (!ids.length) {
    return [];
  }

  const res = await HSApi.get<Icd10ListResult>(`/icd10-definitions/list?ids=${ids.join(',')}`);

  return Icd10.fromServerListData(res.data);
}

export async function getIcd10Favourites(): Promise<Icd10Favourite[]> {
  const [error, res] = await tryCatch(HSApi.get<Icd10Favourite[]>(`/favorite-icd10s`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function addIcd10ToFavourites(id: Icd10['id']): Promise<void> {
  const [error] = await tryCatch(
    HSApi.post<unknown>(`/favorite-icd10s`, { icd10Id: id })
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}

export async function removeIcd10FromFavourites(id: Icd10Favourite['id']): Promise<void> {
  const [error] = await tryCatch(HSApi.delete<unknown>(`/favorite-icd10s/${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
