import { findByKey } from '@thalesrc/js-utils';

import { AnalysisAggregate } from 'api/hs/examination/analysis';

import { Category } from '../category.enum';
import { DatasetModel, GraphModel } from './graph.model';

interface DatasetType {
  [parameter: string]: DatasetModel[];
}

export default function preparePulmonaryGraphData(
  data: AnalysisAggregate[],
  type: 'FVC' | 'FEV1' | 'FEV1/FVC' | 'PEF' | 'FEF25-75',
  analysisCategoryDefinitions: any
): GraphModel {
  if (!data || data.length === 0) return null;
  const colorSet = [
    'rgba(204, 50, 54, 1)',
    'rgba(89, 140, 115, 1)',
    'rgba(89, 43, 115, 1)',
    'rgba(184, 186, 115, 1)',
    'rgba(0, 91, 208, 1)',
    'rgba(238, 130, 238, 1)',
  ];

  const dataSet: DatasetType = {};

  data?.forEach(d => {
    d.pulmonaryResult?.pftDetails?.forEach((item, index) => {
      if (!dataSet[item.parameter]) {
        dataSet[item.parameter] = [];
        dataSet[item.parameter].push({
          label: item.parameter,
          data: [item.act as string],
          fill: false,
          borderColor: colorSet[0],
        });
        dataSet[item.parameter].push({
          label: 'Pred',
          data: [item.pred as string],
          fill: false,
          borderColor: colorSet[1],
        });
        dataSet[item.parameter].push({
          label: 'Nas',
          data: [item.nas as string],
          fill: false,
          borderColor: colorSet[2],
        });
      } else {
        dataSet[item.parameter].find(itemA => itemA.label === item.parameter)?.data.push(item.act as string);
        dataSet[item.parameter].find(itemB => itemB.label === 'Pred')?.data.push(item.pred as string);
        dataSet[item.parameter].find(itemC => itemC.label === 'Nas')?.data.push(item.nas as string);
      }
    });
  });

  return {
    labels: data
      ?.filter(item => findByKey(analysisCategoryDefinitions, 'id', item.categoryDefinitionId)?.label === Category.Pulmonary)
      .map(d => d.date),
    datasets: dataSet ? dataSet[type] : [],
  };
}
