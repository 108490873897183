import HSApi from '../../HSApi';
import { ProtocolNurseOperationListResult } from './protocol-nurse-operation-list.result';

export async function getNurseOperationList(page = 0, size = 20, order: null | string): Promise<ProtocolNurseOperationListResult> {
  const res = await HSApi.get<ProtocolNurseOperationListResult>(
    `/nurse-operations-protocols/views?page=${page}&size=${size}&order=${order ?? ''}`
  );

  return res.data;
}
export async function exportNurseOperationList() {

  const res = await HSApi.get(`/nurse-operations-protocols/views/export?type=EXCEL`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sp-protokol-defteri.xlsx';
  a.click();
}
