import { Avatar, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import { deleteDefaultNurseOperation } from 'api/hs/nurse-operations/default-nurse-operations';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { EnumContext } from 'context/enum.context';

import { DefaultNurseOperationContext } from '../../nurse.operation.context';

function reverseDateFormat(date: string): string {
  return date.split('-').reverse().join('-');
}

export default function ListBodyMeasurement() {
  const { loading, defaultNurseOperations, reload, filter } = useContext(DefaultNurseOperationContext);
  const { nurseOperationDefinitions } = useContext(EnumContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();
  const { pathname } = useLocation();

  const navigateToDetails = useCallback((nurseOperationId: string) => () => history.push(`${pathname}/${nurseOperationId}`), [
    history,
    pathname,
  ]);

  const handleDelete = useCallback(
    async defaultNurseOperationsDetail => {
      const submitted = await openWarnDialog({
        text: `${
          findByKey(nurseOperationDefinitions, 'id', defaultNurseOperationsDetail?.nurseOperationDefinitionId)?.label
        } - kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await deleteDefaultNurseOperation(defaultNurseOperationsDetail.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          await reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage, nurseOperationDefinitions]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={defaultNurseOperations} errorMessage="Gösterilecek Sağlık Personel İşlemi Bulunamadı.">
        <List>
          {defaultNurseOperations
            ?.filter(nurseOperationDefault => (filter === 'all' ? true : nurseOperationDefault.nurseOperationDefinitionId === filter))
            .map((item, index) => (
              <ListItem key={item?.id} button onClick={navigateToDetails(item?.id)}>
                <ListItemAvatar>
                  <Avatar>
                    <LocalHospitalIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={findByKey(nurseOperationDefinitions, 'id', item?.nurseOperationDefinitionId)?.label}
                  secondary={item?.date ? 'Oluşturma Tarihi : ' + reverseDateFormat(item?.date) : null}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleDelete(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </IndicatorWrapper>
    </div>
  );
}
