import React from 'react';

import { getIbysTrainings } from 'api/hs/trainings/ibys-trainings';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import EditDialogForm from '../EditDialogForm';
import PinDialogForm from '../PinDialogForm';
import { COLUMNS } from './columns';
import ListTable from './ListTable';
import ToolbarIbys from './Toolbar';

export default function Completed() {
  return (
    <>
      <HSTableAndPaginationAndTableContextProvider fetcher={(page, size) => getIbysTrainings(page, size)} columns={COLUMNS}>
        <ToolbarIbys />
        <EditDialogForm />
        <PinDialogForm />
        <ListTable />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
