import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetPositionsResult } from './get-positions.result';

export async function getPositions(): Promise<GetPositionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetPositionsResult[]>(`/firms/position-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
