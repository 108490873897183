import { createContext } from 'react';

import { SystemSettingResult } from 'api/hs/definitions/system-setting';
import { SystemSettingModel } from 'model/definitions/system-setting/systemSetting.model';

export interface SystemSettingContext {
  data: SystemSettingResult[];

  loading: boolean;

  search(searchString: string): void;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  page: number;

  setPage(page: number): void;

  size: number;

  setSize(size: number): void;

  totalPages: number;

  setTotalPages(totalPages: number): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  selectedData: SystemSettingModel;

  setSelectedData(data: SystemSettingModel): void;
}

export const SystemSettingListContext = createContext<SystemSettingContext>(null);
