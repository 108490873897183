import React, { createContext, PropsWithChildren, useState } from 'react';

import {
  getActionsPriorityDefinitions,
  getActionsReferenceDefinitions,
  getActionsStatusDefinitions,
  getAddictions,
  getAddictionStates,
  getAnalysisCategoryDefinitions,
  getBloodDonations,
  getBloodTypes,
  getBodyMeasurement,
  getCertificateDefinitions,
  getCommitteeMeetingStatusDefinitions,
  getCommitteeMembersPositionDefinitions,
  getComplaintDefinitions,
  getEducationStatuses,
  getGenders,
  getHospitalType,
  getImmunityStatuses,
  getMaritialStatuses,
  getMedicalHistoryDefinitions,
  getNurseOperationDefinitions,
  getPhysicalWorkConditionAirDefinition,
  getPhysicalWorkConditionBiologicalFactorsDefinition,
  getPhysicalWorkConditionChemicalMatterDefinitions,
  getPhysicalWorkConditionDustRegulationDefinitions,
  getPhysicalWorkConditionElectricityDefinitions,
  getPhysicalWorkConditionNoiseDefinitions,
  getPhysicalWorkConditionPhysicalEnvironmentDefinitions,
  getPregnancyStates,
  getPrescriptionDescriptionDefinitions,
  getPrescriptionMedicinePeriodUnitDefinitions,
  getPrescriptionMedicineUsageDefinitions,
  getPrescriptionProvisionDefinitions,
  getPrescriptionSatietyInformationDefinitions,
  getPrescriptionSubtypeDefinitions,
  getPrescriptionTypeDefinitions,
  getPrescriptionUnitDoseDefinitions,
  getVehicleOperationDefinitions,
  getVitalSignCategoryDefinition,
  getWorkingPositionDefinitions,
  getworkingTimeDefinitions,
  getWorkingTypes,
} from 'api/hs/enum';

export interface EnumType {
  id: number | string;
  label: string;
}

export interface EnumContextType {
  genders: EnumType[];
  bloodTypes: EnumType[];
  bloodDonations: EnumType[];
  maritialStatuses: EnumType[];
  educationStatuses: EnumType[];
  workingTypes: EnumType[];
  pregnancyStates: EnumType[];
  addictions: EnumType[];
  addictionStates: EnumType[];
  complaintDefinitions: EnumType[];
  medicalHistoryDefinitions: EnumType[];
  hospitalTypes: EnumType[];
  vitalSignCategoryDefinition: EnumType[];
  bodyMeasurementsCategoriDefiniton: EnumType[];
  prescriptionTypeDefinitions: EnumType[];
  prescriptionMedicineUsageDefinitions: EnumType[];
  prescriptionDescriptionDefinitions: EnumType[];
  prescriptionMedicinePeriodUnitDefinitions: EnumType[];
  prescriptionProvisionDefinitions: EnumType[];
  prescriptionSubtypeDefinitions: EnumType[];
  satietyInformationDefinitions: EnumType[];
  prescriptionUnitDoseDefinitions: EnumType[];
  immunityStatuses: EnumType[];
  analysisCategoryDefinitions: EnumType[];
  nurseOperationDefinitions: EnumType[];
  certificateDefinitions: EnumType[];
  physicalWorkConditionAirDefinitions: EnumType[];
  physicalWorkConditionChemicalMatterDefinitions: EnumType[];
  physicalWorkConditionBiologicalFactorsDefinition: EnumType[];
  physicalWorkConditionDustRegulationDefinitions: EnumType[];
  physicalWorkConditionElectricityDefinitions: EnumType[];
  physicalWorkConditionNoiseDefinitions: EnumType[];
  physicalWorkConditionPhysicalEnvironmentDefinitions: EnumType[];
  vehicleOperationDefinitions: EnumType[];
  workingPositionDefinitions: EnumType[];
  workingTimeDefinitions: EnumType[];
  actionsStatusDefinitions: EnumType[];
  actionsReferenceDefinitions: EnumType[];
  actionsPriorityDefinitions: EnumType[];
  committeeMembersPositionDefinitions: EnumType[];
  committeeMeetingStatusDefinitions: EnumType[];
}

export const EnumContext = createContext<EnumContextType>(null);

export function EnumContextProvider({ children }: PropsWithChildren<unknown>) {
  const [LOADING] = useState(Symbol('loading'));
  const [fetchers] = useState<{ [P in keyof EnumContextType]: () => Promise<EnumType[]> }>({
    genders: getGenders,
    bloodTypes: getBloodTypes,
    bloodDonations: getBloodDonations,
    maritialStatuses: getMaritialStatuses,
    educationStatuses: getEducationStatuses,
    workingTypes: getWorkingTypes,
    pregnancyStates: getPregnancyStates,
    addictions: getAddictions,
    addictionStates: getAddictionStates,
    complaintDefinitions: getComplaintDefinitions,
    medicalHistoryDefinitions: getMedicalHistoryDefinitions,
    hospitalTypes: getHospitalType,
    prescriptionTypeDefinitions: getPrescriptionTypeDefinitions,
    prescriptionMedicineUsageDefinitions: getPrescriptionMedicineUsageDefinitions,
    prescriptionDescriptionDefinitions: getPrescriptionDescriptionDefinitions,
    prescriptionMedicinePeriodUnitDefinitions: getPrescriptionMedicinePeriodUnitDefinitions,
    prescriptionProvisionDefinitions: getPrescriptionProvisionDefinitions,
    prescriptionSubtypeDefinitions: getPrescriptionSubtypeDefinitions,
    satietyInformationDefinitions: getPrescriptionSatietyInformationDefinitions,
    prescriptionUnitDoseDefinitions: getPrescriptionUnitDoseDefinitions,
    vitalSignCategoryDefinition: getVitalSignCategoryDefinition,
    bodyMeasurementsCategoriDefiniton: getBodyMeasurement,
    immunityStatuses: getImmunityStatuses,
    analysisCategoryDefinitions: getAnalysisCategoryDefinitions,
    nurseOperationDefinitions: getNurseOperationDefinitions,
    certificateDefinitions: getCertificateDefinitions,
    physicalWorkConditionAirDefinitions: getPhysicalWorkConditionAirDefinition,
    physicalWorkConditionBiologicalFactorsDefinition: getPhysicalWorkConditionBiologicalFactorsDefinition,
    physicalWorkConditionChemicalMatterDefinitions: getPhysicalWorkConditionChemicalMatterDefinitions,
    physicalWorkConditionDustRegulationDefinitions: getPhysicalWorkConditionDustRegulationDefinitions,
    physicalWorkConditionElectricityDefinitions: getPhysicalWorkConditionElectricityDefinitions,
    physicalWorkConditionNoiseDefinitions: getPhysicalWorkConditionNoiseDefinitions,
    physicalWorkConditionPhysicalEnvironmentDefinitions: getPhysicalWorkConditionPhysicalEnvironmentDefinitions,
    vehicleOperationDefinitions: getVehicleOperationDefinitions,
    workingPositionDefinitions: getWorkingPositionDefinitions,
    workingTimeDefinitions: getworkingTimeDefinitions,
    actionsPriorityDefinitions: getActionsPriorityDefinitions,
    actionsReferenceDefinitions: getActionsReferenceDefinitions,
    actionsStatusDefinitions: getActionsStatusDefinitions,
    committeeMembersPositionDefinitions: getCommitteeMembersPositionDefinitions,
    committeeMeetingStatusDefinitions: getCommitteeMeetingStatusDefinitions,
  });

  const [data] = useState<{
    results: { [P in keyof EnumContextType]?: EnumType[] | symbol };
  }>({ results: {} });
  const [, triggerRender] = useState({});

  const proxy = new Proxy(
    {},
    {
      // eslint-disable-next-line
      get(_, prop: keyof EnumContextType) {
        if (prop in data.results) {
          if (data.results[prop] === LOADING) {
            return [];
          } else {
            return data.results[prop];
          }
        }

        const storedValue = JSON.parse(localStorage.getItem(`hsf/enums/${prop}`));

        if (storedValue) {
          data.results = { ...data.results, [prop]: storedValue };

          return storedValue;
        }

        data.results = { ...data.results, [prop]: LOADING };

        fetchers[prop]().then(res => {
          localStorage.setItem(`hsf/enums/${prop}`, JSON.stringify(res));

          data.results = { ...data.results, [prop]: res };

          triggerRender(data.results);
        });

        return [];
      },
    }
  );

  return <EnumContext.Provider value={proxy as any}>{children}</EnumContext.Provider>;
}
