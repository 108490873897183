import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Form from 'Common/Form/Form';
import { SelectOptionsContext } from 'context/select-options.context';

import {
  getOccupationalSafetyRegistries,
  getOccupationalSafetyRegistriesDetections,
  getOccupationalSafetyRegistriesViews,
} from '../../api/hs/occupational-safety-registries /occupational-safety-registries';
import AutoComplete from '../../Common/Form/AutoComplete';
import Toolbar from '../../Common/Toolbar';
import { useFetch } from '../../utils';
import CompanyTable from './CompanyTable';
import DetectionsTable from './DetectionsTable';
import RegistriesTable from './RegistriesTable';

const OccupationalSafetyRegistriesModule = () => {
  const formRef = useRef();
  const { companies } = useContext(SelectOptionsContext);
  const { companyId, bookId, pageId } = useParams<{ companyId: string; bookId: string; pageId: string }>();
  const [loading, setLoading] = useState(true);

  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [selectedNotebookId, setSelectedNotebookId] = useState();
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [selectedDetectionId, setSelectedDetectionId] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [registriesData, setRegistriesData] = useState(null);
  const [detectionsData, setDetectionsData] = useState(null);
  const history = useHistory();

  const getNotebook = async () => {
    if (!selectedCompanyId) return [];
    const [err, res] = await tryCatch(getOccupationalSafetyRegistries(selectedCompanyId));
    setTableData(res);
    if (err) {
      return [];
    }
    return res?.map(elem => ({
      text: elem.label,
      value: elem.id,
    }));
  };
  const getPageNumbers = async () => {
    if (!selectedNotebookId) return [];
    const [err, res] = await tryCatch(getOccupationalSafetyRegistriesViews(selectedNotebookId));
    setRegistriesData(res.content);
    if (err) {
      return [];
    }
    return res?.content?.map(e => ({
      text: e.pageNumber.toString(),
      value: e.pageId,
    }));
  };
  const getDetections = async () => {
    if (!selectedPageId) return [];
    const [err, res] = await tryCatch(getOccupationalSafetyRegistriesDetections(selectedPageId));
    setDetectionsData(res);
    if (err) {
      return [];
    }
    return res?.map(e => ({
      text: e.description,
      value: e.id,
    }));
  };

  const { data: notebooks, fetch: fetchNotebook } = useFetch(getNotebook, [], {
    deps: [selectedCompanyId],
    reloadDeps: [selectedCompanyId],
    setLoading,
  });

  const { data: pageNumbers, fetch: fetchPageNumbers } = useFetch(getPageNumbers, [], {
    deps: [selectedNotebookId],
    reloadDeps: [selectedNotebookId],
    setLoading,
  });

  const { fetch: fetchDetections } = useFetch(getDetections, [], {
    deps: [selectedPageId],
    reloadDeps: [selectedPageId],
  });
  useEffect(() => {
    if (pageId) {
      formRef.current.methods.setValue('page', pageId);
      setSelectedPageId(pageId);
      getDetections();
    }
    // eslint-disable-next-line
  }, [pageId]);
  useEffect(() => {
    if (bookId) {
      formRef.current.methods.setValue('label', bookId);
      setSelectedNotebookId(bookId);
      getPageNumbers();
    }
    // eslint-disable-next-line
  }, [bookId]);
  useEffect(() => {
    if (companyId) {
      formRef.current.methods.setValue('companyId', companyId);
      setSelectedCompanyId(companyId);
      getNotebook();
    }
    // eslint-disable-next-line
  }, [companyId]);

  useEffect(() => {
    if (!selectedNotebookId) {
      getNotebook();
    } // eslint-disable-next-line
  }, [selectedNotebookId]);

  useEffect(() => {
    if (!selectedPageId) getPageNumbers();
    // eslint-disable-next-line
  }, [selectedPageId]);

  useEffect(() => {
    if (!selectedDetectionId) getDetections();
    // eslint-disable-next-line
  }, [selectedDetectionId]);
  useEffect(() => {
    if (tableData) {
      setDetectionsData(null);
      setRegistriesData(null);
    }
  }, [tableData]);
  useEffect(() => {
    if (registriesData) {
      setTableData(null);
      setDetectionsData(null);
    }
  }, [registriesData]);
  useEffect(() => {
    if (detectionsData) {
      setTableData(null);
      setRegistriesData(null);
    }
  }, [detectionsData]);
  const onChangeForm = useCallback(
    values => {
      setSelectedCompanyId(values?.companyId);
      setSelectedNotebookId(values?.label);
      setSelectedPageId(values?.page);
      setSelectedDetectionId(values?.detection);
      if (values?.companyId && values?.label && values?.page) {
        history.push(`/occupational-safety-registries/query/companies/${values.companyId}/books/${values.label}/detections/${values.page}`);
        return;
      }
      if (values?.companyId && values?.label) {
        history.push(`/occupational-safety-registries/query/companies/${values.companyId}/books/${values.label}`);
        return;
      }

      if (values?.companyId) {
        history.push('/occupational-safety-registries/query/companies/' + values.companyId);
      }
    },
    [history]
  );

  return (
    <div>
      <Toolbar color="secondary" className="mb-1">
        <Typography variant="h6">Onaylı Defter</Typography>
      </Toolbar>
      <Container maxWidth="xl">
        <div className={'mt-2 pt-2'}>
          <Paper elevation={3}>
            <br />
            <Form className={'m-2'} onChange={onChangeForm} ref={formRef}>
              <Grid container spacing={3}>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete variant={'outlined'} single name="companyId" options={companies} label="Şirketler" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete variant={'outlined'} single name="label" disabled={!notebooks.length} options={notebooks} label="Defter" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete
                    variant={'outlined'}
                    single
                    name="page"
                    disabled={!pageNumbers.length}
                    options={pageNumbers}
                    label="Sayfa no"
                  />
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </div>
      </Container>

      <br />
      <Container maxWidth={'xl'}>
        {selectedCompanyId && tableData && (
          <CompanyTable loading={loading} data={tableData} selectedCompanyId={selectedCompanyId} fetch={fetchNotebook} />
        )}
      </Container>
      <Container maxWidth={'xl'}>
        {selectedNotebookId && registriesData && (
          <RegistriesTable data={registriesData} registryId={selectedNotebookId} fetch={fetchPageNumbers} loading={loading} />
        )}
      </Container>
      <Container maxWidth={'xl'}>
        {selectedPageId && detectionsData && (
          <DetectionsTable data={detectionsData} pageId={selectedPageId} fetch={fetchDetections} loading={loading} />
        )}
      </Container>
    </div>
  );
};

export default OccupationalSafetyRegistriesModule;
