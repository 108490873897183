import { Card, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ActionView } from '../../api/client';
import { AnalysisControllerService } from '../../api/client';
import { NearMissControllerService } from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function NearMisses() {
  const [chartData, setChartData] = useState<ActionView[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();
  const { nearMissTypes, shiftDefinitions } = useContext(SelectOptionsContext);
  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      filter: {
        nearMissDateMin: values?.minDate + 'T00:00:00',
        nearMissDateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'date',
      },
    };
    const res = await NearMissControllerService.getNearMissViews(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Ramak Kala İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirket Bazlı Dağılımı" /> <Divider />
                <DateGroupXYChart
                  chartName="near-missess-company-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="companyName"
                  xAxesTitle="Şirketler"
                  xAxesText="Tarih"
                  yAxesText="Ramak Kala Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Departman Bazlı Dağılımı" /> <Divider />
                <DateGroupXYChart
                  chartName="near-missess-department-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="nearMissDepartmentViews[].departmentName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Tarih"
                  yAxesText="Ramak Kala Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="İş İstasyon Bazlı Dağılımı" /> <Divider />
                <DateGroupXYChart
                  chartName="near-missess-station-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="nearMissStationViews[].stationName"
                  xAxesTitle="İş İstasyonlar"
                  xAxesText="Tarih"
                  yAxesText="Ramak Kala Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <PieChartGridContainer
                title="Ramak Kala Türüne Göre Dağılımı"
                chartProps={{
                  chartName: 'analysis-nearMissTypeViews-report',
                  preparedData: convertAccidentReviewPropertyToChartData(
                    chartData,
                    'nearMissTypeViews[].nearMissTypeDefinitionId',
                    nearMissTypes
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <PieChartGridContainer
                title="Vardiya'ya Göre Dağılımı"
                chartProps={{
                  chartName: 'analysis-shiftId-report',
                  preparedData: convertAccidentReviewPropertyToChartData(chartData, 'shiftId', shiftDefinitions),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
