import { Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import { Pagination } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';

import Search from '../BaseDefinitionPage/BaseDefinition/List/Search';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: 'lightslategray',
  },
  dataName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

// @ts-ignore
function usePagination(data, itemsPerPage, searchKey) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / Number(itemsPerPage));

  function currentData() {
    const begin = (currentPage - 1) * Number(itemsPerPage);
    const end = begin + Number(itemsPerPage);

    // console.log(data.slice(begin, end));
    return searchKey ? data.slice(0, end) : data.slice(begin, end);
  }

  function next() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

type DefaultSizeType = 5 | 10 | 20 | 50;
const DEFAULT_SIZES: DefaultSizeType[] = [5, 10, 20, 50];

const HealtPersonelOperationDefinition = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [defaultValue] = useState('');
  const [label, setLabel] = useState(defaultValue);
  const [size, setSize] = useState('10');

  const updateSearchKeyword = useCallback(
    (value: string) => {
      setLabel(value);
    },
    [setLabel]
  );
  const handleSizeChange = useCallback(({ target: { value } }) => setSize(value as T), [setSize]);
  const healthOperations = [
    'Vital Ölçüler',
    'Vital Ölçüler',
    'Enjeksiyon',
    'Belirsiz',
    'Pansuman',
    'Aşı',
    'Tam İdrar Tetkiki',
    'EKG',
    'Kan Tahlili',
    'Acil Tıbbi Müdahale',
    'Temel Yaşam Desteği',
    'Kanama Kontrol',
    'Sütur',
    'Yabancı Cisim Çıkarma',
    'Atel',
    'Göz Lavajı',
    'Yanık Müdahalesi',
    'Danışmanlık',
    'Diyet Danışmanlığı',
    'İlaç Kullanımı Danışmanlığı',
    'Sigara Danışmanlığı',
    'Gebe Takibi',
  ];

  const filteredData = label ? healthOperations?.filter(str => str.toLowerCase().indexOf(label.toLowerCase()) >= 0) : healthOperations;

  const count = Math.ceil(filteredData?.length / size);
  const _DATA = usePagination(filteredData, size, label);

  const handleChange = (e: any, p: React.SetStateAction<number>) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Search value={label} onChange={updateSearchKeyword} />
      <List>
        {healthOperations.length > 0 &&
          _DATA.currentData().map(healthOperation => (
            <>
              <ListItem>
                <ListItemAvatar className={classes.textColor}>
                  <DomainIcon fontSize="small" />
                </ListItemAvatar>
                <ListItemText color="primary">
                  <span className={classes.dataName}>{healthOperation}</span>
                </ListItemText>
              </ListItem>
              <Divider />
            </>
          ))}
      </List>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Typography color="inherit" variant="body2" className="mr-1">
            Gözüken kayıt sayısı:
          </Typography>
        </Grid>
        <Grid item>
          <Select value={size} onChange={handleSizeChange} classes={{ root: 'pl-1' }}>
            {DEFAULT_SIZES.map(s => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Pagination className="ml-2" showFirstButton showLastButton onChange={handleChange} count={count} page={page} color="primary" />
        </Grid>
      </Grid>
    </>
  );
};

export default HealtPersonelOperationDefinition;
