/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TrainingPlaningEmployeeGroupDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    trainingId?: string;
    referenceTableId?: string;
    assignmentTypeDefinition?: TrainingPlaningEmployeeGroupDto.assignmentTypeDefinition;
}

export namespace TrainingPlaningEmployeeGroupDto {

    export enum assignmentTypeDefinition {
        LOCATION = 'LOCATION',
        DEPARTMENT = 'DEPARTMENT',
        WORKSTATION = 'WORKSTATION',
        COMPANY = 'COMPANY',
        POSITION = 'POSITION',
        EMPLOYEE = 'EMPLOYEE',
    }


}
