import { useCallback, useMemo, useState } from 'react';

export interface UseDialogState {
  opened: boolean;
  open(): void;
  close(): void;
}

export function useDialogState(): UseDialogState {
  const [opened, setOpened] = useState(false);

  const open = useCallback(() => setOpened(true), []);
  const close = useCallback(() => setOpened(false), []);

  return useMemo(() => ({ opened, open, close }), [opened, open, close]);
}
