import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { TrainingEmployeeViewControllerService } from 'api/client';

import { exportAccidentEmployeesExport, exportAccidentsWidgetExport } from '../../../../api/hs/accident-widget';
import { DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import { exportExpiredTrainingEmployeesExport, exportTrainingWidgetsEmployeesExport } from '../../../../api/hs/trainings/training-widget';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import TrainingTable from './TrainingTable';

const fileNames = {
  expiredTrainings: 'süresi-gecmis.xlsx',
  uneducatedEmployee: 'egitim-almamis-personeller.xlsx',
  trainingedEmployee: 'egitim-almis-personeller',
};

export default () => {
  const [tab, setTab] = useState<string>('expiredTrainings');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: expiredTrainings } = useFetch(async () => await TrainingEmployeeViewControllerService.getEmployeeExpiredTrainings(), [], {
    setLoading,
  });

  const { data: uneducatedEmployee } = useFetch(
    async () =>
      await TrainingEmployeeViewControllerService.getEmployeeTrainings({
        trainingEmployeeFilter: {
          isPlanned: false,
        },
        pagingAndSortingRequestParam: {
          page: 0,
          size: 2147483647,
        },
      }),
    [],
    { setLoading }
  );

  const { data: trainingedEmployee } = useFetch(
    async () =>
      await TrainingEmployeeViewControllerService.getEmployeeTrainings({
        trainingEmployeeFilter: { isPlanned: true, isParticipated: true, startDate: dateRange?.start, endDate: dateRange?.end },
      }),
    [],
    { setLoading }
  );

  const {
    trainingWidget: { close, opened },
    trainingWidgetDateRange: { dateRange },
  } = useContext(DialogContext);

  const tabs = { expiredTrainings, uneducatedEmployee, trainingedEmployee };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'expiredTrainings') {
      exportExpiredTrainingEmployeesExport(fileNames[tab]).then(noop);
    }

    if (tab === 'uneducatedEmployee') {
      exportTrainingWidgetsEmployeesExport(
        {
          trainingEmployeeFilter: { isPlanned: false },
        },
        fileNames[tab]
      ).then(noop);
    }

    if (tab === 'trainingedEmployee') {
      exportTrainingWidgetsEmployeesExport(
        {
          trainingEmployeeFilter: { isPlanned: true, isParticipated: true, startDate: dateRange?.start, endDate: dateRange?.end },
        },
        fileNames[tab]
      ).then(noop);
    }
  };

  return (
    <TabContext value={tab.toString()}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Eğitimler" color={colors.training} onClick={tabs[tab]?.content?.length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Süresi Dolmuş (${expiredTrainings?.content?.length ?? 0})`} value={'expiredTrainings'} />
            <Tab wrapped label={`Eğitim Hiç Almamış Kişiler (${uneducatedEmployee?.content?.length ?? 0})`} value={'uneducatedEmployee'} />
            <Tab wrapped label={`Eğitim Alan Kişiler (${trainingedEmployee?.content?.length ?? 0})`} value={'trainingedEmployee'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'expiredTrainings'}>
            <IndicatorWrapper data={expiredTrainings?.content} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <TrainingTable trainings={expiredTrainings?.content} />
            </IndicatorWrapper>
          </TabPanel>

          <TabPanel value={'uneducatedEmployee'}>
            <IndicatorWrapper data={uneducatedEmployee?.content} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <TrainingTable trainings={uneducatedEmployee?.content} />
            </IndicatorWrapper>
          </TabPanel>

          <TabPanel value={'trainingedEmployee'}>
            <IndicatorWrapper data={trainingedEmployee?.content} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <TrainingTable trainings={trainingedEmployee?.content} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
