import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { useAsyncEffect, useTriggerRender } from 'utils';

import {
  CommitteeMeetingsContentResult,
  saveCommitteeMeeting,
  updateCommitteeMeeting,
} from '../../../../api/hs/committee/committee-meetings';
import { getCommitteeMembers } from '../../../../api/hs/definitions/committee-members';
import EmployeeSelectionBox from '../../../../Common/Form/EmployeeSelection/EmployeeSelectionBox';
import Switch from '../../../../Common/Form/Switch';
import { AlertContext } from '../../../../context/alert.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: CommitteeMeetingsContentResult;
}

export default function CommitteeMeetingForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { committeeMeetingStatusDefinitions, companies } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      const payload = {
        subject: values?.subject,
        isFaceToFace: values?.isFaceToFace,
        plannedDate: values?.plannedDate,
        meetingStatusDefinition: values?.meetingStatusDefinition,
        plannedEndDate: values?.plannedEndDate,
        companyId: values?.companyId,
        description: values?.description,
        meetingParticipants: values?.employeeIds?.map((employeeId: string) => {
          return {
            employeeId,
          };
        }),
      };
      try {
        if (!data?.id) {
          await saveCommitteeMeeting(payload);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await updateCommitteeMeeting(data?.id, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();
      for (const [key, value] of Object.entries(data)) {
        formRef?.current?.methods?.setValue(key, value);
      }
      formRef?.current?.methods?.setValue(
        'employeeIds',
        data?.employees?.map(employeeData => employeeData?.id)
      );
      formRef?.current?.methods?.setValue('meetingStatusDefinition', data?.meetingStatusDefinition);
      formRef?.current?.methods?.trigger();
    } else {
      await defer();
      const committeeMembers = await getCommitteeMembers();
      formRef?.current?.methods?.setValue(
        'employeeIds',
        committeeMembers?.map(employeeData => employeeData?.employeeId)
      );
      formRef?.current?.methods?.setValue('meetingStatusDefinition', 'PLANNED');
      formRef?.current?.methods?.trigger();
    }
  }, [data, open]);

  return (
    <>
      <Dialog
        color="secondary"
        opened={open}
        onClose={onClose.bind(null, false)}
        title={data ? 'ISG Kurul Toplantısı Düzenle' : 'ISG Kurul Toplantısı Ekle'}>
        <Form onSubmit={submit} onChange={reRender} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField name="subject" label="Toplantının Adı" />
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <DateInput type="date-time" name="plannedDate" label="Toplantı Zamanı" required={true}/>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <AutoComplete single name="meetingStatusDefinition" options={committeeMeetingStatusDefinitions} label="Toplantı Durumu" />
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <AutoComplete single name="companyId" options={companies} label="Şirket" required={true}/>
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              <Switch
                name="isFaceToFace"
                label={formRef?.current?.value?.isFaceToFace === true ? 'Yüzyüze Toplantı' : 'Uzaktan Toplantı'}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField rows={3} name="description" label="Toplantının Gündemi" />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <EmployeeSelectionBox name="employeeIds" label="Katılımcılar"  />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
