import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';
import Dialog from 'Common/Dialog';

import { useAsyncEffect } from '../../../utils';
import GeneralInfo from './GeneralInfo';
import ParticipantInfo from './ParticipantInfo';
import TopicInfo from './TopicInfo';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: TrainingsViewContentResult;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ShowDialogTraining({ open, onClose, data }: Props) {
  const classes = useStyles();
  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    // await defer();
    // await loadEmployees(...data.ibysTrainingEmployees?.map((employee: string) => employee.employeeId));
    //
    // data?.ibysTrainingEmployees?.map(
    //   async employeeParticipated =>
    //     await formRef?.current?.methods?.setValue(employeeParticipated?.employeeId + 'toogle', employeeParticipated?.isParticipated ? 1 : 0)
    // );
    // await defer();
    // for (const [key, value] of Object.entries(data)) {
    //   formRef?.current?.methods?.setValue(key as any, value);
    // }
    // formRef?.current?.methods?.setValue('ohsProfessionalNid', profile?.nid);
    //
    // await formRef?.current?.methods?.trigger();
  }, []);

  return (
    <Dialog width="md" color="primary" title="Eğitim Önizlemesi" opened={open} onClose={onClose}>
      <List component="nav" className={classes.root}>
        <GeneralInfo trainingDetail={data} />
        <ParticipantInfo trainingDetail={data} />
        <TopicInfo trainingDetail={data} />
      </List>
    </Dialog>
  );
}
