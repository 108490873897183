import { FormControl, FormHelperText, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { difference } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import Noop from 'Common/Noop';
import { BaseEmployee } from 'model';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';

import { EmployeeSelectionCacheContext, EmployeeSelectionContext } from '../employee-selection.context';

type ValueType = BaseEmployee['id'];
type OnChangeFunc = (value: ValueType | ValueType[]) => void;
type AutocompletePropsType = AutocompleteProps<ValueType, boolean, false, true>;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  autoRoot: {
    display: 'grid',
    maxWidth: '100%',
  },
  inputRoot: {
    position: 'relative',
    maxWidth: '100%',
  },
  input: {
    minHeight: 23,
    padding: '4px !important',
  },
}));

export default function Input() {
  const classes = useStyles();
  const { control, errors, trigger } = useFormContext();
  const { name, disabled, single, label, placeholder } = useContext(EmployeeSelectionContext);
  const { options, names, avatars, contentStrings, getEmployees } = useContext(EmployeeSelectionCacheContext);
  const [searchText, setSearchText] = useState<string>('');
  const ref = useRef<HTMLInputElement>();

  const defaultValue = useMemo(() => (single ? null : []), [single]);

  const handleInputChange = useCallback(
    async (_, value: string, reason) => {
      if (reason === 'reset' || disabled) {
        return;
      }

      setSearchText(value);
      getEmployees(value);
    },
    [disabled, getEmployees]
  );

  const handleAutoCompleteOnChange = useCallback<(onChange: OnChangeFunc) => AutocompletePropsType['onChange']>(
    onChange => (_, newValues) => {
      if (disabled) {
        return;
      }

      newValues = (newValues as ValueType[]).filter(val => options.includes(val));

      if (single) {
        onChange(newValues[0] ?? null);

        if (newValues[0]) {
          ref.current.dispatchEvent(new Event('blur'));
        }
      } else {
        onChange(newValues);
      }

      control.updateFormState({ touched: { ...control.formStateRef.current.touched, [name]: true } });
      setSearchText('');
      trigger(name);
    },
    [trigger, name, control, disabled, single, options]
  );

  const getOptionLabel = useCallback((option: ValueType) => contentStrings[option], [contentStrings]);

  const renderInput = useCallback(
    (value: ValueType | ValueType[]) => (params: TextFieldProps) => (
      <TextField
        {...params}
        variant="standard"
        label={label}
        InputProps={{
          ...params.InputProps,
          className: `${params.InputProps.className} ${classes.inputRoot}`,
          placeholder: disabled || (single && !!value) ? null : placeholder,
          inputRef: ref,
        }}
        inputProps={{
          ...params.inputProps,
          disabled: disabled || (single && !!value),
          className: `${params.inputProps?.className} ${classes.input}`,
        }}
      />
    ),
    [classes, label, disabled, placeholder, single]
  );

  const renderTags = useCallback<AutocompletePropsType['renderTags']>(Noop, []);

  const renderOption = useCallback(
    (option: ValueType) => (
      <>
        <Avatar url={avatars[option]} className="mr-2" />
        {names[option]}
      </>
    ),
    [names, avatars]
  );

  const renderAutoComplete = useCallback<ControllerProps<any>['render']>(
    ({ onChange, value }) => (
      <Autocomplete<ValueType, boolean, true, boolean>
        className={classes.autoRoot}
        multiple
        openOnFocus={!disabled}
        disableCloseOnSelect={false}
        freeSolo
        options={difference(options, value)}
        value={value}
        renderInput={renderInput(value)}
        onInputChange={handleInputChange}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        onChange={handleAutoCompleteOnChange(onChange)}
        inputValue={searchText}
        renderTags={renderTags}
        disableClearable
      />
    ),
    [
      renderInput,
      renderTags,
      handleInputChange,
      searchText,
      renderOption,
      getOptionLabel,
      classes.autoRoot,
      disabled,
      options,
      handleAutoCompleteOnChange,
    ]
  );

  return (
    <FormControl fullWidth className={classes.root}>
      <Controller control={control} defaultValue={defaultValue} name={name} render={renderAutoComplete} />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
