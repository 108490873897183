import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useState } from 'react';

import ApparentReason from './ApparentReason';
import FacilitatingFactor from './FacilitatingFactor';
import Improvement from './Improvement';

export default () => {
  const [step, setStep] = useState(0);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Stepper activeStep={step} alternativeLabel>
        <Step key={0} onClick={() => setStep(0)}>
          <StepLabel>Görünür Neden</StepLabel>
        </Step>
        <Step key={1} onClick={() => setStep(1)}>
          <StepLabel>Kolaylaştıran Faktör</StepLabel>
        </Step>
        <Step key={2} onClick={() => setStep(2)}>
          <StepLabel>Kök Neden</StepLabel>
        </Step>
      </Stepper>
      {step === 0 && <ApparentReason />}
      {step === 1 && <FacilitatingFactor />}
      {step === 2 && <Improvement />}
    </Box>
  );
};
