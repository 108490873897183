import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  saveStorehouseDefinition,
  StorehouseDefinitionsResult,
  updateStorehouseDefinition,
} from 'api/hs/definitions/ppe-definitions/storehouse-definitions';
import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import AutoComplete from 'Common/Form/AutoComplete';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { EmployeeSelectionCacheContext } from '../../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { ResourceContext } from '../../../../../context/resource.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: StorehouseDefinitionsResult;
}

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function StorehouseDefinitionsForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { loadEmployees } = useContext(EmployeeSelectionCacheContext);
  const { reload } = useContext(ResourceContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveStorehouseDefinition({
            name: values?.name,
            ppeStorehouseSupervisors: values?.employeeIds?.map(employee => {
              return {
                employeeId: employee,
              };
            }),
          });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          const payload = {
            name: values?.name,
            ppeStorehouseSupervisors: values?.employeeIds?.map((employee: any) => {
              return {
                employeeId: employee,
                storehouseId: data?.id,
              };
            }),
          };
          await updateStorehouseDefinition({ id: data.id, ...payload });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
        await reload('storeHouse');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, reload]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    if (data) {
      await defer();
      await loadEmployees(...data.ppeStorehouseSupervisors?.map((employee: string) => employee.employeeId));
      setValue(
        'employeeIds',
        data?.ppeStorehouseSupervisors?.reduce((acc, item) => [...acc, item?.employeeId], [])
      );
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }

      trigger();
    } else {
      reset({
        label: '' as any,
        employeeIds: [],
      });
    }
  }, [data, setValue, trigger, open, reset]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Depo ve Sorumlu Düzenleme' : 'Depo ve Sorumlu Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required type="text" name="name" label="Depo Adı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <AutoComplete
                  name="employeeIds"
                  label="Sorumlu Personelleri Seç"
                  optionFetcher={employeeOptionFetcher}
                  labelFetcher={employeeLabelFetcher}
                />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
