import React, { useState } from 'react';

import {
  ClassificationDefinitionResult,
  getAdminClassificationDefinitions,
} from 'api/hs/definitions/ppe-definitions/classification-definitions';
import { useFetch } from 'utils';

import Classification from './classification';
import { ClassificationDefinitionsContext } from './classification-definitions.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<ClassificationDefinitionResult>(null);

  const { data, fetch } = useFetch(() => getAdminClassificationDefinitions(), [], { setLoading });

  return (
    <ClassificationDefinitionsContext.Provider
      value={{
        classificationDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Classification />
    </ClassificationDefinitionsContext.Provider>
  );
}
