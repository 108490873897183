import { tryCatch } from '@thalesrc/js-utils';

import { dateSort } from '../../../../utils';
import HSApi from '../../HSApi';
import { RestReportFileUploadResult } from './rest-report-file-upload.result';

export async function getRestReportFileUpload(employeeId: string): Promise<RestReportFileUploadResult[]> {
  const [error, res] = await tryCatch(HSApi.get<RestReportFileUploadResult[]>(`/rest-reports/imported-files?employeeId=${employeeId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return dateSort(res.data);
}

export async function deleteRestReportFileUpload(id: RestReportFileUploadResult['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/rest-reports/imported-files/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
