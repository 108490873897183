import { createContext } from 'react';

import { IbysTrainingsModel } from 'api/hs/trainings/ibys-trainings';

export interface MinistryListContextType {
  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  pinCodeDialogOpened: boolean;

  setPinCodeDialogOpened(opened: boolean): void;

  selectedData: IbysTrainingsModel;

  setSelectedData(data: IbysTrainingsModel): void;
}

export const MinistryListContext = createContext<MinistryListContextType>(null);
