import React, { useCallback, useContext, useState } from 'react';

import Button from 'Common/Button';
import Dialog from 'Common/Dialog';
import { AlertContext } from 'context/alert.context';

import { WorkEquipmentLayoutControllerService } from '../../../api/client';
import ListForm from './ListForm';
import { WorkEquipmentLayoutNewFormContext } from './work-equipment-layout-new-form.context';

interface Props {
  open: boolean;
  onClose(open: boolean): void;
}

interface WorkEquipmentLayoutFormData {
  id: string;
  workEquipmentDefinitionId: string;
  layoutPartId: string;
  layoutDescription: string;
  qrcodeStr: string;
  serialNumber: string;
  trackingNumber: string;
}

export default function PpeStocksIndex({ open, onClose }: Props) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [workEquipmentLayoutData, setWorkEquipmentLayoutData] = useState<WorkEquipmentLayoutFormData[]>([]);
  const [loading, setLoading] = useState(true);
  const { showMessage } = useContext(AlertContext);

  const onCloseAll = useCallback(() => {
    onClose(false);
    setWorkEquipmentLayoutData([]);
  }, [setWorkEquipmentLayoutData, onClose]);

  const onSave = useCallback(async () => {
    try {
      await WorkEquipmentLayoutControllerService.saveAllWorkEquipmentLayouts(
        workEquipmentLayoutData?.map(workEquipmentLayout => {
          return {
            workEquipmentDefinitionId: workEquipmentLayout?.workEquipmentDefinitionId,
            layoutPartId: workEquipmentLayout?.layoutPartId,
            layoutDescription: workEquipmentLayout?.layoutDescription,
            qrcodeStr: workEquipmentLayout?.qrcodeStr,
            serialNumber: workEquipmentLayout?.serialNumber,
            trackingNumber: +workEquipmentLayout?.trackingNumber,
          };
        })
      );
      showMessage('Ekleme işlemi başaralı.', 'success', 'info');
      onClose(true);
      setWorkEquipmentLayoutData([]);
    } catch (e) {
      showMessage('Ekleme işlemi başarısız.', 'error', 'info');
      onClose(false);
      setWorkEquipmentLayoutData([]);
    }
  }, [showMessage, onClose, setWorkEquipmentLayoutData, workEquipmentLayoutData]);

  return (
    <Dialog
      actions={
        <>
          {' '}
          <Button onClick={onCloseAll.bind(null, false)} color="inherit" variant="contained">
            {' '}
            Vazgeç{' '}
          </Button>{' '}
          <Button onClick={onSave} disabled={workEquipmentLayoutData.length <= 0} color="secondary" variant="contained">
            {' '}
            Listeyi Kaydet{' '}
          </Button>{' '}
        </>
      }
      width="md"
      color="secondary"
      title="Yeni İş Ekipman İşlemi"
      opened={open}
      onClose={onCloseAll}>
      <WorkEquipmentLayoutNewFormContext.Provider
        value={{
          newDialogOpened,
          setNewDialogOpened,
          workEquipmentLayoutData,
          setWorkEquipmentLayoutData,
          setLoading,
          loading,
        }}>
        <ListForm />
      </WorkEquipmentLayoutNewFormContext.Provider>
    </Dialog>
  );
}
