import { createContext } from 'react';

import { Medicine } from './medicine.model';

export interface MedicineCacheContextType {
  medicines: Medicine[];
  labels: { [key: string]: string };
  searchMedicines(keyword: string): Promise<void>;
  fetchMedicineDetails(...ids: Medicine['id'][]): Promise<void>;
  favourites: Medicine['id'][];
  addToFavourites(id: Medicine['id']): Promise<void>;
  removeFromFavourites(id: Medicine['id']): Promise<void>;
  favouritesDialogOpened: boolean;
  openFavouritesDialog(currentValue: Medicine['id']): Promise<false | Medicine['id']>;
  closeFavouritesDialog(value: false | Medicine['id']): void;
  currentValueForDialogForm: Medicine['id'];
}

export const MedicineCacheContext = createContext<MedicineCacheContextType>(null);
