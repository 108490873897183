import { Grid, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext } from 'react';

import { WorkEquipmentPeriodicControlContext } from '../../work-equipment-periodic-control.context';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default function InfoWorkEquipmentPeriodicControl() {
  const { WorkEquipmentDefinitionData } = useContext(WorkEquipmentPeriodicControlContext);
  const classes = useStyles();
  return (
    <>
      <Paper className="m-1" elevation={2}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={3} lg={3} xs={12}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <img
                    src={`/saglik-gozetimi/public/images/work_equipment/${WorkEquipmentDefinitionData?.imagePath}`}
                    alt={WorkEquipmentDefinitionData.definitionName}
                    width="250"
                    height="250"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} md={9} lg={9} xs={12}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Tanımı
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.definitionName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      İş Ekipman Kategorisi Durumu
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.typeName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Bulunduğu Şirket
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.companyName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Markası
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.brand}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Modeli
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.model}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Barkod
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.barcode}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                      Bulunduğu Adet
                    </TableCell>
                    <TableCell width="50%" className={classes.textColor2} align="left">
                      {WorkEquipmentDefinitionData?.count}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
