import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { PHONE_MASK } from 'model';
import { email } from 'validators';

export default function PersonalInformationForm() {
  const { bloodTypes, bloodDonations, maritialStatuses, educationStatuses } = useContext(SelectOptionsContext);

  return (
    <Grid container spacing={3}>
      <Grid item md={4}>
        <Select name="bloodType" options={bloodTypes} label="Kan Grubu" />
      </Grid>
      <Grid item md={4}>
        <Select name="bloodDonation" options={bloodDonations} label="Kan Bağışı" />
      </Grid>
      <Grid item md={4}>
        <TextField name="birthPlace" label="Doğum Yeri" />
      </Grid>
      <Grid item md={4}>
        <Select name="maritalStatus" options={maritialStatuses} label="Medeni Durum" />
      </Grid>
      <Grid item md={4}>
        <Select name="educationalStatus" options={educationStatuses} label="Öğrenim Durumu" />
      </Grid>
      <Grid item md={4}>
        <TextField name="profession" label="Mesleği" />
      </Grid>
      <Grid item md={6}>
        <TextField name="gsmNumber" label="Telefon" mask={PHONE_MASK} maskError="Hatalı telefon numarası" />
      </Grid>
      <Grid item md={6}>
        <TextField name="email" label="E-mail" rules={{ validate: { email } }} />
      </Grid>
      <Grid item md={12}>
        <TextField name="address" label="Adres" rows={2} variant="outlined" shrinkAlways />
      </Grid>
      <Grid item md={12}>
        <TextField name="generalDescription" label="Not" rows={2} variant="outlined" shrinkAlways />
      </Grid>
    </Grid>
  );
}
