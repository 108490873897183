/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TrainerDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    nid?: string;
    name?: string;
    lastName?: string;
    title?: string;
    isEmployee?: boolean;
    employeeId?: string;
    firmId?: string;
    isInternal?: boolean;
    trainerTypeDefinition?: TrainerDto.trainerTypeDefinition;
}

export namespace TrainerDto {

    export enum trainerTypeDefinition {
        WORKPLACE_PHYSICIAN = 'WORKPLACE_PHYSICIAN',
        JOB_SECURITY_SPECIALIST = 'JOB_SECURITY_SPECIALIST',
        OTHER = 'OTHER',
    }


}
