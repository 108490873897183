import { Paper } from '@material-ui/core';
import React from 'react';

import { TodoItemControllerService } from '../../api/client';
import { dateSort, useFetch } from '../../utils';
import Create from './Create';
import Header from './Header';
import List from './List';

export default () => {
  const { data, fetch } = useFetch(() => TodoItemControllerService.getAllTodoItems(), []);

  return (
    <Paper elevation={3}>
      <Header />
      <List fetch={fetch} items={dateSort(data)} />
      <Create fetch={fetch} />
    </Paper>
  );
};
