import { createContext } from 'react';

import { ProfessionalBackground } from 'model';

export interface ProfessionalBackgroundContextType {
  backgrounds: ProfessionalBackground[];
  dialogOpened: boolean;
  setDialogOpened(opened: boolean): void;
  selectedBackground: ProfessionalBackground;
  setSelectedBackground(background: ProfessionalBackground): void;
  refresh(): Promise<void>;
}

export const ProfessionalBackgroundContext = createContext<ProfessionalBackgroundContextType>(null);
