import React, { useCallback, useContext } from 'react';

import { MinistryListContext } from '../ministry-list-context';
import EditForm from './EditForm';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, setSelectedData } = useContext(MinistryListContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      setEditDialogOpened(false);
      setSelectedData(null);
    },
    [setEditDialogOpened, setSelectedData]
  );

  return <EditForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
