import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

import { PositionModel } from 'model/definitions/workplace-definition/position.model';

import PositionEditGeneralSettingsForm from './PositionEditGeneralSettingsForm';
import PositionEditPhysicalWorkConditionForm from './PositionEditPhysicalWorkConditionForm';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '66.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  buttonPanding: {
    marginTop: 10,
  },
  accordionPanding: {
    padding: 11,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the position is created/updated
   */
  onClose(success: boolean): void;

  data: PositionModel;
}

export default function CompanyEdit({ open, onClose, data }: Props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 700 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className="width-100" variant="h6">
          {data?.id ? 'Görev / Pozisyon Düzenleme' : 'Görev / Pozisyon Ekle'}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose.bind(null, false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Accordion className={classes.accordionPanding} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>Genel Ayarlar</Typography>
          </AccordionSummary>
          <PositionEditGeneralSettingsForm<PositionModel> open={open} onClose={onClose} data={data} />
        </Accordion>
        <Accordion className={classes.accordionPanding} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.heading}>Çalışma Ortamı Etkenleri</Typography>
          </AccordionSummary>
          <PositionEditPhysicalWorkConditionForm<PositionModel> open={open} onClose={onClose} data={data} />
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
