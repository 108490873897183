import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { getUserById } from '../../users';
import { VitalSignPayload } from './vital-sign.payload';
import { VitalSignResult } from './vital-sign.result';

export async function getVitalSign(employeeId: string, examinationId?: string): Promise<VitalSignResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<VitalSignResult[]>(`/vital-signs?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<VitalSignResult[]>(`/vital-signs?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return await asyncMap(res.data, async item => ({
    ...item,
    userData: item?.createdBy ? await getUserById(item?.createdBy) : '',
  }));
}

export async function saveVitalSign(body: VitalSignPayload): Promise<VitalSignResult> {
  const [err, res] = await tryCatch(HSApi.post('/vital-signs', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateVitalSign({ id, ...body }: MakeRequired<Partial<VitalSignResult>, 'id'>): Promise<VitalSignResult> {
  const [error, res] = await tryCatch(HSApi.put<VitalSignResult>(`/vital-signs/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteVitalSign(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/vital-signs/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
