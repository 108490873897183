import React, { useState } from 'react';

import { CommitteeMeetingsContentResult } from '../../api/hs/committee/committee-meetings';
import { CommitteeMeetingsContext } from './committee-meetings.context';
import ListAdd from './ListAdd';

export default function PpeStocksIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<CommitteeMeetingsContentResult>(null);

  return (
    <CommitteeMeetingsContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <ListAdd />
    </CommitteeMeetingsContext.Provider>
  );
}
