import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAllergy, updateAllergy } from 'api/hs/definitions/health-definitions/allergy';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { AllergyModel } from 'model/definitions/health-definitions/allergy.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the allergy is created/updated
   */
  onClose(success: boolean): void;

  data: AllergyModel;
}

export default function AllergyEdit({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<AllergyModel>
      open={open}
      onClose={onClose}
      onSave={saveAllergy}
      onUpdate={updateAllergy}
      data={data}
      resourceReloadName="allergies"
      createTitle="Alerji Ekleme"
      updateTitle="Alerji Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="allergyName" label="Alerji Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
