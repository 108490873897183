import React, { useCallback } from 'react';

import { postOccupationalSafetyRegistriesList } from '../../api/hs/occupational-safety-registries /occupational-safety-registries-list';
import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from '../../Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';
import { COLUMNS } from './columns';
import ListTable from './ListTableData';
import Toolbar from './Toolbar';

const Index = () => {
  const fetch = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return postOccupationalSafetyRegistriesList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  return (
    <div>
      <HSTableAndPaginationAndTableContextProvider columns={COLUMNS} fetcher={fetch}>
        <Toolbar />
        <ListTable />
      </HSTableAndPaginationAndTableContextProvider>
    </div>
  );
};

export default Index;
