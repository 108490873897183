import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { ChronicDisease } from './chronic-disease.model';
import { SaveChronicDiseasePayload, UpdateChronicDiseasePayload } from './chronic-disease.payload';
import { ChronicDiseaseResult } from './chronic-disease.result';

export async function getChronicDisease(employeeId: string, examinationId?: string): Promise<ChronicDisease> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ChronicDiseaseResult>(`/chronic-diseases?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ChronicDiseaseResult>(`/chronic-diseases?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data ? ChronicDisease.fromServerData(res.data) : new ChronicDisease();
}

export async function getChronicDiseaseData(employeeId: string, examinationId?: string) {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ChronicDiseaseResult>(`/chronic-diseases?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ChronicDiseaseResult>(`/chronic-diseases?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return !!res.data;
}

export async function saveChronicDisease(payload: SaveChronicDiseasePayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.post(`/chronic-diseases`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error;
  }

  return res;
}

export async function updateChronicDisease(id: string, payload: UpdateChronicDiseasePayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.put(`/chronic-diseases/${id}`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error;
  }

  return res;
}
