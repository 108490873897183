import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Theme, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';

import Button from 'Common/Button';

import { SystemCheckContext } from '../../system-check.context';

export default function ListSystemCheck() {
  const { loading, systemCheck, setEditDialogOpened } = useContext(SystemCheckContext);
  const largerThanMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const buttonContainerClassName = useMemo(() => (largerThanMd ? 'pl-4 text-right' : 'pl-4'), [largerThanMd]);
  const openEditDialog = useCallback(() => setEditDialogOpened(true), [setEditDialogOpened]);
  return (
    <>
      <Grid container>
        <Grid item sm={12} md={12} lg={12}>
          {!!systemCheck && !loading && (
            <>
              <TableContainer className="ml-2">
                <Table size="small">
                  <TableBody>
                    {systemCheck.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: '30%' }} align="left">
                          {item.bodySystemsCheckDefinitionName}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            className="text-white text-bold"
                            style={{ backgroundColor: item.color, width: '15%' }}
                            variant="contained"
                            disabled>
                            {item.valueText}
                          </Button>{' '}
                          {`   `}
                          {item.description}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          <div style={{ marginTop: '10px' }} className={buttonContainerClassName}>
            <Button onClick={openEditDialog} color="secondary" variant="contained">
              Sistem Sorgulama Düzenle
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
