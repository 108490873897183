import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';

export async function updateFirmNearMissTypeDefinition({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/near-miss-type-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateNearMissTypeDefinition({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`near-miss-type-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
