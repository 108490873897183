import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import {
  getPeriodicExaminationConfigurations,
  PeriodicExaminationConfigurationsModel,
  PeriodicExaminationConfigurationsResult,
} from 'api/hs/examination/periodic-examination-configurations';
import { useAsyncEffect } from 'utils';

import { PeriodicExaminationConfigurationContext } from './periodic-examination-configurations.context';
import PeriodicExaminationConfiguration from './PeriodicExaminationConfiguration';

export default function Index() {
  const [periodicExaminationConfigurations, setPeriodicExaminationConfigurations] = useState<PeriodicExaminationConfigurationsResult>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PeriodicExaminationConfigurationsModel>(null);
  const { employeeId } = useParams<{ employeeId: string }>();

  const fetch = useCallback(async () => {
    const result = await getPeriodicExaminationConfigurations(employeeId);
    setPeriodicExaminationConfigurations(result);
  }, [employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <PeriodicExaminationConfigurationContext.Provider
      value={{
        periodicExaminationConfigurations,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
      }}>
      <PeriodicExaminationConfiguration />
    </PeriodicExaminationConfigurationContext.Provider>
  );
}
