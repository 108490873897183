import React, { PropsWithChildren, useCallback, useState } from 'react';

import { getUser, getUserProfile, updateUserProfile } from 'api/hs/user';
import { useAsyncEffect, useFetch } from 'utils';

import { USER_NOT_CHECKED } from './user-not-checked.model';
import { UserProfile } from './user-profile.model';
import { UserContext, UserContextType } from './user.context';
import { User } from './user.model';

export default function UserContextProvider({ children }: PropsWithChildren<{}>) {
  const [user, setUser] = useState<User>();
  const { data: profile, fetch: reloadProfile } = useFetch(
    async () => {
      if (!user) {
        return null;
      }

      return new UserProfile(await getUserProfile());
    },
    null,
    { deps: [user], reloadDeps: [user] }
  );

  const refreshUser = useCallback(async () => {
    const userData = await getUser();
    setUser(userData);
  }, []);

  const updateProfile: UserContextType['updateProfile'] = useCallback(
    async payload => {
      await updateUserProfile(payload);
      await Promise.all([refreshUser(), reloadProfile()]);
    },
    [reloadProfile, refreshUser]
  );

  useAsyncEffect(async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        setUser(null);
      }
  }, []);

  return (
    <UserContext.Provider value={{ user: user as User, setUser, profile, updateProfile, refreshUser }}>{children}</UserContext.Provider>
  );
}
