import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteOpinion, getOpinion, OpinionResult } from 'api/hs/definitions/health-definitions/opinion';
import OpinionEditDialog from 'Common/Forms/Definitions/HealthDefinition/Opinion/OpinionEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Opinion() {
  return (
    <BaseDefinitionPage<OpinionResult>
      listLabelPropName="opinion"
      fetchData={getOpinion}
      FormDialog={OpinionEditDialog}
      onDelete={deleteOpinion}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
