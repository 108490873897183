import React from 'react';

import ActionsList from './List';
interface Props {
  fetcher: () => Promise<any>;
  referenceId?: string;
  nearMissId?: string;
  actionReferenceValue?: string;
  baseFetcher?: () => Promise<any>;
}
export default function Index({ fetcher, referenceId, nearMissId, actionReferenceValue, baseFetcher }: Props) {
  return (
    <>
      <ActionsList
        baseFetcher={baseFetcher}
        fetcher={fetcher}
        referenceId={referenceId}
        nearMissId={nearMissId}
        actionReferenceValue={actionReferenceValue}
      />
    </>
  );
}
