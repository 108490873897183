import { Drawer, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, makeStyles } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { getEmployee, getEmployeeWarningFlags, updateEmployee } from 'api/hs/employee';

import { getAllergies, getChronicDisease, getChronicDiseaseData, getDisabilities } from '../../../../../api/hs/examination';
import { getPeriodicExaminationConfigurations } from '../../../../../api/hs/examination/periodic-examination-configurations';
import { getPregnancies } from '../../../../../api/hs/examination/pregnancies';
import { UserContext } from '../../../../../context/user';
import { Employee as EmployeeModel } from '../../../../../model';
import { UserRole } from '../../../../../model/user-role.enum';
import { useAsyncEffect } from '../../../../../utils';
import Avatar from '../../../Avatar';
import CommentForm from '../../../EmployeeInfoBox/CommentForm';
import Flags from '../../../EmployeeInfoBox/Flags';
import PersonalInfo from '../../../EmployeeInfoBox/PersonalInfo';
import ProfessionalInfo from '../../../EmployeeInfoBox/ProfessionalInfo';
import { EmployeeListContext } from '../../employee-list.context';

const useStyles = makeStyles(theme => ({
  subHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    minWidth: 325,
  },
}));

export default function DetailDrawer() {
  const classes = useStyles();
  const { detailsDrawerOpened, setDetailsDrawerOpened, selectedEmployee, reload } = useContext(EmployeeListContext);
  const { user } = useContext(UserContext);
  const [employee, setEmployee] = useState<EmployeeModel>(new EmployeeModel());

  useAsyncEffect(async () => {
    if (selectedEmployee?.id) await fetch();
  }, [selectedEmployee?.id]).then(noop);

  const fetch = useCallback(async () => {
    let _employee = { ...employee };

    const roleFunctionMap = {
      [UserRole.PHYSICIAN]: {
        allergiesId: () =>
          getAllergies(selectedEmployee?.id).then(allergy =>
            allergy?.allergyDetails?.map(({ allergyDefinitionId }) => allergyDefinitionId)
          ),
        chronicDiseaseIcd10s: () =>
          getChronicDisease(selectedEmployee?.id).then(chronicDisease => chronicDisease.icd10s.map(icd10 => icd10)),
        chronicDiseaseData: () => getChronicDiseaseData(selectedEmployee?.id).then(chronicDisease => chronicDisease),
        examinationFrequencyInMonth: () =>
          getPeriodicExaminationConfigurations(selectedEmployee?.id).then(
            periyodicExaminationData => periyodicExaminationData?.examinationFrequencyInMonth
          ),
        nextExaminationDate: () =>
          getPeriodicExaminationConfigurations(selectedEmployee?.id).then(
            periyodicExaminationData => periyodicExaminationData?.nextExaminationDate
          ),
        hasDisability: () =>
          getDisabilities(selectedEmployee?.id).then(disabilities => (disabilities ? !!disabilities.disabilityDetails.length : false)),
        pregnancyHistory: () => getPregnancies(selectedEmployee?.id).then(pregnancies => pregnancies),
        warningLabels: () => getEmployeeWarningFlags(selectedEmployee?.id).then(warningLabels => warningLabels),
      },
      [UserRole.OSS]: {
        hasDisability: () =>
          getDisabilities(selectedEmployee?.id).then(disabilities => (disabilities ? !!disabilities.disabilityDetails.length : false)),
      },
    };
    roleFunctionMap[UserRole.NURSE] = roleFunctionMap[UserRole.PHYSICIAN];

    const functionsMap = new Map<string, Promise<any>>();

    if (user.roles?.length > 0) {
      user.roles
        .filter(role => role in roleFunctionMap)
        .forEach(role =>
          Object.keys(roleFunctionMap[role])
            .filter(functionKey => !(functionKey in functionsMap))
            .forEach(functionKey => {
              functionsMap.set(functionKey, roleFunctionMap[role][functionKey]);
            })
        );
    }

    for (const [key, fn] of functionsMap.entries()) {
      // @ts-ignore
      _employee = { ..._employee, [key]: await (fn as () => void)() };
    }

    _employee = { ..._employee, ...(await getEmployee(selectedEmployee?.id)) };
    setEmployee(_employee);
  }, [employee, selectedEmployee?.id, user]);

  const closeDrawer = useCallback(() => {
    setDetailsDrawerOpened(false);
  }, [setDetailsDrawerOpened]);

  const saveComment = useCallback(
    async value => {
      await updateEmployee({ id: selectedEmployee.id, generalDescription: value });
      reload();
    },
    [selectedEmployee, reload]
  );

  const TABS = [
    {
      key: 1,
      Component: <Flags key="employee" employee={employee} />,
      visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      key: 2,
      Component: <ProfessionalInfo key={'employeeProfessionalInfo'} employee={selectedEmployee} />,
      visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      key: 3,
      Component: <PersonalInfo key={'personalInfo'} employee={selectedEmployee} />,
      visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    },
    {
      key: 4,
      Component: <CommentForm key={'commentForm'} value={selectedEmployee?.generalDescription} onSubmit={saveComment} />,
      visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    },
  ];

  return (
    <Drawer anchor="left" open={detailsDrawerOpened} onClose={closeDrawer}>
      <List
        component="nav"
        className={classes.list}
        subheader={
          <ListSubheader component="div" className={`${classes.subHeader} text-white`}>
            <ListItem className="py-2">
              <ListItemAvatar>
                <Avatar url={selectedEmployee?.avatarPath} />
              </ListItemAvatar>
              <ListItemText primary={selectedEmployee?.name} />
            </ListItem>
          </ListSubheader>
        }>
        {TABS.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r))).map(({ key, Component, visibleTo }) => Component)}
      </List>
    </Drawer>
  );
}
