/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisDetailDto } from '../models/AnalysisDetailDto';
import type { AnalysisDto } from '../models/AnalysisDto';
import type { AnalysisParameterUnitDefinitionDto } from '../models/AnalysisParameterUnitDefinitionDto';
import type { AnalysisRequest } from '../models/AnalysisRequest';
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { PageAnalysisDto } from '../models/PageAnalysisDto';
import type { PageAnalysisView } from '../models/PageAnalysisView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AnalysisControllerService {

    /**
     * @param id
     * @returns AnalysisDto OK
     * @throws ApiError
     */
    public static getAnalysisById(
        id: string,
    ): CancelablePromise<AnalysisDto> {
        return __request({
            method: 'GET',
            path: `/analysis/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns AnalysisDto OK
     * @throws ApiError
     */
    public static updateAnalysis(
        id: string,
        requestBody: AnalysisDto,
    ): CancelablePromise<AnalysisDto> {
        return __request({
            method: 'PUT',
            path: `/analysis/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllAnalysis(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/analysis/${id}`,
        });
    }

    /**
     * @param id
     * @returns AnalysisDetailDto OK
     * @throws ApiError
     */
    public static getAllAnalysisDetails1(
        id: string,
    ): CancelablePromise<Array<AnalysisDetailDto>> {
        return __request({
            method: 'GET',
            path: `/analysis/${id}/analysis-details`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns AnalysisDetailDto OK
     * @throws ApiError
     */
    public static saveAnalysisDetail(
        id: string,
        requestBody: Array<AnalysisDetailDto>,
    ): CancelablePromise<Array<AnalysisDetailDto>> {
        return __request({
            method: 'PUT',
            path: `/analysis/${id}/analysis-details`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getAnalysisFiles1(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/analysis/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static analysisFileImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/analysis/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageAnalysisView OK
     * @throws ApiError
     */
    public static getAnalysisViews(
        requestBody: AnalysisRequest,
    ): CancelablePromise<PageAnalysisView> {
        return __request({
            method: 'POST',
            path: `/analysis/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageAnalysisDto OK
     * @throws ApiError
     */
    public static getAnalysisListFiltered(
        requestBody: AnalysisRequest,
    ): CancelablePromise<PageAnalysisDto> {
        return __request({
            method: 'POST',
            path: `/analysis/pages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AnalysisDto OK
     * @throws ApiError
     */
    public static saveAnalysisDto(
        requestBody: Array<AnalysisDto>,
    ): CancelablePromise<Array<AnalysisDto>> {
        return __request({
            method: 'POST',
            path: `/analysis/list`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param examinationId
     * @returns AnalysisDto OK
     * @throws ApiError
     */
    public static getAllAnalysis(
        employeeId?: string,
        examinationId?: string,
    ): CancelablePromise<Array<AnalysisDto>> {
        return __request({
            method: 'GET',
            path: `/analysis`,
            query: {
                'employeeId': employeeId,
                'examinationId': examinationId,
            },
        });
    }

    /**
     * @param employeeId
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getRestReportFileByEmployeeId(
        employeeId: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/analysis/imported-files`,
            query: {
                'employeeId': employeeId,
            },
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getRestReportFile2(
        id: string,
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'GET',
            path: `/analysis/imported-files/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static analysisFileDelete1(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/analysis/imported-files/${id}`,
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static uploadAnalysisFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/analysis/imported-files/${id}/raw`,
        });
    }

    /**
     * @returns AnalysisParameterUnitDefinitionDto OK
     * @throws ApiError
     */
    public static getAllAnalysisParameterUnitDefinition(): CancelablePromise<Array<AnalysisParameterUnitDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/analysis-parameter-unit-definitions`,
        });
    }

}