import React from 'react';

import Add from './add';
import List from './list';

export default function Index() {
  return (
    <>
      <List />
      <Add />
    </>
  );
}
