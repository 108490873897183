import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../../HSApi';
import { MedicinePayload } from './Medicine.payload';
import { MedicineResult } from './Medicine.result';

export async function searchMedicine(medicine: string, page = 0, size = 10): Promise<MedicineResult> {
  const res = await HSApi.get<MedicineResult>(`/medicine-definitions?nameOrProducer=${medicine}&page=${page}&size=${size}`);

  return res.data;
}

export async function getMedicinePage(page = 0, size = 10): Promise<MedicineResult> {
  const res = await HSApi.get<MedicineResult>(`/medicine-definitions?page=${page}&size=${size}`);

  return res.data;
}
export async function saveMedicine(body: MedicinePayload): Promise<MedicineResult> {
  const [err, res] = await tryCatch(HSApi.post('/medicine-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
