import { Avatar } from '@material-ui/core';
import React from 'react';

import { PpeAssigmentEmployeeResult } from 'api/hs/TransactionAndLists/Employee/PpeList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

export const CELL: TableColumn<PpeAssigmentEmployeeResult>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    defaultVisibility: true,
    render(row): JSX.Element {
      return <Avatar src={`/saglik-gozetimi/public/images/ppe/${row?.imagePath}`} />;
    },
  },
  {
    id: 'label',
    defaultVisibility: true,
    label: 'KKD Tipi',
    sortable: false,
    width: '10%',
  },
  {
    id: 'classificationName',
    defaultVisibility: true,
    label: 'KKD Ana Grup',
    sortable: false,
    width: '10%',
  },
  {
    id: 'ppeTypeName',
    defaultVisibility: true,
    label: 'KKD Alt Grup',
    sortable: false,
    width: '10%',
  },
  {
    id: 'brand',
    defaultVisibility: true,
    label: 'Markası',
    sortable: false,
    width: '10%',
  },
  {
    id: 'model',
    defaultVisibility: true,
    label: 'Modeli',
    sortable: false,
    width: '10%',
  },
  {
    id: 'ppeEnStandard',
    defaultVisibility: true,
    label: 'EN Standardı',
    sortable: false,
    width: '10%',
  },
];
