import React from 'react';

import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { OccupationalSafetyRegistriesContentResult } from '../../api/hs/occupational-safety-registries /occupational-safety-registries-list';
import Link from '../../Common/Link';
import { useDateFormat } from '../../utils';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}
export const COLUMNS: TableColumn<OccupationalSafetyRegistriesContentResult>[] = [
  {
    id: 'companyName',
    width: '10%',
    label: 'Şirket',
    defaultVisibility: true,
    sortable: true,
    render(row: OccupationalSafetyRegistriesContentResult): JSX.Element {
      return <Link to={`/occupational-safety-registries/query/companies/${row.companyId}`}>{row.companyName}</Link>;
    },
  },
  {
    id: 'pageNumber',
    width: '5%',
    label: 'Defter no',
    defaultVisibility: true,
    sortable: true,
  },

  {
    id: 'label',
    width: '15%',
    label: 'Defter Adı',
    defaultVisibility: true,
    sortable: true,
    render(row: OccupationalSafetyRegistriesContentResult): JSX.Element {
      return <Link to={`/occupational-safety-registries/query/companies/${row.companyId}/books/${row.registryId}/`}>{row.label}</Link>;
    },
  },
  {
    id: 'description',
    width: '15%',
    label: 'Tespit',
    defaultVisibility: true,
    sortable: true,
    render(row: OccupationalSafetyRegistriesContentResult): JSX.Element {
      return (
        <Link to={`/occupational-safety-registries/query/companies/${row.companyId}/books/${row.registryId}/detections/${row.pageId}`}>
          {row.description}
        </Link>
      );
    },
  },
  {
    id: 'registryCreatedDate',
    width: '5%',
    label: 'Defter Oluşturulma Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: OccupationalSafetyRegistriesContentResult): JSX.Element {
      return <DateField date={new Date(row.registryCreatedDate)} />;
    },
  },
  {
    id: 'createdDate',
    width: '5%',
    label: 'Oluşturulma Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: OccupationalSafetyRegistriesContentResult): JSX.Element {
      return <DateField date={new Date(row?.createdDate)} />;
    },
  },
  {
    id: 'userName',
    width: '10%',
    sortable: true,
    label: 'Oluşturan',
    defaultVisibility: true,
  },
];
