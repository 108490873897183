import { Chip, Divider, Icon, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { EnumContext } from 'context/enum.context';

import { dateDisplayFormat } from '../../../../../../utils';
import { PregnanciesContextContext } from '../../pregnancies.context';
import Action from './Action';
import EditDialog from './EditDialog';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: 'lightslategray',
  },
  descriptionName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

export default function PregnanciesList() {
  const classes = useStyles();
  const { loading, pregnancies } = useContext(PregnanciesContextContext);
  const { pregnancyStates, bloodTypes } = useContext(EnumContext);

  return (
    <IndicatorWrapper loading={loading} data={pregnancies} errorMessage="Gösterilecek Gebelik Bulunamadı">
      <List key="pregnancies">
        {pregnancies.map((item, index) => (
          <Fragment key={index}>
            <ListItem key={index}>
              <ListItemText
                key={index}
                primary={
                  <Typography component="div">
                    <Icon style={{ color: 'gray' }} className="icon-human-pregnant" />
                    <span className={classes.descriptionName}>
                      {findByKey(pregnancyStates, 'id', item?.pregnancyStateDefinitionId)?.label}
                    </span>
                    {` `}
                    {item.bloodTypeIncompatibility ? (
                      <Chip style={{ backgroundColor: '#df4055', color: '#fff' }} label="Rh Uyuşmazlığı" />
                    ) : null}
                    {` `}
                    {item?.multipleBirth ? <Chip label="Çoklu Gebelik" color="primary" /> : null}
                  </Typography>
                }
                secondary={
                  <Typography>
                    <span className={classes.textColor}>Son Adet Tarihi : {dateDisplayFormat(item?.lastMenstruationDate)}</span>
                    <br />
                    <span className={classes.textColor}>Tahmini Doğum Tarihi : {dateDisplayFormat(item?.birthDate)}</span>
                    <br />
                    <span className={classes.textColor}>
                      Eşinin Kan Grubu : {findByKey(bloodTypes, 'id', item?.husbandBloodTypeDefinitionId)?.label}
                    </span>
                    <br />
                    <span className={classes.textColor}>Açıklama : {item?.description}</span>
                  </Typography>
                }
              />

              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
