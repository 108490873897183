// malesef yuklenen dosyalarda original name yokmus
export default function getFileName(file: File, data: any) {
  if (file) return file.name;

  if (data && data.importedFile) {
    const name = data.importedFile.name || '';
    return name.substr(name.indexOf('_') + 1, name.length);
  }

  return undefined;
}
