import { createContext } from 'react';

import { ActionsContentResult } from 'api/hs/actions';

export interface ActionContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: ActionsContentResult;

  setSelectedData(data: ActionsContentResult): void;
}

export const ActionContext = createContext<ActionContextType>(null);
