import { findByKey, OpenPromise } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
    addToFavouriteMedicines,
    getFavouriteMedicines,
    getMedicines,
    getMedicinesById,
    removeFromFavouriteMedicines,
    SearchMedicineResultItem,
} from 'api/hs/examination/medicine';
import { useAsyncEffect, useDebouncedCallback, useDialogState, useFetch } from 'utils';

import Favourites from './Favourites';
import { MedicineCacheContext, MedicineCacheContextType } from './medicine.context';
import { Medicine } from './medicine.model';

export default function MedicineCacheContextProvider({ children }: PropsWithChildren<{}>) {
    const { opened, open, close } = useDialogState();
    const [currentValueForDialogForm, setCurrentValueForDialogForm] = useState<Medicine['id']>(null);
    const [dialogPromise, setDialogPromise] = useState(new OpenPromise<false | Medicine['id']>());
    const [medicines, setMedicines] = useState<Medicine[]>([]);
    const [searchCache, setSearchCache] = useState<string[]>([]);
    const searchMedicinesReq = useDebouncedCallback(getMedicines, 50);
    const { data: rawFavourites, fetch: fetchFavourites } = useFetch(getFavouriteMedicines, [], {});
    const getMedicinesByIdReq = useDebouncedCallback(getMedicinesById, 50);

    const favourites: MedicineCacheContextType['favourites'] = useMemo(() => rawFavourites?.map(fav => fav.medicineId), [rawFavourites]);

    const addToFavourites: MedicineCacheContextType['addToFavourites'] = useCallback(
        async id => {
            await addToFavouriteMedicines(id);
            await fetchFavourites();
        },
        [fetchFavourites]
    );

    const removeFromFavourites: MedicineCacheContextType['removeFromFavourites'] = useCallback(
        async id => {
            const entryId = findByKey(rawFavourites, 'medicineId', id).id;

            await removeFromFavouriteMedicines(entryId);
            await fetchFavourites();
        },
        [fetchFavourites, rawFavourites]
    );

    const pushNewMedicines = useCallback(
        (meds: SearchMedicineResultItem[]) => {
            setMedicines([...medicines, ...meds.filter(med => !findByKey(medicines, 'id', med.id)).map(med => new Medicine(med))]);
        },
        [medicines]
    );

    const searchMedicines = useCallback(
        async (keyword: string) => {
            if (searchCache.includes(keyword)) {
                return;
            }

            setSearchCache([...searchCache, keyword]);
            const meds = await searchMedicinesReq(keyword);

            pushNewMedicines(meds.content);
        },
        [searchCache, searchMedicinesReq, pushNewMedicines]
    );

    const fetchMedicineDetails: MedicineCacheContextType['fetchMedicineDetails'] = useCallback(
        async (...ids) => {
            const difference = ids.filter(id => !medicines.some(med => med.id === id));

            if (!difference.length) {
                return;
            }

            const meds = await getMedicinesByIdReq(...difference);

            pushNewMedicines(meds);
        },
        [getMedicinesByIdReq, medicines, pushNewMedicines]
    );

    const labels: MedicineCacheContextType['labels'] = useMemo(
        () => medicines.reduce((acc, { id, label }) => ({ ...acc, [id]: label }), {}),
        [medicines]
    );

    const openFavouritesDialog: MedicineCacheContextType['openFavouritesDialog'] = useCallback(
        current => {
            const promise = new OpenPromise();
            setCurrentValueForDialogForm(current);
            setDialogPromise(promise);
            open();

            return promise;
        },
        [open]
    );

    const closeFavouritesDialog: MedicineCacheContextType['closeFavouritesDialog'] = useCallback(
        value => {
            close();
            dialogPromise.resolve(value);
        },
        [close, dialogPromise]
    );

    const context = useMemo<MedicineCacheContextType>(
        () => ({
            medicines,
            searchMedicines,
            labels,
            favourites,
            addToFavourites,
            removeFromFavourites,
            favouritesDialogOpened: opened,
            openFavouritesDialog,
            closeFavouritesDialog,
            currentValueForDialogForm,
            fetchMedicineDetails,
        }),
        [
            medicines,
            searchMedicines,
            labels,
            favourites,
            addToFavourites,
            removeFromFavourites,
            opened,
            openFavouritesDialog,
            closeFavouritesDialog,
            currentValueForDialogForm,
            fetchMedicineDetails,
        ]
    );

    useAsyncEffect(async () => {
        fetchMedicineDetails(...favourites);
    }, [favourites, fetchMedicineDetails]);

    return (
        <MedicineCacheContext.Provider value={context}>
            {children}
            <Favourites />
        </MedicineCacheContext.Provider>
    );
}
