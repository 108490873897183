import React from 'react';

import { UserRole } from 'model/user-role.enum';

// import Action from './ActionDefiniton';
import BuildingsDefinitions from './Buildings';
import BuildingsFloors from './Buildings/BuildingsFloors';
import BuildingsFloorsParts from './Buildings/BuildingsFloorsParts';
import Company from './CompanyDefinition';
import CompanyUser from './CompanyUser';
import Department from './DepartmentDefinition';
import Exposure from './ExposureDefinition';
import Location from './LocationDefinition';
import Status from './PositionDefinition';
import ShiftDefinition from './ShiftDefinition';
import Station from './StationDefinition';
import Users from './User';

interface WorkPlaceListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'Şirketler',
    Component: Company,
    path: '',
    visibleTo: [UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Kullanıcılar',
    Component: Users,
    path: 'kullanicilar',
    visibleTo: [UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Şirket - Kullanıcı',
    Component: CompanyUser,
    path: 'sirket-kullanicilar',
    visibleTo: [UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Departmanlar',
    Component: Department,
    path: 'departmanlar',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: 'İş İstasyonları',
    Component: Station,
    path: 'is-istasyonlari',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: 'Görevler',
    Component: Status,
    path: 'gorevler',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: 'Vardiya',
    Component: ShiftDefinition,
    path: 'shift',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  // {
  //   label: 'Aksiyonlar',
  //   Component: Action,
  //   path: 'aksiyonlar',
  //   visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.ADMIN, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  // },
  {
    label: 'Maruziyetler',
    Component: Exposure,
    path: 'maruziyetler',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: 'Lokasyon',
    Component: Location,
    path: 'lokasyonlar',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: 'Bina - Kat -  Bölüm Tanımlama',
    Component: BuildingsDefinitions,
    path: 'buildings',
    visibleTo: [UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: undefined,
    Component: BuildingsFloors,
    path: 'buildings/:buildingsId/floors',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
  {
    label: undefined,
    Component: BuildingsFloorsParts,
    path: 'buildings/floors/:floorId/parts',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.OSS],
  },
] as WorkPlaceListItem[];
