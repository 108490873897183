import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useUniqueId } from 'utils';

import DateInput from '../../../../../Common/Form/DateInput';

export type SubmitNewVaccineValues = { vaccine: number; doseCount: string } & { [key: string]: string };

interface Props {
  opened: boolean;
  onClose(): any;
  onSubmit(value: SubmitNewVaccineValues): any;
}

export default function NewVaccine({ opened, onClose, onSubmit }: Props) {
  const formId = useUniqueId('new-vaccine-form');
  const { setValue, reset, trigger, ...methods } = useForm();
  const { vaccines } = useContext(SelectOptionsContext);

  const doseCountValue = +methods.getValues('doseCount');
  const doseCount = Math.max(doseCountValue || 1, 1);

  if (doseCountValue < 1) {
    setValue('doseCount', 1);
  }

  useAsyncEffect(async () => {
    if (!opened) {
      return;
    }

    await defer();
    reset();
    setValue('doseCount', 1);
    trigger('doseCount');
  }, [setValue, reset, trigger, opened]);

  return (
    <Dialog open={opened} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Aşı Planı Oluştur</DialogTitle>
      <DialogContent>
        <FormProvider {...{ setValue, trigger, reset, ...methods }}>
          <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Select name="vaccine" options={vaccines} label="Aşı Tipi" required />
            <TextField name="doseCount" type="number" label="Doz Sayısı" rules={{ min: 1 }} />
            {new Array(doseCount).fill(undefined).map((_, index) => (
              <DateInput key={index} name={`date-${index}`} label={`${index + 1}. Doz`} type="date" required />
            ))}
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right pr-1 pb-2">
        <Button className="mr-2" onClick={onClose}>
          İptal
        </Button>
        <Button className="mr-2" color="primary" variant="contained" form={formId} type="submit">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
}
