/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ResetPasswordDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    resetPasswordType?: ResetPasswordDto.resetPasswordType;
}

export namespace ResetPasswordDto {

    export enum resetPasswordType {
        NEW_USER = 'NEW_USER',
        FORGOT_PASSWORD = 'FORGOT_PASSWORD',
        EMPLOYEE_LOGIN = 'EMPLOYEE_LOGIN',
    }


}
