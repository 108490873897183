import { asyncMap, defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { getPpeStorehouses } from '../../../api/hs/ppe/ppe-detail-stocks';
import { saveOutputSave } from '../../../api/hs/ppe/ppe-outputs';
import { PpeAssigmentEmployeeResult } from '../../../api/hs/TransactionAndLists/Employee/PpeList';
import Button from '../../../Common/Button';
import Dialog from '../../../Common/Dialog';
import { AlertContext } from '../../../context/alert.context';
import { dateToInputFormat, useAsyncEffect } from '../../../utils';
import { PpeNewOutputContext } from './ppe-new-output.context';
import PpeOutputsNewList from './ppe-new-outputs-list-form';

interface Props {
  open: boolean;
  onClose(open: boolean): void;
  data?: PpeAssigmentEmployeeResult[];
}

interface PpeOutputData {
  employeeId: string;
  ppeDefinitionId: string;
  count: string;
  outputDate: string;
  stockId: string;
}

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default function PpeStocksIndex({ open, onClose, data }: Props) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [ppeOutputsData, setPpeOutputsData] = useState<PpeOutputData[]>([]);
  const [loading, setLoading] = useState(true);
  const { showMessage } = useContext(AlertContext);

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      const modifyData = await asyncMap(data, async ppeAssigmentEmployee => ({
        id: guid(),
        employeeId: ppeAssigmentEmployee?.employeeId,
        ppeDefinitionId: ppeAssigmentEmployee?.ppeDefinitionId,
        stockId: (await getPpeStorehouses(ppeAssigmentEmployee?.ppeDefinitionId))[0].stockId,
        count: '1',
        outputDate: dateToInputFormat(new Date()),
        reason: 'DEBIT',
      }));
      setPpeOutputsData(modifyData?.filter(outputData => outputData?.stockId));
    }
  }, [data, setPpeOutputsData]);

  const onCloseAll = useCallback(() => {
    onClose(false);
    setPpeOutputsData([]);
  }, [setPpeOutputsData, onClose]);

  const onSave = useCallback(async () => {
    try {
      await saveOutputSave(
        ppeOutputsData?.map(filtreData => {
          return {
            employeeId: filtreData?.employeeId,
            ppeDefinitionId: filtreData?.ppeDefinitionId,
            count: filtreData?.count,
            outputDate: filtreData?.outputDate,
            stockId: filtreData?.stockId,
            reason: 'DEBIT',
          };
        })
      );
      showMessage('Ekleme işlemi başaralı.', 'success', 'info');
      onClose(true);
      setPpeOutputsData([]);
    } catch (e) {
      showMessage('Ekleme işlemi başarısız.', 'error', 'info');
      onClose(false);
    }
  }, [ppeOutputsData, showMessage, onClose]);

  return (
    <Dialog
      actions={
        <>
          {' '}
          <Button onClick={onCloseAll.bind(null, false)} color="inherit" variant="contained">
            {' '}
            Vazgeç{' '}
          </Button>{' '}
          <Button onClick={onSave} disabled={ppeOutputsData.length <= 0} color="secondary" variant="contained">
            {' '}
            Listeyi Kaydet{' '}
          </Button>{' '}
        </>
      }
      width="md"
      color="secondary"
      title="Yeni Zimmet İşlemi"
      opened={open}
      onClose={onCloseAll}>
      <PpeNewOutputContext.Provider
        value={{
          newDialogOpened,
          setNewDialogOpened,
          ppeOutputsData,
          setPpeOutputsData,
          setLoading,
          loading,
        }}>
        <PpeOutputsNewList />
      </PpeNewOutputContext.Provider>
    </Dialog>
  );
}
