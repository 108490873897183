import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';

import { exportRiskAssessmentHazard } from '../../../../api/hs/risk-assessment';
import { RiskAssessmentsListContext } from '../../risk-assessments-list.context';
import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    marginLeft: '20%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarWorkEquipmentDefinition() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const { rawData, extraArgs } = useContext(HSTableContext);
  const history = useHistory();
  const { riskAssessmentType } = useContext(RiskAssessmentsListContext);
  const routeNewRiskAssessmentForm = useCallback(() => {
    history.push(`/risk/risk-assessments-form`);
  }, [history]);

  const exportFile = useCallback(async () => {
    await exportRiskAssessmentHazard(extraArgs.filters ?? { exposeType: 'EXCEL', riskAssessmentType });

    closePopover();
  }, [closePopover, extraArgs, riskAssessmentType]);

  return (
    <>
      <Toolbar color="secondary">
        <Typography variant="h6" color="inherit">
          Risk Değerlendirme ({rawData?.totalElements})
        </Typography>
        <span className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <IconButton color="inherit" onClick={openPopover}>
            <MoreIcon />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 35, horizontal: -175 }}
            keepMounted
            open={!!anchorEl}
            onClose={closePopover}>
            <List>
              <ListItem onClick={routeNewRiskAssessmentForm} className="cursor-pointer">
                <ListItemIcon>
                  <PostAddIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Yeni Risk Değerlendirme</Typography>
              </ListItem>
              <ListItem onClick={exportFile} className="cursor-pointer">
                <ListItemIcon>
                  <GetAppIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Excel'e Aktar</Typography>
              </ListItem>
            </List>
          </Popover>
          <VisibleColumnMenu />
          <Filters />
        </div>
      </Toolbar>
    </>
  );
}
