import React, { useCallback, useContext } from 'react';

import PreviewForm from 'Common/Forms/PreviewForm';

import { ReferralsContextContext } from '../../referrals.context';

export default function EmailDialog() {
  const { reload, newPreviewDialog, setPreviewDialogOpened, previewData } = useContext(ReferralsContextContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setPreviewDialogOpened(false);
    },
    [reload, setPreviewDialogOpened]
  );

  return <PreviewForm open={newPreviewDialog} onClose={closeDialog} htmlData={previewData} />;
}
