import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { ActionPayload } from './action.payload';
import { ActionsResult } from './action.result';
import { ActionsContentResult } from './actions-content.result';
import { ActionsFilters, GetActionsListPayload } from './get-actions.payload';

export async function getActionList(page = 0, size = 20, order: null | string, filters: ActionsFilters): Promise<ActionsResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof ActionsFilters)[] = [
    'completedMaxDate',
    'completedMinDate',
    'plannedMaxDate',
    'plannedMinDate',
    'createdMaxDate',
    'createdMinDate',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.completedMaxDate) {
    parsedFilters.completedMaxDate = new Date(parsedFilters.completedMaxDate).toISOString();
  }

  if (parsedFilters.completedMinDate) {
    parsedFilters.completedMinDate = new Date(parsedFilters.completedMinDate).toISOString();
  }

  if (parsedFilters.plannedMaxDate) {
    parsedFilters.plannedMaxDate = new Date(parsedFilters.plannedMaxDate).toISOString();
  }

  if (parsedFilters.plannedMinDate) {
    parsedFilters.plannedMinDate = new Date(parsedFilters.plannedMinDate).toISOString();
  }

  if (parsedFilters.createdMaxDate) {
    parsedFilters.createdMaxDate = new Date(parsedFilters.createdMaxDate).toISOString();
  }

  if (parsedFilters.createdMinDate) {
    parsedFilters.createdMinDate = new Date(parsedFilters.createdMinDate).toISOString();
  }

  const payload: GetActionsListPayload = {
    actionFilter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<ActionsResult>(`/actions/views`, payload);

  return res.data;
}

export async function deleteAction(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/actions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveActions(body: ActionPayload): Promise<ActionsContentResult> {
  const [err, res] = await tryCatch(HSApi.post('/actions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateActions(id, payload): Promise<PpeDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<ActionsContentResult>(`/actions/${id}`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
