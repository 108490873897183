import { Icon, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { defer, findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import PrintTable, { PrintTableRef } from 'Common/PrintTable/PrintTable';
import { styleTableMinistry } from 'Common/PrintTable/StyleTable/Ek2FormStyle';

import { getByIdCompany } from '../../../../api/hs/definitions/workplace-definition/company';
import { getEmployee } from '../../../../api/hs/employee';
import { getHospitalHistory } from '../../../../api/hs/examination';
import { getSurgeryHistory } from '../../../../api/hs/examination';
import { getChronicDisease } from '../../../../api/hs/examination';
import { getMedicineTreatment } from '../../../../api/hs/examination';
import { getAllergies } from '../../../../api/hs/examination';
import { getWorkAccidentHistory } from '../../../../api/hs/examination';
import { getDisabilities } from '../../../../api/hs/examination';
import { getAddictions } from '../../../../api/hs/examination';
import { getMedicalHistories } from '../../../../api/hs/examination';
import { getComplaintHistories } from '../../../../api/hs/examination';
import { getProfessionalBackground } from '../../../../api/hs/examination';
import { getExaminationById } from '../../../../api/hs/examination';
import { getAllAnalysisByEmployeeIdAndExaminationId } from '../../../../api/hs/examination/analysis';
import { getBodyMeasurements } from '../../../../api/hs/examination/body-measurements';
import { getFamilyHistory } from '../../../../api/hs/examination/family-history';
import { getOpinion } from '../../../../api/hs/examination/hs-exam-opinions';
import { getImmunities } from '../../../../api/hs/examination/immunity';
import { getPhysicalCheck } from '../../../../api/hs/examination/physical-checks';
import { getSystemCheck } from '../../../../api/hs/examination/system-checks';
import { getVaccines } from '../../../../api/hs/examination/vaccine';
import { getVitalSign } from '../../../../api/hs/examination/vital-sign';
import { getUserById, getUserProfileBrief } from '../../../../api/hs/users';
import { Icd10CacheContext } from '../../../../Common/Form/Icd10Selection/icd10.context';
import { EnumContext } from '../../../../context/enum.context';
import { ResourceContext } from '../../../../context/resource.context';
import { dateSort, dateToInputFormat } from '../../../../utils';
import { EmployeeContext } from '../employee.context';

function periodUnitTypeLabel(periodUnitType: string): string {
  switch (periodUnitType) {
    case 'DAILY':
      return 'günde';
    case 'WEEKLY':
      return 'haftada';
    case 'MONTHLY':
      return 'ayda';
    case 'YEARLY':
      return 'yılda';
    default:
      return periodUnitType;
  }
}

function getAddictionMetric(id: number): string {
  switch (id) {
    case 79:
      return 'kadeh';
    case 80:
      return 'adet';
    case 81:
      return 'kez';
    default:
      return '';
  }
}

interface Props {
  onClose(success: boolean): void;
  selectedExaminationId?: string;
  createdExaminationUserId?: string;
}

const dateSortHireDate = (array: any[]): any => {
  return array.sort((a, b) => {
    return new Date(b.hireDate) - new Date(a.hireDate);
  });
};

export default function Ek2Ministry({ onClose, selectedExaminationId, createdExaminationUserId }: Props) {
  const docRef = useRef<PrintTableRef>();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { employee } = useContext(EmployeeContext);
  const {
    departments,
    bloodRelation,
    examinationDefinitions,
    physicalCheck,
    vaccines,
    disabilities,
    workingRestrictionDefinitions,
  } = useContext(ResourceContext);
  const {
    bloodTypes,
    maritialStatuses,
    complaintDefinitions,
    medicalHistoryDefinitions,
    addictionStates,
    immunityStatuses,
    analysisCategoryDefinitions,
  } = useContext(EnumContext);
  const { getOptions, options } = useContext(Icd10CacheContext);
  const [data, setData] = useState({});
  const printPage = useCallback(async () => {
    onClose();
    const [
      employeeData,
      complaintData,
      medicalHistoriesData,
      professionalBackgroundData,
      familyHistoryData,
      examinationData,
      companyData,
      chronicDiseaseData,
      hospitalHistoryData,
      surgeryHistoryData,
      medicineTreatmentData,
      allergiesData,
      workAccidentHistoryData,
      disabilitiesData,
      addictionsData,
      systemCheckData,
      physicalCheckData,
      bodyMeasurementsData,
      vitalSignData,
      vaccinesData,
      immunitiesData,
      opinionData,
      analysisData,
      userData,
      userNameData,
    ] = await Promise.all([
      getEmployee(employeeId),
      getComplaintHistories(employeeId, examinationId ? examinationId : selectedExaminationId),
      getMedicalHistories(employeeId, examinationId ? examinationId : selectedExaminationId),
      getProfessionalBackground(employeeId, examinationId ? examinationId : selectedExaminationId),
      getFamilyHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getExaminationById(examinationId ? examinationId : selectedExaminationId),
      getByIdCompany(employee?.companyId),
      getChronicDisease(employeeId, examinationId ? examinationId : selectedExaminationId),
      getHospitalHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getSurgeryHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getMedicineTreatment(employeeId, examinationId ? examinationId : selectedExaminationId),
      getAllergies(employeeId, examinationId ? examinationId : selectedExaminationId),
      getWorkAccidentHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getDisabilities(employeeId, examinationId ? examinationId : selectedExaminationId),
      getAddictions(employeeId, examinationId ? examinationId : selectedExaminationId),
      getSystemCheck(employeeId, examinationId ? examinationId : selectedExaminationId),
      getPhysicalCheck(employeeId, examinationId ? examinationId : selectedExaminationId),
      getBodyMeasurements(employeeId, examinationId ? examinationId : selectedExaminationId),
      getVitalSign(employeeId, examinationId ? examinationId : selectedExaminationId),
      getVaccines(employeeId, examinationId ? examinationId : selectedExaminationId),
      getImmunities(employeeId, examinationId ? examinationId : selectedExaminationId),
      getOpinion(examinationId ? examinationId : selectedExaminationId),
      getAllAnalysisByEmployeeIdAndExaminationId(employeeId, examinationId ? examinationId : selectedExaminationId),
      getUserProfileBrief(createdExaminationUserId),
      getUserById(createdExaminationUserId),
    ]);

    const newData = {
      ...employeeData,
      complaint: complaintData,
      medicalHistory: medicalHistoriesData,
      professionalBackgrounds: dateSortHireDate(professionalBackgroundData),
      familyHistory: familyHistoryData,
      examination: examinationData,
      company: companyData,
      chronicDisease: chronicDiseaseData,
      hospitalHistory: hospitalHistoryData,
      surgeryHistory: surgeryHistoryData,
      medicineTreatment: medicineTreatmentData,
      allergies: allergiesData,
      workAccidentHistory: workAccidentHistoryData,
      disabilities: disabilitiesData,
      addictions: addictionsData,
      systemCheck: systemCheckData,
      physicalCheck: physicalCheckData,
      bodyMeasurements: dateSort(bodyMeasurementsData)[0],
      vitalSign: dateSort(vitalSignData)[0],
      vaccines: vaccinesData,
      immunities: immunitiesData,
      opinion: opinionData,
      analysis: analysisData,
      createdUser: userData,
      userName: userNameData,
    };
    setData(newData);

    await getOptions(...newData?.familyHistory.reduce((acc, item) => [...acc, ...item.icd10s], []));
    await getOptions(...newData?.chronicDisease?.icd10s);
    await defer();

    docRef.current.print();
  }, [employeeId, examinationId, employee, onClose, getOptions, selectedExaminationId, createdExaminationUserId]);

  function reverseDateFormat(date: string): string {
    return date?.split('-')?.reverse()?.join('-');
  }

  const opinionText = useCallback(
    opinion => {
      const workingDescription = opinion?.workingCondition ? opinion?.workingCondition + ' şartı ile ' : '';
      const positionDescription = opinion?.positionDefinition ? opinion?.positionDefinition + ' işinde bedenen ve ruhen ' : '';
      const opinionDescription = opinion?.workingRestrictions?.find(
        item => item?.workingRestrictionDefinitionId === workingRestrictionDefinitions?.find(opinions => opinions?.opinion === 'Kanaat')?.id
      );
      return opinionDescription?.workingRestrictionValue !== 2 ? (
        opinionDescription?.workingRestrictionValue === 1 ? (
          <>
            <tr>
              <td colSpan={6}>
                <b>
                  {' '}
                  {`1 - ${positionDescription} ${
                    opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
                  }`}
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>
                  {`2 - ${workingDescription} ${
                    opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
                  }`}
                </b>
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td colSpan={6}>
                <b>
                  {`1 - ${positionDescription} ${
                    opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
                  } `}
                </b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>
                  {`2 - ${workingDescription} ${
                    opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
                  }`}
                </b>
              </td>
            </tr>
          </>
        )
      ) : null;
    },
    [workingRestrictionDefinitions]
  );

  return (
    <>
      <MenuItem onClick={printPage}>
        <ListItemIcon>
          <Icon className="icon-clipboard-text" />
        </ListItemIcon>
        <Typography variant="inherit">EK-2 Bakanlık</Typography>
      </MenuItem>
      <PrintTable styleTable={styleTableMinistry} ref={docRef}>
        <table key="ek2FormBakan" style={{ width: '100%' }}>
          <tbody style={{ border: '1px dotted gray' }}>
            <thead />
            <tr>
              <td align="center" colSpan={4}>
                <h3 style={{ marginTop: '15px' }} className="text-center">{`${findByKey(
                  examinationDefinitions,
                  'id',
                  data?.examination?.examinationDefinitionId
                )?.examinationName.toUpperCase()} - FORMU`}</h3>
              </td>
              <td align="center" colSpan={2}>
                <p className="text-right">
                  <b>EK-2</b>
                </p>
              </td>
            </tr>

            <tr>
              <td colSpan={6}>
                <b>İŞYERİNİN</b>
              </td>
            </tr>
            <tr>
              <td>Ünvanı</td>
              <td colSpan={5}> {data?.company?.companyTitle}</td>
            </tr>
            <tr>
              <td>SGK Sicil No</td>
              <td colSpan={5}> {data?.company?.ssiId}</td>
            </tr>
            <tr>
              <td>Telefon No</td>
              <td colSpan={5}> {data?.company?.phone}</td>
            </tr>
            <tr>
              <td>Faks No</td>
              <td colSpan={5}> {data?.company?.fax}</td>
            </tr>
            <tr>
              <td>E-posta</td>
              <td colSpan={5}> {data?.company?.email}</td>
            </tr>
            <tr>
              <td>Adres</td>
              <td colSpan={5}> {data?.company?.address}</td>
            </tr>

            <tr>
              <td colSpan={4}>
                <div style={{ textIndent: '32px' }}>
                  {`${findByKey(examinationDefinitions, 'id', data?.examination?.examinationDefinitionId)?.examinationName}`} olmayı kabul
                  ettiğimi ve muayene sırasında verdiğim bilgilerin doğru ve eksiksiz olduğunu beyan ederim.
                </div>
                <div style={{ textAlign: 'right', marginRight: '15px', marginTop: '10px' }}>Çalışanın Adı Soyadı ve İmzası</div>
              </td>
              <td colSpan={2}>
                <img
                  src={`${window.location.origin}/saglik-gozetimi/public/images/Avatars/Orginal/${data?.avatarPath}`}
                  alt={data?.avatarPath}
                  width="150"
                  height="150"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>ÇALIŞANIN</b>
              </td>
            </tr>
            <tr>
              <td>Adı Soyadı</td>
              <td colSpan={5}>{data?.name}</td>
            </tr>
            <tr>
              <td> Kimlik No</td>
              <td colSpan={5}>{data?.nid}</td>
            </tr>
            <tr>
              <td>Doğum Yeri ve Tarihi</td>
              <td colSpan={5}>{`${data?.birthPlace} - ${reverseDateFormat(data?.birthDate)}`}</td>
            </tr>
            <tr>
              <td>Cinsiyet</td>
              <td colSpan={5}>{data?.gender ? 'Kadın' : 'Erkek'}</td>
            </tr>
            <tr>
              <td>Medeni Durumu</td>
              <td>{findByKey(maritialStatuses, 'id', data?.maritalStatus)?.label}</td>
              <td>Çocuk Sayısı</td>
              <td colSpan={3}>{data?.numberOfChildren}</td>
            </tr>
            <tr>
              <td>Adres</td>
              <td colSpan={5}>{data?.address}</td>
            </tr>
            <tr>
              <td>Telefon Numarası</td>
              <td colSpan={5}>{data?.telephone}</td>
            </tr>
            <tr>
              <td>Mesleği</td>
              <td colSpan={5}>{data?.profession}</td>
            </tr>
            <tr>
              <td colSpan={6}>Yaptığı iş (Ayrıntılı olarak tanımlanacaktır) : </td>
            </tr>
            <tr>
              <td>Çalıştığı Bölüm </td>
              <td colSpan={5}>{findByKey(departments, 'id', data?.departmentId)?.departmentName}</td>
            </tr>
            <tr>
              <td colSpan={6}>Daha önce çalıştığı yerler (Bugünden geçmişe doğru): </td>
            </tr>

            <tr>
              <td colSpan={6}>
                {data?.professionalBackgrounds?.length !== 0 ? (
                  <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                    <tr>
                      <th style={{ width: '25%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                        Daha önce çalıştığı yerler ( Bu günden geçmişe doğru )
                      </th>
                      <th style={{ width: '25%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>İş Kolu</th>
                      <th style={{ width: '25%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>Yaptığı İş</th>
                      <th style={{ width: '25%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>İşe Giriş / Çıkış</th>
                    </tr>
                    <tr>
                      {data?.professionalBackgrounds?.map((professionalBackground, index) => (
                        <tr key={professionalBackground?.id}>
                          <td style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>{`${index + 1} - ${
                            professionalBackground?.companyName
                          }`}</td>
                          <td align="center" style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                            {professionalBackground?.sector}
                          </td>
                          <td align="center" style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                            {professionalBackground?.job}
                          </td>
                          <td
                            align="center"
                            style={{
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                            }}>{`${reverseDateFormat(professionalBackground?.hireDate)} - ${reverseDateFormat(
                            professionalBackground?.quitDate
                          )}`}</td>
                        </tr>
                      ))}
                    </tr>
                  </table>
                ) : (
                  'Mesleki Özgeçmiş kayıtı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>Özgeçmişi</b>
              </td>
            </tr>
            <tr>
              <td>Kan grubu</td>
              <td colSpan={5}> {findByKey(bloodTypes, 'id', data?.bloodType)?.label} </td>
            </tr>
            <tr>
              <td>Konjenital/kronik hastalık</td>
              <td colSpan={5}>
                {' '}
                {data?.chronicDisease?.length !== 0 ? (
                  <ul>
                    {data?.chronicDisease?.icd10s
                      ?.map(id => findByKey(options, 'id', id))
                      ?.filter(isTruthy)
                      ?.map(({ id, label }) => (
                        <li key={id}>{label}</li>
                      ))}
                    {data?.chronicDisease?.description ? <li>{`Açıklaması : ${data?.chronicDisease?.description}`}</li> : null}
                  </ul>
                ) : (
                  'Konjenital/Kronik hastalık kaydı bulunmamaktadır.'
                )}{' '}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>Bağışıklama</td>
            </tr>
            <tr>
              <td colSpan={6}>
                {' '}
                <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                  <tbody style={{ borderBottom: '1px solid', borderBottomColor: 'silver' }}>
                    {data?.immunities?.length !== 0 ? (
                      <>
                        {data?.immunities?.map(immunitiesValue => (
                          <tr key={immunitiesValue?.id} style={{ border: 'none' }}>
                            <td style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse', paddingLeft: '15px' }}>
                              - {findByKey(vaccines, 'id', immunitiesValue?.vaccineDefinitionId)?.name}
                            </td>
                            <td style={{ width: '60%', border: ' 1px solid silver', borderCollapse: 'collapse', paddingLeft: '15px' }}>
                              {findByKey(immunityStatuses, 'id', immunitiesValue?.immunityStatusDefinitionId)?.label}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      'Bağışıklanma kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>{' '}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>Soygeçmişi</b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                {data?.familyHistory?.length !== 0 ? (
                  <tr key="familyHistory">
                    {data?.familyHistory?.map(familyHistoryValue => (
                      <tr key={familyHistoryValue?.id}>
                        <td>{findByKey(bloodRelation, 'id', familyHistoryValue?.bloodRelationDefinitionId)?.label}</td>
                        <td colSpan={5}>
                          <ul>
                            {familyHistoryValue?.icd10s
                              ?.map(id => findByKey(options, 'id', id))
                              ?.filter(isTruthy)
                              ?.map(({ id, label }) => (
                                <li key={id}>{label}</li>
                              ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={6}>Soygeçmiş kaydı bulunmamaktadır.</td>
                  </tr>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>TIBBİ ANAMNEZ</b>
              </td>
            </tr>
            <tr>
              <td width="50%" colSpan={3} align="left">
                1. Aşağıdaki yakınmalardan herhangi birini yaşadınız mı ?
              </td>
              <td width="25%" colSpan={1} align="center">
                Hayır
              </td>
              <td width="25%" colSpan={1} align="center">
                Evet
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                {data?.complaint?.complaintHistories?.length !== 0 ? (
                  <>
                    {data?.complaint?.complaintHistories
                      ?.filter(filterValue => filterValue?.value !== 2)
                      ?.map(complaintHistory => (
                        <tr key={complaintHistory?.id} style={{ border: 'none' }}>
                          <td align="left" width="50%" colSpan={3}>
                            - {findByKey(complaintDefinitions, 'id', complaintHistory?.complaintDefinitionId)?.label}
                          </td>
                          <td align="center" width="25%" colSpan={1}>
                            {complaintHistory?.value === 0 ? ' X' : null}
                          </td>
                          <td align="center" width="25%" colSpan={1}>
                            {complaintHistory?.value === 1 ? ' X' : null}
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  'Yakınma Öyküsü kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td width="50%" colSpan={3} align="left">
                2. Aşağıdaki hastalıklardan herhangi birini geçirdiniz mi?
              </td>
              <td width="25%" colSpan={1} align="center">
                Hayır
              </td>
              <td width="25%" colSpan={1} align="center">
                Evet
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                {data?.medicalHistory?.medicalHistories?.length !== 0 ? (
                  <>
                    {data?.medicalHistory?.medicalHistories
                      ?.filter(filterValue => filterValue?.value !== 2)
                      ?.map(medicalHistories => (
                        <tr key={medicalHistories?.id} style={{ border: 'none' }}>
                          <td align="left" width="50%" colSpan={3}>
                            {findByKey(medicalHistoryDefinitions, 'id', medicalHistories?.definitionId)?.label}
                          </td>
                          <td align="center" width="25%" colSpan={1}>
                            {medicalHistories?.value === 0 ? ' X' : null}
                          </td>
                          <td align="center" width="25%" colSpan={1}>
                            {medicalHistories?.value === 1 ? ' X' : null}
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  'Hastalık Öyküsü kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>3. Hastanede yattınız mı ? </td>
            </tr>
            <tr>
              {data?.hospitalHistory?.length !== 0 ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>
                    <ul>
                      {data?.hospitalHistory?.map(item => (
                        <li key={item?.id}>{item?.description}</li>
                      ))}
                    </ul>
                  </td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>4. Ameliyat oldunuz mu? </td>
            </tr>
            <tr>
              {data?.surgeryHistory?.length !== 0 ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>
                    <ul>
                      {data?.surgeryHistory?.map(item => (
                        <li key={item?.id}>{item?.description}</li>
                      ))}
                    </ul>
                  </td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>5. İş kazası geçirdiniz mi? </td>
            </tr>
            <tr>
              {data?.workAccidentHistory?.length !== 0 ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>
                    <ul>
                      {data?.workAccidentHistory?.map(item => (
                        <li key={item?.id}>{`${item?.description} - ${item?.date ? reverseDateFormat(item?.date) : null}`}</li>
                      ))}
                    </ul>
                  </td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>6. Meslek hastalıkları şüphesi ile ilgili tetkik veya muayeneye tabi tutuldunuz mu? </td>
            </tr>
            <tr>
              {data?.occupationalDiseaseDescription ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>{data?.occupationalDiseaseDescription}</td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>7. Maluliyet aldınız mı? </td>
            </tr>
            <tr>
              {data?.disabilities?.length !== 0 ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>
                    <>
                      <div key={data?.disabilities?.id}> {`Toplam Engellilik Oranı : % ${data?.disabilities?.percentage}`} </div>
                      <div>
                        {data?.disabilities?.disabilityDetails
                          ?.map(disability => findByKey(disabilities, 'id', disability?.disabilityDefinitionId)?.name)
                          .join(' , ')}
                      </div>
                      {data?.disabilities?.description ? (
                        <div key={data?.disabilities?.id}> {`Açıklama : ${data?.disabilities?.description}`} </div>
                      ) : null}
                    </>
                  </td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>8. Şu anda herhangi bir tedavi görüyor musunuz?</td>
            </tr>
            <tr>
              {data?.medicineTreatment?.length !== 0 ? (
                <>
                  <td>Evet</td>
                  <td colSpan={5}>
                    <ul>
                      {data?.medicineTreatment?.map(item => (
                        <li key={item?.id}>{item?.description}</li>
                      ))}
                    </ul>
                  </td>
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>9. Sigara içiyor musunuz ?</td>
            </tr>
            <tr>
              {data?.addictions?.addictionDetails?.filter(
                value => value?.addictiveSubstanceDefinitionId === 80 && value?.addictionStateDefinitionId !== 0
              ).length !== 0 ? (
                <>
                  {data?.addictions?.addictionDetails
                    ?.filter(value => value?.addictiveSubstanceDefinitionId === 80)
                    ?.map(item => (
                      <tr key={item?.id}>
                        <td>{findByKey(addictionStates, 'id', item?.addictionStateDefinitionId)?.label}</td>
                        <td colSpan={5}>
                          {item.addictionStateDefinitionId === 0
                            ? `${findByKey(addictionStates, 'id', item?.addictionStateDefinitionId)?.label} `
                            : item.addictionStateDefinitionId === 1
                            ? `${item.startYearForQuit} yılında bırakmış. ${item.countOfYearsForQuit} yıl boyunca ${
                                item.period
                              } ${periodUnitTypeLabel(item?.periodUnitDefinition)}

                        ${item.countPerDayForQuit} ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanmış.`
                            : `${item.startYearForUsing} yılından beri  ${item.period} ${periodUnitTypeLabel(item?.periodUnitDefinition)} ${
                                item.countPerDayForUsing
                              } ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanıyor.`}
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>10. Alkol alıyor musunuz ?</td>
            </tr>
            <tr>
              {data?.addictions?.addictionDetails?.filter(
                value => value?.addictiveSubstanceDefinitionId === 79 && value?.addictionStateDefinitionId !== 0
              ).length !== 0 ? (
                <>
                  {data?.addictions?.addictionDetails
                    ?.filter(value => value?.addictiveSubstanceDefinitionId === 79)
                    ?.map(item => (
                      <tr key={item?.id}>
                        <td>{findByKey(addictionStates, 'id', item?.addictionStateDefinitionId)?.label}</td>
                        <td colSpan={5}>
                          {item.addictionStateDefinitionId === 0
                            ? `${findByKey(addictionStates, 'id', item?.addictionStateDefinitionId)?.label} `
                            : item.addictionStateDefinitionId === 1
                            ? `${item.startYearForQuit} yılında bırakmış. ${item.countOfYearsForQuit} yıl boyunca ${
                                item.period
                              } ${periodUnitTypeLabel(item?.periodUnitDefinition)}

                        ${item.countPerDayForQuit} ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanmış.`
                            : `${item.startYearForUsing} yılından beri  ${item.period} ${periodUnitTypeLabel(item?.periodUnitDefinition)} ${
                                item.countPerDayForUsing
                              } ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanıyor.`}
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <td colSpan={6}>Hayır</td>
              )}
            </tr>

            <tr>
              <td colSpan={6}>
                <b>FİZİK MUAYENE SONUÇLARI :</b>
              </td>
            </tr>
            <tr>
              <td style={{ border: 'none' }} colSpan={6}>
                <table style={{ width: '100%', border: 'none' }}>
                  <tbody>
                    {data?.physicalCheck?.length !== 0 ? (
                      <>
                        {data?.physicalCheck
                          ?.filter(filterValue => filterValue?.physicalCheckStatusDefinitionId !== 2)
                          ?.map(physicalValue => (
                            <tr key={physicalValue?.id} style={{ border: 'none' }}>
                              <td style={{ width: '40%', border: 'none' }}>
                                {findByKey(physicalCheck, 'id', physicalValue?.physicalCheckDefinitionId)?.label}
                              </td>
                              <td style={{ width: '10%', border: 'none' }}>
                                {physicalValue?.physicalCheckStatusDefinitionId === 1 ? ': Var' : ': Yok'}
                              </td>
                              <td style={{ width: '50%', border: 'none' }}>
                                {physicalValue?.description ? physicalValue?.description : null}
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      'Fizik Muayene kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
                <tr style={{ border: 'none' }}>
                  <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                    Diğer :
                  </td>
                </tr>

                {data?.vitalSign?.length !== 0 ? (
                  <>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`TA:  ${data?.vitalSign?.systolic} / ${data?.vitalSign?.diastolic}   mmHg`}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`Nb: ${data?.vitalSign?.pulseRate}/dk`}</li>
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`TA:   /   mmHg`}</li>
                        </ul>
                      </td>
                    </tr>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`Nb: /dk`}</li>
                        </ul>
                      </td>
                    </tr>
                  </>
                )}

                {data?.bodyMeasurements?.length !== 0 ? (
                  <>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`Boy: ${data?.bodyMeasurements?.lengthInCm}   Kilo: ${data?.bodyMeasurements?.weightInKg}     Vücut Kitle İndeksi: ${data?.bodyMeasurements?.bmi}`}</li>
                        </ul>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr style={{ border: 'none' }}>
                      <td colSpan={6} style={{ width: '100%', border: 'none' }}>
                        <ul>
                          <li>{`Boy:             Kilo:             Vücut Kitle İndeksi`}</li>
                        </ul>
                      </td>
                    </tr>
                  </>
                )}
              </td>
            </tr>

            <tr>
              <td colSpan={6}>
                <b>LABORATUVAR BULGULARI :</b>
              </td>
            </tr>
            <tr>
              {data?.analysis?.length !== 0 ? (
                <td colSpan={6}>
                  <ul>
                    {data?.analysis?.map(item => (
                      <li key={item?.id}>{`${findByKey(analysisCategoryDefinitions, 'id', item?.categoryDefinitionId)?.label} - ${
                        item?.date ? reverseDateFormat(item?.date) : null
                      }`}</li>
                    ))}
                  </ul>
                </td>
              ) : (
                <td colSpan={6}>Labovatuvar bulguları bulunmamaktadır.</td>
              )}
            </tr>
            <tr>
              <td colSpan={6}>
                <b>KANAAT VE SONUÇ* :</b>
              </td>
            </tr>
            {data?.opinion ? (
              opinionText(data?.opinion)
            ) : (
              <tr>
                <td colSpan={6}>Kanaat Kaydı bulunmamaktadır.</td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ fontSize: '16px', textAlign: 'justify', fontStyle: 'italic', marginTop: '50px' }}>
          (*Yapılan muayene sonucunda çalışanın gece veya vardiyalı çalışma koşullarında çalışıp çalışamayacağı ile vücut sağlığını ve
          bütünlüğünü tamamlayıcı uygun alet teçhizat vs... bulunması durumunda çalışan için bu koşullarla çalışmaya elverişli olup olmadığı
          kanaati belirtilecektir.)
        </div>
        <div style={{ marginTop: '50px' }}>
          <div style={{ float: 'left', textAlign: 'left' }}>
            <div>
              <b>İMZA</b>
            </div>
            <div>
              <b>{`Adı ve Soyadı : ${data?.userName?.name}`}</b>
            </div>
            <div>
              <b>Diploma.Tar. ve No : {data?.createdUser?.graduationDate}</b>
            </div>
            <div>
              <b>İşyeri Hek. Belgesi Tar. ve No: {data?.createdUser?.doctorCertificate}</b>
            </div>
          </div>
          <div style={{ float: 'right', textAlign: 'right' }}>
            <b>{reverseDateFormat(dateToInputFormat(new Date()))}</b>
          </div>
        </div>
      </PrintTable>
    </>
  );
}
