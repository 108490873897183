import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';

import { deleteLocation, getLocation, LocationResult } from 'api/hs/definitions/workplace-definition/location';
import LocationEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Location/LocationEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Location() {
  return (
    <BaseDefinitionPage<LocationResult>
      listLabelPropName="label"
      fetchData={getLocation}
      FormDialog={LocationEditDialog}
      onDelete={deleteLocation}
      Icon={<LocationOnIcon />}
      searchName="label"
    />
  );
}
