import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { useEffect } from 'react';

import { API_EVENTS } from 'api/hs/HSApi';
import { Alert, AlertRef } from 'Common';
export const AlertContext = React.createContext<AlertRef>(null);

export function AlertContextProvider({ children }: PropsWithChildren<unknown>) {
  const ref = useRef<AlertRef>();

  const showMessage: AlertRef['showMessage'] = useMemo(() => (...args) => ref.current?.showMessage(...args), []);

  // Handle API Errors
  useEffect(() => {
    const handle403Errors = () => showMessage('Bu işleme yetkiniz bulunmamaktadır', 'error');

    API_EVENTS.addEventListener('notAuthorized', handle403Errors);

    return () => API_EVENTS.removeEventListener('notAuthorizedError', handle403Errors);
  }, [showMessage]);
  return (
    <AlertContext.Provider value={{ showMessage }}>
      {children}
      <Alert ref={ref} />
    </AlertContext.Provider>
  );
}
