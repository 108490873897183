import React, { useCallback, useContext } from 'react';

import ImagePreview from 'Common/Forms/ImagePreview';

import { ProfileUploadContext } from '../../profile-file-upload-context';

export default function PreviewImageDialog() {
  const { setPreviewImageOpened, reload, previewImageOpened, selectedData } = useContext(ProfileUploadContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setPreviewImageOpened(false);
    },
    [reload, setPreviewImageOpened]
  );

  return <ImagePreview open={previewImageOpened} onClose={closeDialog} avatarPath={`${selectedData?.path}/${selectedData?.name}`} />;
}
