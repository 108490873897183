import { Divider, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import EditEmployeeDialog from 'Common/EditEmployeeDialog';
import { AlertContext } from 'context/alert.context';
import { EnumContext } from 'context/enum.context';
import { ResourceContext } from 'context/resource.context';
import { dateDisplayFormat, useDialogState } from 'utils';

import Avatar from '../../Avatar';
import EmergencyContacts from '../EmergencyContacts';
import { EmployeeContext } from '../employee.context';
import HealthInsurances from '../HealthInsurances';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default function PersonalDetails() {
  const classes = useStyles();
  const { employee, refresh } = useContext(EmployeeContext);
  const { maritialStatuses, genders, bloodTypes, educationStatuses, workingTypes } = useContext(EnumContext);
  const { companies, departments, locations, workStations, positions, exposures } = useContext(ResourceContext);
  const { open, close, opened } = useDialogState();
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
        showMessage('Personel Güncellendi', 'success', 'info');
      }
      close();
    },
    [refresh, showMessage, close]
  );

  return (
    <>
      <EditEmployeeDialog open={opened} onClose={handleSubmit} employee={employee} />
      <Grid container spacing={1} className="pb-2">
        <Grid item sm={6}>
          <Avatar className={`${classes.avatar} mb-2 ml-5`} alt={employee?.name} url={employee?.avatarPath} variant="rounded" />
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" className={classes.textColor}>
                    T.C. Kimlik No.
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.nid}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Ad Soyad
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Kan Grubu
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(bloodTypes, 'id', employee?.bloodType)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Doğum Tarihi
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {dateDisplayFormat(employee?.birthDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Doğum Yeri
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.birthPlace}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Cinsiyet
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(genders, 'id', employee?.gender)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Medeni Durumu
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(maritialStatuses, 'id', employee?.maritalStatus)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Çocuk Sayısı
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.numberOfChildren}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Öğrenim Durumu
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(educationStatuses, 'id', employee?.educationalStatus)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Mesleği
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.profession}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Emeklilik Durumu
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.retirementStatus ? 'Emekli' : 'Emekli değil'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Telefon
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.gsmNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    E-posta
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Adres
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.address}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Not
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.occupationalDiseaseDescription}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={6} className={classes.aside}>
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" className={classes.textColor}>
                    İşe Başlangıç Tarihi
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {dateDisplayFormat(employee?.hireDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    İşten Çıkış Tarihi
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {dateDisplayFormat(employee?.quitDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Sicil No
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.registryNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    SGK Numarası
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.ssiNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Çalışma Şekli
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(workingTypes, 'id', employee?.workingType)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    İş Telefon
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.businessTelephone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Şirket
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(companies, 'id', employee?.companyId)?.companyName || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Departman
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(departments, 'id', employee?.departmentId)?.departmentName || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Lokasyon
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(locations, 'id', employee?.locationDefinitionId)?.label || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    İş İstasyonu
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(workStations, 'id', employee?.stationId)?.stationName || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Görev/Pozisyon
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {findByKey(positions, 'id', employee?.positionId)?.positionName || ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.textColor} component="th" scope="row">
                    Maruziyetler
                  </TableCell>
                  <TableCell className={classes.textColor2} align="left">
                    {employee?.exposureId?.map(id => findByKey(exposures, 'id', id)?.exposureName || '').join(', ')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <footer className="text-right">
            <Button color="primary" variant="contained" onClick={open}>
              Personel Bilgilerini Düzenle
            </Button>
          </footer>
        </Grid>
      </Grid>
      <Divider />
      <HealthInsurances />
      <EmergencyContacts />
    </>
  );
}
