import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import { AnalysisAggregate, deleteAnalysis } from 'api/hs/examination/analysis';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { EnumContext } from 'context/enum.context';
import { stopPropagation } from 'utils';

import { AnalysisContext } from '../../analysis.context';

interface Props {
  data: AnalysisAggregate;
}

export default function Action({ data }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened, reload } = useContext(AnalysisContext);
  const { analysisCategoryDefinitions } = useContext(EnumContext);
  const { openWarnDialog } = useContext(DialogContext);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const { showMessage } = useContext(AlertContext);

  const openDeleteDialog = useCallback(async () => {
    closeMenu();
    // @ts-ignore
    const categoryName = analysisCategoryDefinitions.find(def => def.id === data.categoryDefinitionId).label;
    const submitted = await openWarnDialog({
      text: `${categoryName} - ${data.date} kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteAnalysis(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [closeMenu, data, reload, openWarnDialog, showMessage, analysisCategoryDefinitions]);

  const openEditDialog = useCallback(() => {
    setSelectedData(data);
    setEditDialogOpened(true);
    closeMenu();
  }, [closeMenu, setEditDialogOpened, setSelectedData, data]);

  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem onClick={openEditDialog}>
            <ListItemIcon>
              <EditIcon fontSize="medium" />
            </ListItemIcon>
            <Typography color="inherit" variant="inherit">
              Düzenle
            </Typography>
          </MenuItem>
          <MenuItem onClick={openDeleteDialog}>
            <ListItemIcon>
              <DeleteIcon fontSize="medium" />
            </ListItemIcon>
            <Typography color="inherit" variant="inherit">
              Sil
            </Typography>
          </MenuItem>
        </Menu>
      </span>
    </>
  );
}
