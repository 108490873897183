import React from 'react';
import { Route, Switch } from 'react-router';

import CasePlans from './CasePlans';
import CaseTeams from './CaseTeams';
import CaseTeamsDetail from './CaseTeamsDetail';
import Drill from './Drill';

export default function Index() {
  return (
    <Switch>
      <Route exact path="/emergency-management/drill">
        <Drill />
      </Route>
      <Route exact path="/emergency-management/caseTeams">
        <CaseTeams />
      </Route>
      <Route exact path="/emergency-management/case-plans">
        <CasePlans />
      </Route>
      <Route
        exact
        path="/emergency-management/caseTeams/:emergencyCaseTeamId"
        render={({ location }) => <CaseTeamsDetail caseTeamsRowData={location.state.rowData} />}
      />
    </Switch>
  );
}
