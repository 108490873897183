import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReplayIcon from '@material-ui/icons/Replay';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { PpeOutputsContentResult } from 'api/hs/ppe/ppe-outputs';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { SelectOptionsContext } from '../../../context/select-options.context';
import { useDateFormat } from '../../../utils';
import { PpeOutputsContext } from '../ppe-outputs.context';

function PpeReasonLabel({ row }: { row: PpeOutputsContentResult }) {
  const { reasons } = useContext(SelectOptionsContext);
  return <>{findByKey(reasons, 'value', row?.reason)?.text}</>;
}

function ActionComponent({ row }: { row: PpeOutputsContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setNewReturnForm, setSelectedData } = useContext(PpeOutputsContext);

  const returnFormOpen = useCallback(
    item => {
      closeMenu();
      setNewReturnForm(true);
      setSelectedData(item);
    },
    [setNewReturnForm, closeMenu, setSelectedData]
  );

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => returnFormOpen(row)}>
          <ListItemIcon>
            <ReplayIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">İade Et</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}

export const CELL: TableColumn<PpeOutputsContentResult>[] = [
  {
    id: 'imagePath',
    render(row): JSX.Element {
      return <Avatar src={`/saglik-gozetimi/public/images/ppe/${row?.imagePath}`} />;
    },
    label: '',
    sortable: false,
    width: '5%',
  },
  {
    id: 'employeeName',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '15%',
  },
  {
    id: 'positionName',
    defaultVisibility: true,
    label: 'Görev',
    sortable: true,
    width: '15%',
  },
  {
    id: 'type',
    defaultVisibility: true,
    label: 'KKD Alt Grup',
    sortable: true,
    width: '10%',
  },
  {
    id: 'brand',
    defaultVisibility: true,
    label: 'Marka',
    sortable: true,
    width: '10%',
  },
  {
    id: 'count',
    defaultVisibility: true,
    label: 'Adet',
    sortable: true,
    width: '5%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '15%',
  },
  {
    id: 'reason',
    defaultVisibility: true,
    label: 'Durum',
    width: '5%',
    sortable: true,
    render(row: PpeOutputsContentResult): JSX.Element {
      return <PpeReasonLabel key={row?.id} row={row} />;
    },
  },
  {
    id: 'exposureTime',
    defaultVisibility: true,
    label: 'Kullanım',
    sortable: true,
    width: '5%',
    render(row: PpeOutputsContentResult): string {
      return row?.exposureTime + ' Ay';
    },
  },
  {
    id: 'createdDate',
    defaultVisibility: true,
    label: 'Tarih',
    sortable: true,
    width: '10%',
    render(row: PpeOutputsContentResult): JSX.Element {
      return <DateField date={new Date(row.createdDate)} />;
    },
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '5%',
    render(row: PpeOutputsContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
