/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AudioDetailRequest } from '../models/AudioDetailRequest';
import type { PageAudioDetailView } from '../models/PageAudioDetailView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AudioDetailViewControllerService {

    /**
     * @param requestBody
     * @returns PageAudioDetailView OK
     * @throws ApiError
     */
    public static getEmployeeEAudioDetailsFiltered(
        requestBody: AudioDetailRequest,
    ): CancelablePromise<PageAudioDetailView> {
        return __request({
            method: 'POST',
            path: `/audio-details/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}