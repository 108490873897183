import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { WorkEquipmentTypeDefinitionDto } from '../../../../../api/client';
import { WorkEquipmentTypeDefinitionControllerService } from '../../../../../api/client';
import { ResourceContext } from '../../../../../context/resource.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentTypeDefinitionDto;
}

export default function WorkEquipmentTypeDefinitonForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const { reload } = useContext(ResourceContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await WorkEquipmentTypeDefinitionControllerService.saveWorkEquipmentTypeDefinition({ ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await WorkEquipmentTypeDefinitionControllerService.updateWorkEquipmentTypeDefinition(data.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
        await reload('workEquipmentTypeDefinitions');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, showMessage, onClose, reload]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog
      color="primary"
      opened={open}
      onClose={() => onClose(false)}
      title={data ? 'İş Ekipman Kategori Düzenleme' : 'İş Ekipman Kategori Ekleme'}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12}>
            <TextField required name="name" label="İş Ekipman Kategori Giriniz" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
