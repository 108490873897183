import { Box, makeStyles, Step, StepButton, StepContent, Stepper, Typography } from '@material-ui/core';
import { findByKey, tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Toolbar from 'Common/Toolbar';
import { ResourceContext } from 'context/resource.context';
import { BaseEmployee } from 'model';
import { useDateFormat } from 'utils';
import { useTranslate } from 'utils/use-translate';

import { PlanningTrainingDefinitionModel } from '../../api/hs/trainings';
import { getByIdTraining, getGuestIds } from '../../api/hs/trainings';
import { useAsyncEffect } from '../../utils';
import Documents from './Documents';
import Duration from './Duration';
import Info from './Info';
import List from './List';
import Participant from './Participant';
import { PlanningParticipantForm } from './planning-employees.model';
import { PlanningInfo } from './planning-info.model';
import { PlanningStep } from './planning-steps.enum';
import { GuestParticipant, GuestTrainer, PlanningContext, PlanningContextType } from './planning.context';
import Preview from './Preview';
import HSApi from "../../api/hs/HSApi";
import {excelExport, handleResult} from "../../api/hs/utils";
import {TrainingDefinitionEmployeeControllerService} from "../../api/client";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    height: '100%',
    gridTemplate: 'auto 1fr / 100%',
  },
  content: {
    overflow: 'auto',
  },
}));

export default function Planning() {
  const classes = useStyles();
  const translate = useTranslate('training');
  const [activeStep, setActiveStep] = useState<PlanningStep>(PlanningStep.TrainingSelection);
  const [selectedTrainings, setSelectedTrainings] = useState<PlanningTrainingDefinitionModel['id'][]>([]);
  const [info, setInfo] = useState<PlanningInfo>(null);
  const [participantForm, setParticipantForm] = useState<PlanningParticipantForm>(null);
  const [durations, setDurations] = useState<Record<string, number>>(null);
  const [importFromExcelVisible, setImportFromExcelVisible] = useState<boolean>(false);
  const [remoteDurations, setRemoteDurations] = useState<Map<string, Map<string, number>>>(new Map());
  const [remoteTrainingEmployeeFile, setRemoteTrainingEmployeeFile] = useState<File>(null);
  const { trainingDefinitions } = useContext(ResourceContext);
  const [guestTrainers, setGuestTrainers] = useState<GuestTrainer[]>([]);
  const [guestParticipants, setGuestParticipants] = useState<GuestParticipant[]>([]);
  const [participants, setParticipants] = useState<(BaseEmployee | GuestParticipant)[]>([]);
  const [quiz, setQuiz] = useState<File[]>([]);
  const [otherFiles, setOtherFiles] = useState<File[]>([]);
  const [preQuiz, setPreQuiz] = useState<File[]>([]);
  const format = useDateFormat();
  const [docsCompleted, setDocsCompleted] = useState<boolean>(false);
  const { trainingId } = useParams<{ trainingId: string }>();

  const addGuestTrainer = useCallback(
    (trainer: GuestTrainer) => {
      setGuestTrainers([...guestTrainers, trainer]);
    },
    [guestTrainers]
  );

  const addGuestParticipants = useCallback(
    (participant: GuestParticipant) => {
      setGuestParticipants([...guestParticipants, participant]);
    },
    [guestParticipants]
  );

  const context = useMemo<PlanningContextType>(
    () => ({
      selectedTrainings,
      setSelectedTrainings,
      activeStep,
      setActiveStep,
      info,
      setInfo,
      participantForm,
      setParticipantForm,
      durations,
      setDurations,
      remoteDurations,
      setRemoteDurations,
      guestTrainers,
      addGuestTrainer,
      guestParticipants,
      addGuestParticipants,
      participants,
      setParticipants,
      quiz,
      setQuiz,
      otherFiles,
      setOtherFiles,
      preQuiz,
      setPreQuiz,
      importFromExcelVisible,
      setImportFromExcelVisible,
      remoteTrainingEmployeeFile,
      setRemoteTrainingEmployeeFile
    }),
    [
      selectedTrainings,
      activeStep,
      info,
      participantForm,
      durations,
      remoteDurations,
      guestTrainers,
      addGuestTrainer,
      guestParticipants,
      addGuestParticipants,
      participants,
      quiz,
      setQuiz,
      otherFiles,
      setOtherFiles,
      preQuiz,
      setPreQuiz,
      importFromExcelVisible,
      setImportFromExcelVisible,
      remoteTrainingEmployeeFile,
      setRemoteTrainingEmployeeFile
    ]
  );

  const selectionStepSubTitle = useMemo(
    () =>
      selectedTrainings.length
        ? selectedTrainings.map(training => findByKey(trainingDefinitions, 'id', training)?.name).join(', ')
        : translate('Select at least one training'),
    [selectedTrainings, trainingDefinitions, translate]
  );

  const infoStepSubTitle = useMemo(() => {
    if (!info) {
      return '';
    }

    return `${info.title} - ${info.plannedDate ? format(new Date(info.plannedDate), 'dd MMMM yyyy HH:mm') : 'Eğitim tarihi girilmedi.'}`;
  }, [info, format]);

  useAsyncEffect(async () => {
    if (trainingId) {
      const res = await getByIdTraining(trainingId);
      setDurations(
        res?.trainingDefinitions?.reduce((acc, training) => ({ ...acc, [training.trainingDefinitionId]: training.durationInMinutes }), {})
      );
      setInfo({ ...res, trainerIds: res?.trainingTrainers?.map(trainer => trainer?.trainerId) });
      setParticipantForm({
        blacklist: res?.trainingPlaningEmployeeExcluding?.reduce((acc, item) => [...acc, item?.employeeId], []),
        added: res?.trainingPlaningEmployeeIncluding?.reduce((acc, item) => [...acc, item?.employeeId], []),
        departmentDefinitionIds: res?.trainingPlaningEmployeeGroups
          ?.filter(employeeGroup => employeeGroup?.assignmentTypeDefinition === 'DEPARTMENT')
          ?.reduce((acc, item) => [...acc, item?.referenceTableId], []),
        locationDefinitionIds: res?.trainingPlaningEmployeeGroups
          ?.filter(employeeGroup => employeeGroup?.assignmentTypeDefinition === 'LOCATION')
          ?.reduce((acc, item) => [...acc, item?.referenceTableId], []),
        positionDefinitionIds: res?.trainingPlaningEmployeeGroups
          ?.filter(employeeGroup => employeeGroup?.assignmentTypeDefinition === 'POSITION')
          ?.reduce((acc, item) => [...acc, item?.referenceTableId], []),
        workstationDefinitionIds: res?.trainingPlaningEmployeeGroups
          ?.filter(employeeGroup => employeeGroup?.assignmentTypeDefinition === 'WORKSTATION')
          ?.reduce((acc, item) => [...acc, item?.referenceTableId], []),
        guests: res.trainingEmployees?.filter(guestEmployee => guestEmployee?.guestId)?.reduce((acc, item) => [...acc, item.guestId], []),
      });
      setGuestTrainers(res.trainingTrainers);
      setSelectedTrainings(res?.trainingDefinitions?.reduce((acc, item) => [...acc, item.trainingDefinitionId], []));
      const guestIds = res.trainingEmployees
        ?.filter(guestEmployee => guestEmployee?.guestId)
        ?.reduce((acc, item) => [...acc, item.guestId], []);
      const guests = await getGuestIds(guestIds);
      setGuestParticipants(
        guests?.map(guest => ({
          name: guest?.name,
          surname: guest?.surname,
          id: guest?.id,
        }))
      );
    }
    if (docsCompleted || activeStep < PlanningStep.Documents) {
      return;
    }
    setDocsCompleted(true);
  }, [docsCompleted, trainingId]);

  return (
    <PlanningContext.Provider value={context}>
      <Box className={classes.container}>
        <Toolbar>
          <Typography variant="h6" className="text-bold">
            {translate('Training Planning')}
          </Typography>
        </Toolbar>
        <Box className={`${classes.content} p-2`}>
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
            <Step key={PlanningStep.TrainingSelection}>
              <StepButton
                completed={!!selectedTrainings.length}
                optional={<Typography variant="caption">{selectionStepSubTitle}</Typography>}
                className="text-left"
                onClick={() => activeStep > PlanningStep.TrainingSelection && setActiveStep(PlanningStep.TrainingSelection)}>
                <Typography variant="h6">{translate('Training Selection')}</Typography>
              </StepButton>
              <StepContent>
                <List />
              </StepContent>
            </Step>
            <Step key={PlanningStep.TrainingInfo}>
              <StepButton
                completed={!!info}
                className="text-left"
                optional={info && infoStepSubTitle}
                disabled={!selectedTrainings.length}
                onClick={() => activeStep > PlanningStep.TrainingInfo && setActiveStep(PlanningStep.TrainingInfo)}>
                <Typography variant="h6">{translate('Training Info')}</Typography>
              </StepButton>
              <StepContent>
                <Info />
              </StepContent>
            </Step>
            {
              // info && !(!info.isFaceToFace && info.importFromExcel) && (
              <Step key={PlanningStep.Duration}>
                <StepButton
                  completed={!!durations}
                  className="text-left"
                  optional={
                    activeStep !== PlanningStep.Duration &&
                    durations &&
                    'Toplam ' + Object.values(durations).reduce((acc, dur) => acc + +dur, 0) + ' dk'
                  }
                  disabled={!selectedTrainings.length || !info}
                  onClick={() => activeStep > PlanningStep.Duration && setActiveStep(PlanningStep.Duration)}>
                  <Typography variant="h6">{translate('Training Durations')}</Typography>
                </StepButton>
                <StepContent>
                  <Duration/>
                </StepContent>
              </Step>
            //)
            }
            <Step key={PlanningStep.Participants}>
              <StepButton
                completed={!!participantForm}
                className="text-left"
                disabled={!selectedTrainings.length || !info}
                optional={participantForm && participants.length + ' Katılımcı'}
                onClick={() => activeStep > PlanningStep.Participants && setActiveStep(PlanningStep.Participants)}>
                <Typography variant="h6">{translate('Participants')}</Typography>
              </StepButton>
              <StepContent>
                <Participant />
              </StepContent>
            </Step>
            <Step key={PlanningStep.Documents}>
              <StepButton
                completed={docsCompleted}
                className="text-left"
                disabled={!selectedTrainings.length || !info || !participantForm}
                optional={activeStep >= PlanningStep.Documents && [...quiz, ...preQuiz, ...otherFiles].length + ' Dosya'}
                onClick={() => activeStep > PlanningStep.Documents && setActiveStep(PlanningStep.Documents)}>
                <Typography variant="h6">{translate('Documents')}</Typography>
              </StepButton>
              <StepContent>
                <Documents />
              </StepContent>
            </Step>
            <Step key={PlanningStep.Preview}>
              <StepButton completed={false} className="text-left" disabled={!selectedTrainings.length || !info || !participantForm}>
                <Typography variant="h6">{translate('Preview')}</Typography>
              </StepButton>
              <StepContent>
                <Preview />
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </Box>
    </PlanningContext.Provider>
  );
}
