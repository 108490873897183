import { Dialog as MUIDialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { ConvertedProfessionalBackgroundPayload, saveProfessionalBackground, updateProfessionalBackground } from 'api/hs/examination';
import Button from 'Common/Button';
import ProfessionalBackgroundForm from 'Common/Forms/ProfessionalBackgroundForm';
import { ProfessionalBackground } from 'model';
import { useAsyncEffect, useXhr } from 'utils';

import { ProfessionalBackgroundContext } from './professional-background.context';

export default function Dialog() {
  const { dialogOpened, setDialogOpened, selectedBackground, refresh } = useContext(ProfessionalBackgroundContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { setValue, trigger, reset, errors, ...methods } = useForm();

  const closeDialog = useCallback(() => setDialogOpened(false), [setDialogOpened]);

  const createBackground = useXhr(saveProfessionalBackground, 'Yeni kayıt oluşturuldu.', 'Kayıt sırasında hata oluştu.');
  const updateBackground = useXhr(
    (payload: ConvertedProfessionalBackgroundPayload) => updateProfessionalBackground(selectedBackground.id, payload),
    'Kayıt güncellendi.',
    'Güncelleme sırasında hata oluştu.',
    [selectedBackground]
  );

  const upsertBackground = useCallback(
    async (form: ProfessionalBackground) => {
      const method = selectedBackground ? updateBackground : createBackground;
      const PROPS_TO_CONVERT_INTO_NULL = ['sector', 'station', 'hireDate', 'quitDate', 'job'];
      // Convert `''` values into `null`
      for (const key of PROPS_TO_CONVERT_INTO_NULL) {
        if ((form as any)[key] !== '') {
          continue;
        }

        (form as any)[key] = null;
      }
      await method({ ...form, employeeId, examinationId });
      setDialogOpened(false);
      refresh();
    },
    [updateBackground, createBackground, employeeId, examinationId, selectedBackground, refresh, setDialogOpened]
  );

  useAsyncEffect(async () => {
    if (!dialogOpened) {
      return;
    }

    await defer();

    if (selectedBackground) {
      for (const [key, value] of Object.entries(selectedBackground)) {
        setValue(key, value);
      }
    } else {
      reset({
        exposures: [] as any,
        companyName: null as any,
        sector: null,
        station: null,
        hireDate: null,
        quitDate: null,
        job: null,
      });
    }
    trigger();
  }, [selectedBackground, dialogOpened, setValue, trigger]);

  return (
    <MUIDialog open={dialogOpened} keepMounted onClose={closeDialog}>
      <DialogTitle>{selectedBackground ? 'Mesleki Özgeçmiş Düzenle' : 'Mesleki Özgeçmiş Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, setValue, trigger, reset, errors }}>
          <form onSubmit={methods.handleSubmit(upsertBackground)}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <ProfessionalBackgroundForm />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="button" onClick={closeDialog} className="mr-2">
                  Vazgeç
                </Button>
                <Button variant="contained" color="primary" type="submit" disabled={!!Object.keys(errors).length}>
                  {selectedBackground ? 'Güncelle' : 'Kaydet'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </MUIDialog>
  );
}
