import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { OpinionPayload } from './Opinion.payload';
import { OpinionResult } from './Opinion.result';

export async function getOpinion(): Promise<OpinionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<OpinionResult[]>(`/firms/hsexam-opinion-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveOpinion(body: OpinionPayload): Promise<OpinionResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/hsexam-opinion-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateOpinion({ id, ...body }: MakeRequired<Partial<OpinionResult>, 'id'>): Promise<OpinionResult> {
  const [error, res] = await tryCatch(HSApi.put<OpinionResult>(`/firms/hsexam-opinion-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteOpinion(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/hsexam-opinion-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminOpinion(): Promise<OpinionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<OpinionResult[]>(`/hs-exam-working-restriction-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminOpinion(body: OpinionPayload): Promise<OpinionResult> {
  const [err, res] = await tryCatch(HSApi.post('/hs-exam-working-restriction-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminOpinion({ id, ...body }: MakeRequired<Partial<OpinionResult>, 'id'>): Promise<OpinionResult> {
  const [error, res] = await tryCatch(HSApi.put<OpinionResult>(`/hs-exam-working-restriction-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminOpinion(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/hs-exam-working-restriction-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
