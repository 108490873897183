import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminPosition, getAdminPosition, PositionResult } from 'api/hs/definitions/workplace-definition/position';
import AdminPositionEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Position/AdminPositionEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  positionName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
  },
  positionType: {
    fontSize: '12px',
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  positionType2: {
    fontSize: '10px',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'rgb(170, 170, 170)',
    fontWeight: 'bold',
  },

  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<PositionResult>) {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <AssignmentIndIcon fontSize="medium" />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.positionName}>{item.positionName}</span>
          </div>
          <div>
            <span className={classes.positionType}>Tehlike Kaynakları : </span>
            <span className={classes.positionType2}>Tehlike ataması yapılmamış.</span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default function AdminPosition() {
  return (
    <BaseDefinitionPage<PositionResult>
      listLabelPropName="positionName"
      fetchData={getAdminPosition}
      FormDialog={AdminPositionEditDialog}
      onDelete={deleteAdminPosition}
      Icon={<DomainIcon />}
      ListItemTemplate={ItemTemplate}
    />
  );
}
