import { asyncMap, defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { EmergencyCaseTeamMemberControllerService, EmergencyCaseTeamMemberDto } from '../../../api/client';
import { getPpeStorehouses } from '../../../api/hs/ppe/ppe-detail-stocks';
import Button from '../../../Common/Button';
import Dialog from '../../../Common/Dialog';
import { AlertContext } from '../../../context/alert.context';
import { dateToInputFormat, useAsyncEffect } from '../../../utils';
import { CaseTeamsDetailContext } from '../case-teams-detail.context';
import ListAddNewCaseTeamMembers from './ListAddNewCaseTeamMembers';
import { NewCaseTeamMembersContext } from './new-case-team-members.context';

interface Props {
  open: boolean;
  onClose(open: boolean): void;
  data: EmergencyCaseTeamMemberDto[];
}

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default function NewCaseTeamMembersIndex({ open, onClose, data }: Props) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [caseTeamMembersData, setCaseTeamMembersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { emergencyCaseTeams } = useContext(CaseTeamsDetailContext);
  const { showMessage } = useContext(AlertContext);

  const onCloseAll = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const onSave = useCallback(async () => {
    try {
      await EmergencyCaseTeamMemberControllerService.saveEmergencyCaseTeam1(
        emergencyCaseTeams?.id,
        caseTeamMembersData?.map(filtreData => {
          return {
            employeeId: filtreData?.employeeId,
            emergencyCaseTeamId: filtreData?.emergencyCaseTeamId,
            joinDate: filtreData?.joinDate,
            quitDate: filtreData?.quitDate === '' ? null : filtreData?.quitDate,
          };
        })
      );
      showMessage('Ekleme işlemi başaralı.', 'success', 'info');
      onClose(true);
      setCaseTeamMembersData([]);
    } catch (e) {
      showMessage('Ekleme işlemi başarısız.', 'error', 'info');
      onClose(false);
    }
  }, [caseTeamMembersData, showMessage, onClose, emergencyCaseTeams?.id]);

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      const modifyData = data.map(employeeItem => ({
        id: guid(),
        employeeId: employeeItem?.employeeId,
        emergencyCaseTeamId: emergencyCaseTeams?.id,
        joinDate: employeeItem?.joinDate,
        quitDate: employeeItem?.quitDate,
      }));
      setCaseTeamMembersData(modifyData);
    }
  }, [data, setCaseTeamMembersData, open]);

  return (
    <Dialog
      actions={
        <>
          {' '}
          <Button onClick={onCloseAll} color="inherit" variant="contained">
            {' '}
            Vazgeç{' '}
          </Button>{' '}
          <Button onClick={onSave} disabled={caseTeamMembersData.length <= 0} color="secondary" variant="contained">
            {' '}
            Listeyi Kaydet{' '}
          </Button>{' '}
        </>
      }
      width="md"
      color="secondary"
      title="Yeni Acil Ekip Personel İşlemleri"
      opened={open}
      onClose={onCloseAll}>
      <NewCaseTeamMembersContext.Provider
        value={{
          newDialogOpened,
          setNewDialogOpened,
          caseTeamMembersData,
          setCaseTeamMembersData,
          setLoading,
          loading,
        }}>
        <ListAddNewCaseTeamMembers />
      </NewCaseTeamMembersContext.Provider>
    </Dialog>
  );
}
