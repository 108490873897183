/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RiskAssessmentEmployeeView } from './RiskAssessmentEmployeeView';
import type { RiskAssessmentFineKinneyScoreView } from './RiskAssessmentFineKinneyScoreView';
import type { RiskAssessmentMatrixScoreView } from './RiskAssessmentMatrixScoreView';
import type { RiskAssessmentPositionView } from './RiskAssessmentPositionView';
import type { RiskAssessmentStationView } from './RiskAssessmentStationView';

export type RiskAssessmentHazardView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    riskAssessmentId?: string;
    departmentId?: string;
    companyId?: string;
    hazardCategoryId?: string;
    precautionLevelId?: string;
    riskAssessmentType?: RiskAssessmentHazardView.riskAssessmentType;
    lastModifiedByName?: string;
    date?: string;
    companyName?: string;
    companySsiId?: string;
    companyAddress?: string;
    companyHazardClass?: string;
    departmentName?: string;
    workDescription?: string;
    hazardCategoryName?: string;
    hazardDescription?: string;
    riskDescription?: string;
    affected?: string;
    precautionLevelName?: string;
    description?: string;
    revisePeriod?: number;
    reviseDate?: string;
    userName?: string;
    stationName?: string;
    positionName?: string;
    riskAssessmentEmployees?: Array<RiskAssessmentEmployeeView>;
    riskAssessmentMatrixScore?: RiskAssessmentMatrixScoreView;
    riskAssessmentFineKinneyScore?: RiskAssessmentFineKinneyScoreView;
    stations?: Array<RiskAssessmentStationView>;
    positions?: Array<RiskAssessmentPositionView>;
}

export namespace RiskAssessmentHazardView {

    export enum riskAssessmentType {
        MATRIX = 'MATRIX',
        FINE_KINNEY = 'FINE_KINNEY',
    }


}
