import React, { useState } from 'react';

import { TrainingEmployeeViewContentResult } from 'api/hs/trainings/TrainingEmployeeFilter';

import EmployeeTrainingList from './List';
import { EmployeeTrainingDetailContext } from './training-employee.context';

export default function Index() {
  const [showDialogEmployeeTraining, setShowDialogEmployeeTraining] = useState(false);
  const [selectedData, setSelectedData] = useState<TrainingEmployeeViewContentResult>(null);

  return (
    <EmployeeTrainingDetailContext.Provider
      value={{
        showDialogEmployeeTraining,
        setShowDialogEmployeeTraining,
        selectedData,
        setSelectedData,
      }}>
      <EmployeeTrainingList />
    </EmployeeTrainingDetailContext.Provider>
  );
}
