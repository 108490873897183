/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StationDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    predefinedId?: string;
    source?: StationDefinitionDto.source;
    stationName?: string;
    status?: StationDefinitionDto.status;
    label?: string;
}

export namespace StationDefinitionDto {

    export enum source {
        SYSTEM = 'SYSTEM',
        USER_DEFINED = 'USER_DEFINED',
    }

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
