import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { LoginPayload } from './login.payload';
import { LoginResult } from './login.result';

export async function login(body: LoginPayload): Promise<LoginResult> {
  const [error, res] = await tryCatch(HSApi.post<LoginResult>(`/account/login`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
