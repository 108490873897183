import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { dateSort, MakeRequired } from 'utils';

import { getUserById } from '../../users';
import { DefaultNurseOperationsPayload } from './default-nurse-operations.payload';
import { DefaultNurseOperationsResult } from './default-nurse-operations.result';

export async function getDefaultNurseOperation(employeeId: string): Promise<DefaultNurseOperationsResult[]> {
  let res;
  try {
    res = await HSApi.get<DefaultNurseOperationsResult[]>(`/nurse-operations?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function getByIdDefaultNurseOperation(id: string): Promise<DefaultNurseOperationsResult> {
  let res;
  try {
    res = await HSApi.get<DefaultNurseOperationsResult>(`/nurse-operations/${id}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return { ...res.data, userData: res?.data?.createdBy ? await getUserById(res?.data?.createdBy) : '' };
}

export async function saveDefaultNurseOperation(body: DefaultNurseOperationsPayload): Promise<DefaultNurseOperationsResult> {
  const [err, res] = await tryCatch(HSApi.post('/nurse-operations', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
    throw err.response.data.message;
  }

  return res.data;
}

export async function updateDefaultNurseOperation({
  id,
  ...body
}: MakeRequired<Partial<DefaultNurseOperationsResult>, 'id'>): Promise<DefaultNurseOperationsResult> {
  const [error, res] = await tryCatch(HSApi.put<DefaultNurseOperationsResult>(`/nurse-operations/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteDefaultNurseOperation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/nurse-operations/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function exportNurseOperation() {
  const res = await HSApi.get(`/nurse-operations/view/export`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sp-islemleri.xlsx';
  a.click();
}
