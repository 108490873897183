import React, { useCallback, useContext } from 'react';

import { PrescriptionContext } from '../../prescription.context';
import ConvertToEPrescriptionForm from '../form/ConvertToEPrescriptionForm';

export default function ConvertToEPrescriptionDialog() {
  const { convertEPrescriptionDialogOpened, setConvertEPrescriptionEditDialogOpened, selectedPrescription, reload } = useContext(
    PrescriptionContext
  );

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setConvertEPrescriptionEditDialogOpened(false);
    },
    [reload, setConvertEPrescriptionEditDialogOpened]
  );

  return <ConvertToEPrescriptionForm open={convertEPrescriptionDialogOpened} onClose={closeDialog} data={selectedPrescription} />;
}
