import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreIcon from '@material-ui/icons/MoreVert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { DrillControllerService, DrillDto } from '../../api/client';
import DeleteDialogIcon from '../../Common/DeleteDialogIcon';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { DialogContext } from '../../context/dialog.context';
import { ResourceContext } from '../../context/resource.context';
import { useFetch } from '../../utils';
import DrillActionListAdd from './DrillActionListAdd';
import DrillForm from './DrillForm';
import FilesAddForm from './Files';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function Drill() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { data, fetch } = useFetch(() => DrillControllerService.getAllDrills(), [], { setLoading });
  const { companies } = useContext(ResourceContext);
  const { openWarnDialog } = useContext(DialogContext);
  const [actionOpenDialog, setActionDialog] = useState(false);
  const [actionSelectedData, setActionSelectedData] = useState(null);
  const [fileDialogOpened, setFileDialogOpened] = useState(false);
  const [fileSelectedData, setFileSelectedData] = useState(null);

  const fileOnClose = useCallback(() => {
    setFileDialogOpened(false);
    setFileSelectedData(null);
  }, [setFileDialogOpened, setFileSelectedData]);

  const fileOnOpen = useCallback(
    (item: DrillDto) => {
      setFileSelectedData(item);
      setFileDialogOpened(true);
    },
    [setFileSelectedData, setFileDialogOpened]
  );
  const { showMessage } = useContext(AlertContext);
  const showOpenDialog = useCallback(() => {
    closePopover();
    setOpen(true);
  }, [setOpen, closePopover]);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await fetch();
      }
      setSelectedData(null);
      setOpen(false);
    },
    [setOpen, fetch]
  );

  const onEdit = useCallback(
    (item: any) => {
      setSelectedData(item);
      setOpen(true);
    },
    [setSelectedData, setOpen]
  );

  const onOpenActionDialog = useCallback(
    (item: DrillDto) => {
      setActionDialog(true);
      setActionSelectedData(item);
    },
    [setActionDialog, setActionSelectedData]
  );

  const onCloseActionDialog = useCallback(() => {
    setActionDialog(false);
    setActionSelectedData(null);
  }, [setActionDialog, setActionSelectedData]);

  const onDelete = useCallback(
    async (drill: any) => {
      const submitted = await openWarnDialog({
        text: `${drill.subject} - konulu kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await DrillControllerService.deleteDrill(drill.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [fetch, openWarnDialog, showMessage]
  );

  return (
    <>
      <Toolbar color="secondary">
        <Typography variant="h6">Tatbikatlar</Typography>
        <span className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <IconButton color="inherit" onClick={openPopover}>
            <MoreIcon />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 35, horizontal: -175 }}
            keepMounted
            open={!!anchorEl}
            onClose={closePopover}>
            <List>
              <ListItem onClick={showOpenDialog} className="cursor-pointer">
                <ListItemIcon>
                  <PostAddIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Yeni Tatbikat</Typography>
              </ListItem>
            </List>
          </Popover>
        </div>
      </Toolbar>
      <div className="p-1">
        <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek  Kayıt Bulunamadı">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  {' '}
                  Şirket
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  Tatbikat Konusu
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  Tatbikat Tarihi
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                  Tatbikat Geçerlilik Tarihi
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                  Tatbikat Aksiyon
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                  Dosya
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="right">
                  İşlemler
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(row => (
                <TableRow hover key={row?.id}>
                  <TableCell align="left">{findByKey(companies, 'id', row?.companyId)?.companyName}</TableCell>
                  <TableCell align="left">{row?.subject}</TableCell>
                  <TableCell align="center">{row?.executionDate?.split('-')?.reverse()?.join('-')}</TableCell>
                  <TableCell align="center">{row?.nextExecutionDate?.split('-')?.reverse()?.join('-')}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => onOpenActionDialog(row)}>
                      <Icon className="icon-human-handsup" />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => fileOnOpen(row)}>
                      <FileCopyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <div>
                      <IconButton onClick={() => onEdit(row)} edge="end" aria-label="edit">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => onDelete(row)} edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </IndicatorWrapper>
      </div>
      <DrillForm fetch={fetch} open={open} onClose={closeDialog} data={selectedData} />
      <DrillActionListAdd open={actionOpenDialog} onClose={onCloseActionDialog} data={actionSelectedData} />
      <FilesAddForm open={fileDialogOpened} onClose={fileOnClose} data={fileSelectedData} />
    </>
  );
}
