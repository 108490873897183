import { noop } from '@thalesrc/js-utils';
import { DependencyList, useEffect } from 'react';

type VoidFunction = () => void;

export default async function useAsyncEffect(callback: () => Promise<void | VoidFunction>, deps?: DependencyList) {
  useEffect(() => {
    const promise = callback() as Promise<VoidFunction>;

    return () => {
      (promise || Promise.resolve(noop)).then((onDestroy = noop) => onDestroy());
    };
  }, deps); // eslint-disable-line
}
