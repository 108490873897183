import React, { useState } from 'react';

import { RiskAssessmentHazardView } from '../../api/client';
import RiskAssessmentHazard from './ListAdd';
import { RiskAssessmentsListContext } from './risk-assessments-list.context';

interface Props {
  riskAssessmentType: string;
}
export default function RiskAssessmentIndex({ riskAssessmentType }: Props) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<RiskAssessmentHazardView>(null);
  const [actionDialogOpened, setActionDialogOpened] = useState(false);

  return (
    <RiskAssessmentsListContext.Provider
      value={{
        riskAssessmentType,
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        actionDialogOpened,
        setActionDialogOpened,
        setSelectedData,
      }}>
      <RiskAssessmentHazard />
    </RiskAssessmentsListContext.Provider>
  );
}
