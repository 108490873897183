import React, { useCallback } from 'react';

import { getWorkEquipmentJobStatus } from 'api/hs/work-equipment/work-equipment-job-status/work-equipment-job-status';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { HSTableProps } from '../../../../Common/Table/HSTable/hs-table.props';
import Toolbar from '../Toolbar';
import { COLUMNS } from './columns';
import EditDialog from './EditDialog';
import FileDialog from './FileDialog';
import JobDetailDialog from './JobDetailDialog';
import ListTable from './ListTableData';

export default function WorkEquipmentJobStatus() {
  const fetchWorkEquipmentJobStatusList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getWorkEquipmentJobStatus(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <>
      <HSTableAndPaginationAndTableContextProvider fetcher={fetchWorkEquipmentJobStatusList} columns={COLUMNS}>
        <Toolbar />
        <ListTable />
        <EditDialog />
        <FileDialog />
        <JobDetailDialog />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
