/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FineKinneyPossibilityDefinitionDto } from '../models/FineKinneyPossibilityDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FineKinneyPossibilityDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static updateFineKinneyPossibilityDefinitions(
        id: string,
        requestBody: FineKinneyPossibilityDefinitionDto,
    ): CancelablePromise<FineKinneyPossibilityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/fine-kinney-possibility-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllFineKinneyPossibilityDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/fine-kinney-possibility-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmFineKinneyPossibilityDefinitions(
        id: string,
        requestBody: FineKinneyPossibilityDefinitionDto,
    ): CancelablePromise<FineKinneyPossibilityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/fine-kinney-possibility-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmFineKinneyPossibilityDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/fine-kinney-possibility-definitions/${id}`,
        });
    }

    /**
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFineKinneyPossibilityDefinitions(): CancelablePromise<Array<FineKinneyPossibilityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/fine-kinney-possibility-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static saveFineKinneyPossibilityDefinitions(
        requestBody: FineKinneyPossibilityDefinitionDto,
    ): CancelablePromise<FineKinneyPossibilityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/fine-kinney-possibility-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmFineKinneyPossibilityDefinitions(): CancelablePromise<Array<FineKinneyPossibilityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/fine-kinney-possibility-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyPossibilityDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmFineKinneyPossibilityDefinitions(
        requestBody: FineKinneyPossibilityDefinitionDto,
    ): CancelablePromise<FineKinneyPossibilityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/fine-kinney-possibility-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}