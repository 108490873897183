import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { TrainingMainGroupPayload } from './training-main-group.payload';
import { TrainingMainGroupResult } from './training-main-group.result';

export async function getTrainingMainGroup(): Promise<TrainingMainGroupResult[]> {
  const [error, res] = await tryCatch(HSApi.get<TrainingMainGroupResult[]>(`/firms/trainings/main-group-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveTrainingMainGroup(body: TrainingMainGroupPayload): Promise<TrainingMainGroupResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/trainings/main-group-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateTrainingMainGroup({
  id,
  ...body
}: MakeRequired<Partial<TrainingMainGroupResult>, 'id'>): Promise<TrainingMainGroupResult> {
  const [error, res] = await tryCatch(HSApi.put<TrainingMainGroupResult>(`/firms/trainings/main-group-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteTrainingMainGroup(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/trainings/main-group-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminTrainingMainGroup(): Promise<TrainingMainGroupResult[]> {
  const [error, res] = await tryCatch(HSApi.get<TrainingMainGroupResult[]>(`/trainings/main-group-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminTrainingMainGroup(body: TrainingMainGroupPayload): Promise<TrainingMainGroupResult> {
  const [err, res] = await tryCatch(HSApi.post('/trainings/main-group-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminTrainingMainGroup({
  id,
  ...body
}: MakeRequired<Partial<TrainingMainGroupResult>, 'id'>): Promise<TrainingMainGroupResult> {
  const [error, res] = await tryCatch(HSApi.put<TrainingMainGroupResult>(`/trainings/main-group-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminTrainingMainGroup(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/main-group-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
