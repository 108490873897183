import { XYSeries } from '@amcharts/amcharts4/charts';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_tr_TR from '@amcharts/amcharts4/lang/tr_TR';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import React, { useLayoutEffect, useRef } from 'react';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

interface ChartProps {
  chartName: string;
  data: any[];
  // categoryFieldName: string;
  categoryFieldByIdNameMap?: Map<string, string>;
}

const ClusteredXYColumnChart = (props: ChartProps) => {
  const chartRef = useRef(null);
  useLayoutEffect(() => {
    const chart = am4core.create(props.chartName, am4charts.XYChart);
    chart.colors.step = 2;
    chart.language.locale = am4lang_tr_TR;
    chart.legend = new am4charts.Legend();
    chart.exporting.menu = new am4core.ExportMenu();

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'category';
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.maxPrecision = 0;

    function createSeries(value: string, name: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'category';
      series.name = name;
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';

      series.events.on('hidden', arrangeColumns);
      series.events.on('shown', arrangeColumns);

      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = '{valueY}';
      bullet.label.fill = am4core.color('#ffffff');

      return series;
    }

    chart.data = props.data;
    const fields = new Set<string>();
    chart.data.forEach(d =>
      Object.keys(d)
        .filter(o => o !== 'category')
        .forEach(f => fields.add(f))
    );
    fields.forEach(field => {
      if (props.categoryFieldByIdNameMap) {
        createSeries(field, props.categoryFieldByIdNameMap.get(field));
      } else {
        createSeries(field, field);
      }
    });

    function arrangeColumns() {
      const series = chart.series.getIndex(0);

      const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        const x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
        const x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
        const delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          const middle = chart.series.length / 2;

          let newIndex = 0;
          // tslint:disable-next-line:only-arrow-functions
          chart.series.each(function (seriesData: XYSeries) {
            if (!seriesData.isHidden && !seriesData.isHiding) {
              seriesData.dummyData = newIndex;
              newIndex++;
            } else {
              seriesData.dummyData = chart.series.indexOf(seriesData);
            }
          });
          const visibleCount = newIndex;
          const newMiddle = visibleCount / 2;

          // tslint:disable-next-line:only-arrow-functions
          chart.series.each(function (seriesData: XYSeries) {
            const trueIndex = chart.series.indexOf(seriesData);
            const newSeriesIndex = seriesData.dummyData;

            const dx = (newSeriesIndex - trueIndex + middle - newMiddle) * delta;

            seriesData.animate({ property: 'dx', to: dx }, seriesData.interpolationDuration, seriesData.interpolationEasing);
            seriesData.bulletsContainer.animate(
              {
                property: 'dx',
                to: dx,
              },
              seriesData.interpolationDuration,
              seriesData.interpolationEasing
            );
          });
        }
      }
    }

    chartRef.current = chart;
    return () => {
      chart.dispose();
    };
  }, [props.data, props.chartName, props.categoryFieldByIdNameMap]);

  return <div id={props.chartName} style={{ width: '100%', height: '600px' }} />;
};

export default ClusteredXYColumnChart;
