import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { AnalysisDetailAggregate } from './detail.model';
import { AnalysisDetailResult } from './detail.result';

export async function getAnalysisDetail(id: string): Promise<AnalysisDetailAggregate[]> {
  let res;
  try {
    res = await HSApi.get<AnalysisDetailAggregate[]>(`/analysis/${id}/analysis-details`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res?.data;
}

export async function updateAnalysisDetail(id: string, body: AnalysisDetailResult[]): Promise<AnalysisDetailResult[]> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisDetailResult[]>(`/analysis/${id}/analysis-details`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response;
  }

  return res.data;
}
