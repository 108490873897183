import { createContext } from 'react';

import { BuildingDefinitionDto } from '../../../../api/client';

export interface BuildingsDefinitionContextType {
  buildingDefinitions: BuildingDefinitionDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: BuildingDefinitionDto;

  setSelectedData(data: BuildingDefinitionDto): void;
}

export const BuildingsDefinitionContext = createContext<BuildingsDefinitionContextType>(null);
