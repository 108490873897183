import { InputBaseComponentProps, makeStyles } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField';
import React, { useMemo } from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

import { combineFunctions } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  helper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));

interface Props {
  name: string;
  label?: string;
  type?: 'text' | 'password' | 'email' | 'date' | 'number' | 'time';
  variant?: 'standard' | 'outlined' | 'filled';
  rows?: number;
  rules?: ValidationRules;
  className?: string;
  mask?: (RegExp | string)[];
  maskError?: string;
  required?: boolean;
  shrinkAlways?: boolean;
  disabled?: boolean;
  onChange(data: string): void;
}

export default function TextFieldWithOnChange({
  name,
  type = 'text',
  label = '',
  variant = 'standard',
  rows = 0,
  rules = {},
  className = '',
  mask,
  maskError = 'Hatalı girdi',
  required = false,
  disabled = false,
  shrinkAlways = false,
  onChange,
}: Props) {
  const classes = useStyles();
  const { register, getValues, errors, setValue } = useFormContext();
  const value = getValues(name);

  const mergedClassName = useMemo(() => `${classes.root} ${className}`, [classes.root, className]);

  const inputComponent = useMemo(
    () => ({ inputRef, ...props }: InputBaseComponentProps) => (
      <MaskedInput
        mask={mask}
        {...props}
        render={(ref, inputProps) => (
          <input
            {...inputProps}
            ref={combineFunctions(inputRef, ref)}
            onChange={e => {
              inputProps.onChange(e);
              setValue(name, e.target.value);
            }}
            disabled={disabled}
          />
        )}
      />
    ),
    [mask, name, setValue, disabled]
  );

  rules = useMemo(() => {
    return {
      ...rules,
      ...(!required
        ? null
        : {
            required: 'Bu alan zorunlu',
          }),
      validate: {
        ...rules.validate,
        ...(!mask
          ? null
          : {
              mask: val =>
                !val || mask.every((rule, index) => (typeof rule === 'string' ? rule === val[index] : rule.test(val[index]))) || maskError,
            }),
      },
    };
  }, [rules, mask, maskError, required]);

  return (
    <MUITextField
      fullWidth
      InputLabelProps={{ shrink: shrinkAlways || type === 'date' || type === 'time' || !!value }}
      InputProps={{ ...(mask ? { inputComponent, disabled } : { disabled }) }}
      error={!!errors[name]}
      inputRef={register(rules)}
      helperText={errors[name]?.message}
      onChange={e => onChange(e)}
      name={name}
      type={type}
      label={label}
      multiline={rows > 0}
      rows={rows}
      variant={variant}
      className={mergedClassName}
      FormHelperTextProps={{ className: classes.helper }}
      required={required}
    />
  );
}
