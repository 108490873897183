import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetAllergiesResult } from './get-allergies.result';

export async function getAllergies(): Promise<GetAllergiesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetAllergiesResult[]>(`/firms/allergy-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
