import { createContext } from 'react';

import { TrainingDefinitionsModel, TrainingDefinitionsResult } from 'api/hs/definitions/trainings/training-definitions';

export interface TrainingDefinitionsContextType {
  trainingDefinitions: TrainingDefinitionsResult[];

  loading: boolean;

  reload(): void;

  setLoading(loading: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: TrainingDefinitionsModel;

  setSelectedData(data: TrainingDefinitionsModel): void;
}

export const TrainingDefinitionsContext = createContext<TrainingDefinitionsContextType>(null);
