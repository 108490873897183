/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type QuestionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    questionText?: string;
    questionType?: QuestionDto.questionType;
    templateId?: string;
    optionSetId?: string;
}

export namespace QuestionDto {

    export enum questionType {
        TEXT = 'TEXT',
        DATE = 'DATE',
        SINGLE_CHOICE = 'SINGLE_CHOICE',
    }


}
