import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { SaveSurgeryHistoryPayload, UpdateSurgeryHistoryPayload } from './surgery-history.payload';
import { SurgeryHistoryResult } from './surgery-history.result';

export async function getSurgeryHistory(employeeId: string, examinationId: string): Promise<SurgeryHistoryResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<SurgeryHistoryResult>(`/surgery-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<SurgeryHistoryResult>(`/surgery-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
    throw e;
  }

  return dateSort(res.data);
}

export async function saveSurgeryHistory(body: SaveSurgeryHistoryPayload): Promise<SurgeryHistoryResult> {
  const [err, res] = await tryCatch(HSApi.post('/surgery-histories', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function updateSurgeryHistory(historyId: string, body: UpdateSurgeryHistoryPayload): Promise<SurgeryHistoryResult> {
  const [err, res] = await tryCatch(HSApi.put(`/surgery-histories/${historyId}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function deleteSurgeryHistory(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/surgery-histories/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
