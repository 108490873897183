import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import PpeOutputsFormDialog from '../../../../../../PPE/ppe-outputs/ppe-new-output';
import { PpeOutputsGroupContext } from '../ppe-outputs-group.context';

export default function Add() {
  const { setNewDebitDialogOpened, newDebitDialogOpened, reload } = useContext(PpeOutputsGroupContext);

  const closeDialog = useCallback(
    success => {
      setNewDebitDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDebitDialogOpened]
  );

  const onClick = () => {
    setNewDebitDialogOpened(true);
  };
  return (
    <>
      <header className="text-right mr-1">
        <Button startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          Zimmet Ekle
        </Button>
      </header>
      <PpeOutputsFormDialog onClose={closeDialog} open={newDebitDialogOpened} />
    </>
  );
}
