import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { getUserById } from '../users';
import { SaveExaminationPayload } from './examinations.payload';
import { ExaminationResultItem, ExaminationsResult } from './examinations.result';

export async function getExaminations(employeeId: string): Promise<ExaminationsResult> {
  let res;
  try {
    res = await HSApi.get<ExaminationsResult>(`/examinations?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getExaminationById(id: ExaminationResultItem['id']): Promise<ExaminationResultItem> {
  const [err, res] = await tryCatch(HSApi.get<ExaminationResultItem>('/examinations/' + id));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return { ...res.data, userData: res?.data?.createdBy ? await getUserById(res?.data?.createdBy) : '' };
}

export async function saveExamination(body: SaveExaminationPayload): Promise<ExaminationResultItem> {
  const [err, res] = await tryCatch(HSApi.post('/examinations', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
    throw err.response.data.message;
  }

  return res.data;
}

export async function deleteExamination(id: ExaminationResultItem['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/examinations/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getExaminationByEmployeeId(
  employeeId: ExaminationResultItem['employeeId'],
  page = 0,
  size = 250,
  order: null | string
) {
  const payload = {
    examinationFilter: { employeeIds: [employeeId] },
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const [err, res] = await tryCatch(HSApi.post(`/examinations/views`, payload));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data.content;
}

export async function patchExaminationById(examinationId: string, body: any) {
  const [err, res] = await tryCatch(HSApi.patch(`/examinations/${examinationId}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
