import { Grid } from '@material-ui/core';
import React from 'react';

import { FineKinneyHazardScoreDefinitionDto } from 'api/client';
import { updateFirmFineKinneyHazardScoreDefinitionUpdate } from 'api/hs/risk-fineKinney';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

interface Props {
  open: boolean;
  data: FineKinneyHazardScoreDefinitionDto;

  onClose(success: boolean): void;
}

export default function FineKinneyScoreDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<FineKinneyHazardScoreDefinitionDto>
      open={open}
      onClose={onClose}
      onUpdate={updateFirmFineKinneyHazardScoreDefinitionUpdate}
      data={data}
      createTitle="Fine-Kinney Matris Skor Ekleme"
      updateTitle="Fine-Kinney Matris Skor Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Risk Adı" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="score" label="Risk Skoru" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="description" label="Eylem" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="deadline" label="Termin Süresi" />
      </Grid>
    </BaseDefinitionForm>
  );
}
