import React, { useContext, useMemo, useState } from 'react';

import { FormContext } from '../Form/form.context';
import Favourites from './Favourites';
import { Icd10Context, Icd10ContextType } from './icd10.context';
import Input from './Input';
import Selection from './Selection';

interface Props {
  name: string;
  disabled?: boolean;
}

export default function Icd10Selection({ name, disabled = false }: Props) {
  const [selectionOpened, setSelectionOpened] = useState(false);
  const [favouritesOpened, setFavouritesOpened] = useState(false);
  const { readonly } = useContext(FormContext);

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const context = useMemo<Icd10ContextType>(
    () => ({ name, disabled: notEditable, selectionOpened, setSelectionOpened, favouritesOpened, setFavouritesOpened }),
    [name, selectionOpened, notEditable, favouritesOpened]
  );

  return (
    <Icd10Context.Provider value={context}>
      <Input />
      <Selection />
      <Favourites />
    </Icd10Context.Provider>
  );
}
