import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import {
  getPolyclinicExamOpinions,
  PolyclinicExamOpinionsModel,
  PolyclinicExamOpinionsResult,
} from 'api/hs/examination/polyclinic-exam-opinions';
import { useAsyncEffect } from 'utils';

import { PolyclinicExamOpinionContext } from './polyclinic-exam-opinions.context';
import PolyclinicExamOpinion from './polyclinicExamOpinion';

export default function Index() {
  const [polyclinicExamOpinions, setPolyclinicExamOpinions] = useState<PolyclinicExamOpinionsResult>(null);
  const [loading, setLoading] = useState({});
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PolyclinicExamOpinionsModel>(null);
  const { examinationId } = useParams<{ examinationId: string }>();

  const fetch = useCallback(async () => {
    setLoading(true);
    const result = await getPolyclinicExamOpinions(examinationId);
    setPolyclinicExamOpinions(result);
    setLoading(false);
  }, [examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <PolyclinicExamOpinionContext.Provider
      value={{
        polyclinicExamOpinions,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
      }}>
      <PolyclinicExamOpinion />
    </PolyclinicExamOpinionContext.Provider>
  );
}
