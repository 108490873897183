import { createContext } from 'react';

import { SystemCheckModel } from 'api/hs/examination/system-checks';

export interface SystemCheckContextType {
  systemCheck: SystemCheckModel[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: SystemCheckModel;

  setSelectedData(data: SystemCheckModel): void;
}

export const SystemCheckContext = createContext<SystemCheckContextType>(null);
