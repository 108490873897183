import React from 'react';

import BuildingFloorPartsDefinitionsAdd from './Add';
import BuildingFloorPartsDefinitionsList from './List';

export default function Index() {
  return (
    <>
      <BuildingFloorPartsDefinitionsAdd />
      <BuildingFloorPartsDefinitionsList />
    </>
  );
}
