import { IconButton } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { deleteOccupationalSafetyRegistriesPages } from '../../api/hs/occupational-safety-registries /occupational-safety-registries';
import PageNumbersForm from '../../Common/Forms/OccupationalSafetyRegistries/Occupation/RegistriesForm/PageNumbersForm';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { AlertContext } from '../../context/alert.context';
import { DialogContext } from '../../context/dialog.context';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '300px',
    right: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
  table: {
    minWidth: 650,
  },
}));

export default function RegistriesTable({ data, registryId, fetch, loading }: any) {
  const history = useHistory();
  const classes = useStyles();
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const openDeleteDialog = useCallback(
    async deletedItem => {
      const submitted = await openWarnDialog({
        text: `${deletedItem?.label} - uyarısını silmek istediğinize emin misiniz ?`,
      });
      try {
        if (submitted) {
          await deleteOccupationalSafetyRegistriesPages(deletedItem?.pageId);
          showMessage('Silme işlemi başarılı.', 'success');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error');
      }
    },
    [openWarnDialog, showMessage, fetch]
  );
  return (
    <TableContainer component={Paper}>
      <Fab className={classes.fabButton} onClick={() => setOpen(true)} color="primary">
        <AddIcon className={classes.addButton} />
      </Fab>
      <IndicatorWrapper loading={loading} data={data} errorMessage={'Listelenecek Kayıt bulunamadı.'}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sayfa no</TableCell>
              <TableCell align="left">Tespit Sayısı</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(row => (
              <TableRow hover key={row.pageId}>
                <TableCell
                  align="left"
                  onClick={() =>
                    history.push(
                      `/occupational-safety-registries/query/companies/${row.companyId}/books/${registryId}/detections/${row.pageId}`
                    )
                  }>
                  {row.pageNumber}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    history.push(
                      `/occupational-safety-registries/query/companies/${row.companyId}/books/${registryId}/detections/${row.pageId}`
                    )
                  }>
                  {row.detectionCount}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => openDeleteDialog(row)} aria-label="delete">
                    <DeleteIcon fontSize={'default'} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}{' '}
          </TableBody>
        </Table>
      </IndicatorWrapper>
      <PageNumbersForm open={open} onClose={() => setOpen(false)} data={data} registryId={registryId} fetch={fetch} />
    </TableContainer>
  );
}
