import { Chip, makeStyles } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import React, { useContext } from 'react';

import { EmployeeStatus } from 'model';

import { EmployeeContext } from '../employee.context';

const useStyles = makeStyles(theme => ({
  container: {
    '&:empty': {
      padding: '0 !important',
    },
  },
  chip: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',

    '& svg, & [class*="icon-"]': {
      color: 'inherit',
    },

    '& [class*="icon-"]': {
      fontSize: 'large',
    },
  },
  red: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  lightGreen: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  green: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
  },
  pink: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.common.white,
  },
  lightBlue: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  darkBlue: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  lightYellow: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
  },
  darkYellow: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
  lime: {
    backgroundColor: '#ffc400',
    color: theme.palette.common.white,
  },
}));

export default function Flags() {
  const classes = useStyles();
  const { employee } = useContext(EmployeeContext);

  return (
    <div className={`p-2 ${classes.container}`}>
      {employee?.status === EmployeeStatus?.Archived && (
        <Chip className={`${classes.chip} ${classes.red}`} label="Arşivlenmiş" icon={<ArchiveIcon />} />
      )}
      {employee?.hasDisability && (
        <Chip className={`${classes.chip}`} label="Engelli" icon={<i className="icon-wheelchair-accessibility" />} />
      )}

      {employee?.warningLabels &&
        employee?.warningLabels?.map(e => (
          <Chip
            key={e.label}
            className={e.warningFlagType === 'ICD10_ALL_EXAM' ? `${classes.chip} ${classes.red}` : `${classes.chip} ${classes.lime}`}
            label={`${e.label}`}
            icon={<i className="icon-stethoscope" />}
          />
        ))}
      {!!employee?.chronicDiseaseData && (
        <Chip className={`${classes.chip} ${classes.green}`} label="Kronik Hastalık" icon={<i className="icon-chart-areaspline" />} />
      )}
      {!!employee?.allergiesId?.length && (
        <Chip className={`${classes.chip} ${classes.lightGreen}`} label="Alerji" icon={<i className="icon-chart-bubble" />} />
      )}
      {employee?.pregnancyHistory?.some(
        preg => preg.pregnancyStateDefinitionId === 33 && new Date(preg?.birthDate).getTime() > new Date().getTime()
      ) && <Chip className={`${classes.chip} ${classes.pink}`} label="Gebelik" icon={<i className="icon-human-pregnant" />} />}
      {false && <Chip className={`${classes.chip} ${classes.lightBlue}`} label="Emziren" icon={<i className="icon-human-pregnant" />} />}
      {new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 18 < new Date(employee.birthDate).getTime() && (
        <Chip className={`${classes.chip} ${classes.lightYellow}`} label="18 Yaş Altı" icon={<i className="icon-human" />} />
      )}
      {new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 50 > new Date(employee.birthDate).getTime() && (
        <Chip className={`${classes.chip} ${classes.darkYellow}`} label="50 Yaş Üstü" icon={<i className="icon-human" />} />
      )}
      {employee?.pregnancyHistory?.some(
        preg =>
          preg.pregnancyStateDefinitionId === 34 &&
          preg.maternityLceave &&
          new Date(preg?.maternityLeave.startDate).getTime() < new Date().getTime() &&
          new Date(preg?.maternityLeave.endDate).getTime() > new Date().getTime()
      ) && <Chip className={`${classes.chip} ${classes.darkBlue}`} label="Gebelik İzninde" icon={<i className="icon-human-pregnant" />} />}
    </div>
  );
}
