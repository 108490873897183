import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getEmployeeTrainingListPlanning } from 'api/hs/definitions/trainings/employee-training/employee-training-list-planning';
import { useFetch } from 'utils';

import { TrainingPlanningListContext } from './training-planning-list.context';
import TrainingPlanning from './TrainingPlanning';
export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [newDialogOpenedTrainingInclude, setNewDialogOpenedTrainingInclude] = useState(false);
  const { employeeId } = useParams<{ employeeId: string }>();

  const { data, fetch } = useFetch(() => getEmployeeTrainingListPlanning(employeeId), [], { setLoading });

  return (
    <TrainingPlanningListContext.Provider
      value={{
        employeeTrainingListPlanning: data,
        reload: fetch,
        loading,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        newDialogOpenedTrainingInclude,
        setNewDialogOpenedTrainingInclude,
      }}>
      <TrainingPlanning />
    </TrainingPlanningListContext.Provider>
  );
}
