import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { GetPpeOutputsListPayload, PpeOutputFilters } from './ppe-outputs.payload';
import { PpeOutputsResult } from './ppe-outputs.result';

export async function getPpeOutputsList(page = 0, size = 20, order: null | string, filters: PpeOutputFilters): Promise<PpeOutputsResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof PpeOutputFilters)[] = ['debitDateMax', 'debitDateMin', 'refundDateMax', 'refundDateMin'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.debitDateMax) {
    parsedFilters.debitDateMax = new Date(parsedFilters.debitDateMax).toISOString();
  }

  if (parsedFilters.debitDateMin) {
    parsedFilters.debitDateMin = new Date(parsedFilters.debitDateMin).toISOString();
  }

  if (parsedFilters.refundDateMax) {
    parsedFilters.refundDateMax = new Date(parsedFilters.refundDateMax).toISOString();
  }
  if (parsedFilters.refundDateMin) {
    parsedFilters.refundDateMin = new Date(parsedFilters.refundDateMin).toISOString();
  }
  const payload: GetPpeOutputsListPayload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<PpeOutputsResult>(`/ppe-outputs/views`, payload);

  return res.data;
}

export async function exportPpeOutputsList(filters: PpeOutputFilters): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof PpeOutputFilters)[] = ['debitDateMax', 'debitDateMin', 'refundDateMax', 'refundDateMin'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    filter: parsedFilters,
  };

  const res = await HSApi.post<PpeOutputsResult>(`/ppe-outputs/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'zimmet-listesi.xlsx';
  a.click();
}

export async function saveOutputSave(body: any) {
  const [err, res] = await tryCatch(HSApi.post('/ppe-outputs', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
