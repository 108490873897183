/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BodySystemsCheckDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    predefinedId?: string;
    source?: BodySystemsCheckDefinitionDto.source;
    label?: string;
    description?: string;
    defaultNegativeResponse?: string;
    status?: BodySystemsCheckDefinitionDto.status;
}

export namespace BodySystemsCheckDefinitionDto {

    export enum source {
        SYSTEM = 'SYSTEM',
        USER_DEFINED = 'USER_DEFINED',
    }

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
