import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';

import { saveOccupationalSafetyRegistriesPages } from '../../../../../api/hs/occupational-safety-registries /occupational-safety-registries';
import { OccupationalSafetyRegistriesResult } from '../../../../../api/hs/occupational-safety-registries /occupational-safety-registries.result';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: OccupationalSafetyRegistriesResult;

  registryId: string;

  fetch(): void;
}

export default function PageNumbersForm({ open, onClose, data, registryId, fetch }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);

  const submit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveOccupationalSafetyRegistriesPages({ ...values, registryId });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          await fetch();
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data, registryId, fetch]
  );

  return (
    <Dialog color="primary" opened={open} onClose={onClose} title={'Sayfa numarası ekle'}>
      <Form onSubmit={submit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} lg={12}>
            <TextField type="text" name="pageNumber" label="Sayfa no" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
