import { Grid, Icon, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext, useMemo } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { ResourceContext } from 'context/resource.context';

import { OpinionContext } from '../opinion.context';

export default function ListOpinions() {
  const { loading, opinion } = useContext(OpinionContext);

  const { workingRestrictionDefinitions } = useContext(ResourceContext);

  function getValue(value: number): string {
    switch (value) {
      case 0:
        return 'çalışamaz';
      case 1:
        return 'çalışır';
      default:
        return '';
    }
  }

  const opinionText = useMemo(() => {
    const workingDescription = opinion?.workingCondition ? opinion?.workingCondition + ' şartı ile ' : '';
    const positionDescription = opinion?.positionDefinition ? opinion?.positionDefinition + ' işinde' : '';
    const opinionDescription = opinion?.workingRestrictions?.find(
      item => item?.workingRestrictionDefinitionId === workingRestrictionDefinitions?.find(opinions => opinions?.opinion === 'Kanaat')?.id
    );
    return opinionDescription?.workingRestrictionValue !== 2
      ? opinionDescription?.workingRestrictionValue === 1
        ? `${positionDescription} ${workingDescription} ${
            opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
          }`
        : `${positionDescription} ${
            opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
          } `
      : null;
  }, [opinion, workingRestrictionDefinitions]);

  return (
    <>
      <IndicatorWrapper loading={loading} data={opinion} errorMessage="Gösterilecek Kanaat Bulunamadı.">
        <Grid container>
          <Grid item sm={12} md={12} lg={12}>
            {!!opinion && !loading && (
              <>
                <List style={{ paddingLeft: '20px' }} key="hs-opinion">
                  <Grid container>
                    {opinion?.workingRestrictions
                      ?.filter(workingRestriction => workingRestriction.workingRestrictionValue < 2)
                      .map((item, index) => (
                        <Grid key={index} item md={6} sm={6} lg={6}>
                          <ListItem key={index}>
                            <ListItemIcon>
                              <ArrowRightIcon />
                            </ListItemIcon>
                            {findByKey(workingRestrictionDefinitions, 'id', item?.workingRestrictionDefinitionId)?.opinion}
                            <span style={{ marginLeft: '5px', fontStyle: 'italic' }}>{getValue(item.workingRestrictionValue)}</span>
                          </ListItem>
                        </Grid>
                      ))}
                    {opinion?.description ? (
                      <Grid item md={12} sm={12} lg={12}>
                        <ListItem>
                          <Typography className="text-italic">
                            <Icon style={{ color: 'gray' }} className="icon-document" /> {opinion?.description}
                          </Typography>
                        </ListItem>
                      </Grid>
                    ) : null}
                    <Grid item md={12} sm={12} lg={12}>
                      <ListItem>
                        <Typography className="text-italic text-bold">
                          <Icon style={{ color: 'gray' }} className="icon-checkbox-marked-outline" /> {opinionText}
                        </Typography>
                      </ListItem>
                    </Grid>
                  </Grid>
                </List>
              </>
            )}
          </Grid>
        </Grid>
      </IndicatorWrapper>
    </>
  );
}
