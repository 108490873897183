import { Grid } from '@material-ui/core';
import React from 'react';

import { NearMissTypeDefinitionControllerService, NearMissTypeDefinitionDto } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateNearMissTypeDefinition } from '../../../../api/hs/definitions/near-miss-type-definitions';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the allergy is created/updated
   */
  onClose(success: boolean): void;

  data: NearMissTypeDefinitionDto;
}

export default function AdminNearMissTypeDefinitions({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<NearMissTypeDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={NearMissTypeDefinitionControllerService.saveNearMissTypeDefinition}
      onUpdate={updateNearMissTypeDefinition}
      data={data}
      resourceReloadName="accidentTypes"
      createTitle="Ramak Kala Türü Ekleme"
      updateTitle="Ramak Kala Türü Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Ramak Kala Türü Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
