/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ImportedFileDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    name?: string;
    originalFileName?: string;
    path?: string;
    type?: string;
    importedFileSource?: ImportedFileDto.importedFileSource;
    referenceTableId?: string;
    employeeId?: string;
    size?: number;
}

export namespace ImportedFileDto {

    export enum importedFileSource {
        EMPLOYEE_DEBIT = 'EMPLOYEE_DEBIT',
        REST_REPORT = 'REST_REPORT',
        PROFILE = 'PROFILE',
        ANALYSIS = 'ANALYSIS',
        ACCIDENT = 'ACCIDENT',
        ACCIDENT_REVIEW = 'ACCIDENT_REVIEW',
        RISK_ASSESSMENT_HAZARD = 'RISK_ASSESSMENT_HAZARD',
        NEAR_MISS = 'NEAR_MISS',
        TRAINING = 'TRAINING',
        TRAINING_PRETEST = 'TRAINING_PRETEST',
        TRAINING_EXAM = 'TRAINING_EXAM',
        COMMITTEE_MEETING = 'COMMITTEE_MEETING',
        COMMITTEE_MEMBER = 'COMMITTEE_MEMBER',
        ACTION = 'ACTION',
        OCCUPATIONAL_SAFETY_REGISTRY = 'OCCUPATIONAL_SAFETY_REGISTRY',
        WORK_EQUIPMENT = 'WORK_EQUIPMENT',
        DRILL = 'DRILL',
    }


}
