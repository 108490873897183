import React, { useState } from 'react';

import { getPpeAssigmentEmployee, PpeAssignmentsResult } from 'api/hs/ppe/ppe-assignments';
import { useFetch } from 'utils';

import { AssigmentEmployeeContext } from './assigment-employee.context';
import AssigmentPosition from './detail';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeAssignmentsResult>(null);

  const { data, fetch } = useFetch(() => getPpeAssigmentEmployee(), [], { setLoading });

  return (
    <AssigmentEmployeeContext.Provider
      value={{
        ppeAssignmentsEmployee: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <AssigmentPosition />
    </AssigmentEmployeeContext.Provider>
  );
}
