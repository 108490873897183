export enum LicenceModule {
  PERSONEL = 'PERSONEL',
  SAGLIK_GOZETIMI = 'SAGLIK_GOZETIMI',
  RISK_DEGERLENDIRME = 'RISK_DEGERLENDIRME',
  AKSIYON = 'AKSIYON',
  IS_KAZA = 'IS_KAZA',
  EGITIM = 'EGITIM',
  ACIL_DURUM = 'ACIL_DURUM',
  KKD = 'KKD',
  ISG = 'ISG',
  ONAYLI_DEFTER = 'ONAYLI_DEFTER',
  IS_EKIPMAN = 'IS_EKIPMAN',
  ISTATISTIK = 'ISTATISTIK',
  TANIM = 'TANIM',
}

const licenceNames = new Map<LicenceModule, string>();

licenceNames.set(LicenceModule.PERSONEL, 'PERSONEL');
licenceNames.set(LicenceModule.SAGLIK_GOZETIMI, 'SAĞLIK GÖZETİMİ');
licenceNames.set(LicenceModule.RISK_DEGERLENDIRME, 'RİSK DEĞERLENDİRME');
licenceNames.set(LicenceModule.AKSIYON, 'AKSİYON');
licenceNames.set(LicenceModule.IS_KAZA, 'İŞ KAZASI VE RAMAK KALA');
licenceNames.set(LicenceModule.EGITIM, 'EĞİTİM YÖNETİMİ');
licenceNames.set(LicenceModule.ACIL_DURUM, 'ACİL DURUM YÖNETİMİ');
licenceNames.set(LicenceModule.KKD, 'KKD');
licenceNames.set(LicenceModule.ISG, 'İSG KURULU');
licenceNames.set(LicenceModule.ONAYLI_DEFTER, 'ONAYLI DEFTER');
licenceNames.set(LicenceModule.IS_EKIPMAN, 'İŞ EKİPMANLARI');
licenceNames.set(LicenceModule.ISTATISTIK, 'İSTATİSTİKLER');
licenceNames.set(LicenceModule.TANIM, 'TANIMLAR');

const FullModules = [LicenceModule.PERSONEL, LicenceModule.SAGLIK_GOZETIMI, LicenceModule.RISK_DEGERLENDIRME, LicenceModule.AKSIYON, LicenceModule.IS_KAZA, LicenceModule.EGITIM, LicenceModule.ACIL_DURUM, LicenceModule.KKD, LicenceModule.ISG, LicenceModule.ONAYLI_DEFTER, LicenceModule.IS_EKIPMAN, LicenceModule.ISTATISTIK, LicenceModule.TANIM];

export { licenceNames as ModuleNames, FullModules };
