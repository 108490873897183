import React from 'react';

import Add from './Form';
import List from './List';

export default function Index() {
  return (
    <>
      <div className="m-2">
        <Add />
      </div>
      <List />
    </>
  );
}
