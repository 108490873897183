import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { savePosition, updatePosition } from 'api/hs/definitions/workplace-definition/position';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { getProfessionDefinitions, ProfessionDefinitionsModel } from '../../../../../api/hs/profession-definitions';
import { ResourceContext } from '../../../../../context/resource.context';
import { SelectOptionsContext } from '../../../../../context/select-options.context';
import AutoComplete from '../../../../Form/AutoComplete';
import { SelectOption } from '../../../../Form/Select';
import TextField from '../../../../Form/TextField';

interface Props<T extends { id: string | number }> {
  open: boolean;

  onClose(success: boolean): void;

  data: T;
}
function mapProfessionDefinition(types: ProfessionDefinitionsModel[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ ad, kod }) => ({ text: ad, value: kod }));
}

async function professionDefinitionOptionFetcher(value: string) {
  const res = await getProfessionDefinitions(value);
  return mapProfessionDefinition(res.content);
}

async function professionDefinitionLabelFetcher(value: string[] | string) {
  const res = await getProfessionDefinitions(value);
  return mapProfessionDefinition(res.content);
}

export default function PositionEditGeneralSettingsForm<T extends { id: string | number }>({ open, onClose, data }: Props<T>) {
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { exposures } = useContext(SelectOptionsContext);
  const { reload } = useContext(ResourceContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await savePosition(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updatePosition({ id: data.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
        await reload('positions');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, reload]
  );
  useAsyncEffect(async () => {
    if (data) {
      await defer();

      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }

      trigger();
    }
  }, [data, setValue, trigger]);

  return (
    <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
      <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12}>
            <TextField required name="positionName" label="Görev Adı Giriniz" />
          </Grid>
          <Grid item lg={12} md={12}>
            <AutoComplete name="exposureIds" options={exposures} label="Maruziyetler" />
          </Grid>
          <Grid item lg={12} md={12}>
            <AutoComplete
              name="professionCode"
              label="Meslek Tanımı"
              optionFetcher={professionDefinitionOptionFetcher}
              labelFetcher={professionDefinitionLabelFetcher}
              single
            />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
