import { Grid } from '@material-ui/core';
import React from 'react';

import { HazardCurrentPrecautionDefinitionDto } from 'api/client';
import { HazardCurrentPrecautionDefinitionControllerService } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateFirmRiskAssessmentsPrecautionLevelDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: HazardCurrentPrecautionDefinitionDto;

  onClose(success: boolean): void;
}

export default function HazardCurrentPrecautionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<HazardCurrentPrecautionDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={HazardCurrentPrecautionDefinitionControllerService.saveFirmHazardCurrentPrecautionDefinitions}
      onUpdate={updateFirmRiskAssessmentsPrecautionLevelDefinitionUpdate}
      data={data}
      createTitle="Mevcut Önlem Ekleme"
      updateTitle="Mevcut Önlem Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Mevcut Önlem Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
