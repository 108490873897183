/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateRangeDto } from '../models/DateRangeDto';
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeView } from '../models/EmployeeView';
import type { EmployeeWidgetDto } from '../models/EmployeeWidgetDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmployeeWidgetControllerService {

    /**
     * @param dateRange
     * @param employeeWidgetType
     * @returns EmployeeView OK
     * @throws ApiError
     */
    public static getEmployeeWidgetView(
        dateRange: DateRangeDto,
        employeeWidgetType: 'EMPLOYEE_ACTIVE' | 'EMPLOYEE_NEW',
    ): CancelablePromise<Array<EmployeeView>> {
        return __request({
            method: 'GET',
            path: `/employee/widgets/views`,
            query: {
                'dateRange': dateRange,
                'employeeWidgetType': employeeWidgetType,
            },
        });
    }

    /**
     * @param dateRange
     * @param employeeWidgetType
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static getEmployeeWidgetViewExport(
        dateRange: DateRangeDto,
        employeeWidgetType: 'EMPLOYEE_ACTIVE' | 'EMPLOYEE_NEW',
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/employee/widgets/views/export`,
            query: {
                'type': type,
                'dateRange': dateRange,
                'employeeWidgetType': employeeWidgetType,
            },
        });
    }

    /**
     * @param dateRange
     * @returns EmployeeWidgetDto OK
     * @throws ApiError
     */
    public static getEmployeeWidgetCount(
        dateRange: DateRangeDto,
    ): CancelablePromise<EmployeeWidgetDto> {
        return __request({
            method: 'GET',
            path: `/employee/widgets/count`,
            query: {
                'dateRange': dateRange,
            },
        });
    }

    /**
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployeeHasNoPeriodicExamCfWidgetView(): CancelablePromise<Array<EmployeeDto>> {
        return __request({
            method: 'GET',
            path: `/employee/periodic-examination/widgets/views`,
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static getEmployeeHasNoPeriodicExamCfWidgetViewExport(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/employee/periodic-examination/widgets/views/export`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @returns number OK
     * @throws ApiError
     */
    public static getEmployeeHasNoPeriodicExamConfWidgetCount(): CancelablePromise<number> {
        return __request({
            method: 'GET',
            path: `/employee/periodic-examination/widgets/count`,
        });
    }

}