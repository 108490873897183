import HSApi from '../../HSApi';
import { GetTrainingListPayload, TrainingFilters } from './get-training-list.payload';
import { TrainingsListResult } from './training-list-result';

export async function getTrainingList(page = 0, size = 20, order: null | string, filters: TrainingFilters): Promise<TrainingsListResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof TrainingFilters)[] = ['minDuration', 'maxDuration', 'startDate', 'endDate'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.startDate) {
    parsedFilters.startDate = new Date(parsedFilters.startDate).toISOString();
  }

  if (parsedFilters.endDate) {
    parsedFilters.endDate = new Date(parsedFilters.endDate).toISOString();
  }

  const payload: GetTrainingListPayload = {
    trainingFilter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<TrainingsListResult>(`/trainings/view`, payload);

  return res.data;
}

export async function ExportTrainingList(filters: TrainingFilters): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof TrainingFilters)[] = [
    'minDuration',
    'maxDuration',
    'startDate',
    'endDate',
    'isParticipated',
    'isArchived',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.startDate) {
    parsedFilters.startDate = new Date(parsedFilters.startDate).toISOString();
  }

  if (parsedFilters.endDate) {
    parsedFilters.endDate = new Date(parsedFilters.endDate).toISOString();
  }

  const payload = {
    trainingFilter: parsedFilters,
  };

  const res = await HSApi.post<TrainingsListResult>(`/trainings/view/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'egitim-listesi.xlsx';
  a.click();
}
