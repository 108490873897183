/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccidentReviewActionRequest = {
    description: string;
    dueDate: string;
    actionPriorityDefinition: AccidentReviewActionRequest.actionPriorityDefinition;
    employeeIds?: Array<string>;
}

export namespace AccidentReviewActionRequest {

    export enum actionPriorityDefinition {
        LOW = 'LOW',
        MIDDLE = 'MIDDLE',
        HIGH = 'HIGH',
    }


}
