import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { updatePpeOutputRefund } from 'api/hs/TransactionAndLists/Employee/PpeList';
import { Outputs } from 'api/hs/TransactionAndLists/Employee/PpeList/ppe-outputs-groups-employee.result';
import Dialog from 'Common/Dialog';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import Select from 'Common/Form/Select';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import TextField from '../../../../../../Common/Form/TextField';
import { AlertContext } from '../../../../../../context/alert.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: Outputs;
}

export default function PpeReturnForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { reasons } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);

  const submit = useCallback(
    async values => {
      try {
        await updatePpeOutputRefund([{ id: data?.id, ...values }]);
        showMessage('İade işlemi başarılı..', 'success', 'info');
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [onClose, showMessage, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.setValue('reason', 'REFUND');
    formRef?.current?.methods?.setValue('refundDate', new Date(Date.now()).toISOString());
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title="İade Formu">
        <Form onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Select name="reason" options={reasons?.filter(reason => reason.value !== 'DEBIT')} label="İade Sebepi" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <TextField label="Adet" name="count" type="number" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="İade Tarihi" name="refundDate" type="date-time" />
            </Grid>
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                Kaydet
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
