import { Icd10 } from 'model/definitions/health-definitions/icd10.model';
import { modelDataMatcher } from 'utils';

import { DiagnosisResult } from './diagnosis.result';

export class DiagnosisModel {
  public id: string = null;
  public description: string = null;
  public occupationalDiseasePreDiagnosis: boolean = null;
  // public isWorkRelatedDiseaseDiagnosis: boolean = null;
  public icd10s: Icd10['id'][] = [];

  constructor(data: Partial<DiagnosisModel> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: DiagnosisResult) {
    return new DiagnosisModel({ ...data, icd10s: data.diagnosisIcd10s.map(({ icd10Id }) => icd10Id) });
  }

  public static fromServerListData(data: DiagnosisResult[]) {
    return data.map(DiagnosisModel.fromServerData);
  }
}
