import { Button, Grid } from '@material-ui/core';
import { defer, findByKey, tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import Select, { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';

import { getPpeStorehouses } from '../../../../../api/hs/ppe/ppe-detail-stocks';
import { EmployeeSelectionCacheContext } from '../../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { dateToInputFormat, useAsyncEffect, useFetch, useTriggerRender } from '../../../../../utils';
import { PpeNewOutputContext } from '../../ppe-new-output.context';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default function PpeOutputsFormDialog() {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { ppeDefinitions, storeHouse } = useContext(SelectOptionsContext);
  const reRender = useTriggerRender();
  const { setPpeOutputsData, ppeOutputsData } = useContext(PpeNewOutputContext);
  const { loadEmployees } = useContext(EmployeeSelectionCacheContext);
  const { employeeId } = useParams<{ employeeId: string }>();
  const [selectedPpeDefinitionId, setSelectedPpeDefinitionId] = useState<string>(null);

  const { data: stockList } = useFetch<SelectOption<string>[]>(
    async () => {
      if (!selectedPpeDefinitionId) return [];

      const [err, res] = await tryCatch(getPpeStorehouses(selectedPpeDefinitionId));
      if (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
        return [];
      }
      return res?.map(stock => ({
        text: `${findByKey(storeHouse, 'value', stock?.storehouseId)?.text} - Stok No : ${stock?.stockNumber} - ${
          stock?.remainStockCount
        } tane`,
        value: stock?.stockId,
      }));
    },
    [],
    { deps: [selectedPpeDefinitionId], reloadDeps: [selectedPpeDefinitionId] }
  );

  const onSave = useCallback(
    values => {
      const outputData = {
        ...values,
        id: guid(),
      };
      setPpeOutputsData([...ppeOutputsData, outputData]);
      formRef?.current?.methods?.reset({ ppeDefinitionId: null, stockId: null, count: 1, outputDate: new Date(Date.now()).toISOString() });
    },
    [ppeOutputsData, setPpeOutputsData]
  );

  useAsyncEffect(async () => {
    await defer();
    loadEmployees(...ppeOutputsData?.map((employee: any) => employee.employeeId));
    formRef?.current?.methods?.setValue('count', 1);
    formRef?.current?.methods?.setValue('employeeId', ppeOutputsData[0]?.employeeId ?? null);
    formRef?.current?.methods?.setValue('outputDate', dateToInputFormat(new Date()));
    if (employeeId) {
      formRef?.current?.methods?.setValue('employeeId', employeeId);
    }
    formRef?.current?.methods?.trigger();
  }, [setPpeOutputsData, ppeOutputsData]);

  const onChangeForm = useCallback(
    formValues => {
      reRender();
      setSelectedPpeDefinitionId(formValues?.ppeDefinitionId);
    },
    [reRender]
  );

  return (
    <Form onChange={onChangeForm} onSubmit={onSave} ref={formRef}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <AutoComplete
            name="employeeId"
            label="Personel Seç"
            optionFetcher={employeeOptionFetcher}
            labelFetcher={employeeLabelFetcher}
            single
          />
        </Grid>
        <Grid item md={6} xs={6} sm={6} lg={6}>
          <TextField type="number" name="count" label="Kaç Adet" required={true}/>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <AutoComplete single name="ppeDefinitionId" options={ppeDefinitions} label="KKD Tipi "  required={true}/>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <DateInput label="Çıkış Tarihi" name="outputDate" type="date" required={true} />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <Select disabled={!stockList.length} name="stockId" options={stockList} label="Depo"  required={true}/>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
          <Button disabled={!formRef?.current?.value?.stockId} variant="contained" type="submit" color="secondary" className="mr-2">
            Listeye Ekle
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
