import { modelDataMatcher } from 'utils';

export class PlanningTrainingDefinitionModel {
  public id: string = null;
  public durationInMinute: number = null;
  public trainingDefinitionId: string = null;
  public trainingId: string = null;

  constructor(data: Partial<PlanningTrainingDefinitionModel> = {}) {
    modelDataMatcher(data, this);
  }
}
