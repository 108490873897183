import { createContext } from 'react';

import { Icd10 } from 'api/hs/icd10';

export interface Icd10ContextType {
  name: string;

  disabled: boolean;

  selectionOpened: boolean;

  setSelectionOpened(opened: boolean): void;

  favouritesOpened: boolean;

  setFavouritesOpened(opened: boolean): void;
}

export const Icd10Context = createContext<Icd10ContextType>(null);

export const CHILDREN_NOT_LOADED = Symbol('Children not loaded');
export const CHILDREN_LOADING = Symbol('Children loading');

export interface Icd10Item extends Icd10 {
  children?: Icd10Item[] | symbol;
}

export interface Icd10CacheContextType {
  items: Icd10Item[];

  loadChildrenOf(id: Icd10Item['id']): Promise<void>;

  labels: { [key: string]: string };
  options: Icd10Item[];

  searchOptions(search: string): void;

  getOptions(...ids: Icd10Item['id'][]): Promise<void>;

  loading: boolean;

  favourites: Icd10Item['id'][];

  toggleFavourite(id: Icd10Item['id']): Promise<void>;
}

export const Icd10CacheContext = createContext<Icd10CacheContextType>(null);

export interface Icd10SelectionContextType {
  selection: Icd10Item['id'][];

  setSelection(value: Icd10Item['id'][]): void;
}

export const Icd10SelectionContext = createContext<Icd10SelectionContextType>(null);
