import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { AllergyResult, deleteAllergy, getAllergy } from 'api/hs/definitions/health-definitions/allergy';
import AllergyEditDialog from 'Common/Forms/Definitions/HealthDefinition/Allergy/AllergyEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Allergy() {
  return (
    <BaseDefinitionPage<AllergyResult>
      listLabelPropName="allergyName"
      fetchData={getAllergy}
      FormDialog={AllergyEditDialog}
      onDelete={deleteAllergy}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
