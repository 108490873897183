import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import React from 'react';

import { deleteAdminStation, getAdminStation, StationResult } from 'api/hs/definitions/workplace-definition/station';
import AdminStationEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Station/AdminStationEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  stationName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
  },
  stationType: {
    fontSize: '12px',
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  stationType2: {
    fontSize: '10px',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'rgb(170, 170, 170)',
    fontWeight: 'bold',
  },

  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<StationResult>) {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <BusinessCenterIcon />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.stationName}>{item.stationName}</span>
          </div>
          <div>
            <span className={classes.stationType}>Tehlike Kaynakları : </span>
            <span className={classes.stationType2}>Tehlike ataması yapılmamış.</span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default function Station() {
  return (
    <BaseDefinitionPage<StationResult>
      listLabelPropName="stationName"
      fetchData={getAdminStation}
      FormDialog={AdminStationEditDialog}
      onDelete={deleteAdminStation}
      Icon={<BusinessCenterIcon />}
      ListItemTemplate={ItemTemplate}
    />
  );
}
