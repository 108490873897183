import { createContext } from 'react';

import { AnalysisParametersResult } from '../../../../api/hs/definitions/health-definitions/analysis/AnalysisParametersResult';

export interface AnalysisParametersContextType {
  analysisParameters: AnalysisParametersResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: AnalysisParametersResult;

  setSelectedData(data: AnalysisParametersResult): void;
}

export const AnalysisParameterContext = createContext<AnalysisParametersContextType>(null);
