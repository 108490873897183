import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { CompanyPayload } from './Company.payload';
import { CompanyResult } from './Company.result';

export async function getCompany(): Promise<CompanyResult[]> {
  const [error, res] = await tryCatch(HSApi.get<CompanyResult[]>(`/companies`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getByIdCompany(companyId: string): Promise<CompanyResult> {
  const [error, res] = await tryCatch(HSApi.get<CompanyResult>(`/companies/${companyId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveCompany(body: CompanyPayload): Promise<CompanyResult> {
  const [err, res] = await tryCatch(HSApi.post('/companies', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateCompany({ id, ...body }: MakeRequired<Partial<CompanyResult>, 'id'>): Promise<CompanyResult> {
  const [error, res] = await tryCatch(HSApi.put<CompanyResult>(`/companies/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteCompany(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/companies/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
