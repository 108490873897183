import { noop } from '@thalesrc/js-utils';
import { useCallback, useEffect, useState } from 'react';

export default function useFetch<T>(
  fetcher: () => Promise<T>,
  defaultValue: T,
  { setLoading = noop, deps = [], reloadDeps = [] }: { deps?: any[]; reloadDeps?: any[]; setLoading?(loading: boolean): void } = {}
): { data: T; error: string; fetch: () => Promise<void>; setData: (data: T) => void } {
  const [data, setData] = useState<T>(defaultValue);
  const [error, setError] = useState<string>();

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      setData(await fetcher());
    } catch (err) {
      // tslint:disable-next-line:no-console
      setError(err ? err.toString() : 'an error occurred');
    }
    setLoading(false);
  }, deps); // eslint-disable-line

  useEffect(() => {
    fetch();
  }, reloadDeps); // eslint-disable-line

  return { data, error, fetch, setData };
}
