import React from 'react';

import Add from './add';
import Listt from './list';

export default function Index() {
  return (
    <>
      <Listt />
      <Add />
    </>
  );
}
