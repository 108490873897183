import React, { useCallback, useContext } from 'react';

import { AssigmentEmployeeContext } from '../../assigment-employee.context';
import AssigmentEmployeeForm from '../form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(AssigmentEmployeeContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <AssigmentEmployeeForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
