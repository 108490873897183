import { replace } from '@thalesrc/js-utils';
import React, { useCallback, useState } from 'react';

import { getDefaultEmployeeFilters, getEmployees, GetEmployeesPayload, GetEmployeesResult } from 'api/hs/employee-filter';
import { Employee } from 'model';
import { DeepPartial, useAsyncEffect } from 'utils';

import { Cell, CELLS } from './cells';
import { EmployeeListContext } from './employee-list.context';
import Table from './Table';
import Toolbar from './Toolbar';

const DEFAULT_FILTERS: DeepPartial<GetEmployeesPayload> = {
  pagingAndSortingRequestParam: { size: 10, page: 0 },
};

export default function List() {
  const [context, setContext] = useState<GetEmployeesResult>(null);
  const [filters, setFilters] = useState<GetEmployeesPayload>(DEFAULT_FILTERS as GetEmployeesPayload);
  const [cells, setCells] = useState<Cell[]>(CELLS.map(cell => ({ ...cell })));
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>(null);
  const [editEmployeeDialogOpened, setEditEmployeeDialogOpened] = useState(false);
  const [detailsDrawerOpened, setDetailsDrawerOpened] = useState(false);
  const setCellVisibility = useCallback(
    (id: string, visible: boolean) => {
      const cell = cells.find(_cell => _cell.id === id);

      setCells(replace(cells, cell, { ...cell, visible }));
    },
    [cells]
  );

  const updateFilters = useCallback(
    ({ filter, pagingAndSortingRequestParam }: DeepPartial<GetEmployeesPayload>) => {
      setFilters({
        filter: { ...filters.filter, ...filter },
        pagingAndSortingRequestParam: { ...filters.pagingAndSortingRequestParam, ...pagingAndSortingRequestParam },
      });
    },
    [filters]
  );

  const fetchEmployees = useCallback(async () => {
    const result = await getEmployees(filters);
    if (Math.max(result.totalPages - 1, 0) < filters.pagingAndSortingRequestParam.page) {
      updateFilters({ pagingAndSortingRequestParam: { page: Math.max(result.totalPages - 1, 0) } });

      return;
    }

    setContext(result);
  }, [filters, updateFilters]);

  // On Load
  useAsyncEffect(async () => {
    const res = await getDefaultEmployeeFilters();
    setFilters({
      filter: res?.filter,
      pagingAndSortingRequestParam: {
        page: res?.pagingAndSortingRequestParam?.page,
        size: res?.pagingAndSortingRequestParam?.size,
        order: res?.pagingAndSortingRequestParam?.order ?? 'name-ASC',
      },
    });
  }, []);

  // On Filter Change
  useAsyncEffect(async () => {
    if (!filters || filters === DEFAULT_FILTERS) {
      return;
    }

    await fetchEmployees();
  }, [filters]);

  return (
    <EmployeeListContext.Provider
      value={{
        ...context,
        updateFilters,
        filters,
        cells,
        setCellVisibility,
        reload: fetchEmployees,
        selectedEmployee,
        setSelectedEmployee,
        editEmployeeDialogOpened,
        setEditEmployeeDialogOpened,
        detailsDrawerOpened,
        setDetailsDrawerOpened,
      }}>
      <Toolbar />
      <Table />
    </EmployeeListContext.Provider>
  );
}
