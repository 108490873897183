import { makeStyles, TableBody, TableCell, TableRow } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { TableContext, TableContextType } from './table.context';

const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

/**
 * Default Table Body Component
 */
export default function DefaultBody<T>() {
  const classes = useStyles();
  const { visibleColumns, data, onRowClick, RowProps } = useContext<TableContextType<T>>(TableContext);

  return (
    <TableBody>
      {data.map((entry, index) => (
        <TableRow
          {...RowProps}
          key={index}
          className={`${classes.row} ${RowProps.className || ''}`}
          onClick={e => {
            (RowProps.onClick || noop)(e);
            onRowClick(entry, e);
          }}>
          {visibleColumns.map((col, colIndex) => (
            <TableCell key={typeof col.id === 'symbol' ? colIndex : (col.id as string)} className="p-1" width={col.width}>
              {col.render ? col.render(entry) : entry[col.id as keyof T]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
