import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';

import ScrollableTabs from 'Common/ScrollableTabs';

import { EmployeeContext } from '../employee.context';
import Pregnancies from '../Pregnancies';
import Addiction from './Addiction';
import Allergies from './Allergies';
import ChronicDisease from './ChronicDisease';
import ComplaintHistory from './ComplaintHistory';
import Disabilities from './Disabilities';
import HospitalHistory from './HospitalHistory';
import MedicalHistory from './MedicalHistory';
import MedicineTreatments from './MedicineTreatments';
import OccupationalDisease from './OccupationalDisease';
import SurgeryHistory from './SurgeryHistory';
import WorkAccidents from './WorkAccidents';

const TABS = [
  {
    title: 'Yakınma Öyküsü',
    icon: 'icon-clipboard-check',
    Component: ComplaintHistory,
  },
  {
    title: 'Hastalık Öyküsü',
    icon: 'icon-clipboard-check',
    Component: MedicalHistory,
  },
  {
    title: 'Hastane / Yatış Öyküsü',
    icon: 'icon-clipboard-check',
    Component: HospitalHistory,
  },
  {
    title: 'Ameliyat Öyküsü',
    icon: 'icon-clipboard-check',
    Component: SurgeryHistory,
  },
  {
    title: 'Kronik Hastalıklar',
    icon: 'icon-clipboard-check',
    Component: ChronicDisease,
  },
  {
    title: 'Kullanılan İlaçlar',
    icon: 'icon-clipboard-check',
    Component: MedicineTreatments,
  },
  {
    title: 'Alerjiler',
    icon: 'icon-clipboard-check',
    Component: Allergies,
  },
  {
    title: 'İş Kazası Öyküsü',
    icon: 'icon-clipboard-check',
    Component: WorkAccidents,
  },
  {
    title: 'Engellilik Durumu',
    icon: 'icon-clipboard-check',
    Component: Disabilities,
  },
  {
    title: 'Gebelik',
    icon: 'icon-clipboard-check',
    Component: Pregnancies,
    availableGender: [1],
  },
  {
    title: 'Meslek Hastalığı',
    icon: 'icon-clipboard-check',
    Component: OccupationalDisease,
  },
  {
    title: 'Bağımlılık',
    icon: 'icon-clipboard-check',
    Component: Addiction,
  },
];

export default function MedicalResume() {
  const [activeTab, setActiveTab] = useState('0');
  const { employee } = useContext(EmployeeContext);
  const changeTab = useCallback((e, value) => setActiveTab(value), []);

  return (
    <TabContext value={activeTab}>
      <AppBar position="static" color="default" style={{ maxWidth: '100%' }}>
        <ScrollableTabs onChange={changeTab} indicatorColor="primary" textColor="primary">
          {TABS.filter(({ availableGender = [0, 1] }) => availableGender.includes(employee.gender)).map(({ title }, index) => (
            <Tab key={index} label={title} value={index + ''} />
          ))}
        </ScrollableTabs>
      </AppBar>
      {TABS.filter(({ availableGender = [0, 1] }) => availableGender.includes(employee.gender)).map(({ Component }, index) => (
        <TabPanel key={index} value={index + ''}>
          <Component />
        </TabPanel>
      ))}
    </TabContext>
  );
}
