import React from 'react';

import { ReferralListContentResult } from 'api/hs/TransactionAndLists/ReferralList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { dateDisplayFormat } from '../../utils';
import Avatar from '../EmployeeList/Avatar';

export const CELL: TableColumn<ReferralListContentResult>[] = [
  {
    id: 'avatarPath',
    render(row): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: true,
    width: '5%',
  },
  {
    id: 'nid',
    defaultVisibility: true,
    label: 'TC No',
    sortable: true,
    width: '10%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '10%',
  },
  {
    id: 'hospitalTypeName',
    defaultVisibility: true,
    label: 'Hastane',
    sortable: true,
    width: '10%',
  },
  {
    id: 'referralSectionDefinitionName',
    defaultVisibility: true,
    label: 'Bölüm',
    sortable: true,
    width: '5%',
  },
  {
    id: 'noteToPhysician',
    defaultVisibility: true,
    label: 'Hekim Notu',
    sortable: true,
    width: '10%',
  },
  {
    id: 'description',
    defaultVisibility: true,
    label: 'Açıklama',
    sortable: true,
    width: '10%',
  },
  {
    id: 'referralDate',
    defaultVisibility: true,
    label: 'Sevk Tarihi',
    sortable: true,
    width: '5%',
    render(row: ReferralListContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.referralDate)}</>;
    },
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '15%',
  },
  {
    id: 'departmentName',
    defaultVisibility: true,
    label: 'Departman',
    sortable: true,
    width: '15%',
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Ekleyen',
    sortable: true,
    width: '10%',
  },
];
