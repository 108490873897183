import { AppBar, Grid } from '@material-ui/core';
import React from 'react';

import LoadingWrapper from '../../../Common/LoadingWrapper';

interface Props {
  title: string;
  color: string;
  onClick?(): void;
  showExport?: boolean;
  loading?: boolean;
}

export default ({ title, color, onClick, loading = false, showExport = true }: Props) => {
  return (
    <AppBar position="static" className="pl-2" style={{ backgroundColor: color, maxWidth: '100%' }}>
      <Grid container>
        <Grid item sm={11}>
          <h2>{title}</h2>
        </Grid>
        {showExport && (
          <Grid item sm={1}>
            <LoadingWrapper loading={loading}>
              {onClick ? (
                <p onClick={onClick} style={{ cursor: 'pointer' }}>
                  <img src={'images/excel-export.png'} alt="excel export" width={40} />
                </p>
              ) : (
                <p style={{ opacity: '.5' }}>
                  <img src={'images/excel-export.png'} alt="excel export" width={40} />
                </p>
              )}
            </LoadingWrapper>
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
};
