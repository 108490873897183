import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { getTrainingById, TrainingEmployeeViewContentResult, TrainingResult } from 'api/hs/trainings/TrainingEmployeeFilter';
import Dialog from 'Common/Dialog';
import { useAsyncEffect, useDateFormat } from 'utils';

import { ResourceContext } from '../../../context/resource.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: TrainingEmployeeViewContentResult;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function DialogTrainings({ open, onClose, data }: Props) {
  const classes = useStyles();
  const format = useDateFormat('dd MMMM yyyy');
  const [trainingsData, setTrainingsData] = useState<TrainingResult>([]);
  const { trainers } = useContext(ResourceContext);

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    const res = await getTrainingById(data?.id);
    setTrainingsData(res);
  }, [data]);

  return (
    <Dialog width="md" color="primary" title="Eğitimler" opened={open} onClose={onClose}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="15%" className="text-bold">
                Eğitim
              </TableCell>
              <TableCell width="15%" className="text-bold" align="center">
                Eğitim Tarihi
              </TableCell>
              <TableCell width="5%" className="text-bold" align="center">
                Eğitim Süresi
              </TableCell>
              <TableCell width="15%" className="text-bold" align="center">
                Geçerlilik Tarihi
              </TableCell>
              <TableCell width="20%" className="text-bold" align="center">
                Eğitmen
              </TableCell>
              <TableCell width="10%" className="text-bold" align="center">
                Eğitim Şekli
              </TableCell>
              <TableCell width="15%" className="text-bold" align="center">
                Eğitim Yeri
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainingsData?.map(training => (
              <TableRow hover key={training.id}>
                <TableCell component="th" scope="row">
                  {training.title}
                </TableCell>
                <TableCell align="center">{training?.completedDate ? format(new Date(`${training?.completedDate}`)) : null}</TableCell>
                <TableCell align="center">{training?.durationInMinutes ? training?.durationInMinutes + ' dk' : null}</TableCell>
                <TableCell align="center">{training?.expiredDate ? format(new Date(`${training?.expiredDate}`)) : null}</TableCell>
                <TableCell align="center">
                  {training?.trainers
                    ?.map(trainer => findByKey(trainers, 'id', trainer?.id))
                    ?.map(trainer => `${trainer?.name} ${trainer?.lastName}`)
                    ?.join(', ')}
                </TableCell>
                <TableCell align="center">{training?.faceToFace === '1' ? 'Yüz Yüze' : 'Uzaktan'}</TableCell>
                <TableCell align="center">{training?.location}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
}
