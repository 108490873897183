import { FormControlLabel, Radio as MUIRadio } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormContext } from './Form/form.context';

interface Props {
  name: string;
  label: string;
  value: any;
  disabled?: boolean;
  defaultValue?: boolean;
}

export default function Radio({ name, label, value, disabled = false, defaultValue = false }: Props) {
  const { trigger, getValues } = useFormContext();
  const { readonly } = useContext(FormContext);

  const currentValue = getValues(name);
  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const handleChange = useCallback(
    (onChange: (value: boolean) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      if (notEditable) {
        return;
      }

      if (e.target.checked) {
        onChange(value);
        trigger();
      }
    },
    [trigger, value, notEditable]
  );

  const renderRadio = useCallback(
    ({ onChange }) => (
      <MUIRadio checked={defaultValue || currentValue === value} onChange={handleChange(onChange)} disabled={notEditable} />
    ),
    [defaultValue, currentValue, value, handleChange, notEditable]
  );

  return <FormControlLabel control={<Controller defaultValue={defaultValue} name={name} render={renderRadio} />} label={label} />;
}
