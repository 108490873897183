import { createContext } from 'react';

import { UseDialogState } from 'utils';

import { UseDateRangeState } from '../../utils/use-date-range-state';

export interface DialogContextType {
  employee: UseDialogState;
  employeeDateRange: UseDateRangeState;
  referraledEmployee: UseDialogState;
  employeeWidget: UseDialogState;
  referraledEmployeeDateRange: UseDateRangeState;
  examination: UseDialogState;
  examinationDateRange: UseDateRangeState;
  vaccine: UseDialogState;
  vaccineDateRange: UseDateRangeState;
  pregnancy: UseDialogState;
  action: UseDialogState;
  actionDateRange: UseDateRangeState;
  nearMiss: UseDialogState;
  nearMissDateRange: UseDateRangeState;
  accident: UseDialogState;
  accidentDateRange: UseDateRangeState;
  employeeWidgetDateRange: UseDateRangeState;
  trainingWidget: UseDialogState;
  trainingWidgetDateRange: UseDateRangeState;
  lostDayWidget: UseDialogState;
  lostDayWidgetDateRange: UseDateRangeState;
}

export const DialogContext = createContext<DialogContextType>(null);
