import { Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PeopleIcon from '@material-ui/icons/People';
import { AvatarGroup } from '@material-ui/lab';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { EmergencyCaseTeamControllerService } from 'api/client';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';
import { ResourceContext } from 'context/resource.context';
import { useAsyncEffect, useXhr } from 'utils';

import AlertInformation from '../../../../Common/AlertInformation';
import { EmployeeSelectionCacheContext } from '../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { CaseTeamsContext } from '../../case-teams.context';
import EditDialog from './EditDialog';
import ToolbarCaseTeams from './Toolbar';

export default function CaseTeamsList() {
  const { loading, emergencyManagementCaseTeams, setEditDialogOpened, setSelectedData, reload } = useContext(CaseTeamsContext);
  const history = useHistory();
  const { companies, emergencyCaseDefinition } = useContext(ResourceContext);
  const { names, loadEmployees, avatars, employees } = useContext(EmployeeSelectionCacheContext);

  const { openWarnDialog } = useContext(DialogContext);

  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const deleteFileReq = useXhr(
    async (id: string) => {
      await EmergencyCaseTeamControllerService.deleteAllEmployeeEmergencyCaseTeam(id);
      await reload();
    },
    'Seçili Kayıt Silindi',
    'Kayıt silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Kayıtı silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const routeCaseTeamsDetail = useCallback(
    item => {
      history.push(`/emergency-management/caseTeams/${item?.id}`, { rowData: item });
    },
    [history]
  );

  useAsyncEffect(async () => {
    if (!emergencyManagementCaseTeams) {
      return;
    }
    await defer();
    const employeeIds = emergencyManagementCaseTeams?.reduce((acc, item) => [...acc, item.employees], []);

    await loadEmployees(...employeeIds);
  }, [emergencyManagementCaseTeams]);

  return (
    <div>
      <ToolbarCaseTeams />
      <IndicatorWrapper loading={loading} data={emergencyManagementCaseTeams} errorMessage="Gösterilecek Kayıt Bulunamadı.">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="25%" align="left">
                  Şirket
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="25%" align="left">
                  Acil Durum Ekibi
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="25%" align="center">
                  Ekip Personelleri
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="center">
                  Ekip Üyeleri
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="20%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emergencyManagementCaseTeams.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">{findByKey(companies, 'id', item?.companyId)?.companyName}</TableCell>
                  <TableCell align="left">{findByKey(emergencyCaseDefinition, 'id', item?.emergencyCaseDefinitionId)?.label}</TableCell>
                  <TableCell align="center">
                    {item?.employees.length > 0 ? (
                      <AvatarGroup max={4}>
                        {item?.employees?.map(employee => (
                          <Avatar
                            key={employee}
                            src={`/saglik-gozetimi/public/images/Avatars/Orginal/${avatars[employee]}`}
                            alt={names[employee]}
                          />
                        ))}
                      </AvatarGroup>
                    ) : (
                      <AlertInformation message="Personel Atanmamıştır." severity="error" alert="Personel Atanmamıştır." />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => routeCaseTeamsDetail(item)}>
                      <PeopleIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => removeFile(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
