/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IbysTrainingDefinitionEmployeeDto } from '../models/IbysTrainingDefinitionEmployeeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IbysEmployeeTrainingDefinitionControllerService {

    /**
     * @param requestBody
     * @param id
     * @returns IbysTrainingDefinitionEmployeeDto OK
     * @throws ApiError
     */
    public static partialUpdateDefinitionEmployeeIbys(
        requestBody: IbysTrainingDefinitionEmployeeDto,
        id?: string,
    ): CancelablePromise<IbysTrainingDefinitionEmployeeDto> {
        return __request({
            method: 'PATCH',
            path: `/trainings/ibys-trainings/training-definition-employee/${id}`,
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}