import { Divider, Grid, List, ListItem, ListSubheader, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { getAddictions, GetAddictionsResult, GetAddictionsResultItem, SaveAddictionPayload, saveAddictions } from 'api/hs/examination';
import Button from 'Common/Button';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import PanelWithDialog, { PanelWithDialogProps, PanelWithDialogRef } from 'Common/PanelWithDialog';
import { EnumContext } from 'context/enum.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useFetch, useXhr } from 'utils';

function getLabelForAddictionUsage(id: number): string {
  switch (id) {
    case 79:
      return 'Kadeh';
    case 80:
      return 'Adet';
    case 81:
      return 'Sıklıkla/Gün';
    default:
      return '';
  }
}

function periodUnitTypeLabel(periodUnitType: string): string {
  switch (periodUnitType) {
    case 'DAILY':
      return 'günde';
    case 'WEEKLY':
      return 'haftada';
    case 'MONTHLY':
      return 'ayda';
    case 'YEARLY':
      return 'yılda';
    default:
      return periodUnitType;
  }
}

function getAddictionMetric(id: number): string {
  switch (id) {
    case 79:
      return 'kadeh';
    case 80:
      return 'adet';
    case 81:
      return 'kez';
    default:
      return '';
  }
}

function renderFormItems(id: number, value: number, periyodUnit?: any): JSX.Element {
  switch (value) {
    case 1:
      return (
        <>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__startYearForQuit'} label="Yılında" />
          </Grid>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__countOfYearsForQuit'} label="Yıl Boyunca" />
          </Grid>
          <Grid item sm={2} className="px-1">
            <Select required name={id + '__periodUnitDefinition'} options={periyodUnit} label="" defaultValue={periyodUnit[0]?.value} />
          </Grid>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__period'} label="Zaman Birimi" />
          </Grid>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__countPerDayForQuit'} label={getLabelForAddictionUsage(id)} />
          </Grid>
        </>
      );
    case 2:
      return (
        <>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__startYearForUsing'} label="Yılından Beri" />
          </Grid>
          <Grid item sm={2} className="px-1">
            <Select required name={id + '__periodUnitDefinition'} options={periyodUnit} label="" defaultValue={periyodUnit[0]?.value} />
          </Grid>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__period'} label="Zaman Birimi" />
          </Grid>
          <Grid item sm={2} className="px-1">
            <TextField required type="number" name={id + '__countPerDayForUsing'} label={getLabelForAddictionUsage(id)} />
          </Grid>
        </>
      );
    default:
      return null;
  }
}

export default function Addiction() {
  const ref = useRef<PanelWithDialogRef>(null);
  const { addictions } = useContext(EnumContext);
  const { addictionStates, periodUnit } = useContext(SelectOptionsContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const [loading, setLoading] = useState(true);

  const { data, fetch } = useFetch(
    async () => {
      const res = await getAddictions(employeeId, examinationId);

      return {
        ...res,
        addictionDetails: res.addictionDetails.map(item => ({
          ...item,
          label: findByKey(addictions, 'id', item.addictiveSubstanceDefinitionId)?.label,
          description:
            item.addictionStateDefinitionId === 0
              ? findByKey(addictionStates, 'value', 0)?.text
              : item.addictionStateDefinitionId === 1
              ? `${item.startYearForQuit} yılında bırakmış. ${item.countOfYearsForQuit} yıl boyunca ${item.period} ${periodUnitTypeLabel(
                  item?.periodUnitDefinition
                )}

                 ${item.countPerDayForQuit} ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanmış.`
              : `${item.startYearForUsing} yılından beri  ${item.period} ${periodUnitTypeLabel(item?.periodUnitDefinition)} ${
                  item.countPerDayForUsing
                } ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanıyor.`,
        })),
      };
    },
    null,
    {
      setLoading,
      deps: [addictions, addictionStates],
    }
  );

  const saveReq = useXhr(
    async (payload: SaveAddictionPayload) => {
      setLoading(true);
      await saveAddictions(payload);
      await fetch();
    },
    'Bağımlılıklar kaydedildi',
    'Bağımlılıklar kaydedilirken bir hata oluştu',
    [fetch]
  );

  const insertAddictions = useCallback(async () => {
    const { submitted, form } = await ref.current.openDialog(data?.employeeId ? data : null);

    if (submitted) {
      const { description, ...items } = form;

      const payload: SaveAddictionPayload = {
        employeeId,
        examinationId,
        description,
        addictionDetails: Object.entries<any>(items)
          .filter(([key]) => !key.includes('__'))
          .map(([key, value]) => ({
            addictiveSubstanceDefinitionId: +key,
            addictionStateDefinitionId: +value,
            ...Object.entries(items)
              .filter(([k]) => k.startsWith(key + '__'))
              .reduce((acc, [k, v]) => ({ ...acc, [k.split('__')[1]]: v }), {}),
          })),
      };

      await saveReq(payload);
    }
  }, [employeeId, examinationId, saveReq, data]);

  const prepareForm = useCallback<PanelWithDialogProps<GetAddictionsResult>['onDialogOpen']>(
    async ({ opened, data: passedData, reset, setValue, trigger }) => {
      if (!opened) {
        return;
      }

      if (!passedData?.employeeId) {
        reset();
        return;
      }

      await defer();
      setValue('description', passedData.description);

      for (const item of passedData.addictionDetails) {
        setValue(item.addictiveSubstanceDefinitionId + '', item.addictionStateDefinitionId);
      }

      trigger();
      await defer();

      for (const item of passedData.addictionDetails) {
        for (const key of [
          'countOfYearsForQuit',
          'countPerDayForQuit',
          'countPerDayForUsing',
          'startYearForQuit',
          'startYearForUsing',
          'period',
          'periodUnitDefinition',
        ] as (keyof GetAddictionsResultItem)[]) {
          setValue(`${item.addictiveSubstanceDefinitionId}__${key}`, item[key]);
        }
      }

      trigger();
    },
    []
  );

  return (
    <PanelWithDialog
      ref={ref}
      dialogTitle="Bağımlılıkları Düzenle"
      dialogProps={{ maxWidth: 'md' }}
      onDialogOpen={prepareForm}
      form={values => (
        <>
          <List>
            {addictions.map(({ id, label }) => (
              <Fragment key={id}>
                <ListSubheader>
                  <Typography variant="h6" className="text-black">
                    {label} kullanıyor mu?
                  </Typography>
                </ListSubheader>
                <ListItem>
                  <Grid container>
                    <Grid item md={2} lg={2} sm={3} className="px-1">
                      <Select required name={id + ''} options={addictionStates} label="Kullanım" defaultValue={0} />
                    </Grid>
                    {renderFormItems(id, values[id], periodUnit)}
                  </Grid>
                </ListItem>
                <Divider className="mb-2" />
              </Fragment>
            ))}
            <ListSubheader>
              <Typography variant="h6" className="text-black">
                Açıklama
              </Typography>
            </ListSubheader>
            <ListItem>
              <TextField rows={3} name="description" />
            </ListItem>
          </List>
        </>
      )}
      indicationWrapperProps={{
        data: data?.employeeId,
        loading,
        errorMessage: 'Henüz bağımlılık bilgisi girilmemiş',
        errorChildren: (
          <footer className="text-right mt-2">
            <Button variant="contained" color="primary" onClick={insertAddictions}>
              Bağımlılıkları Düzenle
            </Button>
          </footer>
        ),
      }}>
      <header className="text-right">
        <Button color="secondary" variant="contained" onClick={insertAddictions}>
          Bağımlılıkları Düzenle
        </Button>
      </header>
      <Table>
        <TableBody>
          {data?.addictionDetails?.map(addiction => (
            <TableRow key={addiction.id}>
              <TableCell>{addiction.label}</TableCell>
              <TableCell>{addiction.description}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>Açıklama</TableCell>
            <TableCell>{data?.description}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </PanelWithDialog>
  );
}
