import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAdminSystemCheck, updateAdminSystemCheck } from 'api/hs/definitions/health-definitions/system-check';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { SystemCheckModel } from 'model/definitions/health-definitions/systemCheck.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the SystemCheck is created/updated
   */
  onClose(success: boolean): void;

  data: SystemCheckModel;
}

export default function AdminSystemCheck({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<SystemCheckModel>
      open={open}
      onClose={onClose}
      onSave={saveAdminSystemCheck}
      onUpdate={updateAdminSystemCheck}
      data={data}
      resourceReloadName="systemCheck"
      createTitle="Yeni Sistem Sorgulama Oluştur"
      updateTitle="Sistem Sorgulama Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Sistem Sorgulama Adı Giriniz" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField name="description" label="Açıklama" rows={3} />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField name="defaultNegativeResponse" label="Otomatik Yanıt" rows={3} />
      </Grid>
    </BaseDefinitionForm>
  );
}
