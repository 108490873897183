import { Grid, makeStyles } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import Button from 'Common/Button';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { ResourceContext } from 'context/resource.context';
import { useAsyncEffect, useDialogState, useTriggerRender } from 'utils';

import { PlanningStep } from './planning-steps.enum';
import { PlanningContext } from './planning.context';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'grid',
    gridTemplate: 'auto / auto minmax(20%, 1fr)',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default function Duration() {
  const classes = useStyles();
  const { selectedTrainings, activeStep, setDurations, setActiveStep, durations } = useContext(PlanningContext);
  const { trainingDefinitions } = useContext(ResourceContext);
  const formRef = useRef<FormRef<Record<string, number>>>();
  const totalFormRef = useRef<FormRef<{ total: number }>>();
  const reRender = useTriggerRender();
  const { open, close, opened } = useDialogState();

  const names = useMemo(
    () =>
      selectedTrainings.reduce(
        (acc, next) => ({ ...acc, [next]: findByKey(trainingDefinitions, 'id', next)?.name }),
        {} as Record<string, string>
      ),
    [selectedTrainings, trainingDefinitions]
  );
  const defaultDurations = useMemo(
    () =>
      selectedTrainings.reduce(
        (acc, next) => ({ ...acc, [next]: findByKey(trainingDefinitions, 'id', next)?.durationInMinute ?? 0 }),
        {} as Record<string, number>
      ),
    [selectedTrainings, trainingDefinitions]
  );

  const handleSubmit = useCallback(
    values => {
      setDurations(values);
      setActiveStep(PlanningStep.Participants);
    },
    [setDurations, setActiveStep]
  );

  const distributeTotalTime = useCallback(
    ({ total }: { total: number }) => {
      const lessons = Object.keys(formRef.current?.value);
      const lessonCount = lessons.length;
      const avarage = ~~(+total / lessonCount);

      const [lastLesson, ...otherLessons] = lessons.reverse();

      for (const key of otherLessons) {
        formRef.current?.methods.setValue(key, avarage);
      }

      formRef.current?.methods.setValue(lastLesson, +total - avarage * (lessonCount - 1));
      formRef.current?.methods.trigger();

      close();
    },
    [close]
  );

  useAsyncEffect(async () => {
    if (activeStep !== PlanningStep.Duration) {
      return;
    }

    await defer();

    for (const [name, value] of Object.entries(durations ?? defaultDurations)) {
      formRef?.current?.methods?.setValue(name, value);
    }

    await formRef?.current?.methods?.trigger();
  }, [defaultDurations, activeStep, durations]);

  return (
    <>
      <Button type="button" color="secondary" onClick={open}>
        Toplam Eğitim Süresi Belirle
      </Button>
      <Form ref={formRef} onSubmit={handleSubmit} onChange={reRender}>
        <Grid container>
          {selectedTrainings.map(training => (
            <Grid item xs={12} md={6} key={training} className="px-2">
              <div className={classes.row}>
                <span className="mr-2 text-larger">{names[training]}</span>
                <TextField type="number" name={training} label="Duration" endAdornment="dk" position="end" required />
              </div>
            </Grid>
          ))}
        </Grid>
        <footer className={classes.footer}>
          <Button disabled={!formRef.current?.valid} type="submit" variant="contained" color="secondary">
            Devam
          </Button>
        </footer>
      </Form>
      <Dialog
        opened={opened}
        onClose={close}
        color="primary"
        title="Toplam Eğitim Süresi Belirle"
        actions={
          <Button form={totalFormRef.current?.id} type="submit" disabled={!totalFormRef.current?.valid} variant="contained" color="primary">
            Belirle
          </Button>
        }>
        <Form ref={totalFormRef} onChange={reRender} onSubmit={distributeTotalTime}>
          <TextField name="total" type="number" label="Toplam Süre" endAdornment="dk" required />
        </Form>
      </Dialog>
    </>
  );
}
