import React, { useContext } from 'react';

import { UserContext } from '../../../../context/user';
import { UserRole } from '../../../../model/user-role.enum';
import BaseSection from '../BaseSection';
import FamilyHistory from '../FamilyHistory';
import IbysSection from '../IbysDescriptions/IbysSection';
import Measurements from '../Measurements';
import MedicalResume from '../MedicalResume';
import PeriodicExamination from '../PeriodicExaminationConfigurations';
import ProfessionalBackground from '../ProfessionalBackground';
import FileUploadSection from './FileUploadSection';
import PersonalDetails from './PersonalDetails';

const TABS = [
  {
    key: 1,
    title: 'Kişisel Bilgiler',
    icon: 'icon-account',
    Component: PersonalDetails,
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 2,
    title: 'Mesleki Özgeçmiş',
    icon: 'icon-account-card-details',
    Component: ProfessionalBackground,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 3,
    title: 'Soygeçmiş',
    icon: 'icon-account-multiple',
    Component: FamilyHistory,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 4,
    title: 'Tıbbi Özgeçmiş',
    icon: 'icon-file-document',
    Component: MedicalResume,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 5,
    title: 'Vücut / Vital Ölçümleri',
    icon: 'icon-stethoscope',
    Component: Measurements,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 6,
    title: 'Per.Muayene Ayarları',
    icon: 'icon-calendar-clock',
    Component: PeriodicExamination,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 7,
    title: 'Dosya Yükleme Alanı',
    icon: 'icon-folder-upload',
    Component: FileUploadSection,
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    key: 8,
    title: 'Meslek Tanımı Alanı',
    icon: 'icon-account-card-details',
    Component: IbysSection,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
];

export default function ProfilePage() {
  const { user } = useContext(UserContext);
  return (
    <div className="p-2">
      {TABS.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r))).map(({ title, icon, key, Component, visibleTo }) => (
        <BaseSection key={key} icon={icon} title={title}>
          <Component />
        </BaseSection>
      ))}
    </div>
  );
}
