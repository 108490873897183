import { Grid } from '@material-ui/core';
import React from 'react';

import Immunity from './Immunity';
import Vaccine from './Vaccine';

export default function ImmunityAndVaccine() {
  return (
    <Grid container>
      <Grid item sm={6} className="px-2">
        <Immunity />
      </Grid>
      <Grid item sm={6} className="px-2">
        <Vaccine />
      </Grid>
    </Grid>
  );
}
