import { Chip, IconButton } from '@material-ui/core';
import Avatars from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { defer, noop } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { EmployeeSelectionCacheContext } from '../../../../../Common/Form/EmployeeSelection/employee-selection.context';
import Avatar from '../../../../../TransactionsAndLists/EmployeeList/Avatar';
import { useAsyncEffect } from '../../../../../utils';
import { NewCaseTeamMembersContext } from '../../new-case-team-members.context';

export default function CaseTeamsMembersList() {
  const { caseTeamMembersData, setCaseTeamMembersData } = useContext(NewCaseTeamMembersContext);
  const { loadEmployees, names, avatars } = useContext(EmployeeSelectionCacheContext);

  const onDelete = useCallback(
    item => {
      setCaseTeamMembersData(caseTeamMembersData?.filter(data => data?.id !== item?.id));
    },
    [setCaseTeamMembersData, caseTeamMembersData]
  );

  useAsyncEffect(async () => {
    if (!caseTeamMembersData) {
      return;
    }
    await defer();

    loadEmployees(...caseTeamMembersData.map(employeeData => employeeData?.employeeId));
  }, [caseTeamMembersData]);

  return (
    <IndicatorWrapper loading={false} data={caseTeamMembersData} errorMessage="Listeye Kayıt Eklenmedi.">
      <div style={{ width: '100%', height: '3px', backgroundColor: 'gray' }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                Personel
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Katılma Tarihi
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Ayrılma Tarihi
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {caseTeamMembersData?.map((rowData, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {<Chip key={index} avatar={<Avatar url={avatars[rowData?.employeeId]} />} label={names[rowData?.employeeId]} />}
                </TableCell>
                <TableCell align="center">{rowData?.joinDate?.split('-')?.reverse()?.join('-')}</TableCell>
                <TableCell align="center">{rowData?.quitDate?.split('-')?.reverse()?.join('-')}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onDelete(rowData)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </IndicatorWrapper>
  );
}
