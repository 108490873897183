import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext } from 'react';

import { deleteProfessionalBackground } from 'api/hs/examination';
import { DialogContext } from 'context/dialog.context';
import { ProfessionalBackground } from 'model';
import { useXhr } from 'utils';

import { ProfessionalBackgroundContext } from '../professional-background.context';

interface Props {
  background: ProfessionalBackground;
}

export default function Action({ background }: Props) {
  const { openWarnDialog } = useContext(DialogContext);
  const { refresh, setDialogOpened, setSelectedBackground } = useContext(ProfessionalBackgroundContext);

  const deleteBackgroundRequest = useXhr(deleteProfessionalBackground, 'Kayıt başarıyla silindi', 'Kayıt silme işleminde hata oluştu');

  const deleteBackground = useCallback(async () => {
    const submit = await openWarnDialog({ text: `${background.companyName} kaydını silmek istediğinize emin misiniz ?` });

    if (submit) {
      await deleteBackgroundRequest(background.id);
      refresh();
    }
  }, [openWarnDialog, background, refresh, deleteBackgroundRequest]);

  const editBackground = useCallback(() => {
    setSelectedBackground(background);
    setDialogOpened(true);
  }, [setDialogOpened, setSelectedBackground, background]);

  return (
    <>
      <IconButton edge="end" aria-label="delete" onClick={deleteBackground}>
        <DeleteIcon />
      </IconButton>
      <IconButton edge="end" aria-label="edit" onClick={editBackground}>
        <EditIcon />
      </IconButton>
    </>
  );
}
