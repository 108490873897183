import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getPpeAssigmentEmployee } from 'api/hs/TransactionAndLists/Employee/PpeList';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import Table from 'Common/Table/Default';
import { useFetch } from 'utils';

import Button from '../../../../../Common/Button';
import PpeReturnForm from '../../../../../PPE/ppe-outputs/ppe-new-output';
import { CELL } from './columns';

export default function Index() {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const { data, fetch } = useFetch(async () => await getPpeAssigmentEmployee(employeeId), [], { setLoading });

  const onClose = useCallback(async () => {
    setOpen(false);
    await fetch();
  }, [fetch]);

  const showReturnForm = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <>
      <PpeReturnForm open={open} onClose={onClose} data={data} />
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek  Kayıt Bulunamadı">
        <header className="text-right mb-1 mr-1">
          <Button variant="contained" startIcon={<AddIcon />} color="secondary" onClick={showReturnForm} className="ml-1">
            Kullanılması Gerekenleri Zimmetle
          </Button>
        </header>
        <Table columns={CELL} data={data} />
      </IndicatorWrapper>
    </>
  );
}
