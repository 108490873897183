import { Grid } from '@material-ui/core';
import React from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import { SelectOption } from 'Common/Form/Select';

import { getProfessionDefinitions, ProfessionDefinitionsModel } from '../../../../api/hs/profession-definitions';
import { getWorkEquipmentList, searchWorkEquipments, WorkEquipmentsModel } from '../../../../api/hs/work-equipments';
interface WorkEquipmentParams {
  required: boolean;
}
export function WorkEquipment({ required }: WorkEquipmentParams) {
  function mapProfessionDefinition(types: WorkEquipmentsModel[]): SelectOption<string>[] {
    return types.map<SelectOption<string>>(({ ad, kod }) => ({ text: ad, value: kod }));
  }

  async function workEquipmentsOptionFetcher(value: string) {
    const res = await searchWorkEquipments(value);
    return mapProfessionDefinition(res.content);
  }

  async function workEquipmentsLabelFetcher(value: string[]) {
    const res = await getWorkEquipmentList(value);
    return mapProfessionDefinition(res);
  }

  function mapProfessionDefiniti(types: ProfessionDefinitionsModel[]): SelectOption<string>[] {
    return types.map<SelectOption<string>>(({ ad, kod }) => ({ text: ad, value: kod }));
  }

  async function professionDefinitionOptionFetcher(value: string) {
    const res = await getProfessionDefinitions(value);
    return mapProfessionDefiniti(res.content);
  }

  async function professionDefinitionLabelFetcher(value: string[] | string) {
    const res = await getProfessionDefinitions(value);
    return mapProfessionDefiniti(res.content);
  }

  return (
    <>
      <Grid item lg={12} md={12}>
        <AutoComplete
          name="code"
          label="İş Ekipmanları"
          optionFetcher={workEquipmentsOptionFetcher}
          labelFetcher={workEquipmentsLabelFetcher}
          renderOption="checkbox"
          required={required}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <AutoComplete
          required={required}
          name="professionCode"
          label="Meslek Tanımı"
          optionFetcher={professionDefinitionOptionFetcher}
          labelFetcher={professionDefinitionLabelFetcher}
          single
        />
      </Grid>
    </>
  );
}
