import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';

import { useAsyncEffect } from '../../../../../utils';
import { CaseTeamsDetailContext } from '../../../case-teams-detail.context';
import { NewCaseTeamMembersContext } from '../../new-case-team-members.context';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default function PpeOutputsFormDialog() {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { setCaseTeamMembersData, caseTeamMembersData } = useContext(NewCaseTeamMembersContext);
  const { emergencyCaseTeams } = useContext(CaseTeamsDetailContext);

  const onSave = useCallback(
    values => {
      const newFormDate = {
        ...values,
        emergencyCaseTeamId: emergencyCaseTeams?.id,
        id: guid(),
      };
      setCaseTeamMembersData([...caseTeamMembersData, newFormDate]);
      formRef?.current?.methods?.reset({ employeeId: null, quitDate: '', joinDate: '' });
    },
    [caseTeamMembersData, setCaseTeamMembersData, emergencyCaseTeams?.id]
  );

  return (
    <Form onSubmit={onSave} ref={formRef}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <AutoComplete required={true}
            name="employeeId"
            label="Personel Seç"
            optionFetcher={employeeOptionFetcher}
            labelFetcher={employeeLabelFetcher}
            single
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <DateInput defaultValue={''} label="Katılma Tarihi" name="joinDate" type="date" required={true} />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <DateInput defaultValue={''} label="Ayrılma Tarihi" name="quitDate" type="date" />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
          <Button variant="contained" type="submit" color="secondary" className="mr-2">
            Listeye Ekle
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
