import { Breadcrumbs, Icon, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import Toolbar from 'Common/Toolbar';

import { WorkEquipmentPeriodicControlContext } from '../../work-equipment-periodic-control.context';
export default function ToolbarWorkEquipmentPeriodic() {
  const history = useHistory();
  const { WorkEquipmentDefinitionData } = useContext(WorkEquipmentPeriodicControlContext);

  const backRoute = useCallback(() => {
    history.push('/work-equipment/definition');
  }, [history]);

  return (
    <Toolbar color="secondary">
      <Breadcrumbs style={{ color: 'white' }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link style={{ cursor: 'pointer' }} onClick={backRoute} color="inherit">
          <Icon className="icon-settings-box mr-1" />
          İş Ekipman Listesi
        </Link>
        <Typography style={{ color: 'white' }} color="textPrimary">
          <Icon className="  icon-file-document-box mr-1" />
          {`${WorkEquipmentDefinitionData?.definitionName} Periyodik Kontrol`}
        </Typography>
      </Breadcrumbs>
    </Toolbar>
  );
}
