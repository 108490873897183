import React, { useCallback } from 'react';

import { getPpeOutputsList } from 'api/hs/ppe/ppe-outputs';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { CELL } from './columns';
import ListTable from './ListTable';
import ReturnFormDialog from './ReturnFormDialog';
import Toolbar from './Toolbar';

export default function List() {
  const fetchPpeOutputsList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getPpeOutputsList(
      page,
      size,
      sorting === false ? 'createdDate-DESC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider fetcher={fetchPpeOutputsList} columns={CELL}>
      <Toolbar />
      <ListTable />
      <ReturnFormDialog />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
