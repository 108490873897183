import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetRestReportDefinitionsResult } from './get-rest-report-definitions.result';

export async function getRestReportDefinitions(): Promise<GetRestReportDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetRestReportDefinitionsResult[]>(`/firm/rest-report-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
