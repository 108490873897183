import { createContext } from 'react';

import { NearMissDto } from '../../api/client';

export interface NearMissContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: NearMissDto;

  setSelectedData(data: NearMissDto): void;
}

export const NearMissContext = createContext<NearMissContextType>(null);
