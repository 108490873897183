import React, { useState } from 'react';

import { getTrainingDefinitions, TrainingDefinitionsModel } from 'api/hs/definitions/trainings/training-definitions';
import { useFetch } from 'utils';

import TrainingDefinitions from './training-definitions';
import { TrainingDefinitionsContext } from './training-definitions.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<TrainingDefinitionsModel>(null);

  const { data, fetch } = useFetch(() => getTrainingDefinitions(), [], { setLoading });

  return (
    <TrainingDefinitionsContext.Provider
      value={{
        trainingDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <TrainingDefinitions />
    </TrainingDefinitionsContext.Provider>
  );
}
