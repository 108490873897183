import React, { useState } from 'react';

import { getStorehouseDefinitions, StorehouseDefinitionsResult } from 'api/hs/definitions/ppe-definitions/storehouse-definitions';
import { useFetch } from 'utils';

import { StorehouseDefinitionsContext } from './storehouse-definitions.context';
import StorehouseDefinition from './Storehouses';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<StorehouseDefinitionsResult>(null);

  const { data, fetch } = useFetch(() => getStorehouseDefinitions(), [], { setLoading });

  return (
    <StorehouseDefinitionsContext.Provider
      value={{
        storehouseDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <StorehouseDefinition />
    </StorehouseDefinitionsContext.Provider>
  );
}
