import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { ComplaintsPayload } from './complaints.payload';
import { ComplaintsResult } from './complaints.result';

export async function getComplaint(examinationId: string): Promise<ComplaintsResult> {
  const [error, res] = await tryCatch(HSApi.get<ComplaintsResult>(`/complaints?examinationId=${examinationId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveComplaint(body: ComplaintsPayload): Promise<ComplaintsResult> {
  const [err, res] = await tryCatch(HSApi.post('/complaints', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateComplaint({ id, ...body }: MakeRequired<Partial<ComplaintsResult>, 'id'>): Promise<ComplaintsResult> {
  const [error, res] = await tryCatch(HSApi.put<ComplaintsResult>(`/complaints/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteComplaint(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/complaints/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
