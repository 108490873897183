import { PregnanciesResult } from 'api/hs/examination/pregnancies';
import { modelDataMatcher } from 'utils';

import { WarningLabelsResult } from '../../api/hs/examination/warning-labels';
import { BaseEmployee } from './base-employee.model';

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export class Employee implements BaseEmployee {
  public id: string = null;
  public companyId: string = null;
  public name: string = null;
  public birthDate: string = null;
  public birthPlace: string = null;
  public gender: number = null;
  public nid: string = null;
  public avatarPath: string = null;
  public generalDescription: string = null;
  public bloodType: number = null;
  public hireDate: string = null;
  public quitDate: string = null;
  public ssiNumber: string = null;
  public email: string = null;
  public gsmNumber: string = null;
  public profession: string = null;
  public registryNumber: string = null;
  public telephone: string = null;
  public address: string = null;
  public businessTelephone: string = null;
  public workingType: number = null;
  public maritalStatus: number = null;
  public numberOfChildren: number = null;
  public retirementStatus: number = null;
  public educationalStatus: number = null;
  public departmentId: string = null;
  public locationDefinitionId: string = null;
  public stationId: string = null;
  public positionId: string = null;
  public exposureId: string[] = [];
  public allergiesId: string[] = [];
  public chronicDiseaseIcd10s: string[] = [];
  public chronicDiseaseData: boolean = false;
  public examinationFrequencyInMonth: number = null;
  public nextExaminationDate: string = null;
  public occupationalDiseaseDescription: string = null;
  public status: EmployeeStatus = null;
  public hasDisability?: boolean = null;
  public pregnancyHistory?: PregnanciesResult[] = null;
  public warningLabels?: WarningLabelsResult[] = null;

  constructor(data: Partial<Employee> = {}) {
    modelDataMatcher(data, this);
  }
}
