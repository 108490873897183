import { createContext } from 'react';

import { GetEmployeesPayload } from 'api/hs/employee-filter/get-employees.payload';
import { GetEmployeesResult } from 'api/hs/employee-filter/get-employees.result';
import { Employee } from 'model';
import { DeepPartial } from 'utils';

import { Cell } from './cells';

interface EmployeeContextType extends GetEmployeesResult {
  updateFilters(filters: DeepPartial<GetEmployeesPayload>): void;
  filters: GetEmployeesPayload;
  cells: Cell[];
  setCellVisibility(id: string, visible: boolean): void;
  reload(): void;
  selectedEmployee: Employee;
  setSelectedEmployee(employee: Employee): void;
  editEmployeeDialogOpened: boolean;
  setEditEmployeeDialogOpened(opened: boolean): void;
  detailsDrawerOpened: boolean;
  setDetailsDrawerOpened(opened: boolean): void;
}

export const EmployeeListContext = createContext<EmployeeContextType>(null);
