import React, { useCallback, useState } from 'react';

import { SystemSettingModel } from 'model/definitions/system-setting/systemSetting.model';
import { useAsyncEffect } from 'utils';

import { getSystemSetting, searchSystemSetting, SystemSettingResult } from '../../api/hs/definitions/system-setting';
import List from './System';
import { SystemSettingListContext } from './system-setting.context';

export default function Index() {
  const [allData, setAllData] = useState<SystemSettingResult[]>([]);
  const [data, setData] = useState<SystemSettingResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<SystemSettingModel>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const fetch = useCallback(
    async (searchString: string = '') => {
      const result = searchString ? await searchSystemSetting(searchString) : await getSystemSetting();
      setAllData(result);
      if (searchString) {
        setPage(1);
        setData(result.slice(0, size));
        setTotalPages(Math.ceil(result.length / size));
      } else {
        setData(result.slice((page - 1) * size, page * size));
        setTotalPages(Math.ceil(result.length / size));
      }
      setLoading(false);
    },
    [page, size]
  );

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  const changeSize = useCallback(
    (s: number) => {
      setData(allData.slice(0, s));
      setTotalPages(Math.ceil(allData.length / s));
      setSize(s);
      setPage(1);
    },
    [allData]
  );
  const changePage = useCallback(
    (p: number) => {
      setData(allData.slice((p - 1) * size, p * size));
      setPage(p);
    },
    [allData, size]
  );

  const search = useCallback(
    async (searchString: any) => {
      await fetch(searchString);
    },
    [fetch]
  );

  return (
    <SystemSettingListContext.Provider
      value={{
        data,
        reload: fetch,
        loading,
        setLoading,
        editDialogOpened,
        setEditDialogOpened,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
        totalPages,
        setTotalPages,
        size,
        setSize: changeSize,
        page,
        setPage: changePage,
        search,
      }}>
      <List />
    </SystemSettingListContext.Provider>
  );
}
