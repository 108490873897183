import { difference } from '@thalesrc/js-utils';
import i18n from 'i18n';
import { useEffect, useMemo, useState } from 'react';

import { LanguageNamespaces } from 'model/language-namespaces.type';

import useAsyncEffect from './use-async-effect';
import useDebouncedCallback from './use-debounced-callback';
import { ON_DESTROY_SYMBOL, useOnDestroy } from './use-on-destroy';
import { useTriggerRender } from './use-trigger-render';

const LOADED_NAMESPACES: LanguageNamespaces[] = ['common'];
const LOCALES: Record<string, Locale> = {};
const OBSERVABLE = new EventTarget();
const EVENT_NAME = 'localeLoaded';

function languageModuleFixer(language: string): string {
  switch (language) {
    case 'en':
      return 'en-US';
    default:
      return language;
  }
}

interface I18nHookData {
  loadNamespaces(...namespaces: LanguageNamespaces[]): Promise<void>;
  language: string;
  locale: Locale;
}

export function useI18n(): I18nHookData {
  const reRender = useTriggerRender();
  const onDestroy$ = useOnDestroy();
  const language = i18n.language;
  const [currentLocale, setCurrentLocale] = useState<Locale>(LOCALES[language] ?? null);

  const debouncedReRender = useDebouncedCallback(reRender, 25);
  const loadNamespaces = useDebouncedCallback((...namespaces: LanguageNamespaces[]) => {
    const nonLoadedNamespaces = difference(namespaces, LOADED_NAMESPACES);

    if (nonLoadedNamespaces.length) {
      LOADED_NAMESPACES.push(...nonLoadedNamespaces);

      Promise.race([i18n.loadNamespaces(nonLoadedNamespaces), onDestroy$]).then(val => {
        if (val === ON_DESTROY_SYMBOL) {
          return;
        }

        debouncedReRender();
      });
    }
  }, 25);

  useEffect(() => {
    i18n.on('languageChanged', debouncedReRender);
    i18n.on('loaded', debouncedReRender);

    return () => {
      i18n.off('languageChanged', debouncedReRender);
      i18n.off('loaded', debouncedReRender);
    };
  }, [debouncedReRender]);

  useAsyncEffect(async () => {
    if (!language) {
      return;
    }

    if (language in LOCALES) {
      setCurrentLocale(LOCALES[language]);

      return;
    }

    LOCALES[language] = null;

    const { default: locale } = await import(`date-fns/locale/${languageModuleFixer(language)}`);

    LOCALES[language] = locale;
    OBSERVABLE.dispatchEvent(new CustomEvent(EVENT_NAME));
  }, [language]);

  useEffect(() => {
    function listener() {
      setCurrentLocale(LOCALES[language]);
    }

    OBSERVABLE.addEventListener(EVENT_NAME, listener);

    return () => {
      OBSERVABLE.removeEventListener(EVENT_NAME, listener);
    };
  }, [language]);

  return useMemo<I18nHookData>(() => ({ loadNamespaces, language, locale: currentLocale }), [loadNamespaces, language, currentLocale]);
}
