import React from 'react';

import BaseSection from '../BaseSection';
import BodyMeasurements from './BodyMeasurements';
import VitalSigns from './VitalSigns';

export default function Index() {
  return (
    <>
      <BaseSection icon="icon-check" title="Vücut Ölçümleri">
        <BodyMeasurements />
      </BaseSection>
      <BaseSection icon="icon-check" title="Vital Ölçümleri">
        <VitalSigns />
      </BaseSection>
    </>
  );
}
