import { formatDate } from '@fullcalendar/react';
import { Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { AccidentControllerService } from '../../../api/client';
import { useFetch } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';

interface PairProps {
  title: string;
  desc: string;
}

const Pair = (props: PairProps) => {
  return (
    <>
      <Grid item xs={4} sm={2}>
        <label className="text-bold">{props.title}</label>
      </Grid>
      <Grid item xs={8} sm={4}>
        {props.desc}
      </Grid>
    </>
  );
};

export default () => {
  const { token, accidentId } = useContext(AccidentReviewContext);
  const { data } = useFetch(() => AccidentControllerService.getAccidentViewById(accidentId, null, token), {});

  return (
    <Container className="mt-2">
      <Paper elevation={3} className="p-1">
        <Box className={'p-2'}>
          <Typography component="h5" variant="h5">
            Kaza Bilgileri
          </Typography>
          <Divider className="mt-1 mb-2" />
          <Grid container spacing={2} className="mt-2">
            <Pair title="Kaza Türü" desc={data?.accidentTypeViews?.map(item => item.typeDefinitionLabel).join(', ')} />{' '}
            <Pair title="Departman" desc={data?.accidentDepartmentViews?.map(item => item.departmentName).join(', ')} />
            <Pair title="İstasyon" desc={data?.accidentStationViews?.map(item => item.stationName).join(', ')} />
            <Pair title="Tarih" desc={formatDate(data?.date)} />
            <Pair title="Vardiya" desc={data?.shiftDescription} />
            <Pair title="Kaza Tanımı" desc={data?.description} />
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};
