import React, { useState } from 'react';

import { getPpeAssigmentPosition, PpeAssignmentsResult } from 'api/hs/ppe/ppe-assignments';
import { useFetch } from 'utils';

import { AssigmentPositionContext } from './assigment-position.context';
import AssigmentPosition from './detail';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeAssignmentsResult>(null);

  const { data, fetch } = useFetch(() => getPpeAssigmentPosition(), [], { setLoading });

  return (
    <AssigmentPositionContext.Provider
      value={{
        ppeAssignmentsPosition: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <AssigmentPosition />
    </AssigmentPositionContext.Provider>
  );
}
