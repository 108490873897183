import DateFnsUtils from '@date-io/date-fns';
import { Grid, Paper } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { tr } from 'date-fns/locale';
import React, { useCallback, useRef } from 'react';

import Button from '../../Common/Button';
import DateInput from '../../Common/Form/DateInput';
import Form, { FormRef } from '../../Common/Form/Form';
import { RadioGroupInput } from '../../Common/Form/RadioGroup';
import { useTriggerRender } from '../../utils';
import { convertToRadioOptions, DisplayOption } from './display-option';

const lastYear = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDay());

interface ReportFormProps {
  onSubmit: any;
  collapsed: boolean;
  setCollapsed: any;
  options?: DisplayOption[];
  selectedOption?: DisplayOption;
}

const ReportForm = (props: ReportFormProps) => {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const onChangeForm = useCallback(
    formValues => {
      reRender();
    },
    [reRender]
  );

  return (
    <>
      <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>
        <Button onClick={() => props.setCollapsed(!props.collapsed)} color={'primary'}>
          {props.collapsed ? <MenuIcon /> : <MenuOpenIcon />}
        </Button>
        <Grid item sm={12}>
          {!props.collapsed && (
            <Paper>
              <Form onSubmit={props.onSubmit} ref={formRef} className={'m-2'} onChange={onChangeForm}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6} lg={4} sm={6}>
                    <DateInput label="Başlangıç Tarihi" name="minDate" type="date" defaultValue={lastYear.toISOString().substr(0, 10)} />
                  </Grid>
                  <Grid item xs={6} md={6} lg={4} sm={6}>
                    <DateInput label="Bitis Tarihi" name="maxDate" type="date" defaultValue={new Date().toISOString().substr(0, 10)} />
                  </Grid>
                  {props.options && (
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <RadioGroupInput
                        defaultValue={props.selectedOption.toString()}
                        label="Veri Ayrıntı Düzeyi"
                        name="dataDisplayOption"
                        options={convertToRadioOptions(props.options)}
                      />
                    </Grid>
                  )}
                </Grid>
                <div className={'text-right'}>
                  <Button variant="contained" type="submit" color="secondary" className="mb-2 ml-2">
                    {'Rapor Oluştur'}
                  </Button>
                </div>
              </Form>
            </Paper>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default ReportForm;
