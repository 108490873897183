import React from 'react';

import Add from './add';
import PrescriptionList from './list';
import ConvertToEPrescriptionDialog from './list/ConvertToEPrescriptionDialog';
import EmailDialog from './list/EPostaDialog';
import PreviewDialog from './list/PreviewDialog';

export default function Index() {
  return (
    <>
      <PrescriptionList />
      <Add />
      <ConvertToEPrescriptionDialog />
      <EmailDialog />
      <PreviewDialog />
    </>
  );
}
