import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useRef } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import Dialog from '../../../Common/Dialog';
import Select from '../../../Common/Form/Select';
import { useTriggerRender } from '../../../utils';

interface Props {
  open: boolean;
  onClose(): void;
  data: any;
  setTableData(item: any): void;
  setFormData(item: any): void;
}

const reviewerTypeMeanings = [
  { text: 'Takım Lideri', value: 'LEAD' },
  { text: 'Takım Üyesi', value: 'USER' },
  { text: 'Gözlemcı', value: 'READONLY' },
];

export default function ReviewTypeDialog({ data, open, onClose, setTableData, setFormData }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();

  const submit = useCallback(
    value => {
      setTableData({ ...data, ...value });
      setFormData(data);
      onClose();
    },
    [setTableData, data, onClose, setFormData]
  );

  return (
    <Dialog color="secondary" width="xs" opened={open} onClose={() => onClose()} title="Görev Seçiniz">
      <Form onSubmit={submit} onChange={reRender} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Select required name="reviewerType" options={reviewerTypeMeanings} label="Gözden Geçiren" />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              Kaydet
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose()}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
