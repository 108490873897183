/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyCaseDefinitionDto } from '../models/EmergencyCaseDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmergencyCaseDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmEmergencyCaseDefinitionsDefinition(
        id: string,
        requestBody: EmergencyCaseDefinitionDto,
    ): CancelablePromise<EmergencyCaseDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/emergency-case-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmEmergencyCaseDefinitionsDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/emergency-case-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static updateEmergencyCaseDefinitions(
        id: string,
        requestBody: EmergencyCaseDefinitionDto,
    ): CancelablePromise<EmergencyCaseDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/emergency-case-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllEmergencyCaseDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-definitions/${id}`,
        });
    }

    /**
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmEmergencyCaseDefinitions(): CancelablePromise<Array<EmergencyCaseDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/emergency-case-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmEmergencyCaseDefinitionsDefinition(
        requestBody: EmergencyCaseDefinitionDto,
    ): CancelablePromise<EmergencyCaseDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/emergency-case-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCaseDefinitions(): CancelablePromise<Array<EmergencyCaseDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns EmergencyCaseDefinitionDto OK
     * @throws ApiError
     */
    public static saveEmergencyCaseDefinitions(
        requestBody: EmergencyCaseDefinitionDto,
    ): CancelablePromise<EmergencyCaseDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/emergency-case-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}