import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { OpinionContext } from '../../opinion.context';

export default function Add() {
  const { setEditDialogOpened } = useContext(OpinionContext);

  const openEditDialog = useCallback(() => setEditDialogOpened(true), [setEditDialogOpened]);
  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={openEditDialog} className="mt-2">
        Kanaat Ekle / Düzenle
      </Button>
    </>
  );
}
