import { Button, FormControl, FormHelperText, Input } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { login } from 'api/hs/authentication/login';
import { UserContext } from 'context/user';
import { email } from 'validators';

import { AlertContext } from '../context/alert.context';
import InputLabel from './InputLabel';
import Layout from './Layout';

export default function LicenceError() {
  const { setUser } = useContext(UserContext);
  const history = useHistory();
  const { showMessage } = useContext(AlertContext);

  return (
    <Layout>
      <div className="text-center">
        Kullanmış olduğunuz lisans geçersiz, lütfen lisans durumunuzu kontrol edin. Hata olduğunu düşünüyorsanız Artımetrik ile iletişime geçiniz.
      </div>
    </Layout>
  );
}
