import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import {
  deleteWorkAccidentHistory,
  getWorkAccidentHistory,
  saveWorkAccidentHistory,
  updateWorkAccidentHistory,
  WorkAccidentHistory,
} from 'api/hs/examination';
import TextField from 'Common/Form/TextField';

import DateInput from '../../../../Common/Form/DateInput';
import History from './History';

export default function WorkAccidents() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(() => getWorkAccidentHistory(employeeId, examinationId), [employeeId, examinationId]);
  const save = useCallback(
    ({ description, date }: Omit<WorkAccidentHistory, 'id'>) => saveWorkAccidentHistory({ employeeId, examinationId, description, date }),
    [employeeId, examinationId]
  );
  const update = useCallback(({ id, ...rest }: WorkAccidentHistory) => updateWorkAccidentHistory(id, rest), []);
  const remove = useCallback((id: WorkAccidentHistory['id']) => deleteWorkAccidentHistory(id), []);

  return (
    <History<WorkAccidentHistory>
      fetch={fetch}
      save={save}
      update={update}
      remove={remove}
      title="İş Kazası Öyküsü"
      buttonLabel="İş Kazası Öyküsü Ekle"
      form={
        <>
          <Grid item md={12}>
            <DateInput type="date" label="Kaza Tarihi" name="date" required />
          </Grid>
          <Grid item md={12}>
            <TextField rows={2} label="Açıklama" name="description" required />
          </Grid>
        </>
      }
    />
  );
}
