import { Grid, List, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';

import AlertInformation from 'Common/AlertInformation';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { SelectOptionsContext } from 'context/select-options.context';
import { dateDisplayFormat } from 'utils';

import ESignatureNotification from '../../../../../../Common/ESignatureNotification';
import { PrescriptionContext } from '../../prescription.context';
import Action from './Action';

const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  col: {
    color: 'inherit',
  },
  header: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

export default function PrescriptionList() {
  const { loading, prescriptions } = useContext(PrescriptionContext);
  const classes = useStyles();
  const {
    prescriptionTypeDefinitions,
    prescriptionDescriptionDefinitions,
    prescriptionProvisionDefinitions,
    prescriptionSubtypeDefinitions,
  } = useContext(SelectOptionsContext);

  useEffect(() => {
    if (!prescriptions) {
      return;
    }
  }, [prescriptions]);

  ESignatureNotification();

  return (
    <IndicatorWrapper loading={loading} data={prescriptions} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.sideGrid}>
          <List>
            {prescriptions && prescriptions.length > 0 ? (
              <TableContainer style={{ marginBottom: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header} align="left" style={{ width: '20%' }}>
                        e-Reçete No
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '20%' }}>
                        Reçete Tarihi
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '20%' }}>
                        Reçete Türü
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                        Reçete Alt Türü
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                        Provizyon Tipi
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                        Açıklama Türü
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                        Açıklama
                      </TableCell>
                      <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                        İşlemler
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prescriptions?.map((item, index) => (
                      <TableRow hover key={`prescription-${item.employeeId}-${index}`}>
                        <TableCell className={classes.col} align="left">
                          {item.eprescriptionNo ? item.eprescriptionNo : '-'}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {dateDisplayFormat(item.requestDate)}
                          <br />
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {prescriptionTypeDefinitions.find(def => def.value === item.prescriptionTypeDefinitionId)?.text}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {prescriptionSubtypeDefinitions.find(def => def.value === item.prescriptionSubtypeDefinitionId)?.text}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {prescriptionProvisionDefinitions.find(def => def.value === item.prescriptionProvisionTypeDefinitionId)?.text}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {prescriptionDescriptionDefinitions.find(def => def.value === item.prescriptionDescriptionTypeDefinitionId)?.text}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          {item.description}
                        </TableCell>
                        <TableCell className={classes.col} align="left">
                          <Action data={item} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <AlertInformation severity="error" message="Gösterilecek Kayıt Bulunamadı !" />
            )}
          </List>
        </Grid>
      </Grid>
    </IndicatorWrapper>
  );
}
