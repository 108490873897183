import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getPpeOutputsListEmployee } from 'api/hs/TransactionAndLists/Employee/PpeList';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import Table from 'Common/Table/Default';
import { useFetch } from 'utils';

import { CELL } from './columns';

export default function Index() {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [loading, setLoading] = useState(true);

  const { data } = useFetch(async () => await getPpeOutputsListEmployee(employeeId), [], { setLoading });

  return (
    <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek  Kayıt Bulunamadı">
      <Table columns={CELL} data={data} />
    </IndicatorWrapper>
  );
}
