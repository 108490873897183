import { Chip, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { ActionsContentResult } from 'api/hs/actions';
import { deleteAction } from 'api/hs/actions';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../context/dialog.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';
import Avatar from '../../../../TransactionsAndLists/EmployeeList/Avatar';
import { dateDisplayFormat, dateToInputFormat, useXhr } from '../../../../utils';
import DateDifferent from '../../../../utils/DateDifferent';
import { ActionContext } from '../../action.context';
export function EmployeeAvatar({ row }: { row: ActionsContentResult }) {
  return (
    <>
      {row?.employees?.map(employee => (
        <Chip key={employee?.id} avatar={<Avatar url={employee?.avatarPath} />} label={employee?.name} />
      ))}
    </>
  );
}

export function StatusShowAlert({ row }: { row: ActionsContentResult }) {
  switch (row?.actionStatusName) {
    case 'Yapılamaz':
      return (
        <Alert variant="outlined" icon={<CloseIcon />} severity="error">
          {row?.actionStatusName}
        </Alert>
      );
    case 'Yapıldı':
      return <Alert severity="success">{row?.actionStatusName}</Alert>;
    case 'Yapılıyor':
      return (
        <Alert icon={<AccessTimeIcon />} severity="warning">
          {row?.actionStatusName}
        </Alert>
      );
    case 'Yapılacak':
      return <Alert severity="info">{row?.actionStatusName}</Alert>;
    default:
      return <Alert severity="info">{row?.actionStatusName}</Alert>;
  }
}

export function PriorityShowAlert({ row }: { row: ActionsContentResult }) {
  switch (row?.actionPriorityName) {
    case 'Yüksek':
      return (
        <Alert variant="outlined" severity="error">
          {row?.actionPriorityName}
        </Alert>
      );
    case 'Düşük':
      return (
        <Alert icon={false} severity="success">
          {row?.actionPriorityName}
        </Alert>
      );
    case 'Orta':
      return (
        <Alert icon={false} severity="warning">
          {row?.actionPriorityName}
        </Alert>
      );
    default:
      return (
        <Alert icon={false} severity="info">
          {row?.actionPriorityName}
        </Alert>
      );
  }
}

function ActionComponent({ row }: { row: ActionsContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(ActionContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog } = useContext(DialogContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteAction(id);
      await refresh();
    },
    'Seçili Aksiyon Silindi',
    'Aksiyon silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu aksiyonu silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export function ActionReferenceName({ row }: { row: ActionsContentResult }) {
  const { actionsReferenceDefinitions } = useContext(SelectOptionsContext);
  return (
    <div key={row?.id} id={row?.id}>
      {findByKey(actionsReferenceDefinitions, 'value', row?.actionReference)?.text}
    </div>
  );
}

function Test({ row }: { row: ActionsContentResult }) {
  return (
    <div>
      {DateDifferent(new Date().toISOString(), row?.plannedEndDate) < 0 ? (
        <>
          <Alert variant="standard" severity="error">
            {row?.plannedEndDate?.split('-')?.reverse()?.join('-')} tarihi geçti.
          </Alert>
        </>
      ) : (
        <>{row?.plannedEndDate?.split('-')?.reverse()?.join('-')}</>
      )}
    </div>
  );
}

export const COLUMNS: TableColumn<ActionsContentResult>[] = [
  {
    id: 'employees',
    width: '15%',
    label: 'Sorumlular',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return <EmployeeAvatar row={row} />;
    },
  },
  {
    id: 'actionReference',
    width: '10%',
    label: 'Aksiyon Kaynağı',
    defaultVisibility: true,
    sortable: true,
    render(row: ActionsContentResult): JSX.Element {
      return <ActionReferenceName row={row} />;
    },
  },
  {
    id: 'actionPriorityDefinition',
    width: '5%',
    label: 'Öncelik',
    defaultVisibility: true,
    sortable: true,
    render(row: ActionsContentResult): JSX.Element {
      return <PriorityShowAlert row={row} />;
    },
  },
  {
    id: 'plannedEndDate',
    width: '15%',
    label: 'Termin Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: ActionsContentResult): JSX.Element {
      return <>{<Test row={row} />}</>;
    },
  },
  {
    id: 'completedDate',
    width: '15%',
    label: 'Tamamlanma Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: ActionsContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.completedDate)}</>;
    },
  },
  {
    id: 'actionStatusDefinition',
    width: '10%',
    label: 'Durumu',
    defaultVisibility: true,
    sortable: true,
    render(row: ActionsContentResult): JSX.Element {
      return <StatusShowAlert row={row} />;
    },
  },
  {
    id: 'userName',
    width: '15%',
    sortable: true,
    label: 'Oluşturan',
    defaultVisibility: true,
  },
  {
    id: Symbol(),
    label: 'İşlemler',
    width: '5%',
    render(row: ActionsContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
