import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { PpeAssigmentResult } from './ppe-assignments.result';

export async function getPpeAssigmentPosition(): Promise<PpeAssigmentResult[]> {
  let res;
  try {
    res = await HSApi.get<PpeAssigmentResult[]>(`/ppe-definitions/ppe-assignments?assignmentType=POSITION`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getPpeAssigmentEmployee(): Promise<PpeAssigmentResult[]> {
  let res;
  try {
    res = await HSApi.get<PpeAssigmentResult[]>(`/ppe-definitions/ppe-assignments?assignmentType=EMPLOYEE`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deletePpeAssignments(id: string, type: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe-definitions/${id}/ppe-assignments/types/${type}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updatePpeAssignments(id: any, body: any) {
  const [error, res] = await tryCatch(HSApi.put(`/ppe-definitions/${id}/ppe-assignments`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
