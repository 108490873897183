import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useCallback, useContext } from 'react';

import { WorkEquipmentPeriodicControllerService } from '../../../../api/client';
import DeleteDialogIcon from '../../../../Common/DeleteDialogIcon';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { AlertContext } from '../../../../context/alert.context';
import { DialogContext } from '../../../../context/dialog.context';
import BaseSection from '../../../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import { useDateFormat } from '../../../../utils';
import { WorkEquipmentPeriodicControlContext } from '../../work-equipment-periodic-control.context';
import AddButton from '../AddButton';
import EditDialog from './EditDialog';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export default function Index() {
  const { workEquipmentPeriodicControl, WorkEquipmentDefinitionData, loading, reload, setSelectedData, setEditDialogOpened } = useContext(
    WorkEquipmentPeriodicControlContext
  );
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const handleDelete = useCallback(
    async value => {
      const submitted = await openWarnDialog({
        text: `${value.label} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await WorkEquipmentPeriodicControllerService.deleteAllWorkEquipmentPeriodic(value.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  const onEdit = useCallback(
    (value: any) => {
      setSelectedData({ ...value, cronRequestAsJson: JSON.parse(value.cronRequestAsJson) });
      setEditDialogOpened(true);
    },
    [setSelectedData, setEditDialogOpened]
  );

  return (
    <div className="m-1">
      <BaseSection icon="icon-calendar-clock" title={`${WorkEquipmentDefinitionData?.definitionName} Periyodik Kontrolleri`}>
        <AddButton />
        <IndicatorWrapper loading={loading} data={workEquipmentPeriodicControl} errorMessage="Gösterilecek Kayıt Bulunamadı.">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="text-bold text-gray" width="15%" align="left">
                    Periyod İsmi
                  </TableCell>
                  <TableCell className="text-bold text-gray" width="45%" align="left">
                    Periyodun Açıklaması
                  </TableCell>
                  <TableCell className="text-bold text-gray" width="20%" align="left">
                    Oluşturma Tarihi
                  </TableCell>
                  <TableCell className="text-bold text-gray" width="10%" align="right">
                    İşlem
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workEquipmentPeriodicControl.map(item => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item?.label}</TableCell>
                    <TableCell align="left">{item?.cronDescription}</TableCell>
                    <TableCell align="left">
                      <DateField date={new Date(item?.createdDate)} />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => onEdit(item)} edge="end" aria-label="edit">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(item)} edge="end" aria-label="delete">
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <EditDialog />
        </IndicatorWrapper>
      </BaseSection>
    </div>
  );
}
