import { Chip, Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { Icd10CacheContext } from 'Common/Form/Icd10Selection/icd10.context';
import IndicatorWrapper from 'Common/IndicatorWrapper';

import { DiagnosisContextContext } from '../../diagnosis.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function Listtt() {
  const { loading, diagnosis } = useContext(DiagnosisContextContext);
  const { getOptions, options } = useContext(Icd10CacheContext);
  const { examinationId } = useParams<{ examinationId: string }>();

  useEffect(() => {
    if (!diagnosis) {
      return;
    }
    getOptions(...diagnosis.reduce((acc, item) => [...acc, ...item.icd10s], []));
  }, [getOptions, diagnosis]);

  return (
    <IndicatorWrapper loading={loading} data={diagnosis} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <List id="listReferrals">
        {diagnosis?.map((item, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemAvatar>
                <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-dna" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography component="div">
                    {item?.icd10s
                      ?.map(id => findByKey(options, 'id', id))
                      ?.filter(isTruthy)
                      ?.map(({ id, label }) => (
                        <Fragment key={id}>
                          <Icon style={{ color: 'gray' }} className="icon-heart-pulse" />
                          {label} {` `}
                          {item?.occupationalDiseasePreDiagnosis ? (
                            <Chip size="small" label="Meslek Hastalığı Tanısı" color="primary" />
                          ) : null}
                        </Fragment>
                      ))}
                  </Typography>
                }
                secondary={
                  <Typography className="text-italic">
                    <Icon style={{ color: 'gray' }} className="icon-document" /> {item?.description}
                  </Typography>
                }
              />
              {examinationId ? (
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
