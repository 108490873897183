import React, { useCallback } from 'react';

import { RiskAssessmentHazardView } from '../../api/client';
import { getAccidentIdActions } from '../../api/hs/accident/accident';
import Dialog from '../../Common/Dialog';
import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import CustomAction from '../../CustomAction';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: RiskAssessmentHazardView;
}
export default function ActionListAdd({ open, onClose, data }: Props) {
  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      return getAccidentIdActions(
        data?.id,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [data?.id]
  );

  return (
    <div key={data?.id}>
      <Dialog color="secondary" width="xl" opened={open} onClose={() => onClose(false)} title="Risk Değerlendirme Aksiyon">
        <CustomAction actionReferenceValue={'RISK_ASSESSMENT'} referenceId={data?.id} fetcher={fetchActionList} />
      </Dialog>
    </div>
  );
}
