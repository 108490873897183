import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import AlertInformation from 'Common/AlertInformation';
import Button from 'Common/Button';
import Radio from 'Common/Form/Radio';
import { useAsyncEffect, useUniqueId } from 'utils';

import { MedicineCacheContext } from './medicine.context';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const RADIO_NAME = 'favouriteMedicineId';

export default function Favourites() {
  const classes = useStyles();
  const formId = useUniqueId('medicine-form');
  const { favouritesDialogOpened, closeFavouritesDialog, favourites, labels, removeFromFavourites, currentValueForDialogForm } = useContext(
    MedicineCacheContext
  );
  const methods = useForm();

  const handleRemove = useCallback(
    (id: string) => (e: React.MouseEvent) => {
      removeFromFavourites(id);
    },
    [removeFromFavourites]
  );

  const handleSubmit = useCallback(
    values => {
      closeFavouritesDialog(values[RADIO_NAME]);
    },
    [closeFavouritesDialog]
  );

  const cancel = useCallback(() => closeFavouritesDialog(false), [closeFavouritesDialog]);

  useAsyncEffect(async () => {
    if (!favouritesDialogOpened) {
      return;
    }

    await defer();

    methods.setValue(RADIO_NAME, favourites.includes(currentValueForDialogForm) ? currentValueForDialogForm : null);
    methods.trigger();
  }, [favouritesDialogOpened, methods.setValue, methods.trigger, favourites]);

  return (
    <Dialog open={favouritesDialogOpened} onClose={cancel} fullWidth maxWidth="sm">
      <DialogTitle>Favorilerden İlaç Seç</DialogTitle>
      <DialogContent>
        {favourites.length ? (
          <FormProvider {...methods}>
            <form id={formId} onSubmit={methods.handleSubmit(handleSubmit)}>
              {favourites.map(id => (
                <div key={id} className={`${classes.row} mb-1`}>
                  <Radio name={RADIO_NAME} label={labels[id] || '...'} value={id} />
                  <IconButton onClick={handleRemove(id)} size="small" title="Favorilerden Çıkar">
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </form>
          </FormProvider>
        ) : (
          <AlertInformation severity="error" message="Hiç favori ilaç bulunmuyor" />
        )}
      </DialogContent>
      <DialogActions className="text-right">
        <Button onClick={cancel}>Vazgeç</Button>
        {favourites.length ? (
          <Button color="primary" variant="contained" form={formId} type="submit" disabled={!methods.getValues(RADIO_NAME)}>
            Seç
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
