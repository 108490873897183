import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { WorkEquipmentPeriodicControlContext } from '../../work-equipment-periodic-control.context';
import WorkEquipmentPeriodicForm from '../Form/WorkEquipmentPeriodicForm';

export default function WorkEquipmentPeriodicControlAddButton() {
  const { reload, newDialogOpened, setNewDialogOpened } = useContext(WorkEquipmentPeriodicControlContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        reload();
      }
      setNewDialogOpened(false);
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <header className="text-right mr-1">
        <Button startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          Yeni Periyodik Kontrol Ekle
        </Button>
      </header>
      <WorkEquipmentPeriodicForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
