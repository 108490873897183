import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { CreateTrainingImportedFilesResult } from '../create-training-imported-files.result';
import { TrainingDetailResult } from '../training-detail.result';

export async function getTrainingById(trainingId: string): Promise<TrainingDetailResult> {
  let res;
  try {
    res = await HSApi.get<TrainingDetailResult>(`/trainings/${trainingId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getImportedFiles(trainingId: string): Promise<CreateTrainingImportedFilesResult[]> {
  let res;
  try {
    res = await HSApi.get<CreateTrainingImportedFilesResult[]>(`/trainings/imported-files/${trainingId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteImportedFiles(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/imported-files/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
