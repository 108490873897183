import { TableCell } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import TablePagination from 'Common/TablePagination';

import { TableContext } from '../Raw/table.context';
import { PaginationContext } from './pagination.context';

export default function DefaultPagination() {
  const { visibleColumns } = useContext(TableContext);
  const { page, size, totalPage, onSizeChange, onPageChange } = useContext(PaginationContext);

  const handlePageChange = useCallback(p => onPageChange(p - 1), [onPageChange]);

  return (
    <TableCell colSpan={visibleColumns.length}>
      <TablePagination size={size} onSizeChange={onSizeChange} onPageChange={handlePageChange} page={page + 1} count={totalPage} />
    </TableCell>
  );
}
