import { Card, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { IbysTrainingControllerService } from '../../api/client';
import { TrainingViewControllerService } from '../../api/client';
import { TrainingEmployeeViewControllerService } from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { useAsyncEffect } from '../../utils';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import { convertByFieldNameTotalOfValue } from '../utils/chart-data-converter-pie';
import { valueOfFieldArr } from '../utils/chart-data-utils';
import { addFieldValueCount, showValueCountLength } from '../utils/date-clustered-chart-data-converter-xy';
import { DisplayOption } from '../utils/display-option';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export const partHeaderStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  title: {
    fontSize: 'xx-large',
  },
});

export default function Education() {
  const partHeaderSt = partHeaderStyles();
  const [chartData, setChartData] = useState([]);
  const [neverTrainings, setNeverTrainings] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();
  const { companies, departments, positions, workStations, trainingGroupDefinitions } = useContext(SelectOptionsContext);
  const [ibysTraining, setIbysTraining] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      trainingEmployeeFilter: {
        startDate: values?.minDate + 'T00:00:00',
        endDate: values?.maxDate + 'T23:59:59',
        hasTraining: true,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
      },
    };

    const trainingRequest = {
      trainingFilter: {},
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
      },
    };

    const neverTrainingsRequest = {
      trainingEmployeeFilter: {
        isPlanned: false,
        startDate: values?.minDate + 'T00:00:00',
        endDate: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
      },
    };

    const res = await TrainingEmployeeViewControllerService.getTrainingEmployeeViews(request);
    const neverTrainingsRes = await TrainingEmployeeViewControllerService.getEmployeeTrainings(neverTrainingsRequest);
    const trainingRes = await TrainingViewControllerService.getAllTrainingViews(trainingRequest);
    const ibysTrainingRes = await IbysTrainingControllerService.getAllIbysTrainingStatus();
    setIbysTraining(ibysTrainingRes?.content);
    setTrainingData(trainingRes?.content);
    setChartData(res.content);
    setNeverTrainings(neverTrainingsRes.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Eğitim İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Card
                style={{
                  minHeight: '230',
                  minWidth: '275',
                  background: '#a80b1e',
                  color: 'white',
                  padding: '10px',
                  margin: '10px',
                  borderRadius: '5px',
                }}
                variant="outlined">
                <CardHeader className="text-center" title="Planlanan Eğitimler" />
                <CardContent>
                  <Typography className="text-italic" variant="subtitle1">
                    Eğitim Sayısı : {chartData?.filter(filtredDate => !filtredDate?.training?.completedDate)?.length}
                  </Typography>
                  <Typography className="text-italic" variant="subtitle1">
                    Katılan Sayısı :{' '}
                    {
                      chartData
                        ?.filter(filtredDate => !filtredDate?.training?.completedDate)
                        ?.filter(filtredEmployeeData => filtredEmployeeData?.employee)?.length
                    }
                  </Typography>
                  <Typography className="text-italic" variant="subtitle1">
                    Süresi :{' '}
                    {chartData
                      ?.filter(filtredDate => !filtredDate?.training?.completedDate)
                      ?.map(filtredDate => filtredDate?.training)
                      ?.reduce((total, trainingDefinition) => {
                        return total + trainingDefinition.durationInMinutes;
                      }, 0)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card
                style={{
                  minHeight: '230',
                  minWidth: '275',
                  background: '#02AAB0',
                  color: 'white',
                  padding: '10px',
                  margin: '10px',
                  borderRadius: '5px',
                }}
                variant="outlined">
                <CardHeader className="text-center" title="Tamamlanan Eğitimler" />
                <CardContent>
                  <Typography className="text-italic" variant="subtitle1">
                    Eğitim Sayısı : {chartData?.filter(filtredDate => filtredDate?.training?.completedDate)?.length}
                  </Typography>
                  <Typography className="text-italic" variant="subtitle1">
                    Katılan Sayısı :{' '}
                    {
                      chartData
                        ?.filter(filtredDate => filtredDate?.training?.completedDate)
                        ?.filter(filtredEmployeeData => filtredEmployeeData?.employee)?.length
                    }
                  </Typography>
                  <Typography className="text-italic" variant="subtitle1">
                    Süresi :{' '}
                    {Math.round(
                      chartData
                        ?.filter(filtredDate => filtredDate?.training?.completedDate)
                        ?.map(filtredDate => filtredDate?.training)
                        ?.reduce((total, trainingDefinition) => {
                          return total + trainingDefinition.durationInMinutes;
                        }, 0) / 60
                    )}
                    {' saat'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card
                style={{
                  minHeight: '210px',
                  minWidth: '275',
                  background: '#3e9a09',
                  color: 'white',
                  padding: '10px',
                  margin: '10px',
                  borderRadius: '5px',
                }}
                className={`text-center`}
                variant="outlined">
                <CardHeader className="text-center" title="Eğitime Katılmayanlar" />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Hiç Eğitime Katılmayan Kişi Sayısı : {neverTrainings?.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Card
                style={{
                  minHeight: '210px',
                  minWidth: '275',
                  background: '#5472d3',
                  color: 'white',
                  padding: '10px',
                  margin: '10px',
                  borderRadius: '5px',
                }}
                className={`text-center`}
                variant="outlined">
                <CardHeader className="text-center" title="Bakanlık Eğitimler" />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Bakanlığa Gönderilen Eğitim Sayısı : {ibysTraining?.filter(ibysSaving => ibysSaving?.ibysServerId)?.length}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Bakanlığa Gönderilen Personel Sayısı :{' '}
                    {ibysTraining
                      ?.filter(ibysSaving => ibysSaving?.ibysServerId)
                      ?.map(ibysEmployee => ibysEmployee?.ibysTrainingEmployees)
                      ?.reduce((total, employees) => {
                        return total + employees.length;
                      }, 0)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Eğitime Katılanlara Göre" classes={partHeaderSt} />
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Şirketlere Göre Dağılımı"
                      chartProps={{
                        chartName: 'exposureDefinitionId-report',
                        preparedData: convertAccidentReviewPropertyToChartData(chartData, 'employee.companyId', companies),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Departmana Göre Dağılımı"
                      chartProps={{
                        chartName: 'departmentDefinitionId-report',
                        preparedData: convertAccidentReviewPropertyToChartData(chartData, 'employee.departmentId', departments),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Pozisyona Göre Dağılımı"
                      chartProps={{
                        chartName: 'positionDefinitionId-report',
                        preparedData: convertAccidentReviewPropertyToChartData(chartData, 'employee.positionId', positions),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="İş İstasyonuna Göre Dağılımı"
                      chartProps={{
                        chartName: 'stationDefinitionId-report',
                        preparedData: convertAccidentReviewPropertyToChartData(chartData, 'employee.stationId', workStations),
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Eğitim Almamış Kişilere  Göre" classes={partHeaderSt} />
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Şirketlere Göre Dağılımı"
                      chartProps={{
                        chartName: 'never-trainings-company-report',
                        preparedData: convertAccidentReviewPropertyToChartData(neverTrainings, 'companyId', companies),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Departmana Göre Dağılımı"
                      chartProps={{
                        chartName: 'never-trainings-departments-report',
                        preparedData: convertAccidentReviewPropertyToChartData(neverTrainings, 'departmentId', departments),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="Pozisyona Göre Dağılımı"
                      chartProps={{
                        chartName: 'never-trainings-position-report',
                        preparedData: convertAccidentReviewPropertyToChartData(neverTrainings, 'positionId', positions),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <PieChartGridContainer
                      title="İş İstasyonlarına Göre Dağılımı"
                      chartProps={{
                        chartName: 'never-trainings-station-report',
                        preparedData: convertAccidentReviewPropertyToChartData(neverTrainings, 'stationId', workStations),
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} lg={6}>
              <PieChartGridContainer
                title="Eğitim Şekline Göre Dağılımı"
                chartProps={{
                  data: chartData,
                  chartName: 'facetoFaceDefinitionId-report',
                  selectorFieldByName: 'training.faceToFace',
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PieChartGridContainer
                title="Eğitim Grubuna Göre Dağılımı"
                chartProps={{
                  chartName: 'training-groupId-report',
                  preparedData: convertAccidentReviewPropertyToChartData(
                    trainingData,
                    'trainingDefinitionTrainings[].trainingDefinition.groupId',
                    trainingGroupDefinitions
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Eğitime Süresine Göre" classes={partHeaderSt} />
                <Grid container>
                  <Grid item xs={6}>
                    <PieChartGridContainer
                      title="Şirketlere Göre Dağılımı"
                      chartProps={{
                        chartName: 'training-durationInMinutes-companyName-report',
                        preparedData: convertByFieldNameTotalOfValue(chartData, 'employee.companyName', 'training.durationInMinutes'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PieChartGridContainer
                      title="Departmanlara Göre Dağılımı"
                      chartProps={{
                        chartName: 'training-durationInMinutes-departmentName-report',
                        preparedData: convertByFieldNameTotalOfValue(chartData, 'employee.departmentName', 'training.durationInMinutes'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PieChartGridContainer
                      title="Pozisyonlara Göre Dağılımı"
                      chartProps={{
                        chartName: 'training-durationInMinutes-positionName-report',
                        preparedData: convertByFieldNameTotalOfValue(chartData, 'employee.positionName', 'training.durationInMinutes'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PieChartGridContainer
                      title="İş İstasyonlarına Göre Dağılımı"
                      chartProps={{
                        chartName: 'training-durationInMinutes-stationName-report',
                        preparedData: convertByFieldNameTotalOfValue(chartData, 'employee.stationName', 'training.durationInMinutes'),
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
