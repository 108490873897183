import { Button, ListItem } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import TextField from 'Common/Form/TextField';
import { useAsyncEffect } from 'utils';

interface Props {
  value: any;

  onSubmit(value: string): void;
}

export default function CommentForm({ value, onSubmit }: Props) {
  const { setValue, trigger, ...formMethods } = useForm();

  const handleSubmit = useCallback(({ field }) => onSubmit(field), [onSubmit]);

  useAsyncEffect(async () => {
    await defer();

    setValue('field', value);
    trigger();
  }, [value, setValue, trigger]);

  return (
    <FormProvider {...{ ...formMethods, setValue, trigger }}>
      <form className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <ListItem style={{ height: '100px' }}>
          <TextField name="field" rows={2} label="Yorum Yap" variant="outlined" />
        </ListItem>
        <ListItem style={{ height: '25px' }}>
          <Button color="primary" size="small" variant="contained" type="submit">
            Yorum Kaydet
          </Button>
        </ListItem>
      </form>
    </FormProvider>
  );
}
