import React, { useState } from 'react';
import { useParams } from 'react-router';

import { CommitteeMeetingsDetailResult, getCommitteeMeetingId } from 'api/hs/committee/committee-meetings-detail';
import { useFetch } from 'utils';

import { CommitteeMeetingsDetailContext } from './committee-meetings-detail.context';
import CommitteeMeeting from './CommitteeMeeting';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<CommitteeMeetingsDetailResult>(null);
  const { committeeMeetingId } = useParams<{ committeeMeetingId: string }>();

  const { data, fetch } = useFetch(() => getCommitteeMeetingId(committeeMeetingId), [], { setLoading });

  return (
    <CommitteeMeetingsDetailContext.Provider
      value={{
        committeeMeetingsDetail: data,
        reload: fetch,
        loading,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <CommitteeMeeting />
    </CommitteeMeetingsDetailContext.Provider>
  );
}
