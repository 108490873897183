import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import React, { useLayoutEffect, useRef } from 'react';

import { convertByFieldIdSum, convertByFieldNameSum, PieChartData } from './chart-data-converter-pie';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

/**
 * if preparedData is not empty data, selectorFieldByName, selectorFieldById, selectorFieldByIdNameMap must be empty
 * either selectorFieldByName or (selectorFieldById and selectorFieldByIdNameMap) must be full
 */
export interface ChartProps {
  chartName: string;
  preparedData?: PieChartData[];
  data?: any[];
  selectorFieldByName?: string;
  selectorFieldById?: string;
  selectorFieldByIdNameMap?: Map<string, string>;
  visible?: (arg: boolean) => void;
}

const PieChart = (props: ChartProps) => {
  const chart = useRef(null);
  useLayoutEffect(() => {
    let cData: PieChartData[];
    if (props.preparedData) {
      cData = props.preparedData;
    } else {
      if (props.selectorFieldByName !== undefined) {
        cData = convertByFieldNameSum(props.data, props.selectorFieldByName);
      } else if (props.selectorFieldById !== undefined) {
        cData = convertByFieldIdSum(props.data, props.selectorFieldById, props.selectorFieldByIdNameMap);
      }
    }

    const chartRoot = am4core.create(props.chartName, am4charts.PieChart);
    chartRoot.data = cData;
    chartRoot.radius = 100;

    const pieSeries = chartRoot.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.category = 'label';
    pieSeries.dataFields.value = 'value';
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.resizable = true;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    chartRoot.hiddenState.properties.radius = am4core.percent(0);

    chartRoot.legend = new am4charts.Legend();
    chart.current = chartRoot;
    props.visible(true);
    return () => {
      chartRoot.dispose();
    };
  }, [props.data, props.chartName, props]);

  return <div id={props.chartName} style={{ width: '100%', height: '600px' }} />;
};

export default PieChart;
