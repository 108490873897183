import { tryCatch } from '@thalesrc/js-utils';

import { LoginResult } from '../authentication/login.result';
import HSApi from '../HSApi';
import { UpdateUserProfilePayload } from './update-user-profile.payload';
import { UserProfileResult } from './user-profile.result';

export async function getUser(): Promise<LoginResult> {
  const [error, res] = await tryCatch(HSApi.get<LoginResult>(`/users/current`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getUserProfile(): Promise<UserProfileResult> {
  let res;
  try {
    res = await HSApi.get<UserProfileResult>(`/users/current/profiles`);
  } catch (err) {
    console.error(err); // tslint:disable-line:no-console

    throw err;
  }

  return res.data;
}

export async function updateUserProfile(payload: UpdateUserProfilePayload): Promise<void> {
  const [error] = await tryCatch(HSApi.put<unknown>(`/users/current/profiles`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
