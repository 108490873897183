import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetDisabilitiesResult } from './get-disabilities.result';

export async function getDisabilities(): Promise<GetDisabilitiesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetDisabilitiesResult[]>(`/firms/disability-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
