import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteReferral, getReferral, ReferralResult } from 'api/hs/definitions/health-definitions/referral';
import ReferralEditDialog from 'Common/Forms/Definitions/HealthDefinition/Referral/ReferralEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function RestReport() {
  return (
    <BaseDefinitionPage<ReferralResult>
      listLabelPropName="name"
      fetchData={getReferral}
      FormDialog={ReferralEditDialog}
      onDelete={deleteReferral}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
