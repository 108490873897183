import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from '../../../Common/Button';
import DateInput from '../../../Common/Form/DateInput';
import colors from '../colors';
import Header from '../Dialog/Header';

export default ({ opened, close, setRange }) => {
  const { reset, setValue, trigger, ...formMethods } = useForm();

  const handleSubmit = async (value: any) => {
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);

    setRange(startDate, endDate);
    close();
  };

  return (
    <Dialog open={opened} onClose={close} maxWidth="md">
      <Header title="Tarih Aralığı" color={colors.passive} showExport={false} />
      <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
        <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <DialogContent style={{ height: '150px' }}>
            <Grid container>
              <Grid item xs={12} sm={6} className="pr-sm-1">
                <DateInput type="date" name="startDate" label="Start Date" />
              </Grid>
              <Grid item xs={12} sm={6} className="pl-sm-1">
                <DateInput type="date" name="endDate" label="End Date" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="pb-2 pr-2">
            <Button onClick={close}>İptal</Button>
            <Button type="submit" color="primary" variant="contained">
              Seç
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
