import { Button } from '@material-ui/core';
import React, { useContext } from 'react';

import LoadingWrapper from '../../../Common/LoadingWrapper';
import { AccidentReviewContext } from '../accident-review-context';

interface Props {
  loading: boolean;
  data: any;
}

export default ({ data, loading }: Props) => {
  const { insertable, updatable } = useContext(AccidentReviewContext);

  if (!insertable) return <></>;
  if (data && !updatable) return <></>;

  return (
    <LoadingWrapper loading={loading}>
      <Button variant="contained" type="submit" color="secondary">
        {data?.id ? 'Güncelle' : 'Kaydet'}
      </Button>
    </LoadingWrapper>
  );
};
