import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { InjuryTypeDefinitionControllerService, InjuryTypeDefinitionDto } from '../../../../../api/client';
import { updateInjuryTypes } from '../../../../../api/hs/accident/accident';

interface Props {
  open: boolean;
  data: InjuryTypeDefinitionDto;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the allergy is created/updated
   */
  onClose(success: boolean): void;
}

export default function InjuryTypeEditDialog({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<InjuryTypeDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={InjuryTypeDefinitionControllerService.saveFirmInjuryTypeDefinition}
      onUpdate={updateInjuryTypes}
      data={data}
      resourceReloadName="injuryTypes"
      createTitle="Yaralanma Türü Ekleme"
      updateTitle="Yaralanma Türü Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Yaralanma Türü Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
