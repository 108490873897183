import { createContext } from 'react';

export interface CheckListItem {
  id: number;
  value: number;
  valueText: string;
  label: string;
  color: string;
}

export type UpsertData = { description: string } & { [key: number]: number };

export interface CheckListContextType {
  loading: boolean;
  list: CheckListItem[];
  description: string;
  editOpened: boolean;
  setEditOpened(value: boolean): void;
  editButtonText: string;
  editDialogTitle: string;
  upsert(data: UpsertData): Promise<void>;
}

export const CheckListContext = createContext<CheckListContextType>(null);
