import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetWorkStationsResult } from './get-work-stations.result';

export async function getWorkStations(): Promise<GetWorkStationsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetWorkStationsResult[]>(`/firms/station-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
