/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MatrixHazardDamageDefinitionDto } from '../models/MatrixHazardDamageDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class MatrixHazardDamageDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static updateMatrixDamageDefinitions(
        id: string,
        requestBody: MatrixHazardDamageDefinitionDto,
    ): CancelablePromise<MatrixHazardDamageDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/matrix-damage-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllMatrixDamageDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/matrix-damage-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmMatrixDamageDefinitions(
        id: string,
        requestBody: MatrixHazardDamageDefinitionDto,
    ): CancelablePromise<MatrixHazardDamageDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/matrix-damage-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmMatrixDamageDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/matrix-damage-definitions/${id}`,
        });
    }

    /**
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static getAllMatrixDamageDefinitions(): CancelablePromise<Array<MatrixHazardDamageDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/matrix-damage-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static saveMatrixDamageDefinitions(
        requestBody: MatrixHazardDamageDefinitionDto,
    ): CancelablePromise<MatrixHazardDamageDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/matrix-damage-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmMatrixDamageCategoryDefinitions(): CancelablePromise<Array<MatrixHazardDamageDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/matrix-damage-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns MatrixHazardDamageDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmMatrixDamageDefinitions(
        requestBody: MatrixHazardDamageDefinitionDto,
    ): CancelablePromise<MatrixHazardDamageDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/matrix-damage-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}