import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { exportActions, exportPregnancies, getPregnancies } from '../../../../api/hs/dashboard/widget/widget';
import { ActionWidgetType, DateRange, PregnancyWidgetType } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import PregnancyTable from './PregnancyTable';

const types = [PregnancyWidgetType.PREGNANT, PregnancyWidgetType.BREASTFEEDING, PregnancyWidgetType.MATERNITY_LEAVE];
const fileNames = {
  pregnancies: 'gebe.xlsx',
  breastFeedings: 'emziren-personel.xlsx',
  maternityLeaves: 'dogum-izni.xlsx',
};

export default () => {
  const [tab, setTab] = useState<string>('pregnancies');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: pregnancies } = useFetch(() => getPregnancies(PregnancyWidgetType.PREGNANT), [], { setLoading });
  const { data: breastFeedings } = useFetch(() => getPregnancies(PregnancyWidgetType.BREASTFEEDING), [], { setLoading });
  const { data: maternityLeaves } = useFetch(() => getPregnancies(PregnancyWidgetType.MATERNITY_LEAVE), [], { setLoading });
  const tabs = { pregnancies, maternityLeaves, breastFeedings };

  const {
    pregnancy: { close, opened },
  } = useContext(DialogContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'pregnancies') {
      exportPregnancies(PregnancyWidgetType.PREGNANT, fileNames[tab]).then(noop);
    }
    if (tab === 'breastFeedings') {
      exportPregnancies(PregnancyWidgetType.BREASTFEEDING, fileNames[tab]).then(noop);
    }
    if (tab === 'maternityLeaves') {
      exportPregnancies(PregnancyWidgetType.MATERNITY_LEAVE, fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab.toString()}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Gebelik" color={colors.pregnancy} onClick={tabs[tab]?.length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Gebe Personel (${pregnancies.length})`} value={'pregnancies'} />
            <Tab wrapped label={`Emziren Personel (${breastFeedings.length})`} value={'breastFeedings'} />
            <Tab wrapped label={`Doğum İzinli Personel (${maternityLeaves.length})`} value={'maternityLeaves'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'pregnancies'}>
            <IndicatorWrapper data={pregnancies} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <PregnancyTable users={pregnancies} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'breastFeedings'}>
            <IndicatorWrapper data={breastFeedings} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <PregnancyTable users={breastFeedings} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'maternityLeaves'}>
            <IndicatorWrapper data={maternityLeaves} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <PregnancyTable users={maternityLeaves} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
