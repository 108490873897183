/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrainingDefinitionEmployeeDto } from '../models/TrainingDefinitionEmployeeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TrainingDefinitionEmployeeControllerService {

    /**
     * @param trainingEmployeeId
     * @param requestBody
     * @returns TrainingDefinitionEmployeeDto OK
     * @throws ApiError
     */
    public static updateAllTrainingDefinitionEmployees(
        trainingEmployeeId: string,
        requestBody: Array<TrainingDefinitionEmployeeDto>,
    ): CancelablePromise<Array<TrainingDefinitionEmployeeDto>> {
        return __request({
            method: 'PUT',
            path: `/training-employees/${trainingEmployeeId}/training-definition-employees/list`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param trainingId
     * @param exposeType
     * @param formData
     * @returns any OK
     * @throws ApiError
     */
    public static importTrainingEmployeesFromFile(
        trainingId: string,
        exposeType: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
        formData?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/training/${trainingId}/training-definition-employees/import`,
            query: {
                'exposeType': exposeType,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody
     * @returns TrainingDefinitionEmployeeDto OK
     * @throws ApiError
     */
    public static addTrainingDefinitionEmployees(
        requestBody: Array<TrainingDefinitionEmployeeDto>,
    ): CancelablePromise<Array<TrainingDefinitionEmployeeDto>> {
        return __request({
            method: 'POST',
            path: `/training-employees/training-definition-employees/list`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static getTrainingDefinitionEmployeeFileTemplate(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/training/training-definition-employees/import/template`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param trainingEmployeeId
     * @returns TrainingDefinitionEmployeeDto OK
     * @throws ApiError
     */
    public static getTrainingDefinitionEmployees(
        trainingEmployeeId: string,
    ): CancelablePromise<Array<TrainingDefinitionEmployeeDto>> {
        return __request({
            method: 'GET',
            path: `/training-employees/${trainingEmployeeId}/training-definition-employees`,
        });
    }

    /**
     * @param trainingId
     * @param employeeId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllTrainingDefinitionEmployee(
        trainingId: string,
        employeeId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/trainings/${trainingId}/employees/${employeeId}/training-definition-employees`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTrainingDefinitionEmployee(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/training-definition-employees/${id}`,
        });
    }

}