import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { EmployeeTrainingListPlanningResult } from 'api/hs/definitions/trainings/employee-training/employee-training-list-planning';
import { deleteEmployeeTrainingListPlanning } from 'api/hs/definitions/trainings/employee-training/employee-training-list-planning';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { AlertContext } from '../../../../../../../context/alert.context';
import { DialogContext } from '../../../../../../../context/dialog.context';
import { ResourceContext } from '../../../../../../../context/resource.context';
import { stopPropagation, useDateFormat } from '../../../../../../../utils';
import { TrainingPlanningListContext } from '../../training-planning-list.context';

function ActionComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const { reload } = useContext(TrainingPlanningListContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const { employeeId } = useParams<{ employeeId: string }>();
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const handleDelete = useCallback(async () => {
    closeMenu();
    const submitted = await openWarnDialog({
      text: `Bu Eğitiminden çıkarmak istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteEmployeeTrainingListPlanning(row?.id, employeeId);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [reload, openWarnDialog, showMessage, employeeId, row, closeMenu]);

  return (
    <div key={row.id}>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Bu Eğitimden Çıkar</Typography>
          </MenuItem>
        </Menu>
      </span>
    </div>
  );
}

function TrainerComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const { trainers } = useContext(ResourceContext);

  return <>{row?.trainingTrainers?.map(trainer => findByKey(trainers, 'id', trainer?.trainerId)?.name).join(' , ')}</>;
}

function PlannedDateComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const format = useDateFormat('dd MMMM yyyy HH:mm');

  return <>{format(new Date(`${row.plannedDate}`))}</>;
}

export const CELL: TableColumn<EmployeeTrainingListPlanningResult>[] = [
  {
    id: 'title',
    defaultVisibility: true,
    label: 'Eğitim Adı',
    width: '15%',
  },
  {
    id: Symbol(),
    defaultVisibility: true,
    label: 'Eğitimciler',
    width: '15%',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return <TrainerComponent row={row} />;
    },
  },
  {
    id: 'validityPeriodInMonth',
    defaultVisibility: true,
    label: 'Geçerlilik Süresi (Ay)',
    width: '15%',
  },
  {
    id: 'plannedDate',
    defaultVisibility: true,
    width: '10%',
    label: 'Planlanan Tarih',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return (
        <>
          <PlannedDateComponent row={row} />
        </>
      );
    },
  },
  {
    id: 'isFaceToFace',
    defaultVisibility: true,
    width: '10%',
    label: 'Yüz yüze',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.isFaceToFace ? 'Yüzyüze' : 'Uzaktan';
    },
  },
  {
    id: 'atWorkplace',
    defaultVisibility: true,
    width: '10%',
    label: 'İş yerinde',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.atWorkplace ? 'Evet' : 'Hayır';
    },
  },
  {
    id: 'isInAnnualPlan',
    defaultVisibility: true,
    width: '10%',
    label: 'Yıllık Plan Dahil',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.isInAnnualPlan ? 'Evet' : 'Hayır';
    },
  },
  {
    width: '10%',
    id: 'description',
    defaultVisibility: true,
    label: 'Açıklama',
  },
  {
    id: Symbol(),
    width: '5%',
    label: 'İşlemler',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return <ActionComponent row={row} />;
    },
  },
];
