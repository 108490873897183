import { Dialog, DialogContent } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { exportReferraledEmployee, getReferraledEmployee } from '../../../../api/hs/dashboard/widget/widget';
import { DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import ReferralTable from './ReferralTable';

export default () => {
  const {
    referraledEmployee: { close, opened },
    referraledEmployeeDateRange: { dateRange },
  } = useContext(DialogContext);

  const [loading, setLoading] = useState<boolean>(false);
  const { data: users } = useFetch(() => getReferraledEmployee(new DateRange(dateRange?.start, dateRange?.end)), [], { setLoading });

  const handleExcelExport = () => {
    exportReferraledEmployee(new DateRange(dateRange.start, dateRange.end), 'sevkli.xlsx').then(noop);
  };

  return (
    <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
      <Header title="Sevkli Personel" color={colors.employee} onClick={users.length > 0 && handleExcelExport} />
      <DialogContent style={{ height: '300px' }}>
        <IndicatorWrapper data={users} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
          <ReferralTable users={users} />
        </IndicatorWrapper>
      </DialogContent>
    </Dialog>
  );
};
