import React, { useContext } from 'react';

import { EnumContext } from 'context/enum.context';
import { ResourceContext } from 'context/resource.context';

import { dateDisplayFormat } from '../../../utils';
import Avatar from '../Avatar';

export interface Cell {
  id: string;
  attr?: string;
  entityPath?: string;
  visible: boolean;
  numeric: boolean;
  label: string;
  sortable: boolean;
  width?: string;
  hideable: boolean;
  Render?({ value }: { value: any }): JSX.Element;
}

export const CELLS: Cell[] = [
  {
    id: 'avatarPath',
    attr: 'avatarPath',
    entityPath: 'avatarPath',
    visible: true,
    hideable: false,
    numeric: false,
    label: '',
    sortable: false,
    width: '2%',
    Render: ({ value }) => <Avatar url={value} />,
  },
  {
    id: 'nid',
    attr: 'nid',
    entityPath: 'nid',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'TCKN',
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    attr: 'name',
    entityPath: 'name',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Adı Soyadı',
    sortable: true,
    width: '8%',
  },
  {
    id: 'gender',
    attr: 'gender',
    entityPath: 'gender',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Cinsiyet',
    sortable: true,
    width: '2%',
  },
  {
    id: 'bloodType',
    attr: 'bloodType',
    entityPath: 'bloodType',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Kan Grubu',
    sortable: true,
    width: '6%',
    Render({ value }) {
      const { bloodTypes } = useContext(EnumContext);

      return <>{bloodTypes.find(({ id }) => id === value)?.label}</>;
    },
  },
  {
    id: 'birthPlace',
    attr: 'birthPlace',
    entityPath: 'birthPlace',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'Doğum Yeri',
    sortable: true,
    width: '7%',
  },
  {
    id: 'birthDate',
    attr: 'birthDate',
    entityPath: 'birthDate',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Doğum Tarihi',
    sortable: true,
    width: '5%',
    Render({ value }: { value: any }): JSX.Element {
      return <>{dateDisplayFormat(value)}</>;
    },
  },
  {
    id: 'age',
    attr: 'birthDate',
    entityPath: 'birthDate',
    visible: true,
    hideable: true,
    numeric: true,
    label: 'Yaş',
    sortable: true,
    width: '1%',
    Render({ value }) {
      const ageDifMs = Date.now() - Date.parse(value);
      const ageDate = new Date(ageDifMs);

      return <>{Math.abs(ageDate.getUTCFullYear() - 1970)}</>;
    },
  },
  {
    id: 'occupationalDiseaseDescription',
    attr: 'occupationalDiseaseDescription',
    entityPath: 'employeeInformation.occupationalDiseaseDescription',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'Meslek Hastalığı',
    sortable: true,
  },
  {
    id: 'companyId',
    attr: 'companyId',
    entityPath: 'employeeInformation.companyId',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Şirket Adı',
    sortable: true,
    width: '7%',
    Render({ value }) {
      const { companies } = useContext(ResourceContext);

      return <>{companies.find(({ id }) => id === value)?.companyName}</>;
    },
  },
  {
    id: 'gsmNumber',
    attr: 'gsmNumber',
    entityPath: 'gsmNumber',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Cep Telefonu',
    sortable: true,
    width: '6%',
  },
  {
    id: 'departmentId',
    attr: 'departmentId',
    entityPath: 'employeeInformation.departmentId',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Departman',
    sortable: true,
    width: '6%',
    Render({ value }) {
      const { departments } = useContext(ResourceContext);

      return <>{departments.find(({ id }) => id === value)?.departmentName}</>;
    },
  },
  {
    id: 'stationId',
    attr: 'stationId',
    entityPath: 'employeeInformation.stationId',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'İstasyon',
    sortable: true,
    width: '3%',
    Render({ value }) {
      const { workStations } = useContext(ResourceContext);

      return <>{workStations.find(({ id }) => id === value)?.stationName}</>;
    },
  },
  {
    id: 'positionId',
    attr: 'positionId',
    entityPath: 'employeeInformation.positionId',
    visible: true,
    hideable: true,
    numeric: false,
    label: 'Pozisyon',
    sortable: true,
    width: '6%',
    Render({ value }) {
      const { positions } = useContext(ResourceContext);

      return <>{positions.find(({ id }) => id === value)?.positionName}</>;
    },
  },
  {
    id: 'businessTelephone',
    attr: 'businessTelephone',
    entityPath: 'employeeInformation.telephone',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'İş Telefonu',
    sortable: true,
    width: '5',
  },
  {
    id: 'intercom',
    attr: 'intercom',
    entityPath: 'employeeInformation.telephone',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'Dahili',
    sortable: true,
    width: '5%',
  },
  {
    id: 'address',
    attr: 'address',
    entityPath: 'address',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'Adres',
    sortable: true,
  },
  {
    id: 'email',
    attr: 'email',
    entityPath: 'employeeInformation.email',
    visible: false,
    hideable: true,
    numeric: false,
    label: 'E-Posta',
    sortable: true,
  },
];
