import { createContext } from 'react';

import { VitalSignModel, VitalSignResult } from 'api/hs/examination/vital-sign';

export interface VitalSignsContextType {
  vitalSigns: VitalSignResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: VitalSignModel;

  setSelectedData(data: VitalSignModel): void;
}

export const VitalSignsContext = createContext<VitalSignsContextType>(null);
