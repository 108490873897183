import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { SaveMedicineTreatmentPayload, UpdateMedicineTreatmentPayload } from './medicine-treatments.payload';
import { MedicineTreatmentsResult } from './medicine-treatments.result';

export async function getMedicineTreatment(employeeId: string, examinationId: string): Promise<MedicineTreatmentsResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<MedicineTreatmentsResult>(`/medicine-treatments?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<MedicineTreatmentsResult>(`/medicine-treatments?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function saveMedicineTreatment(body: SaveMedicineTreatmentPayload): Promise<MedicineTreatmentsResult> {
  const [err, res] = await tryCatch(HSApi.post('/medicine-treatments', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateMedicineTreatment(id: string, body: UpdateMedicineTreatmentPayload): Promise<MedicineTreatmentsResult> {
  const [err, res] = await tryCatch(HSApi.put(`/medicine-treatments/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteMedicineTreatment(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/medicine-treatments/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
