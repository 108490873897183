import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Input,
  LinearProgress,
  LinearProgressProps,
  Typography
} from '@material-ui/core';
import {defer, findByKey} from '@thalesrc/js-utils';
import React, {useCallback, useContext, useRef, useState} from 'react';

import Dialog from 'Common/Dialog';
import Form, {FormRef} from 'Common/Form/Form';

import {saveEducation, signIbysDataTraining} from '../../../api/hs/trainings/ibys-trainings';
import {EmployeeSelectionCacheContext} from '../../../Common/Form/EmployeeSelection/employee-selection.context';
import TextField from '../../../Common/Form/TextField';
import {AlertContext} from '../../../context/alert.context';
import {ResourceContext} from '../../../context/resource.context';
import {UserContext} from '../../../context/user';
import {useAsyncEffect} from '../../../utils';
import {IbysTrainingDefinitionEmployeeDto} from 'api/client/models/IbysTrainingDefinitionEmployeeDto';
import {
  IbysEmployeeTrainingDefinitionControllerService,
  IbysEmployeeTrainingDefinitionStatusControllerService,
  IbysTrainingDefinitionEmployeeStatusDto,
  IbysTrainingDto
} from "../../../api/client";
import {IbysSigningTraining} from "../../../api/hs/trainings/ibys-trainings/ibys-training-signing.model";
import UnsuccessfulTrainingInfo from "./UnsuccessfulTrainingInfo";

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: IbysTrainingDto;
}

class TrainingInfo {
  totalTrainingCount: number;
  sendTrainingCount: number;
  successfulTrainingCount: number;
  unSuccessfulTrainingCount: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: '100%', mr: 1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function calculateTrainingCount(data: IbysTrainingDefinitionEmployeeDto[]): TrainingInfo {
  let trainingInfo = new TrainingInfo();
  trainingInfo.totalTrainingCount = data.length;
  trainingInfo.sendTrainingCount = 0;
  trainingInfo.successfulTrainingCount = 0;
  trainingInfo.unSuccessfulTrainingCount = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].resultCode == 0 && data[i].resultMessage) {
      trainingInfo.unSuccessfulTrainingCount++
    } else if (data[i].resultCode == 1) {
      trainingInfo.successfulTrainingCount++;
    }
    if (data[i].resultCode != 1 && data[i].resultMessage) {
      trainingInfo.sendTrainingCount++;
    }
  }
  return trainingInfo
}

export default function PinForm({open, onClose, data}: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [sending, setSending] = useState<boolean>(false);

  const [trainingCount, setTrainingCount] = useState<number>(0);
  const [sendTrainingCount, setSendTrainingCount] = useState<number>(0);
  const [successfulTrainingCount, setSuccessfulTrainingCount] = useState<number>(0);
  const [unsuccessfulTrainingCount, setUnsuccessfulTrainingCount] = useState<number>(0);
  const {profile} = useContext(UserContext);
  const {trainingDefinitions, companies} = useContext(ResourceContext);
  const {employees, loadEmployees} = useContext(EmployeeSelectionCacheContext);
  const {showMessage} = useContext(AlertContext);

  useAsyncEffect(async () => {
    if (data && data.ibysTrainingDefinitionEmployees) {
      let trainingInfo = calculateTrainingCount(data.ibysTrainingDefinitionEmployees);
      setTrainingCount(trainingInfo.totalTrainingCount);
      setSuccessfulTrainingCount(trainingInfo.successfulTrainingCount);
      setUnsuccessfulTrainingCount(trainingInfo.unSuccessfulTrainingCount);
      setSendTrainingCount(trainingInfo.sendTrainingCount);
    }

    if (!data) {
      return;
    }
    await loadEmployees(...data?.ibysTrainingEmployees?.map(employee => employee.employeeId));

    await defer();
    await formRef?.current?.methods?.setValue('pinCode', profile?.esignPinCode ?? '');
    await formRef?.current?.methods?.trigger();
  }, [data, profile]);

  function trainingJsonDataForSigning(data: IbysTrainingDto, tde: IbysTrainingDefinitionEmployeeDto): IbysSigningTraining {
    let signingTraining: IbysSigningTraining = {
      sertifikaTipi: data?.trainerType,
      isgProfTcKNo: data?.ohsProfessionalNid,
      egitimTarihi: data?.date?.split('-').reverse().join('.'),
      egitimYeri: data?.atWorkplace ? 1 : 0,
      egitimYontemi: data?.isFaceToFace ? 1 : 0,
      egiticiTcKNo: data?.trainerNid,
      imzalayanKimlikNo: data?.ohsProfessionalNid,
      isyeriSgkSicilNo: findByKey(companies, 'id', data?.companyId)?.ssiId,
      egitimKodu: findByKey(trainingDefinitions, 'id', tde.trainingDefinitionId)?.code,
      egitimSuresi: tde.durationInMinutes,
      calisanTcKNo: findByKey(employees, 'id', tde.employeeId)?.nid
    };
    return signingTraining;
  }

  function showUnsuccessfulTrainingInfo(unsTrainingCount:number) {
    if(unsTrainingCount > 0) {
      return <UnsuccessfulTrainingInfo data={data} unsuccessfulTrainingCount={unsTrainingCount}/>;
    }
    return <></>;
  }

  const onSubmit = useCallback(
    async values => {
      setSending(true);
      try {
        let trainingInfo = calculateTrainingCount(data.ibysTrainingDefinitionEmployees);
        let sendTrCount = trainingInfo.sendTrainingCount;
        let successfulTrCount = trainingInfo.successfulTrainingCount;
        let failedTrCount = trainingInfo.unSuccessfulTrainingCount;
        let hasError = false;

        for (let trainingDefEmployee of data.ibysTrainingDefinitionEmployees) {
          if (sessionStorage.getItem("ibysTrainingSendPause") == "true") {
            sessionStorage.setItem("ibysTrainingSendPause", "false");
            break;
          }

          if (trainingDefEmployee.resultCode != null || trainingDefEmployee.resultMessage != null) {
            continue;
          }
          let resultSigningTraining: IbysSigningTraining = trainingJsonDataForSigning(data, trainingDefEmployee);
          const signedData = await signIbysDataTraining({...values, xmlContent: JSON.stringify(resultSigningTraining)});
          if (signedData.sonucKodu !== '200') {
            showMessage(`${signedData.sonucKodu} - ${signedData.sonucMesaji}`, 'error', 'info');
            hasError=true;
            break;
          }
          resultSigningTraining.action = 'egitimGonder';
          resultSigningTraining.zamanDamgasi = signedData.timestamp;
          const saveData = await saveEducation([resultSigningTraining]);
          let trDefEmployeeDto = {} as IbysTrainingDefinitionEmployeeDto;
          trDefEmployeeDto.ministryId = saveData[0].bildirimNo;
          trDefEmployeeDto.resultMessage = saveData[0].aciklama;
          trDefEmployeeDto.resultCode = Number(saveData[0].sonuc);
          if (trDefEmployeeDto.resultCode == 0 && trDefEmployeeDto.resultMessage) {
            failedTrCount++
            setUnsuccessfulTrainingCount(failedTrCount);
          } else if (trDefEmployeeDto.resultCode == 1) {
            successfulTrCount++;
            setSuccessfulTrainingCount(successfulTrCount);
          }
          if (trDefEmployeeDto.resultCode != 1 && trDefEmployeeDto.resultMessage) {
            sendTrCount++;
            setSendTrainingCount(sendTrCount);
          }
          await IbysEmployeeTrainingDefinitionControllerService.partialUpdateDefinitionEmployeeIbys(trDefEmployeeDto, trainingDefEmployee.id);

          let trDefEmployeeStatusDto = {} as IbysTrainingDefinitionEmployeeStatusDto;
          trDefEmployeeStatusDto.data = signedData.data;
          trDefEmployeeStatusDto.signatureTimestamp = signedData.timestamp;
          trDefEmployeeStatusDto.ministryId = saveData.bildirimNo;
          trDefEmployeeStatusDto.ibysTrainingEmployeeDefinitionId = trainingDefEmployee.id;
          trainingDefEmployee.resultMessage = trDefEmployeeDto.resultMessage;
          trainingDefEmployee.resultCode = trDefEmployeeDto.resultCode;
          await IbysEmployeeTrainingDefinitionStatusControllerService.saveIbysTrainingEmployeeDefinitionStatus(trDefEmployeeStatusDto);
        }
        if(!hasError) {
          showMessage('IBYS gönderim başarılı', 'success', 'info');
        }
      } catch (e) {
        console.log(e);
        showMessage('IBYS gönderim sırasında  hata oluştu', 'error', 'info');
      }
      setSending(false);
    },
    [data, trainingDefinitions, employees, showMessage, companies]
  );

  return (
    <Dialog width="sm" color="primary" title="Bakanlığa Gönder" opened={open} onClose={onClose}>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Grid container className="p-2">
          <Grid item xs={12} sm={12} className="pr-sm-1">
            <Hidden>
            <TextField type="hidden" name="action" defaultValue="ibys_istek"/>
            </Hidden>
            <TextField type="password" name="pinCode" label="E-imza Pin Kodu" required/>
            <Typography color="error" variant="caption" display="block" gutterBottom>
              Profil Ayarlar Sayfasındaki "E-imza PIN Kodu" kısımdaki bilgi
            </Typography>
          </Grid>
        </Grid>
        <footer className="text-right p-2">
          <Button className="mr-1" type="button" variant="contained" onClick={onClose} disabled={sending}>
            Vazgeç
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={sending}>
            {' '}
            Bakanlığa Gönder
          </Button>
        </footer>
        <Divider/>
        <Grid container className="p-2">
          <Grid item xs={6}>
            <Grid container className="p-2">
              <Grid item xs={12} sm={12} className="pr-sm-1">
                <Typography align="center" display="block">
                  Toplam Eğitim Sayısı
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className="pr-sm-1">
                <Typography align="center" display="block">
                  {trainingCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className="p-2">
              <Grid item xs={12} sm={12} className="pr-sm-1">
                <Typography align="center" display="block">
                  Gönderilen Eğitim Sayısı
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className="pr-sm-1">
                <Typography align="center" display="block">
                  {sendTrainingCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="p-2" alignItems="center">
          <Grid item xs={6} className="pr-sm-1">
            <Typography align="center" display="block">
              Başarılı
            </Typography>
          </Grid>
          <Grid item xs={6} className="pr-sm-1">
            <Typography color="error" align="center" display="block">
              Başarısız
            </Typography>
          </Grid>
          <Grid item xs={6} className="pr-sm-1">
            <Typography align="center" display="block">
              {successfulTrainingCount}
            </Typography>
          </Grid>
          <Grid item xs={6} className="pr-sm-1">
            <Typography color="error" align="center" display="block">
              {unsuccessfulTrainingCount}
              {showUnsuccessfulTrainingInfo(unsuccessfulTrainingCount)}

            </Typography>
          </Grid>
        </Grid>
        <Grid container className="p-2">
          <Grid item xs={12} sm={12} className="pr-sm-1">
            <LinearProgressWithLabel variant="determinate"
                                     value={Math.floor((sendTrainingCount / (trainingCount == 0 ? 1 : trainingCount)) * 100)}/>
          </Grid>
        </Grid>
        <footer className="text-right p-2">
          <Button type="button" variant="contained" color="primary" disabled={!sending}
                  onClick={() => sessionStorage.setItem("ibysTrainingSendPause", "true")}>
            Durdur
          </Button>
        </footer>
      </Form>
    </Dialog>
  );
}
