import React, { useCallback, useContext } from 'react';

import { WorkEquipmentJobStatusContext } from '../../work-equipment-job-status.context';
import DetailJobStatusDialog from '../DetailJobStatusDialog';

export default function JobDetailDialog() {
  const { setJobStatusDetailDialogOpened, jobStatusDetailDialogOpened, selectedData } = useContext(WorkEquipmentJobStatusContext);

  const closeDialog = useCallback(async () => {
    setJobStatusDetailDialogOpened(false);
  }, [setJobStatusDetailDialogOpened]);

  return <DetailJobStatusDialog open={jobStatusDetailDialogOpened} onClose={closeDialog} data={selectedData} />;
}
