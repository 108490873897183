import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { AnalysisPayload } from './Analysis.payload';
import { AnalysisResult } from './Analysis.result';
import { AnalysisParametersPayload } from './AnalysisParameters.payload';
import { AnalysisParametersResult } from './AnalysisParametersResult';

export async function getAnalysis(): Promise<AnalysisResult[]> {
  const [error, res] = await tryCatch(HSApi.get<AnalysisResult[]>(`/firms/analysis-category-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAnalysis(body: AnalysisPayload): Promise<AnalysisResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/analysis-category-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAnalysis({ id, ...body }: MakeRequired<Partial<AnalysisResult>, 'id'>): Promise<AnalysisResult> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisResult>(`/firms/analysis-category-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAnalysis(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/analysis-category-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminAnalysis(): Promise<AnalysisResult[]> {
  const [error, res] = await tryCatch(HSApi.get<AnalysisResult[]>(`/analysis-category-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminAnalysis(body: AnalysisPayload): Promise<AnalysisResult> {
  const [err, res] = await tryCatch(HSApi.post('/analysis-category-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminAnalysis({ id, ...body }: MakeRequired<Partial<AnalysisResult>, 'id'>): Promise<AnalysisResult> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisResult>(`/analysis-category-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminAnalysis(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/analysis-category-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAnalysisParameters(id: string): Promise<AnalysisParametersResult[]> {
  const [error, res] = await tryCatch(
    HSApi.get<AnalysisParametersResult[]>(`/firms/analysis-parameter-definitions?categoryDefinitionId=${id}`)
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
  return res.data;
}
export async function updateAnalysisParameters(id: string, body: any): Promise<AnalysisParametersResult> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisParametersResult>(`/firms/analysis-parameter-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
export async function saveAnalysisParameters(body: AnalysisParametersPayload): Promise<AnalysisParametersResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/analysis-parameter-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
export async function deleteAnalysisParameters(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/analysis-parameter-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
