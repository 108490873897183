// tslint:disable:max-classes-per-file

import { findByKey } from '@thalesrc/js-utils';

import { PhysicalChecksResult } from 'api/hs/examination/physical-checks/physical-checks.result';
import { modelDataMatcher } from 'utils';

import { GetPhysicalCheckDefinitionsResult } from '../../resource';

function getColorByValue(value: number): string {
  switch (value) {
    case 0:
      return '#469789';
    case 1:
      return '#eb4355';
    default:
      return '#c0c0c0';
  }
}

function getTextByValue(value: number): string {
  switch (value) {
    case 0:
      return 'Yok';
    case 1:
      return 'Var';
    default:
      return 'Belirsiz';
  }
}

export class PhysicalChecksModel {
  public id: string = null;
  public valueText: string = null;
  public color: string = null;
  public description: string = null;
  public physicalCheckStatusDefinitionId: number = null;
  public physicalCheckDefinitionId: number = null;
  public physicalCheckDefinitionName: string = null;
  public physicalCheckDefinitionDescription: string = null;
  public physicalCheckDefinitionDefaultNegativeResponse: string = null;

  constructor(data: Partial<PhysicalChecksModel> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromEnumAndServerData(resource: GetPhysicalCheckDefinitionsResult[], data: PhysicalChecksResult[]): PhysicalChecksModel[] {
    return resource.map(item => {
      const serverData = findByKey(data, 'physicalCheckDefinitionId', item.id);
      return new PhysicalChecksModel({
        id: typeof serverData?.id === 'string' ? serverData?.id : null,
        physicalCheckDefinitionId: item.id,
        physicalCheckDefinitionName: item.label,
        physicalCheckDefinitionDescription: item.description,
        physicalCheckDefinitionDefaultNegativeResponse: item.defaultNegativeResponse,
        description: typeof serverData?.description === 'string' ? serverData?.description : null,
        physicalCheckStatusDefinitionId:
          typeof serverData?.physicalCheckStatusDefinitionId === 'number' ? serverData?.physicalCheckStatusDefinitionId : 2,
        valueText:
          typeof serverData?.physicalCheckStatusDefinitionId === 'number'
            ? getTextByValue(serverData?.physicalCheckStatusDefinitionId)
            : getTextByValue(2),
        color:
          typeof serverData?.physicalCheckStatusDefinitionId === 'number'
            ? getColorByValue(serverData?.physicalCheckStatusDefinitionId)
            : getColorByValue(2),
      });
    });
  }
}
