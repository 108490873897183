import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { remove } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import AlertInformation from 'Common/AlertInformation';
import Button from 'Common/Button';

import { Icd10CacheContext, Icd10Context, Icd10Item, Icd10SelectionContext, Icd10SelectionContextType } from './icd10.context';

const useStyles = makeStyles(theme => ({
  itemLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  yellowStar: {
    color: theme.palette.warning.light,
  },
}));

function Item({ id }: { id: Icd10Item['id'] }) {
  const { itemLabelContainer, yellowStar } = useStyles();
  const { selection, setSelection } = useContext(Icd10SelectionContext);
  const { favourites, toggleFavourite, labels } = useContext(Icd10CacheContext);

  const checked = useMemo<boolean>(() => selection.includes(id), [selection, id]);

  const handleSelect = useCallback(
    (e: React.ChangeEvent, value: boolean) => {
      if (value) {
        setSelection([...selection, id]);
      } else {
        setSelection(remove(selection, id));
      }
    },
    [setSelection, selection, id]
  );

  const handleFavouriteToggle = useCallback(() => toggleFavourite(id), [toggleFavourite, id]);

  return (
    <ListItem>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleSelect} />}
        label={
          <div className={itemLabelContainer}>
            <IconButton
              className="p-1 mr-1"
              onClick={handleFavouriteToggle}
              title={favourites.includes(id) ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}>
              {favourites.includes(id) ? <StarIcon className={yellowStar} /> : <StarBorderIcon />}
            </IconButton>
            {labels[id]}
          </div>
        }
      />
    </ListItem>
  );
}

export default function Favourites() {
  const { favouritesOpened, setFavouritesOpened, name } = useContext(Icd10Context);
  const { favourites } = useContext(Icd10CacheContext);
  const [selection, setSelection] = useState<Icd10Item['id'][]>([]);
  const { getValues, setValue } = useFormContext();

  const close = useCallback(() => setFavouritesOpened(false), [setFavouritesOpened]);
  const context = useMemo<Icd10SelectionContextType>(() => ({ selection, setSelection }), [selection]);

  const submitSelection = useCallback(() => {
    setValue(name, selection);
    close();
  }, [setValue, close, name, selection]);

  useEffect(() => {
    if (!favouritesOpened) {
      return;
    }

    setSelection(getValues(name));
  }, [favouritesOpened, name, getValues]);

  return (
    <Dialog open={favouritesOpened} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Favorilerden Tanı Ekle</DialogTitle>
      <DialogContent>
        {favourites.length ? (
          <Icd10SelectionContext.Provider value={context}>
            <List>
              {favourites.map(fav => (
                <Item key={fav} id={fav} />
              ))}
            </List>
          </Icd10SelectionContext.Provider>
        ) : (
          <AlertInformation severity="error" message="Hiç favori tanı bulunmuyor" />
        )}
      </DialogContent>
      <DialogActions className="text-right">
        <Button onClick={close}>Vazgeç</Button>
        {favourites.length ? (
          <Button color="primary" variant="contained" onClick={submitSelection}>
            Seç
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
