import React, { useState } from 'react';

import { useFetch } from 'utils';

import { BuildingDefinitionControllerService } from '../../../../api/client';
import { BuildingDefinitionDto } from '../../../../api/client';
import Building from './Building';
import { BuildingsDefinitionContext } from './buildings-definition.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<BuildingDefinitionDto>(null);

  const { data, fetch } = useFetch(() => BuildingDefinitionControllerService.getAllBuildingDefinitions(), [], { setLoading });

  return (
    <BuildingsDefinitionContext.Provider
      value={{
        buildingDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Building />
    </BuildingsDefinitionContext.Provider>
  );
}
