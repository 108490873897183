import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { PositionExposureControllerService, StationExposureControllerService } from '../../../../client';
import { PositionPayload } from './Position.payload';
import { PositionResult } from './Position.result';

export async function getPosition(): Promise<PositionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PositionResult[]>(`/firms/position-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  // return res.data;
  return await asyncMap(res.data, async item => ({
    ...item,
    exposureIds:
      (await PositionExposureControllerService.getAllPositionExposure(item?.id))?.reduce((acc, value) => [...acc, value.exposureId], []) ??
      null,
  }));
}

export async function savePosition(body: PositionPayload): Promise<PositionResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/position-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  await PositionExposureControllerService.savePositionsExposures(
    res.data?.id,
    body?.exposureIds?.map(exposureId => {
      return {
        exposureId,
        positionId: res?.data?.id,
      };
    })
  );

  return res.data;
}

export async function updatePosition({ id, ...body }: MakeRequired<Partial<PositionResult>, 'id'>): Promise<PositionResult> {
  const [error, res] = await tryCatch(HSApi.put<PositionResult>(`/firms/position-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  await PositionExposureControllerService.savePositionsExposures(
    id,
    body?.exposureIds?.map(exposureId => {
      return {
        exposureId,
        positionId: res?.data?.id,
      };
    })
  );

  return res.data;
}

export async function deletePosition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/position-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminPosition(): Promise<PositionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PositionResult[]>(`/position-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminPosition(body: PositionPayload): Promise<PositionResult> {
  const [err, res] = await tryCatch(HSApi.post('/position-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminPosition({ id, ...body }: MakeRequired<Partial<PositionResult>, 'id'>): Promise<PositionResult> {
  const [error, res] = await tryCatch(HSApi.put<PositionResult>(`/position-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminPosition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/position-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
