import { Divider, Grid, Paper, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { AccidentControllerService, AccidentReviewControllerService, AccidentReviewDto } from '../../../api/client';
import Button from '../../../Common/Button';
import Checkbox from '../../../Common/Form/Checkbox';
import Form, { FormRef } from '../../../Common/Form/Form';
import Radio from '../../../Common/Form/Radio';
import TextField from '../../../Common/Form/TextField';
import LoadingWrapper from '../../../Common/LoadingWrapper';
import { AlertContext } from '../../../context/alert.context';
import { dateTimePrettier, useAsyncEffect, useFetch } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';

const Communication = () => {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { token, updatable, accidentId } = useContext(AccidentReviewContext);
  const { showMessage } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState();

  const { data: communication, fetch } = useFetch(
    () => AccidentReviewControllerService.getCommunication(accidentId, null, token),
    undefined
  );
  const { data: csi } = useFetch(() => AccidentReviewControllerService.getCsi(accidentId, null, token), []);

  useEffect(() => {
    if (!formRef.current) return;

    if (communication) {
      // @ts-ignore
      Object.keys(communication).forEach(key => formRef.current.methods.setValue(key, communication[key]));
      communication.images.forEach(image => formRef.current.methods.setValue(`check_${image.referenceTableId}`, true));
      formRef.current.methods.trigger().then(noop);
    }
  }, [formRef, communication]);

  useAsyncEffect(async () => {
    if (!formRef.current) return;

    formRef.current.methods.setValue('title', '');
    formRef.current.methods.setValue('subtitle', '');
    formRef.current.methods.setValue('definition', '');
    formRef.current.methods.setValue('reason', '');
    formRef.current.methods.setValue('precaution', '');

    if (selection === 'existing') {
      const review: AccidentReviewDto = await AccidentReviewControllerService.getReview(accidentId, null, token);
      const accident = await AccidentControllerService.getAccidentViewById(accidentId, null, token);

      const definition = `${dateTimePrettier(new Date(accident?.createdDate))} tarihinde ${accident?.accidentDepartmentViews
        ?.map(item => item.departmentName)
        .join(', ')} yerinde oldu.`;

      formRef.current.methods.setValue('title', accident.accidentTypeViews?.map(type => type.typeDefinitionLabel).join(' '));
      formRef.current.methods.setValue('subtitle', accident?.accidentDepartmentViews?.map(type => type.departmentName).join(' '));
      formRef.current.methods.setValue('definition', definition);
      formRef.current.methods.setValue('reason', review.apparentReason);
      formRef.current.methods.setValue('precaution', review.rootCause);
    }
    formRef.current.methods.trigger().then(noop);
  }, [formRef, selection]).then(noop); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoading(true);

    const request = {
      definition: formRef.current.value.definition,
      precaution: formRef.current.value.precaution,
      reason: formRef.current.value.reason,
      title: formRef.current.value.title,
      subtitle: formRef.current.value.subtitle,
      images: Object.keys(formRef.current.value)
        .filter((key: string) => key.startsWith('check_'))
        .filter(key => formRef.current.value[key] === true)
        .map((key: string) => key.replace('check_', ''))
        .map((key: string) => {
          return { csiId: key, description: 'asdad' };
        }),
    };

    AccidentReviewControllerService.saveCommunication(accidentId, null, request, token)
      .then(() => {
        showMessage('Kayıt işlemi başarılı.', 'success', 'info');
        fetch().then(noop);
      })
      .catch(() => {
        showMessage('Bir hata oluştu.', 'error', 'info');
      })
      .finally(() => setLoading(false));
  };

  const onClick = () => {
    formRef.current?.methods?.trigger().then(() => setSelection(formRef.current.value.selection));
  };

  if (!updatable && !communication) return <></>;

  return (
    <Form onSubmit={submit} ref={formRef}>
      <Grid container direction={'column'} className={'m-2'} justifyContent={'space-between'}>
        {!communication && (
          <Grid container item direction={'row'}>
            <Grid item xs={12} sm={6} justifyContent={'space-between'}>
              <RadioGroup row>
                <Radio name="selection" label={'Var olanı kopyala'} value={'existing'} />
                <Radio name="selection" label={'Yeni oluştur'} value={'new'} />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button color="primary" variant="contained" onClick={onClick}>
                Devam
              </Button>
            </Grid>
          </Grid>
        )}
        {(communication || selection) && (
          <>
            <Grid container item className={'mt-2'} direction={'column'}>
              <Paper elevation={1} className={'p-3'}>
                <Grid item>
                  <TextField name="title" label="Üst Manşet" disabled={!updatable} />
                </Grid>
                <Grid item>
                  <TextField name="subtitle" label="Alt Manşet" disabled={!updatable} />
                </Grid>
              </Paper>
            </Grid>
            <Grid container item className={'mt-2'} direction={'column'}>
              <Paper elevation={1} className={'p-3'}>
                <Grid item>
                  <TextField name="definition" label="Tanım" rows={3} disabled={!updatable} />
                </Grid>
                <Grid item>
                  <TextField name="reason" label="Neden" rows={3} disabled={!updatable} />
                </Grid>
                <Grid item>
                  <TextField name="precaution" label="Önlem" disabled={!updatable} />
                </Grid>
              </Paper>
            </Grid>
            <Grid container item className={'mt-2'}>
              {csi?.map(data => (
                <Grid key={data.id} container spacing={2} alignItems="center" justifyContent={'space-around'}>
                  <Grid item xs={1}>
                    <Checkbox name={`check_${data.id}`} label={data.orderNum.toString()} disabled={!updatable} />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={3}>
                    <img
                      src={`/saglik-gozetimi/${data.importedFile?.path}/${data.importedFile?.name}`}
                      width={200}
                      alt={data.fileType?.label}
                    />
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={2}>
                    <Typography variant="h6" component="h6">
                      {data.fileType?.label}
                    </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />

                  <Grid item xs={4}>
                    <Tooltip title={data.note}>
                      <div>{data.note.substr(0, 50)}</div>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {updatable && (
              <Grid container item className={'mt-2'} justifyContent={'flex-end'}>
                <LoadingWrapper loading={loading}>
                  <Button color="primary" variant="contained" type="submit">
                    Kaydet
                  </Button>
                </LoadingWrapper>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Form>
  );
};

export default Communication;
