import React, { useState } from 'react';

import { WorkEquipmentJobStatusView } from '../../api/client';
import { WorkEquipmentJobStatusContext } from './work-equipment-job-status.context';
import WorkEquipmentJobStatusList from './WorkEquipmentJobStatusList';

export default function Index() {
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [fileDialogOpened, setFileDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<WorkEquipmentJobStatusView>(null);
  const [jobStatusDetailDialogOpened, setJobStatusDetailDialogOpened] = useState(false);
  return (
    <WorkEquipmentJobStatusContext.Provider
      value={{
        jobStatusDetailDialogOpened,
        setJobStatusDetailDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        fileDialogOpened,
        setFileDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <WorkEquipmentJobStatusList />
    </WorkEquipmentJobStatusContext.Provider>
  );
}
