import { Avatar as MUIAvatar } from '@material-ui/core';
import React from 'react';

interface Props {
  url: string;
  className?: string;
}

export default function Avatar({ url, className = '' }: Props) {
  return <MUIAvatar src={url ? '/saglik-gozetimi/public/images/Avatars/Orginal/' + url : null} className={className} />;
}
