/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddictionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    addictionStateDefinitionId?: number;
    addictiveSubstanceDefinitionId?: number;
    countPerDayForQuit?: number;
    countOfYearsForQuit?: number;
    countPerDayForUsing?: number;
    startYearForQuit?: number;
    startYearForUsing?: number;
    periodUnitDefinition?: AddictionDto.periodUnitDefinition;
    period?: number;
}

export namespace AddictionDto {

    export enum periodUnitDefinition {
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
        YEARLY = 'YEARLY',
    }


}
