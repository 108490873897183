import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { dateSort, MakeRequired } from 'utils';

import { updateAnalysisDetail } from '../detail';
import { AnalysisModel } from './analysis.model';
import { SaveAnalysisPayload } from './analysis.payload';
import { AnalysisResult, AnalysisResultAggregate } from './analysis.result';

export async function getAllAnalysisByEmployeeIdAndExaminationId(
  employeeId: string,
  examinationId?: string
): Promise<Partial<AnalysisModel[]>> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<AnalysisResult[]>(`/analysis?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<AnalysisResult[]>(`/analysis?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res?.data);
}

export async function saveAnalysis(payload: SaveAnalysisPayload[]): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.post(`/analysis/list`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function updateAnalysis({ id, ...body }: MakeRequired<Partial<AnalysisResult>, 'id'>): Promise<AnalysisResult> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisResult>(`/analysis/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function updateAnalysisWithItsDetail({
  id,
  detailList,
  ...body
}: MakeRequired<Partial<AnalysisResultAggregate>, 'id'>): Promise<AnalysisResult> {
  const [error, res] = await tryCatch(HSApi.put<AnalysisResult>(`/analysis/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  const details = await updateAnalysisDetail(id, detailList);
  const data = Object.assign({}, { ...res.data, details });

  return data;
}

export async function deleteAnalysis(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/analysis/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function saveAnalysisFile(id: AnalysisResult['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`/analysis/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function getAnalysisList(page = 0, size = 20, order: null | string, filters: any) {
  const payload = {
    filter: filters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/analysis/pages`, payload);

  return res.data;
}
