import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { EnumContext } from 'context/enum.context';

import { BodyMeasurementsContext } from '../../body-measurements.context';
import Action from './Action';
import EditDialog from './EditDialog';

const useStyles = makeStyles(theme => ({
  col: {
    color: 'inherit',
  },
  header: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

export default function ListBodyMeasurement() {
  const { loading, bodyMeasurements } = useContext(BodyMeasurementsContext);
  const { bodyMeasurementsCategoriDefiniton } = useContext(EnumContext);
  const { examinationId, nurseOperationId } = useParams<{ examinationId: string; nurseOperationId: string }>();
  const classes = useStyles();

  const testColor = (obesityClassificationDefinitionId: number) => {
    if (obesityClassificationDefinitionId === 1) {
      return '#c32525';
    } else if (obesityClassificationDefinitionId === 2) {
      return '#2db301';
    } else if (obesityClassificationDefinitionId === 3) {
      return '#c32525';
    } else if (obesityClassificationDefinitionId === 4) {
      return '#c32525';
    } else if (obesityClassificationDefinitionId === 5) {
      return '#c32525';
    } else {
      return '#f3983a';
    }
  };

  return (
    <IndicatorWrapper loading={loading} data={bodyMeasurements} errorMessage="Gösterilecek Vücut Ölçüm Bulunamadı.">
      <TableContainer style={{ marginBottom: '10px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                Ölçüm Tarihi
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                Boy(cm)
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                Kilo(kg)
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                VKİ
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                Bel
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                Kalça
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '10%' }}>
                Bel / Kalça
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '10%' }}>
                Ölçüm Yapan Kişi
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '10%' }}>
                Açıklama
              </TableCell>
              <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                İşlemler
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyMeasurements?.map((item, index) => (
              <TableRow
                style={{
                  color: `${testColor(item.obesityClassificationDefinitionId)}`,
                  backgroundColor: `${item.examinationId !== examinationId ? 'gainsboro' : null}`,
                }}
                hover
                key={item.id}>
                <TableCell className={classes.col} align="left">
                  {item?.measurementDate?.split('-')?.reverse()?.join('-') + ' ' + item?.measurementTime}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.lengthInCm}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.weightInKg}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.bmi +
                    ' (' +
                    findByKey(bodyMeasurementsCategoriDefiniton, 'id', item.obesityClassificationDefinitionId)?.label +
                    ')'}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.waistInCm}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.hipInCm}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.waistHipRatio}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item?.userData?.name}
                </TableCell>
                <TableCell className={classes.col} align="left">
                  {item.description}
                </TableCell>
                <TableCell align="left">
                  {item?.examinationId === examinationId ? (
                    <Action data={item} />
                  ) : item?.nurseOperationId === nurseOperationId ? (
                    <Action data={item} />
                  ) : !examinationId && !nurseOperationId ? (
                    <Action data={item} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditDialog />
    </IndicatorWrapper>
  );
}
