import React from 'react';

import TableData from './TableData';
import Toolbar from './Toolbar';
export default function Index() {
  return (
    <>
      <Toolbar />
      <TableData />
    </>
  );
}
