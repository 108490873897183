import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { PpeDetailStocksResult } from './ppe-detail-stocks.result';
import { PpeStorehousesResult } from './ppe-storehouses.result';
export async function getPpeDetailStocks(id: string): Promise<PpeDetailStocksResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PpeDetailStocksResult[]>(`/ppe-definition/${id}/ppe-stocks`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getPpeStorehouses(ppDefinitionId: string): Promise<PpeStorehousesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PpeStorehousesResult[]>(`/ppe-definition/${ppDefinitionId}/ppe-storehouses`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
