import React, { useCallback, useContext } from 'react';

import CompanyUserEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/CompanyUser/CompanyUserEditDialog';

import { CompanyUserContext } from '../../company-user.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(CompanyUserContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <CompanyUserEditDialog open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
