import React, { PropsWithChildren } from 'react';

import Foot from '../Raw/Foot';
import Table from '../Raw/Table';
import DefaultPagination from './DefaultPagination';

export default function TableWithPagination({ children }: PropsWithChildren<{}>) {
  return (
    <Table>
      {children}
      <Foot>
        <DefaultPagination />
      </Foot>
    </Table>
  );
}
