import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { WorkEquipmentDefinitionControllerService } from 'api/client';
import { WorkEquipmentDefinitionViewDto } from 'api/client';
import { getWorkEquipmentList, searchWorkEquipments, WorkEquipmentsModel } from 'api/hs/work-equipments';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import { uploadWorkEquipmentDefinition } from '../../../../api/hs/work-equipment/work-equipment';
import AutoComplete from '../../../../Common/Form/AutoComplete';
import AvatarInput from '../../../../Common/Form/AvatarInput';
import { ResourceContext } from '../../../../context/resource.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentDefinitionViewDto;
}

export default function WorkEquipmentDefinitionForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { workEquipmentTypeDefinitions, companies } = useContext(SelectOptionsContext);
  const { reload } = useContext(ResourceContext);
  const { showMessage } = useContext(AlertContext);

  async function workEquipmentsOptionFetcher(value: string) {
    const res = await searchWorkEquipments(value);
    return mapProfessionDefinition(res.content);
  }

  async function workEquipmentsLabelFetcher(value: string[]) {
    const res = await getWorkEquipmentList(value);
    return mapProfessionDefinition(res);
  }
  function mapProfessionDefinition(types: WorkEquipmentsModel[]): SelectOption<string>[] {
    return types.map<SelectOption<string>>(({ ad, kod }) => ({ text: ad, value: kod }));
  }

  const submit = useCallback(
    async values => {
      let workDefinitionId = data?.definitionId;
      try {
        if (!data?.definitionId) {
          const res = await WorkEquipmentDefinitionControllerService.saveWorkEquipmentDefinition({ ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          workDefinitionId = res.id;
        } else {
          await WorkEquipmentDefinitionControllerService.updateWorkEquipmentDefinition(data.definitionId, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        if (values.avatar) {
          await uploadWorkEquipmentDefinition(workDefinitionId, values?.avatar);
        }
        onClose(true);
        reload('workEquipmentDefinition');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data, reload]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.setValue('name', data?.definitionName);
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog
        color="secondary"
        opened={open}
        onClose={() => onClose(false)}
        title={data ? 'İş Ekipman Tanımı Düzenle' : 'İş Ekipman Tanımı Ekle'}>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <AvatarInput name="avatar" previewUrl={`/saglik-gozetimi/public/images/work_equipment/${data?.imagePath}`} />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <AutoComplete required single name="typeId" options={workEquipmentTypeDefinitions} label="İş Ekipman Kategorisi " />
              <TextField required name="name" label="Tanımı" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <AutoComplete single name="companyId" options={companies} label="Şirket " />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="brand" label="Marka" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="model" label="Model" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <AutoComplete
                name="code"
                label="IBYS Kodu"
                optionFetcher={workEquipmentsOptionFetcher}
                labelFetcher={workEquipmentsLabelFetcher}
                single
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <TextField name="barcode" label="Barkod Numarası" />
            </Grid>
            {data ? (
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <img src={`data:image/png;base64, ${data?.barcodeInBase64}`} alt={data?.definitionName} />
              </Grid>
            ) : null}
            <Grid item md={12} xs={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.definitionId ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={() => onClose(false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
