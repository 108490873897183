import { Tooltip } from '@material-ui/core';
import React from 'react';

import { ActionsContentResult } from 'api/hs/actions';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import {
  ActionReferenceName,
  EmployeeAvatar,
  PriorityShowAlert,
  StatusShowAlert,
} from '../../../../Actions/ActionListAdd/Action/List/columns';
import { dateDisplayFormat } from '../../../../utils';

export const COLUMNS: TableColumn<ActionsContentResult>[] = [
  {
    id: 'employees',
    width: '15%',
    label: 'Sorumlular',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return row.employees?.length > 0 ? <EmployeeAvatar row={row} /> : '-';
    },
  },
  {
    id: 'description',
    width: '55%',
    label: 'Aksiyon',
    defaultVisibility: true,
    render: row => (
      <Tooltip title={row.description}>
        <div>{row.description.substr(0, 50)}</div>
      </Tooltip>
    ),
  },
  {
    id: 'actionReference',
    width: '10%',
    label: 'Aksiyon Kaynağı',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return <ActionReferenceName row={row} />;
    },
  },
  {
    id: 'actionPriorityDefinition',
    width: '5%',
    label: 'Öncelik',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return <PriorityShowAlert row={row} />;
    },
  },
  {
    id: 'plannedEndDate',
    width: '5%',
    label: 'Termin Tarihi',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.plannedEndDate)}</>;
    },
  },
  {
    id: 'actionStatusDefinition',
    width: '10%',
    label: 'Durumu',
    defaultVisibility: true,
    render(row: ActionsContentResult): JSX.Element {
      return <StatusShowAlert row={row} />;
    },
  },
];
