import { tryCatch } from '@thalesrc/js-utils';

import { Employee } from 'model';
import { MakeRequired } from 'utils';

import {PagingAndSortingRequestParam, WorkEquipmentLayoutFilter} from '../../client';
import { ActionCounts, DateRange } from '../dashboard/widget/widget.payload';
import { WarningLabelsResult } from '../examination/warning-labels';
import HSApi from '../HSApi';
import { handleResult } from '../utils';
import { ExtendedSearchEmployeePayload, EXTENDED_SEARCH_EMPLOYEE_PAYLOAD_KEYS } from './employee-search.payload';
import { EmployeeExposureResult } from './employee.result';
import { EmployeeSearchResult } from './employee.search.result';

export async function getEmployeeExposures(employeeId: string): Promise<EmployeeExposureResult> {
  const [error, res] = await tryCatch(HSApi.get<EmployeeExposureResult>(`/exposures?employeeId=${employeeId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function saveEmployeeExposure(employeeId: string, exposureDefinitionId: number): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.post(`/exposures`, { employeeId, exposureDefinitionId }));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function deleteEmployeeExposure(exposureId: string): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.delete(`/exposures/${exposureId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function upsertEmployeeExposures(employeeId: string, exposures: number[]): Promise<void> {
  const currentExposures = await getEmployeeExposures(employeeId);

  const exposuresToSave = exposures.filter(id => !currentExposures.some(exp => exp.exposureDefinitionId === id));
  const exposuresToDelete = currentExposures.filter(exp => !exposures.includes(exp.exposureDefinitionId));

  await Promise.all([
    ...exposuresToSave.map(id => saveEmployeeExposure(employeeId, id)),
    ...exposuresToDelete.map(exp => deleteEmployeeExposure(exp.id)),
  ]);
}

export async function addEmployee(body: Omit<Employee, 'id'>): Promise<Employee> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof Employee)[] = [
    'birthDate',
    'hireDate',
    'nextExaminationDate',
    'quitDate',
    'positionId',
    'stationId',
    'companyId',
    'departmentId',
    'exposureId',
  ];

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((body as any)[key] !== '') {
      continue;
    }

    (body as any)[key] = null;
  }
  const [error, res] = await tryCatch(HSApi.post<Employee>(`/employees`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response.data.message;
  }

  await Promise.all(body.exposureId.map(id => saveEmployeeExposure(res.data.id, id)));

  return res.data;
}

export async function getEmployee(id: string): Promise<Employee> {
  const [error, res] = await tryCatch(Promise.all([HSApi.get<Employee>(`/employees/${id}`), getEmployeeExposures(id)]));
  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return {
    ...res[0]?.data,
    exposureId: res[1]?.map(({ exposureDefinitionId }) => exposureDefinitionId),
  };
}

export async function updateEmployee({ id, ...body }: MakeRequired<Partial<Employee>, 'id'>): Promise<Employee> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof Employee)[] = [
    'birthDate',
    'hireDate',
    'nextExaminationDate',
    'quitDate',
    'positionId',
    'stationId',
    'companyId',
    'departmentId',
    'exposureId',
  ];

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((body as any)[key] !== '') {
      continue;
    }

    (body as any)[key] = null;
  }

  const [error, res] = await tryCatch(HSApi.put<Employee>(`/employees/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  if (body.exposureId) {
    await upsertEmployeeExposures(id, body.exposureId);
  }

  return res.data;
}

export async function uploadAvatar(id: Employee['id'], file: File) {
  const data = new FormData();
  data.set('avatar', file);

  const [err, res] = await tryCatch(HSApi.post(`/employees/${id}/avatar`, data));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function uploadFromExcel(file: File): Promise<unknown> {
  const data = new FormData();
  data.set('file', file);

  const [err, res] = await tryCatch(HSApi.post(`/employees/view/import`, data));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
    throw err.response;
  }

  return res.data;
}

export async function exportEmployee() {

  const res = await HSApi.get(`/employee-filter/view/export?type=PDF`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'personel-listesi.pdf';
  a.click();
}

export async function exportEmployeeReferral(id: string = '', name: string = 'referral') {

  const res = await HSApi.get(`/referrals/${id}/detail-view/export?type=PDF`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = name+'.pdf';
  a.click();
}

export async function exportEmployeeReport(id: string = '', name: string = 'referral') {

  const res = await HSApi.get(`/rest-reports/${id}/detail-view/export?type=PDF`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = name+'.pdf';
  a.click();
}

export async function exportEmployeeKKDReport(id: string = '', name: string = 'Zimmetli-KKD-Listesi') {

  const res = await HSApi.get(`/ppe-output-group/${id}/ppe-outputs/export?type=PDF`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = name+'.pdf';
  a.click();
}

export async function exportEmployeePrescription(id: string = '', name: string = 'Prescription') {
  const res = await HSApi.get(`/prescriptions/${id}/detail-view/export?type=PDF`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = name+'.pdf';
  a.click();
}

export async function searchEmployee(searchString: string = ''): Promise<EmployeeSearchResult[]> {
  const res = await HSApi.get<EmployeeSearchResult[]>(`/employees/list?searchKeyword=${searchString}`);

  return res.data;
}

export async function searchEmployeeNIdList(employeeNidList: string[]) {
  const res = await HSApi.get<EmployeeSearchResult[]>(`/employees?nids=${employeeNidList}&size=1000000`);

  return res.data.content;
}

export async function searchTrainingEmployeeParticipants(employeeNidList: string[]) {
  const res = await HSApi.post(`/search-employees`, {
    PagingAndSortingRequestParam: { page: 0, size: 2147483647 },
    managerId: null,
    requestBody: {
      employeeNids: employeeNidList,
    },
  });

  return res.data.content;
}

export async function extendedSearchEmployee(payload: ExtendedSearchEmployeePayload): Promise<EmployeeSearchResult[]> {
  const params = new URLSearchParams(
    Object.entries(payload).filter(([key, value]) => EXTENDED_SEARCH_EMPLOYEE_PAYLOAD_KEYS.includes(key) && value.length)
  );

  if (!params.toString()) {
    return [];
  }

  const res = await HSApi.get<EmployeeSearchResult[]>(`/search-employees?${params.toString()}`);

  return res.data;
}

export async function getByIdEmployee(employeeId: string) {
  const res = await HSApi.get(`/employees/${employeeId}`);

  return res.data;
}

export async function getEmployeesByIds(ids: string[]): Promise<Employee[]> {
  if (!ids.length) {
    return [];
  }

  const res = await HSApi.get<Employee[]>(`/employees/pages?ids=${ids.join(',')}`);

  return res.data;
}

export async function getSearchEmployeesByIds(ids: string[]): Promise<Employee[]> {
  if (!ids.length) {
    return [];
  }

  const res = await HSApi.post<Employee[]>(`/employees/pages`, {
    employeeIds: ids,
  });

  return res.data;
}

export async function getEmployeeCounts(dateRange?: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/employee/widgets/count${dateRange?.toString()}`));

  return handleResult(res, error);
}

export async function getEmployeeWarningFlags(employeeId: string): Promise<WarningLabelsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<WarningLabelsResult[]>(`/warning-flags?employeeId=${employeeId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
  return res.data;
}

export async function getEmployeeWidgetViewCount(dateRange?: DateRange, employeeWidgetType?: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/employee/widgets/views${dateRange?.toString()}&employeeWidgetType=${employeeWidgetType}`)
  );

  return handleResult(res, error);
}
