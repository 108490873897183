import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import { updateEmployee } from 'api/hs/employee';
import { DialogContext } from 'context/dialog.context';
import { Employee, EmployeeStatus } from 'model';
import { stopPropagation } from 'utils';

import ArchiveEmployeeDialogIcon from '../../../ArchiveEmployeeDialogIcon';
import { EmployeeListContext } from '../../employee-list.context';

interface Props {
  employee: Employee;
}

export default function Action({ employee }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedEmployee, setEditEmployeeDialogOpened, reload } = useContext(EmployeeListContext);
  const { openWarnDialog } = useContext(DialogContext);

  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const openArchiveDialog = useCallback(async () => {
    closeMenu();

    const submitted = await openWarnDialog({
      text: `${employee?.name} ${
        employee.status === 'ACTIVE'
          ? '- kaydını arşive göndermek istediğinize emin misiniz ?'
          : '- kaydını arşivden çıkarmak istediğinize emin misiniz ?'
      } `,
      Icon: <ArchiveEmployeeDialogIcon />,
    });

    if (submitted) {
      await updateEmployee({ id: employee.id, status: employee.status === 'ACTIVE' ? EmployeeStatus.Archived : EmployeeStatus.Active });
      reload();
    }
  }, [closeMenu, employee, reload, openWarnDialog]);

  const openEditDialog = useCallback(() => {
    setSelectedEmployee(employee);
    setEditEmployeeDialogOpened(true);
    closeMenu();
  }, [closeMenu, setEditEmployeeDialogOpened, setSelectedEmployee, employee]);

  return (
    <span onClick={stopPropagation}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem onClick={openArchiveDialog}>
          <ListItemIcon>
            <ArchiveIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">{employee.status === 'ACTIVE' ? 'ARŞİVLE' : 'ARŞİVDEN ÇIKAR'} </Typography>
        </MenuItem>
        <MenuItem onClick={openEditDialog}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">DÜZENLE</Typography>
        </MenuItem>
      </Menu>
    </span>
  );
}
