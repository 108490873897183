import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { PregnanciesResult } from './pregnancies.result';

export async function getPregnancies(employeeId: string, examinationId?: string): Promise<PregnanciesResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<PregnanciesResult[]>(`/pregnancies?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<PregnanciesResult[]>(`/pregnancies?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function savePregnancies(body: any): Promise<PregnanciesResult> {
  const [err, res] = await tryCatch(HSApi.post('/pregnancies', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updatePregnancies({ id, ...body }) {
  const [error, res] = await tryCatch(HSApi.put<PregnanciesResult>(`/pregnancies/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deletePregnancies(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/pregnancies/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
