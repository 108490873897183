import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import Toolbar from 'Common/Toolbar';

import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { ActionContext } from '../../action.context';
import ActionsFormDialog from '../Form';
import Filters from './Filters';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarAction() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openPopover = useCallback(({ target }) => setAnchorEl(target as HTMLElement), []);
  const closePopover = useCallback(() => setAnchorEl(null), []);
  const { setNewDialogOpened, newDialogOpened, setSelectedData } = useContext(ActionContext);
  const { data, refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setNewDialogOpened(false);
      setSelectedData(null);
    },
    [refresh, setNewDialogOpened, setSelectedData]
  );

  const newPpeDefinition = useCallback(() => {
    closePopover();
    setNewDialogOpened(true);
  }, [setNewDialogOpened, closePopover]);
  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Aksiyon ({data?.length})
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={openPopover}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -175 }} keepMounted open={!!anchorEl} onClose={closePopover}>
          <List>
            <ListItem onClick={newPpeDefinition} className="cursor-pointer">
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Yeni Aksiyon</Typography>
            </ListItem>
          </List>
        </Popover>
      </div>
      <ActionsFormDialog open={newDialogOpened} onClose={closeDialog} data={null} />
      <Filters />
    </Toolbar>
  );
}
