import { Grid, Icon, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { patchTraining } from '../../../../api/hs/trainings';
import DateInput from '../../../../Common/Form/DateInput';
import Select from '../../../../Common/Form/Select';
import { AlertContext } from '../../../../context/alert.context';
import { ResourceContext } from '../../../../context/resource.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';
import { useDateFormat } from '../../../../utils';
import { TrainingDetailContext } from '../completed.context';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function CertificateTypeLabel(certificateType: string): string {
  switch (certificateType) {
    case 'PREDEFINED':
      return 'Ön Tanımlı';
    case 'PREDEFINED_LEGAL':
      return 'Bakanlık';
    default:
      return certificateType;
  }
}

export default function GeneralInfo() {
  const classes = useStyles();
  const { trainingDetail, reload } = useContext(TrainingDetailContext);
  const [isEdit, setIsEdit] = useState(false);
  const [isCompletedDateEdit, setIsCompletedDateIsEdit] = useState(false);
  const formRef = useRef<FormRef>();
  const { trainingsCertificateType } = useContext(SelectOptionsContext);
  const { trainers } = useContext(ResourceContext);
  const [openGeneralInfo, setOpenGeneralInfo] = React.useState(true);
  const { showMessage } = useContext(AlertContext);
  const format = useDateFormat('dd MMMM yyyy HH:mm');

  const handleClick = () => {
    setOpenGeneralInfo(!openGeneralInfo);
  };

  const edit = useCallback(async () => {
    setIsEdit(true);
    await defer();
    formRef?.current?.methods?.setValue('certificateType', trainingDetail.certificateType);
    formRef?.current?.methods?.trigger();
  }, [trainingDetail]);

  const editCompletedDate = useCallback(async () => {
    setIsCompletedDateIsEdit(true);
    await defer();
    formRef?.current?.methods?.setValue('completedDate', trainingDetail.completedDate);
    formRef?.current?.methods?.trigger();
  }, [trainingDetail]);

  const handleSubmit = useCallback(
    async values => {
      try {
        await patchTraining(trainingDetail.id, values);
        showMessage('İlgili Alan Başarı ile kayıt edildi', 'success');
        setIsEdit(false);
        setIsCompletedDateIsEdit(false);
        reload();
      } catch (error) {
        showMessage('Hata', 'error');
      }
    },
    [showMessage, trainingDetail, reload]
  );

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon icon-school" />
        </ListItemIcon>
        <ListItemText primary="Eğitimin Genel Bilgileri" />
        {openGeneralInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openGeneralInfo} timeout="auto" unmountOnExit>
        <Grid container spacing={1} className="pb-2">
          <Grid item sm={6}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.textColor}>
                      Eğitim Adı
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Eğitimciler
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.trainingTrainers?.map(trainer => findByKey(trainers, 'id', trainer?.trainerId)?.name).join(' , ')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Geçerlilik Süresi (Ay)
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.validityPeriodInMonth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Planlanan Tarih
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.plannedDate ? format(new Date(trainingDetail?.plannedDate)) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.textColor}>
                      Eğitim Yeri
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.location}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Tamamlanma Tarihi
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {isCompletedDateEdit ? (
                        <Form onSubmit={handleSubmit} ref={formRef}>
                          <Grid container>
                            <Grid item lg={8} md={8} xs={8} sm={8}>
                              <DateInput name="completedDate" type="date-time" />
                            </Grid>
                            <Grid style={{ display: 'contents' }} item lg={2} md={2} xs={2} sm={2}>
                              <IconButton type="submit">
                                <CheckIcon />
                              </IconButton>
                              <IconButton onClick={() => setIsCompletedDateIsEdit(false)}>
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Form>
                      ) : trainingDetail?.completedDate ? (
                        trainingDetail?.completedDate ? (
                          format(new Date(trainingDetail?.completedDate))
                        ) : null
                      ) : (
                        '   Tamamlanma Tarihi Girilmemiş'
                      )}
                      {isCompletedDateEdit ? (
                        <>
                          {/*<IconButton>*/}
                          {/*  <CheckIcon/>*/}
                          {/*</IconButton>*/}
                          {/*<IconButton onClick={() => setIsEdit(false)}>*/}
                          {/*  <CloseIcon />*/}
                          {/*</IconButton>*/}
                        </>
                      ) : (
                        <IconButton onClick={editCompletedDate}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={6} className={classes.aside}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Yıllık Plan Dahilinde
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.isInAnnualPlan ? 'Evet' : 'Hayır'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      İş yerinde
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.atWorkplace ? 'Evet' : 'Hayır'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Eğitim Şekli
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.isFaceToFace ? 'Yüzyüze' : 'Uzaktan'}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Sertifika Tipi
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {isEdit ? (
                        <Form onSubmit={handleSubmit} ref={formRef}>
                          <Grid container>
                            <Grid item lg={8} md={8} xs={8} sm={8}>
                              <Select name="certificateType" options={trainingsCertificateType} label="Sertifika Tipi" />
                            </Grid>
                            <Grid style={{ display: 'contents' }} item lg={2} md={2} xs={2} sm={2}>
                              <IconButton type="submit">
                                <CheckIcon />
                              </IconButton>
                              <IconButton onClick={() => setIsEdit(false)}>
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Form>
                      ) : trainingDetail?.certificateType ? (
                        CertificateTypeLabel(trainingDetail?.certificateType)
                      ) : (
                        'Sertifika Bilgi Yok'
                      )}
                      {isEdit ? (
                        <>
                          {/*<IconButton>*/}
                          {/*  <CheckIcon/>*/}
                          {/*</IconButton>*/}
                          {/*<IconButton onClick={() => setIsEdit(false)}>*/}
                          {/*  <CloseIcon />*/}
                          {/*</IconButton>*/}
                        </>
                      ) : (
                        <IconButton onClick={edit}>
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
