import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteExposure, ExposureResult, getExposure } from 'api/hs/definitions/workplace-definition/exposure';
import ExposureEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Exposure/ExposureEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Exposure() {
  return (
    <BaseDefinitionPage<ExposureResult>
      listLabelPropName="exposureName"
      fetchData={getExposure}
      FormDialog={ExposureEditDialog}
      onDelete={deleteExposure}
      Icon={<DomainIcon />}
      searchName="exposureName"
    />
  );
}
