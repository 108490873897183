import { Button, Divider, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer, tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState, useFetch } from 'utils';

import { getOccupationalSafetyRegistries } from '../../api/hs/occupational-safety-registries /occupational-safety-registries';
import TextField from '../../Common/Form/TextField';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

export default function Filters() {
  const classes = useStyles();
  const { companies } = useContext(SelectOptionsContext);

  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [, setSelectedNotebookId] = useState(null);
  const fetchBusinesses = useCallback(async () => {
    if (!selectedCompanyId) return [];
    const [err, res] = await tryCatch(getOccupationalSafetyRegistries(selectedCompanyId));
    if (err) {
      return [];
    }
    return res?.map(elem => ({
      text: elem.label,
      value: elem.id,
    }));
  }, [selectedCompanyId]);
  const { data: notebooks } = useFetch(fetchBusinesses, [], {
    deps: [selectedCompanyId],
    reloadDeps: [selectedCompanyId],
  });
  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);
  const onChangeForm = useCallback(values => {
    setSelectedCompanyId(values?.companyIds);
    setSelectedNotebookId(values?.registryIds);
  }, []);
  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  return (
    <>
      <Fab
        onClick={open}
        size="small"
        className={classes.fab}
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">Onaylı Defter Filtreleme</Typography>
        </Toolbar>
        <Form onSubmit={submit} ref={formRef} onChange={onChangeForm}>
          <Grid container className="p-2">
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete name="companyIds" options={companies} label="Şirketler" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete name="registryIds" disabled={!notebooks.length} options={notebooks} label="Defter" />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField name="description" label="Açıklama" />
            </Grid>
            <Grid className="mt-2" spacing={1} item container xs={12}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography variant="subtitle1">Oluşturulma Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <DateInput type="date" name="createdDateMin" label="Başlangıç" />
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <DateInput type="date" name="createdDateMax" label="Bitiş" />
              </Grid>
            </Grid>
            <Grid className="mt-2" spacing={1} item container xs={12}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography variant="subtitle1">Değiştirilme Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <DateInput type="date" name="updatedDateMin" label="Başlangıç" />
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={6}>
                <DateInput type="date" name="updatedDateMax" label="Bitiş" />
              </Grid>
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
