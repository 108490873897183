import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import IndicatorWrapper from 'Common/IndicatorWrapper';

import { dateDisplayFormat } from '../../../../../utils';
import { PeriodicExaminationConfigurationContext } from '../periodic-examination-configurations.context';

export default function ListPeriodicExaminationConfiguration() {
  const { loading, setEditDialogOpened, periodicExaminationConfigurations } = useContext(PeriodicExaminationConfigurationContext);
  const openEditDialog = useCallback(() => setEditDialogOpened(true), [setEditDialogOpened]);
  return (
    <>
      <IndicatorWrapper
        loading={loading}
        data={periodicExaminationConfigurations}
        errorMessage="Gösterilecek Periyodik Muayene Bulunamadı.">
        {!!periodicExaminationConfigurations && !loading && (
          <>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" style={{ width: '25%' }}>
                      Muayene Sıklığı (Ay)
                    </TableCell>
                    <TableCell align="left" style={{ width: '75%' }}>
                      : {periodicExaminationConfigurations?.examinationFrequencyInMonth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ width: '25%' }}>
                      Muayene Tarihi
                    </TableCell>
                    <TableCell align="left" style={{ width: '75%' }}>
                      : {dateDisplayFormat(periodicExaminationConfigurations?.nextExaminationDate)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </IndicatorWrapper>
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={openEditDialog} className="mt-1">
        Periyodik Muayene Ayarlarını Düzenle
      </Button>
    </>
  );
}
