import { createContext } from 'react';

interface WorkEquipmentLayoutFormData {
  id: string;
  workEquipmentDefinitionId: string;
  layoutPartId: string;
  layoutDescription: string;
  qrcodeStr: string;
  serialNumber: string;
  trackingNumber: string;
}

export interface WorkEquipmentLayoutNewFormContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  workEquipmentLayoutData: WorkEquipmentLayoutFormData[];

  setWorkEquipmentLayoutData(data: any[]): WorkEquipmentLayoutFormData[];

  loading: boolean;

  setLoading(loading: boolean): void;
}

export const WorkEquipmentLayoutNewFormContext = createContext<WorkEquipmentLayoutNewFormContextType>(null);
