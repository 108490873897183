import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useCallback } from 'react';

interface Props<T extends number> {
  sizes?: T[];
  size: T;
  onSizeChange(size: T): void;
  page: number;
  count: number;
  onPageChange(page: number): void;
}

type DefaultSizeType = 5 | 10 | 20 | 50;
const DEFAULT_SIZES: DefaultSizeType[] = [5, 10, 20, 50];

export default function TablePagination<T extends number = DefaultSizeType>({
  sizes = DEFAULT_SIZES as T[],
  size,
  onSizeChange,
  page,
  count,
  onPageChange,
}: Props<T>) {
  const handleSizeChange = useCallback(({ target: { value } }) => onSizeChange(value as T), [onSizeChange]);
  const handlePageChange = useCallback((_, p: number) => onPageChange(p), [onPageChange]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Typography color="inherit" variant="body2" className="mr-1">
          Gözüken kayıt sayısı:
        </Typography>
      </Grid>
      <Grid item>
        <Select value={size} onChange={handleSizeChange} classes={{ root: 'pl-1' }}>
          {sizes.map(s => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Pagination className="ml-2" showFirstButton showLastButton onChange={handlePageChange} count={count} page={page} color="primary" />
      </Grid>
    </Grid>
  );
}
