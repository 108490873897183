import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { EnumResult } from './enum.result';

export async function getActionsStatusDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/actions-status-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
