/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TrainingDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    predefinedId?: string;
    source?: TrainingDefinitionDto.source;
    name?: string;
    code?: string;
    groupId?: string;
    description?: string;
    isLegalObligation?: boolean;
    durationInMinute?: number;
    isPeriodic?: boolean;
    periodDurationInMonth?: number;
    assignableType?: TrainingDefinitionDto.assignableType;
    aim?: string;
    objective?: string;
    trainingReference?: string;
    isIbysTraining?: boolean;
    trainingOrder?: number;
    label?: string;
}

export namespace TrainingDefinitionDto {

    export enum source {
        SYSTEM = 'SYSTEM',
        USER_DEFINED = 'USER_DEFINED',
    }

    export enum assignableType {
        NOT_ASSIGNABLE = 'NOT_ASSIGNABLE',
        MANDATORY_FOR_ALL_EMPLOYEE = 'MANDATORY_FOR_ALL_EMPLOYEE',
        ASSIGNABLE = 'ASSIGNABLE',
    }


}
