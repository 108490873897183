import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { saveMedicine } from 'api/hs/definitions/health-definitions/medicine';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  fabButton: {
    width: '40px',
    height: '40px',
    color: 'white',
    float: 'right',
    marginRight: '5px',
    marginTop: '-92px',
    backgroundColor: '#eb4355',
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));

export default function MedicineForm() {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { prescriptionTypeDefinitions } = useContext(SelectOptionsContext);
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(
    async values => {
      try {
        await saveMedicine(values);
        showMessage('Ekleme işlemi başarılı.', 'success', 'info');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
      setOpen(false);
    },
    [showMessage]
  );

  const onClick = () => {
    setOpen(true);
  };
  return (
    <>
      <Fab onClick={onClick} className={classes.fabButton}>
        <AddIcon className={classes.addButton} />
      </Fab>
      <Dialog
        PaperProps={{ style: { maxWidth: 500 } }}
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.dialogTitle}>İlaç Ekleme</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
            <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField required type="text" name="label" label="İlaç Adı" />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <TextField type="text" name="barcode" label="Barkod Numarası" />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <TextField type="text" name="producerCompany" label="Üretici Firma" />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <Select name="prescriptionType" options={prescriptionTypeDefinitions} label="Reçete Türü" />
                </Grid>
                <Grid item md={12} className="text-right">
                  <Button variant="contained" type="submit" color="primary" className="mr-2">
                    Kaydet
                  </Button>
                  <Button variant="contained" type="button" onClick={() => setOpen(false)}>
                    Vazgeç
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
