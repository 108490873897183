import React from 'react';

import MinistryTrainingList from './List/MinistryTrainingList';
export default function Index() {
  return (
    <>
      <MinistryTrainingList />
    </>
  );
}
