import { Grid } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import React, { useContext, useState } from 'react';

import { exportAccidentCommunication } from '../../../api/hs/accident/accident';
import Button from '../../../Common/Button';
import LoadingWrapper from '../../../Common/LoadingWrapper';
import { AlertContext } from '../../../context/alert.context';

interface Props {
  accidentId: string;
}

export default ({ accidentId }: Props) => {
  const { showMessage } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async () => {
    try {
      setLoading(true);
      await exportAccidentCommunication(accidentId, `kaza-iletisim.pdf`);
    } catch (error) {
      showMessage('Bir hata oluştu', 'error', 'info');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item className="text-right">
      <LoadingWrapper loading={loading}>
        <Button startIcon={<CloudDownload />} color="secondary" onClick={onClick} className="mt-2">
          <b>Kaza İletişim Raporunu İndir</b>
        </Button>
      </LoadingWrapper>
    </Grid>
  );
};
