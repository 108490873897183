/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AudioDetailFilter } from './AudioDetailFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type AudioDetailRequest = {
    filter?: AudioDetailFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    exposeType?: AudioDetailRequest.exposeType;
}

export namespace AudioDetailRequest {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
