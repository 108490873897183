import { Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';

import Button from './Button';

export interface WarnDialogProps {
  opened: boolean;
  onClose(submitted: boolean): void;
  Icon?: JSX.Element;
  text?: string;
  cancelText?: string;
  acceptText?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '9999 !important',
  },
}));

export default function WarnDialog({
  opened,
  onClose,
  Icon = null,
  text = '',
  cancelText = 'Vazgeç',
  acceptText = 'Kabul',
}: WarnDialogProps) {
  const classes = useStyles();
  const cancel = useCallback(() => onClose(false), [onClose]);
  const accept = useCallback(() => onClose(true), [onClose]);

  return (
    <Dialog open={opened} keepMounted onClose={cancel} classes={{ root: classes.root }}>
      {Icon}
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={cancel}>
          {cancelText}
        </Button>
        <Button variant="contained" onClick={accept} color="error">
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
