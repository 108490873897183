import { createContext } from 'react';

import { ClassificationDefinitionResult } from 'api/hs/definitions/ppe-definitions/classification-definitions';

export interface ClassificationDefinitionsContextType {
  classificationDefinitions: ClassificationDefinitionResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: ClassificationDefinitionResult;

  setSelectedData(data: ClassificationDefinitionResult): void;
}

export const ClassificationDefinitionsContext = createContext<ClassificationDefinitionsContextType>(null);
