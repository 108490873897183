import React, { useState } from 'react';

import { NearMissDto } from '../../api/client';
import { NearMissContext } from './near-miss.context';
import NearMissRecord from './NearMissRecord';

export default function Index() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<NearMissDto>(null);
  return (
    <NearMissContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <NearMissRecord />
    </NearMissContext.Provider>
  );
}
