/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExaminationFillingFields } from './ExaminationFillingFields';
import type { ExaminationFilter } from './ExaminationFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type ExaminationFilterDto = {
    exposeType?: ExaminationFilterDto.exposeType;
    examinationFilter?: ExaminationFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    fillingFields?: ExaminationFillingFields;
}

export namespace ExaminationFilterDto {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
