import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useState } from 'react';

import DetectionsForm from '../../Common/Forms/OccupationalSafetyRegistries/Occupation/RegistriesForm/DetectionsForm';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '300px',
    right: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));
export default function Add({ pageId, fetch }) {
  const classes = useStyles();

  const [newOpen, setNewOpen] = useState(false);
  const onClose = useCallback(async () => {
    setNewOpen(false);
    await fetch();
  }, [setNewOpen, fetch]);
  const onNewDialog = useCallback(() => {
    setNewOpen(true);
  }, [setNewOpen]);

  return (
    <div>
      <Fab onClick={onNewDialog} className={classes.fabButton} color="primary">
        <AddIcon className={classes.addButton} />
      </Fab>
      <DetectionsForm pageId={pageId} open={newOpen} onClose={onClose} data={null} />
    </div>
  );
}
