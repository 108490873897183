import React from 'react';

import Add from './Add';
import ListBodyMeasurement from './List';

export default function Index() {
  return (
    <>
      <ListBodyMeasurement />
      <Add />
    </>
  );
}
