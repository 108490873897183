import { createContext, RefObject } from 'react';

import { UseDialogState } from 'utils';

export interface Color {
  h: number;
  s: number;
  v: number;
  a: number;
}

export interface ColorPickerContextType {
  name: string;
  label?: string;
  disabled?: boolean;
  color: Color;
  setColor(color: Color): void;
  colorText: string;
  picker: UseDialogState;
  inputRef: RefObject<HTMLDivElement>;
}

export const ColorPickerContext = createContext<ColorPickerContextType>(null);
