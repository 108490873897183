import { modelDataMatcher } from 'utils';

export class Medicine {
  public id: string = null;
  public label: string = null;
  public barcode: string = null;
  public producerCompany: string = null;
  public atcCode: string = null;
  public atcName: string = null;
  public ssiRefundable: boolean = null;
  public prescriptionType: number = null;
  public status: string = null;

  constructor(data: Partial<Medicine> = {}) {
    modelDataMatcher(data, this);
  }
}
