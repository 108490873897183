import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { PregnanciesModel, savePregnancies, updatePregnancies } from 'api/hs/examination/pregnancies';
import Checkbox from 'Common/Form/Checkbox';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { EmployeeContext } from 'TransactionsAndLists/EmployeeList/Employee/employee.context';
import { dateToInputFormat, useAsyncEffect } from 'utils';

import DateInput from '../../../../../../Common/Form/DateInput';
import { PregnanciesContextContext } from '../../pregnancies.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PregnanciesModel;
}

export default function DialogPregnanciesForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, getValues, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { pregnancyStates, bloodTypes } = useContext(SelectOptionsContext);
  const { reload } = useContext(PregnanciesContextContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { refresh } = useContext(EmployeeContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          if (values.pregnancyStateDefinitionId === 34) {
            await savePregnancies({
              ...values,
              employeeId,
              examinationId,
              maternityLeave: { startDate: values.startDate, endDate: values.endDate },
            });
          } else {
            await savePregnancies({ ...values, employeeId, examinationId });
          }

          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updatePregnancies({ id: data.id, ...values, employeeId, examinationId });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
        await refresh();
        reload();
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, employeeId, examinationId, reload, refresh]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      trigger();

      await defer();
      setValue('startDate', data.maternityLeave?.startDate);
      setValue('endDate', data.maternityLeave?.endDate);

      trigger();
    } else {
      reset({
        description: null,
        lastMenstruationDate: '' as any,
        multipleBirth: false,
        birthDate: '' as any,
        pregnancyStateDefinitionId: null,
        husbandBloodTypeDefinitionId: null,
        bloodTypeIncompatibility: false,
        startDate: '' as any,
        endDate: '' as any,
      });
    }
  }, [data, setValue, reset, trigger, open]);

  const lastMenstruationDate = getValues('lastMenstruationDate');

  useEffect(() => {
    if (!lastMenstruationDate) {
      setValue('birthDate', '');
    } else {
      const selectedDate = new Date(lastMenstruationDate);
      const newDate = new Date(selectedDate.getTime() + 280 * 24 * 60 * 60 * 1000);
      setValue('birthDate', `${dateToInputFormat(newDate)}`);
    }
    trigger();
  }, [lastMenstruationDate, setValue, trigger]);

  function renderFormItems(pregnancyStateDefinitionId: number): JSX.Element {
    switch (pregnancyStateDefinitionId) {
      case 34:
        return (
          <>
            <Grid item md={12} sm={12} lg={12}>
              <DateInput type="date" name="startDate" label="İzne Çıkış Tarihi" />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <DateInput type="date" name="endDate" label="İzinden Dönüş Tarihi" />
            </Grid>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 550 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Gebelik Öyküsü Düzenleme' : 'Gebelik Öyküsü Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <Select required name="pregnancyStateDefinitionId" options={pregnancyStates} label="Durum" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput type="date" name="lastMenstruationDate" label="Son Adet" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput required type="date" name="birthDate" label="Beklenen Doğum Tarihi" />
              </Grid>
              {renderFormItems(getValues('pregnancyStateDefinitionId'))}
              <Grid item md={12} sm={12} lg={12}>
                <Select name="husbandBloodTypeDefinitionId" options={bloodTypes} label="Eşinin Kan Grubu" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Checkbox name="multipleBirth" label="Çoklu Doğum" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Checkbox name="bloodTypeIncompatibility" label="Rh Uyuşmazlığı Var" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
