import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { StationExposureControllerService } from '../../../../client';
import { getFileImportedFile } from '../../committee-members/committee-members';
import { StationPayload } from './Station.payload';
import { StationResult } from './Station.result';

export async function getStation(): Promise<StationResult[]> {
  const [error, res] = await tryCatch(HSApi.get<StationResult[]>(`/firms/station-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  // return res.data;
  return await asyncMap(res.data, async item => ({
    ...item,
    exposureIds:
      (await StationExposureControllerService.getAllStationExposure(item?.id))?.reduce((acc, value) => [...acc, value.exposureId], []) ??
      null,
  }));
}

export async function saveStation(body: StationPayload): Promise<StationResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/station-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  await StationExposureControllerService.saveStationsExposures(
    res.data?.id,
    body?.exposureIds?.map(exposureId => {
      return {
        exposureId,
        stationId: res?.data?.id,
      };
    })
  );

  return res.data;
}

export async function updateStation({ id, ...body }: MakeRequired<Partial<StationResult>, 'id'>): Promise<StationResult> {
  const [error, res] = await tryCatch(HSApi.put<StationResult>(`/firms/station-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  await StationExposureControllerService.saveStationsExposures(
    id,
    body?.exposureIds?.map(exposureId => {
      return {
        exposureId,
        stationId: res?.data?.id,
      };
    })
  );

  return res.data;
}

export async function deleteStation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/station-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminStation(): Promise<StationResult[]> {
  const [error, res] = await tryCatch(HSApi.get<StationResult[]>(`/station-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminStation(body: StationPayload): Promise<StationResult> {
  const [err, res] = await tryCatch(HSApi.post('/station-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminStation({ id, ...body }: MakeRequired<Partial<StationResult>, 'id'>): Promise<StationResult> {
  const [error, res] = await tryCatch(HSApi.put<StationResult>(`/station-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminStation(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/station-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
