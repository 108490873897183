/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionExposureDto } from '../models/PositionExposureDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PositionExposureControllerService {

    /**
     * @param positionId
     * @returns PositionExposureDto OK
     * @throws ApiError
     */
    public static getAllPositionExposure(
        positionId: string,
    ): CancelablePromise<Array<PositionExposureDto>> {
        return __request({
            method: 'GET',
            path: `/positions/exposures`,
            query: {
                'positionId': positionId,
            },
        });
    }

    /**
     * @param positionId
     * @param requestBody
     * @returns PositionExposureDto OK
     * @throws ApiError
     */
    public static savePositionsExposures(
        positionId: string,
        requestBody: Array<PositionExposureDto>,
    ): CancelablePromise<Array<PositionExposureDto>> {
        return __request({
            method: 'POST',
            path: `/positions/exposures`,
            query: {
                'positionId': positionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deletePositionExposure(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/positions/exposures/${id}`,
        });
    }

}