import { makeStyles, Typography } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import InlineSearchBar from 'Common/SearchBar/InlineSearchBar';
import CoreToolbar from 'Common/Toolbar';

import { EmployeeListContext } from '../employee-list.context';
import Filters from './Filters';
import MoreMenu from './MoreMenu';
import VisibleColumnMenu from './VisibleColumnMenu';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function Toolbar() {
  const classes = useStyles();
  const { totalElements, filters, updateFilters } = useContext(EmployeeListContext);

  const updateSearchKeyword = useCallback(value => updateFilters({ filter: { searchKeyword: value } }), [updateFilters]);

  return (
    <CoreToolbar>
      <Typography variant="h6" color="inherit" className="text-bold">
        Personel Listesi ( {totalElements} )
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <InlineSearchBar value={filters?.filter?.searchKeyword} onChange={updateSearchKeyword} placeholder="Ad, Soyad veya TCKN Giriniz" />
        <VisibleColumnMenu />
        <MoreMenu />
        <Filters />
      </div>
    </CoreToolbar>
  );
}
