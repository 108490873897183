import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { AccidentTypeDefinitionControllerService, AccidentTypeDefinitionDto } from '../../api/client';
import AdminAccidentTypeEditDialog from '../../Common/Forms/Definitions/AccidentDefinition/AccidentTypeDefinition/AdminAccidentTypeEditDialog';
import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminAccidentTypeDefinition() {
  return (
    <BaseDefinitionPage<AccidentTypeDefinitionDto>
      listLabelPropName="label"
      fetchData={AccidentTypeDefinitionControllerService.getAllAccidentTypeDefinitions}
      FormDialog={AdminAccidentTypeEditDialog}
      onDelete={AccidentTypeDefinitionControllerService.deleteAccidentTypeDefinition}
      Icon={<FlashOnIcon />}
    />
  );
}
