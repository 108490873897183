import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { EmergencyCasePlanControllerService, EmergencyCasePlanDto } from '../../api/client';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: EmergencyCasePlanDto;

  fetch(): void;
}

function nextMonths(date: Date, months: number) {
  if (date === 'Invalid Date' || months === 0) {
    return '';
  }
  const d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function CasePlansForm({ open, onClose, data, fetch }: Props) {
  const { companies } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data) {
          await EmergencyCasePlanControllerService.saveEmployeeEmergencyCasePlan(values);
          showMessage('Başarılı kayıt edildi.', 'success', 'info');
        } else {
          await EmergencyCasePlanControllerService.updateEmployeeEmergencyCasePlan(data?.id, values);
          showMessage('Kayıt başarılı şekilde güncellendi.', 'success', 'info');
        }
        onClose(true);
        await fetch();
      } catch (err) {
        showMessage('Kayıt sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, fetch, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog
      width="sm"
      title={data ? 'Acil Durum Planı Düzenleme' : 'Acil Durum Planı Ekleme'}
      color="secondary"
      opened={open}
      onClose={() => onClose(false)}>
      <Form onChange={reRender} onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <AutoComplete single name="companyId" options={companies} label="Şirketler" required={true} />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <TextField name="label" label="Acil Durum Plan ismi" required={true} />
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <TextField type="number" name="expirationTimeInMonths" label="Geçerlilik Süresi (Ay)" required={true} rules={{ required: 'Bu alan zorunlu' }} />
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <DateInput type="date" name="executionDate" label="Gerçekleştirme Tarihi" required={true} />
          </Grid>
          <div style={{ color: 'red' }}>
            Son Geçerlilik Tarihi :{' '}
            {nextMonths(
              new Date(formRef?.current?.methods?.getValues('executionDate')),
              +formRef?.current?.methods.getValues('expirationTimeInMonths')
            )
              ?.split('-')
              ?.reverse()
              ?.join('-')}
          </div>
          <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
