import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getPhysicalCheck, PhysicalChecksModel } from 'api/hs/examination/physical-checks';
import { useAsyncEffect, useResourcePromise } from 'utils';

import { PhysicalCheckContext } from './physical-check.context';
import PhysicalCheck from './physicalCheck';

export default function Index() {
  const physicalCheck$ = useResourcePromise('physicalCheck');
  const [physicalChecks, setPhysicalChecks] = useState<PhysicalChecksModel[]>([]);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PhysicalChecksModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const physicalCheck = await physicalCheck$;
    const result = await getPhysicalCheck(employeeId, examinationId);
    const test = PhysicalChecksModel.fromEnumAndServerData(physicalCheck, result);
    setPhysicalChecks([]);
    await setPhysicalChecks(test);
  }, [employeeId, examinationId, physicalCheck$]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <PhysicalCheckContext.Provider
      value={{
        physicalChecks,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
      }}>
      <PhysicalCheck />
    </PhysicalCheckContext.Provider>
  );
}
