import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import { deleteMedicineTreatment, getMedicineTreatment, saveMedicineTreatment, updateMedicineTreatment } from 'api/hs/examination';

import History from './History';
import { ListItem } from './History/history.context';

export default function MedicineTreatments() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(() => getMedicineTreatment(employeeId, examinationId), [employeeId, examinationId]);
  const save = useCallback(({ description }: Omit<ListItem, 'id'>) => saveMedicineTreatment({ employeeId, examinationId, description }), [
    employeeId,
    examinationId,
  ]);
  const update = useCallback(({ id, ...rest }: ListItem) => updateMedicineTreatment(id, rest), []);
  const remove = useCallback((id: ListItem['id']) => deleteMedicineTreatment(id), []);

  return (
    <History fetch={fetch} save={save} update={update} remove={remove} title="Kullanılan İlaçlar" buttonLabel="Kullanılan İlaç Ekle" />
  );
}
