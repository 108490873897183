import { createContext } from 'react';

import { AccidentReviewDto, AccidentView } from '../../../api/client';
import { AccidentContentResult } from '../../../api/hs/accident';

export interface AccidentsDetailContextType {
  accidentDetail: AccidentView;

  accidentReview: AccidentReviewDto;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: AccidentContentResult;

  setSelectedData(data: AccidentContentResult): void;

  reload(): void;
}

export const AccidentsDetailContext = createContext<AccidentsDetailContextType>(null);
