import React from 'react';

import WorkEquipmentJobStatusList from './List';
export default function Index() {
  return (
    <>
      <WorkEquipmentJobStatusList />
    </>
  );
}
