import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const FormInputMultiCheckbox = ({ name, label, options }) => {
  const { control, getValues } = useFormContext();

  const currentValue = getValues(name);

  const handleCheck = useCallback(
    checkedId => {
      const newIds = currentValue?.includes(checkedId)
        ? currentValue?.filter(id => id !== checkedId)
        : [...(currentValue ?? []), checkedId];
      return newIds;
    },
    [currentValue]
  );

  return (
    <FormControl variant={'outlined'}>
      <FormLabel component="legend">{label}</FormLabel>

      <div>
        {options.map((option: any) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  defaultValue={[]}
                  render={({ onChange }) => {
                    return (
                      <Checkbox
                        defaultChecked={currentValue?.includes(option.value)}
                        onChange={() => onChange(handleCheck(option.value))}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={option.text}
              key={option.value}
            />
          );
        })}
      </div>
    </FormControl>
  );
};
