import React, { useCallback, useState } from 'react';

import { getFirmUser } from 'api/hs/resource';
import { GetFirmUserResult } from 'api/hs/resource';
import { UsersModel } from 'api/hs/users';
import { useAsyncEffect } from 'utils';

import { UserContext } from './user.context';
import Users from './Users';

export default function Index() {
  const [users, setUsers] = useState<GetFirmUserResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<UsersModel>(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);

  const fetch = useCallback(async () => {
    const result = await getFirmUser();
    setUsers(result);
    setLoading(false);
  }, []);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <UserContext.Provider
      value={{
        users,
        reload: fetch,
        loading,
        newDialogOpened,
        setNewDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <Users />
    </UserContext.Provider>
  );
}
