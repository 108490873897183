import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { MatrixHazardScoreDefinitionControllerService } from 'api/client';
import { MatrixHazardScoreDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import MatrixHazardScoreDefinitionForm from './Forms/MatrixHazardScoreDefinitionForm';

export default function MatrixHazardScoreDefinition() {
  return (
    <BaseDefinitionPage<MatrixHazardScoreDefinitionDto>
      listLabelPropName="label"
      fetchData={MatrixHazardScoreDefinitionControllerService.getAllMatrixScoreDefinitions}
      FormDialog={MatrixHazardScoreDefinitionForm}
      onDelete={MatrixHazardScoreDefinitionControllerService.deleteAllMatrixScoreDefinitions}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
