/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccidentDepartmentView } from './AccidentDepartmentView';
import type { AccidentEmployeeView } from './AccidentEmployeeView';
import type { AccidentReviewView } from './AccidentReviewView';
import type { AccidentStationView } from './AccidentStationView';
import type { AccidentTypeView } from './AccidentTypeView';

export type AccidentView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    accidentId?: string;
    companyId?: string;
    companyName?: string;
    description?: string;
    date?: string;
    shiftDescription?: string;
    shiftId?: string;
    userName?: string;
    lastModifiedByName?: string;
    accidentReviewStatus?: AccidentView.accidentReviewStatus;
    accidentDepartmentViews?: Array<AccidentDepartmentView>;
    accidentStationViews?: Array<AccidentStationView>;
    accidentTypeViews?: Array<AccidentTypeView>;
    accidentEmployeeViews?: Array<AccidentEmployeeView>;
    accidentReviewView?: AccidentReviewView;
}

export namespace AccidentView {

    export enum accidentReviewStatus {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        COMPLETED = 'COMPLETED',
    }


}
