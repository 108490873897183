import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminSystemCheck, getAdminSystemCheck, SystemCheckResult } from 'api/hs/definitions/health-definitions/system-check';
import AdminSystemCheckEditDialog from 'Common/Forms/Definitions/HealthDefinition/SystemCheck/AdminSystemCheckEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminSystemCheck() {
  return (
    <BaseDefinitionPage<SystemCheckResult>
      listLabelPropName="label"
      fetchData={getAdminSystemCheck}
      FormDialog={AdminSystemCheckEditDialog}
      onDelete={deleteAdminSystemCheck}
      Icon={<DomainIcon />}
    />
  );
}
