import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  getEmployeeWorkConditions,
  getPositionsWorkConditions,
  PositionsIbysWorkConditionsArrayPayload,
  PositionsIbysWorkConditionsPayload,
  PositionsIbysWorkConditionsResult,
  updateEmployeeWorkConditions,
} from 'api/hs/positions-ibys-work-conditions';
import PhysicalWorkCondition from 'Common/Forms/Definitions/PhysicalWorkCondition/PhysicalWorkCondition';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { EmployeeContext } from '../../employee.context';

// @ts-ignore

interface Props<T extends { id: string | number }> {
  open: boolean;

  onClose(success: boolean): void;

  data: T;
}
const useStyles = makeStyles(() => ({
  button: {
    margin: 10,
  },
}));

const defaultDefinitionValue = {
  ELECTRICITY: [1399],
  NOISE: [1299],
  AIR: [1199],
  BIOLOGICAL_FACTORS: [1699],
  DUST_REGULATIONS: [1499],
  PHYSICAL: [1099],
  CHEMICAL: [1599],
};

export default function PositionIbysWorkCondition<T extends { id: string | number }>({ data }: Props<T>) {
  const classes = useStyles();

  const { employeeId } = useParams<{ employeeId: string }>();
  const { employee } = useContext(EmployeeContext);

  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async (values: PositionsIbysWorkConditionsArrayPayload) => {
      try {
        const workConditionArray: PositionsIbysWorkConditionsPayload[] = [];

        values.electricityDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'ELECTRICITY',
          });
        });

        values.noiseDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'NOISE',
          });
        });

        values.airDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'AIR',
          });
        });

        values.biologicalFactorsDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'BIOLOGICAL_FACTORS',
          });
        });

        values.dustRegulationDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'DUST_REGULATIONS',
          });
        });

        values.physicalEnvironmentDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'PHYSICAL',
          });
        });

        values.chemicalMatterDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'CHEMICAL',
          });
        });
        await updateEmployeeWorkConditions(employeeId.toString(), workConditionArray);
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [employeeId, showMessage]
  );

  useAsyncEffect(async () => {
    if (employee) {
      await defer();

      let WorkConditionDefinitions: PositionsIbysWorkConditionsResult[] = await getEmployeeWorkConditions(employeeId.toString());
      if (WorkConditionDefinitions.length === 0 && employee.positionId) {
        WorkConditionDefinitions = await getPositionsWorkConditions(employee.positionId.toString());
      } else if (WorkConditionDefinitions.length === 0 && !employee.positionId) {
        setDefinitionsValue(
          defaultDefinitionValue.CHEMICAL,
          defaultDefinitionValue.ELECTRICITY,
          defaultDefinitionValue.AIR,
          defaultDefinitionValue.NOISE,
          defaultDefinitionValue.BIOLOGICAL_FACTORS,
          defaultDefinitionValue.DUST_REGULATIONS,
          defaultDefinitionValue.PHYSICAL
        );
      }
      workConditionDefinitionsFunc(WorkConditionDefinitions);
      trigger();
    }
  }, [employee, setValue, trigger]);

  const ResetValues = async () => {
    if (employee.positionId) {
      const WorkConditionDefinitions: PositionsIbysWorkConditionsResult[] = await getPositionsWorkConditions(
        employee.positionId.toString()
      );
      workConditionDefinitionsFunc(WorkConditionDefinitions);
    } else {
      setDefinitionsValue(
        defaultDefinitionValue.CHEMICAL,
        defaultDefinitionValue.ELECTRICITY,
        defaultDefinitionValue.AIR,
        defaultDefinitionValue.NOISE,
        defaultDefinitionValue.BIOLOGICAL_FACTORS,
        defaultDefinitionValue.DUST_REGULATIONS,
        defaultDefinitionValue.PHYSICAL
      );
    }
  };

  function workConditionDefinitionsFunc(WorkConditionDefinitions: PositionsIbysWorkConditionsResult[]) {
    const chemicalMatterDefinitions: number[] = [];
    const electricityDefinitions: number[] = [];
    const airDefinitions: number[] = [];
    const noiseDefinitions: number[] = [];
    const biologicalFactorsDefinitions: number[] = [];
    const dustRegulationDefinitions: number[] = [];
    const physicalEnvironmentDefinitions: number[] = [];

    WorkConditionDefinitions.forEach(value => {
      if (value.physicalWorkCondition === 'CHEMICAL') {
        chemicalMatterDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'ELECTRICITY') {
        electricityDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'AIR') {
        airDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'NOISE') {
        noiseDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'BIOLOGICAL_FACTORS') {
        biologicalFactorsDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'DUST_REGULATIONS') {
        dustRegulationDefinitions.push(value.code);
      }
      if (value.physicalWorkCondition === 'PHYSICAL') {
        physicalEnvironmentDefinitions.push(value.code);
      }
    });
    setDefinitionsValue(
      chemicalMatterDefinitions,
      electricityDefinitions,
      airDefinitions,
      noiseDefinitions,
      biologicalFactorsDefinitions,
      dustRegulationDefinitions,
      physicalEnvironmentDefinitions
    );
  }
  function setDefinitionsValue(
    chemicalMatterDefinitions: number[],
    electricityDefinitions: number[],
    airDefinitions: number[],
    noiseDefinitions: number[],
    biologicalFactorsDefinitions: number[],
    dustRegulationDefinitions: number[],
    physicalEnvironmentDefinitions: number[]
  ) {
    setValue('', '');
    setValue('chemicalMatterDefinitions', chemicalMatterDefinitions);
    setValue('electricityDefinitions', electricityDefinitions);
    setValue('airDefinitions', airDefinitions);
    setValue('noiseDefinitions', noiseDefinitions);
    setValue('biologicalFactorsDefinitions', biologicalFactorsDefinitions);
    setValue('dustRegulationDefinitions', dustRegulationDefinitions);
    setValue('physicalEnvironmentDefinitions', physicalEnvironmentDefinitions);
  }

  return (
    <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
      <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <PhysicalWorkCondition required={false} />
        <Grid item md={12} className="text-right">
          <Button variant="contained" type="button" color="primary" className={classes.button} onClick={ResetValues}>
            Sıfırla
          </Button>
          <Button variant="contained" type="submit" color="primary" className="mr-2">
            {data?.id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
}
