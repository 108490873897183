/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';
import type { PpeOutputFilter } from './PpeOutputFilter';

export type PpeOutputViewRequest = {
    filter?: PpeOutputFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    exposeType?: PpeOutputViewRequest.exposeType;
}

export namespace PpeOutputViewRequest {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
