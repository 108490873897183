import { createContext } from 'react';

import { AccidentContentResult } from '../../../api/hs/accident';

export interface AccidentsContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: AccidentContentResult;

  setSelectedData(data: AccidentContentResult): void;
}

export const AccidentsContext = createContext<AccidentsContextType>(null);
