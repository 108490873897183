import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { NearMissTypeDefinitionControllerService, NearMissTypeDefinitionDto } from '../../api/client';
import AdminNearMissTypeDefinitionsForm from '../../Common/Forms/Definitions/NearMissTypeDefinition/AdminNearMissTypeDefinitions';
import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminNearMissTypeDefinitions() {
  return (
    <BaseDefinitionPage<NearMissTypeDefinitionDto>
      listLabelPropName="label"
      fetchData={NearMissTypeDefinitionControllerService.getAllNearMissTypeDefinitions}
      FormDialog={AdminNearMissTypeDefinitionsForm}
      onDelete={NearMissTypeDefinitionControllerService.deleteNearMissTypeDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
