import { createContext } from 'react';

import { ReferralsResult } from 'api/hs/examination/referrals';
import { ReferralsModel } from 'model/employee/examination/referrals.model';

export interface ReferralsContextType {
  referrals: ReferralsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: ReferralsModel;

  setSelectedData(data: ReferralsModel): void;

  newEmailDialog: boolean;

  setEmailDialogOpened(opened: boolean): void;

  newPreviewDialog: boolean;

  setPreviewDialogOpened(opened: boolean): void;

  previewData: string;

  setPreviewData(data: string): void;
}

export const ReferralsContextContext = createContext<ReferralsContextType>(null);
