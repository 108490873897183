import { IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { ResourceContext } from 'context/resource.context';

import { WorkEquipmentLayoutNewFormContext } from '../../work-equipment-layout-new-form.context';

export default function WorkEquipmentLayoutList() {
  const { workEquipmentLayoutData, setWorkEquipmentLayoutData } = useContext(WorkEquipmentLayoutNewFormContext);
  const { workEquipmentDefinition, buildings } = useContext(ResourceContext);

  const onDelete = useCallback(
    item => {
      setWorkEquipmentLayoutData(workEquipmentLayoutData?.filter(data => data?.id !== item?.id));
    },
    [setWorkEquipmentLayoutData, workEquipmentLayoutData]
  );

  return (
    <IndicatorWrapper loading={false} data={workEquipmentLayoutData} errorMessage="Listeye Kayıt Eklenmedi.">
      <div style={{ width: '100%', height: '3px', backgroundColor: 'gray' }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                İş Ekipman Tanım
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                Yerleşim Yeri
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                QR Code
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Seri Numarası
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Yer Bilgisi
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {workEquipmentLayoutData?.map((rowData, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {findByKey(workEquipmentDefinition, 'definitionId', rowData?.workEquipmentDefinitionId)?.definitionName}
                </TableCell>
                <TableCell align="left">{findByKey(buildings, 'id', rowData?.building)?.name}</TableCell>
                <TableCell align="left">{rowData?.qrcodeStr}</TableCell>
                <TableCell align="center">{rowData?.serialNumber}</TableCell>
                <TableCell align="center">{rowData?.layoutDescription}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onDelete(rowData)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </IndicatorWrapper>
  );
}
