import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { BodyMeasurementsModel, saveBodyMeasurements, updateBodyMeasurements } from 'api/hs/examination/body-measurements';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { dateToInputFormat, timeToInputFormat, useAsyncEffect } from 'utils';

import DateInput from '../../../../../../../Common/Form/DateInput';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: BodyMeasurementsModel;
}

export default function FamilyHistoryForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, getValues, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId, nurseOperationId } = useParams<{
    employeeId: string;
    examinationId: string;
    nurseOperationId: string;
  }>();
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveBodyMeasurements({ ...values, employeeId, examinationId, nurseOperationId });
          showMessage('Ekleme işlemi başarılı.', 'success');
        } else {
          await updateBodyMeasurements({ id: data.id, ...values, employeeId, examinationId, nurseOperationId });
          showMessage('Güncelleme işlemi başarılı.', 'success');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error');
        onClose(false);
      }
    },
    [data, onClose, showMessage, employeeId, examinationId, nurseOperationId]
  );

  const heightValue = getValues('lengthInCm');
  const weightValue = getValues('weightInKg');
  const waistValue = getValues('waistInCm');
  const hipValue = getValues('hipInCm');

  useEffect(() => {
    if (!waistValue || !hipValue) {
      setValue('waistHipRatio', '');
    } else {
      setValue('waistHipRatio', waistValue / hipValue);
    }

    if (!heightValue || !weightValue) {
      setValue('bmi', '');
    } else {
      setValue('bmi', Math.round(100 * (weightValue / (heightValue / 100) ** 2)) / 100);
    }

    // TODO TextField will be disabled for Waist / Hip and BMI ratio
  }, [heightValue, weightValue, waistValue, hipValue, setValue]);

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    setValue('measurementTime', timeToInputFormat());
    await trigger();
    await defer();
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      await trigger();
    } else {
      reset();
      await defer();
      setValue('measurementDate', dateToInputFormat(new Date()));
      setValue('measurementTime', timeToInputFormat());
      await trigger();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 550 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Vücut Ölçüm Düzenleme' : 'Vücut Ölçüm Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={6} sm={6} lg={6}>
                <DateInput type="date" name="measurementDate" label="Ölçüm Tarihi" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <DateInput type="time" name="measurementTime" label="Ölçüm Saati" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="lengthInCm" label="Boy(cm)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="weightInKg" label="Kilo(kg)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField name="bmi" label="VKİ" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="waistInCm" label="Bel(cm)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="hipInCm" label="Kalça(cm)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField name="waistHipRatio" label="Bel / Kalça" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
