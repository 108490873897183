import { createContext } from 'react';

import { DiagnosisModel } from 'api/hs/examination/diagnosis';

export interface DiagnosisContextType {
  diagnosis: DiagnosisModel[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: DiagnosisModel;

  setSelectedData(data: DiagnosisModel): void;
}

export const DiagnosisContextContext = createContext<DiagnosisContextType>(null);
