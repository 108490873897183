import { dateToInputFormat } from '../../../../utils';
import { stringify } from '../../utils';

export enum WidgetType {
  REPORT_REPORT = 'REPORT_REPORT',
  REFERRAL = 'REFERRAL',
  PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
  VACCINE = 'VACCINE',
  TRAINING = 'TRAINING',
  EMPLOYEE = 'EMPLOYEE',
  PREGNANCY = 'PREGNANCY',
  ACTION = 'ACTION',
  WORK_EQUIPMENT = 'WORK_EQUIPMENT',
  PPE = 'PPE',
  NEAR_MISS = 'NEAR_MISS',
  ACCIDENT = 'ACCIDENT',
  LOST_DAY = 'LOST_DAY',
}

export enum WidgetTypeStatus {
  ALL = 'ALL',
  MINE = 'MINE',
  ACTIVE = 'ACTIVE',
}

export interface WidgetVisibilityItem {
  id: string;
  widgetType: WidgetType;
}

export interface WidgetVisibility {
  widgetType: WidgetType;
}

export interface PregnancyCounts {
  pregnant: number;
  breastfeeding: number;
  maternityLeave: number;
}

export interface ActionCounts {
  actionActive: number;
  actionPlannedPassed: number;
  actionCompleted: number;
}

export interface AccidentCounts {
  accidentCount: number;
  eccidentEmployeeCount: number;
  lostWorkDaySum: number;
}

export interface PregnancyWidgetItem {
  employeeId: string;
  name: string;
  nid: string;
  birthDate: Date;
  bloodTypeIncompatibility: boolean;
  multipleBirth: boolean;
}

export interface ActionWidgetItem {
  name: string;
  date: string;
  referenceTableId: string;
}

export interface PeriodicExaminationWidgetItem {
  employeeId: string;
  name: string;
  date: Date;
}

export enum PregnancyWidgetType {
  PREGNANT = 'PREGNANT',
  BREASTFEEDING = 'BREASTFEEDING',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
}

export enum ActionWidgetType {
  ACTION_ACTIVE = 'ACTION_ACTIVE',
  ACTION_PLANNED_PASSED = 'ACTION_PLANNED_PASSED',
  ACTION_COMPLETED = 'ACTION_COMPLETED',
}

export enum EmployeeWidgetType {
  EMPLOYEE_ACTIVE = 'EMPLOYEE_ACTIVE',
  EMPLOYEE_NEW = 'EMPLOYEE_NEW',
}

export interface VaccineCounts {
  vaccineNearlied: number;
  vaccineMissed: number;
}

export interface VaccineWidgetItem {
  name: string;
  vaccineName: string;
  order: number;
  date: Date;
  employeeId: string;
}

export interface PeriodicExaminationCounts {
  periodicExamNearlied: number;
  periodicExamMissed: number;
}

export interface ReportedEmployeeCounts {
  restReportedEmployee: number;
  restReportEndToday: number;
}

export interface TrainingCounts {
  trainingNearlied: number;
  trainingMissed: number;
}

export interface ReportedEmployeeItem {
  employeeId: string;
  name: string;
  startDate: Date;
  endDate: Date;
}

export interface ReferraledEmployeeCounts {
  referralEmployee: number;
  referralMissed: number;
}

export interface ReferraledEmployeeResult {
  content: ReferraledEmployeeItem[];
}

export interface ReferraledEmployeeItem {
  name: string;
  referralDate: Date;
  description: string;
  hospitalType: string;
  noteToPhysician: string;
  referralSectionDefinitionName: string;
  employeeId: string;
}

export class DateRange {
  public startDate: string = null;
  public endDate: string = null;

  constructor(startDate: Date, endDate: Date) {
    this.startDate = dateToInputFormat(startDate);
    this.endDate = dateToInputFormat(endDate);
  }

  toString(): string {
    return stringify(this);
  }
}
