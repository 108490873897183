import { createContext } from 'react';

import { WorkEquipmentTypeDefinitionDto } from '../../../api/client';

export interface TypeDefinitionContextType {
  workEquipmentTypeDefinition: WorkEquipmentTypeDefinitionDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: WorkEquipmentTypeDefinitionDto;

  setSelectedData(data: WorkEquipmentTypeDefinitionDto): void;
}

export const TypeDefinitionContext = createContext<TypeDefinitionContextType>(null);
