/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CheckNumberRequest = {
    protocolSourceType?: CheckNumberRequest.protocolSourceType;
    yearPrefix?: number;
    number?: number;
}

export namespace CheckNumberRequest {

    export enum protocolSourceType {
        PHYSICIAN = 'PHYSICIAN',
        NURSE = 'NURSE',
    }


}
