import { Chip, CircularProgress, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { Edit, Timer } from '@material-ui/icons';
import FileCopy from '@material-ui/icons/FileCopy';
import React, { useContext, useEffect, useState } from 'react';

import { deleteById, getById } from '../../../api/hs/dashboard/event/event';
import { EventPayload } from '../../../api/hs/dashboard/event/event.payload';
import Button from '../../../Common/Button';
import { AlertContext } from '../../../context/alert.context';
import { timePrettier } from '../../../utils';
import Header from '../../Header';

export default ({ eventId, reload, open, close }) => {
  const [eventDetail, setEventDetail] = useState<EventPayload>();
  const { showMessage } = useContext(AlertContext);

  useEffect(() => {
    if (eventId) {
      getById(eventId)
        .then(setEventDetail)
        .catch(() => {
          showMessage('Bir hata oluştu', 'warning', 'info');
          close();
        });
    }
  }, [close, eventId, showMessage]);

  const handleDelete = async () => {
    try {
      if (window.confirm('Silmek istediğinizden emin misiniz?')) {
        await deleteById(eventId);
        showMessage('Etkinlik silindi', 'info', 'info');
        reload();
        close();
      }
    } catch (e) {
      showMessage('Bir hata oluştu!', 'error', 'info');
    }
  };

  const showEventDialog = () => {
    close();
    open();
  };

  return eventDetail ? (
    <>
      <Header title={'Etkinlik Bilgileri'} icon={<FileCopy />} fabIcon={<Edit className="text-xx-large" />} fabOnClick={showEventDialog} />
      <DialogContent>
        <Grid container className={'mt-4'}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5" color="secondary">
              {eventDetail.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Chip
              style={{ float: 'right' }}
              icon={<Timer />}
              label={timePrettier(eventDetail.startTime) + ' - ' + timePrettier(eventDetail.endTime)}
            />
          </Grid>
          <Grid item xs={12}>
            <p>{eventDetail.description}</p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="pb-2 pr-2">
        <Button color="error" onClick={handleDelete}>
          Sil
        </Button>
        <Button color="default" onClick={close}>
          Kapat
        </Button>
      </DialogActions>
    </>
  ) : (
    <CircularProgress />
  );
};
