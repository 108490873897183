import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';

import DateInput from '../Form/DateInput';

export default function ProfessionalBackgroundForm() {
  const { exposures } = useContext(SelectOptionsContext);

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <TextField label="İş Yeri Adı" name="companyName" required />
      </Grid>
      <Grid item md={6}>
        <TextField label="İş Kolu" name="sector" />
      </Grid>
      <Grid item md={6}>
        <TextField label="İş İstasyonu" name="station" />
      </Grid>
      <Grid item md={6}>
        <DateInput type="date" label="İşe Giriş Tarihi" name="hireDate" />
      </Grid>
      <Grid item md={6}>
        <DateInput type="date" label="İşten Çıkış Tarihi" name="quitDate" />
      </Grid>
      <Grid item md={12}>
        <TextField label="Yaptığı İş" name="job" />
      </Grid>
      <Grid item md={12}>
        <AutoComplete name="exposures" options={exposures} label="Maruziyet" renderOption="checkbox" />
      </Grid>
    </Grid>
  );
}
