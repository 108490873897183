import { modelDataMatcher } from 'utils';

export class UsersModel {
  public id: string = null;
  public email: string = null;
  public name: string = null;
  public string: string = null;

  constructor(data: Partial<UsersModel> = {}) {
    modelDataMatcher(data, this);
  }
}
