import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';

import { saveEmployeeImported } from 'api/hs/file-uploads/employee-imported-files';
import Button from 'Common/Button';
import { DialogContext } from 'context/dialog.context';
import { useXhr } from 'utils';

import { ProfileUploadContext } from '../../profile-file-upload-context';

export default function Add() {
  const { openFileDialog } = useContext(DialogContext);
  const { employeeId } = useParams<{ employeeId: string }>();
  const { reload } = useContext(ProfileUploadContext);

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => saveEmployeeImported(employeeId, file)));
    },
    'Dosyaları yüklendi',
    err => err,
    [employeeId]
  );

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!files) {
      return;
    }

    await uploadFiles(files);
    await reload();
  }, [openFileDialog, reload, uploadFiles]);

  return (
    <>
      <header className="text-right mr-1">
        <Button onClick={uploadFile} variant="outlined" startIcon={<AddIcon />} color="primary" className="ml-1">
          Dosya Ekle
        </Button>
      </header>
    </>
  );
}
