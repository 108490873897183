import React from 'react';
import { Route, Switch } from 'react-router';

import CommitteeMeetings from './CommitteeMeetings';
import CommitteeMeetingsDetail from './CommitteeMeetingsDetail';
import CommitteeMembers from './CommitteeMembers';

export default function CommitteeMenuList() {
  return (
    <Switch>
      <Route exact path="/committee/committee-meetings/:committeeMeetingId">
        <CommitteeMeetingsDetail />
      </Route>
      <Route exact path="/committee/committee-members">
        <CommitteeMembers />
      </Route>
      <Route path="/committee/committee-meetings">
        <CommitteeMeetings />
      </Route>
    </Switch>
  );
}
