import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';

import ScrollableTabs from 'Common/ScrollableTabs';

import PositionIbysWorkCondition from './PositionIbysWorkCondition';
import WorkingPositionDefinitions from './WorkingPositionDefinitions';
import WorkingTimeDefinitions from './WorkingTimeDefinitions';

const TABS = [
  {
    title: 'Fiziksel Çalışma Koşulları',
    icon: 'icon-clipboard-check',
    Component: PositionIbysWorkCondition,
  },
  {
    title: 'İş ekipmanları',
    icon: 'icon-heart-pulse',
    Component: WorkingPositionDefinitions,
  },
  {
    title: ' Çalışma Şekli',
    icon: 'icon-seat-individual-suite',
    Component: WorkingTimeDefinitions,
  },
];

export default function IbysSection() {
  const [activeTab, setActiveTab] = useState('0');
  const changeTab = useCallback((e, value) => setActiveTab(value), []);

  return (
    <TabContext value={activeTab}>
      <AppBar position="static" color="default" style={{ maxWidth: '100%' }}>
        <ScrollableTabs onChange={changeTab} indicatorColor="primary" textColor="primary">
          {TABS.map(({ title }, index) => (
            <Tab key={index} label={title} value={index + ''} />
          ))}
        </ScrollableTabs>
      </AppBar>
      {TABS.map(({ Component }, index) => (
        <TabPanel key={index} value={index + ''}>
          <Component />
        </TabPanel>
      ))}
    </TabContext>
  );
}
