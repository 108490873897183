import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React, { useContext } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';

export default function PersonalInfo() {
  const { bloodTypes, bloodDonations, genders, maritialStatuses, educationStatuses } = useContext(SelectOptionsContext);

  return (
    <>
      <ListItem>
        <ListItemText primary="Kişisel Bilgiler" />
      </ListItem>
      <Divider className="mb-1" />
      <ListItem className="py-0">
        <TextField type="number" className="mr-1" label="Yaş Min" name="ageMin" />
        <TextField type="number" className="ml-1" label="Yaş Max" name="ageMax" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="bloodTypeIds" options={bloodTypes} renderOption="checkbox" label="Kan Grubu" />
      </ListItem>
      <ListItem className="py-0">
        <Select name="bloodDonation" options={bloodDonations} label="Kan Bağışı" emptyOption="Tümü" />
      </ListItem>
      <ListItem className="py-0">
        <Select name="gender" options={genders} label="Cinsiyet" emptyOption="Tümü" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="maritalStatusIds" options={maritialStatuses} renderOption="checkbox" label="Medeni Durum" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="educationalStatusIds" options={educationStatuses} renderOption="checkbox" label="Eğitim Durum" />
      </ListItem>
    </>
  );
}
