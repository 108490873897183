import {
  Divider,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { findByKey } from '@thalesrc/js-utils';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  getImmunities,
  getImmunityDescription,
  saveImmunity,
  saveImmunityDescription,
  updateImmunity,
  updateImmunityDescription,
} from 'api/hs/examination/immunity';
import { GetVaccinesResult } from 'api/hs/resource';
import Button from 'Common/Button';
import LoadingWrapper from 'Common/LoadingWrapper';
import { ResourceContext } from 'context/resource.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useFetch, useXhr } from 'utils';

const useStyles = makeStyles(theme => ({
  fieldInput: {
    alignItems: 'flex-end',
  },
  firstCol: {
    whiteSpace: 'nowrap',
    width: '10%',
  },
}));

export default function Immunity() {
  const classes = useStyles();
  const { vaccines } = useContext(ResourceContext);
  const { immunityStatuses } = useContext(SelectOptionsContext);
  const [list, setList] = useState<(GetVaccinesResult & { isEditing: boolean; value: number; valueText: string })[]>([]);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const [loading, setLoading] = useState(true);
  const { data, fetch } = useFetch(() => getImmunities(employeeId, examinationId), [], { setLoading });
  const { data: descData, fetch: descFetch } = useFetch(() => getImmunityDescription(employeeId, examinationId), null, { setLoading });
  const [selectValue, setSelectValue] = useState<number | string>('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const edit = useCallback(
    (id: string, value: number) => () => {
      setList(list.map(item => ({ ...item, isEditing: item.id === id })));
      setSelectValue(value || '');
    },
    [list]
  );

  const cancel = useCallback(() => {
    setList(list.map(item => ({ ...item, isEditing: false })));
  }, [list]);

  const saveImmunityReq = useXhr(
    async (id: string) => {
      setLoading(true);
      const existing = findByKey(data, 'vaccineDefinitionId', id as any);

      if (existing) {
        await updateImmunity(existing.id, { immunityStatusDefinitionId: selectValue as any });
      } else {
        await saveImmunity({ employeeId, examinationId, immunityStatusDefinitionId: selectValue as number, vaccineDefinitionId: id });
      }

      await fetch();
    },
    'Bağışıklık Kaydedildi',
    'Bağışıklık kaydedilirken bir sorun oluştu',
    [employeeId, examinationId, selectValue, data, fetch]
  );

  const saveDescriptionReq = useXhr(
    async () => {
      setLoading(true);

      if (descData) {
        await updateImmunityDescription(descData.id, { description: descriptionValue });
      } else {
        await saveImmunityDescription({ employeeId, examinationId, description: descriptionValue });
      }

      await descFetch();
    },
    'Bağışıklık açıklaması kaydedildi',
    'Bağışıklık açıklaması kaydedilirken bir sorun oluştu',
    [descData, employeeId, examinationId, descriptionValue]
  );

  const handleSelectChange = useCallback(({ target: { value } }: ChangeEvent<{ value: any }>) => setSelectValue(value), []);
  const handleDescChange = useCallback(({ target: { value } }: ChangeEvent<{ value: any }>) => setDescriptionValue(value), []);

  useEffect(() => {
    setList(
      vaccines.map(vaccine => ({
        ...vaccine,
        isEditing: false,
        value: findByKey(data, 'vaccineDefinitionId', vaccine.id)?.immunityStatusDefinitionId || 145,
        get valueText() {
          return findByKey(immunityStatuses, 'value', this.value)?.text;
        },
      }))
    );
  }, [vaccines, data, immunityStatuses]);

  useEffect(() => {
    setDescriptionValue(descData?.description || '');
  }, [descData]);

  return (
    <LoadingWrapper loading={loading}>
      <Typography variant="h6" className="pl-1 pb-1">
        Bağışıklama
      </Typography>
      <Divider />
      <Table>
        <TableBody>
          {list.map(({ id, name, isEditing, value, valueText }) => (
            <TableRow key={id}>
              <TableCell className={classes.firstCol}>{name}</TableCell>
              <TableCell>
                {isEditing ? (
                  <Select value={selectValue} onChange={handleSelectChange}>
                    {immunityStatuses.map(({ text, value: statusValue }) => (
                      <MenuItem key={statusValue} value={statusValue}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  valueText
                )}
              </TableCell>
              <TableCell className="p-0 text-right">
                {isEditing ? (
                  <>
                    <IconButton className="mr-1" onClick={() => saveImmunityReq(id)}>
                      <CheckIcon style={{ color: 'green' }} />
                    </IconButton>
                    <IconButton onClick={cancel}>
                      <CloseIcon style={{ color: 'red' }} />
                    </IconButton>
                  </>
                ) : (
                  <IconButton onClick={edit(id, value)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <section className="px-2 pb-2">
        <TextField
          value={descriptionValue}
          onChange={handleDescChange}
          fullWidth
          label="Açıklama"
          className="my-3"
          variant="outlined"
          multiline
          rows={4}
          InputProps={{
            className: classes.fieldInput,
            endAdornment: (
              <Button className="ml-2" variant="contained" color="primary" onClick={saveDescriptionReq}>
                Kaydet
              </Button>
            ),
          }}
        />
      </section>
    </LoadingWrapper>
  );
}
