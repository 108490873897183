import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { FineKinneyHazardDamageDefinitionControllerService } from 'api/client';
import { FineKinneyHazardDamageDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import FineKinneyDamageDefinitionForm from './Forms/FineKinneyDamageDefinitionForm';

export default function FineKinneyDamageDefinition() {
  return (
    <BaseDefinitionPage<FineKinneyHazardDamageDefinitionDto>
      listLabelPropName="label"
      fetchData={FineKinneyHazardDamageDefinitionControllerService.getAllFirmFineKinneyDamageCategoryDefinitions}
      FormDialog={FineKinneyDamageDefinitionForm}
      Icon={<FlashOnIcon />}
      searchName="label"
      noDelete={true}
      noSave={true}
    />
  );
}
