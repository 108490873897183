import { CardHeader, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { throws } from 'assert';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { UserControllerService } from 'api/client';
import { changePassword } from 'api/hs/authentication/change-password';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';

import { updateUserPasswordChange } from '../../../api/hs/users';

const useStyles = makeStyles({
  root: {
    maxWidth: 450,
  },
});

export default function PasswordChange() {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async values => {
      const formData = formMethods.getValues();
      if (formData.newPassword === formData.confirmNewPassword) {
        try {
          await updateUserPasswordChange({ oldPassword: formData.oldPassword, newPassword: formData.newPassword });
          showMessage('Şifre değiştirme işlemi başarılı.', 'success', 'info');
        } catch (e) {
          showMessage(e, 'error', 'info');
        }
      } else {
        showMessage('Girdiğiniz şifre birbiriyle uyuşmamaktadır.', 'error', 'info');
      }
    },
    [showMessage, formMethods]
  );

  return (
    <div className="mt-1 mr-1">
      <Card className={classes.root}>
        <CardHeader style={{ backgroundColor: '#2196f3', color: 'white' }} title="Şifre Değiştir" />
        <CardContent>
          <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
            <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <Grid container spacing={1}>
                <Grid item md={12} sm={12} lg={12}>
                  <TextField type="password" name="oldPassword" label="Eski Şifre" />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <TextField type="password" name="newPassword" label="Yeni Şifre" />
                </Grid>
                <Grid item md={6} sm={6} lg={6}>
                  <TextField type="password" name="confirmNewPassword" label="Yeni Şifre Tekrar" />
                </Grid>
                <Grid item md={12} className="text-right">
                  <Button variant="contained" type="submit" color="primary" className="mr-2">
                    Şifre Değiştir
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </div>
  );
}
