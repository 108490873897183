import React, { useState } from 'react';

import { PpeStocksContentResult } from 'api/hs/ppe/ppe-stocks';

import PpeStocks from './ppe-stock';
import { PpeStocksContext } from './ppe-stocks.context';

export default function PpeStocksIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeStocksContentResult>(null);

  return (
    <PpeStocksContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <PpeStocks />
    </PpeStocksContext.Provider>
  );
}
