import { Paper } from '@material-ui/core';
import React from 'react';

import Analysis from './Analysis';

export default function AnalysisPage() {
  return (
    <div className="p-2">
      <Paper className="p-2" elevation={3}>
        <Analysis />
      </Paper>
    </div>
  );
}
