import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  getPositionsWorkConditions,
  PositionsIbysWorkConditionsArrayPayload,
  PositionsIbysWorkConditionsPayload,
  PositionsIbysWorkConditionsResult,
  updatePositionsWorkConditions,
} from 'api/hs/positions-ibys-work-conditions';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import PhysicalWorkCondition from '../../PhysicalWorkCondition/PhysicalWorkCondition';

interface Props<T extends { id: string | number }> {
  open: boolean;

  onClose(success: boolean): void;

  data: T;
}

export default function PositionEditPhysicalWorkConditionForm<T extends { id: string | number }>({ onClose, data }: Props<T>) {
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async (values: PositionsIbysWorkConditionsArrayPayload) => {
      try {
        const workConditionArray: PositionsIbysWorkConditionsPayload[] = [];

        values.electricityDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'ELECTRICITY',
          });
        });

        values.noiseDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'NOISE',
          });
        });

        values.airDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'AIR',
          });
        });

        values.biologicalFactorsDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'BIOLOGICAL_FACTORS',
          });
        });

        values.dustRegulationDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'DUST_REGULATIONS',
          });
        });

        values.physicalEnvironmentDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'PHYSICAL',
          });
        });

        values.chemicalMatterDefinitions.forEach(value => {
          workConditionArray.push({
            code: value.toString(),
            physicalWorkCondition: 'CHEMICAL',
          });
        });
        await updatePositionsWorkConditions(data.id.toString(), workConditionArray);
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      const positionsIbysWorkConditionDefinitions: PositionsIbysWorkConditionsResult[] = await getPositionsWorkConditions(
        data.id.toString()
      );
      const chemicalMatterDefinitions: number[] = [];
      const electricityDefinitions: number[] = [];
      const airDefinitions: number[] = [];
      const noiseDefinitions: number[] = [];
      const biologicalFactorsDefinitions: number[] = [];
      const dustRegulationDefinitions: number[] = [];
      const physicalEnvironmentDefinitions: number[] = [];

      positionsIbysWorkConditionDefinitions.forEach(value => {
        if (value.physicalWorkCondition === 'CHEMICAL') {
          chemicalMatterDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'ELECTRICITY') {
          electricityDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'AIR') {
          airDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'NOISE') {
          noiseDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'BIOLOGICAL_FACTORS') {
          biologicalFactorsDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'DUST_REGULATIONS') {
          dustRegulationDefinitions.push(value.code);
        }
        if (value.physicalWorkCondition === 'PHYSICAL') {
          physicalEnvironmentDefinitions.push(value.code);
        }
      });
      setValue('chemicalMatterDefinitions', chemicalMatterDefinitions);
      setValue('electricityDefinitions', electricityDefinitions);
      setValue('airDefinitions', airDefinitions);
      setValue('noiseDefinitions', noiseDefinitions);
      setValue('biologicalFactorsDefinitions', biologicalFactorsDefinitions);
      setValue('dustRegulationDefinitions', dustRegulationDefinitions);
      setValue('physicalEnvironmentDefinitions', physicalEnvironmentDefinitions);
      trigger();
    }
  }, [data, setValue, trigger]);

  return (
    <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
      <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <PhysicalWorkCondition required={false} />
        <Grid item md={12} className="text-right">
          <Button variant="contained" type="submit" color="primary" className="mr-2">
            {data?.id ? 'Güncelle' : 'Kaydet'}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
}
