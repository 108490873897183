import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import TablePagination from 'Common/TablePagination';

import { Icd10MedicineContextType, Icd10MedicineListContext } from '../Icd10.context';

export default function ListIndex<T extends { id: string | number }>() {
  const { data, loading, ListTableBody, ListTableHead, fetch } = useContext<Icd10MedicineContextType<T>>(Icd10MedicineListContext);

  const changeSize = useCallback(
    (s: number) =>
      fetch({
        size: s,
      }),
    [fetch]
  );
  const changePage = useCallback(
    (p: number) =>
      fetch({
        page: p,
      }),
    [fetch]
  );

  return (
    <IndicatorWrapper data={data} errorMessage="Gösterilecek Kayıt Bulunamadı" loading={loading}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <ListTableHead />
          </TableHead>
          <TableBody>
            {data?.content?.map((item, index) => (
              <ListTableBody key={index} item={item} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5}>
                <TablePagination
                  size={data?.pageable?.pageSize}
                  onSizeChange={changeSize}
                  page={data?.pageable?.pageNumber || 1}
                  count={data?.totalPages - 1}
                  onPageChange={changePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </IndicatorWrapper>
  );
}
