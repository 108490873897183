import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext, useState } from 'react';

import { AnalysisControllerService } from 'api/client';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { downlaodFile, openFile, useFetch, useXhr } from 'utils';

import { saveAnalysisFile } from '../../../../../../api/hs/examination/analysis';
import Button from '../../../../../../Common/Button';
import { DialogContext } from '../../../../../../context/dialog.context';

interface Props {
  analysisId: string;
}
export default function AnalysisFiles({ analysisId }: Props) {
  const [loading, setLoading] = useState(true);
  const { openFileDialog, openWarnDialog } = useContext(DialogContext);

  const { data, fetch } = useFetch(() => AnalysisControllerService.getAnalysisFiles1(analysisId), [], { setLoading });

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await AnalysisControllerService.analysisFileDelete1(id);
      await fetch();
      //  await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => saveAnalysisFile(analysisId, file)));
      await fetch();
    },
    'Dosyaları yüklendi',
    err => err,
    [analysisId]
  );

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({
      accept: [
        'video/mov',
        'audio/mp3',
        'audio/mp4',
        'audio/mp3',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
      title: 'Dosya Yükle',
    });

    if (!files) {
      return;
    }

    await uploadFiles(files);
    await fetch();
  }, [openFileDialog, fetch, uploadFiles]);

  return (
    <div style={{ width: '100%' }}>
      <header className="text-right mb-1 mr-1">
        <Button onClick={uploadFile} variant="contained" startIcon={<AddIcon />} color="primary" className="ml-1">
          Dosya Ekle
        </Button>
      </header>
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Dosya Bulunamadı.">
        <List key="analysis-files">
          {data?.map((item, index) => (
            <Fragment key={index}>
              <ListItem key={index}>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-document" />
                </ListItemAvatar>
                <ListItemText primary={item?.originalFileName} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => removeFileShow(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => openFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end"
                    aria-label="delete">
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </IndicatorWrapper>
    </div>
  );
}
