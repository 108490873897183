import React, { useState } from 'react';

import { getPeriodicExaminationConfigurations } from 'api/hs/examination/periodic-examination-configurations';
import { Employee } from 'model';

import { dateDisplayFormat, useAsyncEffect } from '../../../utils';
import { InfoListItem } from './info-list-item.model';
import InfoList from './InfoList';

const PERSONAL_INFO_LIST: InfoListItem[] = [
  {
    label: 'Muayene Sıklığı',
    Render({ employee: { examinationFrequencyInMonth, id } }) {
      const [frequencyInMonth, setFrequencyInMonth] = useState(examinationFrequencyInMonth || '');

      useAsyncEffect(async () => {
        if (!id) {
          return;
        }
        if (!examinationFrequencyInMonth) {
          const res = await getPeriodicExaminationConfigurations(id);
          setFrequencyInMonth(res.examinationFrequencyInMonth);
        } else {
          setFrequencyInMonth(examinationFrequencyInMonth);
        }
      }, [examinationFrequencyInMonth]);
      return <>{frequencyInMonth}</>;
    },
  },
  {
    label: 'Sonraki Muayene',
    Render({ employee: { nextExaminationDate, id } }) {
      const [nextExaminationDateData, setNextExaminationDateData] = useState(nextExaminationDate || '');

      useAsyncEffect(async () => {
        if (!id) {
          return;
        }
        if (!nextExaminationDate) {
          const res = await getPeriodicExaminationConfigurations(id);
          setNextExaminationDateData(res.nextExaminationDate);
        } else {
          setNextExaminationDateData(nextExaminationDate);
        }
      }, [nextExaminationDate]);
      return <>{dateDisplayFormat(nextExaminationDateData)}</>;
    },
  },
];

interface Props {
  employee: Employee;
}

export default function PersonalInfo({ employee }: Props) {
  return <InfoList title="Periyodik Muayene Ayarları" list={PERSONAL_INFO_LIST} employee={employee} />;
}
