/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentReviewerDto } from '../models/AccidentReviewerDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentReviewerControllerService {

    /**
     * @param accidentId
     * @returns AccidentReviewerDto OK
     * @throws ApiError
     */
    public static getReviewers(
        accidentId: string,
    ): CancelablePromise<Array<AccidentReviewerDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviewers`,
        });
    }

    /**
     * @param accidentId
     * @param requestBody
     * @returns AccidentReviewerDto OK
     * @throws ApiError
     */
    public static updateAllAccidentReviewers(
        accidentId: string,
        requestBody: Array<AccidentReviewerDto>,
    ): CancelablePromise<Array<AccidentReviewerDto>> {
        return __request({
            method: 'PUT',
            path: `/accidents/${accidentId}/reviewers`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param requestBody
     * @returns AccidentReviewerDto OK
     * @throws ApiError
     */
    public static addReviewer(
        accidentId: string,
        requestBody: AccidentReviewerDto,
    ): CancelablePromise<AccidentReviewerDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviewers`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteReviewer(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/reviewers/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns AccidentReviewerDto OK
     * @throws ApiError
     */
    public static updateReviewer(
        id: string,
        requestBody: AccidentReviewerDto,
    ): CancelablePromise<AccidentReviewerDto> {
        return __request({
            method: 'PATCH',
            path: `/accidents/reviewers/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param auth
     * @param authenticationInfo
     * @returns AccidentReviewerDto OK
     * @throws ApiError
     */
    public static validateReviewer(
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewerDto> {
        return __request({
            method: 'GET',
            path: `/accidents/reviewers`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

}