import { Button, Dialog as MUIDialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';

import { getNurseOperationDefinitions } from 'api/hs/enum/get-nurse-operation-definitions';
import { saveDefaultNurseOperation } from 'api/hs/nurse-operations/default-nurse-operations';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { ProtocolNumberContext } from 'context/protocol-number.context';
import { dateToInputFormat, useAsyncEffect, useFetch } from 'utils';

import DateInput from '../../../../../../../Common/Form/DateInput';
import { DefaultNurseOperationContext } from '../../nurse.operation.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
  protocolNoText: {
    fontSize: '12px',
    color: 'red',
  },
}));

export default function FormDialogPolyclinicExamOpinions() {
  const classes = useStyles();
  const { setNewDialogOpened, reload, newDialogOpened } = useContext(DefaultNurseOperationContext);
  const { setValue, getValues, trigger, ...methods } = useForm();
  const { employeeId } = useParams<{ employeeId: string }>();
  const { showMessage } = useContext(AlertContext);
  const { ProtocolNextNumber, showProtocolNumber } = useContext(ProtocolNumberContext);
  const history = useHistory();
  const { pathname } = useLocation();

  const submitAction = useCallback(
    (type: number) => () => {
      const values = getValues();

      saveDefaultNurseOperation({
        employeeId,
        nurseOperationDefinitionId: type,
        date: values.date,
        protocolNumber: values.protocolNo
          ? {
              yearPrefix: new Date().getFullYear(),
              number: +values.protocolNo,
            }
          : null,
      })
        .then(res => {
          history.push(`${pathname}/${res.id}`);
          reload();
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        })
        .catch(err => showMessage(err, 'error', 'info'));
      setNewDialogOpened(false);
    },
    [getValues, showMessage, employeeId, reload, setNewDialogOpened, history, pathname]
  );

  const cancel = useCallback(() => {
    setNewDialogOpened(false);
  }, [setNewDialogOpened]);

  useAsyncEffect(async () => {
    if (!newDialogOpened) {
      return;
    }
    await defer();
    setValue('date', dateToInputFormat(new Date()));
    trigger();
  }, [trigger, newDialogOpened]);

  const { data } = useFetch(async () => await getNurseOperationDefinitions(), []);

  useAsyncEffect(() => {
    ProtocolNextNumber('NURSE');
  }, []);

  return (
    <MUIDialog open={newDialogOpened} fullWidth maxWidth="xs" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Sağlık Personel İşlemi Oluştur</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, getValues, setValue, trigger }}>
          <form noValidate id="new-nurse-operation-form">
            <Grid container spacing={1}>
              <Grid item md={6} sm={6} lg={6}>
                <DateInput type="date" required name="date" label="Muayene Tarihi" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField type="number" name="protocolNo" label="Protokol No" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <span className={classes.protocolNoText}>
                  *Protokol Numarası boş bırakıldığında oluşacak numara:{' '}
                  {showProtocolNumber?.yearPrefix + '/' + String(showProtocolNumber?.number).padStart(4, '0')}
                </span>
              </Grid>
              <Grid item md={12} sm={12} lg={12} className="text-center">
                <Button disabled color="default" type="button" style={{ width: '100%', fontWeight: 'bold' }}>
                  İşlem Tipi Giriniz
                </Button>
              </Grid>
              {data.map((item, index) => (
                <Grid key={index} item md={12} sm={12} lg={12} className="text-center">
                  <Button key={index} onClick={submitAction(item?.id)} type="button" style={{ width: '100%' }}>
                    {item?.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </MUIDialog>
  );
}
