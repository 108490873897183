import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ReferralViewControllerService, ReferralViewRequest } from '../../api/client';
import { FormRef } from '../../Common/Form/Form';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function Accident() {
  const [chartData, setChartData] = useState<ReferralViewRequest[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const { accidentReviewBehaviorDefinition } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request: ReferralViewRequest = {
      filter: {
        dateMin: values?.minDate + 'T00:00:00',
        dateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'referralDate',
      },
    };
    const res = await ReferralViewControllerService.getReferralListFiltered(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Sevk İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && dataDisplayOption != null && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Sevklere Göre Şirket Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="referral-company-report"
                  data={chartData}
                  dateFieldName="referralDate"
                  selectorFieldByName="companyName"
                  xAxesTitle="Şirketler"
                  xAxesText="Tarih"
                  yAxesText="Sevk Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Sevklere Göre Departman Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="rest-report-department-report"
                  data={chartData}
                  dateFieldName="referralDate"
                  selectorFieldByName="departmentName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Tarih"
                  yAxesText="Sevk Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>

            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Sevklere Göre Hastane Tipi Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="rest-report-reason-name-report"
                  data={chartData}
                  dateFieldName="referralDate"
                  selectorFieldByName="hospitalTypeName"
                  xAxesTitle="Hastaneler"
                  xAxesText="Tarih"
                  yAxesText="Sevk Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Sevklere Göre Bölüm Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="examination-examinationType-report"
                  data={chartData}
                  dateFieldName="referralDate"
                  selectorFieldByName="referralSectionDefinitionName"
                  xAxesTitle="Bölümler"
                  xAxesText="Tarih"
                  yAxesText="Sevk Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
