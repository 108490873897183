import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import TableWithPagination from 'Common/Table/WithPagination/TableWithPagination';

import { examinationGetList, updateExaminations } from '../../../api/hs/Examinations/examinations';
import { AlertContext } from '../../../context/alert.context';
import { useAsyncEffect } from '../../../utils';

export default function ListTable() {
  const { loading, data } = useContext(HSTableContext);
  const { showMessage } = useContext(AlertContext);

  useAsyncEffect(async () => {
    const getList: string[] = [];

    data?.forEach(x => {
      if (x?.ibysExaminationView?.requestState === 'NOT_SENT' || x?.ibysExaminationView?.requestState === 'ON_STAND_BY') {
        if (x.ibysExaminationView.examIdOnIbysServer) getList.push(x.ibysExaminationView.examIdOnIbysServer);
      }
    });

    /*if (getList.length > 0) {
      const getValue = await examinationGetList(getList);
      for (const [key, value] of Object.entries(getValue)) {
        if (value.processResult === 200) {
          await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
            processResult: 'SUCCESSFUL',
            ibysRequestState: 'SENT',
            processResultMessage: value.processResultMessage,
          });
        } else if (value.processResult !== 0) {
          await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
            processResult: 'UNSUCCESSFUL',
            ibysRequestState: 'INCORRECT_REQUEST',
            processResultMessage: value.processResultMessage,
          });
        } else {
          // processResult == 0
          if (value.savingResult === 200 || value.savingResult === 0) {
            await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
              processResult: 'CONTINUES',
              ibysRequestState: 'ON_STAND_BY',
            });
          } else {
            await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
              processResult: 'UNSUCCESSFUL',
              ibysRequestState: 'INCORRECT_REQUEST',
              processResultMessage: value.processResultMessage,
            });
          }
        }
      }
    }
    showMessage('Bakanlıktan güncel bilgiler alındı.', 'success', 'info');
     */
  }, [data, showMessage]);

  return (
    <div className="m-1">
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Muayene Kaydı Bulunamadı">
        <TableWithPagination />
      </IndicatorWrapper>
    </div>
  );
}
