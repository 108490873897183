import React, { useState } from 'react';

import { PpeOutputsContentResult } from '../../api/hs/ppe/ppe-outputs';
import PpeOutputsPage from './ppe-output';
import { PpeOutputsContext } from './ppe-outputs.context';

export default function PpeStocksIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [newReturnForm, setNewReturnForm] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeOutputsContentResult>(null);

  return (
    <PpeOutputsContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        newReturnForm,
        setNewReturnForm,
        selectedData,
        setSelectedData,
      }}>
      <PpeOutputsPage />
    </PpeOutputsContext.Provider>
  );
}
