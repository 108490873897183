import React, { useCallback, useContext } from 'react';

import SystemSettingEditDialog from 'Common/Forms/Definitions/SystemDefinition/SystemSettingEditDialog';

import { SystemSettingListContext } from '../../system-setting.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(SystemSettingListContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <SystemSettingEditDialog open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
