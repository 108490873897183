import { findByKey } from '@thalesrc/js-utils';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useAsyncEffect, useFetch } from 'utils';

import { BuildingDefinitionControllerService } from '../../../../api/client';
import { BuildingFloorDefinitionControllerService } from '../../../../api/client';
import { BuildingFloorDefinitionDto } from '../../../../api/client';
import { BuildingFloorsContext } from './building-floors.context';
import BuildingsFloor from './BuildingsFloor';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<BuildingFloorDefinitionDto>(null);
  const { buildingsId } = useParams<{ buildingsId: string }>();

  const { data, fetch } = useFetch(() => BuildingFloorDefinitionControllerService.getAllBuildingFloorDefinitions(buildingsId), [], {
    setLoading,
  });

  return (
    <BuildingFloorsContext.Provider
      value={{
        buildingFloorDefinition: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <BuildingsFloor />
    </BuildingFloorsContext.Provider>
  );
}
