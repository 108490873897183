import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { PpeDefinitionsContentResult, savePpeDefinition, updatePpeDefinition } from 'api/hs/ppe/ppe-definitions';
import Form, { FormRef } from 'Common/Form/Form';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import Dialog from '../../../Common/Dialog';
import { ResourceContext } from '../../../context/resource.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PpeDefinitionsContentResult;
}

export default function PpeDefinitionFormDialog({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { ppeTypeDefinitions, ppeClassificationDefinitions } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const { reload } = useContext(ResourceContext);

  const submit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await savePpeDefinition({ ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await updatePpeDefinition({ id: data.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
        await reload('ppeDefinitions');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data, reload]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title={data ? 'KKD Tipi Düzenle' : 'KKD Tipi Ekle'}>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField required name="label" label="Tanımı" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="barcode" label="Barkodu" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="model" label="Model" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="brand" label="Marka" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="serialNumber" label="Seri Numarası" />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="ppeEnStandard" label="EN Standardı" />
            </Grid>
            <Grid item md={12} xs={12} sm={6} lg={12}>
              <TextField type="number" name="criticalInventoryCount" label="Kritik Envanter Sayısı" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={6}>
              <Select name="ppeClassificationId" options={ppeClassificationDefinitions} label="KKD Ana Grup " />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Select name="ppeTypeId" options={ppeTypeDefinitions} label="KKD Alt Grup " />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <TextField rows={3} name="description" label="Açıklama" />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
