import { createContext } from 'react';

import { TableDataResult } from 'model';

export interface HSTableContextType<T> {
  refresh(): Promise<void>;
  loading: boolean;
  size: number;
  setSize(size: number): void;
  page: number;
  setPage(page: number): void;
  totalPage: number;
  data: T[];
  rawData: TableDataResult<T>;
  extraArgs: Record<string, any>;
  setExtraArgs(extra: Record<string, any>): void;
}

export const HSTableContext = createContext<HSTableContextType<any>>(null);
