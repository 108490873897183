import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetEmployeesPayload } from './get-employees.payload';
import { GetEmployeesResult } from './get-employees.result';

export async function getEmployees(body: GetEmployeesPayload): Promise<GetEmployeesResult> {
  const [error, res] = await tryCatch(HSApi.post<GetEmployeesResult>(`/employee-filter`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
