import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';

import ScrollableTabs from 'Common/ScrollableTabs';

import { UserContext } from '../../../../context/user';
import { UserRole } from '../../../../model/user-role.enum';
import AnalysisFileUpload from './FileUploads/AnalysisFileUpload';
import PpeFileUpload from './FileUploads/PpeFileUpload';
import FileUpload from './FileUploads/profile-file-upload';
import RestReport from './FileUploads/RestReportFileUpload';

const VisibleTabsList = [
  {
    title: 'Dosyalar',
    icon: 'icon-clipboard-check',
    Component: FileUpload,
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    title: 'Tahlil Dosyaları',
    icon: 'icon-heart-pulse',
    Component: AnalysisFileUpload,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    title: 'Rapor Dosyaları',
    icon: 'icon-seat-individual-suite',
    Component: RestReport,
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
  },
  {
    title: 'KKD Dosyaları',
    icon: 'icon-seat-individual-suite',
    Component: PpeFileUpload,
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
  },
];

export default function MedicalResume() {
  const [activeTab, setActiveTab] = useState('0');
  const changeTab = useCallback((e, value) => setActiveTab(value), []);
  const { user } = useContext(UserContext);

  const fileTabs = VisibleTabsList?.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r)));

  return (
    <TabContext value={activeTab}>
      <AppBar position="static" color="default" style={{ maxWidth: '100%' }}>
        <ScrollableTabs onChange={changeTab} indicatorColor="primary" textColor="primary">
          {fileTabs?.map(({ title }, index) => (
            <Tab key={index} label={title} value={index + ''} />
          ))}
        </ScrollableTabs>
      </AppBar>
      {fileTabs?.map(({ Component }, index) => (
        <TabPanel key={index} value={index + ''}>
          <Component />
        </TabPanel>
      ))}
    </TabContext>
  );
}
