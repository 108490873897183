import React from 'react';

import AssigmentPositionAdd from './add';
import AssigmentPositionList from './list';

export default function Index() {
  return (
    <>
      <AssigmentPositionAdd />
      <AssigmentPositionList />
    </>
  );
}
