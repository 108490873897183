import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { BodyMeasurementsContext } from '../../body-measurements.context';
import DialogBodyMeasurement from '../Form/DialogBodyMeasurement';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(BodyMeasurementsContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={onClick} className="mt-1">
        Vücut Ölçümü Ekle
      </Button>

      <DialogBodyMeasurement onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
