import React, { useCallback } from 'react';

import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { getNearMissesList } from '../../../../../api/hs/accident/accident';
import { COLUMNS } from './columns';
import EditDialog from './EditDialog';
import ListTable from './ListTableData';
import Toolbar from './Toolbar';

export default function NearMissList() {
  const fetchNearMissList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getNearMissesList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <>
      <HSTableAndPaginationAndTableContextProvider fetcher={fetchNearMissList} columns={COLUMNS}>
        <Toolbar />
        <ListTable />
        <EditDialog />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
