/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildingDefinitionDto } from '../models/BuildingDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BuildingDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns BuildingDefinitionDto OK
     * @throws ApiError
     */
    public static updateBuildingDefinition(
        id: string,
        requestBody: BuildingDefinitionDto,
    ): CancelablePromise<BuildingDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/buildings/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBuildingDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/buildings/${id}`,
        });
    }

    /**
     * @returns BuildingDefinitionDto OK
     * @throws ApiError
     */
    public static getAllBuildingDefinitions(): CancelablePromise<Array<BuildingDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/buildings`,
        });
    }

    /**
     * @param requestBody
     * @returns BuildingDefinitionDto OK
     * @throws ApiError
     */
    public static saveBuildingDefinition(
        requestBody: BuildingDefinitionDto,
    ): CancelablePromise<BuildingDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/buildings`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}