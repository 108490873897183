import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  AnalysisAggregate,
  AnalysisDetailResult,
  getParameters,
  PulmonaryDetailsModel,
  saveAudiometry,
  savePulmonary,
  updateAnalysis,
  updateAnalysisWithItsDetail,
} from 'api/hs/examination/analysis';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { EnumContext } from '../../../../../../context/enum.context';
import { AnalysisContext } from '../../analysis.context';
import { Category } from '../category.enum';
import { PulmonaryQuestion } from '../pulmonary-question.enum';
import AudiometryForm from './AudiometryForm';
import CommonAnalysisForm from './CommonAnalysisForm';
import PulmonaryForm from './PulmonaryForm';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: AnalysisAggregate;
}

interface HandleSubmitProps {
  opinion: string;
  date: string;
  description: string;
  [key: string]: number | string;
}

interface AudiometryEarType {
  [type: string]: number;
}

const getNumber = (key: string, getValues: any): number => {
  const numberValue = parseFloat(getValues(key));
  if (numberValue) {
    return numberValue;
  }

  return 0;
};

const calculateSSO = (key: string, getValues: any): number => {
  return parseFloat(
    ((getNumber(`${key}Ear_khz500`, getValues) + getNumber(`${key}Ear_khz1000`, getValues) + getNumber(`${key}Ear_khz2000`, getValues)) / 3)
      .toFixed(2)
      .replace(/\.00$/, '')
  );
};

const calculatePredAndNas = (key: string, getValues: any): { nas: number; predPercent: number } => {
  if (key === 'FEF25-75') {
    const nasCalculation = parseFloat(getValues(`pred_${key}`)) * 0.7;
    const predPercentCalculation = (parseFloat(getValues(`act_${key}`)) / parseFloat(getValues(`pred_${key}`))) * 100;
    return {
      nas: nasCalculation ? parseFloat(nasCalculation.toFixed(2).replace(/\.00$/, '')) : 0,
      predPercent: predPercentCalculation ? parseFloat(predPercentCalculation.toFixed(2).replace(/\.00$/, '')) : 0,
    };
  } else {
    const nasCalculation = parseFloat(getValues(`pred_${key}`)) * 0.8;
    const predPercentCalculation = (parseFloat(getValues(`act_${key}`)) / parseFloat(getValues(`pred_${key}`))) * 100;
    return {
      nas: nasCalculation ? parseFloat(nasCalculation.toFixed(2).replace(/\.00$/, '')) : 0,
      predPercent: predPercentCalculation ? parseFloat(predPercentCalculation.toFixed(2).replace(/\.00$/, '')) : 0,
    };
  }
};

export default function AnalysisEditForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { analysisCategoryDefinitions } = useContext(EnumContext);
  const { parameters, setParameters } = useContext(AnalysisContext);

  const handleAudiometryInput = useCallback(
    e => {
      const side = e.target.name.includes('right') ? 'right' : 'left';
      const calculation = calculateSSO(side, formMethods.getValues);
      setValue(`${side}Ear_sso`, calculation);
    },
    [setValue, formMethods.getValues]
  );

  const handlePulmonaryInput = useCallback(
    e => {
      const pulmonaryType = e.target.name.split('_')[1];
      const calculation = calculatePredAndNas(pulmonaryType, formMethods.getValues);
      setValue(`predPercent_${pulmonaryType}`, calculation.predPercent);
      setValue(`nas_${pulmonaryType}`, calculation.nas);
    },
    [setValue, formMethods.getValues]
  );

  const renderAnalysisForm = useCallback(
    params => {
      if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Audiometry) {
        return <AudiometryForm parameters={params} handleInput={handleAudiometryInput} />;
      } else if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Pulmonary) {
        return <PulmonaryForm parameters={params} handleInput={handlePulmonaryInput} />;
      } else {
        return <CommonAnalysisForm parameters={params} />;
      }
    },
    [data, handleAudiometryInput, handlePulmonaryInput, analysisCategoryDefinitions]
  );

  const handleSubmit = useCallback(
    async (values: HandleSubmitProps) => {
      try {
        if (data) {
          const analysisCoreData = {
            categoryDefinitionId: data.categoryDefinitionId,
            employeeId,
            examinationId,
            opinion: values.opinion,
            date: values.date,
          };

          if (
            findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label !== Category.Audiometry &&
            findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label !== Category.Pulmonary
          ) {
            const detailList: AnalysisDetailResult[] = [];

            Object.entries(values).forEach(([key, value]) => {
              if (key !== 'date' && key !== 'opinion') {
                const keyNumber = key.includes('common-analysis-form_') ? key.split('common-analysis-form_')[1] : null;
                if (keyNumber) {
                  detailList.push({
                    result: value as number,
                    parameterDefinitionId: keyNumber,
                    opinion: values[`description_${keyNumber}`] as string,
                  });
                }
              }
            });

            await updateAnalysisWithItsDetail({ id: data.id, detailList, ...analysisCoreData });
          } else if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Audiometry) {
            const leftEar: AudiometryEarType = {};
            const rightEar: AudiometryEarType = {};
            Object.entries(values).forEach(([key, value]) => {
              if (key !== 'opinion' && key !== 'date' && key !== 'description') {
                if (key.includes('leftEar_')) {
                  const newKey: string = key.split('leftEar_')[1];
                  leftEar[newKey] = value ? (value as number) : 0;
                } else if (key.includes('rightEar_')) {
                  const newKey = key.split('rightEar_')[1];
                  rightEar[newKey] = value ? (value as number) : 0;
                }
              }
            });

            await updateAnalysis({
              id: data.id,
              opinion: values.opinion,
              date: values.date,
            });
            await saveAudiometry({
              analysisId: data.id,
              leftEar: { ...leftEar, sso: calculateSSO('left', formMethods.getValues) },
              rightEar: { ...rightEar, sso: calculateSSO('right', formMethods.getValues) },
            });
          } else if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Pulmonary) {
            const pftDetails: PulmonaryDetailsModel[] = [];
            parameters?.forEach(p => {
              if (Object.keys(values).find(v => v.split('_')[1] === p.parameter)) {
                const calculation = calculatePredAndNas(p.parameter, formMethods.getValues);
                const nasSoruValue =
                  p?.parameter === PulmonaryQuestion.Question1ID
                    ? values.nas_Soru1
                    : p?.parameter === PulmonaryQuestion.Question2ID
                    ? values.nas_Soru2
                    : calculation.nas;

                pftDetails.push({
                  parameterDefinitionId: p.id,
                  act: values[`act_${p.parameter}`] as number,
                  nas: nasSoruValue as number,
                  pred: values[`pred_${p.parameter}`] as number,
                  predPercent: calculation.predPercent as number,
                });
              }
            });

            await updateAnalysis({
              id: data.id,
              opinion: values.opinion,
              date: values.date,
            });
            await savePulmonary({
              id: data.id,
              pftDetails,
            });
          }
        }
        showMessage('Kayıt başarılı ile güncellendi.', 'success', 'info');
        onClose(true);
      } catch (error) {
        showMessage(error?.data?.message, 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, formMethods.getValues, parameters, employeeId, examinationId, analysisCategoryDefinitions]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }

    await defer();

    if (data?.categoryDefinitionId) {
      const parametersRes = await getParameters(data.categoryDefinitionId);
      setParameters(parametersRes);
      setValue('date', data?.date);
      setValue('opinion', data?.opinion);

      if (
        findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label !== Category.Audiometry &&
        findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label !== Category.Pulmonary
      ) {
        data?.detailsList?.forEach(item => {
          setValue(`common-analysis-form_${item.parameterDefinitionId}`, item.result);
          setValue(`description_${item.parameterDefinitionId}`, item.opinion);
        });
      } else if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Audiometry) {
        if (data?.audiometry?.leftEar) {
          for (const [key, value] of Object.entries(data?.audiometry?.leftEar)) {
            if (key.includes('sso')) {
              setValue(`leftEar_sso`, calculateSSO('left', formMethods.getValues));
            } else {
              setValue(`leftEar_${key}`, value);
            }
          }
        }

        if (data?.audiometry?.rightEar) {
          for (const [key, value] of Object.entries(data?.audiometry?.rightEar)) {
            if (key.includes('sso')) {
              setValue(`rightEar_sso`, calculateSSO('right', formMethods.getValues));
            } else {
              setValue(`rightEar_${key}`, value);
            }
          }
        }
      } else if (findByKey(analysisCategoryDefinitions, 'id', data?.categoryDefinitionId)?.label === Category.Pulmonary) {
        data?.pulmonaryResult?.pftDetails?.forEach(item => {
          const actualParameter = parametersRes?.find(p => p.id.toString() === item.parameterDefinitionId.toString());
          setValue(`act_${actualParameter?.parameter}`, item.act);
          setValue(`nas_${actualParameter?.parameter}`, item.nas);
          setValue(`pred_${actualParameter?.parameter}`, item.pred);
          setValue(`predPercent_${actualParameter?.parameter}`, item.predPercent);
        });
      }

      trigger();
    }
  }, [data, setValue, reset, trigger, formMethods.getValues, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 800 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>
        {data?.categoryDefinitionId
          ? `${
              analysisCategoryDefinitions.find(def => {
                return def.id === data.categoryDefinitionId;
              })?.label
            } Tahlil/Tetkik Düzenleme`
          : 'Tahlil/Tetkik Düzenleme'}
      </DialogTitle>
      <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
        <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <DialogContent>
            <Grid container spacing={1}>
              {renderAnalysisForm(parameters)}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
