import { createContext } from 'react';

import { FirmUsersModel, FirmUsersResult } from 'api/hs/definitions/firm-definitions/firm-users';

export interface FirmUsersContextType {
  firmUsers: FirmUsersResult[];

  selectedFirmId: string;

  setSelectedFirmId(firmId: string): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: FirmUsersModel;

  setSelectedData(data: FirmUsersModel): void;
}

export const FirmUsersContext = createContext<FirmUsersContextType>(null);
