import { Paper } from '@material-ui/core';
import React from 'react';

import ImmunityAndVaccine from './ImmunityAndVaccine';

export default function ImmunityAndVaccinePage() {
  return (
    <div className="p-2">
      <Paper className="p-2" elevation={3}>
        <ImmunityAndVaccine />
      </Paper>
    </div>
  );
}
