import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetPhysicalCheckDefinitionsResult } from './get-physical-check-definitions.result';

export async function getPhysicalCheckDefinition(): Promise<GetPhysicalCheckDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetPhysicalCheckDefinitionsResult[]>(`/firms/physical-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
