import React from 'react';

import RiskAssessmentHazardAdd from './Add';
import RiskAssessmentHazardList from './List';

export default function Index() {
  return (
    <>
      <RiskAssessmentHazardList />
      <RiskAssessmentHazardAdd />
    </>
  );
}
