import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { ActionWidgetItem } from '../../../../api/hs/dashboard/widget/widget.payload';
import { ResourceContext } from '../../../../context/resource.context';
import { datePrettier } from '../../../../utils';

interface Props {
  accidents: any[];
}

export default ({ accidents }: Props) => {
  const history = useHistory();
  if (!accidents || accidents.length === 0) return <b>Kayıt bulunamadı!</b>;

  const handleClick = (accidentId: string) => {
    history.push(`/accident/list/${accidentId}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Kaza Tanımı</TableCell>
            <TableCell className="text-bold">Tarih</TableCell>
            <TableCell className="text-bold">Şirket</TableCell>
            <TableCell className="text-bold">Departmanlar</TableCell>
            <TableCell className="text-bold">İş İstasyonları</TableCell>
            <TableCell className="text-bold">Kaza Türü</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accidents.map((accident, index) => (
            <TableRow key={index} hover onClick={() => handleClick(accident.accidentId)}>
              <TableCell scope="row">{accident.description}</TableCell>
              <TableCell>{new Date(accident.date).toLocaleString('tr-TR')}</TableCell>
              <TableCell>{accident.companyName}</TableCell>
              <TableCell scope="row">{accident.accidentDepartmentViews.map(x => x.departmentName).join(', ')}</TableCell>
              <TableCell scope="row">{accident.accidentStationViews.map(z => z.stationName).join(', ')}</TableCell>
              <TableCell scope="row">{accident.accidentTypeViews.map(y => y.typeDefinitionLabel).join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
