import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { RestReportControllerService } from '../../../api/client';
import { getReferraledEmployeeCounts, hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { DateRange, WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { getRestReportWidgetCounts } from '../../../api/hs/examination/rest-reports';
import { useAsyncEffect, useFetch } from '../../../utils';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DateRangeDialog from './DateRangeDialog';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }) => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [restReportCount, setRestReportCount] = useState(0);
  const [referralCount, setReferralCount] = useState(0);
  const {
    lostDayWidget: { open },
    lostDayWidgetDateRange: { dateRange, days, setRangeByDays, setRange },
  } = useContext(DialogContext);

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const hide = () => {
    hideWidget({ widgetType: WidgetType.LOST_DAY }).then(reload);
  };

  useAsyncEffect(async () => {
    if (dateRange) {
      await getRestReportWidgetCounts(new DateRange(dateRange?.start, dateRange?.end)).then(setRestReportCount);
      await getReferraledEmployeeCounts(new DateRange(dateRange.start, dateRange.end)).then(setReferralCount);
    }
  }, [dateRange]);

  return (
    <Card key={'lostday'} className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.lostDay, cursor: 'alias' }}>
      <CardContent>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sm={10}>
            <h3>Rapor Gün Kayıp : {restReportCount}</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide}>
              <>
                {dateRangeOptions.map(dateRangeOption => (
                  <MenuItem key={dateRangeOption} onClick={() => setRangeByDays(dateRangeOption)}>
                    <ListItemIcon>{dateRangeOption === days && <Done fontSize="small" />}</ListItemIcon>
                    <ListItemText>{dateRangeOption} Gün</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setDialogVisibility(true)}>
                  <ListItemIcon>{days === undefined && <Done fontSize="small" />}</ListItemIcon>
                  <ListItemText>Diğer...</ListItemText>
                </MenuItem>
              </>
            </DropdownMenuContainer>
          </Grid>
        </Grid>
        <br />
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid style={{ marginTop: '12px' }} item sm={12}>
            <h3>Sevk Gün Kayıbı : {referralCount}</h3>
          </Grid>
        </Grid>
        <Divider />
        <p />
      </CardContent>
      <DateRangeDialog close={() => setDialogVisibility(false)} opened={dialogVisibility} setRange={setRange} dateRange={dateRange} />
    </Card>
  );
};
