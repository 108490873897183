import React from 'react';
import { Route, Switch } from 'react-router';

import Analysis from './AnalysisDefinition';
import AnalysisDefinitionParameter from './AnalysisDefinitionParameter';

export default function AnalysisDefinition() {
  return (
    <Switch>
      <Route path="/definitions/health/tatlil-tetkik/:categoryDefinitionId">
        <AnalysisDefinitionParameter />
      </Route>

      <Route path="/definitions/health/tatlil-tetkik" exact>
        <Analysis />
      </Route>
    </Switch>
  );
}
