import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';

export async function updateRiskAssessmentsPrecautionLevelDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/precaution-level-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsPrecautionLevelDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/precaution-level-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRiskAssessmentsMatrixScoreDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/matrix-score-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsMatrixScoreDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/matrix-score-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRiskAssessmentsMatrixPossibilityDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/matrix-possibility-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsMatrixPossibilityDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/matrix-possibility-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRiskAssessmentsMatrixDamageDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/matrix-damage-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsMatrixDamageDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/matrix-damage-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRiskAssessmentsHazardCategoryDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/hazard-category-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsHazardCategoryDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/hazard-category-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRiskAssessmentsFineKinneyFrequencyDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/fine-kinney-frequency-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmRiskAssessmentsFineKinneyFrequencyDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/fine-kinney-frequency-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFineKinneyHazardScoreDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/fine-kinney-score-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmFineKinneyHazardScoreDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/fine-kinney-score-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFineKinneyPossibilityDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/fine-kinney-possibility-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmFineKinneyPossibilityDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/fine-kinney-possibility-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFineKinneyHazardDamageDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`risk-assessments/fine-kinney-damage-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmFineKinneyHazardDamageDefinitionUpdate({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/risk-assessments/fine-kinney-damage-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
