import React, { useCallback, useContext } from 'react';

import NewRecordButton from '../../../../Accident/Review/NewRecordButton';
import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { ActionContext } from '../../action.context';
import ActionsFormDialog from '../Form';

export default function EditDialog({
  referenceId,
  nearMissId,
  actionReferenceValue,
  baseFetcher,
}: {
  referenceId: string;
  nearMissId: string;
  actionReferenceValue: string;
  baseFetcher: any;
}) {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(ActionContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
        if (baseFetcher) {
          await baseFetcher();
        }
      }
      setEditDialogOpened(false);
    },
    [refresh, setEditDialogOpened, baseFetcher]
  );

  return (
    <header className="text-right mr-1">
      <NewRecordButton type="button" onClick={() => setEditDialogOpened(true)} label={'Aksiyon Ekle'} />
      <ActionsFormDialog
        open={editDialogOpened}
        onClose={closeDialog}
        data={selectedData}
        referenceId={referenceId}
        nearMissId={nearMissId}
        actionReferenceValue={actionReferenceValue}
      />
    </header>
  );
}
