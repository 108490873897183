import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext, useState } from 'react';

import Button from 'Common/Button';

import { CommitteeMeetingsDetailContext } from '../../committee-meetings-detail.context';
import CommitteeMeetingResultForm from './CommitteeMeetingResultForm';

export default function CommitteeMeetingResultAddButton({ reloadList }) {
  const { reload } = useContext(CommitteeMeetingsDetailContext);
  const [openNewResult, setOpenNewResult] = useState(false);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        reload();
        await reloadList();
      }
      setOpenNewResult(false);
    },
    [reload, setOpenNewResult, reloadList]
  );

  const onClick = () => {
    setOpenNewResult(true);
  };
  return (
    <>
      <header className="text-right mr-1">
        <Button startIcon={<AddIcon />} color="primary" onClick={onClick} className="ml-1">
          Yeni Toplantı Kararı Ekle
        </Button>
      </header>
      <CommitteeMeetingResultForm onClose={closeDialog} data={null} open={openNewResult} />
    </>
  );
}
