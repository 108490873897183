import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { WorkEquipmentLayoutContext } from '../work-equipment-layout.context';
import WorkEquipmentLayoutNewForm from '../WorkEquipmentLayoutNewForm';

export default function NewWorkEquipmentLayoutDialog() {
  const { newDialogOpened, setNewDialogOpened } = useContext(WorkEquipmentLayoutContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setNewDialogOpened(false);
    },
    [setNewDialogOpened, refresh]
  );

  return <WorkEquipmentLayoutNewForm open={newDialogOpened} onClose={closeDialog} />;
}
