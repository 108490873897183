import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { TypeDefinitionPayload } from './type-definition.payload';
import { TypeDefinitionResult } from './type-definition.result';

export async function getTypeDefinitions(): Promise<TypeDefinitionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<TypeDefinitionResult[]>(`/firms/ppe/type-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveTypeDefinition(body: TypeDefinitionPayload): Promise<TypeDefinitionResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/ppe/type-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateTypeDefinition({
  id,
  ...body
}: MakeRequired<Partial<TypeDefinitionResult>, 'id'>): Promise<TypeDefinitionResult> {
  const [error, res] = await tryCatch(HSApi.put<TypeDefinitionResult>(`/firms/ppe/type-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteTypeDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/ppe/type-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminTypeDefinitions(): Promise<TypeDefinitionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<TypeDefinitionResult[]>(`/ppe/type-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminTypeDefinition(body: TypeDefinitionPayload): Promise<TypeDefinitionResult> {
  const [err, res] = await tryCatch(HSApi.post('/ppe/type-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminTypeDefinition({
  id,
  ...body
}: MakeRequired<Partial<TypeDefinitionResult>, 'id'>): Promise<TypeDefinitionResult> {
  const [error, res] = await tryCatch(HSApi.put<TypeDefinitionResult>(`/ppe/type-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminTypeDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe/type-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
