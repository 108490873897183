import { tryCatch } from '@thalesrc/js-utils';

import { dateSort } from '../../../../utils';
import HSApi from '../../HSApi';
import { PpeFileUploadResult } from './ppe-file-upload.result';

export async function getPpeFileUpload(employeeId: string): Promise<PpeFileUploadResult[]> {
  const [error, res] = await tryCatch(HSApi.get<PpeFileUploadResult[]>(`/ppe-outputs/employee/${employeeId}/imported-files`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return dateSort(res.data);
}

export async function deletePpeFileUpload(id: PpeFileUploadResult['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe-outputs/imported-files/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
