import { Button, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { getWorkEquipmentList, searchWorkEquipments, WorkEquipmentsModel } from 'api/hs/work-equipments';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState } from 'utils';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

async function workEquipmentsOptionFetcher(value: string) {
  const res = await searchWorkEquipments(value);
  return mapProfessionDefinition(res.content);
}

async function workEquipmentsLabelFetcher(value: string[]) {
  const res = await getWorkEquipmentList(value);
  return mapProfessionDefinition(res);
}
function mapProfessionDefinition(types: WorkEquipmentsModel[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ ad, kod }) => ({ text: ad, value: kod }));
}

export default function Filters() {
  const classes = useStyles();
  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const { companies, workEquipmentTypeDefinitions } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);

  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  return (
    <>
      <Fab
        onClick={open}
        size="small"
        className={classes.fab}
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">İş Ekipman Listesi Filtreleme</Typography>
        </Toolbar>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid item xs={12}>
              <AutoComplete name="companyIds" options={companies} label="Şirketler" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                name="codes"
                label="IBYS Kodu"
                optionFetcher={workEquipmentsOptionFetcher}
                labelFetcher={workEquipmentsLabelFetcher}
                renderOption="checkbox"
              />
            </Grid>

            <Grid item xs={12}>
              <AutoComplete name="typeIds" options={workEquipmentTypeDefinitions} label="İş Ekipman Kategori" />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" name="serialNumber" label="Takip Numarası" />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" name="brand" label="Marka" />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" name="model" label="Model" />
            </Grid>
            <Grid item xs={12}>
              <TextField type="text" name="serialNumber" label="Seri Numarası" />
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
