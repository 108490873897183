/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BodyMeasurementViewRequest } from '../models/BodyMeasurementViewRequest';
import type { PageBodyMeasurementView } from '../models/PageBodyMeasurementView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BodyMeasurementViewControllerService {

    /**
     * @param requestBody
     * @returns PageBodyMeasurementView OK
     * @throws ApiError
     */
    public static getAllBodyMeasurement(
        requestBody: BodyMeasurementViewRequest,
    ): CancelablePromise<PageBodyMeasurementView> {
        return __request({
            method: 'POST',
            path: `/body-measurements/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}