/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeView } from './EmployeeView';

export type CommitteeMeetingView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    subject?: string;
    plannedDate?: string;
    employeeNamesStr?: string;
    isFaceToFace?: boolean;
    meetingStatusDefinition?: CommitteeMeetingView.meetingStatusDefinition;
    meetingStatusDefinitionLabel?: string;
    userName?: string;
    lastModifiedByName?: string;
    description?: string;
    companyId?: string;
    companyName?: string;
    result?: string;
    employees?: Array<EmployeeView>;
}

export namespace CommitteeMeetingView {

    export enum meetingStatusDefinition {
        DONE = 'DONE',
        ABORT = 'ABORT',
        DELAY = 'DELAY',
        PLANNED = 'PLANNED',
    }


}
