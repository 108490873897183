import { Icon, IconButton, List, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { getExaminationById } from 'api/hs/examination';
import { getDiagnosis } from 'api/hs/examination/diagnosis';
import Toolbar from 'Common/Toolbar';
import { ResourceContext } from 'context/resource.context';
import { printFile, useFetch } from 'utils';

import Analysis from '../../Analysis';
import BaseSection from '../../BaseSection';
import Complaints from '../../Complaints';
import EK2Form from '../../EK2Form';
import Ek2Ministry from '../../Ek2Ministry/İndex';
import ExaminationSend from '../../ExaminationSend';
import FamilyHistory from '../../FamilyHistory';
import ImmunityAndVaccine from '../../ImmunityAndVaccine';
import BodyMeasurements from '../../Measurements/BodyMeasurements';
import VitalSigns from '../../Measurements/VitalSigns';
import MedicalIntervention from '../../MedicalIntervention';
import MedicalResume from '../../MedicalResume';
import HsOpinion from '../../Opinion';
import PeriodicExamination from '../../PeriodicExaminationConfigurations';
import PhysicalCheck from '../../PhysicalCheck';
import PolyclinicExamOpinions from '../../PolyclinicExamOpinions';
import Prescription from '../../Prescription';
import ProfessionalBackground from '../../ProfessionalBackground';
import Referrals from '../../Referrals';
import SystemCheck from '../../SystemCheck';
import Diagnosis from './Diagnosis';
import EditExaminationDialog from './EditExaminationDialog';
import { ExaminationDetailContext, ExaminationDetailContextType } from './examination-detail.context';
import Reports from './Reports';

export default function Detail() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const { data: diagnosis, fetch: reloadDiagnosis } = useFetch(async () => await getDiagnosis(employeeId, examinationId), []);
  const { data: examData, fetch: fetchExamination } = useFetch(() => getExaminationById(examinationId), null);
  const [editExaminationDialogOpened, setEditExaminationDialogOpened] = useState(false);

  const { examinationDefinitions } = useContext(ResourceContext);

  const close = useCallback(() => setEditExaminationDialogOpened(false), [setEditExaminationDialogOpened]);

  const context = useMemo<ExaminationDetailContextType>(
    () => ({
      diagnosis,
      reloadDiagnosis,
      protocolNumber: examData?.protocolNumber ?? null,
      fetchExamination,
    }),
    [diagnosis, reloadDiagnosis, examData, fetchExamination]
  );

  const showDialog = useCallback(
    item => {
      closeMenu();
      setEditExaminationDialogOpened(true);
    },
    [setEditExaminationDialogOpened, closeMenu]
  );

  return (
    <ExaminationDetailContext.Provider value={context}>
      <Toolbar color="secondary">
        <List style={{ width: '100%' }} component="nav">
          <ListItem>
            <ListItemText
              primary={
                <>
                  <Typography variant="h6" style={{ minWidth: 'max-content', fontSize: '18px' }}>
                    {`${
                      examinationDefinitions.find(def => def.id === examData?.examinationDefinitionId)?.examinationName || 'Muayene'
                    } ( ${examData?.date?.split('-')?.reverse()?.join('-')} )`}
                    {` `}
                    {examData?.userData?.name} tarafından oluşturuldu
                  </Typography>
                </>
              }
              secondary={
                <Typography className="text-italic">
                  Protokol Numarası :
                  {examData?.protocolNumber?.yearPrefix + '/' + String(examData?.protocolNumber?.number).padStart(4, '0')}
                </Typography>
              }
            />
            <ListItemText className="text-right">
              <IconButton className="text-white" onClick={openMenu}>
                <MoreVertIcon />
              </IconButton>
            </ListItemText>
          </ListItem>
        </List>
      </Toolbar>
      <div className="p-2">
        <BaseSection icon="icon-account-card-details" title="Mesleki Özgeçmiş">
          <ProfessionalBackground />
        </BaseSection>
        <BaseSection icon="icon-account-multiple" title="Soygeçmiş">
          <FamilyHistory />
        </BaseSection>
        <BaseSection icon="icon-file-document" title="Tıbbi Özgeçmiş">
          <MedicalResume />
        </BaseSection>
        <BaseSection icon="icon-tooltip-edit" title="Yakınma Şikayet">
          <Complaints />
        </BaseSection>
        <BaseSection icon="icon-needle" title="Bağışıklanma / Aşı">
          <ImmunityAndVaccine />
        </BaseSection>
        <BaseSection icon="icon-account-settings" title="Sistem Sorgulama">
          <SystemCheck />
        </BaseSection>
        <BaseSection icon="icon-stethoscope" title="Vücut / Vital Ölçümleri">
          <BaseSection icon="icon-check" title="Vücut Ölçümleri">
            <BodyMeasurements />
          </BaseSection>
          <BaseSection icon="icon-check" title="Vital Ölçümleri">
            <VitalSigns />
          </BaseSection>
        </BaseSection>
        <BaseSection icon="icon-human" title="Fizik Muayene">
          <PhysicalCheck />
        </BaseSection>
        <BaseSection icon="icon-medical-bag" title="Tıbbi Müdahale">
          <MedicalIntervention />
        </BaseSection>
        <BaseSection icon="icon-magnify" title="Tanı">
          <Diagnosis />
        </BaseSection>
        <BaseSection icon="icon-prescription" title="Reçete">
          <Prescription />
        </BaseSection>
        <BaseSection icon="icon-heart-pulse" title="Tahlil/Tetkik">
          <Analysis />
        </BaseSection>
        <BaseSection icon="icon-code-string" title="Sevkler">
          <Referrals />
        </BaseSection>
        <BaseSection icon="icon-seat-individual-suite" title="Raporlar">
          <Reports />
        </BaseSection>
        {findByKey(examinationDefinitions, 'id', examData?.examinationDefinitionId)?.type === 'HS_EXAMINATION' ? (
          <BaseSection icon="icon-file-check" title="Kanaat">
            <HsOpinion />
          </BaseSection>
        ) : (
          <BaseSection icon="icon-file-check" title="Kanaat">
            <PolyclinicExamOpinions />
          </BaseSection>
        )}
        <BaseSection icon="icon-calendar-clock" title="Per.Muayene Ayarları">
          <PeriodicExamination />
        </BaseSection>
        {findByKey(examinationDefinitions, 'id', examData?.examinationDefinitionId)?.type === 'HS_EXAMINATION' ? (
          <BaseSection icon="icon-send" title="IBYS Muayene Gönderimi">
            <ExaminationSend />
          </BaseSection>
        ) : null}
      </div>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        {findByKey(examinationDefinitions, 'id', examData?.examinationDefinitionId)?.type === 'HS_EXAMINATION' ? (
          <>
            <MenuItem onClick={showDialog}>
              <ListItemIcon>
                <Icon className="icon-pencil" />
              </ListItemIcon>
              <Typography variant="inherit">Düzenle</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                printFile(`/examinations/${examinationId}/opinion/view/export?type=HTML`);
                closeMenu();
              }}>
              <ListItemIcon>
                <Icon className="icon-clipboard-check" />
              </ListItemIcon>
              <Typography variant="inherit">Kanaat Formu</Typography>
            </MenuItem>
            <EK2Form createdExaminationUserId={examData?.createdBy ? examData?.createdBy : null} onClose={closeMenu} />
            <Ek2Ministry createdExaminationUserId={examData?.createdBy ? examData?.createdBy : null} onClose={closeMenu} />
          </>
        ) : (
          <MenuItem
            onClick={() => {
              printFile(`/examinations/${examinationId}/opinion/view/export?type=HTML`);
              closeMenu();
            }}>
            <ListItemIcon>
              <Icon className="icon-clipboard-check" />
            </ListItemIcon>
            <Typography variant="inherit">Kanaat Formu</Typography>
          </MenuItem>
        )}
      </Menu>
      <EditExaminationDialog data={examData} onClose={close} open={editExaminationDialogOpened} />
    </ExaminationDetailContext.Provider>
  );
}
