import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';

import { SelectOptionsContext } from '../../../../context/select-options.context';

interface PhysicalWorkConditionParams {
  required: boolean;
}

export default function PhysicalWorkCondition({ required }: PhysicalWorkConditionParams) {
  const {
    physicalWorkConditionChemicalMatterDefinitions,
    physicalWorkConditionAirDefinitions,
    physicalWorkConditionBiologicalFactorsDefinition,
    physicalWorkConditionDustRegulationDefinitions,
    physicalWorkConditionElectricityDefinitions,
    physicalWorkConditionNoiseDefinitions,
    physicalWorkConditionPhysicalEnvironmentDefinitions,
  } = useContext(SelectOptionsContext);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="chemicalMatterDefinitions"
            options={physicalWorkConditionChemicalMatterDefinitions}
            label="Kimyasal Madde"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="airDefinitions"
            options={physicalWorkConditionAirDefinitions}
            label="Hava"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="biologicalFactorsDefinitions"
            options={physicalWorkConditionBiologicalFactorsDefinition}
            label="Biyolojik Faktörler"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="dustRegulationDefinitions"
            options={physicalWorkConditionDustRegulationDefinitions}
            label="Toz Regülasyonu"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="electricityDefinitions"
            options={physicalWorkConditionElectricityDefinitions}
            label="Elektrik"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="noiseDefinitions"
            options={physicalWorkConditionNoiseDefinitions}
            label="Gürültü/Ses"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
        <Grid item lg={12} md={12}>
          <AutoComplete
            name="physicalEnvironmentDefinitions"
            options={physicalWorkConditionPhysicalEnvironmentDefinitions}
            label="Fiziki Çevre"
            renderOption="checkbox"
            required={required}
          />
        </Grid>
      </Grid>
    </>
  );
}
