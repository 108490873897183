import React, { useContext } from 'react';

import BlockSearchBar from 'Common/SearchBar/BlockSearchBar';

import { Icd10MedicineListContext } from '../Icd10.context';

export default function Search() {
  const defaultValue = '';
  const { searchPlaceholder, search } = useContext(Icd10MedicineListContext);

  const updateSearchKeyword = (value: string) => {
    search(value);
  };

  return (
    <>
      <BlockSearchBar placeholder={searchPlaceholder} value={defaultValue} onChange={updateSearchKeyword} />
    </>
  );
}
