import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { FineKinneyHazardScoreDefinitionDto } from 'api/client';
import { FineKinneyHazardScoreDefinitionControllerService } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import FineKinneyScoreDefinitionForm from './Forms/FineKinneyScoreDefinitionForm';

export default function FineKinneyScoreDefinition() {
  return (
    <BaseDefinitionPage<FineKinneyHazardScoreDefinitionDto>
      listLabelPropName="label"
      fetchData={FineKinneyHazardScoreDefinitionControllerService.getAllFineKinneyScoreDefinitions}
      FormDialog={FineKinneyScoreDefinitionForm}
      onDelete={FineKinneyHazardScoreDefinitionControllerService.deleteAllFineKinneyScoreDefinitions}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
