import { createContext } from 'react';

import {
  DefaultNurseOperationDetailModel,
  DefaultNurseOperationDetailResult,
} from 'api/hs/nurse-operations/default-nurse-operation-detail';
import { DefaultNurseOperationsResult } from 'api/hs/nurse-operations/default-nurse-operations';

export interface DefaultNurseOperationDetailContextType {
  defaultNurseOperationDetail: DefaultNurseOperationDetailResult[];

  nurseOperation: DefaultNurseOperationsResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: DefaultNurseOperationDetailModel;

  setSelectedData(data: DefaultNurseOperationDetailModel): void;
}

export const DefaultNurseOperationDetailContext = createContext<DefaultNurseOperationDetailContextType>(null);
