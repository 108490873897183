import { Paper } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import {
  DefaultNurseOperationDetailModel,
  DefaultNurseOperationDetailResult,
  getDefaultNurseOperationDetail,
} from 'api/hs/nurse-operations/default-nurse-operation-detail';
import { DefaultNurseOperationsResult, getByIdDefaultNurseOperation } from 'api/hs/nurse-operations/default-nurse-operations';
import { useAsyncEffect } from 'utils';

import Injection from '../../Injections';
import BodyMeasurements from '../../Measurements/BodyMeasurements';
import VitalSigns from '../../Measurements/VitalSigns';
import { DefaultNurseOperationDetailContext } from './default-nurse-operation-detail.context';
import DefaultNurseOperationDetail from './DefaultNurseOperationDetail';
import Toolbar from './DefaultNurseOperationDetail/Toolbar';

export default function Index() {
  const [defaultNurseOperationDetail, setDefaultNurseOperationDetail] = useState<DefaultNurseOperationDetailResult[]>([]);
  const [nurseOperation, setNurseOperation] = useState<DefaultNurseOperationsResult>(null);
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<DefaultNurseOperationDetailModel>(null);
  const { nurseOperationId } = useParams<{ nurseOperationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getDefaultNurseOperationDetail(nurseOperationId);
    setDefaultNurseOperationDetail(result);
    setLoading(false);
  }, [nurseOperationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  const fetchByIdDefaultNurseOperation = useCallback(async () => {
    const result = await getByIdDefaultNurseOperation(nurseOperationId);
    setNurseOperation(result);
  }, [nurseOperationId]);

  useAsyncEffect(async () => {
    await fetchByIdDefaultNurseOperation();
  }, []);

  const nurseOperationType = useMemo(() => {
    switch (nurseOperation?.nurseOperationDefinitionId) {
      case 7:
        return <VitalSigns />;
      case 8:
        return <BodyMeasurements />;
      case 9:
        return <Injection />;
      case 11:
        return <div>Aşı</div>;
      default:
        return <DefaultNurseOperationDetail />;
    }
  }, [nurseOperation]);

  return (
    <DefaultNurseOperationDetailContext.Provider
      value={{
        defaultNurseOperationDetail,
        nurseOperation,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Toolbar />
      <Paper className="m-1 p-1">{nurseOperationType}</Paper>
    </DefaultNurseOperationDetailContext.Provider>
  );
}
