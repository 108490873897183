import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';

import { useAsyncEffect } from 'utils';

import List from './BaseDefinition';
import { DefinitionContextFormType, DefinitionContextType, DefinitionListContext, ListItemTemplateProps } from './page.context';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: 'lightslategray',
  },
  dataName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

interface Props<T extends { id: string | number }> {
  fetchData(): Promise<T[]>;

  onDelete?(id: string | number): Promise<any>;

  listLabelPropName: keyof T;

  Icon: JSX.Element;

  FormDialog: React.ComponentType<DefinitionContextFormType<T>>;

  ListItemTemplate?: React.ComponentType<ListItemTemplateProps<T>>;

  searchName?: string;

  noDelete?: boolean;

  noSave?: boolean;

  noUpdate?: boolean;
}

function DefaultListItemTemplate<T extends { id: string | number }>({ item, Action }: ListItemTemplateProps<T>) {
  const classes = useStyles();
  const { listLabelPropName, Icon } = useContext<DefinitionContextType<T>>(DefinitionListContext);

  return (
    <React.Fragment>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>{Icon}</ListItemAvatar>
        <ListItemText color="primary">
          <span className={classes.dataName}>{item[listLabelPropName]}</span>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

export default function Index<T extends { id: string | number }>({
  fetchData,
  onDelete,
  listLabelPropName,
  Icon,
  FormDialog,
  ListItemTemplate = DefaultListItemTemplate,
  searchName,
  noSave,
  noUpdate,
  noDelete,
}: Props<T>) {
  const [data, setData] = useState<T[]>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<T>(null);
  const fetch = useCallback(async () => {
    const result = await fetchData();
    setData(result);
    setLoading(false);
  }, [fetchData]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <DefinitionListContext.Provider
      value={{
        data,
        reload: fetch,
        loading,
        setLoading,
        listLabelPropName,
        editDialogOpened,
        selectedData,
        setSelectedData,
        setEditDialogOpened,
        newDialogOpened,
        setNewDialogOpened,
        onDelete,
        Icon,
        FormDialog,
        ListItemTemplate,
        searchName,
        noSave,
        noUpdate,
        noDelete,
      }}>
      <List />
    </DefinitionListContext.Provider>
  );
}
