import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import Toolbar from '../../../Common/Toolbar';
import { CommitteeMembersContext } from '../committee-members.context';
import CommitteeMembersAdd from './Add';
import CommitteeMembersList from './List';

export default function CommitteeMembersListAdd() {
  const { committeeMembers } = useContext(CommitteeMembersContext);
  return (
    <>
      <Toolbar color="secondary">
        <Typography variant="h6">Kurul Üyeleri ( {committeeMembers?.length} )</Typography>
      </Toolbar>
      <CommitteeMembersAdd />
      <CommitteeMembersList />
    </>
  );
}
