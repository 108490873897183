import { createContext } from 'react';

import { CommitteeMeetingsContentResult } from 'api/hs/committee/committee-meetings';

export interface CommitteeMeetingsContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: CommitteeMeetingsContentResult;

  setSelectedData(data: CommitteeMeetingsContentResult): void;
}

export const CommitteeMeetingsContext = createContext<CommitteeMeetingsContextType>(null);
