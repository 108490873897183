import { createContext } from 'react';

import { FamilyHistoryModel } from 'api/hs/examination/family-history';

export interface FamilyHistoryContextType {
  familyHistory: FamilyHistoryModel[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: FamilyHistoryModel;

  setSelectedData(data: FamilyHistoryModel): void;
}

export const FamilyHistoryContext = createContext<FamilyHistoryContextType>(null);
