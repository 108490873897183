import React, { useCallback, useContext } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';

import { WorkEquipmentJobStatusContext } from '../../work-equipment-job-status.context';
import WorkEquipmentJobStatusEditForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(WorkEquipmentJobStatusContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [refresh, setEditDialogOpened]
  );

  return <WorkEquipmentJobStatusEditForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
