import React from 'react';

import InfoWorkEquipment from './Info';
import List from './List';
import Toolbar from './Toolbar';
export default function Index() {
  return (
    <>
      <Toolbar />
      <InfoWorkEquipment />
      <List />
    </>
  );
}
