import { createStyles, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React, {PropsWithChildren, useContext} from 'react';
import { LicenceContext } from "../context/licence";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100vh',
      backgroundImage: 'url("/images/login-background-transparent.jpg")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      padding: theme.spacing(4),
      width: `calc(100% - ${theme.spacing(4)}px)`,
      maxWidth: 384,
      margin: '0 auto',
    },
    title: {
      color: theme.palette.grey[400],
      fontWeight: 400,
      textAlign: 'center',
    },
  })
);

export default function Layout({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
    const { licence } = useContext(LicenceContext);

  return (
    <Grid container className={classes.root} alignContent="center" justifyContent="center">
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className="text-center">
            <img src={licence != undefined && licence.login_logo != undefined && licence.login_logo !== null?licence.login_logo:'/images/logo.png'} alt={licence != undefined && licence.name != undefined && licence.name && licence.name !== null?licence.name:'ARTI METRİK'} width={100} />
          </div>
          <Divider />
          <h3 className={classes.title}>İş Sağlığı ve Güvenliği</h3>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}
