import { createContext } from 'react';

import { PpeOutputsContentResult } from '../../api/hs/ppe/ppe-outputs';

export interface PpeOutputsContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  newReturnForm: boolean;

  setNewReturnForm(opened: boolean): void;

  selectedData: PpeOutputsContentResult;

  setSelectedData(data: PpeOutputsContentResult): void;
}

export const PpeOutputsContext = createContext<PpeOutputsContextType>(null);
