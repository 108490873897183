import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { PpeAssigmentResult, updatePpeAssignments } from 'api/hs/ppe/ppe-assignments';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PpeAssigmentResult;
}

export default function AssigmentPositionForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { positions, ppeDefinitions } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        const payload = {
          position: values.positionDefinitionIds?.map((positionId: any) => {
            return {
              assignmentType: 'POSITION',
              positionId,
            };
          }),
        };
        await updatePpeAssignments(values.ppeDefinitionId, payload.position);
        showMessage(' İşlem başarılı.', 'success', 'info');
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();
    formRef?.current?.methods?.setValue(
      'positionDefinitionIds',
      data?.ppeAssignments?.reduce((acc, item) => [...acc, item?.positionId], [])
    );
    formRef?.current?.methods?.setValue('ppeDefinitionId', data?.id);

    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog width="sm" title="GÖREVE GÖRE KKD ATAMA" color="secondary" opened={open} onClose={onClose.bind(null, false)}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <AutoComplete required single name="ppeDefinitionId" options={ppeDefinitions} label="KKD Tanım" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <AutoComplete required name="positionDefinitionIds" options={positions} label="Görevler" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
