import { Card, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import React, { useCallback, useContext } from 'react';

import { AccidentReviewControllerService, AccidentReviewCsiDto } from '../../../api/client';
import { AccidentReviewContext } from '../accident-review-context';
import ActionContainer from './ActionContainer';

const useStyles = makeStyles(theme => ({
  card: {
    color: theme.palette.grey[600],
    margin: 10,

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.light,
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

interface Props {
  data: AccidentReviewCsiDto;
  onClick(): void;
  fetch(): void;
}

export default ({ data, fetch, onClick }: Props) => {
  const styles = useStyles();
  const { token } = useContext(AccidentReviewContext);

  const onClickCancel = useCallback(() => {
    AccidentReviewControllerService.deleteCsi(data.id, token).then(fetch);
  }, [data.id, fetch, token]);

  return (
    <Grid xs={12}>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="h6" component="h6">
                #{data.orderNum}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />

            <Grid item xs={3}>
              <img src={`/saglik-gozetimi/${data.importedFile?.path}/${data.importedFile?.name}`} width={200} alt={data.fileType?.label} />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={1}>
              <Typography variant="h6" component="h6">
                {data.fileType?.label}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />

            <Grid item xs={5}>
              <Tooltip title={data.note}>
                <div>{data.note.substr(0, 50)}</div>
              </Tooltip>
            </Grid>

            <ActionContainer onClickCancel={onClickCancel} onClickPreview={onClick} />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
