import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { WorkEquipmentDefinitionContext } from '../work-equipment-definition.context';
import WorkEquipmentDefinitionForm from './Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(WorkEquipmentDefinitionContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [refresh, setEditDialogOpened]
  );

  return <WorkEquipmentDefinitionForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
