import { createContext } from 'react';

import { EmergencyCaseTeamDto } from '../../api/client';

export interface CaseTeamsContextType {
  emergencyManagementCaseTeams: EmergencyCaseTeamDto[];

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: EmergencyCaseTeamDto;

  setSelectedData(data: EmergencyCaseTeamDto): void;
}

export const CaseTeamsContext = createContext<CaseTeamsContextType>(null);
