import { createContext } from 'react';

import { PpeAssigmentResult } from 'api/hs/ppe/ppe-assignments';

export interface AssigmentEmployeeContextType {
  ppeAssignmentsEmployee: PpeAssigmentResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PpeAssigmentResult;

  setSelectedData(data: PpeAssigmentResult): void;
}

export const AssigmentEmployeeContext = createContext<AssigmentEmployeeContextType>(null);
