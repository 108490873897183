import { createContext } from 'react';

import { PrescriptionModel } from 'api/hs/examination';

export interface PrescriptionContextType {
  prescriptions: PrescriptionModel[];

  setPrescriptions(prescriptions: PrescriptionModel[]): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  previewData: string;

  setPreviewData(data: string): void;

  newEmailDialog: boolean;

  setEmailDialogOpened(opened: boolean): void;

  newPreviewDialog: boolean;

  setPreviewDialogOpened(opened: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  convertEPrescriptionDialogOpened: boolean;

  setConvertEPrescriptionEditDialogOpened(opened: boolean): void;

  newSecondaryDialogOpened: boolean;

  setNewSecondaryDialogOpened(opened: boolean): void;

  selectedPrescription: PrescriptionModel;

  setSelectedPrescription(data: PrescriptionModel): void;
}

export const PrescriptionContext = createContext<PrescriptionContextType>(null);
