import React from 'react';

import { getPpeDefinitionList } from 'api/hs/ppe/ppe-definitions';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import EditDialog from '../EditDialog';
import { COLUMNS } from './columns';
import ListTable from './ListTableData';
import Toolbar from './Toolbar';

export default function PPEList() {
  return (
    <>
      <HSTableAndPaginationAndTableContextProvider
        fetcher={(page, size, sorting) => getPpeDefinitionList(page, size, sorting)}
        columns={COLUMNS}>
        <Toolbar />
        <ListTable />
        <EditDialog />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
