import { Button, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { useHistory } from 'react-router';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';

import { IbysTrainingsModel, updateTrainingIbys } from '../../../api/hs/trainings/ibys-trainings';
import { BaseIbysTrainingPlanningPayload } from '../../../api/hs/trainings/ibys-trainings';
import DateInput from '../../../Common/Form/DateInput';
import { EmployeeSelectionCacheContext } from '../../../Common/Form/EmployeeSelection/employee-selection.context';
import Select, { SelectOption } from '../../../Common/Form/Select';
import TextField from '../../../Common/Form/TextField';
import ToggleButtonGroup from '../../../Common/Form/ToggleButtonGroup';
import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { AlertContext } from '../../../context/alert.context';
import { SelectOptionsContext } from '../../../context/select-options.context';
import { NID_MASK } from '../../../model';
import Avatar from '../../../TransactionsAndLists/EmployeeList/Avatar';
import { useAsyncEffect } from '../../../utils';

interface OptionExtra {
  color: string;
}
const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Gönder', value: 1, color: '#469789' },
  { text: 'Gönderme', value: 0, color: '#eb4355' },
];

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: IbysTrainingsModel;
}

export default function EditFormMinistryList({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { companies, trainingLocation, trainingType, trainerType } = useContext(SelectOptionsContext);
  const { loadEmployees, avatars, names } = useContext(EmployeeSelectionCacheContext);
  const { showMessage } = useContext(AlertContext);
  const { refresh } = useContext(HSTableContext);
  const history = useHistory();

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();
    await loadEmployees(...data.ibysTrainingEmployees?.map((employee: string) => employee.employeeId));

    data?.ibysTrainingEmployees?.map(
      async employeeParticipated =>
        await formRef?.current?.methods?.setValue(employeeParticipated?.employeeId + 'toogle', employeeParticipated?.isParticipated ? 1 : 0)
    );
    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key as any, value);
    }
    await formRef?.current?.methods?.trigger();
  }, [data]);

  const IbysData = useCallback(
    async values => {
      const employeeData = Object.entries(values)
        .filter(([key]) => key.includes('toogle'))
        .reduce((acc, [key, value]) => {
          return [
            ...acc,
            {
              employeeId: key.substr(0, 36),
              isParticipated: value ? true : false,
            },
          ];
        }, []);

      const payload: BaseIbysTrainingPlanningPayload = {
        title: values.title,
        date: values.date,
        atWorkplace: values.atWorkplace,
        ibysTrainingEmployees: employeeData,
        companyId: values.companyId,
        isFaceToFace: values.isFaceToFace,
        ohsProfessionalNid: values.ohsProfessionalNid,
        trainerNid: values.trainerNid,
        trainerType: values.trainerType,
      };

      try {
        await updateTrainingIbys(data?.id, payload);
        showMessage('Ibys bilgileri başarılı şekilde güncellendi.', 'success');
        onClose();
        await refresh();
        history.push('/training/ministry-training-list');
      } catch (e) {
        showMessage('Güncelleme sırasında hata oluştu.', 'error');
      }
    },
    [data, showMessage, history, onClose, refresh]
  );

  return (
    <Dialog color="primary" title="Bakanlık Takip Formu Düzenle" opened={open} onClose={onClose}>
      <Form onSubmit={IbysData} ref={formRef}>
        <Grid container className="p-2">
          <Grid item xs={12} sm={6} className="pr-sm-1">
            <TextField name="title" label="Eğitim Adı" required />
          </Grid>
          <Grid item xs={12} sm={6} className="pr-sm-1">
            <Select name="atWorkplace" options={trainingLocation} label="Eğitim Yeri" required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <Select name="isFaceToFace" options={trainingType} label="Eğitim Türü" required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <DateInput name="date" type="date" label="Eğitim Tarihi" required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <Select name="companyId" options={companies} label="Şirket" required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <TextField mask={NID_MASK} name="trainerNid" label="Eğitici T.C." required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <TextField name="ohsProfessionalNid" label="İSG Profesyonel T.C." required />
          </Grid>
          <Grid item xs={12} sm={6} className="pl-sm-1">
            <Select required name="trainerType" options={trainerType} label="Eğitmen Türü" />
          </Grid>
          <Grid item xs={12} sm={12} className="pl-sm-1">
            <Typography variant="h6" color="inherit">
              Katılımcı Listesi
            </Typography>
            <Divider />
          </Grid>
          <Grid className="mt-2 text-center" item xs={12} lg={12} sm={12}>
            <Typography color="error" variant="overline" display="block" gutterBottom>
              Sadece gÖNDER işaretlenenler bakanlığa gönderilecek.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="p-2">
          {data?.ibysTrainingEmployees?.map(employee => (
            <>
              <Grid className="pl-sm-1 pt-1" item sm={6} md={6} lg={6}>
                <Chip avatar={<Avatar url={avatars[employee.employeeId]} />} label={names[employee?.employeeId]} />
              </Grid>
              <Grid className="pl-sm-1 mb-1" item sm={6} md={6} lg={6}>
                <ToggleButtonGroup<number, OptionExtra>
                  name={employee.employeeId + 'toogle'}
                  options={OPTIONS}
                  defaultValue={1}
                  renderOption={({ color, selected, text, value }) => (
                    <ToggleButton
                      value={value}
                      className={selected ? 'text-white' : ''}
                      style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                      {text}
                    </ToggleButton>
                  )}
                />
              </Grid>
            </>
          ))}
        </Grid>
        <Divider />
        <footer className="text-right p-2">
          <Button className="mr-1" type="button" variant="contained" onClick={onClose}>
            Vazgeç
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Güncelle
          </Button>
        </footer>
      </Form>
    </Dialog>
  );
}
