import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useCallback, useContext } from 'react';

import { AnalysisAudiometryModel, AnalysisDetailAggregate, PulmonaryDetailsAggregate } from 'api/hs/examination/analysis';

import Button from '../../../../../../Common/Button';
import { EnumContext } from '../../../../../../context/enum.context';
import { PARAMETER_NAMES } from '../audiometry-parameters';
import { Category } from '../category.enum';
import { PulmonaryQuestion } from '../pulmonary-question.enum';

interface Props {
  list: AnalysisDetailAggregate[];

  audiometryResultList: AnalysisAudiometryModel;

  pftDetailList: PulmonaryDetailsAggregate[];

  analysisCategory: string;
}

const useStyles = makeStyles(theme => ({
  cellFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 0,
  },
  cellRoot: {
    paddingLeft: 0,
  },
  bolder: {
    fontWeight: 'bold',
  },
  styledTextField: {
    marginTop: '16px',
  },
  bgGray: {
    backgroundColor: '#f2f2f2',
  },
}));

const renderCommonTable = (list: AnalysisDetailAggregate[], classes: any, hasDescriptionField: boolean) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.cellRoot }} width="300px">
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Parametre
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Sonuç
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Birim
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Referans
            </Typography>
          </TableCell>
          {hasDescriptionField && (
            <TableCell classes={{ root: classes.cellRoot }}>
              <Typography color="inherit" classes={{ root: classes.bolder }}>
                Açıklama
              </Typography>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {list
          ?.filter(resultData => resultData.result)
          ?.map((item, index) => (
            <TableRow key={`${item.id}${index}`} classes={index % 2 !== 0 ? { root: classes.bgGray } : null}>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                <b>{item.parameter}</b>
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
                {item.result && (
                  <Typography
                    variant="body2"
                    className={classes.cellFlex}
                    color={
                      item.min !== 0 && item.max !== 0
                        ? item.result < item.min || item.result > item.max
                          ? 'error'
                          : 'inherit'
                        : 'inherit'
                    }>
                    {item.result}
                    {item.min !== 0 && item.max !== 0 && item.result < item.min && <ArrowDownwardIcon className="ml-1" />}
                    {item.min !== 0 && item.max !== 0 && item.result > item.max && <ArrowUpwardIcon className="ml-1" />}
                  </Typography>
                )}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.analysisParameterUnit}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.min} - {item.max}
              </TableCell>
              {hasDescriptionField && (
                <TableCell classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
                  {item.opinion}
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </>
  );
};

const renderPulmonaryTable = (pftDetailList: PulmonaryDetailsAggregate[], classes: any) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.cellRoot }} width="300px">
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Parametre
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              PRED
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              ACT
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Birim
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              %PRED
            </Typography>
          </TableCell>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              NAS
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {pftDetailList
          ?.filter(a => !(a.parameter === PulmonaryQuestion.Question1ID) && !(a.parameter === PulmonaryQuestion.Question2ID))
          ?.map((item, index) => (
            <TableRow key={`${item.parameterDefinitionId}${index}`} classes={index % 2 !== 0 ? { root: classes.bgGray } : null}>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                <b>{item.parameter}</b>
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
                {item.pred}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.act}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.analysisParameterUnit}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.predPercent}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.nas}
              </TableCell>
            </TableRow>
          ))}
        {pftDetailList
          ?.filter(a => a.parameter === PulmonaryQuestion.Question1ID || a.parameter === PulmonaryQuestion.Question2ID)
          ?.sort((a, b) => {
            return a.parameter.localeCompare(b.parameter);
          })
          ?.map((item, index) => (
            <TableRow key={`${item.parameterDefinitionId}${index}`}>
              <TableCell style={{ fontWeight: 'italic' }} classes={{ root: classes.cellRoot }} colSpan={5} align="left">
                <b>{item?.parameter === PulmonaryQuestion.Question1ID ? PulmonaryQuestion.Question1 : PulmonaryQuestion.Question2}</b>
              </TableCell>
              <TableCell align="left">
                <Button
                  className="text-white text-bold"
                  style={{ width: '100%', backgroundColor: `${item?.nas === 0 ? '#469789' : '#eb4355'}` }}
                  variant="contained"
                  disabled>
                  {item.nas === 0 ? 'YOK' : 'VAR'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </>
  );
};

const renderAudiometryTable = (audiometryResultList: AnalysisAudiometryModel, classes: any) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.cellRoot }}>
            <Typography color="inherit" classes={{ root: classes.bolder }} />
          </TableCell>
          {PARAMETER_NAMES.map((item, index) => (
            <TableCell key={`audiometry-head-item-${index}`} classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell classes={{ root: classes.cellRoot }} align="left">
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Sol
            </Typography>
          </TableCell>
          {Object.values(audiometryResultList?.leftEar).map((item, index) => (
            <TableCell key={`left-ear-item-${index}`} classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
              {item}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell classes={{ root: classes.cellRoot }} align="left">
            <Typography color="inherit" classes={{ root: classes.bolder }}>
              Sağ
            </Typography>
          </TableCell>
          {Object.values(audiometryResultList?.rightEar).map((item, index) => (
            <TableCell key={`right-ear-item-${index}`} classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
              {item}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </>
  );
};

export default function ParameterList({ list, audiometryResultList, pftDetailList, analysisCategory }: Props) {
  const classes = useStyles();
  const { analysisCategoryDefinitions } = useContext(EnumContext);
  const hasDescriptionField = list?.find(param => param.hasOwnProperty('opinion')) ? true : false;

  const renderTable = () => {
    if (findByKey(analysisCategoryDefinitions, 'id', analysisCategory)?.label === Category.Audiometry) {
      if (!audiometryResultList.leftEar || !audiometryResultList.rightEar) return null;

      return renderAudiometryTable(audiometryResultList, classes);
    } else if (findByKey(analysisCategoryDefinitions, 'id', analysisCategory)?.label === Category.Pulmonary) {
      if (!pftDetailList || pftDetailList.length === 0) return null;

      return renderPulmonaryTable(pftDetailList, classes);
    } else {
      if (!list || list.length === 0) return null;

      return renderCommonTable(list, classes, hasDescriptionField);
    }
  };

  return (
    <TableContainer>
      <Table size="small">{renderTable()}</Table>
    </TableContainer>
  );
}
