/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationRequest } from '../models/NotificationRequest';
import type { PageReferralView } from '../models/PageReferralView';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { ReferralViewRequest } from '../models/ReferralViewRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ReferralViewControllerService {

    /**
     * @param requestBody
     * @returns PageReferralView OK
     * @throws ApiError
     */
    public static getReferralListFiltered(
        requestBody: ReferralViewRequest,
    ): CancelablePromise<PageReferralView> {
        return __request({
            method: 'POST',
            path: `/referrals/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static referralDetailSendMail(
        requestBody: NotificationRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/referrals/detail-view/notification`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static referralDetailViewExport(
        id: string,
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/referrals/${id}/detail-view/export`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param psRequestParam
     * @returns PageReferralView OK
     * @throws ApiError
     */
    public static getReferralList(
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageReferralView> {
        return __request({
            method: 'GET',
            path: `/referrals/view`,
            query: {
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static referralViewExport(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/referrals/view/export`,
            query: {
                'type': type,
            },
        });
    }

}