import { createContext } from 'react';

import { TypeDefinitionResult } from 'api/hs/definitions/ppe-definitions/type-definitions';

export interface TypeDefinitionsContextType {
  typeDefinitions: TypeDefinitionResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: TypeDefinitionResult;

  setSelectedData(data: TypeDefinitionResult): void;
}

export const TypeDefinitionsContext = createContext<TypeDefinitionsContextType>(null);
