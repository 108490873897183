export function dateToInputFormat(date: Date): string {
  if (!date) return null;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function timeToInputFormat() {
  return new Date().toLocaleTimeString('tr-TR');
}

export function timePrettier(hour: string): string {
  if (!hour) return hour;
  return hour.substr(0, 5);
}

export function datePrettier(date: any): string {
  if (!date) return '';

  if (typeof date === 'string') {
    return date.split('-').reverse().join('/');
  }

  return [date.getUTCDate(), date.getUTCMonth(), date.getFullYear()].join('/');
}

export function dateTimePrettier(date: Date): string {
  if (!date) return '';

  return [date.getUTCDate(), date.getUTCMonth(), date.getFullYear()].join('/') + ' ' + date.toTimeString().substr(0, 5);
}

export function minusDays(date: Date, days: number): Date {
  const _date = new Date(date);
  _date.setDate(_date.getDate() - days);
  return _date;
}

export function plusDays(date: Date, days: number): Date {
  const _date = new Date(date);
  _date.setDate(_date.getDate() + days);
  return _date;
}

export function dateDisplayFormat(date: string): string {
  return date?.split('-')?.reverse()?.join('-');
}
