import React, { useState } from 'react';

import { FirmUsersModel, getFirmUsers } from 'api/hs/definitions/firm-definitions/firm-users';
import { useFetch } from 'utils';

import { FirmUsersContext } from './firm-users.context';
import FirmUsers from './firmUsers';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [selectedFirmId, setSelectedFirmId] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<FirmUsersModel>(null);

  const { data, fetch } = useFetch(async () => (selectedFirmId ? await getFirmUsers(selectedFirmId) : []), [], {
    setLoading,
    deps: [selectedFirmId],
    reloadDeps: [selectedFirmId],
  });

  return (
    <FirmUsersContext.Provider
      value={{
        firmUsers: data,
        reload: fetch,
        loading,
        selectedFirmId,
        setSelectedFirmId,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <FirmUsers />
    </FirmUsersContext.Provider>
  );
}
