import React from 'react';
import { Route, Switch } from 'react-router';

import RiskAssessmentForm from './RiskAssessmentForm';
import RiskAssessmentsListAdd from './RiskAssessments';

export default function RiskAssessments() {
  return (
    <Switch>
      <Route exact path="/risk/risk-assessments-form">
        <RiskAssessmentForm />
      </Route>
      <Route exact path="/risk/matrix">
        <RiskAssessmentsListAdd riskAssessmentType="MATRIX" />
      </Route>
      <Route exact path="/risk/fine-kinney">
        <RiskAssessmentsListAdd riskAssessmentType="FINE_KINNEY" />
      </Route>

      <Route exact path="/risk/risk-assessments-form/:rowRiskAssessmentId">
        <RiskAssessmentForm />
      </Route>
    </Switch>
  );
}
