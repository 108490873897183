import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { VaccineViewControllerService, VaccineViewRequest } from '../../api/client';
import { FormRef } from '../../Common/Form/Form';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function VaccineReport() {
  const [chartData, setChartData] = useState<VaccineViewRequest[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const { accidentReviewBehaviorDefinition } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request: VaccineViewRequest = {
      filter: {
        dateMin: values?.minDate,
        dateMax: values?.maxDate,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'date',
      },
    };
    const res = await VaccineViewControllerService.getVaccineListFiltered(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Aşı İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && dataDisplayOption != null && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirketlere Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="vaccine-company-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="companyName"
                  xAxesTitle="Şirketler"
                  xAxesText="Tarih"
                  yAxesText="Aşı Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title=" Departmanlara Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="vaccine-department-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="departmentName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Tarih"
                  yAxesText="Aşı Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title=" Aşılara Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="vaccine-name-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="vaccineName"
                  xAxesTitle="Aşı Türleri"
                  xAxesText="Tarih"
                  yAxesText="Aşı Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
