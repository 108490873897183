import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { saveVitalSign, updateVitalSign, VitalSignModel } from 'api/hs/examination/vital-sign';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { dateToInputFormat, timeToInputFormat, useAsyncEffect } from 'utils';

import DateInput from '../../../../../../../Common/Form/DateInput';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: VitalSignModel;
}

export default function DialogVitalSignForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, getValues, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId, nurseOperationId } = useParams<{
    employeeId: string;
    examinationId: string;
    nurseOperationId: string;
  }>();
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveVitalSign({ ...values, employeeId, examinationId, nurseOperationId });
          showMessage('Ekleme işlemi başarılı.', 'success');
        } else {
          await updateVitalSign({ id: data.id, ...values, employeeId, examinationId, nurseOperationId });
          showMessage('Güncelleme işlemi başarılı.', 'success');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error');
        onClose(false);
      }
    },
    [data, onClose, showMessage, employeeId, examinationId, nurseOperationId]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      await trigger();
    } else {
      reset();
      await defer();
      setValue('measurementDate', dateToInputFormat(new Date()));
      setValue('measurementTime', timeToInputFormat());
      await trigger();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 550 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Vital Ölçüm Düzenleme' : 'Vital Ölçüm Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={6} sm={6} lg={6}>
                <DateInput required type="date" name="measurementDate" label="Ölçüm Tarihi" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <DateInput type="time" name="measurementTime" label="Ölçüm Saati" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField type="number" name="systolic" label="Kan Basıncı Sistolik(mmHg)" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <TextField type="number" name="diastolic" label="Kan Basıncı Diastolik(mmHg)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="pulseRate" label="Nabız(../dk)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="bodyTemperature" label="Ateş (℃)" />
              </Grid>
              <Grid item md={4} sm={4} lg={4}>
                <TextField type="number" name="respirationRate" label="Solunum(../dk)" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
