import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { HazardCategoryDefinitionControllerService } from '../../../../api/client';
import { HazardCategoryDefinitionDto } from '../../../../api/client';
import { updateRiskAssessmentsHazardCategoryDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: HazardCategoryDefinitionDto;

  onClose(success: boolean): void;
}

export default function HazardCategoryDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<HazardCategoryDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={HazardCategoryDefinitionControllerService.saveHazardCategoryDefinitions1}
      onUpdate={updateRiskAssessmentsHazardCategoryDefinitionUpdate}
      data={data}
      createTitle="Tehlike Ekleme"
      updateTitle="Tehlike Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Tehlike Kategorisi" />
      </Grid>
    </BaseDefinitionForm>
  );
}
