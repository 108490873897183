import { createContext } from 'react';

import { PpeStocksContentResult } from 'api/hs/ppe/ppe-stocks';

export interface PpeStocksContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PpeStocksContentResult;

  setSelectedData(data: PpeStocksContentResult): void;
}

export const PpeStocksContext = createContext<PpeStocksContextType>(null);
