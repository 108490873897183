import { createContext } from 'react';

interface PpeOutputData {
  employeeId: string;
  ppeDefinitionId: string;
  count: string;
  outputDate: string;
  stockId: string;
}

export interface PpeNewOutputContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  ppeOutputsData: PpeOutputData[];

  setPpeOutputsData(data: any[]): PpeOutputData[];

  loading: boolean;

  setLoading(loading: boolean): void;
}

export const PpeNewOutputContext = createContext<PpeNewOutputContextType>(null);
