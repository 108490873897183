/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenericUserEventResponse = {
    employeeId?: string;
    employeeName?: string;
    date?: string;
    color?: string;
    durationOfMinutes?: {
        seconds?: number;
        nano?: number;
        negative?: boolean;
        zero?: boolean;
        units?: Array<{
            dateBased?: boolean;
            timeBased?: boolean;
            durationEstimated?: boolean;
        }>;
    };
    userEventType?: GenericUserEventResponse.userEventType;
    description?: string;
    label?: string;
    referenceTableId?: string;
    isDone?: boolean;
}

export namespace GenericUserEventResponse {

    export enum userEventType {
        PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
        VACCINE = 'VACCINE',
        TRAINING = 'TRAINING',
        USER_DEFINED = 'USER_DEFINED',
        COMMITTEE_MEETING = 'COMMITTEE_MEETING',
        PREGNANCY = 'PREGNANCY',
        ACTION = 'ACTION',
        DRILL = 'DRILL',
        RISK_ASSESSMENT = 'RISK_ASSESSMENT',
        PPE = 'PPE',
    }


}
