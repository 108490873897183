import { Button, FormControl, FormHelperText, Input } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { login } from 'api/hs/authentication/login';
import { UserContext } from 'context/user';
import { email } from 'validators';

import { AlertContext } from '../context/alert.context';
import InputLabel from './InputLabel';
import Layout from './Layout';

export default function Login() {
  const { register, errors, trigger, handleSubmit } = useForm();
  const { setUser } = useContext(UserContext);
  const history = useHistory();
  const { showMessage } = useContext(AlertContext);

  function validate() {
    trigger();
  }

  async function handleLogin(value: { email: string; password: string }) {
    try {
      const user = await login(value);
      showMessage('Giriş Başarılı', 'success', 'info');
      setUser(user);
      history.push('/');
    } catch (e) {
      showMessage('e-posta ya da şifre yanlış tekrar deneyiniz.', 'error', 'info');
    }
  }

  return (
    <Layout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
        <FormControl fullWidth className="mb-2" error={!!errors.email}>
          <InputLabel htmlFor="email">
            <AccountCircleIcon className="mr-2" />
            E-Posta Adresinizi Giriniz
          </InputLabel>
          <Input
            id="email"
            name="email"
            className="mt-3"
            inputRef={register({
              required: 'Bu alan zorunlu',
              validate: { email },
            })}
            onChange={validate}
          />
          <FormHelperText>{errors.email?.message}</FormHelperText>
        </FormControl>
        <FormControl fullWidth className="mb-2" error={!!errors.password}>
          <InputLabel htmlFor="password">
            <VpnKeyIcon className="mr-2" />
            Şifrenizi Giriniz
          </InputLabel>
          <Input
            id="password"
            name="password"
            type="password"
            className="mt-3"
            inputRef={register({
              required: 'Bu alan zorunlu',
            })}
            onChange={validate}
          />
          <FormHelperText>{errors.password?.message}</FormHelperText>
        </FormControl>
        <footer className="text-center">
          <div className="mb-2">
            <Button variant="contained" color="primary" className="px-4" disabled={!!Object.keys(errors).length} type="submit">
              GİRİŞ YAP
            </Button>
          </div>
          <div>
            Şifrenizi mi unuttunuz?
            <Link to="/sifremi-unuttum" className="ml-1">
              Yeni şifre »
            </Link>
          </div>
        </footer>
      </form>
    </Layout>
  );
}
