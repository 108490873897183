/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageRiskAssessmentHazardView } from '../models/PageRiskAssessmentHazardView';
import type { RiskAssessmentHazardFilterDto } from '../models/RiskAssessmentHazardFilterDto';
import type { RiskAssessmentHazardView } from '../models/RiskAssessmentHazardView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RiskAssessmentHazardViewControllerService {

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static riskAssessmentViewExport(
        requestBody: RiskAssessmentHazardFilterDto,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/hazards/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageRiskAssessmentHazardView OK
     * @throws ApiError
     */
    public static filterRiskAssessmentHazards(
        requestBody?: RiskAssessmentHazardFilterDto,
    ): CancelablePromise<PageRiskAssessmentHazardView> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/hazards/view`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns RiskAssessmentHazardView OK
     * @throws ApiError
     */
    public static getRiskAssessmentHazardView(
        id: string,
    ): CancelablePromise<RiskAssessmentHazardView> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/hazards/views/${id}`,
        });
    }

}