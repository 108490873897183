/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfessionalBackgroundDto } from '../models/ProfessionalBackgroundDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ProfessionalBackgroundControllerService {

    /**
     * @param id
     * @returns ProfessionalBackgroundDto OK
     * @throws ApiError
     */
    public static getProfessionalBackground(
        id: string,
    ): CancelablePromise<ProfessionalBackgroundDto> {
        return __request({
            method: 'GET',
            path: `/professional-backgrounds/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ProfessionalBackgroundDto OK
     * @throws ApiError
     */
    public static updateProfessionalBackground(
        id: string,
        requestBody: ProfessionalBackgroundDto,
    ): CancelablePromise<ProfessionalBackgroundDto> {
        return __request({
            method: 'PUT',
            path: `/professional-backgrounds/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllProfessionalBackground(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/professional-backgrounds/${id}`,
        });
    }

    /**
     * @param employeeId
     * @param examinationId
     * @returns ProfessionalBackgroundDto OK
     * @throws ApiError
     */
    public static getAllProfessionalBackgrounds(
        employeeId: string,
        examinationId?: string,
    ): CancelablePromise<Array<ProfessionalBackgroundDto>> {
        return __request({
            method: 'GET',
            path: `/professional-backgrounds`,
            query: {
                'employeeId': employeeId,
                'examinationId': examinationId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ProfessionalBackgroundDto OK
     * @throws ApiError
     */
    public static saveProfessionalBackground(
        requestBody: ProfessionalBackgroundDto,
    ): CancelablePromise<ProfessionalBackgroundDto> {
        return __request({
            method: 'POST',
            path: `/professional-backgrounds`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}