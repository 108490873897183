import React from 'react';

import StorehouseDefinitionsAdd from './Add';
import StorehouseDefinitionsList from './List';

export default function Index() {
  return (
    <>
      <StorehouseDefinitionsAdd />
      <StorehouseDefinitionsList />
    </>
  );
}
