import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';

import { ActionsContentResult, saveActions, updateActions } from 'api/hs/actions';
import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import { EmployeeSelectionCacheContext } from 'Common/Form/EmployeeSelection/employee-selection.context';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import Switch from 'Common/Form/Switch';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useTriggerRender } from 'utils';

interface Props {
  open: boolean;
  data: ActionsContentResult;
  isReferenceDefinitions?: string;
  referenceId?: string;
  nearMissId?: string;
  actionReferenceValue: string;

  onClose(success: boolean): void;
}

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

function renderFormItems(value: boolean, dataCompletedDate: string): JSX.Element {
  if (value) {
    return (
      <>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <DateInput defaultValue={dataCompletedDate} label="Tamamlanma Tarihi" name="completedDate" type="date" />
        </Grid>
      </>
    );
  } else {
    return;
  }
}

export default function ActionsFormDialog({ open, onClose, data, referenceId, actionReferenceValue }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { actionsPriorityDefinitions, actionsStatusDefinitions, actionsReferenceDefinitions } = useContext(SelectOptionsContext);
  const { loadEmployees } = useContext(EmployeeSelectionCacheContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();
  const { committeeMeetingId } = useParams<{ committeeMeetingId: string }>();
  const { accidentId } = useParams<{ accidentId: string }>();

  const submit = useCallback(
    async values => {
      const payload = {
        description: values?.description,
        referenceId: data ? data?.referenceId : committeeMeetingId ? committeeMeetingId : accidentId ? accidentId : referenceId,
        actionPriorityDefinition: values?.actionPriorityDefinition,
        actionStatusDefinition: values?.actionStatusDefinition,
        actionReference: values?.actionReference,
        plannedEndDate: values?.plannedEndDate,
        completedDate: values?.completedDate ?? null,
        actionEmployees: values?.employeeIds?.map((employeeId: string) => {
          return {
            employeeId,
          };
        }),
      };
      try {
        if (!data?.id) {
          await saveActions(payload);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await updateActions(data?.id, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, committeeMeetingId, accidentId, referenceId, showMessage, onClose]
  );

  useAsyncEffect(async () => {
    if (!data) {
      if (referenceId) {
        await defer();
        formRef?.current?.methods?.setValue('actionReference', actionReferenceValue);
        formRef?.current?.methods?.trigger();
      }
      return;
    }
    await defer();
    loadEmployees(...data.employees?.map((employee: any) => employee?.id));

    formRef?.current?.methods?.setValue(
      'employeeIds',
      data?.employees?.reduce((acc, item) => [...acc, item?.id], [])
    );
    formRef?.current?.methods?.setValue('isCompleted', !!data?.completedDate);

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    formRef?.current?.methods?.trigger();
  }, [data, open, committeeMeetingId, accidentId]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title={data ? 'Aksiyon Düzenle' : 'Aksiyon Ekle'}>
        <Form onChange={reRender} onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete required
                name="employeeIds"
                label="Aksiyon Atanacak Personel"
                optionFetcher={employeeOptionFetcher}
                labelFetcher={employeeLabelFetcher}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField rows={3} name="description" label="Aksiyonun Tanımı" required />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete single name="actionPriorityDefinition" options={actionsPriorityDefinitions} label="Öncelik Durumu " required />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete single name="actionStatusDefinition" options={actionsStatusDefinitions} label="Durumu " required />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete disabled={true} single name="actionReference" options={actionsReferenceDefinitions} label="Aksiyon Kaynağı " />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="Termin Tarih" name="plannedEndDate" type="date" required />
            </Grid>
            <Grid item className="mt-1" md={12} lg={12} sm={12}>
              <Switch defaultValue={false} name="isCompleted" label="Aksiyon Tamamlandı." />
            </Grid>
            {renderFormItems(formRef?.current?.value?.isCompleted, data?.completedDate)}
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
