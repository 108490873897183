import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetBranchCodesResult } from './get-branch-codes.result';

export async function getBranchCodes(): Promise<GetBranchCodesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetBranchCodesResult[]>(`/prescriptions/prescription-definitions/branch-codes`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
