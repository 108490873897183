import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import { useTranslate } from 'utils';

import { FormContext } from '../Form/form.context';
import { EmployeeSelectionCacheContext, EmployeeSelectionContext, EmployeeSelectionContextType } from './employee-selection.context';

export interface EmployeeSelectionContextProviderProps {
  name: string;
  label?: string;
  single?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export default function EmployeeSelectionContextProvider({
  name,
  disabled = false,
  single = false,
  label = 'Employee Selection',
  placeholder = 'Search Employee',
  children,
}: PropsWithChildren<EmployeeSelectionContextProviderProps>) {
  const { readonly } = useContext(FormContext);
  const translate = useTranslate('form');
  const { getEmployees } = useContext(EmployeeSelectionCacheContext);

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);
  const translatedLabel = useMemo(() => translate(label), [translate, label]);
  const translatedPlaceholder = useMemo(() => translate(placeholder), [translate, placeholder]);

  const context = useMemo<EmployeeSelectionContextType>(
    () => ({ disabled: notEditable, name, single, label: translatedLabel, placeholder: translatedPlaceholder }),
    [notEditable, name, single, translatedLabel, translatedPlaceholder]
  );

  useEffect(() => {
    getEmployees('');
  }, []); // eslint-disable-line

  return <EmployeeSelectionContext.Provider value={context}>{children}</EmployeeSelectionContext.Provider>;
}
