import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_tr_TR from '@amcharts/amcharts4/lang/tr_TR';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import React, { useLayoutEffect, useRef } from 'react';

import { convertDataToChartDataByDateAndFieldName } from './date-clustered-chart-data-converter-xy';
import { DisplayOption, displayOptionMap } from './display-option';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

interface ChartProps {
  chartName: string;
  dataDisplayOption: DisplayOption;
  data?: any[];
  finalData?: any[];
  selectorFieldByName?: string;
  selectorFieldByIdNamePair?: Map<string, string>;
  dateFieldName: string;
  xAxesText: string;
  yAxesText: string;
  xAxesTitle: string;
  stacked?: boolean;
}

const DateGroupXYChart = (props: ChartProps) => {
  const chart = useRef(null);
  let convertedChartData: any;
  if (props.finalData) {
    convertedChartData = props.finalData;
  } else {
    convertedChartData = convertDataToChartDataByDateAndFieldName(props.data, props.dateFieldName, props.selectorFieldByName);
  }

  useLayoutEffect(() => {
    const chartRoot = am4core.create(props.chartName, am4charts.XYChart);
    chartRoot.language.locale = am4lang_tr_TR;
    chartRoot.data = convertedChartData;

    const dateAxis = chartRoot.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = 'date';
    dateAxis.groupData = true;
    dateAxis.title.text = props.xAxesTitle;
    dateAxis.groupInterval = displayOptionMap.get(props.dataDisplayOption)?.amcTimeInterval;

    const valueAxis = chartRoot.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = props.yAxesText;
    valueAxis.maxPrecision = 0;

    const selectorFieldArray: string[][] = [];
    chartRoot.data.forEach(obj => {
      selectorFieldArray.push(Object.keys(obj).filter(o => o !== 'date'));
    });
    const concatenatedCompanyArr = selectorFieldArray.reduce((previousValue, currentValue) => {
      return previousValue.concat(currentValue);
    }, []);
    const companyNames = new Set([...concatenatedCompanyArr]);

    companyNames.forEach(vaccineName => {
      createSeries(vaccineName, vaccineName, props.stacked ? props.stacked : false);
    });

    function createSeries(field: string, name: string, stacked: boolean) {
      const series = chartRoot.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.groupFields.valueY = 'sum';
      series.stacked = stacked;
      series.name = name;
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
      series.stacked = stacked;
      series.minWidth = 30;
      series.columns.template.minWidth = 20;
    }

    chartRoot.paddingRight = 20;
    chartRoot.exporting.menu = new am4core.ExportMenu();
    chartRoot.legend = new am4charts.Legend();
    chartRoot.cursor = new am4charts.XYCursor();

    chart.current = chartRoot;
    return () => {
      chartRoot.dispose();
    };
  }, [
    props.data,
    props.dataDisplayOption,
    convertedChartData,
    props.chartName,
    props.dateFieldName,
    props.xAxesTitle,
    props.yAxesText,
    props.stacked,
  ]);

  return <div id={props.chartName} style={{ width: '100%', height: '600px' }} />;
};

export default DateGroupXYChart;
