/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NurseOperationViewRequest } from '../models/NurseOperationViewRequest';
import type { PageNurseOperationView } from '../models/PageNurseOperationView';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class NurseOperationViewControllerService {

    /**
     * @param requestBody
     * @returns PageNurseOperationView OK
     * @throws ApiError
     */
    public static getNurseOperationListFiltered(
        requestBody: NurseOperationViewRequest,
    ): CancelablePromise<PageNurseOperationView> {
        return __request({
            method: 'POST',
            path: `/nurse-operations/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param psRequestParam
     * @returns PageNurseOperationView OK
     * @throws ApiError
     */
    public static getNurseOperationList(
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageNurseOperationView> {
        return __request({
            method: 'GET',
            path: `/nurse-operations/view`,
            query: {
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static nurseOperationsViewExport(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/nurse-operations/view/export`,
            query: {
                'type': type,
            },
        });
    }

}