/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeDto } from '../models/EmployeeDto';
import type { SearchEmployeeResponse } from '../models/SearchEmployeeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmployeeSearchGuestControllerService {

    /**
     * @param id
     * @param authenticationInfo
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployee(
        id: string,
        authenticationInfo?: string,
    ): CancelablePromise<EmployeeDto> {
        return __request({
            method: 'GET',
            path: `/guest/employees/${id}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
        });
    }

    /**
     * @param ids
     * @param authenticationInfo
     * @returns EmployeeDto OK
     * @throws ApiError
     */
    public static getEmployeeList(
        ids: Array<string>,
        authenticationInfo?: string,
    ): CancelablePromise<Array<EmployeeDto>> {
        return __request({
            method: 'GET',
            path: `/guest/employees/pages`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @param keyword
     * @param authenticationInfo
     * @returns SearchEmployeeResponse OK
     * @throws ApiError
     */
    public static searchEmployees(
        keyword: string,
        authenticationInfo?: string,
    ): CancelablePromise<Array<SearchEmployeeResponse>> {
        return __request({
            method: 'GET',
            path: `/guest/employees/list`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'keyword': keyword,
            },
        });
    }

}