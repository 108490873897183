import React from 'react';

import ActionsList from './List';
export default function Index() {
  return (
    <>
      <ActionsList />
    </>
  );
}
