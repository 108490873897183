import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { MatrixPossibilityDefinitionControllerService } from 'api/client';
import { MatrixPossibilityDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import MatrixPossibilityDefinitionForm from './Forms/MatrixPossibilityDefinitionForm';

export default function MatrixPossibilityDefinition() {
  return (
    <BaseDefinitionPage<MatrixPossibilityDefinitionDto>
      listLabelPropName="label"
      fetchData={MatrixPossibilityDefinitionControllerService.getAllMatrixPossibilityDefinitions}
      FormDialog={MatrixPossibilityDefinitionForm}
      onDelete={MatrixPossibilityDefinitionControllerService.deleteAllMatrixPossibilityDefinitions}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
