import { debounceWithKey, defer, findByKey, uniquifyByKey } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import {
  addIcd10ToFavourites,
  getIcd10Favourites,
  getIcd10sByParent,
  getIdc10sByIds,
  removeIcd10FromFavourites,
  searchIcd10s,
} from 'api/hs/icd10';
import { useAsyncEffect, useFetch } from 'utils';

import { CHILDREN_LOADING, CHILDREN_NOT_LOADED, Icd10CacheContext, Icd10CacheContextType, Icd10Item } from './icd10.context';

function deconstructItems(items: Icd10Item[]): Icd10Item[] {
  return items.reduce((acc, item) => [...acc, item, ...(item.children instanceof Array ? deconstructItems(item.children) : [])], []);
}

export default function Icd10CacheContextProvider({ children }: PropsWithChildren<{}>) {
  const [searchDebounceKey] = useState(Symbol('search debounce key'));
  const [getDebounceKey] = useState(Symbol('get debounce key'));
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Icd10Item[]>([]);
  const [fetchedOptions, setFetchedOptions] = useState<Icd10Item[]>([]);
  const [fetchedKeywords, setFetchedKeywords] = useState<string[]>([]);
  const { data: favouritesRaw, fetch: reloadFavourites } = useFetch(() => getIcd10Favourites(), [], { setLoading });

  const flattenedItems = useMemo(() => deconstructItems(items), [items]);
  const favourites = useMemo(() => favouritesRaw?.map(({ icd10Id }) => icd10Id), [favouritesRaw]);

  const toggleFavourite = useCallback(
    async (id: Icd10Item['id']) => {
      if (favourites.includes(id)) {
        await removeIcd10FromFavourites(findByKey(favouritesRaw, 'icd10Id', id).id);
      } else {
        await addIcd10ToFavourites(id);
      }

      await reloadFavourites();
    },
    [favourites, favouritesRaw, reloadFavourites]
  );

  const loadChildrenOf = useCallback(
    async (id: Icd10Item['id']) => {
      const item = flattenedItems.find(i => i.id === id);
      item.children = CHILDREN_LOADING;

      setItems([...items]);
      setLoading(true);

      const res = await getIcd10sByParent(item.name);
      item.children = res.map(child => ({ ...child, children: CHILDREN_NOT_LOADED, parent: id }));

      setItems([...items]);
      setLoading(false);
    },
    [flattenedItems, items]
  );

  const fetchOptions = useCallback(
    async search => {
      if (fetchedKeywords.includes(search)) {
        return;
      }
      setLoading(true);
      setFetchedKeywords([...fetchedKeywords, search]);
      setFetchedOptions([...fetchedOptions, ...(await searchIcd10s(search))]);
      setLoading(false);
    },
    [fetchedKeywords, fetchedOptions]
  );

  const searchOptions = useCallback(
    (search: string) => {
      debounceWithKey(searchDebounceKey, fetchOptions, 400, null, search);
    },
    [searchDebounceKey, fetchOptions]
  );

  const options = useMemo<Icd10Item[]>(() => uniquifyByKey([...flattenedItems, ...fetchedOptions], 'id'), [flattenedItems, fetchedOptions]);

  const getOptionsReq = useCallback(
    async (...ids: Icd10Item['id'][]) => {
      setLoading(true);
      setFetchedOptions([...fetchedOptions, ...(await getIdc10sByIds(...ids))]);
      setLoading(false);
      await defer();
    },
    [fetchedOptions]
  );

  const getOptions = useCallback(
    (...ids: Icd10Item['id'][]) => {
      const idsToFetch = ids.filter(id => !options.find(opt => opt.id === id));

      if (idsToFetch.length) {
        return debounceWithKey(getDebounceKey, getOptionsReq, 400, null, ...idsToFetch);
      }
    },
    [options, getDebounceKey, getOptionsReq]
  );

  const labels = useMemo(
    () =>
      options.reduce(
        (acc, { id, label }) => ({
          ...acc,
          [id]: label,
        }),
        {} as { [key: number]: string }
      ),
    [options]
  );

  const context = useMemo<Icd10CacheContextType>(
    () => ({
      items,
      loadChildrenOf,
      labels,
      searchOptions,
      options,
      loading,
      getOptions,
      favourites,
      toggleFavourite,
    }),
    [items, loadChildrenOf, labels, searchOptions, options, loading, getOptions, favourites, toggleFavourite]
  );

  useAsyncEffect(async () => {
    const res = await getIcd10sByParent('0');

    setItems(res.map(item => ({ ...item, children: CHILDREN_NOT_LOADED, parent: null })));
  }, []);

  useEffect(() => {
    getOptions(...favourites);
  }, [favourites, getOptions]);
  return <Icd10CacheContext.Provider value={context}>{children}</Icd10CacheContext.Provider>;
}
