import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PrescriptionMedicineModel } from 'api/hs/examination';
import MedicineSelection from 'Common/Form/MedicineSelection';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PrescriptionMedicineModel;

  handleAddMedicineToPrescription(values: PrescriptionMedicineModel): void;
}

export default function PrescriptionMedicineForm({ open, onClose, data, handleAddMedicineToPrescription }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const {
    prescriptionMedicineUsageDefinitions,
    prescriptionDescriptionDefinitions,
    prescriptionMedicinePeriodUnitDefinitions,
    satietyInformationDefinitions,
  } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);

  const handleDoseAmountUsageFrequency = (e: React.MouseEvent) => {
    const values = (e.target as HTMLButtonElement)?.innerText?.split('X');
    const usageFrequency = values[0];
    const doseAmount = values[1] === '1/2' ? '0.5' : values[1];
    setValue('doseAmount', doseAmount);
    setValue('usageFrequency', usageFrequency);
    trigger();
  };

  const handleSubmit = useCallback(
    async values => {
      try {
        if (values?.medicineId) {
          handleAddMedicineToPrescription(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          showMessage('Lütfen ilaç seçiniz.', 'error', 'info');
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, handleAddMedicineToPrescription]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }

    await defer();

    if (!data) {
      reset({
        medicineId: null,
        medicinePackageCount: 1 as any,
        description: '' as any,
        usageTypeDefinitionId: prescriptionMedicineUsageDefinitions[0]?.value as any,
        usageFrequency: '' as any,
        doseAmount: '' as any,
        usagePeriod: 1 as any,
        usagePeriodUnitId: prescriptionMedicinePeriodUnitDefinitions[0]?.value as any,
        satietyInformationDefinitionId: satietyInformationDefinitions[0]?.value as any,
        prescriptionDescriptionDefinitions: prescriptionDescriptionDefinitions[0]?.value as any,
      });
    }
  }, [data, reset, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 600 } }}
      open={open}
      keepMounted
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper,
        scrollPaper: classes.dialogScroll,
      }}>
      <DialogTitle>İlaç Ekle</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={3} className="mb-2">
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <MedicineSelection name="medicineId" label="İlaç Seçiniz" />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={6}>
                <TextField
                  required
                  type="number"
                  name="medicinePackageCount"
                  label="İlaç Adedi (Kutu, Şişe vb.)"
                  shrinkAlways={true}
                  rules={{ min: 1, max: 1000 }}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={6}>
                <Select
                  options={prescriptionMedicineUsageDefinitions}
                  defaultValue={prescriptionMedicineUsageDefinitions[0]?.value}
                  name="usageTypeDefinitionId"
                  label="Kullanım Şekli"
                />
              </Grid>
              <Box className="text-center" mx="auto">
                <Grid item xs={12} md={12} sm={12} lg={12} className="mb-2">
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="1x1"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    1x1
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="2x1"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    2x1
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="3x1"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    3x1
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="1x1/2"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    1x1/2
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="2x1/2"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    2x1/2
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    className="mr-2"
                    value="3x1/2"
                    onClick={e => handleDoseAmountUsageFrequency(e)}>
                    3x1/2
                  </Button>
                </Grid>
              </Box>
              <Grid item xs={12} md={12} sm={12} lg={6}>
                <TextField required type="number" name="usageFrequency" label="Günde Kaç Kez" rules={{ min: 1, max: 1000 }} />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={6}>
                <TextField required type="text" name="doseAmount" label="Seferde Kaç Doz" rules={{ min: 1, max: 1000 }} />
              </Grid>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Ek Seçenekleri Göster</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} sm={12} lg={6}>
                      <TextField type="number" name="usagePeriod" label="Periyot" shrinkAlways={true} rules={{ min: 1, max: 1000 }} />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={6}>
                      <Select
                        options={prescriptionMedicinePeriodUnitDefinitions}
                        defaultValue={prescriptionMedicinePeriodUnitDefinitions[0]?.value}
                        name="usagePeriodUnitId"
                        label="Periyot Birimi"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={6}>
                      <Select
                        options={satietyInformationDefinitions}
                        defaultValue={satietyInformationDefinitions[0]?.value}
                        name="satietyInformationDefinitionId"
                        label="Tokluk Bilgisi"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={6}>
                      <Select
                        options={prescriptionDescriptionDefinitions}
                        defaultValue={prescriptionDescriptionDefinitions[0]?.value}
                        name="prescriptionDescriptionTypeDefinitionId"
                        label="Açıklama Türü"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField rows={2} label="Açıklama" name="description" />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid container className="text-right">
              <Grid item xs={12} md={12} sm={12} lg={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-1 ml-1">
                  Kaydet
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
