import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext, useMemo } from 'react';

import Toolbar from 'Common/Toolbar';

import { EnumContext } from '../../../../../../../context/enum.context';
import { DefaultNurseOperationContext } from '../../nurse.operation.context';

const useStyles = makeStyles(theme => ({
  select: {
    '&, & svg': {
      color: theme.palette.common.white,
    },
    '&:before, &:after': {
      display: 'none',
    },
  },
}));
export default function Index() {
  const classes = useStyles();
  const { defaultNurseOperations, setFilter } = useContext(DefaultNurseOperationContext);
  const { nurseOperationDefinitions } = useContext(EnumContext);

  const menuItems = useMemo(
    () =>
      defaultNurseOperations.reduce(
        (acc, next) => ({ ...acc, [next.nurseOperationDefinitionId]: (acc[next.nurseOperationDefinitionId] ?? 0) + 1 }),
        {}
      ),

    [defaultNurseOperations]
  );

  return (
    <>
      <Toolbar color="secondary">
        <Select
          defaultValue="all"
          className={`${classes.select} mt-2 mb-1`}
          variant="standard"
          onChange={(e: any) => setFilter(e.target.value)}>
          <MenuItem value="all">Hepsini Göster ({defaultNurseOperations.length})</MenuItem>
          {Object.entries(menuItems).map(([id, count], index) => (
            <MenuItem key={id || -1 * index} value={+id}>
              {findByKey(nurseOperationDefinitions, 'id', +id)?.label} ({count})
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
    </>
  );
}
