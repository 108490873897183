export const sumValueOfFieldArr = (data: any[], fieldName: string): number => {
  let total = 0;
  if (data) {
    data.forEach(d => {
      total += sumValueOfFieldWithTotal(d, fieldName);
    });
  }
  return total;
};

export const dataDisplayValue = (fieldName: any, options: any[]) => {
  return options.find(d => d.value === fieldName)?.text;
};

export const sumValueOfField = (data: any, fieldName: string) => {
  return sumValueOfFieldWithTotal(data, fieldName);
};

export const valueOfFieldArr = (data: any[], fieldName: string): number => {
  let t = 0;
  data.forEach(d => {
    t += sumValueOfFieldWithTotal(d, fieldName);
  });
  return t;
};

export const sumValueOfFieldWithTotal = (data: any, fieldName: string): number => {
  if (!data) {
    return;
  }
  const indexOfArr: number = fieldName.indexOf('[].');
  const indexOfDot: number = fieldName.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = fieldName.substr(0, indexOfArr);
    const newFieldName = fieldName.substr(indexOfArr + 3);
    return sumValueOfFieldArr(Reflect.get(data, fragment), newFieldName);
  } else if (indexOfDot > 0) {
    const fragment = fieldName.substr(0, indexOfDot);
    const newFieldName = fieldName.substr(indexOfDot + 1);
    return sumValueOfFieldWithTotal(Reflect.get(data, fragment), newFieldName);
  } else {
    return Reflect.get(data, fieldName) || 0;
  }
};

export const getCountOfFieldArr = (data: any[], fieldName: string) => {
  let total = 0;
  if (data) {
    data.forEach(d => {
      total += getCountOfField(d, fieldName);
    });
  }
  return total;
};

export const getCountOfField = (data: any, fieldName: string): number => {
  if (!data) {
    return;
  }
  const indexOfArr: number = fieldName.indexOf('[].');
  const indexOfDot: number = fieldName.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = fieldName.substr(0, indexOfArr);
    const newFieldName = fieldName.substr(indexOfArr + 3);
    return getCountOfFieldArr(Reflect.get(data, fragment), newFieldName);
  } else if (indexOfDot > 0) {
    const fragment = fieldName.substr(0, indexOfDot);
    const newFieldName = fieldName.substr(indexOfDot + 1);
    return getCountOfField(Reflect.get(data, fragment), newFieldName);
  } else {
    return Reflect.get(data, fieldName) ? 1 : 0;
  }
};
