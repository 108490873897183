import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { TrainersPayload } from './trainers.payload';
import { TrainersResult } from './trainers.result';

export async function getTrainers(): Promise<TrainersResult[]> {
  const [error, res] = await tryCatch(HSApi.get<TrainersResult[]>(`/trainers`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveTrainer(body: TrainersPayload): Promise<TrainersResult> {
  const [err, res] = await tryCatch(HSApi.post('/trainers', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateTrainer({ id, ...body }: MakeRequired<Partial<TrainersResult>, 'id'>): Promise<TrainersResult> {
  const [error, res] = await tryCatch(HSApi.put<TrainersResult>(`/trainers/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteTrainer(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainers/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
