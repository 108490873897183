import React from 'react';

import AdminNearMissTypeDefinitions from './AdminNearMissTypeDefinitions';

interface AdminNearMissListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'Ramak Kala Türleri',
    Component: AdminNearMissTypeDefinitions,
    path: '',
  },
] as AdminNearMissListItem[];
