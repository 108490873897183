import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getDefaultNurseOperation } from 'api/hs/nurse-operations/default-nurse-operations';
import { useFetch } from 'utils';

import DefaultNurseOperation from './DefaultNurseOperation';
import { DefaultNurseOperationContext } from './nurse.operation.context';

export default function Index() {
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [filter, setFilter] = useState<number | 'all'>('all');
  const { employeeId } = useParams<{ employeeId: string }>();

  const { data, fetch } = useFetch(async () => await getDefaultNurseOperation(employeeId), []);

  return (
    <DefaultNurseOperationContext.Provider
      value={{
        defaultNurseOperations: data,
        reload: fetch,
        filter,
        setFilter,
        loading,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
      }}>
      <DefaultNurseOperation />
    </DefaultNurseOperationContext.Provider>
  );
}
