import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  saveTrainingDefinitions,
  TrainingDefinitionsModel,
  updateTrainingDefinitions,
} from 'api/hs/definitions/trainings/training-definitions';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: TrainingDefinitionsModel;
}

export default function TrainingDefinitionsForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { trainingGroupDefinitions, yesNoBoolean, assignableTypeDefinitions } = useContext(SelectOptionsContext);
  const handleSubmit = useCallback(
    async values => {
      const PROPS_TO_CONVERT_INTO_NULL = [
        'name',
        'description',
        'isIbysTraining',
        'durationInMinute',
        'isPeriodic',
        'periodDurationInMonth',
        'aim',
        'objective',
        'trainingReference',
        'assignableType',
      ];
      // Convert `''` values into `null`
      for (const key of PROPS_TO_CONVERT_INTO_NULL) {
        if ((values as any)[key] !== '') {
          continue;
        }

        (values as any)[key] = null;
      }

      try {
        if (!data?.id) {
          await saveTrainingDefinitions({ isLegalObligation: false, ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateTrainingDefinitions({
            id: data.id,
            isLegalObligation: false,
            ...values,
          });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      trigger();
    } else {
      reset();
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 500 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Konu Güncelle' : 'Konu Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger, getValues }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField disabled={data?.isLegalObligation} required name="name" label="Konu Adı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Select disabled={data?.isLegalObligation} required name="groupId" options={trainingGroupDefinitions} label="Konu Grubu" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField disabled={data?.isLegalObligation} name="code" label="Konunun Kodu" />
              </Grid>

              <Grid item md={6} sm={6} lg={6}>
                <Select name="isIbysTraining" options={yesNoBoolean} label="İBYS Eğitimi mi ?" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <Select name="assignableType" options={assignableTypeDefinitions} label="Atanma Durumu" />
              </Grid>

              <Grid item md={6} sm={6} lg={6}>
                <TextField type="number" name="durationInMinute" label="Konunun Süresi / dk" />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <Select name="isPeriodic" options={yesNoBoolean} label="Periyodik" />
              </Grid>
              {getValues('isPeriodic') ? (
                <Grid item md={12} sm={12} lg={12}>
                  <TextField type="number" name="periodDurationInMonth" label="Periyodik Süresi / ay" />
                </Grid>
              ) : null}

              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={2} name="objective" label="Konunun Amacı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="aim" label="Konunun Hedefi" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="trainingReference" label="Konunun Referansı" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
