import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ExaminationView, ExaminationViewControllerService } from '../../api/client';
import { FormRef } from '../../Common/Form/Form';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function ExaminationReports() {
  const [chartData, setChartData] = useState<ExaminationView[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const { hsExamOpinionDefinitions, polyclinicOpinions } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      examinationFilter: {
        minDate: values?.minDate + 'T00:00:00',
        maxDate: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'date',
      },
      fillingFields: {
        withDiagnosis: 'false',
        withOpinions: 'true',
      },
    };
    const res = await ExaminationViewControllerService.filterExaminations(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Muayene İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      <Grid container>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard className={classes.rootBgRed} title={'Toplam Muayene'} amount={chartData?.length} />
        </Grid>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard
            className={classes.rootBgGreen}
            title={'Sağlık Gözetimi Muayenesi'}
            amount={chartData?.filter(hsExamination => hsExamination.examinationMainTypeName === 'HS_EXAMINATION')?.length}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard
            className={classes.rootBgCyan}
            title={'Poliklinik Muayenesi'}
            amount={chartData?.filter(hsExamination => hsExamination.examinationMainTypeName === 'POLYCLINIC_EXAMINATION')?.length}
          />
        </Grid>
      </Grid>

      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={6} lg={6} sm={6} xl={6} md={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirket Bazlı Muayene Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="examination-company-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="companyName"
                  xAxesTitle="Şirketler"
                  xAxesText="Tarih"
                  yAxesText="Muayene Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} lg={6} sm={6} xl={6} md={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Departman Bazlı Muayene Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="examination-department-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="departmentName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Tarih"
                  yAxesText="Muayene Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} lg={6} sm={6} xl={6} md={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="İş İstasyon Bazlı Muayene Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="examination-station-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="stationName"
                  xAxesTitle="İş İstasyonlar"
                  xAxesText="Tarih"
                  yAxesText="Muayene Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} lg={6} sm={6} xl={6} md={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Muayene Tipine Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="examination-examinationTypeName-report"
                  data={chartData}
                  dateFieldName="date"
                  selectorFieldByName="examinationTypeName"
                  xAxesTitle="Muayene Tipi"
                  xAxesText="Tarih"
                  yAxesText="Muayene Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} sm={12} xl={12} md={12}>
              <PieChartGridContainer
                title="Sağlık Gözetimi Muayenesi Kanaat Dağılımı"
                chartProps={{
                  chartName: 'opinion-report',
                  preparedData: convertAccidentReviewPropertyToChartData(
                    chartData,
                    'hsExamOpinionView[].workingRestrictionViews[].workingRestrictionDefinitionId',
                    hsExamOpinionDefinitions
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} lg={6} sm={6} xl={6} md={6}>
              <PieChartGridContainer
                title="Poliklinik Muayenesi Kanaat Dağılımı"
                chartProps={{
                  chartName: 'opinion-polyclinic-report',
                  preparedData: convertAccidentReviewPropertyToChartData(
                    chartData,
                    'polyclinicExamOpinionView[].polyclinicExamOpinionDefinitionId',
                    polyclinicOpinions
                  ),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
