import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#00BCD4',
      contrastText: '#FFF',
    },
    error: {
      main: '#F44336',
    },
    common: {
      black: '#2C303C',
    },
  },
});
