import { createContext } from 'react';

import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';

export interface TrainingDetailContextType {
  showDialogTraining: boolean;

  setShowDialogTraining(opened: boolean): void;

  trainerDialogOpened: boolean;

  setTrainerDialogOpened(opened: boolean): void;

  selectedData: TrainingsViewContentResult;

  setSelectedData(data: TrainingsViewContentResult): void;
}

export const TrainingDetailContext = createContext<TrainingDetailContextType>(null);
