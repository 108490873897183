/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyCaseTeamDto } from '../models/EmergencyCaseTeamDto';
import type { EmergencyTeamTrainingViewRequest } from '../models/EmergencyTeamTrainingViewRequest';
import type { PageEmergencyCaseTeamDto } from '../models/PageEmergencyCaseTeamDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmergencyCaseTeamControllerService {

    /**
     * @returns EmergencyCaseTeamDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCaseTeam(): CancelablePromise<Array<EmergencyCaseTeamDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-teams`,
        });
    }

    /**
     * @param requestBody
     * @returns EmergencyCaseTeamDto OK
     * @throws ApiError
     */
    public static saveEmergencyCaseTeam(
        requestBody: EmergencyCaseTeamDto,
    ): CancelablePromise<EmergencyCaseTeamDto> {
        return __request({
            method: 'POST',
            path: `/emergency-case-teams`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageEmergencyCaseTeamDto OK
     * @throws ApiError
     */
    public static getEmployeeEmergencyCaseTeamsFiltered(
        requestBody: EmergencyTeamTrainingViewRequest,
    ): CancelablePromise<PageEmergencyCaseTeamDto> {
        return __request({
            method: 'POST',
            path: `/emergency-case-teams/pages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns EmergencyCaseTeamDto OK
     * @throws ApiError
     */
    public static getEmergencyCaseTeamById(
        id: string,
    ): CancelablePromise<EmergencyCaseTeamDto> {
        return __request({
            method: 'GET',
            path: `/emergency-case-teams/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllEmployeeEmergencyCaseTeam(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-teams/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseTeamDto OK
     * @throws ApiError
     */
    public static partialUpdateEmergencyCaseTeam(
        id: string,
        requestBody: EmergencyCaseTeamDto,
    ): CancelablePromise<EmergencyCaseTeamDto> {
        return __request({
            method: 'PATCH',
            path: `/emergency-case-teams/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}