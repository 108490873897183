import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useRef, useState } from 'react';

import Button from 'Common/Button';
import Form, { FormRef } from 'Common/Form/Form';
import { dateToInputFormat, useTriggerRender } from 'utils';

import { getById, update } from '../../../api/hs/dashboard/event/event';
import { EventPayload, UserEventType } from '../../../api/hs/dashboard/event/event.payload';
import { AlertContext } from '../../../context/alert.context';
import Header from '../../Header';
import { CalendarContext } from '../calendar.context';
import { eventTypes } from '../event-types';
import EventForm from './EventForm';

export default function UpdateEventDialog() {
  const {
    updateEventDialog: { close, opened },
    reload,
    eventId,
  } = useContext(CalendarContext);
  const { showMessage } = useContext(AlertContext);

  const formRef = useRef<FormRef<{ wholeDay: boolean }>>();
  const reRender = useTriggerRender();
  const [eventDetail, setEventDetail] = useState<EventPayload>();

  useEffect(() => {
    if (eventId) {
      getById(eventId)
        .then(setEventDetail)
        .catch(() => {
          showMessage('Bir hata oluştu', 'error', 'info');
          close();
        });
    }
  }, [close, eventId, showMessage]);

  const handleSubmit = async (value: any) => {
    try {
      const request: EventPayload = {
        title: value.title,
        description: value.description,
        startDate: dateToInputFormat(new Date(value.startDate)),
        endDate: dateToInputFormat(new Date(value.endDate)),
        startTime: value.wholeDay ? '00:00:00' : value.startTime,
        endTime: value.wholeDay ? '23:59:59' : value.endTime,
        isPublic: value.public,
        color: value.color || eventTypes.USER_DEFINED.color,
      };

      await update(eventId, request);
      reload();
      showMessage('Etkinlik güncellendi.', 'success', 'info');
      close();
    } catch (e) {
      showMessage('Bir hata oluştu!', 'error', 'info');
    }
  };

  return (
    <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
      <Header title="Etkinlik Güncelle" icon={<EditIcon />} />
      <DialogContent>
        <Form ref={formRef} onChange={reRender} onSubmit={handleSubmit}>
          <EventForm wholeDay={false} event={eventDetail} />
        </Form>
      </DialogContent>
      <DialogActions className="pb-2 pr-2">
        <Button onClick={close}>İptal</Button>
        <Button type="submit" form={formRef.current?.id} color="primary" variant="contained" disabled={!formRef.current?.valid}>
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );
}
