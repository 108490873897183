import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { PpeStocksContentResult, savePpeStocks, updatePpeStocks } from 'api/hs/ppe/ppe-stocks';
import Dialog from 'Common/Dialog';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import AutoComplete from '../../../../Common/Form/AutoComplete';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PpeStocksContentResult;
}

export default function PpeStocksFormDialog({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { ppeDefinitions, storeHouse } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);

  const submit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await savePpeStocks({ ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await updatePpeStocks({ id: data.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title={data ? 'KKD Stok Düzenle' : 'KKD Stok Ekle'}>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="stockCount" label="Stok Sayısı" required={true} />
            </Grid>
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <TextField name="stockNumber" label="Stok Numarası" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <AutoComplete  disabled={data?.id} single name="ppeDefinitionId" options={ppeDefinitions} label="KKD Tipi "  required={true}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Select name="storehouseId" options={storeHouse} label="Depo Tanımı "  required={true} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="Üretim Tarihi" name="productionDate" type="date" required={true}/>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="Son Kullanma Tarihi" name="expireDate" type="date" required={true} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
