/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SystemSettingDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: number;
    code?: string;
    key?: string;
    status?: SystemSettingDto.status;
    value?: string;
}

export namespace SystemSettingDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
