import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import React, { useLayoutEffect, useRef } from 'react';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

interface AccidentWeightProps {
  data: any[];
  name: string;
  yAxesText: string;
}

const AccidentReview = (props: AccidentWeightProps) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const chart = am4core.create(props.name, am4charts.XYChart);
    chart.data = props.data;

    chart.xAxes.push(new am4charts.DateAxis());

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = props.yAxesText;
    valueAxis.adjustLabelPrecision = false;
    valueAxis.extraMax = 0.1;

    const companyNames = new Set<string>();
    chart.data.forEach(d =>
      Object.keys(d)
        .filter(o => o !== 'date')
        .forEach(f => companyNames.add(f))
    );

    companyNames.forEach(companyName => {
      createSeries(companyName, companyName);
    });

    function createSeries(field: string, name: string) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'date';
      series.strokeWidth = 2;
      series.minBulletDistance = 10;
      series.strokeWidth = 3;
      series.name = name;

      const bullet = series.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color('#fdd400');
      bullet.tooltipText = '[#fff font-size: 15px]{name} :\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]';
      const circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color('#fff');
      circle.strokeWidth = 3;
    }

    chart.exporting.menu = new am4core.ExportMenu();

    chart.legend = new am4charts.Legend();

    chartRef.current = chart;
    return () => {
      chart.dispose();
    };
  }, [props.data, props.name, props.yAxesText]);

  return <div id={props.name} style={{ width: '100%', height: '600px' }} />;
};

export default AccidentReview;
