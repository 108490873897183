import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { VacccinePayload } from './Vacccine.payload';
import { VaccineResult } from './Vaccine.result';

export async function getVacccine(): Promise<VaccineResult[]> {
  const [error, res] = await tryCatch(HSApi.get<VaccineResult[]>(`/firms/vaccine-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveVacccine(body: VacccinePayload): Promise<VaccineResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/vaccine-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateVacccine({ id, ...body }: MakeRequired<Partial<VaccineResult>, 'id'>): Promise<VaccineResult> {
  const [error, res] = await tryCatch(HSApi.put<VaccineResult>(`/firms/vaccine-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteVacccine(id: number) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/vaccine-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminVacccine(): Promise<VaccineResult[]> {
  const [error, res] = await tryCatch(HSApi.get<VaccineResult[]>(`/vaccine-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminVacccine(body: VacccinePayload): Promise<VaccineResult> {
  const [err, res] = await tryCatch(HSApi.post('/vaccine-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminVacccine({ id, ...body }: MakeRequired<Partial<VaccineResult>, 'id'>): Promise<VaccineResult> {
  const [error, res] = await tryCatch(HSApi.put<VaccineResult>(`/vaccine-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminVacccine(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/vaccine-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
