import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { exportActions, getActions } from '../../../../api/hs/dashboard/widget/widget';
import { ActionWidgetType, DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import ActionTable from './ActionTable';

const fileNames = {
  actionPlannedPassed: 'planlanan-aksiyon.xlsx',
  actionCompleted: 'tamamlanan-aksiyon.xlsx',
  actionActive: 'aktif-aksiyon.xlsx',
};

export default () => {
  const [tab, setTab] = useState<string>('actionActive');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: actionActive } = useFetch(
    () => getActions(ActionWidgetType.ACTION_ACTIVE, new DateRange(dateRange?.start, dateRange?.end)),
    [],
    { setLoading }
  );
  const { data: actionPlannedPassed } = useFetch(() => getActions(ActionWidgetType.ACTION_PLANNED_PASSED), [], { setLoading });
  const { data: actionCompleted } = useFetch(() => getActions(ActionWidgetType.ACTION_COMPLETED), [], { setLoading });

  const tabs = { actionActive, actionCompleted, actionPlannedPassed };

  const {
    action: { close, opened },
    actionDateRange: { dateRange },
  } = useContext(DialogContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'actionActive') {
      exportActions(ActionWidgetType.ACTION_ACTIVE, fileNames[tab], new DateRange(dateRange.start, dateRange.end)).then(noop);
    }

    if (tab === 'actionPlannedPassed') {
      exportActions(ActionWidgetType.ACTION_PLANNED_PASSED, fileNames[tab]).then(noop);
    }
    if (tab === 'actionCompleted') {
      exportActions(ActionWidgetType.ACTION_COMPLETED, fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab.toString()}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Aksiyon" color={colors.action} onClick={tabs[tab]?.length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Aktif Aksiyon (${actionActive.length ?? 0})`} value={'actionActive'} />
            <Tab wrapped label={`Geciken Aksiyon (${actionPlannedPassed.length ?? 0})`} value={'actionPlannedPassed'} />
            <Tab wrapped label={`Tamamlanan Aksiyon (${actionCompleted.length ?? 0})`} value={'actionCompleted'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'actionActive'}>
            <IndicatorWrapper data={actionActive} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ActionTable actions={actionActive} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'actionPlannedPassed'}>
            <IndicatorWrapper data={actionPlannedPassed} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ActionTable actions={actionPlannedPassed} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'actionCompleted'}>
            <IndicatorWrapper data={actionCompleted} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ActionTable actions={actionCompleted} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
