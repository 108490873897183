// tslint:disable:max-classes-per-file

export class AnalysisAudiometryCategoriesModel {
  public label: string = null;
  public description: string = null;
}

export class AudiometryEarDetailModel {
  public khz250: number = null;
  public khz500: number = null;
  public khz1000: number = null;
  public khz2000: number = null;
  public khz3000: number = null;
  public khz4000: number = null;
  public khz6000: number = null;
  public khz8000: number = null;
  public sso: string = null;
}

export class AudiometryModel {
  public analysisId: string = null;
  public audioCategories: AnalysisAudiometryCategoriesModel[] = null;
  public leftEar: AudiometryEarDetailModel = null;
  public rightEar: AudiometryEarDetailModel = null;
}
