import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { getReportedEmployeeCounts, hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { DateRange, ReportedEmployeeCounts, WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DateRangeDialog from './DateRangeDialog';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }) => {
  const [counts, setCounts] = useState<ReportedEmployeeCounts>();
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const {
    employee: { open },
    employeeDateRange: { dateRange, days, setRangeByDays, setRange },
  } = useContext(DialogContext);

  useEffect(() => {
    setRangeByDays(7);
  }, [setRangeByDays]);

  useEffect(() => {
    if (dateRange) {
      getReportedEmployeeCounts(new DateRange(dateRange.start, dateRange.end)).then(setCounts);
    }
  }, [dateRange]);

  const hide = () => {
    hideWidget({ widgetType: WidgetType.REPORT_REPORT }).then(reload);
  };

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Card key="report" className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.employee }}>
      <CardContent>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sm={10} onClick={open}>
            <h3>Raporlu Personel</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide}>
              <>
                {dateRangeOptions.map(dateRangeOption => (
                  <MenuItem key={dateRangeOption} onClick={() => setRangeByDays(dateRangeOption)}>
                    <ListItemIcon>{dateRangeOption === days && <Done fontSize="small" />}</ListItemIcon>
                    <ListItemText>{dateRangeOption} Gün</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setDialogVisibility(true)}>
                  <ListItemIcon>{days === undefined && <Done fontSize="small" />}</ListItemIcon>
                  <ListItemText>Diğer...</ListItemText>
                </MenuItem>
              </>
            </DropdownMenuContainer>
          </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', flexDirection: 'column' }} onClick={open}>
          <Grid item sm={12}>
            <h3>{counts?.restReportedEmployee || 0}</h3>
          </Grid>
          <Divider />
          <p>Bugün Dönenler : {counts?.restReportEndToday || 0}</p>
        </Grid>
      </CardContent>
      <DateRangeDialog close={() => setDialogVisibility(false)} opened={dialogVisibility} setRange={setRange} />
    </Card>
  );
};
