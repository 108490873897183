import { makeStyles, TabsTypeMap, TabTypeMap } from '@material-ui/core';
import { TabList, useTabContext } from '@material-ui/lab';
import React, { useCallback, useEffect, useRef } from 'react';

import { IS_MACOS } from 'model';

type ChildType = React.ComponentElement<TabTypeMap['props'], any>;

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
  },
}));

export default function ScrollableTabs({
  children,
  ...props
}: Omit<TabsTypeMap<{ children: ChildType[] }>['props'], 'scrollButtons' | 'variant'>) {
  const classes = useStyles();
  const tabRef = useRef<Element>();
  const actions = useRef<{ updateIndicator(): void; updateScrollButtons(): void }>();
  const getScroller = useCallback<() => HTMLDivElement>(() => tabRef.current.querySelector('.MuiTabs-scroller'), []);
  const { value } = useTabContext();

  useEffect(() => {
    const activeIndex = children.findIndex(child => child.props.value === value);
    const activeButton = tabRef.current.querySelectorAll<HTMLButtonElement>('.MuiTab-root')[activeIndex];

    const scroller = getScroller();
    const { scrollLeft, offsetWidth } = scroller;

    if (activeButton.offsetLeft - scrollLeft > offsetWidth / 2 || activeButton.offsetLeft < scrollLeft) {
      scroller.scrollTo({ left: activeButton.offsetLeft - offsetWidth / 2 + activeButton.offsetWidth / 2, behavior: 'smooth' });

      actions.current.updateIndicator();
      actions.current.updateScrollButtons();
    }
  }, [children, getScroller, value]);

  useEffect(() => {
    if (IS_MACOS) {
      return;
    }

    const scroller = getScroller();

    function handler(event: WheelEvent) {
      scroller.scrollTo({ left: scroller.scrollLeft + event.deltaY });
      actions.current.updateIndicator();
      actions.current.updateScrollButtons();
    }

    scroller.addEventListener('wheel', handler);

    return () => scroller.removeEventListener('wheel', handler);
  }, [getScroller]);

  return (
    <div className={classes.container}>
      <TabList {...props} ref={tabRef as any} action={actions} scrollButtons="on" variant="scrollable">
        {children}
      </TabList>
    </div>
  );
}
