import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetFirmUserResult } from './get-firm-user.result';

export async function getFirmUser(): Promise<GetFirmUserResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetFirmUserResult[]>(`/firms/users`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
