import {
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { deleteTrainingDefinitions } from 'api/hs/definitions/trainings/training-definitions';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { ResourceContext } from 'context/resource.context';

import { SelectOptionsContext } from '../../../../../context/select-options.context';
import Search from '../../../../BaseDefinitionPage/BaseDefinition/List/Search';
import { TrainingDefinitionsContext } from '../../training-definitions.context';
import EditDialog from './EditDialog';

function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / Number(itemsPerPage));

  function currentData() {
    const begin = (currentPage - 1) * Number(itemsPerPage);
    const end = begin + Number(itemsPerPage);
    return data.slice(begin, end);
  }

  function next() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    // tslint:disable-next-line:no-shadowed-variable
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

type DefaultSizeType = 5 | 10 | 20 | 50;
const DEFAULT_SIZES: DefaultSizeType[] = [5, 10, 20, 50];

export default function ListTrainingDefinitions() {
  const { loading, trainingDefinitions, setEditDialogOpened, setSelectedData, reload } = useContext(TrainingDefinitionsContext);
  const { trainingGroupDefinitions } = useContext(ResourceContext);
  const { assignableTypeDefinitions } = useContext(SelectOptionsContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [page, setPage] = useState(1);
  const [defaultValue] = useState('');
  const [label, setLabel] = useState(defaultValue);
  const [size, setSize] = useState('10');

  const updateSearchKeyword = useCallback((value: string) => setLabel(value), [setLabel]);
  const handleSizeChange = useCallback(({ target: { value } }) => setSize(value as T), [setSize]);

  const filteredData = trainingDefinitions?.filter(
    x =>
      x?.name.toLowerCase().includes(label.toLowerCase()) ||
      x?.code.toLowerCase().includes(label.toLowerCase()) ||
      findByKey(trainingGroupDefinitions, 'id', x.groupId).label.toLocaleLowerCase('tr-TR').includes(label.toLocaleLowerCase('tr-TR'))
  );

  const count = Math.ceil(filteredData?.length / size);
  const _DATA = usePagination(filteredData, size);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const onDelete = useCallback(
    async (training: any) => {
      const submitted = await openWarnDialog({
        text: `${training.description} - açıklamalı kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await deleteTrainingDefinitions(training.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={trainingDefinitions} errorMessage="Gösterilecek Eğitim Bilgisi Bulunamadı">
        <Search value={label} onChange={updateSearchKeyword} />
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold" width="5%" align="left" />
                <TableCell className="text-bold text-gray" width="30%" align="left">
                  Konu Adı
                </TableCell>
                <TableCell className="text-bold text-gray" width="5%" align="left">
                  Konu Kodu
                </TableCell>
                <TableCell className="text-bold text-gray" width="15%" align="left">
                  Konu Grup
                </TableCell>
                <TableCell className="text-bold text-gray" width="5%" align="left">
                  Konunun Süresi
                </TableCell>
                <TableCell className="text-bold text-gray" width="15%" align="left">
                  İBYS Eğitimi mi ?
                </TableCell>
                <TableCell className="text-bold text-gray" width="15%" align="left">
                  Atanma Durumu
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_DATA.currentData().map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <Icon style={{ color: 'gray' }} className="icon-school" />
                  </TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{item?.code}</TableCell>
                  <TableCell align="left">{findByKey(trainingGroupDefinitions, 'id', item?.groupId)?.name}</TableCell>
                  <TableCell align="left">{item?.durationInMinute}</TableCell>
                  <TableCell align="left">{item?.isIbysTraining ? 'Evet' : 'Hayır'}</TableCell>
                  <TableCell align="left">{findByKey(assignableTypeDefinitions, 'value', item?.assignableType)?.text}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    {item?.isLegalObligation ? null : (
                      <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography color="inherit" variant="body2" className="mr-1">
              Gözüken kayıt sayısı:
            </Typography>
          </Grid>
          <Grid item>
            <Select value={size} onChange={handleSizeChange} classes={{ root: 'pl-1' }}>
              {DEFAULT_SIZES.map(s => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Pagination className="ml-2" showFirstButton showLastButton onChange={handleChange} count={count} page={page} color="primary" />
          </Grid>
        </Grid>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
