import {
  Breadcrumbs,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { BuildingFloorDefinitionControllerService } from 'api/client';
import Button from 'Common/Button';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { ResourceContext } from '../../../../../../context/resource.context';
import { BuildingFloorsContext } from '../../building-floors.context';
import EditDialog from './EditDialog';

export default function BuildingsList() {
  const { loading, buildingFloorDefinition, setEditDialogOpened, setSelectedData, reload } = useContext(BuildingFloorsContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { locations } = useContext(ResourceContext);
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();
  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const onDelete = useCallback(
    async (buildingFloor: any) => {
      const submitted = await openWarnDialog({
        text: `${buildingFloor.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await BuildingFloorDefinitionControllerService.deleteBuildingFloorDefinition(buildingFloor.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  const route = useCallback(
    (buildingFloorId: string) => {
      history.push(`/definitions/workplace/buildings/floors/${buildingFloorId}/parts`);
    },
    [history]
  );

  const routeBuildings = useCallback(() => {
    history.push(`/definitions/workplace/buildings`);
  }, [history]);

  return (
    <div className="m-2">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" onClick={routeBuildings}>
          Binalar
        </Link>
        <Typography color="textPrimary">{buildingFloorDefinition[0]?.buildingDefinition?.name}</Typography>
      </Breadcrumbs>

      <IndicatorWrapper loading={loading} data={buildingFloorDefinition} errorMessage="Gösterilecek Kat Bilgisi Bulunamadı">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold text-gray" width="5%" align="left" />
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Lokasyon
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Bina
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Kat
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Bölüm
                </TableCell>

                <TableCell className="text-bold text-gray" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buildingFloorDefinition.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <BusinessIcon style={{ color: 'gray' }} />
                  </TableCell>
                  <TableCell align="left">{findByKey(locations, 'id', item?.buildingDefinition?.locationId)?.label}</TableCell>
                  <TableCell align="left">{item?.buildingDefinition?.name}</TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">
                    <Button onClick={() => route(item?.id)} color="primary">
                      Bölümleri
                    </Button>
                  </TableCell>

                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
