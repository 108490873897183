import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import { getComplaintHistories, upsertComplaintHistories } from 'api/hs/examination';
import { ComplaintHistoryDetail } from 'model';
import { useEnumPromise } from 'utils';

import CheckList, { FetchResult } from './CheckList';
import { UpsertData } from './CheckList/checklist.context';

export default function ComplaintHistory() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const complaintDefinitions$ = useEnumPromise('complaintDefinitions');

  const fetch = useCallback<() => Promise<FetchResult>>(async () => {
    const complaintDefinitions = await complaintDefinitions$;
    const result = await getComplaintHistories(employeeId, examinationId);

    return {
      description: result.description,
      list: complaintDefinitions
        .map(def => ComplaintHistoryDetail.fromEnumAndServerData(def, result.complaintHistories))
        .map(({ complaintDefinitionId, complaintDefinitionName, color, valueText, value }) => ({
          id: complaintDefinitionId,
          label: complaintDefinitionName,
          color,
          valueText,
          value,
        })),
    };
  }, [employeeId, examinationId, complaintDefinitions$]);

  const upsert = useCallback(
    async ({ description, ...rest }: UpsertData) => {
      await upsertComplaintHistories({
        employeeId,
        examinationId,
        description,
        complaintHistories: Object.entries(rest).map(([id, value]) => ({ complaintDefinitionId: +id, value })),
      });
    },
    [employeeId, examinationId]
  );

  return <CheckList fetch={fetch} upsert={upsert} editButtonText="Yakınma Öyküsü Düzenle" editDialogTitle="Yakınma Öyküsü Düzenle" />;
}
