import { printIframe } from './print-iframe';
import {previewPrescription} from "../api/hs/examination";
import {tryCatch} from "@thalesrc/js-utils";
import HSApi from "../api/hs/HSApi";

export async function printFile(src: string) {
  const a = document.createElement('iframe');
  const [err, res] = await tryCatch(HSApi.get(src));
  a.srcdoc = res.data;
  a.style.setProperty('display', 'none');
  printIframe(a);
}
