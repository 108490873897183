/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageWorkEquipmentLayoutView } from '../models/PageWorkEquipmentLayoutView';
import type { WorkEquipmentLayoutDto } from '../models/WorkEquipmentLayoutDto';
import type { WorkEquipmentLayoutRequest } from '../models/WorkEquipmentLayoutRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkEquipmentLayoutControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentLayoutDto OK
     * @throws ApiError
     */
    public static updateWorkEquipmentLayout(
        id: string,
        requestBody: WorkEquipmentLayoutDto,
    ): CancelablePromise<WorkEquipmentLayoutDto> {
        return __request({
            method: 'PUT',
            path: `/work-equipment-layouts/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllWorkEquipmentLayout(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-layouts/${id}`,
        });
    }

    /**
     * @returns WorkEquipmentLayoutDto OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentLayouts(): CancelablePromise<Array<WorkEquipmentLayoutDto>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-layouts`,
        });
    }

    /**
     * @param requestBody
     * @returns WorkEquipmentLayoutDto OK
     * @throws ApiError
     */
    public static saveAllWorkEquipmentLayouts(
        requestBody: Array<WorkEquipmentLayoutDto>,
    ): CancelablePromise<Array<WorkEquipmentLayoutDto>> {
        return __request({
            method: 'POST',
            path: `/work-equipment-layouts`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageWorkEquipmentLayoutView OK
     * @throws ApiError
     */
    public static getWorkEquipmentLayoutsByFilter(
        requestBody: WorkEquipmentLayoutRequest,
    ): CancelablePromise<PageWorkEquipmentLayoutView> {
        return __request({
            method: 'POST',
            path: `/work-equipment-layouts/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static workEquipmentLayoutViewExport(
        requestBody: WorkEquipmentLayoutRequest,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/work-equipment-layouts/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}