import React, { useMemo } from 'react';

import { UseDialogState, useDialogState } from '../../utils';
import { UseDateRangeState, useDateRangeState } from '../../utils/use-date-range-state';
import Card from './Card';
import { DialogContext, DialogContextType } from './dialog.context';
import AccidentDialog from './Dialog/Accident';
import ActionDialog from './Dialog/Action';
import EmployeeDialog from './Dialog/Employee';
import EmployeeWidgetDialog from './Dialog/EmployeeWidget';
import ExaminationDialog from './Dialog/Examination';
import NearMissDialog from './Dialog/NearMiss';
import PregnancyDialog from './Dialog/Pregnancy';
import ReferraledEmployeeDialog from './Dialog/ReferraledEmployee';
import TrainingDialog from './Dialog/Training';
import VaccineDialog from './Dialog/Vaccine';

export default () => {
  const lostDayWidget = useDialogState();
  const lostDayWidgetDateRange = useDateRangeState();
  const trainingWidget = useDialogState();
  const trainingWidgetDateRange = useDateRangeState();
  const accident = useDialogState();
  const accidentDateRange = useDateRangeState();
  const employee = useDialogState();
  const referraledEmployee = useDialogState();
  const examination = useDialogState();
  const vaccine = useDialogState();
  const action = useDialogState();
  const pregnancy = useDialogState();
  const employeeWidget = useDialogState();
  const nearMiss = useDialogState();
  const nearMissDateRange = useDateRangeState();
  const employeeDateRange = useDateRangeState();
  const referraledEmployeeDateRange = useDateRangeState();
  const examinationDateRange = useDateRangeState();
  const vaccineDateRange = useDateRangeState();
  const actionDateRange = useDateRangeState();
  const employeeWidgetDateRange = useDateRangeState();

  const context = useMemo<DialogContextType>(
    () => ({
      accidentDateRange,
      accident,
      nearMiss,
      nearMissDateRange,
      employee,
      employeeWidget,
      employeeWidgetDateRange,
      employeeDateRange,
      referraledEmployee,
      referraledEmployeeDateRange,
      examination,
      examinationDateRange,
      vaccine,
      vaccineDateRange,
      pregnancy,
      action,
      actionDateRange,
      trainingWidgetDateRange,
      trainingWidget,
      lostDayWidgetDateRange,
      lostDayWidget,
    }),
    [
      employee,
      employeeDateRange,
      referraledEmployee,
      referraledEmployeeDateRange,
      examination,
      examinationDateRange,
      vaccine,
      vaccineDateRange,
      pregnancy,
      action,
      actionDateRange,
      employeeWidget,
      employeeWidgetDateRange,
      nearMiss,
      nearMissDateRange,
      accidentDateRange,
      accident,
      trainingWidgetDateRange,
      trainingWidget,
      lostDayWidget,
      lostDayWidgetDateRange,
    ]
  );

  return (
    <DialogContext.Provider value={context}>
      <Card />
      {employee.opened && <EmployeeDialog />}
      {referraledEmployee.opened && <ReferraledEmployeeDialog />}
      {examination.opened && <ExaminationDialog />}
      {vaccine.opened && <VaccineDialog />}
      {pregnancy.opened && <PregnancyDialog />}
      {action.opened && <ActionDialog />}
      {employeeWidget.opened && <EmployeeWidgetDialog />}
      {nearMiss.opened && <NearMissDialog />}
      {accident.opened && <AccidentDialog />}
      {trainingWidget.opened && <TrainingDialog />}
    </DialogContext.Provider>
  );
};
