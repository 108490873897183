import React, { useState } from 'react';

import { useFetch } from 'utils';

import { WorkEquipmentTypeDefinitionControllerService } from '../../../api/client';
import { WorkEquipmentTypeDefinitionDto } from '../../../api/client';
import { TypeDefinitionContext } from './type-definition.context';
import TypeDefinition from './TypeDefiniton';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<WorkEquipmentTypeDefinitionDto>(null);

  const { data, fetch } = useFetch(() => WorkEquipmentTypeDefinitionControllerService.getAllWorkEquipmentTypes(), [], { setLoading });

  return (
    <TypeDefinitionContext.Provider
      value={{
        workEquipmentTypeDefinition: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <TypeDefinition />
    </TypeDefinitionContext.Provider>
  );
}
