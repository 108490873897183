import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import GeneralInfo from './GeneralInfo';
import ParticipantInfo from './ParticipantInfo';
import Toolbar from './Toolbar';
import TopicsInfo from './TopicsInfo';
import TrainingFiles from './TrainingFiles';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function TrainingDetailPage() {
  const classes = useStyles();
  return (
    <>
      <Toolbar />
      <List component="nav" className={classes.root}>
        <GeneralInfo />
        <ParticipantInfo />
        <TopicsInfo />
        <TrainingFiles />
      </List>
    </>
  );
}
