import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { FineKinneyHazardFrequencyDefinitionDto } from '../../../../api/client';
import { updateFirmRiskAssessmentsFineKinneyFrequencyDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: FineKinneyHazardFrequencyDefinitionDto;

  onClose(success: boolean): void;
}

export default function FineKinneyFrequencyDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<FineKinneyHazardFrequencyDefinitionDto>
      open={open}
      onClose={onClose}
      onUpdate={updateFirmRiskAssessmentsFineKinneyFrequencyDefinitionUpdate}
      data={data}
      createTitle="Fine Kinney Sıklık Ekleme"
      updateTitle="Fine Kinney Sıklık Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="İsmi" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="value" label="Değeri" />
      </Grid>
    </BaseDefinitionForm>
  );
}
