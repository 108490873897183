import { Fab, makeStyles } from '@material-ui/core';
import React from 'react';

import Toolbar from 'Common/Toolbar';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',

    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  fab: {
    transform: 'translateY(40%)',
    zIndex: 2,
  },
}));

interface Props {
  title: string;
  icon?: any;
  fabIcon?: any;
  children?: any;

  fabOnClick?(): void;
}

export default (props: Props) => {
  const classes = useStyles();

  return (
    <Toolbar toolbarProps={{ className: classes.toolbar }}>
      <h3 className="my-0">
        {props.icon}
        <span className="ml-1"> {props.title}</span>
      </h3>
      {props.children}
      {props.fabIcon && (
        <Fab className={`bg-error text-white ${classes.fab}`} onClick={props.fabOnClick}>
          {props.fabIcon}
        </Fab>
      )}
    </Toolbar>
  );
};
