import React from 'react';
import { Route, Switch } from 'react-router';

import Employee from './Employee';
import List from './List';

export default function EmployeeList() {
  return (
    <Switch>
      <Route path="/transactions/employee" exact>
        <List />
      </Route>
      <Route path="/transactions/employee/:employeeId">
        <Employee />
      </Route>
    </Switch>
  );
}
