import { Avatar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import { deletePpeFileUpload, PpeOutputsGroupsEmployeeResult, savePpeEmployeeImported } from 'api/hs/TransactionAndLists/Employee/PpeList';
import Button from 'Common/Button';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { downlaodFile, openFile, useDateFormat, useXhr } from 'utils';

import { DialogContext } from '../../../../../../context/dialog.context';
import { SelectOptionsContext } from '../../../../../../context/select-options.context';
import AddDebitForm from '../Add';
import EditForm from '../EditForm';
import { PpeOutputsGroupContext } from '../ppe-outputs-group.context';
import {exportEmployeeKKDReport} from "../../../../../../api/hs/employee";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export default function PpeOutputsGroupEmployeeList() {
  const { loading, ppeOutputsGroupsEmployee } = useContext(PpeOutputsGroupContext);

  return (
    <>
      <AddDebitForm />
      <IndicatorWrapper loading={loading} data={ppeOutputsGroupsEmployee} errorMessage="Listelenecek Kayıt Bulunamadı.">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" />
                <TableCell width="20%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  Zimmet Tarihi
                </TableCell>
                <TableCell width="15%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  Teslim Edilen KKD Sayısı
                </TableCell>
                <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                  Dosya
                </TableCell>
                <TableCell width="50%" align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {ppeOutputsGroupsEmployee.map((row: PpeOutputsGroupsEmployeeResult) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditForm />
      </IndicatorWrapper>
    </>
  );
}
interface Props {
  row: PpeOutputsGroupsEmployeeResult;
}
function Row({ row }: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { setNewDialogOpened, setSelectedData, reload } = useContext(PpeOutputsGroupContext);
  const { reasons } = useContext(SelectOptionsContext);
  const { openFileDialog } = useContext(DialogContext);
  const { openWarnDialog } = useContext(DialogContext);
  const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;

  const onShowReturnForm = useCallback(
    rowData => {
      setSelectedData(rowData);
      setNewDialogOpened(true);
    },
    [setSelectedData, setNewDialogOpened]
  );

  const exportFile = useCallback(async () => {
    await exportEmployeeKKDReport(row?.id);
  }, [BASE_URL, row]);

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => savePpeEmployeeImported(row?.id, file)));
    },
    'Dosyaları yüklendi',
    err => err,
    [row]
  );

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Dosya Yükle', maxCount: 1 });

    if (!files) {
      return;
    }

    await uploadFiles(files);
    await reload();
  }, [openFileDialog, reload, uploadFiles]);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deletePpeFileUpload(id);
      await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <DateField date={new Date(row?.outputGroupDate)} />
        </TableCell>
        <TableCell align="center">{row?.outputs?.filter(output => output?.reason === 'DEBIT')?.length}</TableCell>
        <TableCell align="left">
          <IconButton
            onClick={() =>
              openFile(`/saglik-gozetimi/${row?.importedFile?.path}/${row?.importedFile?.name}`, row?.importedFile?.originalFileName)
            }
            disabled={!row?.importedFile}
            edge="end"
            aria-label="delete">
            <VisibilityIcon />
          </IconButton>
          <IconButton
            disabled={!row?.importedFile}
            edge="end"
            onClick={() =>
              downlaodFile(`/saglik-gozetimi/${row?.importedFile?.path}/${row?.importedFile?.name}`, row?.importedFile?.originalFileName)
            }
            aria-label="download">
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={() => removeFile(row?.importedFile)} disabled={!row?.importedFile} edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <Button className="mr-1" onClick={exportFile} startIcon={<GetAppIcon />} variant="contained" type="submit" color="primary">
            Zimmet Formu Oluştur
          </Button>
          <Button onClick={uploadFile} startIcon={<CloudUploadIcon />} variant="contained" type="submit" color="primary">
            Zimmet Formu Yükle
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" />
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }}>
                      Girdi Numarası
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }}>
                      KKD Alt Grup
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Marka
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Model
                    </TableCell>
                    <TableCell width="5%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Adet
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Durumu
                    </TableCell>
                    <TableCell width="15%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Teslim Eden
                    </TableCell>
                    <TableCell width="10%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                      Kullanım Ömrü(Ay)
                    </TableCell>
                    <TableCell width="15%" align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.outputs
                    ?.filter(reasonData => reasonData.reason === 'DEBIT')
                    ?.map(rowData => (
                      <TableRow key={rowData?.id}>
                        <TableCell component="th" scope="row">
                          <Avatar src={`/saglik-gozetimi/public/images/ppe/${rowData?.imagePath}`} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowData.stockNumber}
                        </TableCell>
                        <TableCell>{rowData.type}</TableCell>
                        <TableCell align="left">{rowData.brand}</TableCell>
                        <TableCell align="left">{rowData?.model}</TableCell>
                        <TableCell align="left">{rowData?.count}</TableCell>
                        <TableCell align="left">{findByKey(reasons, 'value', rowData?.reason)?.text}</TableCell>
                        <TableCell align="left">{rowData?.userName}</TableCell>
                        <TableCell align="center">{rowData?.exposureTime}</TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => onShowReturnForm(rowData)}
                            startIcon={<ArrowBackIcon />}
                            variant="contained"
                            type="submit"
                            color="error">
                            İade Et
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
