export interface ExtendedSearchEmployeePayload {
  departmentDefinitionIds?: string[];
  positionDefinitionIds?: string[];
  workstationDefinitionIds?: string[];
  locationDefinitionIds?: string[];
}

export const EXTENDED_SEARCH_EMPLOYEE_PAYLOAD_KEYS = [
  'departmentDefinitionIds',
  'positionDefinitionIds',
  'workstationDefinitionIds',
  'locationDefinitionIds',
];
