import { Grid, IconButton, List, ListItem, ListItemIcon, makeStyles, Paper, Popover, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreIcon from '@material-ui/icons/MoreVert';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { WorkingHoursControllerService } from '../../api/client';
import { WorkingHoursDto } from '../../api/client';
import { saveWorkingHoursFileUpload } from '../../api/hs/working-hours';
import AutoComplete from '../../Common/Form/AutoComplete';
import DateInput from '../../Common/Form/DateInput';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import Toolbar from '../../Common/Toolbar';
import { DialogContext } from '../../context/dialog.context';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { dateToInputFormat, useAsyncEffect, useXhr } from '../../utils';
import WorkingHoursExport from './WorkingHoursExport';
import WorkingHoursForm from './WorkingHoursForm';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

function backMonths(date: Date, months: number) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + -months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function Index() {
  const classes = useStyles();
  const { companies: company } = useContext(ResourceContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const { companies } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [data, setData] = useState<WorkingHoursDto>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<WorkingHoursDto>(null);
  const [formState, setFormState] = useState(null);
  const { openFileDialog, openWarnDialog } = useContext(DialogContext);

  const fetch = useCallback(async () => {
    if (formState) {
      const { companyId, startDate, endDate } = formState;
      setLoading(true);
      const res = await WorkingHoursControllerService.getWorkingHours(startDate, endDate, companyId);
      setData(res);
      setLoading(false);
    }
  }, [formState]);

  useAsyncEffect(async () => {
    if (formState) {
      await fetch();
    }
  }, [formState]);

  const onClickOpenDialog = () => {
    setSelectedData(null);
    setOpenDialog(true);
    closeMenu();
  };

  const onClickOpenExportDialog = () => {
    setOpenExportDialog(true);
    closeMenu();
  };

  const onClickCloseDialog = async (success: boolean) => {
    if (success) {
      setOpenDialog(false);
      await fetch();
    }
    setOpenDialog(false);
  };

  const onClickCloseExportDialog = () => {
    setOpenExportDialog(false);
  };

  const editDialogOpen = (valueItem: any) => {
    setSelectedData(valueItem);
    setOpenDialog(true);
  };

  useAsyncEffect(async () => {
    await defer();
    formRef?.current?.methods?.setValue('endDate', dateToInputFormat(new Date()));
    formRef?.current?.methods?.setValue('startDate', backMonths(new Date(), 3));
    await formRef?.current?.methods?.trigger();
  }, []);

  const onChange = useCallback(async values => {
    if (values?.companyId && values?.startDate && values?.endDate) {
      setFormState(values);
    }
  }, []);

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => saveWorkingHoursFileUpload(formState?.companyId, file)));
      await fetch();
    },
    'Dosyalar yüklendi',
    'Dosyalar yüklenirken hata oluştu.',
    [fetch]
  );

  const uploadFile = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({ title: 'Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!files) {
      return;
    }

    await uploadFiles(files);
  }, [openFileDialog, uploadFiles, closeMenu]);

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await WorkingHoursControllerService.deleteWorkingHours(id);
      await fetch();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeDataShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: ` Bu kayıtı silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  return (
    <>
      <header>
        <Toolbar color="secondary">
          <Typography variant="h6">Çalışma Planı</Typography>
          <span className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton color="inherit" onClick={openMenu}>
              <MoreIcon />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 35, horizontal: -125 }}
              keepMounted
              open={!!anchorEl}
              onClose={closeMenu}>
              <List>
                <ListItem onClick={onClickOpenDialog} className="cursor-pointer">
                  <ListItemIcon>
                    <PlaylistAddIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Yeni Çalışma Saati</Typography>
                </ListItem>
                <ListItem onClick={onClickOpenExportDialog} className="cursor-pointer">
                  <ListItemIcon>
                    <BackupIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Yeni Çalışma Aktar</Typography>
                </ListItem>
                <ListItem disabled={!formState?.companyId} onClick={uploadFile} className="cursor-pointer">
                  <ListItemIcon>
                    <SystemUpdateAltIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Yeni Çalışma Yükle</Typography>
                </ListItem>
              </List>
            </Popover>
          </div>
        </Toolbar>
      </header>
      <header>
        <Paper className="p-2 m-2" elevation={3}>
          <Form onChange={onChange} ref={formRef}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={4} lg={4} xl={4}>
                <AutoComplete variant="outlined" single name="companyId" options={companies} label="Şirketler" />
              </Grid>
              <Grid item md={4} sm={4} lg={4} xl={4}>
                <DateInput variant="outlined" name="startDate" type="date" label="Başlangıç" />
                <span style={{ color: 'gray' }}>Başlangıç tarihi varsayılan son 3 ay seçilmiştir.</span>
              </Grid>
              <Grid item md={4} sm={4} lg={4} xl={4}>
                <DateInput variant="outlined" name="endDate" type="date" label="Bitiş" />
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </header>
      <div className="p-2">
        <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%" style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                    Şirket
                  </TableCell>
                  <TableCell width="20%" style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                    Tarih
                  </TableCell>
                  <TableCell width="20%" style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                    Çalışma Saatleri
                  </TableCell>
                  <TableCell width="35%" style={{ fontWeight: 'bold', color: 'gray' }} align="right">
                    İşlem
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((rowData, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{findByKey(company, 'id', rowData?.companyId)?.companyName}</TableCell>
                    <TableCell align="center">{rowData?.date?.split('-')?.reverse()?.join('-')}</TableCell>
                    <TableCell align="center">{rowData?.workingHours}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => editDialogOpen(rowData)} edge="start" aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => removeDataShow(rowData)} edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </IndicatorWrapper>
      </div>
      <WorkingHoursForm open={openDialog} onClose={onClickCloseDialog} data={selectedData ?? null} />
      <WorkingHoursExport open={openExportDialog} onClose={onClickCloseExportDialog} />
    </>
  );
}
