import React, { useCallback, useState } from 'react';

import { TableDataResult } from 'model';
import { useAsyncEffect } from 'utils';

import { ListItemTemplateProps } from './Icd10.context';
import { Icd10MedicineListContext } from './Icd10.context';
import List from './ListSearch';

interface Props<T extends { id: string | number }> {
  fetchData(page?: number, size?: number): Promise<TableDataResult<T>>;

  searchService(search: string, page?: number, size?: number): Promise<TableDataResult<T>>;

  ListTableBody: React.ComponentType<ListItemTemplateProps<T>>;

  ListTableHead: React.ComponentType;

  searchPlaceholder: string;
}

export default function Index<T extends { id: string | number }>({
  fetchData,
  ListTableBody,
  ListTableHead,
  searchPlaceholder,
  searchService,
}: Props<T>) {
  const [data, setData] = useState<TableDataResult<T>>(null);
  const [loading, setLoading] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const fetch = useCallback(
    async ({ page, size, searchString } = {}) => {
      page = page || data?.pageable?.pageNumber;
      size = size || data?.pageable?.pageSize;
      searchString = searchString === undefined ? searchValue : searchString;
      const result = searchString ? await searchService(searchString, page, size) : await fetchData(page, size);
      setData(result);
      setLoading(false);
    },
    [fetchData, data, searchService, searchValue]
  );

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  const search = useCallback(
    async (searchString: string) => {
      setSearchValue(searchString);
      await fetch({ searchString });
    },
    [fetch]
  );
  return (
    <Icd10MedicineListContext.Provider
      value={{
        data,
        fetch,
        loading,
        setLoading,
        ListTableBody,
        ListTableHead,
        searchPlaceholder,
        search,
      }}>
      <List />
    </Icd10MedicineListContext.Provider>
  );
}
