import React, { useCallback } from 'react';
import { useParams } from 'react-router';

import { deleteHospitalHistory, getHospitalHistory, saveHospitalHistory, updateHospitalHistory } from 'api/hs/examination';

import History from './History';
import { ListItem } from './History/history.context';

export default function HospitalHistory() {
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(() => getHospitalHistory(employeeId, examinationId), [employeeId, examinationId]);
  const save = useCallback(({ description }: Omit<ListItem, 'id'>) => saveHospitalHistory({ employeeId, examinationId, description }), [
    employeeId,
    examinationId,
  ]);
  const update = useCallback(({ id, ...rest }: ListItem) => updateHospitalHistory(id, rest), []);
  const remove = useCallback((id: ListItem['id']) => deleteHospitalHistory(id), []);

  return <History fetch={fetch} save={save} update={update} remove={remove} title="Hastane/Yatış" />;
}
