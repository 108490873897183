import React from 'react';
import { Route, Switch } from 'react-router';

import AccidentRecord from './AccidentRecord/AccidentRecord';
import AccidentsListIndex from './Accidents/Accidents';
import AccidentsDetail from './Accidents/AccidentsDetail';
import Review from './Review';
import WorkingHours from './WorkingHours';
export default function Accident() {
  return (
    <Switch>
      <Route path="/accident/list/:accidentId" exact>
        <AccidentsDetail />
      </Route>
      <Route path="/accident/list" exact>
        <AccidentsListIndex />
      </Route>
      <Route path="/accident/review" exact>
        <Review />
      </Route>
      <Route path="/accident/form" exact>
        <AccidentRecord />
      </Route>
      <Route path="/accident/working-hours" exact>
        <WorkingHours />
      </Route>
    </Switch>
  );
}
