import { createContext } from 'react';

import { PpeOutputsGroupsEmployeeResult } from 'api/hs/TransactionAndLists/Employee/PpeList';

export interface PpeOutputsGroupContextType {
  ppeOutputsGroupsEmployee: PpeOutputsGroupsEmployeeResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  newDebitDialogOpened: boolean;

  setNewDebitDialogOpened(opened: boolean): void;

  selectedData: PpeOutputsGroupsEmployeeResult;

  setSelectedData(data: PpeOutputsGroupsEmployeeResult): void;
}

export const PpeOutputsGroupContext = createContext<PpeOutputsGroupContextType>(null);
