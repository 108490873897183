/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RiskAssessmentHazardFilter = {
    maxDate?: string;
    minDate?: string;
    companyIds?: Array<string>;
    departmentDefinitionIds?: Array<string>;
    stationDefinitionIds?: Array<string>;
    employeeIds?: Array<string>;
    searchWorkDescription?: string;
    hazardCategoryIds?: Array<string>;
    searchHazardDescription?: string;
    searchRiskDescription?: string;
    searchAffected?: string;
    searchDescription?: string;
    revisePeriod?: number;
    reviseMaxDate?: string;
    reviseMinDate?: string;
    riskAssessmentType?: RiskAssessmentHazardFilter.riskAssessmentType;
    matrixHazardPossibilityIds?: Array<string>;
    matrixHazardDamageIds?: Array<string>;
    matrixHazardScoreIds?: Array<string>;
    matrixFinalHazardPossibilityIds?: Array<string>;
    matrixFinalHazardDamageIds?: Array<string>;
    matrixFinalHazardScoreIds?: Array<string>;
    fineKinneyHazardPossibilityIds?: Array<string>;
    fineKinneyHazardDamageIds?: Array<string>;
    fineKinneyHazardScoreIds?: Array<string>;
    fineKinneyHazardFrequencyIds?: Array<string>;
    fineKinneyFinalHazardPossibilityIds?: Array<string>;
    fineKinneyFinalHazardDamageIds?: Array<string>;
    fineKinneyFinalHazardScoreIds?: Array<string>;
    fineKinneyFinalHazardFrequencyIds?: Array<string>;
}

export namespace RiskAssessmentHazardFilter {

    export enum riskAssessmentType {
        MATRIX = 'MATRIX',
        FINE_KINNEY = 'FINE_KINNEY',
    }


}
