import { makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import CommonToolbar from 'Common/Toolbar';

import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarWorkEquipmentJobStatus() {
  const classes = useStyles();
  const { rawData } = useContext(HSTableContext);

  return (
    <CommonToolbar color="secondary">
      <Typography variant="h6" color="inherit">
        İş Ekipman Kontrol Listesi ( {rawData?.totalElements} )
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <VisibleColumnMenu />
        <Filters />
      </div>
    </CommonToolbar>
  );
}
