/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FineKinneyHazardFrequencyDefinitionDto } from '../models/FineKinneyHazardFrequencyDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FineKinneyHazardFrequencyDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static updateHazardFrequencyDefinitions(
        id: string,
        requestBody: FineKinneyHazardFrequencyDefinitionDto,
    ): CancelablePromise<FineKinneyHazardFrequencyDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/fine-kinney-frequency-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllHazardFrequencyDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/fine-kinney-frequency-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmHazardFrequencyDefinitions(
        id: string,
        requestBody: FineKinneyHazardFrequencyDefinitionDto,
    ): CancelablePromise<FineKinneyHazardFrequencyDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/fine-kinney-frequency-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmHazardFrequencyDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/fine-kinney-frequency-definitions/${id}`,
        });
    }

    /**
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static getAllHazardFrequencyDefinitions(): CancelablePromise<Array<FineKinneyHazardFrequencyDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/fine-kinney-frequency-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static saveHazardFrequencyDefinitions(
        requestBody: FineKinneyHazardFrequencyDefinitionDto,
    ): CancelablePromise<FineKinneyHazardFrequencyDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/fine-kinney-frequency-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmHazardFrequencyDefinitions(): CancelablePromise<Array<FineKinneyHazardFrequencyDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/fine-kinney-frequency-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns FineKinneyHazardFrequencyDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmHazardFrequencyDefinitions(
        requestBody: FineKinneyHazardFrequencyDefinitionDto,
    ): CancelablePromise<FineKinneyHazardFrequencyDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/fine-kinney-frequency-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}