import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { deleteStation, getStation, StationResult } from 'api/hs/definitions/workplace-definition/station';
import StationEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Station/StationEditDialog';

import { ResourceContext } from '../../context/resource.context';
import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  stationName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
  },
  stationType: {
    fontSize: '12px',
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  stationType2: {
    fontSize: '10px',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'rgb(170, 170, 170)',
    fontWeight: 'bold',
  },

  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<StationResult>) {
  const { exposures } = useContext(ResourceContext);

  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <BusinessCenterIcon />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.stationName}>{item.stationName}</span>
          </div>
          <div>
            <span className={classes.stationType}>Maruziyetler : </span>
            <span className={classes.stationType2}>
              {item?.exposureIds.length > 0
                ? item?.exposureIds?.map(exposureIdsData => findByKey(exposures, 'id', exposureIdsData)?.exposureName).join(' , ')
                : 'Maruziyet Ataması yapılmamıştır.'}
            </span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default function Station() {
  return (
    <BaseDefinitionPage<StationResult>
      listLabelPropName="stationName"
      fetchData={getStation}
      FormDialog={StationEditDialog}
      onDelete={deleteStation}
      Icon={<BusinessCenterIcon />}
      ListItemTemplate={ItemTemplate}
      searchName="stationName"
    />
  );
}
