/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentSeverityDefinitionDto } from '../models/AccidentSeverityDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentSeverityTypeDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmAccidentSeverity(
        id: string,
        requestBody: AccidentSeverityDefinitionDto,
    ): CancelablePromise<AccidentSeverityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/accident-severity-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmAccidentSeverity(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/accident-severity-definitions/${id}`,
        });
    }

    /**
     * @param definitionId
     * @param requestBody
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static updateAccidentSeverityDefinition(
        definitionId: string,
        requestBody: AccidentSeverityDefinitionDto,
    ): CancelablePromise<AccidentSeverityDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/accident-severity-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAccidentSeverity(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accident-severity-definitions/${definitionId}`,
        });
    }

    /**
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static getFirmAccidentSeverityDefinitions(): CancelablePromise<Array<AccidentSeverityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/accident-severity-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmAccidentSeverity(
        requestBody: AccidentSeverityDefinitionDto,
    ): CancelablePromise<AccidentSeverityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/accident-severity-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static getAccidentSeverityDefinitions(): CancelablePromise<Array<AccidentSeverityDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accident-severity-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns AccidentSeverityDefinitionDto OK
     * @throws ApiError
     */
    public static saveAccidentSeverityDefinition(
        requestBody: AccidentSeverityDefinitionDto,
    ): CancelablePromise<AccidentSeverityDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/accident-severity-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}