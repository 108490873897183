import { Button, Grid, IconButton } from '@material-ui/core';
import { CancelOutlined } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { AccidentReviewControllerService, AccidentReviewDefinitionControllerService } from '../../../api/client';
import Dialog from '../../../Common/Dialog';
import AutoComplete from '../../../Common/Form/AutoComplete';
import Form, { FormRef } from '../../../Common/Form/Form';
import TextField from '../../../Common/Form/TextField';
import { AlertContext } from '../../../context/alert.context';
import { DialogContext } from '../../../context/dialog.context';
import { useFetch, useTriggerRender } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import getFileName from './get-file-name';
import SubmitButton from './SubmitButton';

interface Props {
  orderNum: number;
  fetch(): void;
  open: boolean;
  onClose(val: boolean): void;
  data: any;
}

export default ({ orderNum = 1, open, fetch, onClose, data }: Props) => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);

  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();

  const { showMessage } = useContext(AlertContext);
  const { token, accidentId } = useContext(AccidentReviewContext);
  const { openFileDialog } = useContext(DialogContext);

  const { data: fileTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getFileTypes(), []);

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Görsel Yükle', maxCount: 1, accept: ['image/*'] });

    setFile(files && files[0]);
  }, [openFileDialog]);

  const submit = (values: any) => {
    setLoading(true);

    if (data) {
      AccidentReviewControllerService.updateCsi(accidentId, data.id, values.note, values.fileType, values.orderNum, null, null, token, {
        image: file,
      })
        .then(() => {
          showMessage('Güncellendi.', 'success', 'info');
          fetch();
          onClose(false);
        })
        .catch(() => {
          showMessage('Bir hata oluştu.', 'error', 'info');
        })
        .finally(() => {
          setLoading(false);
          setFile(undefined);
        });
      return;
    }

    AccidentReviewControllerService.saveCsi(accidentId, values.note, values.fileType, values.orderNum, undefined, undefined, token, {
      image: file,
    })
      .then(() => {
        showMessage('Kaydedildi.', 'success', 'info');
        fetch();
        onClose(false);
      })
      .catch(() => {
        showMessage('Bir hata oluştu.', 'error', 'info');
      })
      .finally(() => {
        setLoading(false);
        setFile(undefined);
      });
  };

  const onClickCancel = () => {
    if (data) data.importedFile = undefined;
    setFile(undefined);
    reRender();
  };

  const fileName = getFileName(file, data);

  return (
    <Dialog width="sm" color="secondary" opened={open} onClose={onClose.bind(null, false)} title={data ? 'Düzenle' : 'Fotoğraf/Kroki Ekle'}>
      <Form onChange={reRender} onSubmit={submit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField name="orderNum" defaultValue={data?.orderNum || orderNum.toString()} label="No" />
          </Grid>
          <Grid item xs={10} md={4}>
            <AutoComplete
              name="fileType"
              options={fileTypes.map(type => {
                return {
                  value: type.id,
                  text: type.label,
                };
              })}
              label="Dosya Türü"
              required
              single
              defaultValue={data?.fileTypeId}
            />
          </Grid>
          <Grid container item xs={12} md={6} alignItems={'center'} justifyContent={'center'}>
            {fileName ? (
              <>
                {fileName}
                <IconButton onClick={onClickCancel}>
                  <CancelOutlined fontSize="medium" />
                </IconButton>
              </>
            ) : (
              <span>
                <IconButton onClick={uploadFile}>
                  <CloudUploadIcon fontSize="medium" />
                </IconButton>
                Görsel Seçiniz
              </span>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField name="note" rows={5} label="Açıklama..." defaultValue={data?.note || ''} />
          </Grid>

          <Grid item md={12} className="text-right">
            <SubmitButton loading={loading} data={data} />
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
};
