import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { DefaultNurseOperationDetailContext } from '../../default-nurse-operation-detail.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ListDefaultNurseOperationDetail() {
  const { loading, defaultNurseOperationDetail } = useContext(DefaultNurseOperationDetailContext);

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={defaultNurseOperationDetail} errorMessage="Gösterilecek  Kayıt Bulunamadı">
        <List key="hs-defaultNurseOperationDetail">
          {defaultNurseOperationDetail?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-note-plus-outline" />
                </ListItemAvatar>
                <ListItemText primary={item?.description} />
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
