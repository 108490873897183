import React, { useCallback, useContext } from 'react';

import { BuildingsDefinitionContext } from '../../buildings-definition.context';
import BuildingsForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(BuildingsDefinitionContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <BuildingsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
