import { withStyles } from '@material-ui/core';
import MUIAccordion from '@material-ui/core/Accordion';
import MUIAccordionDetails from '@material-ui/core/AccordionDetails';
import MUIAccordionSummary from '@material-ui/core/AccordionSummary';
import MUIIcon from '@material-ui/core/Icon';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Link from 'Common/Link';
import { useTranslate } from 'utils';

import MENU_GROUPS from './menu-list';

const Accordion = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    transition: 'background-color .3s ease',
    '&.Mui-expanded': {
      margin: '0',
      backgroundColor: theme.palette.common.black,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
}))(MUIAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  content: {
    display: 'flex',
    '&.Mui-expanded': {
      margin: '0',
    },
    '& h4': {
      width: '100%',
      textAlign: 'center',
      margin: 0,
      fontWeight: 400,
    },
  },
}))(MUIAccordionSummary);

const SummaryIcon = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    fontSize: 'initial',
  },
}))(MUIIcon);

const AccordionDetails = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MUIAccordionDetails);

const List = withStyles(theme => ({
  root: {
    width: '100%',
  },
}))(MUIList);

const ListItem = withStyles(theme => ({
  root: {
    width: '100%',
    '&.active': {
      backgroundColor: theme.palette.primary.light,
      '& > a': {
        color: theme.palette.common.white,
      },
    },
    '& > a': {
      display: 'flex',
      width: '100%',
      color: theme.palette.common.black,
      textDecoration: 'none',
      alignItems: 'center',
    },
  },
}))(MUIListItem);

const ListItemIcon = withStyles(theme => ({
  root: {
    color: 'inherit',
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
}))(MUIListItemIcon);

const LinkIcon = withStyles(theme => ({
  root: {
    fontSize: 'initial',
  },
}))(MUIIcon);

export default function () {
  const { pathname } = useLocation();
  const translate = useTranslate();

  return (
    <>
      {MENU_GROUPS.map(({ title, children, icon }, index) => (
        <Accordion key={index} expanded>
          <AccordionSummary key={index}>
            <SummaryIcon className={'icon ' + icon} />
            <h4>{translate(title)}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <List dense={true}>
              {children.map(({ title: itemTitle, link, icon: itemIcon, external }, itemIndex) => (
                <ListItem button={true} key={itemIndex} className={pathname?.includes(link) ? 'active' : null}>
                  <Link to={link} external={!!external}>
                    <ListItemIcon>
                      <LinkIcon className={'icon ' + itemIcon} />
                    </ListItemIcon>
                    <ListItemText>{itemTitle}</ListItemText>
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
