import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

import { Employee } from 'model';

import { InfoListItem } from './info-list-item.model';

interface Props {
  title: string;
  list: InfoListItem[];
  employee: Employee;
}

export default function InfoList({ title, list, employee }: Props) {
  return (
    <>
      <ListItem className="mt-1">
        <ListItemText primary={<span style={{ fontWeight: 'bold', color: '#5f6c7c', fontSize: '14px' }}>{title}</span>} />
      </ListItem>
      <Divider />
      {list.map(({ Render, label }, index) => (
        <ListItem style={{ height: '35px' }} key={index} button>
          <span
            style={{
              width: '45%',
              fontSize: '13px ',
              fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
            }}>
            {label}
          </span>
          :
          <span
            style={{
              width: '55%',
              marginLeft: '3px',
              fontSize: '13px ',
              fontFamily: 'Roboto", "Helvetica", "Arial", sans-serif',
            }}>
            <Render employee={employee} />
          </span>
        </ListItem>
      ))}
    </>
  );
}
