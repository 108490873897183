import { Breadcrumbs, Icon, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import Toolbar from '../../../Common/Toolbar';
import { ResourceContext } from '../../../context/resource.context';
import { CaseTeamsDetailContext } from '../case-teams-detail.context';

export default function CaseTeamDetailToolbar() {
  const { emergencyCaseTeams } = useContext(CaseTeamsDetailContext);
  const { companies, emergencyCaseDefinition } = useContext(ResourceContext);
  const history = useHistory();

  const backRoute = useCallback(() => {
    history.push('/emergency-management/caseTeams');
  }, [history]);

  return (
    <Toolbar color="secondary">
      <Breadcrumbs style={{ color: 'white' }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link style={{ cursor: 'pointer' }} onClick={backRoute} color="inherit">
          <Icon className="icon-people mr-1" />
          Acil Durum Ekipleri
        </Link>
        <Typography style={{ color: 'white' }} color="textPrimary">
          <Icon className="  icon-file-document-box mr-1" />
          {`${findByKey(companies, 'id', emergencyCaseTeams?.companyId)?.companyName.toUpperCase()} -
            ${findByKey(emergencyCaseDefinition, 'id', emergencyCaseTeams?.emergencyCaseDefinitionId)?.label.toUpperCase()}`}
        </Typography>
      </Breadcrumbs>
    </Toolbar>
  );
}
