import { tryCatch } from '@thalesrc/js-utils';
import Axios from 'axios';
import axios from 'axios';

import HSApi from '../../HSApi';
import { BaseIbysTrainingDetailResult } from './base-ibys-training-detail.result';
import { BaseIbysTrainingPlanningPayload } from './create-ibys-training-planning.payload';
import { IbysTrainingDetailResult } from './ibys-training-detail.result';

export interface SignIbysData {
  pinCode: string;
  xmlContent: string;
}

export interface TrainingStatusResult {
  aciklama:string;
  bildirimNo:string;
  sonuc:string
}

interface EgitimBilgiDto {
  sertifikaTipi:number,
  isgProfTcKNo:string,
  egitimKodu:string,
  egitimSuresi:number,
  egitimYeri:number,
  egitimYontemi:number,
  egiticiTcKNo:string,
  isyeriSgkSicilNo:string,
  calisanTcKNo:string,
  zamanDamgasi?:string,
  imzalayanKimlikNo:string,
  action?:string
}

export interface SaveEducationPayload {
  egitimBilgiDtoList: EgitimBilgiDto[];
}

async function createIbysTrainingPlanningReq(payload: BaseIbysTrainingPlanningPayload): Promise<IbysTrainingDetailResult> {
  // console.log(payload);
  // const res = await HSApi.post(`/trainings`, payload);
  // return res.data;
}

export async function createIbysTrainingPlanning({
  ibysTrainingEmployees: employees,
  ...payload
}: BaseIbysTrainingPlanningPayload): Promise<IbysTrainingDetailResult> {
  const res = await createIbysTrainingPlanningReq({
    ...payload,
    ibysTrainingEmployees: employees,
  });

  return res;
}

export async function getIbysTrainings(page = 0, size = 20): Promise<BaseIbysTrainingDetailResult> {
  const res = await HSApi.get<BaseIbysTrainingDetailResult>(`/trainings/ibys-trainings?page=${page}&size=${size}&order=createdDate-DESC`);
  const ibysServerIdData = res?.data?.content?.filter(item => item.ibysServerId);
  const sendData = ibysServerIdData?.filter(data => data?.savingResult === 0 || (data?.savingResult === 200 && data?.processResult === 0));
  if (sendData?.length > 0) {
    const data = await getIbysTrainingsStatus(sendData.reduce((acc, item) => [...acc, item?.ibysServerId], []));
    for (const [key, value] of Object.entries(data)) {
      await updateTrainingIbys(res?.data?.content?.find(item => item?.ibysServerId === key)?.id, {
        processResult: value?.processResult,
        savingResult: value?.savingResult,
        processResultMessage: value?.processResultMessage,
        savingResultMessage: value?.savingResultMessage,
        officialQueryId: value?.sorguNo,
      });
    }
  }

  return res.data;
}

export async function deleteIbysTraining(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/ibys-trainings/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveIbysTraining(trainingId: string, body?: any): Promise<IbysTrainingDetailResult> {
  const [err, res] = await tryCatch(HSApi.post(`/trainings/${trainingId}/ibys-trainings`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateTrainingIbys(id: string, body: any) {
  const [error, res] = await tryCatch(HSApi.patch<IbysTrainingDetailResult>(`/trainings/ibys-trainings/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response;
  }

  return res.data;
}

export async function xmlContentIbysTraining(trainingId: string) {
  const [err, res] = await tryCatch(HSApi.post(`/trainings/ibys-trainings/${trainingId}/xml-content`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
var rand = function() {
  return Math.random().toString(36).substr(2); // remove `0.`
};
var token = function() {
  return rand() + rand(); // to make it longer
};
export async function signIbysDataTraining(payload: SignIbysData) {
  var token_ = token();
  var data_new = null;
  const data = await fetch('https://safe.artimetrik.net/action.php?token='+token_, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => {
      window.open('https://safe.artimetrik.net/ibys.php?token='+token_, "_blank", "noreferrer");
      data_new = fetch('https://safe.artimetrik.net/action.php?token='+token_, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({action: 'ibys_detay'}),
      })
          .then(response => {
            return response.json();
          })
          .then(d => {
            return d;
          })
          .catch(err => {
            console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
          });
    })
    .then(d => {
      return d;
    })
    .catch(err => {
      console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
    });
  return data_new;
}

export async function signIbysDataTrainingOld(payload: SignIbysData) {
  const data = await fetch('http://localhost:12200/sign-ibys-data', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
      .then(response => {
        return response.json();
      })
      .then(d => {
        return d;
      })
      .catch(err => {
        console.log('Fetch Error in saveEPrescription:', err); // tslint:disable-line:no-console
      });

  return data;
}

export async function getIBYS_URL() {
  const [err, res] = await tryCatch(Axios.get(`/saglik-gozetimi/config/jssetting.php`));
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }
  return res.data.IBYS_URL;
}
export async function getAuthenticate(URL_DATA: string) {
  const [err, res] = await tryCatch(axios.post(`${URL_DATA}/authenticate`, { username: 'jwtuser', password: '72UL;v.Nx_/kMY<~' }));
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveEducation(payload: EgitimBilgiDto[]): Promise<TrainingStatusResult> {
  const [err, res] = await tryCatch(
      axios.post<TrainingStatusResult>('https://safe.artimetrik.net/action.php', payload)
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveEducationOld(payload: EgitimBilgiDto[]): Promise<TrainingStatusResult> {
  const URL_DATA = await getIBYS_URL();
  const TOKEN_DATA = await getAuthenticate(URL_DATA);
  const [err, res] = await tryCatch(
    axios.post<TrainingStatusResult>(`${URL_DATA}/ibys/send-education`, payload, {
      headers: { Authorization: 'Bearer ' + TOKEN_DATA.data },
    })
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getIbysTrainingsStatus(ids: string[]) {
  const URL_DATA = await getIBYS_URL();
  const TOKEN_DATA = await getAuthenticate(URL_DATA);
  const [err, res] = await tryCatch(
    axios.get(`${URL_DATA}/ibys/trainings/status?ids=${ids}`, { headers: { Authorization: 'Bearer ' + TOKEN_DATA.data } })
  );
  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }
  return res.data.data;
}
