import { Checkbox, FormControlLabel, IconButton, List, ListItem, makeStyles, Popover } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import React, { useContext, useState } from 'react';

import { EmployeeListContext } from '../employee-list.context';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 58 * 4.5,
  },
}));

export default function VisibleColumnMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { cells, setCellVisibility } = useContext(EmployeeListContext);

  return (
    <>
      <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)} color="inherit">
        <ViewColumnIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: -150 }}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          className: classes.menuPaper,
        }}>
        <List>
          {cells
            .filter(({ hideable }) => hideable)
            .map(({ id, visible, label }) => (
              <ListItem key={id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={id}
                      checked={visible}
                      color="primary"
                      onChange={({ target: { checked } }) => setCellVisibility(id, checked)}
                    />
                  }
                  label={label}
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
}
