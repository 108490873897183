import { makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';

import { useTranslate } from 'utils';

import { EmployeeListContext } from '../employee-list.context';

const useStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    maxWidth: 200,
  },
}));

export default function Head() {
  const classes = useStyles();
  const {
    cells,
    filters: {
      pagingAndSortingRequestParam: { order },
    },
    updateFilters,
  } = useContext(EmployeeListContext);
  const translate = useTranslate('employee');

  const [orderEntity, sortDirection] = useMemo(() => (order ? order.split('-') : [null, false]) as any, [order]);

  const handleSorting = useCallback(
    (path: string) => {
      updateFilters({
        pagingAndSortingRequestParam: {
          order: !orderEntity || orderEntity !== path ? `${path}-ASC` : sortDirection === 'ASC' ? `${path}-DESC` : '',
          page: 0,
        },
      });
    },
    [orderEntity, sortDirection, updateFilters]
  );

  return (
    <TableHead>
      <TableRow>
        {cells
          .filter(({ visible }) => visible)
          .map(({ label, entityPath, sortable }, index) => (
            <TableCell
              key={index}
              className={`${classes.cell} p-1 text-bold text-ellipsis`}
              sortDirection={entityPath === orderEntity ? sortDirection.toLowerCase() : false}>
              {!sortable ? (
                translate(label)
              ) : (
                <TableSortLabel
                  active={orderEntity === entityPath}
                  direction={orderEntity === entityPath ? sortDirection.toLowerCase() : 'asc'}
                  onClick={() => handleSorting(entityPath)}>
                  {translate(label)}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        <TableCell className={`${classes.cell} p-1 text-bold text-ellipsis`}>Detay</TableCell>
        <TableCell className={`${classes.cell} p-1 text-bold text-ellipsis`}>İşlem</TableCell>
      </TableRow>
    </TableHead>
  );
}
