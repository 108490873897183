import { Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Tab, Tabs } from '@material-ui/core';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { TabContext, TabPanel } from '@material-ui/lab';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { WorkEquipmentPeriodicControlDto } from 'api/client';
import { WorkEquipmentPeriodicControllerService } from 'api/client';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import { FormInputMultiCheckbox } from 'Common/Form/MultiCheckBox';
import { RadioGroupInput } from 'Common/Form/RadioGroup';

import Button from '../../../../Common/Button';
import AutoComplete from '../../../../Common/Form/AutoComplete';
import DateInput from '../../../../Common/Form/DateInput';
import TextField from '../../../../Common/Form/TextField';
import { AlertContext } from '../../../../context/alert.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';
import { useAsyncEffect, useTriggerRender } from '../../../../utils';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentPeriodicControlDto;
}

const monthly = [
  {
    value: 1,
    text: 'Ocak',
  },
  {
    value: 2,
    text: 'Şubat',
  },
  {
    value: 3,
    text: 'Mart',
  },
  {
    value: 4,
    text: 'Nisan',
  },
  {
    value: 5,
    text: 'Mayıs',
  },
  {
    value: 6,
    text: 'Haziran',
  },
  {
    value: 7,
    text: 'Temmuz',
  },
  {
    value: 8,
    text: 'Ağustos',
  },
  {
    value: 9,
    text: 'Eylül',
  },
  {
    value: 10,
    text: 'Ekim',
  },
  {
    value: 11,
    text: 'Kasım',
  },
  {
    value: 12,
    text: 'Aralık',
  },
];

export default function WorkEquipmentPeriodicForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [tab, setTab] = useState<string>('DAILY');
  const [dataDisplay, setDataDisplay] = useState<boolean>(false);
  const [dataResult, setDataResult] = useState(null);
  const { showMessage } = useContext(AlertContext);
  const [isPlannedButton, setIsPlannedButton] = useState(false);
  const reRender = useTriggerRender();
  const { weeks } = useContext(SelectOptionsContext);
  const { workEquipmentDefinitionId } = useParams<{ workEquipmentDefinitionId: string }>();

  const submit = useCallback(
    async values => {
      try {
        if (!data) {
          const res = await WorkEquipmentPeriodicControllerService.saveWorkEquipmentPeriodic({
            ...values,
            frequencyType: tab,
            workEquipmentDefinitionId,
          });
          showMessage('Başarılı ile oluşturuldu', 'success', 'info');
          setDataResult(res);
          setDataDisplay(true);
        } else {
          const res = await WorkEquipmentPeriodicControllerService.updateWorkEquipmentPeriodic(data?.id, {
            ...values,
            frequencyType: tab,
            workEquipmentDefinitionId,
          });
          showMessage('Başarılı ile güncellendi', 'success', 'info');
          setDataResult(res);
          setDataDisplay(true);
        }
        setIsPlannedButton(true);
      } catch (e) {
        showMessage('Hata oluştu.', 'error', 'info');
      }
    },
    [showMessage, tab, setDataResult, setDataDisplay, data, workEquipmentDefinitionId, setIsPlannedButton]
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
    setDataDisplay(false);
  };

  useAsyncEffect(async () => {
    if (!data) return;
    await defer();
    setTab(data?.cronRequestAsJson?.frequencyType);
    for (const [key, value] of Object.entries(data?.cronRequestAsJson)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    await formRef?.current?.methods?.trigger();
  }, [setTab, data, open]);

  const radioOptions = [
    {
      text: 'Her Gün',
      value: 'isEveryDay',
    },
    {
      text: 'Haftaiçi hergün',
      value: 'isEveryWeekDay',
    },
  ];

  return (
    <>
      <Dialog
        width="sm"
        color="secondary"
        opened={open}
        onClose={() => {
          onClose(false);
          setDataDisplay(false);
          setIsPlannedButton(false);
        }}
        title={data ? 'Periyodik Kontrol Düzenle' : 'Yeni Periyodik Kontrol Ekle'}>
        <Form onChange={reRender} ref={formRef} onSubmit={submit}>
          <Grid container spacing={1}>
            <TabContext value={tab.toString()}>
              <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab wrapped label={`Günlük`} value={'DAILY'} />
                <Tab wrapped label={`Haftalık `} value={'WEEKLY'} />
                <Tab wrapped label={`Aylık`} value={'MONTHLY'} />
                <Tab wrapped label={`Yıllık`} value={'YEARLY'} />
              </Tabs>
              <TabPanel value={'DAILY'}>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} sm={12}>
                    <RadioGroupInput name="dailyOption" options={radioOptions} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={'WEEKLY'}>
                <Grid container>
                  <FormInputMultiCheckbox label="Haftanın Günleri" name="weekDays" options={weeks} />
                </Grid>
              </TabPanel>
              <TabPanel value={'MONTHLY'}>
                <Grid container spacing={1}>
                  <Grid className="mt-1" item xs={1} md={1} lg={1} sm={1}>
                    <span>Her </span>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} sm={3}>
                    <TextField name="frequencyCount" label="" />
                  </Grid>
                  <Grid className="mt-1" item xs={2} md={2} lg={2} sm={2}>
                    <span>ayda bir </span>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} sm={3}>
                    <TextField name="startMonthDayIndex" label="" />
                  </Grid>
                  <Grid className="mt-1" item xs={1} md={1} lg={1} sm={1}>
                    <span>. gün </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={'YEARLY'}>
                <Grid container spacing={1}>
                  <Grid className="mr-1 mt-1" item xs={1} md={1} lg={1} sm={1}>
                    <span>Her </span>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} sm={4}>
                    <AutoComplete single name="month" options={monthly} label="" />
                  </Grid>
                  <Grid className="mr-2 mt-1" item xs={1} md={1} lg={1} sm={1}>
                    <span>ayının </span>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} sm={3}>
                    <TextField type="number" name="startMonthDayIndex" label="" />
                  </Grid>
                  <Grid className="mt-1" item xs={2} md={2} lg={2} sm={2}>
                    <span>.gününde </span>
                  </Grid>
                </Grid>
              </TabPanel>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <TextField name="controlLabel" label="Periyodik Kontrol Adı" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <DateInput label="Başlangıç Tarihi" name="startDate" type="date" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <DateInput defaultValue="12:30" label="Kontrol Zamanı" name="hour" type="time" />
                </Grid>
                <Grid className="text-right mt-1" item xs={4} md={4} lg={4} sm={4}>
                  <Button disabled={isPlannedButton} type="submit" variant="contained" color="secondary">
                    {data ? ' Planı Düzenle' : 'Planla'}
                  </Button>
                </Grid>
              </Grid>
              {dataDisplay ? (
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Sonraki Planlanan Tarihler
                    </ListSubheader>
                  }>
                  <Grid container>
                    <Grid item sm={12} lg={12} md={12} xs={12}>
                      <ListItem button>
                        <ListItemIcon>
                          <AlarmOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={dataResult?.cronDescription} />
                      </ListItem>
                    </Grid>
                    {dataResult?.nextExecutionDates?.map((item, index) => (
                      <Grid key={index} item sm={6} lg={6} md={6} xs={6}>
                        <ListItem key={index} button>
                          <ListItemIcon>
                            <TimelapseIcon />
                          </ListItemIcon>
                          <ListItemText primary={item} secondary={item?.cronDescription} />
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                </List>
              ) : null}
              <Grid container>
                <Grid className="text-right" xs={12}>
                  <Button
                    onClick={() => {
                      onClose(false);
                      setIsPlannedButton(false);
                    }}
                    type="button"
                    variant="contained"
                    color="inherit">
                    Kapat
                  </Button>
                </Grid>
              </Grid>
            </TabContext>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
