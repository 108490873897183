import React, { PropsWithChildren, useCallback, useState } from 'react';

import { getProtocolNumberNextNumber, ProtocolNumberResult } from 'api/hs/protocol-number';

export const ProtocolNumberContext = React.createContext(null);

export function ProtocolNumberProvider({ children }: PropsWithChildren<unknown>) {
  const [showProtocolNumber, setShowProtocolNumber] = useState<ProtocolNumberResult>();

  const ProtocolNextNumber = useCallback(
    async (protocolSourceType: string) => {
      const res = await getProtocolNumberNextNumber(protocolSourceType);
      setShowProtocolNumber(res);
    },
    [setShowProtocolNumber]
  );

  return <ProtocolNumberContext.Provider value={{ ProtocolNextNumber, showProtocolNumber }}>{children}</ProtocolNumberContext.Provider>;
}
