/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentReviewActionRequest } from '../models/AccidentReviewActionRequest';
import type { AccidentReviewAnalysisApparentReasonRequest } from '../models/AccidentReviewAnalysisApparentReasonRequest';
import type { AccidentReviewAnalysisFacilitatingFactorRequest } from '../models/AccidentReviewAnalysisFacilitatingFactorRequest';
import type { AccidentReviewAnalysisImprovementRequest } from '../models/AccidentReviewAnalysisImprovementRequest';
import type { AccidentReviewCommunicationDto } from '../models/AccidentReviewCommunicationDto';
import type { AccidentReviewCommunicationRequest } from '../models/AccidentReviewCommunicationRequest';
import type { AccidentReviewCsiDto } from '../models/AccidentReviewCsiDto';
import type { AccidentReviewCsiRequest } from '../models/AccidentReviewCsiRequest';
import type { AccidentReviewDocumentDto } from '../models/AccidentReviewDocumentDto';
import type { AccidentReviewDocumentRequest } from '../models/AccidentReviewDocumentRequest';
import type { AccidentReviewDto } from '../models/AccidentReviewDto';
import type { AccidentReviewEmployeeWitnessRequest } from '../models/AccidentReviewEmployeeWitnessRequest';
import type { AccidentReviewerDto } from '../models/AccidentReviewerDto';
import type { AccidentReviewFilter } from '../models/AccidentReviewFilter';
import type { AccidentReviewFullDto } from '../models/AccidentReviewFullDto';
import type { AccidentReviewGuestWitnessRequest } from '../models/AccidentReviewGuestWitnessRequest';
import type { AccidentReviewWitnessDto } from '../models/AccidentReviewWitnessDto';
import type { AccidentReviewWitnessUpdateRequest } from '../models/AccidentReviewWitnessUpdateRequest';
import type { ActionDto } from '../models/ActionDto';
import type { ApparentReasonResponse } from '../models/ApparentReasonResponse';
import type { FacilitatingFactorResponse } from '../models/FacilitatingFactorResponse';
import type { ImprovementResponse } from '../models/ImprovementResponse';
import type { PageActionView } from '../models/PageActionView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentReviewControllerService {

    /**
     * @param accidentId
     * @param csiId
     * @param note
     * @param fileTypeId
     * @param orderNum
     * @param auth
     * @param request
     * @param authenticationInfo
     * @param formData
     * @returns AccidentReviewCsiDto OK
     * @throws ApiError
     */
    public static updateCsi(
        accidentId: string,
        csiId: string,
        note: string,
        fileTypeId: string,
        orderNum: number,
        auth: AccidentReviewerDto,
        request: AccidentReviewCsiRequest,
        authenticationInfo?: string,
        formData?: {
            image?: Blob;
        },
    ): CancelablePromise<AccidentReviewCsiDto> {
        return __request({
            method: 'PUT',
            path: `/accidents/${accidentId}/reviews/research/csi/${csiId}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'note': note,
                'fileTypeId': fileTypeId,
                'orderNum': orderNum,
                'auth': auth,
                'request': request,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param witnessId
     * @param requestBody
     * @param authenticationInfo
     * @returns AccidentReviewWitnessDto OK
     * @throws ApiError
     */
    public static updateGuestWitness(
        witnessId: string,
        requestBody: AccidentReviewWitnessUpdateRequest,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewWitnessDto> {
        return __request({
            method: 'PUT',
            path: `/accidents/reviews/research/witnesses/${witnessId}/guest`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param witnessId
     * @param requestBody
     * @param authenticationInfo
     * @returns AccidentReviewWitnessDto OK
     * @throws ApiError
     */
    public static updateEmployeeWitness(
        witnessId: string,
        requestBody: AccidentReviewWitnessUpdateRequest,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewWitnessDto> {
        return __request({
            method: 'PUT',
            path: `/accidents/reviews/research/witnesses/${witnessId}/employee`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param documentId
     * @param note
     * @param fileTypeId
     * @param orderNum
     * @param auth
     * @param request
     * @param authenticationInfo
     * @param formData
     * @returns AccidentReviewDocumentDto OK
     * @throws ApiError
     */
    public static updateDocument(
        documentId: string,
        note: string,
        fileTypeId: string,
        orderNum: number,
        auth: AccidentReviewerDto,
        request: AccidentReviewDocumentRequest,
        authenticationInfo?: string,
        formData?: {
            document?: Blob;
        },
    ): CancelablePromise<AccidentReviewDocumentDto> {
        return __request({
            method: 'PUT',
            path: `/accidents/reviews/research/documents/${documentId}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'note': note,
                'fileTypeId': fileTypeId,
                'orderNum': orderNum,
                'auth': auth,
                'request': request,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param documentId
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDocument(
        documentId: string,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/reviews/research/documents/${documentId}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns AccidentReviewWitnessDto OK
     * @throws ApiError
     */
    public static saveGuestWitness(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewGuestWitnessRequest,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewWitnessDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/research/witnesses/guest`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns AccidentReviewWitnessDto OK
     * @throws ApiError
     */
    public static saveEmployeeWitness(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewEmployeeWitnessRequest,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewWitnessDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/research/witnesses/employee`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns AccidentReviewDocumentDto OK
     * @throws ApiError
     */
    public static getDocuments(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<Array<AccidentReviewDocumentDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/research/documents`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param note
     * @param fileTypeId
     * @param orderNum
     * @param auth
     * @param request
     * @param authenticationInfo
     * @param formData
     * @returns AccidentReviewDocumentDto OK
     * @throws ApiError
     */
    public static saveDocument(
        accidentId: string,
        note: string,
        fileTypeId: string,
        orderNum: number,
        auth: AccidentReviewerDto,
        request: AccidentReviewDocumentRequest,
        authenticationInfo?: string,
        formData?: {
            document: Blob;
        },
    ): CancelablePromise<AccidentReviewDocumentDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/research/documents`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'note': note,
                'fileTypeId': fileTypeId,
                'orderNum': orderNum,
                'auth': auth,
                'request': request,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns AccidentReviewCsiDto OK
     * @throws ApiError
     */
    public static getCsi(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<Array<AccidentReviewCsiDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/research/csi`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param note
     * @param fileTypeId
     * @param orderNum
     * @param auth
     * @param request
     * @param authenticationInfo
     * @param formData
     * @returns AccidentReviewCsiDto OK
     * @throws ApiError
     */
    public static saveCsi(
        accidentId: string,
        note: string,
        fileTypeId: string,
        orderNum: number,
        auth: AccidentReviewerDto,
        request: AccidentReviewCsiRequest,
        authenticationInfo?: string,
        formData?: {
            image: Blob;
        },
    ): CancelablePromise<AccidentReviewCsiDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/research/csi`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'note': note,
                'fileTypeId': fileTypeId,
                'orderNum': orderNum,
                'auth': auth,
                'request': request,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns AccidentReviewCommunicationDto OK
     * @throws ApiError
     */
    public static getCommunication(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewCommunicationDto> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/communications`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static saveCommunication(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewCommunicationRequest,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/communications`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns ImprovementResponse OK
     * @throws ApiError
     */
    public static getImprovements(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<ImprovementResponse> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/analysis/improvements`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static saveImprovements(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewAnalysisImprovementRequest,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/analysis/improvements`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns FacilitatingFactorResponse OK
     * @throws ApiError
     */
    public static getFacilitatingFactors(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<FacilitatingFactorResponse> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/analysis/facilitating-factors`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static saveFacilitatingFactors(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewAnalysisFacilitatingFactorRequest,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/analysis/facilitating-factors`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns ApparentReasonResponse OK
     * @throws ApiError
     */
    public static getApparentReasons(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<ApparentReasonResponse> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/analysis/apparent-reasons`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static saveApparentReasons(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewAnalysisApparentReasonRequest,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/analysis/apparent-reasons`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns PageActionView OK
     * @throws ApiError
     */
    public static getActions(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<PageActionView> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/actions`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns ActionDto OK
     * @throws ApiError
     */
    public static saveAction1(
        accidentId: string,
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewActionRequest,
        authenticationInfo?: string,
    ): CancelablePromise<ActionDto> {
        return __request({
            method: 'POST',
            path: `/accidents/${accidentId}/reviews/actions`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param dto
     * @param authenticationInfo
     * @returns AccidentReviewDto OK
     * @throws ApiError
     */
    public static getReview(
        accidentId: string,
        dto: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewDto> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'dto': dto,
            },
        });
    }

    /**
     * @param accidentId
     * @param requestBody
     * @returns AccidentReviewDto OK
     * @throws ApiError
     */
    public static updateReview(
        accidentId: string,
        requestBody: AccidentReviewDto,
    ): CancelablePromise<AccidentReviewDto> {
        return __request({
            method: 'PATCH',
            path: `/accidents/${accidentId}/reviews`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param auth
     * @param requestBody
     * @param authenticationInfo
     * @returns AccidentReviewDto OK
     * @throws ApiError
     */
    public static updateReview1(
        auth: AccidentReviewerDto,
        requestBody: AccidentReviewDto,
        authenticationInfo?: string,
    ): CancelablePromise<AccidentReviewDto> {
        return __request({
            method: 'PATCH',
            path: `/accidents/reviews`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param accidentId
     * @param auth
     * @param authenticationInfo
     * @returns AccidentReviewWitnessDto OK
     * @throws ApiError
     */
    public static getWitnesses(
        accidentId: string,
        auth: AccidentReviewerDto,
        authenticationInfo?: string,
    ): CancelablePromise<Array<AccidentReviewWitnessDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/research/witnesses`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
            query: {
                'auth': auth,
            },
        });
    }

    /**
     * @param accidentId
     * @returns binary OK
     * @throws ApiError
     */
    public static exportCommunication(
        accidentId: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/reviews/communications/export`,
        });
    }

    /**
     * @param accidentId
     * @param employeeId
     * @returns binary OK
     * @throws ApiError
     */
    public static exportAccidentReview(
        accidentId: string,
        employeeId: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/accidents/${accidentId}/employees/${employeeId}/reviews/export`,
        });
    }

    /**
     * @param filter
     * @returns AccidentReviewFullDto OK
     * @throws ApiError
     */
    public static getReviewList(
        filter: AccidentReviewFilter,
    ): CancelablePromise<Array<AccidentReviewFullDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/list`,
            query: {
                'filter': filter,
            },
        });
    }

    /**
     * @param witnessId
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static deleteWitness(
        witnessId: string,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/reviews/research/witnesses/${witnessId}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
        });
    }

    /**
     * @param csiId
     * @param authenticationInfo
     * @returns any OK
     * @throws ApiError
     */
    public static deleteCsi(
        csiId: string,
        authenticationInfo?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/accidents/reviews/research/csi/${csiId}`,
            headers: {
                'Authentication-Info': authenticationInfo,
            },
        });
    }

}