import { eventTypes } from '../Calendar/event-types';

export default {
  employee: '#00bcd4',
  referredEmployee: '#17A4B7',
  examination: eventTypes.PERIODIC_EXAMINATION.color,
  vaccine: eventTypes.VACCINE.color,
  pregnancy: eventTypes.PREGNANCY.color,
  passive: '#969595',
  action: eventTypes.ACTION.color,
  nearMiss: '#00695f',
  accident: '#8e24aa',
  training: '#2196f3',
  lostDay: '#ab003c',
};
