import { FormControl, FormHelperText, makeStyles, Slider as MUISlider, Typography } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { FormContext } from './Form/form.context';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  label: {
    fontSize: 'medium !important',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export type SliderRules = ControllerProps<'input'>['rules'];

interface Props {
  name: string;
  label?: string;
  rules?: SliderRules;
  min?: number;
  max?: number;
  disabled?: boolean;
}

export default function Slider({ name, label = '', rules = {}, min = 0, max = 100, disabled = false }: Props) {
  const classes = useStyles();
  const { errors, control, trigger } = useFormContext();
  const { readonly } = useContext(FormContext);

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  return (
    <FormControl fullWidth error={!!errors[name]} className={classes.root}>
      <Typography className={`h6 ${classes.label}`}>{label}</Typography>
      <Controller
        rules={rules}
        control={control}
        defaultValue={0}
        name={name}
        render={({ onChange, value }) => (
          <div className="px-1">
            <MUISlider
              value={value}
              min={min}
              max={max}
              valueLabelDisplay="auto"
              onChange={(_, newValues) => {
                onChange(newValues);
              }}
              onChangeCommitted={() => {
                control.updateFormState({ touched: { ...control.formStateRef.current.touched, [name]: true } });
                trigger(name);
              }}
              disabled={notEditable}
            />
          </div>
        )}
      />
      <FormHelperText>{errors[name]?.message}</FormHelperText>
    </FormControl>
  );
}
