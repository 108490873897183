import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { ComplaintsResult, getComplaint } from 'api/hs/examination/complaint';
import { ComplaintModel } from 'model/employee/examination/complaint.model';
import { useAsyncEffect } from 'utils';

import Complaint from './Complaint';
import { ComplaintsContextContext } from './complaints.context';

export default function Index() {
  const [complaints, setComplaints] = useState<ComplaintsResult>(null);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<ComplaintModel>(null);
  const { examinationId } = useParams<{ examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getComplaint(examinationId);
    setComplaints(result);
    setLoading(false);
  }, [examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <ComplaintsContextContext.Provider
      value={{
        complaints,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Complaint />
    </ComplaintsContextContext.Provider>
  );
}
