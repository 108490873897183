import { makeStyles } from '@material-ui/core';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import React from 'react';

const useStyles = makeStyles(theme => ({
  archiveDialogIcon: {
    width: 75,
    height: 75,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.error.main,
  },
}));

export default function ArchiveEmployeeDialogIcon() {
  const classes = useStyles();

  return <ArchiveOutlinedIcon className={`${classes.archiveDialogIcon} my-2`} />;
}
