/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkEquipmentPeriodicControlDto } from '../models/WorkEquipmentPeriodicControlDto';
import type { WorkEquipmentPeriodicControlRequest } from '../models/WorkEquipmentPeriodicControlRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkEquipmentPeriodicControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentPeriodicControlDto OK
     * @throws ApiError
     */
    public static updateWorkEquipmentPeriodic(
        id: string,
        requestBody: WorkEquipmentPeriodicControlRequest,
    ): CancelablePromise<WorkEquipmentPeriodicControlDto> {
        return __request({
            method: 'PUT',
            path: `/work-equipment-periodic-controls/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllWorkEquipmentPeriodic(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-periodic-controls/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns WorkEquipmentPeriodicControlDto OK
     * @throws ApiError
     */
    public static saveWorkEquipmentPeriodic(
        requestBody: WorkEquipmentPeriodicControlRequest,
    ): CancelablePromise<WorkEquipmentPeriodicControlDto> {
        return __request({
            method: 'POST',
            path: `/work-equipment-periodic-controls`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns WorkEquipmentPeriodicControlDto OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentPeriodicControls(
        definitionId: string,
    ): CancelablePromise<Array<WorkEquipmentPeriodicControlDto>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-periodic-controls/${definitionId}`,
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getAllWeekDaysAsEnum(): CancelablePromise<Array<'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-periodic-controls/weekdays`,
        });
    }

}