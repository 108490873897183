import { tryCatch } from '@thalesrc/js-utils';

import { dateSort } from '../../../../utils';
import HSApi from '../../HSApi';
import { EmployeeImportedFilesResult } from './employee-imported-files.result';

export async function getEmployeeImportedFiles(employeeId: string): Promise<EmployeeImportedFilesResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EmployeeImportedFilesResult[]>(`/employees/${employeeId}/imported-files`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return dateSort(res.data);
}

export async function saveEmployeeImported(employeeId: string, file: File): Promise<EmployeeImportedFilesResult> {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/employees/${employeeId}/imported-files`, data));
  if (err) {
    throw err.response.data.message;
  }

  return res.data;
}

export async function deleteEmployeeImported(id: EmployeeImportedFilesResult['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/employees/imported-files/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
