import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { Fragment, useContext, useEffect } from 'react';

import { Icd10CacheContext } from 'Common/Form/Icd10Selection/icd10.context';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { ResourceContext } from 'context/resource.context';

import { FamilyHistoryContext } from '../../family-history.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function Listtt() {
  const { loading, familyHistory } = useContext(FamilyHistoryContext);
  const { bloodRelation } = useContext(ResourceContext);
  const { getOptions, options } = useContext(Icd10CacheContext);

  useEffect(() => {
    if (!familyHistory) {
      return;
    }
    getOptions(...familyHistory.reduce((acc, item) => [...acc, ...item.icd10s], []));
  }, [getOptions, familyHistory]);

  return (
    <IndicatorWrapper loading={loading} data={familyHistory} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <List id="listReferrals">
        {familyHistory?.map((item, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemAvatar>
                <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-dna" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography component="div">
                    <Typography>
                      <b>{findByKey(bloodRelation, 'id', item.bloodRelationDefinitionId)?.label}</b>
                    </Typography>
                    {item?.icd10s
                      ?.map(id => findByKey(options, 'id', id))
                      ?.filter(isTruthy)
                      ?.map(({ id, label }) => (
                        <Fragment key={id}>
                          <Icon style={{ color: 'gray' }} className="icon-heart-pulse" />
                          {label} {` `}
                        </Fragment>
                      ))}
                  </Typography>
                }
                secondary={
                  <Typography className="text-italic">
                    <Icon style={{ color: 'gray' }} className="icon-document" /> {item?.description}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
