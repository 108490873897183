import { CircularProgress } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

interface Props {
  loading: boolean;
}

export default function LoadingWrapper({ loading, children }: PropsWithChildren<Props>) {
  return (
    <>
      {loading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </>
  );
}
