import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { FineKinneyHazardScoreDefinitionDto } from '../../../../api/client';
import { FineKinneyHazardScoreDefinitionControllerService } from '../../../../api/client';
import { updateFineKinneyHazardScoreDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: FineKinneyHazardScoreDefinitionDto;

  onClose(success: boolean): void;
}

export default function FineKinneyScoreDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<FineKinneyHazardScoreDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={FineKinneyHazardScoreDefinitionControllerService.saveFineKinneyScoreDefinitions}
      onUpdate={updateFineKinneyHazardScoreDefinitionUpdate}
      data={data}
      createTitle="Fine-Kinney Matris Skor Ekleme"
      updateTitle="Fine-Kinney Matris Skor Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="İsmi" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="score" label="Skor" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="description" label="Açıklama" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="deadline" label="Zaman Sınırı" />
      </Grid>
    </BaseDefinitionForm>
  );
}
