import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MoreIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';

import { exportNearMissList } from '../../../../../api/hs/accident/accident';
import VisibleColumnMenu from '../../../../../TransactionsAndLists/ReportList/VisibleColumnMenu';
import Filters from './Filters';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default function ToolbarNearMissDefinition() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { data, extraArgs } = useContext(HSTableContext);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const exportFile = useCallback(async () => {
    await exportNearMissList(extraArgs.filters);

    closeMenu();
  }, [closeMenu, extraArgs]);

  const routeNewNearMiss = useCallback(() => {
    history.push(`/near-miss/form`);
  }, [history]);

  return (
    <Toolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Ramak Kala Listesi ({data?.length})
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={openMenu}>
          <MoreIcon />
        </IconButton>
        <VisibleColumnMenu />
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -125 }} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <List>
            <ListItem onClick={exportFile} className="cursor-pointer">
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Excel'e Aktar</Typography>
            </ListItem>
            <ListItem onClick={routeNewNearMiss} className="cursor-pointer">
              <ListItemIcon>
                <AddBoxIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Yeni Ramak Kala</Typography>
            </ListItem>
          </List>
        </Popover>
      </div>
      <Filters />
    </Toolbar>
  );
}
