import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { GroupDefinitionsPayload } from './group-definitions.payload';
import { GroupDefinitionsResult } from './group-definitions.result';

export async function getGroupDefinitions(): Promise<GroupDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GroupDefinitionsResult[]>(`/firms/trainings/group-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveGroupDefinitions(body: GroupDefinitionsPayload): Promise<GroupDefinitionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/trainings/group-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateGroupDefinitions({
  id,
  ...body
}: MakeRequired<Partial<GroupDefinitionsResult>, 'id'>): Promise<GroupDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<GroupDefinitionsResult>(`/firms/trainings/group-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteGroupDefinitions(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/trainings/group-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminGroupDefinitions(): Promise<GroupDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GroupDefinitionsResult[]>(`/trainings/group-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminGroupDefinitions(body: GroupDefinitionsPayload): Promise<GroupDefinitionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/trainings/group-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminGroupDefinitions({
  id,
  ...body
}: MakeRequired<Partial<GroupDefinitionsResult>, 'id'>): Promise<GroupDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<GroupDefinitionsResult>(`/trainings/group-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminGroupDefinitions(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/group-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
