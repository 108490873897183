import HSApi from 'api/hs/HSApi';

import { ProtocolNumberResult } from './protocol-number.result';

export async function getProtocolNumberNextNumber(protocolSourceType: string): Promise<ProtocolNumberResult> {
  let res;
  try {
    res = await HSApi.get<ProtocolNumberResult>(`/protocols/registries/next-number?protocolSourceType=${protocolSourceType}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}
