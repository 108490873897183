export function printIframe(iframe: HTMLIFrameElement): void {
  iframe.onload = () => {
    iframe.contentWindow.print();
    window.addEventListener(
      'click',
      () => {
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 500);
      },
      { once: true }
    );
  };
  document.body.appendChild(iframe);
}
