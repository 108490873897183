import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAdminStation, updateAdminStation } from 'api/hs/definitions/workplace-definition/station';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { StationModel } from 'model/definitions/workplace-definition/station.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the station is created/updated
   */
  onClose(success: boolean): void;

  data: StationModel;
}

export default function AdminStation({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm
      open={open}
      onClose={onClose}
      onSave={saveAdminStation}
      onUpdate={updateAdminStation}
      data={data}
      resourceReloadName="workStations"
      createTitle="İş İstasyonu Ekle"
      updateTitle="İş İstasyonu Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="stationName" label="İş İstasyon Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
