import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { WorkAccidentHistory } from './work-accident-history.model';
import { SaveWorkAccidentHistoryPayload, UpdateWorkAccidentHistoryPayload } from './work-accident-history.payload';
import { WorkAccidentHistoryResult } from './work-accident-history.result';

export async function getWorkAccidentHistory(employeeId: string, examinationId: string): Promise<WorkAccidentHistory[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<WorkAccidentHistoryResult>(`/work-accident-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<WorkAccidentHistoryResult>(`/work-accident-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return WorkAccidentHistory.fromServerListData(dateSort(res.data));
}

export async function saveWorkAccidentHistory(body: SaveWorkAccidentHistoryPayload): Promise<WorkAccidentHistoryResult> {
  const [err, res] = await tryCatch(HSApi.post('/work-accident-histories', body));

  if (err) {
    console.error(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateWorkAccidentHistory(id: string, body: UpdateWorkAccidentHistoryPayload): Promise<WorkAccidentHistoryResult> {
  const [err, res] = await tryCatch(HSApi.put(`/work-accident-histories/${id}`, body));

  if (err) {
    console.error(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteWorkAccidentHistory(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/work-accident-histories/${id}`));

  if (err) {
    console.error(err); // tslint:disable-line:no-console
  }

  return res.data;
}
