import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import React, { useLayoutEffect } from 'react';

interface ChartProps {
  name: string;
  data: any[];
  chartRef: any;
}

const DefaultPieChart = (props: ChartProps) => {
  useLayoutEffect(() => {
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
    const c = am4core.create(props.name, am4charts.PieChart);
    c.data = props.data;
    c.exporting.menu = new am4core.ExportMenu();

    const pieSeries = c.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'count';
    pieSeries.dataFields.category = 'name';
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    c.legend = new am4charts.Legend();
    // c.legend.maxWidth = 2000;
    // c.legend.width = 700;
    // c.legend.contentAlign = "center";
    // c.legend.horizontalCenter = "middle";
    props.chartRef.current = c;
    return () => {
      c.dispose();
    };
  });

  return <div id={props.name} style={{ width: '100%', height: '600px' }} />;
};

export default DefaultPieChart;
