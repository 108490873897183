import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminOpinion, getAdminOpinion, OpinionResult } from 'api/hs/definitions/health-definitions/opinion';
import AdminOpinionEditDialog from 'Common/Forms/Definitions/HealthDefinition/Opinion/AdminOpinionEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Opinion() {
  return (
    <BaseDefinitionPage<OpinionResult>
      listLabelPropName="opinion"
      fetchData={getAdminOpinion}
      FormDialog={AdminOpinionEditDialog}
      onDelete={deleteAdminOpinion}
      Icon={<DomainIcon />}
    />
  );
}
