import { Grid } from '@material-ui/core';
import { defer, replace } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import { createTrainer } from 'api/hs/trainings';
import Button from 'Common/Button';
import Form, { FormRef } from 'Common/Form/Form';
import GenerativeAutoComplete, { GenerativeAutoCompleteRef } from 'Common/Form/GenerativeAutoComplete';
import Select, { SelectOption } from 'Common/Form/Select';
import Switch from 'Common/Form/Switch';
import TextField from 'Common/Form/TextField';
import { ResourceContext } from 'context/resource.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { NID_MASK } from 'model';
import { useAsyncEffect, useTranslate, useTriggerRender, useXhr } from 'utils';

import AutoComplete from '../../Common/Form/AutoComplete';
import DateInput from '../../Common/Form/DateInput';
import { PlanningStep } from './planning-steps.enum';
import { GuestTrainer, PlanningContext } from './planning.context';
import { useWatch } from 'react-hook-form';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function Info() {
  const reRender = useTriggerRender();
  const { setActiveStep, setInfo, info, activeStep, importFromExcelVisible, setImportFromExcelVisible } = useContext(PlanningContext);
  const formRef = useRef<FormRef<{ trainerIds: (string | symbol)[] }>>();
  const generativeAutoCompleteRef = useRef<GenerativeAutoCompleteRef>();
  const { trainingLocation, trainingType, trainers, trainingDocumentType, yesNoBoolean, internalStatus } = useContext(SelectOptionsContext);
  const { reload } = useContext(ResourceContext);
  const [temporaryTrainer, setTemporaryTrainer] = useState<SelectOption<symbol>[]>([]);
  const translate = useTranslate('training');

  const handleSubmit = useCallback(
    values => {
      setInfo(values);
      setActiveStep(PlanningStep.Duration);
    },
    [setActiveStep, setInfo]
  );

  const createTrainerReq = useXhr(createTrainer, translate('New trainer created succesfully'), translate('An error occured'), []);

  const handleGuestSubmit = useCallback(
    async (
      id: symbol,
      { name, lastName, isInternal, title, trainerTypeDefinition, nid, employeeId, isEmployee }: Omit<GuestTrainer, 'id'>
    ) => {
      setTemporaryTrainer([{ text: `${name} ${lastName}`, value: id }]);
      const { id: trainerIds } = await createTrainerReq({
        name,
        lastName,
        isInternal,
        title,
        trainerTypeDefinition,
        nid,
        employeeId: employeeId?.[0],
        isEmployee,
      });

      reload('trainers');
      formRef.current?.methods.setValue('trainerIds', replace(formRef.current?.value.trainerIds, id, trainerIds));
    },
    [createTrainerReq, reload]
  );

  const trainerOptions = useMemo(() => [...trainers, ...temporaryTrainer], [trainers, temporaryTrainer]);

  useAsyncEffect(async () => {
    if (!info || activeStep !== PlanningStep?.TrainingInfo) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(info)) {
      formRef?.current?.methods?.setValue(key as any, value);
    }

    await formRef?.current?.methods?.trigger();
  }, [info, activeStep]);

  return (
    <Form ref={formRef} onChange={reRender} onSubmit={handleSubmit} className="pt-2">
      <Grid spacing={1} container>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <TextField name="title" label="Training Name" required />
        </Grid>
        <Grid item xs={12} sm={6} className="pl-sm-1">
          <GenerativeAutoComplete<{}, {}>
            ref={generativeAutoCompleteRef}
            onFormChange={reRender}
            name="trainerIds"
            options={trainerOptions}
            label="Trainer"
            dialogLabel={translate('Add New Trainer')}
            adornment={open => (
              <Button color="secondary" onClick={open}>
                {translate('Add New Trainer')}
              </Button>
            )}
            onGenerate={handleGuestSubmit}
            required>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <TextField required mask={NID_MASK} name="nid" label="T.C. Kimlik No" />
              </Grid>
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <TextField name="name" label="Trainer Name" required />
              </Grid>
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <TextField name="lastName" label="Trainer Lastname" required />
              </Grid>
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <Select name="trainerTypeDefinition" options={trainingDocumentType} label="Eğitimci Tipi" />
              </Grid>
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <TextField name="title" label="Ünvan" />
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Select name="isInternal" defaultValue={internalStatus[0]?.value} options={internalStatus} label=" " />
              </Grid>
              {generativeAutoCompleteRef?.current?.formValue?.isInternal === true ? (
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Select name="isEmployee" options={yesNoBoolean} label="Şirket Çalışanı mı ?" />
                </Grid>
              ) : null}
              {generativeAutoCompleteRef?.current?.formValue?.isEmployee === true ? (
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <AutoComplete
                    name="employeeId"
                    label="Personel Seç"
                    optionFetcher={employeeOptionFetcher}
                    labelFetcher={employeeLabelFetcher}
                  />
                </Grid>
              ) : null}
            </Grid>
          </GenerativeAutoComplete>
        </Grid>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <DateInput type="date-time" name="plannedDate" label="Planlanan Tarih" />
        </Grid>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <DateInput type="date-time" name="completedDate" label="Gerçekleşen Tarih" />
        </Grid>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <Select name="atWorkplace" options={trainingLocation} label="Training Place" required />
        </Grid>
        <Grid item xs={12} sm={6} className="pl-sm-1">
          <Select name="isFaceToFace" options={trainingType} label="Training Type" required onChangeListener={(value:boolean) =>setImportFromExcelVisible(!value)} />
        </Grid>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <TextField name="location" label="Training Location" />
        </Grid>
        <Grid item xs={12} sm={6} className="pl-sm-1">
          <TextField type="number" name="validityPeriodInMonth" label={translate('Validity Period in Months')} />
        </Grid>
        <Grid item xs={12} sm={6} className="pr-sm-1">
          <Switch name="isInAnnualPlan" label="Yıllık Plan Dahlilinde" />
        </Grid>
        {
          importFromExcelVisible && (
            <Grid item xs={12} sm={6} className="pr-sm-1">
              <Switch name="importFromExcel" label="Katılımcıları Excelden Yükle"/>
            </Grid>
          )
        }
        <Grid item xs={12} className="mt-2">
          <TextField name="description" label="Description" rows={3} />
        </Grid>
      </Grid>
      <footer className="text-right">
        <Button type="submit" variant="contained" color="secondary" disabled={!formRef.current?.valid}>
          Devam
        </Button>
      </footer>
    </Form>
  );
}
