import { createContext } from 'react';

import { HealthInsurancesModel, HealthInsurancesResult } from 'api/hs/health-insurances';

export interface HealthInsurancesContextType {
  healthInsurances: HealthInsurancesResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: HealthInsurancesModel;

  setSelectedData(data: HealthInsurancesModel): void;
}

export const HealthInsurancesContext = createContext<HealthInsurancesContextType>(null);
