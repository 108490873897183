import { createContext } from 'react';

import {
  PeriodicExaminationConfigurationsModel,
  PeriodicExaminationConfigurationsResult,
} from 'api/hs/examination/periodic-examination-configurations';

export interface PeriodicExaminationConfigurationContextType {
  periodicExaminationConfigurations: PeriodicExaminationConfigurationsResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PeriodicExaminationConfigurationsModel;

  setSelectedData(data: PeriodicExaminationConfigurationsModel): void;
}

export const PeriodicExaminationConfigurationContext = createContext<PeriodicExaminationConfigurationContextType>(null);
