import React from 'react';

import { UserRole } from 'model/user-role.enum';

import FineKinneyDamageDefinition from './Risk-FineKinney/FineKinneyDamageDefinition';
import FineKinneyFrequencyDefinition from './Risk-FineKinney/FineKinneyFrequencyDefinition';
import FineKinneyPossibilityDefinition from './Risk-FineKinney/FineKinneyPossibilityDefinition';
import FineKinneyScoreDefinition from './Risk-FineKinney/FineKinneyScoreDefinition';
import HazardCategoryDefinition from './Risk-FineKinney/HazardCategoryDefinition';
import HazardCurrentPrecautionDefinition from './Risk-FineKinney/HazardCurrentPrecautionDefinition';
import MatrixDamageDefinition from './Risk-FineKinney/MatrixDamageDefinition';
import MatrixHazardScoreDefinition from './Risk-FineKinney/MatrixHazardScoreDefinition';
import MatrixPossibilityDefinition from './Risk-FineKinney/MatrixPossibilityDefinition';
interface AdminRiskFineKinneyListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'Mevcut Önlemler',
    Component: HazardCurrentPrecautionDefinition,
    path: '',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'Tehlike Kategori',
    Component: HazardCategoryDefinition,
    path: 'admin-tehlike-kategori',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: '5x5 Değerlendirme',
    Component: MatrixHazardScoreDefinition,
    path: 'admin-risk-skor',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: '5x5 Olasılık',
    Component: MatrixPossibilityDefinition,
    path: 'admin-risk-olasilik',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: '5x5 Şiddet',
    Component: MatrixDamageDefinition,
    path: 'admin-risk-zarar',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'Fine-Kinney Sıklık',
    Component: FineKinneyFrequencyDefinition,
    path: 'admin-fine-kinney-siklik',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'Fine-Kinney Değerlendirme',
    Component: FineKinneyScoreDefinition,
    path: 'admin-fine-kinney-skor',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'Fine-Kinney Olasılık',
    Component: FineKinneyPossibilityDefinition,
    path: 'admin-fine-kinney-olasılık',
    visibleTo: [UserRole.SYS_ADMIN],
  },
  {
    label: 'Fine-Kinney Şiddet',
    Component: FineKinneyDamageDefinition,
    path: 'admin-fine-kinney-zarar',
    visibleTo: [UserRole.SYS_ADMIN],
  },
] as AdminRiskFineKinneyListItem[];
