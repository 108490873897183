import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminRestReport, getAdminRestReport, RestReportResult } from 'api/hs/definitions/health-definitions/rest-report';
import AdminRestReportEditDialog from 'Common/Forms/Definitions/HealthDefinition/RestReport/AdminRestReportEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminRestReport() {
  return (
    <BaseDefinitionPage<RestReportResult>
      listLabelPropName="reasonName"
      fetchData={getAdminRestReport}
      FormDialog={AdminRestReportEditDialog}
      onDelete={deleteAdminRestReport}
      Icon={<DomainIcon />}
    />
  );
}
