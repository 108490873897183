/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { NearMissDto } from '../models/NearMissDto';
import type { NearMissViewRequest } from '../models/NearMissViewRequest';
import type { PageNearMissView } from '../models/PageNearMissView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class NearMissControllerService {

    /**
     * @param requestBody
     * @returns NearMissDto OK
     * @throws ApiError
     */
    public static saveNearMiss(
        requestBody: NearMissDto,
    ): CancelablePromise<NearMissDto> {
        return __request({
            method: 'POST',
            path: `/near-misses`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getNearMissFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/near-misses/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static nearMissFileImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/near-misses/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageNearMissView OK
     * @throws ApiError
     */
    public static getNearMissViews(
        requestBody: NearMissViewRequest,
    ): CancelablePromise<PageNearMissView> {
        return __request({
            method: 'POST',
            path: `/near-misses/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static nearMissViewExport(
        requestBody: NearMissViewRequest,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/near-misses/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns NearMissDto OK
     * @throws ApiError
     */
    public static getNearMiss(
        id: string,
    ): CancelablePromise<NearMissDto> {
        return __request({
            method: 'GET',
            path: `/near-misses/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteNearMiss(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/near-misses/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns NearMissDto OK
     * @throws ApiError
     */
    public static partialUpdateNearMiss(
        id: string,
        requestBody: NearMissDto,
    ): CancelablePromise<NearMissDto> {
        return __request({
            method: 'PATCH',
            path: `/near-misses/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static getNearMissFormExport(
        id: string,
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/near-misses/${id}/forms/raw`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getNearMissFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/near-misses/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteNearMissFile(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/near-misses/imported-files/${id}`,
        });
    }

}