import React, { useCallback, useContext } from 'react';

import { WorkEquipmentPeriodicControlContext } from '../../work-equipment-periodic-control.context';
import WorkEquipmentPeriodicForm from '../Form/WorkEquipmentPeriodicForm';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(WorkEquipmentPeriodicControlContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <WorkEquipmentPeriodicForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
