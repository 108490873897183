import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar, { DatesSetArg, DayHeaderContentArg } from '@fullcalendar/react';
import {
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { SettingsOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import RightIcon from '@material-ui/icons/ChevronRight';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { dateToInputFormat, useDateFormat, useDialogState } from 'utils';

import { getAll, reschedule } from '../../api/hs/dashboard/event/event';
import { EventQueryPayload, ReschedulePayload, UserEventType } from '../../api/hs/dashboard/event/event.payload';
import { AlertContext } from '../../context/alert.context';
import Header from '../Header';
import { CalendarContext, CalendarContextType } from './calendar.context';
import EventPreviewDialog from './Dialog/EventPreviewDialog';
import NewEventDialog from './Dialog/NewEventDialog';
import UpdateEventDialog from './Dialog/UpdateEventDialog';
import { eventTypes } from './event-types';

const eventKeys = Object.keys(eventTypes);

const useStyles = makeStyles(theme => ({
  actionIcon: {
    color: 'inherit',
  },
}));

export default function Calendar() {
  const ref = useRef<FullCalendar>();
  const formatTitle = useDateFormat('MMMM yyyy');
  const formatDayName = useDateFormat('EEEE');
  const [calendarStartDate, setCalendarStartDate] = useState();
  const [calendarEndDate, setCalendarEndDate] = useState();
  const [calendarMonth, setCalendarMonth] = useState(new Date());
  const [settingsAnchor, setSettingsAnchor] = React.useState<null | HTMLElement>(null);

  // @ts-ignore
  const [selectedEventTypes, setSelectedEventTypes] = useState<UserEventType[]>(eventKeys);

  const newEventDialog = useDialogState();
  const eventPreviewDialog = useDialogState();
  const updateEventDialog = useDialogState();

  const getEvents = useCallback(() => {
    if (!(calendarStartDate && calendarEndDate && selectedEventTypes)) return;

    const request: EventQueryPayload = {
      startDate: dateToInputFormat(calendarStartDate),
      endDate: dateToInputFormat(calendarEndDate),
      userEventTypes: selectedEventTypes,
    };

    getAll(request).then(setEvents);
  }, [calendarStartDate, calendarEndDate, selectedEventTypes]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  const handleDatesSet = useCallback(({ view: { currentStart, activeStart, activeEnd } }: DatesSetArg) => {
    // @ts-ignore
    setCalendarStartDate(activeStart);
    // @ts-ignore
    setCalendarEndDate(activeEnd);
    setCalendarMonth(currentStart);
  }, []);

  const goPrev = useCallback(() => {
    ref.current.getApi().prev();
  }, []);

  const goNext = useCallback(() => {
    ref.current.getApi().next();
  }, []);

  const reload = useCallback(() => {
    getEvents();
  }, [getEvents]);

  const generateDayName = useCallback(({ date }: DayHeaderContentArg) => formatDayName(date), [formatDayName]);
  const title = useMemo(() => formatTitle(calendarMonth), [formatTitle, calendarMonth]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState();
  const [event, setEvent] = useState();

  const context = useMemo<CalendarContextType>(
    () => ({
      title,
      startDate,
      endDate,
      goPrev,
      goNext,
      reload,
      events,
      event,
      eventId,
      newEventDialog,
      eventPreviewDialog,
      updateEventDialog,
    }),
    [title, startDate, endDate, goPrev, goNext, reload, events, event, eventId, newEventDialog, eventPreviewDialog, updateEventDialog]
  );
  const classes = useStyles();
  const { showMessage } = useContext(AlertContext);

  const handleDateSelect = (selection: any) => {
    selection.end.setDate(selection.end.getDate() - 1);

    setStartDate(selection.start);
    setEndDate(selection.end);
  };

  const handleEventClick = (data: any) => {
    setEventId(data.event.id);
    setEvent(JSON.parse(data.event.title));
    eventPreviewDialog.open();
  };

  const handleEventDrop = async data => {
    try {
      const _event = JSON.parse(data.event.title);
      const newDate: string = data.event.startStr;
      // kodu kod icin uzgunum. backendi optimize etmemiz gerekiyor
      const id = _event.userEventType === UserEventType.USER_DEFINED ? data.event.id : _event.referenceTableId;
      await reschedule(id, newDate, _event.userEventType);
      showMessage('Etkinlik tarihi güncellendi.', 'success', 'info');
      getEvents();
    } catch (e) {
      showMessage('Bir hata oluştu!', 'error', 'info');
      return false;
    }
  };

  const handleFilterChange = (value: any) => {
    if (selectedEventTypes.includes(value)) {
      let filtered = selectedEventTypes.filter(item => item !== value);
      if (filtered.length === 0) {
        filtered = [...eventKeys];
      }

      setSelectedEventTypes(filtered);
      return;
    }

    setSelectedEventTypes([...selectedEventTypes, value]);
  };

  function renderEventContent(eventInfo: any) {
    const payload = JSON.parse(eventInfo.event.title);
    const label = payload.employeeName ? payload.employeeName : payload.label;
    const tooltip = payload.description ? payload.description : payload.label;
    return (
      <Tooltip title={tooltip}>
        <div>{label}</div>
      </Tooltip>
    );
  }

  const handleClickSettings = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(_event.currentTarget);
  };

  return (
    <CalendarContext.Provider value={context}>
      <Paper elevation={3}>
        <Header
          title="Takvim"
          icon={
            <>
              <IconButton onClick={handleClickSettings}>
                <SettingsOutlined />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={settingsAnchor}
                open={Boolean(settingsAnchor)}
                onClose={() => setSettingsAnchor(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                elevation={0}>
                <MenuList>
                  {Object.keys(eventTypes).map(option => (
                    <MenuItem key={option} onClick={() => handleFilterChange(option)}>
                      <ListItemIcon>
                        <Checkbox
                          style={{
                            color: eventTypes[option].color,
                          }}
                          checked={selectedEventTypes.includes(option)}
                          value={option}
                        />
                      </ListItemIcon>
                      <ListItemText>{eventTypes[option].value}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </>
          }
          fabIcon={<AddIcon className="text-xx-large" />}
          fabOnClick={newEventDialog.open}>
          <div className={`text-white`}>
            <IconButton className={classes.actionIcon} onClick={goPrev}>
              <LeftIcon />
            </IconButton>
            {title}
            <IconButton className={classes.actionIcon} onClick={goNext}>
              <RightIcon />
            </IconButton>
          </div>
        </Header>
        <Box className="p-1">
          <FullCalendar
            ref={ref}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={false}
            datesSet={handleDatesSet}
            firstDay={1}
            dayHeaderContent={generateDayName}
            editable={true}
            selectable={true}
            select={handleDateSelect}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            fixedWeekCount={false}
            dayMaxEvents={1}
            height={402}
            events={
              events &&
              events.map(item => {
                return {
                  id: item.referenceTableId,
                  item,
                  title: JSON.stringify(item),
                  date: dateToInputFormat(new Date(item.date)),
                  editable: eventTypes[item.userEventType].editable,
                  backgroundColor: item.userEventType === UserEventType.USER_DEFINED ? item.color : eventTypes[item.userEventType].color,
                };
              })
            }
            eventContent={renderEventContent}
          />
        </Box>
      </Paper>
      <EventPreviewDialog />
      <UpdateEventDialog />
      <NewEventDialog />
    </CalendarContext.Provider>
  );
}
