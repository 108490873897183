import { Button, Drawer, Fab, List, ListItem, ListSubheader, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GetEmployeesPayload } from '../../../../../api/hs/employee-filter';
import { EmployeeListContext } from '../../employee-list.context';
import MedicalBackground from './MedicalBackground';
import PersonalInfo from './PersonalInfo';
import ProfessionalInfo from './ProfessionalInfo';

const useStyles = makeStyles(theme => ({
  fab: {
    transform: 'translateY(50%)',
    color: 'white',
  },
  subheader: {
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    height: '100%',
    width: 340,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& > *': {
      minHeight: 'max-content',
    },
    '& > div': {
      minHeight: 'initial',
      height: '100%',
      overflowY: 'auto',
    },
  },
}));

const BMI_MIN = 10;
const BMI_MAX = 50;

const EMPTY_ARRAY = Symbol('emptyArray');

const EMPTY_FILTERS_FILTER: { [P in keyof GetEmployeesPayload['filter']]: (GetEmployeesPayload['filter'][P] | symbol | '')[] } = {
  ageMax: [null, ''],
  ageMin: [null, ''],
  alcoholAddictionStateIds: [null, EMPTY_ARRAY],
  allergyDefinitionIds: [null, EMPTY_ARRAY],
  archived: [null, ''],
  bloodDonation: [null, ''],
  bloodPressureClassificationIds: [null, EMPTY_ARRAY],
  bloodTypeIds: [null, EMPTY_ARRAY],
  bmiMax: [null, ''],
  bmiMin: [null, ''],
  chronicDiseaseIcd10s: [null, EMPTY_ARRAY],
  companyIds: [null, EMPTY_ARRAY],
  departmentDefinitionIds: [null, EMPTY_ARRAY],
  disabilityDefinitionIds: [null, EMPTY_ARRAY],
  disabled: [null, ''],
  educationalStatusIds: [null, EMPTY_ARRAY],
  exposureDefinitionIds: [null, EMPTY_ARRAY],
  gender: [null, ''],
  hasAccident: [null, ''],
  hasAllergy: [null, ''],
  hasChronicDisease: [null, ''],
  hasDisease: [null, ''],
  hasRegularMedicationUsage: [null, ''],
  hasSurgeryHistory: [null, ''],
  locationDefinitionIds: [null, EMPTY_ARRAY],
  managerId: [null, ''],
  maritalStatusIds: [null, EMPTY_ARRAY],
  obesityClassificationIds: [null, EMPTY_ARRAY],
  positionDefinitionIds: [null, EMPTY_ARRAY],
  pregnancyStateIds: [null, EMPTY_ARRAY],
  searchKeyword: [null, ''],
  smokeAddictionStateIds: [null, EMPTY_ARRAY],
  stationDefinitionIds: [null, EMPTY_ARRAY],
  workingTypeIds: [null, EMPTY_ARRAY],
};

export default function Filters() {
  const classes = useStyles();
  const [drawerShown, setDrawerShown] = useState(false);
  const { setValue, trigger, handleSubmit, reset, formState, getValues, ...formMethods } = useForm();
  const { filters, updateFilters } = useContext(EmployeeListContext);
  const [formResetted, setFormResetted] = useState(false);

  const customReset = useCallback(() => {
    const values = getValues();

    reset({
      ...Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
      bloodTypeIds: [],
      maritalStatusIds: [],
      educationalStatusIds: [],
      workingTypeIds: [],
      companyIds: [],
      departmentDefinitionIds: [],
      locationDefinitionIds: [],
      stationDefinitionIds: [],
      positionDefinitionIds: [],
      exposureDefinitionIds: [],
      disabilityDefinitionIds: [],
      pregnancyStateIds: [],
      allergyDefinitionIds: [],
      chronicDiseaseIcd10s: [],
      smokeAddictionStateIds: [],
      alcoholAddictionStateIds: [],
      bmi: [BMI_MIN, BMI_MAX],
    });
  }, [reset, getValues]);

  const openDrawer = useCallback(async () => {
    setDrawerShown(true);
    customReset();

    await defer();

    const { bmiMin, bmiMax, ...values } = filters.filter;

    for (const [key, value] of Object.entries(values).filter(([k]) => Object.keys(getValues()).includes(k))) {
      setValue(key, value instanceof Array ? [...value] : value);
      trigger(key);
    }

    setValue('bmi', [bmiMin || BMI_MIN, bmiMax || BMI_MAX]);
    trigger('bmi');
  }, [customReset, filters, setValue, trigger, getValues]);

  const closeDrawer = useCallback(() => {
    setDrawerShown(false);
  }, []);

  const changeFilters = useCallback(
    ({ bmi: [min, max], ...values }) => {
      updateFilters({
        filter: {
          ...values,
          bmiMin: min === BMI_MIN ? null : min,
          bmiMax: max === BMI_MAX ? null : max,
        },
      });
      closeDrawer();
    },
    [updateFilters, closeDrawer]
  );

  const resetForm = useCallback(async () => {
    customReset();
    setFormResetted(true);
  }, [customReset]);

  const hasFilter = useMemo(
    () =>
      filters?.filter
        ? Object.entries(filters.filter)?.some(([key, value]) =>
            value instanceof Array && value.length === 0
              ? !EMPTY_FILTERS_FILTER[key]?.includes(EMPTY_ARRAY)
              : !EMPTY_FILTERS_FILTER[key]?.includes(value)
          )
        : false,
    [filters]
  );

  return (
    <>
      <Fab
        size="small"
        style={{ backgroundColor: hasFilter ? '#eb4355' : '#2196f3' }}
        className={`${classes.fab} ml-1`}
        onClick={openDrawer}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={drawerShown} onClose={closeDrawer}>
        <FormProvider {...{ ...formMethods, setValue, trigger, handleSubmit, reset, formState, getValues }}>
          <form className={classes.form} onSubmit={handleSubmit(changeFilters)}>
            <List
              className={classes.list}
              component="aside"
              subheader={<ListSubheader className={`${classes.subheader} text-white h5 py-2`}>Filtre</ListSubheader>}>
              <div>
                <PersonalInfo />
                <ProfessionalInfo />
                <MedicalBackground />
              </div>
              <ListItem>
                <Button type="button" fullWidth variant="contained" className="mr-1" onClick={resetForm}>
                  Temizle
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={formResetted ? false : !Object.values(formState.touched).some(isTruthy)}
                  fullWidth
                  variant="contained"
                  className="ml-1">
                  Uygula
                </Button>
              </ListItem>
            </List>
          </form>
        </FormProvider>
      </Drawer>
    </>
  );
}
