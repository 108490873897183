import { tryCatch } from '@thalesrc/js-utils';

import { DateRange } from '../dashboard/widget/widget.payload';
import HSApi from '../HSApi';
import { excelExport, handleResult } from '../utils';

export async function getNearMissCounts(dateRange?: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/near-misses/widgets/count${dateRange?.toString()}`));

  return handleResult(res, error);
}

export async function getNearMissView(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/near-misses/widgets/views${dateRange.toString()}`));

  return handleResult(res, error);
}

export async function exportNearMissWidgetExport(dateRange: DateRange, fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/near-misses/widgets/views/export${dateRange.toString()}`, {
      responseType: 'blob',
    })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}
