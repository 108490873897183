import React, { useCallback, useContext } from 'react';

import PpeReturnForm from './Form';
import { PpeOutputsGroupContext } from './ppe-outputs-group.context';

export default function EditDialog() {
  const { setNewDialogOpened, newDialogOpened, selectedData, reload } = useContext(PpeOutputsGroupContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setNewDialogOpened(false);
    },
    [reload, setNewDialogOpened]
  );

  return <PpeReturnForm open={newDialogOpened} onClose={closeDialog} data={selectedData} />;
}
