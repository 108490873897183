import React from 'react';

import AccidentSeverityTypeDefinition from './AccidentSeverityTypeDefinition';
import AccidentTypeDefinition from './AccidentTypeDefinition';
import InjuryTypeDefinition from './InjuryTypeDefinition';

interface AccidentDefinitionListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'KAZA TÜRLERİ',
    Component: AccidentTypeDefinition,
    path: '',
  },
  {
    label: 'YARALANMA TÜRLERİ',
    Component: InjuryTypeDefinition,
    path: 'injury-types',
  },
  {
    label: 'KAZANIN AĞIRLIK DERECELERİ',
    Component: AccidentSeverityTypeDefinition,
    path: 'accident-severity-types',
  },
] as AccidentDefinitionListItem[];
