/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IbysTrainingDefinitionEmployeeStatusDto } from '../models/IbysTrainingDefinitionEmployeeStatusDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IbysEmployeeTrainingDefinitionStatusControllerService {

    /**
     * @param requestBody
     * @returns IbysTrainingDefinitionEmployeeStatusDto OK
     * @throws ApiError
     */
    public static saveIbysTrainingEmployeeDefinitionStatus(
        requestBody: IbysTrainingDefinitionEmployeeStatusDto,
    ): CancelablePromise<IbysTrainingDefinitionEmployeeStatusDto> {
        return __request({
            method: 'POST',
            path: `/trainings/ibys-trainings/employee-definition-status`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns IbysTrainingDefinitionEmployeeStatusDto OK
     * @throws ApiError
     */
    public static saveIbysTrainingEmployeeDefinitionStatuses(
        requestBody: Array<IbysTrainingDefinitionEmployeeStatusDto>,
    ): CancelablePromise<Array<IbysTrainingDefinitionEmployeeStatusDto>> {
        return __request({
            method: 'POST',
            path: `/trainings/ibys-trainings/employee-definition-status/list`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}