import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { forwardRef, PropsWithChildren, Ref, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';

import Button from 'Common/Button';
import IndicatorWrapper, { IndicationWrapperProps } from 'Common/IndicatorWrapper';
import { useAsyncEffect, useDialogState, useUniqueId } from 'utils';

type OnOpenDialogMethods<T = any> = Pick<UseFormMethods<Record<string, any>>, 'setValue' | 'reset' | 'trigger'> & {
  opened: boolean;
  data: T;
};

type FilteredDialogProps = Omit<DialogProps, 'open' | 'onClose'>;

export interface PanelWithDialogProps<T extends {} = {}, U = T> {
  dialogTitle: string;
  form: JSX.Element | ((values: { [key: string]: any }, setValue: (name: string, value: any) => any) => JSX.Element);
  cancelButtonText?: string;
  submitButtonText?: string;
  indicationWrapperProps: IndicationWrapperProps;
  onDialogOpen?(methods: OnOpenDialogMethods<U>): Promise<unknown>;
  dialogProps?: FilteredDialogProps;
}

export interface PanelWithDialogRef<T = any, F = T> {
  openDialog(data: T): Promise<{ submitted: boolean; form: F }>;
}

const DEFAULT_DIALOG_PROPS: FilteredDialogProps = {
  fullWidth: true,
  maxWidth: 'sm',
};

async function defaultOnDialogOpen({ opened, data, setValue, reset, trigger }: OnOpenDialogMethods) {
  if (!opened) {
    return;
  }

  if (!data) {
    reset();
    return;
  }

  await defer();

  for (const [key, value] of Object.entries(data)) {
    setValue(key, value);
  }

  trigger();
}

function PanelWithDialog<T = any>(
  {
    dialogTitle,
    form,
    cancelButtonText = 'İptal',
    submitButtonText = 'Kaydet',
    children,
    indicationWrapperProps,
    onDialogOpen = defaultOnDialogOpen,
    dialogProps = {},
  }: PropsWithChildren<PanelWithDialogProps<T>>,
  ref: Ref<PanelWithDialogRef>
) {
  const formId = useUniqueId('allergy-form');
  const { open, close, opened } = useDialogState();
  const { setValue, reset, trigger, getValues, errors, ...methods } = useForm();
  const dialogActions = useRef<{ close(): void; submit(form: T): void }>({ close, submit: close });
  const [formData, setFormData] = useState<T>(null);

  const mergedDialogProps = useMemo<DialogProps>(
    () => ({ ...DEFAULT_DIALOG_PROPS, ...dialogProps, open: opened, onClose: () => dialogActions.current.close() }),
    [dialogProps, opened]
  );

  useImperativeHandle(
    ref,
    () => ({
      openDialog: (data: T) =>
        new Promise(resolve => {
          setFormData(data);
          open();

          dialogActions.current.close = () => {
            close();
            resolve({ submitted: false, form: null });
          };

          dialogActions.current.submit = formValue => {
            close();
            resolve({ submitted: true, form: formValue });
          };
        }),
    }),
    [open, close]
  );

  useAsyncEffect(async () => {
    await onDialogOpen({ setValue, reset, trigger, opened, data: formData });
  }, [setValue, reset, trigger, opened, onDialogOpen, formData]);

  return (
    <>
      <Dialog {...mergedDialogProps}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <FormProvider {...{ setValue, reset, trigger, getValues, errors, ...methods }}>
            <form id={formId} onSubmit={(...args) => methods.handleSubmit(dialogActions.current.submit as any)(...args)}>
              {typeof form === 'function' ? form(getValues(), setValue) : form}
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className="text-right pr-3 pb-2">
          <Button onClick={() => dialogActions.current.close()}>{cancelButtonText}</Button>
          <Button color="primary" variant="contained" form={formId} type="submit" disabled={!!Object.keys(errors).length}>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
      <IndicatorWrapper {...indicationWrapperProps}>{children}</IndicatorWrapper>
    </>
  );
}

export default forwardRef<PanelWithDialogRef, PropsWithChildren<PanelWithDialogProps>>(PanelWithDialog);
