import React from 'react';
import { Route, Switch } from 'react-router';

import WorkEquipmentDefinition from './WorkEquipmentDefinition';
import WorkEquipmentJobStatus from './WorkEquipmentJobStatus';
import WorkEquipmentLayout from './WorkEquipmentLayout';
import WorkEquipmentPeriodicControl from './WorkEquipmentPeriodicControl';
export default function EmployeeList() {
  return (
    <Switch>
      <Route exact path="/work-equipment/definition">
        <WorkEquipmentDefinition />
      </Route>
      <Route
        exact
        path="/work-equipment/definition/:workEquipmentDefinitionId"
        render={({ location }) => <WorkEquipmentPeriodicControl WorkEquipmentDefinitionData={location.state.rowData} />}
      />
      <Route exact path="/work-equipment/layout">
        <WorkEquipmentLayout />
      </Route>
      <Route exact path="/work-equipment/jobStatus">
        <WorkEquipmentJobStatus />
      </Route>
    </Switch>
  );
}
