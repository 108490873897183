import { TableRowProps, TableSortLabelProps } from '@material-ui/core';
import { createContext } from 'react';

import { TableColumn } from './table-column.model';

interface SortingData<T> {
  id: keyof T | symbol;
  direction: TableSortLabelProps['direction'];
}

export interface TableContextType<T extends {}> {
  columns: TableColumn<T>[];
  visibleColumns: TableColumn<T>[];
  sort: SortingData<T> | false;
  setSort(state: TableContextType<T>['sort']): void;
  columnVisibilities: { [P in keyof T]: boolean };
  setColumnVisibility(id: keyof T | symbol, visible: boolean): void;
  data: T[];
  setData(data: T[]): void;
  onRowClick(row: T, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>): any;
  RowProps: TableRowProps;
}

export const TableContext = createContext<TableContextType<any>>(null);
