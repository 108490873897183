/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProtocolNumber } from './ProtocolNumber';

export type ExaminationDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    examinationDefinitionId?: number;
    employeeId?: string;
    protocolNumber?: ProtocolNumber;
    date?: string;
    opinion?: ExaminationDto.opinion;
}

export namespace ExaminationDto {

    export enum opinion {
        COULD_WORK = 'COULD_WORK',
        CONDITIONAL_WORK = 'CONDITIONAL_WORK',
        CHANGE_WORK = 'CHANGE_WORK',
        COULD_NOT_WORK = 'COULD_NOT_WORK',
    }


}
