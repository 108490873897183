import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { ReferralsModel } from 'model/employee/examination/referrals.model';
import { useAsyncEffect } from 'utils';

import { saveReferral, updateReferral } from '../../../api/hs/examination/referrals';
import DateInput from '../../Form/DateInput';
import Select from '../../Form/Select';
import TextField from '../../Form/TextField';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: ReferralsModel;
}

export default function ReferralForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { referralSection, hospitalTypes } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveReferral({ ...values, employeeId, examinationId });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateReferral({ id: data.id, ...values, employeeId, examinationId });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, employeeId, examinationId]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    if (data) {
      await defer();
      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }

      trigger();
    } else {
      reset({
        referralDate: null,
        hospitalTypeDefinitionId: '' as any,
        referralSectionDefinitionId: '' as any,
        noteToPhysician: '' as any,
        description: '' as any,
      });
    }
  }, [data, setValue, trigger, open, reset]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Sevk Düzenleme' : 'Sevk Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput required type="date" name="referralDate" label="Sevk Tarihi" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Select name="hospitalTypeDefinitionId" options={hospitalTypes} label="Sevk Edilen Hastahane" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <Select required name="referralSectionDefinitionId" options={referralSection} label="Sevk Edilen Bölüm" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} type="text" name="noteToPhysician" label="Hekim için Not" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} type="text" name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
