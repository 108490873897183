/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MedicineDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    label?: string;
    barcode?: string;
    producerCompany?: string;
    atcCode?: string;
    atcName?: string;
    ssiRefundable?: boolean;
    prescriptionType?: number;
    status?: MedicineDefinitionDto.status;
}

export namespace MedicineDefinitionDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
