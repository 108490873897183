import { tryCatch } from '@thalesrc/js-utils';

import { WorkEquipmentJobStatusFilter } from '../../../client';
import HSApi from '../../HSApi';

export async function getWorkEquipmentJobStatus(page = 0, size = 20, order: null | string, filters: any) {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof WorkEquipmentJobStatusFilter)[] = ['executionDateMin', 'executionDateMax', 'description'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }
  if (parsedFilters.executionDateMin) {
    parsedFilters.executionDateMin = new Date(parsedFilters.executionDateMin).toISOString();
  }

  if (parsedFilters.executionDateMax) {
    parsedFilters.executionDateMax = new Date(parsedFilters.executionDateMax).toISOString();
  }
  parsedFilters.onlyLatests = true;
  const payload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/work-equipment-job-statuses/views`, payload);

  return res.data;
}

export async function exportWorkEquipmentJobStatus(filters: any) {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof WorkEquipmentJobStatusFilter)[] = ['executionDateMin', 'executionDateMax', 'description'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }
  if (parsedFilters.executionDateMin) {
    parsedFilters.executionDateMin = new Date(parsedFilters.executionDateMin).toISOString();
  }

  if (parsedFilters.executionDateMax) {
    parsedFilters.executionDateMax = new Date(parsedFilters.executionDateMax).toISOString();
  }

  const payload = {
    filter: parsedFilters,
  };

  const res = await HSApi.post(`/work-equipment-job-statuses/view/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'iş-ekipman-kontrol-listesi.xlsx';
  a.click();
}

export async function saveJobStatusFileImported(id: string, file: File) {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/work-equipment-job-statuses/${id}/imported-files`, data));
  if (err) {
    throw err.response.data.message;
  }

  return res.data;
}
