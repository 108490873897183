import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getPpeDetailStocks, PpeDetailStocksResult } from 'api/hs/ppe/ppe-detail-stocks';
import { useFetch } from 'utils';

import PpeDetail from './ppe-detail';
import { PpeDetailStocksContext } from './ppe-detail-stocks.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PpeDetailStocksResult>(null);
  const { ppeDetailId } = useParams<{ ppeDetailId: string }>();

  const { data, fetch } = useFetch(() => getPpeDetailStocks(ppeDetailId), [], { setLoading });

  return (
    <PpeDetailStocksContext.Provider
      value={{
        PpeDetailStocks: data,
        reload: fetch,
        loading,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <PpeDetail />
    </PpeDetailStocksContext.Provider>
  );
}
