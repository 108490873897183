import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { dateSort, MakeRequired } from 'utils';

import { MedicalInterventionPayload } from './medical-intervention.payload';
import { MedicalInterventionResult } from './medical-intervention.result';

export async function getMedicalIntervention(examinationId: string): Promise<MedicalInterventionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<MedicalInterventionResult[]>(`/medical-interventions?examinationId=${examinationId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return dateSort(res.data);
}

export async function saveMedicalIntervention(body: MedicalInterventionPayload): Promise<MedicalInterventionResult> {
  const [err, res] = await tryCatch(HSApi.post('/medical-interventions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateMedicalIntervention({
  id,
  ...body
}: MakeRequired<Partial<MedicalInterventionResult>, 'id'>): Promise<MedicalInterventionResult> {
  const [error, res] = await tryCatch(HSApi.put<MedicalInterventionResult>(`/medical-interventions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteMedicalIntervention(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/medical-interventions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
