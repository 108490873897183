import { Accordion, AccordionDetails, AccordionSummary, Icon, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { PropsWithChildren, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
  },
}));

interface Props {
  icon: string;
  title: string;
  expandedByDefault?: boolean;
}

export default function BaseSection({ children, icon, title, expandedByDefault = false }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expandedByDefault);
  const [firstExpand, setFirstExpand] = useState(false);

  useEffect(() => {
    if (firstExpand || !expanded) {
      return;
    }

    setFirstExpand(true);
  }, [firstExpand, expanded]);

  return (
    <Accordion expanded={expanded} onChange={(_, value) => setExpanded(value)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Icon style={{ color: 'gray' }} className={'mr-1 icon ' + icon} />
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className="pt-0">{firstExpand ? <div className={classes.content}>{children}</div> : null}</AccordionDetails>
    </Accordion>
  );
}
