import React, { createContext } from 'react';

import ActionComp from './BaseDefinition/List/Action';

export interface ListItemTemplateProps<T> {
  item: T;
  Action: typeof ActionComp;
}

export interface DefinitionContextFormType<T> {
  onClose(success: boolean): any;

  open: boolean;
  data: T;
}

export interface DefinitionContextType<T> {
  data: T[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  listLabelPropName: keyof T;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  selectedData: T;

  setSelectedData(data: T): void;

  onDelete(id: string | number): void;

  Icon: JSX.Element;

  FormDialog: React.ComponentType<DefinitionContextFormType<T>>;

  ListItemTemplate: React.ComponentType<ListItemTemplateProps<T>>;

  searchName?: string;

  noSave?: boolean;

  noUpdate?: boolean;

  noDelete?: boolean;
}

export const DefinitionListContext = createContext<DefinitionContextType<any>>(null);
