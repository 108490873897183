import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { ResourceContext } from '../context/resource.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props<T extends { id: string | number }> {
  open: boolean;
  children: JSX.Element | JSX.Element[];

  onClose(success: boolean): void;

  onSave?(data: Omit<T, 'id'>): Promise<any>;

  onUpdate?(updateData: T): Promise<any>;

  data: T;

  createTitle?: string;

  updateTitle: string;

  maxDialogWidth?: number;

  resourceReloadName?: string;

  searchName?: string;
}

export default function BaseDefinitionForm<T extends { id: string | number }>({
  open,
  onClose,
  data,
  children,
  onSave,
  onUpdate,
  createTitle,
  updateTitle,
  resourceReloadName,
  maxDialogWidth = 600,
}: Props<T>) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { reload } = useContext(ResourceContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await onSave(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await onUpdate({ id: data.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        await reload(resourceReloadName);
        reset();
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, reset, showMessage, onSave, onUpdate, reload, resourceReloadName]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }

      trigger();
    }
  }, [data, setValue, trigger]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: maxDialogWidth } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? updateTitle : createTitle}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              {children}
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
