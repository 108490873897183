import { createStyles, makeStyles } from '@material-ui/core';
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { LicenceContext } from "../context/licence";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

interface Props {
  className?: string;
    onlyArti?: boolean;
}

export default function Logo({ className = '', onlyArti = false }: Props) {
  const styles = useStyles();
  const { licence } = useContext(LicenceContext);

  return (
    <Link to={!onlyArti?"/":{pathname: "https://artimetrik.com.tr/"}} target={!onlyArti?'_self':'_blank'} className={className + ' ' + styles.root}>
      <img width="50px" height="50px" src={!onlyArti && licence != undefined && licence.logo != undefined && licence.logo && licence.logo !== null?licence.logo:'/images/logo.png'} alt={!onlyArti && licence != undefined && licence.name != undefined && licence.name && licence.name !== null?licence.name:'ARTI METRİK'} />
      <span style={{ fontSize: '18px', marginLeft: '5px' }}>{!onlyArti && licence.name && licence.name !== null?licence.name:'ARTI METRİK'}</span>
    </Link>
  );
}
