import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { getExaminationList } from 'api/hs/TransactionAndLists/ExaminationList';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { CELL } from './COLUMS';
import ListTable from './ListTable';
import Toolbar from './Toolbar';

export default function List() {
  const history = useHistory();

  const fetchExaminationList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getExaminationList(
      page,
      size,
      sorting === false ? 'date-DESC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider
      fetcher={fetchExaminationList}
      RowProps={{ className: 'cursor-pointer' }}
      onRowClick={row => history.push(`/transactions/employee/${row.employeeId}/examination/${row.id}`)}
      columns={CELL}>
      <Toolbar />
      <ListTable />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
