import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useUniqueId } from 'utils';

import { TreeBoxControl } from './control.model';

interface ControlProps {
  control: TreeBoxControl;
}

export default function Control({ control: { name, label } }: ControlProps) {
  const id = useUniqueId('tree-box-control-id');
  const { getValues, setValue, trigger } = useFormContext();

  const value = getValues(name);

  const handleChange = useCallback(() => {
    setValue(name, !value);
    trigger();
  }, [value, setValue, name, trigger]);

  return <TreeItem nodeId={id} label={<FormControlLabel control={<Checkbox checked={value} onChange={handleChange} />} label={label} />} />;
}
