/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { RiskAssessmentHazardDto } from '../models/RiskAssessmentHazardDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RiskAssessmentHazardControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentHazardDto OK
     * @throws ApiError
     */
    public static updateRiskAssessmentHazard(
        id: string,
        requestBody: RiskAssessmentHazardDto,
    ): CancelablePromise<RiskAssessmentHazardDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/hazards/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRiskAssessmentHazard(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/hazards/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentHazardDto OK
     * @throws ApiError
     */
    public static updatePartialRiskAssessmentHazard(
        id: string,
        requestBody: RiskAssessmentHazardDto,
    ): CancelablePromise<RiskAssessmentHazardDto> {
        return __request({
            method: 'PATCH',
            path: `/risk-assessments/hazards/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns RiskAssessmentHazardDto OK
     * @throws ApiError
     */
    public static getAllRiskAssessmentHazard(
        id: string,
    ): CancelablePromise<Array<RiskAssessmentHazardDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/${id}/hazards`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentHazardDto OK
     * @throws ApiError
     */
    public static saveRiskAssessmentHazard(
        id: string,
        requestBody: RiskAssessmentHazardDto,
    ): CancelablePromise<RiskAssessmentHazardDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/${id}/hazards`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getAllRiskAssessmentHazardFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/hazards/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static riskAssessmentHazardImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/hazards/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static uploadRiskAssessmentHazardFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/hazards/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static riskAssessmentHazardFileDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/hazards/imported-files/${id}`,
        });
    }

}