import { createContext } from 'react';

import { CommitteeMembersResult } from 'api/hs/definitions/committee-members';

export interface CommitteeMembersContextType {
  committeeMembers: CommitteeMembersResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: CommitteeMembersResult;

  setSelectedData(data: CommitteeMembersResult): void;
}

export const CommitteeMembersContext = createContext<CommitteeMembersContextType>(null);
