import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { ShiftDefinitionControllerService, ShiftDefinitionDto } from '../../../../../api/client';
import { updateShiftDefinitions } from '../../../../../api/hs/shift-definitions/shift-definition';
import DateInput from '../../../../Form/DateInput';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: ShiftDefinitionDto;
}

export default function ShiftDefinitionEditDialog({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<ShiftDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={ShiftDefinitionControllerService.saveFirmShiftDefinition}
      onUpdate={updateShiftDefinitions}
      data={data}
      resourceReloadName="shiftDefinitions"
      createTitle="Vardiya Tanımı Ekleme"
      updateTitle="Vardiya Tanımı Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Vardiya Tanımı Giriniz" />
      </Grid>
      <Grid item lg={6} md={6}>
        <DateInput type={'time'} required name="startAt" label="Başlama Saati Giriniz" />
      </Grid>
      <Grid item lg={6} md={6}>
        <DateInput type={'time'} required name="endAt" label="Bitiş Saati Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
