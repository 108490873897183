import { Button, Grid } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { AccidentEmployeeControllerService, AccidentEmployeeDto } from '../../api/client';
import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../api/hs/employee';
import AutoComplete from '../../Common/Form/AutoComplete';
import { SelectOption } from '../../Common/Form/Select';
import TextField from '../../Common/Form/TextField';
import { SelectOptionsContext } from '../../context/select-options.context';
import HumanBody from '../AccidentRecord/HumanBody';
function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}
async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

interface Props {
  open: boolean;

  onClose(success: boolean): void;
  fetch?(): void;
  data: AccidentEmployeeDto;
  accidentId: string;
}

export default function AccidentEmployeesForm({ open, onClose, data, accidentId, fetch }: Props) {
  const { employeeBodyRegions, employeeInjuryTypes, accidentSeverityTypes } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const [codeIdPairs, setCodeIdPairs] = useState<Map<string, string>>();

  useEffect(() => {
    if (employeeBodyRegions) {
      const map = new Map();
      employeeBodyRegions.forEach(item => map.set(item.value, item.id));
      setCodeIdPairs(map);
    }
  }, [employeeBodyRegions]);

  const submit = useCallback(
    async values => {
      const payload = {
        accidentId,
        employeeId: values?.employeeId,
        accidentSeverityDefinitionId: values.accidentSeverityDefinitionId,
        employeeInjuryTypes: values?.employeeInjuryTypes.map((station: string) => {
          return {
            injuryTypeDefinitionId: station,
          };
        }),
        employeeBodyRegions: values?.employeeBodyRegions.map((code: string) => {
          return {
            bodyRegionDefinitionId: codeIdPairs.get(code),
          };
        }),

        workDescription: values?.workDescription,
        description: values?.description,
        lostWorkDays: values?.lostWorkDays,
      };
      try {
        if (!data?.id) {
          await AccidentEmployeeControllerService.saveAccidentEmployee(payload.accidentId, { ...payload });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
          // await fetch()
        } else {
          await AccidentEmployeeControllerService.updateAccidentEmployee(data.id, { ...payload });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
          //    await fetch()
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, data, accidentId, onClose, codeIdPairs]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    formRef?.current?.methods?.setValue('employeeId', data?.employeeId);
    formRef?.current?.methods?.setValue('workDescription', data?.workDescription);
    formRef?.current?.methods?.setValue('accidentSeverityDefinitionId', data?.accidentSeverityDefinitionId);
    formRef?.current?.methods?.setValue(
      'employeeInjuryTypes',
      data?.employeeInjuryTypes?.map(employeeInjuryType => employeeInjuryType.injuryTypeDefinitionId)
    );
    formRef?.current?.methods?.setValue(
      'employeeBodyRegions',
      data?.employeeBodyRegions?.map(
        employeeBodyRegion => findByKey(employeeBodyRegions, 'id', employeeBodyRegion.bodyRegionDefinitionId)?.value
      )
    );
    formRef?.current?.methods?.setValue('description', data?.description);
    formRef?.current?.methods?.setValue('lostWorkDays', data?.lostWorkDays);

    formRef?.current?.methods?.trigger();
  }, [data]);

  const onSelectBodyPart = (part: string) => {
    if (formRef?.current?.value?.employeeBodyRegions?.includes(part)) {
      formRef?.current?.methods?.setValue(
        'employeeBodyRegions',
        formRef?.current?.value?.employeeBodyRegions.filter((p: string) => p !== part)
      );
      formRef?.current?.methods?.trigger();
      return;
    }

    formRef?.current?.methods?.setValue('employeeBodyRegions', [...formRef?.current?.value?.employeeBodyRegions, part]);
    formRef?.current?.methods?.trigger();
  };

  return (
    <Dialog width={'md'} color="secondary" opened={open} onClose={onClose} title={'Kaza Geçiren Personel Ekle'}>
      <Form onSubmit={submit} onChange={reRender} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item md={6} sm={12}>
            <AutoComplete
              name="employeeId"
              single
              label="Kaza Geçiren Personel"
              optionFetcher={employeeOptionFetcher}
              labelFetcher={employeeLabelFetcher}
            />{' '}
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField name="workDescription" label="Kaza Sırasında Yaptığı İş" />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete
              options={accidentSeverityTypes}
              single
              name="accidentSeverityDefinitionId"
              label="Kazanın Ağırlık Derecesi"
              renderOption={'checkbox'}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete options={employeeInjuryTypes} name="employeeInjuryTypes" label="Yaralanma Türü" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={6} sm={12}>
            <AutoComplete
              options={employeeBodyRegions}
              name="employeeBodyRegions"
              label="Etkilenen Vücut Bölgesi"
              renderOption={'checkbox'}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField name="lostWorkDays" label="İş günü kaybı" type={'number'} />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField name="description" label="Yaralanan Bölge Ek Bilgi" />
          </Grid>
          <Grid item md={12} sm={12}>
            <HumanBody selectedParts={formRef?.current?.value?.employeeBodyRegions} onSelect={onSelectBodyPart} />
          </Grid>

          <Grid item md={6} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
