import { IconButton } from '@material-ui/core';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import SchoolIcon from '@material-ui/icons/School';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { TrainingEmployeeViewContentResult } from 'api/hs/trainings/TrainingEmployeeFilter';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import Avatar from '../../../TransactionsAndLists/EmployeeList/Avatar';
import { EmployeeTrainingDetailContext } from '../training-employee.context';

function ActionComponent({ row }: { row: TrainingEmployeeViewContentResult }) {
  const { setShowDialogEmployeeTraining, setSelectedData } = useContext(EmployeeTrainingDetailContext);
  const history = useHistory();

  const showDialog = useCallback(
    item => {
      setShowDialogEmployeeTraining(true);
      setSelectedData(item);
    },
    [setSelectedData, setShowDialogEmployeeTraining]
  );
  return (
    <div style={{ display: 'flex' }} key={row?.id}>
      <IconButton onClick={() => showDialog(row)}>
        <SchoolIcon />
      </IconButton>
      <IconButton onClick={() => history.push(`/transactions/employee/${row?.id}/education`)}>
        <AssignmentReturnIcon />
      </IconButton>
    </div>
  );
}

export const CELL: TableColumn<TrainingEmployeeViewContentResult>[] = [
  {
    id: 'avatarPath',
    render(row): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: '',
    sortable: false,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Adı Soyadı',
    sortable: true,
    width: '15%',
  },
  {
    id: 'nid',
    defaultVisibility: true,
    label: 'T.C.',
    sortable: false,
    width: '10%',
  },
  {
    id: 'departmentName',
    defaultVisibility: true,
    label: 'Departman',
    sortable: false,
    width: '15%',
  },
  {
    id: 'positionName',
    defaultVisibility: true,
    label: 'Görevi',
    sortable: true,
    width: '10%',
  },
  {
    id: 'stationName',
    defaultVisibility: true,
    label: 'İş İstasyonu',
    sortable: false,
    width: '10%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: false,
    width: '15%',
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '5%',
    render(row: TrainingEmployeeViewContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
