import React, { useCallback, useContext } from 'react';

import EmailDialogForm from 'Common/Forms/EmailForm';
import { NotificationTypeEnum } from 'model/notification-type.enum';

import { ReferralsContextContext } from '../../referrals.context';

export default function EmailDialog() {
  const { setEmailDialogOpened, reload, newEmailDialog, selectedData } = useContext(ReferralsContextContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEmailDialogOpened(false);
    },
    [reload, setEmailDialogOpened]
  );

  return (
    <EmailDialogForm
      open={newEmailDialog}
      onClose={closeDialog}
      notificationType={NotificationTypeEnum.REFERRAL}
      notificationReferenceId={selectedData?.id}
    />
  );
}
