/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PositionIbysWorkTypeDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    positionId?: string;
    workType?: PositionIbysWorkTypeDto.workType;
    code?: number;
}

export namespace PositionIbysWorkTypeDto {

    export enum workType {
        WORKING_POSITION = 'WORKING_POSITION',
        WORKING_TIME = 'WORKING_TIME',
        VEHICLE_OPERATION = 'VEHICLE_OPERATION',
    }


}
