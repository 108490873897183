import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { EmergencyContactContext } from '../../emergency-contacts.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ListEmergencyContacts() {
  const { loading, emergencyContacts } = useContext(EmergencyContactContext);

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={emergencyContacts} errorMessage="Acil Durumda Aranacak Kayıt Bulunamadı.">
        <List key="hs-defaultNurseOperationDetail">
          {emergencyContacts?.map((item, index) => (
            <Fragment key={index}>
              <ListItem key={index}>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-phone" />
                </ListItemAvatar>
                <ListItemText primary={item?.phoneNumber} secondary={item?.name + ' ( ' + item?.relation + ' )'} />
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
