import { Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { ClassificationDefinitionsContext } from '../../classification-definitions.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ReferralsList() {
  const { loading, classificationDefinitions } = useContext(ClassificationDefinitionsContext);

  return (
    <IndicatorWrapper loading={loading} data={classificationDefinitions} errorMessage="Kayıt Bulunamadı.">
      <List id="classificationDefinitions">
        {classificationDefinitions?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <AddShoppingCartIcon className="text-gray" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText primary={item?.label} />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
