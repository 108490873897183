import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { deleteCommitteeMembers, deleteFileImportedFile, saveFileImportedFile } from '../../../../api/hs/definitions/committee-members';
import Button from '../../../../Common/Button';
import { EmployeeSelectionCacheContext } from '../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { DialogContext } from '../../../../context/dialog.context';
import { EnumContext } from '../../../../context/enum.context';
import { ResourceContext } from '../../../../context/resource.context';
import Avatar from '../../../../TransactionsAndLists/EmployeeList/Avatar';
import { downlaodFile, openFile, useAsyncEffect, useXhr } from '../../../../utils';
import { CommitteeMembersContext } from '../../committee-members.context';
import EditDialog from './EditDialog';

export default function ListFirmSubscriptions() {
  const { loading, committeeMembers, setEditDialogOpened, setSelectedData, reload } = useContext(CommitteeMembersContext);
  const { committeeMembersPositionDefinitions } = useContext(EnumContext);
  const { companies } = useContext(ResourceContext);
  const { names, loadEmployees, avatars, employees } = useContext(EmployeeSelectionCacheContext);
  const { openFileDialog, openWarnDialog } = useContext(DialogContext);

  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  useAsyncEffect(async () => {
    if (!committeeMembers) {
      return;
    }
    await defer();
    const employeeIds = committeeMembers?.reduce((acc, item) => [...acc, item.employeeId], []);
    loadEmployees(...employeeIds);
  }, [committeeMembers]);

  const uploadFilesReq = useXhr(
    async (id: string, files: File[]) => {
      await Promise.all(files.map(file => saveFileImportedFile(id, file)));
      await reload();
    },
    'Dosya yüklendi',
    'Dosya yüklenirken bir hata oluştu',
    [reload]
  );

  const openFileUploadDialog = useCallback(
    async (id: string) => {
      const files = await openFileDialog({ title: 'Atama Yazısı Yükle', maxCount: 1, accept: ['.doc', '.pdf', 'docx'] });

      if (!files) {
        return;
      }

      await uploadFilesReq(id, files);
    },
    [openFileDialog, uploadFilesReq]
  );

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteCommitteeMembers(id);
      await reload();
    },
    'Seçili Kayıt Silindi',
    'Kayıt silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Kayıtı silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await deleteFileImportedFile(id);
      await reload();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [reload]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.file?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.file?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={committeeMembers} errorMessage="Gösterilecek Kayıt Bulunamadı.">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold" width="5%" align="left" />
                <TableCell style={{ color: 'gray' }} className="text-bold" width="20%" align="left">
                  Adı Soyadı
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  Kuruldaki Görevi
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="10%" align="left">
                  E-mail
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  Şirket Adı
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="25%" align="center">
                  Atama Yazısı
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {committeeMembers.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <Avatar url={avatars[item.employeeId]} />
                  </TableCell>
                  <TableCell align="left">{names[item?.employeeId]}</TableCell>
                  <TableCell align="left">
                    {findByKey(committeeMembersPositionDefinitions, 'id', item?.committeeMemberPositionDefinition)?.label}
                  </TableCell>
                  <TableCell align="left">{employees?.find(employeesData => employeesData.id === item?.employeeId)?.email}</TableCell>
                  <TableCell align="left">{findByKey(companies, 'id', item?.companyId)?.companyName}</TableCell>

                  <TableCell align="center">
                    {item?.file ? (
                      <>
                        <IconButton
                          onClick={() =>
                            downlaodFile(`/saglik-gozetimi/${item?.file?.path}/${item?.file?.name}`, item?.file?.originalFileName)
                          }
                          edge="end"
                          aria-label="download">
                          <GetAppIcon />
                        </IconButton>
                        <IconButton onClick={() => removeFileShow(item)} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => openFile(`/saglik-gozetimi/${item?.file?.path}/${item?.file?.name}`, item?.file?.originalFileName)}
                          edge="end"
                          aria-label="delete">
                          <VisibilityIcon />
                        </IconButton>
                      </>
                    ) : (
                      <Button
                        onClick={() => openFileUploadDialog(item?.id)}
                        variant="contained"
                        color="default"
                        startIcon={<CloudUploadIcon />}>
                        Atama Yazısı Yükle
                      </Button>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => removeFile(item)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
