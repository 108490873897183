/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeWarningFlag = {
    label?: string;
    warningFlagType?: EmployeeWarningFlag.warningFlagType;
}

export namespace EmployeeWarningFlag {

    export enum warningFlagType {
        ICD10_LAST_EXAM = 'ICD10_LAST_EXAM',
        ICD10_ALL_EXAM = 'ICD10_ALL_EXAM',
    }


}
