import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { saveAnalysisParameters, updateAnalysisParameters } from '../../../../../api/hs/definitions/health-definitions/analysis';
import { AnalysisParametersResult } from '../../../../../api/hs/definitions/health-definitions/analysis/AnalysisParametersResult';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: AnalysisParametersResult;
}

export default function AnalysisParametersForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);
  const { categoryDefinitionId } = useParams<{ categoryDefinitionId: string }>();

  const submit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveAnalysisParameters({ ...values, categoryDefinitionId });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateAnalysisParameters(data.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, showMessage, data, categoryDefinitionId]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();
    formRef?.current?.methods?.setValue('parameter', data?.parameter);
    formRef?.current?.methods?.setValue('min', data?.min);
    formRef?.current?.methods?.setValue('max', data?.max);
    formRef?.current?.methods?.setValue('analysisParameterUnit', data?.analysisParameterUnit);

    formRef?.current?.methods?.trigger();
  }, [data]);
  return (
    <>
      <Dialog color="primary" opened={open} onClose={onClose} title={data ? 'Tahlil / Tetkit Düzenle' : 'Tahlil / Tetkit Ekle'}>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item md={12} sm={12} lg={12}>
              <TextField name="parameter" label="Parametre" />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <TextField label="Birim" name={'analysisParameterUnit'} />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <TextField type="text" name="min" label="Minimum değer" />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <TextField type="text" name="max" label="Maximum değer" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12} />
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
