import React from 'react';
import { Route, Switch } from 'react-router';

import List from './List';

export default function EmployeeList() {
  return (
    <Switch>
      <Route path="/transactions/examination" exact>
        <List />
      </Route>
    </Switch>
  );
}
