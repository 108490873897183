enum QueryDomains {
  VACCINE = 'vaccine',
  EXAMINATION = 'examination',
  NURSE_OPERATION = 'nurseOperation',
  PRESCRIPTION = 'prescription',
  REPORT = 'report',
  REFERRAL = 'referral',
  ACCIDENT = 'accident',
  NEAR_MISS = 'nearMiss',
  EXPOSURE = 'exposure',
  ANALYSIS = 'analysis',
  ACTION = 'action',
  TRAINING = 'training',
  COMMITTEE_MEETINGS = 'committeeMeetings',
}
export default QueryDomains;
