import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { SavePulmonaryPayload } from './pulmonary.payload';
import { PulmonaryResult, PulmonaryResultAggregate } from './pulmonary.result';

export async function getPulmonary(id: string): Promise<PulmonaryResultAggregate> {
  const [error, res] = await tryCatch(HSApi.get(`/analysis/${id}/pft-details`));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res?.data;
}

export async function savePulmonary({ id, ...payload }: MakeRequired<Partial<SavePulmonaryPayload>, 'id'>): Promise<PulmonaryResult> {
  const [error, res] = await tryCatch(HSApi.post(`/analysis/${id}/pft-details`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res?.data;
}
