import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useState } from 'react';

import AnalysisParametersForm from './AnalysisParametersForm';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: '6px',
    right: '10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));
export default function AddButton({ fetch }) {
  const classes = useStyles();

  const [newOpen, setNewOpen] = useState(false);
  const onClose = useCallback(async () => {
    setNewOpen(false);
    await fetch();
  }, [setNewOpen, fetch]);
  const onNewDialog = useCallback(() => {
    setNewOpen(true);
  }, [setNewOpen]);

  return (
    <div>
      <Fab onClick={onNewDialog} className={classes.fabButton} color="primary">
        <AddIcon className={classes.addButton} />
      </Fab>
      <AnalysisParametersForm open={newOpen} onClose={onClose} data={null} />
    </div>
  );
}
