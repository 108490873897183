import { Icd10 } from 'model/definitions/health-definitions/icd10.model';
import { modelDataMatcher } from 'utils';

import { FamilyHistoryResult } from './family-history.result';

export class FamilyHistoryModel {
  public id: string = null;
  public description: string = null;
  public bloodRelationDefinitionId: string = null;
  public icd10s: Icd10['id'][] = [];

  constructor(data: Partial<FamilyHistoryModel> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: FamilyHistoryResult) {
    return new FamilyHistoryModel({ ...data, icd10s: data.familyHistoryIcd10s.map(({ icd10 }) => icd10) });
  }

  public static fromServerListData(data: FamilyHistoryResult[]) {
    return data.map(FamilyHistoryModel.fromServerData);
  }
}
