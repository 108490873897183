import { Divider, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { defer, findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import {
  deleteDisabilities,
  getDisabilities,
  GetDisabilitiesResult,
  saveDisabilities,
  SaveDisabilityPayload,
  updateDisabilities,
  UpdateDisabilityPayload,
} from 'api/hs/examination';
import Button from 'Common/Button';
import AutoComplete from 'Common/Form/AutoComplete';
import TextField from 'Common/Form/TextField';
import PanelWithDialog, { PanelWithDialogProps, PanelWithDialogRef } from 'Common/PanelWithDialog';
import { DialogContext } from 'context/dialog.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useXhr } from 'utils';

import { EmployeeContext } from '../employee.context';

const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
}));

export default function Disabilities() {
  const classes = useStyles();
  const ref = useRef<PanelWithDialogRef>();
  const { refresh } = useContext(EmployeeContext);
  const { disabilities } = useContext(SelectOptionsContext);
  const [data, setData] = useState<GetDisabilitiesResult>(null);
  const [loading, setLoading] = useState(true);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { openWarnDialog } = useContext(DialogContext);

  const fetch = useCallback(async () => {
    setLoading(true);
    const res = await getDisabilities(employeeId, examinationId);

    setData(res);
    setLoading(false);
  }, [employeeId, examinationId]);

  const saveReq = useXhr(
    async (payload: SaveDisabilityPayload) => {
      setLoading(true);
      await saveDisabilities(payload);
      await fetch();
      await refresh();
    },
    'Engellilik Durumu Oluşturuldu',
    'Engellilik Durumunu Kaydederken Bir Hata Oluştu',
    [fetch, refresh]
  );

  const updateReq = useXhr(
    async (payload: UpdateDisabilityPayload) => {
      setLoading(true);
      await updateDisabilities(data.id, payload);
      await fetch();
      await refresh();
    },
    'Engellilik Durumu Güncellendi',
    'Engellilik Durumunu Güncellenirken Bir Hata Oluştu',
    [data, fetch, refresh]
  );

  const deleteReq = useXhr(
    async () => {
      setLoading(true);
      await deleteDisabilities(data.id);
      await fetch();
      await refresh();
    },
    'Engellilik Durumu Kaldırıldı',
    'Engellilik Durumunu Kaldırılırken Bir Hata Oluştu',
    [data, fetch, refresh]
  );

  const saveNewDisability = useCallback(async () => {
    const { form, submitted } = await ref.current.openDialog(null);

    if (!submitted) {
      return;
    }

    await saveReq({
      description: form.description,
      percentage: +form.percentage,
      disabilityDetails: form.disabilities.map((id: number) => ({ disabilityDefinitionId: id })),
      examinationId,
      employeeId,
    });
  }, [saveReq, examinationId, employeeId]);

  const updateDisability = useCallback(async () => {
    const { form, submitted } = await ref.current.openDialog(data);

    if (!submitted) {
      return;
    }

    await updateReq({
      description: form.description,
      percentage: +form.percentage,
      disabilityDetails: form.disabilities.map((id: number) => ({ disabilityDefinitionId: id })),
    });
  }, [updateReq, data]);

  const deleteDisability = useCallback(async () => {
    const submitted = await openWarnDialog({ text: 'Engellilik Durumunu Kaldırmak İstediğinize Emin misiniz?' });

    if (!submitted) {
      return;
    }

    await deleteReq();
  }, [deleteReq, openWarnDialog]);

  const prepareForm = useCallback<PanelWithDialogProps<GetDisabilitiesResult>['onDialogOpen']>(
    async ({ opened, data: passedData, reset, setValue, trigger }) => {
      if (!opened) {
        return;
      }

      if (!passedData) {
        reset();
        return;
      }

      await defer();
      const { disabilityDetails, ...dialogData } = passedData;

      for (const [key, value] of Object.entries(dialogData)) {
        setValue(key, value);
      }

      setValue(
        'disabilities',
        disabilityDetails.map(({ disabilityDefinitionId }) => disabilityDefinitionId)
      );

      trigger();
    },
    []
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <PanelWithDialog
      ref={ref}
      dialogTitle="Engellilik Durumu Düzenle"
      form={
        <>
          <AutoComplete name="disabilities" options={disabilities} renderOption="checkbox" label="Engel Durumları" />
          <TextField name="percentage" type="number" label="Toplam Engellilik Oranı (%)" />
          <TextField name="description" label="Açıklama" rows={3} />
        </>
      }
      indicationWrapperProps={{
        data,
        errorMessage: 'Herhangi bir engelliliği bulunmamaktadır.',
        loading,
        errorChildren: (
          <div className="text-right mt-2">
            <Button variant="contained" color="primary" onClick={saveNewDisability}>
              ENGELLİLİK DURUMU DÜZENLE
            </Button>
          </div>
        ),
      }}
      onDialogOpen={prepareForm}>
      <Grid container>
        <Grid item sm={6}>
          <List>
            {data?.disabilityDetails
              ?.map(({ disabilityDefinitionId }) => findByKey(disabilities, 'value', disabilityDefinitionId))
              ?.filter(isTruthy)
              ?.map(({ value, text }) => (
                <ListItem key={value}>
                  <ListItemIcon>
                    <Icon className="icon-wheelchair-accessibility" />
                  </ListItemIcon>
                  <ListItemText>{text}</ListItemText>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item sm={6} className={classes.sideGrid}>
          <List>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" className="text-italic text-gray">
                    Engellilik Oranı: <strong className="text-normal text-black">{data?.percentage}</strong>
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" className="text-italic text-gray">
                    Açıklama
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary={data?.description} />
            </ListItem>
          </List>
          <div className="text-right">
            <Button color="error" onClick={deleteDisability} className="mr-2">
              Engellilik Kaydını Sil
            </Button>
            <Button color="secondary" variant="contained" onClick={updateDisability}>
              Düzenle
            </Button>
          </div>
        </Grid>
      </Grid>
    </PanelWithDialog>
  );
}
