import React, { Component } from 'react';

import AddPregnancies from './Add';
import ListPregnancies from './List';
class Index extends Component {
  render() {
    return (
      <div>
        <AddPregnancies />
        <ListPregnancies />
      </div>
    );
  }
}

export default Index;
