import { Button, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import Select, { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState } from 'utils';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../../api/hs/employee';
import DateInput from '../../../../Common/Form/DateInput';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function Filters() {
  const classes = useStyles();
  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const {
    genders,
    companies,
    departments,
    workStations,
    locations,
    positions,
    workingTypes,
    examinationDefinitions,
    hsExamOpinionDefinitions,
    polyclinicOpinions,
  } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);

  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  return (
    <>
      <Fab
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}
        onClick={open}
        size="small"
        className={classes.fab}
        color="secondary">
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">Muayene Filtreleme</Typography>
        </Toolbar>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid item xs={12}>
              <DateInput type="date" name="minDate" label="Min Date" />
            </Grid>
            <Grid item xs={12}>
              <DateInput type="date" name="maxDate" label="Max Date" />
            </Grid>
            <Grid item xs={6} className="pr-1">
              <TextField type="number" name="minAge" label="Min Age" />
            </Grid>
            <Grid item xs={6} className="pl-1">
              <TextField type="number" name="maxAge" label="Max Age" />
            </Grid>
            <Grid item xs={12}>
              <Select name="gender" options={genders} label="Gender" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                name="employeeIds"
                label="Personel Seç"
                optionFetcher={employeeOptionFetcher}
                labelFetcher={employeeLabelFetcher}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="companyIds" options={companies} label="Company" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="departmentDefinitionIds" options={departments} label="Department" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="stationDefinitionIds" options={workStations} label="Work Station" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="locationDefinitionIds" options={locations} label="Location" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="positionDefinitionIds" options={positions} label="Position" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="workingTypeIds" options={workingTypes} label="Working Type" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="examinationDefinitionIds" options={examinationDefinitions} label="Examination Type" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="hsExamOpinionDefinitionIds" options={hsExamOpinionDefinitions} label="Examination Opinion Definitions" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="polyclinicExamOpinionDefinitionIds" options={polyclinicOpinions} label="Polyclinic Opinion Definitions" />
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
