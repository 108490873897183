import React, { useState } from 'react';

import { IbysTrainingsModel } from 'api/hs/trainings/ibys-trainings';

import ESignatureNotification from '../../Common/ESignatureNotification';
import { MinistryListContext } from './ministry-list-context';
import MinistryTrainingList from './MinistryTrainingList';

export default function Index() {
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [pinCodeDialogOpened, setPinCodeDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<IbysTrainingsModel>(null);

  ESignatureNotification();

  return (
    <MinistryListContext.Provider
      value={{
        editDialogOpened,
        setEditDialogOpened,
        pinCodeDialogOpened,
        setPinCodeDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <MinistryTrainingList />
    </MinistryListContext.Provider>
  );
}
