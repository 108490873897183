import {
  IconButton,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import LoopIcon from '@material-ui/icons/Loop';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import PreviewForm from 'Common/Forms/PreviewForm';

import { PrescriptionControllerService } from '../../../api/client';
import { previewPrescription } from '../../../api/hs/examination';
import AlertInformation from '../../../Common/AlertInformation';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { SelectOptionsContext } from '../../../context/select-options.context';
import { dateDisplayFormat, printFile, stopPropagation, useFetch } from '../../../utils';
import {exportEmployeePrescription} from "../../../api/hs/employee";

const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;
const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  col: {
    color: 'inherit',
  },
  header: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

export default function PrescriptionPage() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { employeeId } = useParams<{ employeeId: string }>();
  const [loading, setLoading] = useState(true);
  const {
    prescriptionTypeDefinitions,
    prescriptionDescriptionDefinitions,
    prescriptionProvisionDefinitions,
    prescriptionSubtypeDefinitions,
  } = useContext(SelectOptionsContext);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [newPreviewDialog, setPreviewDialogOpened] = useState(false);
  const [previewData, setPreviewData] = useState('');

  const { data, fetch } = useFetch(() => PrescriptionControllerService.getAllPrescriptions(employeeId), [], { setLoading });

  const exportFile = useCallback(async (id: string) => {
    await exportEmployeePrescription(id);
  }, []);

  const onPreview = useCallback(
    async (id: string) => {
      const htmlData = await previewPrescription(id);
      setPreviewData(htmlData);
      setPreviewDialogOpened(true);
      closeMenu();
    },
    [setPreviewDialogOpened, closeMenu, setPreviewData]
  );

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await fetch();
      }
      setPreviewDialogOpened(false);
    },
    [fetch, setPreviewDialogOpened]
  );

  return (
    <div className="m-1 p-1">
      <IndicatorWrapper loading={loading} data={data} errorMessage="Gösterilecek Kayıt Bulunamadı">
        <Paper elevation={3}>
          {data && data.length > 0 ? (
            <TableContainer style={{ marginBottom: '10px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header} align="center" style={{ width: '20%' }}>
                      e-Reçete No
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '20%' }}>
                      Reçete Tarihi
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '20%' }}>
                      Reçete Türü
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                      Reçete Alt Türü
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                      Provizyon Tipi
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                      Açıklama Türü
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '30%' }}>
                      Açıklama
                    </TableCell>
                    <TableCell className={classes.header} align="left" style={{ width: '3%' }}>
                      İşlemler
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item, index) => (
                    <TableRow hover key={`prescription-${item.employeeId}-${index}`}>
                      <TableCell className={classes.col} align="center">
                        {item.eprescriptionNo ? item.eprescriptionNo : '-'}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {dateDisplayFormat(item.requestDate)}
                        <br />
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {prescriptionTypeDefinitions.find(def => def.value === item.prescriptionTypeDefinitionId)?.text}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {prescriptionSubtypeDefinitions.find(def => def.value === item.prescriptionSubtypeDefinitionId)?.text}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {prescriptionProvisionDefinitions.find(def => def.value === item.prescriptionProvisionTypeDefinitionId)?.text}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {prescriptionDescriptionDefinitions.find(def => def.value === item.prescriptionDescriptionTypeDefinitionId)?.text}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        {item.description}
                      </TableCell>
                      <TableCell className={classes.col} align="left">
                        <span onClick={stopPropagation}>
                          <IconButton onClick={openMenu}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
                            <MenuItem onClick={() => exportFile(item?.id)}>
                              <ListItemIcon>
                                <GetAppIcon fontSize="medium" />
                              </ListItemIcon>
                              <Typography variant="inherit">İndir</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => onPreview(item?.id)}>
                              <ListItemIcon>
                                <VisibilityIcon fontSize="medium" />
                              </ListItemIcon>
                              <Typography variant="inherit">Önizleme</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                printFile(`/prescriptions/${item?.id}/detail-view/export?type=HTML`);
                                closeMenu();
                              }}>
                              <ListItemIcon>
                                <PrintIcon fontSize="medium" />
                              </ListItemIcon>
                              <Typography variant="inherit">Yazdır</Typography>
                            </MenuItem>
                          </Menu>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <AlertInformation severity="error" message="Gösterilecek Kayıt Bulunamadı !" />
          )}
        </Paper>
      </IndicatorWrapper>
      <PreviewForm open={newPreviewDialog} onClose={closeDialog} htmlData={previewData} />
    </div>
  );
}
