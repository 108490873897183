import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { TodoItemControllerService, TodoItemDto } from '../../api/client';
import { AlertContext } from '../../context/alert.context';

interface Props {
  fetch(): void;
}

export default ({ fetch }: Props) => {
  const [note, setNote] = useState(null);
  const { showMessage } = useContext(AlertContext);
  const save = () => {
    const request: TodoItemDto = {
      todoContent: note,
      isCompleted: false,
    };
    if (note.length < 255) {
      TodoItemControllerService.saveTodo(request).then(fetch);
      setNote('');
    } else {
      showMessage('Notunuz 255 karakteri geçemez.', 'error', 'info');
      setNote('');
    }
  };

  return (
    <Box>
      <Grid item sm={12} className={'p-2'}>
        <form style={{ display: 'flex' }}>
          <TextField
            required
            id="standard-basic"
            label="Notunuz..."
            variant="standard"
            style={{ flex: 1 }}
            onChange={event => setNote(event.target.value)}
            value={note}
          />
          <Button
            disabled={!note}
            className="ml-1"
            variant="contained"
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={save}>
            Ekle
          </Button>
        </form>
      </Grid>
    </Box>
  );
};
