import { Button, Divider, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState } from 'utils';

import TextField from '../../../../Common/Form/TextField';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

export default function Filters() {
  const classes = useStyles();
  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const { companies, workEquipmentDefinition } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);

  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  const controlStatus = [
    {
      text: 'Kontrol Edildi',
      value: true,
    },
    {
      text: 'Kontrol Edilmedi',
      value: false,
    },
  ];

  const suitabilityUseOption = [
    {
      text: 'Kullanılabilir',
      value: true,
    },
    {
      text: 'Kullanılamaz',
      value: false,
    },
  ];

  return (
    <>
      <Fab
        onClick={open}
        size="small"
        className={classes.fab}
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">İş Ekipman Kontrol Filtreleme</Typography>
        </Toolbar>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid item xs={12}>
              <AutoComplete name="companyIds" options={companies} label="Şirketler" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete single name="completed" options={controlStatus} label="Kontrol Durumu" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete name="workEquipmentDefinitionIds" options={workEquipmentDefinition} label="İş Ekipman Tanımı" />
            </Grid>
            <Grid item xs={12}>
              <AutoComplete single name="suitabilityUse" options={suitabilityUseOption} label="Kullanım Durumu" />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description" label="Açıklama" />
            </Grid>
            <Grid spacing={1} item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Kontrol Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="executionDateMin" label="Başlangıç" />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="executionDateMax" label="Bitiş" />
              </Grid>
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
