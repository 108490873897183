/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageWorkEquipmentDefinitionView } from '../models/PageWorkEquipmentDefinitionView';
import type { WorkEquipmentDefinitionDto } from '../models/WorkEquipmentDefinitionDto';
import type { WorkEquipmentDefinitionRequest } from '../models/WorkEquipmentDefinitionRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkEquipmentDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentDefinitionDto OK
     * @throws ApiError
     */
    public static updateWorkEquipmentDefinition(
        id: string,
        requestBody: WorkEquipmentDefinitionDto,
    ): CancelablePromise<WorkEquipmentDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/work-equipment-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteWorkEquipmentDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-definitions/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns WorkEquipmentDefinitionDto OK
     * @throws ApiError
     */
    public static saveWorkEquipmentDefinition(
        requestBody: WorkEquipmentDefinitionDto,
    ): CancelablePromise<WorkEquipmentDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/work-equipment-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentDefinitionDto OK
     * @throws ApiError
     */
    public static workEquipmentDefinitionImageImport(
        id: string,
        requestBody?: {
            image: Blob;
        },
    ): CancelablePromise<WorkEquipmentDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/work-equipment-definitions/${id}/image`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageWorkEquipmentDefinitionView OK
     * @throws ApiError
     */
    public static getWorkEquipmentDefinitionsByFilter(
        requestBody: WorkEquipmentDefinitionRequest,
    ): CancelablePromise<PageWorkEquipmentDefinitionView> {
        return __request({
            method: 'POST',
            path: `/work-equipment-definitions/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentDefinitionModels(): CancelablePromise<Array<string>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-definitions/models`,
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentDefinitionBrands(): CancelablePromise<Array<string>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-definitions/brands`,
        });
    }

}