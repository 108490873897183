import { Button, Container, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useRef } from 'react';

import { AccidentControllerService } from '../../api/client';
import AutoComplete from '../../Common/Form/AutoComplete';
import DateInput from '../../Common/Form/DateInput';
import Form from '../../Common/Form/Form';
import TextField from '../../Common/Form/TextField';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { useTriggerRender } from '../../utils';

interface Props {
  onSave(id: string): void;
}

const AccidentRecordForm = ({ onSave }: Props) => {
  const { companies, accidentTypes, shiftDefinitions, departments, workStations } = useContext(SelectOptionsContext);
  let data: { id: string };
  data = { id: null }; // TODO vicdan burda bi sıkıntı var
  const formRef = useRef();
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      const payload = {
        accidentTypes: values?.accidentTypes.map((accidentType: string) => {
          return {
            accidentTypeDefinitionId: accidentType,
          };
        }),
        companyId: values?.companyId,
        accidentDepartments: values?.accidentDepartments.map((department: string) => {
          return {
            departmentId: department,
          };
        }),
        accidentStations: values?.accidentStations.map((station: string) => {
          return {
            stationId: station,
          };
        }),
        date: values?.date,
        shiftId: values?.shiftId,
        description: values?.description,
      };
      try {
        if (!data?.id) {
          const result = await AccidentControllerService.saveAccident(payload);
          onSave(result.id);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await AccidentControllerService.partialUpdateAccident(data?.id, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [showMessage, data, onSave]
  );
  return (
    <Container maxWidth={'xl'}>
      <Form onSubmit={submit} onChange={reRender} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete single name="companyId" options={companies} label="Şirket" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="accidentDepartments" options={departments} label="Departman" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="accidentStations" options={workStations} label="İstasyon" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="accidentTypes" options={accidentTypes} label="Kaza Türü" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete single name="shiftId" options={shiftDefinitions} label="Vardiya" renderOption={'checkbox'} />
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <DateInput required type="date-time" name="date" label="Kaza Tarihi" />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField name="description" label="Kaza Tanımı" rows={3} />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => formRef?.current?.methods?.reset()}>
              Formu Temizle
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default AccidentRecordForm;
