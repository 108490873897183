/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddictionDto } from './AddictionDto';

export type ExaminationFilter = {
    maxDate?: string;
    minDate?: string;
    minAge?: number;
    maxAge?: number;
    gender?: number;
    examinationDefinitionIds?: Array<number>;
    status?: ExaminationFilter.status;
    companyIds?: Array<string>;
    employeeIds?: Array<string>;
    departmentDefinitionIds?: Array<string>;
    stationDefinitionIds?: Array<string>;
    locationDefinitionIds?: Array<string>;
    positionDefinitionIds?: Array<string>;
    workingTypeIds?: Array<number>;
    polyclinicExamOpinionDefinitionIds?: Array<number>;
    hsExamOpinionDefinitionIds?: Array<string>;
    prescriptionExists?: boolean;
    restReportExists?: boolean;
    referralExists?: boolean;
    occupationalDiseaseExists?: boolean;
    chronicDiseaseExists?: boolean;
    disabilityExists?: boolean;
    isIbysSent?: boolean;
    opinionExists?: boolean;
    allergyExists?: boolean;
    addictionExists?: boolean;
    exposureExists?: boolean;
    diagnosisExists?: boolean;
    analysisExists?: boolean;
    familyHistoryExists?: boolean;
    icd10s?: Array<string>;
    referralSections?: Array<string>;
    referralHospitals?: Array<string>;
    restReportTime?: number;
    prescriptionTypeDefinitions?: Array<number>;
    medicines?: Array<string>;
    analysisCategoryDefinitions?: Array<string>;
    disabilityDefinitions?: Array<string>;
    bloodRelationDefinitions?: Array<string>;
    restReportDefinitions?: Array<string>;
    addictiveList?: Array<AddictionDto>;
    exposureDefinitions?: Array<string>;
    numberOfChildren?: number;
    allergyDefinitions?: Array<string>;
}

export namespace ExaminationFilter {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
