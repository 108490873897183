import React from 'react';

import AddMedicine from './AddMedicineDefinition/FormMedicine';
import ListMedicine from './ListMedicineDefinitions/MedicineDefinition';

export default function MedicineDefinition() {
  return (
    <>
      <AddMedicine />
      <ListMedicine />
    </>
  );
}
