import { Paper } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';

import { getDiagnosis } from 'api/hs/examination/diagnosis';
import { useFetch } from 'utils';

import Diagnosis from './Diagnosis';

export default function DiagnosisPage() {
  const { employeeId } = useParams<{ employeeId: string }>();
  const { data, fetch } = useFetch(async () => await getDiagnosis(employeeId), []);

  return (
    <div className="p-2">
      <Paper className="p-2" elevation={3}>
        <Diagnosis diagnosis={data} reloadDiagnosis={fetch} />
      </Paper>
    </div>
  );
}
