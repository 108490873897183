import { Container, Grid, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { AccidentReviewerControllerService, AccidentReviewerDto } from '../../api/client';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { useFetch } from '../../utils';
import { AccidentReviewContext } from './accident-review-context';
import Action from './Action';
import Analysis from './Analysis';
import Communication from './Communication';
import Preview from './Preview';
import Research from './Research';

export default () => {
  const history = useHistory();

  const getParameters = new URLSearchParams(window.location.search);
  const tokenParam = getParameters && getParameters.get('token');
  if (!tokenParam) {
    history.push('/');
    return <></>;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [token, setToken] = useState<string>();
  const [accidentId, setAccidentId] = useState<string>();

  const { data: reviewer } = useFetch(() => AccidentReviewerControllerService.validateReviewer(null, tokenParam), undefined, {
    setLoading,
  });

  useEffect(() => {
    setToken(tokenParam);
    localStorage.setItem('accidentReviewerToken', tokenParam);
  }, [tokenParam]);

  useEffect(() => {
    if (reviewer) {
      setAccidentId(reviewer.accidentId);
    }
  }, [reviewer]);

  return (
    <>
      {accidentId && (
        <AccidentReviewContext.Provider
          value={{
            accidentId,
            token,
            reviewer,
            insertable: [AccidentReviewerDto.reviewerType.LEAD, AccidentReviewerDto.reviewerType.USER].includes(reviewer?.reviewerType),
            updatable: [AccidentReviewerDto.reviewerType.LEAD].includes(reviewer?.reviewerType),
          }}>
          <IndicatorWrapper loading={loading} data={reviewer} errorMessage={'Bu alana erişim için yetkiniz bulunmamaktadır.'}>
            <Grid container>
              <Preview />
              <Container className="mt-2">
                <Paper elevation={3}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={0} onClick={() => setActiveStep(0)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Araştırma
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Research />
                      </StepContent>
                    </Step>
                    <Step key={1} onClick={() => setActiveStep(1)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Analiz
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Analysis />
                      </StepContent>
                    </Step>
                    <Step key={2} onClick={() => setActiveStep(2)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Aksiyon
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Action />
                      </StepContent>
                    </Step>
                    <Step key={3} onClick={() => setActiveStep(3)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          İletişim
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Communication />
                      </StepContent>
                    </Step>
                  </Stepper>
                </Paper>
              </Container>
            </Grid>
          </IndicatorWrapper>
        </AccidentReviewContext.Provider>
      )}
    </>
  );
};
