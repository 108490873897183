import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { PpeDefinitionsContentResult } from 'api/hs/ppe/ppe-definitions';
import { deletePpeDefinition, savePpeDefinitionFile } from 'api/hs/ppe/ppe-definitions';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../context/dialog.context';
import { ResourceContext } from '../../../../context/resource.context';
import { useXhr } from '../../../../utils';
import { PpeDefinitionsContext } from '../../ppe-definitions.context';

function PpeClassificationLabel({ row }: { row: PpeDefinitionsContentResult }) {
  const { ppeClassificationDefinitions } = useContext(ResourceContext);
  return <>{findByKey(ppeClassificationDefinitions, 'id', row?.ppeClassificationId)?.label}</>;
}

function PpeTypeLabel({ row }: { row: PpeDefinitionsContentResult }) {
  const { ppeTypeDefinitions } = useContext(ResourceContext);
  return <>{findByKey(ppeTypeDefinitions, 'id', row?.ppeTypeId)?.label}</>;
}

function ActionComponent({ row }: { row: PpeDefinitionsContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(PpeDefinitionsContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deletePpeDefinition(id);
      await refresh();
    },
    'Seçili Kayıt Silindi',
    'Kayıt silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.description} isimli KKD Tanımını silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => savePpeDefinitionFile(row?.id, file)));
      await refresh();
    },
    'Resim yüklendi',
    'Resim yüklenirken bir hata oluştu',
    [row, refresh]
  );

  const openFileUploadDialog = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({
      maxCount: 1,
      title: 'Resim Yükle',
    });

    if (!files) {
      return;
    }

    await uploadFilesReq(files);
  }, [openFileDialog, uploadFilesReq, closeMenu]);

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => openFileUploadDialog(row)}>
          <ListItemIcon>
            <ImageIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Resim Yükle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export const COLUMNS: TableColumn<PpeDefinitionsContentResult>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    defaultVisibility: true,
    render(row): JSX.Element {
      return <Avatar src={`/saglik-gozetimi/public/images/ppe/${row?.imagePath}`} />;
    },
  },
  {
    id: 'label',
    width: '10%',
    label: 'Tanımı',
    defaultVisibility: true,
  },
  {
    id: 'serialNumber',
    width: '10%',
    label: 'Seri Numarası',
    defaultVisibility: true,
  },
  {
    id: 'ppeClassificationId',
    width: '15%',
    label: 'KKD Ana Grup',
    defaultVisibility: true,
    render(row: PpeDefinitionsContentResult): JSX.Element {
      return <PpeClassificationLabel key={row?.id} row={row} />;
    },
  },
  {
    id: 'ppeTypeId',
    width: '15%',
    label: 'KKD Alt Grup',
    defaultVisibility: true,
    render(row: PpeDefinitionsContentResult): JSX.Element {
      return <PpeTypeLabel key={row?.id} row={row} />;
    },
  },
  {
    id: 'brand',
    width: '10%',
    label: 'Marka',
    defaultVisibility: true,
  },
  {
    id: 'model',
    width: '10%',
    label: 'Model',
    defaultVisibility: true,
  },
  {
    id: 'ppeEnStandard',
    width: '10%',
    label: 'EN Standardı',
    defaultVisibility: true,
  },
  {
    id: 'criticalInventoryCount',
    width: '10%',
    label: 'Kritik Sayı',
    defaultVisibility: true,
  },
  {
    id: Symbol(),
    label: 'İşlemler',
    width: '5%',
    render(row: PpeDefinitionsContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
