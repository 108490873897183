import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { getNurseOperationList } from 'api/hs/TransactionAndLists/NurseOperationList';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import { CELL } from './COLUMS';
import ListTable from './TableList';
import Toolbar from './Toolbar';

export default function Examination() {
  const history = useHistory();
  const fetchNurseOperationList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getNurseOperationList(
      page,
      size,
      sorting === false ? 'date-DESC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
    );
  }, []);
  return (
    <HSTableAndPaginationAndTableContextProvider
      fetcher={fetchNurseOperationList}
      RowProps={{ className: 'cursor-pointer' }}
      columns={CELL}
      onRowClick={row => history.push(`/transactions/employee/${row.employeeId}/nursing-actions/${row.id}`)}>
      <Toolbar />
      <ListTable />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
