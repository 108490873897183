import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import React, { useCallback, useContext } from 'react';

import { AccidentReviewControllerService, AccidentReviewWitnessDto } from '../../../api/client';
import { AccidentReviewContext } from '../accident-review-context';
import ActionContainer from './ActionContainer';

const useStyles = makeStyles(theme => ({
  card: {
    color: theme.palette.grey[600],
    margin: 10,

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.light,
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  witnessName: {
    flex: 1,
  },
}));

interface Props {
  data: AccidentReviewWitnessDto;
  onClick(data: any): void;
  fetch(): void;
}

export default ({ data, fetch, onClick }: Props) => {
  const styles = useStyles();
  const { token } = useContext(AccidentReviewContext);

  const onClickCancel = useCallback(() => {
    AccidentReviewControllerService.deleteWitness(data.id, token).then(fetch);
  }, [data.id, fetch, token]);

  return (
    <Grid xs={12} sm={6}>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <Typography variant="h6" component="h6" className={styles.witnessName}>
            {data.employee ? data.employee?.name : data.guest?.name + ' ' + data.guest?.surname}
          </Typography>
          <ActionContainer onClickCancel={onClickCancel} onClickPreview={onClick} />
        </CardContent>
      </Card>
    </Grid>
  );
};
