import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { defer, findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { deleteAllergies, getAllergies, GetAllergiesResult, saveAllergies, updateAllergies } from 'api/hs/examination';
import Button from 'Common/Button';
import AutoComplete from 'Common/Form/AutoComplete';
import TextField from 'Common/Form/TextField';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useDialogState, useUniqueId, useXhr } from 'utils';

import { EmployeeContext } from '../employee.context';

const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
}));

export default function Allergies() {
  const classes = useStyles();
  const { refresh } = useContext(EmployeeContext);
  const formId = useUniqueId('allergy-form');
  const [loading, setLoading] = useState(true);
  const [allergies, setAllergies] = useState<GetAllergiesResult>();
  const { opened, open, close } = useDialogState();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { allergies: allergyOptions } = useContext(SelectOptionsContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { setValue, reset, trigger, ...methods } = useForm();

  const fetch = useCallback(async () => {
    setLoading(true);
    setAllergies(await getAllergies(employeeId, examinationId));
    setLoading(false);
  }, [employeeId, examinationId]);

  const submitForm = useXhr(
    async ({ description, allergies: submittedAllergies }: { allergies: number[]; description: string }) => {
      const data = { description, allergyDetails: submittedAllergies.map(id => ({ allergyDefinitionId: id })) };

      setLoading(true);

      if (allergies) {
        await updateAllergies(allergies.id, data);
      } else {
        await saveAllergies({ ...data, employeeId, examinationId });
      }
      refresh();
      fetch();
      close();
    },
    'Alerji Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [allergies, employeeId, examinationId]
  );

  const deleteReq = useXhr(
    async () => {
      setLoading(true);
      await deleteAllergies(allergies.id);
      fetch();
      refresh();
    },
    'Alerji Kaydı Başarıyla Silindi',
    'Silme işlemi esnasında bir hata oluştu',
    [allergies]
  );

  const deleteAllergy = useCallback(async () => {
    const submitted = await openWarnDialog({ text: 'Alerji Kaydını Silmek İstediğinize Emin misiniz?' });

    if (submitted) {
      await deleteReq();
    }
  }, [openWarnDialog, deleteReq]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useAsyncEffect(async () => {
    if (!opened) {
      return;
    }

    if (!allergies) {
      reset();
      return;
    }

    await defer();

    setValue(
      'allergies',
      allergies.allergyDetails.map(({ allergyDefinitionId }) => allergyDefinitionId)
    );
    setValue('description', allergies.description);
    trigger();
  }, [allergies, setValue, reset, trigger, opened]);

  return (
    <>
      <Dialog open={opened} onClose={close}>
        <DialogTitle>{allergies ? 'Alerjileri Düzenle' : 'Alerji Ekle'}</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...methods, setValue, reset, trigger }}>
            <form id={formId} onSubmit={methods.handleSubmit(submitForm)}>
              <AutoComplete name="allergies" options={allergyOptions} label="Alerji Seçiniz" renderOption="checkbox" />
              <TextField name="description" label="Açıklama" rows={3} />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className="text-right pr-3">
          <Button onClick={close}>İptal</Button>
          <Button color="primary" variant="contained" form={formId} type="submit">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
      <IndicatorWrapper
        loading={loading}
        data={allergies}
        errorMessage="Henüz bir alerji girilmedi."
        errorChildren={
          <div className="text-right pt-2">
            <Button variant="contained" color="primary" onClick={open}>
              Alerji Ekle
            </Button>
          </div>
        }>
        <Grid container>
          <Grid item sm={6}>
            <List>
              {allergies?.allergyDetails
                ?.map(({ allergyDefinitionId }) => findByKey(allergyOptions, 'value', allergyDefinitionId))
                ?.filter(isTruthy)
                ?.map(({ value, text }) => (
                  <ListItem key={value}>
                    <ListItemIcon>
                      <Icon className="icon-chart-bubble" />
                    </ListItemIcon>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item sm={6} className={classes.sideGrid}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" className="text-italic text-gray">
                      Açıklama
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary={allergies?.description} />
              </ListItem>
            </List>
            <div className="text-right">
              <Button color="error" onClick={deleteAllergy} className="mr-2">
                Alerji Kaydını Sil
              </Button>
              <Button color="secondary" variant="contained" onClick={open}>
                Düzenle
              </Button>
            </div>
          </Grid>
        </Grid>
      </IndicatorWrapper>
    </>
  );
}
