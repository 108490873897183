import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import {
  deleteRestReportFileUpload,
  getRestReportFileUpload,
  RestReportFileUploadResult,
} from 'api/hs/file-uploads/rest-report-file-upload';
import ImagePreview from 'Common/Forms/ImagePreview';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';
import { downlaodFile } from 'utils';
import { useAsyncEffect, useXhr } from 'utils';

export default function RestReportFileUpload() {
  const { openWarnDialog } = useContext(DialogContext);
  const [restReportFileUpload, setRestReportFileUpload] = useState<RestReportFileUploadResult[]>(null);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState<RestReportFileUploadResult>(null);
  const [previewImageOpened, setPreviewImageOpened] = useState(false);
  const { employeeId } = useParams<{ employeeId: string }>();

  const closePreviewImageDialog = useCallback(() => {
    setPreviewImageOpened(false);
  }, []);

  async function fetch() {
    const res = await getRestReportFileUpload(employeeId);
    setRestReportFileUpload(res);
    setLoading(false);
  }

  useAsyncEffect(async () => {
    await fetch();
  }, [employeeId]);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteRestReportFileUpload(id);
      await fetch();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  const onPreview = useCallback(
    async reportFileData => {
      setSelectedData(reportFileData);
      setPreviewImageOpened(true);
    },
    [setPreviewImageOpened, setSelectedData]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={restReportFileUpload} errorMessage="Gösterilecek Rapor  Dosya Bulunamadı">
        <List key="hs-analysisFileUploadList">
          {restReportFileUpload?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-account" />
                </ListItemAvatar>
                <ListItemText primary={item?.originalFileName} />
                <ListItemSecondaryAction>
                  {item?.type === 'image/jpeg' || item?.type === 'image/png' ? (
                    <IconButton onClick={() => onPreview(item)} edge="end" aria-label="delete">
                      <VisibilityIcon />
                    </IconButton>
                  ) : null}
                  <IconButton
                    onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end"
                    aria-label="download">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => removeFile(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <ImagePreview
          open={previewImageOpened}
          avatarPath={`${selectedData?.path}/${selectedData?.name}`}
          onClose={closePreviewImageDialog}
        />
      </IndicatorWrapper>
    </div>
  );
}
