import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';

export async function resetPassword(forgotPassId: string, payload: any) {
  const [error] = await tryCatch(HSApi.put<void>(`/users/reset-passwords/${forgotPassId}`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
