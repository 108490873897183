import { modelDataMatcher } from 'utils';

import { WorkAccidentHistoryResult, WorkAccidentHistoryResultItem } from './work-accident-history.result';

export class WorkAccidentHistory {
  public id: string = null;
  public description: string = null;
  public date: string = null;

  constructor(data: Partial<WorkAccidentHistory> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: WorkAccidentHistoryResultItem): WorkAccidentHistory {
    return new WorkAccidentHistory(data);
  }

  public static fromServerListData(data: WorkAccidentHistoryResult): WorkAccidentHistory[] {
    return data.map(WorkAccidentHistory.fromServerData);
  }
}
