import React from 'react';

import TrainingDefinitionsList from './List';
import TrainingDefinitionsAdd from './TrainingDefinitionAdd';

export default function Index() {
  return (
    <>
      <TrainingDefinitionsAdd />
      <TrainingDefinitionsList />
    </>
  );
}
