import { Button, Drawer, makeStyles, Paper, Typography } from '@material-ui/core';
import {noop, tryCatch} from '@thalesrc/js-utils';
import React, {useContext} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import TextField from 'Common/Form/TextField';
import {WorkingHoursControllerService} from "../../api/client";
import axios from "axios";
import {AlertContext} from "../../context/alert.context";
import {LicenceContext} from "../../context/licence";

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 320,
  },
  paper: {
    maxWidth: 320,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

interface SupportDrawerProps {
  opened: boolean;
  onClose(): void;
}

export default function SupportDrawer({ opened = false, onClose = noop }: SupportDrawerProps) {
  const classes = useStyles();
  const formMethods = useForm();
    const { showMessage } = useContext(AlertContext);
    const { licence } = useContext(LicenceContext);
  const onSubmit = async (values: any) => {
      values.append('company_id',1);
      const [err, res] = await tryCatch(axios.post(`https://www.artimetrik.net/api/create-ticket`, { values }));
      if (err) {
          showMessage('Talebiniz oluşturulurken hata oluştu!', 'warning', 'info');
          console.log(err);
      } else {
          showMessage('Talebiniz başarılı şekilde oluşturuldu!', 'success', 'info');
          onClose();
      }
  };

  return (
    <Drawer anchor="right" open={opened} onClose={onClose} PaperProps={{ className: `${classes.container} px-2 py-4` }}>
      <Typography variant="h5" className="text-center">
        ARTI METRİK <br />
        DESTEK
      </Typography>
      <Paper elevation={3} className={classes.paper + ' mt-2 p-4'}>
        Aşağıdaki form üzerinden yazılım ve destek ekibimize bildiriminizi gönderebilirsiniz.
      </Paper>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mt-4">
          <TextField name="subject" label="Konu" className="mb-2" rules={{ required: 'Bu alan zorunlu' }} />
          <TextField name="description" label="Açıklama" className="mb-2" rows={5} rules={{ required: 'Bu alan zorunlu' }} />
          <footer className="text-right">
            <Button type="submit" variant="contained" color="primary" disabled={!!Object.keys(formMethods.errors).length}>
              Gönder
            </Button>
          </footer>
        </form>
      </FormProvider>
    </Drawer>
  );
}
