import { Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { TypeDefinitionContext } from '../../type-definition.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ReferralsList() {
  const { loading, workEquipmentTypeDefinition } = useContext(TypeDefinitionContext);

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={workEquipmentTypeDefinition} errorMessage="İş Ekipman Tipi Bulunamadı.">
        <List className="m-1" id="workEquipmentTypeDefinition">
          {workEquipmentTypeDefinition?.map((item, index) => (
            <Fragment key={item?.id}>
              <ListItem>
                <ListItemAvatar>
                  <AccountBalanceIcon className="text-gray" />
                </ListItemAvatar>
                <ListItemText color="primary">
                  <ListItemText primary={item?.name} />
                </ListItemText>
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
