import React from 'react';

import AddButtonNewCaseTeamMembers from './Add/AddButtonNewCaseTeamMembers';
import CaseTeamsList from './List';
import NewCaseTeamDialog from './NewCaseTeamDialog';
import NewCaseTrainingDialog from './NewCaseTrainingDialog';
import CaseTeamDetailToolbar from './Toolbar';
export default function ListAdd() {
  return (
    <>
      <CaseTeamDetailToolbar />
      <CaseTeamsList />
      <NewCaseTeamDialog />
      <NewCaseTrainingDialog />
    </>
  );
}
