import { Button, Grid } from '@material-ui/core';
import { tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { BuildingFloorDefinitionControllerService } from 'api/client';
import { BuildingPartDefinitionControllerService } from 'api/client';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { useFetch } from 'utils';

import { WorkEquipmentLayoutNewFormContext } from '../../work-equipment-layout-new-form.context';

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default function WorkEquipmentLayoutNewForm() {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { buildings, workEquipmentDefinition } = useContext(SelectOptionsContext);
  const { setWorkEquipmentLayoutData, workEquipmentLayoutData } = useContext(WorkEquipmentLayoutNewFormContext);
  const [selectedBuildingDefinitionId, setSelectedBuildingDefinitionId] = useState<string>(null);
  const [selectedFloorDefinitionId, setSelectedFloorDefinitionId] = useState<string>(null);

  const { data: floorList } = useFetch<SelectOption<string>[]>(
    async () => {
      if (!selectedBuildingDefinitionId) return [];

      const [err, res] = await tryCatch(
        BuildingFloorDefinitionControllerService.getAllBuildingFloorDefinitions(selectedBuildingDefinitionId)
      );
      if (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
        return [];
      }
      return res?.map(floor => ({
        text: floor?.name,
        value: floor?.id,
      }));
    },
    [],
    { deps: [selectedBuildingDefinitionId], reloadDeps: [selectedBuildingDefinitionId] }
  );

  const { data: partsList } = useFetch<SelectOption<string>[]>(
    async () => {
      if (!selectedFloorDefinitionId) return [];

      const [err, res] = await tryCatch(BuildingPartDefinitionControllerService.getAllBuildingPartDefinitions(selectedFloorDefinitionId));
      if (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
        return [];
      }
      return res?.map(part => ({
        text: part?.name,
        value: part?.id,
      }));
    },
    [],
    { deps: [selectedFloorDefinitionId], reloadDeps: [selectedFloorDefinitionId] }
  );

  const onSave = useCallback(
    formData => {
      const values = {
        ...formData,
        id: guid(),
      };
      setWorkEquipmentLayoutData([...workEquipmentLayoutData, values]);
    },
    [workEquipmentLayoutData, setWorkEquipmentLayoutData]
  );

  const onChangeForm = useCallback(
    formValues => {
      setSelectedBuildingDefinitionId(formValues?.building);
      if (formValues?.floors) {
        setSelectedFloorDefinitionId(formValues?.floors);
      }
    },
    [setSelectedBuildingDefinitionId]
  );

  return (
    <Form onChange={onChangeForm} onSubmit={onSave} ref={formRef}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <AutoComplete single name="building" options={buildings} label="Binalar" />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <AutoComplete disabled={!floorList.length} single name="floors" options={floorList} label="Katlar" />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <AutoComplete disabled={!partsList.length} single name="layoutPartId" options={partsList} label="Bölümler" />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <AutoComplete single name="workEquipmentDefinitionId" options={workEquipmentDefinition} label="İş Ekipman Tanımı" required={true}/>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <TextField type="number" name="trackingNumber" label="Takip Numarası" />
        </Grid>
        <Grid item xs={6} md={6} lg={6} sm={6}>
          <TextField name="serialNumber" label="Seri Numarası" />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <TextField name="qrcodeStr" label="QR Kodu" />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <TextField rows={3} name="layoutDescription" label="Yer Bilgisi" />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <span style={{ display: 'block', color: 'red' }}>
            * Verileri girerken bina-kat-bölüm bilgilerini ya da yer bilgisini giriniz.Bu alanların ikisini de boş bırakmayınz.
          </span>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <span style={{ display: 'block', color: 'red' }}>
            * Bina-Kat-Bölüm Bilgilerini , Tanımlar - İşyeri Tanımları - Bina-Kat-Bölüm tabından girebilirsiniz.
          </span>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
          <Button variant="contained" type="submit" color="secondary" className="mr-2">
            Listeye Ekle
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
