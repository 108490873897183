import { withStyles } from '@material-ui/core';
import BaseLine from '@material-ui/core/CssBaseline';

export default withStyles(theme => ({
  '@global': {
    // Spacing
    ...['padding', 'margin']
      .map(prop =>
        ([null, 'sm', 'md', 'lg', 'xl'] as ('sm' | 'md' | 'lg' | 'xl')[])
          .map(bp =>
            ['', 'left', 'top', 'right', 'bottom', 'x', 'y']
              .map(key =>
                [0, 1, 2, 3, 4, -1, -2, -3, -4]
                  .map(num => ({
                    [`.${prop[0]}${key ? key[0] : ''}${bp ? '-' + bp : ''}-${num < 0 ? 'n' + Math.abs(num) : num}`]: (() => {
                      const value = `${theme.spacing(num)}px !important`;
                      const def =
                        key !== 'x' && key !== 'y'
                          ? { [`${prop}${key ? '-' + key : ''}`]: value }
                          : key === 'x'
                          ? {
                              [`${prop}-left`]: value,
                              [`${prop}-right`]: value,
                            }
                          : {
                              [`${prop}-top`]: value,
                              [`${prop}-bottom`]: value,
                            };

                      return bp ? { [theme.breakpoints.up(bp)]: def } : def;
                    })(),
                  }))
                  .reduce(reduceClasses, {})
              )
              .reduce(reduceClasses, {})
          )
          .reduce(reduceClasses, {})
      )
      .reduce(reduceClasses, {}),

    // Text Aligning
    ...['left', 'center', 'right'].reduce((acc, next) => ({ ...acc, ['.text-' + next]: { textAlign: next } }), {}),

    // Font Weight
    ...['light', 'normal', 'bold', 100, 200, 300, 400, 500, 600, 700, 800, 900].reduce(
      (acc, next) => ({ ...acc, ['.text-' + next]: { fontWeight: next } }),
      {}
    ),

    // Font Style
    ...['italic', 'normal', 'underline'].reduce((acc, next) => ({ ...acc, ['.text-' + next]: { fontStyle: next } }), {}),

    // Font Size
    ...['xx-small', 'x-small', 'smaller', 'small', 'large', 'larger', 'x-large', 'xx-large'].reduce(
      (acc, next) => ({ ...acc, ['.text-' + next]: { fontSize: next } }),
      {}
    ),

    // Heading
    ...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((acc, next) => ({ ...acc, [`.${next}`]: (theme.typography as any)[next] }), {}),

    // Text Colors
    ...Object.entries({ ...theme.palette.common, gray: theme.palette.grey[700] })
      .map(([color, value]) => ({ [`.text-${color}`]: { color: value + ' !important' } }))
      .reduce(reduceClasses, {}),

    // Bg Colors
    ...Object.entries({
      ...theme.palette.common,
      gray: theme.palette.grey[700],
      ...['primary', 'secondary', 'warning', 'error'].reduce((acc, color) => ({ ...acc, [color]: (theme.palette as any)[color].main }), {}),
    })
      .map(([color, value]) => ({ [`.bg-${color}`]: { backgroundColor: value + ' !important' } }))
      .reduce(reduceClasses, {}),

    // Etc.
    '.cursor-pointer': {
      cursor: 'pointer',
    },
    '.flex-center': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.flex-justify': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.text-ellipsis': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '.width-100': {
      width: '100%',
    },
    '.display-block': {
      display: 'block',
    },
    '.half-opaque': {
      opacity: 0.5,
    },
    '.rotate': {
      animation: '$rotate 2s linear infinite',
    },

    // Global Styles
    '::-webkit-scrollbar': {
      width: 8,
      height: 8,

      '&-button': {
        width: 0,
        height: 0,
      },
      '&-thumb': {
        background: theme.palette.grey[400],
        border: `0px none ${theme.palette.grey[400]}`,
        borderRadius: 50,
        transition: 'background .3s ease',

        '&:hover': {
          background: theme.palette.grey.A200,
        },
        '&:active': {
          background: theme.palette.grey.A700,
        },
      },
      '&-track': {
        background: theme.palette.grey[100],
        border: `0px none ${theme.palette.grey[100]}`,
        borderRadius: 28,

        '&:hover': {
          background: theme.palette.grey[200],
        },
        '&:active': {
          background: theme.palette.grey[300],
        },
      },
      '&-corner': {
        background: 'transparent',
      },
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    ':root': {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    body: {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    '#root': {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },

    // Overrides
    '.fc': {
      '--fc-today-bg-color': theme.palette.warning.light + '20',

      '& .fc-col-header-cell-cushion': {
        color: theme.palette.secondary.dark,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      '& .fc-daygrid-day-number': {
        color: theme.palette.common.black,
      },
    },
  },
}))(BaseLine);

// tslint:disable-next-line:ban-types
function reduceClasses(acc: Object, next: Object) {
  return { ...acc, ...next };
}
