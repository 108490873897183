import { useRef } from 'react';

export function usePrevious<T = any>(data: T): T {
  const ref = useRef<T>();

  const prev = ref.current;
  ref.current = data;

  return prev;
}
