import {Button, Dialog, DialogContent, Grid, Hidden, makeStyles, Typography} from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { UserContext } from 'context/user';
import {useAsyncEffect, useDateFormat} from 'utils';

import {
  FizikselCalismaOrtami,
  SaveExaminationPayload,
  updateExaminations,
  xmlContentIbysExaminations,
} from '../../../../api/hs/Examinations';
import { saveExaminations, signIbysExaminations } from '../../../../api/hs/Examinations/examinations';
import {
  CalismaOrtamiBilgiDto,
  CalismaSekliBilgiDto,
  IsEkipmanlariBilgiDto,
  MuayeneBilgiDto, MuayeneBilgiResponseDto
} from "../../../../api/hs/Examinations/ibys-examination";
import {IbysExaminationControllerService, IbysExamSignedDataDto} from "../../../../api/client";

const useStyles = makeStyles(theme => ({
  gridContainerRoot: {
    marginBottom: '50px',
    border: '1px solid #e0e0e0',
    borderBottom: 'none',
  },
  gridTitleRoot: {
    padding: '15px 10px',
  },
  gridRoot: {
    borderBottom: '1px solid #e0e0e0',
    padding: '15px 10px',
  },
  bolder: {
    fontWeight: 'bold',
  },
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  id: string;
  startFill: any;
  setValue: any;
  getValues: any;
  trigger: any;
}

function reportTypeValue(reportType: string): string {
  switch (reportType) {
    case 'RECRUITMENT_EXAMINATION':
      return '100050';
    case 'PERIODIC_EXAMINATION':
      return '100051';
    case 'RETURN_TO_WORK_EXAMINATION':
      return '100052';
    case 'JOB_CHANGE_EXAMINATION':
      return '100053';
    default:
      return '100051';
  }
}

function opinionTypeValue(opinionType: string): string {
  switch (opinionType) {
    case 'COULD_WORK':
      return '100054';
    case 'CONDITIONAL_WORK':
      return '100055';
    case 'CHANGE_WORK':
      return '100056';
    case 'COULD_NOT_WORK':
      return '100057';
    default:
      return '100054';
  }
}

export default function ConvertToExaminationsDialog({ open, onClose, id, setValue, getValues, trigger, startFill }: Props) {
  const classes = useStyles();
  const { showMessage } = useContext(AlertContext);

  const { profile } = useContext(UserContext);
  const format = useDateFormat();

  const sendIbys = async () => {
    try {
      //const xmlContentVal = await xmlContentIbysExaminations(id);

      let muayeneBilgiDto = new MuayeneBilgiDto();
      muayeneBilgiDto.isyeriSgkSicilNo =  getValues('ssiNumber');
      muayeneBilgiDto.ihKimlikNo =  parseInt(getValues('physicianNid'), 10);
      muayeneBilgiDto.calisanTcKNo =  parseInt(getValues('employeeNid'), 10);
      muayeneBilgiDto.raporTuru =  reportTypeValue(getValues('reportType'));
      muayeneBilgiDto.calisanMeslegi =  getValues('professionCode').trim();
      let calismaSekli = new CalismaSekliBilgiDto();
      calismaSekli.calismaPozisyonu = getValues('workingPositionDefinitions');
      calismaSekli.calismaZamani = getValues('workingTimeDefinitions');
      calismaSekli.aracKullanimi = getValues('vehicleOperationDefinitions');
      muayeneBilgiDto.calismaSekliDTO =  calismaSekli;
      let isEkipmanlari:IsEkipmanlariBilgiDto[] = [];
      let isEkipmani = new IsEkipmanlariBilgiDto();
      //FIXME
      isEkipmani.bagliReferansKodu = "0";
      isEkipmani.ustReferansKodu = "1030";
      isEkipmanlari.push(isEkipmani);
      muayeneBilgiDto.kullanilanIsEkipmanlari = isEkipmanlari;

      muayeneBilgiDto.muayeneRaporTarihi = format(new Date(getValues('reportDate')), 'dd.MM.yyyy');
      muayeneBilgiDto.rontgenYapildiMi = getValues('roentgenExists');
      muayeneBilgiDto.isitmeTestiYapildiMi = getValues('audioTestExists');
      muayeneBilgiDto.solunumFonkTestiYapildiMi = getValues('pftTestExists');
      muayeneBilgiDto.kanTetkikiYapildiMi = getValues('bloodTestExists');
      muayeneBilgiDto.idrarTetkikiYapildiMi = getValues('urineTestExists');
      muayeneBilgiDto.raporGecerlikTarihi = format(new Date(getValues('reportEndDate')), 'dd.MM.yyyy');
      muayeneBilgiDto.sonucVeKanaat = opinionTypeValue(getValues('opinion'));
      let calismaOrtami = new CalismaOrtamiBilgiDto();
      calismaOrtami.fizikiOrtamSinifi = getValues('physicalEnvironmentDefinitions');
      calismaOrtami.biyolojikEtkenlerSinifi =  getValues('biologicalFactorsDefinitions');
      calismaOrtami.tozYonetmeligiSinifi = getValues('dustRegulationDefinitions');
      calismaOrtami.gurultuSinifi = getValues('noiseDefinitions');
      calismaOrtami.kimyasalMaddeSinifi = getValues('chemicalMatterDefinitions');
      calismaOrtami.havaSinifi = getValues('airDefinitions');
      calismaOrtami.elektrikSinifi = getValues('electricityDefinitions');
      muayeneBilgiDto.calismaOrtamiDTO = calismaOrtami;
      //muayeneBilgiDto.zamanDamgasi = XX
      muayeneBilgiDto.imzalayanKimlikNo = muayeneBilgiDto.ihKimlikNo;
      const signData = await signIbysExaminations({ action: getValues('action'), pinCode: getValues('pinCode'), xmlContent: JSON.stringify(muayeneBilgiDto)});
      if (signData.sonucKodu !== '200') {
        showMessage(`${signData.sonucKodu} - ${signData.sonucMesaji}`, 'error', 'info');
      } else {
        muayeneBilgiDto.action = 'muayeneGonder';
        muayeneBilgiDto.zamanDamgasi = signData.timestamp;
        /*
        const imzaliDeger: string = String(signData.data).replaceAll(/\n/g, '');
        const payload: SaveExaminationPayload = {
          muayeneKaydet: {
            sgkTescilNo: getValues('ssiNumber'),
            isyeriHekimiTcKimlikNo: parseInt(getValues('physicianNid'), 10),
            imzaliDeger,
            calisanMuayeneVerisi: [
              {
                calisanTcKimlikNo: parseInt(getValues('employeeNid'), 10),
                raporTuru: reportTypeValue(getValues('reportType')),
                calisanMeslegi: getValues('professionCode').trim(),
                calismaSekli: {
                  calismaPozisyonu: parseInt(getValues('workingPositionDefinitions'), 10),
                  calismaZamani: parseInt(getValues('workingTimeDefinitions'), 10),
                  aracKullanimi: parseInt(getValues('vehicleOperationDefinitions'), 10),
                },
                isEkipmanlari: getValues('code'),
                raporTarihi: getValues('reportDate'),
                rontgenYapildiMi: getValues('roentgenExists'),
                isitmeTestiYapildiMi: getValues('audioTestExists'),
                solunumFonkTestiYapildiMi: getValues('pftTestExists'),
                kanTetkikiYapildiMi: getValues('bloodTestExists'),
                idrarTetkikiYapildiMi: getValues('urineTestExists'),
                raporSonGecerlilikTarihi: getValues('reportEndDate'),
                sonucVeKanaat: opinionTypeValue(getValues('opinion')),
                fizikiCalismaOrtami: arrayConvertModel(getValues('physicalEnvironmentDefinitions')),
                biyolojikEtkenOrtami: arrayConvertModel(getValues('biologicalFactorsDefinitions')),
                tozYonetmeligiOrtami: arrayConvertModel(getValues('dustRegulationDefinitions')),
                gurultuCalismaOrtami: arrayConvertModel(getValues('noiseDefinitions')),
                kimyasalMaddeOrtamiEntity: arrayConvertModel(getValues('chemicalMatterDefinitions')),
                havaCalismaOrtami: arrayConvertModel(getValues('airDefinitions')),
                elektrikCalismaOrtami: arrayConvertModel(getValues('electricityDefinitions')),
              },
            ],

          }

        };*/
        try {
          const saveData:MuayeneBilgiResponseDto[] = await saveExaminations([muayeneBilgiDto]);
          // @ts-ignore
          let result: MuayeneBilgiResponseDto = saveData[0];
          if(result.sonuc == "1") {
            await updateExaminations(id, { ibysRequestState: 'SENT', processResultMessage:  result.aciklama, examIdOnIbysServer: result.bildirimNo });
            //FIXME update data
            showMessage('IBYS gönderim başarılı', 'success', 'info');
            let ibysSignedDataRequest  = {} as IbysExamSignedDataDto;
            ibysSignedDataRequest.ibysExaminationId=id;

            ibysSignedDataRequest.data = signData.data
            ibysSignedDataRequest.signatureTimestamp = signData.timestamp;
            await IbysExaminationControllerService.saveIbysSaveExaminationSignedData(ibysSignedDataRequest);
            startFill();
            onClose(true);
          } else {
            await updateExaminations(id, { ibysRequestState: 'INCORRECT_REQUEST', processResultMessage:  result.aciklama, examIdOnIbysServer:result.bildirimNo });
            showMessage('IBYS gönderimi başarısız', 'error', 'info');
            startFill();
            onClose(true);
          }
        } catch (e) {
          showMessage('IBYS gönderim sırasında  hata oluştu', 'error', 'info');
        }
      }
    } catch (err) {
      showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      console.log('ERROR in handleSubmit', err); // tslint:disable-line:no-console
    }
  };

  function arrayConvertModel(tempVal: number[]) {
    const resultArray: FizikselCalismaOrtami[] = [];
    tempVal.forEach(val => {
      resultArray.push({
        id: null,
        kod: val,
        calisanMuayeneId: null,
      });
    });
    return resultArray;
  }
  useAsyncEffect(async () => {
    if (!open) {
      return;
    }

    await defer();

    setValue('pinCode', profile?.esignPinCode ?? '');
    trigger();
  }, [open, profile, setValue, trigger]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 600 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{
        paper: classes.dialogPaper,
        scrollPaper: classes.dialogScroll,
      }}>
      <DialogContent>
        <Grid item xs={12} className={classes.gridTitleRoot}>
          <Typography align="center" className={classes.bolder} component="h3">
            IBYS Sistemine Gönder
          </Typography>
        </Grid>
        <Hidden>
          <TextField type="hidden" name="action" defaultValue="ibys_istek"/>
        </Hidden>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <TextField type="password" label="Pin Kodu" name="pinCode" required />
          </Grid>
        </Grid>
        <Grid container className="text-right">
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Button variant="contained" type="button" color="primary" className="mr-2" onClick={sendIbys}>
              Gönder
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
