// import { IconButton } from '@material-ui/core';
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import CheckIcon from '@material-ui/icons/Check';
// import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { defer, findByKey } from '@thalesrc/js-utils';
// import { Edit } from '@material-ui/icons';
// import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { AccidentReviewerControllerService, AccidentReviewerDto } from '../../../api/client';
// import { reviewerTypesMeanings } from './reviewer-type-meanings';
import { UserControllerService } from '../../../api/client';
// import Table from '../../../Common/Table/Default';
// import { TableColumn } from '../../../Common/Table/Raw/table-column.model';
// import { AlertContext } from '../../../context/alert.context';
import { GuestControllerService } from '../../../api/client';
import AlertInformation from '../../../Common/AlertInformation';
import Button from '../../../Common/Button';
import { EmployeeSelectionCacheContext } from '../../../Common/Form/EmployeeSelection/employee-selection.context';
import Form, { FormRef } from '../../../Common/Form/Form';
import Select from '../../../Common/Form/Select';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { AlertContext } from '../../../context/alert.context';
import { UserContext } from '../../../context/user';
import { useAsyncEffect, useFetch, useTriggerRender } from '../../../utils';
import NewRecordButton from '../../Review/NewRecordButton';
import AddReviewerDialog from './AddReviewerDialog';
import ReviewTypeDialog from './ReviewTypeDialog';
interface Props {
  accidentId: string;
}

const reviewerTypeMeanings = [
  { text: 'Takım Lideri', value: 'LEAD' },
  { text: 'Takım Üyesi', value: 'USER' },
  { text: 'Gözlemcı', value: 'READONLY' },
];

export default ({ accidentId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [reviewerTypeDialog, setReviewerTypeDialog] = useState<boolean>(false);
  const [reviewerTypeData] = useState<boolean>(false);
  const [formData, setFormData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const { showMessage } = useContext(AlertContext);
  const [reviewTypeFormData, setReviewTypeFormData] = useState([]);
  const { loadEmployees, names, employees } = useContext(EmployeeSelectionCacheContext);
  const { data, fetch } = useFetch(() => AccidentReviewerControllerService.getReviewers(accidentId), [], { setLoading });
  const hasTeamLead = !!reviewTypeFormData?.find(item => item.reviewerType === AccidentReviewerDto.reviewerType.LEAD);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { user } = useContext(UserContext);
  const reRender = useTriggerRender();

  const FormData = useCallback(
    formDataValues => {
      setFormData([...formData, formDataValues]);
    },
    [formData]
  );

  const onClickAddUser = () => {
    setShowDialog(true);
  };

  useAsyncEffect(async () => {
    await defer();
    loadEmployees(...formData?.map((employee: any) => employee.employeeId));
  }, [formData]);

  useAsyncEffect(async () => {
    const res = await UserControllerService.getUserEmployeeInfos();
    setFormData(
      res?.map(item => ({
        id: item?.userId,
        employeeId: item?.employeeId,
      }))
    );
  }, [setFormData, data]);

  useAsyncEffect(async () => {
    const guestIds = data?.filter(item => item?.guestId)?.reduce((acc, item) => [...acc, item.guestId], []);
    let res;
    if (guestIds.length > 0) {
      res = await GuestControllerService.getGuests(guestIds);
    }

    const guestData = data
      ?.filter(item => item?.guestId)
      ?.map(guests => ({
        id: findByKey(res, 'id', guests?.guestId)?.id,
        guestId: findByKey(res, 'id', guests?.guestId)?.id,
        name: findByKey(res, 'id', guests?.guestId)?.name,
        surname: findByKey(res, 'id', guests?.guestId)?.surname,
        email: findByKey(res, 'id', guests?.guestId)?.email,
        reviewerType: guests?.reviewerType,
      }));
    const employeeData = data
      ?.filter(item => item?.employeeId)
      ?.map(employeeItem => ({
        id: employeeItem?.employeeId,
        employeeId: employeeItem?.employeeId,
        reviewerType: employeeItem?.reviewerType,
      }));

    setReviewTypeFormData([...guestData, ...employeeData]);
  }, [data]);

  const onDelete = useCallback(
    item => {
      setFormData(formData?.filter(formDataItem => formDataItem?.id !== item?.id));
    },
    [setFormData, formData]
  );

  const onCloseReviewerTypeDialog = useCallback(() => {
    setReviewerTypeDialog(false);
  }, [setReviewerTypeDialog]);

  const TableData = useCallback(
    (item: any) => {
      setReviewTypeFormData([...reviewTypeFormData, item]);
    },
    [setReviewTypeFormData, reviewTypeFormData]
  );

  const edit = useCallback(
    (selectedValue: string) => {
      setFormData(formData.map(item => ({ ...item, isEditing: item.id === selectedValue?.id })));
      setSelectedData(selectedValue);
    },
    [formData, setSelectedData]
  );

  const onSave = useCallback(async () => {
    try {
      const guestIdNot = reviewTypeFormData?.filter(item => item?.reviewerTypeOptions === 'external');
      let saveGuests: any;
      if (guestIdNot.length > 0) {
        saveGuests = await Promise.all(
          guestIdNot?.map(item =>
            GuestControllerService.saveGuest1({
              name: item?.name,
              surname: item?.surname,
              email: item?.email,
              firmId: user?.firmId,
            })
          )
        );
      }

      const guestIdNotPayload = reviewTypeFormData
        ?.filter(item => item?.reviewerTypeOptions === 'external')
        ?.map(item => ({
          guestId: findByKey(saveGuests, 'email', item?.email)?.id,
          reviewerType: item?.reviewerType,
        }));

      const employeePayload = reviewTypeFormData
        ?.filter(item => item?.employeeId)
        ?.map(employeeData => ({
          employeeId: employeeData?.employeeId,
          reviewerType: employeeData?.reviewerType,
        }));

      const guestPayload = reviewTypeFormData
        ?.filter(item => item?.guestId)
        ?.map(guestData => ({
          guestId: guestData?.guestId,
          reviewerType: guestData?.reviewerType,
        }));

      const payload = [...guestIdNotPayload, ...employeePayload, ...guestPayload];
      await AccidentReviewerControllerService.updateAllAccidentReviewers(accidentId, payload);
      showMessage('Ekip Üyeleri Başarılı ile kayıt edildi.', 'success', 'info');
      await fetch();
    } catch (e) {
      showMessage('Kayıt işlemi başarısız.', 'error', 'info');
    }
  }, [user, reviewTypeFormData, accidentId, showMessage, fetch]);

  const onCloseSelect = useCallback(() => {
    setFormData(formData.map(item => ({ ...item, isEditing: false })));
    setSelectedData({});
  }, [formData, setSelectedData]);

  const handleFormChange = useCallback(
    (value: any) => {
      reRender();
      if (value?.reviewerType) {
        setReviewTypeFormData([...reviewTypeFormData, { ...selectedData, reviewerType: value?.reviewerType }]);
        setFormData(formData?.filter(delData => delData?.id !== selectedData?.id));
      }
    },
    [reRender, selectedData, reviewTypeFormData, formData]
  );

  const revertFormData = useCallback(
    value => {
      setFormData([...formData, value]);
      setReviewTypeFormData(reviewTypeFormData?.filter(reviewData => reviewData?.id !== value?.id));
    },
    [formData, reviewTypeFormData]
  );

  return (
    <div>
      <NewRecordButton type="button" onClick={onClickAddUser} label={'Kullanıcı Ekle'} />
      <Paper className="m-2" elevation={3}>
        {formData.length < 1 ? (
          <AlertInformation severity="warning" message="Kullanıcı Ekleyiniz." />
        ) : (
          <>
            <div className="p-2" style={{ display: 'flex', width: '100%' }}>
              <SupervisedUserCircleIcon style={{ color: 'gray' }} fontSize="large" />
              <Typography className="ml-1" style={{ fontSize: '20px', color: 'gray' }} variant="body1" gutterBottom>
                {' '}
                Ekip Üyeleri
              </Typography>
            </div>
            <Divider variant="middle" />
            <List>
              <Grid container spacing={1}>
                {formData?.map((item, index) => (
                  <Grid key={index} item md={6} sm={6} lg={6}>
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ maxWidth: '500px' }}
                        primary={item?.name ? `${item?.name} ${item?.surname}` : names[item?.employeeId]}
                        secondary={item?.email ? item?.email : employees?.find(employee => employee.id === item?.employeeId)?.email}
                      />
                      {!item?.isEditing ? (
                        <>
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => edit(item)} edge="end" aria-label="edit">
                              <ImportExportIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </>
                      ) : (
                        <div style={{ width: '100%' }}>
                          <Form onChange={handleFormChange} ref={formRef}>
                            <Grid style={{ marginLeft: '100px' }} container spacing={1}>
                              <Grid className="text-left" item xs={8} md={8} sm={8} lg={8}>
                                <Select required name="reviewerType" options={reviewerTypeMeanings} label="Ekip Rolü" />
                              </Grid>
                              <Grid item xs={4} md={4} sm={4} lg={4} className="text-left">
                                <IconButton onClick={() => onCloseSelect(item)}>
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Form>
                        </div>
                      )}
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </List>
          </>
        )}
      </Paper>
      <IndicatorWrapper data={true} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
        <div style={{ display: 'flex', flex: 'auto', justifyContent: 'space-around' }}>
          <Card variant="outlined" style={{ minWidth: '350px', minHeight: '400px' }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Takım Lideri
              </Typography>
              <Divider />
              <List>
                {reviewTypeFormData
                  ?.filter(leadData => leadData.reviewerType === 'LEAD')
                  ?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.name ? `${item?.name} ${item?.surname}` : names[item?.employeeId]}
                        secondary={item?.email ? item?.email : employees?.find(employee => employee.id === item?.employeeId)?.email}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => revertFormData(item)} edge="end" aria-label="edit">
                          <ImportExportIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ minWidth: '350px', minHeight: '400px' }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Takım Üyesi
              </Typography>
              <Divider />
              <List>
                {reviewTypeFormData
                  ?.filter(leadData => leadData.reviewerType === 'USER')
                  ?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.name ? `${item?.name} ${item?.surname}` : names[item?.employeeId]}
                        secondary={item?.email ? item?.email : employees?.find(employee => employee.id === item?.employeeId)?.email}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => revertFormData(item)} edge="end" aria-label="edit">
                          <ImportExportIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ minWidth: '350px', minHeight: '400px' }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Gözlemci
              </Typography>
              <Divider />
              <List>
                {reviewTypeFormData
                  ?.filter(leadData => leadData.reviewerType === 'READONLY')
                  ?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.name ? `${item?.name} ${item?.surname}` : names[item?.employeeId]}
                        secondary={item?.email ? item?.email : employees?.find(employee => employee.id === item?.employeeId)?.email}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => revertFormData(item)} edge="end" aria-label="delete">
                          <ImportExportIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </Card>
        </div>
      </IndicatorWrapper>
      <AddReviewerDialog
        onFormData={FormData}
        accidentId={accidentId}
        teamLeadDisabled={hasTeamLead}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        data={formData}
        fetch={fetch}
      />
      <ReviewTypeDialog
        setFormData={onDelete}
        setTableData={TableData}
        open={reviewerTypeDialog}
        onClose={onCloseReviewerTypeDialog}
        data={reviewerTypeData}
      />
      <div className="mt-2">
        {!hasTeamLead && <AlertInformation severity="warning" message="Kaza incelemesi başlatabilmek için takım lideri eklemelisiniz." />}
      </div>
      <div className="mt-2 mr-3 text-right">
        <Button onClick={onSave} type="submit" disabled={!hasTeamLead} variant="outlined" color="secondary">
          Listeyi Kaydet
        </Button>
      </div>
    </div>
  );
};
