/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    avatarPath?: string;
    name?: string;
    address?: string;
    gender?: string;
    birthDate?: string;
    birthPlace?: string;
    email?: string;
    gsmNumber?: string;
    countyId?: number;
    bloodDonation?: string;
    bloodTypeName?: string;
    bloodType?: number;
    nid?: string;
    cityId?: number;
    telephone?: string;
    status?: EmployeeView.status;
    numberOfChildren?: string;
    educationalStatusName?: string;
    educationalStatus?: number;
    retirementStatus?: string;
    maritalStatusName?: string;
    maritalStatus?: number;
    workingTypeName?: string;
    workingType?: number;
    departmentName?: string;
    departmentId?: string;
    businessEmail?: string;
    positionName?: string;
    positionId?: string;
    professionCode?: string;
    businessGsmNumber?: string;
    stationName?: string;
    stationId?: string;
    locationName?: string;
    locationId?: string;
    companyCode?: string;
    companyName?: string;
    companyId?: string;
    registryNumber?: string;
    hireDate?: string;
    createdByName?: string;
    lastModifiedByName?: string;
    managerId?: string;
    generalDescription?: string;
    age?: number;
}

export namespace EmployeeView {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
