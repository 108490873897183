import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useUniqueId } from 'utils';

import DateInput from '../../../../../Common/Form/DateInput';

export interface ExternalVaccineValues {
  vaccine: number;
  date: string;
  lotNumber?: string;
  description?: string;
}

interface Props {
  opened: boolean;
  onClose(): any;
  onSubmit(value: ExternalVaccineValues): any;
}

export default function NewExternalVaccine({ opened, onClose, onSubmit }: Props) {
  const formId = useUniqueId('edit-vaccine-form');
  const { setValue, reset, trigger, ...methods } = useForm();
  const { vaccines } = useContext(SelectOptionsContext);

  useAsyncEffect(async () => {
    if (!opened) {
      return;
    }

    await defer();
    reset();
  }, [setValue, reset, trigger, opened]);

  return (
    <Dialog open={opened} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Aşı Kayıt</DialogTitle>
      <DialogContent>
        <FormProvider {...{ setValue, trigger, reset, ...methods }}>
          <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Select name="vaccine" options={vaccines} label="Aşı Tipi" required />
            <DateInput name="date" label="Aşı Tarihi" type="date" required />
            <TextField name="lotNumber" label="LOT No" />
            <TextField name="description" label="Açıklama" rows={3} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right pr-1 pb-2">
        <Button className="mr-2" onClick={onClose}>
          İptal
        </Button>
        <Button className="mr-2" color="primary" variant="contained" form={formId} type="submit">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
}
