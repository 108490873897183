import React from 'react';

import FirmSubscriptionsAdd from './add';
import FirmSubscriptionsList from './list';
export default function Firms() {
  return (
    <>
      <FirmSubscriptionsAdd />
      <FirmSubscriptionsList />
    </>
  );
}
