import { tryCatch } from '@thalesrc/js-utils';

import { NearMissFilter, NearMissView } from '../../client';
import HSApi from '../HSApi';
import { excelExport, handleResult } from '../utils';
import { AccidentContentResult } from './accident-content.result';
import { AccidentResult } from './accident.result';
import { AccidentFilters, GetAccidentsListPayload, GetNearMissListPayload, NearMissFilters } from './get-accidents.payload';

export async function getAccidentIdActions(definitionId: string, page = 0, size = 20, order: null | string): Promise<any> {
  const payload = {
    actionFilter: {
      referenceId: definitionId,
    },
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<any>(`/actions/views`, payload);

  return res.data;
}
export async function getAccidentList(page = 0, size = 20, order: null | string, filters: AccidentFilters): Promise<AccidentsResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof AccidentFilters)[] = [
    'accidentDateMax',
    'accidentDateMin',
    // 'companyIds',
    // 'employeeIds',
    // 'accidentTypeDefinitionIds',
    // 'accidentInjuryTypeDefinitionIds',
    // 'departmentIds',
    // 'stationIds',
    // 'accidentDescription',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.accidentDateMax) {
    parsedFilters.accidentDateMax = new Date(parsedFilters.accidentDateMax).toISOString();
  }

  if (parsedFilters.accidentDateMin) {
    parsedFilters.accidentDateMin = new Date(parsedFilters.accidentDateMin).toISOString();
  }

  // if (parsedFilters.plannedMaxDate) {
  //   parsedFilters.plannedMaxDate = new Date(parsedFilters.plannedMaxDate).toISOString();
  // }
  //
  // if (parsedFilters.plannedMinDate) {
  //   parsedFilters.plannedMinDate = new Date(parsedFilters.plannedMinDate).toISOString();
  // }
  //
  // if (parsedFilters.createdMaxDate) {
  //   parsedFilters.createdMaxDate = new Date(parsedFilters.createdMaxDate).toISOString();
  // }
  //
  // if (parsedFilters.createdMinDate) {
  //   parsedFilters.createdMinDate = new Date(parsedFilters.createdMinDate).toISOString();
  // }

  const payload: GetAccidentsListPayload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<AccidentResult>(`/accidents/views`, payload);

  return res.data;
}

export async function getNearMissesList(page = 0, size = 20, order: null | string, filters: NearMissFilters): Promise<NearMissView> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof NearMissFilters)[] = [
    'nearMissDateMax',
    'nearMissDateMin',
    'companyIds',
    'nearMissDescription',
    'nearMissTypeDefinitionIds',
    'departmentIds',
    'stationIds',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.nearMissDateMax) {
    parsedFilters.nearMissDateMax = new Date(parsedFilters.nearMissDateMax).toISOString();
  }

  if (parsedFilters.nearMissDateMin) {
    parsedFilters.nearMissDateMin = new Date(parsedFilters.nearMissDateMin).toISOString();
  }

  const payload: GetNearMissListPayload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<AccidentResult>(`/near-misses/views`, payload);

  return res.data;
}

export async function exportAccidentList(filters: AccidentFilters): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof AccidentFilters)[] = [
    'accidentDateMin',
    'accidentDateMax',
    'accidentDescription',
    'companyIds',
    'departmentIds',
    'stationIds',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    filter: parsedFilters,
  };

  const res = await HSApi.post<AccidentResult>(`/accidents/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'kaza-listesi.xlsx';
  a.click();
}

export async function getAccident(id: string): Promise<AccidentContentResult> {
  const [err, res] = await tryCatch(HSApi.get(`/accidents/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function deleteAccident(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/accident/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateAccidents(id, payload): Promise<PpeDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<AccidentContentResult>(`/accident/${id}`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getImportedFilesAccident(id: string): Promise<AccidentContentResult> {
  const [err, res] = await tryCatch(HSApi.get(`/accidents/imported-files/${id}/raw`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function accidentFileUpload(id: any['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`accidents/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function deleteFileImportedAccident(id: string) {
  const [err, res] = await tryCatch(
    HSApi.delete(`/accidents/imported-files/
${id}`)
  );

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAccidentSeverityDefinitions(): Promise<AccidentContentResult> {
  const [err, res] = await tryCatch(HSApi.get(`/accident-severity-definitions`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveAccidentSeverityDefinitions(): Promise<AccidentContentResult> {
  const [err, res] = await tryCatch(HSApi.post(`firms/accident-severity-definitions`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateAccidentTypes({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/accident-type-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateAdminAccidentTypes({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`/accident-type-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }
  return res.data;
}

// @ts-ignore
export async function updateAccidentSeverityTypes({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/accident-severity-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateInjuryTypes({ id, ...body }): Promise<any> {
  const [err, res] = await tryCatch(HSApi.put(`firms/injury-type-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function nearMissFileUpload(id: any['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`near-misses/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function exportNearMissList(filters: NearMissFilter): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof NearMissFilter)[] = [
    'nearMissDateMin',
    'nearMissDateMax',
    'nearMissTypeDefinitionIds',
    'companyIds',
    'departmentIds',
    'stationIds',
    'nearMissDescription',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    filter: parsedFilters,
  };

  const res = await HSApi.post<AccidentResult>(`/near-misses/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'ramak-kala-listesi.xlsx';
  a.click();
}

export async function exportAccidentReview(accidentId: string, employeeId: string, fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/accidents/${accidentId}/employees/${employeeId}/reviews/export`, { responseType: 'blob' })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function exportAccidentCommunication(accidentId: string, fileName: string) {
  const [error, res] = await tryCatch(HSApi.get(`/accidents/${accidentId}/reviews/communications/export`, { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}
