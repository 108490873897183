import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext } from 'react';

import { deleteComplaint } from 'api/hs/examination/complaint';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { ComplaintModel } from 'model/employee/examination/complaint.model';

import { ComplaintsContextContext } from '../../complaints.context';

interface Props {
  data: ComplaintModel;
}

export default function Action({ data }: Props) {
  const { setSelectedData, setEditDialogOpened, complaints, reload } = useContext(ComplaintsContextContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const edit = useCallback(
    item => () => {
      setSelectedData(item);
      setEditDialogOpened(true);
    },
    [setSelectedData, setEditDialogOpened]
  );

  const handleDelete = useCallback(async () => {
    const submitted = await openWarnDialog({
      text: `${complaints.description} - kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteComplaint(complaints.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [complaints, reload, openWarnDialog, showMessage]);

  return (
    <>
      <IconButton onClick={edit(data)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </>
  );
}
