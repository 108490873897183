import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getOpinion as getOpinionDefinition } from 'api/hs/definitions/health-definitions/opinion';
import { getOpinion, OpinionModel, OpinionsResult } from 'api/hs/examination/hs-exam-opinions';
import { GetWorkingRestrictionDefinitionsResult } from 'api/hs/resource/get-working-restriction-definitions.result';
import { useAsyncEffect } from 'utils';

import HsExOpinion from './opinion';
import { OpinionContext } from './opinion.context';

export default function Index() {
  const [opinion, setOpinion] = useState<OpinionsResult>(null);
  const [workingRestriction, setWorkingRestriction] = useState<GetWorkingRestrictionDefinitionsResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<OpinionModel>(null);
  const { examinationId } = useParams<{ examinationId: string }>();

  const fetch = useCallback(async () => {
    setLoading(true);
    const result = await getOpinion(examinationId);
    setOpinion(result);
    setLoading(false);
  }, [examinationId]);

  useAsyncEffect(async () => {
    await fetch();
    await WorkingRestriction();
  }, []);

  useAsyncEffect(async () => {
    await WorkingRestriction();
  }, []);

  const WorkingRestriction = useCallback(async () => {
    const workingRestrictionResult = await getOpinionDefinition();
    setWorkingRestriction(workingRestrictionResult);
  }, []);

  return (
    <OpinionContext.Provider
      value={{
        opinion,
        workingRestriction,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
      }}>
      <HsExOpinion />
    </OpinionContext.Provider>
  );
}
