import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { HazardCurrentPrecautionDefinitionDto } from '../../../api/client';
import { HazardCurrentPrecautionDefinitionControllerService } from '../../../api/client';
import BaseDefinitionPage from '../../BaseDefinitionPage';
import HazardCurrentPrecautionForm from './Forms/HazardCurrentPrecautionForm';

export default function HazardCurrentPrecautionDefinition() {
  return (
    <BaseDefinitionPage<HazardCurrentPrecautionDefinitionDto>
      listLabelPropName="label"
      fetchData={HazardCurrentPrecautionDefinitionControllerService.getAllFirmHazardCurrentPrecautionDefinitions}
      FormDialog={HazardCurrentPrecautionForm}
      onDelete={HazardCurrentPrecautionDefinitionControllerService.deleteFirmHazardCurrentPrecautionDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
