import { Grid } from '@material-ui/core';
import React from 'react';

import { saveSystemSetting, updateSystemSetting } from 'api/hs/definitions/system-setting';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { SystemSettingModel } from 'model/definitions/system-setting/systemSetting.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the systemSetting is created/updated
   */
  onClose(success: boolean): void;

  data: SystemSettingModel;
}

export default function SystemSetting({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<SystemSettingModel>
      open={open}
      onClose={onClose}
      onSave={saveSystemSetting}
      onUpdate={updateSystemSetting}
      data={data}
      createTitle="Ayar Ekleme"
      updateTitle="Ayar Düzenleme"
      maxDialogWidth={550}>
      <Grid item lg={12} md={12}>
        <TextField required name="code" label="Kod" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="key" label="Anahtar" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="value" label="Değer" />
      </Grid>
    </BaseDefinitionForm>
  );
}
