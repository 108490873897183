import { createContext } from 'react';

import { WorkEquipmentPeriodicControlDto } from '../../api/client';
import { WorkEquipmentDefinitionView } from '../../api/client';

export interface WorkEquipmentPeriodicControlContextType {
  workEquipmentPeriodicControl: WorkEquipmentPeriodicControlDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  WorkEquipmentDefinitionData: WorkEquipmentDefinitionView;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: WorkEquipmentPeriodicControlDto;

  setSelectedData(data: WorkEquipmentPeriodicControlDto): void;
}

export const WorkEquipmentPeriodicControlContext = createContext<WorkEquipmentPeriodicControlContextType>(null);
