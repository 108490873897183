import {
  Divider,
  Icon,
  IconButton,
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { deleteExamination, ExaminationResultItem, getExaminationByEmployeeId, saveExamination } from 'api/hs/examination';
import { SaveExaminationPayload } from 'api/hs/examination/examinations.payload';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import Link from 'Common/Link';
import Toolbar from 'Common/Toolbar';
import { DialogContext } from 'context/dialog.context';
import { ResourceContext } from 'context/resource.context';
import { printFile, useFetch, useXhr } from 'utils';

import EK2Form from '../../EK2Form';
import Ek2Ministry from '../../Ek2Ministry/İndex';
import NewExamination from './NewExamination';

const useStyles = makeStyles(theme => ({
  select: {
    '&, & svg': {
      color: theme.palette.common.white,
    },
    '&:before, &:after': {
      display: 'none',
    },
  },
}));

export default function List() {
  const classes = useStyles();

  const { employeeId } = useParams<{ employeeId: string; examinationId: string }>();
  const [loading, setLoading] = useState(true);
  const { examinationDefinitions } = useContext(ResourceContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [selectedExamination, setSelectedExamination] = useState<ExaminationResultItem>(null);
  const { openWarnDialog } = useContext(DialogContext);
  const [filter, setFilter] = useState<number | 'all'>('all');
  const { push } = useHistory();
  const { data, fetch } = useFetch(async () => await getExaminationByEmployeeId(employeeId), [], { setLoading });

  const closeMoreMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openMoreMenu = useCallback(
    (exam: ExaminationResultItem) => (e: React.MouseEvent<HTMLElement>) => {
      setSelectedExamination(exam);
      setAnchorEl(e.target as HTMLElement);
    },
    []
  );

  const deleteExaminationReq = useXhr(
    async () => {
      await deleteExamination(selectedExamination.id);
      await fetch();
    },
    'Muayene Silindi',
    'Muayene silinirken bir hata oluştu',
    [fetch, selectedExamination?.id]
  );

  const createExamination = useXhr(
    async (payload: Omit<SaveExaminationPayload, 'employeeId'>) => {
      const res = await saveExamination({ employeeId, ...payload });
      push(`/transactions/employee/${employeeId}/examination/${res.id}`);
    },
    'Muayene Oluşturuldu',
    err => err,
    [selectedExamination?.id, employeeId]
  );

  const removeRestReport = useCallback(async () => {
    closeMoreMenu();
    const submitted = await openWarnDialog({ text: 'Muayene kaydını silmek istediğinize emin misiniz?' });

    if (!submitted) {
      return;
    }

    await deleteExaminationReq();
  }, [closeMoreMenu, deleteExaminationReq, openWarnDialog]);

  const menuItems = useMemo(
    () => data.reduce((acc, next) => ({ ...acc, [next.examinationDefinitionId]: (acc[next.examinationDefinitionId] ?? 0) + 1 }), {}),
    [data]
  );

  return (
    <>
      <Toolbar color="secondary">
        <Select
          defaultValue="all"
          className={`${classes.select} mt-2 mb-1`}
          variant="standard"
          onChange={(e: any) => setFilter(e.target.value)}>
          <MenuItem value="all">Hepsini Göster ({data.length})</MenuItem>
          {Object.entries(menuItems).map(([id, count], index) => (
            <MenuItem key={id || -1 * index} value={+id}>
              {findByKey(examinationDefinitions, 'id', +id)?.examinationName} ({count})
            </MenuItem>
          ))}
        </Select>
        <span className="width-100" />
        <NewExamination onSubmit={createExamination} />
      </Toolbar>
      <Paper className="m-1" elevation={3}>
        <IndicatorWrapper data={data} loading={loading} errorMessage="Henüz hiç muayene yapılmamış">
          <MuiList>
            {data
              .filter(examination => (filter === 'all' ? true : examination.examinationDefinitionId === filter))
              .map(examination => (
                <Fragment key={examination.id}>
                  <ListItem>
                    <ListItemIcon>
                      <Icon className="icon-stethoscope" />
                    </ListItemIcon>
                    <Link to={`/transactions/employee/${employeeId}/examination/${examination.id}`}>
                      <ListItemText
                        className="text-black"
                        secondaryTypographyProps={{ component: 'div', className: 'text-gray' }}
                        primary={`${
                          examinationDefinitions?.find(def => def.id === examination.examinationDefinitionId)?.examinationName || 'Muayene'
                        } (${examination?.date?.split('-').reverse().join('-')})`}
                        secondary={
                          <>
                            <div>Kanaat: {examination?.opinion ? examination?.opinion : 'Kanaat girilmemiştir.'}</div>
                            <div>Tanı: {examination?.diagnosis ? examination?.diagnosis : 'Tanı konulmamıştır.'}</div>
                          </>
                        }
                      />
                    </Link>
                    <ListItemSecondaryAction>
                      <IconButton onClick={openMoreMenu(examination)}>
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
          </MuiList>
        </IndicatorWrapper>
      </Paper>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMoreMenu}>
        <MenuItem onClick={removeRestReport}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        {findByKey(examinationDefinitions, 'id', selectedExamination?.examinationDefinitionId)?.type === 'HS_EXAMINATION' ? (
          <>
            <MenuItem
              onClick={() => {
                printFile(`/examinations/${selectedExamination?.id}/opinion/view/export?type=HTML`);
                closeMoreMenu();
              }}>
              <ListItemIcon>
                <Icon className="icon-clipboard-check" />
              </ListItemIcon>
              <Typography variant="inherit">Kanaat Formu</Typography>
            </MenuItem>
            <EK2Form
              createdExaminationUserId={selectedExamination?.createdBy ? selectedExamination?.createdBy : null}
              selectedExaminationId={selectedExamination?.id}
              onClose={closeMoreMenu}
            />
            <Ek2Ministry
              createdExaminationUserId={selectedExamination?.createdBy ? selectedExamination?.createdBy : null}
              selectedExaminationId={selectedExamination?.id}
              onClose={closeMoreMenu}
            />
          </>
        ) : (
          <MenuItem
            onClick={() => {
              printFile(`/examinations/${selectedExamination?.id}/opinion/view/export?type=HTML`);
              closeMoreMenu();
            }}>
            <ListItemIcon>
              <Icon className="icon-clipboard-check" />
            </ListItemIcon>
            <Typography variant="inherit">Kanaat Formu</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
