import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { handleResult, stringify } from '../../utils';
import { EventPayload, EventQueryPayload, GenericUserEvent, ReschedulePayload, UserEventType } from './event.payload';

export async function getAll(request: EventQueryPayload) {
  const [error, res] = await tryCatch(HSApi.get<GenericUserEvent[]>(`/generic-user-events${stringify(request)}`));

  return handleResult(res, error);
}

export async function getById(id: string) {
  const [error, res] = await tryCatch(HSApi.get<EventPayload>(`/user-events/${id}`));

  return handleResult(res, error);
}

export async function save(request: EventPayload) {
  const [error, res] = await tryCatch(HSApi.post<EventPayload>(`/user-events`, request));

  return handleResult(res, error);
}

export async function update(id: string, request: EventPayload) {
  const [error, res] = await tryCatch(HSApi.put<EventPayload>(`/user-events/${id}`, request));

  return handleResult(res, error);
}

export async function reschedule(id: string, date: string, eventType: UserEventType) {
  const eventTypePathPair = {
    [UserEventType.USER_DEFINED]: [`/user-events/${id}`, { startDate: date }],
    [UserEventType.TRAINING]: [`/trainings/${id}/plannedDate`, { plannedDate: date }],
    [UserEventType.VACCINE]: [`/vaccines/details/${id}`, { date }],
    [UserEventType.PERIODIC_EXAMINATION]: [`/periodic-examination-configurations/${id}`, { nextExaminationDate: date }],
    [UserEventType.COMMITTEE_MEETING]: [`/committee-meetings/${id}`, { plannedDate: date }],
    [UserEventType.ACTION]: [`/actions/${id}`, { plannedEndDate: date }],
    [UserEventType.DRILL]: [`/drills/${id}`, { executionDate: date }],
  };
  const [error, res] = await tryCatch(HSApi.patch<EventPayload>(eventTypePathPair[eventType][0], eventTypePathPair[eventType][1]));

  return handleResult(res, error);
}

export async function deleteById(id: string) {
  const [error, res] = await tryCatch(HSApi.delete<EventPayload>(`/user-events/${id}`));

  return handleResult(res, error);
}
