import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { NotificationPayload } from './notification.payload';

export async function savePrescriptionsNotification(body: NotificationPayload) {
  const [err, res] = await tryCatch(HSApi.post(`/prescriptions/detail-view/notification`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveOpinionNotification(body: NotificationPayload) {
  const [err, res] = await tryCatch(HSApi.post(`/examinations/opinion/view/notification`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveRestReportNotification(body: NotificationPayload) {
  const [err, res] = await tryCatch(HSApi.post(`/rest-reports/detail-view/notification`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveReferralsNotification(body: NotificationPayload) {
  const [err, res] = await tryCatch(HSApi.post(`/referrals/detail-view/notification`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
