import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetWorkEquipmentDefinitionResult } from './get-work-equipment-definition.result';

export async function getWorkEquipmentDefinition(): Promise<GetWorkEquipmentDefinitionResult[]> {
  const [error, res] = await tryCatch(
    HSApi.post<GetWorkEquipmentDefinitionResult[]>(`/work-equipment-definitions/views?page=0&size=100000`, {})
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data.content;
}
