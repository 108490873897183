import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext, useState } from 'react';

import { WorkEquipmentJobStatusView } from 'api/client';
import { WorkEquipmentJobStatusControllerService } from 'api/client';
import { saveJobStatusFileImported } from 'api/hs/work-equipment/work-equipment-job-status/work-equipment-job-status';
import Button from 'Common/Button';
import Dialog from 'Common/Dialog';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';
import { downlaodFile, openFile, useAsyncEffect, useXhr } from 'utils';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentJobStatusView;
}

export default function FilesAddForm({ open, onClose, data }: Props) {
  const { openFileDialog } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);

  const fetch = useCallback(async () => {
    const res = await WorkEquipmentJobStatusControllerService.getStatusControlFile(data?.jobStatusId);
    setFiles(res);
    setLoading(false);
  }, [setLoading, setFiles, data]);

  useAsyncEffect(async () => {
    if (!data) return;
    await fetch();
  }, [data]);

  const uploadFiles = useXhr(
    async (filesData: File[]) => {
      await Promise.all(filesData.map(file => saveJobStatusFileImported(data?.jobStatusId, file)));
      await fetch();
    },
    'Dosyalar yüklendi',
    err => err,
    []
  );

  const uploadFile = useCallback(async () => {
    const filesValue = await openFileDialog({ title: 'Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!filesValue) {
      return;
    }
    await uploadFiles(filesValue);
  }, [openFileDialog, uploadFiles]);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await WorkEquipmentJobStatusControllerService.deleteStatusControlFile(id);
      await fetch();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    []
  );

  return (
    <>
      <Dialog
        width="sm"
        color="secondary"
        opened={open}
        onClose={() => {
          onClose(true);
        }}
        title="Dosya İşlemleri">
        <header className="text-right mr-1">
          <Button startIcon={<AddIcon />} color="primary" onClick={uploadFile} className="ml-1">
            Dosya Ekle
          </Button>
        </header>
        <IndicatorWrapper loading={loading} data={files} errorMessage="Dosya Bulunamadı...">
          <List key="workEquipmentJobStatusFiles">
            {files?.map((item, index) => (
              <Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Icon style={{ color: 'gray' }} fontSize="default" className="icon-file-account" />
                  </ListItemAvatar>
                  <ListItemText primary={item?.originalFileName} />
                  <ListItemSecondaryAction>
                    {item?.type === 'image/jpeg' || item?.type === 'image/png' ? (
                      <IconButton
                        onClick={() => openFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                        edge="end"
                        aria-label="delete">
                        <VisibilityIcon />
                      </IconButton>
                    ) : null}
                    <IconButton
                      onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                      edge="end"
                      aria-label="download">
                      <GetAppIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteFileReq(item?.id)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </IndicatorWrapper>
      </Dialog>
    </>
  );
}
