import { Paper } from '@material-ui/core';
import React from 'react';

import Referral from './Referrals';

export default function ReferralPage() {
  return (
    <div className="p-2">
      <Paper className="p-2" elevation={3}>
        <Referral />
      </Paper>
    </div>
  );
}
