import { Box, Grid } from '@material-ui/core';
import React from 'react';

import EmployeeSearch from '../Common/EmployeeSearch';
import Calendar from './Calendar';
import Todo from './Todo';
import Widget from './Widget';

export default function Dashboard() {
  return (
    <Box className="p-2">
      <Widget />

      <Grid container style={{ marginTop: 35 }}>
        <Grid item xs={12} lg={8}>
          <Calendar />
        </Grid>
        <Grid item xs={12} lg={4} className="pl-lg-4 pt-md-0 pt-xs-2 pt-sm-2">
          <EmployeeSearch />
          <Todo />
        </Grid>
      </Grid>
    </Box>
  );
}
