/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RiskAssessmentDto } from '../models/RiskAssessmentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RiskAssessmentControllerService {

    /**
     * @param id
     * @returns RiskAssessmentDto OK
     * @throws ApiError
     */
    public static getRiskAssessmentById(
        id: string,
    ): CancelablePromise<RiskAssessmentDto> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentDto OK
     * @throws ApiError
     */
    public static updateRiskAssessment(
        id: string,
        requestBody: RiskAssessmentDto,
    ): CancelablePromise<RiskAssessmentDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllRiskAssessment(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns RiskAssessmentDto OK
     * @throws ApiError
     */
    public static saveRiskAssessment(
        requestBody: RiskAssessmentDto,
    ): CancelablePromise<RiskAssessmentDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}