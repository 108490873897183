import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { arrayize, limit, remove } from '@thalesrc/js-utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from './Button';

export interface FileUploadDialogProps {
  opened: boolean;
  onClose(files: File[] | false): void;
  title?: string;
  cancelText?: string;
  acceptText?: string;
  maxCount?: number;
  accept?: '*' | string[];
  dialogProps?: Partial<DialogProps>;
}

const useStyles = makeStyles(theme => ({
  actionButton: {
    minWidth: 'max-content',
  },
  actionSpacer: {
    width: '100%',
  },
  dropContainer: {
    position: 'relative',
    borderWidth: 4,
    borderStyle: 'dashed',
    borderColor: 'transparent',
    transition: 'border-color .3s ease-in-out',
  },
  dropContainerDragging: {
    borderColor: theme.palette.grey[400],
  },
  dropHere: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, .9)',

    '& > span': {
      color: theme.palette.grey[600],
    },
  },
}));

function overListener(e: DragEvent) {
  e.stopPropagation();
  e.preventDefault();
}

export default function FileUploadDialog({
  opened,
  onClose,
  title = 'Dosya Yükle',
  cancelText = 'İptal',
  acceptText = 'Yükle',
  maxCount = 1,
  accept = '*',
  dialogProps = {},
}: FileUploadDialogProps) {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [dragging, setDragging] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const [previews, setPreviews] = useState(new WeakMap<File, string>());

  const cancel = useCallback(() => onClose(false), [onClose]);
  const submit = useCallback(() => onClose(files), [onClose, files]);

  const openSelection = useCallback(() => {
    inputRef.current.click();

    inputRef.current.addEventListener(
      'change',
      () => {
        if (!inputRef.current.files.length) {
          return;
        }

        setFiles(limit([...files, ...inputRef.current.files], maxCount));

        inputRef.current.value = '';
      },
      { once: true }
    );
  }, [maxCount, files]);

  const removeFile = useCallback(
    (file: File) => {
      setFiles(remove([...files], file));
    },
    [files]
  );

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (dragging) {
        return;
      }

      setDragging(true);

      function dropListener(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();

        setDragging(false);
        removeListeners();
      }

      function leaveListener({ clientX, clientY }: DragEvent) {
        if (clientX || clientY) {
          return;
        }

        setDragging(false);
        removeListeners();
      }

      window.addEventListener('dragleave', leaveListener);
      window.addEventListener('dragover', overListener);
      window.addEventListener('drop', dropListener);

      function removeListeners() {
        window.removeEventListener('dragleave', leaveListener);
        window.removeEventListener('dragover', overListener);
        window.removeEventListener('drop', dropListener);
      }
    },
    [dragging]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      let allowedFiles = [...event.dataTransfer.files];

      if (accept !== '*') {
        allowedFiles = allowedFiles.filter(file =>
          accept.some(rule => {
            if (rule.startsWith('.')) {
              return file.name.split('.').reverse()[0] === rule.replace('.', '');
            } else if (rule.includes('/*')) {
              const [subRule] = rule.split('*');
              return file.type.startsWith(subRule);
            } else {
              return file.type === rule;
            }
          })
        );
      }

      setFiles(limit([...files, ...allowedFiles], maxCount));
    },
    [files, maxCount, accept]
  );

  const containerClassName = useMemo(
    () => (dragging ? `${classes.dropContainer} ${classes.dropContainerDragging}` : classes.dropContainer),
    [classes, dragging]
  );

  useEffect(() => {
    const imageFiles = files.filter(file => file.type.startsWith('image/'));

    for (const file of imageFiles) {
      if (previews.has(file)) {
        continue;
      }

      previews.set(file, URL.createObjectURL(file));
    }

    setPreviews(new WeakMap(imageFiles.map(file => [file, previews.get(file)])));
  }, [files]); // eslint-disable-line

  useEffect(() => {
    if (opened) {
      setFiles([]);
    }
  }, [opened]);

  return (
    <Dialog open={opened} keepMounted onClose={cancel} fullWidth maxWidth="sm" onDragEnter={handleDragEnter} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="py-1 px-2">
        <input ref={inputRef} type="file" hidden multiple={maxCount > 1} accept={arrayize(accept).join(',')} />
        <div className={containerClassName + ' text-center py-3 px-2'} onDrop={handleDrop}>
          {files.length ? (
            <List className="width-100">
              {[...files].map((file, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    {file.type.startsWith('image') ? <Avatar src={previews.get(file)} alt="" /> : <DescriptionIcon />}
                  </ListItemAvatar>
                  <ListItemText primary={file.name} secondary={file.size + ' bytes'} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => removeFile(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <div className="text-center">
              <Button onClick={openSelection} color="secondary">
                Bilgisayarından Dosya Seç
              </Button>
              <div className="text-gray half-opaque mt-2">ve ya</div>
              <div className="text-gray half-opaque">dosyaları buraya sürükle</div>
            </div>
          )}
          {dragging ? (
            <div className={classes.dropHere}>
              <Typography component="span" variant="h6">
                {files.length >= maxCount ? 'Daha fazla dosya eklenemez!' : 'Dosyaları buraya sürükle'}
              </Typography>
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions className="px-2 pb-2">
        {files.length && files.length < maxCount ? (
          <>
            <Button color="secondary" onClick={openSelection} className={classes.actionButton}>
              Başka Bir Dosya Yükle
            </Button>
            <span className={classes.actionSpacer} />
          </>
        ) : null}
        <Button variant="contained" onClick={cancel} className={classes.actionButton}>
          {cancelText}
        </Button>
        <Button variant="contained" color="primary" onClick={submit} className={classes.actionButton} disabled={files.length < 1}>
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
