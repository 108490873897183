import { Fab, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import Toolbar from 'Common/Toolbar';
import { useDateFormat } from 'utils';

import { TrainingDetailContext } from '../completed.context';

export default function ToolbarData() {
  const { trainingDetail } = useContext(TrainingDetailContext);
  const history = useHistory();

  const format = useDateFormat('dd MMMM yyyy HH:mm');

  return (
    <>
      <Toolbar>
        <Fab onClick={() => history.push(`/training/detail-list`)} size="small" color="primary">
          <ArrowBackIcon />
        </Fab>
        <Typography style={{ marginLeft: '10px' }} variant="h6" color="inherit">
          {trainingDetail?.title} - {trainingDetail?.plannedDate ? format(new Date(trainingDetail?.plannedDate)) : null}
        </Typography>
      </Toolbar>
    </>
  );
}
