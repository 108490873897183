import React from 'react';

import TrainingGroupAdd from './GroupDefinitionAdd';
import TrainingGroupList from './List';

export default function Index() {
  return (
    <>
      <TrainingGroupAdd />
      <TrainingGroupList />
    </>
  );
}
