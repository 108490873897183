import React from 'react';

import ReferralAdd from './Add';
import ReferralsList from './List';

export default function Index() {
  return (
    <>
      <ReferralsList />
      <ReferralAdd />
    </>
  );
}
