import React, { useCallback, useContext } from 'react';

import DiagnosisForm from 'Common/Forms/Examination/DiagnosisForm';

import { DiagnosisContextContext } from '../../diagnosis.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(DiagnosisContextContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <DiagnosisForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
