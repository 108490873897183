import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { excelExport, handleResult } from '../../utils';
import {
  ActionCounts,
  ActionWidgetItem,
  ActionWidgetType,
  DateRange,
  PeriodicExaminationCounts,
  PeriodicExaminationWidgetItem,
  PregnancyCounts,
  PregnancyWidgetItem,
  PregnancyWidgetType,
  ReferraledEmployeeCounts,
  ReferraledEmployeeResult,
  ReportedEmployeeCounts,
  ReportedEmployeeItem,
  VaccineCounts,
  VaccineWidgetItem,
  WidgetType,
  WidgetTypeStatus,
  WidgetVisibility,
  WidgetVisibilityItem,
} from './widget.payload';

export async function getWidgetTypes(status: WidgetTypeStatus) {
  const [error, res] = await tryCatch(HSApi.get<WidgetType[]>(`/widget-types?status=${status}`));
  const resData = handleResult(res, error);

  const Widgets = [
    {
      key: 1,
      value: 'EMPLOYEE',
    },
    {
      key: 5,
      value: 'ACCIDENT',
    },
    {
      key: 6,
      value: 'NEAR_MISS',
    },
    {
      key: 2,
      value: 'PERIODIC_EXAMINATION',
    },
    {
      key: 4,
      value: 'WORK_EQUIPMENT',
    },
    {
      key: 7,
      value: 'TRAINING',
    },
    {
      key: 8,
      value: 'LOST_DAY',
    },
    {
      key: 9,
      value: 'VACCINE',
    },
    {
      key: 10,
      value: 'ACTION',
    },

    {
      key: 11,
      value: 'REPORT_REPORT',
    },
    {
      key: 3,
      value: 'REFERRAL',
    },
    {
      key: 4,
      value: 'PREGNANCY',
    },
  ];

  const orderWidgets = resData
    ?.map(b => {
      return {
        index: Widgets?.find(a => a.value === b)?.key,
        value: b,
      };
    })
    ?.sort((a, b) => a.index - b.index)
    ?.map(a => a.value);

  return orderWidgets;
}

export async function getCurrentWidgetVisibilities() {
  const [error, res] = await tryCatch(HSApi.get<WidgetVisibilityItem[]>(`/widget-invisibilities`));

  return handleResult(res, error);
}

export async function hideWidget(payload: WidgetVisibility) {
  const [error, res] = await tryCatch(HSApi.post<WidgetVisibility[]>(`/widget-invisibilities`, payload));

  return handleResult(res, error);
}

export async function showWidget(id: string) {
  const [error, res] = await tryCatch(HSApi.delete(`/widget-invisibilities/${id}`));

  return handleResult(res, error);
}

export async function getPregnancyCounts() {
  const [error, res] = await tryCatch(HSApi.get<PregnancyCounts>(`/pregnancies/widgets/count`));

  return handleResult(res, error);
}

export async function getActionCounts(dateRange?: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<ActionCounts>(`/actions/widgets/count${dateRange?.toString()}`));

  return handleResult(res, error);
}

export async function getPregnancies(type: PregnancyWidgetType) {
  // backendde 3 servise bolunmus. boylesi daha iyi sanirim :)
  const urls = {
    [PregnancyWidgetType.PREGNANT]: `/pregnancies/widgets/views`,
    [PregnancyWidgetType.BREASTFEEDING]: `/breast-feeding/widgets/views`,
    [PregnancyWidgetType.MATERNITY_LEAVE]: `/maternity-leaves/widgets/views`,
  };

  const [error, res] = await tryCatch(HSApi.get<PregnancyWidgetItem[]>(urls[type]));

  return handleResult(res, error);
}

export async function getActions(type: ActionWidgetType, dateRange?: DateRange) {
  const urls = {
    [ActionWidgetType.ACTION_ACTIVE]: `/actions/widgets/views${dateRange?.toString()}&actionWidgetType=ACTION_ACTIVE`,
    [ActionWidgetType.ACTION_PLANNED_PASSED]: `/actions/widgets/views?actionWidgetType=ACTION_PLANNED_PASSED`,
    [ActionWidgetType.ACTION_COMPLETED]: `/actions/widgets/views?actionWidgetType=ACTION_COMPLETED`,
  };

  const [error, res] = await tryCatch(HSApi.get<ActionWidgetItem[]>(urls[type]));

  return handleResult(res, error);
}

export async function exportPregnancies(type: PregnancyWidgetType, fileName: string) {
  // backendde 3 servise bolunmus. boylesi daha iyi sanirim :)
  const urls = {
    [PregnancyWidgetType.PREGNANT]: `/pregnancies/widgets/views/export`,
    [PregnancyWidgetType.BREASTFEEDING]: `/breast-feeding/widgets/views/export`,
    [PregnancyWidgetType.MATERNITY_LEAVE]: `/maternity-leaves/widgets/views/export`,
  };
  const [error, res] = await tryCatch(HSApi.get(urls[type], { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function exportActions(type: ActionWidgetType, fileName: string, dateRange?: DateRange) {
  const urls = {
    [ActionWidgetType.ACTION_ACTIVE]: `/actions/widgets/views/export?type=EXCEL&${dateRange
      ?.toString()
      ?.replaceAll('?', '')}&actionWidgetType=ACTION_ACTIVE`,
    [ActionWidgetType.ACTION_PLANNED_PASSED]: `/actions/widgets/views/export?type=EXCEL&actionWidgetType=ACTION_PLANNED_PASSED`,
    [ActionWidgetType.ACTION_COMPLETED]: `/actions/widgets/views/export?type=EXCEL&actionWidgetType=ACTION_COMPLETED`,
  };
  const [error, res] = await tryCatch(HSApi.get(urls[type], { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function getVaccineCounts(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<VaccineCounts>(`/vaccines/widgets/count${dateRange.toString()}`));
  return handleResult(res, error);
}

export async function getVaccines(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<VaccineWidgetItem[]>(`/vaccines/widgets/views${dateRange.toString()}`));

  return handleResult(res, error);
}

export async function exportVaccines(dateRange: DateRange, fileName: string) {
  const [error, res] = await tryCatch(HSApi.get(`/vaccines/widgets/views/export?${dateRange.toString()}`, { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function exportEmployeeWidget(dateRange: DateRange, employeeWidgetType: string, fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/employee/widgets/views/export?${dateRange?.toString()?.replaceAll('?', '')}&employeeWidgetType=${employeeWidgetType}`, {
      responseType: 'blob',
    })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function getPeriodicExaminationCounts(dateRange: DateRange) {
  const [error, res] = await tryCatch(
    HSApi.get<PeriodicExaminationCounts>(`/periodic-examination-configurations/widgets/count${dateRange.toString()}`)
  );
  return handleResult(res, error);
}

export async function getPeriodicExaminations(dateRange: DateRange) {
  const [error, res] = await tryCatch(
    HSApi.get<PeriodicExaminationWidgetItem[]>(`/periodic-examination-configurations/widgets/views${dateRange.toString()}`)
  );

  return handleResult(res, error);
}

export async function exportPeriodicExaminations(dateRange: DateRange, fileName: string): Promise<any> {
  const [error, res] = await tryCatch(
    HSApi.get(`/periodic-examination-configurations/widgets/views/export${dateRange.toString()}`, { responseType: 'blob' })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function exportEmployeePeriodicExamination(fileName: string): Promise<any> {
  const [error, res] = await tryCatch(HSApi.get(`/employee/periodic-examination/widgets/views/export`, { responseType: 'blob' }));
  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function getReportedEmployeeCounts(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<ReportedEmployeeCounts>(`/rest-reports/widgets/count${dateRange.toString()}`));
  return handleResult(res, error);
}

export async function getReportedEmployee(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<ReportedEmployeeItem[]>(`/rest-reports/widgets/views${dateRange.toString()}`));

  return handleResult(res, error);
}

export async function getBackReportedEmployee() {
  const [error, res] = await tryCatch(HSApi.get<ReportedEmployeeItem[]>(`/rest-reports/widgets/views`));

  return handleResult(res, error);
}

export async function exportReportedEmployee(dateRange: DateRange, fileName: string) {
  const [error, res] = await tryCatch(HSApi.get(`/rest-reports/widgets/views/export${dateRange.toString()}`, { responseType: 'blob' }));

  const data = handleResult(res, error);
  excelExport(data, fileName);
}

export async function getReferraledEmployeeCounts(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<ReferraledEmployeeCounts>(`/referrals/widgets/count${dateRange.toString()}`));
  return handleResult(res, error);
}

export async function getReferraledEmployee(dateRange: DateRange) {
  const [error, res] = await tryCatch(HSApi.get<ReferraledEmployeeResult>(`/referrals/widgets/views${dateRange.toString()}`));

  return handleResult(res, error);
}

export async function exportReferraledEmployee(dateRange: DateRange, fileName: string) {
  const [error, res] = await tryCatch(
    HSApi.get(`/rest-reports/widgets/views${dateRange.toString()}`, {
      responseType: 'blob',
    })
  );

  const data = handleResult(res, error);
  excelExport(data, fileName);
}
