import { Grid } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { AccidentReviewControllerService, AccidentReviewDefinitionControllerService } from '../../../api/client';
import TextField from '../../../Common/Form/TextField';
import { AlertContext } from '../../../context/alert.context';
import { useAsyncEffect, useFetch, useTriggerRender } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import NewRecordButton from '../NewRecordButton';
import Options from './Options';

export default () => {
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const { token, insertable, accidentId } = useContext(AccidentReviewContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: improvementTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getImprovementTypes(), []);
  const { data: improvement, fetch: fetchImprovements } = useFetch(
    () => AccidentReviewControllerService.getImprovements(accidentId, null, token),
    { improvements: [] },
    { setLoading }
  );

  useAsyncEffect(async () => {
    if (improvement?.improvements?.length > 0) {
      improvement.improvements.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`improvement[${item.id}]`, true);
        }

        if (item.other) {
          formRef.current?.methods?.setValue(`improvement_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`improvement_other`, item.other), 250);
        }
      });
      reRender();
    }
  }, [improvement]).then(noop);

  const submit = (values: any) => {
    setLoading(true);

    const selectedItems = Object.keys(values?.improvement || {})
      .filter(id => values.improvement[id])
      .map(id => {
        return { id };
      });

    if (values.improvement_other?.length > 0) {
      // @ts-ignore
      selectedItems.push({ other: values.improvement_other });
    }

    const improvementRequest = {
      note: values.rootCause,
      improvements: selectedItems,
    };

    AccidentReviewControllerService.saveImprovements(accidentId, null, improvementRequest, token)
      .then(() => {
        showMessage('Kayıt işlemi başarılı.', 'success', 'info');
        fetchImprovements().then(noop);
      })
      .catch(() => {
        showMessage('Bir hata oluştu.', 'error', 'info');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form onChange={reRender} onSubmit={submit} ref={formRef}>
      <Grid item xs={12}>
        <TextField name="rootCause" rows={5} label="Kök Neden" defaultValue={improvement?.note} />
      </Grid>
      {improvement?.note?.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Options
              name="improvement"
              title={'İyileştirilmesi Gereken Yönetim Fonksiyonları'}
              formRef={formRef}
              items={improvementTypes}
            />
          </Grid>
        </Grid>
      )}
      {insertable && <NewRecordButton onClick={noop} label="Kaydet" type="submit" loading={loading} />}
    </Form>
  );
};
