import React, { useCallback, useContext, useState } from 'react';

import { getEmployee } from 'api/hs/employee';
import EditEmployeeDialog from 'Common/EditEmployeeDialog';
import { useAsyncEffect } from 'utils';

import { EmployeeListContext } from '../../employee-list.context';

export default function EditDialog() {
  const { editEmployeeDialogOpened, setEditEmployeeDialogOpened, selectedEmployee, reload } = useContext(EmployeeListContext);
  const [employeeDetails, setEmployeeDetails] = useState(null);

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }

      setEditEmployeeDialogOpened(false);
    },
    [reload, setEditEmployeeDialogOpened]
  );

  useAsyncEffect(async () => {
    if (!selectedEmployee) {
      setEmployeeDetails(null);
    } else {
      setEmployeeDetails(await getEmployee(selectedEmployee.id));
    }
  }, [selectedEmployee]);

  return <EditEmployeeDialog open={editEmployeeDialogOpened} onClose={closeDialog} employee={employeeDetails} />;
}
