/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationRequest } from '../models/NotificationRequest';
import type { PagePrescriptionDto } from '../models/PagePrescriptionDto';
import type { PrescriptionDto } from '../models/PrescriptionDto';
import type { PrescriptionRequest } from '../models/PrescriptionRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PrescriptionControllerService {

    /**
     * @param id
     * @returns PrescriptionDto OK
     * @throws ApiError
     */
    public static getPrescriptionById(
        id: string,
    ): CancelablePromise<PrescriptionDto> {
        return __request({
            method: 'GET',
            path: `/prescriptions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PrescriptionDto OK
     * @throws ApiError
     */
    public static updatePrescription(
        id: string,
        requestBody: PrescriptionDto,
    ): CancelablePromise<PrescriptionDto> {
        return __request({
            method: 'PUT',
            path: `/prescriptions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllPrescription(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/prescriptions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PrescriptionDto OK
     * @throws ApiError
     */
    public static partialUpdatePrescription(
        id: string,
        requestBody: PrescriptionDto,
    ): CancelablePromise<PrescriptionDto> {
        return __request({
            method: 'PATCH',
            path: `/prescriptions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param employeeId
     * @param examinationId
     * @returns PrescriptionDto OK
     * @throws ApiError
     */
    public static getAllPrescriptions(
        employeeId: string,
        examinationId?: string,
    ): CancelablePromise<Array<PrescriptionDto>> {
        return __request({
            method: 'GET',
            path: `/prescriptions`,
            query: {
                'employeeId': employeeId,
                'examinationId': examinationId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns PrescriptionDto OK
     * @throws ApiError
     */
    public static savePrescription(
        requestBody: PrescriptionDto,
    ): CancelablePromise<PrescriptionDto> {
        return __request({
            method: 'POST',
            path: `/prescriptions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PagePrescriptionDto OK
     * @throws ApiError
     */
    public static getPrescriptionListFiltered(
        requestBody: PrescriptionRequest,
    ): CancelablePromise<PagePrescriptionDto> {
        return __request({
            method: 'POST',
            path: `/prescriptions/pages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @param emailList
     * @returns any OK
     * @throws ApiError
     */
    public static prescriptionSendMail(
        requestBody: NotificationRequest,
        emailList?: Array<string>,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/prescriptions/detail-view/notification`,
            query: {
                'emailList': emailList,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static prescriptionExport(
        id: string,
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/prescriptions/${id}/detail-view/export`,
            query: {
                'type': type,
            },
        });
    }

}