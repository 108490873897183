import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { AnalysisResult, deleteAdminAnalysis, getAdminAnalysis } from 'api/hs/definitions/health-definitions/analysis';
import AdminAnalysisEditDialog from 'Common/Forms/Definitions/HealthDefinition/Analysis/AdminAnalysisEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Analysis() {
  return (
    <BaseDefinitionPage<AnalysisResult>
      listLabelPropName="label"
      fetchData={getAdminAnalysis}
      FormDialog={AdminAnalysisEditDialog}
      onDelete={deleteAdminAnalysis}
      Icon={<DomainIcon />}
    />
  );
}
