import { Grid } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { AccidentReviewControllerService, AccidentReviewDefinitionControllerService } from '../../../api/client';
import TextField from '../../../Common/Form/TextField';
import { AlertContext } from '../../../context/alert.context';
import { useAsyncEffect, useFetch, useTriggerRender } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import NewRecordButton from '../NewRecordButton';
import Options from './Options';

export default () => {
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const { token, insertable, accidentId } = useContext(AccidentReviewContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: personalTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getPersonalTypes(), []);
  const { data: organizationTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getOrganizationTypes(), []);
  const { data: equipmentTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getEquipmentTypes(), []);
  const { data: materialTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getMaterialTypes(), []);

  const { data: facilitatingFactors, fetch: fetchFacilitatingFactors } = useFetch(
    () => AccidentReviewControllerService.getFacilitatingFactors(accidentId, null, token),
    { equipments: [], materials: [], personal: [], organizations: [] },
    { setLoading }
  );

  useAsyncEffect(async () => {
    if (facilitatingFactors?.personal?.length > 0) {
      facilitatingFactors.personal.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`personal[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`personal_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`personal_other`, item.other), 250);
        }
      });
      reRender();
    }

    if (facilitatingFactors?.organizations?.length > 0) {
      facilitatingFactors.organizations.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`organization[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`organization_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`organization_other`, item.other), 250);
        }
      });
      reRender();
    }

    if (facilitatingFactors?.equipments?.length > 0) {
      facilitatingFactors.equipments.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`equipment[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`equipment_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`equipment_other`, item.other), 250);
        }
      });
      reRender();
    }

    if (facilitatingFactors?.materials?.length > 0) {
      facilitatingFactors.materials.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`material[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`material_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`material_other`, item.other), 250);
        }
      });
      reRender();
    }
  }, [facilitatingFactors]).then(noop);

  const submit = (values: any) => {
    setLoading(true);

    const personals = Object.keys(values?.personal || {})
      .filter(id => values.personal[id])
      .map(id => {
        return { id };
      });

    const equipments = Object.keys(values?.equipment || {})
      .filter(id => values.equipment[id])
      .map(id => {
        return { id };
      });

    const organizations = Object.keys(values?.organization || {})
      .filter(id => values.organization[id])
      .map(id => {
        return { id };
      });

    const materials = Object.keys(values?.material || {})
      .filter(id => values.material[id])
      .map(id => {
        return { id };
      });

    if (values.personal_other?.length > 0) {
      // @ts-ignore
      personals.push({ other: values.personal_other });
    }

    if (values.equipment_other?.length > 0) {
      // @ts-ignore
      equipments.push({ other: values.equipment_other });
    }

    if (values.organization_other?.length > 0) {
      // @ts-ignore
      organizations.push({ other: values.organization_other });
    }

    if (values.material_other?.length > 0) {
      // @ts-ignore
      materials.push({ other: values.material_other });
    }

    const request = {
      note: values.facilitatingFactor,
      personals,
      organizations,
      equipments,
      materials,
    };

    AccidentReviewControllerService.saveFacilitatingFactors(accidentId, null, request, token)
      .then(() => {
        showMessage('Kayıt işlemi başarılı.', 'success', 'info');
        fetchFacilitatingFactors().then(noop);
      })
      .catch(() => {
        showMessage('Bir hata oluştu.', 'error', 'info');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form onChange={reRender} onSubmit={submit} ref={formRef}>
      <Grid item xs={12}>
        <TextField name="facilitatingFactor" rows={5} label="Kolaylaştıran Faktör" defaultValue={facilitatingFactors?.note} />
      </Grid>
      {facilitatingFactors?.note?.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Options title={'Kişisel'} name="personal" formRef={formRef} items={personalTypes} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Options title={'Organizasyon/Sistem/Metot'} name="organization" formRef={formRef} items={organizationTypes} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Options title={'Tesis/Makine/Ekipman'} name="equipment" formRef={formRef} items={equipmentTypes} />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Options title={'Malzeme'} name="material" formRef={formRef} items={materialTypes} />
          </Grid>
        </Grid>
      )}
      {insertable && <NewRecordButton onClick={noop} label="Kaydet" type="submit" loading={loading} />}
    </Form>
  );
};
