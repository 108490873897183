import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { RestReportPayload } from './RestReport.payload';
import { RestReportResult } from './RestReport.result';

export async function getRestReport(): Promise<RestReportResult[]> {
  const [error, res] = await tryCatch(HSApi.get<RestReportResult[]>(`/firm/rest-report-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveRestReport(body: RestReportPayload): Promise<RestReportResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/rest-report-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateRestReport({ id, ...body }: MakeRequired<Partial<RestReportResult>, 'id'>): Promise<RestReportResult> {
  const [error, res] = await tryCatch(HSApi.put<RestReportResult>(`/firms/rest-report-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteRestReport(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/rest-report-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminRestReport(): Promise<RestReportResult[]> {
  const [error, res] = await tryCatch(HSApi.get<RestReportResult[]>(`/rest-report-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminRestReport(body: RestReportPayload): Promise<RestReportResult> {
  const [err, res] = await tryCatch(HSApi.post('/rest-report-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminRestReport({ id, ...body }: MakeRequired<Partial<RestReportResult>, 'id'>): Promise<RestReportResult> {
  const [error, res] = await tryCatch(HSApi.put<RestReportResult>(`/rest-report-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminRestReport(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/rest-report-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
