import React, { useCallback } from 'react';

import { getCommitteeMeetingsList } from 'api/hs/committee/committee-meetings';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { COLUMNS } from './columns';
import EditDialog from './EditDialog';
import ListTable from './ListTableData';
import Toolbar from './Toolbar';

export default function CommitteeMeetingsList() {
  const fetchCommitteeMeetingList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getCommitteeMeetingsList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <>
      <HSTableAndPaginationAndTableContextProvider fetcher={fetchCommitteeMeetingList} columns={COLUMNS}>
        <Toolbar />
        <ListTable />
        <EditDialog />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
