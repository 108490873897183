/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PolyclinicExamOpinionView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    examinationId?: string;
    employeeId?: string;
    polyclinicExamOpinionDefinition?: PolyclinicExamOpinionView.polyclinicExamOpinionDefinition;
    polyclinicExamOpinionDefinitionId?: number;
}

export namespace PolyclinicExamOpinionView {

    export enum polyclinicExamOpinionDefinition {
        REFERRED = 'REFERRED',
        WORKING = 'WORKING',
        REST_REPORTED = 'REST_REPORTED',
    }


}
