import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { IbysWorkTypesModel } from './ibys-work-types.model';
import { IbysWorkTypesResult } from './ibys-work-types.result';

export async function updateWorkTypes(id: string, body: IbysWorkTypesModel[]): Promise<IbysWorkTypesResult> {
  const [error, res] = await tryCatch(HSApi.put<IbysWorkTypesResult>(`/employee/${id}/work-types`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getWorkTypes(employeeId: string): Promise<IbysWorkTypesResult[]> {
  let res;
  try {
    res = await HSApi.get<IbysWorkTypesResult[]>(`/employee/${employeeId}/work-types`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteWorkTypes(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/employee/${id}/work-types`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
