import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort } from '../../../../utils';
import { DateRange } from '../../dashboard/widget/widget.payload';
import { handleResult } from '../../utils';
import { SaveRestReportPayload, UpdateRestReportPayload } from './rest-reports.payload';
import { GetRestReportsResult, RestReportResultItem } from './rest-reports.result';

export async function getRestReports(employeeId: string, examinationId?: string): Promise<GetRestReportsResult> {
  // tslint:disable-next-line:no-shadowed-variable
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<GetRestReportsResult>(`/rest-reports?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<GetRestReportsResult>(`/rest-reports?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return await asyncMap(dateSort(res.data), async item => ({
    ...item,
    filesRestReport: await getRestReportFile(item.id),
  }));
}

export async function saveRestReport(body: SaveRestReportPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/rest-reports', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateRestReport(id: RestReportResultItem['id'], body: UpdateRestReportPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.put(`/rest-reports/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteRestReport(id: RestReportResultItem['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/rest-reports/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function uploadRestReportFile(id: RestReportResultItem['id'], file: File) {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/rest-reports/${id}/imported-files`, data));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getRestReportFile(restReportId: string) {
  const [err, res] = await tryCatch(HSApi.get(`/rest-reports/${restReportId}/imported-files`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function previewReport(id: string) {
  const [err, res] = await tryCatch(HSApi.get(`/rest-reports/${id}/detail-view/export?type=HTML`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}

export async function getRestReportWidgetCounts(dateRange?: DateRange) {
  const [error, res] = await tryCatch(HSApi.get(`/rest-reports/widgets/lost-day/count${dateRange?.toString()}`));

  return handleResult(res, error);
}
