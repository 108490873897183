import React from 'react';

import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { COLUMNS } from './columns';
import EditDialog from './EditDialog';
import ListTable from './ListTableData';
interface Props {
  fetcher: () => Promise<any>;
  baseFetcher: () => Promise<any>;
  referenceId?: string;
  nearMissId?: string;
  actionReferenceValue?: string;
}
export default function CustomAction({ fetcher, referenceId, nearMissId, actionReferenceValue, baseFetcher }: Props) {
  return (
    <>
      <HSTableAndPaginationAndTableContextProvider fetcher={fetcher} columns={COLUMNS}>
        <EditDialog
          baseFetcher={baseFetcher}
          referenceId={referenceId}
          nearMissId={nearMissId}
          actionReferenceValue={actionReferenceValue}
        />
        <ListTable />
      </HSTableAndPaginationAndTableContextProvider>
    </>
  );
}
