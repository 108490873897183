import { Link } from '@material-ui/core';
import { useContext } from 'react';

import { checkESignToken, E_SIGN_TOKEN_KEY } from '../api/hs/examination';
import { AlertContext } from '../context/alert.context';
import { useAsyncEffect } from '../utils';

export default function ESignatureNotification() {
  const { showMessage } = useContext(AlertContext);

  useAsyncEffect(async () => {
      showMessage(
          <>
              <div>Artımetrik e-imza D-Signer uygulamanızın kurulu ve açık olduğuna emin olun.</div>
              <div>
                  <Link href="https://www.dsigner.net/" color="inherit" target="_blank" rel="noopener noreferrer">
                      D-Signer uygulaması kurulu değilse kurulum rehberi için tıklayın.
                  </Link>
              </div>
          </>,
          'info',
          'token',
          null
      );
    /**const result = await checkESignToken();
    if (result === E_SIGN_TOKEN_KEY.checked) {
      showMessage('E-imza takılı.', 'success', 'token');
    } else if (result === E_SIGN_TOKEN_KEY.unchecked) {
      showMessage('E-imza takılı deǧil.', 'error', 'token');
    } else {
      showMessage(
        <>
          <div>Artımetrik e-imza uygulaması kurulu değil.</div>
          <div>
            <Link href="/saglik-gozetimi/public/download/artimetrik_desktop_installer.rar" color="inherit">
              Java-8 ve java 9 versiyonları için tıklayınız.
            </Link>
          </div>
          <div>
            <Link href="/saglik-gozetimi/public/download/artimetrik_desktop_installer-j11.rar" color="inherit">
              Java 11 ve üstü versiyonlar için tıklayınız.
            </Link>
          </div>
        </>,
        'info',
        'token',
        null
      );
    }**/
  }, []);
}
