import { makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeView } from '@material-ui/lab';
import React, { useMemo } from 'react';

import Checkbox from '../Checkbox';
import { TreeBoxController } from './controller.model';
import { getNames } from './get-names';
import Item from './Item';

export interface TreeBoxProps {
  controls: TreeBoxController[];
}

const useStyles = makeStyles(() => ({
  checkbox: {
    display: 'none',
  },
}));

export default function TreeBox({ controls }: TreeBoxProps) {
  const classes = useStyles();
  const names = useMemo(() => getNames(controls), [controls]);

  return (
    <>
      <TreeView disableSelection defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {controls.map((control, index) => (
          <Item key={index} control={control} />
        ))}
      </TreeView>
      {names.map(name => (
        <Checkbox key={name} name={name} label={''} className={classes.checkbox} />
      ))}
    </>
  );
}
