import React from 'react';

import RiskAssessmentList from './List';
export default function Index() {
  return (
    <>
      <RiskAssessmentList />
    </>
  );
}
