// prettier-ignore
import { Divider, Grid, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import React, { useState } from 'react';

import SupportDrawer from './SupportDrawer';

export default function Header() {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Divider orientation="vertical" flexItem />
        <IconButton color="inherit" className="px-2" onClick={() => setDrawerOpened(true)}>
          <HelpIcon />
        </IconButton>
      </Grid>
      <SupportDrawer opened={drawerOpened} onClose={() => setDrawerOpened(false)} />
    </>
  );
}
