import { difference, noop } from '@thalesrc/js-utils';
import React, {
  BaseSyntheticEvent,
  forwardRef,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';

import { usePrevious, useUniqueId } from 'utils';

import { FormContext, FormContextType } from './form.context';

export interface FormRef<T extends Record<string, any> = {}> {
  methods: UseFormMethods<T>;
  id: string;
  value: T;
  valid: boolean;
  submit(): void;
}

interface Props<T extends Record<string, any> = {}> {
  onSubmit?(value: any, event: BaseSyntheticEvent): any;
  onChange?(value: T): any;
  readonly?: boolean;
  className?: string;
}

function HSForm<T extends Record<string, any> = {}>(
  { children, onSubmit = noop, onChange = noop, readonly = false, className = '' }: PropsWithChildren<Props<T>>,
  ref: Ref<FormRef<T>>
) {
  const methods = useForm<T, T>();
  const id = useUniqueId('form');
  const fieldsRef = useRef<Record<string, { disabled: boolean }>>({});
  const formElementRef = useRef<HTMLFormElement>();

  const currentValue = useMemo(() => methods.getValues() ?? ({} as T), [methods]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const previousValue = usePrevious(currentValue) ?? ({} as T);

  const register = useCallback<FormContextType['register']>(
    (name, state) => {
      fieldsRef.current[name] = state;
      onChange(currentValue as T);
    },
    [onChange, currentValue]
  );

  const unregister = useCallback<FormContextType['unregister']>(name => {
    delete fieldsRef.current[name];
  }, []);

  const disabledFields = Object.entries(fieldsRef.current)
    .filter(([, { disabled }]) => disabled)
    .map(([key]) => key);
  const valid = !Object.keys(methods.errors).filter(key => !disabledFields.includes(key)).length;
  const previousValid = usePrevious(valid);

  const submit = useCallback(() => {
    formElementRef.current?.dispatchEvent(new Event('submit'));
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      methods,
      id,
      value: currentValue as T,
      valid,
      submit,
    }),
    [methods, id, currentValue, valid, submit]
  );

  const context = useMemo<FormContextType>(() => ({ readonly, register, unregister }), [readonly, register, unregister]);

  useEffect(() => {
    if (valid !== previousValid) {
      onChange(currentValue as T);

      return;
    }

    const areDifferent =
      !!difference(Object.keys(currentValue), Object.keys(previousValue)).length ||
      Object.entries(currentValue).some(([key, value]) =>
        value instanceof Array
          ? !(previousValue[key] instanceof Array) ||
            !!difference(value, previousValue[key]).length ||
            !!difference(previousValue[key], value).length
          : previousValue[key] !== value
      );

    if (areDifferent) {
      onChange(currentValue as T);
    }
  }, [currentValue, previousValue, onChange, valid, previousValid]);

  return (
    <FormContext.Provider value={context}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} id={id} className={className} ref={formElementRef}>
          {children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
}

export default forwardRef<FormRef<any>, PropsWithChildren<Props<any>>>(HSForm);
