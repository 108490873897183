import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { findByKey } from '@thalesrc/js-utils';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import { AnalysisAggregate, AnalysisAudiometryCategoriesModel, AudiometryAudioCategoriesResult } from 'api/hs/examination/analysis';
import AlertInformation from 'Common/AlertInformation';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { EnumContext, EnumType } from 'context/enum.context';
import { dateDisplayFormat, makeSlug } from 'utils';

import { AnalysisContext, AnalysisContextType } from '../../analysis.context';
import { Category } from '../category.enum';
import GraphDialog from '../graphs/graphicDialog';
import Action from './Action';
import EditDialog from './EditDialog';
import AnalysisFiles from './FileList';
import ParameterList from './ParameterList';

interface GroupType {
  [category: string]: AnalysisAggregate[];
}

interface CategoryLabelsProps {
  categories: AnalysisAudiometryCategoriesModel[];
  id: string;
  openCategoryDialog(item: AudiometryAudioCategoriesResult): void;
}

const useStyles = makeStyles(theme => ({
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  col: {
    color: 'inherit',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  header: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  fullWidth: {
    width: '100%',
  },
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  noBorder: {
    border: 'none',
  },
  arccordionDetailsRoot: {
    display: 'block',
  },
}));

const renderCategoryLabels = ({ categories, id, openCategoryDialog }: CategoryLabelsProps) => {
  if (!categories || categories.length === 0) return null;

  return (
    <Grid container className="mt-2">
      {categories.map((category: AnalysisAudiometryCategoriesModel, i) => (
        <Grid item key={`${id}-category-${i}`}>
          <Button className="mt-1 mr-2" color="inherit" variant="contained" onClick={() => openCategoryDialog(category)}>
            {category.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

const getAnalysisGroupByCategory = (analysis: AnalysisAggregate[]): GroupType => {
  const groupData: GroupType = {};

  analysis.forEach(item => {
    const category = `categoryNo${item.categoryDefinitionId}`;
    if (!groupData[category]) {
      groupData[category] = [];
      groupData[category].push(item);
    } else {
      groupData[category].push(item);
    }
  });

  return groupData;
};

export default function AnalysisList() {
  const { loading, analysis, setGraphDialogOpened, setSelectedGraphData, selectedGraphData } = useContext<AnalysisContextType>(
    AnalysisContext
  );
  const { analysisCategoryDefinitions } = useContext(EnumContext);
  const [openedCategoryDialog, setOpenedCategoryDialog] = useState<boolean>(false);
  const [selectedAudioLabelData, setSelectedAudioLabelData] = useState<AudiometryAudioCategoriesResult>(null);
  const [analysisByGroup, setAnalysisByGroup] = useState<GroupType>(null);
  const [activeCategory, setActiveCategory] = useState<EnumType>(null);

  const classes = useStyles();
  const openCategoryDialog = (item: AudiometryAudioCategoriesResult) => {
    setOpenedCategoryDialog(true);
    setSelectedAudioLabelData(item);
  };

  const openGraphDialog = (category: EnumType) => {
    setGraphDialogOpened(true);
    setActiveCategory(category);
  };

  const onClose = () => {
    setSelectedAudioLabelData(null);
    setOpenedCategoryDialog(false);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, item: AnalysisAggregate) => {
    if (e.target.checked) {
      if (selectedGraphData) {
        setSelectedGraphData([...selectedGraphData, item]);
      } else {
        setSelectedGraphData([item]);
      }
    } else {
      const copySelectedMultiData = [...selectedGraphData];
      copySelectedMultiData?.splice(selectedGraphData?.map(d => d.id).indexOf(item.id), 1);
      setSelectedGraphData(copySelectedMultiData);
    }
  };

  useEffect(() => {
    setAnalysisByGroup(getAnalysisGroupByCategory(analysis));
  }, [analysis, activeCategory, analysisCategoryDefinitions]);

  return (
    <>
      <IndicatorWrapper loading={loading} data={analysis} errorMessage="Gösterilecek Kayıt Bulunamadı">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.sideGrid}>
            <List>
              {analysisByGroup && Object.keys(analysisByGroup).length > 0 ? (
                <Fragment>
                  <TableContainer style={{ marginBottom: '10px', overflowY: 'hidden' }}>
                    <Table size="small">
                      <TableBody>
                        {Object.entries(analysisByGroup).map(([key, value]) => {
                          const category = analysisCategoryDefinitions.find(def => def.id.toString() === key.split('categoryNo')[1]);
                          return (
                            <TableRow key={`analysis-${key}`}>
                              <TableCell classes={{ root: classes.noBorder }}>
                                <Accordion key={`analysis-${key}`}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Grid key={`analysis-${key}`} container alignItems="center">
                                      <Grid item sm={11} md={11} lg={11} className={[classes.flex, classes.alignCenter].join('')}>
                                        <Typography color="inherit">{category?.label}</Typography>
                                      </Grid>
                                      {(findByKey(analysisCategoryDefinitions, 'id', category?.id)?.label === Category.Audiometry ||
                                        findByKey(analysisCategoryDefinitions, 'id', category?.id)?.label === Category.Pulmonary) && (
                                        <Grid item sm={1} md={1} lg={1} className={classes.flex}>
                                          <FormControlLabel
                                            aria-label="accordion-graphic-button"
                                            onClick={event => event.stopPropagation()}
                                            onFocus={event => event.stopPropagation()}
                                            control={<BarChartIcon fontSize="medium" onClick={() => openGraphDialog(category)} />}
                                            label=""
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails key={`analysis-${key}`} classes={{ root: classes.arccordionDetailsRoot }}>
                                    {analysisByGroup[key]?.map((item, index) => {
                                      return (
                                        <Accordion key={`analysis-${key}`}>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container alignItems="center">
                                              <Grid item sm={11} md={11} lg={11} className={classes.flex}>
                                                {(findByKey(analysisCategoryDefinitions, 'id', category?.id)?.label ===
                                                  Category.Audiometry ||
                                                  findByKey(analysisCategoryDefinitions, 'id', category?.id)?.label ===
                                                    Category.Pulmonary) && (
                                                  <FormControlLabel
                                                    aria-label="sub-accordion-checkbox"
                                                    onClick={event => event.stopPropagation()}
                                                    onFocus={event => event.stopPropagation()}
                                                    control={
                                                      <Checkbox
                                                        name={`${makeSlug(category?.label)}-${item.id}`}
                                                        onChange={e => handleCheckbox(e, item)}
                                                        checked={selectedGraphData?.find(d => d.id === item?.id) ? true : false}
                                                      />
                                                    }
                                                    label=""
                                                  />
                                                )}
                                                <Typography color="inherit">
                                                  {category?.label} - {dateDisplayFormat(item.date)}
                                                </Typography>
                                              </Grid>
                                              <Grid item sm={1} md={1} lg={1}>
                                                <Action data={item} />
                                              </Grid>
                                            </Grid>
                                          </AccordionSummary>
                                          <AccordionDetails key={item?.id} classes={{ root: classes.arccordionDetailsRoot }}>
                                            <Box className={classes.fullWidth}>
                                              <ListItem key={item?.id} className="pl-0">
                                                <ListItemText>
                                                  <b>Kanaat:</b> {item?.opinion ? item?.opinion : 'Kanaat girilmemiştir.'}
                                                </ListItemText>
                                              </ListItem>
                                              <ListItem key={item?.id} className="pl-0">
                                                <ListItemText>
                                                  <b>Sonuçlar:</b>
                                                  {(!item.detailsList || item.detailsList.length === 0) &&
                                                    findByKey(analysisCategoryDefinitions, 'id', item.categoryDefinitionId)?.label !==
                                                      Category.Audiometry &&
                                                    findByKey(analysisCategoryDefinitions, 'id', item.categoryDefinitionId)?.label !==
                                                      Category.Pulmonary &&
                                                    ' Sonuç bulunmamaktadır.'}
                                                </ListItemText>
                                              </ListItem>
                                              <ListItem key={item?.id} className="pl-0">
                                                <ListItemText>
                                                  <ParameterList
                                                    list={item.detailsList}
                                                    audiometryResultList={item.audiometry}
                                                    pftDetailList={item.pulmonaryResult?.pftDetails}
                                                    analysisCategory={item.categoryDefinitionId}
                                                  />
                                                  {item.audiometry?.audioCategories?.length > 0 &&
                                                    !item.pulmonaryResult?.pftCategory &&
                                                    renderCategoryLabels({
                                                      categories: item.audiometry?.audioCategories,
                                                      id: item.id,
                                                      openCategoryDialog,
                                                    })}
                                                  {!item.audiometry?.audioCategories &&
                                                    item.pulmonaryResult?.pftCategory &&
                                                    renderCategoryLabels({
                                                      categories: [item.pulmonaryResult?.pftCategory],
                                                      id: item.id,
                                                      openCategoryDialog,
                                                    })}
                                                </ListItemText>
                                              </ListItem>
                                              <div key={item.id}>
                                                <Accordion key={item.id} style={{ width: '100%' }}>
                                                  <AccordionSummary key={item.id} expandIcon={<ExpandMoreIcon />}>
                                                    <Icon style={{ color: '#2196f3' }} className="icon-attachment" />
                                                    <Typography style={{ color: 'gray' }}> Ek Dosyalar</Typography>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <AnalysisFiles analysisId={item?.id} />
                                                  </AccordionDetails>
                                                </Accordion>
                                              </div>
                                            </Box>
                                          </AccordionDetails>
                                        </Accordion>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              ) : (
                <AlertInformation severity="error" message="Gösterilecek Kayıt Bulunamadı !" />
              )}
            </List>
          </Grid>
        </Grid>
        <EditDialog />
      </IndicatorWrapper>
      <Dialog
        PaperProps={{ style: { maxWidth: 500 } }}
        open={openedCategoryDialog}
        keepMounted
        onClose={onClose.bind(null, false)}
        classes={{ paper: classes.dialog }}>
        <DialogTitle className={classes.dialogTitle}>{selectedAudioLabelData?.label}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: selectedAudioLabelData?.description }} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" type="button" onClick={onClose.bind(null, false)}>
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
      <GraphDialog activeCategory={activeCategory} />
    </>
  );
}
