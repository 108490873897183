/* eslint-disable @typescript-eslint/consistent-type-assertions */

import {UserRole} from 'model/user-role.enum';
import {LicenceModule} from 'model/licence-module.enum';

interface LinkItem {
  indexKey: number;
  title: string;
  icon: string;
  children?: LinkChildItem[];
  visibleTo?: UserRole[];
  activeTo?: LicenceModule[];
}

interface LinkChildItem {
  link: string;
  title: string;
  icon: string;
  visibleTo?: UserRole[];
  activeTo?: LicenceModule[];
  external?: boolean;
}

export default <LinkItem[]>[
  {
    indexKey: 0,
    title: 'Personel Bilgisi',
    icon: 'icon-account-multiple',
    visibleTo: [UserRole.PHYSICIAN, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.NURSE, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
    children: [
      {
        link: '/transactions/employee',
        title: 'Personel Listesi',
        icon: 'icon-account-multiple',
        visibleTo: [UserRole.PHYSICIAN, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.NURSE, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
      },
    ],
    activeTo: [LicenceModule.PERSONEL, LicenceModule.SAGLIK_GOZETIMI, LicenceModule.RISK_DEGERLENDIRME, LicenceModule.AKSIYON, LicenceModule.IS_KAZA, LicenceModule.EGITIM, LicenceModule.ACIL_DURUM, LicenceModule.KKD, LicenceModule.ISG, LicenceModule.ONAYLI_DEFTER, LicenceModule.IS_EKIPMAN, LicenceModule.ISTATISTIK, LicenceModule.TANIM],
  },
  {
    indexKey: 1,
    title: 'Sağlık Gözetimi',
    icon: 'icon-stethoscope',
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    activeTo: [LicenceModule.SAGLIK_GOZETIMI],
    children: [
      {
        link: '/transactions/examination',
        title: 'Muayene İşlemleri',
        icon: 'icon-stethoscope',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/nurse-operation',
        title: 'SP İşlemleri',
        icon: 'icon-heart-pulse',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/vaccine',
        title: 'Aşı Kayıt Listesi',
        icon: 'icon-needle',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/report',
        title: 'Rapor Listesi',
        icon: 'icon-clipboard-text',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/referral',
        title: 'Sevk  Listesi',
        icon: 'icon-code-string',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/protocols/registries/physician',
        title: 'Protokol Defteri',
        icon: 'icon-book-open-page-variant',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
      {
        link: '/transactions/protocols/registries/nurse-operation',
        title: 'SP Protokol Defteri',
        icon: 'icon-book-open-page-variant',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
      },
    ],
  },
  {
    indexKey: 2,
    title: 'AKSİYONLAR',
    icon: 'icon-human-handsup',
    activeTo: [LicenceModule.AKSIYON],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    children: [
      {
        link: '/actions',
        title: 'Aksiyon Listesi',
        icon: 'icon-human-handsup',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
    ],
  },
  {
    indexKey: 3,
    title: 'Risk Değerlendirme',
    icon: 'icon-alert',
    activeTo: [LicenceModule.RISK_DEGERLENDIRME],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
    children: [
      {
        link: '/risk/matrix',
        title: '5x5',
        icon: 'icon-alert',
      },
      {
        link: '/risk/fine-kinney',
        title: 'Fine-Kinney',
        icon: 'icon-alert',
      },
    ],
  },
  {
    indexKey: 4,
    title: 'İŞ KAZASI VE RAMAK KALA',
    icon: 'icon-flash-outline',
    activeTo: [LicenceModule.IS_KAZA],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    children: [
      {
        link: '/accident/list',
        title: 'İş Kazaları',
        icon: 'icon-flash-outline',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
      {
        link: '/near-miss/list',
        title: 'Ramak Kala',
        icon: 'icon-exclamation',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
      {
        link: '/accident/working-hours',
        title: 'Çalışma Saatleri',
        icon: 'icon-flash-outline',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
    ],
  },
  {
    indexKey: 5,
    title: 'EĞİTİM YÖNETİMİ',
    icon: 'icon-school',
    activeTo: [LicenceModule.EGITIM],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    children: [
      // {
      //   link: '/training/list',
      //   title: 'Eğitim Durum Liste',
      //   icon: 'icon-school',
      //   visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      // },
      {
        link: '/training/detail-list',
        title: 'Eğitim Listesi',
        icon: 'icon-school',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
      {
        link: '/training/ministry-training-list',
        title: 'IBYS Bildirim Takibi',
        icon: 'icon-school',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
      {
        link: '/training/employee-list',
        title: 'Personel Bazlı Liste',
        icon: 'icon-school',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
    ],
  },
  {
    indexKey: 6,
    title: 'Acil Durum Yönetimi',
    icon: 'icon-bell-ring',
    activeTo: [LicenceModule.ACIL_DURUM],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
    children: [
      {
        link: '/emergency-management/drill',
        title: 'Tatbikatlar',
        icon: 'icon-bell-ring',
      },
      {
        link: '/emergency-management/caseTeams',
        title: 'Acil Durum Ekipleri',
        icon: 'icon-bell-ring',
      },
      {
        link: '/emergency-management/case-plans',
        title: 'Acil Durum Planı',
        icon: 'icon-bell-ring',
      },
    ],
  },
  {
    indexKey: 7,
    title: 'KKD',
    icon: 'icon-headphones',
    activeTo: [LicenceModule.KKD],
    visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
    children: [
      {
        link: '/ppe/ppe-definitions',
        title: 'KKD Tipi',
        icon: 'icon-headphones',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
      {
        link: '/ppe/ppe-stocks',
        title: 'KKD Stok Girişi',
        icon: 'icon-headphones',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
      {
        link: '/ppe/ppe-assignments',
        title: 'KKD Atama',
        icon: 'icon-headphones',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
      {
        link: '/ppe/ppe-outputs',
        title: 'KKD Zimmetler',
        icon: 'icon-headphones',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
    ],
  },
  {
    indexKey: 8,
    title: 'ISG Kurulu',
    icon: 'icon-people',
    activeTo: [LicenceModule.ISG],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    children: [
      {
        link: '/committee/committee-members',
        title: 'Kurul Üyeleri',
        icon: 'icon-people',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
      {
        link: '/committee/committee-meetings',
        title: 'Toplantı Listesi',
        icon: 'icon-people',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      },
    ],
  },
  {
    indexKey: 9,
    title: 'ONAYLI DEFTER',
    icon: 'icon-note-text',
    activeTo: [LicenceModule.ONAYLI_DEFTER],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
    children: [
      {
        link: '/occupational-safety-registries/query',
        title: 'Onaylı Defter',
        icon: 'icon-note-text',
      },
      {
        link: '/occupational-safety-registries/list',
        title: 'Onaylı Defter Listesi',
        icon: 'icon-note-text',
      },
    ],
  },
  {
    indexKey: 10,
    title: 'İş Ekipmanları',
    icon: 'icon-account-settings-variant',
    activeTo: [LicenceModule.IS_EKIPMAN],
    visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
    children: [
      {
        link: '/work-equipment/definition',
        title: 'İş Ekipman Tanımları',
        icon: 'icon-account-settings-variant',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
      {
        link: '/work-equipment/layout',
        title: 'İş Ekipman Listesi',
        icon: 'icon-account-settings-variant',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
      {
        link: '/work-equipment/jobStatus',
        title: 'İş Ekipman Kontrol Listesi',
        icon: 'icon-account-settings-variant',
        visibleTo: [UserRole.OSS, UserRole.FIRM_ADMIN],
      },
    ],
  },

  // {
  //   title: 'DENETİMLER',
  //   icon: 'icon-clipboard-check',
  //   children: [
  //     {
  //       link: '/saglik-gozetimi/Audits/Liste/1',
  //       title: 'Denetimler',
  //       icon: 'icon-clipboard-check',
  //       external: true,
  //     },
  //   ],
  // },
  // {
  //   title: 'ŞABLONLAR',
  //   icon: 'icon-needle',
  //   children: [
  //     {
  //       link: '/templates',
  //       title: 'Şablonlar',
  //       icon: 'icon-needle',
  //
  //     },
  //   ],
  // },
  {
    indexKey: 11,
    title: 'İSTATİSTİKLER',
    icon: 'icon-chart-areaspline',
    activeTo: [LicenceModule.ISTATISTIK],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN, UserRole.HR, UserRole.IT],
    children: [
      // {
      //   link: '/statistics/statistics',
      //   title: 'Stats',
      //   icon: 'icon-chart-areaspline',
      // },
      {
        link: '/statistics/reports',
        title: 'Muayene',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/activity-report',
        title: 'Faaliyet Raporu',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/lost-working-days',
        title: 'İş Günü Kayıpları',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/vaccine-report',
        title: 'Aşı Raporu',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/nurse-report',
        title: 'Hemşirelik İşlem Raporu',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/accident',
        title: 'Kaza',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/rest-report',
        title: 'İstirahat Raporu',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/referral',
        title: 'Sevk',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/action',
        title: 'Aksiyonlar',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/analysis',
        title: 'Tahlil Tetkik',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/odyo-sft',
        title: 'Odyo / SFT',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/near-misses',
        title: 'Ramak Kala',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/diagnosis',
        title: 'Hastalık / ICD10',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/body-measurements',
        title: 'Vücut Ölçümleri',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/vital-measurements',
        title: 'Vital Ölçümleri',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/chronic-diseases',
        title: 'Kronik Rahatsızlıklar',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/exposures',
        title: 'Maruziyetler',
        icon: 'icon-chart-areaspline',
      },
      {
        link: '/statistics/education',
        title: 'Eğitimler',
        icon: 'icon-chart-areaspline',
      },
    ],
  },
  {
    indexKey: 12,
    title: 'TANIMLAR',
    icon: 'icon-settings',
    activeTo: [LicenceModule.TANIM],
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
    children: [
      {
        link: '/definitions/health',
        title: 'Sağlık Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/workplace',
        title: 'İşyeri Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/training',
        title: 'Eğitim Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/emergency',
        title: 'Acil Durum Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/kkd-definitions',
        title: 'KKD Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/work-equipment-definitions',
        title: 'İş Ekipman Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/system-setting',
        title: 'Parametre Ayarları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/firm-definitions',
        title: 'Sistem Ayarları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/accident-definitions',
        title: 'Kaza Tanımları',
        icon: 'icon-hospital',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/admin-health-definitions',
        title: 'Sys Sağlık Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/risk-fine-kinney-definitions',
        title: 'Risk - Fine Kinney Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/admin-risk-fine-kinney-definitions',
        title: 'Sys Risk - Fine Kinney Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/near-miss-definitions',
        title: 'Ramak Kala Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS, UserRole.SYS_ADMIN, UserRole.FIRM_ADMIN],
      },
      {
        link: '/definitions/admin-near-miss-definitions',
        title: 'Sys Ramak Kala Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/admin-work-definitions',
        title: 'Sys İşyeri Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/admin-ppe-definitions',
        title: 'Sys KKD Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/admin-emergency',
        title: ' Sys Acil Durum Tanımları',
        icon: 'icon-settings',
        visibleTo: [UserRole.SYS_ADMIN],
      },
      {
        link: '/definitions/admin-accident-definitions',
        title: 'Sys Kaza Tanımları',
        icon: 'icon-hospital',
        visibleTo: [UserRole.SYS_ADMIN],
      },
    ],
  },
];
