import { Grid } from '@material-ui/core';
import React from 'react';

import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { AccidentSeverityDefinitionDto, AccidentSeverityTypeDefinitionControllerService } from '../../../../../api/client';
import { updateAccidentSeverityTypes } from '../../../../../api/hs/accident/accident';

interface Props {
  open: boolean;
  data: AccidentSeverityDefinitionDto;

  onClose(success: boolean): void;
}

export default function AccidentSeverityEditDialog({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<AccidentSeverityDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={AccidentSeverityTypeDefinitionControllerService.saveFirmAccidentSeverity}
      onUpdate={updateAccidentSeverityTypes}
      data={data}
      resourceReloadName="accidentSeverityTypes"
      createTitle="Kazanın Ağırlık Derecesi Türü Ekleme"
      updateTitle="Kazanın Ağırlık Derecesi Türü Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Kazanın Ağırlık Derecesi Türü Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
