import { count } from 'echarts/types/src/component/dataZoom/history';

import { SelectOption } from '../../Common/Form/Select';
import { sumValueOfFieldWithTotal } from './chart-data-utils';

export interface PieChartData {
  label: string;
  value: number;
}

export const convertPropertyToChartData = (data: any[], fieldName: string, options: SelectOption<string>[]): PieChartData[] => {
  const map = new Map(options.map(a => [a.value, a.text as string]));
  return convertByFieldIdSum(data, fieldName, map);
};

export const convertByFieldNameSum = (data: any[], selectorFieldByName: string): PieChartData[] => {
  const resultMap = new Map<string, PieChartData>();
  data.forEach(d => {
    addFieldValueCount(resultMap, d, selectorFieldByName);
  });
  return Array.from(resultMap.values());
};

export const convertByFieldNameTotalOfValue = (data: any[], selectorFieldByName: string, sumValueOfCollection: string): PieChartData[] => {
  const resultMap = new Map<string, PieChartData>();
  data.forEach(d => {
    addFieldValueCount(resultMap, d, selectorFieldByName, d, sumValueOfCollection);
  });

  const r = Array.from(resultMap.values());
  return r;
};

export const convertByFieldIdSum = (data: any[], selectorFieldById: string, idNameMap: Map<string, string>): PieChartData[] => {
  const resultFieldIdAndCountMap = new Map<string, PieChartData>();
  data.forEach(d => {
    addFieldValueCount(resultFieldIdAndCountMap, d, selectorFieldById, d);
  });
  return Array.from(resultFieldIdAndCountMap.values()).map(val => {
    return { label: idNameMap.get(val.label), value: val.value };
  });
};

export const addFieldValueCountArr = (resultMap: Map<string, PieChartData>, data: any[], selectorField: string) => {
  if (!data) {
    return;
  }
  data.forEach(d => {
    addFieldValueCount(resultMap, d, selectorField, d);
  });
};

export const addFieldValueCount = (
  resultMap: Map<string, PieChartData>,
  data: any,
  selectorField: string,
  originalData?: any,
  sumValueOfCollection?: string
) => {
  if (!data) {
    return;
  }
  const indexOfArr: number = selectorField?.indexOf('[].');
  const indexOfDot: number = selectorField?.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = selectorField.substr(0, indexOfArr);
    const newFieldName = selectorField.substr(indexOfArr + 3);
    addFieldValueCountArr(resultMap, Reflect.get(data, fragment), newFieldName);
  } else if (indexOfDot > 0) {
    const fragment = selectorField.substr(0, indexOfDot);
    const newFieldName = selectorField.substr(indexOfDot + 1);
    if (sumValueOfCollection) {
      addFieldValueCount(resultMap, Reflect.get(data, fragment), newFieldName, originalData, sumValueOfCollection);
    } else {
      addFieldValueCount(resultMap, Reflect.get(data, fragment), newFieldName, originalData);
    }
  } else {
    if (!data) {
      return;
    }
    const fieldValue = Reflect.get(data, selectorField);
    let countValue = 0;
    if (sumValueOfCollection) {
      countValue = sumValueOfFieldWithTotal(originalData, sumValueOfCollection);
      if (resultMap.has(fieldValue)) {
        resultMap.get(fieldValue).value = resultMap.get(fieldValue).value + countValue;
      } else {
        resultMap.set(fieldValue, { label: fieldValue, value: countValue });
      }
    } else {
      if (resultMap.has(fieldValue)) {
        resultMap.get(fieldValue).value++;
      } else {
        resultMap.set(fieldValue, { label: fieldValue, value: 1 });
      }
    }
  }
};
