import { RoleNames as roleNames, UserRole } from 'model/user-role.enum';

export function userRoleName(userRole: UserRole): string {
  return roleNames.get(userRole);
}

export function detectUserRoleByStr(userRoleStr: string): UserRole {
  for (const role of Object.values(UserRole)) {
    if (role.toString() === userRoleStr) {
      return role;
    }
  }
}

export function userRoleNameByStr(userRoleStr: string): string {
  return userRoleName(detectUserRoleByStr(userRoleStr));
}

export function userRoleNameArrToStr(userRoles: string[]): string {
  return userRoles.map(r => userRoleNameByStr(r)).join(',');
}

export function userRoleArrToStr(userRoles: UserRole[]): string {
  return userRoles.map(r => userRoleName(r)).join(',');
}
