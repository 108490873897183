import { createContext } from 'react';

import { TrainingEmployeeViewContentResult } from 'api/hs/trainings/TrainingEmployeeFilter';

export interface EmployeeTrainingDetailContextType {
  showDialogEmployeeTraining: boolean;

  setShowDialogEmployeeTraining(opened: boolean): void;

  selectedData: TrainingEmployeeViewContentResult;

  setSelectedData(data: TrainingEmployeeViewContentResult): void;
}

export const EmployeeTrainingDetailContext = createContext<EmployeeTrainingDetailContextType>(null);
