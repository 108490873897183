import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetSystemCheckResult } from './get-system-check.result';

export async function getSystemCheck(): Promise<GetSystemCheckResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetSystemCheckResult[]>(`/firms/system-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
