import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { DepartmentPayload } from './Department.payload';
import { DepartmentResult } from './Department.result';

export async function getDepartment(): Promise<DepartmentResult[]> {
  const [error, res] = await tryCatch(HSApi.get<DepartmentResult[]>(`/firms/department-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveDepartment(body: DepartmentPayload): Promise<DepartmentResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/department-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateDepartment({ id, ...body }: MakeRequired<Partial<DepartmentResult>, 'id'>): Promise<DepartmentResult> {
  const [error, res] = await tryCatch(HSApi.put<DepartmentResult>(`/firms/department-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteDepartment(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/department-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminDepartment(): Promise<DepartmentResult[]> {
  const [error, res] = await tryCatch(HSApi.get<DepartmentResult[]>(`/department-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminDepartment(body: DepartmentPayload): Promise<DepartmentResult> {
  const [err, res] = await tryCatch(HSApi.post('/department-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminDepartment({ id, ...body }: MakeRequired<Partial<DepartmentResult>, 'id'>): Promise<DepartmentResult> {
  const [error, res] = await tryCatch(HSApi.put<DepartmentResult>(`/department-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminDepartment(id: number) {
  const [err, res] = await tryCatch(HSApi.delete(`/departments/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
