import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import TablePagination from 'Common/TablePagination';

import { SystemSettingListContext } from '../../system-setting.context';
import Action from './Action';
import EditDialog from './EditDialog';

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: 1,
  },
  head: {
    backgroundColor: 'white',
    color: 'gray',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Index() {
  const { data, loading, size, page, totalPages, setPage, setSize } = useContext(SystemSettingListContext);

  return (
    <div>
      <IndicatorWrapper data={data} errorMessage="Gösterilecek Kayıt Bulunamadı" loading={loading}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">KOD</StyledTableCell>
                <StyledTableCell align="left">ANAHTAR</StyledTableCell>
                <StyledTableCell align="left">DEĞER</StyledTableCell>
                <StyledTableCell align="right">İŞLEMLER</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(setting => (
                <StyledTableRow key={setting.key}>
                  <StyledTableCell style={{ width: '15%' }} align="left">
                    {setting.code}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '35%' }} align="left">
                    {setting.key}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '35%' }} align="left">
                    {setting.value}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '15%' }} align="right">
                    <Action data={setting} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={4}>
                  <TablePagination size={size} onSizeChange={setSize} page={page} count={totalPages} onPageChange={setPage} />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </IndicatorWrapper>
      <EditDialog />
    </div>
  );
}
