/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WorkEquipmentPeriodicControlRequest = {
    startDate?: string;
    workEquipmentDefinitionId?: string;
    frequencyCount?: string;
    frequencyType?: WorkEquipmentPeriodicControlRequest.frequencyType;
    controlLabel?: string;
    hour?: string;
    startMonthDayIndex?: string;
    weekDays?: Array<'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'>;
    month?: number;
    dailyOption?: string;
}

export namespace WorkEquipmentPeriodicControlRequest {

    export enum frequencyType {
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
        YEARLY = 'YEARLY',
    }


}
