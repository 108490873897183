import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import React from 'react';

import { ShiftDefinitionControllerService, ShiftDefinitionDto } from '../../api/client';
import AdminShiftDefinitionEditDialog from '../../Common/Forms/Definitions/WorkPlaceDefiniton/Shift/AdminShiftDefinitionEditDialog';
import { timePrettier } from '../../utils';
import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  shiftLabel: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
  },
  shiftHour: {
    fontSize: '14px',
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  shiftHour2: {
    fontSize: '10px',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'rgb(170, 170, 170)',
    fontWeight: 'bold',
  },

  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<ShiftDefinitionDto>) {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <TimerIcon fontSize="medium" />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.shiftLabel}>{item.label}</span>
          </div>
          <div>
            <span className={classes.shiftHour}>{timePrettier(item.startAt)} - </span>
            <span className={classes.shiftHour}>{timePrettier(item.endAt)}</span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default function AdminShiftDefinition() {
  return (
    <BaseDefinitionPage<ShiftDefinitionDto>
      listLabelPropName="label"
      fetchData={ShiftDefinitionControllerService.getAllShiftDefinitions}
      FormDialog={AdminShiftDefinitionEditDialog}
      onDelete={ShiftDefinitionControllerService.deleteShiftDefinition}
      Icon={<TimerIcon />}
      ListItemTemplate={ItemTemplate}
    />
  );
}
