import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { getReferraledEmployeeCounts, hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { DateRange, ReferraledEmployeeCounts, WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DateRangeDialog from './DateRangeDialog';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }) => {
  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const {
    referraledEmployee: { open },
    referraledEmployeeDateRange: { dateRange, days, setRangeByDays, setRange },
  } = useContext(DialogContext);

  const [counts, setCounts] = useState<ReferraledEmployeeCounts>();

  useEffect(() => {
    setRangeByDays(7);
  }, [setRangeByDays]);

  useEffect(() => {
    if (dateRange) {
      getReferraledEmployeeCounts(new DateRange(dateRange.start, dateRange.end)).then(setCounts);
    }
  }, [dateRange]);

  const hide = () => {
    hideWidget({ widgetType: WidgetType.REFERRAL }).then(reload);
  };

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Card key="referral" className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.referredEmployee }}>
      <CardContent>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }} container>
          <Grid item sm={10} onClick={open}>
            <h3>Sevkli Personel</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide}>
              <>
                {dateRangeOptions.map(dateRangeOption => (
                  <MenuItem key={dateRangeOption} onClick={() => setRangeByDays(dateRangeOption)}>
                    <ListItemIcon>{dateRangeOption === days && <Done fontSize="small" />}</ListItemIcon>
                    <ListItemText>{dateRangeOption} Gün</ListItemText>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => setDialogVisibility(true)}>
                  <ListItemIcon>{days === undefined && <Done fontSize="small" />}</ListItemIcon>
                  <ListItemText>Diğer...</ListItemText>
                </MenuItem>
              </>
            </DropdownMenuContainer>
          </Grid>
        </Grid>
        <Grid container onClick={open}>
          <Grid item sm={12}>
            <h2>{counts?.referralEmployee || 0}</h2>
          </Grid>
        </Grid>
        <Divider />
        <p />
      </CardContent>
      <DateRangeDialog close={() => setDialogVisibility(false)} opened={dialogVisibility} setRange={setRange} />
    </Card>
  );
};
