import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import Table from '../../../../Common/Table/Default';
import { PpeDetailStocksContext } from '../../ppe-detail-stocks.context';
import { CELL } from './columns';

export default function PpeDetailList() {
  const { PpeDetailStocks, loading } = useContext(PpeDetailStocksContext);
  return (
    <div className="p-1">
      <IndicatorWrapper data={PpeDetailStocks} loading={loading} errorMessage="Listelenecek  Kayıt Bulunamadı">
        <Table columns={CELL} data={PpeDetailStocks} />
      </IndicatorWrapper>
    </div>
  );
}
