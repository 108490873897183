import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';

import Button from '../../Common/Button';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface Props {
  label: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick(): void;
  loading?: boolean;
}

export default (props: Props) => {
  const styles = useStyles();

  const type = props.type || 'button';

  return (
    <Grid xs={12} className="mb-2">
      <div className={styles.container}>
        {props.loading ? (
          <CircularProgress />
        ) : (
          <Button className="mr-3" startIcon={<AddIcon />} variant="outlined" color="primary" onClick={props.onClick} type={type}>
            {props.label}
          </Button>
        )}
      </div>
    </Grid>
  );
};
