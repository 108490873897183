import { createContext } from 'react';

import { modelDataMatcher } from 'utils';

export class ListItem {
  public description: string = null;
  public id: string = null;

  constructor(data: Partial<ListItem> = {}) {
    modelDataMatcher(data, this);
  }
}

export interface HistoryContextType<T extends ListItem = ListItem> {
  list: T[];
  loading: boolean;
  dialogOpened: boolean;
  setDialogOpened(opened: boolean): void;
  selectedItem: T;
  setSelectedItem(item: T): void;
  title: string;
  buttonLabel: string;
  save(item: Omit<T, 'id'>): Promise<any>;
  update(item: T): Promise<any>;
  remove(id: T['id']): Promise<any>;
  form: JSX.Element;
}

export const StoryContext = createContext<HistoryContextType>(null);
