import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { dateSort, MakeRequired } from 'utils';

import { DefaultNurseOperationDetailPayload } from './default-nurse-operation-detail.payload';
import { DefaultNurseOperationDetailResult } from './default-nurse-operation-detail.result';

export async function getDefaultNurseOperationDetail(nurseOperationId: string): Promise<DefaultNurseOperationDetailResult[]> {
  let res;
  try {
    res = await HSApi.get<DefaultNurseOperationDetailResult[]>(`/nurse-operations/${nurseOperationId}/details`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function saveDefaultNurseOperationDetail(
  id: string,
  body: DefaultNurseOperationDetailPayload
): Promise<DefaultNurseOperationDetailResult> {
  const [err, res] = await tryCatch(HSApi.post(`/nurse-operations/${id}/details`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateDefaultNurseOperationDetail({
  id,
  ...body
}: MakeRequired<Partial<DefaultNurseOperationDetailResult>, 'id'>): Promise<DefaultNurseOperationDetailResult> {
  const [error, res] = await tryCatch(HSApi.put<DefaultNurseOperationDetailResult>(`/nurse-operations/details/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteDefaultNurseOperationDetail(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/nurse-operations/details/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
