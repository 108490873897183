import { Button, Divider, Grid, Typography } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import React, { useCallback, useContext, useRef } from 'react';
import { useHistory } from 'react-router';

import { saveIbysTraining } from 'api/hs/trainings/ibys-trainings';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';

import { TrainingDetailContext } from '../training-detail.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;
}

export default function TrainerForm({ open, onClose }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);
  const { trainers } = useContext(SelectOptionsContext);
  const { selectedData } = useContext(TrainingDetailContext);
  const history = useHistory();

  const onSave = useCallback(
    async values => {
      try {
        await saveIbysTraining(selectedData?.id, values);
        showMessage('Bakanlık verisi başarılı ile oluşturuldu.', 'success', 'info');
        history.push('/training/detail-list');
      } catch (e) {
        showMessage('Bakanlık verisine dönüştürürken hata oluşdu.', 'error', 'info');
      }
    },
    [showMessage, selectedData, history]
  );

  return (
    <Dialog width="xs" color="primary" title="Eğitmen Seçiniz" opened={open} onClose={() => onClose(false)}>
      <Form onSubmit={onSave} ref={formRef}>
        <Grid container className="p-2">
          <Grid item xs={12} sm={12} className="pr-sm-1">
            <AutoComplete required name="trainerId" options={trainers} label="Eğitmen Seçiniz" single />
          </Grid>
          <Grid item xs={12} sm={12} className="pr-sm-1">
            <Typography color="error" variant="caption" display="block" gutterBottom>
              Bakanlık verisine dönüştürmek için lütfen tek eğitmen seçiniz..
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <footer className="text-right p-2">
          <Button className="mr-1" type="button" variant="contained" onClick={() => onClose(false)}>
            Vazgeç
          </Button>
          <Button startIcon={<LoopIcon />} type="submit" variant="contained" color="primary">
            Dönüştür
          </Button>
        </footer>
      </Form>
    </Dialog>
  );
}
