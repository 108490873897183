import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { OpinionPayload } from './opinion.payload';
import { OpinionsResult } from './opinions.result';

export async function getOpinion(examinationId?: string): Promise<OpinionsResult> {
  let res;
  try {
    res = await HSApi.get<OpinionsResult>(`/hs-exam-opinions?examinationId=${examinationId}`);
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    }
    console.error(e); // tslint:disable-line:no-console
    throw e;
  }

  return res.data;
}

export async function saveOpinion(body: OpinionPayload): Promise<OpinionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/hs-exam-opinions', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateOpinion(id: string, body: OpinionPayload): Promise<OpinionsResult> {
  const [err, res] = await tryCatch(HSApi.put(`/hs-exam-opinions/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteOpinion(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/hs-exam-opinions/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
