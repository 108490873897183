import { noop, OpenPromise } from '@thalesrc/js-utils';
import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import FileUploadDialog, { FileUploadDialogProps } from 'Common/FileUploadDialog';
import WarnDialog, { WarnDialogProps } from 'Common/WarnDialog';

export interface DialogContextType {
  openWarnDialog(props: Omit<WarnDialogProps, 'opened' | 'onClose'>): Promise<boolean>;
  openFileDialog(props: Omit<FileUploadDialogProps, 'opened' | 'onClose'>): Promise<File[] | false>;
}

export const DialogContext = createContext<DialogContextType>(null);

const DEFAULT_WARN_DIALOG_PROPS = { opened: false, onClose: noop };
const DEFAULT_FILE_DIALOG_PROPS = { opened: false, onClose: noop };

export function DialogContextProvider({ children }: PropsWithChildren<{}>) {
  const [warnDialogProps, setWarnDialogProps] = useState<WarnDialogProps>(DEFAULT_WARN_DIALOG_PROPS);
  const [fileDialogProps, setFileDialogProps] = useState<FileUploadDialogProps>(DEFAULT_FILE_DIALOG_PROPS);

  const openWarnDialog = useCallback((props: Omit<WarnDialogProps, 'opened' | 'onClose'>) => {
    const prom = new OpenPromise<boolean>();

    setWarnDialogProps({
      ...props,
      opened: true,
      onClose(submitted) {
        prom.resolve(submitted);
        setWarnDialogProps(DEFAULT_WARN_DIALOG_PROPS);
      },
    });

    return prom;
  }, []);

  const openFileDialog = useCallback((props: Omit<FileUploadDialogProps, 'opened' | 'onClose'>) => {
    const prom = new OpenPromise<File[] | false>();

    setFileDialogProps({
      ...props,
      opened: true,
      onClose(files) {
        prom.resolve(files);
        setFileDialogProps(DEFAULT_FILE_DIALOG_PROPS);
      },
    });

    return prom;
  }, []);

  const context = useMemo(() => ({ openWarnDialog, openFileDialog }), [openWarnDialog, openFileDialog]);

  return (
    <DialogContext.Provider value={context}>
      {children}
      <WarnDialog {...warnDialogProps} />
      <FileUploadDialog {...fileDialogProps} dialogProps={{ style: { zIndex: 9999999 } }} />
    </DialogContext.Provider>
  );
}
