import { createContext } from 'react';

import { CommitteeMeetingsDetailResult } from 'api/hs/committee/committee-meetings-detail';

export interface CommitteeMeetingsDetailContextType {
  committeeMeetingsDetail: CommitteeMeetingsDetailResult;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: CommitteeMeetingsDetailResult;

  setSelectedData(data: CommitteeMeetingsDetailResult): void;
}

export const CommitteeMeetingsDetailContext = createContext<CommitteeMeetingsDetailContextType>(null);
