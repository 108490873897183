import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { getProtocolList, ProtocolsPhysicianContentResult } from 'api/hs/TransactionAndLists/ProtocolsPhysicianList';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import { CELL } from './COLUMS';
import ListTable from './TableList';
import Toolbar from './Toolbar';

export default function Examination() {
  const history = useHistory();

  const routerProtocol = (row: ProtocolsPhysicianContentResult) => {
    switch (row.protocolOperationType) {
      case 'REFERRAL':
        return history.push(`/transactions/employee/${row.employeeId}/referral`);
      case 'REPORT':
        return history.push(`/transactions/employee/${row.employeeId}/report`);
      case 'ANALYSIS':
        return history.push(`/transactions/employee/${row.employeeId}/analysis`);
      default:
        return history.push(`/transactions/employee/${row.employeeId}/examination/${row.referenceTableId}`);
    }
  };

  const fetchProtocolList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getProtocolList(page, size, sorting === false ? 'protocolNo-ASC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`);
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider
      fetcher={fetchProtocolList}
      RowProps={{ className: 'cursor-pointer' }}
      columns={CELL}
      onRowClick={row => routerProtocol(row)}>
      <Toolbar />
      <ListTable />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
