/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IbysExaminationView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    examinationId?: string;
    requestState?: IbysExaminationView.requestState;
    examIdOnIbysServer?: string;
    officialQueryId?: string;
    processResult?: IbysExaminationView.processResult;
    processResultMessage?: string;
}

export namespace IbysExaminationView {

    export enum requestState {
        SENT = 'SENT',
        NOT_SENT = 'NOT_SENT',
        INCORRECT_REQUEST = 'INCORRECT_REQUEST',
        ON_STAND_BY = 'ON_STAND_BY',
    }

    export enum processResult {
        CONTINUES = 'CONTINUES',
        UNSUCCESSFUL = 'UNSUCCESSFUL',
        SUCCESSFUL = 'SUCCESSFUL',
    }


}
