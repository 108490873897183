import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import React from 'react';

import { deleteAdminDepartment, DepartmentResult, getAdminDepartment } from 'api/hs/definitions/workplace-definition/department';
import AdminDepartmentEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Department/AdminDepartmentEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminDepartment() {
  return (
    <BaseDefinitionPage<DepartmentResult>
      listLabelPropName="departmentName"
      fetchData={getAdminDepartment}
      FormDialog={AdminDepartmentEditDialog}
      onDelete={deleteAdminDepartment}
      Icon={<AccountBalanceIcon />}
    />
  );
}
