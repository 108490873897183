import React, { useState } from 'react';

import { getGroupDefinitions, GroupDefinitionsModel } from 'api/hs/definitions/trainings/group-definitions';
import { useFetch } from 'utils';

import TrainingGroupDefinitions from './group-definitions';
import { TrainingGroupDefinitionsContext } from './group-definitions.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<GroupDefinitionsModel>(null);

  const { data, fetch } = useFetch(() => getGroupDefinitions(), [], { setLoading });

  return (
    <TrainingGroupDefinitionsContext.Provider
      value={{
        groupTrainingGroupDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <TrainingGroupDefinitions />
    </TrainingGroupDefinitionsContext.Provider>
  );
}
