import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOptionsContext } from 'context/select-options.context';

import { EmergencyCaseTeamTrainingDefinitionControllerService } from '../../../../api/client';
import { EmergencyCaseTeamTrainingDefinitionDto } from '../../../../api/client';
import Dialog from '../../../../Common/Dialog';
import { AlertContext } from '../../../../context/alert.context';
import { useAsyncEffect } from '../../../../utils';
import { CaseTeamsDetailContext } from '../../case-teams-detail.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: EmergencyCaseTeamTrainingDefinitionDto[];
}

export default function NewCaseTrainingsForm({ data, open, onClose }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { trainingDefinitions } = useContext(SelectOptionsContext);
  const { emergencyCaseTeams } = useContext(CaseTeamsDetailContext);
  const { showMessage } = useContext(AlertContext);

  const onSave = useCallback(
    async values => {
      const payload = values?.trainingDefinitionIds?.map(training => ({
        trainingDefinitionId: training,
        emergencyCaseTeamId: emergencyCaseTeams?.id,
      }));
      try {
        await EmergencyCaseTeamTrainingDefinitionControllerService.saveEmergencyCaseTeamTraining(emergencyCaseTeams?.id, payload);
        showMessage('Başarılı eğitim ataması yapıldı', 'success', 'info');
        onClose(true);
      } catch (e) {
        showMessage('Eğitim ataması sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, emergencyCaseTeams?.id]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    const trainingDefinitionIds = data?.reduce((acc, item) => [...acc, item?.trainingDefinitionId], []);
    formRef?.current?.methods?.setValue('trainingDefinitionIds', trainingDefinitionIds);
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog color="secondary" width="sm" opened={open} onClose={() => onClose(false)} title={'Ekibe Eğitim Atama'}>
      <Form onSubmit={onSave} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <AutoComplete name="trainingDefinitionIds" label="Eğitimler" options={trainingDefinitions} required />
          </Grid>

          <Grid item xs={12} md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
