/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { PageWorkEquipmentJobStatusView } from '../models/PageWorkEquipmentJobStatusView';
import type { WorkEquipmentJobStatusDto } from '../models/WorkEquipmentJobStatusDto';
import type { WorkEquipmentJobStatusRequest } from '../models/WorkEquipmentJobStatusRequest';
import type { WorkEquipmentJobStatusView } from '../models/WorkEquipmentJobStatusView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkEquipmentJobStatusControllerService {

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getStatusControlFile(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-job-statuses/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static statusControlFileImport(
        id: string,
        requestBody?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/work-equipment-job-statuses/${id}/imported-files`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageWorkEquipmentJobStatusView OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentStatusViews(
        requestBody: WorkEquipmentJobStatusRequest,
    ): CancelablePromise<PageWorkEquipmentJobStatusView> {
        return __request({
            method: 'POST',
            path: `/work-equipment-job-statuses/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteWorkEquipmentStatus(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-job-statuses/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns WorkEquipmentJobStatusDto OK
     * @throws ApiError
     */
    public static updateWorkEquipmentStatus(
        id: string,
        requestBody: WorkEquipmentJobStatusDto,
    ): CancelablePromise<WorkEquipmentJobStatusDto> {
        return __request({
            method: 'PATCH',
            path: `/work-equipment-job-statuses/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param layoutId
     * @returns WorkEquipmentJobStatusDto OK
     * @throws ApiError
     */
    public static getAllWorkEquipmentStatus(
        layoutId?: string,
    ): CancelablePromise<Array<WorkEquipmentJobStatusDto>> {
        return __request({
            method: 'GET',
            path: `/work-equipment-job-statuses`,
            query: {
                'layoutId': layoutId,
            },
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getStatusControlFileRaw(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/work-equipment-job-statuses/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @param minDate
     * @param maxDate
     * @returns WorkEquipmentJobStatusView OK
     * @throws ApiError
     */
    public static getWorkEquipmentStatusView(
        id: string,
        minDate: string,
        maxDate: string,
    ): CancelablePromise<Array<WorkEquipmentJobStatusView>> {
        return __request({
            method: 'GET',
            path: `/layout/${id}/work-equipment-job-statuses/views`,
            query: {
                'minDate': minDate,
                'maxDate': maxDate,
            },
        });
    }

    /**
     * @param layoutIds
     * @returns WorkEquipmentJobStatusView OK
     * @throws ApiError
     */
    public static getLastWorkEquipmentStatusViewMap(
        layoutIds: Array<string>,
    ): CancelablePromise<Record<string, WorkEquipmentJobStatusView>> {
        return __request({
            method: 'GET',
            path: `/layout/work-equipment-job-statuses/last/views`,
            query: {
                'layoutIds': layoutIds,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteStatusControlFile(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/work-equipment-job-statuses/imported-files/${id}`,
        });
    }

}