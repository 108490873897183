import { Dialog } from '@material-ui/core';
import React, { useContext } from 'react';

import { UserEventType } from '../../../api/hs/dashboard/event/event.payload';
import { CalendarContext } from '../calendar.context';
import GenericEventDetail from './GenericEventDetail';
import UserDefinedEventDetail from './UserDefinedEventDetail';

export default function EventPreviewDialog() {
  const {
    eventPreviewDialog: { close, opened },
    updateEventDialog: { open },
    eventId,
    event,
    reload,
  } = useContext(CalendarContext);

  return (
    <Dialog open={opened} onClose={close} fullWidth maxWidth="sm">
      {event && (
        <>
          {event.userEventType === UserEventType.USER_DEFINED ? (
            <UserDefinedEventDetail eventId={eventId} close={close} reload={reload} open={open} />
          ) : (
            <GenericEventDetail close={close} event={event} />
          )}
        </>
      )}
    </Dialog>
  );
}
