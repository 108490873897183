import React, { useState } from 'react';

import { WorkEquipmentDefinitionView } from '../../api/client';
import WorkEquipmentDefinitions from './Definitions';
import { WorkEquipmentDefinitionContext } from './work-equipment-definition.context';

export default function Index() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<WorkEquipmentDefinitionView>(null);

  return (
    <WorkEquipmentDefinitionContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <WorkEquipmentDefinitions />
    </WorkEquipmentDefinitionContext.Provider>
  );
}
