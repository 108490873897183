import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { getAccidentIdActions } from '../../../../api/hs/accident/accident';
import Button from '../../../../Common/Button';
import { EmployeeSelectionCacheContext } from '../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { HSTableProps } from '../../../../Common/Table/HSTable/hs-table.props';
import { EnumContext } from '../../../../context/enum.context';
import { ResourceContext } from '../../../../context/resource.context';
import CustomAction from '../../../../CustomAction';
import Avatar from '../../../../TransactionsAndLists/EmployeeList/Avatar';
import BaseSection from '../../../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import { useAsyncEffect, useDateFormat } from '../../../../utils';
import CommitteeMeetingForm from '../../../CommitteeMeetings/ListAdd/Form';
import { CommitteeMeetingsDetailContext } from '../../committee-meetings-detail.context';
import CommitteeMeetingFiles from '../CommitteeMeetingFiles';
import CommitteeMeetingResult from '../CommitteeMeetingResult';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export default function Index() {
  const { committeeMeetingsDetail, reload } = useContext(CommitteeMeetingsDetailContext);
  const { companies } = useContext(ResourceContext);
  const { committeeMeetingStatusDefinitions } = useContext(EnumContext);
  const classes = useStyles();
  const { loadEmployees, avatars, names } = useContext(EmployeeSelectionCacheContext);
  const [open, setOpen] = useState(false);

  const onCloseDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setOpen(false);
    },
    [reload]
  );

  useAsyncEffect(async () => {
    if (!committeeMeetingsDetail?.employees) {
      return;
    }
    await defer();

    loadEmployees(...committeeMeetingsDetail.employees.map(employeeData => employeeData?.id));
  }, [committeeMeetingsDetail?.employees]);

  const showCommitteeMeetingForm = useCallback(() => {
    setOpen(true);
  }, []);

  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      return getAccidentIdActions(
        committeeMeetingsDetail?.id,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [committeeMeetingsDetail?.id]
  );

  return (
    <Paper className="m-1" elevation={2}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Toplantının Adı
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {committeeMeetingsDetail?.subject}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" className={classes.textColor} component="th" scope="row">
                    Şirket
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {findByKey(companies, 'id', committeeMeetingsDetail?.companyId)?.companyName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" className={classes.textColor} component="th" scope="row">
                    Planlanan Tarih
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {committeeMeetingsDetail?.plannedDate ? <DateField date={new Date(committeeMeetingsDetail?.plannedDate)} /> : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <TableContainer>
            <Table size="small" aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Toplantı Durumu
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {findByKey(committeeMeetingStatusDefinitions, 'id', committeeMeetingsDetail?.meetingStatusDefinition)?.label}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Toplantı Şekli
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {committeeMeetingsDetail?.isFaceToFace ? 'Yüz yüze' : 'Uzaktan'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%" component="th" scope="row" className={classes.textColor}>
                    Toplantının Gündemi
                  </TableCell>
                  <TableCell width="50%" className={classes.textColor2} align="left">
                    {committeeMeetingsDetail?.description}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <div className="text-right mt-1 mr-2">
        <Button onClick={showCommitteeMeetingForm} variant="outlined" startIcon={<EditIcon />} color="primary" className="ml-1">
          Toplantı Bilgilerini Düzenle
        </Button>
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Katılımcı Listesi
          </ListSubheader>
        }>
        <Grid container>
          {committeeMeetingsDetail?.employees?.map(employeeItem => (
            <Grid key={employeeItem?.id} item xs={12} sm={6} md={6} lg={6}>
              <ListItem key={employeeItem?.id} button>
                <ListItemIcon>
                  <Avatar url={avatars[employeeItem.id]} />
                </ListItemIcon>
                <ListItemText primary={names[employeeItem?.id]} />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
      <BaseSection
        icon="icon-human-handsup"
        title={`Toplantının Aksiyonları ${
          committeeMeetingsDetail?.committeeMeetingsActionLength
            ? '( ' + committeeMeetingsDetail?.committeeMeetingsActionLength + ' adet mevcut )'
            : ''
        }`}>
        <CustomAction
          baseFetcher={reload}
          actionReferenceValue={'HEALTH_MEETING'}
          referenceId={committeeMeetingsDetail?.id}
          fetcher={fetchActionList}
        />
      </BaseSection>
      <BaseSection
        icon="icon-grease-pencil"
        title={`Toplantının Kararları ${
          committeeMeetingsDetail?.committeeMeetingsResultLength
            ? '( ' + committeeMeetingsDetail?.committeeMeetingsResultLength + ' adet mevcut )'
            : ''
        }`}>
        <CommitteeMeetingResult />
      </BaseSection>
      <BaseSection
        icon="icon-attachment"
        title={`Toplantının Dosyaları ${
          committeeMeetingsDetail?.committeeMeetingsFileLength
            ? '( ' + committeeMeetingsDetail?.committeeMeetingsFileLength + ' adet mevcut ) '
            : ''
        }`}>
        <CommitteeMeetingFiles />
      </BaseSection>
      <CommitteeMeetingForm open={open} onClose={onCloseDialog} data={committeeMeetingsDetail} />
    </Paper>
  );
}
