import React, { useCallback, useContext } from 'react';

import { AnalysisContext } from '../../analysis.context';
import AnalysisEditForm from '../forms/AnalysisEditForm';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, setSelectedData, setParameters, reload } = useContext(AnalysisContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }

      setSelectedData(null);
      setParameters([]);
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened, setSelectedData, setParameters]
  );

  return <AnalysisEditForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
