import { useCallback, useContext } from 'react';

import { AlertContext } from 'context/alert.context';

type Parameters<T> = T extends (...args: infer U) => any ? U : never;
type ReturnType<T> = T extends (...args: any[]) => Promise<infer U> ? U : never;

export default function useXhr<T extends (...args: any[]) => Promise<any>>(
  method: T,
  successMessage: string,
  errorMessage: string | ((error: any) => JSX.Element),
  deps: any[] = []
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  const { showMessage } = useContext(AlertContext);

  return useCallback(
    async (...args: Parameters<T>) => {
      let result: ReturnType<T>;

      try {
        result = await method(...args);
        showMessage(successMessage, 'success');
      } catch (error) {
        showMessage(typeof errorMessage === 'string' ? errorMessage : errorMessage(error), 'error');
      }

      return result;
    },
    [showMessage, successMessage, errorMessage, ...deps] // eslint-disable-line
  );
}
