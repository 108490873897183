import { createContext } from 'react';

import { AnalysisAggregate, ParameterModel } from 'api/hs/examination/analysis';

export interface AnalysisContextType {
  analysis: AnalysisAggregate[];

  parameters: ParameterModel[];

  setParameters(data: ParameterModel[]): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: AnalysisAggregate;

  setSelectedData(data: AnalysisAggregate): void;

  selectedGraphData: AnalysisAggregate[];

  setSelectedGraphData(data: AnalysisAggregate[]): void;

  graphDialogOpened: boolean;

  setGraphDialogOpened(opened: boolean): void;
}

export const AnalysisContext = createContext<AnalysisContextType>(null);
