import React, { useCallback, useState } from 'react';

import { CompanyUserResult, getCompanyUser } from 'api/hs/definitions/workplace-definition/company-user';
import { CompanyUserModel } from 'model/definitions/workplace-definition/company-user.model';
import { useAsyncEffect } from 'utils';

import { CompanyUserContext } from './company-user.context';
import CompanyUser from './CompanyUser';

export default function Index() {
  const [companyUser, setCompanyUser] = useState<CompanyUserResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<CompanyUserModel>(null);

  const fetch = useCallback(async () => {
    const result = await getCompanyUser();
    setCompanyUser(result);
    setLoading(false);
  }, []);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <CompanyUserContext.Provider
      value={{
        companyUser,
        reload: fetch,
        loading,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <CompanyUser />
    </CompanyUserContext.Provider>
  );
}
