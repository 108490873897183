import { tryCatch, uniquifyByKey } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { ComplaintHistoryPayload } from './complaint-history.payload';
import { ComplaintHistoryResult } from './complaint-history.result';

export async function getComplaintHistories(employeeId: string, examinationId?: string): Promise<ComplaintHistoryResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ComplaintHistoryResult>(`/complaint-histories?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ComplaintHistoryResult>(`/complaint-histories?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

async function saveComplaintHistories(body: ComplaintHistoryPayload): Promise<ComplaintHistoryResult> {
  const [err, res] = await tryCatch(HSApi.post('/complaint-histories', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

async function updateComplaintHistories(body: ComplaintHistoryPayload): Promise<ComplaintHistoryResult> {
  const [err, res] = await tryCatch(HSApi.put(`/complaint-histories`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function upsertComplaintHistories({ complaintHistories, description, employeeId, examinationId }: ComplaintHistoryPayload) {
  const current = await getComplaintHistories(employeeId, examinationId);

  current.complaintHistories = uniquifyByKey(current.complaintHistories.reverse(), 'complaintDefinitionId');

  // If it is the first define
  if (current.description === null) {
    await saveComplaintHistories({ complaintHistories, description, employeeId, examinationId });
  } else {
    // Otherwise
    const definedComplaints = current.complaintHistories.map(comp => comp.complaintDefinitionId);

    const saveBody: ComplaintHistoryPayload = {
      description,
      employeeId,
      examinationId,
      complaintHistories: complaintHistories
        .filter(h => !definedComplaints.includes(h.complaintDefinitionId))
        .map(({ complaintDefinitionId, value }) => ({ complaintDefinitionId, value })),
    };

    if (saveBody.complaintHistories.length) {
      await saveComplaintHistories(saveBody);
    }

    await updateComplaintHistories({
      description,
      employeeId,
      examinationId,
      complaintHistories: complaintHistories
        .filter(h => !saveBody.complaintHistories.some(hh => hh.complaintDefinitionId === h.complaintDefinitionId))
        .map(({ complaintDefinitionId, value }) => ({
          complaintDefinitionId,
          value,
        })),
    });
  }
}

export async function deleteComplaintHistories(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/complaint-histories/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
