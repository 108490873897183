import React, { useCallback, useContext } from 'react';

import { EmployeeTrainingDetailContext } from '../training-employee.context';
import DialogTrainings from './DialogTrainings';

export default function ShowDialog() {
  const { showDialogEmployeeTraining, setShowDialogEmployeeTraining, selectedData, setSelectedData } = useContext(
    EmployeeTrainingDetailContext
  );

  const closeDialog = useCallback(
    (success: boolean) => {
      setShowDialogEmployeeTraining(false);
      setSelectedData(null);
    },
    [setShowDialogEmployeeTraining, setSelectedData]
  );

  return <DialogTrainings open={showDialogEmployeeTraining} onClose={closeDialog} data={selectedData} />;
}
