import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { FamilyHistoryModel, getFamilyHistory } from 'api/hs/examination/family-history';
import { useAsyncEffect } from 'utils';

import FamilyHistory from './family-history';
import { FamilyHistoryContext } from './family-history.context';

export default function Index() {
  const [familyHistory, setFamilyHistory] = useState<FamilyHistoryModel[]>([]);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<FamilyHistoryModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getFamilyHistory(employeeId, examinationId);
    setFamilyHistory(result);
    setLoading(false);
  }, [employeeId, examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <FamilyHistoryContext.Provider
      value={{
        familyHistory,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <FamilyHistory />
    </FamilyHistoryContext.Provider>
  );
}
