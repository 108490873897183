import { createContext } from 'react';

import { BuildingPartDefinitionDto } from '../../../../api/client';

export interface BuildingsFloorsPartsContextType {
  buildingPartDefinition: BuildingPartDefinitionDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: BuildingPartDefinitionDto;

  setSelectedData(data: BuildingPartDefinitionDto): void;
}

export const BuildingsFloorsPartsContext = createContext<BuildingsFloorsPartsContextType>(null);
