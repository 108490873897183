import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { ParameterModel } from './parameter.model';
import { ParameterResult } from './parameter.result';

export async function getParameters(categoryDefinitionId: string): Promise<ParameterModel[]> {
  let res;
  try {
    res = await HSApi.get<ParameterResult[]>(`/firms/analysis-parameter-definitions?categoryDefinitionId=${categoryDefinitionId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteParameters(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/parameters/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
