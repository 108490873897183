import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { ForgotPasswordPayload } from './forgot-password.payload';

export async function forgotPassword(body: ForgotPasswordPayload): Promise<void> {
  const [error] = await tryCatch(HSApi.post<void>(`/users/forgot-password-inform`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
