import { Chip, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { defer } from '@thalesrc/js-utils';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';
import { useAsyncEffect } from 'utils';

import { EmployeeSelectionCacheContext } from '../../../../../Common/Form/EmployeeSelection/employee-selection.context';
import { StorehouseDefinitionsContext } from '../../storehouse-definitions.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function StoreHouseList() {
  const { loading, storehouseDefinitions } = useContext(StorehouseDefinitionsContext);
  const { loadEmployees, names, avatars } = useContext(EmployeeSelectionCacheContext);

  useAsyncEffect(async () => {
    if (!storehouseDefinitions) {
      return;
    }
    await defer();
    const employeeIds = storehouseDefinitions?.reduce(
      (acc, item) => [...acc, ...item.ppeStorehouseSupervisors.map(sup => sup.employeeId)],
      []
    );
    await loadEmployees(...employeeIds);
  }, [storehouseDefinitions]);

  return (
    <IndicatorWrapper loading={loading} data={storehouseDefinitions} errorMessage="Kayıt Bulunamadı.">
      <List id="classificationDefinitions">
        {storehouseDefinitions?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <HomeWorkIcon className="text-gray" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText
                  primary={item?.name}
                  secondary={
                    <>
                      {item?.ppeStorehouseSupervisors?.map(employee => (
                        <>
                          <Chip avatar={<Avatar url={avatars[employee.employeeId]} />} label={names[employee?.employeeId]} />
                        </>
                      ))}
                    </>
                  }
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
