import { Avatar, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { PpeStocksContentResult } from 'api/hs/ppe/ppe-stocks';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

function ActionComponent({ row }: { row: PpeStocksContentResult }) {
  const history = useHistory();

  const routeDetailStocks = useCallback(() => {
    history.push(`/ppe/ppe-stocks/${row.ppeDefinitionId}`);
  }, [row, history]);

  return (
    <div key={row?.id}>
      {row?.ppeStocks?.length > 0 ? (
        <IconButton id={row?.id} onClick={routeDetailStocks}>
          <VisibilityIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

function StockStatusShowAlert({ row }: { row: PpeStocksContentResult }) {
  switch (row?.stockStatus) {
    case 'OUT_OF_STOCK':
      return <Alert severity="warning">Stok Girişi Yapılmamış</Alert>;
    case 'IN_STOCK':
      return <Alert severity="success">Stokta</Alert>;
    case 'NEAR_OUT_OF_STOCK':
      return <Alert severity="warning">Tükenmek üzere</Alert>;
    default:
      return <Alert severity="info">{row?.stockStatus}</Alert>;
  }
}

export const COLUMNS: TableColumn<PpeStocksContentResult>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    defaultVisibility: true,
    render(row: PpeStocksContentResult): JSX.Element {
      return <Avatar src={`/saglik-gozetimi/public/images/ppe/${row?.imagePath}`} />;
    },
  },
  {
    id: 'label',
    width: '10%',
    label: 'Tanımı',
    defaultVisibility: true,
  },
  {
    id: 'classificationName',
    width: '10%',
    label: 'KKD Ana Grup',
    defaultVisibility: true,
  },
  {
    id: 'ppeTypeName',
    width: '10%',
    label: 'KKD Alt Grup',
    defaultVisibility: true,
  },
  {
    id: 'criticalInventoryCount',
    width: '5%',
    label: 'Kritik Envanter',
    defaultVisibility: true,
  },
  {
    id: 'remainStockCount',
    width: '5%',
    label: 'Kalan Stok',
    defaultVisibility: true,
  },
  {
    id: 'stockStatus',
    width: '10%',
    label: 'Stok Durumu',
    render(row: PpeStocksContentResult): JSX.Element {
      return <StockStatusShowAlert row={row} />;
    },
  },
  {
    id: 'description',
    width: '15%',
    label: 'Açıklama',
    defaultVisibility: true,
  },
  {
    id: Symbol(),
    width: '5%',
    label: 'Detay',
    render(row: PpeStocksContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
