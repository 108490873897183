import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ChronicDiseaseViewControllerService } from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { EnumContext } from '../../context/enum.context';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import { dataDisplayValue } from '../utils/chart-data-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import { chartUseStyles } from '../utils/SimpleCard';
import VitalChartContainer from '../VitalMeasurements/VitalChartContainer';
import ChronicDiseasesContainer from './ChronicDiseasesChartContainer';

export default function Exposures() {
  const [chartData, setChartData] = useState([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();
  const { exposures } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      filter: {
        dateMin: values?.minDate + 'T00:00:00',
        dateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: null,
      },
    };
    const res = await ChronicDiseaseViewControllerService.getChronicDiseaseViews(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Kronik Rahatsızlıklar İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirket Bazlı Dağılımı" />{' '}
                <ChronicDiseasesContainer
                  chartName="chronical-diseases-company-report"
                  rawData={chartData}
                  categoryFieldName="companyName"
                  selectorFieldByName="chronicDiseaseIcd10s[].icd10Label"
                  xAxesTitle="Şirketler"
                  xAxesText="Şirket"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Departman Bazlı Dağılımı" />{' '}
                <ChronicDiseasesContainer
                  chartName="chronical-diseases-department-report"
                  rawData={chartData}
                  categoryFieldName="departmentName"
                  selectorFieldByName="chronicDiseaseIcd10s[].icd10Label"
                  xAxesTitle="Departmanlar"
                  xAxesText="Department"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="İstasyon Bazlı Dağılımı" />{' '}
                <ChronicDiseasesContainer
                  chartName="chronical-diseases-station-report"
                  rawData={chartData}
                  categoryFieldName="stationName"
                  selectorFieldByName="chronicDiseaseIcd10s[].icd10Label"
                  xAxesTitle="İstasyonlar"
                  xAxesText="İstasyon"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Görev Bazlı Dağılımı" />{' '}
                <ChronicDiseasesContainer
                  chartName="chronical-diseases-position-report"
                  rawData={chartData}
                  categoryFieldName="positionName"
                  selectorFieldByName="chronicDiseaseIcd10s[].icd10Label"
                  xAxesTitle="Görevler"
                  xAxesText="Görev"
                  yAxesText=""
                />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
