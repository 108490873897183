import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { addEmployee, updateEmployee, uploadAvatar } from 'api/hs/employee';
import EmployeeInformationForm from 'Common/Forms/EmployeeInformationForm';
import PersonalInformationForm from 'Common/Forms/PersonalInformationForm';
import ProfessionalInformationForm from 'Common/Forms/ProfessionalInformationForm';
import { Employee } from 'model';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { StationExposureControllerService } from '../api/client';
import { PositionExposureControllerService } from '../api/client';
import { AlertContext } from '../context/alert.context';
import Form, { FormRef } from './Form/Form';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
    maxWidth: 680,
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the employee is created/updated
   */
  onClose(success: boolean): void;

  employee: Employee;
}

export default function EditEmployeeDialog({ open, onClose, employee }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, watch, ...formMethods } = useForm();
  const [exposureIds, setExposureIds] = useState([]);
  const [firstAccordionExpanded, setFirstAccordionExpanded] = useState(true);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const handleFirstAccordionChange = useCallback(() => setFirstAccordionExpanded(!firstAccordionExpanded), [firstAccordionExpanded]);

  const handleSubmit = useCallback(
    async values => {
      try {
        let employeeId = employee?.id;

        if (!employee?.id) {
          const res = await addEmployee(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          employeeId = res.id;
        } else {
          await updateEmployee({ id: employee.id, ...values });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }

        if (values.avatar) {
          await uploadAvatar(employeeId, values.avatar);
        }

        reset();
        onClose(true);
      } catch (err) {
        showMessage(err, 'error', 'info');
        onClose(false);
      }
    },
    [employee, onClose, reset, showMessage]
  );

  useAsyncEffect(async () => {
    if (employee) {
      await defer();

      for (const [key, value] of Object.entries(employee)) {
        formRef?.current?.methods?.setValue(key, value === null ? '' : value);
      }

      await formRef?.current?.methods?.trigger();
    }

    formRef?.current?.methods?.setValue('avatar', null);
    await formRef?.current?.methods?.trigger();
  }, [employee]);

  const stationValue = formRef?.current?.methods?.getValues('stationId');
  const positionValue = formRef?.current?.methods?.getValues('positionId');

  useAsyncEffect(async () => {
    if (positionValue) {
      const res = await PositionExposureControllerService.getAllPositionExposure(positionValue);
      setExposureIds(
        employee
          ? [...exposureIds, ...employee?.exposureId, ...res?.reduce((acc, item) => [...acc, item?.exposureId], [])]
          : res?.reduce((acc, item) => [...acc, item?.exposureId], [])
      );
    }
  }, [positionValue, employee]);

  useAsyncEffect(async () => {
    if (stationValue) {
      const res = await StationExposureControllerService.getAllStationExposure(stationValue);
      setExposureIds(
        employee
          ? [...exposureIds, ...employee?.exposureId, ...res?.reduce((acc, item) => [...acc, item?.exposureId], [])]
          : res?.reduce((acc, item) => [...acc, item?.exposureId], [])
      );
    }
  }, [stationValue, employee]);

  useAsyncEffect(async () => {
    formRef?.current?.methods?.setValue('exposureId', [...new Set(exposureIds)]);
    await formRef?.current?.methods?.trigger();
  }, [exposureIds]);

  return (
    <Dialog open={open} keepMounted onClose={() => onClose(false)} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{employee?.id ? 'Personel Düzenle' : 'Yeni Personel Ekle'}</DialogTitle>
      <DialogContent>
        <Form onChange={reRender} onSubmit={handleSubmit} ref={formRef}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Accordion expanded={firstAccordionExpanded} onChange={handleFirstAccordionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Personel Bilgileri</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <EmployeeInformationForm avatarPath={employee?.avatarPath} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Kişisel Bilgiler</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PersonalInformationForm />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Mesleki Bilgiler</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ProfessionalInformationForm />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="primary" className="mr-2">
                {employee?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={() => onClose(false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
