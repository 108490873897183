import { Icon } from '@material-ui/core';
import { defer, difference } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import Button from 'Common/Button';
import Form, { FormRef } from 'Common/Form/Form';
import TreeBox from 'Common/Form/TreeBox';
import { TreeBoxControl } from 'Common/Form/TreeBox/control.model';
import { TreeBoxController } from 'Common/Form/TreeBox/controller.model';
import { ResourceContext } from 'context/resource.context';
import { useAsyncEffect } from 'utils';

import { PlanningTrainingDefinitionModel } from '../../api/hs/trainings';
import { PlanningStep } from './planning-steps.enum';
import { PlanningContext } from './planning.context';

function pluckCheckboxIds(values: Record<string, boolean>) {
  return Object.entries(values)
    .filter(([, val]) => val)
    .map(([key]) => key);
}

export default function List() {
  const { trainingDefinitions, trainingGroupDefinitions, trainingMainGroupDefinitions } = useContext(ResourceContext);
  const { selectedTrainings, setSelectedTrainings, setActiveStep } = useContext(PlanningContext);
  const formRef = useRef<FormRef<Record<PlanningTrainingDefinitionModel['id'], boolean>>>();

  const trainings = useMemo(
    () =>
      trainingMainGroupDefinitions
        .map<TreeBoxController>(main => ({
          label: main.name,
          children: trainingGroupDefinitions
            .filter(group => group.trainingMainGroupDefinitionId === main.id)
            .map<TreeBoxController>(group => ({
              label: group.name,
              children: trainingDefinitions
                .filter(training => training.groupId === group.id)
                .map<TreeBoxControl>(training => ({
                  label: training?.code
                    ? `(${training?.code}) ${training.name} ${training?.isIbysTraining ? '- IBYS Eğitimi' : ''}`
                    : training.name,
                  name: training.id,
                })),
            }))
            .filter(group => !!group.children.length),
        }))
        .filter(main => !!main.children.length),
    [trainingMainGroupDefinitions, trainingGroupDefinitions, trainingDefinitions]
  );

  const handleChange = useCallback((values: Record<string, boolean>) => setSelectedTrainings(pluckCheckboxIds(values)), [
    setSelectedTrainings,
  ]);

  useAsyncEffect(async () => {
    if (!difference(selectedTrainings, pluckCheckboxIds(formRef?.current?.value))?.length) {
      return;
    }
    await defer();
    formRef?.current?.methods?.reset(selectedTrainings?.reduce((acc, training) => ({ ...acc, [training]: true }), {}));
    formRef?.current?.methods?.trigger();
  }, [selectedTrainings]);

  return (
    <Form className="py-2" onChange={handleChange} ref={formRef}>
      <TreeBox controls={trainings} />
      <footer className="text-right">
        <Button
          disabled={!selectedTrainings.length}
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => setActiveStep(PlanningStep.TrainingInfo)}>
          Devam
        </Button>
      </footer>
    </Form>
  );
}
