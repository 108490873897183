import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { TrainingMainGroupContext } from '../../training-main-group.context';
import Action from './Action';
import EditDialog from './EditDialog';
export default function TrainingMainGroupList() {
  const { loading, trainingMainGroup } = useContext(TrainingMainGroupContext);

  return (
    <IndicatorWrapper loading={loading} data={trainingMainGroup} errorMessage="Henüz Bir Eğitim Ana Grubu Eklenmemiş.">
      <List id="trainingGroup">
        {trainingMainGroup?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <Icon style={{ color: 'gray' }} className="icon-school" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText className="text-bold" style={{ color: 'gray' }} primary={item?.name} secondary={item?.description} />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
