import React, { useCallback, useContext } from 'react';

import { MinistryListContext } from '../ministry-list-context';
import PinForm from './PinForm';

export default function PinDialogForm() {
  const { pinCodeDialogOpened, setPinCodeDialogOpened, selectedData, setSelectedData } = useContext(MinistryListContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      setPinCodeDialogOpened(false);
      setSelectedData(null);
    },
    [setSelectedData, setPinCodeDialogOpened]
  );

  return <PinForm open={pinCodeDialogOpened} onClose={closeDialog} data={selectedData} />;
}
