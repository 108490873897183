import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import AvatarInput from 'Common/Form/AvatarInput';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { NID_MASK } from 'model';

import DateInput from '../Form/DateInput';

export interface Props {
  avatarPath?: string;
}

export default function EmployeeInformationForm({ avatarPath = '' }: Props) {
  const { genders, companies } = useContext(SelectOptionsContext);

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <AvatarInput name="avatar" previewUrl={avatarPath} />
      </Grid>
      <Grid item md={6}>
        <TextField name="nid" label="Tc Kimlik Numarası" mask={NID_MASK} maskError="Hatalı kimlik no" required />
        <TextField name="name" label="Personel Adı" required />
      </Grid>
      <Grid item md={6}>
        <DateInput name="birthDate" type="date" label="Doğum Tarihi" required />
      </Grid>
      <Grid item md={6}>
        <Select name="gender" options={genders} label="Cinsiyet" required />
      </Grid>
      <Grid item md={12}>
        <Select name="companyId" options={companies} label="Şirket" required />
      </Grid>
    </Grid>
  );
}
