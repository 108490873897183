import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert, AvatarGroup } from '@material-ui/lab';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { deleteCommitteeMeeting, fileSaveCommitteeMeeting } from 'api/hs/committee/committee-meetings';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { CommitteeMeetingsContentResult } from '../../../../api/hs/committee/committee-meetings';
import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../context/dialog.context';
import { useDateFormat, useXhr } from '../../../../utils';
import { CommitteeMeetingsContext } from '../../committee-meetings.context';

function EmployeeAvatar({ row }: { row: CommitteeMeetingsContentResult }) {
  return (
    <>
      <AvatarGroup max={4}>
        {row?.employees?.map(employee => (
          <Avatar key={employee?.id} src={`/saglik-gozetimi/public/images/Avatars/Orginal/${employee?.avatarPath}`} alt={employee?.name} />
        ))}
      </AvatarGroup>
    </>
  );
}

function ActionComponent({ row }: { row: CommitteeMeetingsContentResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(CommitteeMeetingsContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);
  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteCommitteeMeeting(id);
      await refresh();
    },
    'Seçili Kurul Toplantısı Silindi',
    'Kurul Toplantısı silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu kurul toplantısı silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => fileSaveCommitteeMeeting(row?.id, file)));
      await refresh();
    },
    'Toplantı tutanağı yüklendi',
    'Resim yüklenirken bir hata oluştu',
    [row, refresh]
  );

  const openFileUploadDialog = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({
      maxCount: 1,
      title: 'Toplantı Tutanağı Yükle',
    });

    if (!files) {
      return;
    }

    await uploadFilesReq(files);
  }, [openFileDialog, uploadFilesReq, closeMenu]);

  const routeDetailCommitteeMeetings = useCallback(() => {
    history.push(`/committee/committee-meetings/${row?.id}`);
  }, [row, history]);

  return (
    <div key={row?.id}>
      <IconButton onClick={routeDetailCommitteeMeetings} id={row?.id}>
        <VisibilityIcon />
      </IconButton>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => openFileUploadDialog(row)}>
          <ListItemIcon>
            <AttachmentIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Toplantı Tutanağı</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

function StatusMeetingShowAlert({ row }: { row: CommitteeMeetingsContentResult }) {
  switch (row?.meetingStatusDefinitionLabel) {
    case 'Gerçekleştirildi':
      return (
        <Alert variant="outlined" severity="success">
          {row?.meetingStatusDefinitionLabel}
        </Alert>
      );
    case 'İptal Edildi':
      return (
        <Alert variant="outlined" severity="error">
          {row?.meetingStatusDefinitionLabel}
        </Alert>
      );
    case 'Ertelendi':
      return (
        <Alert variant="outlined" icon={<AccessTimeIcon />} severity="warning">
          {row?.meetingStatusDefinitionLabel}
        </Alert>
      );
    case 'Planlandı':
      return (
        <Alert variant="outlined" severity="info">
          {row?.meetingStatusDefinitionLabel}
        </Alert>
      );
    default:
      return (
        <Alert variant="outlined" severity="info">
          {row?.meetingStatusDefinitionLabel}
        </Alert>
      );
  }
}

export const COLUMNS: TableColumn<CommitteeMeetingsContentResult>[] = [
  {
    id: 'subject',
    width: '15%',
    label: 'Toplantının Adı',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'meetingStatusDefinition',
    width: '10%',
    label: 'Durumu',
    defaultVisibility: true,
    sortable: true,
    render(row: CommitteeMeetingsContentResult): JSX.Element {
      return <StatusMeetingShowAlert row={row} />;
    },
  },
  {
    id: 'plannedDate',
    width: '10%',
    label: 'Planlama Tarihi ve Saati',
    defaultVisibility: true,
    sortable: true,
    render(row: CommitteeMeetingsContentResult): JSX.Element {
      return <DateField date={new Date(row.plannedDate)} />;
    },
  },
  {
    id: 'isFaceToFace',
    width: '10%',
    label: 'Toplantı Şekli',
    defaultVisibility: true,
    sortable: true,
    render(row: CommitteeMeetingsContentResult): JSX.Element {
      return <div>{row.isFaceToFace ? 'Yüz yüze' : 'Uzaktan'}</div>;
    },
  },
  {
    id: 'companyName',
    width: '15%',
    label: 'Şirket',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'employees',
    width: '20%',
    label: 'Katılımcılar',
    defaultVisibility: true,
    render(row: CommitteeMeetingsContentResult): JSX.Element {
      return <EmployeeAvatar row={row} />;
    },
  },
  {
    id: 'userName',
    width: '10%',
    label: 'Oluşturan',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '10%',
    render(row: CommitteeMeetingsContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
