import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { updateCompanyUser } from 'api/hs/definitions/workplace-definition/company-user';
import AutoComplete from 'Common/Form/AutoComplete';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { CompanyUserModel } from 'model/definitions/workplace-definition/company-user.model';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: CompanyUserModel;
}

export default function CompanyUserEditDialog({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { firmUser } = useContext(SelectOptionsContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        const dataPayload = {
          companyId: data.company.id,
          userIds: values.userIds,
        };
        await updateCompanyUser(dataPayload);
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        reset();
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, reset, showMessage]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }
      const usersData: any = [];
      data.users.map((item: any) => usersData.push(item.id));
      setValue('userIds', usersData);

      trigger();
    }
  }, [data, setValue, trigger]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 500 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{data?.company?.companyName}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12}>
                <AutoComplete name="userIds" options={firmUser} label="Kullanıcılar" renderOption="checkbox" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  Güncelle
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
