import React, { useCallback, useContext } from 'react';

import { WorkEquipmentJobStatusContext } from '../../work-equipment-job-status.context';
import FilesAddForm from '../FilesAddForm';

export default function EditDialog() {
  const { fileDialogOpened, setFileDialogOpened, selectedData } = useContext(WorkEquipmentJobStatusContext);

  const closeDialog = useCallback(async () => {
    setFileDialogOpened(false);
  }, [setFileDialogOpened]);

  return <FilesAddForm open={fileDialogOpened} onClose={closeDialog} data={selectedData} />;
}
