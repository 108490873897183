import { asyncMap, tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { getUserById } from '../../users';
import { BodyMeasurementsPayload } from './body-measurements.payload';
import { BodyMeasurementsResult } from './body-measurements.result';

export async function getBodyMeasurements(employeeId: string, examinationId?: string): Promise<BodyMeasurementsResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<BodyMeasurementsResult[]>(`/body-measurements?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<BodyMeasurementsResult[]>(`/body-measurements?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return await asyncMap(res.data, async item => ({
    ...item,
    userData: item?.createdBy ? await getUserById(item?.createdBy) : '',
  }));
}

export async function saveBodyMeasurements(body: BodyMeasurementsPayload): Promise<BodyMeasurementsResult> {
  const [err, res] = await tryCatch(HSApi.post('/body-measurements', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateBodyMeasurements({
  id,
  ...body
}: MakeRequired<Partial<BodyMeasurementsResult>, 'id'>): Promise<BodyMeasurementsResult> {
  const [error, res] = await tryCatch(HSApi.put<BodyMeasurementsResult>(`/body-measurements/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteBodyMeasurements(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/body-measurements/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
