import { Dialog as MUIDialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import { useAsyncEffect } from 'utils';

import { ListItem, StoryContext } from './history.context';

export default function Dialog() {
  const { dialogOpened, setDialogOpened, selectedItem, setSelectedItem, title, save, update, form } = useContext(StoryContext);
  const { setValue, trigger, reset, ...methods } = useForm();

  const clear = useCallback(async () => {
    setSelectedItem(new ListItem());
  }, [setSelectedItem]);

  const cancel = useCallback(() => {
    setDialogOpened(false);
    clear();
  }, [setDialogOpened, clear]);

  const upsert = useCallback(
    async (formValue: Omit<ListItem, 'id'>) => {
      if (selectedItem?.id) {
        await update({ ...formValue, id: selectedItem.id });
      } else {
        await save(formValue);
      }
      setDialogOpened(false);
      clear();
    },
    [selectedItem, save, update, setDialogOpened, clear]
  );

  useAsyncEffect(async () => {
    await defer();

    if (selectedItem?.id) {
      const { id, ...values } = selectedItem;

      for (const [key, value] of Object.entries(values)) {
        setValue(key, value);
      }

      trigger();
    } else {
      reset();
    }
  }, [selectedItem, setValue, trigger, reset]);

  return (
    <MUIDialog open={dialogOpened} fullWidth maxWidth="sm" keepMounted onClose={cancel}>
      <DialogTitle className="dialog-title">{selectedItem?.id ? title + ' Düzenle' : title + ' Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, setValue, trigger, reset }}>
          <form onSubmit={methods.handleSubmit(upsert)}>
            <Grid container spacing={3}>
              {form}
              <Grid item md={12} className="text-right">
                <Button className="mr-2" variant="contained" type="button" onClick={cancel}>
                  Vazgeç
                </Button>
                <Button variant="contained" color={selectedItem?.id ? 'secondary' : 'primary'} type="submit">
                  {selectedItem?.id ? 'Düzenle' : 'Kaydet'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </MUIDialog>
  );
}
