import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { Storage as StorageIcon } from '@material-ui/icons';
import React from 'react';

import CoreToolbar from '../../../Common/Toolbar';
import Add from './Add';
import List from './List';
import Search from './Search';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function Index() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CoreToolbar>
        <IconButton className={`mr-1 ml-n2 text-white`}>
          <StorageIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className="text-bold">
          Ayarlar
        </Typography>
      </CoreToolbar>
      <Add />
      <Search />
      <div style={{ padding: '5px' }}>
        <List />
      </div>
    </div>
  );
}
