import React, { PropsWithChildren, useContext, useState } from 'react';

import { TableContextType } from '../Raw/table.context';
import PaginationAndTableContextProvider from '../WithPagination/PaginationAndTableContextProvider';
import { TableWithPaginationProps } from '../WithPagination/table-with-pagination.props';
import { HSTableContext } from './hs-table.context';
import { HSTableProps } from './hs-table.props';
import HSTableContextProvider from './HSTableContextProvider';

function LowerContextWrapper<T>({
  children,
  onSortChange,
  ...otherProps
}: PropsWithChildren<Omit<TableWithPaginationProps<T>, 'data' | 'onPageChange' | 'onSizeChange' | 'page' | 'size' | 'totalPage'>>) {
  const { data, setPage, setSize, page, size, totalPage } = useContext(HSTableContext);

  return (
    <PaginationAndTableContextProvider
      onSortChange={onSortChange}
      {...{ data, onPageChange: setPage, onSizeChange: setSize, page, size, totalPage }}
      {...otherProps}>
      {children}
    </PaginationAndTableContextProvider>
  );
}

export default function HSTableAndPaginationAndTableContextProvider<T>({
  children,
  fetcher,
  defaultExtraArgs = {},
  ...props
}: PropsWithChildren<HSTableProps<T>>) {
  const [sorting, setSorting] = useState<TableContextType<T>['sort']>();

  return (
    <HSTableContextProvider fetcher={fetcher} sorting={sorting} defaultExtraArgs={defaultExtraArgs}>
      <LowerContextWrapper onSortChange={setSorting} {...props}>
        {children}
      </LowerContextWrapper>
    </HSTableContextProvider>
  );
}
