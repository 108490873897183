import React from 'react';

import FirmAdd from './add';
import FirmsList from './list';
export default function Firms() {
  return (
    <>
      <FirmAdd />
      <FirmsList />
    </>
  );
}
