import { InputAdornment, InputBaseComponentProps, makeStyles } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField';
import React, { useContext, useEffect, useMemo } from 'react';
import { ControllerProps, useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

import { combineFunctions, usePrevious, useTranslate } from 'utils';

import { FormContext } from './Form/form.context';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  helper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  label: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  labelRight: {
    left: 'initial',
    right: 0,
  },
}));

export type InputRules = ControllerProps<'input'>['rules'];

interface Props {
  name: string;
  label?: string;
  type?: 'text' | 'password' | 'email' | 'date' | 'number' | 'time' | 'datetime-local';
  variant?: 'standard' | 'outlined' | 'filled';
  rows?: number;
  rules?: InputRules;
  className?: string;
  mask?: (RegExp | string)[];
  maskError?: string;
  required?: boolean;
  shrinkAlways?: boolean;
  disabled?: boolean;
  endAdornment?: JSX.Element | string;
  position?: 'start' | 'end';
  defaultValue?: string;
}

export default function TextField({
  name,
  type = 'text',
  label = '',
  variant = 'standard',
  rows = 0,
  rules = {},
  className = '',
  mask,
  maskError = 'Hatalı girdi',
  required = false,
  disabled = false,
  shrinkAlways = false,
  endAdornment = null,
  position = 'start',
  defaultValue,
}: Props) {
  const classes = useStyles();
  const { register, getValues, trigger, errors, setValue } = useFormContext();
  const value = getValues(name);
  const { readonly, register: _register, unregister } = useContext(FormContext);
  const translate = useTranslate('form');

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);
  const previousNotEditable = usePrevious(notEditable);
  const mergedClassName = useMemo(() => `${classes.root} ${className}`, [classes.root, className]);

  const inputComponent = useMemo(
    () => ({ inputRef, ...props }: InputBaseComponentProps) => (
      <MaskedInput
        mask={mask}
        {...props}
        render={(ref, inputProps) => (
          <input
            {...inputProps}
            ref={combineFunctions(inputRef, ref)}
            onChange={e => {
              inputProps.onChange(e);
              setValue(name, e.target.value);
            }}
            disabled={notEditable}
          />
        )}
      />
    ),
    [mask, name, setValue, notEditable]
  );

  rules = useMemo<InputRules>(() => {
    return {
      ...rules,
      ...(!required
        ? null
        : {
            required: 'Bu alan zorunlu',
          }),
      // validate: {
      //   ...rules.validate,
      //   ...(!mask
      //     ? null
      //     : {
      //         mask: val =>
      //           !val || mask.every((rule, index) => (typeof rule === 'string' ? rule === val[index] : rule.test(val[index]))) || maskError,
      //       }),
      // },
    };
  }, [rules, required]);

  useEffect(() => {
    if (previousNotEditable === notEditable) {
      return;
    }

    _register(name, { disabled: notEditable });
  }, [name, _register, notEditable, previousNotEditable]);

  useEffect(
    () => () => {
      unregister(name);
    },
    [name, unregister]
  );

  return (
    <MUITextField
      fullWidth
      InputLabelProps={{
        shrink: shrinkAlways || type === 'date' || type === 'time' || type === 'datetime-local' || !!value,
        className: `${classes.label} ${position === 'end' ? classes.labelRight : ''}`,
      }}
      InputProps={{
        ...{
          disabled,
          ...(mask ? { inputComponent } : null),
          ...(endAdornment ? { endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> } : null),
          inputProps: {
            className: `${position === 'end' ? 'text-right ' : ''}`,
          },
        },
      }}
      defaultValue={defaultValue}
      error={!notEditable && !!errors[name]}
      inputRef={register(rules) as any}
      helperText={!notEditable && errors[name]?.message}
      onChange={() => trigger()}
      name={name}
      type={type}
      label={translate(label)}
      multiline={rows > 0}
      rows={rows}
      variant={variant}
      className={mergedClassName}
      FormHelperTextProps={{ className: classes.helper }}
      required={required}
    />
  );
}
