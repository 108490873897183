import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import { BuildingsFloorsPartsContext } from '../../buildings-floors-parts.context';
import BuildingsFloorPartForm from '../Form';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: '40px',
    height: '40px',
    color: 'white',
    float: 'right',
    marginRight: '5px',
    marginTop: '-92px',
    backgroundColor: '#eb4355',
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));

export default function Add() {
  const classes = useStyles();
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(BuildingsFloorsPartsContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Fab onClick={onClick} className={classes.fabButton}>
        <AddIcon className={classes.addButton} />
      </Fab>
      <BuildingsFloorPartForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
