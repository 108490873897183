/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HazardCurrentPrecautionDefinitionDto } from '../models/HazardCurrentPrecautionDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class HazardCurrentPrecautionDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static updateHazardCurrentPrecautionDefinitions(
        id: string,
        requestBody: HazardCurrentPrecautionDefinitionDto,
    ): CancelablePromise<HazardCurrentPrecautionDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/precaution-level-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllHazardCurrentPrecautionDefinitions(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/risk-assessments/precaution-level-definitions/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmHazardCurrentPrecautionDefinitions(
        id: string,
        requestBody: HazardCurrentPrecautionDefinitionDto,
    ): CancelablePromise<HazardCurrentPrecautionDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/risk-assessments/precaution-level-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmHazardCurrentPrecautionDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/risk-assessments/precaution-level-definitions/${id}`,
        });
    }

    /**
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static getAllHazardCurrentPrecautionDefinitions(): CancelablePromise<Array<HazardCurrentPrecautionDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/precaution-level-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static saveHazardCategoryDefinitions(
        requestBody: HazardCurrentPrecautionDefinitionDto,
    ): CancelablePromise<HazardCurrentPrecautionDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/precaution-level-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static getAllFirmHazardCurrentPrecautionDefinitions(): CancelablePromise<Array<HazardCurrentPrecautionDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/risk-assessments/precaution-level-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns HazardCurrentPrecautionDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmHazardCurrentPrecautionDefinitions(
        requestBody: HazardCurrentPrecautionDefinitionDto,
    ): CancelablePromise<HazardCurrentPrecautionDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/risk-assessments/precaution-level-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}