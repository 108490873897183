import { createContext } from 'react';

import { DefaultNurseOperationsResult } from 'api/hs/nurse-operations/default-nurse-operations';

export interface DefaultNurseOperationsContextType {
  defaultNurseOperations: DefaultNurseOperationsResult[];

  loading: boolean;

  filter: string | number;

  setLoading(loading: boolean): void;

  setFilter(filter: 'all' | number): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;
}

export const DefaultNurseOperationContext = createContext<DefaultNurseOperationsContextType>(null);
