import { createContext } from 'react';

import { BaseEmployee } from 'model';

export interface EmployeeSelectionContextType {
  name: string;
  label: string;
  single: boolean;
  disabled: boolean;
  placeholder: string;
}

export const EmployeeSelectionContext = createContext<EmployeeSelectionContextType>(null);

export interface EmployeeSelectionCacheContextType {
  employees: BaseEmployee[];
  options: BaseEmployee['id'][];
  names: Record<BaseEmployee['id'], string>;
  avatars: Record<BaseEmployee['id'], string>;
  contentStrings: Record<BaseEmployee['id'], string>;
  getEmployees(search: string): void;
  loadEmployees(...ids: BaseEmployee['id'][]): void;
}

export const EmployeeSelectionCacheContext = createContext<EmployeeSelectionCacheContextType>(null);
