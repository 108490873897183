import { Button, Grid } from '@material-ui/core';
import React, { useContext, useRef } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import { WorkingHoursExportTemplate } from '../../api/hs/working-hours';
import Dialog from '../../Common/Dialog';
import AutoComplete from '../../Common/Form/AutoComplete';
import DateInput from '../../Common/Form/DateInput';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';

interface Props {
  open: boolean;
  onClose(): void;
}
export default function WorkingHoursExport({ open, onClose }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { companies } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);

  const onSubmit = async (values: any) => {
    try {
      await WorkingHoursExportTemplate(values);
      showMessage('Başarılı ile aktarıldı', 'success', 'info');
      onClose();
    } catch (e) {
      showMessage('Dışarı aktarırken bir sorunla karşılaştı.', 'error', 'info');
      onClose();
    }
  };

  return (
    <Dialog color="secondary" width="sm" opened={open} onClose={() => onClose()} title={'Çalışma Planı Dışarı Aktar'}>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} lg={12} xl={12}>
            <AutoComplete required single name="companyId" options={companies} label="Şirketler" />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xl={6}>
            <DateInput variant="outlined" name="startDate" type="date" label="Başlangıç" />
          </Grid>
          <Grid item md={6} sm={6} lg={6} xl={6}>
            <DateInput variant="outlined" name="endDate" type="date" label="Bitiş" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {'Aktar'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose()}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
