/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NurseOperationFilter } from './NurseOperationFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type NurseOperationViewRequest = {
    filter?: NurseOperationFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    exposeType?: NurseOperationViewRequest.exposeType;
}

export namespace NurseOperationViewRequest {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
