import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { dateDisplayFormat } from '../../../../../../utils';
import { HealthInsurancesContext } from '../../health-insurances.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function ListDefaultNurseOperationDetail() {
  const { loading, healthInsurances } = useContext(HealthInsurancesContext);

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={healthInsurances} errorMessage="Gösterilecek Özel Sağlık Sigortası Kayıtı Bulunamadı">
        <List key="hs-defaultNurseOperationDetail">
          {healthInsurances?.map((item, index) => (
            <Fragment key={index}>
              <ListItem key={index}>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-tag-multiple" />
                </ListItemAvatar>
                <ListItemText
                  primary={item?.agencyName + ' - ' + item?.policyNumber}
                  secondary={dateDisplayFormat(item?.startDate) + ' ' + dateDisplayFormat(item?.endDate)}
                />
                <ListItemSecondaryAction>
                  <Action data={item} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
