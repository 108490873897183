import React from 'react';
import { Route, Switch } from 'react-router';

import BuildingsDefinition from './BuildingsDefinition';

export default function WorkEquipmentDefinitionRoute() {
  return (
    <Switch>
      <Route path="" exact>
        <BuildingsDefinition />
      </Route>
    </Switch>
  );
}
