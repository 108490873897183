import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router';

import { PregnancyWidgetItem } from '../../../../api/hs/dashboard/widget/widget.payload';
import { datePrettier } from '../../../../utils';

interface Props {
  users: PregnancyWidgetItem[];
}

const Check = (props: any) => {
  return props.checked ? <Checkbox checked disabled /> : <CheckBoxOutlineBlank />;
};

export default ({ users }: Props) => {
  const history = useHistory();

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}`);
  };

  if (!users || users.length === 0) return <b>Kayıt bulunamadı!</b>;

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Ad Soyad</TableCell>
            <TableCell className="text-bold">TC Kimlik</TableCell>
            <TableCell className="text-bold">Doğum Tarihi</TableCell>
            <TableCell className="text-bold">Kan Uyuşmazlığı</TableCell>
            <TableCell className="text-bold">Çoklu Doğum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: PregnancyWidgetItem) => (
            <TableRow key={user?.nid} hover onClick={() => handleClick(user.employeeId)}>
              <TableCell scope="row">{user.name}</TableCell>
              <TableCell>{user.nid}</TableCell>
              <TableCell>{datePrettier(user.birthDate)}</TableCell>
              <TableCell>
                <Check checked={user.bloodTypeIncompatibility} />
              </TableCell>
              <TableCell>
                <Check checked={user.multipleBirth} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
