import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { getProfessionalBackgrounds } from 'api/hs/examination';
import Button from 'Common/Button';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { ProfessionalBackground as ProfessionalBackgroundModel } from 'model';
import { useAsyncEffect } from 'utils';

import { ProfessionalBackgroundControllerService } from '../../../../api/client';
import Dialog from './Dialog';
import List from './List';
import { ProfessionalBackgroundContext, ProfessionalBackgroundContextType } from './professional-background.context';

export default function ProfessionalBackground() {
  const [backgrounds, setBackgrounds] = useState<ProfessionalBackgroundModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedBackground, setSelectedBackground] = useState<ProfessionalBackgroundModel>();
  const [dialogOpened, setDialogOpened] = useState(false);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const refresh = useCallback(async () => {
    setLoading(true);
    const proBackGround = await ProfessionalBackgroundControllerService.getAllProfessionalBackgrounds(employeeId, examinationId);
    setBackgrounds(proBackGround.map(b => new ProfessionalBackgroundModel(b)));
    setLoading(false);
  }, [employeeId, examinationId]);

  const openAddDialog = useCallback(() => {
    setSelectedBackground(null);
    setDialogOpened(true);
  }, []);

  const context = useMemo<ProfessionalBackgroundContextType>(
    () => ({ backgrounds, dialogOpened, setDialogOpened, selectedBackground, setSelectedBackground, refresh }),
    [backgrounds, dialogOpened, selectedBackground, refresh]
  );

  useAsyncEffect(async () => {
    refresh();
  }, [refresh]);

  return (
    <ProfessionalBackgroundContext.Provider value={context}>
      <Dialog />
      <IndicatorWrapper data={backgrounds} loading={loading} errorMessage="Henüz Bir Mesleki Özgeçmiş Eklenmemiş.">
        <List />
      </IndicatorWrapper>
      <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={openAddDialog} className="mt-2">
        MESLEKİ ÖZGEÇMİŞ EKLE
      </Button>
    </ProfessionalBackgroundContext.Provider>
  );
}
