/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExaminationFilterDto } from '../models/ExaminationFilterDto';
import type { NotificationRequest } from '../models/NotificationRequest';
import type { PageExaminationView } from '../models/PageExaminationView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ExaminationViewControllerService {

    /**
     * @param requestBody
     * @returns PageExaminationView OK
     * @throws ApiError
     */
    public static filterExaminations(
        requestBody?: ExaminationFilterDto,
    ): CancelablePromise<PageExaminationView> {
        return __request({
            method: 'POST',
            path: `/examinations/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static examinationFilterViewExport1(
        requestBody: ExaminationFilterDto,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/examinations/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static examinationOpinionSendMail(
        requestBody: NotificationRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/examinations/opinion/view/notification`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static examinationOpinionExportPdf(
        id: string,
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'PDF',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/examinations/${id}/opinion/view/export`,
            query: {
                'type': type,
            },
        });
    }

}