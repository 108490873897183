import { Container, Grid, Paper, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import {
  AccidentReviewControllerService,
  AccidentReviewerControllerService,
  AccidentReviewerDto,
  UserControllerService,
} from '../../../api/client';
import AlertInformation from '../../../Common/AlertInformation';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { useFetch } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import Action from '../Action';
import Analysis from '../Analysis';
import Communication from '../Communication';
import Research from '../Research';
import ExportAccidentReview from './ExportAccidentReview';
import ExportAccidentReviewCommunication from './ExportAccidentReviewCommunication';
import UpdateAccidentReviewStatus from './UpdateAccidentReviewStatus';

interface Props {
  accidentId: string;
}

export default ({ accidentId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data, error, fetch } = useFetch(
    () => AccidentReviewControllerService.getReview(accidentId, null, null),
    {},
    {
      setLoading,
    }
  );

  const { data: employeeInfo } = useFetch(() => UserControllerService.getUserEmployeeInfo(), undefined, {
    setLoading,
  });

  const { data: reviewer } = useFetch(
    () =>
      AccidentReviewerControllerService.validateReviewer(
        {
          employeeId: employeeInfo.employeeId,
        },
        null
      ),
    undefined,
    {
      setLoading,
    }
  );

  const [activeStep, setActiveStep] = useState(0);

  return (
    <AccidentReviewContext.Provider
      value={{
        insertable: [AccidentReviewerDto.reviewerType.LEAD, AccidentReviewerDto.reviewerType.USER].includes(reviewer?.reviewerType),
        updatable: [AccidentReviewerDto.reviewerType.LEAD].includes(reviewer?.reviewerType),
        reviewer: undefined,
        token: undefined,
        accidentId,
      }}>
      {error && <AlertInformation severity="warning" message="Henüz kaza inceleme başlatılmamış." />}
      {data && (
        <>
          <IndicatorWrapper loading={loading} data={data} errorMessage={'Bu alana erişim için yetkiniz bulunmamaktadır.'}>
            <Grid container>
              <Container>
                <Paper elevation={5}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={0} onClick={() => setActiveStep(0)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Araştırma
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Research />
                      </StepContent>
                    </Step>
                    <Step key={1} onClick={() => setActiveStep(1)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Analiz
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Analysis />
                      </StepContent>
                    </Step>
                    <Step key={2} onClick={() => setActiveStep(2)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          Aksiyon
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Action />
                      </StepContent>
                    </Step>
                    <Step key={3} onClick={() => setActiveStep(3)}>
                      <StepLabel>
                        <Typography variant="h5" component="h5">
                          İletişim
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Communication />
                      </StepContent>
                    </Step>
                  </Stepper>
                </Paper>
              </Container>
            </Grid>
          </IndicatorWrapper>

          <UpdateAccidentReviewStatus completed={data.completed} accidentId={accidentId} fetch={fetch} />
          <ExportAccidentReview accidentId={accidentId} />
          {data.communication && <ExportAccidentReviewCommunication accidentId={accidentId} />}
        </>
      )}
    </AccidentReviewContext.Provider>
  );
};
