import React from 'react';
import { Route, Switch } from 'react-router';

import NearMisses from './NearMisses/NearMiss';
import NearMissDetail from './NearMisses/NearMissDetail';
import NearMissRecord from './NearMissRecord';

export default function NearMiss() {
  return (
    <Switch>
      <Route exact path="/near-miss/list/:nearMissId">
        <NearMissDetail />
      </Route>
      <Route exact path="/near-miss/list/">
        <NearMisses />
      </Route>
      <Route path="/near-miss/form">
        <NearMissRecord />
      </Route>
    </Switch>
  );
}
