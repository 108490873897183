import { Table as MUITable, TableContainer } from '@material-ui/core';
import { arrayize, isTruthy } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useMemo } from 'react';

import RawBody from './Body';
import DefaultBody from './DefaultBody';
import DefaultHead from './DefaultHead';
import RawFoot from './Foot';
import RawHead from './Head';

export default function Table({ children }: PropsWithChildren<{}>) {
  const parsedChildren = useMemo<React.ReactNode & { type?: any }[]>(() => (children ? arrayize(children) : []), [children]).filter(
    isTruthy
  );

  const head = useMemo<React.ReactNode>(() => parsedChildren.find(({ type }) => type === RawHead), [parsedChildren]);
  const body = useMemo<React.ReactNode>(() => parsedChildren.find(({ type }) => type === RawBody), [parsedChildren]);
  const foot = useMemo<React.ReactNode>(() => parsedChildren.find(({ type }) => type === RawFoot), [parsedChildren]);

  return (
    <TableContainer>
      <MUITable>
        {head || <DefaultHead />}
        {body || <DefaultBody />}
        {foot}
      </MUITable>
    </TableContainer>
  );
}
