import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteDisability, DisabilityResult, getDisability } from 'api/hs/definitions/health-definitions/disability';
import DisabilitEditDialog from 'Common/Forms/Definitions/HealthDefinition/Disability/DisabilitEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Disability() {
  return (
    <BaseDefinitionPage<DisabilityResult>
      listLabelPropName="name"
      fetchData={getDisability}
      FormDialog={DisabilitEditDialog}
      onDelete={deleteDisability}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
