import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { ResourceContext } from '../../../../../context/resource.context';
import { FirmSubscriptionsContext } from '../../firm-subscription.context';
import EditDialog from './EditDialog';

export default function ListFirmSubscriptions() {
  const { loading, firmSubscriptions, setEditDialogOpened, setSelectedData } = useContext(FirmSubscriptionsContext);
  const { firms } = useContext(ResourceContext);

  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={firmSubscriptions} errorMessage="Gösterilecek Firma Kayıt Bilgisi Bulunamadı">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold" width="5%" align="left" />
                <TableCell style={{ color: 'gray' }} className="text-bold" width="35%" align="left">
                  Firma Adı
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  Başlangıç Tarihi
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="15%" align="left">
                  Bitiş Durumu
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="10%" align="left">
                  Durum
                </TableCell>
                <TableCell style={{ color: 'gray' }} className="text-bold" width="20%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firmSubscriptions.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <AccountBalanceIcon style={{ color: 'gray' }} />
                  </TableCell>
                  <TableCell align="left">{findByKey(firms, 'id', item?.firmId)?.firmName}</TableCell>
                  <TableCell align="left">{item?.subscriptionStart}</TableCell>
                  <TableCell align="left">{item?.subscriptionEnd}</TableCell>
                  <TableCell align="left">
                    {item?.active ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
