import { Avatar, Icon, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AvatarGroup } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { RiskAssessmentHazardView } from 'api/client';
import { RiskAssessmentHazardControllerService } from 'api/client';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { PpeOutputsContentResult } from '../../../../api/hs/ppe/ppe-outputs';
import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../context/dialog.context';
import { ResourceContext } from '../../../../context/resource.context';
import { useDateFormat, useXhr } from '../../../../utils';
import { RiskAssessmentsListContext } from '../../risk-assessments-list.context';

function ActionComponent({ row }: { row: RiskAssessmentHazardView }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog } = useContext(DialogContext);
  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await RiskAssessmentHazardControllerService.deleteRiskAssessmentHazard(id);
      await refresh();
    },
    'Seçili Risk Değerlendirme Silindi',
    'Risk Değerlendirme silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu kayıtı silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const routeUpdateRiskAssessment = useCallback(
    item => {
      history.push(`/risk/risk-assessments-form/${item?.id}`);
    },
    [history]
  );

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => routeUpdateRiskAssessment(row)}>
          <ListItemIcon>
            <EditIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

const matrixRiskColor = (value: number) => {
  if (value === 1) {
    return '#78c6a3';
  } else if (value >= 2 && value <= 6) {
    return '#78c6a3';
  } else if (value > 6 && value < 16) {
    return '#FFCC1D';
  } else if (value >= 16 && value <= 20) {
    return '#D00000';
  } else if (value > 20) {
    return '#9D0208';
  } else {
    return '#78c6a3';
  }
};

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}

function EmployeeAvatar({ row }: { row: RiskAssessmentHazardView }) {
  return (
    <>
      <AvatarGroup max={4}>
        {row?.riskAssessmentEmployees?.map(employee => (
          <Avatar
            key={employee?.id}
            src={`/saglik-gozetimi/public/images/Avatars/Orginal/${employee?.employeeAvatarPath}`}
            alt={employee?.employeeName}
          />
        ))}
      </AvatarGroup>
    </>
  );
}

function ActionList({ row }: { row: RiskAssessmentHazardView }) {
  const { setActionDialogOpened, setSelectedData } = useContext(RiskAssessmentsListContext);

  const onClick = useCallback(() => {
    setActionDialogOpened(true);
    setSelectedData(row);
  }, [row, setActionDialogOpened, setSelectedData]);

  return (
    <div key={row?.id}>
      <IconButton disabled={!row.actionCountNumber} onClick={onClick}>
        <Icon className="icon-human-handsup" />
        {row.actionCountNumber ? row.actionCountNumber : null}
      </IconButton>
    </div>
  );
}

function ScoreTooltip({ score }: { score: string }) {
  const { riskAssessmentMatrixScoreDefinition } = useContext(ResourceContext);
  return (
    <Tooltip title={findByKey(riskAssessmentMatrixScoreDefinition, 'score', score)?.label}>
      <div
        style={{
          color: `${matrixRiskColor(score)}`,
          fontWeight: 'bold',
          fontSize: '18px',
        }}>
        {score}
      </div>
    </Tooltip>
  );
}

export const COLUMNSMATRIX: TableColumn<RiskAssessmentHazardView>[] = [
  {
    id: 'riskAssessmentEmployees',
    width: '15%',
    label: 'Ekip Üyeleri',
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element {
      return <EmployeeAvatar row={row} />;
    },
  },
  {
    id: 'departmentName',
    width: '15%',
    label: 'Departman',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'companyName',
    width: '10%',
    label: 'Şirket',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'riskDescription',
    width: '5%',
    label: 'Risk Tanımı',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'hazardDescription',
    width: '5%',
    label: 'Tehlike Tanımı',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'hazardCategoryName',
    width: '5%',
    label: 'Tehlike Kategori',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'date',
    width: '10%',
    label: 'Tarih',
    sortable: true,
    defaultVisibility: true,
    render(row: PpeOutputsContentResult): JSX.Element {
      return <DateField date={new Date(row.date)} />;
    },
  },
  {
    id: 'positionName',
    width: '10%',
    sortable: true,
    label: 'Pozisyon',
    defaultVisibility: true,
  },
  {
    id: 'olasılık',
    width: '5%',
    label: 'Olasılık',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | string {
      return (
        <>
          <Tooltip title={row?.riskAssessmentMatrixScore?.possibilityLabel}>
            <div> {row?.riskAssessmentMatrixScore?.possibilityValue}</div>
          </Tooltip>
        </>
      );
    },
  },
  {
    id: 'siddet',
    width: '5%',
    label: 'Şiddet',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | string {
      return (
        <>
          <Tooltip title={row?.riskAssessmentMatrixScore?.damageLabel}>
            <div>{row?.riskAssessmentMatrixScore?.damageValue}</div>
          </Tooltip>
        </>
      );
    },
  },
  {
    id: 'skor',
    width: '5%',
    label: 'Skor',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | number {
      return <ScoreTooltip score={row?.riskAssessmentMatrixScore?.scoreValue} />;
    },
  },

  {
    id: 'bakiyeolasilik',
    width: '5%',
    label: 'B.R. Olasılık',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | number {
      return (
        <>
          <Tooltip title={row?.riskAssessmentMatrixScore?.finalPossibilityLabel}>
            <div> {row?.riskAssessmentMatrixScore?.finalPossibilityValue}</div>
          </Tooltip>
        </>
      );
    },
  },

  {
    id: 'bakiyesiddet',
    width: '5%',
    label: 'B.R. Şiddet',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | number {
      return (
        <>
          <Tooltip title={row?.riskAssessmentMatrixScore?.finalDamageLabel}>
            <div> {row?.riskAssessmentMatrixScore?.finalDamageValue}</div>
          </Tooltip>
        </>
      );
    },
  },

  {
    id: 'bakiyeskor',
    width: '5%',
    label: 'B.R Skor',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element | number {
      return <ScoreTooltip score={row?.riskAssessmentMatrixScore?.finalScoreValue} />;
    },
  },
  {
    id: 'riskassigmentactions',
    width: '5%',
    label: 'Aksiyonları',
    sortable: false,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): JSX.Element {
      return <ActionList key={row?.id} row={row} />;
    },
  },

  {
    id: 'revisePeriod',
    width: '5%',
    label: 'Revizyon Sıklığı (ay)',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'reviseDate',
    width: '5%',
    label: 'Revize Tarihi',
    sortable: true,
    defaultVisibility: true,
    render(row: RiskAssessmentHazardView): string {
      return row?.reviseDate?.split('-')?.reverse()?.join('-');
    },
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '5%',
    render(row: RiskAssessmentHazardView): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
