import { createContext } from 'react';

import { UseDialogState } from 'utils';

import { GenericUserEvent } from '../../api/hs/dashboard/event/event.payload';

export interface CalendarContextType {
  title: string;
  startDate: Date;
  endDate: Date;
  goPrev(): void;
  goNext(): void;
  reload(): void;
  event: GenericUserEvent;
  eventId: string;
  newEventDialog: UseDialogState;
  updateEventDialog: UseDialogState;
  eventPreviewDialog: UseDialogState;
}

export const CalendarContext = createContext<CalendarContextType>(null);
