import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { TrainingDetailResult } from './training-detail.result';

export async function getTrainings(): Promise<TrainingDetailResult[]> {
  const res = await HSApi.get<TrainingDetailResult[]>(`/trainings`);

  return res.data;
}

export async function patchTraining(trainingId: string, body: any) {
  const [err, res] = await tryCatch(HSApi.patch(`/trainings/${trainingId}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
