import React from 'react';

import { VaccineListContentResult } from 'api/hs/TransactionAndLists/VaccineList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { ReportListContentResult } from '../../api/hs/TransactionAndLists/ReportList';
import { dateDisplayFormat } from '../../utils';
import Avatar from '../EmployeeList/Avatar';

export const CELL: TableColumn<VaccineListContentResult>[] = [
  {
    id: 'avatarPath',
    render(row: ReportListContentResult): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: true,
    width: '5%',
  },
  {
    id: 'nid',
    defaultVisibility: true,
    label: 'TC No',
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '20%',
  },
  {
    id: 'vaccineName',
    defaultVisibility: true,
    label: 'Aşı Adı',
    sortable: true,
    width: '5%',
  },
  {
    id: 'bloodTypeName',
    defaultVisibility: true,
    label: 'Kan Grubu',
    sortable: true,
    width: '5%',
  },
  {
    id: 'order',
    defaultVisibility: true,
    label: 'Doz',
    sortable: true,
    width: '5%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '20%',
  },
  {
    id: 'departmentName',
    defaultVisibility: true,
    label: 'Departman',
    sortable: true,
    width: '20%',
  },
  {
    id: 'isApproved',
    defaultVisibility: true,
    label: 'Durum',
    sortable: true,
    width: '5%',
  },
  {
    id: 'date',
    defaultVisibility: true,
    label: 'Yapıldığı Tarih',
    sortable: true,
    width: '5%',
    render(row: VaccineListContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.date)}</>;
    },
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Ekleyen',
    sortable: true,
    width: '10%',
  },
];
