import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { StorehouseDefinitionsPayload } from './storehouse-definitions.payload';
import { StorehouseDefinitionsResult } from './storehouse-definitions.result';

export async function getStorehouseDefinitions(): Promise<StorehouseDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<StorehouseDefinitionsResult[]>(`/ppe/storehouse-definitions?withSupervisors=true`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveStorehouseDefinition(body: StorehouseDefinitionsPayload): Promise<StorehouseDefinitionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/ppe/storehouse-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateStorehouseDefinition({
  id,
  ...body
}: MakeRequired<Partial<StorehouseDefinitionsResult>, 'id'>): Promise<StorehouseDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<StorehouseDefinitionsResult>(`/ppe/storehouse-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteStorehouseDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe/storehouse-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
