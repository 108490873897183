import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { exportReportedEmployee, getBackReportedEmployee, getReportedEmployee } from '../../../../api/hs/dashboard/widget/widget';
import { DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import EmployeeTable from './EmployeeTable';

const fileNames = { reported: 'raporlu.xlsx', missed: 'rapordan-bugun-donen.xlsx' };

export default () => {
  const [tab, setTab] = useState<string>('reported');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: reported } = useFetch(() => getReportedEmployee(new DateRange(dateRange.start, dateRange.end)), [], { setLoading });
  const { data: missed } = useFetch(() => getBackReportedEmployee(), [], { setLoading });

  const tabs = { reported, missed };

  const {
    employee: { close, opened },
    employeeDateRange: { dateRange },
  } = useContext(DialogContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'reported') {
      exportReportedEmployee(new DateRange(dateRange.start, dateRange.end), fileNames[tab]).then(noop);
      return;
    }

    if (tab === 'missed') {
      exportReportedEmployee(new DateRange(null, null), fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Raporlu Personel" color={colors.employee} onClick={tabs[tab].length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Raporlu Personeller (${reported.length})`} value={'reported'} />
            <Tab wrapped label={`Rapordan Bugün Dönenler (${missed.length})`} value={'missed'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'reported'}>
            <IndicatorWrapper data={reported} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <EmployeeTable users={reported} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'missed'}>
            <IndicatorWrapper data={missed} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <EmployeeTable users={missed} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
