import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { NearMissTypeDefinitionControllerService, NearMissTypeDefinitionDto } from 'api/client';
import NearMissTypeDefinitionForm from 'Common/Forms/Definitions/NearMissTypeDefinition/NearMissTypeDefinitions';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function NearMissTypeDefinitions() {
  return (
    <BaseDefinitionPage<NearMissTypeDefinitionDto>
      listLabelPropName="label"
      fetchData={NearMissTypeDefinitionControllerService.getFirmNearMissTypeDefinitions}
      FormDialog={NearMissTypeDefinitionForm}
      onDelete={NearMissTypeDefinitionControllerService.deleteFirmNearMissTypeDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
