import { Grid } from '@material-ui/core';
import React from 'react';

import { saveReferral, updateReferral } from 'api/hs/definitions/health-definitions/referral';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { ReferralModel } from 'model/definitions/health-definitions/referral.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the Referral is created/updated
   */
  onClose(success: boolean): void;

  data: ReferralModel;
}

export default function Referral({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<ReferralModel>
      open={open}
      onClose={onClose}
      onSave={saveReferral}
      onUpdate={updateReferral}
      data={data}
      resourceReloadName="referralSection"
      createTitle="Yeni Bölüm Ekleme"
      updateTitle="Bölüm Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="name" label="Yeni Bölüm Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
