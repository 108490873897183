import { createContext } from 'react';

import { EmergencyCaseTeamDto, EmergencyCaseTeamMemberDto, EmergencyCaseTeamTrainingDefinitionDto } from '../../api/client';

export interface CaseTeamsDetailContextType {
  emergencyCaseTeams: EmergencyCaseTeamDto;

  newCaseTeamMembersNewDialog: boolean;

  setNewCaseTeamMembersNewDialog(open: boolean): void;

  emergencyCaseTeamMember: EmergencyCaseTeamMemberDto[];

  emergencyCaseTeamTraining: EmergencyCaseTeamTrainingDefinitionDto[];

  emergencyCaseTeamTrainingReload(): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  loading: boolean;

  setLoading(loading: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: EmergencyCaseTeamMemberDto;

  setSelectedData(data: EmergencyCaseTeamMemberDto): void;

  newCaseTrainingsNewDialog: boolean;

  setNewCaseTrainingsNewDialog(open: boolean): void;

  employeeIdTrainingMap: any;
}

export const CaseTeamsDetailContext = createContext<CaseTeamsDetailContextType>(null);
