/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildingPartDefinitionDto } from '../models/BuildingPartDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BuildingPartDefinitionControllerService {

    /**
     * @param id
     * @returns BuildingPartDefinitionDto OK
     * @throws ApiError
     */
    public static getBuildingPartDefinition(
        id: string,
    ): CancelablePromise<BuildingPartDefinitionDto> {
        return __request({
            method: 'GET',
            path: `/buildings/floors/parts/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns BuildingPartDefinitionDto OK
     * @throws ApiError
     */
    public static updateBuildingPartDefinition(
        id: string,
        requestBody: BuildingPartDefinitionDto,
    ): CancelablePromise<BuildingPartDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/buildings/floors/parts/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBuildingPartDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/buildings/floors/parts/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns BuildingPartDefinitionDto OK
     * @throws ApiError
     */
    public static saveBuildingPartDefinition(
        requestBody: BuildingPartDefinitionDto,
    ): CancelablePromise<BuildingPartDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/buildings/floors/parts`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns BuildingPartDefinitionDto OK
     * @throws ApiError
     */
    public static getAllBuildingPartDefinitions(
        id: string,
    ): CancelablePromise<Array<BuildingPartDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/buildings/floors/${id}/parts`,
        });
    }

}