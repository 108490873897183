import React from 'react';
import { Route, Switch } from 'react-router';

import OccupationalSafetyRegistriesModule from './OccupationalSafetyRegistries';
import OccupationalSafetyRegistriesList from './OccupationSafetyRegistriesList';

export default function OccupationalSafetyRegistries() {
  return (
    <Switch>
      <Route path="/occupational-safety-registries/query" exact>
        <OccupationalSafetyRegistriesModule />
      </Route>
      <Route path="/occupational-safety-registries/query/companies/:companyId" exact>
        <OccupationalSafetyRegistriesModule />
      </Route>
      <Route path="/occupational-safety-registries/query/companies/:companyId/books/:bookId" exact>
        <OccupationalSafetyRegistriesModule />
      </Route>
      <Route path="/occupational-safety-registries/query/companies/:companyId/books/:bookId/detections/:pageId" exact>
        <OccupationalSafetyRegistriesModule />
      </Route>
      <Route path="/occupational-safety-registries/list" exact>
        <OccupationalSafetyRegistriesList />
      </Route>
    </Switch>
  );
}
