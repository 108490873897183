import { Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { MutableRefObject } from 'react';

import { BaseAccidentReviewAnalysisDefinitionDto } from '../../../api/client';
import Checkbox from '../../../Common/Form/Checkbox';
import { FormRef } from '../../../Common/Form/Form';
import TextField from '../../../Common/Form/TextField';

interface Props {
  title: string;
  name: string;
  items: BaseAccidentReviewAnalysisDefinitionDto[];
  formRef: MutableRefObject<FormRef<Record<string, any>>>;
}

export default ({ formRef, title, name, items }: Props) => {
  const onClickOther = async () => {
    // await defer();
    formRef.current?.methods.setValue(`${name}_other_enabled`, !formRef.current?.value[`${name}_other_enabled`]);
    // reRender ı form 'a verince sonsuz bi sekilde trigger ediyor
    formRef.current?.methods?.trigger();
  };

  return (
    <>
      <Grid item xs={12}>
        <h4>{title}</h4>
      </Grid>
      {items.map(item => (
        <Grid item xs={12} key={item.id}>
          <Checkbox name={`${name}[${item.id}]`} label={item.label} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Checkbox name={`${name}_other_enabled`} label="Diğer" onClick={onClickOther} />
      </Grid>
      {formRef.current?.value[`${name}_other_enabled`] && (
        <Grid item xs={12}>
          <TextField name={`${name}_other`} label="Diğer" required />
        </Grid>
      )}
    </>
  );
};
