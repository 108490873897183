import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { AccidentTypeDefinitionControllerService, AccidentTypeDefinitionDto } from '../../api/client';
import AccidentTypeEditDialog from '../../Common/Forms/Definitions/AccidentDefinition/AccidentTypeDefinition/AccidentTypeEditDialog';
import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AccidentTypeDefinition() {
  return (
    <BaseDefinitionPage<AccidentTypeDefinitionDto>
      listLabelPropName="label"
      fetchData={AccidentTypeDefinitionControllerService.getFirmAccidentTypeDefinitions}
      FormDialog={AccidentTypeEditDialog}
      onDelete={AccidentTypeDefinitionControllerService.deleteFirmAccidentTypeDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
