/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeViewRequest } from '../models/EmployeeViewRequest';
import type { PageEmployeeView } from '../models/PageEmployeeView';
import type { PageTrainingEmployeeView } from '../models/PageTrainingEmployeeView';
import type { TrainingEmployeeViewRequest } from '../models/TrainingEmployeeViewRequest';
import type { TrainingView } from '../models/TrainingView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TrainingEmployeeViewControllerService {

    /**
     * @param requestBody
     * @returns PageTrainingEmployeeView OK
     * @throws ApiError
     */
    public static getTrainingEmployeeViews(
        requestBody: TrainingEmployeeViewRequest,
    ): CancelablePromise<PageTrainingEmployeeView> {
        return __request({
            method: 'POST',
            path: `/trainings/training-employees/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static trainingEmployeesViewExport(
        requestBody: EmployeeViewRequest,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/training/employees/view/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PageEmployeeView OK
     * @throws ApiError
     */
    public static getEmployeeTrainings(
        requestBody: EmployeeViewRequest,
    ): CancelablePromise<PageEmployeeView> {
        return __request({
            method: 'POST',
            path: `/training/employee/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns number OK
     * @throws ApiError
     */
    public static getEmployeeTrainingCount(
        requestBody: EmployeeViewRequest,
    ): CancelablePromise<number> {
        return __request({
            method: 'POST',
            path: `/training/employee/views/count`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns TrainingView OK
     * @throws ApiError
     */
    public static getDetailedEmployeeTraining(
        id: string,
    ): CancelablePromise<Array<TrainingView>> {
        return __request({
            method: 'GET',
            path: `/training/employees/${id}/view`,
        });
    }

    /**
     * @param type
     * @returns binary OK
     * @throws ApiError
     */
    public static trainingEmployeesViewsExpiredExport(
        type: 'EXCEL' | 'PDF' | 'WORD' | 'HTML' | 'XML' | 'JSON' | 'CSV' = 'EXCEL',
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/training/employees/views/expired/export`,
            query: {
                'type': type,
            },
        });
    }

    /**
     * @returns PageEmployeeView OK
     * @throws ApiError
     */
    public static getEmployeeExpiredTrainings(): CancelablePromise<PageEmployeeView> {
        return __request({
            method: 'GET',
            path: `/training/employee/views/expired`,
        });
    }

    /**
     * @returns number OK
     * @throws ApiError
     */
    public static getEmployeeExpiredTrainingCount(): CancelablePromise<number> {
        return __request({
            method: 'GET',
            path: `/training/employee/views/expired/count`,
        });
    }

}