import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { ParameterAggregate } from 'api/hs/examination/analysis';
import TextField from 'Common/Form/TextField';

import DateInput from '../../../../../../Common/Form/DateInput';

interface Props {
  parameters: ParameterAggregate[];
}

const useStyles = makeStyles(theme => ({
  cellFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 0,
  },
  cellRoot: {
    paddingLeft: 0,
    border: 'none !important',
  },
  styledTextField: {
    marginTop: '16px',
  },
  bolder: {
    fontWeight: 'bold',
  },
}));

export default function CommonAnalysisForm({ parameters }: Props) {
  const classes = useStyles();
  const hasDescriptionField = parameters?.find(param => param.opinion !== undefined);

  return (
    <>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <DateInput required type="date" name="date" label="Reçete Tarihi" />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <TextField rows={2} label="Kanaat" name="opinion" />
      </Grid>
      <Typography color="inherit" align="left">
        Sonuç
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.cellRoot }} />
            <TableCell classes={{ root: classes.cellRoot }}>Sonuç</TableCell>
            {hasDescriptionField && <TableCell classes={{ root: classes.cellRoot }} />}
            <TableCell classes={{ root: classes.cellRoot }}>Referans</TableCell>
            <TableCell classes={{ root: classes.cellRoot }}>Birim</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters?.map((item, index) => (
            <TableRow key={`${item.parameter}${index}`}>
              <TableCell classes={{ root: classes.cellRoot }} className={classes.bolder} align="left">
                <b>{item.parameter}</b>
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
                <TextField
                  type="number"
                  name={`common-analysis-form_${item.id}`}
                  className={hasDescriptionField ? classes.styledTextField : ''}
                />
              </TableCell>
              {hasDescriptionField && (
                <TableCell classes={{ root: classes.cellRoot }} style={{ width: '100px' }} align="left">
                  <TextField rows={1} label="Açıklama " name={`description_${item.id}`} />
                </TableCell>
              )}
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.min} - {item.max}
              </TableCell>
              <TableCell classes={{ root: classes.cellRoot }} align="left">
                {item.analysisParameterUnit}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
