import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { PeriodicExaminationConfigurationsPayload } from './periodic-examination-configurations.payload';
import { PeriodicExaminationConfigurationsResult } from './periodic-examination-configurations.result';

export async function getPeriodicExaminationConfigurations(employeeId?: string): Promise<PeriodicExaminationConfigurationsResult> {
  let res;
  try {
    res = await HSApi.get<PeriodicExaminationConfigurationsResult>(`/periodic-examination-configurations?employeeId=${employeeId}`);
  } catch (e) {
    if (e.status === 404) {
      return null;
    }
    console.error(e); // tslint:disable-line:no-console
    throw e;
  }

  return res.data;
}

export async function savePeriodicExaminationConfigurations(
  body: PeriodicExaminationConfigurationsPayload
): Promise<PeriodicExaminationConfigurationsResult> {
  const [err, res] = await tryCatch(HSApi.post('/periodic-examination-configurations', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
