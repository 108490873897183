import React from 'react';
import { Route, Switch } from 'react-router';

import PpeAssignments from './ppe-assignments';
import PpeDetailStocks from './ppe-detail-stocks';
import PpeOutputs from './ppe-outputs';
import PpeStocks from './ppe-stocks';
import PpeDefinitions from './PpeDefinition';
export default function EmployeeList() {
  return (
    <Switch>
      <Route path="/ppe/ppe-stocks/:ppeDetailId">
        <PpeDetailStocks />
      </Route>
      <Route path="/ppe/ppe-definitions" exact>
        <PpeDefinitions />
      </Route>
      <Route path="/ppe/ppe-stocks">
        <PpeStocks />
      </Route>
      <Route path="/ppe/ppe-outputs">
        <PpeOutputs />
      </Route>
      <Route path="/ppe/ppe-assignments">
        <PpeAssignments />
      </Route>
    </Switch>
  );
}
