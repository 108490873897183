import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { EnumResult } from './enum.result';

async function getPrescriptionTypeDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/prescription-type-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionMedicineUsageDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/medicine-usage-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionDescriptionDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/prescription-description-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionMedicinePeriodUnitDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/medicine-period-unit-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionProvisionDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/prescription-provision-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionSubtypeDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/prescription-subtype-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionSatietyInformationDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/satiety-information-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

async function getPrescriptionUnitDoseDefinitions(): Promise<EnumResult[]> {
  const [error, res] = await tryCatch(HSApi.get<EnumResult[]>(`/prescriptions/unit-dose-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export {
  getPrescriptionTypeDefinitions,
  getPrescriptionMedicineUsageDefinitions,
  getPrescriptionDescriptionDefinitions,
  getPrescriptionMedicinePeriodUnitDefinitions,
  getPrescriptionProvisionDefinitions,
  getPrescriptionSubtypeDefinitions,
  getPrescriptionSatietyInformationDefinitions,
  getPrescriptionUnitDoseDefinitions,
};
