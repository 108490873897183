import WorkEquipmentTypeDefinition from './WorkEquipmentTypeDefinition';

interface WorkEquipmentListItem {
  label: string;
  Component?: any;
  path: string;
}

export default [
  {
    label: 'İş Ekipman Kategori',
    Component: WorkEquipmentTypeDefinition,
    path: '',
  },
] as WorkEquipmentListItem[];
