import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React, { useContext } from 'react';

import { getIdc10sByIds, searchIcd10 } from 'api/hs/health-definitions';
import { Icd10ItemResponse } from 'api/hs/health-definitions/icd10-item.response';
import AutoComplete from 'Common/Form/AutoComplete';
import Select, { SelectOption } from 'Common/Form/Select';
import Slider from 'Common/Form/Slider';
import { SelectOptionsContext } from 'context/select-options.context';

import { UserContext } from '../../../../../context/user';
import { UserRole } from '../../../../../model/user-role.enum';

function mapAllergyTypesToOptions(types: Icd10ItemResponse[]): SelectOption<number>[] {
  return types.map<SelectOption<number>>(({ id, icd10Name }) => ({ text: icd10Name, value: id }));
}

async function allergyTypeOptionFetcher(value: string) {
  const res = await searchIcd10(value);

  return mapAllergyTypesToOptions(res);
}

async function allergyTypeLabelFetcher(values: number[]) {
  const res = await getIdc10sByIds(values);

  return mapAllergyTypesToOptions(res);
}

export default function MedicalBackground() {
  const { yesNoBoolean, disabilities, pregnancyStates, availabilitiesBoolean, allergies, addictionStates } = useContext(
    SelectOptionsContext
  );
  const { user } = useContext(UserContext);

  const components = {
    disabled: <Select name="disabled" options={yesNoBoolean} label="Engellilik" emptyOption="Tümü" />,
    disabilityDefinitionIds: (
      <AutoComplete name="disabilityDefinitionIds" options={disabilities} label="Engellilik Tipi" renderOption="checkbox" />
    ),
    hasAccident: <Select name="hasAccident" options={availabilitiesBoolean} label="İş Kazaları" emptyOption="Tümü" />,
    archived: <Select name="archived" options={yesNoBoolean} label="Arşivlenmiş" />,
    bmi: <Slider name="bmi" label="Vücut Kitle Endeksi" min={10} max={50} />,
  };

  const roleComponentMap = {
    [UserRole.OSS]: {
      bmi: components.bmi,
      disabled: components.disabled,
      disabilityDefinitionIds: components.disabilityDefinitionIds,
      hasAccident: components.hasAccident,
      archived: components.archived,
    },
    [UserRole.PHYSICIAN]: {
      bmi: components.bmi,
      disabled: components.disabled,
      disabilityDefinitionIds: components.disabilityDefinitionIds,
      pregnancyStateIds: <AutoComplete name="pregnancyStateIds" options={pregnancyStates} label="Gebelik Öyküsü" renderOption="checkbox" />,
      hasAllergy: <Select name="hasAllergy" options={availabilitiesBoolean} label="Alerji" emptyOption="Tümü" />,
      allergyDefinitionIds: <AutoComplete name="allergyDefinitionIds" options={allergies} label="Alerji Tipi" renderOption="checkbox" />,
      hasChronicDisease: <Select name="hasChronicDisease" options={availabilitiesBoolean} label="Kronik Hastalık" emptyOption="Tümü" />,
      chronicDiseaseIcd10s: (
        <AutoComplete
          name="chronicDiseaseIcd10s"
          label="Kronik Hastalık Tipi"
          optionFetcher={allergyTypeOptionFetcher}
          labelFetcher={allergyTypeLabelFetcher}
        />
      ),
      hasSurgeryHistory: <Select name="hasSurgeryHistory" options={yesNoBoolean} label="Ameliyat" emptyOption="Tümü" />,
      hasRegularMedicationUsage: (
        <Select name="hasRegularMedicationUsage" options={availabilitiesBoolean} label="Düzenli İlaç Kullanımı" emptyOption="Tümü" />
      ),
      hasDisease: <Select name="hasDisease" options={availabilitiesBoolean} label="Hastalık / Meslek Hastalığı" emptyOption="Tümü" />,
      hasAccident: components.hasAccident,
      smokeAddictionStateIds: (
        <AutoComplete name="smokeAddictionStateIds" options={addictionStates} label="Sigara" renderOption="checkbox" />
      ),
      alcoholAddictionStateIds: (
        <AutoComplete name="alcoholAddictionStateIds" options={addictionStates} label="Alkol" renderOption="checkbox" />
      ),
      archived: components.archived,
    },
  };

  roleComponentMap[UserRole.NURSE] = roleComponentMap[UserRole.PHYSICIAN];

  const componentsMap = {};

  if (user.roles?.length > 0) {
    user.roles
      .filter(role => role in roleComponentMap)
      .forEach(role =>
        Object.keys(roleComponentMap[role])
          .filter(componentId => !(componentId in componentsMap))
          .forEach(componentId => {
            componentsMap[componentId] = roleComponentMap[role][componentId];
          })
      );
  }

  return (
    <>
      <ListItem>
        <ListItemText primary="Mesleki Bilgiler" />
      </ListItem>
      <Divider className="mb-1" />
      {Object.keys(componentsMap).map((componentId, index) => (
        <ListItem key={index} className="py-0">
          {componentsMap[componentId]}
        </ListItem>
      ))}
    </>
  );
}
