import React, { useCallback } from 'react';

import { getWorkEquipmentLayout } from 'api/hs/work-equipment/work-equipment-layout/work-equipment-layout';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { CELL } from './columns';
import EditDialog from './EditDialog';
import ListTable from './ListTable';
import Toolbar from './Toolbar';

export default function List() {
  const fetchWorkEquipmentLayoutList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getWorkEquipmentLayout(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider fetcher={fetchWorkEquipmentLayoutList} columns={CELL}>
      <Toolbar />
      <ListTable />
      <EditDialog />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
