import { createContext } from 'react';

import { BuildingFloorDefinitionDto } from '../../../../api/client';

export interface BuildingFloorsContextType {
  buildingFloorDefinition: BuildingFloorDefinitionDto[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  buildingName: string;

  setEditDialogOpened(opened: boolean): void;

  selectedData: BuildingFloorDefinitionDto;

  setSelectedData(data: BuildingFloorDefinitionDto): void;
}

export const BuildingFloorsContext = createContext<BuildingFloorsContextType>(null);
