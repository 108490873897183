import { AppBar, makeStyles, StyleRules, Toolbar as MUIToolbar, ToolbarProps } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

type ColorType = 'primary' | 'secondary';

const useStyles = makeStyles(theme => ({
  ...(['primary', 'secondary'] as ColorType[]).reduce(
    (acc, next) => ({
      ...acc,
      [next]: {
        backgroundColor: theme.palette[next].light,
      },
    }),
    {} as { [P in ColorType]: StyleRules }
  ),
}));

interface Props {
  color?: ColorType;
  className?: string;
  toolbarProps?: Partial<ToolbarProps>;
}

export default function Toolbar({ children, color = 'primary', className = '', toolbarProps = null }: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <AppBar position="static" className={`${classes[color]} ${className}`}>
      <MUIToolbar variant="dense" {...toolbarProps}>
        {children}
      </MUIToolbar>
    </AppBar>
  );
}
