import { Button as MUIButton, ButtonProps, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

const useTextStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  },
  warn: {
    color: theme.palette.warning.main,
  },
}));

const useContainedStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  warn: {
    backgroundColor: theme.palette.warning.main,

    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

interface Props {
  color?: ButtonProps['color'] | 'error' | 'warn';
}

export default function Button({ color, className: cn = '', variant = 'text', ...props }: Omit<ButtonProps, 'color'> & Props) {
  const textClasses = useTextStyles();
  const containedClasses = useContainedStyles();

  const classes = useMemo(() => (variant === 'text' ? textClasses : containedClasses), [variant, textClasses, containedClasses]);

  const className: string = useMemo(() => (color in classes ? `${(classes as any)[color]} ${cn}` : cn), [color, classes, cn]);

  return (
    <MUIButton className={className} {...props} color={color in classes ? 'default' : (color as ButtonProps['color'])} variant={variant} />
  );
}
