import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AccidentView, WorkingHoursControllerService } from '../../api/client';
import Form, { FormRef } from '../../Common/Form/Form';
import { RadioGroupInput } from '../../Common/Form/RadioGroup';
import { ResourceContext } from '../../context/resource.context';
import { useAsyncEffect, useTriggerRender } from '../../utils';
import { convertDateClusteredByMultipleFieldNames } from '../utils/clustered-chart-data-converter-xy';
import { convertByMultipleFieldNames } from '../utils/date-clustered-chart-data-converter-xy';
import { DisplayOption } from '../utils/display-option';
import { monthLostWorkDaysMap } from './accident-chart-utils';
import AccidentReview from './AccidentReview';
import { partHeaderStyles } from './index';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

interface AccidentWeightContainerProps {
  data: AccidentView[];
  startDate: string;
  endDate: string;
}

const accidentWeightConstantOptions = [
  {
    text: '1.000.000',
    value: '1000000',
  },
  {
    text: '200.000',
    value: '200000',
  },
];

const AccidentReviewContainer = (props: AccidentWeightContainerProps) => {
  const [rawChartData, setRawChartData] = useState<any[]>([]);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [frequencyConstant, setFrequencyConstant] = useState<string>('1000000');
  const [weightConstant, setWeightConstant] = useState<string>('1000000');
  const [frequencyChartData, setFrequencyChartData] = useState<any[]>();
  const [weightChartData, setWeightChartData] = useState<any[]>();
  const [companyNameIdMap, setCompanyNameIdMap] = useState<Map<string, string>>();
  const [monthLostWorkDayMap, setMonthLostWorkDayMap] = useState<Map<string, any>>();
  const { companies } = useContext(ResourceContext);
  const partHeaderSt = partHeaderStyles();

  const onSubmit = useCallback(
    formValues => {
      if (formValues && formValues.weightConstant !== weightConstant) {
        setWeightConstant(formValues.weightConstant);
      }
      if (formValues && formValues.frequencyConstant !== frequencyConstant) {
        setFrequencyConstant(formValues.frequencyConstant);
      }
    },
    [weightConstant, frequencyConstant]
  );

  useEffect(() => {
    const data = convertByMultipleFieldNames(props.data, 'date', ['companyName'], DisplayOption.MONTHLY);

    setRawChartData(data);
    setMonthLostWorkDayMap(monthLostWorkDaysMap(props.data));
    const cNameIdMap = new Map<string, string>();
    companies.forEach(c => cNameIdMap.set(c.companyName, c.id));

    setCompanyNameIdMap(cNameIdMap);
  }, [companies, props.data]);

  useAsyncEffect(async () => {
    if (companyNameIdMap && companyNameIdMap.size !== 0 && rawChartData?.length !== 0) {
      const companyNames = new Set<string>();
      rawChartData.forEach(d =>
        Object.keys(d)
          .filter(o => o !== 'date')
          .forEach(f => companyNames.add(f))
      );
      const freqChartData: any[] = [];
      const wChartData: any[] = [];
      for (const companyName of companyNames) {
        const workingHours = await WorkingHoursControllerService.getWorkingHours(
          props.startDate,
          props.endDate,
          companyNameIdMap.get(companyName)
        );
        if (workingHours && workingHours.length > 0) {
          rawChartData.forEach(cd => {
            let total = 0;
            workingHours.forEach(w => {
              if (cd.date === w.date.slice(0, 7)) {
                total = total + w.workingHours;
              }
            });

            const objFreq = { ...cd };
            const objWeight = { ...cd };
            if (total !== 0) {
              // tslint:disable-next-line:radix
              objFreq[companyName] = (Reflect.get(cd, companyName) / total) * parseInt(frequencyConstant);
              freqChartData.push(objFreq);
            }
            const lostWorkDay = monthLostWorkDayMap.get(cd.date)[companyName];
            if (total !== 0) {
              // tslint:disable-next-line:radix
              objWeight[companyName] = (lostWorkDay / total) * parseInt(weightConstant);
              wChartData.push(objWeight);
            }
          });
        } else {
          rawChartData.forEach(cd => {
            Reflect.set(cd, companyName, 0);
          });
        }
        setFrequencyChartData(freqChartData);
        setWeightChartData(wChartData);
      }
      await defer();
    }
  }, [rawChartData, companyNameIdMap, frequencyConstant, weightConstant]);

  return (
    <>
      {(frequencyChartData || weightChartData) && (
        <Grid container>
          <Grid item xs={12}>
            <Card style={{ padding: '10px', margin: '10px' }} raised>
              <CardHeader title="Temel kaza verileri" classes={partHeaderSt} />
              <CardContent>
                <Form className={'m-2'} ref={formRef} onChange={onSubmit}>
                  <Grid container>
                    {frequencyChartData && (
                      <Grid item xs={12} lg={6}>
                        <Card style={{ padding: '10px', margin: '10px' }}>
                          <CardHeader title="Kaza Sıklık Dağılımı" />
                          <CardContent style={{ textAlign: 'center' }}>
                            <RadioGroupInput
                              defaultValue="1000000"
                              label="Sıklık Sabiti:"
                              name="frequencyConstant"
                              options={accidentWeightConstantOptions}
                            />
                            {frequencyConstant && (
                              <AccidentReview data={frequencyChartData} name="accident-frequency-report" yAxesText="Kaza Sıklığı" />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {weightChartData && (
                      <Grid item xs={12} lg={6}>
                        <Card style={{ padding: '10px', margin: '10px' }}>
                          <CardHeader title="Kaza Ağırlık Dağılımı" />{' '}
                          <CardContent style={{ textAlign: 'center' }}>
                            <RadioGroupInput
                              defaultValue="1000000"
                              label="Ağırlık Sabiti:"
                              name="weightConstant"
                              options={accidentWeightConstantOptions}
                            />
                            {weightConstant && (
                              <AccidentReview data={weightChartData} name="accident-weight-report" yAxesText="Kaza Agırlığı" />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AccidentReviewContainer;
