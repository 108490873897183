import { Grid } from '@material-ui/core';
import React from 'react';

import { saveLocation, updateLocation } from 'api/hs/definitions/workplace-definition/location';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { LocationModel } from 'model/definitions/workplace-definition/location.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the department is created/updated
   */
  onClose(success: boolean): void;

  data: LocationModel;
}

export default function CompanyEdit({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<LocationModel>
      open={open}
      onClose={onClose}
      onSave={saveLocation}
      onUpdate={updateLocation}
      data={data}
      resourceReloadName="locations"
      createTitle="Lokasyon Ekle"
      updateTitle="Lokasyon Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Lokasyon Adı" />
      </Grid>
    </BaseDefinitionForm>
  );
}
