import React from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';

interface Props {
  external?: boolean;
}

export default function Link({ external = false, to, children, ...props }: Props & LinkProps) {
  return external ? <a href={to as string}>{children}</a> : <BaseLink {...{ ...props, to, children }} />;
}
