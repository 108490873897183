import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import { UserContext } from '../../context/user';
import { FullRoles, UserRole } from '../../model/user-role.enum';
import DiplomaInformation from './DiplomaInformation';
import GeneralInformation from './GeneralInformation';
import MedulaInfo from './MedulaInfo';
import PasswordChange from './PasswordChange';

export default function ProfilePage() {
  const { user } = useContext(UserContext);

  const TABS = [
    {
      key: 1,
      Component: <GeneralInformation />,
      visibleTo: FullRoles,
    },
    {
      key: 2,
      Component: <PasswordChange />,
      visibleTo: FullRoles,
    },
    {
      key: 3,
      Component: <DiplomaInformation />,
      visibleTo: [UserRole.PHYSICIAN],
    },
    {
      key: 4,
      Component: <MedulaInfo />,
      visibleTo: [UserRole.PHYSICIAN],
    },
  ];

  return (
    <div style={{ marginLeft: '7px' }}>
      <Grid container direction="row" alignItems="flex-start">
        {TABS.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r))).map(({ Component, visibleTo, key }) => (
          <Grid key={key} item md={4} sm={12} lg={4}>
            {Component}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
