import {
  Divider,
  IconButton,
  List as MUIList,
  ListItem as MUIListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HotelIcon from '@material-ui/icons/Hotel';
import React, { Fragment, useCallback, useContext } from 'react';

import Button from 'Common/Button';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { DialogContext } from 'context/dialog.context';

import { ListItem, StoryContext } from './history.context';

export default function List() {
  const { list, loading, setSelectedItem, setDialogOpened, remove, buttonLabel } = useContext(StoryContext);
  const { openWarnDialog } = useContext(DialogContext);

  const edit = useCallback(
    (item: ListItem) => () => {
      setSelectedItem(item);
      setDialogOpened(true);
    },
    [setSelectedItem, setDialogOpened]
  );

  const add = useCallback(() => {
    setSelectedItem(null);
    setDialogOpened(true);
  }, [setSelectedItem, setDialogOpened]);

  const handleDelete = useCallback(
    (item: ListItem) => async () => {
      const accepted = await openWarnDialog({ text: `${item.description} - kayıtını silmek istediğinize emin misiniz ?` });

      if (accepted) {
        await remove(item.id);
      }
    },
    [openWarnDialog, remove]
  );

  return (
    <>
      <header className="text-right">
        <Button onClick={add} color="primary">
          {buttonLabel}
        </Button>
      </header>
      <IndicatorWrapper data={list} loading={loading} errorMessage="Gösterilecek Kayıt Bulunamadı !">
        <MUIList className="mb-2">
          {list.map((item, index) => (
            <Fragment key={index}>
              <MUIListItem>
                <ListItemAvatar className="text-color">
                  <HotelIcon />
                </ListItemAvatar>
                <ListItemText color="primary">{item.description}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={edit(item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete(item)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </MUIListItem>
              <Divider />
            </Fragment>
          ))}
        </MUIList>
      </IndicatorWrapper>
    </>
  );
}
