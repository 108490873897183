import { Box, Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  ExaminationsArrayModel,
  ExaminationsPostModel,
  ExaminationsPutModel,
  ExaminationsWorkConditionModel,
  getExaminationById,
  getExaminations,
  putExaminations,
  updateExaminations,
} from 'api/hs/Examinations';
import DateInput from 'Common/Form/DateInput';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import PhysicalWorkCondition from 'Common/Forms/Definitions/PhysicalWorkCondition/PhysicalWorkCondition';
import { WorkEquipment } from 'Common/Forms/Definitions/WorkEquipment/WorkEquipment';
import WorkType from 'Common/Forms/Definitions/WorkType/WorkType';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import { examinationGetList } from '../../../../api/hs/Examinations/examinations';
import ESignatureNotification from '../../../../Common/ESignatureNotification';
import ConvertToExaminationsDialog from './ConvertToExaminationsDialog';

const defaultDefinitionValue = {
  ELECTRICITY: [1399],
  NOISE: [1299],
  AIR: [1199],
  BIOLOGICAL_FACTORS: [1699],
  DUST_REGULATIONS: [1499],
  PHYSICAL: [1099],
  CHEMICAL: [1599],
};

const useStyles = makeStyles(() => ({
  buttonPadding: {
    padding: 2,
  },
  marginTop: {
    marginTop: 5,
  },
  alertWith: {
    width: '25%',
  },
  hidden: { display: 'none' },
}));

export default function ExaminationSend() {
  const [open, setOpen] = useState(false);
  const { reset, setValue, trigger, getValues, ...formMethods } = useForm();
  const { yesNo, reportType, opinion } = useContext(SelectOptionsContext);
  const { examinationId } = useParams<{ examinationId: string }>();
  const { showMessage } = useContext(AlertContext);
  const classes = useStyles();
  const [ibysRequestState, setIbysRequestState] = useState('NOT_SENT');
  const [ibysErrorMessage, setIbysErrorMessage] = useState(null);

  const [ibysId, setIbysId] = useState('');

  useAsyncEffect(async () => {
    startFill();
  }, [setValue, trigger, reset]);

  async function startFill() {
    setValue('', '');
    const examinationsDefinitions: ExaminationsPostModel = await getExaminations(examinationId);
    if (examinationsDefinitions.toString() !== '') {
      for (const [key, value] of Object.entries(examinationsDefinitions)) {
        setValue(key, value === null ? '' : value);
      }
      /*if (examinationsDefinitions.ibysRequestState === 'ON_STAND_BY') {
        checkIBYS(examinationsDefinitions.id);
      }*/
      setIbysRequestState(examinationsDefinitions.ibysRequestState);
      setIbysErrorMessage(examinationsDefinitions.processResultMessage);
      setIbysId(examinationsDefinitions.id);
      setValue('workingPositionDefinitions', examinationsDefinitions.workingPositionCode);
      setValue('workingTimeDefinitions', examinationsDefinitions.workingTimeCode);
      setValue('vehicleOperationDefinitions', examinationsDefinitions.vehicleOperationCode);
      setWorkCondition(examinationsDefinitions);
      setWorkEqipment(examinationsDefinitions);
      // setTestExists(examinationsDefinitions);

      trigger();
    }
  }

  const autoFill = useCallback(async () => {
    setValue('', '');
    const examinationsDefinitions: ExaminationsPostModel = await getExaminationById(examinationId);
    for (const [key, value] of Object.entries(examinationsDefinitions)) {
      setValue(key, value === null ? '' : value);
    }
    setValue('professionCode', examinationsDefinitions.professionCode);
    setValue('workingPositionDefinitions', examinationsDefinitions.workingPositionCode);
    setValue('opinion', examinationsDefinitions.opinion);
    setValue('workingTimeDefinitions', examinationsDefinitions.workingTimeCode);
    setValue('vehicleOperationDefinitions', examinationsDefinitions.vehicleOperationCode);
    setWorkCondition(examinationsDefinitions);
    setWorkEqipment(examinationsDefinitions);
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examinationId, setValue, trigger]);

  const setWorkCondition = useCallback(
    (WorkConditionDefinitions: ExaminationsPostModel) => {
      let chemicalMatterConditions: number[];
      let electricityConditions: number[];
      let airConditions: number[];
      let noiseConditions: number[];
      let dustRegulationConditions: number[];
      let biologicalFactorConditions: number[];
      let physicalEnvironmentConditions: number[];

      chemicalMatterConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.chemicalMatterConditions);
      electricityConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.electricityConditions);
      airConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.airConditions);
      noiseConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.noiseConditions);
      biologicalFactorConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.biologicalFactorConditions);
      dustRegulationConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.dustRegulationConditions);
      physicalEnvironmentConditions = ExaminationsWorkConditionFunction(WorkConditionDefinitions.physicalEnvironmentConditions);

      if (chemicalMatterConditions.length === 0) {
        chemicalMatterConditions = defaultDefinitionValue.CHEMICAL;
      }
      if (electricityConditions.length === 0) {
        electricityConditions = defaultDefinitionValue.ELECTRICITY;
      }
      if (airConditions.length === 0) {
        airConditions = defaultDefinitionValue.AIR;
      }
      if (noiseConditions.length === 0) {
        noiseConditions = defaultDefinitionValue.NOISE;
      }
      if (dustRegulationConditions.length === 0) {
        dustRegulationConditions = defaultDefinitionValue.DUST_REGULATIONS;
      }
      if (biologicalFactorConditions.length === 0) {
        biologicalFactorConditions = defaultDefinitionValue.BIOLOGICAL_FACTORS;
      }
      if (physicalEnvironmentConditions.length === 0) {
        physicalEnvironmentConditions = defaultDefinitionValue.PHYSICAL;
      }

      setValue('chemicalMatterDefinitions', chemicalMatterConditions);
      setValue('electricityDefinitions', electricityConditions);
      setValue('airDefinitions', airConditions);
      setValue('noiseDefinitions', noiseConditions);
      setValue('biologicalFactorsDefinitions', biologicalFactorConditions);
      setValue('dustRegulationDefinitions', dustRegulationConditions);
      setValue('physicalEnvironmentDefinitions', physicalEnvironmentConditions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setValue]
  );

  const setWorkEqipment = useCallback(
    (examinationsPostModel: ExaminationsPostModel) => {
      const WorkConditionArray: number[] = [];
      examinationsPostModel.workEquipments.forEach(val => {
        WorkConditionArray.push(val.code);
      });
      setValue('code', WorkConditionArray.length === 0 ? [0] : WorkConditionArray);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      trigger();
    },
    [setValue, trigger]
  );

  function arrayAddCode(tempVal: number[]) {
    const resultArray: ExaminationsArrayModel[] = [];
    tempVal.forEach(val => {
      resultArray.push({
        code: val,
      });
    });
    return resultArray;
  }

  async function checkIBYS(id: string) {
    const getValue = await examinationGetList([id]);
    for (const [value] of Object.entries(getValue)) {
      if (value.processResult !== 200) {
        await updateExaminations(id, { ibysRequestState: 'SENT', examIdOnIbysServer: id });
      } else if (value.processResult !== 0) {
        await updateExaminations(id, { ibysRequestState: 'INCORRECT_REQUEST', examIdOnIbysServer: id });
      } else {
        // processResult == 0
        if (value.savingResult === 200 || value.savingResult === 0) {
          await updateExaminations(id, { ibysRequestState: 'ON_STAND_BY', examIdOnIbysServer: id });
        } else {
          await updateExaminations(id, { ibysRequestState: 'INCORRECT_REQUEST', examIdOnIbysServer: id });
        }
      }
    }
  }

  function alertFunction(requestState: string) {
    let value;
    if (requestState === 'SENT') {
      value = (
        <Alert className="ml-2 mr-2 mb-2" severity="success">
          Başarılı
        </Alert>
      );
    }
    if (requestState === 'ON_STAND_BY') {
      value = (
        <Alert className="ml-2 mr-2 mb-2" severity="info">
          "Bakanlık Tarafından İşleniyor"
        </Alert>
      );
    }
    if (requestState === 'INCORRECT_REQUEST') {
      value = (
        <Alert className="ml-2 mr-2 mb-2" severity="error">
          {ibysErrorMessage}
        </Alert>
      );
    }
    if (requestState === 'NOT_SENT') {
      value = (
        <Alert className="ml-2 mr-2 mb-2" severity="warning">
          "Bakanlığa Gönderilmedi!"
        </Alert>
      );
    }
    return value;
  }

  function ExaminationsWorkConditionFunction(examinationsWorkConditionModel: ExaminationsWorkConditionModel[]) {
    const conditionArray: number[] = [];
    if (examinationsWorkConditionModel) {
      examinationsWorkConditionModel.forEach(val => {
        conditionArray.push(val.code);
      });
    }
    return conditionArray;
  }

  const handleSubmit = async () => {
    try {
      const examinationsDefinitions: ExaminationsPutModel = {
        ibysRequestState,
        ssiNumber: getValues('ssiNumber'),
        professionCode: getValues('professionCode'),
        physicianNid: getValues('physicianNid'),
        employeeNid: getValues('employeeNid'),
        workingPositionCode: parseInt(getValues('workingPositionDefinitions'), 10),
        workingTimeCode: parseInt(getValues('workingTimeDefinitions'), 10),
        vehicleOperationCode: parseInt(getValues('vehicleOperationDefinitions'), 10),
        reportDate: getValues('reportDate'),
        roentgenExists: getValues('roentgenExists') === true ? 1 : 0,
        audioTestExists: getValues('audioTestExists') === true ? 1 : 0,
        pftTestExists: getValues('pftTestExists') === true ? 1 : 0,
        bloodTestExists: getValues('bloodTestExists') === true ? 1 : 0,
        urineTestExists: getValues('urineTestExists') === true ? 1 : 0,
        reportEndDate: getValues('reportEndDate'),
        opinion: getValues('opinion'),
        reportType: getValues('reportType'),
        airConditions: arrayAddCode(getValues('airDefinitions')),
        biologicalFactorConditions: arrayAddCode(getValues('biologicalFactorsDefinitions')),
        chemicalMatterConditions: arrayAddCode(getValues('chemicalMatterDefinitions')),
        dustRegulationConditions: arrayAddCode(getValues('dustRegulationDefinitions')),
        electricityConditions: arrayAddCode(getValues('electricityDefinitions')),
        noiseConditions: arrayAddCode(getValues('noiseDefinitions')),
        physicalEnvironmentConditions: arrayAddCode(getValues('physicalEnvironmentDefinitions')),
        workEquipments: arrayAddCode(getValues('code')),
      };
      await defer();
      await putExaminations(examinationId, examinationsDefinitions);
      startFill();
      showMessage('Ekleme işlemi başarılı.', 'success', 'info');
    } catch (err) {
      showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
    }
  };

  const openConvertToExaminationsDialog = useCallback(async () => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  ESignatureNotification();

  return (
    <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
      <form noValidate className="mt-2">
        <ConvertToExaminationsDialog
          open={open}
          onClose={handleClose}
          id={ibysId}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          startFill={startFill}
        />
        <Box display="flex" p={1} justifyContent="flex-end">
          <Box className={classes.buttonPadding} p={1}>
            <Button
              disabled={ibysRequestState === 'ON_STAND_BY' || ibysRequestState === 'SENT'}
              variant="contained"
              type="button"
              onClick={autoFill}>
              Otomatik Doldur
            </Button>
          </Box>
          <Box className={classes.buttonPadding} p={1}>
            <Button
              disabled={ibysRequestState === 'ON_STAND_BY' || ibysRequestState === 'SENT'}
              variant="contained"
              type="button"
              onClick={handleSubmit}>
              Kaydet
            </Button>
          </Box>
          <Box className={classes.buttonPadding} p={1}>
            <Button
              disabled={ibysRequestState === 'ON_STAND_BY' || ibysRequestState === 'SENT'}
              variant="contained"
              type="button"
              onClick={openConvertToExaminationsDialog}>
              IBYS Sistemine Gönder
            </Button>
          </Box>
        </Box>
        {alertFunction(ibysRequestState)}
        <Grid container>
          <Grid item sm={4} className="px-2">
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }}>
              <CardContent>
                <Typography>Fiziksel Çalışma Koşulları</Typography>
                <PhysicalWorkCondition required={true} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={4} className="px-2">
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }}>
              <CardContent>
                <Typography>İş Ekipmanları</Typography>

                <WorkEquipment required={true} />
              </CardContent>
            </Card>
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }} className={classes.marginTop}>
              <CardContent>
                <Typography>Çalışma Şekli</Typography>
                <WorkType required={true} />
              </CardContent>
            </Card>
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }} className={classes.marginTop}>
              <CardContent>
                <Typography>Bilgiler</Typography>
                <TextField name="physicianNid" label="İş Yeri Hekimi TC" required />
                <TextField name="employeeNid" label="Çalışan TC" required />
                <TextField name="ssiNumber" label="SGK Tescil No" required />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={4} className="px-2">
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }}>
              <CardContent>
                <Typography>Testler</Typography>
                <Select name="roentgenExists" options={yesNo} label="Röntgen Yapıldı Mı ?" required />
                <Select name="audioTestExists" options={yesNo} label="İşitme Testi Yapıldı Mı ?" required />
                <Select name="pftTestExists" options={yesNo} label="Akciğer Testi Yapıldı Mı ?" required />
                <Select name="bloodTestExists" options={yesNo} label="Kan Tahlili Yapıldı Mı ?" required />
                <Select name="urineTestExists" options={yesNo} label="İdrar Tahlili Yapıldı Mı ?" required />
              </CardContent>
            </Card>
            <Card style={{ boxShadow: '2px 2px 6px #878a92' }} className={classes.marginTop}>
              <CardContent>
                <Typography>Rapor</Typography>
                <Select name="reportType" options={reportType} label="Rapor Tipi" required />
                <Grid item md={12} sm={12} lg={12}>
                  <DateInput name="reportDate" type="date" label="Rapor Tarihi" required />
                </Grid>
                <Grid item md={12} sm={12} lg={12}>
                  <DateInput name="reportEndDate" type="date" label="Son Geçerlilik Tarihi" required />
                </Grid>
                <Select name="opinion" options={opinion} label="Kanaat" required={true} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
