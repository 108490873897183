import { Chip, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import Avatar from '../../../../../TransactionsAndLists/EmployeeList/Avatar';
import { AssigmentEmployeeContext } from '../../assigment-employee.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function AssigmentEmployeeList() {
  const { loading, ppeAssignmentsEmployee } = useContext(AssigmentEmployeeContext);

  return (
    <IndicatorWrapper loading={loading} data={ppeAssignmentsEmployee} errorMessage="Kayıt Bulunamadı.">
      <List id="classificationDefinitions">
        {ppeAssignmentsEmployee?.map((item, index) => (
          <Fragment key={item?.id}>
            <ListItem>
              <ListItemAvatar>
                <PersonIcon className="text-gray" />
              </ListItemAvatar>
              <ListItemText color="primary">
                <ListItemText
                  primary={item?.label}
                  secondary={
                    <>
                      {item?.ppeAssignments?.map(employeeData => (
                        <>
                          <Chip
                            style={{ marginRight: '5px', marginTop: '5px' }}
                            avatar={<Avatar url={employeeData?.employee?.avatarPath} />}
                            label={employeeData.employee.name}
                          />
                        </>
                      ))}
                    </>
                  }
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
