/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BodyRegionDefinitionDto } from '../models/BodyRegionDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BodyRegionDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns BodyRegionDefinitionDto OK
     * @throws ApiError
     */
    public static updateBodyRegionDefinition(
        id: string,
        requestBody: BodyRegionDefinitionDto,
    ): CancelablePromise<BodyRegionDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/body-region-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBodyRegionDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/body-region-definitions/${id}`,
        });
    }

    /**
     * @returns BodyRegionDefinitionDto OK
     * @throws ApiError
     */
    public static getBodyRegionDefinitions(): CancelablePromise<Array<BodyRegionDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/body-region-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns BodyRegionDefinitionDto OK
     * @throws ApiError
     */
    public static saveBodyRegionDefinition(
        requestBody: BodyRegionDefinitionDto,
    ): CancelablePromise<BodyRegionDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/body-region-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}