import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteRestReport, getRestReport, RestReportResult } from 'api/hs/definitions/health-definitions/rest-report';
import RestReportEditDialog from 'Common/Forms/Definitions/HealthDefinition/RestReport/RestReportEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function RestReport() {
  return (
    <BaseDefinitionPage<RestReportResult>
      listLabelPropName="reasonName"
      fetchData={getRestReport}
      FormDialog={RestReportEditDialog}
      onDelete={deleteRestReport}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
