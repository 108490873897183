import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { dateSort, MakeRequired } from 'utils';

import { ReferralsPayload } from './referrals.payload';
import { ReferralsResult } from './referrals.result';

export async function getReferral(employeeId: string, examinationId?: string): Promise<ReferralsResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ReferralsResult[]>(`/referrals?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ReferralsResult[]>(`/referrals?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function saveReferral(body: ReferralsPayload): Promise<ReferralsResult> {
  const [err, res] = await tryCatch(HSApi.post('/referrals', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateReferral({ id, ...body }: MakeRequired<Partial<ReferralsResult>, 'id'>): Promise<ReferralsResult> {
  const [error, res] = await tryCatch(HSApi.put<ReferralsResult>(`/referrals/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteReferral(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/referrals/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function previewReferral(id: string) {
  const [err, res] = await tryCatch(HSApi.get(`/referrals/${id}/detail-view/export?type=HTML`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
