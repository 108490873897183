import { Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DomainIcon from '@material-ui/icons/Domain';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { deletePosition, getPosition, PositionResult } from 'api/hs/definitions/workplace-definition/position';
import PositionEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Position/PositionEditDialog';

import { ResourceContext } from '../../context/resource.context';
import BaseDefinitionPage from '../BaseDefinitionPage';
import { ListItemTemplateProps } from '../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  positionName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
  },
  positionType: {
    fontSize: '12px',
    color: 'coral',
    fontWeight: 'bold',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  positionType2: {
    fontSize: '10px',
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'rgb(170, 170, 170)',
    fontWeight: 'bold',
  },

  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<PositionResult>) {
  const classes = useStyles();
  const { exposures } = useContext(ResourceContext);

  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <AssignmentIndIcon fontSize="medium" />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.positionName}>{item.positionName}</span>
          </div>
          <div>
            <span className={classes.positionType}>Maruziyetler : </span>
            <span className={classes.positionType2}>
              {item?.exposureIds.length > 0
                ? item?.exposureIds?.map(exposureIdsData => findByKey(exposures, 'id', exposureIdsData)?.exposureName).join(' , ')
                : 'Maruziyet Ataması yapılmamıştır.'}
            </span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}

export default function Position() {
  return (
    <BaseDefinitionPage<PositionResult>
      listLabelPropName="positionName"
      fetchData={getPosition}
      FormDialog={PositionEditDialog}
      onDelete={deletePosition}
      Icon={<DomainIcon />}
      ListItemTemplate={ItemTemplate}
      searchName="positionName"
    />
  );
}
