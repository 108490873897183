import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import { saveStation, updateStation } from 'api/hs/definitions/workplace-definition/station';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { StationModel } from 'model/definitions/workplace-definition/station.model';

import { ResourceContext } from '../../../../../context/resource.context';
import { SelectOptionsContext } from '../../../../../context/select-options.context';
import AutoComplete from '../../../../Form/AutoComplete';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the station is created/updated
   */
  onClose(success: boolean): void;

  data: StationModel;
}

export default function CompanyEdit({ open, onClose, data }: Props) {
  const { exposures } = useContext(SelectOptionsContext);
  return (
    <BaseDefinitionForm
      open={open}
      onClose={onClose}
      onSave={saveStation}
      onUpdate={updateStation}
      data={data}
      resourceReloadName="workStations"
      createTitle="İş İstasyonu Ekle"
      updateTitle="İş İstasyonu Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="stationName" label="İş İstasyon Adı Giriniz" />
      </Grid>
      <Grid item md={12}>
        <AutoComplete name="exposureIds" options={exposures} label="Maruziyetler" />
      </Grid>
    </BaseDefinitionForm>
  );
}
