import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from 'api/hs/employee';
import {
  NotificationPayload,
  saveOpinionNotification,
  savePrescriptionsNotification,
  saveReferralsNotification,
  saveRestReportNotification,
} from 'api/hs/notification';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import AutoComplete from '../../Form/AutoComplete';
import { SelectOption } from '../../Form/Select';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  notificationType: string;

  notificationReferenceId: string;

  onClose(success: boolean): void;
}

export default function EmailForm({ open, onClose, notificationType, notificationReferenceId }: Props) {
  const classes = useStyles();
  const { showMessage } = useContext(AlertContext);
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { employeeId } = useParams<{ employeeId: string }>();

  function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
    return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
  }

  async function employeeOptionFetcher(value: string) {
    const res = await searchEmployee(value);

    return mapEmployeeToOptions(res);
  }

  async function employeeLabelFetcher(values: string[]) {
    const res = await getEmployeesByIds(values);

    return mapEmployeeToOptions(res);
  }

  function notificationTypes(type: string, body: NotificationPayload) {
    switch (type) {
      case 'REFERRAL':
        return saveReferralsNotification(body);
      case 'REST_REPORT':
        return saveRestReportNotification(body);
      case 'PRESCRIPTION':
        return savePrescriptionsNotification(body);
      case 'OPINION':
        return saveOpinionNotification(body);
      default:
        return type;
    }
  }

  const handleSubmit = useCallback(
    async values => {
      const body: NotificationPayload = {
        notificationType,
        notificationReferenceId,
        employeeIds: values.employeeIds,
      };

      try {
        await notificationTypes(body.notificationType, body);
        showMessage('Gönderme işlemi başarılı.', 'success', 'info');
        onClose(true);
      } catch (e) {
        showMessage('Gönderme işlemi başarısız.', 'error', 'info');
        onClose(false);
      }
    },
    [notificationType, notificationReferenceId, showMessage, onClose]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    defer();

    setValue('employeeIds', [employeeId]);
    trigger();
  }, [open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>E-posta Gönder</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <AutoComplete
                  name="employeeIds"
                  label="Personel Seç"
                  optionFetcher={employeeOptionFetcher}
                  labelFetcher={employeeLabelFetcher}
                />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  Gönder
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
