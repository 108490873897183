import { tryCatch } from '@thalesrc/js-utils';

import { RiskAssessmentHazardFilter } from '../../client';
import HSApi from '../HSApi';
import { ExaminationListResult } from '../TransactionAndLists/ExaminationList';
export async function getRiskAssessmentHazardsList(page = 0, size = 20, order: null | string, filters: any) {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof RiskAssessmentHazardFilter)[] = [
    'maxDate',
    'minDate',
    'reviseMaxDate',
    'reviseMinDate',
    'revisePeriod',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    riskAssessmentHazardFilter: { ...parsedFilters },
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post(`/risk-assessments/hazards/view`, payload);
  const ids = res.data.content?.reduce((acc, item) => [...acc, item?.id], []);
  const countActionData = await getRiskAssessmentCountAction(ids);

  const riskAssessmentActionCount = res.data.content.map(d => {
    return {
      ...d,
      actionCountNumber: countActionData[d.id],
    };
  });
  const riskAssessmentResultData = { ...res.data, content: riskAssessmentActionCount };
  return riskAssessmentResultData;
}

export async function getRiskAssessmentCountAction(ids: string[]) {
  const [error, res] = await tryCatch(HSApi.get(`/actions/count-by-references?referenceIds=${ids}`));
  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getRiskAssessmentHazardIdActions(definitionId: string, page = 0, size = 20, order: null | string): Promise<any> {
  const payload = {
    actionFilter: {
      referenceId: definitionId,
    },
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<any>(`/actions/views`, payload);

  return res.data.content;
}

// @ts-ignore
export async function riskAssessmentFileUpload(id: any['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`/risk-assessments/hazards/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function exportRiskAssessmentHazard(filters: RiskAssessmentHazardFilter): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof RiskAssessmentHazardFilter)[] = [
    'maxDate',
    'minDate',
    'reviseMaxDate',
    'reviseMinDate',
    'revisePeriod',
    'searchAffected',
    'searchDescription',
    'searchHazardDescription',
    'searchRiskDescription',
    'searchWorkDescription',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    riskAssessmentHazardFilter: parsedFilters,
  };

  const res = await HSApi.post<ExaminationListResult>(`/risk-assessments/hazards/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'risk-degerlendirme.xlsx';
  a.click();
}
