import { makeStyles, Typography } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import React, { ComponentType } from 'react';
import { Route, Switch } from 'react-router';

import RouterTabs, { TabModel } from 'Common/RouterTabs';
import Toolbar from 'Common/Toolbar';

import EmployeeAssigment from './employee';
import PositionAssigment from './position';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'grid',
    width: '100%',
    grid: '100% / max-content max-content 1fr minMax(340px, auto)',
  },
}));

const BASE_PATH = '/ppe/ppe-assignments';
const TABS: (TabModel & { Component: ComponentType })[] = [
  {
    path: '',
    label: 'GÖREVE GÖRE ATAMA',
    icon: <AccountBalanceIcon className="mb-0 mr-1" fontSize="small" style={{ alignSelf: 'center' }} />,
    Component: PositionAssigment,
  },
  {
    path: 'employee',
    label: 'PERSONELE GÖRE ATAMA',
    icon: <PersonIcon className="mb-0 mr-1" fontSize="small" style={{ alignSelf: 'center' }} />,
    Component: EmployeeAssigment,
  },
];

export default function PpeAssignmentsList() {
  const classes = useStyles();

  return (
    <>
      <Toolbar color="secondary" className={`py-1`} toolbarProps={{ className: classes.toolbar }}>
        <AssignmentIcon className="icon-school mr-2" />
        <Typography variant="h6" className="mr-4">
          KKD Atama
        </Typography>
        <span className="width-100" />
        <RouterTabs rootPath={BASE_PATH} tabs={TABS} variant="toolbar" />
      </Toolbar>
      <Switch>
        {TABS.map(tab => (
          <Route key={tab.path} path={`${BASE_PATH}/${tab.path}`} exact={tab.path === ''}>
            <tab.Component />
          </Route>
        ))}
      </Switch>
    </>
  );
}
