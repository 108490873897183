import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getReferral, ReferralsResult } from 'api/hs/examination/referrals';
import { ReferralsModel } from 'model/employee/examination/referrals.model';
import { useAsyncEffect } from 'utils';

import Referral from './Referral';
import { ReferralsContextContext } from './referrals.context';

export default function Index() {
  const [referrals, setReferrals] = useState<ReferralsResult[]>(null);
  const [loading, setLoading] = useState(true);
  const [previewData, setPreviewData] = useState('');
  const [newPreviewDialog, setPreviewDialogOpened] = useState(false);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [newEmailDialog, setEmailDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<ReferralsModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getReferral(employeeId, examinationId);
    setReferrals(result);
    setLoading(false);
  }, [examinationId, employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <ReferralsContextContext.Provider
      value={{
        referrals,
        reload: fetch,
        loading,
        newPreviewDialog,
        previewData,
        setPreviewData,
        setPreviewDialogOpened,
        newEmailDialog,
        setEmailDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Referral />
    </ReferralsContextContext.Provider>
  );
}
