import axios from 'axios';

export const API_EVENTS = new EventTarget();

async function getAuthToken() {
    try {
        const date = new Date();
        if(sessionStorage.getItem('tenantTime') != undefined && date.getTime() < parseFloat(sessionStorage.getItem('tenantTime'))) {
            return sessionStorage.getItem('tenantId');
        } else {
            date.setSeconds(date.getSeconds() + 30);
            const response = await axios.get('https://www.artimetrik.net/api/checkLicenceDB');
            sessionStorage.setItem('tenantId', response.data.token);
            sessionStorage.setItem('tenantTime', date.getTime().toString());
            return response.data.token;
        }
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
}
const AXIOS = axios.create({
    baseURL: process.env.REACT_APP_HS_API_BASE_URL,
});

AXIOS.interceptors.request.use(
    async (config) => {
        try {
            const token = await getAuthToken();
            config.headers['X-TenantID'] = token;
        } catch (error) {
            console.error('Error adding X-TenantID header:', error);
        }

        return config;
    },
    (err) => Promise.reject(err)
);

AXIOS.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.message.includes('Request failed with status code 403')) {
            API_EVENTS.dispatchEvent(new CustomEvent('notAuthorized'));
        }

        return Promise.reject(err);
    }
);

export default AXIOS;
