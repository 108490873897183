/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisabilityDetailDto } from './DisabilityDetailDto';

export type DisabilityDto = {
    id?: string;
    description?: string;
    examinationId?: string;
    percentage?: number;
    employeeId?: string;
    status?: DisabilityDto.status;
    disabilityDetails?: Array<DisabilityDetailDto>;
}

export namespace DisabilityDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
