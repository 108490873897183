/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageVitalSignsView } from '../models/PageVitalSignsView';
import type { VitalSignsViewRequest } from '../models/VitalSignsViewRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class VitalSignsViewControllerService {

    /**
     * @param requestBody
     * @returns PageVitalSignsView OK
     * @throws ApiError
     */
    public static getAllVitalSigns(
        requestBody: VitalSignsViewRequest,
    ): CancelablePromise<PageVitalSignsView> {
        return __request({
            method: 'POST',
            path: `/vital-signs/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}