import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Tooltip, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import React, { useCallback, useContext, useState } from 'react';

import { ExportExaminationList } from 'api/hs/TransactionAndLists/ExaminationList/examination-list';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import CommonToolbar from 'Common/Toolbar';

import { examinationGetList, updateExaminations } from '../../../../api/hs/Examinations/examinations';
import { AlertContext } from '../../../../context/alert.context';
import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function Toolbar() {
  const classes = useStyles();
  const { rawData, extraArgs, refresh, data } = useContext(HSTableContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const { showMessage } = useContext(AlertContext);
  const exportFile = useCallback(async () => {
    await ExportExaminationList(extraArgs.filters);

    closeMenu();
  }, [closeMenu, extraArgs]);

  const ministryControl = useCallback(async () => {
    const getList: string[] = [];

    data?.forEach(x => {
      if (x?.ibysExaminationView?.requestState === 'NOT_SENT' || x?.ibysExaminationView?.requestState === 'ON_STAND_BY') {
        if (x.ibysExaminationView.examIdOnIbysServer) getList.push(x.ibysExaminationView.examIdOnIbysServer);
      }
    });

    /*if (getList.length > 0) {
      const getValue = await examinationGetList(getList);
      for (const [key, value] of Object.entries(getValue)) {
        if (value.processResult === 200) {
          await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
            processResult: 'SUCCESSFUL',
            ibysRequestState: 'SENT',
            processResultMessage: value.processResultMessage,
          });
        } else if (value.processResult !== 0) {
          await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
            processResult: 'UNSUCCESSFUL',
            ibysRequestState: 'INCORRECT_REQUEST',
            processResultMessage: value.processResultMessage,
          });
        } else {
          // processResult == 0
          if (value.savingResult === 200 || value.savingResult === 0) {
            await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
              processResult: 'CONTINUES',
              ibysRequestState: 'ON_STAND_BY',
            });
          } else {
            await updateExaminations(data?.find(item => item?.ibysExaminationView?.examIdOnIbysServer === key)?.ibysExaminationView?.id, {
              processResult: 'UNSUCCESSFUL',
              ibysRequestState: 'INCORRECT_REQUEST',
              processResultMessage: value.processResultMessage,
            });
          }
        }
      }
    }
    showMessage('Bakanlıktan güncel bilgiler alındı.', 'success', 'info');
    refresh();*/
  }, [refresh, showMessage, data]);

  return (
    <CommonToolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Muayene Listesi ( {rawData?.totalElements} )
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <Tooltip title="Bakanlık Kontrol">
          <IconButton onClick={ministryControl} color="inherit">
            <SettingsBackupRestoreIcon />
          </IconButton>
        </Tooltip>
        <VisibleColumnMenu />
        <IconButton color="inherit" onClick={openMenu}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -125 }} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <List>
            <ListItem onClick={exportFile} className="cursor-pointer">
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Excel'e Aktar</Typography>
            </ListItem>
          </List>
        </Popover>
        <Filters />
      </div>
    </CommonToolbar>
  );
}
