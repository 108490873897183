import { uniqueId } from '@thalesrc/js-utils';
import { useState } from 'react';

export default function useUniqueId(): number;
export default function useUniqueId(prefix: string): string;
export default function useUniqueId(prefix?: string): string | number {
  const [id] = useState(() => uniqueId(prefix));

  return id;
}
