export const eventTypes = {
  PERIODIC_EXAMINATION: {
    value: 'Periyodik Muayene',
    color: '#E91E63',
    editable: true,
  },
  VACCINE: {
    value: 'Aşı',
    color: '#FF9800',
    editable: true,
  },
  TRAINING: {
    value: 'Eğitim',
    color: '#6F69AC',
    editable: true,
  },
  USER_DEFINED: {
    value: 'Not',
    color: '#2196F3',
    editable: true,
  },
  COMMITTEE_MEETING: {
    value: 'Kurul Toplantısı',
    color: '#42af30',
    editable: true,
  },
  PREGNANCY: {
    value: 'Gebelik',
    color: '#f84af2',
    editable: false,
  },
  ACTION: {
    value: 'Aksiyon',
    color: '#f44336',
    editable: true,
  },
  DRILL: {
    value: 'Tatbikat',
    color: '#b9d095',
    editable: true,
  },
  PPE: {
    value: 'Kişişel Koruyucu Donanım',
    color: '#506250',
    editable: false,
  },
  RISK_ASSESSMENT: {
    value: 'Risk Değerlendirme',
    color: '#4b2535',
    editable: false,
  },
};
