import React from 'react';

import Control from './Control';
import { TreeBoxControl } from './control.model';
import { TreeBoxController } from './controller.model';
import Group from './Group';

interface TreeBoxItemProps {
  control: TreeBoxControl | TreeBoxController;
}

export default function Item({ control }: TreeBoxItemProps) {
  return 'children' in control ? <Group control={control} /> : <Control control={control} />;
}
