import { IconButton, List, ListItem, ListItemIcon, makeStyles, Popover, Typography } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useContext, useState } from 'react';

import { ExportTrainingEmployeeList } from 'api/hs/trainings/TrainingEmployeeFilter/training-employee-list';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import CommonToolbar from 'Common/Toolbar';

import Filters from './Filters';
import VisibleColumnMenu from './VisibleColumnMenu';
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default function Toolbar() {
  const classes = useStyles();
  const { rawData, extraArgs } = useContext(HSTableContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const exportFile = useCallback(async () => {
    await ExportTrainingEmployeeList(extraArgs.filters ?? { isPlanned: true });
    closeMenu();
  }, [closeMenu, extraArgs]);

  return (
    <CommonToolbar color="secondary">
      <Typography variant="h6" color="inherit">
        Eğitimlere Katılan Personel Listesi ({rawData?.totalElements})
      </Typography>
      <span className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <VisibleColumnMenu />
        <IconButton color="inherit" onClick={openMenu}>
          <MoreIcon />
        </IconButton>
        <Popover anchorEl={anchorEl} anchorOrigin={{ vertical: 35, horizontal: -125 }} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <List>
            <ListItem onClick={exportFile} className="cursor-pointer">
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Excel'e Aktar</Typography>
            </ListItem>
          </List>
        </Popover>
        <Filters />
      </div>
    </CommonToolbar>
  );
}
