import {
  Breadcrumbs,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { BuildingPartDefinitionControllerService } from '../../../../../../api/client';
import Button from '../../../../../../Common/Button';
import { BuildingsFloorsPartsContext } from '../../buildings-floors-parts.context';
import EditDialog from './EditDialog';

export default function ListTrainer() {
  const { loading, buildingPartDefinition, setEditDialogOpened, setSelectedData, reload } = useContext(BuildingsFloorsPartsContext);
  const { openWarnDialog } = useContext(DialogContext);
  const history = useHistory();
  const { showMessage } = useContext(AlertContext);
  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const onDelete = useCallback(
    async (buildingFloorPart: any) => {
      const submitted = await openWarnDialog({
        text: `${buildingFloorPart.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await BuildingPartDefinitionControllerService.deleteBuildingPartDefinition(buildingFloorPart.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  const routeFloor = useCallback(
    (buildingId: string) => {
      history.push(`/definitions/workplace/buildings/${buildingId}/floors`);
    },
    [history]
  );

  const routeBuildings = useCallback(() => {
    history.push(`/definitions/workplace/buildings`);
  }, [history]);

  const routeFloors = useCallback(
    (buildingId: string) => {
      history.push(`/definitions/workplace/buildings/${buildingId}/floors`);
    },
    [history]
  );

  return (
    <div className="m-2">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" onClick={() => routeBuildings()}>
          Binalar
        </Link>
        <Link color="inherit" onClick={() => routeFloors(buildingPartDefinition[0]?.floorDefinition?.buildingDefinition?.id)}>
          {buildingPartDefinition[0]?.floorDefinition?.buildingDefinition?.name}
        </Link>

        <Typography color="textPrimary">{buildingPartDefinition[0]?.floorDefinition?.name}</Typography>
      </Breadcrumbs>

      <IndicatorWrapper loading={loading} data={buildingPartDefinition} errorMessage="Gösterilecek Bölüm Bilgisi Bulunamadı">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold text-gray" width="5%" align="left" />
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Kat
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Bölümler
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buildingPartDefinition.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <BusinessIcon style={{ color: 'gray' }} />
                  </TableCell>
                  <TableCell align="left">{item?.floorDefinition?.name}</TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
