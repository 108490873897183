import React, { PropsWithChildren, useMemo } from 'react';

import { PaginationContext, PaginationContextType } from './pagination.context';
import { PaginationProps } from './pagination.props';

export default function PaginationContextProvider({
  page,
  size,
  totalPage,
  children,
  onSizeChange,
  onPageChange,
}: PropsWithChildren<PaginationProps>) {
  const context = useMemo<PaginationContextType>(() => ({ page, size, totalPage, onSizeChange, onPageChange }), [
    page,
    size,
    totalPage,
    onSizeChange,
    onPageChange,
  ]);

  return <PaginationContext.Provider value={context}>{children}</PaginationContext.Provider>;
}
