import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../../HSApi';
import { Icd10WarningLabelsPayload } from './Icd10-warning-labels.payload';
import { Icd10WarningLabelsResult } from './icd10-warning-labels.result';
import { Icd10Result } from './Icd10.result';

export async function searchIcd10(codeOrName: string, page = 0, size = 10): Promise<Icd10Result> {
  const res = await HSApi.get<Icd10Result>(`/icd10-definitions?codeOrName=${codeOrName}&page=${page}&size=${size}`);
  const ids = res.data.content?.reduce((acc, item) => [...acc, item?.id], []);
  const labels = await getIcs10WarningLabels(ids);

  const icd10WarningLabels = res.data.content.map(d => {
    return {
      ...d,
      viewType: labels.find(a => a.icd10Id === d.id)?.viewType,
      label: labels.find(a => a.icd10Id === d.id)?.label,
      labelId: labels.find(a => a.icd10Id === d.id)?.id,
    };
  });
  const icd10WarningLabelsData = { ...res.data, content: icd10WarningLabels };
  return icd10WarningLabelsData;
}

export async function getIcd10Page(page = 0, size = 10): Promise<Icd10Result> {
  const res = await HSApi.get<Icd10Result>(`/icd10-definitions?page=${page}&size=${size}`);
  const ids = res.data.content?.reduce((acc, item) => [...acc, item?.id], []);
  const labels = await getIcs10WarningLabels(ids);

  const icd10WarningLabels = res.data.content.map(d => {
    return {
      ...d,
      viewType: labels.find(a => a.icd10Id === d.id)?.viewType,
      label: labels.find(a => a.icd10Id === d.id)?.label,
      labelId: labels.find(a => a.icd10Id === d.id)?.id,
    };
  });
  const icd10WarningLabelsData = { ...res.data, content: icd10WarningLabels };
  return icd10WarningLabelsData;
}

export async function getIcs10WarningLabels(ids: string[]): Promise<Icd10WarningLabelsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<Icd10WarningLabelsResult[]>(`/icd10-warning-labels?icd10Ids=${ids}`));
  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteIcs10WarningLabels(id: string): Promise<Icd10WarningLabelsResult[]> {
  const [error, res] = await tryCatch(HSApi.delete<Icd10WarningLabelsResult[]>(`/icd10-warning-labels/${id}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function updateIcs10WarningLabels(id: string, body: Icd10WarningLabelsPayload): Promise<Icd10WarningLabelsResult[]> {
  const [error, res] = await tryCatch(HSApi.put<Icd10WarningLabelsResult[]>(`/icd10/${id}/icd10-warning-labels`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    throw error;
  }

  return res.data;
}
