import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { ClassificationDefinitionPayload } from './classification-definition.payload';
import { ClassificationDefinitionResult } from './classification-definition.result';

export async function getClassificationDefinitions(): Promise<ClassificationDefinitionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ClassificationDefinitionResult[]>(`/firms/ppe/classification-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveClassificationDefinition(body: ClassificationDefinitionPayload): Promise<ClassificationDefinitionResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/ppe/classification-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateClassificationDefinition({
  id,
  ...body
}: MakeRequired<Partial<ClassificationDefinitionResult>, 'id'>): Promise<ClassificationDefinitionResult> {
  const [error, res] = await tryCatch(HSApi.put<ClassificationDefinitionResult>(`/firms/ppe/classification-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteClassificationDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/ppe/classification-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminClassificationDefinitions(): Promise<ClassificationDefinitionResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ClassificationDefinitionResult[]>(`/ppe/classification-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminClassificationDefinition(body: ClassificationDefinitionPayload): Promise<ClassificationDefinitionResult> {
  const [err, res] = await tryCatch(HSApi.post('/ppe/classification-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminClassificationDefinition({
  id,
  ...body
}: MakeRequired<Partial<ClassificationDefinitionResult>, 'id'>): Promise<ClassificationDefinitionResult> {
  const [error, res] = await tryCatch(HSApi.put<ClassificationDefinitionResult>(`/ppe/classification-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminClassificationDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe/classification-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
