import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAdminVacccine, updateAdminVacccine } from 'api/hs/definitions/health-definitions/vaccine';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { VaccineModel } from 'model/definitions/health-definitions/vaccine.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the vaccine is created/updated
   */
  onClose(success: boolean): void;

  data: VaccineModel;
}

export default function AdminVaccine({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<VaccineModel>
      open={open}
      onClose={onClose}
      onSave={saveAdminVacccine}
      onUpdate={updateAdminVacccine}
      data={data}
      resourceReloadName="vaccines"
      createTitle="Aşı Ekleme"
      updateTitle="Aşı Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="name" label="Aşı Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
