/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccidentFilter = {
    accidentDateMax?: string;
    accidentDateMin?: string;
    companyIds?: Array<string>;
    employeeIds?: Array<string>;
    accidentTypeDefinitionIds?: Array<string>;
    accidentInjuryTypeDefinitionIds?: Array<string>;
    departmentIds?: Array<string>;
    stationIds?: Array<string>;
    accidentDescription?: string;
    shiftId?: string;
    accidentReviewStatus?: AccidentFilter.accidentReviewStatus;
    bodyRegionDefinitionIds?: Array<string>;
    severityDefinitionIds?: Array<string>;
}

export namespace AccidentFilter {

    export enum accidentReviewStatus {
        NOT_STARTED = 'NOT_STARTED',
        IN_PROGRESS = 'IN_PROGRESS',
        COMPLETED = 'COMPLETED',
    }


}
