import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export const chartUseStyles = makeStyles({
  rootBgRed: {
    minWidth: 275,
    background: '#a80b1e',
    color: 'white',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
  },
  rootBgOrange: {
    minWidth: 275,
    background: '#FFE000',
    color: 'white',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
  },
  rootBgCyan: {
    minWidth: 275,
    background: '#02AAB0',
    color: 'white',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
  },
  rootBgGreen: {
    minWidth: 275,
    background: '#3e9a09',
    color: 'white',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
  },
});

interface SimpleCardProps {
  title: string;
  amount: number;
  className: any;
}

export default function SimpleCard(props: SimpleCardProps) {
  return (
    <Card className={`${props.className} text-center`} variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h2" component="h2">
          {props.amount}
        </Typography>
      </CardContent>
    </Card>
  );
}
