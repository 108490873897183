import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { stopPropagation } from 'utils';

import { WorkEquipmentTypeDefinitionControllerService } from '../../../../../api/client';
import { WorkEquipmentTypeDefinitionDto } from '../../../../../api/client';
import { TypeDefinitionContext } from '../../type-definition.context';

interface Props {
  data: WorkEquipmentTypeDefinitionDto;
}

export default function Action({ data }: Props) {
  const { setSelectedData, setEditDialogOpened, reload } = useContext(TypeDefinitionContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const edit = useCallback(
    item => () => {
      setSelectedData(item);
      setEditDialogOpened(true);
      closeMenu();
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const handleDelete = useCallback(async () => {
    const submitted = await openWarnDialog({
      text: `${data.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await WorkEquipmentTypeDefinitionControllerService.deleteAllWorkEquipmentTypeDefinition(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [data, reload, openWarnDialog, showMessage]);

  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="default" />
            </ListItemIcon>
            <Typography variant="inherit">Sil</Typography>
          </MenuItem>
          <MenuItem onClick={edit(data)}>
            <ListItemIcon>
              <EditIcon fontSize="default" />
            </ListItemIcon>
            <Typography variant="inherit">DÜZENLE</Typography>
          </MenuItem>
        </Menu>
      </span>
    </>
  );
}
