import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { EmployeeTrainingListPlanningResult } from 'api/hs/definitions/trainings/employee-training/employee-training-list-planning';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { ResourceContext } from '../../../../../../../context/resource.context';
import { stopPropagation, useDateFormat } from '../../../../../../../utils';
function ActionComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  return (
    <div key={row.id}>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem>
            <ListItemIcon>
              <VisibilityIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Sertifika Önizleme</Typography>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <GetAppIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Sertifika İndir</Typography>
          </MenuItem>
        </Menu>
      </span>
    </div>
  );
}

function CompleteDateComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const format = useDateFormat('dd MMMM yyyy HH:mm');

  return <>{row?.completedDate ? format(new Date(`${row?.completedDate}`)) : null}</>;
}

function TrainerComponent({ row }: { row: EmployeeTrainingListPlanningResult }) {
  const { trainers } = useContext(ResourceContext);

  return <>{row?.trainingTrainers?.map(trainer => findByKey(trainers, 'id', trainer?.trainerId)?.name).join(' , ')}</>;
}

export const CELL: TableColumn<EmployeeTrainingListPlanningResult>[] = [
  {
    id: 'title',
    defaultVisibility: true,
    label: 'Eğitim Adı',
    width: '15%',
  },
  {
    id: Symbol(),
    defaultVisibility: true,
    label: 'Eğitimciler',
    width: '15%',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return <TrainerComponent row={row} />;
    },
  },
  {
    id: 'validityPeriodInMonth',
    defaultVisibility: true,
    label: 'Geçerlilik Süresi (Ay)',
  },
  {
    id: 'completedDate',
    defaultVisibility: true,
    label: 'Tamamlanma Tarihi',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return (
        <>
          <CompleteDateComponent row={row} />
        </>
      );
    },
  },
  {
    id: 'isFaceToFace',
    defaultVisibility: true,
    label: 'Yüz yüze',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.isFaceToFace ? 'Yüzyüze' : 'Uzaktan';
    },
  },
  {
    id: 'atWorkplace',
    defaultVisibility: true,
    label: 'İş yerinde',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.atWorkplace ? 'Evet' : 'Hayır';
    },
  },
  {
    id: 'isInAnnualPlan',
    defaultVisibility: true,
    label: 'Yıllık Plan Dahil',
    render(row: EmployeeTrainingListPlanningResult): string {
      return row?.isInAnnualPlan ? 'Evet' : 'Hayır';
    },
  },
  {
    id: 'description',
    defaultVisibility: true,
    label: 'Açıklama',
  },
  {
    id: Symbol(),
    width: '5%',
    label: 'İşlemler',
    render(row: EmployeeTrainingListPlanningResult): JSX.Element {
      return <ActionComponent row={row} />;
    },
  },
];
