import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { getAccidentIdActions } from '../../api/hs/accident/accident';
import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import Toolbar from '../../Common/Toolbar';
import CustomAction from '../../CustomAction';
import BaseSection from '../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import AccidentEmployeesRecords from '../AccidentEmployeesRecords';
import Reviewer from '../Accidents/Reviewer';
import AccidentFileUpload from './AccidentFileUpload';
import AccidentRecordForm from './AccidentRecordForm';

const AccidentRecord = () => {
  const [accidentId, setAccidentId] = useState(null);
  const saveAccidentId = useCallback((id: string) => setAccidentId(id), []);
  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      if (!accidentId) return;
      return getAccidentIdActions(
        accidentId,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [accidentId]
  );
  return (
    <div>
      <Toolbar color="secondary" className="mb-2">
        <Typography variant="h6">Kaza Kaydı Oluştur</Typography>
      </Toolbar>
      <BaseSection icon="icon-flash" title="Kaza Kaydı" expandedByDefault>
        <AccidentRecordForm onSave={saveAccidentId} />
      </BaseSection>
      {accidentId && (
        <>
          <BaseSection icon="icon-flash" title="Kaza Geçiren Personel Ekleme">
            <AccidentEmployeesRecords accidentId={accidentId} />
          </BaseSection>
          <BaseSection icon="icon-flash" title="Kaza Aksiyon Ekleme">
            <CustomAction actionReferenceValue={'ACCIDENT'} referenceId={accidentId} fetcher={fetchActionList} />
          </BaseSection>
          <BaseSection icon="icon-attachment" title={`Kaza ile İlgili Dosyalar`}>
            <AccidentFileUpload accidentId={accidentId} />
          </BaseSection>
          <BaseSection icon="icon-people" title="Kaza İnceleme Ekibi">
            <Reviewer accidentId={accidentId} />
          </BaseSection>
        </>
      )}
    </div>
  );
};

export default AccidentRecord;
