import { createContext } from 'react';

import { BodyMeasurementsModel, BodyMeasurementsResult } from 'api/hs/examination/body-measurements';

export interface BodyMeasurementsContextType {
  bodyMeasurements: BodyMeasurementsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: BodyMeasurementsModel;

  setSelectedData(data: BodyMeasurementsModel): void;
}

export const BodyMeasurementsContext = createContext<BodyMeasurementsContextType>(null);
