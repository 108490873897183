import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { WorkEquipmentDefinitionControllerService } from '../../../../api/client';
import { WorkEquipmentDefinitionViewDto } from '../../../../api/client';
import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../context/dialog.context';
import { useXhr } from '../../../../utils';
import { WorkEquipmentDefinitionContext } from '../../work-equipment-definition.context';

function ActionComponent({ row }: { row: WorkEquipmentDefinitionViewDto }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(WorkEquipmentDefinitionContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog } = useContext(DialogContext);
  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await WorkEquipmentDefinitionControllerService.deleteWorkEquipmentDefinition(id);
      await refresh();
    },
    'Seçili İş Ekipmanı Silindi',
    'İş Ekipmanı silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.definitionName} isimli kayıtı silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.definitionId);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const routePeriodicControl = useCallback(() => {
    history.push(`/work-equipment/definition/${row?.definitionId}`, { rowData: row });
  }, [history, row]);

  return (
    <div key={row?.definitionId}>
      <IconButton onClick={routePeriodicControl} id={row?.definitionId}>
        <BuildIcon />
      </IconButton>
      <IconButton id={row?.definitionId} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.definitionId} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.definitionId} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export const COLUMNS: TableColumn<WorkEquipmentDefinitionViewDto>[] = [
  {
    id: 'imagePath',
    width: '5%',
    label: 'Resim',
    sortable: true,
    defaultVisibility: true,
    render(row): JSX.Element {
      return (
        <Avatar alt={row?.definitionName} src={row?.imagePath ? `/saglik-gozetimi/public/images/work_equipment/${row?.imagePath}` : null} />
      );
    },
  },
  {
    id: 'definitionName',
    width: '15%',
    label: 'Tanımı',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'model',
    width: '10%',
    label: 'Model',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'brand',
    width: '10%',
    label: 'Marka',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'companyName',
    width: '20%',
    sortable: true,
    label: 'Şirket',
    defaultVisibility: true,
  },
  {
    id: 'code',
    width: '10%',
    label: 'Kodu',
    sortable: true,
    defaultVisibility: true,
  },
  {
    id: 'typeName',
    width: '20%',
    sortable: true,
    label: 'Kategori',
    defaultVisibility: true,
  },
  {
    id: 'definitionId',
    label: 'İşlemler',
    width: '10%',
    render(row: WorkEquipmentDefinitionViewDto): JSX.Element {
      return <ActionComponent key={row?.definitionId} row={row} />;
    },
  },
];
