import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import {
  SaveImmunityDescriptionPayload,
  SaveImmunityPayload,
  UpdateImmunityDescriptionPayload,
  UpdateImmunityPayload,
} from './immunity.payload';
import { ImmunityDescriptionResult, ImmunityResult, ImmunityResultItem } from './immunity.result';

export async function getImmunities(employeeId: string, examinationId?: string): Promise<ImmunityResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ImmunityResult>(`/immunities?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ImmunityResult>(`/immunities?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveImmunity(body: SaveImmunityPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/immunities', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateImmunity(id: ImmunityResultItem['id'], body: UpdateImmunityPayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.put(`/immunities/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getImmunityDescription(employeeId: string, examinationId?: string): Promise<ImmunityDescriptionResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<ImmunityDescriptionResult>(`/immunity-descriptions?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<ImmunityDescriptionResult>(`/immunity-descriptions?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveImmunityDescription(body: SaveImmunityDescriptionPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/immunity-descriptions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateImmunityDescription(id: any, body: UpdateImmunityDescriptionPayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.put(`/immunity-descriptions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
