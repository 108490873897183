import React from 'react';
import { Line } from 'react-chartjs-2';

import { GraphModel } from './graph.model';

interface Axis {
  x: string;
  y: string;
}
interface Props {
  data: GraphModel;

  axisLabels: Axis;
}

const createOptions = (axisLabels: Axis) => {
  return {
    scales: {
      xAxes: [
        {
          display: true,
          position: 'top',
          scaleLabel: { display: true, labelString: axisLabels?.x || '' },
        },
      ],
      yAxes: [
        {
          scaleLabel: { display: true, labelString: axisLabels?.y || '' },
          ticks: {
            beginAtZero: true,
            reverse: true,
          },
        },
      ],
    },
  };
};

export default function LineChart({ data, axisLabels }: Props) {
  if (!data) return null;
  return (
    <div>
      <Line data={data} options={createOptions(axisLabels)} />
    </div>
  );
}
