import { modelDataMatcher } from 'utils';

import { GetVaccinesResult, VaccineResultItem } from './vaccine.result';

export class Vaccine {
  public id: string = null;
  public groupId: string = null;
  public vaccineDefinitionId: string = null;
  public description: string = null;
  public order: number = null;
  public isDone: boolean = null;
  public isExternal: boolean = null;
  public lotNumber: string = null;
  public protocolNo: number = null;
  public date: string = null;

  constructor(data: Partial<Vaccine> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: VaccineResultItem): Vaccine[] {
    return data.vaccineDetails.map(
      vaccine =>
        new Vaccine({
          ...data,
          groupId: data.id,
          ...vaccine,
        })
    );
  }

  public static fromServerListData(data: GetVaccinesResult): Vaccine[] {
    return data.flatMap(item => Vaccine.fromServerData(item));
  }
}
