import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { saveTrainingPlanning } from 'api/hs/definitions/trainings/employee-training/employee-training-planning/employee-training-planning';
import AutoComplete from 'Common/Form/AutoComplete';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

import DateInput from '../../../../../../Common/Form/DateInput';
import { TrainingPlanningListContext } from '../training-planning-list.context';
const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;
}

export default function NewTrainingPlanningForm({ open, onClose }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { trainingDefinitions } = useContext(SelectOptionsContext);
  const { reload } = useContext(TrainingPlanningListContext);
  const { employeeId } = useParams<{ employeeId: string }>();
  const handleSubmit = useCallback(
    async values => {
      const formData = getValues();
      try {
        await saveTrainingPlanning({
          trainingEmployees: [
            {
              employeeId,
            },
          ],
          plannedDate: formData.plannedDate,
          trainingDefinitions: formData.trainingIds.map((item: any) => ({
            trainingDefinitionId: item,
          })),
        });
        showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        reload();
        reset();
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
        reset();
      }
    },
    [onClose, showMessage, employeeId, getValues, reload, reset]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();
  }, [open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>Yeni Eğitim Planla</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, getValues, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput name="plannedDate" type="date-time" label="Planlanan Tarih" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <AutoComplete name="trainingIds" options={trainingDefinitions} label="Eğitimler" renderOption="checkbox" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  Kaydet
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
