import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DialogContext } from 'context/dialog.context';

import { FormContext } from './Form/form.context';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',

    '& .avatar-button-container': {
      opacity: 0,
      background: 'rgba(255, 255, 255, .5)',
      transition: 'all .3s ease',
    },
    '&:hover .avatar-button-container': {
      opacity: 1,
    },
  },
  absolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  name: string;
  previewUrl?: string;
  disabled?: boolean;
}

export default function AvatarInput({ previewUrl = '', name, disabled = false }: Props) {
  const classes = useStyles();
  const { setValue, control, trigger, getValues } = useFormContext();
  const rootRef = useRef<HTMLDivElement>();
  const [size, setSize] = useState(0);
  const { openFileDialog } = useContext(DialogContext);
  const [preview, setPreview] = useState(previewUrl);
  const { readonly } = useContext(FormContext);

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const openSelector = useCallback(async () => {
    const files = await openFileDialog({ accept: ['image/*'], title: 'Avatar Yükle' });

    if (!files) {
      return;
    }

    setValue(name, files[0]);
    trigger(name);
    setPreview(URL.createObjectURL(files[0]));
  }, [openFileDialog, setValue, trigger, name]);

  const deleteCurrent = useCallback(async () => {
    setValue(name, null);
    setPreview(previewUrl);
    trigger(name);
  }, [setValue, trigger, name, previewUrl]);

  const value = getValues(name);

  useEffect(() => {
    if (!value) {
      setPreview(previewUrl);
    }
  }, [value, previewUrl]);

  useEffect(() => {
    const width = rootRef.current.clientWidth;
    const height = rootRef.current.clientHeight;

    setSize(Math.min(width, height));
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={() => (
        <div ref={rootRef} className={classes.root}>
          <div className={`${classes.absolute} ${classes.container}`}>
            <Avatar style={{ width: size, height: size }} src={preview}>
              {' '}
              <PhotoCameraIcon />
            </Avatar>
          </div>
          {!notEditable ? (
            <div className={`${classes.absolute} ${classes.container} avatar-button-container`}>
              {getValues(name) ? (
                <IconButton onClick={deleteCurrent}>
                  <DeleteIcon style={{ fontSize: size / 2 }} className="text-black" />
                </IconButton>
              ) : (
                <IconButton onClick={openSelector}>
                  <BackupIcon style={{ fontSize: size / 2 }} className="text-black" />
                </IconButton>
              )}
            </div>
          ) : null}
        </div>
      )}
    />
  );
}
