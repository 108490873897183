import { noop } from '@thalesrc/js-utils';
import { createContext } from 'react';

export interface FormContextType {
  readonly: boolean;
  register(name: string, state: { disabled: boolean }): void;
  unregister(name: string): void;
}

const DEFAULT_CONTEXT: FormContextType = {
  readonly: false,
  register: noop,
  unregister: noop,
};

export const FormContext = createContext<FormContextType>(DEFAULT_CONTEXT);
