import { tryCatch } from '@thalesrc/js-utils';

import { ShiftDefinitionDto } from '../../client';
import HSApi from '../HSApi';

// @ts-ignore
export async function updateShiftDefinitions({ id, ...body }): Promise<ShiftDefinitionDto> {
  const [err, res] = await tryCatch(HSApi.put(`firms/shift-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
// @ts-ignore
export async function updateAdminShiftDefinitions({ id, ...body }): Promise<ShiftDefinitionDto> {
  const [err, res] = await tryCatch(HSApi.put(`/shift-definitions/${id}`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
