import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useFetch } from 'utils';

import { AccidentControllerService, AccidentReviewControllerService } from '../../../api/client';
import { AccidentContentResult } from '../../../api/hs/accident';
import AccidentDetailContainer from './AccidentDetailContainer';
import { AccidentsDetailContext } from './accidents-detail.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<AccidentContentResult>(null);
  const { accidentId } = useParams<{ accidentId: string }>();
  const { data, fetch } = useFetch(() => AccidentControllerService.getAccidentViewById(accidentId), {}, { setLoading });
  const { data: accidentReview } = useFetch(() => AccidentReviewControllerService.getReviewAsAuthorized(accidentId), {});

  return (
    <AccidentsDetailContext.Provider
      value={{
        accidentDetail: data,
        accidentReview,
        reload: fetch,
        loading,
        setLoading,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <AccidentDetailContainer />
    </AccidentsDetailContext.Provider>
  );
}
