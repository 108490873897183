import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { FineKinneyHazardFrequencyDefinitionControllerService } from 'api/client';
import { FineKinneyHazardFrequencyDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import FineKinneyFrequencyDefinitionForm from './Forms/FineKinneyFrequencyDefinitionForm';

export default function MatrixPossibilityDefinition() {
  return (
    <BaseDefinitionPage<FineKinneyHazardFrequencyDefinitionDto>
      listLabelPropName="label"
      fetchData={FineKinneyHazardFrequencyDefinitionControllerService.getAllFirmHazardFrequencyDefinitions}
      FormDialog={FineKinneyFrequencyDefinitionForm}
      Icon={<FlashOnIcon />}
      searchName="label"
      noDelete={true}
      noSave={true}
    />
  );
}
