import React, { useState } from 'react';

import { WorkEquipmentLayoutView } from '../../api/client';
import { WorkEquipmentLayoutContext } from './work-equipment-layout.context';
import WorkEquipmentLayoutListAdd from './WorkEquipmentLayoutListAdd';

export default function PpeStocksIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<WorkEquipmentLayoutView>(null);

  return (
    <WorkEquipmentLayoutContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <WorkEquipmentLayoutListAdd />
    </WorkEquipmentLayoutContext.Provider>
  );
}
