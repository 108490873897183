import { OpenPromise } from '@thalesrc/js-utils';
import { useEffect, useState } from 'react';

export const ON_DESTROY_SYMBOL = Symbol('On Destroy');

/**
 * Makes a promise which resolves `ON_DESTROY_SYMBOL` when the component is destroyed
 */
export function useOnDestroy(): Promise<typeof ON_DESTROY_SYMBOL> {
  const [promise] = useState(new OpenPromise<typeof ON_DESTROY_SYMBOL>());

  useEffect(
    () => () => {
      promise.resolve(ON_DESTROY_SYMBOL);
    },
    [promise]
  );

  return promise;
}
