import { OpenPromise } from '@thalesrc/js-utils';
import { useContext, useEffect, useState } from 'react';

import { ResourceContext, ResourceContextType } from '../context/resource.context';

export default function useResourcePromise<T extends keyof ResourceContextType>(enumName: T): Promise<ResourceContextType[T]> {
  const [promise] = useState(new OpenPromise<ResourceContextType[T]>());
  const enums = useContext(ResourceContext);

  useEffect(() => {
    if (!!enums[enumName].length) {
      promise.resolve(enums[enumName]);
    }
  }, [enums, promise, enumName]);

  return promise;
}
