import { createContext } from 'react';

import { PpeDefinitionsContentResult } from 'api/hs/ppe/ppe-definitions';

export interface PpeDefinitionsContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: PpeDefinitionsContentResult;

  setSelectedData(data: PpeDefinitionsContentResult): void;
}

export const PpeDefinitionsContext = createContext<PpeDefinitionsContextType>(null);
