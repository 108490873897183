import { makeStyles, Paper, Table as MUITable, TableContainer } from '@material-ui/core';
import React from 'react';

import Body from './Body';
import Footer from './Footer';
import Head from './Head';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '100%',
  },
}));

export default function Table() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <MUITable size="small">
        <Head />
        <Body />
        <Footer />
      </MUITable>
    </TableContainer>
  );
}
