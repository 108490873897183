import React, { useState } from 'react';
import { useParams } from 'react-router';

import { getImportedFiles, getTrainingById } from '../../../api/hs/trainings/PlannedCompleted/planned-completed';
import { useFetch } from '../../../utils';
import { TrainingDetailContext } from './completed.context';
import TrainingDetail from './List';

export default function Index() {
  const [loading, setLoading] = useState(null);
  const { trainingIdCompleted } = useParams<{ trainingIdCompleted: string }>();

  const { data, fetch } = useFetch(
    async () => {
      const [trainingData, importedFilesData] = await Promise.all([
        await getTrainingById(trainingIdCompleted),
        await getImportedFiles(trainingIdCompleted),
      ]);
      return {
        ...trainingData,
        files: importedFilesData,
      };
    },
    null,
    { setLoading, deps: [trainingIdCompleted], reloadDeps: [trainingIdCompleted] }
  );

  return (
    <TrainingDetailContext.Provider
      value={{
        trainingDetail: data,
        reload: fetch,
        loading,
        setLoading,
      }}>
      <TrainingDetail />
    </TrainingDetailContext.Provider>
  );
}
