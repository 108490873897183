import { Grid, List, ListItem, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext, useEffect, useMemo } from 'react';

import { PrescriptionMedicineModel } from 'api/hs/examination';
import { MedicineCacheContext } from 'Common/Form/MedicineSelection/medicine.context';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { SelectOptionsContext } from 'context/select-options.context';

interface Props {
  medicines: PrescriptionMedicineModel[];

  removeMedicineFromList(id: string): void;
}

const useStyles = makeStyles(theme => ({
  listItem: {
    justifyContent: 'space-between',
  },
  icon: {
    justifyContent: 'flex-end',
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  col: {
    color: 'inherit',
  },
  header: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
}));

export default function PrescriptionMedicineList({ medicines, removeMedicineFromList }: Props) {
  const classes = useStyles();
  const { prescriptionMedicineUsageDefinitions, satietyInformationDefinitions } = useContext(SelectOptionsContext);
  const { labels, fetchMedicineDetails } = useContext(MedicineCacheContext);

  const medIds = useMemo(() => medicines.map((med: any) => med.medicineId), [medicines]);

  useEffect(() => {
    fetchMedicineDetails(...medIds);
  }, [medIds, fetchMedicineDetails]);

  return (
    <IndicatorWrapper loading={false} data={medicines} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.sideGrid}>
          <List>
            {medicines?.map((item, index) => {
              return (
                <ListItem key={`medicine-item-${index}`} disableGutters={true} className={classes.listItem}>
                  <Typography key={`prescription-${item.medicineId}-${index}`}>
                    <b>{labels[item.medicineId]}</b>
                    <br />
                    {`Adet ${item.medicinePackageCount}, Kullanım Şekli ${item.usageFrequency}x${item.doseAmount}
                   ${
                     prescriptionMedicineUsageDefinitions.find(def => def.value.toString() === item.usageTypeDefinitionId.toString())?.text
                   },
                   ${
                     satietyInformationDefinitions.find(def => def.value.toString() === item.satietyInformationDefinitionId.toString())
                       ?.text
                   }`}
                    <br />
                    {item.description && `Açıklama: ${item.description}`}
                  </Typography>
                  <ListItemIcon className={classes.icon}>
                    <DeleteIcon fontSize="medium" onClick={() => removeMedicineFromList(item.medicineId)} />
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </IndicatorWrapper>
  );
}
