import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { AlertContext } from 'context/alert.context';
import { ComplaintModel } from 'model/employee/examination/complaint.model';
import { useAsyncEffect } from 'utils';

import { saveComplaint, updateComplaint } from '../../../api/hs/examination/complaint';
import TextField from '../../Form/TextField';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: ComplaintModel;
}

export default function MedicalInterventionForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveComplaint({ ...values, employeeId, examinationId });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateComplaint({ id: data.id, ...values, employeeId, examinationId });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }

        reset();
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, reset, showMessage, employeeId, examinationId]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();

      for (const [key, value] of Object.entries(data)) {
        setValue(key, value === null ? '' : value);
      }

      trigger();
    }
  }, [data, setValue, trigger]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 500 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Yakınma Şikayet Düzenleme' : 'Yakınma Şikayet Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required type="text" rows={3} name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
