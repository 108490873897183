import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  DefaultNurseOperationDetailModel,
  saveDefaultNurseOperationDetail,
  updateDefaultNurseOperationDetail,
} from 'api/hs/nurse-operations/default-nurse-operation-detail';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import { EnumContext } from '../../../../../../../context/enum.context';
import { DefaultNurseOperationDetailContext } from '../../default-nurse-operation-detail.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: DefaultNurseOperationDetailModel;
}

export default function DefaultNurseOperationDetailForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { nurseOperation } = useContext(DefaultNurseOperationDetailContext);
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { nurseOperationDefinitions } = useContext(EnumContext);
  const { nurseOperationId } = useParams<{ nurseOperationId: string }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveDefaultNurseOperationDetail(nurseOperationId, { ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateDefaultNurseOperationDetail({
            id: data.id,
            description: values.description,
          });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage, nurseOperationId]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      setValue('description', data.description);
      trigger();
    } else {
      reset({
        description: '' as any,
      });
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>
        {data?.id
          ? findByKey(nurseOperationDefinitions, 'id', nurseOperation?.nurseOperationDefinitionId)?.label + ' Güncelle'
          : findByKey(nurseOperationDefinitions, 'id', nurseOperation?.nurseOperationDefinitionId)?.label + ' Ekle'}
      </DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} variant="outlined" required name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
