import { modelDataMatcher } from 'utils';

export class UserProfile {
  public id: string = null;
  public userId: string = null;
  public branchCode: number = null;
  public nid: string = null;
  public medulaUserName: string = null;
  public medulaPassword: string = null;
  public certificateTypeDefinitionId: number = null;
  public plantCode: string = null;
  public esignPinCode: string = null;

  public graduationDate: string = null;
  public diplomaRegistrationDate: string = null;
  public doctorCertificate: string = null;

  constructor(data: Partial<UserProfile> = {}) {
    modelDataMatcher(data, this);
  }
}
