import { Grid } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useRef, useState } from 'react';

import Form, { FormRef } from 'Common/Form/Form';

import {
  AccidentReviewControllerService,
  AccidentReviewDefinitionControllerService,
  ApparentReasonResponse,
  CancelablePromise,
} from '../../../api/client';
import TextField from '../../../Common/Form/TextField';
import { AlertContext } from '../../../context/alert.context';
import { useAsyncEffect, useFetch, useTriggerRender } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import NewRecordButton from '../NewRecordButton';
import Options from './Options';

export default () => {
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const { token, insertable, accidentId } = useContext(AccidentReviewContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [loading, setLoading] = useState<boolean>(false);

  const { data: behaviourTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getBehaviourTypes(), []);
  const { data: factTypes } = useFetch(() => AccidentReviewDefinitionControllerService.getFactTypes(), []);
  const { data: apparentReason, fetch: fetchApparentReasons } = useFetch(
    () => AccidentReviewControllerService.getApparentReasons(accidentId, null, token),
    { behaviors: [], facts: [] },
    { setLoading }
  );

  useAsyncEffect(async () => {
    if (apparentReason?.behaviors?.length > 0) {
      apparentReason.behaviors.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`behaviour[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`behaviour_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`behaviour_other`, item.other), 250);
        }
      });
      reRender();
    }
    if (apparentReason?.facts?.length > 0) {
      apparentReason.facts.forEach(item => {
        if (item.id) {
          formRef.current?.methods?.setValue(`fact[${item.id}]`, true);
        } else if (item.other) {
          formRef.current?.methods?.setValue(`fact_other_enabled`, true);
          setTimeout(() => formRef.current?.methods?.setValue(`fact_other`, item.other), 250);
        }
      });
      reRender();
    }
  }, [apparentReason]).then(noop);

  const submit = (values: any) => {
    setLoading(true);

    const behaviours = Object.keys(values.behaviour || {})
      .filter(id => values.behaviour[id])
      .map(id => {
        return { id };
      });

    if (values?.behaviour_other?.length > 0) {
      // @ts-ignore
      behaviours.push({ other: values.behaviour_other });
    }

    const facts = Object.keys(values.fact || {})
      .filter(id => values.fact[id])
      .map(id => {
        return { id };
      });

    if (values.fact_other?.length > 0) {
      // @ts-ignore
      facts.push({ other: values.fact_other });
    }

    const request = {
      note: values.apparentReason,
      behaviours,
      facts,
    };

    AccidentReviewControllerService.saveApparentReasons(accidentId, null, request, token)
      .then(() => {
        showMessage('Kayıt işlemi başarılı.', 'success', 'info');
        fetchApparentReasons().then(noop);
      })
      .catch(() => {
        showMessage('Bir hata oluştu.', 'error', 'info');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form onChange={reRender} onSubmit={submit} ref={formRef}>
      <Grid item xs={12}>
        <TextField name="apparentReason" rows={5} label="Görünür Neden" defaultValue={apparentReason?.note} />
      </Grid>
      {apparentReason?.note?.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Options title={'Davranış'} name="behaviour" formRef={formRef} items={behaviourTypes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Options title={'Durum'} name="fact" formRef={formRef} items={factTypes} />
          </Grid>
        </Grid>
      )}
      {insertable && <NewRecordButton onClick={noop} label="Kaydet" type="submit" loading={loading} />}
    </Form>
  );
};
