import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { FirmUsersPayload } from './firm-users.payload';
import { FirmUsersResult } from './firm-users.result';

export async function getFirmUsers(firmId: string): Promise<FirmUsersResult[]> {
  let res;
  try {
    res = await HSApi.get<FirmUsersResult[]>(`/firms/${firmId}/users`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveFirmUsers(firmId: string, body: FirmUsersPayload): Promise<FirmUsersResult> {
  const [err, res] = await tryCatch(HSApi.post(`/firms/${firmId}/users`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateFirmUsers(
  firmId: string,
  { id, ...body }: MakeRequired<Partial<FirmUsersResult>, 'id'>
): Promise<FirmUsersResult> {
  const [error, res] = await tryCatch(HSApi.patch<FirmUsersResult>(`/firms/${firmId}/users/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteFirmUsers(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/users/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
