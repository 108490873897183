/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    email?: string;
    name?: string;
    firmId?: string;
    lastLoginDate?: string;
    lastLogoutDate?: string;
    roles?: Array<'PHYSICIAN' | 'NURSE' | 'IT' | 'OSS' | 'HR' | 'FIRM_ADMIN' | 'SYS_ADMIN'>;
    status?: UserDto.status;
}

export namespace UserDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
