import { Card, CardHeader, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import PieChart, { ChartProps } from '../utils/PieChart';

type Props = {
  title: string;
  chartProps: ChartProps;
};

const AccidentPieChartGridContainer = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(true);

  function setStateVisible(val: boolean) {
    setVisible(val);
  }
  return (
    visible && (
      <>
        <Grid item xs={12} lg={6}>
          <Card style={{ padding: '10px', margin: '10px' }}>
            <CardHeader title={props.title} /> <PieChart {...props.chartProps} visible={setStateVisible} />
          </Card>
        </Grid>
      </>
    )
  );
};

export default AccidentPieChartGridContainer;
