import { Button, Dialog, DialogContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import { format } from 'date-fns';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PrescriptionModel, saveEPrescription, updatePrescriptionAfterCreatedEPrescription } from 'api/hs/examination';
import { getIdc10sByIds } from 'api/hs/icd10';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { EnvironmentContext } from 'context/environment.context';
import { UserContext } from 'context/user';
import { dateDisplayFormat, useAsyncEffect, useFetch } from 'utils';

import { EmployeeContext } from '../../../employee.context';
import { ExaminationDetailContext } from '../../../ExaminationPage/Detail/examination-detail.context';
import { PrescriptionContext } from '../../prescription.context';

const useStyles = makeStyles(theme => ({
  gridContainerRoot: {
    marginBottom: '50px',
    border: '1px solid #e0e0e0',
    borderBottom: 'none',
  },
  gridTitleRoot: {
    padding: '15px 10px',
  },
  gridRoot: {
    borderBottom: '1px solid #e0e0e0',
    padding: '15px 10px',
  },
  bolder: {
    fontWeight: 'bold',
  },
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: PrescriptionModel;
}

export default function ConvertToEPrescriptionForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, getValues, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employee } = useContext(EmployeeContext);
  const { selectedPrescription, reload } = useContext(PrescriptionContext);
  const { user, profile } = useContext(UserContext);
  const { name: env } = useContext(EnvironmentContext);
  const { diagnosis, protocolNumber } = useContext(ExaminationDetailContext);

  const icd10sToFetch = useMemo(() => diagnosis.find(diag => !diag.occupationalDiseasePreDiagnosis)?.icd10s, [diagnosis]);
  const { data: icd10s } = useFetch(() => getIdc10sByIds(...(icd10sToFetch || [])), [], {
    deps: [icd10sToFetch],
    reloadDeps: [icd10sToFetch],
  });

  const handleSubmit = useCallback(
    async values => {
      try {
        const payload = {
          action: 'erecete_istek',
          username: profile.medulaUserName,
          password: profile.medulaPassword,
          tcKimlikNo: profile.nid,
          drname: user.name,
          tesisKodu: profile.plantCode,
          sertifikaKodu: profile.certificateTypeDefinitionId,
          bransKodu: profile.branchCode,
          env,
          remoteWebMethod: 'imzaliEreceteGiris',
          content: `<?xml version="1.0" encoding="UTF-8" standalone="yes"?><ereceteBilgisi><doktorBransKodu>${
            profile.branchCode
          }</doktorBransKodu><doktorTcKimlikNo>${profile.nid}</doktorTcKimlikNo><doktorSertifikaKodu>${
            profile.certificateTypeDefinitionId
          }</doktorSertifikaKodu>${selectedPrescription.prescriptionMedicines
            ?.map(
              item =>
                `<ereceteIlacBilgisi><adet>${item.medicinePackageCount}</adet><barkod>${
                  item.barcode
                }</barkod><ereceteIlacAciklamaBilgisi><aciklama>${item.description || 0}</aciklama><aciklamaTuru>${
                  item.prescriptionDescriptionTypeDefinitionId
                }</aciklamaTuru></ereceteIlacAciklamaBilgisi><kullanimDoz1>${item.usageFrequency}</kullanimDoz1><kullanimDoz2>${
                  item.doseAmount
                }</kullanimDoz2><kullanimPeriyot>${item.usagePeriod}</kullanimPeriyot><kullanimPeriyotBirimi>${
                  item.usagePeriodUnitId
                }</kullanimPeriyotBirimi><kullanimSekli>${item.usageTypeDefinitionId}</kullanimSekli></ereceteIlacBilgisi>`
            )
            .join('')}<ereceteNo>0</ereceteNo>${icd10s
            .map(icd10 => `<ereceteTaniBilgisi><taniKodu>${icd10.name}</taniKodu></ereceteTaniBilgisi>`)
            .join('')}<ereceteAciklamaBilgisi><aciklama>${selectedPrescription.description || 0}</aciklama><aciklamaTuru>${
            selectedPrescription.prescriptionDescriptionTypeDefinitionId || 1
          }</aciklamaTuru></ereceteAciklamaBilgisi><protokolNo>${
            protocolNumber ? protocolNumber?.yearPrefix + '/' + String(protocolNumber?.number).padStart(4, '0') : '0'
          }</protokolNo><provizyonTipi>${selectedPrescription.prescriptionProvisionTypeDefinitionId}</provizyonTipi><receteAltTuru>${
            selectedPrescription.prescriptionSubtypeDefinitionId
          }</receteAltTuru><receteTarihi>${format(new Date(selectedPrescription.requestDate), 'dd.MM.yyyy')}</receteTarihi><receteTuru>${
            selectedPrescription.prescriptionTypeDefinitionId
          }</receteTuru><seriNo>1</seriNo><takipNo>0</takipNo><tcKimlikNo>${employee.nid}</tcKimlikNo><tesisKodu>${
            profile.plantCode
          }</tesisKodu></ereceteBilgisi>`,
          ...values,
        };

        const eprescriptionResponse = await saveEPrescription(payload);

        if (eprescriptionResponse.sonucKodu !== '0000') {
          showMessage(`${eprescriptionResponse.sonucKodu} - ${eprescriptionResponse.sonucMesaji}`, 'error', 'info');
        } else {
          const updatedPayload = {
            id: selectedPrescription?.id,
            response: JSON.stringify(eprescriptionResponse),
            request: JSON.stringify(payload),
            protocolNo: eprescriptionResponse?.erecete.protokolNo,
            eprescriptionNo: eprescriptionResponse?.erecete.ereceteNo,
          };

          await updatePrescriptionAfterCreatedEPrescription(updatedPayload);
          reload();
          showMessage('e-Reçete başarıyla oluşturuldu.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        console.log('ERROR in handleSubmit', err); // tslint:disable-line:no-console
      }
    },
    [onClose, showMessage, reload, employee, selectedPrescription, profile, env, icd10s, protocolNumber]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }

    await defer();

    setValue('pinCode', profile?.esignPinCode ?? '');
    trigger();
  }, [open, profile, setValue, trigger]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 600 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{
        paper: classes.dialogPaper,
        scrollPaper: classes.dialogScroll,
      }}>
      <DialogContent>
        <Grid item xs={12} className={classes.gridTitleRoot}>
          <Typography align="center" className={classes.bolder} component="h3">
            İş Yeri Sağlık Birimi Reçete
          </Typography>
        </Grid>
        <Grid className={classes.gridContainerRoot} container alignContent="center" justifyContent="center">
          <Grid item xs={6} className={classes.gridRoot}>
            <Typography className={classes.bolder}>Hastanın T.C. Kimlik No.</Typography>
            <Typography>{employee.nid}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.gridRoot}>
            <Typography>
              <b>Tarih:</b> {dateDisplayFormat(selectedPrescription?.requestDate)}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.gridRoot}>
            <Typography className={classes.bolder}>Hastanın Adı Soyadı</Typography>
            <Typography>{employee.name}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.gridRoot}>
            <Typography className={classes.bolder}>Dr. Adı Soyadı / Dipl. Tes. Tar. ve No:</Typography>
            <Typography>{user.name}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridRoot}>
            <Typography align="center" className={classes.bolder}>
              Tanılar
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridRoot}>
            {icd10s?.map((icd10, index) => (
              <Typography key={`${index}-${icd10.id}`} align="center">
                {icd10.label}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} className={classes.gridRoot}>
            <Typography align="center" className={classes.bolder}>
              İLAÇLAR
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridRoot}>
            {selectedPrescription?.prescriptionMedicines?.map(item => {
              return (
                <div key={`${item.id}-${item.label}`}>
                  <Typography align="center">{item.label}</Typography>
                  <Typography align="center">
                    Adet: {item.medicinePackageCount} Kullanım Şekli: {item.usageFrequency}x{item.doseAmount}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        </Grid>
        <FormProvider {...{ ...formMethods, reset, setValue, getValues, trigger }}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <TextField type="password" label="Pin Kodu" name="pinCode" required />
              </Grid>
            </Grid>

            <Grid container className="text-right">
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  e-Reçete'ye Dönüştür
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
