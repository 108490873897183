import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

interface Props {
  eccidentEmployeeCount: any[];
}

export default ({ eccidentEmployeeCount }: Props) => {
  const history = useHistory();

  if (!eccidentEmployeeCount || eccidentEmployeeCount.length === 0) return <b>Kayıt bulunamadı!</b>;

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Adı Soyadı</TableCell>
            <TableCell className="text-bold">T.C Numarası</TableCell>
            <TableCell className="text-bold">Ağırlık Derecesi</TableCell>
            <TableCell className="text-bold">Kayıp İş Günü</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eccidentEmployeeCount.map((employee, index) => (
            <TableRow key={index} hover onClick={() => handleClick(employee.employeeId)}>
              <TableCell scope="row">{employee.name}</TableCell>
              <TableCell>{employee.nid}</TableCell>
              <TableCell>{employee.severityDefinition}</TableCell>
              <TableCell>{employee.lostWorkDays}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
