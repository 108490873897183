import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { HazardCategoryDefinitionControllerService } from 'api/client';
import { HazardCategoryDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import HazardCategoryDefinitionForm from './Forms/HazardCategoryDefinitionForm';

export default function HazardCategoryDefinition() {
  return (
    <BaseDefinitionPage<HazardCategoryDefinitionDto>
      listLabelPropName="label"
      fetchData={HazardCategoryDefinitionControllerService.getAllFirmHazardCategoryDefinitions}
      FormDialog={HazardCategoryDefinitionForm}
      onDelete={HazardCategoryDefinitionControllerService.deleteFirmHazardCategoryDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
