import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import TextField from 'Common/Form/TextField';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { EmployeeContext } from 'TransactionsAndLists/EmployeeList/Employee/employee.context';
import { useAsyncEffect, useDialogState, useUniqueId, useXhr } from 'utils';

export default function OccupationalDisease() {
  const formId = useUniqueId('occupational-disease-form');
  const { employee, update } = useContext(EmployeeContext);
  const { open, close, opened } = useDialogState();
  const { setValue, trigger, reset, ...methods } = useForm();

  useAsyncEffect(async () => {
    if (!opened) {
      return;
    }

    await defer();

    if (employee?.occupationalDiseaseDescription) {
      setValue('description', employee.occupationalDiseaseDescription);
      trigger();
    } else {
      reset();
    }
  }, [setValue, trigger, reset, employee, opened]);

  const save = useXhr(
    async ({ description }: { description: string }) => {
      await update({ id: employee.id, occupationalDiseaseDescription: description });
      close();
    },
    'Meslek Hastalığı Kaydedildi',
    'Kaydetme esnasında bir hata oluştu',
    [update, employee, close]
  );

  return (
    <>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle>Meslek Hastalığı</DialogTitle>
        <DialogContent>
          <FormProvider {...{ ...methods, setValue, trigger, reset }}>
            <form id={formId} onSubmit={methods.handleSubmit(save)}>
              <TextField name="description" label="Açıklama" />
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions className="text-right pb-3 pr-2">
          <Button className="mr-2" onClick={close}>
            Vazgeç
          </Button>
          <Button type="submit" form={formId} color="primary" variant="contained">
            Kaydet
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item sm={8}>
          <IndicatorWrapper
            loading={false}
            data={employee?.occupationalDiseaseDescription}
            errorMessage="Meslek hastalığı şüphesi ile ilgili tetkik veya muayeneye tabi tutulmamıştır.">
            {employee?.occupationalDiseaseDescription}
          </IndicatorWrapper>
        </Grid>
        <Grid item sm={4}>
          <div className="text-right pb-2">
            <Button variant="contained" color="primary" onClick={open}>
              Meslek Hastalığı {employee?.occupationalDiseaseDescription ? 'Düzenle' : 'Ekle'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
