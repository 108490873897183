import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useContext, useRef } from 'react';

import Button from 'Common/Button';
import Form, { FormRef } from 'Common/Form/Form';
import { dateToInputFormat, useTranslate, useTriggerRender } from 'utils';

import { save } from '../../../api/hs/dashboard/event/event';
import { EventPayload } from '../../../api/hs/dashboard/event/event.payload';
import { AlertContext } from '../../../context/alert.context';
import Header from '../../Header';
import { CalendarContext } from '../calendar.context';
import { eventTypes } from '../event-types';
import EventForm from './EventForm';

export default function NewEventDialog() {
  const {
    newEventDialog: { close, opened },
    startDate,
    endDate,
    reload,
  } = useContext(CalendarContext);
  const { showMessage } = useContext(AlertContext);

  const translate = useTranslate('dashboard');
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();

  const handleSubmit = async (value: any) => {
    try {
      const request: EventPayload = {
        title: value.title,
        description: value.description,
        startDate: dateToInputFormat(new Date(value.startDate)),
        endDate: dateToInputFormat(new Date(value.endDate)),
        startTime: value.wholeDay ? '00:00:00' : value.startTime,
        endTime: value.wholeDay ? '23:59:59' : value.endTime,
        isPublic: value.public,
        color: value.color || eventTypes.USER_DEFINED.color,
      };

      await save(request);
      reload();
      showMessage('Etkinlik oluşturuldu.', 'success', 'info');
      close();
    } catch (e) {
      showMessage('Etkinlik oluşturulamadı!', 'error', 'info');
    }
  };

  return (
    <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
      <Header title={translate('Calendar.CreateNewEvent')} icon={<Add />} />
      <DialogContent>
        <Form ref={formRef} onChange={reRender} onSubmit={handleSubmit}>
          <EventForm wholeDay={formRef.current?.value?.wholeDay || false} event={{ startDate, endDate }} />
        </Form>
      </DialogContent>
      <DialogActions className="pb-2 pr-2">
        <Button onClick={close}>İptal</Button>
        <Button type="submit" form={formRef.current?.id} color="primary" variant="contained" disabled={!formRef.current?.valid}>
          Ekle
        </Button>
      </DialogActions>
    </Dialog>
  );
}
