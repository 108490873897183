/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProtocolNumber } from './ProtocolNumber';

export type ProtocolRegistryDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    employeeId?: string;
    referenceTableId?: string;
    protocolNumber?: ProtocolNumber;
    protocolOperationType?: ProtocolRegistryDto.protocolOperationType;
    firmId?: string;
    protocolSourceType?: ProtocolRegistryDto.protocolSourceType;
}

export namespace ProtocolRegistryDto {

    export enum protocolOperationType {
        EXAMINATION = 'EXAMINATION',
        REFERRAL = 'REFERRAL',
        REPORT = 'REPORT',
        ANALYSIS = 'ANALYSIS',
        DEFAULT_NURSE_OPERATION = 'DEFAULT_NURSE_OPERATION',
        VITAL_SIGN = 'VITAL_SIGN',
        BODY_MEASUREMENT = 'BODY_MEASUREMENT',
    }

    export enum protocolSourceType {
        PHYSICIAN = 'PHYSICIAN',
        NURSE = 'NURSE',
    }


}
