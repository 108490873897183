import { Button, Grid } from '@material-ui/core';
import { defer, tryCatch } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { BuildingFloorDefinitionControllerService } from 'api/client';
import { WorkEquipmentLayoutView } from 'api/client';
import { BuildingPartDefinitionControllerService } from 'api/client';
import { WorkEquipmentLayoutControllerService } from 'api/client';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useFetch } from 'utils';

import Dialog from '../../../../Common/Dialog';
import { AlertContext } from '../../../../context/alert.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentLayoutView;
}

export default function WorkEquipmentLayoutEditForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { buildings, workEquipmentDefinition } = useContext(SelectOptionsContext);
  const [selectedBuildingDefinitionId, setSelectedBuildingDefinitionId] = useState<string>(null);
  const [selectedFloorDefinitionId, setSelectedFloorDefinitionId] = useState<string>(null);
  const { showMessage } = useContext(AlertContext);
  const { data: floorList } = useFetch<SelectOption<string>[]>(
    async () => {
      if (!selectedBuildingDefinitionId) return [];

      const [err, res] = await tryCatch(
        BuildingFloorDefinitionControllerService.getAllBuildingFloorDefinitions(selectedBuildingDefinitionId)
      );
      if (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
        return [];
      }
      return res?.map(floor => ({
        text: floor?.name,
        value: floor?.id,
      }));
    },
    [],
    { deps: [selectedBuildingDefinitionId], reloadDeps: [selectedBuildingDefinitionId] }
  );

  const { data: partsList } = useFetch<SelectOption<string>[]>(
    async () => {
      if (!selectedFloorDefinitionId) return [];

      const [err, res] = await tryCatch(BuildingPartDefinitionControllerService.getAllBuildingPartDefinitions(selectedFloorDefinitionId));
      if (err) {
        // tslint:disable-next-line:no-console
        console.warn(err);
        return [];
      }
      return res?.map(part => ({
        text: part?.name,
        value: part?.id,
      }));
    },
    [],
    { deps: [selectedFloorDefinitionId], reloadDeps: [selectedFloorDefinitionId] }
  );

  const onChangeForm = useCallback(
    formValues => {
      setSelectedBuildingDefinitionId(formValues?.building);
      if (formValues?.floors) {
        setSelectedFloorDefinitionId(formValues?.floors);
      }
    },
    [setSelectedBuildingDefinitionId]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    formRef?.current?.methods?.setValue('workEquipmentDefinitionId', data?.definitionId);
    formRef?.current?.methods?.setValue('building', data?.buildingId);
    formRef?.current?.methods?.setValue('floors', data?.floorId);
    formRef?.current?.methods?.setValue('layoutPartId', data?.layoutPartId);
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  const onSubmit = useCallback(
    async formValue => {
      try {
        const payload = {
          workEquipmentDefinitionId: data?.definitionId,
          layoutPartId: formValue?.layoutPartId,
          layoutDescription: formValue?.layoutDescription,
          qrcodeStr: formValue?.qrcodeStr,
          serialNumber: formValue?.serialNumber,
          trackingNumber: formValue?.trackingNumber,
        };
        await WorkEquipmentLayoutControllerService.updateWorkEquipmentLayout(data?.layoutId, payload);
        showMessage('Güncelleme işlemi başarılı', 'success', 'info');
        onClose(true);
      } catch (e) {
        showMessage('Güncelleme işlemi başarısız', 'error', 'info');
        onClose(false);
      }
    },
    [data, showMessage, onClose]
  );

  return (
    <Dialog color="secondary" opened={open} onClose={() => onClose(false)} title="İş Ekipman Düzenle">
      <Form onSubmit={onSubmit} onChange={onChangeForm} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <AutoComplete single name="building" options={buildings} label="Binalar" />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <AutoComplete disabled={!floorList.length} single name="floors" options={floorList} label="Katlar" />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <AutoComplete disabled={!partsList.length} single name="layoutPartId" options={partsList} label="Bölümler" />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <AutoComplete single name="workEquipmentDefinitionId" options={workEquipmentDefinition} label="İş Ekipman Tanımı" />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <TextField type="number" name="trackingNumber" label="Takip Numarası" />
          </Grid>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <TextField name="serialNumber" label="Seri Numarası" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField name="qrcodeStr" label="QR Kodu" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField rows={3} name="layoutDescription" label="Yer Bilgisi" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <span style={{ display: 'block', color: 'red' }}>
              * Verileri girerken bina-kat-bölüm bilgilerini ya da yer bilgisini giriniz.Bu alanların ikisini de boş bırakmayınz.
            </span>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <span style={{ display: 'block', color: 'red' }}>
              * Bina-Kat-Bölüm Bilgilerini , Tanımlar - İşyeri Tanımları - Bina-Kat-Bölüm tabından girebilirsiniz.
            </span>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              Düzenle
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
