/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CompanyDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    address?: string;
    email?: string;
    fax?: string;
    firmId?: string;
    logoPath?: string;
    ssiId?: string;
    companyName?: string;
    companyCode?: string;
    companyTitle?: string;
    statusId?: CompanyDto.statusId;
    hazardClass?: number;
    phone?: string;
    taxOffice?: string;
    authorized?: string;
}

export namespace CompanyDto {

    export enum statusId {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
