import React, { useState } from 'react';

import { useAsyncEffect, useFetch } from 'utils';

import { RiskAssessmentHazardControllerService } from '../../api/client';
import { RiskAssessmentHazardDto } from '../../api/client';
import RiskAssessmentHazardListAdd from './ListAdd';
import { RiskAssessmentHazardContext } from './risk-assessment-hazard.context';

interface Props {
  riskAssessmentId: string;
  riskAssessmentType: string;
}
export default function Index({ riskAssessmentId, riskAssessmentType }: Props) {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<RiskAssessmentHazardDto>(null);
  const [riskAssessmentHazard, setRiskAssessmentHazard] = useState([]);

  async function fetch() {
    const res = await RiskAssessmentHazardControllerService.getAllRiskAssessmentHazard(riskAssessmentId);
    setRiskAssessmentHazard(res);
    setLoading(false);
  }

  useAsyncEffect(async () => {
    if (riskAssessmentId) {
      await fetch();
    }
  }, [riskAssessmentId]);

  return (
    <RiskAssessmentHazardContext.Provider
      value={{
        riskAssessmentHazard,
        reload: fetch,
        loading,
        riskAssessmentType,
        riskAssessmentId,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <RiskAssessmentHazardListAdd />
    </RiskAssessmentHazardContext.Provider>
  );
}
