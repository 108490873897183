import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';

export async function logout(): Promise<void> {
  const [error] = await tryCatch(HSApi.post<void>(`/account/logout`, {}));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }
}
