import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { WorkEquipmentJobStatusControllerService } from 'api/client';
import { WorkEquipmentJobStatusView } from 'api/client';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect, useTriggerRender } from 'utils';

import Switch from '../../../../Common/Form/Switch';
import TextField from '../../../../Common/Form/TextField';
import { AlertContext } from '../../../../context/alert.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: WorkEquipmentJobStatusView;
}

export default function WorkEquipmentJobStatusEditForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { yesNoBoolean } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      try {
        await WorkEquipmentJobStatusControllerService.updateWorkEquipmentStatus(data?.jobStatusId, {
          ...values,
        });
        showMessage('Başarılı şekilde güncellendi.', 'success', 'info');
        onClose(true);
      } catch (e) {
        showMessage('Güncelleme sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={() => onClose(false)} title={'İş Ekipman Kontrol Düzenle'}>
        <Form onChange={reRender} onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <AutoComplete single name="completed" options={yesNoBoolean} label="Kontrol Edildi mi ?" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <DateInput label="Kontrol Tarihi" name="executionDate" type="date-time" />
            </Grid>
            <Grid item className="mt-1" md={12} lg={12} sm={12}>
              <Switch
                name="suitabilityUse"
                label={formRef?.current?.value?.suitabilityUse === true ? 'Kullanıma Uygun' : 'Kullanıma Uygun Değil'}
              />
            </Grid>
            <Grid item className="mt-1" md={12} lg={12} sm={12}>
              <TextField rows={3} name="description" label="Açıklama" />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {'Güncelle'}
              </Button>
              <Button variant="contained" type="button" onClick={() => onClose(false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
