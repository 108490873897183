import { Button, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { AnalysisResult, deleteAnalysis } from 'api/hs/definitions/health-definitions/analysis';
import AnalysisEditDialog from 'Common/Forms/Definitions/HealthDefinition/Analysis/AnalysisEditDialog';

import { AnalysisCategoryDefinitionControllerService } from '../../../../api/client';
import BaseDefinitionPage from '../../../BaseDefinitionPage';
import { ListItemTemplateProps } from '../../../BaseDefinitionPage/page.context';

const useStyles = makeStyles(theme => ({
  positionName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,Droid Sans, Helvetica Neue, sans-serif',
    color: 'steelblue',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  textColor: {
    color: 'lightslategray',
  },
}));

function ItemTemplate({ item, Action }: ListItemTemplateProps<AnalysisResult>) {
  const classes = useStyles();
  const history = useHistory();

  const routeDetailAnalysisParameters = useCallback(() => {
    history.push(`/definitions/health/tatlil-tetkik/${item?.id}`);
  }, [item, history]);
  return (
    <>
      <ListItem>
        <ListItemAvatar className={classes.textColor}>
          <DomainIcon />
        </ListItemAvatar>
        <ListItemText color="primary">
          <div>
            <span className={classes.positionName}>{item.label}</span>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Button variant="outlined" type="submit" color="primary" className="mr-2" onClick={routeDetailAnalysisParameters}>
            Parametreler
          </Button>
          <Action data={item} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}
export default function Analysis() {
  return (
    <BaseDefinitionPage<AnalysisResult>
      listLabelPropName="label"
      fetchData={AnalysisCategoryDefinitionControllerService.getAllFirmAnalysisDefinitions}
      FormDialog={AnalysisEditDialog}
      onDelete={deleteAnalysis}
      ListItemTemplate={ItemTemplate}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
