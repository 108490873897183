import { createContext } from 'react';

import { GroupDefinitionsModel, GroupDefinitionsResult } from 'api/hs/definitions/trainings/group-definitions';

export interface TrainingGroupDefinitionsContextType {
  groupTrainingGroupDefinitions: GroupDefinitionsResult[];

  loading: boolean;

  reload(): void;

  setLoading(loading: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: GroupDefinitionsModel;

  setSelectedData(data: GroupDefinitionsModel): void;
}

export const TrainingGroupDefinitionsContext = createContext<TrainingGroupDefinitionsContextType>(null);
