import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getPrescriptions, PrescriptionModel } from 'api/hs/examination/prescription';
import { useAsyncEffect } from 'utils';

import Prescription from './prescription';
import { PrescriptionContext } from './prescription.context';

export default function Index() {
  const [prescriptions, setPrescriptions] = useState<PrescriptionModel[]>(null);
  const [loading, setLoading] = useState(null);
  const [previewData, setPreviewData] = useState('');
  const [newSecondaryDialogOpened, setNewSecondaryDialogOpened] = useState(false);
  const [newEmailDialog, setEmailDialogOpened] = useState(false);
  const [newPreviewDialog, setPreviewDialogOpened] = useState(false);
  const [convertEPrescriptionDialogOpened, setConvertEPrescriptionEditDialogOpened] = useState(false);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState<PrescriptionModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getPrescriptions(employeeId, examinationId);
    setPrescriptions(result);
    setLoading(false);
  }, [employeeId, examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <PrescriptionContext.Provider
      value={{
        prescriptions,
        setPrescriptions,
        reload: fetch,
        previewData,
        setPreviewData,
        loading,
        newPreviewDialog,
        setPreviewDialogOpened,
        newEmailDialog,
        setEmailDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        setNewSecondaryDialogOpened,
        setConvertEPrescriptionEditDialogOpened,
        convertEPrescriptionDialogOpened,
        newSecondaryDialogOpened,
        selectedPrescription,
        setSelectedPrescription,
      }}>
      <Prescription />
    </PrescriptionContext.Provider>
  );
}
