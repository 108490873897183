import React, { useState } from 'react';

import { ActionsContentResult } from 'api/hs/actions';

import { ActionContext } from './action.context';
import Actionss from './Actionss';

export default function ActionIndex({ fetcher, referenceId, nearMissId, actionReferenceValue, baseFetcher }) {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<ActionsContentResult>(null);

  return (
    <ActionContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Actionss
        baseFetcher={baseFetcher}
        fetcher={fetcher}
        referenceId={referenceId}
        nearMissId={nearMissId}
        actionReferenceValue={actionReferenceValue}
      />
    </ActionContext.Provider>
  );
}
