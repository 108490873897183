import React from 'react';
import { Route, Switch } from 'react-router';

import AccidentDefinitions from './AccidentDefinitions';
import AdminAccidentDefinitions from './AdminAccidentDefinitions';
import AdminEmergencyCaseDefinitions from './AdminEmergencyCaseDefinitions';
import AdminHealthDefinitions from './AdminHealthDefinitions';
import AdminNearMissDefinitions from './AdminNearMissDefinitions';
import AdminPpeDefinitions from './AdminPpeDefinitions';
import AdminRiskFineKinney from './AdminRiskFineKinney';
import AdminWorkPlaceDescription from './AdminWorkPlaceDescription';
import EmergencyCaseDefinitions from './EmergencyCaseDefinitions';
import FirmDefinition from './FirmDefinitions';
import HealthDefinition from './HealthDefinitions';
import NearMissDefinitions from './NearMissDefinitions';
import PpeDefinitions from './PpeDefinitions';
import RiskFineKinney from './RiskFineKinney';
import SystemSetting from './SystemSetting';
import Training from './TrainingDefinitions';
import WorkEquipmentDefinition from './WorkEquipmentDefinition';
import WorkPlaceDefinition from './WorkPlaceDescription';
export default function Definitions() {
  return (
    <Switch>
      <Route path="/definitions/workplace">
        <WorkPlaceDefinition />
      </Route>
      <Route path="/definitions/health">
        <HealthDefinition />
      </Route>
      <Route path="/definitions/system-setting">
        <SystemSetting />
      </Route>
      <Route path="/definitions/firm-definitions">
        <FirmDefinition />
      </Route>
      <Route path="/definitions/admin-health-definitions">
        <AdminHealthDefinitions />
      </Route>
      <Route path="/definitions/admin-work-definitions">
        <AdminWorkPlaceDescription />
      </Route>
      <Route path="/definitions/admin-ppe-definitions">
        <AdminPpeDefinitions />
      </Route>
      <Route path="/definitions/training">
        <Training />
      </Route>
      <Route path="/definitions/kkd-definitions">
        <PpeDefinitions />
      </Route>
      <Route path="/definitions/accident-definitions">
        <AccidentDefinitions />
      </Route>
      <Route path="/definitions/near-miss-definitions">
        <NearMissDefinitions />
      </Route>

      <Route path="/definitions/admin-near-miss-definitions">
        <AdminNearMissDefinitions />
      </Route>

      <Route path="/definitions/admin-accident-definitions">
        <AdminAccidentDefinitions />
      </Route>
      <Route path="/definitions/work-equipment-definitions">
        <WorkEquipmentDefinition />
      </Route>
      <Route path="/definitions/admin-risk-fine-kinney-definitions">
        <AdminRiskFineKinney />
      </Route>
      <Route path="/definitions/risk-fine-kinney-definitions">
        <RiskFineKinney />
      </Route>
      <Route path="/definitions/emergency">
        <EmergencyCaseDefinitions />
      </Route>
      <Route path="/definitions/admin-emergency">
        <AdminEmergencyCaseDefinitions />
      </Route>
    </Switch>
  );
}
