import React from 'react';

import Listt from './List';

export default function Index() {
  return (
    <>
      <Listt />
    </>
  );
}
