import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { deleteCommitteeMeetingResult, getCommitteeMeetingResult } from '../../../../api/hs/committee/committee-meetings-detail';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { DialogContext } from '../../../../context/dialog.context';
import { useFetch, useXhr } from '../../../../utils';
import { CommitteeMeetingsDetailContext } from '../../committee-meetings-detail.context';
import CommitteeMeetingResultAddButton from './CommitteeMeetingResultAddButton';
import CommitteeMeetingResultForm from './CommitteeMeetingResultForm';

export default function CommitteeMeetingResult() {
  const [loading, setLoading] = useState(true);
  const { committeeMeetingId } = useParams<{ committeeMeetingId: string }>();
  const { openWarnDialog } = useContext(DialogContext);
  const [selectedData, setSelectedData] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const { reload } = useContext(CommitteeMeetingsDetailContext);
  const { data, fetch } = useFetch(() => getCommitteeMeetingResult(committeeMeetingId), [], { setLoading });

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await deleteCommitteeMeetingResult(id);
      await fetch();
      await reload();
    },
    'Seçili Kayıt Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch, reload]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.result} kayıtı silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
        await fetch();
      }
      setEditOpen(false);
    },
    [reload, setEditOpen, fetch]
  );

  const onEdit = useCallback((item: any) => {
    setEditOpen(true);
    setSelectedData(item);
    setEditOpen(true);
  }, []);

  return (
    <>
      <CommitteeMeetingResultAddButton reloadList={fetch} />
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek Karar Bulunamadı">
        <List key="hs-defaultNurseOperationDetail">
          {data?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-grease-pencil" />
                </ListItemAvatar>
                <ListItemText primary={item?.result} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onEdit(item)} edge="end" aria-label="edit">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => removeFileShow(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </IndicatorWrapper>
      <CommitteeMeetingResultForm open={editOpen} onClose={closeDialog} data={selectedData} />
    </>
  );
}
