/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { UserRole } from 'model/user-role.enum';

interface LinkItem {
  title: string;
  icon: string;
  children?: LinkChildItem[];
}

interface LinkChildItem {
  link: string;
  title: string;
  icon: string;
  visibleTo?: UserRole[];
  external?: boolean;
}

export default <LinkItem[]>[
  {
    title: 'İŞ KAZASI',
    icon: 'icon-flash-outline',
    children: [
      {
        link: '#',
        title: 'Kaza İnceleme',
        icon: 'icon-flash-outline',
      },
    ],
  },
];
