import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { InjectionModel, saveInjection, updateInjection } from 'api/hs/nurse-operations/Injection';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { dateToInputFormat, useAsyncEffect } from 'utils';

import DateInput from '../../../../../../Common/Form/DateInput';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: InjectionModel;
}

export default function InjectionDetailForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { setValue, getValues, reset, trigger, ...methods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { nurseOperationId } = useParams<{ nurseOperationId: string }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveInjection(nurseOperationId, { ...values, nurseOperationId });
          showMessage('Ekleme işlemi başarılı.', 'success');
        } else {
          await updateInjection({
            nurseOperationId,
            id: data.id,
            ...values,
          });
          showMessage('Güncelleme işlemi başarılı.', 'success');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error');
        onClose(false);
      }
    },
    [data, onClose, showMessage, nurseOperationId]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      setValue('description', data.description);
      setValue('date', data.date);
      setValue('doctorName', data.doctorName);
      setValue('medicineId', data.medicineId);
      trigger();
    } else {
      reset({
        description: '' as any,
        date: '' as any,
        doctorName: '' as any,
        medicineId: '' as any,
      });
    }
  }, [data, setValue, reset, trigger, open]);

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();
    setValue('date', dateToInputFormat(new Date()));
    trigger();
  }, [trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Enjeksiyon Güncelle' : 'Enjeksiyon Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, reset, getValues, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={methods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required name="medicineId" label="İlaç" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required name="doctorName" label="Reçeteyi Yazan Doktor" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput type="date" required name="date" label="Tarih" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} required name="description" label="Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
