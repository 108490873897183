import { Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { Fragment, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';
import { userRoleArrToStr } from 'Common/RoleUtils';

import { UserContext } from '../../user.context';
import Action from './Action';
import EditDialog from './EditDialog';

export default function UsersList() {
  const { loading, users } = useContext(UserContext);
  return (
    <IndicatorWrapper loading={loading} data={users} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <List id="listReferrals">
        {users?.map((item, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemAvatar>
                <AccountCircleIcon style={{ color: 'gray' }} />
              </ListItemAvatar>
              <ListItemText
                style={{ color: 'gray' }}
                primary={item?.name}
                secondary={item?.roles ? `Kullanıcı Yetkisi : ${userRoleArrToStr(item?.roles)}` : null}
              />
              <ListItemSecondaryAction>
                <Action data={item} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
