import { OpenPromise } from '@thalesrc/js-utils';
import { useContext, useEffect, useState } from 'react';

import { EnumContext, EnumContextType, EnumType } from 'context/enum.context';

export default function useEnumPromise(enumName: keyof EnumContextType): Promise<EnumType[]> {
  const [promise] = useState(new OpenPromise<EnumType[]>());
  const enums = useContext(EnumContext);

  useEffect(() => {
    if (!!enums[enumName].length) {
      promise.resolve(enums[enumName]);
    }
  }, [enums, promise, enumName]);

  return promise;
}
