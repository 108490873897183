import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/Description';
import { remove } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import { DialogContext } from 'context/dialog.context';
import { useTranslate } from 'utils';

import { PlanningStep } from './planning-steps.enum';
import { PlanningContext } from './planning.context';

interface DocumentBoxProps {
  title: string;
  store: File[];
  setStore(files: File[]): void;
  dialogTitle: string;
}

function DocumentBox({ title, store, setStore, dialogTitle }: DocumentBoxProps) {
  const { openFileDialog } = useContext(DialogContext);

  const addFiles = useCallback(async () => {
    const files = await openFileDialog({ title: dialogTitle, maxCount: Number.POSITIVE_INFINITY });

    if (!files) {
      return;
    }

    setStore([...store, ...files]);
  }, [setStore, store, openFileDialog, dialogTitle]);

  const deleteFile = useCallback(
    (file: File) => () => {
      setStore(remove(store, file));
    },
    [setStore, store]
  );

  return (
    <Grid item xs={12} lg={4} className="px-1 mb-2">
      <Paper className="text-center pt-2 pb-4">
        <Typography variant="h6" className="mb-2">
          {title}
        </Typography>
        <List>
          {store.map(item => (
            <ListItem key={item.name}>
              <ListItemIcon>
                <FileIcon />
              </ListItemIcon>
              <ListItemText className="ml-n2">{item.name}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton onClick={deleteFile(item)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button onClick={addFiles} color="secondary">
          Dosya Ekle
        </Button>
      </Paper>
    </Grid>
  );
}

export default function Documents() {
  const translate = useTranslate('training');
  const { quiz, setQuiz, preQuiz, setPreQuiz, otherFiles, setOtherFiles, setActiveStep } = useContext(PlanningContext);

  const goNext = useCallback(() => {
    setActiveStep(PlanningStep.Preview);
  }, [setActiveStep]);

  return (
    <>
      <Grid container className="py-2">
        <DocumentBox title="Ön Testler" dialogTitle="Ön Test Dosyaları Ekle" store={preQuiz} setStore={setPreQuiz} />
        <DocumentBox title="Son Testler" dialogTitle="Son Test Dosyaları Ekle" store={quiz} setStore={setQuiz} />
        <DocumentBox title="Eğitim Materyalleri" dialogTitle="Eğitim Materyalleri Ekle" store={otherFiles} setStore={setOtherFiles} />
      </Grid>
      <footer className="text-right">
        <Button variant="contained" color="secondary" onClick={goNext}>
          {translate('Preview')}
        </Button>
      </footer>
    </>
  );
}
