import { debounceWithKey, noop } from '@thalesrc/js-utils';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  value: string;

  onChange?(value: string): void;

  onEscape?(): void;

  input(props: {
    value: string;
    onChange(value: string): void;
    onKeyPress(key: string): void;
    onFocus(): void;
    onBlur(): void;
  }): JSX.Element;
}

export default function SearchBarContainer({ value, onChange = noop, input, onEscape = noop }: Props) {
  const [innerValue, setInnerValue] = useState('');
  const [debounceKey] = useState(Symbol());
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    if (!focused) {
      return;
    }

    function keydownListener(e: KeyboardEvent) {
      if (e?.key !== 'Escape') {
        return;
      }

      setInnerValue('');
      onChange('');
      onEscape();
    }

    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
    };
  }, [focused, onChange, onEscape]);

  return input({
    value: innerValue,
    onChange: useCallback(
      _value => {
        setInnerValue(_value);

        debounceWithKey(debounceKey, () => onChange(_value), 400);
      },
      [setInnerValue, onChange, debounceKey]
    ),
    onKeyPress: useCallback(
      key => {
        if (key !== 'Escape') {
          return;
        }

        setInnerValue('');
        onChange('');
      },
      [setInnerValue, onChange]
    ),
    onFocus: useCallback(() => setFocused(true), [setFocused]),
    onBlur: useCallback(() => setFocused(false), [setFocused]),
  });
}
