import { createContext } from 'react';

import { GetFirmUserResult } from 'api/hs/resource';
import { UsersModel } from 'api/hs/users';

export interface UserContextType {
  users: GetFirmUserResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: UsersModel;

  setSelectedData(data: UsersModel): void;
}

export const UserContext = createContext<UserContextType>(null);
