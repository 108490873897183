import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { getVaccineList } from 'api/hs/TransactionAndLists/VaccineList';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import { CELL } from './COLUMS';
import TableList from './TableList';
import Toolbar from './Toolbar';

export default function Examination() {
  const history = useHistory();
  const fetchVaccineList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getVaccineList(page, size, sorting === false ? 'date-DESC' : `${sorting.id as string}-${sorting.direction.toUpperCase()}`);
  }, []);
  return (
    <HSTableAndPaginationAndTableContextProvider
      fetcher={fetchVaccineList}
      RowProps={{ className: 'cursor-pointer' }}
      columns={CELL}
      onRowClick={row => history.push(`/transactions/employee/${row.employeeId}/vaccine`)}>
      <Toolbar />
      <TableList />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
