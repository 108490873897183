/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkingHoursDto } from '../models/WorkingHoursDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WorkingHoursControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns WorkingHoursDto OK
     * @throws ApiError
     */
    public static partialUpdateWorkingHours(
        id: string,
        requestBody: WorkingHoursDto,
    ): CancelablePromise<WorkingHoursDto> {
        return __request({
            method: 'PUT',
            path: `/working-hours/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteWorkingHours(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/working-hours/${id}`,
        });
    }

    /**
     * @param startDate
     * @param endDate
     * @param companyId
     * @returns WorkingHoursDto OK
     * @throws ApiError
     */
    public static getWorkingHours(
        startDate: string,
        endDate: string,
        companyId?: string,
    ): CancelablePromise<Array<WorkingHoursDto>> {
        return __request({
            method: 'GET',
            path: `/working-hours`,
            query: {
                'companyId': companyId,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

    /**
     * @param requestBody
     * @returns WorkingHoursDto OK
     * @throws ApiError
     */
    public static saveUpdateWorkingHours(
        requestBody: WorkingHoursDto,
    ): CancelablePromise<WorkingHoursDto> {
        return __request({
            method: 'POST',
            path: `/working-hours`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param companyId
     * @param formData
     * @returns WorkingHoursDto OK
     * @throws ApiError
     */
    public static employeeFilterViewImport(
        companyId: string,
        formData?: {
            file: Blob;
        },
    ): CancelablePromise<Array<WorkingHoursDto>> {
        return __request({
            method: 'POST',
            path: `/working-hours/import`,
            query: {
                'companyId': companyId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param companyId
     * @param startDate
     * @param endDate
     * @returns binary OK
     * @throws ApiError
     */
    public static exportTemplate(
        companyId: string,
        startDate: string,
        endDate: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/working-hours/template/export`,
            query: {
                'companyId': companyId,
                'startDate': startDate,
                'endDate': endDate,
            },
        });
    }

}