import { IconButton, makeStyles, TableBody, TableCell, TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Employee } from 'model';

import { EmployeeListContext } from '../../employee-list.context';
import Action from './Action';
import DetailDrawer from './DetailDrawer';
import EditDialog from './EditDialog';

const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',

    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function Body() {
  const classes = useStyles();
  const { content, cells, setDetailsDrawerOpened, setSelectedEmployee } = useContext(EmployeeListContext);
  const history = useHistory();
  const { pathname } = useLocation();

  const navigateToDetails = useCallback((employeeId: string) => () => history.push(`${pathname}/${employeeId}`), [history, pathname]);

  const openDetailsDrawer = useCallback(
    (employee: Employee) => (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedEmployee(employee);
      setDetailsDrawerOpened(true);
    },
    [setDetailsDrawerOpened, setSelectedEmployee]
  );

  return (
    <>
      <TableBody>
        {!content?.length ? (
          <TableRow>
            <TableCell colSpan={cells.filter(({ visible }) => visible).length + 2}>
              <Alert className="mt-2 flex-center" severity="error">
                Gösterilecek Kayıt Bulunamadı !
              </Alert>
            </TableCell>
          </TableRow>
        ) : (
          content.map(employee => (
            <TableRow key={employee.id} className={`${classes.row}`} onClick={navigateToDetails(employee.id)}>
              {cells
                .filter(({ visible }) => visible)
                .map(({ Render, attr, id }) => (
                  <TableCell key={id} className="p-1">
                    {Render ? <Render value={(employee as any)[attr]} /> : (employee as any)[attr]}
                  </TableCell>
                ))}
              <TableCell className="p-1">
                <IconButton onClick={navigateToDetails(employee.id)}>
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              <TableCell className="p-1">
                <Action employee={employee} />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
      <EditDialog />
      <DetailDrawer />
    </>
  );
}
