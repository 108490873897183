/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StationExposureDto } from '../models/StationExposureDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StationExposureControllerService {

    /**
     * @param stationId
     * @returns StationExposureDto OK
     * @throws ApiError
     */
    public static getAllStationExposure(
        stationId: string,
    ): CancelablePromise<Array<StationExposureDto>> {
        return __request({
            method: 'GET',
            path: `/stations/exposures`,
            query: {
                'stationId': stationId,
            },
        });
    }

    /**
     * @param positionId
     * @param requestBody
     * @returns StationExposureDto OK
     * @throws ApiError
     */
    public static saveStationsExposures(
        positionId: string,
        requestBody: Array<StationExposureDto>,
    ): CancelablePromise<Array<StationExposureDto>> {
        return __request({
            method: 'POST',
            path: `/stations/exposures`,
            query: {
                'positionId': positionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteStationExposure(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/stations/exposures/${id}`,
        });
    }

}