import { Divider, Icon, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { ComplaintsContextContext } from '../../complaints.context';
import Action from './Action';
import EditDialog from './EditDialog';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: 'lightslategray',
  },
  descriptionName: {
    fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

export default function Listtt() {
  const classes = useStyles();
  const { loading, complaints } = useContext(ComplaintsContextContext);

  return (
    <IndicatorWrapper loading={loading} data={complaints} errorMessage="Gösterilecek Kayıt Bulunamadı">
      <List key={complaints?.id}>
        <ListItem key={complaints?.id}>
          <ListItemAvatar className={classes.textColor}>
            <Icon className="icon-note-plus-outline" />
          </ListItemAvatar>
          <ListItemText color="primary">
            <span className={classes.descriptionName}>{complaints?.description}</span>
          </ListItemText>
          <ListItemSecondaryAction>
            <Action data={complaints} />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
