import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { EmergencyCaseTeamDto } from '../../../../api/client';
import { EmergencyCaseTeamControllerService } from '../../../../api/client';
import { AlertContext } from '../../../../context/alert.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: EmergencyCaseTeamDto;
}

export default function CaseTeamsForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { emergencyCaseDefinition, companies } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      try {
        if (!data) {
          await EmergencyCaseTeamControllerService.saveEmergencyCaseTeam(values);
          showMessage('Kayıt Başarılı ile eklendi', 'success', 'info');
        } else {
          await EmergencyCaseTeamControllerService.partialUpdateEmergencyCaseTeam(data?.id, values);
          showMessage('Kayıt Başarılı ile güncellendi', 'success', 'info');
        }
        onClose(true);
      } catch (e) {
        showMessage('İşlem sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, data, showMessage]
  );

  useAsyncEffect(async () => {
    if (data) {
      await defer();
      for (const [key, value] of Object.entries(data)) {
        formRef?.current?.methods?.setValue(key, value);
      }
      formRef?.current?.methods?.trigger();
    }
  }, [data, open]);

  return (
    <>
      <Dialog
        color="secondary"
        opened={open}
        onClose={onClose.bind(null, false)}
        title={data ? 'Acil Ekip Üyesi Düzenle' : 'Acil Ekip Üyesi Ekle'}>
        <Form onSubmit={submit} onChange={reRender} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete single name="emergencyCaseDefinitionId" options={emergencyCaseDefinition} label="Acil Durum Ekibi" required={true}/>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete single name="companyId" options={companies} label="Şirket" required={true} />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
