import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import React from 'react';

import { deleteDepartment, DepartmentResult, getDepartment } from 'api/hs/definitions/workplace-definition/department';
import DepartmentEditDialog from 'Common/Forms/Definitions/WorkPlaceDefiniton/Department/DepartmentEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function Department() {
  return (
    <BaseDefinitionPage<DepartmentResult>
      listLabelPropName="departmentName"
      fetchData={getDepartment}
      FormDialog={DepartmentEditDialog}
      onDelete={deleteDepartment}
      Icon={<AccountBalanceIcon />}
      searchName="departmentName"
    />
  );
}
