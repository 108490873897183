import React from 'react';

import AdminAllergy from './AdminAllergy';
import AdminAnalysisDefinition from './AdminAnalysisDefinition';
import AdminBloodRelationDefinition from './AdminBloodRelationDefinition';
import AdminDisabilityDefinition from './AdminDisabilityDefinition';
import AdminOpinionDefinition from './AdminOpinionDefinition';
import AdminPhysicalCheckDefinition from './AdminPhysicalCheckDefinition';
import AdminReferralDefinition from './AdminReferralDefinition';
import AdminRestReportDefinition from './AdminRestReportDefinition';
import AdminSystemCheckDefinition from './AdminSystemCheckDefinition';
import AdminVaccine from './AdminVaccineDefinition';

interface AdminHealtDefinitionListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'ALERJILER',
    Component: AdminAllergy,
    path: '',
  },
  {
    label: 'AŞILAR',
    Component: AdminVaccine,
    path: 'asilar',
  },
  {
    label: 'FİZİK MUAYENE',
    Component: AdminPhysicalCheckDefinition,
    path: 'fizik-muayene',
  },
  {
    label: 'SİSTEM SORGULAMA',
    Component: AdminSystemCheckDefinition,
    path: 'sistem-sorgulama',
  },
  {
    label: 'TAHLİL/TETKİK',
    Component: AdminAnalysisDefinition,
    path: 'tatlil-tetkik',
  },
  {
    label: 'İSTİRAHAT RAPORU',
    Component: AdminRestReportDefinition,
    path: 'istirahat-raporu',
  },
  {
    label: 'KANAAT',
    Component: AdminOpinionDefinition,
    path: 'kanaat',
  },
  {
    label: 'Yakınlıklar',
    Component: AdminBloodRelationDefinition,
    path: 'yakinliklar',
  },
  {
    label: 'ENGELLİLİK',
    Component: AdminDisabilityDefinition,
    path: 'engellilik',
  },
  {
    label: 'SEVKLER',
    Component: AdminReferralDefinition,
    path: 'sevkler',
  },
] as AdminHealtDefinitionListItem[];
