import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { defer, findByKey, remove } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { saveOpinion } from 'api/hs/examination/hs-exam-opinions';
import Button from 'Common/Button';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';
import { ResourceContext } from 'context/resource.context';
import { useAsyncEffect, useXhr } from 'utils';

import { EmployeeContext } from '../../employee.context';
import { OpinionContext } from '../opinion.context';
import { OPINION_ID } from './opinion-id.model';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface OptionExtra {
  color: string;
}

const DEFAULT_COMPLAINT_ID = 2;

const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Çalışabilir', value: 1, color: '#2196f3' },
  { text: 'Çalışamaz', value: 0, color: '#eb4355' },
  { text: <Icon className="icon-delete" />, value: DEFAULT_COMPLAINT_ID, color: '#c0c0c0' },
];

export default function DialogFormOpinion() {
  const classes = useStyles();
  const [opinionFind, setOpinionFind] = useState({});
  const { editDialogOpened, setEditDialogOpened, opinion, reload, workingRestriction } = useContext(OpinionContext);
  const { setValue, getValues, trigger, ...methods } = useForm();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { workingRestrictionDefinitions, positions } = useContext(ResourceContext);
  const { employee } = useContext(EmployeeContext);

  const cancel = useCallback(() => {
    setEditDialogOpened(false);
  }, [setEditDialogOpened]);

  const submitForm = useXhr(
    async values => {
      await saveOpinion({
        examinationId,
        employeeId,
        description: values.description,
        positionDefinition: values.positionDefinition,
        workingCondition: values.workingCondition,
        workingRestrictions: Object.entries(values)
          .filter(([id]) => id.length > 25)
          .map(([id, value]) => ({ workingRestrictionDefinitionId: id, workingRestrictionValue: value })),
      });
      await reload();
      setEditDialogOpened(false);
    },
    'Kanaat Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [examinationId, reload]
  );

  useAsyncEffect(async () => {
    if (!opinion) {
      return;
    }
    if (opinion) {
      await defer();
      for (const item of opinion.workingRestrictions) {
        setValue(
          item.workingRestrictionDefinitionId + '',
          item.workingRestrictionValue === undefined ? DEFAULT_COMPLAINT_ID : item.workingRestrictionValue
        );
      }

      setValue(
        'positionDefinition',
        opinion?.workingCondition ? opinion?.workingCondition : findByKey(positions, 'id', employee?.positionId)?.positionName
      );
      setValue('workingCondition', opinion?.workingCondition);
      setValue('description', opinion?.description);

      await trigger();
    }
    await defer();
    setValue('positionDefinition', employee?.positionId ? findByKey(positions, 'id', employee?.positionId)?.positionName : null);
    await trigger();
  }, [opinion, setValue, trigger, editDialogOpened]);

  const orderedWorkingRestriction = useMemo(() => {
    const opinionItem = findByKey(
      workingRestriction ?? [],
      'id',
      workingRestrictionDefinitions?.find(opinions => opinions?.opinion === OPINION_ID)?.id
    );
    setOpinionFind(opinionItem);
    return [...remove(workingRestriction ?? [], opinionItem)];
  }, [workingRestriction, workingRestrictionDefinitions]);

  const workingCondition = getValues('workingCondition');
  const positionDefinition = getValues('positionDefinition');
  const opinionValue = getValues(opinionFind?.id + '');

  const opinionText = useMemo(() => {
    const workingDescription = workingCondition ? workingCondition + ' şartı ile ' : '';
    const positionDescription = positionDefinition ? positionDefinition + ' işinde' : '';
    const opinionDescription = opinionValue === 1 ? ' çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.';
    return opinionValue !== 2
      ? opinionValue === 1
        ? `${positionDescription} ${workingDescription} ${opinionDescription}`
        : `${positionDescription} ${opinionDescription} `
      : null;
  }, [opinionValue, positionDefinition, workingCondition]);

  return (
    <MUIDialog open={editDialogOpened} fullWidth maxWidth="sm" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Kanaat Ekle / Düzenle</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, getValues, setValue, trigger }}>
          <form id="edit-opinion-form" onSubmit={methods.handleSubmit(submitForm)}>
            <Grid container>
              <Grid item md={12} sm={12} lg={12}>
                <TextField name="positionDefinition" label="Detaylı İş Tanımı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={2} name="workingCondition" label="Çalışma Şartları" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow key="kanaat">
                        <TableCell style={{ width: '65%', color: 'gray' }} align="left">
                          {opinionFind?.opinion}
                        </TableCell>
                        <TableCell style={{ width: '35%' }} align="right">
                          <ToggleButtonGroup<number, OptionExtra>
                            name={opinionFind?.id + ''}
                            options={OPTIONS}
                            defaultValue={DEFAULT_COMPLAINT_ID}
                            renderOption={({ color, selected, text, value }) => (
                              <ToggleButton
                                value={value}
                                className={selected ? 'text-white' : ''}
                                style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                                {text}
                              </ToggleButton>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {opinionValue !== 2 ? (
                <Grid className="mt-1 mb-1" item md={12} sm={12} lg={12}>
                  <List>
                    <ListItem style={{ color: opinionValue === 1 ? '#2196f3' : '#eb4355' }}>
                      <ListItemText primary={opinionText} />
                    </ListItem>
                  </List>
                </Grid>
              ) : null}

              <Grid item md={12} sm={12} lg={12}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {orderedWorkingRestriction?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '65%', color: 'gray' }} align="left">
                            {findByKey(workingRestrictionDefinitions, 'id', item?.id)?.opinion}
                          </TableCell>
                          <TableCell style={{ width: '35%' }} align="right">
                            <ToggleButtonGroup<number, OptionExtra>
                              name={item.id + ''}
                              options={OPTIONS}
                              defaultValue={DEFAULT_COMPLAINT_ID}
                              renderOption={({ color, selected, text, value }) => (
                                <ToggleButton
                                  value={value}
                                  className={selected ? 'text-white' : ''}
                                  style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                                  {text}
                                </ToggleButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField rows={3} name="description" label="Diğer Hususlar" />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right p-4">
        <Button variant="contained" onClick={cancel}>
          Vazgeç
        </Button>
        <Button variant="contained" form="edit-opinion-form" type="submit" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
