import React from 'react';

import AddPolyclinicExamOpinions from './Add';
import FormDialogPolyclinicExamOpinions from './Form/FormDialogPolyclinicExamOpinions';
import ListPolyclinicExamOpinions from './List';

export default function Index() {
  return (
    <>
      <ListPolyclinicExamOpinions />
      <AddPolyclinicExamOpinions />
      <FormDialogPolyclinicExamOpinions />
    </>
  );
}
