// tslint:disable:max-classes-per-file

import { findByKey } from '@thalesrc/js-utils';

import { ComplaintHistoryDetailResult } from 'api/hs/examination';
import { EnumType } from 'context/enum.context';
import { modelDataMatcher } from 'utils';

function getColorByValue(value: number): string {
  switch (value) {
    case 0:
      return '#469789';
    case 1:
      return '#eb4355';
    default:
      return '#c0c0c0';
  }
}

function getTextByValue(value: number): string {
  switch (value) {
    case 0:
      return 'Hayır';
    case 1:
      return 'Evet';
    default:
      return 'Belirsiz';
  }
}

export class ComplaintHistoryDetail {
  public id?: string = null;
  public value: number = null;
  public valueText: string = null;
  public color: string = null;
  public complaintDefinitionId: number = null;
  public complaintDefinitionName: string = null;

  constructor(data: Partial<ComplaintHistoryDetail> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromEnumAndServerData(_enum: EnumType, data: ComplaintHistoryDetailResult[]): ComplaintHistoryDetail {
    const pickedData = findByKey(data, 'complaintDefinitionId', _enum.id);

    return new ComplaintHistoryDetail({
      id: pickedData?.id,
      value: pickedData?.value,
      valueText: getTextByValue(pickedData?.value),
      color: getColorByValue(pickedData?.value),
      complaintDefinitionId: _enum.id,
      complaintDefinitionName: _enum.label,
    });
  }
}

export class ComplaintHistory {
  public description: string = null;
  public complaintHistories: ComplaintHistoryDetail[] = [];

  constructor(data: Partial<ComplaintHistory> = {}) {
    modelDataMatcher(data, this);
  }
}
