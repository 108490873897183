import React from 'react';

import BuildingFloorDefinitionsAdd from './Add';
import BuildingFloorDefinitionsList from './List';

export default function Index() {
  return (
    <>
      <BuildingFloorDefinitionsAdd />
      <BuildingFloorDefinitionsList />
    </>
  );
}
