import { Button, FormControl, FormHelperText, Input } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { forgotPassword } from 'api/hs/authentication/forgot-password';
import { AlertContext } from 'context/alert.context';
import { email } from 'validators';

import InputLabel from './InputLabel';
import Layout from './Layout';

export default function ForgotPassword() {
  const { register, errors, trigger, handleSubmit } = useForm();
  const { showMessage } = useContext(AlertContext);

  async function handleForgotPassword(value: { email: string }) {
    try {
      await forgotPassword(value);

      showMessage('Şifre yenileme e-postası gönderildi. Lütfen gelen kutusunu kontrol ediniz', 'success', 'info');
    } catch (error) {
      if (error.response.status === 404) {
        showMessage('Kullanıcı bulunamadı', 'error', 'info');
      }
    }
  }

  function validate() {
    trigger();
  }

  return (
    <Layout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleForgotPassword)}>
        <FormControl fullWidth className="mb-2" error={!!errors.email}>
          <InputLabel htmlFor="email">
            <AccountCircleIcon className="mr-2" />
            E-Posta Adresinizi Giriniz
          </InputLabel>
          <Input
            id="email"
            name="email"
            className="mt-3"
            inputRef={register({
              required: 'Bu alan zorunlu',
              validate: { email },
            })}
            onChange={validate}
          />
          <FormHelperText>{errors.email?.message}</FormHelperText>
        </FormControl>
        <footer className="text-center">
          <div className="mb-2">
            <Button variant="contained" color="primary" className="px-4" disabled={!!Object.keys(errors).length} type="submit">
              ŞİFRENİ SIFIRLA
            </Button>
          </div>
          <div>
            <Link to="/" className="ml-1">
              Giriş Sayfası »
            </Link>
          </div>
        </footer>
      </form>
    </Layout>
  );
}
