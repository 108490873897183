import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { GetTrainingsGroupDefinitionsResult } from './get-trainings-group-definitions.result';

export async function getTrainingsGroupDefinitions(): Promise<GetTrainingsGroupDefinitionsResult[]> {
  const [error, res] = await tryCatch(HSApi.get<GetTrainingsGroupDefinitionsResult[]>(`/firms/trainings/group-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
