import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { SaveDisabilityPayload, UpdateDisabilityPayload } from './disabilities.payload';
import { GetDisabilitiesResult } from './disabilities.result';

export async function getDisabilities(employeeId: string, examinationId?: string): Promise<GetDisabilitiesResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<GetDisabilitiesResult>(`/disabilities?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<GetDisabilitiesResult>(`/disabilities?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveDisabilities(body: SaveDisabilityPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/disabilities', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateDisabilities(id: string, body: UpdateDisabilityPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.put(`/disabilities/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteDisabilities(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/disabilities/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
