import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import { saveExposure, updateExposure } from 'api/hs/definitions/workplace-definition/exposure';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { ExposureModel } from 'model/definitions/workplace-definition/exposure.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the exposure is created/updated
   */
  onClose(success: boolean): void;

  data: ExposureModel;
}

export default function ExposureEditDialog({ open, onClose, data }: Props) {
  const { hazardCategories } = useContext(SelectOptionsContext);
  return (
    <BaseDefinitionForm<ExposureModel>
      open={open}
      onClose={onClose}
      onSave={saveExposure}
      onUpdate={updateExposure}
      data={data}
      resourceReloadName="exposures"
      createTitle="Maruziyet Ekle"
      updateTitle="Maruziyet Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <Select required name="hazardSourceDefinitionId" options={hazardCategories} label="Tehlike Sınıfı" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="exposureName" label="Maruziyet Adı" />
      </Grid>
    </BaseDefinitionForm>
  );
}
