/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeIbysWorkTypeDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    employeeId?: string;
    workType?: EmployeeIbysWorkTypeDto.workType;
    code?: number;
}

export namespace EmployeeIbysWorkTypeDto {

    export enum workType {
        WORKING_POSITION = 'WORKING_POSITION',
        WORKING_TIME = 'WORKING_TIME',
        VEHICLE_OPERATION = 'VEHICLE_OPERATION',
    }


}
