import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';
import { CreateGuestPayload } from './create-guest.payload';
import { CreateGuestResult } from './create-guest.result';
import { CreateTrainerPayload } from './create-trainer.payload';
import { CreateTrainerResult } from './create-trainer.result';
import { CreateTrainingPayload, TrainingPlanningPayload } from './create-training-planning.payload';
import { CreateTrainingPlanningResult } from './create-training-planning.result';
import { TrainingDetailResult } from './training-detail.result';
import {TrainingDto} from "../../client";

async function uploadTrainingFile(trainingId: string, paramName: 'imported-files' | 'pretest-files' | 'exam-files', file: File) {
  const data = new FormData();

  data.set('imported-file', file);

  const res = await HSApi.post(`/trainings/${trainingId}/${paramName}`, data);

  return res.data;
}

async function createTrainingGuest(payload: CreateGuestPayload): Promise<CreateGuestResult> {
  const res = await HSApi.post(`/guests`, payload);

  return res.data;
}

// async function updateTrainingGuest(id: string, payload: CreateGuestPayload): Promise<CreateGuestResult> {
//   const res = await HSApi.put(`/guests/${id}`, payload);
//
//   return res.data;
// }

async function createTrainingPlanningReq(payload: TrainingPlanningPayload): Promise<TrainingDto> {
  const res = await HSApi.post(`/trainings`, payload);

  return res.data;
}

async function updateTrainingPlanningReq(trainingId: string, payload: TrainingPlanningPayload): Promise<TrainingDto> {
  const res = await HSApi.put(`/trainings/${trainingId}`, payload);

  return res.data;
}

export async function createTrainingPlanning({
  participants: { employees, guests },
  quizFiles,
  preQuizFiles,
  otherFiles,
  ...payload
}: CreateTrainingPayload): Promise<TrainingDto> {
  const guestIds = await Promise.all(guests.map(guest => createTrainingGuest(guest)));
  const res = await createTrainingPlanningReq({
    ...payload,
    trainingEmployees: [...employees.map(id => ({ employeeId: id })), ...guestIds.map(({ id }) => ({ guestId: id }))],
    plannedDate: payload.plannedDate,
  });

  await Promise.all([
    ...quizFiles.map(file => uploadTrainingFile(res.id, 'exam-files', file)),
    ...preQuizFiles.map(file => uploadTrainingFile(res.id, 'pretest-files', file)),
    ...otherFiles.map(file => uploadTrainingFile(res.id, 'imported-files', file)),
  ]);

  return res;
}

export async function updateTrainingPlanning(
  trainingId: string,
  { participants: { employees, guests }, quizFiles, preQuizFiles, otherFiles, ...payload }: CreateTrainingPayload
): Promise<TrainingDto> {
  const guestIds = await Promise.all(guests.map(guest => createTrainingGuest(guest)));

  const res = await updateTrainingPlanningReq(trainingId, {
    ...payload,
    trainingEmployees: [...employees.map(id => ({ employeeId: id })), ...guestIds.map(({ id }) => ({ guestId: id }))],
    plannedDate: new Date(`${payload.plannedDate}`).toISOString(),
  });

  await Promise.all([
    ...quizFiles.map(file => uploadTrainingFile(res.id, 'exam-files', file)),
    ...preQuizFiles.map(file => uploadTrainingFile(res.id, 'pretest-files', file)),
    ...otherFiles.map(file => uploadTrainingFile(res.id, 'imported-files', file)),
  ]);

  return res;
}

export async function createTrainer(payload: CreateTrainerPayload): Promise<CreateTrainerResult> {
  const PROPS_TO_CONVERT_INTO_NULL = ['title', 'lastName', 'name', 'nid', 'isEmployee', 'employeeId', 'trainerTypeDefinition'];
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((payload as any)[key] !== '') {
      continue;
    }

    (payload as any)[key] = null;
  }
  const res = await HSApi.post<CreateTrainerResult>(`/trainers`, payload);

  return res.data;
}

export async function getByIdTraining(trainingId: string): Promise<TrainingDetailResult> {
  let res;
  try {
    res = await HSApi.get<TrainingDetailResult>(`/trainings/${trainingId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getGuestIds(guestIds: string[]) {
  let res;
  try {
    res = await HSApi.get(`/guests/list?ids=${guestIds}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteTraining(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/trainings/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
