/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ResetPasswordRequest = {
    resetPasswordId?: string;
    resetPasswordType?: ResetPasswordRequest.resetPasswordType;
    newPassword: string;
}

export namespace ResetPasswordRequest {

    export enum resetPasswordType {
        NEW_USER = 'NEW_USER',
        FORGOT_PASSWORD = 'FORGOT_PASSWORD',
        EMPLOYEE_LOGIN = 'EMPLOYEE_LOGIN',
    }


}
