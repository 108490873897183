import { Chip, Grid, Icon } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { ResourceContext } from '../../../../context/resource.context';
import { TrainingDetailContext } from '../completed.context';

export default function ParticipantInfo() {
  const { trainingDetail } = useContext(TrainingDetailContext);
  const { trainingDefinitions } = useContext(ResourceContext);

  const [openTopicsInfo, setOpenTopicsInfo] = React.useState(false);

  const handleClick = () => {
    setOpenTopicsInfo(!openTopicsInfo);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon-attachment" />
        </ListItemIcon>
        <ListItemText primary="Eğitimin Konu Bilgisi" />
        {openTopicsInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse style={{ paddingLeft: '15px', paddingTop: '10px' }} in={openTopicsInfo} timeout="auto" unmountOnExit>
        <Grid container>
          {trainingDetail?.trainingDefinitions?.map(training => (
            <>
              <Grid item md="auto" lg="auto" sm="auto">
                {
                  <Chip
                    className="mt-1 mr-1"
                    icon={<Icon className="icon icon-school" />}
                    label={`${findByKey(trainingDefinitions, 'id', training.trainingDefinitionId)?.name} - ${
                      training?.durationInMinutes
                    } dk`}
                  />
                }
              </Grid>
            </>
          ))}
        </Grid>
      </Collapse>
    </>
  );
}
