import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { SystemCheckPayload } from './SystemCheck.payload';
import { SystemCheckResult } from './SystemCheck.result';

export async function getSystemCheck(): Promise<SystemCheckResult[]> {
  const [error, res] = await tryCatch(HSApi.get<SystemCheckResult[]>(`/firms/system-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveSystemCheck(body: SystemCheckPayload): Promise<SystemCheckResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/system-check-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateSystemCheck({ id, ...body }: MakeRequired<Partial<SystemCheckResult>, 'id'>): Promise<SystemCheckResult> {
  const [error, res] = await tryCatch(HSApi.put<SystemCheckResult>(`/firms/system-check-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteSystemCheck(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/system-check-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminSystemCheck(): Promise<SystemCheckResult[]> {
  const [error, res] = await tryCatch(HSApi.get<SystemCheckResult[]>(`/system-check-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminSystemCheck(body: SystemCheckPayload): Promise<SystemCheckResult> {
  const [err, res] = await tryCatch(HSApi.post('/system-check-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminSystemCheck({ id, ...body }: MakeRequired<Partial<SystemCheckResult>, 'id'>): Promise<SystemCheckResult> {
  const [error, res] = await tryCatch(HSApi.put<SystemCheckResult>(`/system-check-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminSystemCheck(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/system-check-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
