import React, { useCallback, useContext } from 'react';

import Dialog from '../../../../../Common/Dialog';
import { HSTableContext } from '../../../../../Common/Table/HSTable/hs-table.context';
import AccidentRecordsForm from '../../../../AccidentRecords/ListAdd/Form';
import { AccidentsContext } from '../../accidents.context';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(AccidentsContext);
  const { refresh } = useContext(HSTableContext);
  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [setEditDialogOpened, refresh]
  );

  return (
    <Dialog color="secondary" title={'Kaza Düzenle'} opened={editDialogOpened} onClose={closeDialog}>
      {' '}
      <AccidentRecordsForm onClose={closeDialog} data={selectedData} />
    </Dialog>
  );
}
