import { Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { RiskAssessmentHazardControllerService } from '../../../../api/client';
import { RiskAssessmentHazardDto } from '../../../../api/client';
import AutoComplete from '../../../../Common/Form/AutoComplete';
import TextField from '../../../../Common/Form/TextField';
import { AlertContext } from '../../../../context/alert.context';
import { ResourceContext } from '../../../../context/resource.context';
import { SelectOptionsContext } from '../../../../context/select-options.context';
import { RiskAssessmentHazardContext } from '../../risk-assessment-hazard.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: RiskAssessmentHazardDto;
}

export default function RiskAssessmentHazardForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const {
    matrixPossibilityDefinition: possibilityDefinitionMatrix,
    matrixHazardDamageDefinition: damageDefinitionMatrix,
    riskAssessmentMatrixScoreDefinition,
    fineKinneyHazardScoreDefinition,
    fineKinneyPossibilityDefinition: possibilityDefinitionFineKinney,
    fineKinneyHazardDamageDefinition: damageDefinitionFineKinney,
    fineKinneyHazardFrequencyDefinition: frequencyDefinitionFineKinney,
  } = useContext(ResourceContext);

  const hazardPossibilityValue: number = findByKey(
    possibilityDefinitionMatrix,
    'id',
    formRef?.current?.methods?.getValues('hazardPossibilityId')
  )?.value;
  const hazardDamageValue: number = findByKey(damageDefinitionMatrix, 'id', formRef?.current?.methods?.getValues('hazardDamageId'))?.value;
  const riskMatrixScoreValue = hazardPossibilityValue * hazardDamageValue;

  const fineKinneyDamageValue: number = findByKey(damageDefinitionFineKinney, 'id', formRef?.current?.methods?.getValues('hazardDamageId'))
    ?.value;
  const fineKinneyPossibilityValue: number = +findByKey(
    possibilityDefinitionFineKinney,
    'id',
    formRef?.current?.methods?.getValues('hazardPossibilityId')
  )?.value;
  const fineKinneyFrequencyValue: number = +findByKey(
    frequencyDefinitionFineKinney,
    'id',
    formRef?.current?.methods?.getValues('hazardFrequencyId')
  )?.value;
  const fineKinneyScoreValue = fineKinneyDamageValue * fineKinneyPossibilityValue * fineKinneyFrequencyValue;

  const reRender = useTriggerRender();
  const {
    hazardCategoryDefinition,
    matrixPossibilityDefinition,
    matrixHazardDamageDefinition,
    hazardCurrentPrecautionDefinition,
    fineKinneyHazardDamageDefinition,
    fineKinneyHazardFrequencyDefinition,
    fineKinneyPossibilityDefinition,
  } = useContext(SelectOptionsContext);
  const { riskAssessmentId, riskAssessmentType } = useContext(RiskAssessmentHazardContext);
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async values => {
      const payload = {
        riskAssessmentId,
        riskAssessmentType,
        hazardCategoryId: values?.hazardCategoryId,
        hazardDescription: values?.hazardDescription,
        riskDescription: values?.riskDescription,
        affected: values?.affected,
        hazardCurrentPrecautionId: values?.hazardCurrentPrecautionId,
        description: values?.description,
      };

      const RiskAssessmentMatrixPayload = {
        ...payload,
        riskAssessmentMatrixScore: {
          hazardPossibilityId: values?.hazardPossibilityId,
          hazardDamageId: values?.hazardDamageId,
          hazardScoreId: findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.id,
        },
      };

      const RiskAssessmentFineKinneyPayload = {
        ...payload,
        riskAssessmentFineKinneyScore: {
          hazardPossibilityId: values?.hazardPossibilityId,
          hazardDamageId: values?.hazardDamageId,
          hazardFrequencyId: values?.hazardFrequencyId,
          hazardScoreId: findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.id,
        },
      };

      try {
        if (!data) {
          await RiskAssessmentHazardControllerService.saveRiskAssessmentHazard(
            riskAssessmentId,
            riskAssessmentType === 'MATRIX' ? RiskAssessmentMatrixPayload : RiskAssessmentFineKinneyPayload
          );
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await RiskAssessmentHazardControllerService.updateRiskAssessmentHazard(
            data?.id,
            riskAssessmentType === 'MATRIX' ? RiskAssessmentMatrixPayload : RiskAssessmentFineKinneyPayload
          );
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (e) {
        showMessage('İşlem sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [
      data,
      showMessage,
      riskAssessmentId,
      onClose,
      riskAssessmentMatrixScoreDefinition,
      fineKinneyHazardScoreDefinition,
      fineKinneyScoreValue,
      riskMatrixScoreValue,
      riskAssessmentType,
    ]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    for (const [key, value] of Object.entries(data?.riskAssessmentMatrixScore ?? data?.riskAssessmentFineKinneyScore)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    formRef?.current?.methods?.trigger();
  }, [data, open]);

  const fineKinneyColor = (value: number) => {
    if (value < 20) {
      return '#78c6a3';
    } else if (value > 20 && value <= 70) {
      return '#FFCC1D';
    } else if (value > 70 && value <= 200) {
      return '#E85D04';
    } else if (value > 200 && value <= 400) {
      return '#D00000';
    } else if (value > 400) {
      return '#9D0208';
    } else {
      return '#78c6a3';
    }
  };

  const matrixRiskColor = (value: number) => {
    if (value === 1) {
      return '#78c6a3';
    } else if (value >= 2 && value <= 6) {
      return '#78c6a3';
    } else if (value > 6 && value < 16) {
      return '#FFCC1D';
    } else if (value >= 16 && value <= 20) {
      return '#D00000';
    } else if (value > 20) {
      return '#9D0208';
    } else {
      return '#78c6a3';
    }
  };

  return (
    <Dialog width="sm" title={'Tehlike - Risk Değerlendirme'} color="secondary" opened={open} onClose={() => onClose(false)}>
      <Form onChange={reRender} onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <AutoComplete single name="hazardCategoryId" options={hazardCategoryDefinition} label="Tehlike Kategorisi" required />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField label="Tehlike Tanımı" name="hazardDescription" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField rows={3} label="Risk Tanımı" name="riskDescription" />
          </Grid>
          {riskAssessmentType === 'MATRIX' ? (
            <>
              {' '}
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <AutoComplete single name="hazardPossibilityId" options={matrixPossibilityDefinition} label="Olasılık"  required />
              </Grid>
              <Grid item xs={6} md={6} lg={6} sm={6}>
                <AutoComplete single name="hazardDamageId" options={matrixHazardDamageDefinition} label="Şiddet" required />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4} md={4} lg={4} sm={4}>
                <AutoComplete single name="hazardPossibilityId" options={fineKinneyPossibilityDefinition} label="Olasılık" required />
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4}>
                <AutoComplete single name="hazardFrequencyId" options={fineKinneyHazardFrequencyDefinition} label="Sıklık" required />
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4}>
                <AutoComplete single name="hazardDamageId" options={fineKinneyHazardDamageDefinition} label="Şiddet" required/>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Alert
              style={{
                backgroundColor: `${fineKinneyScoreValue ? fineKinneyColor(fineKinneyScoreValue) : matrixRiskColor(riskMatrixScoreValue)}`,
              }}
              variant="filled"
              icon={false}
              severity="info">
              <div>
                <span style={{ fontWeight: 'bold', color: 'white' }}>Risk Skoru : </span>{' '}
                {findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.score}{' '}
                {findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.score}{' '}
              </div>
              <div>
                <span style={{ fontWeight: 'bold', color: 'white' }}>Risk Adı : </span>{' '}
                {findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.label}{' '}
                {findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.label}
              </div>
              <div>
                <span style={{ fontWeight: 'bold', color: 'white' }}>Eylem : </span>{' '}
                {findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.description}{' '}
                {findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.description}
              </div>
              <div>
                <span style={{ fontWeight: 'bold', color: 'white' }}>Termin Süresi : </span>{' '}
                {findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.deadline}{' '}
                {findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.deadline}
              </div>
            </Alert>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField label="Kimler Etkilenir" name="affected" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <AutoComplete
              single
              name="hazardCurrentPrecautionId"
              options={hazardCurrentPrecautionDefinition}
              label="Mevcut Önlemler Yeterli mi ?" required
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <TextField rows={3} label="Açıklama" name="description" />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
