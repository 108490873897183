import React, { useState } from 'react';

import { ActionsContentResult } from 'api/hs/actions';

import Action from './Action';
import { ActionContext } from './action.context';

export default function ActionsIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<ActionsContentResult>(null);

  return (
    <ActionContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Action />
    </ActionContext.Provider>
  );
}
