import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { dateSort, MakeRequired } from '../../../../utils';
import { DiagnosisModel } from './diagnosis.model';
import { SaveDiagnosisPayload } from './diagnosis.payload';
import { DiagnosisResult } from './diagnosis.result';

export async function getDiagnosis(employeeId: string, examinationId?: string): Promise<DiagnosisModel[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<DiagnosisResult[]>(`/diagnosis?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<DiagnosisResult[]>(`/diagnosis?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data ? DiagnosisModel.fromServerListData(dateSort(res.data)) : [];
}

export async function saveDiagnosis(payload: SaveDiagnosisPayload): Promise<unknown> {
  const [error, res] = await tryCatch(HSApi.post(`/diagnosis`, payload));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function updateDiagnosis({ id, ...body }: MakeRequired<Partial<DiagnosisResult>, 'id'>): Promise<DiagnosisResult> {
  const [error, res] = await tryCatch(HSApi.put<DiagnosisResult>(`/diagnosis/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response;
  }

  return res.data;
}

export async function deleteDiagnosis(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/diagnosis/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.error(err);
    throw err;
  }

  return res.data;
}
