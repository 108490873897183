import React from 'react';
import { Route, Switch } from 'react-router';

import MinistryList from './MinistryList';
import List from './PlannedCompletedList';
import Planning from './Planning';
import DetailList from './TrainingDetailList';
import TrainingEmployeeList from './TrainingDetailList/route';
import TrainingEmployeeListt from './TrainingEmployeeList';

export default function Training() {
  return (
    <Switch>
      <Route path="/training/detail-list/planning/:trainingId">
        <Planning />
      </Route>
      <Route path="/training/detail-list/planning">
        <Planning />
      </Route>
      <Route path="/training/detail-list/:trainingIdCompleted">
        <List />
      </Route>
      <Route path="/training/ministry-training-list">
        <MinistryList />
      </Route>
      <Route path="/training/detail-list">
        <DetailList />
      </Route>
      <Route path="/training/employee-list">
        <TrainingEmployeeListt />
      </Route>
    </Switch>
  );
}
