import React, { PropsWithChildren, useCallback, useState } from 'react';

import { useAsyncEffect, useFetch } from 'utils';
import { LicenceContext, LicenceContextType } from './licence.context';
import { Licence } from './licence.model';
import axios from "axios";
async function checkLicence() {
  try {
    const url = "https://www.artimetrik.net/api/checkLicence";
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    return [];
  }
}
export default function LicenceContextProvider({ children }: PropsWithChildren<{}>) {
  const [licence, setLicence] = useState<Licence | symbol>();
  useAsyncEffect(async () => {
      try {
        const licenceData = await checkLicence();
        setLicence(licenceData);
      } catch (error) {
        setLicence(null);
      }
  }, []);

  return (
    <LicenceContext.Provider value={{ licence: licence as Licence, setLicence }}>{children}</LicenceContext.Provider>
  );
}
