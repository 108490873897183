/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IbyExamBiologicalFactorConditionDto } from './IbyExamBiologicalFactorConditionDto';
import type { IbysExamAirConditionDto } from './IbysExamAirConditionDto';
import type { IbysExamChemicalMatterConditionDto } from './IbysExamChemicalMatterConditionDto';
import type { IbysExamDustRegulationConditionDto } from './IbysExamDustRegulationConditionDto';
import type { IbysExamElectricityConditionDto } from './IbysExamElectricityConditionDto';
import type { IbysExamNoiseConditionDto } from './IbysExamNoiseConditionDto';
import type { IbysExamPhysicalEnvironmentConditionDto } from './IbysExamPhysicalEnvironmentConditionDto';
import type { IbysExamWorkEquipmentDto } from './IbysExamWorkEquipmentDto';

export type IbysExaminationDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    examinationId?: string;
    ibysRequestState?: IbysExaminationDto.ibysRequestState;
    ssiNumber?: string;
    examIdOnIbysServer?: string;
    officialQueryId?: string;
    processResult?: IbysExaminationDto.processResult;
    processResultMessage?: string;
    physicianNid?: string;
    employeeNid?: string;
    professionCode?: string;
    workingPositionCode?: number;
    workingTimeCode?: number;
    vehicleOperationCode?: number;
    reportDate?: string;
    roentgenExists?: number;
    audioTestExists?: number;
    pftTestExists?: number;
    bloodTestExists?: number;
    urineTestExists?: number;
    reportEndDate?: string;
    opinion?: IbysExaminationDto.opinion;
    reportType?: IbysExaminationDto.reportType;
    airConditions?: Array<IbysExamAirConditionDto>;
    biologicalFactorConditions?: Array<IbyExamBiologicalFactorConditionDto>;
    chemicalMatterConditions?: Array<IbysExamChemicalMatterConditionDto>;
    dustRegulationConditions?: Array<IbysExamDustRegulationConditionDto>;
    electricityConditions?: Array<IbysExamElectricityConditionDto>;
    noiseConditions?: Array<IbysExamNoiseConditionDto>;
    physicalEnvironmentConditions?: Array<IbysExamPhysicalEnvironmentConditionDto>;
    workEquipments?: Array<IbysExamWorkEquipmentDto>;
}

export namespace IbysExaminationDto {

    export enum ibysRequestState {
        SENT = 'SENT',
        NOT_SENT = 'NOT_SENT',
        INCORRECT_REQUEST = 'INCORRECT_REQUEST',
        ON_STAND_BY = 'ON_STAND_BY',
    }

    export enum processResult {
        CONTINUES = 'CONTINUES',
        UNSUCCESSFUL = 'UNSUCCESSFUL',
        SUCCESSFUL = 'SUCCESSFUL',
    }

    export enum opinion {
        COULD_WORK = 'COULD_WORK',
        CONDITIONAL_WORK = 'CONDITIONAL_WORK',
        CHANGE_WORK = 'CHANGE_WORK',
        COULD_NOT_WORK = 'COULD_NOT_WORK',
    }

    export enum reportType {
        RECRUITMENT_EXAMINATION = 'RECRUITMENT_EXAMINATION',
        PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
        RETURN_TO_WORK_EXAMINATION = 'RETURN_TO_WORK_EXAMINATION',
        JOB_CHANGE_EXAMINATION = 'JOB_CHANGE_EXAMINATION',
        OTHER = 'OTHER',
    }


}
