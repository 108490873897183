import { makeStyles, TableCell, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';

import { getMedicinePage, searchMedicine } from 'api/hs/definitions/health-definitions/medicine/ApiMedicine';
import { Medicine } from 'model/definitions/health-definitions/medicine.model';

import Icd10Medicine from '../../../BaseIcd10MedicineDefinition';
import { ListItemTemplateProps } from '../../../BaseIcd10MedicineDefinition/Icd10.context';

const useStyles = makeStyles(theme => ({
  medicineName: {
    width: '7%',
    maxWidth: '500px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '30px',
  },
  itemLabel: {
    overflow: 'inherit',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },
  producerCompany: {
    width: '11%',
    maxWidth: '153px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '5px',
  },
}));

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: 9,
  },
  head: {
    backgroundColor: 'white',
    color: 'gray',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const prescriptionType = (type: number) => {
  switch (type) {
    case 1:
      return 'Normal';
    case 2:
      return 'Kırmızı';
    case 3:
      return 'Turuncu';
    case 4:
      return 'Mor';
    case 5:
      return 'Yeşil';
    default:
      return 'Belirsiz';
  }
};

function ItemTableBody({ item }: ListItemTemplateProps<Medicine>) {
  const classes = useStyles();
  return (
    <>
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left">{item.barcode}</StyledTableCell>
        <StyledTableCell align="left" className={classes.medicineName}>
          <Tooltip title={item.label}>
            <Typography className={classes.itemLabel}>{item.label}</Typography>
          </Tooltip>
        </StyledTableCell>

        <StyledTableCell align="left" className={classes.producerCompany}>
          <Tooltip title={item.producerCompany}>
            <span>{item.producerCompany}</span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell align="left">{item.atcCode}</StyledTableCell>
        <StyledTableCell align="center">{prescriptionType(item.prescriptionType)}</StyledTableCell>
      </StyledTableRow>
    </>
  );
}

function ItemTableHead() {
  return (
    <>
      <TableRow>
        <StyledTableCell align="left" style={{ width: '12%' }}>
          Barkod
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '60%' }}>
          İlaç Adı
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '11%' }}>
          Üretici
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '7%' }}>
          İlaç Kodu
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '12%' }}>
          Reçete Tipi
        </StyledTableCell>
      </TableRow>
    </>
  );
}

export default function Icd10() {
  return (
    <>
      <Icd10Medicine<Medicine>
        searchService={searchMedicine}
        fetchData={getMedicinePage}
        ListTableBody={ItemTableBody}
        ListTableHead={ItemTableHead}
        searchPlaceholder="İlaç Adı veya Üretici Giriniz"
      />
    </>
  );
}
