import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { saveTrainingMainGroup, TrainingMainGroupModel, updateTrainingMainGroup } from 'api/hs/definitions/trainings/training-main-group';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: TrainingMainGroupModel;
}

export default function TrainingMainGroupForm({ open, onClose, data }: Props) {
  const classes = useStyles();
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await saveTrainingMainGroup(values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await updateTrainingMainGroup({
            id: data.id,
            ...values,
          });
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, onClose, showMessage]
  );

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    await defer();

    if (data) {
      setValue('description', data.description);
      setValue('name', data.name);
      trigger();
    } else {
      reset({
        description: '' as any,
        name: '' as any,
      });
    }
  }, [data, setValue, reset, trigger, open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 450 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>{data?.id ? 'Konu Ana Grubu Güncelle' : 'Konu Ana Grubu Ekle'}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
          <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} lg={12}>
                <TextField required name="name" label="Grup Adı" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <TextField name="description" label="Grup Açıklama" />
              </Grid>
              <Grid item md={12} className="text-right">
                <Button variant="contained" type="submit" color="primary" className="mr-2">
                  {data?.id ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
