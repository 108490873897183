import {
  alpha,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import DateDifferent from 'utils/DateDifferent';

import { EmergencyCaseTeamMemberDto, TrainingEmployeeView } from '../../../../api/client';
import { EmployeeSelectionCacheContext } from '../../../../Common/Form/EmployeeSelection/employee-selection.context';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { ResourceContext } from '../../../../context/resource.context';
import Avatar from '../../../../TransactionsAndLists/EmployeeList/Avatar';
import BaseSection from '../../../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import { useAsyncEffect, useDateFormat, useFetch } from '../../../../utils';
import { CaseTeamsDetailContext } from '../../case-teams-detail.context';
import AddButtonNewCaseTeamMembers from '../Add/AddButtonNewCaseTeamMembers';
import AddButtonNewCaseTeamTrainings from '../Add/AddButtonNewCaseTeamTrainings';

interface TrainingStatus {
  date: string;
  expireDate: string;
  trainingId: string;
  trainingName: string;
  plannedDate: string;
}

enum TrainingStatusDefinition {
  VALID,
  OUT_OF_DATE,
}

export default function CaseTeamsList() {
  const { emergencyCaseTeamMember, emergencyCaseTeams, emergencyCaseTeamTraining, employeeIdTrainingMap } = useContext(
    CaseTeamsDetailContext
  );
  const { loadEmployees, names, avatars } = useContext(EmployeeSelectionCacheContext);
  const { emergencyCaseDefinition, trainingDefinitions } = useContext(ResourceContext);
  useAsyncEffect(async () => {
    if (!emergencyCaseTeamMember) {
      return;
    }
    await defer();

    loadEmployees(...emergencyCaseTeamMember?.map(employeeData => employeeData?.employeeId));
  }, [emergencyCaseTeamMember]);

  function EmployeesTrainingData(employeeData: EmergencyCaseTeamMemberDto): JSX.Element {
    const employeeTraining: TrainingEmployeeView[] = employeeIdTrainingMap?.get(employeeData?.employeeId);
    const trainingDefinitionIds = emergencyCaseTeamTraining?.reduce((acc, item) => [...acc, item?.trainingDefinitionId], []);
    // tslint:disable-next-line:no-shadowed-variable
    const { trainingDefinitions } = useContext(ResourceContext);
    const resultArr: TrainingStatus[] = [];
    trainingDefinitionIds.forEach(trDefId => {
      const result: TrainingStatus = {
        date: null,
        expireDate: null,
        trainingId: trDefId,
        trainingName: null,
        plannedDate: null,
      };
      employeeTraining?.forEach((emplTraining: TrainingEmployeeView) => {
        let isActive: boolean;
        emplTraining?.training.trainingDefinitionTrainings.forEach(trainingData => {
          if (trainingData.trainingDefinitionId === trDefId && !isActive) {
            if (emplTraining.training.expiredDate < new Date().toISOString()) {
              isActive = false;
            } else {
              isActive = true;
              result.trainingName = trainingData.trainingDefinition.name;
              result.expireDate = emplTraining.training.expiredDate ? emplTraining.training.expiredDate : null;
              result.date = emplTraining.training.completedDate ? emplTraining.training.completedDate : null;
              result.plannedDate = emplTraining.training.expiredDate ? null : emplTraining.training.plannedDate;
            }
          }
        });
      });
      resultArr.push(result);
    });

    return (
      <div style={{ width: '100%' }}>
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              ALMIŞ OLDUĞU EĞİTİMLER
            </ListSubheader>
          }>
          {resultArr.map((empTrainingData: TrainingStatus) => (
            <ListItem
              style={{
                opacity: `${empTrainingData?.expireDate ? '1' : '0.6'}`,
                color: `${
                  empTrainingData?.plannedDate
                    ? 'red'
                    : `${
                        DateDifferent(new Date().toISOString(), empTrainingData?.expireDate) < 0
                          ? 'red'
                          : DateDifferent(new Date().toISOString(), empTrainingData?.expireDate) <= 30
                          ? '#ffa733'
                          : '#00e676'
                      }`
                }`,
              }}
              key={empTrainingData?.trainingId}>
              <ListItemAvatar>
                <Icon className="icon-school" />
              </ListItemAvatar>
              <ListItemText
                primary={empTrainingData?.trainingName ?? findByKey(trainingDefinitions, 'id', empTrainingData?.trainingId)?.name}
                secondary={
                  empTrainingData.date ? (
                    <div style={{ display: 'flex' }}>
                      <p>
                        {' '}
                        {`Tamamlanma Tarihi : ${new Date(empTrainingData?.date).toLocaleDateString('tr-TR')}`}
                        {` - Geçerlilik Tarihi : ${new Date(empTrainingData?.expireDate).toLocaleDateString('tr-TR')}`}
                      </p>
                    </div>
                  ) : (
                    `Planlama Tarihi : ${new Date(empTrainingData?.plannedDate).toLocaleDateString('tr-TR')}`
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  return (
    <div className="p-2">
      <IndicatorWrapper data={emergencyCaseTeamMember} loading={false} errorMessage="Listelenecek  Personel Bulunamadı">
        <Grid container spacing={1}>
          {emergencyCaseTeamMember?.map(employeeItem => (
            <Grid key={employeeItem?.employeeId} item xs={12} sm={6} md={6} lg={6}>
              <BaseSection
                key={employeeItem?.employeeId}
                icon="icon-people"
                title={`${names[employeeItem?.employeeId]}  - Katılma Tarihi : ${employeeItem?.joinDate
                  ?.split('-')
                  ?.reverse()
                  ?.join('-')} ${
                  employeeItem?.quitDate ? `- Ayrılma Tarihi : ${employeeItem?.quitDate?.split('-')?.reverse()?.join('-')}` : ''
                } `}>
                <div key={employeeItem?.employeeId} className="mt-2">
                  <Grid container>
                    <Grid className="mt-2" item xs={12} lg={12}>
                      {EmployeesTrainingData(employeeItem)}
                    </Grid>
                  </Grid>
                </div>
              </BaseSection>
            </Grid>
          ))}
        </Grid>
        {/*</List>*/}
      </IndicatorWrapper>
      <AddButtonNewCaseTeamMembers />
      <IndicatorWrapper data={emergencyCaseTeamTraining} loading={false} errorMessage="Listelenecek Eğitim Bulunamadı.">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Ekibin Alması Gereken Eğitimler
            </ListSubheader>
          }>
          <Grid container>
            {emergencyCaseTeamTraining?.map(training => (
              <Grid key={training?.id} item xs={12} sm={6} md={6} lg={6}>
                <ListItem key={training?.id} button>
                  <ListItemIcon>
                    <Icon className="icon icon-school" />
                  </ListItemIcon>
                  <ListItemText primary={findByKey(trainingDefinitions, 'id', training.trainingDefinitionId)?.name} />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </List>
      </IndicatorWrapper>
      <AddButtonNewCaseTeamTrainings />
    </div>
  );
}
