/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    avatarPath?: string;
    addDate?: string;
    addUserId?: string;
    name?: string;
    address?: string;
    gender?: number;
    birthDate?: string;
    birthPlace?: string;
    email?: string;
    gsmNumber?: string;
    countyId?: number;
    bloodDonation?: number;
    bloodType?: number;
    nid?: string;
    cityId?: number;
    status?: EmployeeDto.status;
    telephone?: string;
    managerId?: string;
    numberOfChildren?: string;
    educationalStatus?: number;
    retirementStatus?: number;
    generalDescription?: string;
    diseaseHistoryDescription?: string;
    maritalStatus?: number;
    profession?: string;
    occupationalDiseaseDescription?: string;
    professionalBackgroundDescription?: string;
    complaintHistoryDescription?: string;
    workingType?: number;
    quitDate?: string;
    intercom?: string;
    departmentId?: string;
    businessEmail?: string;
    hireDate?: string;
    positionId?: string;
    businessGsmNumber?: string;
    stationId?: string;
    locationDefinitionId?: string;
    ssiNumber?: string;
    registryNumber?: string;
    companyId?: string;
    businessTelephone?: string;
    title?: string;
}

export namespace EmployeeDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
