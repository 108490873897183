import { Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { NearMissControllerService } from '../../../../../api/client';
import { nearMissFileUpload } from '../../../../../api/hs/accident/accident';
import Button from '../../../../../Common/Button';
import IndicatorWrapper from '../../../../../Common/IndicatorWrapper';
import { DialogContext } from '../../../../../context/dialog.context';
import { downlaodFile, openFile, useFetch, useXhr } from '../../../../../utils';
import { NearMissDetailContext } from '../../near-miss-detail.context';

export default function NearMissFiles() {
  const [loading, setLoading] = useState(true);
  const { nearMissId } = useParams<{ nearMissId: string }>();
  const { openFileDialog, openWarnDialog } = useContext(DialogContext);
  const { reload } = useContext(NearMissDetailContext);
  const { data, fetch } = useFetch(() => NearMissControllerService.getNearMissFiles(nearMissId), [], { setLoading });

  const deleteFileReqFile = useXhr(
    async (id: string) => {
      await NearMissControllerService.deleteNearMissFile(id);
      await fetch();
    },
    'Seçili Dosya Silindi',
    'Dosya silinirken bir hata oluştu',
    [fetch, reload]
  );

  const removeFileShow = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.originalFileName} dosyasını silmek istediğinize emin misiniz?` });

      if (!submitted) {
        return;
      }

      await deleteFileReqFile(item?.id);
    },
    [deleteFileReqFile, openWarnDialog]
  );

  const uploadFiles = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => nearMissFileUpload(nearMissId, file)));
      await fetch();
    },
    'Dosyaları yüklendi',
    err => err,
    [nearMissId]
  );

  const uploadFile = useCallback(async () => {
    const files = await openFileDialog({ title: 'Dosya Yükle', maxCount: Number.MAX_SAFE_INTEGER });

    if (!files) {
      return;
    }

    await uploadFiles(files);
    await reload();
  }, [openFileDialog, reload, uploadFiles]);

  return (
    <>
      <header className="text-right mb-1 mr-1">
        <Button onClick={uploadFile} variant="contained" startIcon={<AddIcon />} color="primary" className="ml-1">
          Dosya Ekle
        </Button>
      </header>
      <IndicatorWrapper data={data} loading={loading} errorMessage="Listelenecek  Dosya Bulunamadı">
        <List key="accident-detail-files">
          {data?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemAvatar>
                  <Icon style={{ color: 'gray' }} fontSize="medium" className="icon-file-document" />
                </ListItemAvatar>
                <ListItemText primary={item?.originalFileName} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => downlaodFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton onClick={() => removeFileShow(item)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => openFile(`/saglik-gozetimi/${item?.path}/${item?.name}`, item?.originalFileName)}
                    edge="end"
                    aria-label="delete">
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </IndicatorWrapper>
    </>
  );
}
