import { Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { ExaminationListContentResult } from 'api/hs/TransactionAndLists/ExaminationList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import AlertInformation from '../../../Common/AlertInformation';
import { ResourceContext } from '../../../context/resource.context';
import { dateDisplayFormat } from '../../../utils';
import Avatar from '../../EmployeeList/Avatar';

function ActionComponent({ row }: { row: ExaminationListContentResult }) {
  const { examinationDefinitions } = useContext(ResourceContext);
  let value;
  if (row?.ibysExaminationView?.requestState === 'SENT') {
    value = (
      <Tooltip placement="top" title="Başarılı" aria-label="success">
        <AlertInformation message="Başarılı." severity="success" alert="Başarılı." />
      </Tooltip>
    );
  }
  if (row?.ibysExaminationView?.requestState === 'ON_STAND_BY') {
    value = (
      <Tooltip placement="top" title="Bakanlık Tarafından İşleniyor" aria-label="warning">
        <AlertInformation message="Bakanlık Tarafından İşleniyor." severity="warning" alert="Bakanlık Tarafından İşleniyor." />
      </Tooltip>
    );
  }
  if (row?.ibysExaminationView?.requestState === 'INCORRECT_REQUEST') {
    value = (
      <Tooltip placement="top" title={row.ibysExaminationView.processResultMessage} aria-label="error">
        <Alert style={{ justifyContent: 'center' }} severity="error">
          Muayene Gönderilmedi.
        </Alert>
      </Tooltip>
    );
  }
  if (row?.ibysExaminationView?.requestState === 'NOT_SENT') {
    value = (
      <Tooltip placement="top" title="Henüz Bakanlığa Gönderim yapılmadı." aria-label="error">
        <Alert style={{ justifyContent: 'center' }} severity="warning">
          Muayene Gönderilmedi.
        </Alert>
      </Tooltip>
    );
  }
  if (
    row?.ibysExaminationView === null &&
    findByKey(examinationDefinitions, 'id', row?.examinationDefinitionId)?.type === 'HS_EXAMINATION'
  ) {
    value = <AlertInformation message="Muayene Gönderilmemiştir." severity="warning" alert="Muayene Gönderilmemiştir." />;
  }
  return <>{value}</>;
}

export const CELL: TableColumn<ExaminationListContentResult>[] = [
  {
    id: 'avatarPath',
    render(row): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: false,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '10%',
  },
  {
    id: 'examinationTypeName',
    defaultVisibility: true,
    label: 'Tür',
    sortable: true,
    width: '20%',
  },
  {
    id: 'date',
    defaultVisibility: true,
    label: 'Tarih',
    sortable: true,
    width: '10%',
    render(row: ExaminationListContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.date)}</>;
    },
  },
  {
    id: 'opinion',
    defaultVisibility: false,
    label: 'Kanaat',
    sortable: true,
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
  },
  {
    id: 'departmentName',
    defaultVisibility: true,
    label: 'Departman',
    sortable: true,
  },
  {
    id: 'protocolNumber',
    defaultVisibility: true,
    label: 'Protokol No',
    sortable: true,
  },
  {
    id: 'ibysExaminationView',
    label: 'Gönderim Durumu',
    sortable: false,
    render(row: ExaminationListContentResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Son Değiştiren',
    sortable: true,
  },
];
