import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React from 'react';

import { showWidget } from '../../../api/hs/dashboard/widget/widget';
import { WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import colors from '../colors';

interface Props {
  reload: void;
  type: WidgetType;
  id: string;
}

export default (props: Props) => {
  const widgets = {
    [WidgetType.REPORT_REPORT]: 'Raporlu Personel',
    [WidgetType.REFERRAL]: 'Sevkli Personel',
    [WidgetType.NEAR_MISS]: 'Ramak Kala',
    [WidgetType.PERIODIC_EXAMINATION]: 'Periyodik Muayene',
    [WidgetType.VACCINE]: 'Aşı',
    [WidgetType.PREGNANCY]: 'Gebe',
    [WidgetType.ACTION]: 'Aksiyon',
    [WidgetType.EMPLOYEE]: 'Personel',
    [WidgetType.ACCIDENT]: 'İş Kazası',
    [WidgetType.TRAINING]: 'Eğitim',
    [WidgetType.LOST_DAY]: 'İş Gün Kayıpları',
  };

  return (
    <Card className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.passive }}>
      <CardContent style={{ paddingTop: 3, paddingBottom: 3 }}>
        <Grid container>
          <Grid item sm={10}>
            <h5>{widgets[props.type]}</h5>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={() => showWidget(props.id).then(props.reload)}>
              <Visibility />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
