/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GuestDto } from '../models/GuestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class GuestControllerService {

    /**
     * @param id
     * @returns GuestDto OK
     * @throws ApiError
     */
    public static getGuest(
        id: string,
    ): CancelablePromise<GuestDto> {
        return __request({
            method: 'GET',
            path: `/guests/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns GuestDto OK
     * @throws ApiError
     */
    public static updateGuest(
        id: string,
        requestBody: GuestDto,
    ): CancelablePromise<GuestDto> {
        return __request({
            method: 'PUT',
            path: `/guests/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllGuest(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/guests/${id}`,
        });
    }

    /**
     * @returns GuestDto OK
     * @throws ApiError
     */
    public static getAllGuests1(): CancelablePromise<Array<GuestDto>> {
        return __request({
            method: 'GET',
            path: `/guests`,
        });
    }

    /**
     * @param requestBody
     * @returns GuestDto OK
     * @throws ApiError
     */
    public static saveGuest1(
        requestBody: GuestDto,
    ): CancelablePromise<GuestDto> {
        return __request({
            method: 'POST',
            path: `/guests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids
     * @returns GuestDto OK
     * @throws ApiError
     */
    public static getGuests(
        ids?: Array<string>,
    ): CancelablePromise<Array<GuestDto>> {
        return __request({
            method: 'GET',
            path: `/guests/list`,
            query: {
                'ids': ids,
            },
        });
    }

}