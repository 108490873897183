import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { PositionsIbysWorkConditionsPayload } from './positions-ibys-work-conditions.payload';
import { PositionsIbysWorkConditionsResult } from './positions-ibys-work-conditions.result';

export async function getPositionsWorkConditions(employeeId: string): Promise<PositionsIbysWorkConditionsResult[]> {
  let res;
  try {
    res = await HSApi.get<PositionsIbysWorkConditionsResult[]>(`/positions/${employeeId}/work-conditions`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getEmployeeWorkConditions(employeeId: string): Promise<PositionsIbysWorkConditionsResult[]> {
  let res;
  try {
    res = await HSApi.get<PositionsIbysWorkConditionsResult[]>(`/employee/${employeeId}/work-conditions/position-merged`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function savePositionsWorkConditions(body: PositionsIbysWorkConditionsPayload[]): Promise<PositionsIbysWorkConditionsResult> {
  const [err, res] = await tryCatch(HSApi.post(`/positions/work-conditions`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updatePositionsWorkConditions(
  id: string,
  body: PositionsIbysWorkConditionsPayload[]
): Promise<PositionsIbysWorkConditionsResult> {
  const [error, res] = await tryCatch(HSApi.put<PositionsIbysWorkConditionsResult>(`/positions/${id}/work-conditions`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function updateEmployeeWorkConditions(
  id: string,
  body: PositionsIbysWorkConditionsPayload[]
): Promise<PositionsIbysWorkConditionsResult> {
  const [error, res] = await tryCatch(HSApi.put<PositionsIbysWorkConditionsResult>(`/employee/${id}/work-conditions`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deletePositionsWorkConditions(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/positions/${id}/work-conditions`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
