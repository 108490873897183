import {IconButton, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import React, {useCallback, useContext, useState} from 'react';

import {IbysTrainingDetailResult} from 'api/hs/trainings/ibys-trainings';
import {TableColumn} from 'Common/Table/Raw/table-column.model';
import {useDateFormat, useXhr} from 'utils';

import {deleteIbysTraining} from '../../../../api/hs/trainings/ibys-trainings';
import {HSTableContext} from '../../../../Common/Table/HSTable/hs-table.context';
import {DialogContext} from '../../../../context/dialog.context';
import {MinistryListContext} from '../../ministry-list-context';
import UnsuccessfulTrainingInfo from "../UnsuccessfulTrainingInfo";

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}

function MinistryStatus({ row }: { row: IbysTrainingDetailResult }) {
  return StatusIconText(row);
}

function StatusIconText(row: IbysTrainingDetailResult): JSX.Element {
  if (row?.ibysTrainingDefinitionEmployees) {
    let success = 0;
    let total = 0;
    let unSuccess = 0;
    row?.ibysTrainingDefinitionEmployees.forEach(tde => {
      total ++
      if(tde.resultCode == 1) {
        success ++;
      }
      if(tde.resultCode == 0) {
        unSuccess ++;
      }
    })
    if(total == 0) {
      return <>{'% 0'}</>
    }
    return <>{`%${Math.floor((success / total) * 100)} (${success}/${total})`} <UnsuccessfulTrainingInfo data={row} unsuccessfulTrainingCount={unSuccess}/></>;

  }
  return <>{'IBYS Gönderilmedi'}</>;
}

function ActionComponent({ row }: { row: IbysTrainingDetailResult }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { openWarnDialog } = useContext(DialogContext);
  const { setSelectedData, setEditDialogOpened, setPinCodeDialogOpened } = useContext(MinistryListContext);
  const { refresh } = useContext(HSTableContext);

  const deleteFileReq = useXhr(
    async (id: string) => {
      await deleteIbysTraining(id);
      await refresh();
    },
    'Seçili Eğitim Silindi',
    'Eğitim silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `${item?.title} isimli eğitimi silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.id);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const pinFormOpen = useCallback(
    item => {
      setPinCodeDialogOpened(true);
      setSelectedData(item);
      closeMenu();
    },
    [setSelectedData, setPinCodeDialogOpened, closeMenu]
  );

  return (
    <div key={row?.id}>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        {row?.ibysServerId ? null : (
          <MenuItem id={row?.id} onClick={() => pinFormOpen(row)}>
            <ListItemIcon>
              <SendIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">IBYS</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export const COLUMNS: TableColumn<IbysTrainingDetailResult>[] = [
  { id: 'title', label: 'Eğitim Adı', width: '15%' },
  {
    id: 'date',
    width: '15%',
    label: 'Tamamlanma Tarihi',
    render(row): JSX.Element {
      return <DateField date={new Date(row.date)} />;
    },
    defaultVisibility: true,
  },
  {
    id: Symbol(),
    label: 'IBYS Durum',
    width: '7%',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <MinistryStatus key={row?.id} row={row} />;
    },
  },
  {
    id: 'ibysTrainingEmployees',
    width: '5%',
    label: 'Eğitim Sayısı',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <div>{row.ibysTrainingDefinitionEmployees.length}</div>;
    },
  },
  { id: 'trainerNid', label: 'Eğitimci T.C.', width: '10%' },
  {
    id: 'isFaceToFace',
    label: 'Eğitim Yeri',
    width: '10%',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <div>{row.isFaceToFace ? 'Yüz yüze' : 'Uzaktan'}</div>;
    },
  },
  {
    id: 'trainerType',
    label: 'Eğitimci Tipi',
    width: '10%',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <div>{row.trainerType === 2 ? 'İşyeri Hekimi' : 'İş Güvenliği Uzmanı'}</div>;
    },
  },
  {
    id: 'atWorkplace',
    label: 'Eğitim Yeri',
    width: '10%',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <div>{row.atWorkplace ? 'İş Yerinde' : 'İş Yeri Dışında'}</div>;
    },
  },
  {
    id: Symbol(),
    label: 'İşlemler',
    width: '5%',
    render(row: IbysTrainingDetailResult): JSX.Element {
      return <ActionComponent key={row?.id} row={row} />;
    },
  },
];
