export interface GenericUserEvent {
  employeeId: string;
  employeeName: string;
  date: Date;
  color: string;
  durationOfMinutes: any;
  userEventType: UserEventType;
  description: string;
  label: string;
  referenceTableId: string;
  isDone: boolean;
}

export interface EventPayload {
  id?: string;
  title: string;
  description: string;
  color?: string;
  isPublic: boolean;
  startDate: string;
  startTime?: string;
  endDate: string;
  endTime?: string;
}

// bunu yaptigim icin uzgunum. backend de tek servise indirgenecek
export interface ReschedulePayload {
  startDate: string;
  endDate: string;
  date: string;
  plannedDate: string;
  nextExaminationDate: string;
}

export interface EventQueryPayload {
  startDate: string;
  endDate: string;
  userEventTypes: UserEventType[];
}

export enum UserEventType {
  PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
  VACCINE = 'VACCINE',
  TRAINING = 'TRAINING',
  USER_DEFINED = 'USER_DEFINED',
  COMMITTEE_MEETING = 'COMMITTEE_MEETING',
  PREGNANCY = 'PREGNANCY',
  ACTION = 'ACTION',
  DRILL = 'DRILL',
  PPE = 'PPE',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
}
