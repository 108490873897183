import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import { TrainingDetailContext } from '../training-detail.context';
import TrainerForm from './TrainerForm';

export default function TrainerDialogForm() {
  const { trainerDialogOpened, setTrainerDialogOpened } = useContext(TrainingDetailContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        refresh();
      }
      setTrainerDialogOpened(false);
    },
    [refresh, setTrainerDialogOpened]
  );

  return <TrainerForm open={trainerDialogOpened} onClose={closeDialog} />;
}
