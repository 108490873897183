import React, { PropsWithChildren } from 'react';

import TableContextProvider from '../Raw/TableContextProvider';
import PaginationContextProvider from './PaginationContextProvider';
import { TableWithPaginationProps } from './table-with-pagination.props';

export default function PaginationAndTableContextProvider<T>({
  page,
  size,
  totalPage,
  onSizeChange,
  onPageChange,
  children,
  ...tableProps
}: PropsWithChildren<TableWithPaginationProps<T>>) {
  return (
    <PaginationContextProvider {...{ page, size, totalPage, onSizeChange, onPageChange }}>
      <TableContextProvider {...tableProps}>{children}</TableContextProvider>
    </PaginationContextProvider>
  );
}
