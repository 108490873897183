import { Button, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { patchExaminationById } from 'api/hs/examination';
import { ExaminationResultItem } from 'api/hs/examination/examinations.result';
import Dialog from 'Common/Dialog';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';

import { AlertContext } from '../../../../../context/alert.context';
import { useAsyncEffect } from '../../../../../utils';
import { ExaminationDetailContext } from './examination-detail.context';
const useStyles = makeStyles(() => ({
  protocolNoText: {
    fontSize: '12px',
    color: 'red',
  },
}));
interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: ExaminationResultItem;
}

export default function EditExaminationDialog({ open, onClose, data }: Props) {
  const classes = useStyles();
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);
  const { fetchExamination } = useContext(ExaminationDetailContext);
  useAsyncEffect(async () => {
    if (data) {
      await defer();
      formRef?.current?.methods?.setValue('protocolNumber', data?.protocolNumber.yearPrefix + '' + data?.protocolNumber?.number);
      formRef?.current?.methods?.setValue('date', data?.date);
      formRef?.current?.methods?.trigger();
    }
  }, [data, open]);

  const submit = useCallback(
    async values => {
      const payload = {
        date: values?.date,
        protocolNumber: {
          yearPrefix: values?.protocolNumber?.substr(0, 4),
          number: values?.protocolNumber?.substr(4, 7),
        },
      };

      try {
        await patchExaminationById(data?.id, payload);
        showMessage('Güncelleme işlemi başarılı', 'success', 'info');
        await fetchExamination();
        onClose();
      } catch (e) {
        showMessage('Güncelleme sırasında hata oluştu.', 'error', 'info');
      }
    },
    [data, showMessage, fetchExamination, onClose]
  );

  return (
    <>
      <Dialog color="primary" title="Muayene Güncelle" opened={open} onClose={onClose}>
        <Form onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid item xs={12}>
              <TextField type="text" name="protocolNumber" label="Protokol Numarası" />
              <span className={classes.protocolNoText}>*Protokol Numarası ilk 4 karakteri yıl olacak şekilde giriniz</span>
            </Grid>
            <Grid item xs={12}>
              <DateInput type="date" name="date" label="Tarih" />
            </Grid>
          </Grid>
          <footer className="px-2 flex-justify">
            <Button color="secondary" className="mr-1" fullWidth type="submit" variant="contained">
              Güncelle
            </Button>
            <Button onClick={onClose} fullWidth type="button" variant="contained">
              Vazgeç
            </Button>
          </footer>
        </Form>
      </Dialog>
    </>
  );
}
