import React from 'react';

import ClassificationAdd from './Add';
import ClassificationList from './List';

export default function Index() {
  return (
    <>
      <ClassificationAdd />
      <ClassificationList />
    </>
  );
}
