import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { deleteAdminDisability, DisabilityResult, getAdminDisability } from 'api/hs/definitions/health-definitions/disability';
import AdminDisabilitEditDialog from 'Common/Forms/Definitions/HealthDefinition/Disability/AdminDisabilitEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminDisability() {
  return (
    <BaseDefinitionPage<DisabilityResult>
      listLabelPropName="name"
      fetchData={getAdminDisability}
      FormDialog={AdminDisabilitEditDialog}
      onDelete={deleteAdminDisability}
      Icon={<DomainIcon />}
    />
  );
}
