import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['tr', 'en'],
    whitelist: ['tr', 'en'],
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

// TODO: Dil Seçimi hazır olduğunda alttaki kodu kaldır
i18n.on('initialized', () => {
  i18n.changeLanguage('tr');
});

export default i18n;
