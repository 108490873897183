import { DisplayOption } from './display-option';

export const convertWithExistingMapByValueAndDateFieldName = (
  resultMap: any,
  data: any[],
  dateFieldName: string,
  fieldName: string,
  label: string,
  displayOption: DisplayOption
): any[] => {
  data.forEach(d => {
    const date = new Date(Reflect.get(d, dateFieldName));
    const key = setDateKey(resultMap, date, displayOption);
    addFieldValue(resultMap[key], d, fieldName, label);
  });
  return resultMap;
};

export const setDateKey = (resultMap: any, date: Date, displayOption: DisplayOption) => {
  let key: string;
  if (displayOption === DisplayOption.DAILY) {
    key = date.toISOString().slice(0, 10);
  } else if (displayOption === DisplayOption.MONTHLY) {
    key = date.toISOString().slice(0, 7);
  } else if (displayOption === DisplayOption.ANNUALLY) {
    key = date.toISOString().slice(0, 4);
  }
  if (!resultMap[key]) {
    resultMap[key] = {
      // date: date,
      date: key,
    };
  }
  return key;
};

export const addFieldValueArr = (resultItem: any, data: any[], fieldName: string, label: string) => {
  data.forEach(d => {
    addFieldValue(resultItem, d, fieldName, label);
  });
};

export const addFieldValue = (item: any, data: any, fieldName: string, label: string) => {
  if (!data) {
    return;
  }
  const indexOfArr: number = fieldName.indexOf('[].');
  const indexOfDot: number = fieldName.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = fieldName.substr(0, indexOfArr);
    const newFieldName = fieldName.substr(indexOfArr + 3);
    addFieldValueArr(item, Reflect.get(data, fragment), newFieldName, label);
  } else if (indexOfDot > 0) {
    const fragment = fieldName.substr(0, indexOfDot);
    const newFieldName = fieldName.substr(indexOfDot + 1);
    addFieldValue(item, Reflect.get(data, fragment), newFieldName, label);
  } else {
    const value = Reflect.get(data, fieldName);
    if (!item[label]) {
      item[label] = 0;
    }
    item[label] += value;
  }
};
