/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationRequest = {
    employeeIds?: Array<string>;
    notificationReferenceId?: string;
    notificationType?: NotificationRequest.notificationType;
}

export namespace NotificationRequest {

    export enum notificationType {
        REFERRAL = 'REFERRAL',
        REST_REPORT = 'REST_REPORT',
        PRESCRIPTION = 'PRESCRIPTION',
        OPINION = 'OPINION',
        COMMITTEE_MEETING = 'COMMITTEE_MEETING',
    }


}
