import { Grid } from '@material-ui/core';
import React from 'react';

import { MatrixPossibilityDefinitionControllerService } from 'api/client';
import { MatrixPossibilityDefinitionDto } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateRiskAssessmentsMatrixPossibilityDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: MatrixPossibilityDefinitionDto;

  onClose(success: boolean): void;
}

export default function MatrixPossibilityDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<MatrixPossibilityDefinitionDto>
      open={open}
      onClose={onClose}
      onSave={MatrixPossibilityDefinitionControllerService.saveMatrixPossibilityDefinitions}
      onUpdate={updateRiskAssessmentsMatrixPossibilityDefinitionUpdate}
      data={data}
      createTitle="Matris Olasılık Ekleme"
      updateTitle="Matris Olasılık Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="İsmi" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="value" label="Değeri" />
      </Grid>
    </BaseDefinitionForm>
  );
}
