import React from 'react';

import AddEditButtun from './Add';
import DialogFormOpinion from './DialogFormOpinion';
import ListOpinion from './ListOpinion';

export default function Index() {
  return (
    <>
      <ListOpinion />
      <DialogFormOpinion />
      <AddEditButtun />
    </>
  );
}
