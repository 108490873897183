import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import TextField from 'Common/Form/TextField';
import { useXhr } from 'utils';

import Dialog from './Dialog';
import { HistoryContextType, ListItem, StoryContext } from './history.context';
import List from './List';

interface Props<T extends ListItem = ListItem> {
  fetch(): Promise<T[]>;
  save(item: Omit<T, 'id'>): Promise<any>;
  update(item: T): Promise<any>;
  remove(id: T['id']): Promise<any>;
  title: string;
  buttonLabel?: string;
  form?: JSX.Element;
}

export default function History<T extends ListItem = ListItem>({
  fetch: fetchFunc,
  save: saveFunc,
  update: updateFunc,
  title,
  remove: removeFunc,
  buttonLabel = title + ' Öyküsü Ekle',
  form = (
    <Grid item md={12}>
      <TextField rows={2} label="Açıklama" name="description" required />
    </Grid>
  ),
}: Props<T>) {
  const [list, setList] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<T>(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setList(await fetchFunc());
    setLoading(false);
  }, [fetchFunc]);

  const save = useXhr(
    async (payload: Omit<T, 'id'>) => {
      setLoading(true);
      await saveFunc(payload);
      await fetch();
    },
    'Yeni Kayıt Oluşturuldu',
    'Kayıt sırasında hata oluştu.',
    [saveFunc, fetch]
  );

  const update = useXhr(
    async (payload: T) => {
      setLoading(true);
      await updateFunc(payload);
      await fetch();
    },
    'Kayıt güncellendi.',
    'Kayıt güncelleme sırasında hata oluştu.',
    [updateFunc, fetch]
  );

  const remove = useXhr(
    async (id: ListItem['id']) => {
      setLoading(true);
      await removeFunc(id);
      await fetch();
    },
    'Kayıt başarıyla silindi',
    'Kayıt silme işleminde hata oluştu',
    [removeFunc, fetch]
  );

  const context = useMemo<HistoryContextType<T>>(
    () => ({ list, loading, dialogOpened, setDialogOpened, selectedItem, setSelectedItem, title, buttonLabel, save, update, remove, form }),
    [list, loading, dialogOpened, selectedItem, title, save, update, remove, buttonLabel, form]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <StoryContext.Provider value={context}>
      <List />
      <Dialog />
    </StoryContext.Provider>
  );
}
