import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { TrainingDefinitionsModel } from './training-definitions.model';
import { TrainingDefinitionsPayload } from './training-definitions.payload';
import { TrainingDefinitionsResult } from './training-definitions.result';

export async function getTrainingDefinitions(): Promise<TrainingDefinitionsModel[]> {
  const [error, res] = await tryCatch(HSApi.get<TrainingDefinitionsResult[]>(`/firms/training-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data.map(item => new TrainingDefinitionsModel(item));
}

export async function saveTrainingDefinitions(body: TrainingDefinitionsPayload): Promise<TrainingDefinitionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/training-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateTrainingDefinitions({
  id,
  ...body
}: MakeRequired<Partial<TrainingDefinitionsResult>, 'id'>): Promise<TrainingDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<TrainingDefinitionsResult>(`/firms/training-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteTrainingDefinitions(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/training-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
