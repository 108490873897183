import React, { useCallback, useContext } from 'react';

import { HealthInsurancesContext } from '../../health-insurances.context';
import HealthInsurancesForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(HealthInsurancesContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <HealthInsurancesForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
