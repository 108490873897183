import { tryCatch } from '@thalesrc/js-utils';

import { dateSort } from '../../../../../utils';
import { ReferralResult } from '../../../definitions/health-definitions/referral';
import { EmployeeImportedFilesResult } from '../../../file-uploads/employee-imported-files';
import HSApi from '../../../HSApi';
import { PpeOutputsContentResult } from '../../../ppe/ppe-outputs';
import { PpeAssigmentEmployeeResult } from './ppe-assigment-employee.result';
import { PpeOutputsGroupsEmployeeResult } from './ppe-outputs-groups-employee.result';

export async function getPpeAssigmentEmployee(employeeId: string): Promise<PpeAssigmentEmployeeResult[]> {
  let res;
  try {
    res = await HSApi.get<PpeAssigmentEmployeeResult[]>(`/ppe-assignments?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function getPpeOutputsListEmployee(employeeId: string, page = 0, size = 10000): Promise<PpeOutputsContentResult> {
  const payload = {
    filter: {
      hasRefund: true,
      employeeIds: [employeeId],
    },
    pagingAndSortingRequestParam: {
      page,
      size,
    },
  };

  const res = await HSApi.post<PpeOutputsContentResult[]>(`/ppe-outputs/views`, payload);

  return res.data.content;
}

export async function getPpeOutputsGroupsEmployee(employeeId: string): Promise<PpeOutputsGroupsEmployeeResult[]> {
  let res;
  try {
    res = await HSApi.get<PpeOutputsGroupsEmployeeResult[]>(`/ppe-output-groups/views?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return dateSort(res.data);
}

export async function updatePpeOutputRefund(body: any) {
  const [error, res] = await tryCatch(HSApi.put<ReferralResult>(`/ppe-outputs/refund`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function savePpeEmployeeImported(ppeOutputId: string, file: File): Promise<EmployeeImportedFilesResult> {
  const data = new FormData();
  data.set('imported-file', file);

  const [err, res] = await tryCatch(HSApi.post(`/ppe-outputs/${ppeOutputId}/imported-files`, data));
  if (err) {
    throw err.response.data.message;
  }

  return res.data;
}

export async function deletePpeFileUpload(id: EmployeeImportedFilesResult['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe-outputs/imported-files/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
