import {
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Alert, AlertTitle } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { RiskAssessmentHazardControllerService } from '../../../../api/client';
import { getAccidentIdActions } from '../../../../api/hs/accident/accident';
import Button from '../../../../Common/Button';
import { DialogContext } from '../../../../context/dialog.context';
import { ResourceContext } from '../../../../context/resource.context';
import CustomAction from '../../../../CustomAction';
import { useXhr } from '../../../../utils';
import { RiskAssessmentHazardContext } from '.././../risk-assessment-hazard.context';
import BalanceRisk from '../BalanceRisk';
import RiskAssessmentHazardFiles from '../RiskAssessmentHazardFiles';
import EditDialog from './EditDailog';

const fineKinneyColor = (value: number) => {
  if (value < 20) {
    return '#78c6a3';
  } else if (value > 20 && value <= 70) {
    return '#FFCC1D';
  } else if (value > 70 && value <= 200) {
    return '#E85D04';
  } else if (value > 200 && value <= 400) {
    return '#D00000';
  } else if (value > 400) {
    return '#9D0208';
  } else {
    return '#78c6a3';
  }
};

const matrixRiskColor = (value: number) => {
  if (value === 1) {
    return '#78c6a3';
  } else if (value >= 2 && value <= 6) {
    return '#78c6a3';
  } else if (value > 6 && value < 16) {
    return '#FFCC1D';
  } else if (value >= 16 && value <= 20) {
    return '#D00000';
  } else if (value > 20) {
    return '#9D0208';
  } else {
    return '#78c6a3';
  }
};

export default function AssigmentPositionList() {
  const { loading, riskAssessmentHazard, reload, setSelectedData, setEditDialogOpened } = useContext(RiskAssessmentHazardContext);
  const { hazardCategoryDefinition, hazardCurrentPrecautionDefinition } = useContext(ResourceContext);
  const { openWarnDialog } = useContext(DialogContext);
  const {
    matrixPossibilityDefinition: possibilityDefinitionMatrix,
    matrixHazardDamageDefinition: damageDefinitionMatrix,
    riskAssessmentMatrixScoreDefinition,
    fineKinneyHazardScoreDefinition,
    fineKinneyPossibilityDefinition: possibilityDefinitionFineKinney,
    fineKinneyHazardDamageDefinition: damageDefinitionFineKinney,
    fineKinneyHazardFrequencyDefinition: frequencyDefinitionFineKinney,
  } = useContext(ResourceContext);

  const onEdit = useCallback(
    selectData => {
      setEditDialogOpened(true);
      setSelectedData(selectData);
    },
    [setSelectedData, setEditDialogOpened]
  );

  const deleteFileReq = useXhr(
    async (id: string) => {
      await RiskAssessmentHazardControllerService.deleteRiskAssessmentHazard(id);
      await reload();
    },
    'Seçili Tehlike Kaydı Silindi',
    'Seçili Tehlike Kaydı silinirken bir hata oluştu',
    [reload]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu kaydını silmek istediğinize emin misiniz?` });
      if (!submitted) {
        return;
      }

      await deleteFileReq(item.id);
    },
    [deleteFileReq, openWarnDialog]
  );

  return (
    <IndicatorWrapper loading={loading} data={riskAssessmentHazard} errorMessage="Tehlike Kayıtı Bulunamadı.">
      <List id="riskAssessmentHazard">
        <Grid container spacing={1}>
          {riskAssessmentHazard?.map((item, index) => (
            <Grid key={index} item md={12} sm={12} lg={12} xl={12} xs={12}>
              <Paper className="pb-1 mb-2" style={{ marginBottom: '5px' }} elevation={8}>
                <div style={{ display: 'flex' }}>
                  <IconButton className="text-italic" aria-label="delete">
                    {index + 1} . Tehlike
                  </IconButton>
                  <div style={{ width: '100%' }} className="text-right">
                    <Button
                      onClick={() => onEdit(item)}
                      variant="outlined"
                      startIcon={<EditIcon />}
                      color="secondary"
                      className="ml-1 mt-1">
                      Düzenle
                    </Button>
                    <Button onClick={() => removeFile(item)} startIcon={<DeleteIcon />} color="error" className="ml-1 mt-1 mr-2">
                      Sİl
                    </Button>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <Alert
                    style={{
                      backgroundColor: `${
                        item?.riskAssessmentFineKinneyScore
                          ? fineKinneyColor(
                              +findByKey(fineKinneyHazardScoreDefinition, 'id', item?.riskAssessmentFineKinneyScore?.hazardScoreId)?.score
                            )
                          : matrixRiskColor(
                              +findByKey(riskAssessmentMatrixScoreDefinition, 'id', item?.riskAssessmentMatrixScore?.hazardScoreId)?.score
                            )
                      }`,
                    }}
                    className="m-1"
                    variant="standard"
                    icon={false}
                    severity="info">
                    <AlertTitle style={{ fontWeight: 'bold' }}>
                      Risk Değerlendirme Skoru
                      <Divider />
                    </AlertTitle>
                    <Grid container spacing={1}>
                      {item?.riskAssessmentFineKinneyScore ? (
                        <>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="subtitle1">
                              Olasılık :{' '}
                              {findByKey(possibilityDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardPossibilityId)
                                ?.value +
                                ' - ' +
                                findByKey(possibilityDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardPossibilityId)
                                  ?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="subtitle1">
                              Sıklık :{' '}
                              {findByKey(frequencyDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardFrequencyId)
                                ?.value +
                                ' - ' +
                                findByKey(frequencyDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardFrequencyId)
                                  ?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="subtitle1">
                              Şiddet :{' '}
                              {findByKey(damageDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardDamageId)?.value +
                                ' - ' +
                                findByKey(damageDefinitionFineKinney, 'id', item?.riskAssessmentFineKinneyScore?.hazardDamageId)?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={6} lg={6} sm={6}>
                            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                              Skor:{' '}
                              {findByKey(fineKinneyHazardScoreDefinition, 'id', item?.riskAssessmentFineKinneyScore?.hazardScoreId)?.score +
                                ' - ' +
                                findByKey(fineKinneyHazardScoreDefinition, 'id', item?.riskAssessmentFineKinneyScore?.hazardScoreId)?.label}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="subtitle1">
                              Olasılık :{' '}
                              {findByKey(possibilityDefinitionMatrix, 'id', item?.riskAssessmentMatrixScore?.hazardPossibilityId)?.value +
                                ' - ' +
                                findByKey(possibilityDefinitionMatrix, 'id', item?.riskAssessmentMatrixScore?.hazardPossibilityId)?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography variant="subtitle1">
                              Şiddet :{' '}
                              {findByKey(damageDefinitionMatrix, 'id', item?.riskAssessmentMatrixScore?.hazardDamageId)?.value +
                                ' - ' +
                                findByKey(damageDefinitionMatrix, 'id', item?.riskAssessmentMatrixScore?.hazardDamageId)?.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12} lg={12} sm={12}>
                            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                              Skor:{' '}
                              {findByKey(riskAssessmentMatrixScoreDefinition, 'id', item?.riskAssessmentMatrixScore?.hazardScoreId)?.score +
                                ' - ' +
                                findByKey(riskAssessmentMatrixScoreDefinition, 'id', item?.riskAssessmentMatrixScore?.hazardScoreId)?.label}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Alert>
                </div>
                <TableContainer style={{ width: '100%' }}>
                  <Table size="small" aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell width="50%" component="th" scope="row">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Tehlike Kategorisi :</span>{' '}
                          {findByKey(hazardCategoryDefinition, 'id', item?.hazardCategoryId)?.label}
                        </TableCell>
                        <TableCell width="50%" align="left">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Risk Açıklama :</span> {item?.riskDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width="50%" component="th" scope="row">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Kimler Etkilenir :</span> {item?.affected}
                        </TableCell>
                        <TableCell width="50%" align="left">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Öncelik Durumu :</span>{' '}
                          {findByKey(hazardCurrentPrecautionDefinition, 'id', item?.hazardCurrentPrecautionId)?.label}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width="50%" component="th" scope="row">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Risk Değerlendirme Tipi :</span>{' '}
                          <span style={{ fontWeight: 'bold', color: 'gray' }}>{item?.riskAssessmentType}</span>
                        </TableCell>
                        <TableCell width="50%" align="left">
                          <span style={{ fontWeight: 'bold', color: '#737373' }}>Açıklama :</span> {item?.description}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div key={item?.id} className="mt-2">
                  <CustomAction
                    baseFetcher={reload}
                    actionReferenceValue={'RISK_ASSESSMENT'}
                    referenceId={item?.id}
                    fetcher={async (page, size, sorting) =>
                      await getAccidentIdActions(
                        item?.id,
                        page,
                        size,
                        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
                      )
                    }
                  />
                </div>
                <div key={item?.id} className="text-right mr-1">
                  <BalanceRisk fetchRiskAssessmentHazard={reload} riskAssessmentHazardData={item} />
                </div>
                <div key={item?.id} className="mt-1">
                  <RiskAssessmentHazardFiles riskAssessmentHazardId={item?.id} />
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </List>
      <EditDialog />
    </IndicatorWrapper>
  );
}
