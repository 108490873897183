import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';

import { SelectOptionsContext } from 'context/select-options.context';

import { FirmUsersContext } from '../../firm-users.context';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default function SelectFirms() {
  const classes = useStyles();
  const { selectedFirmId, setSelectedFirmId } = useContext(FirmUsersContext);
  const { firms } = useContext(SelectOptionsContext);

  const handleChange = (event: any) => {
    setSelectedFirmId(event.target.value);
  };

  return (
    <Fragment>
      <FormControl className={`${classes.root}`} style={{ width: '100%' }}>
        <InputLabel id="selected-firm-label">Firma Adı</InputLabel>
        <Select id="firm-select" value={selectedFirmId} onChange={handleChange} MenuProps={{ classes: { paper: classes.menuPaper } }}>
          {firms?.map(({ text, value }, index) => (
            <MenuItem key={index} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
}
