import { Divider, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert, AlertTitle } from '@material-ui/lab';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { getRiskAssessmentHazardIdActions } from '../../../api/hs/risk-assessment';
import Button from '../../../Common/Button';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { ResourceContext } from '../../../context/resource.context';
import { useFetch } from '../../../utils';
import { RiskAssessmentHazardDto } from '../../api/client';
import BalanceRiskForm from './BalanceRiskForm';

const fineKinneyColor = (value: number) => {
  if (value < 20) {
    return '#78c6a3';
  } else if (value > 20 && value <= 70) {
    return '#FFCC1D';
  } else if (value > 70 && value <= 200) {
    return '#E85D04';
  } else if (value > 200 && value <= 400) {
    return '#D00000';
  } else if (value > 400) {
    return '#9D0208';
  } else {
    return '#78c6a3';
  }
};

const matrixRiskColor = (value: number) => {
  if (value === 1) {
    return '#78c6a3';
  } else if (value >= 2 && value <= 6) {
    return '#78c6a3';
  } else if (value > 6 && value < 16) {
    return '#FFCC1D';
  } else if (value >= 16 && value <= 20) {
    return '#D00000';
  } else if (value > 20) {
    return '#9D0208';
  } else {
    return '#78c6a3';
  }
};
interface Props {
  riskAssessmentHazardData: RiskAssessmentHazardDto;
  fetchRiskAssessmentHazard: any;
}
export default function BalanceRisk({ riskAssessmentHazardData, fetchRiskAssessmentHazard }: Props) {
  const [loading, setLoading] = useState(true);
  const {
    matrixPossibilityDefinition: possibilityDefinitionMatrix,
    matrixHazardDamageDefinition: damageDefinitionMatrix,
    riskAssessmentMatrixScoreDefinition,
    fineKinneyHazardScoreDefinition,
    fineKinneyPossibilityDefinition: possibilityDefinitionFineKinney,
    fineKinneyHazardDamageDefinition: damageDefinitionFineKinney,
    fineKinneyHazardFrequencyDefinition: frequencyDefinitionFineKinney,
  } = useContext(ResourceContext);
  const [open, setOpen] = useState(false);
  const { data } = useFetch(async () => await getRiskAssessmentHazardIdActions(riskAssessmentHazardData?.id), [], { setLoading });

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await fetchRiskAssessmentHazard();
      }
      setOpen(false);
    },
    [setOpen, fetchRiskAssessmentHazard]
  );

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <>
      {data.length > 0 ? (
        <>
          <header className="text-right mb-2">
            <Button onClick={onClick} startIcon={<AddIcon />} style={{ color: 'white' }} color="warn" variant="contained">
              Bakiye Risk
            </Button>
          </header>
          <IndicatorWrapper
            loading={loading}
            data={
              riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardScoreId ??
              riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardScoreId
            }
            errorMessage="Listelecek Bakiye Risk Kayıtı Bulunamadı.">
            {riskAssessmentHazardData?.riskAssessmentMatrixScore ? (
              <>
                <Alert
                  style={{
                    backgroundColor: matrixRiskColor(
                      +`${
                        findByKey(
                          riskAssessmentMatrixScoreDefinition,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardScoreId
                        )?.score
                      }`
                    ),
                  }}
                  variant="standard"
                  icon={false}
                  severity="info"
                  className="text-left ml-1">
                  <AlertTitle style={{ fontWeight: 'bold' }}>
                    Bakiye Risk
                    <Divider />
                  </AlertTitle>
                  <Grid container>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography variant="subtitle1">Revizyon Sıklığı (ay) : {riskAssessmentHazardData?.revisePeriod}</Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography variant="subtitle1">
                        {' '}
                        Bir Sonraki Revize Tarihi : {riskAssessmentHazardData?.reviseDate?.split('-')?.reverse()?.join('-')}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography variant="subtitle1">
                        Olasılık :
                        {findByKey(
                          possibilityDefinitionMatrix,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardPossibilityId
                        )?.value +
                          ' - ' +
                          findByKey(
                            possibilityDefinitionMatrix,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardPossibilityId
                          )?.label}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography variant="subtitle1">
                        Şiddet :
                        {findByKey(damageDefinitionMatrix, 'id', riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardDamageId)
                          ?.value +
                          ' - ' +
                          findByKey(damageDefinitionMatrix, 'id', riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardDamageId)
                            ?.label}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                        Skor :
                        {findByKey(
                          riskAssessmentMatrixScoreDefinition,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardScoreId
                        )?.score +
                          ' - ' +
                          findByKey(
                            riskAssessmentMatrixScoreDefinition,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentMatrixScore?.finalHazardScoreId
                          )?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Alert>
              </>
            ) : (
              <>
                <Alert
                  style={{
                    backgroundColor: fineKinneyColor(
                      +`${
                        findByKey(
                          fineKinneyHazardScoreDefinition,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardScoreId
                        )?.score
                      }`
                    ),
                  }}
                  className="text-left m-1"
                  color="warning"
                  icon={false}
                  variant="standard">
                  <AlertTitle style={{ fontWeight: 'bold' }}>
                    Bakiye Risk
                    <Divider />
                  </AlertTitle>
                  <Grid container>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      <Typography variant="subtitle1">Revizyon Sıklığı (ay) : {riskAssessmentHazardData?.revisePeriod}</Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      {' '}
                      <Typography variant="subtitle1">
                        Bir Sonraki Revize Tarihi : {riskAssessmentHazardData?.reviseDate?.split('-')?.reverse()?.join('-')}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      {' '}
                      <Typography variant="subtitle1">
                        Olasılık :{' '}
                        {findByKey(
                          possibilityDefinitionFineKinney,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardPossibilityId
                        )?.value +
                          ' - ' +
                          findByKey(
                            possibilityDefinitionFineKinney,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardPossibilityId
                          )?.label}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      {' '}
                      <Typography variant="subtitle1">
                        Sıklık :{' '}
                        {findByKey(
                          frequencyDefinitionFineKinney,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardFrequencyId
                        )?.value +
                          ' - ' +
                          findByKey(
                            frequencyDefinitionFineKinney,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardFrequencyId
                          )?.label}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      {' '}
                      <Typography variant="subtitle1">
                        Şiddet :{' '}
                        {findByKey(
                          damageDefinitionFineKinney,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardDamageId
                        )?.value +
                          ' - ' +
                          findByKey(
                            damageDefinitionFineKinney,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardDamageId
                          )?.label}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xl={6} xs={6} lg={6} sm={6}>
                      {' '}
                      <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                        Skor :{' '}
                        {findByKey(
                          fineKinneyHazardScoreDefinition,
                          'id',
                          riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardScoreId
                        )?.score +
                          ' - ' +
                          findByKey(
                            fineKinneyHazardScoreDefinition,
                            'id',
                            riskAssessmentHazardData?.riskAssessmentFineKinneyScore?.finalHazardScoreId
                          )?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Alert>
              </>
            )}
          </IndicatorWrapper>
          <BalanceRiskForm open={open} onClose={closeDialog} data={riskAssessmentHazardData} />
        </>
      ) : null}
    </>
  );
}
