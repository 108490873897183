import React, { useState } from 'react';

import { NearMissDto } from '../../../api/client';
import ListAdd from './ListAdd';
import { NearMissTableContext } from './near-miss-table.context';

export default function NearMissListIndex() {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<NearMissDto>(null);

  return (
    <NearMissTableContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <ListAdd />
    </NearMissTableContext.Provider>
  );
}
