import React, { useContext } from 'react';

import BaseDiagnosis from '../../Diagnosis';
import { ExaminationDetailContext } from './examination-detail.context';

export default function Diagnosis() {
  const { diagnosis, reloadDiagnosis } = useContext(ExaminationDetailContext);

  return <BaseDiagnosis diagnosis={diagnosis} reloadDiagnosis={reloadDiagnosis} />;
}
