import { Grid } from '@material-ui/core';
import { PlayArrow, Stop } from '@material-ui/icons';
import { tryCatch } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { AccidentReviewControllerService } from '../../../api/client';
import Button from '../../../Common/Button';
import LoadingWrapper from '../../../Common/LoadingWrapper';
import { AlertContext } from '../../../context/alert.context';

interface Props {
  accidentId: string;
  completed: boolean;
  fetch(): void;
}

export default ({ accidentId, fetch, completed }: Props) => {
  const { showMessage } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async () => {
    setLoading(true);
    const [error] = await tryCatch(AccidentReviewControllerService.updateReview(accidentId, { completed: !completed }));

    if (error) {
      showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
    } else {
      fetch();
    }

    setLoading(false);
  };

  return (
    <Grid item className="text-right">
      <LoadingWrapper loading={loading}>
        {completed ? (
          <Button startIcon={<PlayArrow />} color="secondary" onClick={onClick} className="mt-2">
            <b>Kaza İncelemeyi Tekrar Başlat</b>
          </Button>
        ) : (
          <Button startIcon={<Stop />} color="error" onClick={onClick} className="mt-2">
            <b>Kaza İncemeleyi Sonlandır</b>
          </Button>
        )}
      </LoadingWrapper>
    </Grid>
  );
};
