import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { NearMissControllerService, NearMissView } from '../../../../../api/client';
import { nearMissFileUpload } from '../../../../../api/hs/accident/accident';
import { HSTableContext } from '../../../../../Common/Table/HSTable/hs-table.context';
import { DialogContext } from '../../../../../context/dialog.context';
import { useDateFormat, useXhr } from '../../../../../utils';
import { NearMissTableContext } from '../../near-miss-table.context';

function NearMissComponent({ row }: { row: NearMissView }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened } = useContext(NearMissTableContext);
  const { refresh } = useContext(HSTableContext);
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);
  const history = useHistory();

  const deleteFileReq = useXhr(
    async (id: string) => {
      await NearMissControllerService.deleteNearMiss(id);
      await refresh();
    },
    'Seçili Ramak Kala Kaydı Silindi',
    'Seçili Ramak Kala Kaydı silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({ text: `Bu ramak kala kaydını silmek istediğinize emin misiniz?` });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item.nearMissId);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      await Promise.all(files.map(file => nearMissFileUpload(row?.nearMissId, file)));
      await refresh();
    },
    'Ramak Kala Dosyası yüklendi',
    'Dosya yüklenirken bir hata oluştu',
    [row, refresh]
  );

  const openFileUploadDialog = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({
      maxCount: 1,
      title: 'Ramak Kala Dosyası Yükle',
    });

    if (!files) {
      return;
    }

    await uploadFilesReq(files);
  }, [openFileDialog, uploadFilesReq, closeMenu]);

  const routeAccidentDetails = useCallback(() => {
    history.push(`/near-miss/list/${row?.nearMissId}`, { rowData: row });
  }, [row, history]);

  return (
    <div key={row?.id}>
      <IconButton onClick={routeAccidentDetails} id={row?.nearMissId}>
        <VisibilityIcon />
      </IconButton>
      <IconButton id={row?.id} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.id} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.nearMissId} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
        <MenuItem id={row?.id} onClick={() => openFileUploadDialog(row)}>
          <ListItemIcon>
            <AttachmentIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Ramak Kala Dosyası</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy HH:mm')}</>;
}

export const COLUMNS: TableColumn<NearMissView>[] = [
  {
    id: 'companyName',
    width: '10%',
    label: 'Şirket',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'nearMissDepartments',
    width: '20%',
    label: 'Departman',
    defaultVisibility: true,
    sortable: true,
    render(row: NearMissView): JSX.Element {
      return <div>{row.nearMissDepartmentViews.map(x => x.departmentName).join(', ')}</div>;
    },
  },
  {
    id: 'nearMissStations',
    width: '10%',
    label: 'İstasyon',
    defaultVisibility: true,
    sortable: true,
    render(row: NearMissView): JSX.Element {
      return <div>{row.nearMissStationViews.map(x => x.stationName).join(', ')}</div>;
    },
  },
  {
    id: 'description',
    width: '20%',
    label: 'Ramak Kala Tanımı',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'shiftDescription',
    width: '10%',
    label: 'Vardiya',
    defaultVisibility: true,
    sortable: true,
  },
  {
    id: 'date',
    width: '10%',
    label: 'Ramak Kala Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: NearMissView): JSX.Element {
      return <DateField date={new Date(row.date)} />;
    },
  },
  {
    id: 'createdDate',
    width: '10%',
    label: 'Girdi Tarihi',
    defaultVisibility: true,
    sortable: true,
    render(row: NearMissView): JSX.Element {
      return <DateField date={new Date(row.createdDate)} />;
    },
  },
  {
    id: 'id',
    label: 'İşlemler',
    width: '10%',
    render(row: NearMissView): JSX.Element {
      return <NearMissComponent key={row?.id} row={row} />;
    },
  },
];
