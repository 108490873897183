import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Detail from './Detail';
import List from './List';

export default function Examination() {
  return (
    <Switch>
      <Route path="/transactions/employee/:employeeId/examination" exact>
        <List />
      </Route>
      <Route path="/transactions/employee/:employeeId/examination/:examinationId">
        <Detail />
      </Route>
    </Switch>
  );
}
