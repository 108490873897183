import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { ReferralPayload } from './Referral.payload';
import { ReferralResult } from './Referral.result';

export async function getReferral(): Promise<ReferralResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ReferralResult[]>(`/firms/referral-section-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveReferral(body: ReferralPayload): Promise<ReferralResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/referral-section-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateReferral({ id, ...body }: MakeRequired<Partial<ReferralResult>, 'id'>): Promise<ReferralResult> {
  const [error, res] = await tryCatch(HSApi.put<ReferralResult>(`/firms/referral-section-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteReferral(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/referral-section-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminReferral(): Promise<ReferralResult[]> {
  const [error, res] = await tryCatch(HSApi.get<ReferralResult[]>(`/referral-section-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminReferral(body: ReferralPayload): Promise<ReferralResult> {
  const [err, res] = await tryCatch(HSApi.post('/referral-section-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminReferral({ id, ...body }: MakeRequired<Partial<ReferralResult>, 'id'>): Promise<ReferralResult> {
  const [error, res] = await tryCatch(HSApi.put<ReferralResult>(`/referral-section-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminReferral(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/referral-section-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
