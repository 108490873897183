/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DiagnosisIcd10Dto } from './DiagnosisIcd10Dto';

export type DiagnosisDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    examinationId?: string;
    employeeId?: string;
    status?: DiagnosisDto.status;
    protocolNo?: number;
    diagnosisIcd10s?: Array<DiagnosisIcd10Dto>;
    occupationalDiseasePreDiagnosis?: boolean;
    workRelatedDiseaseDiagnosis?: boolean;
}

export namespace DiagnosisDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
