import { tryCatch } from '@thalesrc/js-utils';

import { MakeRequired } from '../../../utils';
import HSApi from '../HSApi';
import { UsersPayload } from './users.payload';
import { GetUsersResult, UsersResultItem } from './users.result';

export async function getUserById(id: UsersResultItem['id']): Promise<UsersResultItem> {
  const [err, res] = await tryCatch(HSApi.get<GetUsersResult>(`/users?userIds=${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data[0];
}

export async function getUserProfileBrief(id: UsersResultItem['id']) {
  const [err, res] = await tryCatch(HSApi.get<GetUsersResult>(`/users/${id}/profile-brief`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveUsers(body: UsersPayload): Promise<UsersResultItem> {
  const [err, res] = await tryCatch(HSApi.post(`/users`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateUser({ id, ...body }: MakeRequired<Partial<UsersResultItem>, 'id'>): Promise<UsersResultItem> {
  const [error, res] = await tryCatch(HSApi.put<UsersResultItem>(`/users/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteUsers(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/users/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateUserPasswordChange(body: any) {
  const [error, res] = await tryCatch(HSApi.put<UsersResultItem>(`/users/current/passwords`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response.data.message;
  }

  return res.data;
}
