import { createContext } from 'react';

import { EmployeeTrainingListPlanningResult } from 'api/hs/definitions/trainings/employee-training/employee-training-list-planning';

export interface TrainingPlanningListContextType {
  employeeTrainingListPlanning: EmployeeTrainingListPlanningResult[];

  loading: boolean;

  reload(): void;

  setLoading(loading: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  newDialogOpenedTrainingInclude: boolean;

  setNewDialogOpenedTrainingInclude(opened: boolean): void;
}

export const TrainingPlanningListContext = createContext<TrainingPlanningListContextType>(null);
