import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import { ReportListContentResult } from 'api/hs/TransactionAndLists/ReportList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { dateDisplayFormat } from '../../utils';
import Avatar from '../EmployeeList/Avatar';
export const CELL: TableColumn<ReportListContentResult>[] = [
  {
    id: 'avatarPath',
    render(row: ReportListContentResult): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: true,
    width: '5%',
  },
  {
    id: 'nid',
    defaultVisibility: true,
    label: 'TC No',
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '10%',
  },
  {
    id: 'reasonName',
    defaultVisibility: true,
    label: 'Sebep',
    sortable: true,
    width: '10%',
  },
  {
    id: 'examinationTypeName',
    defaultVisibility: true,
    label: 'Muayene Tipi',
    sortable: true,
    width: '10%',
  },
  {
    id: 'startDate',
    defaultVisibility: true,
    label: 'Başlangıç Tarihi',
    sortable: true,
    width: '5%',
    render(row: ReportListContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.startDate)}</>;
    },
  },
  {
    id: 'endDate',
    defaultVisibility: true,
    label: 'Bitiş Tarihi',
    sortable: true,
    width: '5%',
    render(row: ReportListContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.endDate)}</>;
    },
  },
  {
    id: 'durationInDays',
    defaultVisibility: true,
    label: 'Gün',
    sortable: true,
    width: '5%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '20%',
  },
  {
    id: 'departmentName',
    defaultVisibility: true,
    label: 'Departman',
    sortable: true,
    width: '15%',
  },
  {
    id: 'hasIncapacityReport',
    render(row: ReportListContentResult): JSX.Element {
      return row.hasIncapacityReport ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />;
    },
    defaultVisibility: true,
    label: 'İş Göremezlik',
    sortable: true,
    width: '5%',
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Ekleyen',
    sortable: true,
    width: '10%',
  },
];
