import { FormControlLabel, Switch as MUISwitch } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from 'utils';

import { FormContext } from './Form/form.context';

interface Props {
  name: string;
  label: string | JSX.Element;
  disabled?: boolean;
  className?: string;
  defaultValue?: boolean;
}

export default function Switch({ name, label, disabled = false, className = '', defaultValue = false }: Props) {
  const { trigger } = useFormContext();
  const { readonly } = useContext(FormContext);
  const translate = useTranslate('form');

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const handleChange = useCallback(
    (onChange: (value: boolean) => void) => (e: ChangeEvent, value: boolean) => {
      if (notEditable) {
        return;
      }

      onChange(value);
      trigger();
    },
    [trigger, notEditable]
  );

  const renderSwitch = useCallback(
    ({ onChange, value }) => <MUISwitch checked={value} value={value} onChange={handleChange(onChange)} disabled={notEditable} />,
    [handleChange, notEditable]
  );

  return (
    <FormControlLabel
      control={<Controller defaultValue={defaultValue} name={name} render={renderSwitch} />}
      label={typeof label === 'string' ? translate(label) : label}
      className={className}
    />
  );
}
