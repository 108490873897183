/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NurseOperationView = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    nurseOperationDefinitionId?: number;
    employeeId?: string;
    bloodType?: number;
    departmentId?: string;
    companyId?: string;
    avatarPath?: string;
    status?: NurseOperationView.status;
    nid?: string;
    name?: string;
    nurseOperationDefinition?: string;
    genderName?: string;
    bloodTypeName?: string;
    companyName?: string;
    departmentName?: string;
    protocolNumber?: string;
    date?: string;
    userName?: string;
}

export namespace NurseOperationView {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
