/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccidentReviewCsiFileTypeDefinitionDto } from '../models/AccidentReviewCsiFileTypeDefinitionDto';
import type { AccidentReviewDocumentDocumentTypeDefinitionDto } from '../models/AccidentReviewDocumentDocumentTypeDefinitionDto';
import type { BaseAccidentReviewAnalysisDefinitionDto } from '../models/BaseAccidentReviewAnalysisDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccidentReviewDefinitionControllerService {

    /**
     * @returns AccidentReviewDocumentDocumentTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getDocumentTypes(): CancelablePromise<Array<AccidentReviewDocumentDocumentTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/research/documents/file-types`,
        });
    }

    /**
     * @returns AccidentReviewCsiFileTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getFileTypes(): CancelablePromise<Array<AccidentReviewCsiFileTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/research/csi/file-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getImprovementTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/improvement-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getPersonalTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/facilitating-factors/personal-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getOrganizationTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/facilitating-factors/organization-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getMaterialTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/facilitating-factors/material-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getEquipmentTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/facilitating-factors/equipment-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getFactTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/apparent-reasons/fact-types`,
        });
    }

    /**
     * @returns BaseAccidentReviewAnalysisDefinitionDto OK
     * @throws ApiError
     */
    public static getBehaviourTypes(): CancelablePromise<Array<BaseAccidentReviewAnalysisDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/accidents/reviews/analysis/apparent-reasons/behaviour-types`,
        });
    }

}