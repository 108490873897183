import { createContext } from 'react';

import { EmergencyContactsModel, EmergencyContactsResult } from 'api/hs/emergency-contacts';

export interface EmergencyContactsContextType {
  emergencyContacts: EmergencyContactsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: EmergencyContactsModel;

  setSelectedData(data: EmergencyContactsModel): void;
}

export const EmergencyContactContext = createContext<EmergencyContactsContextType>(null);
