import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

import { EmployeeWidgetDto } from '../../../../api/client';

interface Props {
  employee: EmployeeWidgetDto[];
}

export default ({ employee }: Props) => {
  const history = useHistory();

  const handleClick = (employeeId: string) => {
    history.push(`/transactions/employee/${employeeId}`);
  };

  if (!employee || employee.length === 0) return <b>Kayıt bulunamadı!</b>;

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Adı Soyadı</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employee.map((employeeData, index) => (
            <TableRow key={index} hover onClick={() => handleClick(employeeData?.id)}>
              <TableCell scope="row">{employeeData?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
