import { createContext } from 'react';

import { WorkEquipmentDefinitionViewDto } from '../../api/client';

export interface WorkEquipmentDefinitionContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: WorkEquipmentDefinitionViewDto;

  setSelectedData(data: WorkEquipmentDefinitionViewDto): void;
}

export const WorkEquipmentDefinitionContext = createContext<WorkEquipmentDefinitionContextType>(null);
