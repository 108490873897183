import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getInjection, InjectionModel, InjectionResult } from 'api/hs/nurse-operations/Injection';
import { useAsyncEffect } from 'utils';

import Injection from './injection';
import { InjectionContext } from './injection.context';

export default function Index() {
  const [injections, setInjections] = useState<InjectionResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<InjectionModel>(null);
  const { nurseOperationId } = useParams<{ nurseOperationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getInjection(nurseOperationId);
    setInjections(result);
    setLoading(false);
  }, [nurseOperationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <InjectionContext.Provider
      value={{
        injections,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Injection />
    </InjectionContext.Provider>
  );
}
