import React from 'react';

import List from './List';
import Search from './Search';

export default function Index() {
  return (
    <div style={{ padding: '10px' }}>
      <Search />
      <List />
    </div>
  );
}
