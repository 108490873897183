import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { EmployeeWidgetControllerService } from '../../../../api/client';
import {
  exportEmployeePeriodicExamination,
  exportPeriodicExaminations,
  getPeriodicExaminations,
} from '../../../../api/hs/dashboard/widget/widget';
import { DateRange } from '../../../../api/hs/dashboard/widget/widget.payload';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { dateSort, useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import ExaminationTable from './ExaminationTable';

const fileNames = { nearly: 'muayenesi-yaklasanlar.xlsx', missed: 'muayenesi-gecenler.xlsx', notExist: 'muayene-hic-olmayanlar.xlsx' };

const dateSortName = (array: any[]): any => {
  return array?.sort((a, b) => {
    return new Date(b?.name) - new Date(a?.name);
  });
};

export default () => {
  const [tab, setTab] = useState<string>('nearly');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: missed } = useFetch(() => getPeriodicExaminations(new DateRange(null, new Date())), [], { setLoading });
  const { data: nearly } = useFetch(() => getPeriodicExaminations(new DateRange(dateRange?.start, dateRange?.end)), [], { setLoading });
  const { data: notExist } = useFetch(() => EmployeeWidgetControllerService.getEmployeeHasNoPeriodicExamCfWidgetView(), [], { setLoading });

  const tabs = { nearly, missed, notExist };

  const {
    examination: { close, opened },
    examinationDateRange: { dateRange },
  } = useContext(DialogContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'nearly') {
      exportPeriodicExaminations(new DateRange(dateRange.start, dateRange.end), fileNames[tab]).then(noop);
      return;
    }

    if (tab === 'missed') {
      exportPeriodicExaminations(new DateRange(null, new Date()), fileNames[tab]).then(noop);
    }

    if (tab === 'notExist') {
      exportEmployeePeriodicExamination(fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Periyodik Muayene" color={colors.examination} onClick={tabs[tab].length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Periyodik Muayenesi Gelen (${nearly.length})`} value={'nearly'} />
            <Tab wrapped label={`Periyodik Muayenesi Geçen (${missed.length})`} value={'missed'} />
            <Tab wrapped label={`Periyodik Muayenesi Hiç Olmayanlar (${notExist.length})`} value={'notExist'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'nearly'}>
            <IndicatorWrapper data={nearly} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ExaminationTable users={nearly} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'missed'}>
            <IndicatorWrapper data={missed} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ExaminationTable users={missed} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'notExist'}>
            <IndicatorWrapper data={notExist} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <ExaminationTable users={dateSortName(notExist)} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
