import { Grid } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { defer, noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { AccidentEmployeeControllerService } from '../../../api/client';
import { exportAccidentReview } from '../../../api/hs/accident/accident';
import Button from '../../../Common/Button';
import { EmployeeSelectionCacheContext } from '../../../Common/Form/EmployeeSelection/employee-selection.context';
import LoadingWrapper from '../../../Common/LoadingWrapper';
import { useAsyncEffect, useFetch } from '../../../utils';

interface Props {
  accidentId: string;
}

export default ({ accidentId }: Props) => {
  const { loadEmployees, names } = useContext(EmployeeSelectionCacheContext);

  const [loading, setLoading] = useState<boolean>(false);
  const { data } = useFetch(() => AccidentEmployeeControllerService.getAllAccidentEmployees(accidentId), [], { setLoading });

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    loadEmployees(...data.map(employeeData => employeeData?.employeeId));
  }, [data]).then(noop);

  const onClick = async () => {
    setLoading(true);
    for (const employee of data) {
      await exportAccidentReview(accidentId, employee.employeeId, `kaza-inceleme-${names[employee.employeeId]}.pdf`);
    }
    setLoading(false);
  };

  return (
    <Grid item className="text-right">
      <LoadingWrapper loading={loading}>
        <Button startIcon={<CloudDownload />} color="warn" onClick={onClick} className="mt-2">
          <b>Kaza İnceleme Raporunu İndir</b>
        </Button>
      </LoadingWrapper>
    </Grid>
  );
};
