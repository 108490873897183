import HSApi from '../../HSApi';
import { ExaminationListResult } from './examination-list-result';
import { ExaminationFilters, GetExaminationListPayload } from './get-examination-list.payload';

export async function getExaminationList(
  page = 0,
  size = 20,
  order: null | string,
  filters: ExaminationFilters
): Promise<ExaminationListResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof ExaminationFilters)[] = ['gender', 'maxAge', 'minAge', 'maxDate', 'minDate'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.minDate) {
    parsedFilters.minDate = new Date(parsedFilters.minDate).toISOString();
  }

  if (parsedFilters.maxDate) {
    parsedFilters.maxDate = new Date(parsedFilters.maxDate).toISOString();
  }

  const payload: GetExaminationListPayload = {
    examinationFilter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<ExaminationListResult>(`/examinations/views`, payload);

  return res.data;
}

export async function ExportExaminationList(filters: ExaminationFilters): Promise<ExaminationListResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof ExaminationFilters)[] = ['gender', 'maxAge', 'minAge', 'maxDate', 'minDate'];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.minDate) {
    parsedFilters.minDate = new Date(parsedFilters.minDate).toISOString();
  }

  if (parsedFilters.maxDate) {
    parsedFilters.maxDate = new Date(parsedFilters.maxDate).toISOString();
  }

  const payload = {
    examinationFilter: parsedFilters,
  };

  const res = await HSApi.post<ExaminationListResult>(`/examinations/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'muayene-listesi.xlsx';
  a.click();
}
