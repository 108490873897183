import React from 'react';

import BuildingDefinitionsAdd from './Add';
import BuildingDefinitionsList from './List';

export default function Index() {
  return (
    <>
      <BuildingDefinitionsAdd />
      <BuildingDefinitionsList />
    </>
  );
}
