import React, { useCallback, useContext } from 'react';

import ActionListAdd from './ActionListAdd';
import { RiskAssessmentsListContext } from './risk-assessments-list.context';

export default function ActionDialog() {
  const { actionDialogOpened, setActionDialogOpened, selectedData } = useContext(RiskAssessmentsListContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      setActionDialogOpened(false);
    },
    [setActionDialogOpened]
  );

  return <ActionListAdd open={actionDialogOpened} onClose={closeDialog} data={selectedData} />;
}
