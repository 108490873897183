import HSApi from '../../HSApi';
import { OccupationalSafetyRegistriesFilter, PostOccupationalRegistriesViewsPayload } from './occupational-safety-registries-filter';
import { OccupationalSafetyRegistriesResult } from './occupational-safety-registries.result';

export async function postOccupationalSafetyRegistriesList(
  page = 0,
  size = 20,
  order: null | string,
  filters: OccupationalSafetyRegistriesFilter
): Promise<OccupationalSafetyRegistriesResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof OccupationalSafetyRegistriesFilter)[] = [
    'createdDateMax',
    'createdDateMin',
    'updatedDateMax',
    'updatedDateMin',
    'description',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  if (parsedFilters.createdDateMax) {
    parsedFilters.createdDateMax = new Date(parsedFilters.createdDateMax).toISOString();
  }

  if (parsedFilters.createdDateMin) {
    parsedFilters.createdDateMin = new Date(parsedFilters.createdDateMin).toISOString();
  }

  if (parsedFilters.updatedDateMax) {
    parsedFilters.updatedDateMax = new Date(parsedFilters.updatedDateMax).toISOString();
  }

  if (parsedFilters.updatedDateMin) {
    parsedFilters.updatedDateMin = new Date(parsedFilters.updatedDateMin).toISOString();
  }

  const payload: PostOccupationalRegistriesViewsPayload = {
    filter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<OccupationalSafetyRegistriesResult>(`/occupational-safety-registries/detections/views`, payload);

  return res.data;
}
