import React from 'react';

import { UserRole } from 'model/user-role.enum';

import FineKinneyDamageDefinition from './RiskFineKinney/FineKinneyDamageDefinition';
import FineKinneyFrequencyDefinition from './RiskFineKinney/FineKinneyFrequencyDefinition';
import FineKinneyPossibilityDefinition from './RiskFineKinney/FineKinneyPossibilityDefinition';
import FineKinneyScoreDefinition from './RiskFineKinney/FineKinneyScoreDefinition';
import HazardCategoryDefinition from './RiskFineKinney/HazardCategoryDefinition';
import HazardCurrentPrecautionDefinition from './RiskFineKinney/HazardCurrentPrecautionDefinition';
import MatrixDamageDefinition from './RiskFineKinney/MatrixDamageDefinition';
import MatrixHazardScoreDefinition from './RiskFineKinney/MatrixHazardScoreDefinition';
import MatrixPossibilityDefinition from './RiskFineKinney/MatrixPossibilityDefinition';
interface FirmRiskFineKinneyListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
  visibleTo?: UserRole[];
}

export default [
  {
    label: 'Mevcut Önlemler',
    Component: HazardCurrentPrecautionDefinition,
    path: '',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Tehlike Kategori',
    Component: HazardCategoryDefinition,
    path: 'tehlike-kategori',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },

  {
    label: '5x5 Olasılık',
    Component: MatrixPossibilityDefinition,
    path: 'risk-olasilik',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: '5x5 Şiddet',
    Component: MatrixDamageDefinition,
    path: 'risk-zarar',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: '5x5 Değerlendirme',
    Component: MatrixHazardScoreDefinition,
    path: 'risk-skor',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Fine-Kinney Sıklık',
    Component: FineKinneyFrequencyDefinition,
    path: 'fine-kinney-siklik',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Fine-Kinney Olasılık',
    Component: FineKinneyPossibilityDefinition,
    path: 'fine-kinney-olasılık',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Fine-Kinney Şiddet',
    Component: FineKinneyDamageDefinition,
    path: 'fine-kinney-zarar',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
  {
    label: 'Fine-Kinney Değerlendirme',
    Component: FineKinneyScoreDefinition,
    path: 'fine-kinney-skor',
    visibleTo: [UserRole.OSS, UserRole.NURSE, UserRole.PHYSICIAN, UserRole.FIRM_ADMIN],
  },
] as FirmRiskFineKinneyListItem[];
