import React from 'react';

import NearMissTypeDefinitions from './NearMissTypeDefinitions';

interface NearMissListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'Ramak Kala Türleri',
    Component: NearMissTypeDefinitions,
    path: '',
  },
] as NearMissListItem[];
