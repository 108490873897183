import { Icon, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { defer, findByKey, isTruthy } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router';

import PrintTable, { PrintTableRef } from 'Common/PrintTable/PrintTable';
import { styleTableEk2 } from 'Common/PrintTable/StyleTable/Ek2FormStyle';

import { getByIdCompany } from '../../../../api/hs/definitions/workplace-definition/company';
import { getEmployee } from '../../../../api/hs/employee';
import { getHospitalHistory } from '../../../../api/hs/examination';
import { getSurgeryHistory } from '../../../../api/hs/examination';
import { getChronicDisease } from '../../../../api/hs/examination';
import { getMedicineTreatment } from '../../../../api/hs/examination';
import { getAllergies } from '../../../../api/hs/examination';
import { getWorkAccidentHistory } from '../../../../api/hs/examination';
import { getDisabilities } from '../../../../api/hs/examination';
import { getAddictions } from '../../../../api/hs/examination';
import { getMedicalHistories } from '../../../../api/hs/examination';
import { getComplaintHistories } from '../../../../api/hs/examination';
import { getProfessionalBackground } from '../../../../api/hs/examination';
import { getExaminationById } from '../../../../api/hs/examination';
import { getAllAnalysisByEmployeeIdAndExaminationId } from '../../../../api/hs/examination/analysis';
import { getBodyMeasurements } from '../../../../api/hs/examination/body-measurements';
import { getFamilyHistory } from '../../../../api/hs/examination/family-history';
import { getOpinion } from '../../../../api/hs/examination/hs-exam-opinions';
import { getImmunities } from '../../../../api/hs/examination/immunity';
import { getPhysicalCheck } from '../../../../api/hs/examination/physical-checks';
import { getSystemCheck } from '../../../../api/hs/examination/system-checks';
import { getVaccines } from '../../../../api/hs/examination/vaccine';
import { getVitalSign } from '../../../../api/hs/examination/vital-sign';
import { getUserById, getUserProfileBrief } from '../../../../api/hs/users';
import { Icd10CacheContext } from '../../../../Common/Form/Icd10Selection/icd10.context';
import { EnumContext } from '../../../../context/enum.context';
import { ResourceContext } from '../../../../context/resource.context';
import { dateSort, dateToInputFormat } from '../../../../utils';
import { EmployeeContext } from '../employee.context';

function periodUnitTypeLabel(periodUnitType: string): string {
  switch (periodUnitType) {
    case 'DAILY':
      return 'günde';
    case 'WEEKLY':
      return 'haftada';
    case 'MONTHLY':
      return 'ayda';
    case 'YEARLY':
      return 'yılda';
    default:
      return periodUnitType;
  }
}

function getAddictionMetric(id: number): string {
  switch (id) {
    case 79:
      return 'kadeh';
    case 80:
      return 'adet';
    case 81:
      return 'kez';
    default:
      return '';
  }
}

interface Props {
  onClose(success: boolean): void;
  selectedExaminationId?: string;
  createdExaminationUserId?: string;
}

export default function EK2Form({ onClose, selectedExaminationId, createdExaminationUserId }: Props) {
  const docRef = useRef<PrintTableRef>();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { employee } = useContext(EmployeeContext);

  const {
    departments,
    bloodRelation,
    workStations,
    positions,
    exposures,
    examinationDefinitions,
    allergies,
    systemCheck,
    physicalCheck,
    vaccines,
    disabilities,
    workingRestrictionDefinitions,
  } = useContext(ResourceContext);
  const {
    bloodTypes,
    educationStatuses,
    maritialStatuses,
    complaintDefinitions,
    medicalHistoryDefinitions,
    addictions,
    addictionStates,
    immunityStatuses,
    analysisCategoryDefinitions,
  } = useContext(EnumContext);
  const { getOptions, options } = useContext(Icd10CacheContext);
  const [data, setData] = useState({});
  const printPage = useCallback(async () => {
    onClose();
    const [
      employeeData,
      complaintData,
      medicalHistoriesData,
      professionalBackgroundData,
      familyHistoryData,
      examinationData,
      companyData,
      chronicDiseaseData,
      hospitalHistoryData,
      surgeryHistoryData,
      medicineTreatmentData,
      allergiesData,
      workAccidentHistoryData,
      disabilitiesData,
      addictionsData,
      systemCheckData,
      physicalCheckData,
      bodyMeasurementsData,
      vitalSignData,
      vaccinesData,
      immunitiesData,
      opinionData,
      analysisData,
      userData,
      userNameData,
    ] = await Promise.all([
      getEmployee(employeeId),
      getComplaintHistories(employeeId, examinationId ? examinationId : selectedExaminationId),
      getMedicalHistories(employeeId, examinationId ? examinationId : selectedExaminationId),
      getProfessionalBackground(employeeId, examinationId ? examinationId : selectedExaminationId),
      getFamilyHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getExaminationById(examinationId ? examinationId : selectedExaminationId),
      getByIdCompany(employee?.companyId),
      getChronicDisease(employeeId, examinationId ? examinationId : selectedExaminationId),
      getHospitalHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getSurgeryHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getMedicineTreatment(employeeId, examinationId ? examinationId : selectedExaminationId),
      getAllergies(employeeId, examinationId ? examinationId : selectedExaminationId),
      getWorkAccidentHistory(employeeId, examinationId ? examinationId : selectedExaminationId),
      getDisabilities(employeeId, examinationId ? examinationId : selectedExaminationId),
      getAddictions(employeeId, examinationId ? examinationId : selectedExaminationId),
      getSystemCheck(employeeId, examinationId ? examinationId : selectedExaminationId),
      getPhysicalCheck(employeeId, examinationId ? examinationId : selectedExaminationId),
      getBodyMeasurements(employeeId, examinationId ? examinationId : selectedExaminationId),
      getVitalSign(employeeId, examinationId ? examinationId : selectedExaminationId),
      getVaccines(employeeId, examinationId ? examinationId : selectedExaminationId),
      getImmunities(employeeId, examinationId ? examinationId : selectedExaminationId),
      // tryCatch(getOpinion(examinationId)).then(([err,res])=> err)
      getOpinion(examinationId ? examinationId : selectedExaminationId),
      getAllAnalysisByEmployeeIdAndExaminationId(employeeId, examinationId ? examinationId : selectedExaminationId),
      getUserProfileBrief(createdExaminationUserId),
      getUserById(createdExaminationUserId),
    ]);

    const newData = {
      ...employeeData,
      complaint: complaintData,
      medicalHistory: medicalHistoriesData,
      professionalBackgrounds: professionalBackgroundData,
      familyHistory: familyHistoryData,
      examination: examinationData,
      company: companyData,
      chronicDisease: chronicDiseaseData,
      hospitalHistory: hospitalHistoryData,
      surgeryHistory: surgeryHistoryData,
      medicineTreatment: medicineTreatmentData,
      allergies: allergiesData,
      workAccidentHistory: workAccidentHistoryData,
      disabilities: disabilitiesData,
      addictions: addictionsData,
      systemCheck: systemCheckData,
      physicalCheck: physicalCheckData,
      bodyMeasurement: dateSort(bodyMeasurementsData)[0],
      vitalSign: dateSort(vitalSignData)[0],
      vaccines: vaccinesData,
      immunities: immunitiesData,
      opinion: opinionData,
      analysis: analysisData,
      createdUser: userData,
      userName: userNameData,
    };
    setData(newData);

    await getOptions(...newData?.familyHistory.reduce((acc, item) => [...acc, ...item.icd10s], []));
    await getOptions(...newData?.chronicDisease?.icd10s);
    await defer();

    docRef.current.print();
  }, [onClose, employeeId, examinationId, selectedExaminationId, employee, createdExaminationUserId, getOptions]);

  const opinionText = useCallback(
    opinion => {
      const workingDescription = opinion?.workingCondition ? opinion?.workingCondition + ' şartı ile ' : '';
      const positionDescription = opinion?.positionDefinition ? opinion?.positionDefinition + ' işinde' : '';
      const opinionDescription = opinion?.workingRestrictions?.find(
        item => item?.workingRestrictionDefinitionId === workingRestrictionDefinitions?.find(opinions => opinions?.opinion === 'Kanaat')?.id
      );
      return opinionDescription?.workingRestrictionValue !== 2
        ? opinionDescription?.workingRestrictionValue === 1
          ? `${positionDescription} ${workingDescription} ${
              opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
            }`
          : `${positionDescription} ${
              opinionDescription?.workingRestrictionValue === 1 ? 'çalışmaya elverişlidir.' : 'çalışmaya elverişli değildir.'
            } `
        : null;
    },
    [workingRestrictionDefinitions]
  );

  function reverseDateFormat(date: string): string {
    return date?.split('-')?.reverse()?.join('-');
  }

  return (
    <>
      <MenuItem onClick={printPage}>
        <ListItemIcon>
          <Icon className="icon-clipboard-text" />
        </ListItemIcon>
        <Typography variant="inherit">EK-2 Formu</Typography>
      </MenuItem>
      <PrintTable styleTable={styleTableEk2} ref={docRef}>
        <table key="ek2Form" style={{ width: '100%' }}>
          <tbody>
            <thead>
              <td align="left" style={{ border: 'none' }} colSpan={5}>
                <b>{`${findByKey(examinationDefinitions, 'id', data?.examination?.examinationDefinitionId)?.examinationName} - Formu`}</b>
                <br />
                <b>{`${employee?.name} / ${data?.examination?.protocolNumber?.yearPrefix}${data?.examination?.protocolNumber?.number}`}</b>
                <hr />
              </td>
              <td align="right" style={{ border: 'none' }} colSpan={1}>
                <img
                  src={`${window.location.origin}/saglik-gozetimi/public/images/artimetrik-logo.png`}
                  alt="logo"
                  style={{ margin: '5px' }}
                  width="100px"
                  height="75px"
                />
              </td>
            </thead>
            <tr>
              <td colSpan={6}>
                <b>İŞ YERİ BİLGİLERİ</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>Ünvanı</b>
              </td>
              <td colSpan={5}>{data?.company?.companyTitle}</td>
            </tr>
            <tr>
              <td>
                <b>SGK Sicil No</b>
              </td>
              <td>{data?.company?.ssiId}</td>
              <td>
                <b>Telefon No</b>
              </td>
              <td colSpan={3}>{data?.company?.phone}</td>
            </tr>
            <tr>
              <td>
                <b>Faks No</b>
              </td>
              <td>{data?.company?.fax}</td>
              <td>
                <b>E-posta</b>
              </td>
              <td colSpan={3}>{data?.company?.email}</td>
            </tr>
            <tr>
              <td>
                <b>Adres</b>
              </td>
              <td colSpan={5}>{data?.company?.address}</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div style={{ textIndent: '32px' }}>
                  {`${findByKey(examinationDefinitions, 'id', data?.examination?.examinationDefinitionId)?.examinationName}`} olmayı kabul
                  ettiğimi ve muayene sırasında verdiğim bilgilerin doğru ve eksiksiz olduğunu beyan ederim.
                </div>
                <div style={{ textAlign: 'right', marginRight: '5px' }}>Çalışanın Adı Soyadı ve İmzası</div>
              </td>
              <td colSpan={2}>
                <img
                  src={`${window.location.origin}/saglik-gozetimi/public/images/Avatars/Orginal/${data?.avatarPath}`}
                  alt={data?.avatarPath}
                  width="150"
                  height="150"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>ÇALIŞAN BİLGİLERİ</b>
                <hr />
              </td>
            </tr>
            <tr>
              <td>
                <b>Adı Soyadı</b>
              </td>
              <td>{data?.name}</td>
              <td>
                <b>TC Kimlik No</b>
              </td>
              <td colSpan={3}>{data?.nid}</td>
            </tr>
            <tr>
              <td>
                <b>Doğum Yeri ve Tarihi</b>
              </td>
              <td>{`${data?.birthPlace} - ${reverseDateFormat(data?.birthDate)}`}</td>
              <td>
                <b>Cinsiyet</b>
              </td>
              <td colSpan={3}>{data?.gender ? 'Kadın' : 'Erkek'}</td>
            </tr>
            <tr>
              <td>
                <b>Medeni Durumu</b>
              </td>
              <td>{findByKey(maritialStatuses, 'id', data?.maritalStatus)?.label}</td>
              <td>
                <b>Çocuk Sayısı</b>
              </td>
              <td colSpan={3}>{data?.numberOfChildren}</td>
            </tr>
            <tr>
              <td>
                <b>Öğrenim Durumu</b>
              </td>
              <td>{findByKey(educationStatuses, 'id', data?.educationalStatus)?.label}</td>
              <td>
                <b>Mesleği</b>
              </td>
              <td colSpan={3}>{data?.profession}</td>
            </tr>
            <tr>
              <td>
                <b>Telefon Numarası</b>
              </td>
              <td>{data?.telephone}</td>
              <td>
                <b>Kan Grubu</b>
              </td>
              <td colSpan={3}> {findByKey(bloodTypes, 'id', data?.bloodType)?.label} </td>
            </tr>
            <tr>
              <td>
                <b>İşe Giriş Tarihi</b>
              </td>
              <td>{reverseDateFormat(data?.hireDate)}</td>
              <td>
                <b>Departman/Bölüm</b>
              </td>
              <td colSpan={3}>{findByKey(departments, 'id', data?.departmentId)?.departmentName}</td>
            </tr>
            <tr>
              <td>
                <b>Görev/Poziyon</b>
              </td>
              <td>{findByKey(positions, 'id', data?.positionId)?.positionName}</td>
              <td>
                <b>İş İstasyonu</b>
              </td>
              <td colSpan={3}>{findByKey(workStations, 'id', data?.stationId)?.stationName}</td>
            </tr>
            <tr>
              <td>
                <b>Adres</b>
              </td>
              <td colSpan={5}>{data?.address}</td>
            </tr>
            <tr>
              <td>
                <b>Maruziyetler</b>
              </td>
              <td colSpan={5}>{data?.exposureId?.map(exposureId => findByKey(exposures, 'id', exposureId)?.exposureName).join(' , ')}</td>
            </tr>
            <tr>
              <td>
                <b>Mesleki Özgeçmiş</b>
              </td>
              <td colSpan={5}>
                {data?.professionalBackgrounds?.length !== 0 ? (
                  <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                    <tr>
                      <th style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>İşyeri</th>
                      <th style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>İş Kolu</th>
                      <th style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>Yaptığı İş</th>
                      <th style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>Maruziyetler</th>
                      <th style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>İşe Giriş / Çıkış</th>
                    </tr>
                    <tr>
                      {data?.professionalBackgrounds?.map(professionalBackground => (
                        <tr key={professionalBackground?.id}>
                          <td style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>{professionalBackground?.companyName}</td>
                          <td style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>{professionalBackground?.sector}</td>
                          <td style={{ border: ' 1px solid silver', borderCollapse: 'collapse' }}>{professionalBackground?.job}</td>
                          <td>
                            {professionalBackground?.professionalBackgroundExposures
                              ?.map(exposure => findByKey(exposures, 'id', exposure?.exposureDefinitionId)?.exposureName)
                              .join(' , ')}
                          </td>
                          <td
                            style={{
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                            }}>{`${reverseDateFormat(professionalBackground?.hireDate)} - ${reverseDateFormat(
                            professionalBackground?.quitDate
                          )}`}</td>
                        </tr>
                      ))}
                    </tr>
                  </table>
                ) : (
                  'Mesleki Özgeçmiş kayıtı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>SOYGEÇMİŞ</b>
                <hr />
              </td>
            </tr>
            {data?.familyHistory?.length !== 0 ? (
              <tr key="familyHistory">
                {data?.familyHistory?.map(familyHistoryValue => (
                  <tr key={familyHistoryValue?.id}>
                    <td>{findByKey(bloodRelation, 'id', familyHistoryValue?.bloodRelationDefinitionId)?.label}</td>
                    <td colSpan={5}>
                      <ul>
                        {familyHistoryValue?.icd10s
                          ?.map(id => findByKey(options, 'id', id))
                          ?.filter(isTruthy)
                          ?.map(({ id, label }) => (
                            <li key={id}>{label}</li>
                          ))}
                        {familyHistoryValue?.description ? <li>{`Açıklaması : ${familyHistoryValue?.description}`}</li> : null}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tr>
            ) : (
              <tr>
                <td colSpan={6}>Soygeçmiş kaydı bulunmamaktadır.</td>
              </tr>
            )}
            <tr>
              <td colSpan={6}>
                <b>TIBBİ ÖZGEÇMİŞ</b>
                <hr />
              </td>
            </tr>
            <tr>
              <td>
                <b>Yakınma Öyküsü</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                <table style={{ width: '100%', border: 'none' }}>
                  <tbody>
                    {data?.complaint?.complaintHistories?.length !== 0 ? (
                      <>
                        {data?.complaint?.complaintHistories
                          ?.filter(filterValue => filterValue?.value !== 2)
                          ?.map(complaintHistory => (
                            <tr key={complaintHistory?.id} style={{ border: 'none' }}>
                              <td style={{ width: '30%', border: 'none', paddingLeft: '15px' }}>
                                {findByKey(complaintDefinitions, 'id', complaintHistory?.complaintDefinitionId)?.label}
                              </td>
                              <td style={{ width: '70%', border: 'none' }}>{complaintHistory?.value ? ': Var' : ': Yok'}</td>
                            </tr>
                          ))}
                        {data?.complaint?.description ? (
                          <tr>
                            <td style={{ border: 'none', paddingLeft: '15px' }}>Açıklama</td>
                            <td style={{ border: 'none', paddingLeft: '15px' }} colSpan={5}>
                              : {data?.complaint?.description}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ) : (
                      'Yakınma Öyküsü kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <b>Hastalık Öyküsü</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                <table style={{ width: '100%', border: 'none' }}>
                  <tbody>
                    {data?.medicalHistory?.medicalHistories?.length !== 0 ? (
                      <>
                        {data?.medicalHistory?.medicalHistories
                          ?.filter(filterValue => filterValue?.value !== 2)
                          ?.map(medicalHistories => (
                            <tr key={medicalHistories?.id} style={{ border: 'none' }}>
                              <td style={{ width: '30%', border: 'none', paddingLeft: '15px' }}>
                                {findByKey(medicalHistoryDefinitions, 'id', medicalHistories?.definitionId)?.label}
                              </td>
                              <td style={{ width: '70%', border: 'none' }}>{medicalHistories?.value ? ': Var' : ': Yok'}</td>
                            </tr>
                          ))}
                        {data?.medicalHistory?.description ? (
                          <tr>
                            <td style={{ border: 'none', paddingLeft: '15px' }}>Açıklama</td>
                            <td style={{ border: 'none', paddingLeft: '15px' }} colSpan={5}>
                              : {data?.medicalHistory?.description}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ) : (
                      'Hastalık Öyküsü kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <b>Hastalık / Yatış Öyküsü</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.hospitalHistory?.length !== 0 ? (
                  <ul>
                    {data?.hospitalHistory?.map(item => (
                      <li key={item?.id}>{item?.description}</li>
                    ))}
                  </ul>
                ) : (
                  'Hastane yatış öyküsü bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Ameliyat Öyküsü</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.surgeryHistory?.length !== 0 ? (
                  <ul>
                    {data?.surgeryHistory?.map(item => (
                      <li key={item?.id}>{item?.description}</li>
                    ))}
                  </ul>
                ) : (
                  'Ameliyat öyküsü bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td>
                <b>Konjenital/Kronik Hastalık</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.chronicDisease?.length !== 0 ? (
                  <ul>
                    {data?.chronicDisease?.icd10s
                      ?.map(id => findByKey(options, 'id', id))
                      ?.filter(isTruthy)
                      ?.map(({ id, label }) => (
                        <li key={id}>{label}</li>
                      ))}
                    {data?.chronicDisease?.description ? <li>{`Açıklaması : ${data?.chronicDisease?.description}`}</li> : null}
                  </ul>
                ) : (
                  'Konjenital/Kronik hastalık kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td>
                <b>Yapılmakta Olan Tedavi / Düzenli Kullanılan İlaçlar</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.medicineTreatment?.length !== 0 ? (
                  <ul>
                    {data?.medicineTreatment?.map(item => (
                      <li key={item?.id}>{item?.description}</li>
                    ))}
                  </ul>
                ) : (
                  'Yapılmakta olan tedavi / Düzenli kullanılan ilaçlar kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Alerji</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.allergies?.length !== 0 ? (
                  <>
                    <ul>
                      {data?.allergies?.allergyDetails?.map(item => (
                        <li key={item?.id}>{findByKey(allergies, 'id', item?.allergyDefinitionId)?.allergyName}</li>
                      ))}
                      <li>{data?.allergies?.description}</li>
                    </ul>
                  </>
                ) : (
                  'Alerji kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>İş Kazası Öyküsü</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.workAccidentHistory?.length !== 0 ? (
                  <ul>
                    {data?.workAccidentHistory?.map(item => (
                      <li key={item?.id}>{`${item?.description} - ${item?.date ? reverseDateFormat(item?.date) : null}`}</li>
                    ))}
                  </ul>
                ) : (
                  'İş kazası öyküsü kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Engellilik Durumu</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.disabilities?.length !== 0 ? (
                  <>
                    <div key={data?.disabilities?.id}> {`Toplam Engellilik Oranı : % ${data?.disabilities?.percentage}`} </div>
                    <div>
                      {data?.disabilities?.disabilityDetails
                        ?.map(disability => findByKey(disabilities, 'id', disability?.disabilityDefinitionId)?.name)
                        .join(' , ')}
                    </div>
                    {data?.disabilities?.description ? (
                      <div key={data?.disabilities?.id}> {`Açıklama : ${data?.disabilities?.description}`} </div>
                    ) : null}
                  </>
                ) : (
                  'Engellilik kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Meslek Hastalıkları Tanısı / Tetkikleri</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.occupationalDiseaseDescription
                  ? data?.occupationalDiseaseDescription
                  : 'Meslek hastalıkları tanısı bulunmamaktadır.'}
              </td>
            </tr>
            <tr>
              <td>
                <b>Bağımlılıklar</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.addictions?.addictionDetails?.length !== 0 || data?.addictions?.description !== null ? (
                  <>
                    <ul>
                      {data?.addictions?.addictionDetails?.map(item => (
                        <li key={item?.id}>
                          {item.addictionStateDefinitionId === 0
                            ? `${findByKey(addictions, 'id', item?.addictiveSubstanceDefinitionId)?.label} - ${
                                findByKey(addictionStates, 'id', item?.addictionStateDefinitionId)?.label
                              } `
                            : item.addictionStateDefinitionId === 1
                            ? `${findByKey(addictions, 'id', item?.addictiveSubstanceDefinitionId)?.label} -   ${
                                item.startYearForQuit
                              } yılında bırakmış. ${item.countOfYearsForQuit} yıl boyunca ${item.period} ${periodUnitTypeLabel(
                                item?.periodUnitDefinition
                              )}

                        ${item.countPerDayForQuit} ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanmış.`
                            : `${findByKey(addictions, 'id', item?.addictiveSubstanceDefinitionId)?.label} - ${
                                item.startYearForUsing
                              } yılından beri  ${item.period} ${periodUnitTypeLabel(item?.periodUnitDefinition)} ${
                                item.countPerDayForUsing
                              } ${getAddictionMetric(item.addictiveSubstanceDefinitionId)} kullanıyor.`}
                        </li>
                      ))}
                      {data?.addictions?.description ? <li>{data?.addictions?.description}</li> : null}
                    </ul>
                  </>
                ) : (
                  'Bağımlılıklar kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td>
                <b>Sistem Sorgulama</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                <table style={{ width: '100%', border: 'none' }}>
                  <tbody>
                    {data?.systemCheck?.length !== 0 ? (
                      <>
                        {data?.systemCheck
                          ?.filter(filterValue => filterValue?.bodySystemsCheckStatusDefinitionId !== 2)
                          ?.map(systemCheckValue => (
                            <tr key={systemCheckValue?.id} style={{ border: 'none' }}>
                              <td style={{ width: '40%', border: 'none' }}>
                                {findByKey(systemCheck, 'id', systemCheckValue?.bodySystemsCheckDefinitionId)?.label}
                              </td>
                              <td style={{ width: '10%', border: 'none' }}>
                                {systemCheckValue?.bodySystemsCheckStatusDefinitionId === 1 ? ': Var' : ': Yok'}
                              </td>
                              <td style={{ width: '50%', border: 'none' }}>
                                {systemCheckValue?.description ? systemCheckValue?.description : null}
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      'Sistem sorgulama kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <b>Fizik Muayene</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                <table style={{ width: '100%', border: 'none' }}>
                  <tbody>
                    {data?.physicalCheck?.length !== 0 ? (
                      <>
                        {data?.physicalCheck
                          ?.filter(filterValue => filterValue?.physicalCheckStatusDefinitionId !== 2)
                          ?.map(physicalValue => (
                            <tr key={physicalValue?.id} style={{ border: 'none' }}>
                              <td style={{ width: '40%', border: 'none' }}>
                                {findByKey(physicalCheck, 'id', physicalValue?.physicalCheckDefinitionId)?.label}
                              </td>
                              <td style={{ width: '10%', border: 'none' }}>
                                {physicalValue?.physicalCheckStatusDefinitionId === 1 ? ': Var' : ': Yok'}
                              </td>
                              <td style={{ width: '50%', border: 'none' }}>
                                {physicalValue?.description ? physicalValue?.description : null}
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      'Fizik Muayene kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>Vücut Ölçüm</b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                {data?.bodyMeasurement?.length !== 0 ? (
                  <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                    <tbody style={{ borderBottom: '1px solid', borderBottomColor: 'silver' }}>
                      <tr>
                        <th
                          style={{
                            width: '15%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Ölçüm Tarihi
                        </th>
                        <th
                          style={{
                            width: '10%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Boy (cm)
                        </th>
                        <th
                          style={{
                            width: '10%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Kilo (kg)
                        </th>
                        <th
                          style={{
                            width: '5%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          VKİ
                        </th>
                        <th
                          style={{
                            width: '10%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Bel (cm)
                        </th>
                        <th
                          style={{
                            width: '10%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Kalça (cm)
                        </th>
                        <th
                          style={{
                            width: '10%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Bel / Kalça
                        </th>
                        <th
                          style={{
                            width: '15%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Ölçümü Yapan Kişi
                        </th>
                        <th
                          style={{
                            width: '15%',
                            border: ' 1px solid silver',
                            borderCollapse: 'collapse',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                          }}>
                          Açıklama
                        </th>
                      </tr>
                      <tr>
                        <tr key={data?.bodyMeasurement?.id}>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>{`${reverseDateFormat(data?.bodyMeasurement?.measurementDate)} ${
                            data?.bodyMeasurement?.measurementTime
                          }`}</td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.lengthInCm}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.hipInCm}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.bmi}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.waistInCm}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.hipInCm}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.waistHipRatio}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.userData?.name}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.bodyMeasurement?.description}
                          </td>
                        </tr>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  'Vücut ölçümü bulunmamaktadır.'
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <b>Vital Ölçüm</b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                {data?.vitalSign?.length !== 0 ? (
                  <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                    <tbody style={{ borderBottom: '1px solid', borderBottomColor: 'silver' }}>
                      <tr>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Ölçüm Tarihi
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Kan Basıncı(mmHg)
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Nabız
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Ateş
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Solunum
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Ölçümü Yapan Kişi
                        </th>
                        <th style={{ border: ' 1px solid silver', borderCollapse: 'collapse', verticalAlign: 'middle', fontSize: '13px' }}>
                          Açıklama
                        </th>
                      </tr>
                      <tr>
                        <tr key={data?.vitalSign?.id}>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>{`${reverseDateFormat(data?.vitalSign?.measurementDate)} ${data?.vitalSign?.measurementTime}`}</td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>{`${data?.vitalSign?.systolic} - ${data?.vitalSign?.diastolic}`}</td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.vitalSign?.pulseRate}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.vitalSign?.bodyTemperature}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.vitalSign?.respirationRate}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.vitalSign?.userData?.name}
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              border: ' 1px solid silver',
                              borderCollapse: 'collapse',
                              verticalAlign: 'middle',
                              fontSize: '13px',
                            }}>
                            {data?.vitalSign?.description}
                          </td>
                        </tr>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  'Vital ölçüm bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td colSpan={6}>
                <b>Bağışıklanma</b>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <table style={{ width: '100%', border: ' 1px solid silver', borderCollapse: 'collapse' }}>
                  <tbody style={{ borderBottom: '1px solid', borderBottomColor: 'silver' }}>
                    {data?.immunities?.length !== 0 ? (
                      <>
                        {data?.immunities?.map(immunitiesValue => (
                          <tr key={immunitiesValue?.id} style={{ border: 'none' }}>
                            <td style={{ width: '20%', border: ' 1px solid silver', borderCollapse: 'collapse', paddingLeft: '15px' }}>
                              {findByKey(vaccines, 'id', immunitiesValue?.vaccineDefinitionId)?.name}
                            </td>
                            <td style={{ width: '60%', border: ' 1px solid silver', borderCollapse: 'collapse', paddingLeft: '15px' }}>
                              {findByKey(immunityStatuses, 'id', immunitiesValue?.immunityStatusDefinitionId)?.label}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      'Bağışıklanma kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td colSpan={6}>
                <b>Yapılan Aşılar</b>
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: '15px' }} colSpan={6}>
                {data?.vaccines?.length !== 0 ? (
                  <>
                    <ul style={{ marginTop: '5px' }}>
                      {data?.vaccines
                        ?.filter(vaccine => vaccine?.isDone)
                        ?.map(vaccinesValue => (
                          <li key={vaccinesValue?.id}>{findByKey(vaccines, 'id', vaccinesValue?.vaccineDefinitionId)?.name}</li>
                        ))}
                    </ul>
                  </>
                ) : (
                  'Yapılan aşı kaydı bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td>
                <b>Laboratuvar Bulguları</b>
              </td>
              <td style={{ paddingLeft: '15px' }} colSpan={5}>
                {data?.analysis?.length !== 0 ? (
                  <ul>
                    {data?.analysis?.map(item => (
                      <li key={item?.id}>{`${findByKey(analysisCategoryDefinitions, 'id', item?.categoryDefinitionId)?.label} - ${
                        item?.date ? reverseDateFormat(item?.date) : null
                      }`}</li>
                    ))}
                  </ul>
                ) : (
                  'Herhangi bir tahlil/tetkik bulunmamaktadır.'
                )}
              </td>
            </tr>

            <tr>
              <td colSpan={6}>
                <b>Kanaat / Sonuç</b>
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: '30px', paddingTop: '30px' }} colSpan={6}>
                <table
                  style={{
                    width: '100%',
                    border: 'none',
                    borderCollapse: 'collapse',
                    marginBottom: '20px',
                  }}>
                  <tbody>
                    {data?.opinion ? (
                      <>
                        <ul>
                          <td style={{ width: '100%', border: ' none', paddingLeft: '15px', fontWeight: 'bold' }}>
                            <li>{opinionText(data?.opinion)}</li>{' '}
                          </td>
                          {data?.opinion?.workingRestrictions
                            ?.filter(workingRestrictionVal => workingRestrictionVal.workingRestrictionValue < 2)
                            ?.map(workingRestriction => (
                              <tr key={workingRestriction?.id} style={{ border: 'none' }}>
                                <td style={{ width: '100%', border: ' none', paddingLeft: '15px' }}>
                                  <li>{`${
                                    findByKey(workingRestrictionDefinitions, 'id', workingRestriction?.workingRestrictionDefinitionId)
                                      ?.opinion
                                  } ${workingRestriction?.workingRestrictionValue ? 'çalışır' : 'çalışamaz'}`}</li>
                                </td>
                              </tr>
                            ))}
                          {data?.opinion?.description ? (
                            <td style={{ width: '100%', border: ' none', paddingLeft: '15px' }}>
                              <li>{`Diğer Hususlar : ${data?.opinion?.description}`}</li>{' '}
                            </td>
                          ) : null}
                        </ul>
                      </>
                    ) : (
                      'Kanaat kaydı bulunmamaktadır.'
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: '10px' }}>
          <div style={{ float: 'left', textAlign: 'left' }}>
            <div>
              <b>İşyeri Hekimi</b>
            </div>
            <div>
              <b>{data?.userName?.name}</b>
            </div>
            <div>
              <b>Diploma.Tar. ve No : {data?.createdUser?.graduationDate}</b>
            </div>
            <div>
              <b>Diploma.Tes. Tar. ve No : {data?.createdUser?.diplomaRegistrationDate}</b>
            </div>
            <div>
              <b>İşyeri Hek. Belgesi Tar. ve No: {data?.createdUser?.doctorCertificate}</b>
            </div>
          </div>
          <div style={{ float: 'right', textAlign: 'right' }}>
            <b>{reverseDateFormat(dateToInputFormat(new Date()))}</b>
          </div>
        </div>
      </PrintTable>
    </>
  );
}
