/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WidgetInvisibilityDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    userId?: string;
    widgetType?: WidgetInvisibilityDto.widgetType;
}

export namespace WidgetInvisibilityDto {

    export enum widgetType {
        PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
        REFERRAL = 'REFERRAL',
        REPORT_REPORT = 'REPORT_REPORT',
        VACCINE = 'VACCINE',
        TRAINING = 'TRAINING',
        PREGNANCY = 'PREGNANCY',
        WORK_EQUIPMENT = 'WORK_EQUIPMENT',
        PPE = 'PPE',
        ACTION = 'ACTION',
        EMPLOYEE = 'EMPLOYEE',
        NEAR_MISS = 'NEAR_MISS',
        ACCIDENT = 'ACCIDENT',
        LOST_DAY = 'LOST_DAY',
    }


}
