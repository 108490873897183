import React, { useState } from 'react';

import { getAdminTypeDefinitions, TypeDefinitionResult } from 'api/hs/definitions/ppe-definitions/type-definitions';
import { useFetch } from 'utils';

import PPEType from './Type';
import { TypeDefinitionsContext } from './type-definitions.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<TypeDefinitionResult>(null);

  const { data, fetch } = useFetch(() => getAdminTypeDefinitions(), [], { setLoading });

  return (
    <TypeDefinitionsContext.Provider
      value={{
        typeDefinitions: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <PPEType />
    </TypeDefinitionsContext.Provider>
  );
}
