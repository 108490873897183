import { createContext } from 'react';

import { RiskAssessmentHazardView } from '../../api/client';

export interface RiskAssessmentsListContextType {
  newDialogOpened: boolean;

  actionDialogOpened: boolean;

  setActionDialogOpened(opened: boolean): void;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: RiskAssessmentHazardView;

  setSelectedData(data: RiskAssessmentHazardView): void;

  riskAssessmentType: string;
}

export const RiskAssessmentsListContext = createContext<RiskAssessmentsListContextType>(null);
