import { makeStyles } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Route, Switch, useParams } from 'react-router';

import { getEmployee, getEmployeeWarningFlags, updateEmployee } from 'api/hs/employee';
import { getAllergies, getChronicDisease, getChronicDiseaseData, getDisabilities } from 'api/hs/examination';
import { getPeriodicExaminationConfigurations } from 'api/hs/examination/periodic-examination-configurations';
import { getPregnancies } from 'api/hs/examination/pregnancies';
import PersistentDrawer from 'Common/PersistentDrawer';
import { AlertContext } from 'context/alert.context';
import { Employee as EmployeeModel } from 'model';
import { useAsyncEffect } from 'utils';

import { UserContext } from '../../../context/user';
import { UserRole } from '../../../model/user-role.enum';
import AnalysisPage from './AnalysisPage';
import DiagnosisPage from './DiagnosisPage';
import { EmployeeContext, EmployeeContextType } from './employee.context';
import Examination from './ExaminationPage';
import ImmunityAndVaccinePage from './ImmunityAndVaccinePage';
import InfoDrawer from './InfoDrawer';
import DefaultNurseOperation from './NurseOperation';
import PPE from './Ppe';
import PrescriptionPage from './PrescriptionPage';
import ProfilePage from './ProfilePage';
import ReferralPage from './ReferralPage';
import ReportsPage from './ReportsPage';
import Toolbar from './Toolbar';
import TrainingEmployee from './Trainings';
import Icd10CacheContextProvider from "../../../Common/Form/Icd10Selection/Icd10CacheContext";
import MedicineCacheContextProvider from "../../../Common/Form/MedicineSelection/MedicineCacheContextProvider";

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'grid',
    gridTemplate: 'max-content 1fr / 100%',
    overflow: 'hidden',
  },
  drawerContainer: {
    flex: 1,
  },
}));

export default function Employee() {
  const classes = useStyles();
  const { showMessage } = useContext(AlertContext);
  const { employeeId } = useParams<{ employeeId: string }>();
  const [infoDrawerOpened, setInfoDrawerOpened] = useState(true);
  const [employee, setEmployee] = useState<EmployeeModel>(new EmployeeModel());
  const { user } = useContext(UserContext);

  useAsyncEffect(async () => {
    if (employeeId) await fetch();
  }, [employeeId]).then(noop);

  const fetch = useCallback(async () => {
    let _employee = { ...employee };

    const roleFunctionMap = {
      [UserRole.PHYSICIAN]: {
        allergiesId: () =>
          getAllergies(employeeId).then(allergy => allergy?.allergyDetails?.map(({ allergyDefinitionId }) => allergyDefinitionId)),
        chronicDiseaseIcd10s: () => getChronicDisease(employeeId).then(chronicDisease => chronicDisease.icd10s.map(icd10 => icd10)),
        chronicDiseaseData: () => getChronicDiseaseData(employeeId).then(chronicDisease => chronicDisease),
        examinationFrequencyInMonth: () =>
          getPeriodicExaminationConfigurations(employeeId).then(
            periyodicExaminationData => periyodicExaminationData?.examinationFrequencyInMonth
          ),
        nextExaminationDate: () =>
          getPeriodicExaminationConfigurations(employeeId).then(periyodicExaminationData => periyodicExaminationData?.nextExaminationDate),
        hasDisability: () =>
          getDisabilities(employeeId).then(disabilities => (disabilities ? !!disabilities.disabilityDetails.length : false)),
        pregnancyHistory: () => getPregnancies(employeeId).then(pregnancies => pregnancies),
        warningLabels: () => getEmployeeWarningFlags(employeeId).then(warningLabels => warningLabels),
      },
      [UserRole.OSS]: {
        hasDisability: () =>
          getDisabilities(employeeId).then(disabilities => (disabilities ? !!disabilities.disabilityDetails.length : false)),
      },
    };
    roleFunctionMap[UserRole.NURSE] = roleFunctionMap[UserRole.PHYSICIAN];

    const functionsMap = new Map<string, Promise<any>>();

    if (user.roles?.length > 0) {
      user.roles
        .filter(role => role in roleFunctionMap)
        .forEach(role =>
          Object.keys(roleFunctionMap[role])
            .filter(functionKey => !(functionKey in functionsMap))
            .forEach(functionKey => {
              functionsMap.set(functionKey, roleFunctionMap[role][functionKey]);
            })
        );
    }

    for (const [key, fn] of functionsMap.entries()) {
      // @ts-ignore
      _employee = { ..._employee, [key]: await (fn as () => void)() };
    }

    _employee = { ..._employee, ...(await getEmployee(employeeId)) };
    setEmployee(_employee);
  }, [employee, employeeId, user]);

  const update = useCallback(
    async (data: Partial<EmployeeModel> & { id: EmployeeModel['id'] }) => {
      await updateEmployee(data);
      await showMessage('Bilgiler güncellendi', 'success', 'info');
      await fetch();
    },
    [showMessage, fetch]
  );

  const context = useMemo<EmployeeContextType>(
    () => ({
      employee,
      infoDrawerOpened,
      setInfoDrawerOpened,
      update,
      refresh: fetch,
    }),
    [employee, infoDrawerOpened, update, fetch]
  );

  return (
    <EmployeeContext.Provider value={context}>
      <Icd10CacheContextProvider>
        <MedicineCacheContextProvider>
          <div className={classes.container}>
            <Toolbar />
            <PersistentDrawer opened={infoDrawerOpened} Drawer={<InfoDrawer />} className={classes.drawerContainer}>
              <Switch>
                <Route path="/transactions/employee/:employeeId" exact>
                  <ProfilePage />
                </Route>
                <Route path="/transactions/employee/:employeeId/examination">
                  <Examination />
                </Route>
                <Route path="/transactions/employee/:employeeId/nursing-actions">
                  <DefaultNurseOperation />
                </Route>
                <Route path="/transactions/employee/:employeeId/analysis">
                  <AnalysisPage />
                </Route>
                <Route path="/transactions/employee/:employeeId/vaccine">
                  <ImmunityAndVaccinePage />
                </Route>
                <Route path="/transactions/employee/:employeeId/referral">
                  <ReferralPage />
                </Route>
                <Route path="/transactions/employee/:employeeId/report">
                  <ReportsPage />
                </Route>
                <Route path="/transactions/employee/:employeeId/ppe">
                  <PPE />
                </Route>
                <Route path="/transactions/employee/:employeeId/diagnosis">
                  <DiagnosisPage />
                </Route>
                <Route path="/transactions/employee/:employeeId/prescription">
                  <PrescriptionPage />
                </Route>
                <Route path="/transactions/employee/:employeeId/education">
                  <TrainingEmployee />
                </Route>
              </Switch>
            </PersistentDrawer>
          </div>
        </MedicineCacheContextProvider>
      </Icd10CacheContextProvider>
    </EmployeeContext.Provider>
  );
}
