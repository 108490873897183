import React, { useCallback, useContext } from 'react';

import { CaseTeamsDetailContext } from '../case-teams-detail.context';
import NewCaseTeamMembersIndex from '../new-case-team-members';

export default function NewCaseTeamDialog() {
  const { newCaseTeamMembersNewDialog, setNewCaseTeamMembersNewDialog, reload, emergencyCaseTeamMember } = useContext(
    CaseTeamsDetailContext
  );

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await reload();
      }
      setNewCaseTeamMembersNewDialog(false);
    },
    [setNewCaseTeamMembersNewDialog, reload]
  );

  return <NewCaseTeamMembersIndex data={emergencyCaseTeamMember} open={newCaseTeamMembersNewDialog} onClose={closeDialog} />;
}
