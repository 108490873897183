import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { PropsWithChildren } from 'react';

interface Props {
  opened: boolean;
  onClose(): void;
  width?: DialogProps['maxWidth'];
  title?: string | JSX.Element;
  actions?: string | JSX.Element;
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'error';
}

const useStyles = makeStyles(theme => ({
  default: {},
  ...['primary', 'secondary', 'warning', 'error'].reduce(
    (acc, next) => ({
      ...acc,
      [next]: {
        backgroundColor: (theme.palette as any)[next].main,
        color: (theme.palette as any)[next].contrastText,
      },
    }),
    {}
  ),
  toolbar: {
    display: 'flex',
    alignItems: 'center',

    '& > *:last-child': {
      minWidth: 'max-content',
    },
  },
}));

export default function Dialog({
  opened,
  onClose,
  width = 'sm',
  title = '',
  actions = '',
  color = 'default',
  children,
}: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <MUIDialog open={opened} onClose={onClose} fullWidth maxWidth={width} scroll="paper">
      <DialogTitle className={`${(classes as any)[color]} ${classes.toolbar}`} disableTypography>
        <Typography className="width-100" variant="h6">
          {title}
        </Typography>
        <IconButton className="mr-n2 my-n2" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="px-2">{actions}</DialogActions>
    </MUIDialog>
  );
}
