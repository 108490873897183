import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { VitalSignsViewControllerService } from '../../api/client';
import Toolbar from '../../Common/Toolbar';
import { EnumContext } from '../../context/enum.context';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import { dataDisplayValue } from '../utils/chart-data-utils';
import ClusteredXYColumnChart from '../utils/ClusteredXYColumnChart';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import { chartUseStyles } from '../utils/SimpleCard';
import VitalChartContainer from './VitalChartContainer';

export default function VitalMeasurements() {
  const [chartData, setChartData] = useState([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();
  const { vitalSignCategoryDefinition } = useContext(EnumContext);
  const { positions } = useContext(SelectOptionsContext);
  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      filter: {
        dateMin: values?.minDate,
        dateMax: values?.maxDate,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'measurementDate',
      },
    };
    const res = await VitalSignsViewControllerService.getAllVitalSigns(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Vital Ölçüm İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirket Bazlı Dağılımı" />{' '}
                <VitalChartContainer
                  chartName="vital-measurement-clustered-company-report"
                  rawData={chartData}
                  categoryFieldName="companyName"
                  selectorFieldByName="bloodPressureCategoryDefinitionName"
                  xAxesTitle="Şirketler"
                  xAxesText="Şirket"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Departman Bazlı Dağılımı" />{' '}
                <VitalChartContainer
                  chartName="vital-measurement-clustered-department-report"
                  rawData={chartData}
                  categoryFieldName="departmentName"
                  selectorFieldByName="bloodPressureCategoryDefinitionName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Departman"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="İş İstasyon Bazlı Dağılımı" />{' '}
                <VitalChartContainer
                  chartName="vital-measurement-clustered-station-report"
                  rawData={chartData}
                  categoryFieldName="stationName"
                  selectorFieldByName="bloodPressureCategoryDefinitionName"
                  xAxesTitle="İş İstasyonları"
                  xAxesText="İş İstasyon"
                  yAxesText=""
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Görev Bazlı Dağılımı" />{' '}
                <VitalChartContainer
                  chartName="vital-measurement-clustered-position-report"
                  rawData={chartData}
                  categoryFieldName="positionName"
                  selectorFieldByName="bloodPressureCategoryDefinitionName"
                  xAxesTitle="Görevler"
                  xAxesText="Görev"
                  yAxesText=""
                />
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sm={12}>
              <PieChartGridContainer
                title="Vital Ölçüm Kategorisine Göre Dağılımı"
                chartProps={{
                  chartName: 'body-measurement-bloodPressureCategoryDefinitionId-report',
                  preparedData: convertAccidentReviewPropertyToChartData(
                    chartData,
                    'bloodPressureCategoryDefinitionId',
                    vitalSignCategoryDefinition?.map(item => {
                      return { value: item.id, text: item.label };
                    })
                  ),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
