import HSApi from '../../HSApi';
import { VaccineListResult } from './vaccine-list.result';

export async function getVaccineList(page = 0, size = 20, order: null | string): Promise<VaccineListResult> {
  const res = await HSApi.get<VaccineListResult>(`/vaccines/view?page=${page}&size=${size}&order=${order ?? ''}`);
  return res.data;
}
export async function exportVaccine() {

  const res = await HSApi.get(`/vaccines/view/export`, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'asi-kayit-listesi.xlsx';
  a.click();
}