import { Box, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';

import {
  AccidentReviewControllerService,
  AccidentReviewCsiDto,
  AccidentReviewDocumentDto,
  AccidentReviewWitnessDto,
} from '../../../api/client';
import IndicatorWrapper from '../../../Common/IndicatorWrapper';
import { useFetch } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import NewRecordButton from '../NewRecordButton';
import Csi from './Csi';
import CsiDialog from './CsiDialog';
import Document from './Document';
import DocumentDialog from './DocumentDialog';
import Witness from './Witness';
import WitnessDialog from './WitnessDialog';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 500,
    overflowY: 'auto',
  },
}));

export default () => {
  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [witnessDialogOpened, setWitnessDialogOpened] = useState(false);
  const [csiDialogOpened, setCsiDialogOpened] = useState(false);
  const [documentDialogOpened, setDocumentDialogOpened] = useState(false);

  const [tab, setTab] = useState('0');
  const [witnessData, setWitnessData] = useState<AccidentReviewWitnessDto>();
  const [csiData, setCsiData] = useState<AccidentReviewCsiDto>();
  const [documentData, setDocumentData] = useState<AccidentReviewDocumentDto>();
  const [csiOrderNum, setCsiOrderNum] = useState<number>(0);
  const [documentOrderNum, setDocumentOrderNum] = useState<number>(0);
  const { token, insertable, accidentId } = useContext(AccidentReviewContext);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTab(value);
  };

  const { data: witnesses, fetch: fetchWitnesses } = useFetch(
    () => AccidentReviewControllerService.getWitnesses(accidentId, null, token),
    [],
    {
      setLoading,
    }
  );

  const { data: csi, fetch: fetchCsi } = useFetch(() => AccidentReviewControllerService.getCsi(accidentId, null, token), [], {
    setLoading,
    deps: [csiOrderNum],
  });

  const { data: documents, fetch: fetchDocuments } = useFetch(
    () => AccidentReviewControllerService.getDocuments(accidentId, null, token),
    [],
    {
      setLoading,
      deps: [documentOrderNum],
    }
  );

  useEffect(() => {
    if (csi) {
      csi.filter(item => item.orderNum > csiOrderNum).forEach(item => item.orderNum > csiOrderNum && setCsiOrderNum(item.orderNum));
    }
  }, [csi, csiOrderNum]);

  useEffect(() => {
    if (documents) {
      documents
        .filter(item => item.orderNum > documentOrderNum)
        .forEach(item => item.orderNum > documentOrderNum && setDocumentOrderNum(item.orderNum));
    }
  }, [documents, documentOrderNum]);

  const onClickWitness = (data: AccidentReviewWitnessDto) => {
    setWitnessData(data);
    setWitnessDialogOpened(true);
  };

  const onClickNewWitness = () => {
    setWitnessData(undefined);
    setWitnessDialogOpened(true);
  };

  const onClickCsi = (data: AccidentReviewCsiDto) => {
    setCsiData(data);
    setCsiDialogOpened(true);
  };

  const onClickNewCsi = () => {
    setCsiData(undefined);
    setCsiDialogOpened(true);
  };

  const onClickDocument = (data: AccidentReviewDocumentDto) => {
    setDocumentData(data);
    setDocumentDialogOpened(true);
  };

  const onClickNewDocument = () => {
    setDocumentData(undefined);
    setDocumentDialogOpened(true);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} variant="fullWidth">
          <Tab label="Tanıklarla Görüşme" value={'0'} />
          <Tab label="Olay Yeri İnceleme" value={'1'} />
          <Tab label="Kayıt Doküman" value={'2'} />
        </Tabs>
      </Box>
      <TabPanel value="0">
        <Grid container className={styles.container}>
          {insertable && <NewRecordButton onClick={onClickNewWitness} label="Yeni Tanık Ekle" />}
          <IndicatorWrapper data={witnesses} loading={loading} errorMessage={'Listelenecek kayıt bulunamadı.'}>
            {witnesses &&
              witnesses.map((witness: AccidentReviewWitnessDto) => (
                <Witness data={witness} fetch={fetchWitnesses} key={witness.id} onClick={() => onClickWitness(witness)} />
              ))}
          </IndicatorWrapper>
        </Grid>
      </TabPanel>
      <TabPanel value="1">
        {insertable && <NewRecordButton onClick={onClickNewCsi} label="Yeni Fotoğraf / Kroki Ekle" />}
        <Grid container className={styles.container}>
          <IndicatorWrapper data={csi} loading={loading} errorMessage={'Listelenecek kayıt bulunamadı.'}>
            {csi &&
              csi.map((item: AccidentReviewCsiDto) => <Csi data={item} fetch={fetchCsi} key={item.id} onClick={() => onClickCsi(item)} />)}
          </IndicatorWrapper>
        </Grid>
      </TabPanel>

      <TabPanel value="2">
        {insertable && <NewRecordButton onClick={onClickNewDocument} label="Yeni Kayıt / Doküman Ekle" />}
        <Grid container className={styles.container}>
          <IndicatorWrapper data={documents} loading={loading} errorMessage={'Listelenecek kayıt bulunamadı.'}>
            {documents.map((item: AccidentReviewDocumentDto) => (
              <Document data={item} fetch={fetchDocuments} key={item.id} onClick={() => onClickDocument(item)} />
            ))}
          </IndicatorWrapper>
        </Grid>
      </TabPanel>
      <WitnessDialog open={witnessDialogOpened} onClose={setWitnessDialogOpened} fetch={fetchWitnesses} data={witnessData} />
      <CsiDialog open={csiDialogOpened} orderNum={csiOrderNum + 1} data={csiData} onClose={setCsiDialogOpened} fetch={fetchCsi} />
      <DocumentDialog
        open={documentDialogOpened}
        orderNum={documentOrderNum + 1}
        data={documentData}
        onClose={setDocumentDialogOpened}
        fetch={fetchDocuments}
      />
    </TabContext>
  );
};
