import { findByKey } from '@thalesrc/js-utils';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useAsyncEffect, useFetch } from 'utils';

import { BuildingPartDefinitionControllerService } from '../../../../api/client';
import { BuildingPartDefinitionDto } from '../../../../api/client';
import { BuildingFloorDefinitionControllerService } from '../../../../api/client';
import { BuildingsFloorsPartsContext } from './buildings-floors-parts.context';
import BuildingsFloorsPart from './BuildingsFloorsPart';
export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<BuildingPartDefinitionDto>(null);
  const { floorId } = useParams<{ floorId: string }>();

  const { data, fetch } = useFetch(() => BuildingPartDefinitionControllerService.getAllBuildingPartDefinitions(floorId), [], {
    setLoading,
  });

  return (
    <BuildingsFloorsPartsContext.Provider
      value={{
        buildingPartDefinition: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <BuildingsFloorsPart />
    </BuildingsFloorsPartsContext.Provider>
  );
}
