import { Grid } from '@material-ui/core';
import React from 'react';

import { savePhysical, updatePhysical } from 'api/hs/definitions/health-definitions/physical-check';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { PhysicalCheckModel } from 'model/definitions/health-definitions/physicalCheck.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the PhysicalCheckModel is created/updated
   */
  onClose(success: boolean): void;

  data: PhysicalCheckModel;
}

export default function PhysicalCheck({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<PhysicalCheckModel>
      open={open}
      onClose={onClose}
      onSave={savePhysical}
      onUpdate={updatePhysical}
      data={data}
      resourceReloadName="physicalCheck"
      createTitle="Fizik Muayene Ekleme"
      updateTitle="Fizik Muayene Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Tahlil Adı Giriniz" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField name="description" label="Açıklama" rows={3} />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField name="defaultNegativeResponse" label="Otomatik Yanıt" rows={3} />
      </Grid>
    </BaseDefinitionForm>
  );
}
