import { Dialog as MUIDialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { savePeriodicExaminationConfigurations } from 'api/hs/examination/periodic-examination-configurations';
import Button from 'Common/Button';
import TextField from 'Common/Form/TextField';
import { dateToInputFormat, useAsyncEffect, useXhr } from 'utils';

import DateInput from '../../../../../Common/Form/DateInput';
import { EmployeeContext } from '../../employee.context';
import { PeriodicExaminationConfigurationContext } from '../periodic-examination-configurations.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

export default function FormDialogPolyclinicExamOpinions() {
  const classes = useStyles();
  const { editDialogOpened, setEditDialogOpened, periodicExaminationConfigurations, reload } = useContext(
    PeriodicExaminationConfigurationContext
  );

  const { refresh } = useContext(EmployeeContext);
  const { setValue, getValues, trigger, ...methods } = useForm();
  const { employeeId } = useParams<{ employeeId: string }>();

  const cancel = useCallback(() => {
    setEditDialogOpened(false);
  }, [setEditDialogOpened]);

  const submitForm = useXhr(
    async values => {
      await savePeriodicExaminationConfigurations({ ...values, employeeId });
      await reload();
      await refresh();
      setEditDialogOpened(false);
    },
    'Personel Muayene Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [employeeId, reload]
  );

  useAsyncEffect(async () => {
    if (periodicExaminationConfigurations) {
      await defer();
      setValue('examinationFrequencyInMonth', periodicExaminationConfigurations?.examinationFrequencyInMonth);
      setValue('nextExaminationDate', periodicExaminationConfigurations?.nextExaminationDate);
      trigger();
    }
  }, [periodicExaminationConfigurations, setValue, trigger]);

  const FrequencyInMonth = getValues('examinationFrequencyInMonth');

  useEffect(() => {
    if (!FrequencyInMonth) {
      setValue('nextExaminationDate', '');
    } else {
      const selectedDate = new Date();
      const newDate = new Date(selectedDate.getTime() + FrequencyInMonth * 30 * 24 * 60 * 60 * 1000);
      setValue('nextExaminationDate', `${dateToInputFormat(newDate)}`);
    }
    trigger();
  }, [FrequencyInMonth, setValue, trigger]);

  return (
    <MUIDialog open={editDialogOpened} fullWidth maxWidth="xs" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Periyodik Muayene Düzenle</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, getValues, setValue, trigger }}>
          <form id="edit-examinationFrequency-form" onSubmit={methods.handleSubmit(submitForm)}>
            <Grid container>
              <Grid item md={12} sm={12} lg={12}>
                <TextField type="number" required name="examinationFrequencyInMonth" label="Sıklık (Ay)" />
              </Grid>
              <Grid item md={12} sm={12} lg={12}>
                <DateInput type="date" required name="nextExaminationDate" label="Bir Sonraki Muayene Tarihi" />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right">
        <Button variant="contained" onClick={cancel}>
          Vazgeç
        </Button>
        <Button variant="contained" form="edit-examinationFrequency-form" type="submit" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
