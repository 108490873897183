/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RiskAssessmentFineKinneyScoreDto } from './RiskAssessmentFineKinneyScoreDto';
import type { RiskAssessmentMatrixScoreDto } from './RiskAssessmentMatrixScoreDto';

export type RiskAssessmentHazardDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    riskAssessmentId?: string;
    hazardCategoryId?: string;
    riskDescription?: string;
    hazardDescription?: string;
    description?: string;
    affected?: string;
    hazardCurrentPrecautionId?: string;
    revisePeriod?: number;
    reviseDate?: string;
    riskAssessmentType?: RiskAssessmentHazardDto.riskAssessmentType;
    riskAssessmentMatrixScore?: RiskAssessmentMatrixScoreDto;
    riskAssessmentFineKinneyScore?: RiskAssessmentFineKinneyScoreDto;
}

export namespace RiskAssessmentHazardDto {

    export enum riskAssessmentType {
        MATRIX = 'MATRIX',
        FINE_KINNEY = 'FINE_KINNEY',
    }


}
