import { ActionContext } from 'Actions/ActionListAdd/action.context';
import React, { useCallback, useContext, useState } from 'react';

import EditDialog from '../../../Actions/ActionListAdd/Action/List/EditDialog';
import ListTable from '../../../Actions/ActionListAdd/Action/List/ListTableData';
import { AccidentReviewControllerService } from '../../../api/client';
import HSTableAndPaginationAndTableContextProvider from '../../../Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';
import { AccidentReviewContext } from '../accident-review-context';
import NewRecordButton from '../NewRecordButton';
import Form from './Form';
import { COLUMNS } from './Form/columns';

export default () => {
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { token, insertable, accidentId } = useContext(AccidentReviewContext);

  const fetchActionList = useCallback(() => AccidentReviewControllerService.getActions(accidentId, null, token), [token, accidentId]);

  return (
    <ActionContext.Provider
      value={{
        newDialogOpened,
        setNewDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <HSTableAndPaginationAndTableContextProvider fetcher={fetchActionList} columns={COLUMNS}>
        {insertable && <NewRecordButton label={'Yeni Aksiyon Ekle'} onClick={() => setNewDialogOpened(true)} />}
        <ListTable />
        <EditDialog />
        <Form open={newDialogOpened} fetch={fetchActionList} onClose={() => setNewDialogOpened(false)} data={null} />
      </HSTableAndPaginationAndTableContextProvider>
    </ActionContext.Provider>
  );
};
