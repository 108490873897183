/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgotPasswordInformRequest } from '../models/ForgotPasswordInformRequest';
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { UserDto } from '../models/UserDto';
import type { UserEmployeeInfoDto } from '../models/UserEmployeeInfoDto';
import type { UserPasswordChangesDto } from '../models/UserPasswordChangesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns UserDto OK
     * @throws ApiError
     */
    public static partialUpdate1(
        id: string,
        requestBody: UserDto,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'PUT',
            path: `/users/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUser(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/users/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns UserDto OK
     * @throws ApiError
     */
    public static partialUpdate(
        id: string,
        requestBody: UserDto,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'PATCH',
            path: `/users/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateUserPassword(
        id: string,
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/users/reset-passwords/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static updateUserPasswordChange(
        requestBody: UserPasswordChangesDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/users/current/passwords`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userIds
     * @returns UserDto OK
     * @throws ApiError
     */
    public static getUsers(
        userIds: Array<string>,
    ): CancelablePromise<Array<UserDto>> {
        return __request({
            method: 'GET',
            path: `/users`,
            query: {
                'userIds': userIds,
            },
        });
    }

    /**
     * @param requestBody
     * @returns UserDto OK
     * @throws ApiError
     */
    public static saveUser(
        requestBody: UserDto,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'POST',
            path: `/users`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static setPasswordVerify(
        requestBody: ResetPasswordDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/users/reset-password-verify`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static forgotPasswordInform(
        requestBody: ForgotPasswordInformRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/users/forgot-password-inform`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserEmployeeInfoDto OK
     * @throws ApiError
     */
    public static getUserEmployeeInfos(): CancelablePromise<Array<UserEmployeeInfoDto>> {
        return __request({
            method: 'GET',
            path: `/users/employee-infos`,
        });
    }

    /**
     * @returns UserDto OK
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<UserDto> {
        return __request({
            method: 'GET',
            path: `/users/current`,
        });
    }

    /**
     * @returns UserEmployeeInfoDto OK
     * @throws ApiError
     */
    public static getUserEmployeeInfo(): CancelablePromise<UserEmployeeInfoDto> {
        return __request({
            method: 'GET',
            path: `/users/current/employee-infos`,
        });
    }

}