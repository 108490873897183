/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExaminationDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: number;
    examinationName?: string;
    statusId?: number;
    type?: ExaminationDefinitionDto.type;
    ibysReportType?: ExaminationDefinitionDto.ibysReportType;
}

export namespace ExaminationDefinitionDto {

    export enum type {
        HS_EXAMINATION = 'HS_EXAMINATION',
        POLYCLINIC_EXAMINATION = 'POLYCLINIC_EXAMINATION',
        OTHER = 'OTHER',
    }

    export enum ibysReportType {
        RECRUITMENT_EXAMINATION = 'RECRUITMENT_EXAMINATION',
        PERIODIC_EXAMINATION = 'PERIODIC_EXAMINATION',
        RETURN_TO_WORK_EXAMINATION = 'RETURN_TO_WORK_EXAMINATION',
        JOB_CHANGE_EXAMINATION = 'JOB_CHANGE_EXAMINATION',
        OTHER = 'OTHER',
    }


}
