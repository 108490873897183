import { Grid, Icon, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext } from 'react';

import { TrainingsViewContentResult } from 'api/hs/trainings/training-view';
import { ResourceContext } from 'context/resource.context';
import { useDateFormat } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

interface Props {
  trainingDetail: TrainingsViewContentResult;
}

export default function GeneralInfo({ trainingDetail }: Props) {
  const classes = useStyles();
  const { trainers } = useContext(ResourceContext);
  const [openGeneralInfo, setOpenGeneralInfo] = React.useState(true);
  const format = useDateFormat('dd MMMM yyyy HH:mm');

  const handleClick = () => {
    setOpenGeneralInfo(!openGeneralInfo);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon className="icon icon-school" />
        </ListItemIcon>
        <ListItemText primary="Eğitimin Genel Bilgileri" />
        {openGeneralInfo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openGeneralInfo} timeout="auto" unmountOnExit>
        <Grid container spacing={1} className="pb-2">
          <Grid item sm={6}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.textColor}>
                      Eğitim Adı
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Eğitimciler
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.trainers?.map(trainer => findByKey(trainers, 'id', trainer?.id)?.name).join(' , ')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Geçerlilik Süresi (Ay)
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.validityPeriodInMonth}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Planlanan Tarih
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.expiredDate ? format(new Date(trainingDetail?.expiredDate)) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.textColor}>
                      Eğitim Yeri
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.location}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={6} className={classes.aside}>
            <TableContainer>
              <Table size="small" aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Yıllık Plan Dahilinde
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.isInAnnualPlan ? 'Evet' : 'Hayır'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      İş yerinde
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.atWorkplace === '1' ? 'Evet' : 'Hayır'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Eğitim Şekli
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.faceToFace === '1' ? 'Yüzyüze' : 'Uzaktan'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Son Geçerlilik Tarihi
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.completedDate ? format(new Date(trainingDetail?.completedDate)) : null}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.textColor} component="th" scope="row">
                      Eğitimin Toplam Süresi (dk)
                    </TableCell>
                    <TableCell className={classes.textColor2} align="left">
                      {trainingDetail?.durationInMinutes}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
