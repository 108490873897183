import React from 'react';

import Trainers from './Trainers';
import Test from './TrainingDefinitions';
import TrainingMainGroup from './TrainingMainGroup';
import TrainingGroup from './trainings-group-definitions';
interface TrainingDefinitionsListItem {
  label: string;
  Component?: React.ComponentType;
  path: string;
}

export default [
  {
    label: 'KONU ANA GRUPLARI',
    Component: TrainingMainGroup,
    path: 'training-main-group',
  },
  {
    label: 'KONU GRUPLARI',
    Component: TrainingGroup,
    path: 'egitim-grup',
  },
  {
    label: 'KONULAR',
    Component: Test,
    path: '',
  },
  {
    label: 'EĞİTMENLER',
    Component: Trainers,
    path: 'trainers',
  },
] as TrainingDefinitionsListItem[];
