import { FormControl, IconButton, InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { debounceWithKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { stopPropagation } from 'utils';

import { FormContext } from '../Form/form.context';
import { MedicineCacheContext } from './medicine.context';
import { Medicine } from './medicine.model';

type ValueType = Medicine['id'];
type Multiple = false;
type DisableClearable = false;
type FreeSolo = false;
type AutoCompletePropType = AutocompleteProps<ValueType, Multiple, DisableClearable, FreeSolo>;
type OnChangeFunc = (value: ValueType) => void;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  listbox: {
    maxHeight: 250,
  },
  inputSuffix: {
    '& > *': {
      position: 'static',
    },
  },
  yellowStar: {
    color: theme.palette.warning.light,
  },
}));

const DEFAULT_VALUE: ValueType = null;

interface Props {
  name: string;
  label?: string;
  variant?: TextFieldProps['variant'];
  disabled?: boolean;
}

export default function MedicineSelection({ name, variant = 'standard', label = 'İlaç', disabled = false }: Props) {
  const classes = useStyles();
  const { control, getValues, setValue, trigger } = useFormContext();
  const { readonly } = useContext(FormContext);
  const [searchDebounceKey] = useState(Symbol('search debounce key'));
  const [searchText, setSearchText] = useState('');
  const {
    medicines,
    searchMedicines,
    labels,
    favourites,
    addToFavourites,
    removeFromFavourites,
    openFavouritesDialog,
    fetchMedicineDetails,
  } = useContext(MedicineCacheContext);

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  /**
   * Current Value
   */
  const currentValue = getValues(name) ?? null;

  /**
   * Autocomplete options
   */
  const options = useMemo(() => medicines.map(({ id }) => id), [medicines]);

  /**
   * Open favourites form
   */
  const openFavourites = useCallback(async () => {
    const res = await openFavouritesDialog(currentValue);

    if (!res) {
      return;
    }

    setSearchText(labels[res]);
    setValue(name, res);
    trigger();
  }, [openFavouritesDialog, setValue, trigger, name, labels, currentValue]);

  /**
   * Input component
   */
  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        variant={variant}
        label={label}
        InputProps={{
          ...params.InputProps,
          className: `${params.InputProps.className} px-0`,
          placeholder: 'İlaç ara',
          readOnly: !!currentValue,
          endAdornment: (
            <InputAdornment position="end" onClick={stopPropagation} className={classes.inputSuffix}>
              {params.InputProps.endAdornment}
              <IconButton size="small" className="ml-1" onClick={openFavourites}>
                <StarBorderIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    ),
    [label, variant, currentValue, classes, openFavourites]
  );

  /**
   * Toggle favourite
   */
  const handleToggleFavourite = useCallback(
    (id: ValueType) => (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!favourites.includes(id)) {
        addToFavourites(id);
      } else {
        removeFromFavourites(id);
      }
    },
    [favourites, addToFavourites, removeFromFavourites]
  );

  /**
   * Render select options
   */
  const renderOption = useCallback(
    (option: ValueType) => (
      <>
        <IconButton size="small" className="mr-2" onClick={handleToggleFavourite(option)}>
          {favourites.includes(option) ? <StarIcon className={classes.yellowStar} /> : <StarBorderIcon />}
        </IconButton>
        {labels[option]}
      </>
    ),
    [labels, favourites, handleToggleFavourite, classes]
  );

  /**
   * Handle search
   */
  const handleInputChange = useCallback(
    async (_, value: string, reason) => {
      if (reason === 'reset' || notEditable) {
        return;
      }

      setSearchText(value);

      if (value) {
        debounceWithKey(searchDebounceKey, searchMedicines, 300, null, value);
      }

      if (currentValue) {
        setValue(name, null);
      }
    },
    [notEditable, searchDebounceKey, searchMedicines, setValue, currentValue, name]
  );

  /**
   * Get option label
   */
  const getOptionLabel: AutoCompletePropType['getOptionLabel'] = useCallback(value => labels[value] ?? '...', [labels]);

  /**
   * Handle autocomplete value change
   */
  const handleAutocompleteChange: (onChange: OnChangeFunc) => AutoCompletePropType['onChange'] = useCallback(
    onChange => (_, value) => {
      onChange(value);
      trigger();

      setSearchText(value ? labels[value] : '');
    },
    [trigger, labels]
  );

  /**
   * Autocomplete logic
   */
  const renderAutocomplete: ControllerProps<'select'>['render'] = useCallback(
    ({ onChange, value }) => (
      <Autocomplete<ValueType, Multiple, DisableClearable, FreeSolo>
        options={options}
        noOptionsText="Sonuç bulunamadı"
        renderInput={renderInput}
        renderOption={renderOption}
        inputValue={searchText}
        onInputChange={handleInputChange}
        getOptionLabel={getOptionLabel}
        onChange={handleAutocompleteChange(onChange)}
        value={value ?? null}
      />
    ),
    [renderInput, searchText, handleInputChange, options, getOptionLabel, handleAutocompleteChange, renderOption]
  );

  /**
   * Fetch medicine label
   */
  useEffect(() => {
    if (currentValue) {
      fetchMedicineDetails(currentValue);
    }
  }, [currentValue, fetchMedicineDetails]);

  /**
   * Empty out input text when current value is set to null
   */
  useEffect(() => {
    if (!currentValue) {
      setSearchText('');
    }
  }, [currentValue]);

  return (
    <FormControl fullWidth className={classes.root}>
      <Controller control={control} defaultValue={DEFAULT_VALUE} name={name} render={renderAutocomplete} />
    </FormControl>
  );
}
