// tslint:disable:max-classes-per-file

import { findByKey } from '@thalesrc/js-utils';

import { SystemCheckResult } from 'api/hs/examination/system-checks/system-check.result';
import { modelDataMatcher } from 'utils';

import { GetSystemCheckResult } from '../../resource';

function getColorByValue(value: number): string {
  switch (value) {
    case 0:
      return '#469789';
    case 1:
      return '#eb4355';
    default:
      return '#c0c0c0';
  }
}

function getTextByValue(value: number): string {
  switch (value) {
    case 0:
      return 'Yok';
    case 1:
      return 'Var';
    default:
      return 'Belirsiz';
  }
}

export class SystemCheckModel {
  public id: string = null;
  public valueText: string = null;
  public color: string = null;
  public description: string = null;
  public bodySystemsCheckStatusDefinitionId: number = null;
  public bodySystemsCheckDefinitionId: number = null;
  public bodySystemsCheckDefinitionName: string = null;
  public bodySystemsCheckDefinitionDescription: string = null;

  constructor(data: Partial<SystemCheckModel> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromEnumAndServerData(resource: GetSystemCheckResult[], data: SystemCheckResult[]): SystemCheckModel[] {
    return resource.map(item => {
      const serverData = findByKey(data, 'bodySystemsCheckDefinitionId', item.id);
      return new SystemCheckModel({
        id: typeof serverData?.id === 'string' ? serverData?.id : null,
        bodySystemsCheckDefinitionId: item.id,
        bodySystemsCheckDefinitionName: item.label,
        bodySystemsCheckDefinitionDescription: item.description,
        description: typeof serverData?.description === 'string' ? serverData?.description : null,
        bodySystemsCheckStatusDefinitionId:
          typeof serverData?.bodySystemsCheckStatusDefinitionId === 'number' ? serverData?.bodySystemsCheckStatusDefinitionId : 2,
        valueText:
          typeof serverData?.bodySystemsCheckStatusDefinitionId === 'number'
            ? getTextByValue(serverData?.bodySystemsCheckStatusDefinitionId)
            : getTextByValue(2),
        color:
          typeof serverData?.bodySystemsCheckStatusDefinitionId === 'number'
            ? getColorByValue(serverData?.bodySystemsCheckStatusDefinitionId)
            : getColorByValue(2),
      });
    });
  }
}
