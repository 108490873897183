import React, { useContext } from 'react';

import BaseReports from '../../Reports';
import { ExaminationDetailContext } from './examination-detail.context';

export default function Reports() {
  const { diagnosis } = useContext(ExaminationDetailContext);

  return <BaseReports diagnosis={diagnosis} />;
}
