import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { TrainingEmployeesPayload } from './training-employees.payload';
import { TrainingEmployeesResult } from './training-employees.result';

export async function updateTrainingEmployees(trainingId: string, body: TrainingEmployeesPayload) {
  const [error, res] = await tryCatch(HSApi.put<TrainingEmployeesResult[]>(`/trainings/${trainingId}/training-employees`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error.response;
  }

  return res.data;
}
