import { ToggleButton, ToggleButtonGroup as MUIToggleButtonGroup } from '@material-ui/lab';
import React, { cloneElement, useCallback, useContext, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { FormContext } from './Form/form.context';
import { SelectOption } from './Select';

export type ToggleButtonGroupRules = ControllerProps<'input'>['rules'];

interface Props<T, U = {}> {
  name: string;
  options: (SelectOption<T> & U)[];
  defaultValue?: T;
  rules?: ToggleButtonGroupRules;
  renderOption?(option: SelectOption<T> & U & { selected: boolean; notEditable: boolean }): JSX.Element;
  disabled?: boolean;
}

function defaultRenderOption<T, U>({ text, value }: SelectOption<T> & U) {
  return <ToggleButton value={value}>{text}</ToggleButton>;
}

const EMPTY_DEFAULT = Symbol();

export default function ToggleButtonGroup<T = any, U = {}>({
  name,
  options,
  rules = {},
  renderOption = defaultRenderOption,
  defaultValue = EMPTY_DEFAULT as any,
  disabled = false,
}: Props<T, U>) {
  const { control, trigger } = useFormContext();
  const { readonly } = useContext(FormContext);

  const momoizedDefaultValue = useMemo<T>(() => (defaultValue === (EMPTY_DEFAULT as any) ? null : defaultValue), [defaultValue]);
  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const render = useCallback(
    ({ onChange, value }) => (
      <MUIToggleButtonGroup
        value={value}
        exclusive
        onChange={(e, v: T) => {
          if (v === null) {
            return;
          }

          onChange(v);
          trigger();
        }}>
        {options.map((option, index) =>
          cloneElement(renderOption({ ...option, selected: value === option.value, notEditable }), { key: index })
        )}
      </MUIToggleButtonGroup>
    ),
    [trigger, options, renderOption, notEditable]
  );

  return <Controller rules={rules} control={control} defaultValue={momoizedDefaultValue} name={name} render={render} />;
}
