import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useState } from 'react';

import { WorkEquipmentLayoutView } from 'api/client';
import { WorkEquipmentLayoutControllerService } from 'api/client';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import { TableColumn } from 'Common/Table/Raw/table-column.model';
import { DialogContext } from 'context/dialog.context';
import { useXhr } from 'utils';

import { WorkEquipmentLayoutContext } from '../work-equipment-layout.context';

function ActionComponent({ row }: { row: WorkEquipmentLayoutView }) {
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { openWarnDialog } = useContext(DialogContext);
  const { refresh } = useContext(HSTableContext);
  const { setSelectedData, setEditDialogOpened } = useContext(WorkEquipmentLayoutContext);

  const deleteFileReq = useXhr(
    async (layoutId: string) => {
      await WorkEquipmentLayoutControllerService.deleteAllWorkEquipmentLayout(layoutId);
      await refresh();
    },
    'Seçili Kayıt Silindi',
    'Eğitim silinirken bir hata oluştu',
    [refresh]
  );

  const removeFile = useCallback(
    async item => {
      const submitted = await openWarnDialog({
        text: `${item?.definitionName} isimli iş Ekipman Tanımını silmek istediğinize emin misiniz?`,
      });
      closeMenu();
      if (!submitted) {
        return;
      }

      await deleteFileReq(item?.layoutId);
    },
    [deleteFileReq, openWarnDialog, closeMenu]
  );

  const editFormOpen = useCallback(
    item => {
      closeMenu();
      setEditDialogOpened(true);
      setSelectedData(item);
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );
  return (
    <div key={row?.layoutId}>
      <IconButton id={row?.layoutId} onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem id={row?.layoutId} onClick={() => removeFile(row)}>
          <ListItemIcon>
            <DeleteIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem id={row?.layoutId} onClick={() => editFormOpen(row)}>
          <ListItemIcon>
            <EditIcon fontSize="default" />
          </ListItemIcon>
          <Typography variant="inherit">Düzenle</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export const CELL: TableColumn<WorkEquipmentLayoutView>[] = [
  {
    id: 'imagePath',
    render(row): JSX.Element {
      return (
        <Avatar alt={row?.definitionName} src={row?.imagePath ? `/saglik-gozetimi/public/images/work_equipment/${row?.imagePath}` : null} />
      );
    },
    label: 'Resim',
    sortable: false,
    width: '5%',
  },
  {
    id: 'definitionName',
    defaultVisibility: true,
    label: 'Tanım Adı',
    sortable: true,
    width: '10%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '10%',
  },
  {
    id: 'partName',
    defaultVisibility: true,
    label: 'Bulunduğu Yer',
    sortable: true,
    width: '10%',
    render(row: WorkEquipmentLayoutView): string {
      return row?.partName ?? row?.layoutDescription;
    },
  },
  {
    id: 'typeName',
    defaultVisibility: true,
    label: 'Kategorisi',
    width: '10%',
    sortable: true,
  },
  {
    id: 'brand',
    defaultVisibility: true,
    label: 'Marka',
    sortable: true,
    width: '10%',
  },
  {
    id: 'model',
    defaultVisibility: true,
    label: 'Model',
    sortable: true,
    width: '10%',
  },
  {
    id: 'buildingName',
    defaultVisibility: true,
    label: 'Bina Adı',
    sortable: true,
    width: '10%',
  },
  {
    id: 'serialNumber',
    defaultVisibility: true,
    label: 'Seri Numarası',
    width: '10%',
  },
  {
    id: 'trackingNumber',
    defaultVisibility: true,
    label: 'Takip Numarası',
    width: '10%',
  },
  {
    id: 'layoutId',
    label: 'İşlemler',
    width: '5%',
    render(row: WorkEquipmentLayoutView): JSX.Element {
      return <ActionComponent key={row?.layoutId} row={row} />;
    },
  },
];
