import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { noop } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { EmployeeWidgetControllerService } from '../../../../api/client';
import { exportActions, exportEmployeeWidget, getActions } from '../../../../api/hs/dashboard/widget/widget';
import { DateRange, EmployeeWidgetType } from '../../../../api/hs/dashboard/widget/widget.payload';
import { getEmployeeWidgetViewCount } from '../../../../api/hs/employee';
import IndicatorWrapper from '../../../../Common/IndicatorWrapper';
import { useFetch } from '../../../../utils';
import colors from '../../colors';
import { DialogContext } from '../../dialog.context';
import Header from '../Header';
import EmployeeWidgetTable from './EmployeeWidgetTable';

const fileNames = {
  employeeActive: 'aktif-personel.xlsx',
  employeeNew: 'yeni-personel.xlsx',
};

export default () => {
  const [tab, setTab] = useState<string>('employeeActive');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: employeeActive } = useFetch(
    () => getEmployeeWidgetViewCount(new DateRange(dateRange?.start, dateRange?.end), EmployeeWidgetType.EMPLOYEE_ACTIVE),
    [],
    { setLoading }
  );
  const { data: employeeNew } = useFetch(
    () => getEmployeeWidgetViewCount(new DateRange(dateRange?.start, dateRange?.end), EmployeeWidgetType.EMPLOYEE_NEW),
    [],
    { setLoading }
  );

  const tabs = { employeeActive, employeeNew };

  const {
    employeeWidget: { close, opened },
    employeeWidgetDateRange: { dateRange },
  } = useContext(DialogContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleExcelExport = () => {
    if (tab === 'employeeActive') {
      exportEmployeeWidget(new DateRange(dateRange.start, dateRange.end), EmployeeWidgetType.EMPLOYEE_ACTIVE, fileNames[tab]).then(noop);
    }

    if (tab === 'employeeNew') {
      exportEmployeeWidget(new DateRange(dateRange.start, dateRange.end), EmployeeWidgetType.EMPLOYEE_NEW, fileNames[tab]).then(noop);
    }
  };

  return (
    <TabContext value={tab.toString()}>
      <Dialog open={opened} onClose={close} fullWidth maxWidth="md">
        <Header title="Personel Bilgisi" color={colors.employee} onClick={tabs[tab]?.length > 0 && handleExcelExport} />
        <DialogTitle>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab wrapped label={`Personel Sayısı (${employeeActive.length ?? 0})`} value={'employeeActive'} />
            <Tab wrapped label={`Yeni Personel (${employeeNew.length ?? 0})`} value={'employeeNew'} />
          </Tabs>
        </DialogTitle>
        <DialogContent style={{ height: '300px' }}>
          <TabPanel value={'employeeActive'}>
            <IndicatorWrapper data={employeeActive} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <EmployeeWidgetTable employee={employeeActive} />
            </IndicatorWrapper>
          </TabPanel>
          <TabPanel value={'employeeNew'}>
            <IndicatorWrapper data={employeeNew} loading={loading} errorMessage="Listelenecek Kayıt Bulunamadı">
              <EmployeeWidgetTable employee={employeeNew} />
            </IndicatorWrapper>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};
