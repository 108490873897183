import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../HSApi';

interface Payload {
  companyId: string;
  startDate: string;
  endDate: string;
}
export async function WorkingHoursExportTemplate(payload: Payload): Promise<any> {
  const res = await HSApi.get(
    `/working-hours/template/export?companyId=${payload.companyId}&startDate=${payload.startDate}&endDate=${payload.endDate}`,
    { responseType: 'blob' }
  );
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'calisma-saatleri.xlsx';
  a.click();
}
export async function saveWorkingHoursFileUpload(companyId: string, file: File) {
  const data = new FormData();
  data.set('file', file);

  const [error, res] = await tryCatch(HSApi.post(`/working-hours/import?companyId=${companyId}`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}
