import { Grid } from '@material-ui/core';
import React from 'react';

import { MatrixHazardScoreDefinitionDto } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateFirmRiskAssessmentsMatrixScoreDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: MatrixHazardScoreDefinitionDto;

  onClose(success: boolean): void;
}

export default function MatrixHazardScoreDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<MatrixHazardScoreDefinitionDto>
      open={open}
      onClose={onClose}
      onUpdate={updateFirmRiskAssessmentsMatrixScoreDefinitionUpdate}
      data={data}
      createTitle="Matris Skor Ekleme"
      updateTitle="Matris Skor Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="Risk Adı" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="score" label="Risk Skoru" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="description" label="Eylem" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField rows={3} required name="deadline" label="Termin Süresi" />
      </Grid>
    </BaseDefinitionForm>
  );
}
