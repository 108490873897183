/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PpeClassificationDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    predefinedId?: string;
    source?: PpeClassificationDefinitionDto.source;
    label?: string;
}

export namespace PpeClassificationDefinitionDto {

    export enum source {
        SYSTEM = 'SYSTEM',
        USER_DEFINED = 'USER_DEFINED',
    }


}
