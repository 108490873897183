import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';

import { SelectOptionsContext } from '../../../../context/select-options.context';

interface WorkTypeParams {
  required: boolean;
}

export default function WorkType({ required }: WorkTypeParams) {
  const { vehicleOperationDefinitions, workingPositionDefinitions, workingTimeDefinitions } = useContext(SelectOptionsContext);

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12}>
        <AutoComplete
          name="vehicleOperationDefinitions"
          options={vehicleOperationDefinitions}
          label="Çalışma Şekli"
          single
          required={required}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <AutoComplete
          name="workingPositionDefinitions"
          options={workingPositionDefinitions}
          label="Çalışma Pozisyonu"
          single
          required={required}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <AutoComplete name="workingTimeDefinitions" options={workingTimeDefinitions} label="Çalışma Zamanı" single required={required} />
      </Grid>
    </Grid>
  );
}
