/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Icd10WarningLabelDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    icd10Id?: string;
    label?: string;
    viewType?: Icd10WarningLabelDto.viewType;
}

export namespace Icd10WarningLabelDto {

    export enum viewType {
        LAST_EXAMINATION = 'LAST_EXAMINATION',
        ALL_EXAMINATION = 'ALL_EXAMINATION',
    }


}
