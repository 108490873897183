import React from 'react';

import AssigmentEmployeeAdd from './add';
import AssigmentEmployeeList from './list';

export default function Index() {
  return (
    <>
      <AssigmentEmployeeAdd />
      <AssigmentEmployeeList />
    </>
  );
}
