import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { defer, findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { RiskAssessmentHazardControllerService, RiskAssessmentHazardDto } from 'api/client';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import DateInput from 'Common/Form/DateInput';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { dateToInputFormat, useAsyncEffect, useTriggerRender } from 'utils';

import { AlertContext } from '../../../context/alert.context';
import { ResourceContext } from '../../../context/resource.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: RiskAssessmentHazardDto;
}

export default function BalanceRiskForm({ open, onClose, data }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const {
    matrixPossibilityDefinition: possibilityDefinitionMatrix,
    matrixHazardDamageDefinition: damageDefinitionMatrix,
    riskAssessmentMatrixScoreDefinition,
    fineKinneyHazardScoreDefinition,
    fineKinneyPossibilityDefinition: possibilityDefinitionFineKinney,
    fineKinneyHazardDamageDefinition: damageDefinitionFineKinney,
    fineKinneyHazardFrequencyDefinition: frequencyDefinitionFineKinney,
  } = useContext(ResourceContext);

  const hazardPossibilityValue: number = findByKey(
    possibilityDefinitionMatrix,
    'id',
    formRef?.current?.methods?.getValues('finalHazardPossibilityId')
  )?.value;
  const hazardDamageValue: number = findByKey(damageDefinitionMatrix, 'id', formRef?.current?.methods?.getValues('finalHazardDamageId'))
    ?.value;
  const riskMatrixScoreValue = hazardPossibilityValue * hazardDamageValue;

  const fineKinneyDamageValue: number = findByKey(
    damageDefinitionFineKinney,
    'id',
    formRef?.current?.methods?.getValues('finalHazardDamageId')
  )?.value;
  const fineKinneyPossibilityValue: number = +findByKey(
    possibilityDefinitionFineKinney,
    'id',
    formRef?.current?.methods?.getValues('finalHazardPossibilityId')
  )?.value;
  const fineKinneyFrequencyValue: number = +findByKey(
    frequencyDefinitionFineKinney,
    'id',
    formRef?.current?.methods?.getValues('finalHazardFrequencyId')
  )?.value;
  const fineKinneyScoreValue = fineKinneyDamageValue * fineKinneyPossibilityValue * fineKinneyFrequencyValue;

  const {
    matrixPossibilityDefinition,
    matrixHazardDamageDefinition,
    fineKinneyHazardDamageDefinition,
    fineKinneyHazardFrequencyDefinition,
    fineKinneyPossibilityDefinition,
  } = useContext(SelectOptionsContext);

  const submit = useCallback(
    async values => {
      try {
        const payload = {
          revisePeriod: values?.revisePeriod,
          reviseDate: values?.reviseDate,
        };

        const RiskAssessmentMatrixPayload = {
          ...payload,
          riskAssessmentMatrixScore: {
            finalHazardPossibilityId: values?.finalHazardPossibilityId,
            finalHazardDamageId: values?.finalHazardDamageId,
            finalHazardScoreId: findByKey(riskAssessmentMatrixScoreDefinition, 'score', String(riskMatrixScoreValue))?.id,
          },
        };

        const RiskAssessmentFineKinneyPayload = {
          ...payload,
          riskAssessmentFineKinneyScore: {
            finalHazardPossibilityId: values?.finalHazardPossibilityId,
            finalHazardDamageId: values?.finalHazardDamageId,
            finalHazardFrequencyId: values?.finalHazardFrequencyId,
            finalHazardScoreId: findByKey(fineKinneyHazardScoreDefinition, 'score', String(fineKinneyScoreValue))?.id,
          },
        };

        await RiskAssessmentHazardControllerService.updatePartialRiskAssessmentHazard(
          data?.id,
          data?.riskAssessmentType === 'MATRIX' ? RiskAssessmentMatrixPayload : RiskAssessmentFineKinneyPayload
        );
        showMessage('Bakiye Risk Ekleme işlemi başarılı.', 'success', 'info');
        onClose(true);
      } catch (e) {
        showMessage('Bakiye Risk Ekleme işlemi başarısız.', 'error', 'info');
      }
    },
    [
      data?.riskAssessmentType,
      fineKinneyHazardScoreDefinition,
      fineKinneyScoreValue,
      riskAssessmentMatrixScoreDefinition,
      riskMatrixScoreValue,
      onClose,
      showMessage,
      data?.id,
    ]
  );

  const FrequencyInMonth = formRef?.current?.methods?.getValues('revisePeriod');

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }

    formRef?.current?.methods?.trigger();
  }, [data, open]);

  useEffect(() => {
    if (!FrequencyInMonth) {
      formRef?.current?.methods?.setValue('reviseDate', '');
    } else {
      const selectedDate = new Date();
      const newDate = new Date(selectedDate.getTime() + FrequencyInMonth * 30 * 24 * 60 * 60 * 1000);
      formRef?.current?.methods?.setValue('reviseDate', `${dateToInputFormat(newDate)}`);
    }
    formRef?.current?.methods?.trigger();
  }, [FrequencyInMonth]);

  return (
    <>
      <Dialog color="secondary" opened={open} onClose={onClose.bind(null, false)} title={'Bakiye Risk Düzenle'}>
        <Form onChange={reRender} onSubmit={submit} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Alert icon={false} variant="outlined" severity="warning">
                <AlertTitle style={{ fontWeight: 'bold' }}>
                  Risk Değerlendirme Skoru
                  <Divider />
                </AlertTitle>
                <Grid container spacing={1}>
                  {data?.riskAssessmentFineKinneyScore ? (
                    <>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                          Skor:{' '}
                          {findByKey(fineKinneyHazardScoreDefinition, 'id', data?.riskAssessmentFineKinneyScore?.hazardScoreId)?.score +
                            ' - ' +
                            findByKey(fineKinneyHazardScoreDefinition, 'id', data?.riskAssessmentFineKinneyScore?.hazardScoreId)?.label}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={12} lg={12} sm={12}>
                        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                          Skor:{' '}
                          {findByKey(riskAssessmentMatrixScoreDefinition, 'id', data?.riskAssessmentMatrixScore?.hazardScoreId)?.score +
                            ' - ' +
                            findByKey(riskAssessmentMatrixScoreDefinition, 'id', data?.riskAssessmentMatrixScore?.hazardScoreId)?.label}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Alert>
            </Grid>
            {data?.riskAssessmentMatrixScore ? (
              <>
                <Grid item xs={6} md={6} lg={6} sm={6}>
                  <AutoComplete single name="finalHazardPossibilityId" options={matrixPossibilityDefinition} label="Olasılık" />
                </Grid>
                <Grid item xs={6} md={6} lg={6} sm={6}>
                  <AutoComplete single name="finalHazardDamageId" options={matrixHazardDamageDefinition} label="Şiddet" />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete single name="finalHazardPossibilityId" options={fineKinneyPossibilityDefinition} label="Olasılık" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete single name="finalHazardFrequencyId" options={fineKinneyHazardFrequencyDefinition} label="Sıklık" />
                </Grid>
                <Grid item xs={4} md={4} lg={4} sm={4}>
                  <AutoComplete single name="finalHazardDamageId" options={fineKinneyHazardDamageDefinition} label="Şiddet" />
                </Grid>
              </>
            )}
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <TextField name="revisePeriod" type="number" label="Revizyon Sıklığı (ay)" />
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <DateInput label="Bir Sonraki Revize Tarihi" name="reviseDate" type="date" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                Bakiye Risk Kaydet
              </Button>
              <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
