import { createContext } from 'react';

import { TrainingMainGroupModel, TrainingMainGroupResult } from 'api/hs/definitions/trainings/training-main-group';

export interface TrainingMainGroupContextType {
  trainingMainGroup: TrainingMainGroupResult[];

  loading: boolean;

  reload(): void;

  setLoading(loading: boolean): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: TrainingMainGroupModel;

  setSelectedData(data: TrainingMainGroupModel): void;
}

export const TrainingMainGroupContext = createContext<TrainingMainGroupContextType>(null);
