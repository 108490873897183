import { Hidden, IconButton, SwipeableDrawer } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router';

import Header from './Header';
import Logo from './Logo';
import Sidebar from './Sidebar';
import {LicenceContext} from "../context/licence";

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
      topCont: {
          width: `100%`,
          flex: 1,
          [theme.breakpoints.down('sm')]: {
              width: '100%',
              marginLeft: 0,
          },
      },
      botCont: {
          width: `100%`,
          flex: 0,
          [theme.breakpoints.down('sm')]: {
              width: '100%',
              marginLeft: 0,
          },
      },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
        display: 'flex',
        minHeight: '100%',
        justifyContent: 'space-between'
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    header: {
      alignItems: 'stretch',
    },
    content: {
      width: '100%',
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
      gridTemplateColumns: '100%',
      backgroundColor: theme.palette.background.default,
    },
    contentWrapper: {
      height: '100%',
      overflow: 'auto',
    },
    mobileHeaderLeft: {
      backgroundColor: theme.palette.common.white,
      minWidth: 'max-content',
      display: 'flex',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginLeft: -theme.spacing(3),
      position: 'relative',

      '&::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: 0,
        left: '100%',
        width: 64,
        height: 64,
        transform: 'rotate(-60deg) scale(1.15) translateX(0) translateY(0)',
        transformOrigin: 'left bottom',
        zIndex: -1,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 2px 2px -1px rgba(0,0,0,0.1), 0px 4px 3px 0px rgba(0,0,0,0.07), 0px 1px 5px 0px rgba(0,0,0,0.06)',

        [theme.breakpoints.down('xs')]: {
          width: 56,
          height: 56,
        },
      },
    },
  })
);

// tslint:disable-next-line:no-empty-interface
interface Props {}

export default function Layout({ children }: PropsWithChildren<Props>) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const { pathname } = useLocation();
  const classes = useStyles();
  const { licence } = useContext(LicenceContext);

  useEffect(() => {
    setMobileDrawerOpen(false);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.header}>
          <Hidden mdUp implementation="js">
            <div className={classes.mobileHeaderLeft}>
              <IconButton onClick={() => setMobileDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
              <Logo className="ml-1" />
            </div>
          </Hidden>
          <Header />
        </Toolbar>
      </AppBar>
      <Hidden smDown implementation="js">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left">
            <div className={classes.topCont}>
                <Logo className={classes.toolbar} />
                <Divider />
                <Sidebar />
            </div>
            {(licence != undefined && licence.login_logo != undefined && licence.login_logo !== null)?<div className={classes.botCont}>
                <Divider />
                <Logo onlyArti={true} className={classes.toolbar} />
            </div>:null}
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="js">
        <SwipeableDrawer
          anchor="left"
          open={mobileDrawerOpen}
          onClose={() => setMobileDrawerOpen(false)}
          onOpen={() => setMobileDrawerOpen(true)}
          classes={{
            paper: classes.drawerPaper,
          }}>
            <div className={classes.topCont}>
          <div className="my-3">
            <Logo className="ml-1" />
          </div>
          <Sidebar />
            </div>
            {(licence != undefined && licence.login_logo != undefined && licence.login_logo !== null)?<div className={classes.botCont}>
                <Divider />
                <Logo onlyArti={true} className={classes.toolbar} />
            </div>:null}
        </SwipeableDrawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentWrapper}>{children}</div>
      </main>
    </div>
  );
}
