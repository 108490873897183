/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MatrixHazardDamageDefinitionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    firmId?: string;
    predefinedId?: string;
    source?: MatrixHazardDamageDefinitionDto.source;
    label?: string;
    value?: number;
}

export namespace MatrixHazardDamageDefinitionDto {

    export enum source {
        SYSTEM = 'SYSTEM',
        USER_DEFINED = 'USER_DEFINED',
    }


}
