/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionFilterDto } from '../models/ActionFilterDto';
import type { PageActionView } from '../models/PageActionView';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ActionViewControllerService {

    /**
     * @param requestBody
     * @returns PageActionView OK
     * @throws ApiError
     */
    public static filterActions(
        requestBody?: ActionFilterDto,
    ): CancelablePromise<PageActionView> {
        return __request({
            method: 'POST',
            path: `/actions/views`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public static examinationFilterViewExport2(
        requestBody: ActionFilterDto,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'POST',
            path: `/actions/views/export`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}