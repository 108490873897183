/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FamilyHistoryIcd10Dto } from './FamilyHistoryIcd10Dto';

export type FamilyHistoryDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    employeeId?: string;
    examinationId?: string;
    status?: FamilyHistoryDto.status;
    bloodRelationDefinitionId?: string;
    familyHistoryIcd10s?: Array<FamilyHistoryIcd10Dto>;
}

export namespace FamilyHistoryDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
