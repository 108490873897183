/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProtocolNumber } from './ProtocolNumber';
import type { RestReportIcd10Dto } from './RestReportIcd10Dto';

export type RestReportDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    durationInDays?: number;
    hasIncapacityReport?: boolean;
    examinationId?: string;
    employeeId?: string;
    isInternal?: boolean;
    restReportReasonDefinitionId?: string;
    status?: RestReportDto.status;
    restReportIcd10s?: Array<RestReportIcd10Dto>;
    protocolNumber?: ProtocolNumber;
}

export namespace RestReportDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
