import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
import React from 'react';

interface Props {
  anchor: Element;
  children: React.ReactNode;

  setAnchor(param: any): void;

  hide(): void;
}

export default (props: Props) => {
  const handleClose = () => {
    props.setAnchor(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={props.anchor}
      open={Boolean(props.anchor)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      elevation={0}>
      <MenuList>
        {props.children}

        <Divider />
        <MenuItem onClick={props.hide}>
          <ListItemIcon>
            <VisibilityOff fontSize="small" />
          </ListItemIcon>
          <ListItemText>Gizle</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
