import React from 'react';

import PPEDefinitionsList from './List';
export default function Index() {
  return (
    <>
      <PPEDefinitionsList />
    </>
  );
}
