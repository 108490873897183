import React, { useCallback, useContext } from 'react';

import { TrainingDetailContext } from '../training-detail.context';
import ShowDialogTraining from './ShowDialogTraining';

export default function ShowDialog() {
  const { setShowDialogTraining, showDialogTraining, selectedData, setSelectedData } = useContext(TrainingDetailContext);

  const closeDialog = useCallback(
    (success: boolean) => {
      setShowDialogTraining(false);
      setSelectedData(null);
    },
    [setShowDialogTraining, setSelectedData]
  );

  return <ShowDialogTraining open={showDialogTraining} onClose={closeDialog} data={selectedData} />;
}
