import React from 'react';

import { Licence } from './licence.model';

export interface LicenceContextType {
  licence: Licence;
  setLicence(v: Licence): void;
}

export const LicenceContext = React.createContext<LicenceContextType>(null);
