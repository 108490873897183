import { makeStyles, Popover, PopoverOrigin } from '@material-ui/core';
import { debounceWithKey, difference } from '@thalesrc/js-utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toState } from 'react-color/lib/helpers/color';
import { useFormContext } from 'react-hook-form';

import { useDialogState, useTranslate } from 'utils';

import { Color, ColorPickerContext, ColorPickerContextType } from './color-picker.context';
import Input from './Input';
import Picker from './Picker';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  className?: string;
  defaultValue?: string;
}

const useStyles = makeStyles(theme => ({
  popoverPaper: {
    overflowX: 'unset',
    overflowY: 'unset',

    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '10px',
      bottom: 0,
      right: 0,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      transform: 'translate(-50%, 50%) rotate(135deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
}));

const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const DEBOUNCE_KEY = Symbol();

export default function ColorPicker({ name, label, disabled = false, className = '', defaultValue }: Props) {
  const classes = useStyles();
  const picker = useDialogState();
  const inputRef = useRef<HTMLDivElement>();
  const translate = useTranslate('form');
  const [color, setColor] = useState<Color>(null);
  const { getValues, setValue, trigger } = useFormContext();

  const colorText = useMemo(() => {
    if (!color) {
      return null;
    }

    const state = toState(color);

    if (state.hsv.a === 1) {
      return state.hex;
    }

    if (state.hsv.a === 0) {
      return 'transparent';
    }

    const alpha = Math.round(color.a * 255).toString(16);

    return state.hex + (alpha.length < 2 ? '0' + alpha : alpha);
  }, [color]);

  const context = useMemo<ColorPickerContextType>(
    () => ({ name, label: translate(label), disabled, picker, color, setColor, inputRef, colorText }),
    [name, label, translate, disabled, picker, color, colorText]
  );

  const formValue = getValues(name);

  useEffect(() => {
    setColor(formValue ? toState(formValue).hsv : null);
  }, [formValue]);

  useEffect(() => {
    debounceWithKey(
      DEBOUNCE_KEY,
      () => {
        if (formValue === colorText || !difference([null, undefined], [colorText, formValue]).length) {
          return;
        }

        setValue(name, colorText);
        trigger();
      },
      360
    );
  }, [colorText, setValue, trigger, name, formValue]);

  return (
    <ColorPickerContext.Provider value={context}>
      <Input defaultValue={defaultValue} />
      <Popover
        anchorEl={inputRef.current ?? document.body}
        open={picker.opened}
        onClose={picker.close}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        classes={{ paper: `${classes.popoverPaper} mt-1` }}>
        <Picker />
      </Popover>
    </ColorPickerContext.Provider>
  );
}
