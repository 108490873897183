import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { getAccidentIdActions } from '../../../../api/hs/accident/accident';
import { HSTableProps } from '../../../../Common/Table/HSTable/hs-table.props';
import Toolbar from '../../../../Common/Toolbar';
import CustomAction from '../../../../CustomAction';
import BaseSection from '../../../../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import NearMissRecordForm from '../NearMissRecordForm';
import NearMissFileUpload from './NearMissFileUpload';

export default function NearMissRecordsList() {
  const [nearMissId, setNearMissId] = useState(null);
  const saveNearMissId = useCallback((id: string) => setNearMissId(id), []);
  const fetchActionList = useCallback<HSTableProps<any>['fetcher']>(
    (page, size, sorting, extra) => {
      if (!nearMissId) return;
      return getAccidentIdActions(
        nearMissId,
        page,
        size,
        sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`
      );
    },
    [nearMissId]
  );
  return (
    <>
      <Toolbar color="secondary" className="mb-2">
        <Typography variant="h6">Ramak Kala Kaydı Oluştur</Typography>
      </Toolbar>
      <BaseSection icon="icon-flash" title="Ramak Kala Kaydı" expandedByDefault>
        <NearMissRecordForm data={[]} onSave={saveNearMissId} />
      </BaseSection>
      {nearMissId && (
        <>
          <BaseSection icon="icon-flash" title="Ramak Kala Aksiyonu Ekleme">
            <CustomAction actionReferenceValue={'NEAR_MISS'} referenceId={nearMissId} fetcher={fetchActionList} />
          </BaseSection>
          <BaseSection icon="icon-flash" title="Dosya">
            <NearMissFileUpload nearMissId={nearMissId} />
          </BaseSection>
        </>
      )}
      {/*<ListTable />*/}
      {/*<EditDialog />*/}
    </>
  );
}
