import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';

import { FamilyHistoryContext } from '../../family-history.context';
import FamilyHistoryForm from '../Form/DialogFamilyHistory';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(FamilyHistoryContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Button startIcon={<AddIcon />} className="mt-1" color="primary" variant="contained" onClick={onClick}>
        Soy Geçmiş Ekle
      </Button>

      <FamilyHistoryForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
