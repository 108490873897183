import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { WorkEquipmentsModel } from '.';
import { WorkEquipmentPayload } from './work-equipment.payload';
import { WorkEquipmentResult } from './work-equipments.result';
import { GetWorkEquipmentsSearchResult } from './work-equipments.search.result';

export async function getWorkEquipments(employeeId: string): Promise<WorkEquipmentResult[]> {
  let res;
  try {
    res = await HSApi.get<WorkEquipmentResult[]>(`/employees/${employeeId}/work-equipments`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function searchWorkEquipments(nameOrCode: string): Promise<GetWorkEquipmentsSearchResult> {
  let res;
  try {
    res = await HSApi.get<GetWorkEquipmentsSearchResult>(`/ibys/work-equipments?nameOrUpperCategory=${nameOrCode}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function getWorkEquipmentList(ids: string[]): Promise<WorkEquipmentsModel[]> {
  if (!ids.length) {
    return [];
  }
  let res;
  try {
    res = await HSApi.get<WorkEquipmentsModel[]>(`/ibys/work-equipments/list?code=${ids.join(',')}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateWorkEquipment(id: string, body: WorkEquipmentPayload[]): Promise<GetWorkEquipmentsSearchResult> {
  const [error, res] = await tryCatch(HSApi.put<GetWorkEquipmentsSearchResult>(`/employees/${id}/work-equipments`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteWorkEquipment(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/employees/${id}/work-equipments`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
