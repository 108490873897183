import React, { useCallback, useContext } from 'react';

import { TrainingDefinitionsContext } from '../../training-definitions.context';
import TrainingDefinitionsForm from '../TrainingDefinitionForm';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(TrainingDefinitionsContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <TrainingDefinitionsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
