import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { saveSystemCheck } from 'api/hs/examination/system-checks';
import Button from 'Common/Button';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';
import { useXhr } from 'utils';

import { SystemCheckContext } from '../../system-check.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface OptionExtra {
  color: string;
}

const DEFAULT_COMPLAINT_ID = 2;

const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Var', value: 1, color: '#eb4355' },
  { text: 'Yok', value: 0, color: '#469789' },
  { text: 'Belirsiz', value: DEFAULT_COMPLAINT_ID, color: '#c0c0c0' },
];

export default function DialogFormSystemCheck() {
  const classes = useStyles();
  const { editDialogOpened, setEditDialogOpened, systemCheck, reload } = useContext(SystemCheckContext);
  const { setValue, trigger, ...methods } = useForm();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const cancel = useCallback(() => {
    setEditDialogOpened(false);
  }, [setEditDialogOpened]);
  useEffect(() => {
    for (const item of systemCheck) {
      setValue(
        item.bodySystemsCheckDefinitionId + '',
        item.bodySystemsCheckStatusDefinitionId === undefined ? DEFAULT_COMPLAINT_ID : item.bodySystemsCheckStatusDefinitionId
      );
      setValue('description' + item.bodySystemsCheckDefinitionId, item.description);
    }

    trigger();
  }, [setValue, trigger, systemCheck]);

  const submitForm = useXhr(
    async data => {
      const items = Object.entries(data)
        .filter(([key]) => !key.includes('description'))
        .reduce((acc, [key, value]) => {
          return [
            ...acc,
            {
              employeeId,
              examinationId,
              bodySystemsCheckDefinitionId: key,
              bodySystemsCheckStatusDefinitionId: +value,
              description: data['description' + key],
            },
          ];
        }, []);

      await saveSystemCheck(items);
      await reload();
      setEditDialogOpened(false);
    },
    'Sistem Sorgulama Kaydedildi',
    'Kaydetme sırasında bir hata oluştu',
    [employeeId, examinationId, reload]
  );

  const allIgnore = () => {
    const data = methods.getValues();
    const dataItem = Object.entries(data).filter(([key]) => !key.includes('description'));
    dataItem.map((item, index) => setValue(item[0], 0));
  };

  return (
    <MUIDialog open={editDialogOpened} fullWidth maxWidth="md" keepMounted onClose={cancel}>
      <DialogTitle className={classes.dialogTitle}>Sistem Sorgulama Düzenle</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, setValue, trigger }}>
          <form id="edit-systemCheck-form" onSubmit={methods.handleSubmit(submitForm)}>
            <div className="text-right">
              <Button onClick={allIgnore} color="primary">
                Tümünü Yok Seç
              </Button>
            </div>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {systemCheck.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: '35%', color: 'gray', textAlign: 'justify' }} align="left" component="th" scope="row">
                        <b>{item?.bodySystemsCheckDefinitionName}</b>
                        <br />
                        <p>{item?.bodySystemsCheckDefinitionDescription}</p>
                      </TableCell>
                      <TableCell style={{ width: '30%' }} align="right">
                        <ToggleButtonGroup<number, OptionExtra>
                          name={item.bodySystemsCheckDefinitionId + ''}
                          options={OPTIONS}
                          defaultValue={DEFAULT_COMPLAINT_ID}
                          renderOption={({ color, selected, text, value }) => (
                            <ToggleButton
                              value={value}
                              className={selected ? 'text-white' : ''}
                              style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                              {text}
                            </ToggleButton>
                          )}
                        />
                      </TableCell>
                      <TableCell style={{ width: '30%' }}>
                        <TextField name={'description' + item.bodySystemsCheckDefinitionId} label="Açıklama" variant="outlined" rows={3} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right p-4">
        <Button variant="contained" onClick={cancel}>
          Vazgeç
        </Button>
        <Button variant="contained" form="edit-systemCheck-form" type="submit" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
