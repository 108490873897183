import { AccountCircleSharp } from '@material-ui/icons';
import React from 'react';
import { Route, Switch } from 'react-router';

import Accident from './Accident';
import Actions from './Actions';
import ActivityReport from './ActivityReport';
import Analysis from './Analysis';
import BodyMeasurements from './BodyMeasurements';
import ChronicDiseases from './ChronicDiseases';
import Diagnosis from './Diagnosis';
import Education from './Education';
import ExaminationReports from './ExaminationReports';
import Exposures from './Exposures';
import LostWorkingDays from './LostWorkingDays';
import NearMisses from './NearMisses';
import NurseOperationReport from './NurseOperationReport';
import OdioSft from './OdyoSft';
import Referral from './Referral';
import RestReport from './Rest-Report';
import VaccineReport from './VaccineReport';
import VitalMeasurements from './VitalMeasurements';

export default function Statistics() {
  return (
    <Switch>
      {/*<Route path="/statistics/statistics/">*/}
      {/*  <Index />*/}
      {/*</Route>*/}
      <Route path="/statistics/reports" exact>
        <ExaminationReports />
      </Route>
      <Route path="/statistics/lost-working-days" exact>
        <LostWorkingDays />
      </Route>
      <Route path="/statistics/rest-report" exact>
        <RestReport />
      </Route>
      <Route path="/statistics/vaccine-report" exact>
        <VaccineReport />
      </Route>
      <Route path="/statistics/nurse-report" exact>
        <NurseOperationReport />
      </Route>
      <Route path="/statistics/activity-report" exact>
        <ActivityReport />
      </Route>
      <Route path="/statistics/accident" exact>
        <Accident />
      </Route>
      <Route path="/statistics/referral" exact>
        <Referral />
      </Route>
      <Route path="/statistics/action" exact>
        <Actions />
      </Route>
      <Route path="/statistics/analysis" exact>
        <Analysis />
      </Route>
      <Route path="/statistics/odyo-sft" exact>
        <OdioSft />
      </Route>
      <Route path="/statistics/near-misses" exact>
        <NearMisses />
      </Route>
      <Route path="/statistics/diagnosis" exact>
        <Diagnosis />
      </Route>
      <Route path="/statistics/body-measurements" exact>
        <BodyMeasurements />
      </Route>
      <Route path="/statistics/vital-measurements" exact>
        <VitalMeasurements />
      </Route>
      <Route path="/statistics/chronic-diseases" exact>
        <ChronicDiseases />
      </Route>
      <Route path="/statistics/exposures" exact>
        <Exposures />
      </Route>
      <Route path="/statistics/education" exact>
        <Education />
      </Route>
    </Switch>
  );
}
