import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { EmergencyCaseDefinitionControllerService, EmergencyCaseDefinitionDto } from '../../api/client';
import Dialog from '../../Common/Dialog';
import Form, { FormRef } from '../../Common/Form/Form';
import TextField from '../../Common/Form/TextField';
import { AlertContext } from '../../context/alert.context';
import { ResourceContext } from '../../context/resource.context';
import { useAsyncEffect } from '../../utils';

interface Props {
  open: boolean;

  data: EmergencyCaseDefinitionDto;

  onClose(success: boolean): void;
}

export default function EmergencyCaseDefinitionForm({ data, open, onClose }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { showMessage } = useContext(AlertContext);
  const { reload } = useContext(ResourceContext);
  const onSaveUpdate = useCallback(
    async values => {
      try {
        if (!data) {
          await EmergencyCaseDefinitionControllerService.saveFirmEmergencyCaseDefinitionsDefinition(values);
          showMessage('Kayıt Başarılı.', 'success', 'info');
        } else {
          await EmergencyCaseDefinitionControllerService.updateFirmEmergencyCaseDefinitionsDefinition(data?.id, values);
          showMessage('Kayıt başarılıyla güncellendi.', 'success', 'info');
        }
        onClose(true);
        reload('emergencyCaseDefinition');
      } catch (e) {
        showMessage('işlem sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [onClose, data, showMessage, reload]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [data, open]);

  return (
    <>
      <Dialog
        color="secondary"
        title={data ? 'Acil Durum Ekibi Düzenle' : 'Acil Durum Ekibi Ekle'}
        opened={open}
        onClose={() => onClose(false)}>
        <Form onSubmit={onSaveUpdate} ref={formRef}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <TextField name="label" label="Acil Durum Ekibi " />
              </Grid>
            </Grid>
            <Grid item md={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {data?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
              <Button variant="contained" type="button" onClick={() => onClose(false)}>
                Vazgeç
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Dialog>
    </>
  );
}
