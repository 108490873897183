import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { MakeRequired } from '../../../../../utils';
import { AllergyPayload } from './Alergy.payload';
import { AllergyResult } from './Allergy.result';

export async function getAllergy(): Promise<AllergyResult[]> {
  const [error, res] = await tryCatch(HSApi.get<AllergyResult[]>(`/firms/allergy-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getEmployeeAllergies(employeeId: string) {
  const [error, res] = await tryCatch(HSApi.get(`/allergies?employeeId=${employeeId}`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAllergy(body: AllergyPayload): Promise<AllergyResult> {
  const [err, res] = await tryCatch(HSApi.post('/firms/allergy-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAllergy({ id, ...body }: MakeRequired<Partial<AllergyResult>, 'id'>): Promise<AllergyResult> {
  const [error, res] = await tryCatch(HSApi.put<AllergyResult>(`/firms/allergy-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAllergy(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/firms/allergy-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getAdminAllergy(): Promise<AllergyResult[]> {
  const [error, res] = await tryCatch(HSApi.get<AllergyResult[]>(`/allergy-definitions`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveAdminAllergy(body: AllergyPayload): Promise<AllergyResult> {
  const [err, res] = await tryCatch(HSApi.post('/allergy-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateAdminAllergy({ id, ...body }: MakeRequired<Partial<AllergyResult>, 'id'>): Promise<AllergyResult> {
  const [error, res] = await tryCatch(HSApi.put<AllergyResult>(`/allergy-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteAdminAllergy(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/allergy-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
