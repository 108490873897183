/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NearMissTypeDefinitionDto } from '../models/NearMissTypeDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class NearMissTypeDefinitionControllerService {

    /**
     * @param definitionId
     * @param requestBody
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateNearMissTypeDefinition(
        definitionId: string,
        requestBody: NearMissTypeDefinitionDto,
    ): CancelablePromise<NearMissTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/near-miss-type-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteNearMissTypeDefinition(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/near-miss-type-definitions/${definitionId}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmNearMissTypeDefinition(
        id: string,
        requestBody: NearMissTypeDefinitionDto,
    ): CancelablePromise<NearMissTypeDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/near-miss-type-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmNearMissTypeDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/near-miss-type-definitions/${id}`,
        });
    }

    /**
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getAllNearMissTypeDefinitions(): CancelablePromise<Array<NearMissTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/near-miss-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveNearMissTypeDefinition(
        requestBody: NearMissTypeDefinitionDto,
    ): CancelablePromise<NearMissTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/near-miss-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static getFirmNearMissTypeDefinitions(): CancelablePromise<Array<NearMissTypeDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/near-miss-type-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns NearMissTypeDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmNearMissTypeDefinition(
        requestBody: NearMissTypeDefinitionDto,
    ): CancelablePromise<NearMissTypeDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/near-miss-type-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}