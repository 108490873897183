import { Icd10 } from 'model/definitions/health-definitions/icd10.model';
import { modelDataMatcher } from 'utils';

import { ChronicDiseaseResult } from './chronic-disease.result';

export class ChronicDisease {
  public id: string = null;
  public description: string = null;
  public icd10s: Icd10['id'][] = [];

  constructor(data: Partial<ChronicDisease> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData(data: ChronicDiseaseResult) {
    return new ChronicDisease({ ...data, icd10s: data.chronicDiseaseIcd10s.map(({ icd10 }) => icd10) });
  }
}
