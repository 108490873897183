/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeViewFilter } from './EmployeeViewFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type EmployeeViewRequest = {
    trainingEmployeeFilter?: EmployeeViewFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    exposeType?: EmployeeViewRequest.exposeType;
}

export namespace EmployeeViewRequest {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
