import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAdminOpinion, updateAdminOpinion } from 'api/hs/definitions/health-definitions/opinion';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { OpinionModel } from 'model/definitions/health-definitions/opinion.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the opinion is created/updated
   */
  onClose(success: boolean): void;

  data: OpinionModel;
}

export default function AdminOpinion({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<OpinionModel>
      open={open}
      onClose={onClose}
      onSave={saveAdminOpinion}
      onUpdate={updateAdminOpinion}
      data={data}
      resourceReloadName="hsExamOpinionDefinitions"
      createTitle="Yeni Muayene Sonucu Ekleme"
      updateTitle="Muayene Sonucu Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="opinion" label="Kısıt Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
