import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';
import { MakeRequired } from 'utils';

import { EmergencyContactsPayload } from './emergency-contacts.payload';
import { EmergencyContactsResult } from './emergency-contacts.result';

export async function getEmergencyContacts(employeeId: string): Promise<EmergencyContactsResult[]> {
  let res;
  try {
    res = await HSApi.get<EmergencyContactsResult[]>(`/emergency-contacts?employeeId=${employeeId}`);
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveEmergencyContacts(body: EmergencyContactsPayload): Promise<EmergencyContactsResult> {
  const [err, res] = await tryCatch(HSApi.post(`/emergency-contacts`, body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function updateEmergencyContacts({
  id,
  ...body
}: MakeRequired<Partial<EmergencyContactsResult>, 'id'>): Promise<EmergencyContactsResult> {
  const [error, res] = await tryCatch(HSApi.put<EmergencyContactsResult>(`/emergency-contacts/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteEmergencyContacts(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/emergency-contacts/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
