import { Grid } from '@material-ui/core';
import React from 'react';

import { MatrixHazardDamageDefinitionDto } from 'api/client';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';

import { updateFirmRiskAssessmentsMatrixDamageDefinitionUpdate } from '../../../../api/hs/risk-fineKinney';

interface Props {
  open: boolean;
  data: MatrixHazardDamageDefinitionDto;

  onClose(success: boolean): void;
}

export default function MatrixDamageDefinitionForm({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<MatrixHazardDamageDefinitionDto>
      open={open}
      onClose={onClose}
      onUpdate={updateFirmRiskAssessmentsMatrixDamageDefinitionUpdate}
      data={data}
      createTitle="Matris Şiddet Ekleme"
      updateTitle="Matris Şiddet Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="label" label="İsmi" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField required name="value" label="Değeri" />
      </Grid>
    </BaseDefinitionForm>
  );
}
