// tslint:disable:max-classes-per-file

export class PulmonaryCategoryModel {
  public label: string = null;
  public description: string = null;
}

export class PulmonaryDetailsModel {
  public act: number | string = null;
  public nas: number | string = null;
  public parameterDefinitionId: number = null;
  public pred: number | string = null;
  public predPercent: number | string = null;
}

export class PulmonaryDetailsAggregate extends PulmonaryDetailsModel {
  public createdBy: string = null;
  public createdDate: string = null;
  public lastModifiedBy: string = null;
  public lastModifiedDate: string = null;
  public deleted: boolean = false;
  public parameter: string = null;
  public analysisParameterUnit: string = null;
}

export class PulmonaryModel {
  public analysisId: string = null;
  public pftCategory: PulmonaryCategoryModel = null;
  public pftDetails: PulmonaryDetailsModel[] = [];
}

export class PulmonaryAggregate {
  public analysisId: string = null;
  public pftCategory: PulmonaryCategoryModel = null;
  public pftDetails: PulmonaryDetailsAggregate[] = [];
}
