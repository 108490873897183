import React, { useState } from 'react';
import { useParams } from 'react-router';

import { EmergencyCaseTeamControllerService, EmergencyCaseTeamDto } from '../../api/client';
import { getEmergencyManagement } from '../../api/hs/emergency-management';
import { useFetch } from '../../utils';
import { CaseTeamsContext } from './case-teams.context';
import ListAdd from './ListAdd';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<EmergencyCaseTeamDto>(null);

  const { data, fetch } = useFetch(async () => await getEmergencyManagement(), [], { setLoading });

  return (
    <CaseTeamsContext.Provider
      value={{
        emergencyManagementCaseTeams: data,
        reload: fetch,
        loading,
        setLoading,
        setNewDialogOpened,
        newDialogOpened,
        selectedData,
        setSelectedData,
        editDialogOpened,
        setEditDialogOpened,
      }}>
      <ListAdd />
    </CaseTeamsContext.Provider>
  );
}
