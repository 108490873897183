/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Icd10WarningLabelDto } from '../models/Icd10WarningLabelDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class Icd10WarningLabelControllerService {

    /**
     * @param icd10Id
     * @param requestBody
     * @returns Icd10WarningLabelDto OK
     * @throws ApiError
     */
    public static updateIcd10WarningLabel(
        icd10Id: string,
        requestBody: Icd10WarningLabelDto,
    ): CancelablePromise<Icd10WarningLabelDto> {
        return __request({
            method: 'PUT',
            path: `/icd10/${icd10Id}/icd10-warning-labels`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param icd10Id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteIcd10Id(
        icd10Id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/icd10/${icd10Id}/icd10-warning-labels`,
        });
    }

    /**
     * @param icd10Ids
     * @returns Icd10WarningLabelDto OK
     * @throws ApiError
     */
    public static getAllIcd10WarningLabels(
        icd10Ids?: Array<string>,
    ): CancelablePromise<Array<Icd10WarningLabelDto>> {
        return __request({
            method: 'GET',
            path: `/icd10-warning-labels`,
            query: {
                'icd10Ids': icd10Ids,
            },
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllIcd10WarningLabel(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/icd10-warning-labels/${id}`,
        });
    }

}