import { DisplayOption } from './display-option';

export const convertByMultipleFieldNames = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByNames: string[],
  displayOption: DisplayOption
): any[] => {
  const resultMap: any = {};
  data.forEach(d => {
    const date = new Date(Reflect.get(d, dateFieldName));
    let key: string;
    if (displayOption === DisplayOption.DAILY) {
      key = date.toISOString().slice(0, 10);
    } else if (displayOption === DisplayOption.MONTHLY) {
      key = date.toISOString().slice(0, 7);
    } else if (displayOption === DisplayOption.ANNUALLY) {
      key = date.toISOString().slice(0, 4);
    }
    if (!resultMap[key]) {
      resultMap[key] = {
        // date: date,
        date: key,
      };
    }
    selectorFieldsByNames.forEach(f => addFieldValueCount(resultMap[key], d, f));
  });
  return Object.values(resultMap);
};

export const convertByMultipleFieldIdsAndIdMaps = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByIds: string[],
  displayOption: DisplayOption,
  fieldNameIdNameMaps: Map<string, Map<string, string>>
): any[] => {
  return null;
};

export const convertByMultipleFieldIds = (
  data: any[],
  dateFieldName: string,
  selectorFieldsByIds: string[],
  displayOption: DisplayOption
): any[] => {
  return null;
};

export const convertDataToChartDataByDateAndFieldName = (data: any[], dateFieldName: string, selectorFieldByName: string): any[] => {
  const resultMap: any = {};
  data.forEach(d => {
    const date = new Date(Reflect.get(d, dateFieldName));
    const key = date.toISOString().slice(0, 10);
    if (!resultMap[key]) {
      resultMap[key] = {
        date,
      };
    }
    addFieldValueCount(resultMap[key], d, selectorFieldByName);
  });
  return Object.values(resultMap);
};

export const addFieldValueCountArr = (resultItem: any, data: any[], selectorFieldByName: string) => {
  data.forEach(d => {
    addFieldValueCount(resultItem, d, selectorFieldByName);
  });
};

export const addFieldValueCount = (item: any, data: any, selectorFieldByName: string) => {
  if (!data) {
    return;
  }
  const indexOfArr: number = selectorFieldByName.indexOf('[].');
  const indexOfDot: number = selectorFieldByName.indexOf('.');
  if (indexOfArr > 0 && indexOfArr < indexOfDot) {
    const fragment = selectorFieldByName.substr(0, indexOfArr);
    const newFieldName = selectorFieldByName.substr(indexOfArr + 3);
    addFieldValueCountArr(item, Reflect.get(data, fragment), newFieldName);
  } else if (indexOfDot > 0) {
    const fragment = selectorFieldByName.substr(0, indexOfDot);
    const newFieldName = selectorFieldByName.substr(indexOfDot + 1);
    addFieldValueCount(item, Reflect.get(data, fragment), newFieldName);
  } else {
    const field = Reflect.get(data, selectorFieldByName);
    if (!item[field]) {
      item[field] = 0;
    }
    item[field]++;
  }
};

export const showValueCountLength = (data: any, selectorFieldByName: string) => {
  if (!data) {
    return;
  }

  const res = data?.reduce((acc: any, value: any) => {
    if (!acc[value?.employee[selectorFieldByName]]) {
      acc[value?.employee[selectorFieldByName]] = value?.training?.durationInMinutes;
    } else {
      acc[value?.employee[selectorFieldByName]] = acc[value?.employee[selectorFieldByName]] + value?.training?.durationInMinutes;
    }

    return acc;
  }, {});

  return Object.entries(res);
};
