import { createContext } from 'react';

export interface CaseTeamMembersData {
  employeeId: string;
  emergencyCaseTeamId: string;
  quitDate: string;
  joinDate: string;
}

export interface NewCaseTeamMembersContextType {
  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  caseTeamMembersData: CaseTeamMembersData[];

  setCaseTeamMembersData(data: any[]): CaseTeamMembersData[];

  loading: boolean;

  setLoading(loading: boolean): void;
}

export const NewCaseTeamMembersContext = createContext<NewCaseTeamMembersContextType>(null);
