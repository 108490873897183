/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccidentFilter } from './AccidentFilter';
import type { PagingAndSortingRequestParam } from './PagingAndSortingRequestParam';

export type AccidentViewRequest = {
    filter?: AccidentFilter;
    pagingAndSortingRequestParam?: PagingAndSortingRequestParam;
    exposeType?: AccidentViewRequest.exposeType;
}

export namespace AccidentViewRequest {

    export enum exposeType {
        EXCEL = 'EXCEL',
        PDF = 'PDF',
        WORD = 'WORD',
        HTML = 'HTML',
        XML = 'XML',
        JSON = 'JSON',
        CSV = 'CSV',
    }


}
