/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DrillDto } from '../models/DrillDto';
import type { ImportedFileDto } from '../models/ImportedFileDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DrillControllerService {

    /**
     * @param id
     * @returns DrillDto OK
     * @throws ApiError
     */
    public static getDrillById(
        id: string,
    ): CancelablePromise<DrillDto> {
        return __request({
            method: 'GET',
            path: `/drills/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns DrillDto OK
     * @throws ApiError
     */
    public static updateDrill(
        id: string,
        requestBody: DrillDto,
    ): CancelablePromise<DrillDto> {
        return __request({
            method: 'PUT',
            path: `/drills/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDrill(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/drills/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns DrillDto OK
     * @throws ApiError
     */
    public static partialUpdateDrill(
        id: string,
        requestBody: DrillDto,
    ): CancelablePromise<DrillDto> {
        return __request({
            method: 'PATCH',
            path: `/drills/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DrillDto OK
     * @throws ApiError
     */
    public static getAllDrills(): CancelablePromise<Array<DrillDto>> {
        return __request({
            method: 'GET',
            path: `/drills`,
        });
    }

    /**
     * @param requestBody
     * @returns DrillDto OK
     * @throws ApiError
     */
    public static saveDrill(
        requestBody: DrillDto,
    ): CancelablePromise<DrillDto> {
        return __request({
            method: 'POST',
            path: `/drills`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static getDrillFiles(
        id: string,
    ): CancelablePromise<Array<ImportedFileDto>> {
        return __request({
            method: 'GET',
            path: `/drills/${id}/imported-files`,
        });
    }

    /**
     * @param id
     * @param formData
     * @returns ImportedFileDto OK
     * @throws ApiError
     */
    public static drillFileImport(
        id: string,
        formData?: {
            'imported-file': Blob;
        },
    ): CancelablePromise<ImportedFileDto> {
        return __request({
            method: 'POST',
            path: `/drills/${id}/imported-files`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getDrillFile(
        id: string,
    ): CancelablePromise<Blob> {
        return __request({
            method: 'GET',
            path: `/drills/imported-files/${id}/raw`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteDrillFile(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/drills/imported-files/${id}`,
        });
    }

}