import { createContext } from 'react';

import { MedicalInterventionResult } from 'api/hs/examination/medical-intervention';
import { MedicalInterventionsModel } from 'model/employee/examination/medical-interventions.model';

export interface MedicalInterventionContextType {
  medicalIntervention: MedicalInterventionResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: MedicalInterventionsModel;

  setSelectedData(data: MedicalInterventionsModel): void;
}

export const MedicalInterventionContext = createContext<MedicalInterventionContextType>(null);
