import { Chip, Divider, Grid, Typography } from '@material-ui/core';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import { debounceWithKey, defer, difference, findByKey, remove, tryCatch, uniquify } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react';

import { extendedSearchEmployee, searchEmployeeNIdList, searchTrainingEmployeeParticipants } from 'api/hs/employee';
import Button from 'Common/Button';
import AutoComplete from 'Common/Form/AutoComplete';
import { EmployeeSelectionCacheContext } from 'Common/Form/EmployeeSelection/employee-selection.context';
import EmployeeSelectionBox from 'Common/Form/EmployeeSelection/EmployeeSelectionBox';
import Form, { FormRef } from 'Common/Form/Form';
import GenerativeAutoComplete from 'Common/Form/GenerativeAutoComplete';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import {useAsyncEffect, useTranslate, useTriggerRender, useXhr} from 'utils';

import { PlanningStep } from '../planning-steps.enum';
import { GuestParticipant, PlanningContext } from '../planning.context';
import F2fParticipantList from "./f2f-participant-list";
import RemoteParticipantList from "./remote-participant-list";
import {DialogContext} from "../../../context/dialog.context";
import {nearMissFileUpload} from "../../../api/hs/accident/accident";
import {TrainingDefinitionEmployeeControllerService} from "../../../api/client";
import {excelExport, handleResult} from "../../../api/hs/utils";
import HSApi from "../../../api/hs/HSApi";

const DEBOUNCE_KEY = Symbol();


export async function exportTrainingEmployeeTemplate() {
  const [error, res] = await tryCatch(
    HSApi.get(`/training/training-definition-employees/import/template`, { responseType: 'blob' })
  );

  const data = handleResult(res, error);
  excelExport(data, 'çalışan-eğitim_süresi.xls');
}

export default function Participant() {
  const translate = useTranslate('training');
  const {
    info,
    setParticipantForm,
    setActiveStep,
    guestParticipants,
    addGuestParticipants,
    setParticipants,
    participantForm,
    activeStep,
    importFromExcelVisible,
    remoteTrainingEmployeeFile,
    setRemoteTrainingEmployeeFile,
    selectedTrainings,
    setDurations
  } = useContext(PlanningContext);
  const { departments, locations, positions, workStations } = useContext(SelectOptionsContext);
  const [selection, setSelection] = useState<(string | symbol)[]>([]);
  const [nextStepEnabled, setNextStepEnabled] = useState<boolean>(false);
  const { avatars, names, loadEmployees: loadEmployeesCache, employees } = useContext(EmployeeSelectionCacheContext);
  const { openWarnDialog, openFileDialog } = useContext(DialogContext);
  const formRef = useRef<FormRef<Record<string, string[]> & { guests: symbol[] }>>();
  const reRender = useTriggerRender();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const handleSubmit = useCallback(
    values => {
      setParticipantForm(values);

      const depository = [...employees, ...guestParticipants];

      setParticipants(selection.map(pt => findByKey(depository, 'id', pt)));
      setActiveStep(PlanningStep.Documents);
    },
    [setParticipantForm, setActiveStep, employees, selection, guestParticipants, setParticipants]
  );

  const handleGuestSubmit = useCallback(
    (id: symbol, { name, surname }: Omit<GuestParticipant, 'id'>) => {
      addGuestParticipants({ id, name, surname });
    },
    [addGuestParticipants]
  );

  const handleGuestRemove = (id: symbol) => () => {
      const { setValue, getValues, trigger } = formRef.current.methods;
      setValue('guests', remove(getValues('guests'), id));
      trigger();
    };
  const getGuestLabel = (id: symbol) => {
    const guest = findByKey(guestParticipants, 'id', id);

    return `${guest?.name} ${guest?.surname}`;
  };

  const guestParticipantOptions = useMemo(
    () => guestParticipants?.map<SelectOption<symbol>>(({ name, id, surname }) => ({ text: `${name} ${surname}`, value: id })),
    [guestParticipants]
  );

  const loadEmployees = useCallback(
    async value => {
      reRender();
      const emps = await debounceWithKey(DEBOUNCE_KEY, extendedSearchEmployee, 300, null, value);
      let data;
      if (formRef?.current?.methods?.getValues('nidList')) {
        const regexp = /([0-9]{11})/gm;
        const listData = formRef?.current?.methods?.getValues('nidList');
        const identityNumbers = listData.match(regexp);
        data = await searchTrainingEmployeeParticipants(identityNumbers);
      }
      const result = difference(
        [...emps.map(emp => emp.id), ...(data?.reduce((acc, item) => [...acc, item.id], []) ?? []), ...(value.added ?? [])],
        value.blacklist ?? []
      );
      setSelection(uniquify([...result, ...(value.guests ?? [])]));
      loadEmployeesCache(...result);
    },
    [reRender, loadEmployeesCache]
  );

  const blacklistEmployee = useCallback(
    (id: string) => () => {
      formRef?.current?.methods?.setValue('blacklist', uniquify([...formRef?.current?.value?.blacklist, id]));
      formRef?.current?.methods?.trigger();
    },
    []
  );

  useAsyncEffect(async () => {
    if (!participantForm || activeStep !== PlanningStep.Participants) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(participantForm)) {
      formRef?.current?.methods?.setValue(key as any, value);
    }

    formRef?.current?.methods?.trigger();
  }, [participantForm, activeStep]);

  // const nidList = useCallback(async () => {
  //   const regexp = /([0-9]{11})/gm;
  //   const listData = formRef?.current?.methods?.getValues('nidList');
  //   const identityNumbers = listData.match(regexp);
  //   const data = await searchEmployeeNIdList(identityNumbers);
  //   setSelection(data.reduce((acc, item) => [...acc, item.id], []));
  //   loadEmployeesCache(...data.reduce((acc, item) => [...acc, item.id], []));
  // }, [loadEmployeesCache]);

  const uploadFilesReq = useXhr(
    async (files: File[]) => {
      //await Promise.all(files.map(file => nearMissFileUpload(row?.nearMissId, file)));
      //await refresh();
    },
    'Katılımcı Listesi yüklendi',
    'Dosya yüklenirken bir hata oluştu',
    []
  );

  const openFileUploadDialog = useCallback(async () => {
    closeMenu();
    const files = await openFileDialog({
      maxCount: 1,
      title: 'Katılımcı Listesi Yükle',
    });

    if (files) {
      setRemoteTrainingEmployeeFile((files as File[])[0]);
      setNextStepEnabled(true);
      setDurations(selectedTrainings.map(st => ({key:st, value:0})).reduce((acc, duration) => {
        acc[duration.key] = duration.value;
        return acc;
      }, {} as Record<string, number>));

        }
    //await uploadFilesReq(files);
  }, [openFileDialog, uploadFilesReq, closeMenu]);

  useEffect(() => {
    setNextStepEnabled(remoteTrainingEmployeeFile ? true : false);

  },[]);

  return (
    <Form onSubmit={handleSubmit} onChange={loadEmployees} className="pt-2" ref={formRef}>
      <Grid container>
        {/*<Grid item xs={12} className="mb-2">*/}
        {/*  <Typography variant="h6">Katılımcı Seçimi</Typography>*/}
        {/*  <Divider />*/}
        {/*</Grid>*/}
        {
          !info.isFaceToFace && info.importFromExcel ? (
            <>
              {
                remoteTrainingEmployeeFile ? (
                  <>
                    {remoteTrainingEmployeeFile.name}
                  </>
                ) : (
                  <>
                    <Button color="primary" variant="contained" onClick={async () => await exportTrainingEmployeeTemplate()}>
                      Şablonu İndir
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => openFileUploadDialog()}>
                      Excelden yükle
                    </Button>
                  </>
                )
              }
            </>
          ) : (
          <>
            <Grid item container xs={12} className="px-2">
              <Grid item xs={12} md={4} className="pr-2">
                <Typography variant="h6">Organizasyon Bazlı Personel Seçimi</Typography>
                <Divider />
                <AutoComplete name="departmentDefinitionIds" options={departments} label="Select Departments" />
                <AutoComplete name="locationDefinitionIds" options={locations} label="Select Locations" />
                <AutoComplete name="positionDefinitionIds" options={positions} label="Select Positions" />
                <AutoComplete name="workstationDefinitionIds" options={workStations} label="Select Work Stations" />
              </Grid>
              <Grid item xs={12} md={4} className="px-1">
                <Typography variant="h6">Tek Tek Personel Seçimi</Typography>
                <Divider />
                <EmployeeSelectionBox name="added" label="Added Employees" />
                <GenerativeAutoComplete
                  name="guests"
                  options={guestParticipantOptions}
                  label="Misafir Katılımcılar"
                  dialogLabel="Misafir Katılımcı Ekle"
                  adornment={open => (
                    <Button color="secondary" onClick={open}>
                      Ekle
                    </Button>
                  )}
                  onGenerate={handleGuestSubmit}>
                  <TextField name="name" label="Guest Name" required />
                  <TextField name="surname" label="Guest Surname" required />
                </GenerativeAutoComplete>
                <div className="mt-5">
                  <EmployeeSelectionBox name="blacklist" label="Excluded Employees" />
                </div>
              </Grid>
              <Grid item xs={12} md={4} className="pl-2">
                <Typography variant="h6">Çoklu Personel Seçimi</Typography>
                <Divider />
                <TextField className="mt-1" rows={4} variant="outlined" name="nidList" label="TC Kimlik Listesi" />
                {/*<div className="text-right">*/}
                {/*  <Button onClick={nidList} startIcon={<FormatListNumberedRtlIcon />} type="button" variant="contained" color="primary">*/}
                {/*    Çoklu Çalışan Ekle*/}
                {/*  </Button>*/}
                {/*</div>*/}
              </Grid>
            </Grid>
            <Grid item xs={12} className="mb-2 mt-2">
              <Typography variant="h6">Katılımcı Listesi</Typography>
              <Divider/>
            </Grid>
            {
              info.isFaceToFace ?
                selection  &&  <F2fParticipantList selection={selection} formRef={formRef} handleGuestRemove={handleGuestRemove}  getGuestLabel={getGuestLabel} />  :
                selection  &&  <RemoteParticipantList selection={selection} formRef={formRef} handleGuestRemove={handleGuestRemove}  getGuestLabel={getGuestLabel} />
            }
          </>
          )
        }
      </Grid>
      <footer className="text-right">
        <Button variant="contained" type="submit" color="secondary" disabled={!(nextStepEnabled || selection.length > 0)}>
          {translate('Continue')}
          {nextStepEnabled || selection.length > 0}
        </Button>
      </footer>
    </Form>
  );
}
