import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getVitalSign, VitalSignModel, VitalSignResult } from 'api/hs/examination/vital-sign';
import { dateSort, useAsyncEffect } from 'utils';

import VitalSign from './vital-sign';
import { VitalSignsContext } from './vital-signs.context';

export default function Index() {
  const [vitalSigns, setVitalSigns] = useState<VitalSignResult[]>([]);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<VitalSignModel>(null);
  const { employeeId } = useParams<{ employeeId: string }>();

  const fetch = useCallback(async () => {
    const result = await getVitalSign(employeeId);
    setVitalSigns(dateSort(result));
    setLoading(false);
  }, [employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <VitalSignsContext.Provider
      value={{
        vitalSigns,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <VitalSign />
    </VitalSignsContext.Provider>
  );
}
