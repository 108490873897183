import React from 'react';

// import AssignmentsEmployee from './AssignmentsEmployee';
import PlaningTrainingList from './TrainingPlanningList';

export default function TrainingEmployee() {
  return (
    <>
      <PlaningTrainingList />
      {/*<AssignmentsEmployee />*/}
    </>
  );
}
