import React from 'react';

import AddIcon from './Add';
import FormDialogNurseOperation from './Form';
import ListNurseOperation from './List';
import Toolbar from './Toolbar';

export default function Index() {
  return (
    <>
      <Toolbar />
      <AddIcon />
      <ListNurseOperation />
      <FormDialogNurseOperation />
    </>
  );
}
