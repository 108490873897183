import {
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import { findByKey, replace } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { deleteVaccine, getVaccines, saveVaccine, updateVaccine as updateVacccineReq } from 'api/hs/examination/vaccine';
import Button from 'Common/Button';
import LoadingWrapper from 'Common/LoadingWrapper';
import { DialogContext } from 'context/dialog.context';
import { useDialogState, useFetch, useResourcePromise, useTranslate, useXhr } from 'utils';

import EditVaccine, { EditVaccineValues } from './EditVaccine';
import NewExternalVaccine, { ExternalVaccineValues } from './NewExternalVaccine';
import NewVaccine, { SubmitNewVaccineValues } from './NewVaccine';
import Vaccinate, { VaccinateValues } from './Vaccinate';
import { Vaccine as PageVaccine } from './vaccine.model';

const useStyles = makeStyles(theme => ({
  subheader: {
    position: 'initial',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.black,

    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  listItemRoot: {
    paddingRight: 110,
  },
}));

export default function Vaccine() {
  const classes = useStyles();
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const [loading, setLoading] = useState(true);
  const vaccines$ = useResourcePromise('vaccines');
  const { open: openNew, close: closeNew, opened: openedNew } = useDialogState();
  const { open: openEdit, close: closeEdit, opened: openedEdit } = useDialogState();
  const { open: openVaccinate, close: closeVaccinate, opened: openedVaccinate } = useDialogState();
  const { open: openExternal, close: closeExternal, opened: openedExternal } = useDialogState();
  const { openWarnDialog } = useContext(DialogContext);
  const [selectedVaccine, setSelectedVaccine] = useState<PageVaccine>(null);
  const translate = useTranslate('examination');

  const openEditVaccineDialog = useCallback(
    (vaccine: PageVaccine) => {
      setSelectedVaccine(vaccine);
      openEdit();
    },
    [openEdit]
  );

  const openVaccinateDialog = useCallback(
    (vaccine: PageVaccine) => {
      setSelectedVaccine(vaccine);
      openVaccinate();
    },
    [openVaccinate]
  );

  const { data, fetch } = useFetch<PageVaccine[]>(
    async () => {
      const vaccines = await vaccines$;
      const res = await getVaccines(employeeId, examinationId);

      return res.map(vaccine => ({ ...vaccine, label: findByKey(vaccines, 'id', vaccine.vaccineDefinitionId)?.name }));
    },
    [],
    { setLoading }
  );

  const createNewVaccine = useXhr(
    async (values: SubmitNewVaccineValues) => {
      setLoading(true);

      await saveVaccine({
        employeeId,
        examinationId,
        vaccineDefinitionId: values.vaccine,
        vaccineDetails: new Array(+values.doseCount)
          .fill(0)
          .map((_, index) => ({ date: values['date-' + index], description: '', isDone: false, isExternal: false, order: index + 1 })),
      });

      await fetch();
      closeNew();
    },
    'Aşı planı oluşturuldu',
    'Aşı planı oluşturulurken bir sorun oluştu',
    [employeeId, examinationId, fetch, closeNew]
  );

  const createNewExternalVaccine = useXhr(
    async (values: ExternalVaccineValues) => {
      setLoading(true);

      await saveVaccine({
        employeeId,
        examinationId,
        vaccineDefinitionId: values.vaccine,
        vaccineDetails: [
          {
            ...values,
            isDone: true,
            isExternal: true,
            order: 1,
            description: values.description,
          },
        ],
      });

      await fetch();
      closeExternal();
    },
    'Aşı planı oluşturuldu',
    'Aşı planı oluşturulurken bir sorun oluştu',
    [employeeId, examinationId, fetch, closeExternal]
  );

  const updateVaccine = useXhr(
    async (values: EditVaccineValues) => {
      await updateVacccineReq(selectedVaccine.groupId, {
        vaccineDetails: replace(
          data.filter(({ groupId }) => groupId === selectedVaccine.groupId),
          selectedVaccine,
          { ...selectedVaccine, ...values }
        ),
      });
      await fetch();
      closeEdit();
    },
    'Aşı güncellendi',
    'Aşı güncellenirken bir hata oluştu',
    [fetch, closeEdit, selectedVaccine, data]
  );

  const removeVaccine = useXhr(
    async (vaccine: PageVaccine) => {
      const submitted = await openWarnDialog({
        text: `${vaccine.date.split('-').reverse().join('-')} tarihli ${vaccine.label} aşı kaydını silmek istediğinize emin misiniz?`,
      });

      if (submitted) {
        await deleteVaccine(vaccine.id);
        await fetch();
      }
    },
    'Aşı kaydı silindi',
    'Aşı kaydı silinirken bir hata oluştu',
    [openWarnDialog, fetch]
  );

  const submitVaccinate = useXhr(
    async (values: VaccinateValues) => {
      await updateVacccineReq(selectedVaccine.groupId, {
        vaccineDetails: replace(
          data.filter(({ groupId }) => groupId === selectedVaccine.groupId),
          selectedVaccine,
          { ...selectedVaccine, ...values, isDone: true }
        ),
      });
      await fetch();
      closeVaccinate();
    },
    'Aşı kaydedildi',
    'Aşı kaydedilirken bir hata oluştu',
    [fetch, closeVaccinate, selectedVaccine, data]
  );

  const renderVaccineList = useCallback(
    (vaccine: PageVaccine) => (
      <Grid container>
        <ListItem key={vaccine?.id} classes={{ root: classes.listItemRoot }}>
          <Grid item lg={10} spacing={0}>
            <ListItemText
              primary={`${vaccine?.label} - ${vaccine?.date?.split('-').reverse().join('-')} - ${
                vaccine?.isExternal ? '(Dışarıdan Kayıt)' : `(${vaccine?.order}. Doz)`
              }`}
              {...(vaccine?.isDone && {
                secondary: translate(
                  'Vaccine.Item.Description',
                  { lotNumber: vaccine?.lotNumber, description: vaccine?.description },
                  { desc: <i /> }
                ),
              })}
            />
          </Grid>
          <Grid lg={2} spacing={0}>
            <ListItemSecondaryAction>
              {!vaccine?.isDone && (
                <IconButton edge="end" title="Aşı Yap" onClick={() => openVaccinateDialog(vaccine)}>
                  <Icon className="icon-needle" />
                </IconButton>
              )}
              <IconButton edge="end" title="Düzenle" onClick={() => openEditVaccineDialog(vaccine)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" title="Sil" onClick={() => removeVaccine(vaccine)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </Grid>
        </ListItem>
      </Grid>
    ),
    [removeVaccine, openEditVaccineDialog, openVaccinateDialog, classes.listItemRoot, translate]
  );

  return (
    <>
      <NewVaccine opened={openedNew} onClose={closeNew} onSubmit={createNewVaccine} />
      <EditVaccine opened={openedEdit} onClose={closeEdit} onSubmit={updateVaccine} value={selectedVaccine} />
      <Vaccinate opened={openedVaccinate} onClose={closeVaccinate} onSubmit={submitVaccinate} value={selectedVaccine} />
      <NewExternalVaccine opened={openedExternal} onClose={closeExternal} onSubmit={createNewExternalVaccine} />
      <Typography variant="h6" className="pl-1 pb-1">
        Aşı Planları / Kayıtları
      </Typography>
      <Divider />
      <List>
        <ListSubheader className={classes.subheader}>
          <span>
            <ListIcon className="mr-1" />
            Planlananlar
          </span>
          <Button color="primary" onClick={openNew}>
            Aşı Planı Yap
          </Button>
        </ListSubheader>
        <Divider />
        <LoadingWrapper loading={loading}>{data.filter(({ isDone }) => !isDone).map(renderVaccineList)}</LoadingWrapper>
        <ListSubheader className={`${classes.subheader} mt-2`}>
          <span>
            <ListIcon className="mr-1" />
            Yapılanlar
          </span>
          <Button color="primary" onClick={openExternal}>
            Dışarıdan Aşı Kaydı Gir
          </Button>
        </ListSubheader>
        <Divider />
        <LoadingWrapper loading={loading}>{data.filter(({ isDone }) => isDone).map(renderVaccineList)}</LoadingWrapper>
      </List>
    </>
  );
}
