import {Chip, Grid, Tooltip, TextField} from '@material-ui/core';
import {uniquify, findByKey} from '@thalesrc/js-utils';
import React, {MutableRefObject, useCallback, useContext, useEffect, useState} from 'react';
import Avatar from 'TransactionsAndLists/EmployeeList/Avatar';
import {EmployeeSelectionCacheContext} from "../../../Common/Form/EmployeeSelection/employee-selection.context";
import {FormRef} from 'Common/Form/Form';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {PlanningContext} from "../planning.context";
import {ResourceContext} from "../../../context/resource.context";
import {
  TrainingDefinitionsModel,
  TrainingDefinitionsResult
} from "../../../api/hs/definitions/trainings/training-definitions";


interface Params {
  selection: (string | symbol) [];
  formRef: MutableRefObject<FormRef<Record<string, string[]> & { guests: symbol[] }>>;
  handleGuestRemove: (id: symbol) => void;
  getGuestLabel: (id: symbol) => string;
}


interface ColumnInfo {
  displayName: string;
  tooltip: string;
  id:string
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  trainingHeader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 96
  },
});

export default function RemoteParticipantList({selection, formRef, handleGuestRemove, getGuestLabel}: Params) {
  const {avatars, names} = useContext(EmployeeSelectionCacheContext);
  const [durationValues, setDurationValues] = useState<{[key:string]:number}>();
  const {selectedTrainings, durations, remoteDurations, setRemoteDurations} = useContext(PlanningContext);
  const {trainingDefinitions} = useContext(ResourceContext);


  useEffect(() => {
    let rDurationMap:Map<string, Map<string, number>> = new Map();
    let durationObj:{[key:string]:number} = {};
    if(selection.length) {
      selection.map((emp) =>  {
        let trainingDefDurationMap:Map<string, number> = new Map();
        selectedTrainings.forEach(id => {
          trainingDefDurationMap.set(id,durations[id])
          if(durationValues[`${emp}|${id}`]) {
            durationObj[`${emp}|${id}`] = durationValues[`${emp}|${id}`];
          } else {
            durationObj[`${emp}|${id}`] = durations[id];
          }
        });
        rDurationMap.set(emp, trainingDefDurationMap);
      })
    }
    setDurationValues(durationObj);
    setRemoteDurations(rDurationMap);
  }, [selection]);






  const blacklistEmployee = useCallback(
    (id: string) => () => {
      formRef?.current?.methods?.setValue('blacklist', uniquify([...formRef?.current?.value?.blacklist, id]));
      formRef?.current?.methods?.trigger();
    },
    []
  );



  const classes = useStyles();

  function tableCols() {
    return selectedTrainings?.map( st => {
      let columnInfo = createColumnInfo(st);
      return (
        <TableCell>
          <Tooltip title={columnInfo.tooltip}>
            <div className={classes.trainingHeader}>{columnInfo.displayName}</div>
          </Tooltip>
        </TableCell>
      )
    });
  }

  function createColumnInfo(trainingId:string):ColumnInfo {
    let training:TrainingDefinitionsModel = findByKey(trainingDefinitions, 'id', trainingId);
    return  {
      displayName:(training.isIbysTraining ? training.code + ' - ' : '') + training.name,
      tooltip:training.name,
      id:training.id
    };
  }


  const durationChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    //durationValues[event.target.id]=parseInt(event.target.value);
    let obj:any = {};
    obj[event.target.id] = event.target.value;
    setDurationValues({...durationValues, ...obj});
    //(event) => setDurationValues( [...durationValues, event.target.id])
  }

  function tableRows(employeeId:string) {
    //let rDurationMap = remoteDurations.get(employeeId);
    return selectedTrainings.map((trainingId:string) => {
      return (
        <TableCell>
          <TextField
            id={`${employeeId}|${trainingId}`}
            label=""
            type="number"
            value={durationValues[`${employeeId}|${trainingId}`]}
            onChange={durationChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
      )
    });
  }


  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Adı Soyadı</TableCell>
            {
              tableCols()
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {selection.length && remoteDurations.size && selection.map((emp, index) =>  typeof emp === 'string'  && (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Chip
                  key={emp}
                  avatar={<Avatar url={avatars[emp]}/>}
                  label={names[emp] ? names[emp] : getGuestLabel(emp)}
                  onDelete={blacklistEmployee(emp)}
                  className={`mr-2 mb-2`}
                />
              </TableCell>
              {
                tableRows(emp)
              }
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );

}
