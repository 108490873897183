import { Button, Container, Grid } from '@material-ui/core';
import React, { useCallback, useContext, useRef } from 'react';

import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useTriggerRender } from 'utils';

import { NearMissControllerService, NearMissDto } from '../../../api/client';
import AutoComplete from '../../../Common/Form/AutoComplete';
import DateInput from '../../../Common/Form/DateInput';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: NearMissDto;
  onSave?(id: string): void;
}

export default function NearMissRecordForm({ data, onSave }: Props) {
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { companies, shiftDefinitions, departments, workStations, nearMissTypes } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();

  const submit = useCallback(
    async values => {
      const payload = {
        nearMissTypes: values?.nearMissTypes.map((accidentType: string) => {
          return {
            nearMissTypeDefinitionId: accidentType,
          };
        }),
        companyId: values?.companyId,
        nearMissDepartments: values?.nearMissDepartments.map((department: string) => {
          return {
            departmentId: department,
          };
        }),
        nearMissStations: values?.nearMissStations.map((station: string) => {
          return {
            stationId: station,
          };
        }),
        date: values?.date,
        shiftId: values?.shiftId,
        description: values?.description,
      };
      try {
        if (!data?.id) {
          const result = await NearMissControllerService.saveNearMiss(payload);
          onSave(result.id);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await NearMissControllerService.partialUpdateNearMiss(data?.id, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [data, onSave, showMessage]
  );

  // useAsyncEffect(async () => {
  //   if (!data) {
  //     return;
  //   }
  //   await defer();
  //   formRef?.current?.methods?.setValue('companyId', data?.companyId);
  //   formRef?.current?.methods?.setValue(
  //     'nearMissTypes',
  //     data?.nearMissTypes?.map(n => n.id)
  //   );
  //   formRef?.current?.methods?.setValue(
  //     'nearMissDepartments',
  //     data?.nearMissDepartments?.map(n => n.departmentId)
  //   );
  //   formRef?.current?.methods?.setValue(
  //     'nearMissStations',
  //     data?.nearMissStations?.map(n => n.stationId)
  //   );
  //   formRef?.current?.methods?.setValue('description', data?.description);
  //   formRef?.current?.methods?.setValue('date', data?.date);
  //   formRef?.current?.methods?.setValue('shiftId', data?.shiftId);
  //
  //   formRef?.current?.methods?.trigger();
  // }, [data]);

  return (
    <Form onSubmit={submit} ref={formRef} onChange={reRender}>
      <Container maxWidth={'xl'}>
        <Grid container spacing={1}>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete single name="companyId" options={companies} label="Şirket" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="nearMissDepartments" options={departments} label="Departman" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="nearMissStations" options={workStations} label="İstasyon" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete name="nearMissTypes" options={nearMissTypes} label="Ramak Kala Türü" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete single name="shiftId" options={shiftDefinitions} label="Vardiya" renderOption={'checkbox'} />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <DateInput required type="date-time" name="date" label="Tarihi" />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <TextField name="description" label="Tanımı" rows={3} />
          </Grid>
          <Grid item md={12} xs={12} sm={12} lg={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => formRef?.current?.methods?.reset()}>
              Formu Temizle
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Form>
  );
}
