import { Button, Container, Divider, Grid, Typography } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import {
  RiskAssessmentControllerService,
  RiskAssessmentHazardControllerService,
  RiskAssessmentHazardViewControllerService,
} from 'api/client';
import { RiskAssessmentGuestControllerService } from 'api/client';
import { RiskAssessmentHazardView } from 'api/client';

import AutoComplete from '../Common/Form/AutoComplete';
import DateInput from '../Common/Form/DateInput';
import EmployeeSelectionBox from '../Common/Form/EmployeeSelection/EmployeeSelectionBox';
import Form, { FormRef } from '../Common/Form/Form';
import GenerativeAutoComplete from '../Common/Form/GenerativeAutoComplete';
import { RadioGroupInput } from '../Common/Form/RadioGroup';
import { SelectOption } from '../Common/Form/Select';
import TextField from '../Common/Form/TextField';
import Toolbar from '../Common/Toolbar';
import { AlertContext } from '../context/alert.context';
import { SelectOptionsContext } from '../context/select-options.context';
import BaseSection from '../TransactionsAndLists/EmployeeList/Employee/BaseSection';
import { useAsyncEffect, useTriggerRender } from '../utils';
import RiskAssessmentHazard from './RiskAssessmentHazard';

const radioOptions = [
  {
    text: '5x5',
    value: 'MATRIX',
  },
  {
    text: 'Fine - Kinney',
    value: 'FINE_KINNEY',
  },
];

export default function AddForm() {
  const { companies, departments, workStations, positions } = useContext(SelectOptionsContext);
  const [guests, setGuests] = useState([]);
  const [riskAssessmentId, setRiskAssessmentId] = useState(null);
  const [riskAssessmentType, setRiskAssessmentType] = useState(null);
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const [showHazard, setShowHazard] = useState<boolean>(false);
  const { rowRiskAssessmentId } = useParams<{ rowRiskAssessmentId: string }>();
  const [riskAssessmentRowData, setRiskAssessmentRowData] = useState({});

  useAsyncEffect(async () => {
    if (rowRiskAssessmentId) {
      const res = await RiskAssessmentHazardViewControllerService.getRiskAssessmentHazardView(rowRiskAssessmentId);
      setRiskAssessmentRowData(res);
      setRiskAssessmentId(res.riskAssessmentId);
    }
  }, [rowRiskAssessmentId]);

  const submit = useCallback(
    async values => {
      const payload = {
        companyId: values?.companyId,
        date: values?.date,
        departmentId: values?.departmentId,
        riskAssessmentPositions: values?.positionIds?.map((position: string) => {
          return {
            positionId: position,
          };
        }),
        riskAssessmentStations: values?.stationIds?.map((station: string) => {
          return {
            stationId: station,
          };
        }),
        riskAssessmentEmployees: values?.employeeIds?.map((employee: string) => {
          return {
            employeeId: employee,
          };
        }),
        workDescription: values?.workDescription,
      };
      try {

        if (!rowRiskAssessmentId) {
          const res = await RiskAssessmentControllerService.saveRiskAssessment(payload);
          setRiskAssessmentType(values?.riskAssessmentType);
          setRiskAssessmentId(res?.id);
          if (guests?.length > 0) {
            await RiskAssessmentGuestControllerService.saveGuest(res?.id, guests);
            showMessage('Ekleme işlemi başarılı.', 'success', 'info');
            setShowHazard(true);
          }
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          setShowHazard(true);
        } else {
          const res = await RiskAssessmentHazardViewControllerService.getRiskAssessmentHazardView(rowRiskAssessmentId);
          await RiskAssessmentControllerService.updateRiskAssessment(res.riskAssessmentId, payload);
          await RiskAssessmentGuestControllerService.saveGuest(res.riskAssessmentId, guests);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
      } catch (e) {
        showMessage('İşlem sırasında hata oluştu.', 'error', 'info');
      }
    },
    [showMessage, guests, rowRiskAssessmentId]
  );

  const guestsOptions = useMemo(
    () => guests?.map<SelectOption<symbol>>(({ name, id, surname }) => ({ text: `${name} ${surname}`, value: id })),
    [guests]
  );

  const handleGuestSubmit = useCallback(
    (id: symbol, { name, surname, nid }) => {
      setGuests([...guests, { id, name, surname, nid }]);
    },
    [setGuests, guests]
  );

  useAsyncEffect(async () => {
    if (rowRiskAssessmentId) {
      const res = await RiskAssessmentHazardViewControllerService.getRiskAssessmentHazardView(rowRiskAssessmentId);
      const guestsData = await RiskAssessmentGuestControllerService.getAllGuests(res.riskAssessmentId);
      if (guestsData.length > 0) {
        setGuests(
          guestsData?.map(guestData => {
            return {
              id: guestData?.id,
              name: guestData?.name,
              surname: guestData?.surname,
              nid: guestData?.nid,
            };
          })
        );
      }
    }
  }, [rowRiskAssessmentId]);

  useAsyncEffect(async () => {
    if (guests) {
      await defer();
      formRef?.current?.methods?.setValue(
        'guests',
        guests?.reduce((acc, item) => [...acc, item.id], [])
      );
    }
    await formRef?.current?.methods?.trigger();
  }, [guests]);

  const employeeIds = useMemo(() => riskAssessmentRowData?.riskAssessmentEmployees?.reduce((acc, item) => [...acc, item.employeeId], []), [
    riskAssessmentRowData,
  ]);

  useAsyncEffect(async () => {
    if (Object.entries(riskAssessmentRowData).length) {
      await defer();

      for (const [key, value] of Object.entries(riskAssessmentRowData)) {
        formRef?.current?.methods?.setValue(key, value === null ? '' : value);
      }
      formRef?.current.methods.setValue('employeeIds', employeeIds ?? []);
      formRef?.current?.methods?.setValue(
        'positionIds',
        riskAssessmentRowData?.positions?.reduce((acc, item) => [...acc, item.positionId], [])
      );
      formRef?.current?.methods?.setValue(
        'stationIds',
        riskAssessmentRowData?.stations?.reduce((acc, item) => [...acc, item.stationId], [])
      );
      setRiskAssessmentId(riskAssessmentRowData?.riskAssessmentId);
      setShowHazard(true);
      await formRef?.current?.methods?.trigger();
    }
  }, [rowRiskAssessmentId, riskAssessmentRowData, setRiskAssessmentId, setShowHazard]);

  return (
    <>
      <Toolbar color="secondary">
        <Typography variant="h6">Risk Değerlendirme</Typography>
      </Toolbar>
      <div className="m-2">
        <Form onSubmit={submit} ref={formRef} onChange={reRender}>
          <Container maxWidth={'xl'}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <DateInput required type="date" name="date" label="Risk Değerlendirme Tarihi" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <AutoComplete required single name="companyId" options={companies} label="Şirket" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <AutoComplete single name="departmentId" options={departments} label="Departmanlar" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <AutoComplete name="positionIds" options={positions} label="Pozisyonlar" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <AutoComplete name="stationIds" options={workStations} label="İstasyonlar" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RadioGroupInput
                  disabled={!!riskAssessmentRowData?.riskAssessmentType}
                  defaultValue="MATRIX"
                  label="Risk Değerlendirme Methodu"
                  name="riskAssessmentType"
                  options={radioOptions}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField rows={3} name="workDescription" label="Yapılan İşin Tanımı" />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="subtitle1">Risk Degerlendirme Ekibi Üyeleri</Typography>
                <Divider />
              </Grid>
              <Grid item md={6} sm={6} lg={6}>
                <EmployeeSelectionBox name="employeeIds" label="Personel Seç " />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <GenerativeAutoComplete
                  name="guests"
                  options={guestsOptions}
                  label="Misafir Katılımcılar"
                  dialogLabel="Misafir Katılımcı Ekle"
                  adornment={open => (
                    <Button color="secondary" onClick={open}>
                      Misafir Ekle
                    </Button>
                  )}
                  onGenerate={handleGuestSubmit}>
                  <TextField type="number" name="nid" label="T.C Kimlik Numarası" />
                  <TextField name="name" label="Adı" required />
                  <TextField name="surname" label="Soyadı" required />
                </GenerativeAutoComplete>
              </Grid>
              <Grid className="text-right" item lg={12} md={12} sm={12} xs={12}>
                <Button variant="contained" type="submit" color="secondary" className="mr-2">
                  {Object.entries(riskAssessmentRowData).length > 0 ? 'Güncelle' : 'Kaydet'}
                </Button>
                <Button variant="contained" type="button" onClick={() => formRef?.current?.methods?.reset()}>
                  Vazgeç
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
        {showHazard ? (
          <div className="mt-2">
            <BaseSection expandedByDefault={showHazard} icon="icon-flash" title="Tehlikeler">
              <RiskAssessmentHazard
                riskAssessmentType={riskAssessmentType ?? riskAssessmentRowData?.riskAssessmentType}
                riskAssessmentId={riskAssessmentId ?? rowRiskAssessmentId}
              />
            </BaseSection>{' '}
          </div>
        ) : null}
      </div>
    </>
  );
}
