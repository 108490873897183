/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmployeeIbysWorkConditionDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    employeeId?: string;
    physicalWorkCondition?: EmployeeIbysWorkConditionDto.physicalWorkCondition;
    code?: number;
}

export namespace EmployeeIbysWorkConditionDto {

    export enum physicalWorkCondition {
        PHYSICAL = 'PHYSICAL',
        AIR = 'AIR',
        NOISE = 'NOISE',
        ELECTRICITY = 'ELECTRICITY',
        DUST_REGULATIONS = 'DUST_REGULATIONS',
        CHEMICAL = 'CHEMICAL',
        BIOLOGICAL_FACTORS = 'BIOLOGICAL_FACTORS',
    }


}
