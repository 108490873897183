import React, { useCallback, useContext } from 'react';

import { FamilyHistoryContext } from '../../family-history.context';
import FamilyHistoryForm from '../Form/DialogFamilyHistory';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(FamilyHistoryContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <FamilyHistoryForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
