import { modelDataMatcher } from 'utils';

export class ProfessionalBackground {
  public id: string = null;
  public station: string = null;
  public sector: string = null;
  public companyName: string = null;
  public job: string = null;
  public exposures: number[] = [];
  public quitDate: string = null;
  public hireDate: string = null;

  constructor(data: Partial<ProfessionalBackground> = {}) {
    modelDataMatcher(data, this);
  }
}
