import { Grid, IconButton } from '@material-ui/core';
import { Cancel, Visibility } from '@material-ui/icons';
import React, { useContext } from 'react';

import { AccidentReviewContext } from '../accident-review-context';

interface Props {
  onClickCancel(): void;
  onClickPreview(data: any): void;
}

export default ({ onClickCancel, onClickPreview }: Props) => {
  const { updatable } = useContext(AccidentReviewContext);

  return (
    <Grid>
      <IconButton color="inherit" onClick={onClickPreview}>
        <Visibility />
      </IconButton>
      {updatable && (
        <IconButton color="inherit" onClick={onClickCancel}>
          <Cancel />
        </IconButton>
      )}
    </Grid>
  );
};
