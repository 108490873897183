import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { EmergencyContactsModel, EmergencyContactsResult, getEmergencyContacts } from 'api/hs/emergency-contacts';
import { useAsyncEffect } from 'utils';

import { EmergencyContactContext } from './emergency-contacts.context';
import EmergencyContacts from './emergencyContacts';

export default function Index() {
  const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContactsResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<EmergencyContactsModel>(null);
  const { employeeId } = useParams<{ employeeId: string }>();

  const fetch = useCallback(async () => {
    const result = await getEmergencyContacts(employeeId);
    setEmergencyContacts(result);
    setLoading(false);
  }, [employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <EmergencyContactContext.Provider
      value={{
        emergencyContacts,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <EmergencyContacts />
    </EmergencyContactContext.Provider>
  );
}
