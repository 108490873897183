import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { BuildingDefinitionDto } from 'api/client';
import { BuildingDefinitionControllerService } from 'api/client';
import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { SelectOptionsContext } from 'context/select-options.context';
import { useAsyncEffect } from 'utils';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: BuildingDefinitionDto;
}

export default function BuildingsForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const { locations } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await BuildingDefinitionControllerService.saveBuildingDefinition({ ...values });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await BuildingDefinitionControllerService.updateBuildingDefinition(data.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
        // await reload('ppeClassificationDefinitions');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(true);
      }
    },
    [data, showMessage, onClose]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog color="primary" opened={open} onClose={() => onClose(true)} title={data ? 'Bina Düzenleme' : 'Bina Ekleme'}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12}>
            <TextField required name="name" label="Bina Giriniz" />
          </Grid>
          <Grid item lg={12} md={12}>
            <AutoComplete options={locations} single required name="locationId" label="Lokasyon Giriniz" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
