import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { WorkEquipment } from 'Common/Forms/Definitions/WorkEquipment/WorkEquipment';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import {
  deleteWorkEquipment,
  getWorkEquipments,
  updateWorkEquipment,
  WorkEquipmentArrayPayload,
  WorkEquipmentPayload,
  WorkEquipmentResult,
} from '../../../../../api/hs/work-equipments';

export default function WorkingPositionDefinitions() {
  const { reset, setValue, trigger, ...formMethods } = useForm();
  const { showMessage } = useContext(AlertContext);
  const { employeeId } = useParams<{ employeeId: string }>();

  useAsyncEffect(async () => {
    if (employeeId) {
      await defer();
      const WorkConditionDefinitions: WorkEquipmentResult[] = await getWorkEquipments(employeeId.toString());
      const WorkConditionArray: number[] = [];
      WorkConditionDefinitions.forEach(val => {
        WorkConditionArray.push(val.code);
      });
      setValue('code', WorkConditionArray);
      trigger();
    }
  }, [employeeId, setValue, trigger]);

  const handleSubmit = useCallback(
    async (values: WorkEquipmentArrayPayload) => {
      try {
        const workEquipmentArray: WorkEquipmentPayload[] = [];
        values.code.forEach(val => {
          workEquipmentArray.push({ code: val });
        });
        await deleteWorkEquipment(employeeId.toString());
        await updateWorkEquipment(employeeId.toString(), workEquipmentArray);
        showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [employeeId, showMessage]
  );
  return (
    <FormProvider {...{ ...formMethods, reset, setValue, trigger }}>
      <form noValidate className="mt-2" onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container spacing={1}>
          <WorkEquipment required={false} />
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              Güncelle
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
