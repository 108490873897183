import { ArrowLeftRounded, ArrowRightRounded } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { getCurrentWidgetVisibilities, getWidgetTypes } from '../../../api/hs/dashboard/widget/widget';
import { WidgetType, WidgetTypeStatus, WidgetVisibilityItem } from '../../../api/hs/dashboard/widget/widget.payload';
import { UserContext } from '../../../context/user';
import { UserRole } from '../../../model/user-role.enum';
import AccidentWidgetCard from './AccidentWidget';
import ActionCard from './Action';
import EmployeeCard from './Employee';
import EmployeeWidget from './EmployeeWidget';
import ExaminationCard from './Examination';
import InvisibleWidget from './InvisibleWidget';
import LostDayCard from './LostDay';
import NearMissWidget from './NearMissWidget';
import PregnancyCard from './Pregnancy';
import ReferraledEmployeeCard from './ReferraledEmployee';
import TrainingCard from './Training';
import VaccineCard from './Vaccine';

export default () => {
  const slider = useRef();
  const [visibleWidgets, setVisibleWidgets] = useState([]);
  const [invisibleWidgets, setInvisibleWidgets] = useState([]);
  const { user } = useContext(UserContext);

  const sliceInvisibleWidgets = (arr: WidgetVisibilityItem[], sliceBy = 3) => {
    const chunks = [];
    const length = arr.length;
    let i = 0;

    while (i < length) {
      chunks.push(arr.slice(i, (i += sliceBy)));
    }
    return chunks;
  };

  const getWidgets = useCallback(() => {
    getWidgetTypes(WidgetTypeStatus.ACTIVE).then(setVisibleWidgets);

    getCurrentWidgetVisibilities()
      .then((results: WidgetVisibilityItem[]) => {
        return sliceInvisibleWidgets(results);
      })
      .then(setInvisibleWidgets);
  }, []);

  useEffect(() => {
    getWidgets();
  }, [getWidgets]);

  useEffect(() => {
    if (visibleWidgets && slider.current) {
      slider.current.slickGoTo(0);
    }
  }, [visibleWidgets]);

  const widgets = {
    [WidgetType.EMPLOYEE]: <EmployeeWidget key={'employee'} reload={getWidgets} />,
    [WidgetType.REPORT_REPORT]: <EmployeeCard key={'report'} reload={getWidgets} />,
    [WidgetType.REFERRAL]: user.roles.includes(UserRole.PHYSICIAN, UserRole.NURSE) && (
      <ReferraledEmployeeCard key={'referral'} reload={getWidgets} />
    ),
    [WidgetType.PERIODIC_EXAMINATION]: user.roles.includes(UserRole.PHYSICIAN, UserRole.NURSE) && (
      <ExaminationCard key={'examination'} reload={getWidgets} />
    ),
    [WidgetType.VACCINE]: user.roles.includes(UserRole.PHYSICIAN, UserRole.NURSE) && <VaccineCard key={'vaccine'} reload={getWidgets} />,
    [WidgetType.PREGNANCY]: user.roles.includes(UserRole.PHYSICIAN, UserRole.NURSE) && (
      <PregnancyCard key={'pregnancy'} reload={getWidgets} />
    ),
    [WidgetType.NEAR_MISS]: <NearMissWidget key={'nearmiss'} reload={getWidgets} />,
    [WidgetType.ACCIDENT]: <AccidentWidgetCard key={'accident'} reload={getWidgets} />,
    [WidgetType.TRAINING]: <TrainingCard key={'training'} reload={getWidgets} />,
    [WidgetType.ACTION]: <ActionCard key={'action'} reload={getWidgets} />,
    [WidgetType.LOST_DAY]: <LostDayCard key={'lostday'} reload={getWidgets} />,
  };

  if (visibleWidgets.length === 0 && invisibleWidgets.length === 0) return <></>;

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    prevArrow: <ArrowLeftRounded color={'secondary'} fontSize={'large'} />,
    nextArrow: <ArrowRightRounded color={'secondary'} fontSize={'large'} />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} ref={slider}>
      {visibleWidgets.filter(widget => !!widget && widget in widgets).map(widget => widgets[widget])}
      {invisibleWidgets.length > 0 &&
        invisibleWidgets.map(chunk => {
          return (
            <>
              {chunk
                .filter((widget: WidgetVisibilityItem) => !!widget && widget.widgetType in widgets)
                .sort()
                .map((widget: WidgetVisibilityItem) => (
                  <InvisibleWidget key={widget.id} type={widget.widgetType} reload={getWidgets} id={widget.id} />
                ))}
            </>
          );
        })}
    </Slider>
  );
};
