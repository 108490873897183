import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    justifyContent: 'center',
  },
}));

export default function AlertInformation(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Alert className={classes.alert} severity={props.severity}>
        {props.message}
      </Alert>
    </div>
  );
}
