import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import {
  EmployeeImportedFilesModel,
  EmployeeImportedFilesResult,
  getEmployeeImportedFiles,
} from 'api/hs/file-uploads/employee-imported-files';
import { useAsyncEffect } from 'utils';

import EmployeeImportedFiles from './employeeImportedFiles';
import { ProfileUploadContext } from './profile-file-upload-context';

export default function Index() {
  const [employeeImportedFiles, setEmployeeImportedFiles] = useState<EmployeeImportedFilesResult[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState<EmployeeImportedFilesModel>(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [previewImageOpened, setPreviewImageOpened] = useState(false);
  const { employeeId } = useParams<{ employeeId: string }>();

  const fetch = useCallback(async () => {
    const result = await getEmployeeImportedFiles(employeeId);
    setEmployeeImportedFiles(result);
    setLoading(false);
  }, [employeeId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <ProfileUploadContext.Provider
      value={{
        employeeImported: employeeImportedFiles,
        reload: fetch,
        loading,
        setLoading,
        newDialogOpened,
        selectedData,
        setSelectedData,
        setNewDialogOpened,
        previewImageOpened,
        setPreviewImageOpened,
      }}>
      <EmployeeImportedFiles />
    </ProfileUploadContext.Provider>
  );
}
