import { createContext } from 'react';

import { StorehouseDefinitionsResult } from 'api/hs/definitions/ppe-definitions/storehouse-definitions';

export interface StorehouseDefinitionsContextType {
  storehouseDefinitions: StorehouseDefinitionsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  newDialogOpened: boolean;

  setNewDialogOpened(opened: boolean): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: StorehouseDefinitionsResult;

  setSelectedData(data: StorehouseDefinitionsResult): void;
}

export const StorehouseDefinitionsContext = createContext<StorehouseDefinitionsContextType>(null);
