import formatter from 'date-fns/format';
import { useCallback } from 'react';

import { useI18n } from './use-i18n';

export function useDateFormat(): (date: Date, format: string) => string;
export function useDateFormat(initialFormat: string): (date: Date, format?: string) => string;
export function useDateFormat(initialFormat?: string) {
  const { locale } = useI18n();

  return useCallback((date: Date, format: string = initialFormat) => formatter(date, format, { locale: locale ?? undefined }), [
    initialFormat,
    locale,
  ]);
}
