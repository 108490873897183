import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { SystemCheckPayload } from './system-check.payload';
import { SystemCheckResult } from './system-check.result';

export async function getSystemCheck(employeeId: string, examinationId?: string): Promise<SystemCheckResult[]> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<SystemCheckResult[]>(`/system-checks?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<SystemCheckResult[]>(`/system-checks?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveSystemCheck(body: SystemCheckPayload[]): Promise<SystemCheckResult> {
  const [err, res] = await tryCatch(HSApi.post('/system-checks', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateSystemCheck(id: string, body: SystemCheckPayload): Promise<SystemCheckResult> {
  const [err, res] = await tryCatch(HSApi.put(`/system-checks/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteSystemCheck(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/system-checks/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
