import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { MatrixHazardDamageDefinitionControllerService } from 'api/client';
import { MatrixHazardDamageDefinitionDto } from 'api/client';

import BaseDefinitionPage from '../../BaseDefinitionPage';
import MatrixDamageDefinitionForm from './Forms/MatrixDamageDefinitionForm';

export default function MatrixDamageDefinition() {
  return (
    <BaseDefinitionPage<MatrixHazardDamageDefinitionDto>
      listLabelPropName="label"
      fetchData={MatrixHazardDamageDefinitionControllerService.getAllMatrixDamageDefinitions}
      FormDialog={MatrixDamageDefinitionForm}
      onDelete={MatrixHazardDamageDefinitionControllerService.deleteAllMatrixDamageDefinitions}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
