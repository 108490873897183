import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import { Card, CardHeader, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { convertClusteredDataByFieldName } from '../utils/clustered-chart-data-converter-xy';
import ClusteredXYColumnChart from '../utils/ClusteredXYColumnChart';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);

interface ChartProps {
  chartName: string;

  rawData: any[];
  categoryFieldName: string;
  selectorFieldByName: string;
  // categoryFieldName: string;
  // categoryFieldByIdNameMap?: Map<string, string>;
  xAxesText: string;
  yAxesText: string;
  xAxesTitle: string;
}

const ChronicDiseasesContainer = (props: ChartProps) => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    const dd = convertClusteredDataByFieldName(props.rawData, props.categoryFieldName, props.selectorFieldByName);
    setData(dd);
  }, [props.categoryFieldName, props.rawData, props.selectorFieldByName]);

  return data.length > 0 && <ClusteredXYColumnChart chartName={props.chartName} data={data} />;
};

export default ChronicDiseasesContainer;
