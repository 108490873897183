import { TableRowProps } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { TableColumn } from './table-column.model';
import { TableContext, TableContextType } from './table.context';

export interface TableContextProviderProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  onSortChange?(sortingData: TableContextType<T>['sort']): any;
  onRowClick?(row: T, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>): any;
  RowProps?: TableRowProps;
}

export default function TableContextProvider<T extends {} = {}>({
  columns,
  children,
  data,
  onSortChange = noop,
  onRowClick = noop,
  RowProps = {},
}: PropsWithChildren<TableContextProviderProps<T>>) {
  const [sort, setSort] = useState<TableContextType<T>['sort']>(false);
  const [internalData, setInternalData] = useState(data);
  const [columnVisibilities, setVisibilities] = useState(
    columns.reduce((acc, { id, defaultVisibility = true }) => ({ ...acc, [id]: defaultVisibility }), {} as { [P in keyof T]: boolean })
  );

  const setColumnVisibility = useCallback(
    (id: keyof T | symbol, visible: boolean) => {
      setVisibilities({ ...columnVisibilities, [id]: visible });
    },
    [columnVisibilities]
  );

  const visibleColumns = useMemo(() => columns.filter(({ id }) => columnVisibilities[id as keyof T]), [columns, columnVisibilities]);

  const context = useMemo<TableContextType<T>>(
    () => ({
      columns,
      sort,
      setSort,
      columnVisibilities,
      setColumnVisibility,
      visibleColumns,
      data: internalData,
      setData: setInternalData,
      onRowClick,
      RowProps,
    }),
    [columns, sort, columnVisibilities, setColumnVisibility, visibleColumns, internalData, onRowClick, RowProps]
  );

  useEffect(() => {
    setInternalData(data);
  }, [data]);

  useEffect(() => {
    onSortChange(sort);
  }, [sort, onSortChange]);

  return <TableContext.Provider value={context}>{children}</TableContext.Provider>;
}
