import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getPregnancies, PregnanciesModel, PregnanciesResult } from 'api/hs/examination/pregnancies';
import { useAsyncEffect } from 'utils';

import Pregnancies from './pregnancies';
import { PregnanciesContextContext } from './pregnancies.context';

export default function Index() {
  const [pregnancies, setPregnancies] = useState<PregnanciesResult[]>([]);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<PregnanciesModel>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getPregnancies(employeeId, examinationId);
    setPregnancies(result);
    setLoading(false);
  }, [employeeId, examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <PregnanciesContextContext.Provider
      value={{
        pregnancies,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Pregnancies />
    </PregnanciesContextContext.Provider>
  );
}
