import React from 'react';

import ListSystemCheck from './List';
import DialogFormSystemCheck from './List/DialogFormSystemCheck';

export default function SystemCheck() {
  return (
    <>
      <ListSystemCheck />
      <DialogFormSystemCheck />
    </>
  );
}
