import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';

import { saveCommitteeMeetingResult, updateCommitteeMeetingResult } from 'api/hs/committee/committee-meetings-detail';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

import TextField from '../../../../Common/Form/TextField';

interface CommitteeMeetingResultData {
  id: string;
  result: string;
}
interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: CommitteeMeetingResultData;
}

export default function CommitteeMeetingResultForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { committeeMeetingId } = useParams<{ committeeMeetingId: string }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data) {
          await saveCommitteeMeetingResult(committeeMeetingId, values);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        } else {
          await updateCommitteeMeetingResult(data?.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          onClose(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, data, committeeMeetingId]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    formRef?.current?.methods?.setValue('result', data?.result);
    formRef?.current?.methods?.trigger();
  }, [data]);

  return (
    <Dialog
      width="sm"
      title={data ? 'Karar Düzenleme' : 'Karar Ekleme'}
      color="secondary"
      opened={open}
      onClose={onClose.bind(null, false)}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <TextField name="result" label="Karar" type="text" rows={3} />
          </Grid>
          <Grid item xs={12} md={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
