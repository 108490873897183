/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProtocolNumber } from './ProtocolNumber';

export type PhysicianProtocolRegistryView = {
    referenceTableId?: string;
    id?: string;
    protocolOperationType?: PhysicianProtocolRegistryView.protocolOperationType;
    protocolNumber?: ProtocolNumber;
    employeeId?: string;
    createdDate?: string;
    avatarPath?: string;
    name?: string;
    age?: string;
    gender?: string;
    complaint?: string;
    analysis?: string;
    diagnosis?: string;
    prescription?: string;
    referral?: string;
    restReport?: string;
    opinion?: string;
    description?: string;
}

export namespace PhysicianProtocolRegistryView {

    export enum protocolOperationType {
        EXAMINATION = 'EXAMINATION',
        REFERRAL = 'REFERRAL',
        REPORT = 'REPORT',
        ANALYSIS = 'ANALYSIS',
        DEFAULT_NURSE_OPERATION = 'DEFAULT_NURSE_OPERATION',
        VITAL_SIGN = 'VITAL_SIGN',
        BODY_MEASUREMENT = 'BODY_MEASUREMENT',
    }


}
