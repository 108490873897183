import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { BloodRelationResult, deleteBloodRelation, getBloodRelation } from 'api/hs/definitions/health-definitions/blood-relation';
import BloodRelationEditDialog from 'Common/Forms/Definitions/HealthDefinition/BloodRelation/BloodRelationEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function BloodRelation() {
  return (
    <BaseDefinitionPage<BloodRelationResult>
      listLabelPropName="label"
      fetchData={getBloodRelation}
      FormDialog={BloodRelationEditDialog}
      onDelete={deleteBloodRelation}
      Icon={<DomainIcon />}
      searchName="label"
    />
  );
}
