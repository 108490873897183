import React from 'react';

import { NurseOperationContentResult } from 'api/hs/TransactionAndLists/NurseOperationList';
import { TableColumn } from 'Common/Table/Raw/table-column.model';

import { dateDisplayFormat } from '../../utils';
import Avatar from '../EmployeeList/Avatar';

export const CELL: TableColumn<NurseOperationContentResult>[] = [
  {
    id: 'avatarPath',
    render(row): JSX.Element {
      return <Avatar url={row.avatarPath} />;
    },
    label: 'Resmi',
    sortable: true,
    width: '5%',
  },
  {
    id: 'name',
    defaultVisibility: true,
    label: 'Ad Soyad',
    sortable: true,
    width: '15%',
  },
  {
    id: 'nurseOperationDefinition',
    defaultVisibility: true,
    label: 'Tür',
    sortable: true,
    width: '10%',
  },
  {
    id: 'nid',
    defaultVisibility: true,
    label: 'Kimlik No',
    sortable: true,
    width: '10%',
  },

  {
    id: 'genderName',
    defaultVisibility: false,
    label: 'Cinsiyet',
    sortable: true,
    width: '10%',
  },
  {
    id: 'bloodTypeName',
    defaultVisibility: true,
    label: 'Kan Grubu',
    sortable: true,
    width: '5%',
  },
  {
    id: 'companyName',
    defaultVisibility: true,
    label: 'Şirket',
    sortable: true,
    width: '15%',
  },
  {
    id: 'departmentName',
    defaultVisibility: false,
    label: 'Departman',
    sortable: true,
    width: '10%',
  },
  {
    id: 'protocolNumber',
    defaultVisibility: true,
    label: 'Protokol No',
    sortable: true,
    width: '5%',
  },
  {
    id: 'userName',
    defaultVisibility: true,
    label: 'Son İşlemler',
    sortable: true,
    width: '15%',
  },
  {
    id: 'date',
    defaultVisibility: true,
    label: 'İşlem Tarihi',
    sortable: true,
    width: '5%',
    render(row: NurseOperationContentResult): JSX.Element {
      return <>{dateDisplayFormat(row?.date)}</>;
    },
  },
];
