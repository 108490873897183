import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ActionView, ActionViewControllerService } from '../../api/client';
import { AnalysisControllerService } from '../../api/client';
import { FormRef } from '../../Common/Form/Form';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { EnumContext } from '../../context/enum.context';
import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function Actions() {
  const [chartData, setChartData] = useState<ActionView[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const { analysisCategoryDefinitions } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      filter: {
        dateMin: values?.minDate + 'T00:00:00',
        dateMax: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'analysisDate',
      },
    };
    const res = await AnalysisControllerService.getAnalysisViews(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);

  const datafilter = chartData?.filter(data => data?.analysisDetailViews.length > 0);
  const analysisData = datafilter?.map((dataMap: any) => ({
    test: dataMap?.analysisDetailViews?.filter(a => a.result > a.max),
  }));

  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Tahlil Tetkik İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Şirket Bazlı Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="analysis-company-report"
                  data={chartData}
                  dateFieldName="analysisDate"
                  selectorFieldByName="companyName"
                  xAxesTitle="Şirketler"
                  xAxesText="Tarih"
                  yAxesText="Tahlil / Tetkik Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Departman Bazlı Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="analysis-department-report"
                  data={chartData}
                  dateFieldName="analysisDate"
                  selectorFieldByName="departmentName"
                  xAxesTitle="Departmanlar"
                  xAxesText="Tarih"
                  yAxesText="Tahlil / Tetkik Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="Görev Bazlı Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="analysis-position-report"
                  data={chartData}
                  dateFieldName="analysisDate"
                  selectorFieldByName="positionName"
                  xAxesTitle="Görevler"
                  xAxesText="Tarih"
                  yAxesText="Tahlil / Tetkik Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>

            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Card style={{ padding: '10px', margin: '10px' }}>
                <CardHeader title="İş İstasyon Bazlı Dağılımı" />{' '}
                <DateGroupXYChart
                  chartName="analysis-station-report"
                  data={chartData}
                  dateFieldName="analysisDate"
                  selectorFieldByName="stationName"
                  xAxesTitle="İş İstasyonları"
                  xAxesText="Tarih"
                  yAxesText="Tahlil / Tetkik Sayısı"
                  dataDisplayOption={dataDisplayOption}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <PieChartGridContainer
                title="Tahlil Tetkik'e Göre Dağılımı"
                chartProps={{
                  chartName: 'analysis-categoryDefinitionId-report',
                  preparedData: convertAccidentReviewPropertyToChartData(chartData, 'analysisTypeId', analysisCategoryDefinitions),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
