import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormContext } from './Form/form.context';

export interface Options {
  value: string;
  text: string;
}

interface Props {
  name: string;
  options: Options[];
  color?: string;
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
}

export const RadioGroupInput = ({ name, label, options, disabled = false, defaultValue = '' }: Props) => {
  const { control } = useFormContext();
  const { readonly } = useContext(FormContext);
  const { trigger } = useFormContext();
  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);

  const generateRadioOptions = () => {
    return options.map(singleOption => (
      <div key={singleOption?.value}>
        <FormControlLabel disabled={notEditable} value={singleOption.value} label={singleOption.text} control={<Radio />} />
      </div>
    ));
  };

  const handleChange = useCallback(
    (onChange: (value: boolean) => void) => (e: ChangeEvent, value: boolean) => {
      if (notEditable) {
        return;
      }

      onChange(value);
      trigger();
    },
    [trigger, notEditable]
  );

  return (
    <FormControl variant={'outlined'}>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ onChange, value }) => (
          <RadioGroup row value={value} onChange={handleChange(onChange)}>
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
