import { createContext } from 'react';

import { OpinionModel, OpinionsResult } from 'api/hs/examination/hs-exam-opinions';
import { GetWorkingRestrictionDefinitionsResult } from 'api/hs/resource/get-working-restriction-definitions.result';

export interface OpinionContextType {
  opinion: OpinionsResult;

  workingRestriction: GetWorkingRestrictionDefinitionsResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: OpinionModel;

  setSelectedData(data: OpinionModel): void;
}

export const OpinionContext = createContext<OpinionContextType>(null);
