import { Button, Container, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import { NearMissControllerService } from '../../../../../api/client';
import Dialog from '../../../../../Common/Dialog';
import AutoComplete from '../../../../../Common/Form/AutoComplete';
import DateInput from '../../../../../Common/Form/DateInput';
import Form, { FormRef } from '../../../../../Common/Form/Form';
import TextField from '../../../../../Common/Form/TextField';
import { HSTableContext } from '../../../../../Common/Table/HSTable/hs-table.context';
import { AlertContext } from '../../../../../context/alert.context';
import { SelectOptionsContext } from '../../../../../context/select-options.context';
import { useAsyncEffect, useTriggerRender } from '../../../../../utils';
import { NearMissTableContext } from '../../near-miss-table.context';

export default function EditDialog() {
  const formRef = useRef<FormRef<Record<string, any>>>();

  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(NearMissTableContext);
  const { refresh } = useContext(HSTableContext);
  const { companies, shiftDefinitions, departments, workStations, nearMissTypes } = useContext(SelectOptionsContext);
  const { showMessage } = useContext(AlertContext);
  const reRender = useTriggerRender();
  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [setEditDialogOpened, refresh]
  );
  const submit = useCallback(
    async values => {
      const payload = {
        nearMissTypes: values?.nearMissTypes.map((accidentType: string) => {
          return {
            nearMissTypeDefinitionId: accidentType,
          };
        }),
        companyId: values?.companyId,
        nearMissDepartments: values?.nearMissDepartments.map((department: string) => {
          return {
            departmentId: department,
          };
        }),
        nearMissStations: values?.nearMissStations.map((station: string) => {
          return {
            stationId: station,
          };
        }),
        date: values?.date,
        shiftId: values?.shiftId,
        description: values?.description,
      };
      try {
        if (!selectedData) {
          await NearMissControllerService.saveNearMiss(payload);
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
          await closeDialog(true);
        } else {
          await NearMissControllerService.partialUpdateNearMiss(selectedData?.nearMissId, payload);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
          await closeDialog(true);
        }
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
      }
    },
    [selectedData, showMessage, closeDialog]
  );

  useAsyncEffect(async () => {
    if (!selectedData) {
      return;
    }
    await defer();
    formRef?.current?.methods?.setValue('companyId', selectedData?.companyId);
    formRef?.current?.methods?.setValue(
      'nearMissTypes',
      selectedData?.nearMissTypeViews?.map(n => n.nearMissTypeDefinitionId)
    );
    formRef?.current?.methods?.setValue(
      'nearMissDepartments',
      selectedData?.nearMissDepartmentViews?.map(n => n.departmentId)
    );
    formRef?.current?.methods?.setValue(
      'nearMissStations',
      selectedData?.nearMissStationViews?.map(n => n.stationId)
    );
    formRef?.current?.methods?.setValue('description', selectedData?.description);
    formRef?.current?.methods?.setValue('date', selectedData?.date);
    formRef?.current?.methods?.setValue('shiftId', selectedData?.shiftId);

    formRef?.current?.methods?.trigger();
  }, [selectedData]);

  return (
    <Dialog width={'md'} color="secondary" opened={editDialogOpened} onClose={closeDialog} title={'Ramak Kala Düzenle'}>
      <Form onSubmit={submit} ref={formRef} onChange={reRender}>
        <Container maxWidth={'xl'}>
          <Grid container spacing={1}>
            <Grid item md={4} sm={12} xs={12}>
              <AutoComplete single name="companyId" options={companies} label="Şirket" renderOption={'checkbox'} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <AutoComplete name="nearMissDepartments" options={departments} label="Departman" renderOption={'checkbox'} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <AutoComplete name="nearMissStations" options={workStations} label="İstasyon" renderOption={'checkbox'} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <AutoComplete name="nearMissTypes" options={nearMissTypes} label="Ramak Kala Türü" renderOption={'checkbox'} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <AutoComplete single name="shiftId" options={shiftDefinitions} label="Vardiya" renderOption={'checkbox'} />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <DateInput required type="date-time" name="date" label="Tarihi" />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <TextField name="description" label="Tanımı" rows={3} />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12} className="text-right">
              <Button variant="contained" type="submit" color="secondary" className="mr-2">
                {selectedData?.id ? 'Güncelle' : 'Kaydet'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </Dialog>
  );
}
