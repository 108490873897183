export const handleResult = (res: any, error: any) => {
  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    throw error;
  }

  return res.data;
};

export const stringify = (params: any): string => {
  if (!params || Object.keys(params).length === 0) return '';

  const queryString = Object.keys(params)
    .filter(key => !!params[key])
    .map(key => `${key}=${params[key]}`);
  return '?' + queryString.join('&');
};

export const excelExport = (data: any, fileName: string) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};
