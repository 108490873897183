import { makeStyles } from '@material-ui/core';
import SvgIcon from '@material-ui/icons/Check';
import React from 'react';

import Toolbar from 'Common/Toolbar';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'space-between',

    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  actionIcon: {
    color: 'inherit',
  },
  fab: {
    transform: 'translateY(25%)',
    zIndex: 2,
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <Toolbar toolbarProps={{ className: classes.toolbar }}>
      <h3 className="my-0">
        <SvgIcon className="text-larger mr-1" />
        Yapılacaklar
      </h3>
    </Toolbar>
  );
}
