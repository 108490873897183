import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import Button from 'Common/Button';
import RefferralForm from 'Common/Forms/Examination/RefferralForm';

import { ReferralsContextContext } from '../../referrals.context';

export default function Add() {
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(ReferralsContextContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <Button className="mt-1" startIcon={<AddIcon />} onClick={onClick} color="primary" variant="contained">
        Sevk Ekle
      </Button>
      <RefferralForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
