import React, { useCallback } from 'react';

import { getTrainingEmployeeList } from 'api/hs/trainings/TrainingEmployeeFilter';
import { HSTableProps } from 'Common/Table/HSTable/hs-table.props';
import HSTableAndPaginationAndTableContextProvider from 'Common/Table/HSTable/HSTableAndPaginationAndTableContextProvider';

import { CELL } from './columns';
import ListTable from './ListTable';
import ShowTrainings from './ShowTrainings';
import Toolbar from './Toolbar';
export default function List() {
  const fetchEmployeeTrainingList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getTrainingEmployeeList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? { isPlanned: true }
    );
  }, []);

  return (
    <HSTableAndPaginationAndTableContextProvider fetcher={fetchEmployeeTrainingList} columns={CELL}>
      <Toolbar />
      <ListTable />
      <ShowTrainings />
    </HSTableAndPaginationAndTableContextProvider>
  );
}
