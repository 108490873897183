import React, { useEffect, useState } from 'react';
import { Paper, Path } from 'react-raphael';

const selectedColor = '#ffb3b3';
const defaultColor = '#FFF8E1';
const attributes = { 'stroke-width': '1', stroke: '#757575', fill: defaultColor };

const figures = () => {
  /**
   * her birisi birer svg path i. svg olarak acilip duzenlenebilir
   * uygulama tavsiyesi: online: vectr.com ama en iyisi Inkscape
   */
  const FRONT_HEAD = {
    path:
      'M77.9,46.1c4.5,0,7.6-1.8,9.6-4.8c0.5-1.2,1-3.2,1-3.9c-0.1-1.1,1-5.9,1.2-6.8 c0.1-1.5,0.3-1.4,0-8.1s-7.6-10.7-11.6-10.7c-6.4,0-10.5,6.6-11.1,9.3c-0.4,1.7-0.6,4.9-0.5,5.9c0.1,1,0.2,2.6,0.1,3.4 C67,32,68,35.2,68,37.2c0.1,1.1,0.2,2.6,0.7,3.9C69.3,42,73.4,46.1,77.9,46.1z',
    id: 'FRONT_HEAD',
    human_body_part_type_id: 'FRONT_HEAD',
    selected: false,
    attributes: { ...attributes },
  };
  const RIGHT_EYE = {
    path: 'M69.7,27.2c0-1.1,1.5-2,3.2-2s3.2,0.9,3.2,2s-1.5,2-3.2,2S69.7,28.3,69.7,27.2z',
    id: 'RIGHT_EYE',
    human_body_part_type_id: 'RIGHT_EYE',
    selected: false,
    attributes: { ...attributes },
  };
  const LEFT_EYE = {
    path: 'M80.2,27.2c0-1.1,1.5-2,3.2-2s3.2,0.9,3.2,2s-1.5,2-3.2,2S80.2,28.3,80.2,27.2z',
    id: 'LEFT_EYE',
    human_body_part_type_id: 'LEFT_EYE',
    selected: false,
    attributes: { ...attributes },
  };
  const RIGHT_EAR = {
    path: 'M64.9,30c-0.8,0.8-0.4,3.6-0.2,4.3s1.2,3.1,1.5,3.3c0.3,0.2,1.1,1,1.8-0.3 c0-2.1-1-5.3-1.4-6.9C66.4,30,65.7,29.1,64.9,30z',
    id: 'RIGHT_EAR',
    human_body_part_type_id: 'RIGHT_EAR',
    selected: false,
    attributes: { ...attributes },
  };
  const LEFT_EAR = {
    path: 'M88.5,37.4c0.8,1.3,1.9,0.7,2.1-0.4c0.2-1,1.6-1.6,1.4-4.9c-0.2-3.3-1.7-2.4-2.3-1.5 C89.5,31.5,88.3,36.3,88.5,37.4z',
    id: 'LEFT_EAR',
    human_body_part_type_id: 'LEFT_EAR',
    selected: false,
    attributes: { ...attributes },
  };
  const NOSE = {
    path:
      'M75.3,32.6c-0.1,1,1.3,1.6,2.1,1.4c1,0.5,1.4,0.1,2.1-0.1c1.2,0.2,1.7-0.7,1.7-1.6 c0-0.9-0.9-1.4-1-2.6c-0.1-1.2-0.7-1.6-0.7-2.8c0.1-1.2-0.5-2.1-1.3-2.1c-1.1,0-1.3,1.6-1.2,2.2s-0.4,2.2-0.5,2.9 C76.4,30.5,75.5,31.1,75.3,32.6z',
    id: 'NOSE',
    human_body_part_type_id: 'NOSE',
    selected: false,
    attributes: { ...attributes },
  };
  const MOUTH = {
    path:
      'M80.4,35.7c-0.5-0.6-1.3-0.3-1.6-0.2c-0.2,0.1-0.7,0.2-1.1-0.1c-0.3-0.3-1.3,0-1.5,0.3 c-0.3,0.4-2.5,1.1-2.6,1.7c-0.2,0.6,1.8,0.7,2.3,0.9c0.5,0.2,0.9,0.8,2.7,0.8s2.3-0.6,2.8-0.7s1.7-0.2,1.8-0.7 C83.2,37.2,81.2,36.6,80.4,35.7z',
    id: 'MOUTH',
    human_body_part_type_id: 'MOUTH',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_NECK = {
    path:
      'M66.5,58.9c3.3-0.2,8.6-0.5,9.5,0.8c0.9,1.2,3.3,1.3,4.1,0s8-1.3,10.6-0.9 c1,0.2,2.1,0.2,3,0.1c2.3-0.2,4.3-1.1,5.3-2.1c-1.9,0-3.9-1.1-6.5-2.2c-3.4-1.5-5.1-3.3-5.3-3.9s0-7.2,0.2-9.3 c-1.9,3-5,4.8-9.6,4.8s-8.6-4-9.2-4.9c0.5,1.3,0.3,6.2,0.2,9.5c-1.8,3.2-8.4,5-10,5.8c0.4,0.3,2.2,1.4,4.4,2 C64.2,58.7,65.4,58.9,66.5,58.9z',
    id: 'FRONT_NECK',
    human_body_part_type_id: 'FRONT_NECK',
    selected: false,
    attributes: { ...attributes },
  };
  const CHEST = {
    path:
      'M103.9,69.5c-0.4-4.3-7.9-10.1-10.2-10.7c-1,0.1-2,0.1-3-0.1c-2.7-0.4-9.8-0.3-10.6,0.9 c-0.8,1.3-3.2,1.2-4.1,0c-0.9-1.2-6.2-0.9-9.5-0.8c-1.1,0.1-2.3-0.1-3.4-0.5c-1.8,0.8-10.9,6.3-11.1,13.7c-0.2,7.5,0,15.1,0,17.9 c0.1,0,2,5.3,2.5,6.4c0.5,1.2,1.1,3.7,1.8,5.1c1.7,1.6,9.8,5.3,15.3,3.8c5.5-1.5,7.6-1.3,13.6,0c6,1.3,11-0.6,15.3-5.7 c0.3-0.7,1-2.5,1.1-3.1c0.1-0.7,0.6-1.5,0.9-2c0.3-0.6,1.6-4.5,1.7-4.4C104.4,87.4,104.2,72,103.9,69.5z',
    id: 'CHEST',
    human_body_part_type_id: 'CHEST',
    selected: false,
    attributes: { ...attributes },
  };
  const ABDOMEN = {
    path:
      'M85.3,105.5c-6-1.3-8.1-1.5-13.6,0c-5.5,1.5-13.6-2.2-15.3-3.8c0.7,1.4,0.4,2.5,0.8,3.7 s1,4.5,0.1,8.7s-0.3,7.5-0.1,8.4c0.2,0.6,0.5,1,0.3,1.8c2.4,2.4,6.4,7.2,21,7.2s18.1-4.3,20.6-7c-0.3-0.9-0.1-2.5,0.5-4.4 c0.6-1.9-0.7-9.3-0.8-11c-0.1-1.7,0.5-4.5,0.6-5.8c0.1-1.3,0.9-2.8,1.2-3.5C96.3,104.9,91.3,106.8,85.3,105.5z',
    id: 'ABDOMEN',
    human_body_part_type_id: 'ABDOMEN',
    selected: false,
    attributes: { ...attributes },
  };
  const PELVIS = {
    path:
      'M78.5,131.5c-14.7,0-18.6-4.8-21-7.2c-0.1,0.4-0.3,0.9-0.7,1.5c-0.6,0.9-1,3.2-1.4,5.7 c2.2,1.9,11.8,10,17.6,17.3c1.7-0.8,7.5-1.1,10.4-0.1c5.9-6.9,15.7-14.9,17.9-16.6c-0.2-2.9-1.7-6.6-2.1-7.6 C96.6,127.2,93.1,131.5,78.5,131.5z',
    id: 'PELVIS',
    human_body_part_type_id: 'PELVIS',
    selected: false,
    attributes: { ...attributes },
  };
  const PUBIS = {
    path:
      'M73,148.8c1.6,2,2.9,3.9,3.7,5.6c0.3-0.4,0.6-0.6,1.4-0.7c0.9-0.1,1.2,0.2,1.4,0.3 c0.8-1.6,2.1-3.5,3.8-5.4C80.5,147.7,74.7,148,73,148.8z',
    id: 'PUBIS',
    human_body_part_type_id: 'PUBIS',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_SHOULDER = {
    path:
      'M103.9,69.5c2.8,6.3,9.6,6.3,12.3,10.1c-0.2-0.7-0.2-1.7-0.1-3 c0.4-4-1-10.9-3.7-14.3c-2.7-3.4-11.1-5.6-13.3-5.6c-1,1-3,1.9-5.3,2.1C96,59.4,103.5,65.1,103.9,69.5z',
    id: 'FRONT_LEFT_SHOULDER',
    human_body_part_type_id: 'FRONT_LEFT_SHOULDER',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_ARM = {
    path:
      'M117.1,101c3-1.3,5.4-3,4.8-7.1c-0.3-2.4-3.7-10.7-4.6-12c-0.5-0.7-0.9-1.3-1.2-2.3 c-2.6-3.8-9.4-3.9-12.3-10.1c0.2,2.5,0.5,17.9,0.3,20.7c0,0,4.4,8.9,4.9,10.1C110.9,103,114.3,102.2,117.1,101z',
    id: 'FRONT_LEFT_ARM',
    human_body_part_type_id: 'FRONT_LEFT_ARM',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_ELBOW = {
    path:
      'M128.9,109.3c-0.4-1.6-1-3.4-1.9-5c-1-1.9-3.4-6.3-3.7-6.7c-0.7-1.1-1.1-1.9-1.4-3.6 c0.6,4-1.9,5.8-4.8,7.1c-2.8,1.2-6.2,2-8-0.7c0.6,1.2,1.5,2.4,1.5,3.6c0,0.3,0.4,2.9,0.9,5.3c0.5,2,1.1,3.8,1.3,4.2 C112.5,110.7,125.9,101.5,128.9,109.3z',
    id: 'FRONT_LEFT_ELBOW',
    human_body_part_type_id: 'FRONT_LEFT_ELBOW',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_FOREARM = {
    path:
      'M134.5,129.3c-0.6-1.6-1.2-3.4-1.7-4.5c-0.8-2.1-2.7-10.7-3.9-15.5 c-3-7.8-16.4,1.4-16.1,4.1c1.5,3.8,7.5,12.6,8.4,13.8c1,1.2,3.4,4.8,4.1,5.9C126.7,135.2,134.9,131.3,134.5,129.3z',
    id: 'FRONT_LEFT_FOREARM',
    human_body_part_type_id: 'FRONT_LEFT_FOREARM',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_WRIST = {
    path:
      'M137.8,135.6c-1.8-0.8-1.2-1.7-2-3.1c-0.4-0.6-0.8-1.9-1.3-3.2 c0.4,2-7.8,5.9-9.2,3.8c0.3,0.6,1.1,2.2,1.8,3.7c0.7,1.4,1.3,2.7,1.4,2.9C131.6,137.2,136.1,135.5,137.8,135.6z',
    id: 'FRONT_LEFT_WRIST',
    human_body_part_type_id: 'FRONT_LEFT_WRIST',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_HAND = {
    path:
      'M142.9,146.2c0.1-1.3-2-7-1.4-8.9c-1.3-0.6-2.8-1.3-3.7-1.7c-1.8-0.1-6.3,1.6-9.4,4.1 c0.2,0.4-0.2,1.5,0,3.4c0.2,1.9,0.9,4.9,1.3,6.7C134.6,151.4,142.6,149.5,142.9,146.2z',
    id: 'FRONT_LEFT_HAND',
    human_body_part_type_id: 'FRONT_LEFT_HAND',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_FINGERS = {
    path:
      'M149,141.9c-0.6-0.8-3.7-2.4-4.5-3c-0.4-0.3-1.7-0.9-3-1.5 c-0.7,1.9,1.5,7.6,1.4,8.9c-0.3,3.3-8.3,5.2-13,3.6c0.5,1.9,1.5,3.8,1.8,4.8c0.3,0.9,2,6.1,2.6,6.7c0.6,0.5,1.6,0.2,1.7-0.6 c0.2-0.7-0.3-2.3-0.5-3c-0.2-0.7-1.2-4-1.4-4.5s-0.4-1.1-0.1-1.1c0.4,0,0.4,0.6,0.7,1.2c0.2,0.6,1.2,4.1,1.4,5 c0.2,1,0.9,3.6,1.3,4.5c0.4,1,1,2.1,1.9,2s1-1,1.1-1.8s-1.2-4.9-1.3-5.8c-0.1-0.9-1.1-4-1.3-4.4c-0.2-0.4-0.3-1,0.1-1.1 c0.4,0,0.6,1.2,0.8,1.8s2,6.7,2.1,7.4s0.5,3,1.3,3.7c0.6,0.6,1.2,0.8,1.8,0.2s0.5-2.1,0.2-3.2c-0.3-1.1-1-4.4-1.2-5.2 c-0.2-0.8-1.3-4.4-1.5-5c-0.2-0.7-0.4-1.1-0.2-1.2c0.2-0.1,0.5,0.2,0.7,1.1c0.3,0.9,1.4,4.3,1.8,5.3c0.4,1,0.3,2.4,0.9,3.6 c0.5,1.2,1.5,1.6,2.2,1.5s0.9-1.5,0.9-2.1s-1-4.3-1.3-5.7c-0.3-1.4-1.1-3.5-1.4-4.8c-0.3-1.3-1.5-4.9-1.8-5.4 c-0.3-0.5-0.1-1,0.5-0.8c0.7,0.2,1.9,0.5,2.7,1.6c0.8,1,2.3,1.4,2.8,1.5s1.8,0.1,1.9-1.3C151.3,143.4,149.6,142.7,149,141.9z',
    id: 'FRONT_LEFT_FINGERS',
    human_body_part_type_id: 'FRONT_LEFT_FINGERS',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_SHOULDER = {
    path:
      'M63.2,58.4c-2.1-0.5-3-1.2-4.4-2c-2.2,0-12.3,2.4-15,5.9 c-2.7,3.4-4.1,10.3-3.7,14.3c0.1,1.4,0.1,2.3-0.1,3c2.6-3.8,9.4-3.1,12.3-9.4C52.7,65.9,60.9,59,63.2,58.4z',
    id: 'FRONT_RIGHT_SHOULDER',
    human_body_part_type_id: 'FRONT_RIGHT_SHOULDER',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_ARM = {
    path:
      'M47,100.3c0.6-1.2,4.9-10.1,4.9-10.1c-0.1-2.8,0.1-17.1,0.3-19.6 c-3.1,5.8-9.6,5.2-12.3,9.1c-0.2,1.1-0.7,1.6-1.2,2.3c-0.9,1.3-4.3,9.6-4.6,12c-0.6,4,1.9,5.8,4.8,7.1 C41.9,102.2,45.3,103,47,100.3z',
    id: 'FRONT_RIGHT_ARM',
    human_body_part_type_id: 'FRONT_RIGHT_ARM',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_ELBOW = {
    path:
      'M43.4,113.4c0.2-0.4,0.8-2.2,1.3-4.2c0.6-2.4,0.9-5,0.9-5.3c0-1.3,0.9-2.5,1.5-3.6 c-1.8,2.7-5.1,1.9-8,0.7c-3-1.3-5.4-3-4.8-7.1c-0.2,1.7-0.7,2.5-1.4,3.6c-0.3,0.4-2.7,4.8-3.7,6.7c-0.9,1.6-1.5,3.4-1.9,5 C30.3,101.5,43.7,110.7,43.4,113.4z',
    id: 'FRONT_RIGHT_ELBOW',
    human_body_part_type_id: 'FRONT_RIGHT_ELBOW',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_FOREARM = {
    path:
      'M30.9,133.1c0.6-1.1,3.1-4.7,4.1-5.9c1-1.2,7-10,8.4-13.8 c0.3-2.7-13.1-11.9-16.1-4.1c-1.3,4.9-3.1,13.4-3.9,15.5c-0.5,1.1-1.1,2.9-1.7,4.5C21.3,131.3,29.5,135.2,30.9,133.1z',
    id: 'FRONT_RIGHT_FOREARM',
    human_body_part_type_id: 'FRONT_RIGHT_FOREARM',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_WRIST = {
    path:
      'M27.7,139.7c0.1-0.2,0.7-1.5,1.4-2.9c0.7-1.5,1.5-3.1,1.8-3.7 c-1.4,2.1-9.6-1.8-9.2-3.8c-0.5,1.3-1,2.5-1.3,3.2c-0.8,1.4-0.2,2.3-2,3.1C20.1,135.5,24.6,137.2,27.7,139.7z',
    id: 'FRONT_RIGHT_WRIST',
    human_body_part_type_id: 'FRONT_RIGHT_WRIST',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_HAND = {
    path:
      'M26.4,149.8c0.5-1.9,1.1-4.8,1.3-6.7c0.2-1.9-0.2-3,0-3.4c-3.1-2.5-7.6-4.2-9.4-4.1 c-0.9,0.4-2.3,1.1-3.7,1.7c0.7,1.9-1.5,7.6-1.4,8.9C13.6,149.5,21.6,151.4,26.4,149.8z',
    id: 'FRONT_RIGHT_HAND',
    human_body_part_type_id: 'FRONT_RIGHT_HAND',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_FINGERS = {
    path:
      'M5,144.8c0.2,1.4,1.4,1.4,1.9,1.3s2-0.4,2.8-1.5c0.8-1,2.1-1.4,2.7-1.6 c0.7-0.2,0.8,0.3,0.5,0.8c-0.3,0.5-1.5,4.1-1.8,5.4s-1,3.4-1.4,4.8s-1.3,5.2-1.3,5.7s0.2,2,0.9,2.1s1.7-0.3,2.2-1.5 c0.5-1.2,0.5-2.6,0.9-3.6c0.4-1,1.5-4.5,1.8-5.3c0.3-0.9,0.5-1.2,0.7-1.1c0.2,0.1-0.1,0.5-0.2,1.2s-1.3,4.2-1.5,5s-0.9,4.1-1.2,5.2 c-0.3,1.1-0.4,2.6,0.2,3.2c0.6,0.6,1.2,0.4,1.8-0.2c0.7-0.7,1.2-3,1.3-3.7c0.1-0.8,2-6.8,2.1-7.4s0.4-1.8,0.8-1.8 c0.4,0,0.3,0.7,0.1,1.1c-0.2,0.4-1.2,3.6-1.3,4.4s-1.4,5-1.3,5.8s0.2,1.7,1.1,1.8s1.5-1,1.9-2c0.4-1,1.1-3.6,1.3-4.5 c0.2-1,1.2-4.4,1.4-5c0.2-0.6,0.3-1.2,0.7-1.2s0.1,0.5-0.1,1.1s-1.2,3.9-1.4,4.5s-0.7,2.2-0.5,3c0.2,0.7,1.2,1,1.7,0.6 c0.7-0.6,2.3-5.8,2.6-6.7c0.3-0.9,1.3-2.9,1.8-4.8c-4.7,1.6-12.7-0.3-13-3.6c-0.1-1.3,2-7,1.4-8.9c-1.3,0.6-2.5,1.2-3,1.5 c-0.9,0.6-4,2.2-4.5,3S4.9,143.4,5,144.8z',
    id: 'FRONT_RIGHT_FINGERS',
    human_body_part_type_id: 'FRONT_RIGHT_FINGERS',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_THIGH = {
    path:
      'M83.3,148.7c-1.6,1.9-3,3.8-3.8,5.4c0.2,0.2,0.6,1.1,0.9,1.7 c0.3,0.6,0.2,4.3-0.1,6.6c-0.3,2.2,0.3,12.5,0.8,14.8s1.8,11.6,2.1,14s1.1,6.6,1.5,8.3c7.6,5.4,11-4.2,18.7-6.9 c1.2-2.1,2.9-5.7,3.3-10.9c0.2-2.3,0.3-5.7,0.2-9c-0.2-4.3-0.8-8.5-1.1-10c-0.5-2.7-3.1-17.3-3.6-19.4c-0.5-2.1-0.9-8.4-1.1-11.2 C99,133.8,89.2,141.8,83.3,148.7z',
    id: 'FRONT_LEFT_THIGH',
    human_body_part_type_id: 'FRONT_LEFT_THIGH',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_KNEE = {
    path:
      'M84.8,199.5c0.4,1.7,0.9,3,0.9,5c0,2.1,0.8,4.6,1.1,5.2c3.6,3.8,12.8,9.8,14.5-1.8 c0-2.4,0.7-8.2,0.7-10.5s0.3-2.7,1.5-4.8C95.8,195.3,92.4,204.9,84.8,199.5z',
    id: 'FRONT_LEFT_KNEE',
    human_body_part_type_id: 'FRONT_LEFT_KNEE',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_FIBULA = {
    path:
      'M86.8,209.8c0.2,0.6-0.3,3.4-0.9,7.1c-0.6,3.7-0.9,10.2-0.6,11.9c0.2,1.7,2,6,2.5,7.3 c0.5,1.3,0.1,1.8,0.4,4.4s1.5,9.9,1.9,12.3c0.4,2.4,0.3,4.4-0.1,5.7c1.4-0.9,8.9-1.5,9.7,1.8c-0.2-0.9-0.4-2.1-0.2-4.7 c0.2-2.6,1-12.6,1.7-18.3s1.4-17.1,0.9-20.2s-0.7-6.7-0.7-9.1C99.5,219.6,90.4,213.6,86.8,209.8z',
    id: 'FRONT_LEFT_FIBULA',
    human_body_part_type_id: 'FRONT_LEFT_FIBULA',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_ANKLE = {
    path:
      'M89.9,258.4c-0.5,1.3-0.7,4.4-0.6,4.7c0.1,0.3,0.2,2-0.2,2.8s-0.3,2.8-0.1,4.5 c1.3-2.8,7.7-6.3,10.5-4.9c-0.4-1.2-0.5-1.2-0.2-1.9c0.3-0.7,0.5-2.5,0.3-3.4C98.8,257,91.3,257.5,89.9,258.4z',
    id: 'FRONT_LEFT_ANKLE',
    human_body_part_type_id: 'FRONT_LEFT_ANKLE',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_FOOT = {
    path:
      'M89,270.5c0.2,1.7,2.5,3.1,3.1,3.5s0.6,0.9,0.8,1.5c0.2,0.6,0.6,2.2,0.8,4.4 c0.3-0.9,12.2-3.7,13.7-1.6c-0.2-0.9-2-3.1-3.4-4.6c-1.4-1.5-1.6-2.4-2.6-3.9c-1-1.5-1.5-3.1-2-4.2C96.7,264.2,90.2,267.6,89,270.5 z',
    id: 'FRONT_LEFT_FOOT',
    human_body_part_type_id: 'FRONT_LEFT_FOOT',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_LEFT_TOES = {
    path:
      'M93.7,279.8c0.2,2.2,1.3,3.3,1.8,3.7c0.5,0.4,0.6,0.6,0.8,1c0.2,0.3,0.7,1.3,2.3,2 c1.6,0.8,3.1-0.2,3.5-1c0.9,0.9,2.3,0.1,2.6-0.4c1,0.3,1.6-0.7,1.7-1.1c0.3,0.1,0.7-0.2,0.9-0.5c0.2,0.1,0.5,0.1,1-0.7 c0.5-0.8-0.6-3.7-0.9-4.5C105.9,276.1,94,278.9,93.7,279.8z',
    id: 'FRONT_LEFT_TOES',
    human_body_part_type_id: 'FRONT_LEFT_TOES',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_THIGH = {
    path:
      'M55.4,131.5c-0.2,2.9-1.2,10.1-1.6,12.2c-0.5,2.1-3.1,16.7-3.6,19.4 c-0.3,1.5-0.9,5.7-1.1,10c-0.2,3.3,0,6.7,0.2,9c0.4,5.2,2.2,8.9,3.3,10.9c7.7,2.7,11.1,12.3,18.7,6.9c0.4-1.7,1.1-5.9,1.5-8.3 c0.3-2.4,1.6-11.7,2.1-14c0.5-2.4,1-12.6,0.8-14.8c-0.3-2.2-0.3-6-0.1-6.6c0.3-0.6,0.8-1.6,1-1.8c-0.8-1.6-2.1-3.7-3.7-5.6 C68.2,142.7,58.2,133.7,55.4,131.5z',
    id: 'FRONT_RIGHT_THIGH',
    human_body_part_type_id: 'FRONT_RIGHT_THIGH',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_KNEE = {
    path:
      'M52.6,193c1.2,2.1,1.4,2.5,1.5,4.8s0.7,8.1,0.7,10.5c1.7,11.6,10.8,5.6,14.5,1.8 c0.2-0.6,1.1-3.2,1.1-5.2s0.5-3.3,0.9-5C63.7,205.3,60.3,195.7,52.6,193z',
    id: 'FRONT_RIGHT_KNEE',
    human_body_part_type_id: 'FRONT_RIGHT_KNEE',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_FIBULA = {
    path:
      'M54.8,208.3c0,2.4-0.3,6.1-0.7,9.1c-0.5,3,0.3,14.4,0.9,20.2 c0.7,5.8,1.6,15.8,1.7,18.3c0.2,2.6-0.1,3.8-0.2,4.7c0.7-3.2,8.3-2.7,9.7-1.8c-0.5-1.3-0.5-3.2-0.1-5.7s1.6-9.8,1.9-12.3 c0.3-2.5,0-3.1,0.4-4.4c0.5-1.3,2.3-5.6,2.5-7.3c0.2-1.7,0-8.2-0.6-11.9c-0.6-3.7-1.1-6.5-0.9-7.1C65.7,214,56.6,220,54.8,208.3z',
    id: 'FRONT_RIGHT_FIBULA',
    human_body_part_type_id: 'FRONT_RIGHT_FIBULA',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_ANKLE = {
    path:
      'M56.6,260.6c-0.2,0.9,0.1,2.7,0.3,3.4c0.3,0.7,0.2,0.8-0.2,1.9 c2.7-1.3,9.2,2.1,10.5,4.9c0.2-1.7,0.2-3.6-0.1-4.5s-0.3-2.5-0.2-2.8c0.1-0.3-0.1-3.5-0.6-4.7C64.8,257.9,57.3,257.4,56.6,260.6z',
    id: 'FRONT_RIGHT_ANKLE',
    human_body_part_type_id: 'FRONT_RIGHT_ANKLE',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_FOOT = {
    path:
      'M56.7,265.9c-0.4,1.2-1,2.7-2,4.2c-1,1.5-1.2,2.4-2.6,3.9c-1.4,1.5-3.2,3.7-3.4,4.6 c1.5-2.1,13.4,0.6,13.7,1.6c0.2-2.2,0.6-3.8,0.8-4.4c0.2-0.6,0.2-1.2,0.8-1.5c0.6-0.3,2.9-1.7,3.1-3.5 C65.9,268.1,59.4,264.6,56.7,265.9z',
    id: 'FRONT_RIGHT_FOOT',
    human_body_part_type_id: 'FRONT_RIGHT_FOOT',
    selected: false,
    attributes: { ...attributes },
  };
  const FRONT_RIGHT_TOES = {
    path:
      'M48.7,278.6c-0.2,0.9-1.4,3.7-0.9,4.5c0.5,0.8,0.9,0.8,1,0.7 c0.1,0.3,0.6,0.6,0.9,0.5c0,0.4,0.6,1.4,1.7,1.1c0.2,0.5,1.6,1.4,2.6,0.4c0.4,0.7,1.9,1.8,3.5,1c1.6-0.8,2.2-1.7,2.3-2 c0.2-0.3,0.3-0.6,0.8-1c0.5-0.4,1.6-1.5,1.8-3.7C62,279.3,50.2,276.5,48.7,278.6z',
    id: 'FRONT_RIGHT_TOES',
    human_body_part_type_id: 'FRONT_RIGHT_TOES',
    selected: false,
    attributes: { ...attributes },
  };

  // BACK
  const BACK_HEAD = {
    path:
      'M236.2,39.8c0.6-4,2-13.8,2.2-17.5c0.3-4.5-6.1-11.6-12.8-11.6c-5.7,0-12.2,6.7-12.1,11 c0,1.6,0.4,6.9,0.5,7.6c0,0.5,0.9,6.7,1.6,10.4C217.7,44.1,234.1,44.1,236.2,39.8z',
    id: 'BACK_HEAD',
    human_body_part_type_id: 'BACK_HEAD',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_NECK = {
    path:
      'M246.1,54.8c-5.9-2-10.2-5.7-10.5-6.2c-0.2-0.5,0-6.4,0.3-7.2c0.1-0.1,0.2-0.7,0.3-1.7 c-2.1,4.3-18.6,4.3-20.7-0.1c0.3,1.5,0.5,2.5,0.6,2.7c0.3,0.4,0.6,5.9,0.1,6.6c-1.6,2.1-6.6,4.3-10.3,5.8 C210.5,53.5,242.3,54.1,246.1,54.8z',
    id: 'BACK_NECK',
    human_body_part_type_id: 'BACK_NECK',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK = {
    path:
      'M254.7,81c0.6-3.7,1.4-8.2,0.8-10.6c-0.6-2.4-1.6-10.9-9.4-15.5c-3.7-0.8-35.6-1.4-40.1-0.1 c-8.3,4.5-9.3,14.4-9.5,15.6c-0.2,1.2,0.1,5.5,0.5,7.7c0.4,2.2,1.7,6.2,1,13.9c0.2,0,0.3,0.7,0.4,1.3c0.1,0.5,0.9,3.5,1.9,4.8 c1,1.3,1.9,5.2,2.8,6.8c0.9,1.2,8.7,2.2,17.5,2.4c2,0,4.1-0.4,6.2-0.4c2.1,0,4.2,0.4,6.2,0.3c8.3-0.2,15.3-1.3,16.1-2.4 c0.5-1.2,1.3-3.1,1.6-3.8c0.3-1,0.6-1.9,1-2.6s1.6-3.8,1.8-4.4c0.2-0.6,0.5-2,0.7-2C253.9,88.7,254.1,84.7,254.7,81z',
    id: 'BACK',
    human_body_part_type_id: 'BACK',
    selected: false,
    attributes: { ...attributes },
  };
  const LOIN = {
    path:
      'M232.8,107.1c-2,0.1-4.1-0.3-6.2-0.3c-2.1,0-4.2,0.4-6.2,0.4c-8.9-0.2-16.6-1.3-17.5-2.4 c0.8,1.6,1.4,4,1.8,7.9c0.4,3.9-0.5,6.7-0.7,7.9s0,5,0.3,5.9c0.3,0.8,0.8,1.9-0.1,3.3c1.3-1.9,6.4-3.5,11.8-3.5 c6.2,0,6.5,2.1,10.8,2.1c4,0,5.4-2.1,10.8-2.1c5.3,0,9.2,1.5,10.1,3.5c-0.1-0.3-0.2-0.5-0.2-0.5c-0.2-0.5-0.2-1.3-0.1-1.7 c0.2-0.4,0.6-2.7,0.6-4c0-1.3-0.8-6.3-1-7.3s0.3-6,0.5-7c0.1-1,0.6-3.1,0.9-3.5c0.1-0.1,0.3-0.5,0.5-1 C248.1,105.8,241.1,106.9,232.8,107.1z',
    id: 'LOIN',
    human_body_part_type_id: 'LOIN',
    selected: false,
    attributes: { ...attributes },
  };
  const BUTTOCK = {
    path:
      'M237.7,126.3c-5.3,0-6.8,2.1-10.8,2.1c-4.3,0-4.6-2.1-10.8-2.1c-5.5,0-10.5,1.6-11.8,3.5 c0,0.1-0.1,0.1-0.1,0.2c-1.3,1.9-2.5,10.8-2.4,13.4c0.1,2.6-0.5,5-0.8,6.8c0,4,1.5,5.2,3.2,7.8c4.3,6.5,12.2,11.3,19.3,4.3 c0.6-0.6,1.4-1.9,1.6-2.4s0.4-0.7,0.9-0.7s0.8,0.2,0.9,0.6c0.1,0.4,0.8,1.8,1.5,2.4c7.9,8.3,15.3,3.1,19.4-3.5 c1.8-2.9,3.3-3.3,3.3-8.4c-0.3-1.8-0.7-3.8-0.8-4.5c-0.2-1.3-0.1-5.1-0.6-8.9c-0.3-2.9-1.3-5.4-1.8-6.6c0-0.1-0.1-0.2-0.1-0.3 C246.8,127.8,242.9,126.2,237.7,126.3z',
    id: 'BUTTOCK',
    human_body_part_type_id: 'BUTTOCK',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_SHOULDER = {
    path:
      'M196.5,70.3c0.2-1.2,1.3-11.1,9.5-15.6c-1.7,0.6-3.1,1.1-3.9,1.3 c-1.7,0.5-8.8,2-11.2,4.1c-3.6,3.1-5.4,7.3-5.8,13.1c-0.2,2.8,0,5.4,0,7.2C187.2,75.4,192.3,75,196.5,70.3z',
    id: 'BACK_LEFT_SHOULDER',
    human_body_part_type_id: 'BACK_LEFT_SHOULDER',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_ARM = {
    path:
      'M196.9,78c-0.4-2.2-0.7-6.5-0.5-7.7c-4.1,4.7-9.2,5.1-11.3,10.1 c-1.4,2.2-3.8,7.1-4.2,9.2c-0.4,2.1-0.9,4.2-1.5,5.8c-0.8,7.2,2.2,8.5,5.5,9.7c3.5,1.3,6.1,0.1,8-3.2c0.2-0.5,0.5-0.9,0.7-1.3 c0.7-1.5,3.4-7.2,3.6-7.8s0.4-1,0.7-1C198.7,84.2,197.3,80.2,196.9,78z',
    id: 'BACK_LEFT_ARM',
    human_body_part_type_id: 'BACK_LEFT_ARM',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_ELBOW = {
    path:
      'M184.9,105.2c-3.2-1.2-6.3-2.5-5.5-9.7c-0.6,1.6-0.4,3.2-1.4,4.9 c-1,1.7-0.2,1.4-2,4.2c-0.9,1.3-1.9,4.2-2.8,7.4c-0.3,1-0.5,2-0.7,2.9c0.7-1.2,1.4-4.7,10.5-2.3c2.2,0.6,6.7,2.3,5.6,5.8 c0.6-1.6,1-3.2,1.3-4.5c0.5-2.2,0.8-3.8,0.8-4.2c0.1-0.9,0.2-1.5,0.3-2s0.5-2.1,0.5-2.8c0-0.5,0.6-1.8,1.3-3 C191,105.3,188.4,106.4,184.9,105.2z',
    id: 'BACK_LEFT_ELBOW',
    human_body_part_type_id: 'BACK_LEFT_ELBOW',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_FOREARM = {
    path:
      'M183.1,112.6c-9.2-2.4-9.8,1.1-10.5,2.3c-0.6,2.7-1.2,5.4-1.4,7.4 c-0.5,3.7-1.8,8.9-2.5,11.7c-0.2,1.3,1.3,2,3.7,3c2.3,1,4.9,0.8,5.5,0c2.1-3.6,5.6-7.8,8.3-12.9c1-1.9,1.9-3.9,2.5-5.8 C189.8,115,185.3,113.2,183.1,112.6z',
    id: 'BACK_LEFT_FOREARM',
    human_body_part_type_id: 'BACK_LEFT_FOREARM',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_WRIST = {
    path:
      'M172.4,137c-2.4-1-3.9-1.8-3.7-3c-0.3,1-0.4,1.7-0.5,1.9c-0.1,0.7-0.9,2.1-1.3,3.8 c-0.1,0.4-0.6,0.7-1.2,0.9c0.8-0.2,9.3,3.8,9.4,4.3c-0.2-0.8-0.3-1.7,1.5-5.6c0.3-0.7,0.7-1.5,1.2-2.2 C177.3,137.9,174.7,138,172.4,137z',
    id: 'BACK_LEFT_WRIST',
    human_body_part_type_id: 'BACK_LEFT_WRIST',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_HAND = {
    path:
      'M165.8,140.7c-0.7,0.2-3,1.5-3.7,1.8s-1.2,1.4-2.1,1.9c-0.9,0.5-1.7,1.3-1.9,1.8 c-0.1,0.6-1.2,0.9-1.2,1.5s0.4,1.1,1.4,1.3c1,0.1,2.6-1.7,3-2c0.3-0.3,0.5,0.1,0.4,0.5c-0.1,0.4-1.2,3.1-1.4,4.1 c-0.2,1-0.8,3.6-0.9,4.4s-0.6,4.4-0.7,5c-0.1,0.6-0.7,3.1,0.2,3.8c0.9,0.7,1.6-0.5,2.1-1.6c0.4-1.1,0.3-2.5,0.6-3 c0.2-0.6,0.9-4,1.3-4.7s0.6,0.2,0.3,0.9s-0.8,3.4-0.8,3.8s-0.4,2.9-0.5,3.8s-0.3,2.9,0,3.8c0.3,0.9,1.5,0.3,1.9-0.1 s0.8-2.3,0.9-3.5s0.2-3,0.5-4.2c0.3-1.2,0.8-3.4,1.1-4s0.8,0.1,0.7,0.5c-0.1,0.5-0.5,2-0.8,3.4s-0.3,3-0.6,4.5 c-0.3,1.4-0.7,2.6,0,3.3c0.7,0.7,1.4,0.2,1.8-0.3c0.4-0.5,0.9-2.8,1.2-4.6c0.3-1.8,1-4.4,1.2-5.3c0.2-0.8,0.7-1.6,1.1-1.6 c0.4,0.1,0,1.3-0.2,1.9c-0.2,0.7-1,4.3-1.1,5.5c0,1.2,0.5,1.6,1.2,1.4c0.7-0.3,0.9-1.3,1-1.8c0.1-0.6,0.6-1.8,0.7-2.5 s0.9-3.4,1.3-4.3c0.4-0.9,0.6-2,1.4-5.7c0.8-3.8,0.3-4.7,0.1-5.5C175.1,144.5,166.6,140.5,165.8,140.7z',
    id: 'BACK_LEFT_HAND',
    human_body_part_type_id: 'BACK_LEFT_HAND',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_SHOULDER = {
    path:
      'M266.7,80.6c0-1.8,0.2-4.4,0-7.2c-0.3-5.8-2.1-10-5.8-13.1 c-2.4-2.1-9.5-3.6-11.2-4.1c-0.7-0.2-1.9-0.7-3.6-1.3c7.8,4.9,9.1,14.4,9.3,15.6C259.5,75.1,264.6,75.5,266.7,80.6z',
    id: 'BACK_RIGHT_SHOULDER',
    human_body_part_type_id: 'BACK_RIGHT_SHOULDER',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_ARM = {
    path:
      'M253.9,92c0.2,0,0.5,0.4,0.7,1c0.2,0.6,2.9,6.3,3.6,7.8c0.2,0.4,0.4,0.8,0.7,1.3 c1.9,3.3,4.5,4.4,8,3.2c3.2-1.2,6.3-2.5,5.5-9.7c-0.6-1.6-1.2-3.7-1.5-5.8c-0.4-2.1-2.8-7-4.2-9.2c-2.1-5.1-7.1-5.5-11.3-10.1 c0.9,2.9-0.3,8.4-0.7,10.6C254.3,83.2,254,84.1,253.9,92z',
    id: 'BACK_RIGHT_ARM',
    human_body_part_type_id: 'BACK_RIGHT_ARM',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_ELBOW = {
    path:
      'M258.9,102.1c0.6,1.2,1.2,2.5,1.3,3c0,0.7,0.3,2.3,0.5,2.8s0.2,1,0.3,2 c0,0.4,0.3,2,0.8,4.2c0.3,1.3,0.8,2.9,1.3,4.5c-1-3.4,3.4-5.2,5.6-5.8c9.2-2.4,9.8,1.1,10.5,2.3c-0.2-1-0.5-2-0.7-2.9 c-0.9-3.2-1.9-6.1-2.8-7.4c-1.9-2.8-1-2.5-2-4.2c-1-1.7-0.8-3.3-1.4-4.9c0.8,7.2-2.2,8.5-5.5,9.7 C263.4,106.5,260.8,105.4,258.9,102.1z',
    id: 'BACK_RIGHT_ELBOW',
    human_body_part_type_id: 'BACK_RIGHT_ELBOW',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_FOREARM = {
    path:
      'M263.1,118.5c0.7,1.9,1.5,3.9,2.5,5.8c2.7,5,6.2,9.3,8.3,12.9 c0.5,0.8,3.2,0.9,5.5,0c2.4-1,3.9-1.8,3.7-3c-0.7-2.8-2-8.1-2.5-11.7c-0.2-1.9-0.8-4.6-1.4-7.4c-0.7-1.2-1.4-4.7-10.5-2.3 C266.5,113.3,262.1,115.1,263.1,118.5z',
    id: 'BACK_RIGHT_FOREARM',
    human_body_part_type_id: 'BACK_RIGHT_FOREARM',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_WRIST = {
    path:
      'M274,137.2c0.5,0.8,0.9,1.5,1.2,2.2c1.7,4,1.7,4.8,1.5,5.6c0.1-0.4,8.7-4.4,9.4-4.3 c-0.6-0.2-1.1-0.6-1.2-0.9c-0.4-1.7-1.2-3.1-1.3-3.8c0-0.2-0.2-0.9-0.5-1.9c0.2,1.3-1.3,2-3.7,3C277.2,138.1,274.5,138,274,137.2z',
    id: 'BACK_RIGHT_WRIST',
    human_body_part_type_id: 'BACK_RIGHT_WRIST',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_HAND = {
    path:
      'M276.6,145.1c-0.2,0.8-0.7,1.7,0.1,5.5c0.8,3.8,1,4.8,1.4,5.7 c0.4,0.9,1.2,3.6,1.3,4.3s0.5,1.9,0.7,2.5c0.1,0.6,0.4,1.6,1,1.8c0.7,0.3,1.2-0.2,1.2-1.4s-0.9-4.8-1.1-5.5s-0.6-1.8-0.2-1.9 c0.4-0.1,0.9,0.7,1.1,1.6c0.2,0.8,0.9,3.5,1.2,5.3c0.3,1.8,0.8,4,1.2,4.6s1.1,1.1,1.8,0.3c0.7-0.7,0.3-1.9,0-3.3 c-0.3-1.4-0.3-3-0.6-4.5c-0.3-1.4-0.7-3-0.8-3.4s0.3-1.1,0.7-0.5c0.4,0.6,0.8,2.8,1.1,4c0.3,1.2,0.4,3.1,0.5,4.2 c0.2,1.1,0.6,3,0.9,3.5c0.4,0.5,1.6,1,1.9,0.1c0.3-0.9,0.1-3,0-3.8c-0.1-0.8-0.5-3.3-0.5-3.8s-0.6-3.2-0.8-3.8 c-0.2-0.6-0.1-1.6,0.3-0.9c0.4,0.7,1.1,4.1,1.3,4.7c0.2,0.6,0.1,1.9,0.6,3s1.1,2.3,2.1,1.6s0.3-3.2,0.2-3.8c-0.1-0.6-0.6-4.2-0.7-5 c-0.1-0.8-0.7-3.4-0.9-4.4c-0.2-1-1.3-3.8-1.4-4.1c-0.1-0.4,0.1-0.8,0.4-0.5c0.3,0.3,1.9,2.1,3,2c1-0.1,1.4-0.6,1.4-1.3 c0-0.6-1-0.9-1.2-1.5c-0.1-0.6-1-1.3-1.9-1.8c-0.9-0.5-1.4-1.6-2.1-1.9c-0.8-0.3-3.1-1.5-3.7-1.8 C285.3,140.6,276.7,144.6,276.6,145.1z',
    id: 'BACK_RIGHT_HAND',
    human_body_part_type_id: 'BACK_RIGHT_HAND',
    selected: false,
    attributes: { ...attributes },
  };
  const LEFT_HAMSTRING = {
    path:
      'M204.1,157.9c-1.7-2.5-3.2-3.8-3.2-7.8c-0.3,1.8-1.7,9-2,11.3s-0.6,3-1.5,9.8 s-0.3,15.9,0.6,19.6s2.7,7.6,3.2,10.3c3,4.9,6.7,5.8,10.1,6c3.7,0.2,7-0.6,8.5-3.2c0.3-1.2,1.3-9.3,1.7-12.1s2-12.8,2.2-16.5 s0.2-7.3,0-8.6c-0.2-1.3-0.3-3.4-0.2-4.5C216.3,169.2,208.4,164.4,204.1,157.9z',
    id: 'LEFT_HAMSTRING',
    human_body_part_type_id: 'LEFT_HAMSTRING',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_KNEE = {
    path:
      'M211.2,207c-3.4-0.2-7.1-1.1-10.1-6c0.5,2.7,0.7,6.7,0.9,11.2c0.1,2.9,0,6.2-0.2,8.9 c0.5-4.4,4-4,7.5-3.7c3.5,0.3,7,0.7,7.5,2.1c-0.2-1.2,0.1-2.3,0.6-3.3c0.5-1,0.7-3.7,0.8-6s1.2-5.1,1.6-6.3 C218.2,206.4,214.9,207.2,211.2,207z',
    id: 'BACK_LEFT_KNEE',
    human_body_part_type_id: 'BACK_LEFT_KNEE',
    selected: false,
    attributes: { ...attributes },
  };
  const LEFT_CALF = {
    path:
      'm 209.2,217.44029 c -3.5,-0.37177 -6.9,-0.86747 -7.5,4.5852 -0.1,1.73495 -0.3,3.22205 -0.4,4.46128 -0.4,3.34598 -1,11.89676 -0.5,15.49056 0.5,3.59381 1.8,18.21689 1.9,23.79348 0.5,2.9742 3.9,4.85569 6.6,4.9796 2.8,0.12393 4.9,-1.5097 5.4,-5.10352 0.3,-3.09811 0.6,-6.44407 0.6,-8.179 0.1,-2.97419 1.7,-6.19622 2.7,-9.66611 0.9,-3.46989 0.1,-12.14459 -0.2,-16.97765 -0.3,-4.83305 -0.9,-9.41825 -1.1,-10.78142 -0.5,-1.73494 -4,-2.10672 -7.5,-2.60242 z',
    id: 'LEFT_CALF',
    human_body_part_type_id: 'LEFT_CALF',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_ANKLE = {
    path:
      'm 209.3,270.75041 c -2.7,-0.0573 -6.1,-3.60357 -6.6,-4.9796 0.1,2.52271 0.9,9.27967 0.9,10.08235 0,0.80268 0.56972,2.28303 0.66972,2.51236 0.1,0.22934 0.3,0 0,0 0.0462,2.69012 -0.16972,4.4251 -0.26972,5.51445 -0.1,0.63068 0,1.14669 0.3,1.72003 2.2,-1.60536 10,-2.40804 10.1,-0.91735 0,-0.74535 -0.3,-1.60536 -0.4,-2.46538 -0.3,-1.54802 -0.58568,-3.03612 -0.48568,-3.4948 0.1,-0.45867 -0.0303,-1.36632 -0.33028,-2.169 -0.3,-0.80268 0.61596,-3.39502 1.01596,-4.88571 0.2,-0.63068 -0.12183,-3.2282 0.17813,-4.37489 -0.7,1.60536 -2.27817,3.51487 -5.07817,3.45754 z',
    id: 'BACK_LEFT_ANKLE',
    human_body_part_type_id: 'BACK_LEFT_ANKLE',
    selected: false,
    attributes: { ...attributes },
  };
  const LEFT_SOLE = {
    path: 'M204.3,285.5c0.5,0.5,2.6,1.9,4.8,2.4c2.2,0.5,4-0.3,4.8-1.5c0.4-0.5,0.5-1.4,0.4-2.5 C214.3,281.2,206.5,282.6,204.3,285.5z',
    id: 'LEFT_SOLE',
    human_body_part_type_id: 'LEFT_SOLE',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_LEFT_FOOT = {
    path:
      'M204,282.5c0.1-1.9,0.5-4.2-0.8-7.2c-0.2,0-0.6,0.3-0.7,0.5s-0.5,0.1-0.8,0 c-0.3-0.1-1.4,0.9-1.4,1.1c0,0.1-0.1,0.3-0.5,0.2c-0.4,0-1.2,0.7-1.2,0.9s-0.3,0.4-0.6,0.4c-0.3,0-0.9,0.6-1.1,1.1s0.2,1,0.3,1.9 c0.2,0.9,1.4,2,3.9,2.5c2.5,0.6,2.5,1,3,1.4C204,284.5,203.9,283.6,204,282.5z',
    id: 'BACK_LEFT_FOOT',
    human_body_part_type_id: 'BACK_LEFT_FOOT',
    selected: false,
    attributes: { ...attributes },
  };
  const RIGHT_HAMSTRING = {
    path:
      'M228.4,162c0.1,1.1,0.1,4.1-0.1,5.3s-0.2,4.9,0,8.6c0.2,3.6,1.7,13.6,2.2,16.5 s1.4,10.9,1.7,12.1c1.5,2.6,4.8,3.4,8.5,3.2c3.4-0.2,7.1-1.1,10.1-6c0.5-2.7,2.4-6.6,3.2-10.3c0.8-3.7,1.5-12.8,0.6-19.6 c-0.9-6.7-1.2-7.5-1.5-9.8c-0.3-2.3-1.7-10.2-2-11.9c0,5.4-1.6,5.9-3.3,8.4C243.8,165.2,235.7,170.1,228.4,162z',
    id: 'RIGHT_HAMSTRING',
    human_body_part_type_id: 'RIGHT_HAMSTRING',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_KNEE = {
    path:
      'M232.2,204.5c0.3,1.2,1.4,4,1.6,6.3c0.2,2.3,0.3,5,0.8,6s0.9,2.2,0.6,3.3 c0.5-1.4,4-1.7,7.5-2.1c3.5-0.3,6.9-0.7,7.5,3.7c-0.2-2.7-0.4-6-0.2-8.9c0.2-4.4,0.4-8.5,0.9-11.2c-3,4.9-6.7,5.8-10.1,6 C237,207.9,233.7,207.1,232.2,204.5z',
    id: 'BACK_RIGHT_KNEE',
    human_body_part_type_id: 'BACK_RIGHT_KNEE',
    selected: false,
    attributes: { ...attributes },
  };
  const RIGHT_CALF = {
    path:
      'm 235.2,220.58203 c -0.2,1.45499 -0.8,5.81996 -1.1,10.54869 -0.3,4.72872 -1.2,13.21617 -0.2,16.61115 0.9,3.39498 2.6,6.54746 2.7,9.45745 0,1.57624 0.3,4.97122 0.6,8.00245 0.5,3.51623 2.6,4.24373 5.4,4.12248 2.7,-0.12125 6.1,-1.09124 6.6,-4.00123 0.1,-5.33497 1.4,-19.76364 1.9,-23.27987 0.5,-3.51623 -0.2,-12.00368 -0.5,-15.15616 -0.1,-1.09124 -0.3,-2.66748 -0.4,-4.36498 -0.5,-5.33497 -4,-4.84997 -7.5,-4.48622 -3.5,0.485 -7,0.96999 -7.5,2.54624 z',
    id: 'RIGHT_CALF',
    human_body_part_type_id: 'RIGHT_CALF',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_ANKLE = {
    path:
      'm 237.2,265.51781 c 0.2,1.23675 0.5,4.14881 0.7,4.86483 0.4,1.6273 1.2,3.31969 0.9,4.29607 -0.3,0.97638 0.29498,1.68284 -0.16904,3.70762 -0.11844,0.51685 -0.23096,2.21576 -0.53096,3.97324 -0.2,0.97638 -0.4,1.95276 -0.4,2.79896 0.1,-1.6924 7.7,-0.9462 9.9,0.94147 0.2,-0.65092 0.6,-1.13675 0.5,-1.85276 -0.0712,-0.88094 -0.49498,-1.86805 -0.58463,-3.21249 -0.0327,-0.49064 -0.0525,-1.27759 0.19258,-1.93605 -0.2,0 0.15437,-1.08719 0.15437,-1.34753 0,-0.26037 0.63766,-1.70553 0.63766,-2.61681 0,-0.91129 0.6,-6.8822 0.7,-9.81134 -0.5,1.56221 -3.7,3.8564 -6.4,3.92149 -3,0.13019 -5.1,-1.83903 -5.6,-3.7267 z',
    id: 'BACK_RIGHT_ANKLE',
    human_body_part_type_id: 'BACK_RIGHT_ANKLE',
    selected: false,
    attributes: { ...attributes },
  };
  const RIGHT_SOLE = {
    path: 'M237.6,284.5c0,1.1,0.1,2,0.4,2.5c0.8,1.2,2.6,2,4.8,1.5s4.4-2,4.8-2.4 C245.4,283.3,237.6,281.9,237.6,284.5z',
    id: 'RIGHT_SOLE',
    human_body_part_type_id: 'RIGHT_SOLE',
    selected: false,
    attributes: { ...attributes },
  };
  const BACK_RIGHT_FOOT = {
    path:
      'M247.6,286.1c0.5-0.5,0.5-0.8,3-1.4c2.5-0.6,3.8-1.6,3.9-2.5c0.2-0.9,0.6-1.4,0.3-1.9 c-0.2-0.5-0.7-1.1-1.1-1.1c-0.3,0-0.6-0.2-0.6-0.4s-0.8-0.9-1.2-0.9c-0.4,0-0.4-0.1-0.5-0.2c0-0.1-1.1-1.2-1.4-1.1 c-0.3,0.1-0.8,0.2-0.8,0c-0.1-0.3-0.4-0.5-0.7-0.5c-1.3,3-0.9,5.3-0.8,7.2C248,284.2,247.9,285.1,247.6,286.1z',
    id: 'BACK_RIGHT_FOOT',
    human_body_part_type_id: 'BACK_RIGHT_FOOT',
    selected: false,
    attributes: { ...attributes },
  };

  return {
    FRONT_HEAD,
    RIGHT_EYE,
    LEFT_EYE,
    RIGHT_EAR,
    LEFT_EAR,
    NOSE,
    MOUTH,
    FRONT_NECK,
    CHEST,
    ABDOMEN,
    PELVIS,
    PUBIS,
    FRONT_LEFT_SHOULDER,
    FRONT_LEFT_ARM,
    FRONT_LEFT_ELBOW,
    FRONT_LEFT_FOREARM,
    FRONT_LEFT_WRIST,
    FRONT_LEFT_HAND,
    FRONT_LEFT_FINGERS,
    FRONT_RIGHT_SHOULDER,
    FRONT_RIGHT_ARM,
    FRONT_RIGHT_ELBOW,
    FRONT_RIGHT_FOREARM,
    FRONT_RIGHT_WRIST,
    FRONT_RIGHT_HAND,
    FRONT_RIGHT_FINGERS,
    FRONT_LEFT_THIGH,
    FRONT_LEFT_KNEE,
    FRONT_LEFT_FIBULA,
    FRONT_LEFT_ANKLE,
    FRONT_LEFT_FOOT,
    FRONT_LEFT_TOES,
    FRONT_RIGHT_THIGH,
    FRONT_RIGHT_KNEE,
    FRONT_RIGHT_FIBULA,
    FRONT_RIGHT_ANKLE,
    FRONT_RIGHT_FOOT,
    FRONT_RIGHT_TOES,
    BACK_HEAD,
    BACK_NECK,
    BACK,
    LOIN,
    BUTTOCK,
    BACK_LEFT_SHOULDER,
    BACK_LEFT_ARM,
    BACK_LEFT_ELBOW,
    BACK_LEFT_FOREARM,
    BACK_LEFT_WRIST,
    BACK_LEFT_HAND,
    BACK_RIGHT_SHOULDER,
    BACK_RIGHT_ARM,
    BACK_RIGHT_ELBOW,
    BACK_RIGHT_FOREARM,
    BACK_RIGHT_WRIST,
    BACK_RIGHT_HAND,
    LEFT_HAMSTRING,
    BACK_LEFT_KNEE,
    LEFT_CALF,
    BACK_LEFT_ANKLE,
    LEFT_SOLE,
    BACK_LEFT_FOOT,
    RIGHT_HAMSTRING,
    BACK_RIGHT_KNEE,
    RIGHT_CALF,
    BACK_RIGHT_ANKLE,
    RIGHT_SOLE,
    BACK_RIGHT_FOOT,
  };
};

interface Props {
  selectedParts: string[];
  onSelect(parts: string): void;
}

export default (props: Props) => {
  const [parts, setParts] = useState(figures());
  useEffect(() => {
    if (props.selectedParts && props.selectedParts.length >= 0) {
      props.selectedParts.forEach(part => select(part, true));

      setTimeout(() => {
        Object.keys(parts)
          .filter(part => parts[part].selected && !props.selectedParts.includes(part))
          .forEach(part => select(part, false));
        setParts(parts);
      }, 250);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedParts]);

  function handleHoverIn(part: string) {
    if (parts[part].selected) return;

    parts[part].attributes.fill = selectedColor;
    setParts({ ...parts });
  }

  function handleHoverOut(part: string) {
    if (parts[part].selected) return;

    parts[part].attributes.fill = defaultColor;
    setParts({ ...parts });
  }

  // tslint:disable-next-line:no-unnecessary-initializer
  function select(part: string, selection = undefined) {
    selection = selection || !parts[part].selected;
    parts[part].attributes.fill = selection ? selectedColor : defaultColor;
    parts[part].selected = selection;
    setParts({ ...parts });
  }

  return (
    <Paper width={600} height={500} viewbox="0 0 300 300">
      {Object.keys(parts).map(part => (
        <Path
          id={part}
          key={part}
          d={parts[part].path}
          attr={parts[part].attributes}
          click={() => {
            select(part);
            props.onSelect(part);
          }}
          hover={{ in: () => handleHoverIn(part), out: () => handleHoverOut(part) }}
        />
      ))}
    </Paper>
  );
};
