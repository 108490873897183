import { Grid, Icon, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { PolyclinicExamOpinionContext } from '../../polyclinic-exam-opinions.context';

export default function ListOpinions() {
  const { loading, polyclinicExamOpinions } = useContext(PolyclinicExamOpinionContext);

  const getTextDisplay = (polyclinicExamOpinionDefinition: string) => {
    switch (polyclinicExamOpinionDefinition) {
      case 'REFERRED':
        return 'Yapılan test sonucunda sevk edildi.';
      case 'WORKING':
        return 'Yapılan test sonucunda iş başı yaptı.';
      case 'REST_REPORTED':
        return 'Yapılan test sonucunda rapor verildi.';
      default:
        return '';
    }
  };

  return (
    <>
      <IndicatorWrapper loading={loading} data={polyclinicExamOpinions} errorMessage="Gösterilecek Kanaat Bulunamadı.">
        <Grid container>
          <Grid item sm={12} md={12} lg={12}>
            {!!polyclinicExamOpinions && !loading && (
              <>
                <List
                  style={{ paddingLeft: '20px' }}
                  key="hs-opinion"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      <Icon className="icon-check" /> Sonuç / Karar
                    </ListSubheader>
                  }>
                  <ListItem key={polyclinicExamOpinions?.id}>
                    <ListItemIcon>
                      <Icon className="icon-dna" />
                    </ListItemIcon>
                    <ListItemText
                      primary={getTextDisplay(polyclinicExamOpinions?.polyclinicExamOpinionDefinition)}
                      secondary={
                        <Typography className="text-italic">
                          <Icon style={{ color: 'gray' }} className="icon-document" /> {polyclinicExamOpinions?.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </>
            )}
          </Grid>
        </Grid>
      </IndicatorWrapper>
    </>
  );
}
