import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Button, Card, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useContext, useRef } from 'react';

import { ResourceContext } from '../../context/resource.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import DefaultPieChart from './DefaultPieChart';
import { DomainType, queryOptionsMap } from './index';
import QueryDomains from './QueryDomains';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  textColor: {
    color: '#737373',
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '15px',
  },
  textColor2: {
    fontFamily: 'Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
    fontSize: '16px',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginLeft: '100px',
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: 'white',
  },
  tr: { textAlign: 'left' },
  tdRow: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '2px solid black',
  },
  tdCell: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #DDD',
  },
}));

const convertChartDataWithFraction = (convertData: any[], fraction: string) => {
  const resultMap: any = {};
  convertData.forEach(item => {
    const name = item[fraction];
    if (!resultMap[name]) {
      resultMap[name] = {
        name,
        count: 1,
      };
    } else {
      resultMap[name].count++;
    }
  });
  return Object.values(resultMap);
};

const convertDataToActionTableData = (data: any[]) => {
  const resultMap = {};

  data.forEach(item => {
    let name = item.referenceName;
    const actionStatusName = item.actionStatusName;

    if (!name) {
      name = 'Aksiyon';
    }

    if (!resultMap[name]) {
      resultMap[name] = {
        name,
        value: 1,
        Yapılacak: 0,
        Yapılamaz: 0,
        Yapılıyor: 0,
        Yapıldı: 0,
      };
    } else {
      resultMap[name].value++;
    }
    resultMap[name][actionStatusName]++;
  });
  return Object.values(resultMap);
};

const convertDataToChartData = (convertData: any[], fractionId: string, map: Map<string | number, string>): any[] => {
  const resultMap: any = {};
  convertData.forEach(item => {
    const fId = item[fractionId];
    if (!map.get(fId)) {
      return;
    }
    if (!resultMap[fId]) {
      resultMap[fId] = {
        name: map.get(fId),
        count: 1,
      };
    } else {
      resultMap[fId].count++;
    }
  });
  return Object.values(resultMap);
};

const convertNearMissDataToChartData = (data: any[]) => {
  const mappedNearMissTypes = data
    ?.map(item =>
      item.nearMissTypeViews.map(element => {
        return { label: element.typeDefinitionLabel };
      })
    )
    .flat(1);
  const resultMap = {};
  mappedNearMissTypes?.forEach(item => {
    const name = item.label;
    if (!resultMap[name]) {
      resultMap[name] = {
        name,
        count: 1,
      };
    } else {
      const result = resultMap[name];
      result[name] = result[name] + 1;
      result.count++;
    }
  });

  return Object.values(resultMap);
};

const convertAccidentDataToChartData = (data: any[]) => {
  const accidentIds: any[] = [];
  const accidentLabels = data
    .map(item =>
      item.accidentTypeViews.map(element => {
        accidentIds.push(item.accidentId);
        return {
          // id: element.accidentTypeDefinitionId,
          // accidentId: item.accidentId,
          employeeCount: item.accidentEmployeeViews ? item.accidentEmployeeViews.length : 0,
          name: element.typeDefinitionLabel,
        };
      })
    )
    .flat(1);
  const typeMap = new Map<string, any>();
  accidentLabels?.forEach(item => {
    if (typeMap.has(item.name)) {
      const value = typeMap.get(item.name);
      value.employeeCount = value.employeeCount + item.employeeCount;
      value.count++;
    } else {
      typeMap.set(item.name, {
        name: item.name,
        count: 1,
        employeeCount: item.employeeCount,
      });
    }
  });
  return Array.from(typeMap.values());
};

const convertTrainingDataToChartData = (convertDataChart: any[]): any[] => {
  const resultMap: any[] = [];
  convertDataChart?.forEach(item => {
    resultMap.push([item.title, item.trainingEmployees.length, item.trainingDefinitionTrainings.length, item.durationInMinutes]);
  });
  return resultMap;
};

const ActivityReports = ({ data, dataDisplayOption, getSelectedOptions }) => {
  const { exposures, restReportDefinitions } = useContext(ResourceContext);

  const { prescriptionTypeDefinitions, analysisCategoryDefinitions, nurseOperationDefinition, referralSection } = useContext(
    SelectOptionsContext
  );
  queryOptionsMap.get(QueryDomains.EXAMINATION).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.VACCINE).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.NURSE_OPERATION).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.ANALYSIS).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.NEAR_MISS).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.EXPOSURE).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.PRESCRIPTION).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.REPORT).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.REFERRAL).chartRef = useRef(null);
  queryOptionsMap.get(QueryDomains.ACCIDENT).chartRef = useRef(null);

  const selectedOptions = getSelectedOptions();
  queryOptionsMap.get(QueryDomains.ACTION).data = selectedOptions.has(QueryDomains.ACTION)
    ? convertDataToActionTableData(queryOptionsMap.get(QueryDomains.ACTION).rawData)
    : null;
  queryOptionsMap.get(QueryDomains.EXAMINATION).data = selectedOptions.has(QueryDomains.EXAMINATION)
    ? convertChartDataWithFraction(queryOptionsMap.get(QueryDomains.EXAMINATION).rawData, 'examinationTypeName')
    : null;
  queryOptionsMap.get(QueryDomains.VACCINE).data = selectedOptions.has(QueryDomains.VACCINE)
    ? convertChartDataWithFraction(queryOptionsMap.get(QueryDomains.VACCINE).rawData, 'vaccineName')
    : null;
  queryOptionsMap.get(QueryDomains.REPORT).data = selectedOptions.has(QueryDomains.REPORT)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.REPORT).rawData,
        'restReportReasonDefinitionId',
        new Map(restReportDefinitions.map(a => [a.id, a.reasonName]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.REFERRAL).data = selectedOptions.has(QueryDomains.REFERRAL)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.REFERRAL).rawData,
        'referralSectionDefinitionId',
        new Map(referralSection.map(a => [a.value, a.text as string]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.EXPOSURE).data = selectedOptions.has(QueryDomains.EXPOSURE)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.EXPOSURE).rawData,
        'exposureDefinitionId',
        new Map(exposures.map(a => [a.id, a.exposureName]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.PRESCRIPTION).data = selectedOptions.has(QueryDomains.PRESCRIPTION)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.PRESCRIPTION).rawData,
        'prescriptionTypeDefinitionId',
        new Map(prescriptionTypeDefinitions.map(a => [a.value, a.text as string]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.ANALYSIS).data = selectedOptions.has(QueryDomains.ANALYSIS)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.ANALYSIS).rawData,
        'categoryDefinitionId',
        new Map(analysisCategoryDefinitions.map(a => [a.value.toString(), a.text as string]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.NEAR_MISS).data = selectedOptions.has(QueryDomains.NEAR_MISS)
    ? convertNearMissDataToChartData(queryOptionsMap.get(QueryDomains.NEAR_MISS).rawData)
    : null;
  queryOptionsMap.get(QueryDomains.NURSE_OPERATION).data = selectedOptions.has(QueryDomains.NURSE_OPERATION)
    ? convertDataToChartData(
        queryOptionsMap.get(QueryDomains.NURSE_OPERATION).rawData,
        'nurseOperationDefinitionId',
        new Map(nurseOperationDefinition.map(a => [a.value, a.text as string]))
      )
    : null;
  queryOptionsMap.get(QueryDomains.TRAINING).data = selectedOptions.has(QueryDomains.TRAINING)
    ? convertTrainingDataToChartData(queryOptionsMap.get(QueryDomains.TRAINING).rawData)
    : null;
  queryOptionsMap.get(QueryDomains.COMMITTEE_MEETINGS).data = selectedOptions.has(QueryDomains.COMMITTEE_MEETINGS)
    ? convertChartDataWithFraction(queryOptionsMap.get(QueryDomains.COMMITTEE_MEETINGS).rawData, 'meetingStatusDefinitionLabel')
    : null;
  queryOptionsMap.get(QueryDomains.ACCIDENT).data = selectedOptions.has(QueryDomains.ACCIDENT)
    ? convertAccidentDataToChartData(queryOptionsMap.get(QueryDomains.ACCIDENT).rawData)
    : null;

  for (const [key, value] of queryOptionsMap) {
    const arr = [];
    arr.push(
      value.tableFieldTitles.map(f => {
        return { text: f, bold: true };
      })
    );
    value.data?.forEach(val => {
      const arrRow = Object.values(val);
      arrRow[arrRow.length - 1] = { text: arrRow[arrRow.length - 1], alignment: 'right' };
      arr.push(arrRow);
    });

    value.formattedData = arr;
  }
  const classes = useStyles();
  function savePDF() {
    let pdfMake;
    const promises = [];
    for (const [key, value] of queryOptionsMap) {
      if (selectedOptions.has(key) && value.chartRef) {
        const options = value.chartRef.current.exporting.getFormatOptions('svg');
        options.scale = 2;
        value.chartRef.current.exporting.setFormatOptions('svg', options);
        promises.push(value.chartRef.current.exporting.getImage('svg'));
        if (!pdfMake) {
          pdfMake = value.chartRef.current.exporting.pdfmake;
        }
      }
    }
    promises.unshift(pdfMake);
    Promise.all(promises).then(res => {
      // tslint:disable-next-line:no-shadowed-variable
      const pdfMake = res[0];
      const doc = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [30, 30, 30, 30],
        content: [],
      };
      doc.content.push({
        alignment: 'justify',
        columns: [
          {
            text: 'FAALİYET RAPORU',
            bold: true,
          },
          {
            text: '',
          },
          {
            text: 'Tarih :' + dataDisplayOption.minDate + '-' + dataDisplayOption.maxDate,
            style: 'subheader',
          },
        ],
        style: 'header',

        columnGap: 30,
      });
      doc.content.push({
        text: 'Şirket Bilgileri',
        fontSize: 15,
        bold: true,
        margin: [0, 20, 0, 15],
      });
      doc.content.push({
        text: `Şirket adı: ${data.companyDetails.companyName}
Tehlike Sınıfı: ${data.companyDetails.hazardClass}
Unvan: ${data.companyDetails.companyTitle}
SGK Sicil No: ${data.companyDetails.ssiId}
Adres: ${data.companyDetails.address}
Tel: ${data.companyDetails.phone}
Fax: ${data.companyDetails.fax ? data.companyDetails.fax : ' -'}`,
        fontSize: 10,
        bold: true,
        margin: [0, 20, 0, 15],
      });

      let idx = 1;
      for (const [key, value] of queryOptionsMap) {
        if (selectedOptions.has(key)) {
          doc.content.push({
            text: value.label,
            fontSize: 15,
            bold: true,
            margin: [5, 30, 5, 5],
          });
          doc.content.push({
            margin: [5, 5, 5, 5],
            table: {
              headerRows: 1,
              widths: [...new Array(value.tableFieldTitles.length - 1).fill('*'), 'auto'],
              body: value.formattedData,
            },
            layout: 'lightHorizontalLines',
          });
          if (value.chartRef) {
            doc.content.push({
              image: res[idx],
              width: 600,
              margin: [5, 5, 5, 5],
            });
            idx++;
          }
        }
      }
      pdfMake.createPdf(doc).download('FaaliyetRaporu.pdf');
    });
  }

  am4core.useTheme(am4themes_animated);

  function percentage(partialValue: number, totalValue: number) {
    return '% ' + ((100 * Number(partialValue)) / totalValue).toPrecision(2);
  }

  function Charts(props: { domainType: DomainType; queryDomain: QueryDomains }) {
    if (selectedOptions.has(props.queryDomain) && props.domainType.data && props.domainType.data.length > 0) {
      return (
        <>
          <Grid container>
            <Grid item xs={6}>
              <h3>
                <i>{props.domainType.label}</i>
              </h3>
            </Grid>
            <Grid item xs={6}>
              <h3 style={{ textAlign: 'right' }}>Toplam: {props.domainType.data.length}</h3>
            </Grid>
          </Grid>
          <table className={classes.table}>
            <tbody>
              <tr className={classes.tr}>
                {props.domainType.tableFieldTitles.map(fieldName => {
                  return (
                    <>
                      <td className={classes.tdRow}>
                        <b>{fieldName}</b>
                      </td>
                    </>
                  );
                })}
              </tr>
              {props.domainType.data
                ?.map(item => Object.values(item))
                .map((item, index) => (
                  <tr key={index} className={classes.tr}>
                    {/* tslint:disable-next-line:no-shadowed-variable */}
                    {item.map((val, index) => {
                      return (
                        <td key={index} className={classes.tdCell}>
                          {val}
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
          {props.domainType.chartRef && (
            <DefaultPieChart name={props.domainType.name + 'chart'} data={props.domainType.data} chartRef={props.domainType.chartRef} />
          )}
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Paper>
          <div>
            <Button variant="contained" color="primary" className="px-4" onClick={savePDF}>
              Faaliyet Raporu İndir
            </Button>
            <br />
            {/*<Card>*/}
            <div />
            <h2>
              <i>Şirket Bilgileri</i>
            </h2>
            <p>
              Şirket Adı: <b>{data.companyDetails.companyName}</b>
            </p>
            <p>
              Tehlike Sınıfı: <b>{data.companyDetails.hazardClass}</b>
            </p>
            <p>
              Unvan: <b>{data.companyDetails.companyTitle}</b>
            </p>
            <p>
              SGK Sicil No: <b>{data.companyDetails.ssiId}</b>
            </p>
            <p>
              Adres: <b>{data.companyDetails.address}</b>
            </p>
            <p>
              Tel: <b>{data.companyDetails.phone}</b>
            </p>
            {/*</Card>*/}
            <Card>
              <table className={classes.table}>
                <tbody>
                  <tr className={classes.tr}>
                    <td className={classes.tdRow}>
                      <b>Personel</b>
                    </td>
                    <td className={classes.tdRow}>
                      <b>ADET</b>
                    </td>
                  </tr>
                  <tr key={'woman'} className={classes.tr}>
                    <td className={classes.tdCell}>Kadın</td>
                    <td className={classes.tdCell}>{data.femaleEmployeeList.totalElements}</td>
                  </tr>
                  <tr key={'man'} style={{ textAlign: 'left' }}>
                    <td className={classes.tdCell}>Erkek</td>
                    <td className={classes.tdCell}>{data.maleEmployeeList.totalElements}</td>
                  </tr>
                  {/*<tr key={'allergy'} className={classes.tr}>*/}
                  {/*  <td className={classes.tdCell}>Alerjisi Olan</td>*/}
                  {/*  <td className={classes.tdCell}>{data.employeeHasAllergyList.totalElements}</td>*/}
                  {/*</tr>*/}
                  {/*<tr key={'disability'} className={classes.tr}>*/}
                  {/*  <td className={classes.tdCell}>Engelli Olan</td>*/}
                  {/*  <td className={classes.tdCell}>{data.disabledEmployeeList.totalElements}</td>*/}
                  {/*</tr>*/}
                  {/*<tr key={'chronicDiseases'} className={classes.tr}>*/}
                  {/*  <td className={classes.tdCell}>Kronik Hastalığı Olan</td>*/}
                  {/*  <td className={classes.tdCell}>{data.employeeHasChronicDiseasesList.totalElements}</td>*/}
                  {/*</tr>*/}
                </tbody>
              </table>
            </Card>
            {Array.from(queryOptionsMap).map(([key, value]) => {
              if (selectedOptions.has(key)) {
                return <Charts key={key} queryDomain={key} domainType={value} />;
              } else {
                return <></>;
              }
            })}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ActivityReports;
