import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';

import { saveCompany, updateCompany } from 'api/hs/definitions/workplace-definition/company/CompanyApi';
import Select from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import { SelectOptionsContext } from 'context/select-options.context';
import { PHONE_MASK, SSI_NUMBER_MASK } from 'model';
import { Company } from 'model/definitions/workplace-definition/company.model';
import { email } from 'validators';

import BaseDefinitionForm from '../../../../BaseDefinitionForm';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: Company;
}

export default function CompanyEdit({ open, onClose, data }: Props) {
  const { hazarClass } = useContext(SelectOptionsContext);

  return (
    <BaseDefinitionForm<Company>
      open={open}
      onClose={onClose}
      onSave={saveCompany}
      onUpdate={updateCompany}
      data={data}
      resourceReloadName="companies"
      createTitle="Yeni Şirket Ekle"
      updateTitle="Şirket Düzenleme">
      <Grid item lg={4} md={4}>
        <TextField required name="companyCode" label="Şirket Kodu" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField required name="companyName" label="Şirket Adı" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField name="companyTitle" label="Ünvan" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField name="taxOffice" label="Vergi Dairesi" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField name="phone" label="Telefon" mask={PHONE_MASK} maskError="Hatalı telefon numarası" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField type="number" name="fax" label="Fax" />
      </Grid>
      <Grid item lg={4} md={4}>
        <TextField name="email" label="E-mail" rules={{ validate: { email } }} />
      </Grid>
      <Grid item lg={8} md={8}>
        <TextField required name="ssiId" label="SGK Sicil Numarası" mask={SSI_NUMBER_MASK} maskError="Hatalı SGK Sicil Numarası" />
      </Grid>
      <Grid item lg={12} md={12}>
        <Select name="hazardClass" options={hazarClass} label="Tehlike Sınıfı" />
      </Grid>
      <Grid item lg={12} md={12}>
        <TextField name="address" label="Adres" rows={4} />
      </Grid>
    </BaseDefinitionForm>
  );
}
