/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildingFloorDefinitionDto } from '../models/BuildingFloorDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BuildingFloorDefinitionControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns BuildingFloorDefinitionDto OK
     * @throws ApiError
     */
    public static updateBuildingFloorDefinition(
        id: string,
        requestBody: BuildingFloorDefinitionDto,
    ): CancelablePromise<BuildingFloorDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/buildings/floors/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBuildingFloorDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/buildings/floors/${id}`,
        });
    }

    /**
     * @param requestBody
     * @returns BuildingFloorDefinitionDto OK
     * @throws ApiError
     */
    public static saveBuildingFloorDefinition(
        requestBody: BuildingFloorDefinitionDto,
    ): CancelablePromise<BuildingFloorDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/buildings/floors`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns BuildingFloorDefinitionDto OK
     * @throws ApiError
     */
    public static getAllBuildingFloorDefinitions(
        id: string,
    ): CancelablePromise<Array<BuildingFloorDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/buildings/${id}/floors`,
        });
    }

}