import { noop } from '@thalesrc/js-utils';
import React, { PropsWithChildren } from 'react';

import RawTable from '../Raw/Table';
import TableContextProvider, { TableContextProviderProps } from '../Raw/TableContextProvider';

export default function Table<T extends {} = {}>({
  columns,
  children,
  data,
  onSortChange = noop,
  onRowClick = noop,
  RowProps = {},
}: PropsWithChildren<TableContextProviderProps<T>>) {
  return (
    <TableContextProvider {...{ columns, data, onSortChange, onRowClick, RowProps }}>
      <RawTable>{children}</RawTable>
    </TableContextProvider>
  );
}
