import React from 'react';

import WorkEquipmentTypeDefinitionAdd from './Add';
import WorkEquipmentTypeDefinitionList from './List';

export default function Index() {
  return (
    <>
      <WorkEquipmentTypeDefinitionAdd />
      <WorkEquipmentTypeDefinitionList />
    </>
  );
}
