import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'Common/Button';
import { SelectOption } from 'Common/Form/Select';
import TextField from 'Common/Form/TextField';
import ToggleButtonGroup from 'Common/Form/ToggleButtonGroup';

import { CheckListContext, UpsertData } from './checklist.context';

interface OptionExtra {
  color: string;
}

const DEFAULT_COMPLAINT_ID = 2;

const OPTIONS: (OptionExtra & SelectOption<number>)[] = [
  { text: 'Evet', value: 1, color: '#eb4355' },
  { text: 'Hayır', value: 0, color: '#469789' },
  { text: 'Belirsiz', value: DEFAULT_COMPLAINT_ID, color: '#c0c0c0' },
];

export default function Dialog() {
  const { editOpened, setEditOpened, editButtonText, list, description, upsert } = useContext(CheckListContext);
  const { setValue, trigger, ...methods } = useForm();

  const cancel = useCallback(() => setEditOpened(false), [setEditOpened]);

  const save = useCallback(
    async (data: UpsertData) => {
      await upsert(data);
      setEditOpened(false);
    },
    [upsert, setEditOpened]
  );

  useEffect(() => {
    for (const item of list) {
      setValue(item.id + '', item.value === undefined ? DEFAULT_COMPLAINT_ID : item.value);
    }

    setValue('description', description);
    trigger();
  }, [list, description, setValue, trigger]);

  return (
    <MUIDialog open={editOpened} fullWidth maxWidth="sm" keepMounted onClose={cancel}>
      <DialogTitle>{editButtonText}</DialogTitle>
      <DialogContent>
        <FormProvider {...{ ...methods, setValue, trigger }}>
          <form id="edit-complaint-form" onSubmit={methods.handleSubmit(save)}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {list.map(item => (
                    <TableRow key={item.id}>
                      <TableCell align="left" component="th" scope="row">
                        {item.label}
                      </TableCell>
                      <TableCell align="right">
                        <ToggleButtonGroup<number, OptionExtra>
                          name={item.id + ''}
                          options={OPTIONS}
                          defaultValue={DEFAULT_COMPLAINT_ID}
                          renderOption={({ color, selected, text, value }) => (
                            <ToggleButton
                              value={value}
                              className={selected ? 'text-white' : ''}
                              style={!selected ? null : { backgroundColor: color, fontWeight: 'bold' }}>
                              {text}
                            </ToggleButton>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-2 px-2">
              <TextField name="description" rows={3} label="Açıklama" />
            </div>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="text-right p-4">
        <Button variant="contained" onClick={cancel}>
          Vazgeç
        </Button>
        <Button variant="contained" form="edit-complaint-form" type="submit" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
