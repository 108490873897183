import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, makeStyles } from '@material-ui/core';
import LoadingIcon from '@material-ui/icons/Autorenew';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { TreeItem as MUITreeItem, TreeItemProps, TreeView } from '@material-ui/lab';
import { remove, uniqueId } from '@thalesrc/js-utils';
import React, { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from 'Common/Button';
import { stopPropagation } from 'utils';

import {
  CHILDREN_LOADING,
  CHILDREN_NOT_LOADED,
  Icd10CacheContext,
  Icd10Context,
  Icd10Item,
  Icd10SelectionContext,
  Icd10SelectionContextType,
} from './icd10.context';

const useStyles = makeStyles(theme => ({
  itemLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  yellowStar: {
    color: theme.palette.warning.light,
  },
}));

function TreeItem({ id, children, label, ...other }: Icd10Item) {
  const { itemLabelContainer, yellowStar } = useStyles();
  const { loadChildrenOf, favourites, toggleFavourite } = useContext(Icd10CacheContext);
  const { selection, setSelection } = useContext(Icd10SelectionContext);

  const emptyNodeId = useMemo<string>(() => (children instanceof Array ? null : (uniqueId('tree-item') as string)), [children]);

  const handleToggle = useCallback(
    (event: MouseEvent) => {
      if (children === CHILDREN_LOADING) {
        return event.preventDefault();
      }

      if (children === CHILDREN_NOT_LOADED) {
        return loadChildrenOf(id);
      }
    },
    [children, id, loadChildrenOf]
  );

  const handleFavouriteToggle = useCallback(() => toggleFavourite(id), [toggleFavourite, id]);

  const checked = useMemo<boolean>(() => selection.includes(id), [selection, id]);

  const handleSelect = useCallback(
    (e: ChangeEvent, value: boolean) => {
      if (value) {
        setSelection([...selection, id]);
      } else {
        setSelection(remove(selection, id));
      }
    },
    [setSelection, selection, id]
  );

  const collapseProps = useMemo<Partial<TreeItemProps>>(
    () => (children === CHILDREN_LOADING ? { collapseIcon: <LoadingIcon className="rotate" /> } : null),
    [children]
  );

  return (
    <MUITreeItem
      {...other}
      nodeId={id + ''}
      className={`mb-1`}
      onIconClick={handleToggle}
      onLabelClick={stopPropagation}
      label={
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleSelect} />}
          label={
            <div className={itemLabelContainer}>
              <IconButton
                className="p-1 mr-1"
                onClick={handleFavouriteToggle}
                title={favourites.includes(id) ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}>
                {favourites.includes(id) ? <StarIcon className={yellowStar} /> : <StarBorderIcon />}
              </IconButton>
              {label}
            </div>
          }
        />
      }
      {...collapseProps}>
      {children === CHILDREN_NOT_LOADED || children === CHILDREN_LOADING ? (
        <MUITreeItem nodeId={emptyNodeId} />
      ) : (
        (children as Icd10Item[]).map(child => <TreeItem key={child.id} {...child} />)
      )}
    </MUITreeItem>
  );
}

export default function Selection() {
  const [selection, setSelection] = useState<Icd10Item['id'][]>([]);
  const { selectionOpened, setSelectionOpened, name } = useContext(Icd10Context);
  const { items } = useContext(Icd10CacheContext);
  const { getValues, setValue } = useFormContext();

  const close = useCallback(() => setSelectionOpened(false), [setSelectionOpened]);

  const submitSelection = useCallback(() => {
    setValue(name, selection);
    setSelectionOpened(false);
  }, [setValue, setSelectionOpened, name, selection]);

  const context = useMemo<Icd10SelectionContextType>(() => ({ selection, setSelection }), [selection]);

  useEffect(() => {
    if (!selectionOpened) {
      return;
    }

    setSelection(getValues(name));
  }, [selectionOpened, name, getValues]);

  return (
    <Dialog open={selectionOpened} onClose={close}>
      <DialogTitle>Tanı Ekle</DialogTitle>
      <DialogContent>
        <Icd10SelectionContext.Provider value={context}>
          <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            {items.map(item => (
              <TreeItem key={item.id} {...item} />
            ))}
          </TreeView>
        </Icd10SelectionContext.Provider>
      </DialogContent>
      <DialogActions className="text-right">
        <Button onClick={close}>Vazgeç</Button>
        <Button color="primary" variant="contained" onClick={submitSelection}>
          Seç
        </Button>
      </DialogActions>
    </Dialog>
  );
}
