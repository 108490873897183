/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmergencyCaseTeamMemberDto } from '../models/EmergencyCaseTeamMemberDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EmergencyCaseTeamMemberControllerService {

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseTeamMemberDto OK
     * @throws ApiError
     */
    public static saveEmergencyCaseTeam1(
        id: string,
        requestBody: Array<EmergencyCaseTeamMemberDto>,
    ): CancelablePromise<Array<EmergencyCaseTeamMemberDto>> {
        return __request({
            method: 'POST',
            path: `/emergency-case-teams/${id}/emergency-case-team-members`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns EmergencyCaseTeamMemberDto OK
     * @throws ApiError
     */
    public static getEmergencyCaseTeamById1(
        id: string,
    ): CancelablePromise<EmergencyCaseTeamMemberDto> {
        return __request({
            method: 'GET',
            path: `/emergency-case-team-members/${id}`,
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllEmployeeEmergencyCaseTeam1(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/emergency-case-team-members/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns EmergencyCaseTeamMemberDto OK
     * @throws ApiError
     */
    public static updateEmployeeEmergencyCaseTeam(
        id: string,
        requestBody: EmergencyCaseTeamMemberDto,
    ): CancelablePromise<EmergencyCaseTeamMemberDto> {
        return __request({
            method: 'PATCH',
            path: `/emergency-case-team-members/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param emergencyTeamId
     * @param onlyActive
     * @returns EmergencyCaseTeamMemberDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCaseTeam1(
        emergencyTeamId: string,
        onlyActive: boolean = false,
    ): CancelablePromise<Array<EmergencyCaseTeamMemberDto>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-team-members`,
            query: {
                'emergencyTeamId': emergencyTeamId,
                'onlyActive': onlyActive,
            },
        });
    }

    /**
     * @param emergencyTeamIds
     * @param onlyActive
     * @returns EmergencyCaseTeamMemberDto OK
     * @throws ApiError
     */
    public static getAllEmergencyCaseTeam2(
        emergencyTeamIds: Array<string>,
        onlyActive: boolean = false,
    ): CancelablePromise<Record<string, Array<EmergencyCaseTeamMemberDto>>> {
        return __request({
            method: 'GET',
            path: `/emergency-case-team-members/map`,
            query: {
                'emergencyTeamIds': emergencyTeamIds,
                'onlyActive': onlyActive,
            },
        });
    }

}