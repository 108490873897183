import React, { useState } from 'react';

import { getTrainers, TrainerModel } from 'api/hs/definitions/trainings/trainers';
import { useFetch } from 'utils';

import Trainers from './Trainer';
import { TrainersContext } from './trainers.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<TrainerModel>(null);

  const { data, fetch } = useFetch(() => getTrainers(), [], { setLoading });

  return (
    <TrainersContext.Provider
      value={{
        trainers: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <Trainers />
    </TrainersContext.Provider>
  );
}
