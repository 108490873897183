// @ts-ignore

import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { OccupationalSafetyRegistriesDetectionsPayload } from './occupational-safety-registries-detections.payload';
import { OccupationalSafetyRegistriesDetectionsResult } from './occupational-safety-registries-detections.result';
import { OccupationalSafetyRegistriesContentResult, OccupationalSafetyRegistriesResult } from './occupational-safety-registries-list';
import { PostOccupationalRegistriesViewsPayload } from './occupational-safety-registries-list';
import { OccupationalSafetyRegistriesPagesPayload } from './occupational-safety-registries-pages.payload';
import { OccupationalSafetyRegistriesPagesResult } from './occupational-safety-registries-pages.result';
import { OccupationalSafetyRegistriesPayload } from './occupational-safety-registries.payload';

export async function saveOccupationalSafetyRegistries(
  body: OccupationalSafetyRegistriesPayload
): Promise<OccupationalSafetyRegistriesResult> {
  const [err, res] = await tryCatch(HSApi.post('/occupational-safety-registries', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function getOccupationalSafetyRegistries(companyId: string): Promise<OccupationalSafetyRegistriesResult[]> {
  const [error, res] = await tryCatch(
    HSApi.get<OccupationalSafetyRegistriesResult[]>(`/occupational-safety-registries?companyId=${companyId}`)
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}
export async function deleteOccupationalSafetyRegistries(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/occupational-safety-registries/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
export async function saveOccupationalSafetyRegistriesPages(
  body: OccupationalSafetyRegistriesPagesPayload
): Promise<OccupationalSafetyRegistriesPagesResult> {
  const [err, res] = await tryCatch(HSApi.post('/occupational-safety-registries/pages', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
export async function deleteOccupationalSafetyRegistriesPages(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/occupational-safety-registries/pages/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
export async function saveOccupationalSafetyRegistriesDetections(
  body: OccupationalSafetyRegistriesDetectionsPayload
): Promise<OccupationalSafetyRegistriesResult> {
  const [err, res] = await tryCatch(HSApi.post('/occupational-safety-registries/detections', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}
export async function getOccupationalSafetyRegistriesViews(id: string): Promise<OccupationalSafetyRegistriesContentResult[]> {
  const body = {
    filter: {},
    pagingAndSortingRequestParam: {
      page: 0,
      size: 10000,
    },
  };
  const [error, res] = await tryCatch(
    HSApi.post<OccupationalSafetyRegistriesContentResult[]>(`occupational-safety-registries/${id}/pages/views/`, body)
  );

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function getOccupationalSafetyRegistriesDetections(id: string): Promise<any[]> {
  const [error, res] = await tryCatch(HSApi.get<any[]>(`occupational-safety-registries/page/${id}/detections`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteOccupationalSafetyRegistriesDetections(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/occupational-safety-registries/detections/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function patchOccupationalSafetyRegistriesDetections(
  id: string,
  body: PostOccupationalRegistriesViewsPayload
): Promise<OccupationalSafetyRegistriesDetectionsResult> {
  const [error, res] = await tryCatch(HSApi.patch<any>(`/occupational-safety-registries/detections/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function saveOccupationalSafetyRegistriesFileUpload(id: any['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`/occupational-safety-registries/page/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function getOccupationalSafetyRegistriesFileUpload(id: string): Promise<any[]> {
  const [error, res] = await tryCatch(HSApi.get<any[]>(`/occupational-safety-registries/page/${id}/imported-files`));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function deleteOccupationalSafetyRegistriesFileUpload(id: any['id']) {
  const [err, res] = await tryCatch(HSApi.delete(`/occupational-safety-registries/page/imported-files/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
