import React from 'react';

import BlockSearchBar from 'Common/SearchBar/BlockSearchBar';

interface Props {
  value?: string;
  onChange?: () => void;
}

export default function Index({ value, onChange }: Props) {
  return (
    <div style={{ padding: '5px' }}>
      <BlockSearchBar value={value} onChange={onChange} placeholder="Anahtar Kelime Giriniz..." />
    </div>
  );
}
