import { createContext } from 'react';

import { DiagnosisModel } from 'api/hs/examination/diagnosis';
import { ExaminationResultItem } from 'api/hs/examination/examinations.result';

export interface ExaminationDetailContextType {
  diagnosis: DiagnosisModel[];
  reloadDiagnosis(): Promise<any>;
  protocolNumber: ExaminationResultItem['protocolNumber'];
  fetchExamination(): Promise<any>;
}

export const ExaminationDetailContext = createContext<ExaminationDetailContextType>(null);
