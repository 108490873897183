import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import LoopIcon from '@material-ui/icons/Loop';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useState } from 'react';

import { deleteEPrescription, deletePrescription, PrescriptionModel, previewPrescription, updatePrescription } from 'api/hs/examination';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { UserContext } from 'context/user';
import { printFile, stopPropagation } from 'utils';

import { EnvironmentContext } from '../../../../../../context/environment.context';
import { PrescriptionContext } from '../../prescription.context';
import {exportEmployeePrescription} from "../../../../../../api/hs/employee";
const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;
interface Props {
  data: PrescriptionModel;
}

export default function Action({ data }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const {
    setPreviewData,
    setPreviewDialogOpened,
    setSelectedPrescription,
    setEditDialogOpened,
    setConvertEPrescriptionEditDialogOpened,
    reload,
    setEmailDialogOpened,
  } = useContext(PrescriptionContext);
  const { openWarnDialog } = useContext(DialogContext);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const { showMessage } = useContext(AlertContext);
  const { user, profile } = useContext(UserContext);
  const { name } = useContext(EnvironmentContext);
  const openPrescriptionDeleteDialog = useCallback(async () => {
    closeMenu();

    const submitted = await openWarnDialog({
      text: `${data.requestDate} tarihli reçete kaydını silmek istediğinize emin misiniz?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deletePrescription(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [closeMenu, data, reload, openWarnDialog, showMessage]);

  const openEPrescriptionDeleteDialog = useCallback(async () => {
    closeMenu();

    const submitted = await openWarnDialog({
      text: `${data.eprescriptionNo} numaralı e-reçete kaydını silmek istediğinize emin misiniz?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        const payload = {
          action: 'erecete_sil',
          pinCode: profile.esignPinCode,
          username: profile.medulaUserName,
          password: profile.medulaPassword,
          tcKimlikNo: profile.nid,
          drname: user.name,
          tesisKodu: profile.plantCode,
          sertifikaKodu: profile.certificateTypeDefinitionId,
          env: name,
          remoteWebMethod: 'imzaliEreceteSil',
          content: `<?xml version="1.0" encoding="UTF-8" standalone="yes"?><imzaliEreceteSilBilgisi><tesisKodu>${profile.plantCode}</tesisKodu><doktorTcKimlikNo>${profile.nid}</doktorTcKimlikNo><ereceteNo>${data.eprescriptionNo}</ereceteNo></imzaliEreceteSilBilgisi>`,
        };

        await deleteEPrescription(payload);
        const updatedPayload = {
          id: data?.id,
          response: '',
          request: '',
          protocolNo: '',
          eprescriptionNo: '',
        };

        await updatePrescription(updatedPayload);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [closeMenu, openWarnDialog, data, profile, showMessage, reload, name]);

  const openEditDialog = useCallback(async () => {
    setSelectedPrescription(data);
    setEditDialogOpened(true);
    closeMenu();
  }, [data, closeMenu, setEditDialogOpened, setSelectedPrescription]);

  const openEmailDialog = useCallback(async () => {
    setSelectedPrescription(data);
    setEmailDialogOpened(true);
    closeMenu();
  }, [closeMenu, setEmailDialogOpened, setSelectedPrescription, data]);

  const openConvertToEPrescriptionDialog = useCallback(async () => {
    setSelectedPrescription(data);
    setConvertEPrescriptionEditDialogOpened(true);
    closeMenu();
  }, [data, closeMenu, setConvertEPrescriptionEditDialogOpened, setSelectedPrescription]);

  const exportFile = useCallback(async () => {
    await exportEmployeePrescription(data?.id);
  }, [data]);

  const onPreview = useCallback(async () => {
    const htmlData = await previewPrescription(data?.id);
    setPreviewData(htmlData);
    setPreviewDialogOpened(true);
    closeMenu();
  }, [data, setPreviewDialogOpened, closeMenu, setPreviewData]);

  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          {data.eprescriptionNo ? (
            <MenuItem onClick={openEPrescriptionDeleteDialog}>
              <ListItemIcon>
                <DeleteIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit">e-Reçeteyi Sil</Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={openConvertToEPrescriptionDialog}>
              <ListItemIcon>
                <LoopIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit">e-Reçeteye Dönüştür</Typography>
            </MenuItem>
          )}
          {!data.eprescriptionNo && (
            <MenuItem onClick={openEditDialog}>
              <ListItemIcon>
                <EditIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit">Düzenle</Typography>
            </MenuItem>
          )}
          {!data.eprescriptionNo ? (
            <MenuItem onClick={openPrescriptionDeleteDialog}>
              <ListItemIcon>
                <DeleteIcon fontSize="medium" />
              </ListItemIcon>
              <Typography variant="inherit">Sil</Typography>
            </MenuItem>
          ) : null}

          <MenuItem onClick={exportFile}>
            <ListItemIcon>
              <GetAppIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">İndir</Typography>
          </MenuItem>
          <MenuItem onClick={onPreview}>
            <ListItemIcon>
              <VisibilityIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Önizleme</Typography>
          </MenuItem>
          <MenuItem onClick={openEmailDialog}>
            <ListItemIcon>
              <MailIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Email Gönder</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              printFile(`/prescriptions/${data?.id}/detail-view/export?type=HTML`);
              closeMenu();
            }}>
            <ListItemIcon>
              <PrintIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Yazdır</Typography>
          </MenuItem>
        </Menu>
      </span>
    </>
  );
}
