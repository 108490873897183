import { modelDataMatcher } from 'utils';

import { Icd10ListResult, Icd10ListResultItem } from './icd10-list.result';

export class Icd10 {
  public id: string = null;
  public name: string = null;
  public label: string = null;

  constructor(data: Partial<Icd10> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromServerData({ id, icd10Name, code }: Icd10ListResultItem): Icd10 {
    return new Icd10({ id, label: icd10Name, name: code });
  }

  public static fromServerListData(data: Icd10ListResult): Icd10[] {
    return data ? data.map(Icd10.fromServerData): [];
  }
}
