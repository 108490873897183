import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext } from 'react';

import { CommitteeMembersContext } from '../../committee-members.context';
import CommitteeMembersForm from '../Form';

const useStyles = makeStyles(theme => ({
  fabButton: {
    width: 40,
    height: 40,
    color: 'white',
    marginTop: '-73px',
    backgroundColor: '#eb4355',
  },
  addButton: {
    width: '21px',
    height: '21px',
  },
}));

export default function Add() {
  const classes = useStyles();
  const { setNewDialogOpened, newDialogOpened, reload } = useContext(CommitteeMembersContext);
  const closeDialog = useCallback(
    success => {
      setNewDialogOpened(false);

      if (success) {
        reload();
      }
    },
    [reload, setNewDialogOpened]
  );

  const onClick = () => {
    setNewDialogOpened(true);
  };
  return (
    <>
      <div className="text-right mr-1">
        <Fab onClick={onClick} className={classes.fabButton}>
          <AddIcon className={classes.addButton} />
        </Fab>
      </div>
      <CommitteeMembersForm onClose={closeDialog} data={null} open={newDialogOpened} />
    </>
  );
}
