import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef } from 'react';

import Dialog from 'Common/Dialog';
import AutoComplete from 'Common/Form/AutoComplete';
import Form, { FormRef } from 'Common/Form/Form';
import { useAsyncEffect, useTriggerRender } from 'utils';

import { DrillControllerService } from '../../api/client';
import { DrillDto } from '../../api/client';
import DateInput from '../../Common/Form/DateInput';
import TextField from '../../Common/Form/TextField';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: DrillDto;

  fetch(): void;
}

function nextMonths(date: Date, months: number) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function DrillForm({ open, onClose, data, fetch }: Props) {
  const { companies } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const { showMessage } = useContext(AlertContext);
  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data) {
          await DrillControllerService.saveDrill(values);
          showMessage('Başarılı kayıt edildi.', 'success', 'info');
        } else {
          await DrillControllerService.updateDrill(data?.id, values);
          showMessage('Kayıt başarılı şekilde güncellendi.', 'success', 'info');
        }
        onClose(true);
        await fetch();
      } catch (err) {
        showMessage('Kayıt sırasında hata oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [showMessage, onClose, fetch, data]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();

    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  useEffect(() => {
    if (!data) {
      const currentExecuteDate = formRef?.current?.methods?.getValues('executionDate');
      if (currentExecuteDate) {
        formRef?.current?.methods?.setValue('nextExecutionDate', nextMonths(new Date(currentExecuteDate), 12));
      }
      formRef?.current?.methods?.trigger();
    }
  }, [reRender, data]);

  return (
    <Dialog
      width="md"
      title={data ? 'Tatbikat Düzenleme' : 'Tatbikat Ekleme'}
      color="secondary"
      opened={open}
      onClose={() => onClose(false)}>
      <Form onChange={reRender} onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <AutoComplete single name="companyId" options={companies} label="Şirketler" required={true}/>
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <TextField name="subject" label="Tatbikat Konusu" required={true} />
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <DateInput type="date" name="executionDate" label="Tatbikat Tarihi"  required={true} />
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <DateInput type="date" name="nextExecutionDate" label="Geçerlilik Tarihi"  required={true}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} className="text-right">
            <Button variant="contained" type="submit" color="secondary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={() => onClose(false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
