import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';

import { ActionWidgetItem } from '../../../../api/hs/dashboard/widget/widget.payload';
import { datePrettier } from '../../../../utils';

interface Props {
  actions: ActionWidgetItem[];
}

export default ({ actions }: Props) => {
  if (!actions || actions.length === 0) return <b>Kayıt bulunamadı!</b>;

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className="text-bold">Aksiyon</TableCell>
            <TableCell className="text-bold">Tarih</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action, index) => (
            <TableRow key={index} hover>
              <TableCell scope="row">{action.name}</TableCell>
              <TableCell>{datePrettier(action.date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
