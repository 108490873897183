/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IbysExaminationDto } from '../models/IbysExaminationDto';
import type { IbysExamSignedDataDto } from '../models/IbysExamSignedDataDto';
import type { PageIbysExaminationDto } from '../models/PageIbysExaminationDto';
import type { PagingAndSortingRequestParam } from '../models/PagingAndSortingRequestParam';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IbysExaminationControllerService {

    /**
     * @param id
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static getIbysExaminationById(
        id: string,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'GET',
            path: `/ibys-examinations/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static updateIbysExamination(
        id: string,
        requestBody: IbysExaminationDto,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'PUT',
            path: `/ibys-examinations/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteAllIbysExamination(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/ibys-examinations/${id}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static partialUpdateIbysExamination(
        id: string,
        requestBody: IbysExaminationDto,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'PATCH',
            path: `/ibys-examinations/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static getActionDefinitionById(
        id: string,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'GET',
            path: `/examinations/${id}/ibys-examinations`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static saveOrUpdateIbysExamination(
        id: string,
        requestBody: IbysExaminationDto,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'PUT',
            path: `/examinations/${id}/ibys-examinations`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param psRequestParam
     * @returns PageIbysExaminationDto OK
     * @throws ApiError
     */
    public static getIbysExaminations(
        psRequestParam: PagingAndSortingRequestParam,
    ): CancelablePromise<PageIbysExaminationDto> {
        return __request({
            method: 'GET',
            path: `/ibys-examinations`,
            query: {
                'psRequestParam': psRequestParam,
            },
        });
    }

    /**
     * @param requestBody
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static saveIbysSaveExamination(
        requestBody: IbysExaminationDto,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'POST',
            path: `/ibys-examinations`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns IbysExamSignedDataDto OK
     * @throws ApiError
     */
    public static saveIbysSaveExaminationSignedData(
        requestBody: IbysExamSignedDataDto,
    ): CancelablePromise<IbysExamSignedDataDto> {
        return __request({
            method: 'POST',
            path: `/ibys-examinations/signed-data`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static generateXmlContentOfExamination(
        id: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/ibys-examinations/${id}/xml-contents`,
        });
    }

    /**
     * @param id
     * @returns IbysExaminationDto OK
     * @throws ApiError
     */
    public static saveIbysExamination(
        id: string,
    ): CancelablePromise<IbysExaminationDto> {
        return __request({
            method: 'GET',
            path: `/examinations/${id}/ibys-examination`,
        });
    }

}
