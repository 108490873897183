import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { BuildingDefinitionControllerService } from 'api/client';
import Button from 'Common/Button';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import IndicatorWrapper from 'Common/IndicatorWrapper';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { ResourceContext } from 'context/resource.context';

import { BuildingsDefinitionContext } from '../../buildings-definition.context';
import EditDialog from './EditDialog';

export default function ListTrainer() {
  const { loading, buildingDefinitions, setEditDialogOpened, setSelectedData, reload } = useContext(BuildingsDefinitionContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const history = useHistory();
  const { locations } = useContext(ResourceContext);
  const openEditDialog = useCallback(
    data => {
      setSelectedData(data);
      setEditDialogOpened(true);
    },
    [setEditDialogOpened, setSelectedData]
  );

  const onDelete = useCallback(
    async (building: any) => {
      const submitted = await openWarnDialog({
        text: `${building.name} - isimli kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await BuildingDefinitionControllerService.deleteBuildingDefinition(building.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  const route = useCallback(
    (buildingId: string) => {
      history.push(`/definitions/workplace/buildings/${buildingId}/floors`);
    },
    [history]
  );

  return (
    <div className="m-2">
      <IndicatorWrapper loading={loading} data={buildingDefinitions} errorMessage="Gösterilecek Bina Bilgisi Bulunamadı">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-bold text-gray" width="5%" align="left" />
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Bina
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Lokasyon
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="left">
                  Kat Durumu
                </TableCell>
                <TableCell className="text-bold text-gray" width="10%" align="right">
                  İşlem
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buildingDefinitions.map(item => (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <BusinessIcon style={{ color: 'gray' }} />
                  </TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{findByKey(locations, 'id', item?.locationId)?.label}</TableCell>
                  <TableCell align="left">
                    <Button onClick={() => route(item?.id)} color="primary">
                      Katları
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openEditDialog(item)} edge="end" aria-label="edit">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EditDialog />
      </IndicatorWrapper>
    </div>
  );
}
