import { Grow, IconButton, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { noop } from '@thalesrc/js-utils';
import React, { useEffect, useRef, useState } from 'react';

import SearchBarContainer from './SearchBarContainer';

const useStyles = makeStyles(theme => ({
  input: {
    color: theme.palette.common.white,
    width: 280,
  },
}));

interface Props {
  value: string;

  onChange(value: string): void;

  placeholder?: string;
}

export default function InlineSearchBar({ value = '', onChange = noop, placeholder = '' }: Props) {
  const classes = useStyles();
  const [shown, setShown] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  function handleToggle() {
    setShown(!shown);

    if (!shown) {
      setTimeout(() => inputRef.current.focus(), 300);
    }
  }

  function handleEscape() {
    setShown(false);
    inputRef.current.blur();
  }

  useEffect(() => {
    if (value) {
      setShown(true);
    }
  }, [value]);

  return (
    <>
      <IconButton color="inherit" onClick={handleToggle}>
        <SearchIcon />
      </IconButton>
      <SearchBarContainer
        value={value}
        onChange={onChange}
        onEscape={handleEscape}
        input={({ value: inputValue, onChange: inputOnChange, onKeyPress: inputOnKeyPress, onBlur, onFocus }) => (
          <Grow in={shown} style={{ width: shown ? 'auto' : 0 }}>
            <InputBase
              value={inputValue || ''}
              onChange={({ target: { value: _value } }) => inputOnChange(_value)}
              onKeyPress={({ key }) => inputOnKeyPress(key)}
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder={placeholder}
              inputRef={inputRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => inputOnChange('')} edge="end" className="text-white">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              inputProps={{ className: classes.input }}
            />
          </Grow>
        )}
      />
    </>
  );
}
