import { Checkbox, FormControl, FormHelperText, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { arrayize, debounceWithKey, defer, findByKey, uniquifyByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from 'utils';

import { FormContext } from './Form/form.context';
import { SelectOption } from './Select';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 20,
  },
  listbox: {
    maxHeight: 250,
  },
  error: {
    position: 'absolute',
    bottom: 0,
  },
  inputRoot: {
    paddingRight: 'var(--end-adornment-width) !important',
  },
  endAdornment: {
    position: 'absolute',
    right: 0,
    top: 16,
  },
}));

type RenderOptionFunctionType<T, U> = (option: SelectOption<T> & U, data: { selected: boolean }) => React.ReactNode;

interface BaseProps<T, U extends {} = {}> {
  name: string;
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  renderOption?: RenderOptionFunctionType<T, U> | 'checkbox';
  single?: boolean;
  disabled?: boolean;
  required?: boolean;
  endAdornment?: JSX.Element | string;
}

interface StaticProps<T, U extends {} = {}> extends BaseProps<T, U> {
  options: (SelectOption<T> & U)[];
}

interface DynamicProps<T, U extends {} = {}> extends BaseProps<T, U> {
  optionFetcher: (value: string) => Promise<(SelectOption<T> & U)[]>;
  labelFetcher: (values: T[]) => Promise<(SelectOption<T> & U)[]>;
  debounceTime?: number;
  defaultValue?: string;
}

export default function AutoComplete<T, U = {}>(props: StaticProps<T, U> | DynamicProps<T, U>) {
  const {
    name,
    options,
    label = '',
    variant = 'standard',
    renderOption,
    optionFetcher,
    labelFetcher,
    debounceTime = 300,
    single = false,
    disabled = false,
    required = false,
    endAdornment = null,
    defaultValue = null,
  } = props as StaticProps<T, U> & DynamicProps<T, U>;

  const classes = useStyles();
  const { control, trigger, errors } = useFormContext();
  const [searchDebounceKey] = useState(Symbol('debounce key'));
  const [fetchedOptions, setFetchedOptions] = useState<(SelectOption<T> & U)[]>([]);
  const [searchText, setSearchText] = useState('');
  const [cachedLabels, setCachedLabels] = useState<{ data: (SelectOption<T> & U)[] }>({ data: [] });
  const { readonly } = useContext(FormContext);
  const translate = useTranslate('form');
  const endAdornmentRef = useRef<HTMLDivElement>();

  const notEditable = useMemo(() => disabled || readonly, [disabled, readonly]);
  const translatedLabel = useMemo(() => translate(label), [translate, label]);

  /**
   * Input component
   */
  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        variant={variant}
        label={translatedLabel}
        InputLabelProps={{ ...params.InputLabelProps, required }}
        error={!!errors[name]}
        InputProps={{
          ...params.InputProps,
          ...(single ? { required } : null),
          ...(optionFetcher ? { placeholder: translate('Arama') } : null),
          ...(endAdornment
            ? {
                endAdornment: (
                  <InputAdornment position="end" ref={endAdornmentRef} className={classes.endAdornment}>
                    {endAdornment}
                  </InputAdornment>
                ),
              }
            : null),
        }}
      />
    ),
    [translatedLabel, variant, optionFetcher, required, errors, name, translate, endAdornment, single, classes]
  );

  /**
   * Label getter to get used for every option
   */
  const getOptionLabel = useCallback<(option: SelectOption<T>) => string>(option => (option?.text || '') as string, []);

  const renderOptionFunc = useMemo<RenderOptionFunctionType<T, U>>(() => {
    if (typeof renderOption === 'function') {
      return renderOption;
    }

    switch (renderOption) {
      case 'checkbox':
        return ({ text }, { selected }) => (
          <>
            <Checkbox className="mr-2" checked={selected} color="primary" />
            {text}
          </>
        );
      default:
        return ({ text }) => text;
    }
  }, [renderOption]);

  const fetcher = useCallback(
    async value => {
      const res = await optionFetcher(value);
      setFetchedOptions(res);
    },
    [optionFetcher]
  );

  const handleInputChange = useCallback(
    (_, value: string, reason) => {
      if (reason === 'reset') {
        return;
      }

      setSearchText(value);
      debounceWithKey(searchDebounceKey, fetcher, debounceTime, null, value);
    },
    [fetcher, searchDebounceKey, debounceTime]
  );

  const getInnerValues = useCallback(
    (values: T[] | T) => {
      values = (values as any) === '' ? null : values;

      if (!optionFetcher) {
        return single
          ? findByKey(options, 'value', values as any) ?? null
          : arrayize(values || []).map(v => options.find(option => option.value === v));
      }

      const notFound: T[] = [];

      const mapped = arrayize(values || []).map(v => {
        const cached = findByKey(cachedLabels.data, 'value', v as any);

        if (!cached) {
          notFound.push(v);
        }

        return cached || { text: '...', value: v };
      });

      if (notFound.length) {
        cachedLabels.data = [...cachedLabels.data, ...notFound.map(v => ({ value: v, text: '...' } as any))];

        defer()
          .then(() => labelFetcher(notFound))
          .then(opts => {
            setCachedLabels({ data: uniquifyByKey([...opts, ...cachedLabels.data], 'value') });
          });
      }

      return single ? mapped[0] || null : mapped;
    },
    [optionFetcher, labelFetcher, options, cachedLabels, single]
  );

  const isSingle = useMemo(() => (single ? null : []), [single]);

  const rules = useMemo(
    () => ({
      validate: {
        ...(required ? { required: (value: T | T[]) => (single ? value !== null : !!(value as T[]).length) } : null),
      },
    }),
    [required, single]
  );

  const errorMessage = useMemo(() => {
    switch (errors[name]?.type) {
      case 'required':
        return translate('This field is required');
      default:
        return '';
    }
  }, [errors, name, translate]);

  return (
    <FormControl fullWidth className={classes.root} error={!!errors[name]}>
      <Controller
        control={control}
        defaultValue={defaultValue || isSingle}
        rules={rules}
        name={name}
        render={({ onChange, value }) => (
          <Autocomplete<SelectOption<T> & U, boolean, undefined, boolean>
            multiple={!single}
            options={optionFetcher ? fetchedOptions : options}
            noOptionsText="Sonuç bulunamadı"
            disableCloseOnSelect={!single}
            disabled={notEditable}
            value={getInnerValues(value) as (SelectOption<T> & U)[]}
            onChange={(_, newValues) => {
              if (single) {
                onChange(newValues === null ? null : (newValues as SelectOption<T>).value);
              } else {
                onChange(uniquifyByKey(newValues as SelectOption<T>[], 'value').map(({ value: v }) => v));
              }

              control.updateFormState({ touched: { ...control.formStateRef.current.touched, [name]: true } });
              trigger();

              if (!!optionFetcher) {
                if (!single) {
                  setSearchText('');
                }

                setFetchedOptions([]);
              }
            }}
            getOptionLabel={getOptionLabel}
            renderOption={renderOptionFunc}
            renderInput={renderInput}
            classes={{ listbox: classes.listbox, inputRoot: endAdornment ? classes.inputRoot : '' }}
            style={{ '--end-adornment-width': endAdornmentRef.current?.clientWidth + 'px' } as any}
            {...{
              ...(optionFetcher && {
                onInputChange: handleInputChange,
                ...(!single && {
                  freeSolo: true,
                  inputValue: searchText,
                }),
              }),
            }}
          />
        )}
      />
      <FormHelperText className={classes.error}>{errorMessage}</FormHelperText>
    </FormControl>
  );
}
