import { tryCatch } from '@thalesrc/js-utils';

import HSApi from '../../HSApi';
import { ExaminationListResult } from '../../TransactionAndLists/ExaminationList';
import { CommitteeMeetingsContentResult } from './committee-meetings-content.result';
import { CommitteeMeetingsPayload } from './committee-meetings.payload';
import { CommitteeMeetingsResult } from './committee-meetings.result';
import { CommitteeMeetingsFilters, GetCommitteeMeetingListPayload } from './get-committe-meetings.payload';

export async function getCommitteeMeetingsList(
  page = 0,
  size = 20,
  order: null | string,
  filters: CommitteeMeetingsFilters
): Promise<CommitteeMeetingsResult> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof CommitteeMeetingsFilters)[] = [
    'plannedMaxDate',
    'plannedMinDate',
    'searchResult',
    'searchSubject',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload: GetCommitteeMeetingListPayload = {
    committeeMeetingFilter: parsedFilters,
    pagingAndSortingRequestParam: {
      page,
      size,
      order,
    },
  };

  const res = await HSApi.post<CommitteeMeetingsResult>(`/committee-meetings/view`, payload);

  return res.data;
}

export async function deleteCommitteeMeeting(id: string): Promise<any> {
  const [err, res] = await tryCatch(HSApi.delete(`/committee-meetings/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function saveCommitteeMeeting(body: CommitteeMeetingsPayload): Promise<CommitteeMeetingsContentResult> {
  const [err, res] = await tryCatch(HSApi.post('/committee-meetings', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updateCommitteeMeeting(id, payload): Promise<PpeDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.patch<CommitteeMeetingsContentResult>(`/committee-meetings/${id}`, payload));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function fileSaveCommitteeMeeting(id: CommitteeMeetingsContentResult['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('imported-file', file);

  const [error, res] = await tryCatch(HSApi.post(`/committee-meetings/${id}/imported-files`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}

export async function exportCommitteeMeetingsList(filters: CommitteeMeetingsFilters): Promise<any> {
  const PROPS_TO_CONVERT_INTO_NULL: (keyof CommitteeMeetingsFilters)[] = [
    'searchResult',
    'searchSubject',
    'isFaceToFace',
    'plannedMaxDate',
    'plannedMinDate',
  ];

  const parsedFilters = { ...filters };

  // Convert `''` values into `null`
  for (const key of PROPS_TO_CONVERT_INTO_NULL) {
    if ((parsedFilters as any)[key] !== '') {
      continue;
    }

    (parsedFilters as any)[key] = null;
  }

  const payload = {
    committeeMeetingFilter: parsedFilters,
  };

  const res = await HSApi.post<ExaminationListResult>(`/committee-meetings/views/export`, payload, { responseType: 'blob' });
  const file = res.data;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'kurul_toplantisi.xlsx';
  a.click();
}
