import { tryCatch } from '@thalesrc/js-utils';

import { PpeDefinitionsPayload } from 'api/hs/ppe/ppe-definitions';

import HSApi from '../../HSApi';
import { PpeDefinitionsContentResult } from './ppe-definitions-content.result';
import { PpeDefinitionsResult } from './ppe-definitions.result';

export async function getPpeDefinitionList(page = 0, size = 20, order: null | string): Promise<PpeDefinitionsResult> {
  const res = await HSApi.get<PpeDefinitionsResult>(`/ppe-definitions?page=${page}&size=${size}`);
  return res.data;
}

export async function deletePpeDefinition(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/ppe-definitions/${id}`));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

export async function savePpeDefinition(body: PpeDefinitionsPayload): Promise<PpeDefinitionsResult> {
  const [err, res] = await tryCatch(HSApi.post('/ppe-definitions', body));

  if (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
  }

  return res.data;
}

// @ts-ignore
export async function updatePpeDefinition({ id, ...body }): Promise<PpeDefinitionsResult> {
  const [error, res] = await tryCatch(HSApi.put<PpeDefinitionsResult>(`/ppe-definitions/${id}`, body));

  if (error) {
    // tslint:disable-next-line:no-console
    console.error(error);

    throw error;
  }

  return res.data;
}

export async function savePpeDefinitionFile(id: PpeDefinitionsContentResult['id'], file: File): Promise<unknown> {
  const data = new FormData();
  data.set('image', file);

  const [error, res] = await tryCatch(HSApi.post(`/ppe-definitions/${id}/image`, data));

  if (error) {
    console.error(error); // tslint:disable-line:no-console
    throw error.response;
  }

  return res.data;
}
