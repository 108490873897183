import { Button, Divider, Drawer, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';

import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { HSTableContext } from 'Common/Table/HSTable/hs-table.context';
import Toolbar from 'Common/Toolbar';
import { useAsyncEffect, useDialogState, useTriggerRender } from 'utils';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../../api/hs/employee';
import AutoComplete from '../../../../Common/Form/AutoComplete';
import DateInput from '../../../../Common/Form/DateInput';
import { RadioGroupInput } from '../../../../Common/Form/RadioGroup';
import { SelectOption } from '../../../../Common/Form/Select';
import { SelectOptionsContext } from '../../../../context/select-options.context';
import { RiskAssessmentsListContext } from '../../risk-assessments-list.context';

const useStyles = makeStyles(() => ({
  fab: {
    transform: 'translateY(50%)',
  },
  drawer: {
    maxWidth: 320,
  },
}));

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}
const radioOptions = [
  {
    text: '5x5',
    value: 'MATRIX',
  },
  {
    text: 'Fine - Kinney',
    value: 'FINE_KINNEY',
  },
];

export default function Filters() {
  const classes = useStyles();
  const { riskAssessmentType } = useContext(RiskAssessmentsListContext);
  const { open, close, opened } = useDialogState();
  const { setExtraArgs, extraArgs } = useContext(HSTableContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const {
    companies,
    departments,
    workStations,
    positions,
    hazardCategoryDefinition,
    fineKinneyHazardFrequencyDefinition,
    fineKinneyHazardDamageDefinition,
    fineKinneyPossibilityDefinition,
    matrixHazardDamageDefinition,
    matrixPossibilityDefinition,
    riskAssessmentMatrixScoreDefinition,
    fineKinneyHazardScoreDefinition,
  } = useContext(SelectOptionsContext);
  const submit = useCallback(
    values => {
      setExtraArgs({ filters: values });
      close();
    },
    [setExtraArgs, close]
  );

  const clear = useCallback(() => {
    setExtraArgs({ filters: {} });
    close();
  }, [setExtraArgs, close]);

  useAsyncEffect(async () => {
    if (!extraArgs.filters || !opened) {
      return;
    }

    await defer();

    for (const [key, value] of Object.entries(extraArgs.filters)) {
      formRef.current.methods.setValue(key, value);
    }

    formRef.current.methods.trigger();
  }, [extraArgs, opened]);

  return (
    <>
      <Fab
        onClick={open}
        size="small"
        className={classes.fab}
        style={{
          backgroundColor: `${
            Object.keys(extraArgs)?.length > 0 ? (Object.keys(extraArgs?.filters)?.length > 0 ? '#f50057' : '#2196f3') : '#2196f3'
          }`,
          color: 'white',
        }}>
        <FilterListIcon />
      </Fab>
      <Drawer anchor="left" open={opened} onClose={close} classes={{ paper: classes.drawer }}>
        <Toolbar color="secondary" className="mb-2">
          <Typography variant="h6">Risk Değerlendirme Filtreleme</Typography>
        </Toolbar>
        <Form onChange={reRender} onSubmit={submit} ref={formRef}>
          <Grid container className="p-2">
            <Grid spacing={1} item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Oluşturma Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="minDate" label="Başlangıç" />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="maxDate" label="Bitiş" />
              </Grid>
            </Grid>
            <Grid spacing={1} item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Revize Tarihi</Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="reviseMinDate" label="Başlangıç" />
              </Grid>
              <Grid item xs={6}>
                <DateInput type="date" name="reviseMaxDate" label="Bitiş" />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <AutoComplete name="companyIds" options={companies} label="Şirket" />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <AutoComplete name="departmentDefinitionIds" options={departments} label="Departmanlar" />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <AutoComplete name="stationDefinitionIds" options={workStations} label="İş istasyonları" />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <AutoComplete name="employeeIds" label="Personel" optionFetcher={employeeOptionFetcher} labelFetcher={employeeLabelFetcher} />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <AutoComplete name="hazardCategoryIds" options={hazardCategoryDefinition} label="Tehlike Kategorisi" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField type="number" name="revisePeriod" label="Revizyon Sıklığı (ay)" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField name="searchWorkDescription" label="İş Tanımında Ara" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField name="searchHazardDescription" label="Tehlike Açıklamada  Ara" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField name="searchRiskDescription" label="Risk Açıklamada Ara" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField name="searchAffected" label="Kimler Etkilenirlerde Ara" />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField name="searchDescription" label="Açıklamada Ara" />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <RadioGroupInput
                disabled={!!riskAssessmentType}
                defaultValue={riskAssessmentType}
                label="Risk Değerlendirme Türü"
                name="riskAssessmentType"
                options={radioOptions}
              />
            </Grid>
            {riskAssessmentType === 'MATRIX' ? (
              <>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixHazardPossibilityIds" options={matrixPossibilityDefinition} label="Olasılık" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixHazardDamageIds" options={matrixHazardDamageDefinition} label="Şiddet" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixHazardScoreIds" options={riskAssessmentMatrixScoreDefinition} label="Skor" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixFinalHazardPossibilityIds" options={matrixPossibilityDefinition} label="Bakiye Olasılık" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixFinalHazardDamageIds" options={matrixHazardDamageDefinition} label="Bakiye Şiddet" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="matrixFinalHazardScoreIds" options={riskAssessmentMatrixScoreDefinition} label="Bakiye Skor" />
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyHazardPossibilityIds" options={fineKinneyPossibilityDefinition} label="Olasılık" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyHazardFrequencyIds" options={fineKinneyHazardFrequencyDefinition} label="Sıklık" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyHazardDamageIds" options={fineKinneyHazardDamageDefinition} label="Şiddet" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyHazardScoreIds" options={fineKinneyHazardScoreDefinition} label="Skor" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete
                    name="fineKinneyFinalHazardPossibilityIds"
                    options={fineKinneyPossibilityDefinition}
                    label="Bakiye Olasılık"
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete
                    name="fineKinneyFinalHazardFrequencyIds"
                    options={fineKinneyHazardFrequencyDefinition}
                    label="Bakiye Sıklık"
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyFinalHazardDamageIds" options={fineKinneyHazardDamageDefinition} label="Bakiye Şiddet" />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <AutoComplete name="fineKinneyFinalHazardScoreIds" options={fineKinneyHazardScoreDefinition} label="Bakiye Skor" />
                </Grid>
              </>
            )}
          </Grid>
          <footer className="px-2 flex-justify">
            <Button className="mr-1" fullWidth type="button" variant="contained" onClick={clear}>
              Temizle
            </Button>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Filtrele
            </Button>
          </footer>
        </Form>
      </Drawer>
    </>
  );
}
