/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PpeAssignmentViewDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    positionId?: string;
    employeeId?: string;
    ppeDefinitionId?: string;
    assignmentType?: PpeAssignmentViewDto.assignmentType;
    firmId?: string;
    brand?: string;
    model?: string;
    description?: string;
    imagePath?: string;
    label?: string;
    ppeEnStandard?: string;
    classificationName?: string;
    ppeTypeName?: string;
    employeeName?: string;
    positionName?: string;
    avatarPath?: string;
    nid?: string;
}

export namespace PpeAssignmentViewDto {

    export enum assignmentType {
        POSITION = 'POSITION',
        EMPLOYEE = 'EMPLOYEE',
    }


}
