import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { AccidentSeverityDefinitionDto, AccidentSeverityTypeDefinitionControllerService } from '../../api/client';
import AccidentSeverityEditDialog from '../../Common/Forms/Definitions/AccidentDefinition/AccidentSeverityTypeDefinition/AccidentSeverityEditDialog';
import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AccidentSeverityTypeDefinition() {
  return (
    <BaseDefinitionPage<AccidentSeverityDefinitionDto>
      listLabelPropName="label"
      fetchData={AccidentSeverityTypeDefinitionControllerService.getFirmAccidentSeverityDefinitions}
      FormDialog={AccidentSeverityEditDialog}
      onDelete={AccidentSeverityTypeDefinitionControllerService.deleteFirmAccidentSeverity}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
