import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import { BodyMeasurementsModel, deleteBodyMeasurements } from 'api/hs/examination/body-measurements';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';

import { BodyMeasurementsContext } from '../../body-measurements.context';

interface Props {
  data: BodyMeasurementsModel;
}

export default function Action({ data }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { setSelectedData, setEditDialogOpened, reload } = useContext(BodyMeasurementsContext);
  const { openWarnDialog } = useContext(DialogContext);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const { showMessage } = useContext(AlertContext);
  const { employeeId } = useParams<{ employeeId: string }>();

  const openDeleteDialog = useCallback(async () => {
    closeMenu();

    const submitted = await openWarnDialog({
      text: `${data.description} - açıklamalı kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteBodyMeasurements(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [closeMenu, data, reload, openWarnDialog, showMessage]);

  const openEditDialog = useCallback(() => {
    setSelectedData(data);
    setEditDialogOpened(true);
    closeMenu();
  }, [closeMenu, setEditDialogOpened, setSelectedData, data]);

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        <MenuItem onClick={openDeleteDialog}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Sil</Typography>
        </MenuItem>
        <MenuItem onClick={openEditDialog}>
          <ListItemIcon>
            <EditIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">DÜZENLE</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`/saglik-gozetimi/HemsirelikPrint/VucutPrint/${employeeId}/${data?.id}`);
            closeMenu();
          }}>
          <ListItemIcon>
            <PrintIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit">Yazdır</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
