/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccidentReviewerDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    guestId?: string;
    accidentId?: string;
    employeeId?: string;
    reviewerType: AccidentReviewerDto.reviewerType;
}

export namespace AccidentReviewerDto {

    export enum reviewerType {
        LEAD = 'LEAD',
        USER = 'USER',
        READONLY = 'READONLY',
    }


}
