import { createContext } from 'react';

export interface PaginationContextType {
  size: number;
  onSizeChange(size: number): void;
  page: number;
  onPageChange(page: number): void;
  totalPage: number;
}

export const PaginationContext = createContext<PaginationContextType>(null);
