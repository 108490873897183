import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailIcon from '@material-ui/icons/Mail';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useCallback, useContext, useState } from 'react';

import { deleteReferral, previewReferral } from 'api/hs/examination/referrals';
import DeleteDialogIcon from 'Common/DeleteDialogIcon';
import { AlertContext } from 'context/alert.context';
import { DialogContext } from 'context/dialog.context';
import { ReferralsModel } from 'model/employee/examination/referrals.model';

import { printFile, stopPropagation } from '../../../../../../utils';
import { ReferralsContextContext } from '../../referrals.context';
import {exportEmployeeReferral} from "../../../../../../api/hs/employee";
import {findByKey} from "@thalesrc/js-utils";
import {ResourceContext} from "../../../../../../context/resource.context";
import {EnumContext} from "../../../../../../context/enum.context";
const BASE_URL = process.env.REACT_APP_HS_API_BASE_URL;

interface Props {
  data: ReferralsModel;
}

export default function Action({ data }: Props) {
  const { referralSection } = useContext(ResourceContext);
  const { hospitalTypes } = useContext(EnumContext);
  const { setSelectedData, setEditDialogOpened, reload, setEmailDialogOpened, setPreviewData, setPreviewDialogOpened } = useContext(
    ReferralsContextContext
  );
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const edit = useCallback(
    item => () => {
      setSelectedData(item);
      setEditDialogOpened(true);
      closeMenu();
    },
    [setSelectedData, setEditDialogOpened, closeMenu]
  );

  const openEmailDialog = useCallback(async () => {
    closeMenu();
    setSelectedData(data);
    setEmailDialogOpened(true);
  }, [setEmailDialogOpened, setSelectedData, data, closeMenu]);

  const handleDelete = useCallback(async () => {
    const submitted = await openWarnDialog({
      text: `${data.description} - açıklamalı kaydını silmek istediğinize emin misiniz ?`,
      Icon: <DeleteDialogIcon />,
    });
    try {
      if (submitted) {
        await deleteReferral(data.id);
        showMessage('Silme işlemi başarılı.', 'success', 'info');
        reload();
      }
    } catch (e) {
      showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
    }
  }, [data, reload, openWarnDialog, showMessage]);

  const exportFile = useCallback(async () => {
    var name = data?.hospitalTypeDefinitionId
          ? findByKey(hospitalTypes, 'id', data?.hospitalTypeDefinitionId)?.label +
          ' - ' +
          findByKey(referralSection, 'id', data?.referralSectionDefinitionId)?.name
          : findByKey(referralSection, 'id', data?.referralSectionDefinitionId)?.name
    await exportEmployeeReferral(data?.id, name);
    closeMenu();
  }, [data, closeMenu]);

  const onPreview = useCallback(async () => {
    const htmlData = await previewReferral(data?.id);
    setPreviewData(htmlData);
    closeMenu();
    setPreviewDialogOpened(true);
  }, [data, setPreviewDialogOpened, setPreviewData, closeMenu]);

  return (
    <>
      <span onClick={stopPropagation}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">Sil</Typography>
          </MenuItem>
          <MenuItem onClick={edit(data)}>
            <ListItemIcon>
              <EditIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">DÜZENLE</Typography>
          </MenuItem>
          <MenuItem onClick={openEmailDialog}>
            <ListItemIcon>
              <MailIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">MAİL GÖNDER</Typography>
          </MenuItem>
          <MenuItem onClick={exportFile}>
            <ListItemIcon>
              <GetAppIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">İNDİR</Typography>
          </MenuItem>
          <MenuItem onClick={onPreview}>
            <ListItemIcon>
              <VisibilityIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">ÖNİZLEME</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              printFile(`/referrals/${data?.id}/detail-view/export?type=HTML`);
              closeMenu();
            }}>
            <ListItemIcon>
              <PrintIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="inherit">YAZDIR</Typography>
          </MenuItem>
        </Menu>
      </span>
      {/*<PrintReferral data={data} />*/}
      {/*<IconButton onClick={edit(data)}>*/}
      {/*  <EditIcon />*/}
      {/*</IconButton>*/}
      {/*<IconButton onClick={handleDelete}>*/}
      {/*  <DeleteIcon />*/}
      {/*</IconButton>*/}
      {/*<IconButton onClick={openEmailDialog}>*/}
      {/*  <MailIcon />*/}
      {/*</IconButton>*/}
      {/*<IconButton onClick={exportFile}>*/}
      {/*  <GetAppIcon />*/}
      {/*</IconButton>*/}
      {/*<IconButton onClick={onPreview}>*/}
      {/*  <VisibilityIcon />*/}
      {/*</IconButton>*/}
    </>
  );
}
