import React, { useContext } from 'react';

import Table from 'Common/Table/Default';

import { TrainingPlanningListContext } from '../../training-planning-list.context';
import { CELL } from './columns';

export default function PlanningTraining() {
  const { employeeTrainingListPlanning } = useContext(TrainingPlanningListContext);
  return <Table data={employeeTrainingListPlanning.filter(trainers => trainers?.isCompleted === true)} columns={CELL} />;
}
