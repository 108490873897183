import { Card, CardContent, Divider, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Done, MoreVert, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';

import { getPregnancyCounts, hideWidget } from '../../../api/hs/dashboard/widget/widget';
import { PregnancyCounts, WidgetType } from '../../../api/hs/dashboard/widget/widget.payload';
import { dateRangeOptions } from '../../../utils/use-date-range-state';
import colors from '../colors';
import { DialogContext } from '../dialog.context';
import DropdownMenuContainer from './Menu/DropdownMenuContainer';

export default ({ reload }) => {
  const {
    pregnancy: { open },
  } = useContext(DialogContext);
  const [counts, setCounts] = useState<PregnancyCounts>();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    getPregnancyCounts().then(setCounts);
  }, []);

  const hide = () => {
    hideWidget({ widgetType: WidgetType.PREGNANCY }).then(reload);
  };

  const handleClickMoreButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <Card key="pregnancy" className="m-1 dashboard-stats-card" style={{ backgroundColor: colors.pregnancy }}>
      <CardContent>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item sm={3} onClick={open}>
            <h3>Gebe</h3>
          </Grid>
          <Grid item sm={7} onClick={open}>
            <h3>{counts?.pregnant || 0}</h3>
          </Grid>
          <Grid item sm={2}>
            <IconButton onClick={handleClickMoreButton}>
              <MoreVert />
            </IconButton>
            <DropdownMenuContainer anchor={menuAnchor} setAnchor={setMenuAnchor} hide={hide} />
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', flexDirection: 'column' }} container onClick={open}>
          <Grid item sm={10}>
            <h3>Emziren : {counts?.breastfeeding || 0}</h3>
          </Grid>
          <Divider />
          <p>Doğum İzni : {counts?.maternityLeave || 0}</p>
        </Grid>
      </CardContent>
    </Card>
  );
};
