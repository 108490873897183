import { Grid, Paper, Typography } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { getAccidentList, getNearMissesList } from '../../api/hs/accident/accident';
import { getActionList } from '../../api/hs/actions';
import { getCommitteeMeetingsList } from '../../api/hs/committee/committee-meetings';
import { getByIdCompany } from '../../api/hs/definitions/workplace-definition/company';
import { geExposureList } from '../../api/hs/definitions/workplace-definition/exposure/ApiExposure';
import { getEmployees } from '../../api/hs/employee-filter';
import { getAnalysisList } from '../../api/hs/examination/analysis/analysis';
import { getPrescriptionList } from '../../api/hs/examination/prescription/prescription';
import { getTrainingList } from '../../api/hs/trainings/training-view';
import { getExaminationList } from '../../api/hs/TransactionAndLists/ExaminationList';
import { getNurseOperationList } from '../../api/hs/TransactionAndLists/NurseOperationList';
import { getReferralList } from '../../api/hs/TransactionAndLists/ReferralList';
import { getReportList } from '../../api/hs/TransactionAndLists/ReportList';
import { getVaccineList } from '../../api/hs/TransactionAndLists/VaccineList';
import Button from '../../Common/Button';
import AutoComplete from '../../Common/Form/AutoComplete';
import Checkbox from '../../Common/Form/Checkbox';
import DateInput from '../../Common/Form/DateInput';
import Form, { FormRef } from '../../Common/Form/Form';
import IndicatorWrapper from '../../Common/IndicatorWrapper';
import { HSTableProps } from '../../Common/Table/HSTable/hs-table.props';
import Toolbar from '../../Common/Toolbar';
import { SelectOptionsContext } from '../../context/select-options.context';
import { UserContext } from '../../context/user';
import { TableDataResult } from '../../model';
import { UserRole } from '../../model/user-role.enum';
import { dateToInputFormat, useAsyncEffect, useTriggerRender } from '../../utils';
import ActivityReports from './ActivityReports';
import QueryDomains from './QueryDomains';

export interface DomainType {
  type: QueryDomains;
  name: string;
  label: string;
  tableFieldTitles?: string[];
  role: UserRole[];
  rawData?: any[];
  data?: any[];
  formattedData?: any[];
  chartRef?: any;
  fetcher?: (page: number, size: number, sorting: any, extraArgs: Record<string, any>) => Promise<TableDataResult<any>>;
}

export const queryOptionsMap = new Map<QueryDomains, DomainType>([
  [
    QueryDomains.VACCINE,
    {
      type: QueryDomains.VACCINE,
      name: 'vaccine',
      label: 'Aşı',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Aşı Türü', 'Adet'],
    },
  ],
  [
    QueryDomains.EXAMINATION,
    {
      type: QueryDomains.EXAMINATION,
      name: 'examination',
      label: 'Muayene',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Muayene Türü', 'Adet'],
    },
  ],
  [
    QueryDomains.NURSE_OPERATION,
    {
      type: QueryDomains.NURSE_OPERATION,
      name: 'nurseOperation',
      label: 'Hemşirelik',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Hemşirelik İşlem Türü', 'Adet'],
    },
  ],
  [
    QueryDomains.PRESCRIPTION,
    {
      type: QueryDomains.PRESCRIPTION,
      name: 'prescription',
      label: 'Reçete',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Reçete Adı', 'Adet'],
    },
  ],
  [
    QueryDomains.ANALYSIS,
    {
      type: QueryDomains.ANALYSIS,
      name: 'analysis',
      label: 'Tahlil',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Tahlil Adı', 'Adet'],
    },
  ],
  [
    QueryDomains.REPORT,
    {
      type: QueryDomains.REPORT,
      name: 'report',
      label: 'Rapor',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Rapor Türü', 'Adet'],
    },
  ],

  [
    QueryDomains.ACCIDENT,
    {
      type: QueryDomains.ACCIDENT,
      name: 'accident',
      label: 'Kaza',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Kaza Türü', 'Adet', 'Kazazede Sayısı'],
    },
  ],
  [
    QueryDomains.REFERRAL,
    {
      type: QueryDomains.REFERRAL,
      name: 'referral',
      label: 'Sevk',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Bölüm', 'Adet'],
    },
  ],
  [
    QueryDomains.NEAR_MISS,
    {
      type: QueryDomains.NEAR_MISS,
      name: 'nearMiss',
      label: 'Ramak Kala',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Ramak Kala Türü', 'Adet'],
    },
  ],
  [
    QueryDomains.EXPOSURE,
    {
      type: QueryDomains.EXPOSURE,
      name: 'exposure',
      label: 'Maruziyet',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Maruziyet Adı', 'Adet'],
    },
  ],
  [
    QueryDomains.ACTION,
    {
      type: QueryDomains.ACTION,
      name: 'action',
      label: 'Aksiyon',
      role: [UserRole.PHYSICIAN, UserRole.NURSE],
      tableFieldTitles: ['Aksiyon Kaynağı', 'Adet', 'Yapılacak', 'Yapılamaz', 'Yapılıyor', 'Yapıldı'],
    },
  ],
  [
    QueryDomains.TRAINING,
    {
      type: QueryDomains.TRAINING,
      name: 'training',
      label: 'Eğitim',
      role: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      tableFieldTitles: ['Eğitim Adı', 'Katılımcı Sayısı', 'Alınan Eğitim (Adet)', 'Eğitim Süresi'],
    },
  ],
  [
    QueryDomains.COMMITTEE_MEETINGS,
    {
      type: QueryDomains.COMMITTEE_MEETINGS,
      name: 'committeeMeetings',
      label: 'Isg Kurulu',
      role: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
      tableFieldTitles: ['Durumu', 'Sayısı'],
    },
  ],
]);

const selectedOptions = new Set();

const dateMinusOneYear: Date = new Date();
dateMinusOneYear.setFullYear(new Date().getFullYear() - 1);

function getSelectedOptions() {
  return selectedOptions;
}

const Index = () => {
  const [dataDisplayOption, setDataDisplayOption] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { companies } = useContext(SelectOptionsContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const reRender = useTriggerRender();
  const { user } = useContext(UserContext);

  queryOptionsMap.get(QueryDomains.EXAMINATION).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getExaminationList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra?.payload
    );
  }, []);

  queryOptionsMap.get(QueryDomains.COMMITTEE_MEETINGS).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getCommitteeMeetingsList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra ?? { plannedMaxDate: extra.payload.minDate, plannedMinDate: extra.payload.minDate }
    );
  }, []);
  const fetchFemaleEmployeeList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        gender: 1,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchMaleEmployeeList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        gender: 0,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchChildrenEmployeeList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        ageMax: '18',
        ageMin: '1',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchDisabledEmployeeList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        disabled: true,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchEmployeeHasChronicDiseasesList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        hasChronicDisease: true,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchEmployeeHasAllergyList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        hasAllergy: true,
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  const fetchElderlyEmployeeList = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getEmployees({
      filter: {
        companyIds: extra.payload.companyIds,
        ageMax: '100',
        ageMin: '60',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 1,
        order: null,
      },
    });
  }, []);
  queryOptionsMap.get(QueryDomains.VACCINE).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getVaccineList(page, size, sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`);
  }, []);
  queryOptionsMap.get(QueryDomains.REPORT).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getReportList(page, size, sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`);
  }, []);
  queryOptionsMap.get(QueryDomains.REFERRAL).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getReferralList(page, size, sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`);
  }, []);
  queryOptionsMap.get(QueryDomains.ACCIDENT).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getAccidentList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.NEAR_MISS).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getNearMissesList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.EXPOSURE).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return geExposureList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.PRESCRIPTION).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getPrescriptionList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.ANALYSIS).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getAnalysisList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.NURSE_OPERATION).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getNurseOperationList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  queryOptionsMap.get(QueryDomains.ACTION).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getActionList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);
  queryOptionsMap.get(QueryDomains.TRAINING).fetcher = useCallback<HSTableProps<any>['fetcher']>((page, size, sorting, extra) => {
    return getTrainingList(
      page,
      size,
      sorting === false ? null : `${sorting.id as string}-${sorting.direction.toUpperCase()}`,
      extra.filters ?? {}
    );
  }, []);

  const companyDetails = getByIdCompany('0297a2de-4fea-42ab-bce9-b479926a212b');
  const [collapsed, setCollapsed] = useState(false);

  const chbHandleChange = (type, event) => {
    if (event.target.checked) {
      selectedOptions.add(type);
    } else {
      selectedOptions.delete(type);
    }
  };

  const getData = useCallback(
    async (payload: { minDate: string; maxDate: string; companyIds: string[] }) => {
      setLoading(true);

      const promises = [];

      for (const [key, value] of queryOptionsMap) {
        if (selectedOptions.has(key)) {
          promises.push(value.fetcher(0, 2147483647, false, { payload }));
        }
      }

      await Promise.all([
        getByIdCompany(payload.companyIds),
        fetchFemaleEmployeeList(0, 0, false, { payload }),
        fetchMaleEmployeeList(0, 0, false, { payload }),
        fetchChildrenEmployeeList(0, 2147483647, false, { payload }),
        fetchElderlyEmployeeList(0, 2147483647, false, { payload }),
        fetchEmployeeHasAllergyList(0, 2147483647, false, { payload }),
        fetchDisabledEmployeeList(0, 2147483647, false, { payload }),
        fetchEmployeeHasChronicDiseasesList(0, 2147483647, false, { payload }),
        // fetchPlannedCommitteeMeetingList(0, 2147483647, false, {payload}),
        // fetchDoneCommitteeMeetingList(0, 2147483647, false, {payload}),
        ...promises,
      ]).then(item => {
        // Log the data to the console
        // You would do something with both sets of data here
        const result = {};
        let index = 0;

        result.companyDetails = item[index];
        result.femaleEmployeeList = item[++index];
        result.maleEmployeeList = item[++index];
        result.childrenEmployeeList = item[++index];
        result.elderlyEmployeeList = item[++index];
        result.employeeHasAllergyList = item[++index];
        result.disabledEmployeeList = item[++index];
        result.employeeHasChronicDiseasesList = item[++index];
        // result.plannedCommitteeMeetings = item[++index];
        // result.doneCommitteeMeetings = item[++index];

        for (const [key, value] of queryOptionsMap) {
          if (selectedOptions.has(key)) {
            value.rawData = item[++index]?.content;
          }
        }
        setData(result);
        setLoading(false);
      });
    },
    [
      fetchChildrenEmployeeList,
      fetchDisabledEmployeeList,
      fetchElderlyEmployeeList,
      fetchEmployeeHasAllergyList,
      fetchEmployeeHasChronicDiseasesList,
      fetchFemaleEmployeeList,
      fetchMaleEmployeeList,
    ]
  );
  useEffect(() => {
    queryOptionsMap.forEach(queryOption => {
      if (queryOption.role.some(item => user?.roles.includes(item))) {
        selectedOptions.add(queryOption.type);
      }
    });
  }, [user?.roles]);

  const submit = useCallback(
    async values => {
      const payload = {
        minDate: values?.minDate,
        maxDate: values?.maxDate,
        companyIds: [values?.companyId],
      };
      try {
        const res = await getData(payload);
        setDataDisplayOption(values);
        return res;
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.log(e.message);
      }
    },
    [getData]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }

    await defer();
    queryOptionsMap?.forEach(item => {
      formRef?.current?.methods?.setValue(item?.name, true);
    });

    formRef?.current?.methods?.trigger();
  }, [data]);
  const onChangeForm = useCallback(
    formValues => {
      setDataDisplayOption(formValues);
      reRender();
    },
    [reRender]
  );
  return (
    <div>
      <div>
        <Toolbar color="secondary" className={`py-1`}>
          <Typography variant="h6" color="inherit" className="text-bold">
            Faaliyet Raporu
          </Typography>
        </Toolbar>
        <Grid item xs={12}>
          <Paper>
            <Form onSubmit={submit} className={'m-2'} ref={formRef} onChange={onChangeForm}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <DateInput label="Başlangıç Tarihi" name="minDate" type="date" defaultValue={dateToInputFormat(dateMinusOneYear)} />
                </Grid>
                <Grid item xs={3}>
                  <DateInput label="Bitis Tarihi" name="maxDate" type="date" defaultValue={dateToInputFormat(new Date())} />
                </Grid>
                <Grid item xs={3}>
                  <AutoComplete
                    single
                    name="companyId"
                    options={companies}
                    label="Şirket"
                    renderOption={'checkbox'}
                    defaultValue={'058e0d51-14c9-40c9-8ed1-5b6c768fe6e2'}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={9}>
                  {Array.from(queryOptionsMap.values()).map(
                    queryOption =>
                      queryOption.role.some(item => user?.roles.includes(item)) && (
                        <Checkbox
                          key={queryOption.name}
                          name={queryOption.name}
                          label={queryOption.label}
                          onClick={(e: any) => chbHandleChange(queryOption.type, e)}
                        />
                      )
                  )}
                </Grid>
                <Grid item xs={3}>
                  <div className={'text-right'}>
                    <Button variant="contained" type="submit" color="secondary" className="mb-2 ml-2">
                      {'Rapor Oluştur'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </Paper>
        </Grid>
        <IndicatorWrapper loading={loading} data={data} errorMessage={'Listelenecek  Kayıt Bulunamadı'}>
          <ActivityReports data={data} dataDisplayOption={dataDisplayOption} getSelectedOptions={getSelectedOptions} />
        </IndicatorWrapper>
      </div>
    </div>
  );
};

export default Index;
