import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../../Common/Table/HSTable/hs-table.context';
import { CommitteeMeetingsContext } from '../../committee-meetings.context';
import CommitteeMeetingForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData } = useContext(CommitteeMeetingsContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setEditDialogOpened(false);
    },
    [setEditDialogOpened, refresh]
  );

  return <CommitteeMeetingForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
