import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router';

import {
  AnalysisAggregate,
  getAllAnalysisByEmployeeIdAndExaminationId,
  getAnalysisDetail,
  getAudiometry,
  getParameters,
  getPulmonary,
  ParameterModel,
} from 'api/hs/examination/analysis';
import { AnalysisDetailResultAggregate, AudiometryEarResult, AudiometryResult } from 'api/hs/examination/analysis';
import { PulmonaryResultAggregate } from 'api/hs/examination/analysis/pulmonary';
import { useAsyncEffect } from 'utils';
import { dateSort } from 'utils';

import { EnumContext } from '../../../../context/enum.context';
import Analysis from './analysis';
import { AnalysisContext } from './analysis.context';
import { Category } from './analysis/category.enum';

export default function Index() {
  const [analysis, setAnalysis] = useState<AnalysisAggregate[]>([]);
  const [loading, setLoading] = useState(true);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [graphDialogOpened, setGraphDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<AnalysisAggregate>(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [parameters, setParameters] = useState<ParameterModel[]>(null);
  const [selectedGraphData, setSelectedGraphData] = useState<AnalysisAggregate[]>(null);
  const { employeeId, examinationId } = useParams<{ employeeId: string; examinationId: string }>();
  const { analysisCategoryDefinitions } = useContext(EnumContext);

  const fetch = useCallback(async () => {
    const resultWithAnalysisDetailList = [];
    const result = await getAllAnalysisByEmployeeIdAndExaminationId(employeeId, examinationId);

    for (const item of result) {
      let analysisDetailList: AnalysisDetailResultAggregate[] = [];
      let audiometryResult: AudiometryResult = null;
      let pulmonaryResult: PulmonaryResultAggregate = null;
      let leftEar: AudiometryEarResult = null;
      let rightEar: AudiometryEarResult = null;

      if (findByKey(analysisCategoryDefinitions, 'id', item?.categoryDefinitionId)?.label === Category.Audiometry) {
        try {
          audiometryResult = await getAudiometry(item.id);
          if (audiometryResult?.leftEar && Object.values(audiometryResult?.leftEar)) {
            leftEar = {
              khz250: audiometryResult?.leftEar?.khz250,
              khz500: audiometryResult?.leftEar?.khz500,
              khz1000: audiometryResult?.leftEar?.khz1000,
              khz2000: audiometryResult?.leftEar?.khz2000,
              khz3000: audiometryResult?.leftEar?.khz3000,
              khz4000: audiometryResult?.leftEar?.khz4000,
              khz6000: audiometryResult?.leftEar?.khz6000,
              khz8000: audiometryResult?.leftEar?.khz8000,
              sso: audiometryResult?.leftEar?.sso,
            };
          }
          if (audiometryResult?.rightEar && Object.values(audiometryResult?.rightEar)) {
            rightEar = {
              khz250: audiometryResult?.rightEar?.khz250,
              khz500: audiometryResult?.rightEar?.khz500,
              khz1000: audiometryResult?.rightEar?.khz1000,
              khz2000: audiometryResult?.rightEar?.khz2000,
              khz3000: audiometryResult?.rightEar?.khz3000,
              khz4000: audiometryResult?.rightEar?.khz4000,
              khz6000: audiometryResult?.rightEar?.khz6000,
              khz8000: audiometryResult?.rightEar?.khz8000,
              sso: audiometryResult?.rightEar?.sso,
            };
          }
        } catch (error) {
          console.log('Error in getAudiometry', error); // tslint:disable-line:no-console
        }
      } else if (findByKey(analysisCategoryDefinitions, 'id', item?.categoryDefinitionId)?.label === Category.Pulmonary) {
        try {
          const pulmonaryResponse = await getPulmonary(item.id);
          pulmonaryResult = Object.assign({}, pulmonaryResponse);
          const parametersByCategoryDefinitionId = await getParameters(item.categoryDefinitionId);
          pulmonaryResult?.pftDetails?.forEach(d => {
            const actualParameter = parametersByCategoryDefinitionId?.find(p => p.id === d.parameterDefinitionId);
            d.parameter = actualParameter?.parameter;
            d.analysisParameterUnit = actualParameter?.analysisParameterUnit;
          });
        } catch (error) {
          console.log('Error in getPulmonary', error); // tslint:disable-line:no-console
        }
      } else {
        analysisDetailList = await getAnalysisDetail(item.id);
        const parametersByCategoryDefinitionId = await getParameters(item.categoryDefinitionId);
        analysisDetailList.forEach(analysisItem => {
          const parameterItem = parametersByCategoryDefinitionId?.find(param => {
            return param?.id === analysisItem?.parameterDefinitionId;
          });
          analysisItem.parameter = parameterItem?.parameter;
          analysisItem.analysisParameterUnit = parameterItem?.analysisParameterUnit;
          analysisItem.max = parameterItem?.max;
          analysisItem.min = parameterItem?.min;
        });
      }

      resultWithAnalysisDetailList.push({
        ...item,
        pulmonaryResult,
        detailsList: analysisDetailList?.length > 0 ? analysisDetailList : [],
        audiometry: { leftEar, rightEar, audioCategories: audiometryResult?.audioCategories },
      });
    }

    setAnalysis(dateSort(resultWithAnalysisDetailList));
    setLoading(false);
  }, [employeeId, examinationId, setAnalysis, setLoading, analysisCategoryDefinitions]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <AnalysisContext.Provider
      value={{
        analysis,
        parameters,
        setParameters,
        reload: fetch,
        loading,
        setGraphDialogOpened,
        graphDialogOpened,
        editDialogOpened,
        setEditDialogOpened,
        setNewDialogOpened,
        newDialogOpened,
        setLoading,
        selectedData,
        setSelectedData,
        setSelectedGraphData,
        selectedGraphData,
      }}>
      <Analysis />
    </AnalysisContext.Provider>
  );
}
