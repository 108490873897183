import { Divider, ListItem, ListItemText } from '@material-ui/core';
import React, { useContext } from 'react';

import AutoComplete from 'Common/Form/AutoComplete';
import { SelectOptionsContext } from 'context/select-options.context';

import { EmployeeSearchResult, getEmployeesByIds, searchEmployee } from '../../../../../api/hs/employee';
import { SelectOption } from '../../../../../Common/Form/Select';

function mapEmployeeToOptions(types: EmployeeSearchResult[]): SelectOption<string>[] {
  return types.map<SelectOption<string>>(({ id, name }) => ({ text: name, value: id }));
}

async function employeeOptionFetcher(value: string) {
  const res = await searchEmployee(value);

  return mapEmployeeToOptions(res);
}

async function employeeLabelFetcher(values: string[]) {
  const res = await getEmployeesByIds(values);

  return mapEmployeeToOptions(res);
}

export default function ProfessionalInfo() {
  const { workingTypes, companies, departments, locations, workStations, positions, exposures } = useContext(SelectOptionsContext);

  return (
    <>
      <ListItem>
        <ListItemText primary="Mesleki Bilgiler" />
      </ListItem>
      <Divider className="mb-1" />
      <ListItem className="py-0">
        <AutoComplete name="workingTypeIds" options={workingTypes} renderOption="checkbox" label="Çalışma Şekli" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="companyIds" options={companies} renderOption="checkbox" label="Şirket" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="departmentDefinitionIds" options={departments} renderOption="checkbox" label="Departman / Bölüm" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="locationDefinitionIds" options={locations} renderOption="checkbox" label="Lokasyon" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="stationDefinitionIds" options={workStations} renderOption="checkbox" label="Çalışma İstasyonları" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="positionDefinitionIds" options={positions} renderOption="checkbox" label="Görev Pozisyon" />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete
          name="managerId"
          label="Bağlı olduğu yönetici"
          optionFetcher={employeeOptionFetcher}
          labelFetcher={employeeLabelFetcher}
          single
        />
      </ListItem>
      <ListItem className="py-0">
        <AutoComplete name="exposureDefinitionIds" options={exposures} renderOption="checkbox" label="Maruziyet" />
      </ListItem>
    </>
  );
}
