/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShiftDefinitionDto } from '../models/ShiftDefinitionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ShiftDefinitionControllerService {

    /**
     * @param definitionId
     * @param requestBody
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static updateShiftDefinition(
        definitionId: string,
        requestBody: ShiftDefinitionDto,
    ): CancelablePromise<ShiftDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/shift-definitions/${definitionId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param definitionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteShiftDefinition(
        definitionId: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/shift-definitions/${definitionId}`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static updateFirmShiftDefinition(
        id: string,
        requestBody: ShiftDefinitionDto,
    ): CancelablePromise<ShiftDefinitionDto> {
        return __request({
            method: 'PUT',
            path: `/firms/shift-definitions/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteFirmShiftDefinition(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/firms/shift-definitions/${id}`,
        });
    }

    /**
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static getAllShiftDefinitions(): CancelablePromise<Array<ShiftDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/shift-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static saveShiftDefinition(
        requestBody: ShiftDefinitionDto,
    ): CancelablePromise<ShiftDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/shift-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static getFirmShiftDefinitions(): CancelablePromise<Array<ShiftDefinitionDto>> {
        return __request({
            method: 'GET',
            path: `/firms/shift-definitions`,
        });
    }

    /**
     * @param requestBody
     * @returns ShiftDefinitionDto OK
     * @throws ApiError
     */
    public static saveFirmShiftDefinition(
        requestBody: ShiftDefinitionDto,
    ): CancelablePromise<ShiftDefinitionDto> {
        return __request({
            method: 'POST',
            path: `/firms/shift-definitions`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}