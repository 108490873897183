import React, { useCallback, useContext } from 'react';

import { ClassificationDefinitionsContext } from '../../classification-definitions.context';
import ClassificationDefinitionsForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(ClassificationDefinitionsContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <ClassificationDefinitionsForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
