import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React from 'react';

import { useAsyncEffect } from 'utils';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  sideGrid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto',
  },
  dialogPaper: {
    width: '50vw',
  },
  dialogScroll: {
    alignItems: 'flex-start',
  },
}));

interface Props {
  open: boolean;

  htmlData: string;

  onClose(success: boolean): void;
}

export default function EmailForm({ open, onClose, htmlData }: Props) {
  const classes = useStyles();

  useAsyncEffect(async () => {
    if (!open) {
      return;
    }
    defer();
  }, [open]);

  return (
    <Dialog
      PaperProps={{ style: { maxWidth: 650 } }}
      open={open}
      keepMounted
      onClose={onClose.bind(null, false)}
      classes={{ paper: classes.dialogPaper, scrollPaper: classes.dialogScroll }}>
      <DialogTitle className={classes.dialogTitle}>Önizleme</DialogTitle>
      <DialogContent>
        <iframe width="100%" height="500" title="Preview Data" srcDoc={htmlData} style={{ border: 'none' }} />
      </DialogContent>
    </Dialog>
  );
}
