import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useContext } from 'react';

import { deleteMedicalIntervention } from 'api/hs/examination/medical-intervention';
import { DialogContext } from 'context/dialog.context';
import { MedicalInterventionsModel } from 'model/employee/examination/medical-interventions.model';

import DeleteDialogIcon from '../../../../../../Common/DeleteDialogIcon';
import { AlertContext } from '../../../../../../context/alert.context';
import { MedicalInterventionContext } from '../../medical-intervention.context';

interface Props {
  data: MedicalInterventionsModel;
}

export default function Action({ data }: Props) {
  const { setSelectedData, setEditDialogOpened, reload } = useContext(MedicalInterventionContext);
  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);

  const edit = useCallback(
    item => () => {
      setSelectedData(item);
      setEditDialogOpened(true);
    },
    [setSelectedData, setEditDialogOpened]
  );

  const handleDelete = useCallback(
    async dataMedicalIntervention => {
      const submitted = await openWarnDialog({
        text: `${dataMedicalIntervention?.description} - kaydını silmek istediğinize emin misiniz ?`,
        Icon: <DeleteDialogIcon />,
      });
      try {
        if (submitted) {
          await deleteMedicalIntervention(dataMedicalIntervention.id);
          showMessage('Silme işlemi başarılı.', 'success', 'info');
          reload();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error', 'info');
      }
    },
    [reload, openWarnDialog, showMessage]
  );

  return (
    <>
      <IconButton onClick={edit(data)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleDelete(data)}>
        <DeleteIcon />
      </IconButton>
    </>
  );
}
