import { tryCatch } from '@thalesrc/js-utils';

import HSApi from 'api/hs/HSApi';

import { SaveAllergyPayload, UpdateAllergyPayload } from './allergies.payload';
import { GetAllergiesResult } from './allergies.result';

export async function getAllergies(employeeId: string, examinationId?: string): Promise<GetAllergiesResult> {
  let res;
  try {
    if (examinationId) {
      res = await HSApi.get<GetAllergiesResult>(`/allergies?employeeId=${employeeId}&examinationId=${examinationId}`);
    } else {
      res = await HSApi.get<GetAllergiesResult>(`/allergies?employeeId=${employeeId}`);
    }
  } catch (e) {
    console.error(e); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function saveAllergies(body: SaveAllergyPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.post('/allergies', body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function updateAllergies(id: string, body: UpdateAllergyPayload): Promise<unknown> {
  const [err, res] = await tryCatch(HSApi.put(`/allergies/${id}`, body));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}

export async function deleteAllergies(id: string) {
  const [err, res] = await tryCatch(HSApi.delete(`/allergies/${id}`));

  if (err) {
    console.log(err); // tslint:disable-line:no-console
  }

  return res.data;
}
