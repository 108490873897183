import { Tooltip } from '@material-ui/core';
import { findByKey } from '@thalesrc/js-utils';
import React, { useContext, useState } from 'react';

import { getAllergies, getChronicDisease } from 'api/hs/examination';
import { EnumContext } from 'context/enum.context';
import { ResourceContext } from 'context/resource.context';
import { Employee } from 'model';
import { useAsyncEffect } from 'utils';

import { getEmployeeExposures } from '../../../api/hs/employee';
import { Icd10CacheContext } from '../../../Common/Form/Icd10Selection/icd10.context';
import { UserContext } from '../../../context/user';
import { UserRole } from '../../../model/user-role.enum';
import { InfoListItem } from './info-list-item.model';
import InfoList from './InfoList';

const PROFESSIONAL_INFO_LIST: InfoListItem[] = [
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Şirket',
    Render({ employee: { companyId } }) {
      const { companies } = useContext(ResourceContext);

      return (
        <>
          <Tooltip title={companies.find(({ id }) => id === companyId)?.companyName || ''}>
            <span>{companies.find(({ id }) => id === companyId)?.companyName?.slice(0, 20) + '...'}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Sicil No',
    Render: ({ employee: { registryNumber } }) => <>{registryNumber?.replaceAll('_', '')}</>,
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'İş Telefonu',
    Render: ({ employee: { gsmNumber } }) => <>{gsmNumber}</>,
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Çalışma Şekli',
    Render({ employee: { workingType } }) {
      const { workingTypes } = useContext(EnumContext);

      return <>{workingTypes.find(({ id }) => id === workingType)?.label}</>;
    },
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Departman / Bölüm',
    Render({ employee: { departmentId } }) {
      const { departments } = useContext(ResourceContext);

      return <>{departments.find(({ id }) => id === departmentId)?.departmentName}</>;
    },
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Lokasyon',
    Render({ employee: { locationDefinitionId } }) {
      const { locations } = useContext(ResourceContext);

      return <>{locations.find(({ id }) => id === locationDefinitionId)?.label}</>;
    },
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'İş İstasyonu',
    Render({ employee: { stationId } }) {
      const { workStations } = useContext(ResourceContext);

      return <>{workStations.find(({ id }) => id === stationId)?.stationName}</>;
    },
  },
  {
    visibleTo: [UserRole.OSS, UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Görev / Pozisyon',
    Render({ employee: { positionId } }) {
      const { positions } = useContext(ResourceContext);

      return <>{positions.find(({ id }) => id === positionId)?.positionName}</>;
    },
  },
  {
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    label: 'Maruziyetler',
    Render({ employee: { exposureId, id } }) {
      const { exposures } = useContext(ResourceContext);
      const [exposureIds, setExposureIds] = useState(exposureId || []);

      useAsyncEffect(async () => {
        if (!exposureId) {
          const res = await getEmployeeExposures(id);
          setExposureIds(res.map(({ exposureDefinitionId }) => exposureDefinitionId));
        } else {
          setExposureIds(exposureId);
        }
      }, [exposureId]);

      return (
        <>
          <Tooltip title={exposureIds?.map(exposureIdsData => findByKey(exposures, 'id', exposureIdsData)?.exposureName).join(' , ')}>
            <span>
              {exposureIds
                ? exposureIds
                    ?.map(exposureDataIds => findByKey(exposures, 'id', exposureDataIds)?.exposureName)
                    .join(' , ')
                    ?.slice(0, 20) + '...'
                : null}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE],
    label: 'Alerjiler',
    Render({ employee: { allergiesId, id } }) {
      const { allergies } = useContext(ResourceContext);
      const [allergiesIds, setAllergiesIds] = useState(allergiesId || []);

      useAsyncEffect(async () => {
        if (!allergiesId) {
          const res = await getAllergies(id);
          setAllergiesIds(res.allergyDetails?.map(({ allergyDefinitionId }) => allergyDefinitionId));
        } else {
          setAllergiesIds(allergiesId);
        }
      }, [allergiesId]);

      return <>{allergiesIds?.map(allergyId => findByKey(allergies, 'id', allergyId)?.allergyName).join(' , ')}</>;
    },
  },
  {
    visibleTo: [UserRole.PHYSICIAN, UserRole.NURSE, UserRole.OSS],
    label: 'Kronik Hastalıklar',
    Render({ employee: { chronicDiseaseIcd10s, id } }) {
      const { getOptions, labels } = useContext(Icd10CacheContext);
      const [icd10s, setIcd10s] = useState(chronicDiseaseIcd10s || []);

      getOptions(...icd10s);

      useAsyncEffect(async () => {
        if (!chronicDiseaseIcd10s) {
          const res = await getChronicDisease(id);
          setIcd10s(res.icd10s.map(icd10 => icd10));
        } else {
          setIcd10s(chronicDiseaseIcd10s);
        }
      }, [chronicDiseaseIcd10s]);

      return (
        <>
          <Tooltip title={icd10s?.map(_id => labels[_id]).join(' , ')}>
            <span>{icd10s.length !== 0 ? labels[icd10s[0]]?.slice(0, 20) + '...' : null}</span>
          </Tooltip>
        </>
      );
    },
  },
];

interface Props {
  employee: Employee;
}

export default function ProfessionalInfo({ employee }: Props) {
  const { user } = useContext(UserContext);

  return (
    <InfoList
      title="Mesleki Bilgiler"
      list={PROFESSIONAL_INFO_LIST.filter(({ visibleTo }) => visibleTo.some(r => user?.roles.includes(r)))}
      employee={employee}
    />
  );
}
