import { Chip, IconButton } from '@material-ui/core';
import Avatars from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext } from 'react';

import IndicatorWrapper from 'Common/IndicatorWrapper';

import { ResourceContext } from '../../../../../context/resource.context';
import { useDateFormat } from '../../../../../utils';
import { PpeNewOutputContext } from '../../ppe-new-output.context';

function DateField({ date }: { date: Date }) {
  const format = useDateFormat();

  return <>{format(date, 'dd MMMM yyyy')}</>;
}

export default function PpeOutputList() {
  const { ppeOutputsData, setPpeOutputsData } = useContext(PpeNewOutputContext);
  const { ppeDefinitions } = useContext(ResourceContext);

  const onDelete = useCallback(
    item => {
      setPpeOutputsData(ppeOutputsData?.filter(data => data?.id !== item?.id));
    },
    [setPpeOutputsData, ppeOutputsData]
  );

  return (
    <IndicatorWrapper loading={false} data={ppeOutputsData} errorMessage="Listeye Kayıt Eklenmedi.">
      <div style={{ width: '100%', height: '3px', backgroundColor: 'gray' }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="left">
                KKD Tanım
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Zimmet Tarihi
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', color: 'gray' }} align="center">
                Adet
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {ppeOutputsData?.map((rowData, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  <Chip
                    avatar={
                      <Avatars
                        src={`/saglik-gozetimi/public/images/ppe/${findByKey(ppeDefinitions, 'id', rowData?.ppeDefinitionId)?.imagePath}`}
                      />
                    }
                    label={findByKey(ppeDefinitions, 'id', rowData?.ppeDefinitionId)?.label}
                  />
                </TableCell>
                <TableCell align="center">{<DateField date={new Date(rowData?.outputDate)} />}</TableCell>
                <TableCell align="center">{rowData?.count}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => onDelete(rowData)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </IndicatorWrapper>
  );
}
