// tslint:disable:max-classes-per-file

import { findByKey } from '@thalesrc/js-utils';

import { MedicalHistoryDetailResult } from 'api/hs/examination';
import { EnumType } from 'context/enum.context';
import { modelDataMatcher } from 'utils';

function getColorByValue(value: number): string {
  switch (value) {
    case 0:
      return '#469789';
    case 1:
      return '#eb4355';
    default:
      return '#c0c0c0';
  }
}

function getTextByValue(value: number): string {
  switch (value) {
    case 0:
      return 'Hayır';
    case 1:
      return 'Evet';
    default:
      return 'Belirsiz';
  }
}

export class MedicalHistoryDetail {
  public id?: string = null;
  public value: number = null;
  public valueText: string = null;
  public color: string = null;
  public definitionId: number = null;
  public definitionName: string = null;

  constructor(data: Partial<MedicalHistoryDetail> = {}) {
    modelDataMatcher(data, this);
  }

  public static fromEnumAndServerData(_enum: EnumType, data: MedicalHistoryDetailResult[]): MedicalHistoryDetail {
    const pickedData = findByKey(data, 'definitionId', _enum.id);

    return new MedicalHistoryDetail({
      id: pickedData?.id,
      value: pickedData?.value,
      valueText: getTextByValue(pickedData?.value),
      color: getColorByValue(pickedData?.value),
      definitionId: _enum.id,
      definitionName: _enum.label,
    });
  }
}

export class MedicalHistory {
  public description: string = null;
  public medicalHistories: MedicalHistoryDetail[] = [];

  constructor(data: Partial<MedicalHistory> = {}) {
    modelDataMatcher(data, this);
  }
}
