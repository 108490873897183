import React, { useState } from 'react';

import { getTrainingMainGroup, TrainingMainGroupModel } from 'api/hs/definitions/trainings/training-main-group';
import { useFetch } from 'utils';

import TrainingMainGroup from './training-main-group';
import { TrainingMainGroupContext } from './training-main-group.context';

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<TrainingMainGroupModel>(null);

  const { data, fetch } = useFetch(() => getTrainingMainGroup(), [], { setLoading });

  return (
    <TrainingMainGroupContext.Provider
      value={{
        trainingMainGroup: data,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <TrainingMainGroup />
    </TrainingMainGroupContext.Provider>
  );
}
