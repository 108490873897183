/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RiskAssessmentGuestDto } from '../models/RiskAssessmentGuestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RiskAssessmentGuestControllerService {

    /**
     * @param id
     * @returns RiskAssessmentGuestDto OK
     * @throws ApiError
     */
    public static getAllGuests(
        id: string,
    ): CancelablePromise<Array<RiskAssessmentGuestDto>> {
        return __request({
            method: 'GET',
            path: `/risk-assessments/${id}/guests`,
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentGuestDto OK
     * @throws ApiError
     */
    public static saveGuest(
        id: string,
        requestBody: Array<RiskAssessmentGuestDto>,
    ): CancelablePromise<Array<RiskAssessmentGuestDto>> {
        return __request({
            method: 'PUT',
            path: `/risk-assessments/${id}/guests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RiskAssessmentGuestDto OK
     * @throws ApiError
     */
    public static saveRiskAssessmentGuest(
        id: string,
        requestBody: Array<RiskAssessmentGuestDto>,
    ): CancelablePromise<Array<RiskAssessmentGuestDto>> {
        return __request({
            method: 'POST',
            path: `/risk-assessments/${id}/guests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}