/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LocalTime } from './LocalTime';

export type UserEventDto = {
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    deleted?: boolean;
    id?: string;
    description?: string;
    addUserId?: string;
    title?: string;
    color?: string;
    isPublic?: boolean;
    status?: UserEventDto.status;
    startDate?: string;
    startTime?: LocalTime;
    endDate?: string;
    endTime?: LocalTime;
}

export namespace UserEventDto {

    export enum status {
        DELETED = 'DELETED',
        ACTIVE = 'ACTIVE',
        NURSE_OPERATION = 'NURSE_OPERATION',
        ARCHIVED = 'ARCHIVED',
        PASSIVE = 'PASSIVE',
    }


}
