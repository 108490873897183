import { createContext } from 'react';

import { CompanyUserResult } from 'api/hs/definitions/workplace-definition/company-user';
import { CompanyUserModel } from 'model/definitions/workplace-definition/company-user.model';

export interface CompanyUserContextType {
  companyUser: CompanyUserResult[];

  loading: boolean;

  setLoading(loading: boolean): void;

  reload(): void;

  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  selectedData: CompanyUserModel;

  setSelectedData(data: CompanyUserModel): void;
}

export const CompanyUserContext = createContext<CompanyUserContextType>(null);
