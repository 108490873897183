import { IconButton, ListItemIcon, Menu, MenuItem, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { findByKey } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useState } from 'react';

import { deleteIcs10WarningLabels, getIcd10Page, searchIcd10 } from 'api/hs/definitions/health-definitions/icd10';
import { Icd10 as Icd10Model } from 'model/definitions/health-definitions/icd10.model';

import { AlertContext } from '../../context/alert.context';
import { DialogContext } from '../../context/dialog.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import Icd10Medicine from '../BaseIcd10MedicineDefinition';
import { Icd10MedicineContextType, Icd10MedicineListContext, ListItemTemplateProps } from '../BaseIcd10MedicineDefinition/Icd10.context';
import Icd10LabelsWarningForm from './Icd10LabelsWarningForm';

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: 9,
  },
  head: {
    backgroundColor: 'white',
    color: 'gray',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function ItemTableBody({ item }: ListItemTemplateProps<Icd10Model>) {
  const { fetch } = useContext<Icd10MedicineContextType>(Icd10MedicineListContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const openMenu = useCallback((e: React.MouseEvent) => setAnchorEl(e.target as HTMLElement), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { icd10ViewType } = useContext(SelectOptionsContext);

  const { openWarnDialog } = useContext(DialogContext);
  const { showMessage } = useContext(AlertContext);
  const openDeleteDialog = useCallback(
    async deletedItem => {
      const submitted = await openWarnDialog({
        text: `${deletedItem.label} - uyarısını silmek istediğinize emin misiniz ?`,
      });
      try {
        if (submitted) {
          await deleteIcs10WarningLabels(deletedItem.labelId);
          closeMenu();
          showMessage('Silme işlemi başarılı.', 'success');
          await fetch();
        }
      } catch (e) {
        showMessage('Silme işleminde sorun oluştu.', 'error');
      }
    },
    [fetch, openWarnDialog, showMessage, closeMenu]
  );
  const onEdit = useCallback(
    editedItem => {
      setOpen(true);
      setSelectedData(editedItem);
      closeMenu();
    },
    [setOpen, setSelectedData, closeMenu]
  );
  const onClose = useCallback(async () => {
    setOpen(false);
    setSelectedData(null);
    closeMenu();
    await fetch();
  }, [setOpen, setSelectedData, fetch, closeMenu]);

  return (
    <>
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left">{item.id}</StyledTableCell>
        <StyledTableCell align="left">{item.code}</StyledTableCell>
        <StyledTableCell align="left">{item.icd10Name}</StyledTableCell>
        <StyledTableCell align="left">
          {!item?.label && !item?.viewType ? null : `${item?.label} (${findByKey(icd10ViewType, 'value', item?.viewType)?.text})`}
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
            <MenuItem onClick={() => onEdit(item)}>
              <ListItemIcon>
                <IconButton edge="end" aria-label="edit">
                  <EditIcon fontSize={'medium'} />
                </IconButton>{' '}
              </ListItemIcon>
              <Typography variant="inherit">{item?.label ? 'UYARI DÜZENLE' : 'UYARI EKLE'}</Typography>
            </MenuItem>
            <MenuItem onClick={() => openDeleteDialog(item)} disabled={!item?.label && true}>
              <ListItemIcon>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon fontSize={'medium'} />
                </IconButton>{' '}
              </ListItemIcon>
              <Typography variant="inherit">SİL</Typography>
            </MenuItem>
          </Menu>
        </StyledTableCell>
      </StyledTableRow>
      <Icd10LabelsWarningForm open={open} onClose={onClose} data={selectedData} />
    </>
  );
}

function ItemTableHead() {
  return (
    <>
      <TableRow>
        <StyledTableCell align="left" style={{ width: '4%' }}>
          ID
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '10%' }}>
          ICD10 Kodu
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '60%' }}>
          ICD10 Adı
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: '21%' }}>
          Uyarı Mesajı
        </StyledTableCell>
        <StyledTableCell align="right" style={{ width: '5%' }}>
          İşlem
        </StyledTableCell>
      </TableRow>
    </>
  );
}

export default function Icd10() {
  return (
    <>
      <Icd10Medicine<Icd10Model>
        searchService={searchIcd10}
        fetchData={getIcd10Page}
        ListTableBody={ItemTableBody}
        ListTableHead={ItemTableHead}
        searchPlaceholder="Icd 10 Adı veya Kodunu Giriniz"
      />
    </>
  );
}
