import { Button, Grid } from '@material-ui/core';
import { defer } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';

import { BuildingFloorDefinitionDto } from 'api/client';
import { BuildingFloorDefinitionControllerService } from 'api/client';
import Dialog from 'Common/Dialog';
import Form, { FormRef } from 'Common/Form/Form';
import TextField from 'Common/Form/TextField';
import { AlertContext } from 'context/alert.context';
import { useAsyncEffect } from 'utils';

interface Props {
  open: boolean;

  onClose(success: boolean): void;

  data: BuildingFloorDefinitionDto;
}

export default function BuildingsFloorsForm({ open, onClose, data }: Props) {
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();
  const { buildingsId } = useParams<{ buildingsId: string }>();

  const handleSubmit = useCallback(
    async values => {
      try {
        if (!data?.id) {
          await BuildingFloorDefinitionControllerService.saveBuildingFloorDefinition({ ...values, buildingId: buildingsId });
          showMessage('Ekleme işlemi başarılı.', 'success', 'info');
        } else {
          await BuildingFloorDefinitionControllerService.updateBuildingFloorDefinition(data.id, values);
          showMessage('Güncelleme işlemi başarılı.', 'success', 'info');
        }
        onClose(true);
      } catch (err) {
        showMessage('İşlem sırasında sorun oluştu.', 'error', 'info');
        onClose(false);
      }
    },
    [data, showMessage, onClose, buildingsId]
  );

  useAsyncEffect(async () => {
    if (!data) {
      return;
    }
    await defer();
    for (const [key, value] of Object.entries(data)) {
      formRef?.current?.methods?.setValue(key, value);
    }
    formRef?.current?.methods?.trigger();
  }, [data, open]);

  return (
    <Dialog color="primary" opened={open} onClose={() => onClose(false)} title={data ? 'Kat Düzenleme' : 'Kat Ekleme'}>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12}>
            <TextField required name="name" label="Kat Giriniz" />
          </Grid>
          <Grid item md={12} className="text-right">
            <Button variant="contained" type="submit" color="primary" className="mr-2">
              {data?.id ? 'Güncelle' : 'Kaydet'}
            </Button>
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
}
