import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';

import { InjuryTypeDefinitionControllerService, InjuryTypeDefinitionDto } from '../../api/client';
import InjuryTypeEditDialog from '../../Common/Forms/Definitions/AccidentDefinition/InjuryTypeDefinition/InjuryTypeEditDialog';
import BaseDefinitionPage from '../BaseDefinitionPage';

export default function InjuryTypeDefinition() {
  return (
    <BaseDefinitionPage<InjuryTypeDefinitionDto>
      listLabelPropName="label"
      fetchData={InjuryTypeDefinitionControllerService.getFirmInjuryTypeDefinitions}
      FormDialog={InjuryTypeEditDialog}
      onDelete={InjuryTypeDefinitionControllerService.deleteFirmInjuryTypeDefinition}
      Icon={<FlashOnIcon />}
      searchName="label"
    />
  );
}
