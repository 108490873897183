import { Grid } from '@material-ui/core';
import React from 'react';

import { saveAdminPosition, updateAdminPosition } from 'api/hs/definitions/workplace-definition/position';
import BaseDefinitionForm from 'Common/BaseDefinitionForm';
import TextField from 'Common/Form/TextField';
import { PositionModel } from 'model/definitions/workplace-definition/position.model';

interface Props {
  open: boolean;

  /**
   * Fires when the dialog is wanted to be closed
   * @param success `true` when the position is created/updated
   */
  onClose(success: boolean): void;

  data: PositionModel;
}

export default function AdminPosition({ open, onClose, data }: Props) {
  return (
    <BaseDefinitionForm<PositionModel>
      open={open}
      onClose={onClose}
      onSave={saveAdminPosition}
      onUpdate={updateAdminPosition}
      data={data}
      resourceReloadName="positions"
      createTitle="Görev / Pozisyon Ekle"
      updateTitle="Görev / Pozisyon Düzenleme"
      maxDialogWidth={500}>
      <Grid item lg={12} md={12}>
        <TextField required name="positionName" label="Görev Adı Giriniz" />
      </Grid>
    </BaseDefinitionForm>
  );
}
