import React from 'react';
import { Route, Switch } from 'react-router';

import Detail from './Detail';
import List from './List';

export default function Training() {
  return (
    <Switch>
      <Route path="/training/detail-list/:trainingIdCompleted">
        <Detail />
      </Route>
    </Switch>
  );
}
