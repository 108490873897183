import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ActionView, ActionViewControllerService } from '../../api/client';
import { FormRef } from '../../Common/Form/Form';
import Toolbar from '../../Common/Toolbar';
import { AlertContext } from '../../context/alert.context';
import { SelectOptionsContext } from '../../context/select-options.context';
import { convertAccidentReviewPropertyToChartData } from '../Accident/accident-chart-utils';
import DateGroupXYChart from '../utils/DateGroupXYChart';
import { DisplayOption } from '../utils/display-option';
import PieChart from '../utils/PieChart';
import PieChartGridContainer from '../utils/PieChartGridContainer';
import ReportForm from '../utils/ReportForm';
import SimpleCard, { chartUseStyles } from '../utils/SimpleCard';

export default function Actions() {
  const [chartData, setChartData] = useState<ActionView[]>([]);
  const [dataDisplayOption, setDataDisplayOption] = useState<DisplayOption>();

  const { actionsReferenceDefinitions, actionsPriorityDefinitions, actionsStatusDefinitions } = useContext(SelectOptionsContext);

  const [collapsed, setCollapsed] = useState(false);
  const classes = chartUseStyles();

  const submit = useCallback(async values => {
    const request = {
      actionFilter: {
        createdMinDate: values?.minDate + 'T00:00:00',
        createdMaxDate: values?.maxDate + 'T23:59:59',
      },
      pagingAndSortingRequestParam: {
        page: 0,
        size: 2147483647,
        order: 'createdDate',
      },
    };
    const res = await ActionViewControllerService.filterActions(request);
    setChartData(res.content);
    setDataDisplayOption(values?.dataDisplayOption);
    return res.content;
  }, []);
  return (
    <div>
      <Toolbar color="secondary" className={`py-1`}>
        <Typography variant="h6" color="inherit" className="text-bold">
          Aksiyon İstatistikleri
        </Typography>
      </Toolbar>
      <ReportForm
        onSubmit={submit}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        selectedOption={DisplayOption.ANNUALLY}
        options={[
          DisplayOption.TOTAL,
          DisplayOption.ANNUALLY,
          DisplayOption.SEMIANNUALLY,
          DisplayOption.QUARTERLY,
          DisplayOption.MONTHLY,
          DisplayOption.DAILY,
        ]}
      />
      <Grid container>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard className={classes.rootBgRed} title={'Toplam Aksiyon'} amount={chartData?.length} />
        </Grid>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard
            className={classes.rootBgOrange}
            title={'Tamamlanan Aksiyon'}
            amount={chartData?.filter(filtreDate => filtreDate.completedDate)?.length}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4} sm={4}>
          <SimpleCard
            className={classes.rootBgCyan}
            title={'Planlanan Aksiyon'}
            amount={chartData?.filter(filtreDate => filtreDate.plannedEndDate && !filtreDate.completedDate)?.length}
          />
        </Grid>
      </Grid>
      {chartData.length! > 0 && (
        <>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <PieChartGridContainer
                title="Aksiyon Kaynağına Göre Dağılımı"
                chartProps={{
                  chartName: 'actions-actionReference-report',
                  preparedData: convertAccidentReviewPropertyToChartData(chartData, 'actionReference', actionsReferenceDefinitions),
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <PieChartGridContainer
                title="Aksiyon Önceliğine Göre Dağılımı"
                chartProps={{
                  chartName: 'actions-actionsPriorityDefinitions-report',
                  preparedData: convertAccidentReviewPropertyToChartData(chartData, 'actionPriorityDefinition', actionsPriorityDefinitions),
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <PieChartGridContainer
                title="Aksiyon Durumuna Göre Dağılımı"
                chartProps={{
                  chartName: 'actions-actionStatusDefinition-report',
                  preparedData: convertAccidentReviewPropertyToChartData(chartData, 'actionStatusDefinition', actionsStatusDefinitions),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
