import React from 'react';

import PlaningList from './PlaningList';
import ReceivedList from './ReceivedList';
export default function EmployeeTrainingList() {
  return (
    <>
      <PlaningList />
      <ReceivedList />
    </>
  );
}
