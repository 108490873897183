import React, { useCallback, useContext } from 'react';

import { HSTableContext } from '../../../Common/Table/HSTable/hs-table.context';
import PpeOutputsFormDialog from '../ppe-new-output';
import { PpeOutputsContext } from '../ppe-outputs.context';

export default function NewOutputDialog() {
  const { newDialogOpened, setNewDialogOpened } = useContext(PpeOutputsContext);
  const { refresh } = useContext(HSTableContext);

  const closeDialog = useCallback(
    async (success: boolean) => {
      if (success) {
        await refresh();
      }
      setNewDialogOpened(false);
    },
    [setNewDialogOpened, refresh]
  );

  return <PpeOutputsFormDialog open={newDialogOpened} onClose={closeDialog} />;
}
