import DomainIcon from '@material-ui/icons/Domain';
import React from 'react';

import { AllergyResult, deleteAdminAllergy, getAdminAllergy } from 'api/hs/definitions/health-definitions/allergy';
import AdminAllergyEditDialog from 'Common/Forms/Definitions/HealthDefinition/Allergy/AdminAllergyEditDialog';

import BaseDefinitionPage from '../BaseDefinitionPage';

export default function AdminAllergy() {
  return (
    <BaseDefinitionPage<AllergyResult>
      listLabelPropName="allergyName"
      fetchData={getAdminAllergy}
      FormDialog={AdminAllergyEditDialog}
      onDelete={deleteAdminAllergy}
      Icon={<DomainIcon />}
    />
  );
}
