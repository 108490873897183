import { Button, FormControl, Grid, RadioGroup } from '@material-ui/core';
import { noop } from '@thalesrc/js-utils';
import React, { useCallback, useContext, useRef, useState } from 'react';

import Radio from 'Common/Form/Radio';

import {
  AccidentReviewControllerService,
  EmployeeDto,
  EmployeeSearchGuestControllerService,
  PositionDefinitionControllerService,
} from '../../../api/client';
import Dialog from '../../../Common/Dialog';
import AutoComplete from '../../../Common/Form/AutoComplete';
import DateInput from '../../../Common/Form/DateInput';
import Form, { FormRef } from '../../../Common/Form/Form';
import TextField from '../../../Common/Form/TextField';
import { AlertContext } from '../../../context/alert.context';
import { useAsyncEffect, useTriggerRender } from '../../../utils';
import { AccidentReviewContext } from '../accident-review-context';
import { employeeLabelFetcher, employeeOptionFetcher } from '../utils';
import SubmitButton from './SubmitButton';

interface Props {
  fetch(): void;
  open: boolean;
  onClose(val: boolean): void;
  data: any;
}

export default ({ fetch, open, onClose, data }: Props) => {
  const reRender = useTriggerRender();
  const { token, accidentId, reviewer } = useContext(AccidentReviewContext);
  const { showMessage } = useContext(AlertContext);
  const formRef = useRef<FormRef<Record<string, any>>>();

  const [loading, setLoading] = useState<boolean>(false);
  const [employee, setEmployee] = useState<EmployeeDto>();
  const [employeePositions, setEmployeePositions] = useState<Map<string, string>>();

  useAsyncEffect(async () => {
    const positions = await PositionDefinitionControllerService.getAllFirmPositionDefinitions(token);
    const positionMap = new Map();

    positions.forEach(position => positionMap.set(position.id, position.label));
    setEmployeePositions(positionMap);
  }, []).then(noop);

  const submit = (values: any) => {
    setLoading(true);

    const onSuccess = () => {
      showMessage('Kaydedildi.', 'success', 'info');
      fetch();
      onClose(false);
    };

    const onCatch = () => showMessage('Bir hata oluştu.', 'error', 'info');
    const onFinally = () => setLoading(false);

    if (data) {
      if (data.employee) {
        AccidentReviewControllerService.updateEmployeeWitness(
          data.id,
          {
            note: values.note,
            interviewDate: values.interviewDate,
          },
          token
        )
          .then(onSuccess)
          .catch(onCatch)
          .finally(onFinally);
        return;
      }

      if (data.guest) {
        AccidentReviewControllerService.updateGuestWitness(
          data.id,
          {
            note: values.note,
            interviewDate: values.interviewDate,
          },
          token
        )
          .then(onSuccess)
          .catch(onCatch)
          .finally(onFinally);
      }
      return;
    }

    if (values.witnessType === 'employee') {
      const request = {
        employeeId: employee.id,
        note: values.note,
        interviewDate: values.interviewDate,
      };
      AccidentReviewControllerService.saveEmployeeWitness(accidentId, reviewer, request, token)
        .then(onSuccess)
        .catch(onCatch)
        .finally(onFinally);
      return;
    }

    if (values.witnessType === 'guest') {
      const request = {
        name: values.name,
        title: values.title,
        surname: values.surname,
        note: values.note,
        interviewDate: values.interviewDate,
      };
      AccidentReviewControllerService.saveGuestWitness(accidentId, null, request, token).then(onSuccess).catch(onCatch).finally(onFinally);
    }
  };

  const employeeId = formRef?.current?.methods?.getValues('employeeId');
  useAsyncEffect(() => {
    if (employeeId && employeeId.length > 0) {
      EmployeeSearchGuestControllerService.getEmployee(employeeId, token).then(setEmployee);
    }
  }, [employeeId]);

  return (
    <Dialog width="md" color="secondary" opened={open} onClose={onClose.bind(null, false)} title={data ? 'Düzenle' : 'Tanık Ekle'}>
      <Form onChange={reRender} onSubmit={submit} ref={formRef}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data ? (
              data.guest ? (
                <h4>Misafir</h4>
              ) : (
                <h4>Çalışan</h4>
              )
            ) : (
              <FormControl component="fieldset">
                <RadioGroup row>
                  <Radio name="witnessType" label="Çalışan" value="employee" />
                  <Radio name="witnessType" label="Misafir" value="guest" />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>

          {formRef.current?.value?.witnessType === 'employee' && (
            <>
              <Grid item xs={12}>
                <AutoComplete
                  name="employeeId"
                  label="Personel"
                  optionFetcher={employeeOptionFetcher}
                  labelFetcher={employeeLabelFetcher}
                  defaultValue={null}
                />
              </Grid>
              {employee && (
                <Grid container alignItems={'flex-end'} spacing={2} className="m-2">
                  <Grid xs={12} sm={6} md={3}>
                    {employee.nid}
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    {employeePositions.has(employee.positionId) ? employeePositions.get(employee.positionId) : '-'}
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    {employee.name}
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <DateInput name="interviewDate" label="Görüşme Tarihi" type="date" required />
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {formRef.current?.value?.witnessType === 'guest' && (
            <Grid container spacing={2} alignItems={'baseline'} className={'m-2'}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="name" label="İsim" required />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="surname" label="Soyisim" required />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField name="title" label="Görev/Ünvan" required />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <DateInput name="interviewDate" label="Görüşme Tarihi" type="date" required />
              </Grid>
            </Grid>
          )}

          {data && (
            <Grid xs={12}>
              <DateInput name="interviewDate" label="Görüşme Tarihi" type="date" required defaultValue={data?.interviewDate} />
            </Grid>
          )}

          <Grid item xs={12} className="mt-2">
            <TextField name="note" rows={5} label="Tanık görüşme notu..." required defaultValue={data?.note} />
          </Grid>

          <Grid item md={12} className="text-right">
            <SubmitButton loading={loading} data={data} />
            <Button variant="contained" type="button" onClick={onClose.bind(null, false)}>
              Vazgeç
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Dialog>
  );
};
