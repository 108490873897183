import React, { useCallback, useContext } from 'react';

import { InjectionContext } from '../../injection.context';
import InjectionDetailForm from '../Form';

export default function EditDialog() {
  const { editDialogOpened, setEditDialogOpened, selectedData, reload } = useContext(InjectionContext);
  const closeDialog = useCallback(
    (success: boolean) => {
      if (success) {
        reload();
      }
      setEditDialogOpened(false);
    },
    [reload, setEditDialogOpened]
  );

  return <InjectionDetailForm open={editDialogOpened} onClose={closeDialog} data={selectedData} />;
}
