import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { getMedicalIntervention, MedicalInterventionResult } from 'api/hs/examination/medical-intervention';
import { MedicalInterventionsModel } from 'model/employee/examination/medical-interventions.model';
import { useAsyncEffect } from 'utils';

import { MedicalInterventionContext } from './medical-intervention.context';
import MedicalIntervention from './MedicalInterventions';

export default function Index() {
  const [medicalIntervention, setMedicalIntervention] = useState<MedicalInterventionResult[]>(null);
  const [loading, setLoading] = useState(null);
  const [newDialogOpened, setNewDialogOpened] = useState(false);
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [selectedData, setSelectedData] = useState<MedicalInterventionsModel>(null);
  const { examinationId } = useParams<{ examinationId: string }>();

  const fetch = useCallback(async () => {
    const result = await getMedicalIntervention(examinationId);
    setMedicalIntervention(result);
    setLoading(false);
  }, [examinationId]);

  useAsyncEffect(async () => {
    await fetch();
  }, []);

  return (
    <MedicalInterventionContext.Provider
      value={{
        medicalIntervention,
        reload: fetch,
        loading,
        editDialogOpened,
        setEditDialogOpened,
        setLoading,
        newDialogOpened,
        setNewDialogOpened,
        selectedData,
        setSelectedData,
      }}>
      <MedicalIntervention />
    </MedicalInterventionContext.Provider>
  );
}
