import { createContext } from 'react';

import { WorkEquipmentJobStatusView } from '../../api/client';

export interface WorkEquipmentJobStatusContextType {
  editDialogOpened: boolean;

  setEditDialogOpened(opened: boolean): void;

  fileDialogOpened: boolean;

  setFileDialogOpened(opened: boolean): void;

  jobStatusDetailDialogOpened: boolean;

  setJobStatusDetailDialogOpened(opened: boolean): void;

  selectedData: WorkEquipmentJobStatusView;

  setSelectedData(data: WorkEquipmentJobStatusView): void;
}

export const WorkEquipmentJobStatusContext = createContext<WorkEquipmentJobStatusContextType>(null);
